import React from 'react';
import Component from 'react-pure-render/component';

import report from './analytics';

import TrThankYou from '../tr-thank-you';

export default class TrThankYouContainer extends Component {
  componentDidMount() {
    report('mounted');
  }

  render() {
    return (
      <div className="tr-thank-you-container">
        <TrThankYou />
      </div>
    );
  }
}
