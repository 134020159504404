import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
const requireSceenImg = id =>
  process.env.IS_BROWSER ? require(`./img/screens/${id}.png`) : '';

const screensImgs = {
  overview: requireSceenImg('overview'),
  holdings: requireSceenImg('holdings'),
  analysis: requireSceenImg('analysis')
};

const incrementIndex = (index, arr) =>
  index === arr.length - 1 ? 0 : index + 1;

export default class WelcomeScreens extends Component {
  static propTypes = {
    msg: PropTypes.object,
    screenAnimationDuration: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      activeScreen: 0
    };
  }

  nextScreen = () => {
    const { msg } = this.props;
    const { activeScreen } = this.state;
    this.setState({
      activeScreen: incrementIndex(activeScreen, msg.screens)
    });
  };

  componentDidMount() {
    const { screenAnimationDuration = 8000 } = this.props;

    this.screenAnimation = setInterval(
      this.nextScreen,
      screenAnimationDuration
    );
  }

  componentWillUnmount() {
    clearInterval(this.screenAnimation);
  }

  render() {
    const { msg } = this.props;

    const { activeScreen } = this.state;

    return (
      <ul className="screens">
        {msg.screens.map((screen, index) => (
          <li
            className={classNames({ active: activeScreen === index })}
            key={screen._id}
          >
            <img className="screen-img" src={screensImgs[screen._id]} />
            <div className="screen-box-wrapper">
              <section className="screen-box">
                <header>
                  <h1>{screen.title}</h1>
                </header>
                <main>{screen.text}</main>
              </section>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
