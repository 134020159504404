import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

export const BarSlider = props => {
  const titleStyle = {
    width: props.titleWidth || ''
  };
  const barStyle = {
    width: props.lineWidth / 2 || ''
  };
  const triangleStyle = {};
  var cn = 'bar-slider-container';
  if ('inverted' in props) {
    cn = 'bar-slider-container-inverted';
  }
  if ('noline' in props) {
    barStyle.backgroundColor = 'inherit';
    triangleStyle.top = '17px';
    titleStyle.top = '25px';
  }

  const valueEl = 'value' in props && (
    <div key="0" className="bar-slider-value">
      <FormattedNumber maximumFractionDigits={2} value={props.value || 0} />
    </div>
  );

  const lineEl = (
    <div key="1" className="bar-slider-line-cutter" style={barStyle}>
      <div className="bar-slider-triangle" style={triangleStyle} />
    </div>
  );

  const titleEl = (
    <div key="2" className="bar-slider-title" style={titleStyle}>
      {props.text}
    </div>
  );

  const orderedEls =
    'inverted' in props
      ? [lineEl, valueEl, titleEl]
      : [titleEl, valueEl, lineEl];

  return (
    <div className={cn} style={{ ...{ left: props.left } }}>
      <div className="bar-slider">{orderedEls}</div>
    </div>
  );
};

export default BarSlider;
