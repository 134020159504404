import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router';
import { portfolioItemUrl } from '../decisions/index.ts';
import { getNasdaqSymbolUrl } from '../../common/lib/utils.ts';
import { NewsAnalysis } from '../news-analysis/index.react.tsx';
import {
  FormattedDate,
  FormattedHTMLMessage,
  FormattedMessage,
} from 'react-intl';
import { NoDataAddStocks } from '../dashboard/NoDataAddStocks.tsx';
import { Loader } from '../components/suit';
import { dashboardPath } from '../../common/config';
import { StockLink } from 'sp/browser/StockLink/StockLink';

const defaultTab = 'all';

export default class PortfolioNews extends Component {
  static propTypes = {
    clickedNewsItemLink: PropTypes.func,
    dependencies: PropTypes.any,
    fetchData: PropTypes.func,
    isSentimentMenuOutside: PropTypes.bool,
    max: PropTypes.number,
    msg: PropTypes.object,
    news: PropTypes.object,
    noData: PropTypes.bool,
    noDataMsg: PropTypes.object,
    onTickerChange: PropTypes.func,
    openAddStockDialog: PropTypes.func,
    shouldShowSitePic: PropTypes.bool,
    stocks: PropTypes.object,
    ticker: PropTypes.string,
    viewPort: PropTypes.string,
    isInsidePortfolioNews: PropTypes.bool, // this component shows analysis in portfolio news, but not in overview page.
    tabId: PropTypes.string,
    setTab: PropTypes.fund,
  };

  static defaultProps = {
    isSentimentMenuOutside: false,
    max: -1,
    shouldShowSitePic: false,
    ticker: '-1',
  };

  getMenuBtns = () =>
    this.props.isInsidePortfolioNews
      ? ['all', 'analysis', 'bullish', 'bearish']
      : ['all', 'bullish', 'bearish'];

  componentWillReceiveProps({ onTickerChange, stocks, ticker }) {
    const doesTickerExist = stocks
      .map(stock => stock.get('ticker'))
      .includes(ticker);

    if (ticker !== '-1' && !doesTickerExist) onTickerChange('-1');
  }

  onTickerChange = ({ target }) => {
    const { onTickerChange } = this.props;
    onTickerChange(target.value);
  };

  getStockName = () => {
    const { ticker, stocks } = this.props;
    const stock = stocks.find(stock => stock.get('ticker') === ticker);
    return stock ? stock.get('name') : '';
  };

  outOtherSentiments = newsItem => {
    const currentTab = this.getCurrentTab();
    const sentiment = newsItem.get('sentiment');
    return currentTab === 'all' || currentTab === sentiment;
  };

  outOtherTickers = newsItem => {
    const { ticker } = this.props;
    return (
      ticker === '-1' ||
      newsItem.get('ticker').toUpperCase() === ticker.toUpperCase()
    );
  };

  outByPref = newsItem =>
    this.outOtherSentiments(newsItem) && this.outOtherTickers(newsItem);

  sitePicture = sitePicture =>
    sitePicture
      ? `https://trstorage1.blob.core.windows.net/news-sites-pics/${sitePicture}.jpg`
      : require('./img/defaultSitePicture.png');

  stock = ticker => {
    const { stocks } = this.props;
    const stock = stocks.find(newsItem => ticker === newsItem.get('ticker'));
    return stock;
  };
  getCurrentTab = () =>
    !this.props.isInsidePortfolioNews && this.props.tabId === 'analysis'
      ? 'all'
      : this.props.tabId || defaultTab;

  renderTickerMenu = () => {
    const { msg, onTickerChange, stocks, ticker, viewPort } = this.props;
    const currentTab = this.getCurrentTab();
    return (
      viewPort === 'tablet' && (
        <menu className="ticker-menu">
          <ul>
            <li
              className={classNames({
                active: ticker === '-1',
                disabledd: currentTab === 'analysis',
              })}
            >
              <button onClick={() => onTickerChange('-1')}>
                <span className="ticker">{msg.allHoldings.toUpperCase()}</span>
              </button>
            </li>
            {stocks.map(stock => (
              <li
                className={classNames({
                  active: stock.get('ticker') === ticker,
                })}
                key={stock.get('ticker')}
              >
                <button onClick={() => onTickerChange(stock.get('ticker'))}>
                  <span className="ticker">
                    {stock.get('ticker').toUpperCase()}
                  </span>
                  &nbsp;
                  <span className="name">{stock.get('name')}</span>
                </button>
              </li>
            ))}
          </ul>
        </menu>
      )
    );
  };
  render() {
    const {
      dependencies,
      isSentimentMenuOutside,
      max,
      msg,
      news,
      noDataMsg,
      openAddStockDialog,
      shouldShowSitePic,
      stocks,
      ticker: rawTicker,
      viewPort,
      activateNewsAnalysis,
      isInsidePortfolioNews,
      mediaQuery,
    } = this.props;
    const ticker = rawTicker.toUpperCase();
    const isLoading = dependencies === 'LOADING';

    const filteredNews = news.filter(this.outByPref);
    const limitedFilteredNews =
      max >= 0 ? filteredNews.take(max) : filteredNews;
    const stock = this.stock(rawTicker);
    const hasLink = !!stock;
    const currentTab = this.getCurrentTab();
    const noData = msg.noNews[currentTab].noData;
    const isNasdaq = process.env.THEME === 'nasdaq';
    const isCurrentStockCanadian =
      !!stock && stock.get('ticker').indexOf('tse') !== -1;
    const isCanadaNewsInMurica = isNasdaq && isCurrentStockCanadian;
    const isSecondaryTicker = stock && stock.get('isSecondaryTicker');
    const isETF = stock && stock.get('type') === 'etf';

    return (
      <section
        className={classNames('tr-dashboard-portfolio-news', {
          isSentimentMenuOutside,
        })}
      >
        {/*! isSentimentMenuOutside && this.renderTickerMenu()*/}
        <div className="headerAndMain">
          <header className="tr-dashboard-portfolio-news">
            {!isSentimentMenuOutside && (
              <h1>
                <FormattedMessage
                  id={msg.title[viewPort]}
                  defaultMessage={msg.title[viewPort]}
                  values={{
                    ticker:
                      ticker === '-1' ? (
                        <span>{msg.allHoldings}</span>
                      ) : (
                        <span>
                          ({hasLink ? (
                            <StockLink
                              className="ticker"
                              stock={stock}
                              target="_blank"
                            >
                              {ticker}
                            </StockLink>
                          ) : (
                            ticker.toUpperCase()
                          )})
                        </span>
                      ),
                    name: <span className="name">{this.getStockName()}</span>,
                  }}
                />
              </h1>
            )}
            <label>
              <span className="label">{msg.tickerLabel}</span>
              <select onChange={this.onTickerChange} value={ticker}>
                {currentTab !== 'analysis' && (
                  <option value="-1">{msg.allHoldings}</option>
                )}
                {stocks.map(stock => (
                  <option key={stock.get('ticker')} value={stock.get('ticker')}>
                    {stock.get('name')}
                    &nbsp; ({stock.get('ticker').toUpperCase()})
                  </option>
                ))}
              </select>
            </label>
          </header>
          <main className="tr-dashboard-portfolio-new">
            <menu className="tabs-menu">
              <ul>
                {this.getMenuBtns().map(btn => (
                  <li
                    className={classNames(btn, {
                      active: btn === currentTab,
                    })}
                    key={btn}
                    onClick={() => this.props.setTab(btn)}
                  >
                    <button disabled={btn === currentTab}>
                      {msg.menu[btn]}
                    </button>
                  </li>
                ))}
              </ul>
            </menu>
            <div className="main">
              {this.renderTickerMenu()}
              {isSentimentMenuOutside && (
                <div className="header">
                  <div
                    className={classNames('h1', {
                      bullish: ticker === '-1' && currentTab === 'bullish',
                      negative: ticker === '-1' && currentTab === 'bearish',
                    })}
                  >
                    <FormattedMessage
                      id={
                        msg.title[
                          ticker === '-1'
                            ? 'isSentimentMenuOutsideNeutral'
                            : 'isSentimentMenuOutside'
                        ]
                      }
                      defaultMessage={
                        msg.title[
                          ticker === '-1'
                            ? 'isSentimentMenuOutsideNeutral'
                            : 'isSentimentMenuOutside'
                        ]
                      }
                      values={{
                        ticker:
                          ticker === '-1' ? (
                            <span>{msg.allHoldings}</span>
                          ) : (
                            <span>
                              {hasLink ? (
                                <StockLink
                                  className="ticker"
                                  stock={stock}
                                  target="_blank"
                                >
                                  {ticker}
                                </StockLink>
                              ) : (
                                ticker.toUpperCase()
                              )}
                            </span>
                          ),
                        name: (
                          <span className="name">{this.getStockName()}</span>
                        ),
                        sentiment: (
                          <span className={currentTab}>
                            <FormattedMessage
                              id={msg.sentimentTitle}
                              defaultMessage={msg.sentimentTitle}
                              values={{
                                sentiment: msg[`sentimentTitle.${currentTab}`],
                              }}
                            />
                          </span>
                        ),
                        sentimentNeutral:
                          msg[`isSentimentMenuOutsideNeutral.${currentTab}`],
                      }}
                    />
                  </div>
                </div>
              )}
              {currentTab === 'analysis' &&
              isInsidePortfolioNews &&
              stocks.size > 0 &&
              !isCanadaNewsInMurica ? (
                <NewsAnalysis
                  ticker={ticker.toUpperCase()}
                  mediaQuery={mediaQuery}
                />
              ) : (
                <div className="news-items-wrapper">
                  <div className="news-items">
                    <ul>
                      {limitedFilteredNews.size > 0 &&
                      stocks.size > 0 &&
                      !isCanadaNewsInMurica &&
                      !isSecondaryTicker ? (
                        limitedFilteredNews.map((newsItem, index) => (
                          <li
                            className={classNames('newsItem', {
                              havePic: shouldShowSitePic,
                            })}
                            key={index}
                          >
                            {shouldShowSitePic && (
                              <a
                                className="sitePic"
                                href={newsItem.get('url')}
                                target="_blank"
                              >
                                <img
                                  alt=""
                                  src={this.sitePicture(
                                    newsItem.get('pictureUrl'),
                                  )}
                                />
                              </a>
                            )}
                            <span className="date">
                              <FormattedDate
                                day="numeric"
                                month="short"
                                value={newsItem.get('date')}
                                year="numeric"
                              />
                            </span>
                            <span className="ticker">
                              {newsItem.get('ticker').toUpperCase()}
                            </span>
                            <div className="textAndSite">
                              <a
                                className="text"
                                href={newsItem.get('url')}
                                target="_blank"
                              >
                                {newsItem.get('text')}
                              </a>
                              <span className="site">
                                {newsItem.get('site')}
                              </span>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div className="no-data-wrapper">
                          {isLoading ? (
                            <div className="no-data">
                              <Loader name="portfolio-news" />
                            </div>
                          ) : stocks.size === 0 ? (
                            <NoDataAddStocks
                              msg={noDataMsg}
                              openAddStockDialog={openAddStockDialog}
                            />
                          ) : // TODO not sure there even needs to be a condition here for this
                          isCanadaNewsInMurica || isSecondaryTicker ? (
                            <div className="no-data">
                              <FormattedMessage
                                defaultMessage={msg.noNews.all.noData}
                                id={msg.noNews.all.noData}
                                values={{ ticker }}
                              />
                            </div>
                          ) : (
                            <div className="no-data">
                              <FormattedMessage
                                id={noData}
                                defaultMessage={
                                  noData +
                                  ' Please try again later as this section is updated instantly.'
                                }
                                values={{
                                  ticker:
                                    ticker === '-1'
                                      ? msg.noNews.allHoldings
                                      : ticker.toUpperCase(),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {isSentimentMenuOutside && (
                <div className="panel">
                  <Link to={`/${dashboardPath}/holdings/news`}>
                    <FormattedHTMLMessage
                      id={msg.next}
                      defaultMessage={msg.next}
                    />
                  </Link>
                </div>
              )}
            </div>
          </main>
        </div>
      </section>
    );
  }
}
