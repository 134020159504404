import * as React from 'react';
import * as classNames from 'classnames';
import { imgSrc, ImageOptions } from '../../common/lib/utils';

export default class AlertsIndicator extends React.PureComponent<{
  alerts: any, //PropTypes.object,
  isColorful: boolean, //PropTypes.bool,
  onClick: any, //PropTypes.func,
  readAllAlerts: boolean, //PropTypes.bool
}> {

  static defaultProps = {
    isColorful: true
  };

  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  outRead = alert => !alert.get('hasBeenRead');

  render() {
    const { alerts, isColorful, readAllAlerts } = this.props;

    const hasAlerts = alerts.size > 0;

    const images: { default: ImageOptions, active: ImageOptions } = {
      default: isColorful && false ? 'bellSmaller' : 'bell',
      active: isColorful && false ? 'activeBellSmaller' : 'activeBell'
    };

    return (
      <div
        onClick={this.onClick}
        className={classNames('alerts-indicator', {
          readAllAlerts: readAllAlerts || !hasAlerts
        })}
      >
        <div className="count">
          <div className="count-inner">{alerts.filter(this.outRead).size}</div>
        </div>
        {!hasAlerts ? (
          <img src={imgSrc(images.default)} alt="" />
        ) : (
            <img src={imgSrc(images.active)} alt="" />
          )}
      </div>
    );
  }
}
