import '../../tr-dashboard-user-performance-container/index.styl';
import './PortfolioPerformanceBox.styl';

import * as classNames from 'classnames';
import * as React from 'react';

import { PerformanceDropdownTrio } from '../dropdown-trio';
import {
  defaultPerformanceDropdownOptions as defaultOptions,
  Option,
  optionId,
  NONE,
  MY_PORTFOLIO_OPT,
  AVG_PORTFOLIO_OPT,
  BEST_PORTFOLIO_OPT,
  SNP_OPT,
} from '../dropdown/PerformanceDropdown';
import { PeriodicGraphWithTable } from '../periodic-graph';
import { GraphAlignment } from '../periodic-graph/PeriodicGraph';
import {
  PerfGain, getPeriodicTableColumns, PerformancePeriodicGraphDatum, Timestamp,
} from '../periodic-graph/PeriodicPerformanceGraph';
import { get } from 'lodash';

export interface State {
  selectedOptions: Option[];
}

export interface Props extends ComponentData {
  itemNum: number;
  xAxisLabelTextAlign: GraphAlignment;
  className?: string;
}

export interface ComponentData {
  myPortfolioData: PerfGain[];
  bestPortfolioData: PerfGain[];
  avgPortfolioData: PerfGain[];
  snpPortfolioData: PerfGain[];
}

export class PortfolioPerformanceBox extends React.PureComponent<Props, State> {
  state = {
    selectedOptions: [defaultOptions[0], defaultOptions[1]],
  };

  render() {
    const { selectedOptions } = this.state;
    const {
      myPortfolioData,
      itemNum,
      className,
      children,
      xAxisLabelTextAlign,
    } = this.props;


    const option1 = selectPerfDropdownOption(this.props, selectedOptions[0])
    const option2 = selectPerfDropdownOption(this.props, selectedOptions[1])

    if (
      !myPortfolioData.length &&
      !get(option1, 'data.length', 1) &&
      !get(option2, 'data.length', 1)
    ) {
      if (process.env.IS_RUN_LOCAL)
        console.warn('No data for PortfolioPerformanceBox');
      return <span />;
    }
    const myPortfolioDataOption = { ...MY_PORTFOLIO_OPT, data: myPortfolioData };

    const data = [myPortfolioDataOption]
    if (option1) data.push(option1);
    if (option2) data.push(option2);

    return (
      <section className={classNames('content', className)}>
        <PerformanceDropdownTrio
          onSelectCancellable={this.selectFromDropdownTrio}
          className={classNames('trio')}
          options={defaultOptions}
        />
        <PeriodicGraphWithTable
          className={classNames('graph')}
          data={data}
          itemNum={itemNum}
          columns={this.getColumns()}
          xAxisLabelTextAlign={xAxisLabelTextAlign}
        />
      </section>
    );
  }
  selectFromDropdownTrio = (index: number, option: Option) => {
    const [opt1, opt2] = this.state.selectedOptions;
    const selectedOptions = [opt1, opt2];
    selectedOptions[index] = option;
    this.setState({ selectedOptions });
    return true;
  };
  getColumns = (): Timestamp[] =>
    getPeriodicTableColumns([
      this.props.avgPortfolioData,
      this.props.bestPortfolioData,
      this.props.snpPortfolioData,
      this.props.myPortfolioData,
    ], this.props.itemNum)
}

function selectPerfDropdownOption(props: ComponentData, option: Option) {
  switch (option.name) {
    case optionId.AVG_PORTFOLIO_ID: return { ...AVG_PORTFOLIO_OPT, data: props.avgPortfolioData };
    case optionId.BEST_PORTFOLIO_ID: return { ...BEST_PORTFOLIO_OPT, data: props.bestPortfolioData };
    case optionId.SNP_ID: return { ...SNP_OPT, data: props.snpPortfolioData };
    case optionId.NONE_ID:
    default: return null;
  }
}
