import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics';
import Promotion from '../tr-dashboard-promotion';

import { selectTheme } from '../dashboard/selectors';

export default class TrDashboardPromotionContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object,
    plan: PropTypes.string,
    type: PropTypes.string
  };

  componentDidMount() {
    const { type } = this.props;
    report('saw');
    report('sawType', type);
  }

  clickedUpgrade = type => {
    return () => {
      const { actions } = this.props;
      report('clickedUpgrade', type);
      window.preventNavConfirm = true;
      actions.closeAllDialogs();
    };
  };

  render() {
    const {
      actions,
      auth,
      dashboard,
      mediaQuery,
      msg,
      plan,
      type
    } = this.props;

    if (!(plan && type)) {
      actions.closeDialog();
    }

    return (
      <Promotion
        clickedUpgrade={this.clickedUpgrade}
        currentPlan={auth.getIn(['users', 'viewer', 'plan'])}
        email={auth.getIn(['users', 'viewer', 'loggedInUser', 'email'])}
        enums={msg.enums}
        isLaptop={mediaQuery.get('laptop')}
        isTablet={mediaQuery.get('tablet')}
        msg={msg.promotion}
        name={auth.getIn(['users', 'viewer', 'loggedInUser', 'name'])}
        plan={plan}
        theme={selectTheme(dashboard)}
        type={type}
      />
    );
  }
}
