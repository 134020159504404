import { Record, List } from 'immutable';

import * as actions from './actions';

export const InitialState = Record({
  toasts: new List()
});

const initialState = new InitialState();
const revive = state => initialState.merge(state);

export default (state = initialState, action) => {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.SHOW_TOAST: {
      return state.set('toasts', new List([action.toastId]));
    }

    case actions.HIDE_TOAST: {
      return state.update('toasts', items =>
        items.delete(items.findIndex(itemId => itemId === action.toastId))
      );
    }
  }

  return state;
};
