import { brandName } from '../../common/config';

export default {
  title: 'Welcome to your Smart Portfolio',
  title_isPermanentLoggedIn: 'Congratulations!',
  undertitle: 'Add stocks to create your FREE Smart Portfolio',
  undertitle_isPermanentLoggedIn:
    'Your portfolio has been upgraded to Smart Portfolio',
  openSmartPortfolio: 'Open Smart Portfolio',
  addStocks: 'Add Stocks Manually',
  connectPortfolio: 'Connect Your Existing Portfolio',
  placeholder: 'Enter Symbol or Company Name',
  addSymbol: 'Add Symbol',
  terms: 'I agree to TipRanks\'',
  termsBrokers: 'By clicking you agree to TipRanks',
  termsLink: 'Terms of use',
  privacyPolicyLink: 'Privacy Policy',
  connect: 'Simply connect your existing broker account and have it analyzed',
  seeAll: 'See all supported brokers',
  buttonSearch: 'Create Portfolio',
  buttonConnect: 'Connect Your Portfolio',
  mobileBanner: {
    new: 'NEW!',
    title: `Smart Portfolio @ ${brandName}`
  },
  banner: {
    new: 'NEW!',
    title: `Smart Portfolio @ ${brandName}`,
    discover: 'Discover the ultimate tool to maximize your returns',
    getNotifications:
      'Get real-time notifications from the most accurate analysts',
    seeHowPerform: 'Compare your portfolio’s performance to other investors',
    learn: 'Optimize your asset allocation, risk, and dividend yield',
    alerts: 'Receive real-time alerts about important activity of your holdings'
  }
};
