import { fromJS } from 'immutable';
import { createWebsiteLink } from 'sp/browser/lib/utils';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

import { communityNasdaqUrl } from '../common/lib/utils';
import {
  selectAnyPortfolioCurrentlyHasPerformance,
  selectAnyPortfolioCurrentlyPublic,
  selectHasMadeAnyPortfolioPublicEver,
  selectIsPermanentLoggedIn,
  selectUserPublicSlug,
  selectUserPublicId,
} from './auth/selectors';
import { AuthRecord } from './types';

const imgSrc = path => path.join('/');

export const BROWSER_DEVELOPMENT =
  process.env.NODE_ENV !== 'production' && process.env.IS_BROWSER; // eslint-disable-line no-undef // eslint-disable-line no-undef

export const isWeb = process.env.THEME === 'web';
export const isNasdaq = process.env.THEME === 'nasdaq';
export const brandName = isWeb ? 'TipRanks' : 'Nasdaq';

export const dashboardPath = 'smart-portfolio';

export const links = {
  contactUs: process.env.THEME === 'nasdaq' ? '/smart-portfolio/contact' : 'contact',
}

export const autocompleteUrl = `${process.env.AUTOCOMPLETE_API}` || 'https://autocomplete.tipranks.com';

export const resrictions = fromJS({
  expertAlertsLimit: {
    open: 0,
    free: 1,
    premium: 5,
    ultimate: -1,
  },
});

export const nasdaqLogin = communityNasdaqUrl;

export type SidebarOption = {
  id: RouteId;
  consideredActive?: string;
  routes?: string[];
  forceAbsoluteness?: boolean;
  url?: string; // this takes priority
  urlFromAuth?: (auth: AuthRecord) => string; // over this
  isOpenToFreeUsers?: boolean;
  className?: string;
  predicate?: (auth: AuthRecord) => boolean;
  disabled?: (auth: AuthRecord) => boolean;
  whyDisabled?: (auth: AuthRecord) => string;
};
export const shouldShowMyAccountBtn = process.env.THEME === 'web';
const myAccountOption: SidebarOption[] = shouldShowMyAccountBtn
  ? [{
    id: 'account',
    url: '/account/settings',
    forceAbsoluteness: true,
    predicate: () => selectIsPermanentLoggedIn(), isOpenToFreeUsers: true
  },]
  : [];
const contactUs: SidebarOption[] = isWeb
  ? [{ id: 'contactus', url: links.contactUs, isOpenToFreeUsers: true, forceAbsoluteness:  process.env.THEME !== 'nasdaq' }] // TODO should be able to recognize absolute urls
  : [];

const myExpertsOption: SidebarOption[] =
  process.env.THEME === 'web'
    ? [{ id: 'experts', },]
    : [];
function investmentToolsPredicate() { return window.matchMedia('(min-width: 1024px) and (min-height: 768px)').matches; };
const investmentTools: SidebarOption[] = isWeb
  ? [
    { id: 'dailyAnalystRatings', forceAbsoluteness: true, url: '/stock-ratings', predicate: investmentToolsPredicate, isOpenToFreeUsers: true },
    { id: 'topInsiderStocks', forceAbsoluteness: true, url: '/insiders/hot-stocks', predicate: investmentToolsPredicate, isOpenToFreeUsers: true },
    { id: 'trendingStocks', forceAbsoluteness: true, url: '/trending-stocks', predicate: investmentToolsPredicate, isOpenToFreeUsers: true },
    { id: 'topAnalystStocks', forceAbsoluteness: true, url: '/stocks/top-rated', predicate: investmentToolsPredicate, isOpenToFreeUsers: true },
  ]
  : [];
const tipranksCommunity: SidebarOption[] = window.location.href.includes('staging.tipranks')
  ? [{
    id: 'community',
    predicate: () =>
      process.env.NODE_ENV !== 'production' ||
      window.location.href.includes('preprod') ||
      window.location.href.includes('staging')
  }] : [];
export const appSideMenuOptions: SidebarOption[] = [
  { id: 'overview' },
  {
    id: 'holdings',
    routes: [
      'holdings',
      'news',
      // 'opinions',
      'calendar',
    ],
  },
  { id: 'analysis' },
  { id: 'insights' },
  { id: 'performance' },
  {
    id: 'public',
    consideredActive: 'onboarding',
    className: 'new',
    predicate: () => process.env.THEME !== 'nasdaq',
    urlFromAuth: (auth) => selectHasMadeAnyPortfolioPublicEver(auth)
      ? `/investors/${selectUserPublicId(auth)!}/${selectUserPublicSlug(auth)!}` // guaranteed to exist if the predicate passes.
      : '/smart-portfolio/onboarding/1',
    disabled: auth => selectHasMadeAnyPortfolioPublicEver(auth) && !selectAnyPortfolioCurrentlyPublic(auth),
    whyDisabled: () => 'You\'ve set your portfolio to "Only Me", go to "My Performance" and set your portfolio to "Public" to access your public portfolio!',
    forceAbsoluteness: true,
  },
  ...investmentTools,
  {
    id: 'screener',
    ...(process.env.THEME !== 'nasdaq' && { url: '/screener/stocks' }),
    forceAbsoluteness: process.env.THEME !== 'nasdaq',
    routes: ['stocks', 'etfs']
    , isOpenToFreeUsers: true
  },
  ...myAccountOption,
  ...contactUs
];

const regularCols = [
  'index',
  'name',
  'price',
  'bestAnalystConsensus',
  'analystConsensus',
  'target',
  'bestTarget',
  'insiderSentiment',
  'highLow',
  'added',
  'shares',
  'value',
  'percent',
  'dividend',
  'yield',
  'dividendDate',
  'earningsReport',
  'eps',
  'sector',
  'cap',
  'beta',
  'hedgefundSentiment',
  'newsSentimentScore',
  'threeMonthsReturn',
  'YTDReturn',
  '1yrReturn',
  'bloggerSentiment',
  'pe',
  'purchasePrice',
  'sincePurchaseGain',
  'addedPrice',
  'gainSinceAdded',
  // 'spaceColumn',
  'alerts',
];
const trCols = [ ...regularCols ];
const nasdaqCols = [ ...regularCols, 'emailAlerts' ];

const regularAlwaysCols =  [
  'index',
  'name',
  // 'added',
  // 'yield',
  // 'spaceColumn',
  'alerts',
];
const trAlwaysCols = [ ...regularAlwaysCols ];
const nasdaqAlwaysCols = [ ...regularAlwaysCols, 'emailAlerts' ];

const regularColOrder = {
  index: -3,
  name: -2,
  // price: -1,
  // spaceColumn: 1,
  alerts: 1,
}

const trColOrder = { ...regularColOrder };
const nasdaqColOrder = { ...regularColOrder, emailAlerts: 2 };

const regularDefaultCols = [
  'index',
  'name',
  'price',
  'shares',
  'value',
  'percent',
  'newsSentimentScore',
  'analystConsensus',
  'target',
  'bestAnalystConsensus',
  'hedgefundSentiment',
  'highLow',
  'yield',
  'alerts',
];

const trDefaultCols = [ ...regularDefaultCols ];
const nasdaqDefaultCols = [ ...regularDefaultCols, 'emailAlerts' ];

// TODO should be converted to enum?
export type RouteId = 'account' |
  'experts' |
  'dailyAnalystRatings' |
  'topInsiderStocks' |
  'trendingStocks' |
  'topAnalystStocks' |
  'overview' |
  'holdings' |
  'analysis' |
  'insights' |
  'performance' |
  'screener' |
  'contactus' |
  'public' |
  'community';

/**
 * Checks whether free users can access this side menu routes.
 */
export const isOpenRoute = (routeId: RouteId) =>
  appSideMenuOptions.find(({ id }) => routeId === id)!.isOpenToFreeUsers;

// Note: there are places that call appSideMenuOptions directly
// TODO move all places to use appSideMenuOptions directly - shouldn't be in the store.
export const dashboard = fromJS({
  appSideMenuOptions,
  filters: {
    expertType: {
      options: ['analyst', 'blogger', 'insider', 'hedgefund'],
    },
  },
});

export const overViewSectionConfig = fromJS({
  stats: ['amount', 'dailyGain', 'cashValue'],
});

export const portfolioNewsConfig = fromJS({
  maxItems: 10,
});

export const portfoliosConfig = fromJS({
  plansLimit: {
    open: 1,
    free: 1,
    premium: 1,
    ultimate: 5,
  },
  portfolioItemsTotalLimit: 200,
});

export const holdingsSectionConfig = fromJS({
  maxTable: portfoliosConfig.get('portfolioItemsTotalLimit') * 10, // max stock per table
  // the only fields shown on mobile
  mobileFields: [
    'name',
    'price',
    // 'spaceColumn',
    'alerts',
  ],
  phabletFields: [
    'name',
    'price',
    // 'spaceColumn',
    'alerts',
  ],
  mobileLFields: [
    'name',
    'price',
    'value',
    // 'spaceColumn',
    'alerts',
  ],
  mobileXLFields: [
    'name',
    'price',
    'value',
    'analystConsensus',
    // 'spaceColumn',
    'alerts',
  ],
  mobileXXLFields: [
    'name',
    'price',
    'value',
    'analystConsensus',
    'bestAnalystConsensus',
    // 'spaceColumn',
    'alerts',
  ],
  // field that cannot be sorted
  disableSort: ['index', 'spaceColumn'],
  // all availeable  fields
  cols: isNasdaq ? nasdaqCols : trCols,
  portfolioItemCols: [
    'bestAnalystConsensus',
    'analystConsensus',
    'target',
    'bestTarget',
    'insiderSentiment',
    'highLow',
    'added',
    'shares',
    'purchasePrice',
    'value',
    'percent',
    'dividend',
    'yield',
    'dividendDate',
    'earningsReport',
    'eps',
    'sector',
    'cap',
    'beta',
    'hedgefundSentiment',
    'bloggerSentiment',
    'pe',
    'sincePurchaseGain',
    'addedPrice',
    'gainSinceAdded',
  ],
  // fields that you cannot remove
  alwaysCols: isNasdaq ? nasdaqAlwaysCols : trAlwaysCols,
  // order of cols, default is 0
  colOrder: isNasdaq ? nasdaqColOrder : trColOrder,
  // fields that will be shown on initial state
  defaultCols: isNasdaq ? nasdaqDefaultCols : trDefaultCols,
  personCols: [],
  // restricted fields
  premium: [
    'bestAnalystConsensus',
    'bloggerSentiment',
    'insiderSentiment',
    'hedgefundSentiment',
    'bestTarget',
    'managementFees',
    'managementFee',
  ],
  // fields that depend upon stock-data api call
  stockData: [
    'analystConsensus',
    'bestAnalystConsensus',
    'target',
    'insiderSentiment',
    'dividend',
    'yield',
    'dividendDate',
    'earningsReport',
    'eps',
    'sector',
    'hedgefundSentiment',
    'bloggerSentiment',
  ],
  // fields that depend upon details api call
  detailsData: [
    'price',
    'target',
    'highLow',
    'value',
    'percent',
    'cap',
    'beta',
  ],
});

export const companyConfig = fromJS({
  supportEmail: 'support@tipranks.com',
});

export const apiCalls = {
  memoizationDuration: 1.8e6, // 30 minutes,
};

export const getResolutionDependentPendingLimit = (mediaQuery: MediaQuery) => {
  if (mediaQuery.get('1366')) return 28;
  else if (mediaQuery.get('1024')) return 20;
  else if (mediaQuery.get('768')) return 16;
  else if (mediaQuery.get('360')) return 18;
  else if (mediaQuery.get('320')) return 18;
  else {
    if (process.env.NOT_PROD)
      console.warn('No value in getResolutionDependentPendingLimit');
    return 18;
  }
};

export const sortWeight = fromJS({
  consensus: ['strongsell', 'sell', 'neutral', 'buy', 'strongbuy'],
  sentiment: [3, 2, 1],
  type: ['stock', 'etf', 'fund'],
});

export const companyConstants = fromJS({
  avgPortfolioId: process.env.THEME === 'nasdaq' ? 82034 : 445,
  bestPortfolioId: process.env.THEME === 'nasdaq' ? 184272 : 184271,
  combinedUserPortfolio: -2,
});

/**
 * Config number to decide how many stocks to show in a (usually percent) list (crowd insights, my portfolio analysis) before joining את אלה שלא עוברים את "מספר החסימה" into the "other" item.
 */
export const stockLimitBeforeJoinToOther = 5;

export const registerRoutePath = 'register';
export const loginRoutePath = 'login';
export const reCaptchaSiteKey = process.env.reCaptchaSiteKey;
export const disablePortfolioImport = false;
export const persistUserStateToServer = false;
