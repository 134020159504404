import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import classNames from 'classnames';

// import { getDetailGroups } from './conf';
import DlWrapper from './dl-wrapper.react';

export default class TrDashboardHoldingsTdInnerExpanded extends Component {
  static propTypes = {
    basicTableType: PropTypes.string,
    getDetailGroups: PropTypes.func,
    item: PropTypes.object,
    mediaQuery: PropTypes.object,
    onAddPortfolioItemButtonClicked: PropTypes.func,
    shouldDisplayAddPortfolioItemButton: PropTypes.boolean
  };

  static defaultPropTypes = {
    shouldDisplayAddPortfolioItemButton: false
  };

  constructor(props) {
    super(props);
    this.state = { done: false };
  }

  onAddPortfolioItemButtonClicked = () => {
    const { onAddPortfolioItemButtonClicked, item } = this.props;
    onAddPortfolioItemButtonClicked(item.get('ticker'));
    this.setState({ done: true });
  };

  render() {
    const {
      basicTableType,
      getDetailGroups,
      mediaQuery,
      onAddPortfolioItemButtonClicked,
      shouldDisplayAddPortfolioItemButton
    } = this.props;

    const groupsToShown = getDetailGroups(mediaQuery).map(group =>
      group.set(
        'details',
        group
          .get('details')
          .map((col: ColType) =>
            col.set(
              'id',
              col.getIn(['alternatives', basicTableType], col.get('id'))
            )
          )
      )
    );

    return (
      <div className="cell-inner td-inner td-inner-expanded">
        <ul>
          {groupsToShown.map((group, index) => (
            <li
              key={index}
              style={{
                width: `${group.get('width') * 100}%`
              }}
              className={classNames({
                withBorder: !!group.get('border')
              })}
            >
              <DlWrapper {...this.props} details={group.get('details')} />
            </li>
          ))}
        </ul>
        <div className="controls">
          {shouldDisplayAddPortfolioItemButton && (
            <button
              onClick={this.onAddPortfolioItemButtonClicked}
              className={classNames({
                added: this.state.done
              })}
            >
              <span>{this.state.done ? 'Added' : 'Add to Portfolio'}</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}
