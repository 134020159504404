import { List, Map } from 'immutable';
import { GET_STOCKS_SUCCESS, GET_STOCKS_START } from './actions';

const actionHandlers = {
  [GET_STOCKS_START]: (state, { page, subtype } = {}) =>
    subtype !== 'stocks'
      ? state
      : {
          ...state,
          loadingTable: page === 1,
          loadingMoreItems: page !== state.page
        },
  [GET_STOCKS_SUCCESS]: (state, { res, fields, page, subtype } = {}) =>
    subtype !== 'stocks'
      ? state
      : {
          ...state,
          fields: fields || Map(),
          tickers:
            fields === state.fields
              ? state.tickers
                  .concat(res.stocks.map(stock => stock.get('ticker')))
                  .toOrderedSet()
                  .toList()
              : res.stocks.map(stock => stock.get('ticker')),
          page: fields === state.fields ? page : 1,
          loadingTable: false,
          loadingMoreItems: false
        }
};

export const initialState = {
  tickers: List(),
  fields: Map(),
  page: 1,
  loadingTable: false,
  loadingMoreItems: false
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
