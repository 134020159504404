import { PeriodicGraphData, Value } from '../periodic-graph/PeriodicGraph';
import { Dashboard, DashboardGainRecord, GainDataRecord, GainDatumRecord, GainDatum } from '../../../common/types';
import { createSelector } from 'reselect';
import { selectSomePerformanceGains } from '../../dashboard/selectors';
import {
  AVG_PORTFOLIO_OPT,
  MY_PORTFOLIO_OPT,
  BEST_PORTFOLIO_OPT,
  SNP_OPT,
} from '../dropdown/PerformanceDropdown';
import { List } from 'immutable';
import { ComponentData } from 'sp/browser/performance/portfolio-performance-box/PortfolioPerformanceBox';

export const selectPortfolioPerformancePerMonth = createSelector(
  (dashboard: Dashboard) => dashboard.get('data').get('gains'),
  (gainsWhyIsThisAList) => {
    const adjustToGainValue = (g: GainDatum) =>
      ({ ...g, gainValue: g.gain === null ? g.gain : g.gain * 100 });

    const dashboardGains = gainsWhyIsThisAList.get(0);
    const avgPortfolioData = dashboardGains.get('averagePortfolioGains').get('monthGains')
      .toJS().map(adjustToGainValue);
    const userHasData = dashboardGains.get('portfolioMonthGains').size;
    const myPortfolioData = userHasData ? dashboardGains.get('portfolioMonthGains').get(0).get('monthGains')
      .toJS().map(adjustToGainValue) : [];
    const bestPortfolioData = dashboardGains.get('bestPortfolioGains').get('monthGains')
      .toJS().map(adjustToGainValue);
    const snpPortfolioData = dashboardGains.get('snpGains').get('monthGains')
      .toJS().map(adjustToGainValue);

    const res: ComponentData = {
      myPortfolioData,
      bestPortfolioData,
      avgPortfolioData,
      snpPortfolioData,
    };

    return res;
  },
);

export type Gain = {
  month: number;
  year: number;
  spy: Value;
  company: Value;
  portfolio: Value;
  bestPortfolioGains: Value;
};
