/**
 * This is here because there's only one component that uses it, which came from website.
 */

import { promote } from '../TargetedWebPage/actions';
import * as React from 'react';
import { connect } from 'react-redux';
import { hasPayed, selectViewerPlan } from '../../common/auth/selectors';
import { report } from './analytics';
import * as classNames from 'classnames';
import * as styles from './Restrictable.istyl';
import { AuthRecord, Plan, PlanEnum } from '../../common/types';

interface RestrictableProps {
  // the plan to restrict the user from and onwards
  plan: PlanEnum;
  // the store auth object
  feature: string; // TODO
  replacement?: <P>() => React.ReactElement<P>;
  type?: string;
  className?: string;
  analytic?: string;

  hasPayed?: boolean;

  actions?: any; // TODO type Actions = typeof all actio;
  auth?: AuthRecord;
}

export function strToPlan(plan: Plan): PlanEnum {
  return {
    open: PlanEnum.Open,
    free: PlanEnum.Free,
    premium: PlanEnum.Premium,
    ultimate: PlanEnum.Ultimate,
  }[plan];
}
export function planToStr(plan: PlanEnum): Plan {
  return {
    [PlanEnum.Open]: 'open',
    [PlanEnum.Free]: 'free',
    [PlanEnum.Premium]: 'premium',
    [PlanEnum.Ultimate]: 'ultimate',
  }[plan] as Plan;
}

@connect(({ auth, actions }) => ({ auth, actions }))
export class Restrictable extends React.PureComponent<RestrictableProps> {
  // TODO perhaps make restrict return true if it restricts and false if it doenst
  // and this wont be necessary? its not good that it assumes the only reason the restrict
  // is payed
  get shouldRestrict() {
    return typeof this.props.hasPayed !== 'undefined'
      ? !this.props.hasPayed
      : !hasPayed(this.props.auth as AuthRecord);
  }

  restrict = (e: React.SyntheticEvent<HTMLElement>) => {
    if (this.shouldRestrict) {
      e.stopPropagation();
      e.preventDefault();

      restrict(this.props.plan, this.props.feature, this.props.auth);
    }
  };

  render() {
    // See https://github.com/Microsoft/TypeScript/issues/28806
    // Requires updating @types/react which in turn requires updating react.
    let Element: any = this.props.type || 'div';
    return (
      <Element
        onClickCapture={this.restrict}
        onChangeCapture={this.restrict}
        onInputCapture={this.restrict}
        className={classNames(this.props.className, {
          [styles.restricted]: this.shouldRestrict,
          restricted: this.shouldRestrict,
          'not-restricted': !this.shouldRestrict,
        })}
      >
        {this.shouldRestrict
          ? this.props.replacement && this.props.replacement()
          : this.props.children}
      </Element>
    );
  }
}

// TODO feature into possibleRestrictions kind of thing
// auth? is a hack to allow the compiler to pass auth from restrictable
// and in restrictable its like that because we pass auth from the connector, if it wouldn't be we would have to pass it via props.
export function restrict(
  planNumber: PlanEnum,
  feature: string,
  auth?: AuthRecord,
) {
  const strCurrentPlan = selectViewerPlan(auth as AuthRecord);
  const currentPlan = strToPlan(strCurrentPlan);
  const plan = planToStr(planNumber);
  if (currentPlan < planNumber) {
    report.setLastPromotionTrigger('clickedRestrictedTrigger', feature);
    report('clickedRestricted');
    promote({ plan, type: feature });
  }
}
