import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics.js';

const execSubmit = async (
  addPortfolio,
  newPortfolioName,
  onSubmit,
  shouldRedirect
) => {
  await addPortfolio(newPortfolioName.value.trim(), shouldRedirect);
  newPortfolioName.value = '';
  onSubmit();
};

export default class AddPortfolioForm extends Component {
  static propTypes = {
    addPortfolio: PropTypes.func,
    done: PropTypes.string,
    isLimited: PropTypes.bool,
    isUltimate: PropTypes.bool,
    label: PropTypes.string,
    onSubmit: PropTypes.func,
    placeHolder: PropTypes.string,
    shouldRedirect: PropTypes.bool,
    showPromotion: PropTypes.func,
    state: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { isLimited: false };
  }

  formSubmitted = event => {
    event.preventDefault();

    const {
      addPortfolio,
      isLimited,
      onSubmit = () => null,
      showPromotion,
      shouldRedirect = false
    } = this.props;

    const { newPortfolioName } = this.refs;

    if (newPortfolioName.value.length === 0) {
      return;
    }

    if (isLimited) {
      report.setLastPromotionTrigger('addPortfolio');
      report('clickedAddPortfolio');
      report('clickedLockedAddPortfolio');
      this.setState({ isLimited: true });
      showPromotion();
    } else {
      execSubmit(addPortfolio, newPortfolioName, onSubmit, shouldRedirect);
    }
  };

  render() {
    const {
      done = '+',
      label,
      isUltimate,
      placeHolder,
      state = 'SUCCESS'
    } = this.props;

    const { isLimited } = this.state;

    const addLoading = state === 'LOADING';

    return (
      <form onSubmit={this.formSubmitted}>
        <div className="search-wrapper">
          <label>
            {label ? <div className="label">{label}</div> : null}
            <div
              className={classNames('input-wrapper', {
                loading: false /* addLoading wierd loader idk lol */
              })}
            >
              <input
                autoFocus={true}
                disabled={addLoading}
                placeholder={placeHolder}
                ref="newPortfolioName"
                type="text"
              />
            </div>
          </label>
          <button>{done}</button>
        </div>
        {isUltimate &&
          isLimited && (
            <div style={{ color: 'red', fontSize: '12px', marginTop: '2px' }}>
              You have reached your portfolio limit, if you would like to create
              more portfolios please contact &nbsp;
              <a href="mailto:support@tipranks.com">support@tipranks.com</a>
            </div>
          )}
      </form>
    );
  }
}
