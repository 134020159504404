import * as React from 'react';
import './search-header.styl';

import getImage from './images';

export default ({ goBack, title }) => {
  return (
    <div className="wrapper tr-search-header">
      <button className="arrowWrapper" onClick={goBack}>
        {getImage('arrow', 'svg', {
          dim: [20, 20]
        })}
      </button>
      <span className="title">{title}</span>
    </div>
  );
};

export const __hotReload = true;
