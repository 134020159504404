import reporter from '../analytics';

const events = {
  saw: 'saw-add-stock-screen',
  added: 'added-stocks-to-portfolio',
  sawRegister: 'saw-delayed-register-dialog'
};

const report = reporter(events);

export default report;
