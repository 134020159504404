import { SentimentEnum } from './types';
import * as React from 'react';
import * as classNames from 'classnames';
import * as innerStyles from './VisualConsensus.istyl';
import * as _ from 'lodash';
import { percentField } from '../lib/utils';
import { Icon } from 'tipranks-icons';

export interface ConsensusSentiment {
  actualSentiment: SentimentEnum;
  majoritySentiment: SentimentEnum;
  percent: number | null; // percent
  avg: number; // percent as well
}
interface Props {
  sentiment: ConsensusSentiment;
  styles: Partial<typeof innerStyles>;
  avgMarkerText?: React.ReactNode;
}
export interface NumberSentiment {
  min: number; // not percent
  max: number; // not percent
  value: number; // percent
  avg: number; // percent
  sentiment: SentimentEnum;
}
// we use a differnet props from Props, because we don't
// want ConsensusSentiment -> our graph only has one direction.
interface NumberProps {
  sentiment: NumberSentiment;
  ticker: string;
  // TODO make Partial<typeof innerStyles> work
  styles: Partial<typeof innerStyles>;
}

type BullishBearishProps = Props & {
  consMarkerTextEle: React.ReactNode;
};
type GeneralProps = BullishBearishProps & {
  leftMarkerEle: React.ReactNode;
  rightMarkerEle: React.ReactNode;
  // who's the idiot that made a prop instead of css for this?
  // clue, its me
  showSmallMarker?: boolean;
};

const getCssObject = (
  actualSentiment: SentimentEnum,
  majority: SentimentEnum,
  styles: any
) => {
  const isBullish = actualSentiment === SentimentEnum.Bullish;
  const isNeutral = actualSentiment === SentimentEnum.Neutral;
  return {
    // TODO wtf why are the innerStyles empty
    [innerStyles.isNeutral]: isNeutral,
    [innerStyles.isBullish]: isBullish,
    [innerStyles.isBearish]: !isBullish,
    [styles.isNeutral]: isNeutral,
    [styles.isBullish]: isBullish,
    [styles.isBearish]: !isBullish
  };
};

const getSentimentText = (sentiment: SentimentEnum) => {
  return _.upperFirst(SentimentEnum[sentiment]);
};

export class BullishBearishConsensusBar extends React.PureComponent<
  BullishBearishProps,
  never
  > {
  render() {
    const { styles: outerStyles } = this.props;
    const bear = (
      <Icon
        icon="bearish"
        className={classNames(
          innerStyles.bearishColor,
          innerStyles.bearPic,
          outerStyles.bearPic
        )}
      />
    );
    const bull = (
      <Icon
        icon="bullish"
        className={classNames(
          innerStyles.bullishColor,
          innerStyles.bullPic,
          outerStyles.bullPic
        )}
      />
    );

    return (
      <GeneralVisualConsensus
        leftMarkerEle={bear}
        rightMarkerEle={bull}
        {...this.props}
      />
    );
  }
}

/**
 * NumberConsensusBar is the MediaBuzz graph in /holdings/news/analysis.
 * TODO it's weird, when passed sentiment.sentiment == SentimentEnum.bullish, but when passed neutral, it renders it differently. That's why I opted to calculate what should be the color of the marker in-component, but should it be like that? i dont think so.
 */
export class NumberConsensusBar extends React.PureComponent<
  NumberProps,
  never
  > {
  render() {
    const { styles, sentiment, ticker: rawTicker } = this.props;
    const ticker = rawTicker.toUpperCase();
    const avgMarkerText = (
      <span>
        {ticker}
        <br />Weekly Average
      </span>
    );
    const min = (
      <span
        className={classNames(
          innerStyles.numberText,
          innerStyles.numberLeft,
          innerStyles.neutral
        )}
      >
        {sentiment.min}
      </span>
    );
    const isBullish = sentiment.value > 0.4;
    const isInBetween = sentiment.value <= 0.4;
    const textColor = classNames({
      [innerStyles.bullishColor]: isBullish,
      [innerStyles.neutralColor]: isInBetween
    });
    const graphColor = classNames({
      [innerStyles.bearishConsensusBar]:
        sentiment.sentiment === SentimentEnum.Bearish,
      [innerStyles.bullishConsensusBar]:
        sentiment.sentiment === SentimentEnum.Bullish
    });
    const consMarkerTextEle = (
      <span className={classNames(innerStyles.mediaBuzzMarker, textColor)}>
        {ticker} This<br />Week
      </span>
    );
    const max = (
      <span
        className={classNames(
          innerStyles.numberText,
          innerStyles.numberRight,
          textColor
        )}
      >
        {sentiment.max}
      </span>
    );

    return (
      <div className={graphColor}>
        <GeneralVisualConsensus
          consMarkerTextEle={consMarkerTextEle}
          leftMarkerEle={min}
          rightMarkerEle={max}
          sentiment={{
            actualSentiment: sentiment.sentiment,
            majoritySentiment: sentiment.sentiment,
            percent: sentiment.value,
            avg: sentiment.avg
          }}
          avgMarkerText={avgMarkerText}
          styles={styles}
        />
      </div>
    );
  }
}

export class GeneralVisualConsensus extends React.PureComponent<
  GeneralProps,
  never
  > {
  render() {
    const {
      styles: outerStyles,
      sentiment: { actualSentiment, percent, avg },
      consMarkerTextEle,
      leftMarkerEle,
      rightMarkerEle,
      avgMarkerText,
      showSmallMarker = false
    } = this.props;
    const avgMarkerWidth = 2;
    const computedPercent =
      percent === null ? -1 : percent < 0.1 ? 0.1 : percent;
    const rawMarkerPosition =
      actualSentiment === SentimentEnum.Neutral
        ? 0.5
        : actualSentiment === SentimentEnum.Bullish
          ? computedPercent
          : 1 - computedPercent;
    const markerPosition = rawMarkerPosition === 1 ? 0.99 : rawMarkerPosition;
    const avgPosition = avg * 100;
    const shouldShowAvgMarker = !!avg;
    return (
      <div
        className={classNames(
          innerStyles.visualConsensus,
          outerStyles.visualConsensus
        )}
      >
        {leftMarkerEle}
        <div
          className={classNames(
            innerStyles.actualConsensus,
            outerStyles.actualConsensus
          )}
        >
          <div
            className={classNames(
              innerStyles.opinion,
              innerStyles.veryBearish,
              outerStyles.opinion,
              outerStyles.veryBearish
            )}
          />
          <div
            className={classNames(
              innerStyles.opinion,
              innerStyles.bearish,
              outerStyles.opinion,
              outerStyles.bearish
            )}
          />
          <div
            className={classNames(
              innerStyles.opinion,
              innerStyles.neutral,
              outerStyles.opinion,
              outerStyles.neutral
            )}
          />
          <div
            className={classNames(
              innerStyles.opinion,
              innerStyles.bullish,
              outerStyles.opinion,
              outerStyles.bullish
            )}
          />
          <div
            className={classNames(
              innerStyles.opinion,
              innerStyles.veryBullish,
              outerStyles.opinion,
              outerStyles.veryBullish
            )}
          />
          <div
            className={classNames(
              innerStyles.consMarker,
              outerStyles.consMarker
            )}
            style={{ left: `calc(${markerPosition * 100}%)` }}
          >
            <div
              className={classNames(
                innerStyles.consLabel,
                outerStyles.consLabel
              )}
            >
              <div
                className={classNames(
                  {
                    [innerStyles.bearishStickingOutOfBoxFix]:
                      percent !== null && markerPosition < 0.04,
                    [innerStyles.bullishStickingOutOfBoxFix]:
                      percent !== null && markerPosition >= 0.95
                  },
                  innerStyles.consLabelText,
                  outerStyles.consLabelText
                )}
              >
                {consMarkerTextEle}
              </div>
              <div>
                <Icon icon="downwardsPointingTriangularArrow" />
              </div>
            </div>
          </div>
          {shouldShowAvgMarker ? (
            <div
              className={classNames(
                innerStyles.avgMarker,
                outerStyles.avgMarker
              )}
              style={{ left: `calc(${avgPosition}% - ${avgMarkerWidth}px)` }}
            >
              <div
                className={classNames(
                  innerStyles.avgMarkerText,
                  outerStyles.avgMarkerText
                )}
              >
                {avgMarkerText}
              </div>
            </div>
          ) : (
              ''
            )}
        </div>
        {rightMarkerEle}
      </div>
    );
  }
}

export const TextVisualConsensus = (props: Props) => {
  const {
    styles: outerStyles,
    sentiment: { actualSentiment, majoritySentiment, percent, avg }
  } = props;
  const cssObject = getCssObject(
    actualSentiment,
    majoritySentiment,
    outerStyles
  );
  const consMarkerTextEle = (
    <div>
      {getSentimentText(actualSentiment)}:{' '}
      <span className={classNames(cssObject)}>{percentField(percent, 0)}</span>
    </div>
  );
  return (
    <BullishBearishConsensusBar
      consMarkerTextEle={consMarkerTextEle}
      {...props}
    />
  );
};

// TODO stop using VisualConsensus name, and use bloggers instead
// export const BloggerVisualConsensus = VisualConsensus;
export const VisualConsensus = (props: Props) => {
  return (
    <TextVisualConsensus
      avgMarkerText={
        <span>
          Sector<br />Average
        </span>
      }
      {...props}
    />
  );
};
