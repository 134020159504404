import Clickable from 'tipranks-clickable';
import * as React from 'react';
import * as classNames from 'classnames';
import { svgContent } from './svg-collection';

import './menu-items.styl';
import { buildEvents } from '@tipranks/checkout-link';
import { ExtraEvents } from '../tr-dashboard-header/links';
import { getSpCurrentPageName } from '../tr-payment/index';

interface ClickableProps extends React.HTMLProps<HTMLElement> {
  href?: string;
  text?: string;
  to?: string;
  activeClassName?: string;
  type?: 'to' | 'href' | 'onClick' | 'div';
}
interface Item extends ClickableProps {
  id: string;
  tag: string;
  items?: Item[];
  text: string;
  predicate?: (item?: any) => boolean;
  onMarketingFlowStartEvents?: (pageName: string, events?: ExtraEvents) =>
    ReturnType<typeof buildEvents>;
}
interface MenuItemProps {
  items?: Item[];
  level?: number;
  pageName?: string;
}
export default class MenuItems extends React.PureComponent<
  MenuItemProps,
  { openItem: string | null }
  > {
  public state = { openItem: null }

  isActive(id: string) {
    return id === 'smart-portfolio';
  }

  renderItem = ({ href, to, id, text, items, target, onMarketingFlowStartEvents }: Item, level: number) => {
    if (level === 1 && items) {
      return (
        <label className="checkbox-wrapper">
          <input onClick={this.clickedItem(level, id)} type="checkbox" />
          {text}
        </label>
      );
    }
    return (
      <Clickable 
        className="link"
        href={href}
        target={target}
        to={to}
        {
          ...typeof onMarketingFlowStartEvents === 'function'
          ? onMarketingFlowStartEvents(this.props.pageName || '') : {}
        }
      >
        <div className="svg-wrapper">{svgContent(id, id)}</div>
        <span>{text}</span>
      </Clickable>
    );
  }

  clickedItem = (level, id) => {
    if (level === 1) {
      return () => {
        this.setState({ openItem: this.state.openItem === id ? null : id });
      };
    }
  };

  render() {
    const { items, level = 0 } = this.props;
    const { openItem } = this.state;

    if (!items || !items.length) return null;
    const pageName = getSpCurrentPageName(location.pathname); // Due to recursion, calling `getSpCurrentPageName` is crucial. Else `pageName` will eventually overridden with `undefined`.
    return (
      <div
        className={`header-items header-items-level-${
          level
          } header-items-size-${items.length}`}
      >
        <ul>
          {items
            .filter(({ predicate }) => (predicate ? predicate() : true))
            .map(({ id, text, href, to, items: innerItems, tag }, i, arr) => {
              const hasItems = innerItems && innerItems.length > 0;
              return (
                <li
                  className={classNames(id, { 'is-active': this.isActive(id) })}
                  key={id}
                >
                  <div
                    className={classNames(
                      'header-item-text',
                      tag ? `tag tag-${tag}` : '',
                      {
                        hasItems,
                        isOpen: openItem === id
                      }
                    )}
                  >
                    {this.renderItem(arr[i], level)}
                  </div>
                  {hasItems && (
                    <MenuItems items={innerItems} pageName={pageName} level={level + 1} />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export const __hotReload = true;
