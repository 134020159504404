import '../styles/portfolioallocation.styl';

import * as React from 'react';
import Component from 'react-pure-render/component';
import Connector from './connector.react';
import AssetAllocationGraph from './assetallocationgraph.react.tsx';
import MenuOptions from './menuoptions.react';
import { betterToFixed } from 'sp/common/lib/utils';
import { format } from './utils.js';

export default class PortfolioAllocation extends Component {
  // static propTypes = {
  //   asset: instanceOf(immutable.Map),
  //   total: instanceOf(immutable.List),
  //   colors: instanceOf(immutable.Map),
  //   msg: any,
  //   onChange: func,
  //   state: string,
  //   style: object
  // };

  constructor(props) {
    super(props);
    this.colors = this.props.colors;
  }

  activateAssetAllocationOption = i => {
    this.props.onChange && this.props.onChange(i);
  };

  sortByCount(asset1, asset2) {
    return asset1.get('count') < asset2.get('count');
  }

  renderLegendItem(datum, i, arr, isTotal, isPercent) {
    const { state: activeAssetAllocationOption, msg } = this.props;
    const styleList = {
      background: isTotal
        ? this.colors
          .get('total')
          .filter(x => x.get(0) === i)
          .first()
          .get(1)
        : this.colors.getIn([activeAssetAllocationOption, i])
    };

    // really quick solution to situation where rounded numbers sums up to more the 100%.
    // as suggested by Benji
    const makeSureNotNegative = x => (x < 0.2 ? 0 : x);

    const total = arr.reduce((total, x) => total + x.get('count'), 0);
    const toPercent = absolute => absolute / total;
    const isLast = i === 'cash'; // wtf why i (index) is the id? // wtf who said i (short for identity) is the index?
    const totalPercentButLast = arr.reduce(
      (total, item, index, arr) =>
        total +
        (index === arr.size - 1
          ? 0
          : Math.round(item.get('percent') * 1000) / 10),
      0
    );
    const lastValue = `${makeSureNotNegative(100 - totalPercentButLast).toFixed(
      1
    )}%`;

    const amount = isPercent
      ? betterToFixed(datum.get('percent') * 100) + '%'
      : format.number(datum.get('count'));

    const assetType = datum.get('id');
    // TODO this should take from a global "assettype" thingy
    let idText = format.capitalize(assetType ? msg[assetType] || assetType : msg.default);
    // HACK
    idText = idText === 'Unknownsector' ? 'Other' : idText;

    const line = isPercent ? [idText, ': ', amount] : [amount, ' ', idText];

    return (
      <li key={i} title={idText}>
        <div className="beforeLegendItem" style={styleList} />
        <span>{line}</span>
      </li>
    );
  }

  renderLegend(assetArr, isTotal) {
    return (
      <ul className="graphLegend">
        {assetArr.map((datum, i, arr) =>
          this.renderLegendItem(
            datum,
            isTotal ? arr.getIn([i, 'id']) : i,
            arr,
            isTotal,
            isTotal
          )
        )}
      </ul>
    );
  }

  render(
    {
      asset,
      state: activeAssetAllocationOption,
      total: totalAssets,
      style,
      msg
    } = this.props
  ) {
    const totalColorMap = this.colors
      .get('total')
      .toKeyedSeq()
      .mapEntries(([_, x]) => x);
    const totalColors = totalAssets.map(x => totalColorMap.get(x.get('id')));
    const deepColors = this.colors.get(activeAssetAllocationOption);
    const currentColor = this.colors
      .get('total')
      .filter(x => x.get(0) === activeAssetAllocationOption)
      .first()
      .get(1);
    const totalAssetCount = totalAssets.reduce(
      (total, item) => total + item.get('count'),
      0
    );
    const deepAsset = asset.find((d, k) => k === activeAssetAllocationOption);
    const deepAssetPercentage = totalAssets
      .find((d, k) => d.get('id') === activeAssetAllocationOption)
      .get('count');
    const assetIds = totalAssets.map(a => a.get('id'));

    let innerAssetText =
      msg[activeAssetAllocationOption] || activeAssetAllocationOption;
    if (deepAssetPercentage == 1) {
      innerAssetText = innerAssetText.slice(0, -1);
    }

    // const delimiter = (i, size) => {
    //   if (i === size)
    //     return '.';
    //   if (i === (size - 1))
    //     return ' & ';
    //   return ', ';
    // };
    // const insiderGraph = totalAsset.entrySeq()
    //   .reduce((str, key, i, arr) =>
    //     str + (msg[key[1].get('id')] || key[1].get('id')) + delimiter(i, arr.size - 1),
    //   '');
    const insiderGraph = msg.allAss;
    return (
      <div>
        <section style={style} className="portfolio-allocation">
          <header>
            <MenuOptions
              activeOption={activeAssetAllocationOption}
              onChange={this.activateAssetAllocationOption}
              options={assetIds}
              msg={msg}
            />
          </header>
          <div className="content">
            <div className="content-inner">
              <div className="graph-wrapper total-graph-wrapper">
                <div className="connector">
                  <Connector color={currentColor} />
                </div>
                <div className="center-div">
                  {/*<div className="count">
                      {format.number(totalAssetCount)}
                    </div>*/}
                  <div className="list">{insiderGraph}</div>
                </div>
                <AssetAllocationGraph
                  activeDatum={activeAssetAllocationOption}
                  asset={totalAssets}
                  animated={true}
                  thickness={0.42}
                  colors={totalColors}
                  onSliceClicked={this.activateAssetAllocationOption}
                  type="total"
                />
                {this.renderLegend(totalAssets, true)}
              </div>
              <div className="graph-wrapper deep-graph-wrapper">
                <div className="center-div">
                  <div className="count">
                    {format.number(deepAssetPercentage)}
                  </div>
                  <div className="list">{innerAssetText}</div>
                </div>
                <AssetAllocationGraph
                  asset={deepAsset}
                  thickness={0.4}
                  colors={deepColors}
                  type="deep"
                />
                {!deepAssetPercentage ? null : this.renderLegend(deepAsset)}
              </div>
            </div>
            <div className="link-like-button-wrapper" />
          </div>
        </section>
      </div>
    );
  }
}

export const __hotReload = true;
