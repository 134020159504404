import { StockType } from '../../common/types';
import * as React from 'react';
import { getTickerUrl } from '../../common/lib/utils';
import Clickable from '../Clickable/clickable.react.js';
import { PerfStock } from 'sp/common/components/performance/records';
import { isNumber } from 'lodash';

interface Props {
  stock?: PerfStock;
  type?: number | StockType;
  ticker?: string;
  target?: string; // HTMLAnchorElement.target is string too! (:OOO)
  className?: string;
  isActive?: boolean;
}
/** 
 * Can handle either a stock, or a a type with ticker.
*/
export class StockLink extends React.PureComponent<Props> {
  public render() {
    const { stock, target = '', className = '', isActive = true } = this.props;
    let ticker = this.props.ticker || stock && stock.get('ticker').toUpperCase() || '';
    let type: StockType | '' = this.props.type as any || '';

    if (type) {
      type = isNumber(type) ? stockTypeIdToStockType(type) || '' : type;
    } else if (stock) {
      type = extractType(stock);
    }

    const href = getTickerUrl({ ticker, type });
    return href && isActive ? (
      <Clickable href={href} target={target} className={className}>
        {ticker}
      </Clickable>
    ) : (
        <span className={className}>{ticker}</span>
      );
  }
}

function extractType(stock: PerfStock): StockType | '' {
  const type = stock.get('type');
  if (type) {
    return type;
  }
  const typeId = stock.get('stockTypeId');
  const typeFromId = stockTypeIdToStockType(stock.get('stockTypeId'));
  if (typeId && typeFromId) return typeFromId;
  return '';
}

function stockTypeIdToStockType(stockTypeId: number): StockType | null {
  switch (stockTypeId) {
    case 1:
      return 'stock'; // Stock;
    case 2:
      return null; // Index;
    case 3:
      return null; // Foreign;
    case 4:
      return null; // Inactive;
    case 5:
      return 'etf'; // Etf;
    case 6:
      return 'fund'; // Fund;
    case 7:
      return null; // Bond;
    case 8:
      return 'secondaryticker'; // SecondaryTicker;
    case 9:
      return 'stock'; // CanadianStock;
    case 10:
      return 'etf'; // CanadianETF;
    case 11:
      return 'fund'; // CanadianFund;
    case 12:
      return 'stock'; // NonUSStock;
    case 13:
      return 'etf'; // NonUSETF;
    case 14:
      return 'fund'; // NonUSFund;
    case 15:
      return null; // Commodity;
    case 16:
      return null; // Cryptocurrency;
    default: {
      if (process.env.IS_RUN_LOCAL)
        console.warn('stockTypeIdToStockType error');
      return null;
    }
  }
}

export default StockLink;
