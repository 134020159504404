import { fromJS } from 'immutable';
import * as actions from './actions';
// import * as userActions from '../../api';
import { InputField, InitialState, componentState } from './records';
import { suits } from '../../enums';
import { mapValues } from 'lodash';
import { adapt as adaptFields } from './api';
import { API_GetExtraPerformance_Response } from 'sp/common/api-types';
import { REDUX_STORAGE_LOAD } from 'sp/common/auth/actions';
export const initialState = InitialState();

const revive = state => initialState.merge(state);
const formFields = ['age', 'income', 'profession', 'term', 'tolerance'];
const mapResponseFields = fields =>
  mapValues(fields, (value, key) =>
    InputField({
      value,
      type: key,
      state: value ? suits.SUCCESS : suits.INITIAL,
    }),
  );

const areAllFieldsFilled = fields => formFields.every(name => !!fields[name]);

export function reducer(state: typeof InitialState | any = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case REDUX_STORAGE_LOAD: {
      const restoredState = payload.performance;
      if (!restoredState) return state;
      return state
        .set('portfolioManager', restoredState.portfolioManager)
        .set('bestTrade', restoredState.bestTrade)
        .set('creationDate', restoredState.creationDate)
        .set('everHadHolding', restoredState.everHadHolding)
        .set('holdingOperations', restoredState.holdingOperations)
        .set('name', restoredState.name)
        .set('stockPicker', restoredState.stockPicker)
        .set('dataState', restoredState.dataState);
    }
    case actions.GET_FIELDS_START: {
      const fields = state.get('fields');
      fields.set('age', suits.LOADING);
      fields.set('income', suits.LOADING);
      fields.set('profession', suits.LOADING);
      fields.set('term', suits.LOADING);
      fields.set('tolerance', suits.LOADING);
      return state;
    }
    case actions.GET_FIELDS_ERROR: {
      const fields = state.get('fields');
      fields.set('age', suits.ERROR);
      fields.set('income', suits.ERROR);
      fields.set('profession', suits.ERROR);
      fields.set('term', suits.ERROR);
      fields.set('tolerance', suits.ERROR);
      return state;
    }
    case actions.GET_FIELDS_SUCCESS: {
      const adaptedPayload = adaptFields(payload);
      const allFieldsFilled = areAllFieldsFilled(adaptedPayload);
      const fields = mapResponseFields(adaptedPayload);
      const toMerge = { fields, state: suits.INITIAL };

      if (allFieldsFilled) {
        toMerge.state = suits.SUCCESS;
      }

      const endResult = state
        .mergeIn(['form'], toMerge)
        .mergeIn(['fields'], toMerge.fields);

      return endResult;
    }

    case actions.UPDATE_FIELD: {
      return state.mergeIn(['form', 'fields', payload.type], {
        value: payload.value,
      });
    }

    case actions.UPDATE_FIELD_SERVER_START: {
      const content = payload.map(x => x.set('state', suits.LOADING));
      return state
        .mergeIn(['form', 'fields'], content)
        .setIn(['form', 'state'], suits.LOADING);
    }

    case actions.RESET_MY_DETAILS: {
      return state.setIn(['form', 'state'], suits.INITIAL);
    }

    case actions.UPDATE_FIELD_SERVER_SUCCESS: {
      return state
        .setIn(['form', 'fields'], payload)
        .set('fields', payload)
        .setIn(['form', 'state'], suits.SUCCESS);
    }

    case actions.UPDATE_FIELD_SERVER_ERROR: {
      return state
        .mergeIn(['form', 'fields'], payload.data)
        .setIn(['form', 'state'], suits.ERROR);
    }

    // case actions.RESET_FORM: {
    //   return initialState;
    // }

    case actions.GET_EXTRA_PERFORMANCE_DATA_START: {
      return state.set('dataState', componentState.loading);
    }

    case actions.GET_EXTRA_PERFORMANCE_DATA_SUCCESS: {
      const {
        bestTrade,
        creationDate,
        everHadHolding,
        holdingOperations,
        name,
        portfolioManager,
        stockPicker,
      } = payload as API_GetExtraPerformance_Response;

      return [
        { prop: 'bestTrade', value: bestTrade },
        { prop: 'creationDate', value: creationDate },
        { prop: 'everHadHolding', value: everHadHolding },
        { prop: 'holdingOperations', value: holdingOperations },
        { prop: 'name', value: name },
        { prop: 'portfolioManager', value: portfolioManager },
        { prop: 'stockPicker', value: stockPicker },
      ].reduce((state, datum) => {
        return state.set(datum.prop, datum.value)
      }, state)
        .set('dataState', componentState.success);
    }

    case actions.GET_EXTRA_PERFORMANCE_DATA_ERROR: {
      return state.set('dataState', componentState.error);
    }

    case actions.FETCH_TRANSACTIONS_PRICES_SUCCESS: {
      return state.set('details', payload);
    }
    // case actions.POST_FORM_START: {
    //   return state.set('state', suits.LOADING);
    // }
    //
    // case actions.POST_FORM_SUCCESS: {
    //   return state.set('state', suits.SUCCESS);
    // }
    //
    // case actions.POST_FORM_ERROR: {
    //   return state.merge({
    //     state: suits.ERROR,
    //     error: payload
    //   });
    // }
  }

  return state;
}
