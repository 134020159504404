import { fromJS } from 'immutable';
import { getReq } from '../../common/api';

const apiGetNewsSentiments = ticker =>
  getReq(`api/stocks/getNewsSentiments/?ticker=${ticker}`);

export const GET_NEWS_SENTIMENTS = 'GET_NEWS_SENTIMENTS';
export const GET_NEWS_SENTIMENTS_SUCCESS = 'GET_NEWS_SENTIMENTS_SUCCESS';
export const GET_NEWS_SENTIMENTS_ERROR = 'GET_NEWS_SENTIMENTS_ERROR';
export const GET_NEWS_SENTIMENTS_START = 'GET_NEWS_SENTIMENTS_START';
export const getNewsSentiments = ticker => () => ({
  type: GET_NEWS_SENTIMENTS,
  payload: { promise: apiGetNewsSentiments(ticker) }
});

const apiGetPrices = ticker =>
  getReq(`api/stocks/getHistoricalPrice/?name=${ticker}&daysBack=95`);

export const GET_PRICES = 'GET_PRICES';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR';
export const GET_PRICES_START = 'GET_PRICES_START';
export const getPrices = ticker => () => ({
  type: GET_PRICES,
  ticker,
  payload: { promise: apiGetPrices(ticker) }
});
