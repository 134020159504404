export const ON_SIDE_MENU_CHANGE = 'ON_SIDE_MENU_CHANGE';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const TOGGLE_PHOTO_UPLOAD_STATE = 'TOGGLE_PHOTO_UPLOAD_STATE';
export const SET_UI_PROPERTY = 'SET_UI_PROPERTY';

export function onSideMenuChange(isOpen) {
  return {
    type: ON_SIDE_MENU_CHANGE,
    payload: { isOpen }
  };
}

export function toggleSideMenu() {
  return {
    type: TOGGLE_SIDE_MENU
  };
}

export function setUiProperty(key, value) {
  return {
    type: SET_UI_PROPERTY,
    payload: { key, value }
  };
}

export function togglePhotoUploadState(payload) {
  return {
    type: TOGGLE_PHOTO_UPLOAD_STATE,
    payload: { data: payload }
  };
}
