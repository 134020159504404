import { AssetBox } from './AssetBox.react.tsx';
import React from 'react';
import PropTypes from 'prop-types';
import BarSlider from './BarSlider.react';
import Spacer from './Spacer.react';
import RiskContainer from './RiskContainer.react';
import { peGlossary } from '../../../../decisions/index.ts';

export default class PortfolioPE extends AssetBox('asset-box-portfolio-pe ', [
  'details',
  'portfolioStats'
]) {
  static propTypes = {
    pe: PropTypes.number,
    avgPe: PropTypes.number
  };

  head() {
    return (
      <span className="section-header-alt-1-h1">
        <h3>Portfolio P/E</h3>
        {this.headerTooltip(
          "View your portfolio's price-to-earnings ratio, or how much a company is worth in relation to its share price."
        )}
        {this.headerSideLink('Why P/E is important >', peGlossary)}
      </span>
    );
  }
  body() {
    return (
      <div>
        <RiskContainer
          isOrange={true}
          avg={this.props.avgPe}
          value={this.props.pe}
        />
      </div>
    );
  }
}
