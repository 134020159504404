import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { hasPayed } from '../../common/auth/selectors.ts';
import { setLastLockedFeature } from '../../common/lib/utils.ts';
import { ApiDecorator } from '../ApiDecorator/ApiDecorator.react';
import TrDashboardMgmtFeesRoute from '../tr-dashboard-mgmt-fees-route';

import report from './analytics';
import { promote } from '../TargetedWebPage/actions';

import {
  selectDoAllMgmtFeesHaveShares,
  selectIsMgmtFeesLoading,
  selectMgmtFeesSaveAmount,
  selectMgmtFeesSavePercent,
  selectMostSaveableMgmtFees,
} from '../dashboard/selectors';

@ApiDecorator('mgmtFees')
export default class TrDashboardMgmtFeesRouteContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    mediaQuery: PropTypes.object,
  };

  componentDidMount() {
    report('mounted');
  }

  onRestrictedClick = () => {
    const { actions } = this.props;
    setLastLockedFeature('sp-management-fees');
    report('restrictedClick');
    promote({ plan: 'premium', type: 'mgmtFees' });
  };

  onCompareClicked = mgmtFee => {
    const { actions, auth } = this.props;

    report('comparedClicked');
    const isRestricted = !hasPayed(auth);

    if (isRestricted) {
      this.onRestrictedClick();
    } else {
      actions.openDialog('mgmtFeesComparison', { mgmtFee });
    }
  };

  render() {
    const { auth, dashboard, mediaQuery } = this.props;

    const isRestricted = !hasPayed(auth);
    const isLoading = selectIsMgmtFeesLoading(dashboard);

    return (
      <TrDashboardMgmtFeesRoute
        doAllMgmtFeesHaveShares={selectDoAllMgmtFeesHaveShares(dashboard)}
        isFundBoxMoreDetailed={mediaQuery.get('tablet')}
        isHowWeFindSimilarFundsShown={mediaQuery.get('tablet')}
        isLoading={isLoading}
        isRestricted={isRestricted}
        isRestrictedBoxMoreDetailed={mediaQuery.get('tablet')}
        mgmtFees={selectMostSaveableMgmtFees(dashboard)}
        onCompareClicked={this.onCompareClicked}
        onRestrictedClick={this.onRestrictedClick}
        saveAmount={selectMgmtFeesSaveAmount(dashboard)}
        savePercent={selectMgmtFeesSavePercent(dashboard)}
      />
    );
  }
}
