import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import defaultMsg from './en';
import report from './analytics';

export class WelcomeToast extends Component {
  static propTypes = {
    isToastShown: PropTypes.bool,
    msg: PropTypes.object,
    onRegister: PropTypes.func
  };

  static defaultProps = {
    msg: defaultMsg
  };

  constructor(...args) {
    super(...args);
    this.state = { isShown: true };
  }

  onClose = () => {
    this.setState({ isShown: false });
  };

  onRegister = () => {
    const { onRegister } = this.props;
    report('clicked');
    onRegister();
  };

  render() {
    const { isToastShown, msg } = this.props;
    const { isShown } = this.state;

    return (
      <div
        className={classNames('tr-dashboard-welcome-toast', {
          isShown,
          isToastShown
        })}
      >
        <img alt="" className="woman" src={require('./img/woman.png')} />
        <button className="btn-close" onClick={this.onClose}>
          +
        </button>
        <div className="title">{msg.title}</div>
        <div className="desc">{msg.desc}</div>
        <button className="btn-register" onClick={this.onRegister}>
          {msg.btnRegister}
        </button>
      </div>
    );
  }
}

export default WelcomeToast;
