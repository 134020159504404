import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import Component from 'react-pure-render/component';

import report from './analytics';
import { FormattedMessage } from 'react-intl';
import UserWarnings from '../tr-user-warnings/';
import { selectActivePortfolio } from '../dashboard/selectors';

import { identifyUser } from '../../common/api';
import { syncSmartPortfolioWithPlaid } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { history } from 'sp/browser/history';

const selectWarningMessages = createSelector(
  ({ portfolio }) => portfolio.get('modes'),
  ({ msg }) => msg,
  ({ actions }) => actions,
  (modes, msg, actions) =>
    modes.map(mode => (
      <FormattedMessage
        defaultMessage={msg.warnings[mode]}
        id={msg.warnings[mode]}
        values={{
          importLink: (
            <button
              onClick={() => {
                actions.import();
                report('clickedImportPortfolio', 'warning-bar');
              }}
            >
              {msg.values.importLink}
            </button>
          ),
          or: <span className="accent1">{msg.values.or}</span>
        }}
      />
    ))
);

export default class UserWarningsContainer extends Component<{}, { expertId: number, isLoadingPlaid: boolean }> {
  state = { expertId: 0, isLoadingPlaid: false };
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    const expertIdFromStore
      = this.props.auth
        .get('users')
        .get('viewer')
        .get('loggedInUser')
        .get('expertId');
    if (expertIdFromStore) {
      this.setState({ expertId: expertIdFromStore })
    }
  }

  render() {
    const { dashboard, msg } = this.props;

    const portfolio = selectActivePortfolio(dashboard);
    const warnings = selectWarningMessages({
      portfolio,
      msg: msg.userWarnings,
      actions: {
        import: this.import
      }
    });

    return <UserWarnings msg={msg.userWarnings} warnings={warnings} isLoading={this.state.isLoadingPlaid} loadingText={'Connecting to your existing portfolio, please wait'}/>;
  }

  import = () => {
    const { actions } = this.props;

    const expertId = this.state.expertId;
    this.setState({ isLoadingPlaid: true });
    syncSmartPortfolioWithPlaid(expertId, {
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        if (typeof actions.fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await actions.fetchPortfolios();
        }
        if (Array.isArray(PortfolioIds) && PortfolioIds[0] && typeof actions.changePortfolio === 'function') {
          actions.changePortfolio(PortfolioIds[0])
        }
        if (!location.pathname.includes('smart-portfolio/holdings/holdings')) { 
          history.push('/smart-portfolio/holdings/holdings');
        }
        this.setState({ isLoadingPlaid: false });
      },
      onSyncFailed: () => {
        this.setState({ isLoadingPlaid: false })
      },
      onSyncStopped: () => {
        this.setState({ isLoadingPlaid: false })
      },
    })
  };
}
