import { getGlobalActions } from '../lib/actions';
import * as React from 'react';
import report from './analytics';
import { WelcomeBanner } from './WelcomeBanner';
import msg from './en';
import { WelcomeConnectExistingPortfolio, OnClickBroker } from './WelcomeConnect';
import WelcomeSearch from './WelcomeSearch';
import { Actions, ReduxProps } from '../../common/types';
import WelcomeCSVThingy from './WelcomeCSVThingy';
import { disablePortfolioImport } from '../../common/config';
import { syncSmartPortfolioWithPlaid } from './SmartPortfolioPlaidUtils';
import { checkForPlaidTokenInStorage } from '../../common/PlaidProviderLogic';
import { identifyUser } from '../../common/api';
import { WelcomeConnectIsDone } from './WelcomeConnectIsDone';
import { history } from 'sp/browser/history';

enum PlaidFlowStatus {
  Initial = 1,
  OnGoing = 2,
  Done = 3,
  Failed = 4,
}

export class Welcome extends React.Component<
  {
    onClickBroker: OnClickBroker;
    onClickConnect: () => void;
  } & ReduxProps,
  { checkedId: string, expertId: number, plaidFlowStatus: PlaidFlowStatus, wasPlaidModalOpened: boolean }
  > {
  state = { checkedId: 'search-radio', expertId: 0, plaidFlowStatus: PlaidFlowStatus.Initial, wasPlaidModalOpened: false };
  static defaultProps = {
    onClickConnect: () => getGlobalActions().openDialog('syncDialog'),
    onClickBroker: ({ id }) =>
      getGlobalActions().openDialog('syncDialog', { broker: id })
  };

  componentDidMount() {
    const isPlaidTokenExists = checkForPlaidTokenInStorage();
    if (isPlaidTokenExists) {
      this.onSyncClick();
    }
    report('visited');
  }

  UNSAFE_componentWillReceiveProps(nextProps: {
    onClickBroker: OnClickBroker;
    onClickConnect: () => void;
  } & ReduxProps) {
    const expertIdFromStore
      = nextProps.auth
        .get('users')
        .get('viewer')
        .get('loggedInUser')
        .get('expertId');
    if (expertIdFromStore) {
      this.setState({ expertId: expertIdFromStore })
    }
  }

  private shouldUseCheckbox(checkedIdToCheck: string) {
    const { plaidFlowStatus, checkedId } = this.state;
    const isInvalidPlaidStatus 
      = plaidFlowStatus === PlaidFlowStatus.Done 
      || plaidFlowStatus === PlaidFlowStatus.OnGoing;
    if (isInvalidPlaidStatus) { return false; }
    return checkedIdToCheck === checkedId;
  }

  private setWasPlaidModalOpen = () =>{
    this.setState({ wasPlaidModalOpened: true })
  }

  render() {
    const { mediaQuery, onClickConnect, onClickBroker } = this.props;

    const { wasPlaidModalOpened } = this.state;
    const isLoading = this.getIsLoading();

    return (
      <div className="tr-dashboard-welcome-2 welcome2">
        <div>
          <div className="welcome-banner-wrapper welcome-desktop-location">
            <WelcomeBanner mediaQuery={mediaQuery} />
          </div>
          <div className="welcome-form-wrapper">
            <div className="welcome-form">
              <div className="welcome-selector">
                <h1>
                  {mediaQuery.get('laptop') ? (
                    <span className="the-free-override">
                      <div className="yesyes">Welcome!</div>
                      <div>
                        Open your <b>FREE</b> Smart Portfolio Today!
                      </div>
                    </span>
                  ) : (
                      msg.title
                    )}
                </h1>
                <div className="welcome-content-container">
                  <div className="so-help-me-god">
                    <input
                      className="search-radio"
                      id="search-radio"
                      checked={this.shouldUseCheckbox('search-radio')}
                      type="radio"
                      name="welcome"
                      defaultChecked
                      onClick={this.onChange}
                    />
                    <label className={isLoading ? 'welcome-opacity' : ''} htmlFor="search-radio">{msg.addStocks}</label>
                    <div className="god-helper">
                      <input
                        className="google-radio"
                        id="google-radio"
                        checked={this.shouldUseCheckbox('google-radio')}
                        type="radio"
                        name="welcome"
                        onClick={this.onChange}
                      />
                      <label className={isLoading ? 'welcome-opacity' : ''} htmlFor="google-radio">
                        Upload Your Watchlist
                      </label>
                    </div>
                    {!disablePortfolioImport && <div className="connect-portfolio-link">
                      {isLoading ? (
                        <p className="loading-orange">Loading, Please wait...</p>
                      ) : (
                        <p>Or <span className="link" onClick={this.onSyncClick}>Connect Your Existing Portfolio</span></p>
                      )}
                    <div style={{ fontWeight: "normal", fontSize: 12}}>
                      Nasdaq Smart Portfolio is an online service used to track the value of a portfolio of securities.  Nasdaq does not accept deposits and does not invest funds on behalf of users of Nasdaq Smart Portfolio.  If you are contacted by any person who claims to work for Nasdaq and solicits funds from you, you should terminate contact with that person and contact law enforcement.
                    </div>
                    </div>

                    }
                  </div>
                  {this.shouldUseCheckbox('connect-radio') && (
                    <div className="welcome-connect-wrapper">
                      <WelcomeConnectExistingPortfolio
                        isLoading={isLoading}
                        onClick={onClickConnect}
                        onClickBroker={onClickBroker}
                      />
                    </div>
                  )}
                  {this.shouldUseCheckbox('search-radio') && (
                    <div className="welcome-search-wrapper">
                      <WelcomeSearch {...this.props} />
                    </div>
                  )}
                  {this.shouldUseCheckbox('google-radio') && (
                    <div className="welcome-search-wrapper">
                      <WelcomeCSVThingy />
                    </div>
                  )}
                  {
                   isLoading && wasPlaidModalOpened && (
                      <WelcomeConnectIsDone /> 
                  )}
                </div>
                <div className="mobile-banner-wrapper">
                  <WelcomeBanner mediaQuery={mediaQuery} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSyncClick = () => {
    const expertId = this.state.expertId
    this.setState({ plaidFlowStatus: PlaidFlowStatus.OnGoing })
    syncSmartPortfolioWithPlaid(expertId, {
      setWasPlaidModalOpened: this.setWasPlaidModalOpen,
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        this.setState({ plaidFlowStatus: PlaidFlowStatus.Done })
        await identifyUser({ shouldRefresh: true })
        /* const fetchedPortfolios = */await this.props.actions.fetchPortfolios();
        if (Array.isArray(PortfolioIds) && PortfolioIds[0]) {
          this.props.actions.changePortfolio(PortfolioIds[0])
        }
        history.push('/smart-portfolio/holdings/holdings');
      },
      onSyncFailed: () => {
        this.setState({ checkedId: 'search-radio', plaidFlowStatus: PlaidFlowStatus.Failed })
      },
      onSyncStopped: () => {
        this.setState({ checkedId: 'search-radio', plaidFlowStatus: PlaidFlowStatus.Initial, wasPlaidModalOpened: false })
      },
    })
  }

  private getIsLoading = () => {
    const { plaidFlowStatus } = this.state;
    return (
      plaidFlowStatus === PlaidFlowStatus.OnGoing 
      || plaidFlowStatus === PlaidFlowStatus.Done
    );
  }

  onChange = ({ currentTarget: { id: checkedId } }) => {
    const isLoadingPlaid = this.getIsLoading();
    if (!isLoadingPlaid) {
      this.setState({ checkedId });
    }
  }
}
