import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import MaybeButton from '../maybe-button';
import Loader from '../components/suit/Loader.react.tsx';

import Details from './details';

export default class TrDashboardHoldingsTdInner extends Component {
  static propTypes = {
    //col: PropTypes.instanceOf(Col),
    hideTooltip: PropTypes.func,
    isDetailsLoading: PropTypes.bool,
    isMissingShares: PropTypes.bool,
    item: PropTypes.object,
    enums: PropTypes.object,
    mediaQuery: PropTypes.object,
    onClick: PropTypes.func,
    openPortfolioItemAlerts: PropTypes.func,
    setStockLastRead: PropTypes.func,
    showTooltip: PropTypes.func,
    showPromotion: PropTypes.func
  };

  static defaultProps = {
    isRestricted: false
  };

  onClick = () => {
    const { item, onClick } = this.props;
    onClick(item.get('ticker'));
  };

  isNull = (col, item) =>
    ({
      price: !item.getIn(['stock', 'price', 'amount']),
      value: !item.get('sharesValue'),
      sincePurchaseGain:
        !item.get('sincePurchaseGain') || !item.get('sharesValue'),
      cap: !item.getIn(['stock', 'price', 'cap'])
    }[col.get('id')]);

  render() {
    const { col, enums, isDetailsLoading, item } = this.props;

    const DetailCell = Details(col.get('id'));

    return (
      <div className="cell-inner td-inner">
        <MaybeButton onClick={this.onClick} when={!col.get('isActionable')}>
          {col.get('hasLoader') &&
          isDetailsLoading &&
          this.isNull(col, item) ? (
            <div className="loading-detail">
              <Loader name="holdingsCell" type="loaderStatic" />
            </div>
          ) : (
            <DetailCell {...this.props} msg={enums} showExpanded visualized />
          )}
        </MaybeButton>
      </div>
    );
  }
}
