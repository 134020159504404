/**
 * Represents BetaBars / BarSliders components range.
 */
export const riskRange = [0.5, 2];

const [x, y] = riskRange;
const mid = x + (y - x) / 2;
const low = x + mid / 2;
const high = y - mid / 2;

export default num => {
  if (num < low) {
    return 'low';
  }
  if (num > low && num < high) {
    return 'mid';
  }
  if (num > high) {
    return 'high';
  }
  return null;
};
