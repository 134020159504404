import { VariableSizeSpinner } from '../Spinner/index.react';
import { ComponentState } from '../../common/types';
import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './style.istyl';

type FeedbackProps = {
  formState: ComponentState;
} & any; //React.HTMLProps<HTMLFormElement>;
type FeedbackState = {
  value: string;
};
export class Feedback extends React.PureComponent<
  FeedbackProps,
  FeedbackState
> {
  public constructor(props: FeedbackProps) {
    super(props);
    this.state = {
      value: ''
    };
  }
  private setValue = (value: string) => this.setState({ value });

  private wrapSubmit = (
    handler: any //React.EventHandler<React.FormEvent<HTMLFormElement>>
  ) => e => {
    e.preventDefault();
    handler(e);
  };

  public render() {
    const { onSubmit = () => {}, formState, ...htmlProps } = this.props;
    return (
      <section className={styles.feedback}>
        <form
          {...htmlProps}
          onSubmit={this.wrapSubmit(onSubmit)}
          className={classNames(htmlProps.className, {
            [styles.active]: formState !== 'LOADING' && formState !== 'SUCCESS'
          })}
        >
          <h1>Give Us Feedback</h1>
          <p>How can we make TipRanks and Smart Portfolio better for you?</p>
          <textarea
            name="message"
            onInput={e =>
              this.setValue((e.target as HTMLTextAreaElement).value)
            }
            placeholder="Message"
          />
          <div>
            <button disabled={this.state.value === ''}>Send</button>
          </div>
        </form>
        <div
          className={classNames(styles.spinner, {
            [styles.active]: formState === 'LOADING'
          })}
        >
          <VariableSizeSpinner size={50} />
        </div>
        <div
          className={classNames(styles.thankYou, {
            [styles.active]: formState === 'SUCCESS'
          })}
        >
          Thank you for your feedback.
        </div>
      </section>
    );
  }
}
