import * as React from 'react';
import * as classNames from 'classnames';
import 'whatwg-fetch';

import report from './analytics';
import msg, { getButtonText } from './msg';
import * as styles from './styles.istyl';

import { selectActivePortfolio } from '../dashboard/selectors';
import { ReduxProps, PlaidSyncStatus } from '../../common/types';
import { identifyUser } from '../../common/api';
import { updateAuthSyncSmartPortfolioWithPlaid, updatePlaidPortfolio } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';

interface State {
  isLoading?: boolean;
  selectedPortfolioId: number;
}
type Props = ReduxProps;

export default class TrImportedPortfolioIndication extends React.PureComponent<
  Props,
  State
  > {
  public state: State = { isLoading: false, selectedPortfolioId: 0 };
  public componentDidMount() {
    const currentPortfolioId = selectActivePortfolio(this.props.dashboard).get('id')
    this.setState({ selectedPortfolioId: currentPortfolioId }, () => {
      this.handlePortfolioChange(() => currentPortfolioId > 0)
    })
  }
  public componentDidUpdate() {
    const portfolioIdFromProps = selectActivePortfolio(this.props.dashboard).get('id')
    const { selectedPortfolioId } = this.state;
    this.setState({ selectedPortfolioId: portfolioIdFromProps }, () => {
      this.handlePortfolioChange(() => portfolioIdFromProps > 0 && portfolioIdFromProps !== selectedPortfolioId)
    })
  }
  private reauthenticate = async (portfolioId: number) => {
    this.toggleBtnClickability(true);
    const { actions } = this.props;
    await updateAuthSyncSmartPortfolioWithPlaid(portfolioId, {
      onSyncEnd: async () => {
        await identifyUser({ shouldRefresh: true })
        if (typeof actions.fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await actions.fetchPortfolios();
        }
        if (typeof actions.changePortfolio === 'function') {
          actions.changePortfolio(portfolioId)
        }
        this.toggleBtnClickability(false);
      },
      onSyncFailed: () => {
        this.toggleBtnClickability(false);
      },
      onSyncStopped: () => {
        this.toggleBtnClickability(false);
      }
    })
  }

  private handlePortfolioChange = (getCondition: () => boolean) => {
    const isReAuthNeeded 
      = typeof getCondition === 'function'
      && getCondition()
      && this.isImported()
      && shouldReauthByPortfolioStatus(this.getActivePortfolio());
    if(isReAuthNeeded) {
      return this.reauthenticate(this.state.selectedPortfolioId);;
    }
  }

  public toggleBtnClickability = (isLoading: boolean) =>
    this.setState({ isLoading });

  private update = async () => {
    const { dashboard } = this.props;
    this.toggleBtnClickability(true);
    const portfolio = selectActivePortfolio(dashboard);
    const portfolioId = portfolio.get('id');
    await updatePlaidPortfolio(String(portfolioId))
    report('updateButtonClicked');
    await identifyUser({ shouldRefresh: true })
    location.reload(true);
    this.toggleBtnClickability(false);
    return;
  };

  private onClick = () => {
    if (this.state.isLoading) { return; }
    
    const activePortfolio = selectActivePortfolio(this.props.dashboard);
    const plaidSyncStatus = activePortfolio.get('plaidSyncStatus');
    
    const shouldUpdateWithoutReAuth 
      = plaidSyncStatus === PlaidSyncStatus.Active
      || plaidSyncStatus === PlaidSyncStatus.Failure
      || activePortfolio.get('type') === 'pending' // TODO: May be removed ? 

    if (shouldUpdateWithoutReAuth) {
      return this.update();
    }
    if (shouldReauthByPortfolioStatus(this.getActivePortfolio())) {
      return this.reauthenticate(this.state.selectedPortfolioId);;
    }
    console.log(
      'No action is taken with Trade It Sync Status',
      plaidSyncStatus
    );
  };
  private getActivePortfolio = () => {
    const { dashboard } = this.props;
    return selectActivePortfolio(dashboard);
  };
  private getData = () => {
    const activePortfolio = this.getActivePortfolio();
    return {
      isImported: activePortfolio.get('isImported'),
      siteName: activePortfolio.get('type') !== 'pending'
        ? activePortfolio.get('siteName')
        : 'ib',
      syncStatus: activePortfolio.get('type') !== 'pending'
        ? activePortfolio.get('plaidSyncStatus')
        : PlaidSyncStatus.Active,
    };
  };
  private getBrokerName = () => {
    const { siteName } = this.getData();
    return siteName;
  };
  private isImported = () => {
    const { isImported = true } = this.getData();
    return isImported;
  };

  public render() {
    if (!this.isImported()) return null;
    const { dashboard, mediaQuery } = this.props;

    const { isLoading = false } = this.state;

    const isTablet = mediaQuery.get('tablet');
    let { syncStatus } = this.getData();
    syncStatus = syncStatus || 1;
    const name = nameSite(this.getBrokerName());
    const buttonTxt = getButtonText({
      status: syncStatus,
      isLoading,
      isTablet
    });

    const headline = msg.headline({ name });
    return (
      <div className="tr-imported-portfolio-indication">
        <div className={styles.wrapper}>
          <div className={styles.headline}>
            <span>{headline}</span>
            {shouldReauthByPortfolioStatus(this.getActivePortfolio()) && (
              <span className={styles.bold}>
                &nbsp;(Reauthentication Required)
              </span>
            )}
          </div>
          <div className={classNames(styles.buttonWrapper, 'button')}>
            <button
              disabled={isLoading}
              onClick={this.onClick}
              className={classNames(styles.button, {
                [styles.rotate]: isLoading
              })}
            >
              <span>{buttonTxt}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function nameSite(name: string) {
  if (name === 'fidelity') return 'Fidelity';
  if (name === 'ib') return 'Interactive Brokers';
  if (name === 'td') return 'TD Ameritrade';
  if (name === 'robinhood') return 'Robinhood';
  if (name === 'schwab') return 'Charles Schwab';
  if (name === 'etrade') return 'E*Trade';
  if (name === 'scottstrade') return 'Scottrade';
  if (name === 'tradestation') return 'TradeStation';
  if (name === 'tradeking') return 'TradeKing';
  if (name === 'dummy') return 'GalForgives';
  if (name === null) return 'N/A';
  return name;
}
export function shouldReauthByPortfolioStatus(portfolio) {
  const syncStatus = portfolio.get('plaidSyncStatus');
  return (
    PlaidSyncStatus.ReAuthenticationNeeded === syncStatus
  );
}
