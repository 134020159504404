import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Component from 'react-pure-render/component';

export default class TrDashboardHoldingsCircleButton extends Component {
  constructor(...args) {
    super(...args);
    this.state = { done: false };
  }

  static propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['delete', 'add'])
  };

  setDone = () => this.setState({ done: true });

  onClick = () => {
    const { item, onClick, type } = this.props;

    const success = onClick(item.get('ticker'));
    if (type === 'add' && success !== false) this.setDone();
  };

  render() {
    const { type } = this.props;
    const { done } = this.state;

    return (
      <div className={classNames('circle-button', `${type}-button`)}>
        <button
          className={`${type}-button`}
          disabled={done}
          onClick={this.onClick}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: done ? '&#x2713' : '+'
            }}
          />
        </button>
      </div>
    );
  }
}
