import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { bindFns } from '../../common/lib/utils.ts';

export default class TooltipOwner extends Component {
  static propTypes = {
    actions: PropTypes.object,
    args: PropTypes.object,
    children: PropTypes.any,
    tooltip: PropTypes.string
  };

  componentDidMount() {
    bindFns.call(this, ['showTooltip', 'hideTooltip']);
  }

  render() {
    const { children } = this.props;

    return (
      <div
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        {...this.props}
      >
        {children}
      </div>
    );
  }

  showTooltip({ target }) {
    const { actions, args, tooltip } = this.props;
    actions.showTooltip(tooltip, args, target);
  }

  hideTooltip() {
    const { actions, args, tooltip } = this.props;
    actions.hideTooltip(tooltip, args);
  }
}
