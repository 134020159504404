import './Dashboard.styl';

import * as classNames from 'classnames';
import { List } from 'immutable';
import * as React from 'react';
import { selectActivePortfolio, selectDismissdedWarningDialogs } from 'sp/browser/dashboard/selectors';
import { ImportingPage } from 'sp/browser/ImportingPage/ImportingPage';
import { Community } from 'sp/browser/InternalCommunityPage';
import { requireAuth } from 'sp/browser/lib/router-utils';
import {
  FirstOnboardingPage,
  OnboardingPageWrapper,
  SecondOnboardingPage,
  ThirdOnboardingPage,
} from 'sp/browser/pages/PublicPortfolio/Onboarding';
import { PublicPortfolioToRegister } from 'sp/browser/pages/PublicPortfolioToRegister';
import { RouteNav } from 'sp/browser/RouteNav/RouteNav';
import { MyPerformancePageContainer } from 'sp/browser/tr-dashboard-user-performance-container/container';
import { appSideMenuOptions, companyConstants } from 'sp/common/config';
import { ConnectedComponent } from 'sp/common/lib/ConnectedComponent';
import { classify, getRoutes, popupRegister } from 'sp/common/lib/utils.ts';
import { ReduxProps } from 'sp/common/types';

import { selectIsTemporaryLoggedIn } from '../../common/auth/selectors';
import { AccountSettings } from '../AccountSettings/AccountSettings';
import { ApiDecorator } from '../ApiDecorator';
import localStorage from '../components/personsList/localstorage';
import ContactUs from '../contact/contact.react';
import Dictionary from '../dictionary/dictionary.react';
import { isShittyBrowser } from '../lib/utils';
import PortfolioCalendarContainer from '../PortfolioCalendarContainer';
import { SignUpThankYou } from '../SignUpThankYou/SignUpThankYou';
import { TargetedWebPage } from '../TargetedWebPage/TargetedWebPage';
import AlertsSettingsContainer from '../tr-dashboard-alerts-settings-container';
import AvgPortfolioContainer from '../tr-dashboard-avg-portfolio-container';
import DashboardExperts from '../tr-dashboard-experts-container';
import TrDashboardHoldingsContainer from '../tr-dashboard-holdings-container/';
import TrDashboardMgmtFeesRouteContainer from '../tr-dashboard-mgmt-fees-route-container';
import DashboardOverviewContainer from '../tr-dashboard-overview-container';
import { TrDashboardPortfolioNewsContainer } from '../tr-dashboard-portfolio-news-container/index.react';
import { TrDashboardSavePortfolios } from '../tr-dashboard-save-portfolios';
import { Welcome as WelcomeContainer } from '../tr-dashboard-welcome-2';
import WelcomeDev from '../tr-dashboard-welcome-dev';
import { WelcomeToast } from '../tr-dashboard-welcome-toast';
import ETFsScreener from '../tr-etf-screener-container';
import Plans from '../tr-plans-container';
import StockScreener from '../tr-stock-screener-container';
import TrThankYouContainer from '../tr-thank-you-container';
import AnalysisPortfolio from './routes/analysis/portfolio/AnalysisPortfolio.react';
import { history } from 'sp/browser/history';

const renderPanel = (
  msg,
  dashboard,
  activeRoute,
  actions,
  isImported,
  isCombinedPortfolio,
) => {
  let portfolioSelect = List([
    <button
      className="add laptop-only"
      key="addBtn"
      onClick={() => actions.openDialog('addPortfolio')}
    >
      <span>{msg.dashboard.addPortfolio}</span>
    </button>,
  ]);
  if (!isImported && !isCombinedPortfolio) {
    portfolioSelect = portfolioSelect.push(
      <button
        className="add"
        key="addStocksBtn"
        onClick={() => actions.openDialog('addStock')}
      >
        <span>{msg.dashboard.addStocks}</span>
      </button>,
    );
  }

  return (
    {
      overview: portfolioSelect,
      'holdings.holdings': portfolioSelect,
      'holdings.holdings-new': portfolioSelect, // TODO remove this hack!
      'holdings.news': portfolioSelect,
      'holdings.news.all': portfolioSelect,
      'holdings.news.analysis': portfolioSelect,
      'holdings.news.bullish': portfolioSelect,
      'holdings.news.bearish': portfolioSelect,
      'holdings.opinions': portfolioSelect,
      'holdings.calendar': portfolioSelect,
      'analysis.overview': portfolioSelect,
      'analysis.fees': portfolioSelect.push(
        <span className="fees-subtitle" key="feesSubtitles">
          Management Fees
        </span>,
      ),
      'holdings.alerts-settings': (
        <span className="sub-sub-route-name alerts-settings">
          <span>{msg.dashboard.emailAlerts}</span>
        </span>
      ),
      insights: portfolioSelect,
    }[activeRoute] || null
  );
};
const renderRoute = ({ route, activeRoute }, props) => {
  // look ma, everyone can do a router nowadays
  if (activeRoute === 'profile' || activeRoute === 'investors') {
    if (route.includes('register'))
      return <PublicPortfolioToRegister />;

    const { publicPortfolioId } = props.params;

    return (
      <MyPerformancePageContainer
        {...props}
        isPublicPortfolio
        isRoute
        publicPortfolioId={publicPortfolioId}
      />
    );
  }

  switch (route) {
    case 'overview':
      return <DashboardOverviewContainer {...props} isRoute />;

    case 'holdings.holdings':
      return <TrDashboardHoldingsContainer {...props} isRoute />;

    case 'holdings.holdings-new':
      return <TrDashboardHoldingsContainer {...props} isRoute />;

    case 'holdings.news':
      return (
        <TrDashboardPortfolioNewsContainer
          {...props}
          tabId="all"
          isRoute
          isInsidePortfolioNews
        />
      );

    case 'holdings.news.all':
      return (
        <TrDashboardPortfolioNewsContainer
          {...props}
          tabId="all"
          isRoute
          isInsidePortfolioNews
        />
      );

    case 'holdings.news.analysis':
      return (
        <TrDashboardPortfolioNewsContainer
          {...props}
          tabId="analysis"
          isRoute
          isInsidePortfolioNews
        />
      );

    case 'holdings.news.bullish':
      return (
        <TrDashboardPortfolioNewsContainer
          {...props}
          tabId="bullish"
          isRoute
          isInsidePortfolioNews
        />
      );

    case 'holdings.news.bearish':
      return (
        <TrDashboardPortfolioNewsContainer
          {...props}
          tabId="bearish"
          isRoute
          isInsidePortfolioNews
        />
      );

    case 'holdings.calendar':
      return <PortfolioCalendarContainer {...props} isRoute />;

    case 'analysis.overview':
      return <AnalysisPortfolio {...props} isRoute />;

    case 'analysis.fees':
      return <TrDashboardMgmtFeesRouteContainer {...props} isRoute />;

    case 'insights':
      return <AvgPortfolioContainer {...props} isRoute />;

    case 'holdings.alerts-settings':
      return <AlertsSettingsContainer {...props} isRoute />;

    case 'experts':
      return <DashboardExperts {...props} isRoute />;

    case 'performance.me':
      return (
        <MyPerformancePageContainer
          {...props}
          isPublicPortfolio={false}
          isRoute
        />
      );

    case 'welcome':
      return <WelcomeContainer {...props} isRoute />;

    case 'welcome-dev':
      return <WelcomeDev {...props} isRoute />;

    case 'plans':
      return <Plans {...props} isRoute />;

    case 'contact':
      return <ContactUs {...props} isRoute />;

    case 'faq':
      return <Dictionary {...props} slug="smart-portfolio-faq" isRoute />;

    case 'glossary':
      return <Dictionary {...props} slug={props.params.slug} isRoute />;

    case 'go.pro':
      return <TargetedWebPage {...props} isRoute />;

    case 'sign-up':
      return <SignUpThankYou {...props} type={props.params.type} isRoute />;

    case 'importing':
      return <ImportingPage />;
    case 'thank-you':
      return <TrThankYouContainer />;
    case 'screener.stocks':
      return <StockScreener {...props} />;
    case 'screener.etfs':
      return <ETFsScreener {...props} />;
    case 'account.settings':
      return <AccountSettings {...props} />;
    case 'community':
      return <Community {...props} isRoute />;
    case 'onboarding.1':
      return <OnboardingPageWrapper page={1} render={() => <FirstOnboardingPage />} />
    case 'onboarding.2':
      return <OnboardingPageWrapper page={2} render={(props) => <SecondOnboardingPage {...props} />} />
    case 'onboarding.3':
      return <OnboardingPageWrapper page={3} render={() => <ThirdOnboardingPage />} />
    default:
      return (
        <section className={`coming-soon hello-${route}`}>
          <h1>Coming Soon!</h1>
          <h2>This area is under construction</h2>
        </section>
      );
  }
};

@ApiDecorator('portfolios', 'portfolioItems')
export default class Dashboard extends ConnectedComponent<{ routes: any }, ReduxProps> {
  public state = { isToastShown: false }

  componentDidMount() {
    history.listenBefore(this.beforeTransition);

    // TIPRANKS-13669 - trigger redraw
    const overflowY = document.body.style.overflowY;
    document.body.style.overflowY = '';
    setTimeout(() => {
      document.body.style.overflowY = overflowY;
    }, 200);

    // nasdaq analytic
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.THEME === 'nasdaq'
    ) {
      const iframe = document.createElement('iframe');
      iframe.src = '/iframes/nasdaq-analytic.html';
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      let lastRoute = '';
      history.listenBefore(event => {
        const path = event.pathname.split('/smart-portfolio/');
        if (path.length > 1) {
          const route = path[1].replace(/\//g, '-');
          if (route !== lastRoute) {
            iframe!.contentWindow!.postMessage(
              `tipranks-nasdaq-analytics:${route}`,
              '*',
            );
            lastRoute = route;
          }
        }
      });
    }
  }

  isExemptFromConfirm = () => {
    const exemptRoutes = ['gopro'];
    return (
      exemptRoutes.filter(
        route => window.location.pathname.indexOf(route) !== -1,
      ).length > 0
    );
  };

  componentDidUpdate() {
    const isTemporaryLoggedIn = selectIsTemporaryLoggedIn();

    if (isTemporaryLoggedIn && !this.isExemptFromConfirm() && !isShittyBrowser) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
  }

  onBeforeUnload = () =>
    !window.preventNavConfirm && !window.location.href.includes('plans')
      ? `If you close this tab your portfolio data will be lost!
      You can save your portfolio by clicking on the save button.`
      : undefined;

  handleSharesWarning = listener => {
    const { routes } = this.props;
    const { actions, dashboard } = this.connected;

    const { route } = getRoutes(routes);
    const portfolio = selectActivePortfolio(dashboard);
    const isMissingShares = portfolio.get('modes').includes('missingShares');
    const dismissdedWarningDialogs = selectDismissdedWarningDialogs(dashboard);

    const firstTime = name => !dismissdedWarningDialogs.includes(name);
    const isDestinationExcluded = [
      '/smart-portfolio/holdings/news',
      '/smart-portfolio/holdings/calendar',
      '/smart-portfolio/plans',
    ].includes(listener.pathname);

    const navIn = 'missingSharesOnNavIn';
    if (
      route === 'holdings.holdings' &&
      isMissingShares &&
      firstTime(navIn) &&
      !isDestinationExcluded
    ) {
      actions.openDialog(navIn);
      actions.dismissWarningDialog(navIn);
    }
  };

  register = event => {
    const { routes } = this.props;
    event.preventDefault();
    requireAuth(routes);
    return false;
  };

  shouldShowToast = () => {
    const { routes } = this.props;

    const isTemporaryLoggedIn = selectIsTemporaryLoggedIn();
    const { route } = getRoutes(routes);
    return (
      !['welcome', 'plans', 'go.pro', 'sign-in'].includes(route) &&
      isTemporaryLoggedIn
    );
  };

  handleWelcomeToast = () => {
    const { isToastShown } = this.state;
    if (this.shouldShowToast() && !isToastShown) {
      setTimeout(() => this.setState({ isToastShown: true }), 1e3);
    }
  };

  beforeTransition = (listener) => {
    const url = window.location.href;
    if (!url.includes('plans')) {
      localStorage.setItem('lastLockedFeatureURL', url);
    }
    this.handleSharesWarning(listener);
    this.handleWelcomeToast();
  };

  render() {
    const {
      actions,
      dashboard,
      msg,
      mediaQuery,
    } = this.connected;

    const {
      routes,
    } = this.props;

    const { isToastShown } = this.state;

    const leRoutes = getRoutes(routes);
    const { activeRoute, route } = leRoutes;

    const isTemporaryLoggedIn = selectIsTemporaryLoggedIn();
    const activePortfolio = selectActivePortfolio(dashboard);

    const isCombinedPortfolio =
      activePortfolio.get('id') ===
      companyConstants.get('combinedUserPortfolio');
    const isImported = activePortfolio.get('isImported') || activePortfolio.get('type') === 'pending';

    /**
     * this little shit controls the styling of the whole fucking app.
     * There are dozen of stylus files that depend on this particular pieece of shit to exist depending on the page.
     */
    const dashboardStupidClass = `dashboard-${classify(route)}`;

    return (
      <div className={classNames('dashboard', dashboardStupidClass)}>
        <RouteNav
          {...this.props}
          {...this.connected}
          activeRoute={activeRoute}
          activeSubRoute={route}
          history={history}
          items={appSideMenuOptions}
          isLaptop={mediaQuery.get('laptop')}
          msg={msg.dashboard.nav}
          toggleRouteNav={actions.toggleRouteNav}
          panel={renderPanel(
            msg,
            dashboard,
            route,
            actions,
            isImported,
            isCombinedPortfolio,
          )}
          register={this.register}
          routeNavState={dashboard.get('routeNav')}
        >
          {renderRoute(leRoutes, this.props)}
        </RouteNav>
        <div className="save-portfolios-fixed">
          <TrDashboardSavePortfolios {...this.props} msg={msg.savePortfolio} />
        </div>
        {this.shouldShowToast() &&
          isToastShown &&
          isTemporaryLoggedIn &&
          !isShittyBrowser && (
            <WelcomeToast
              isToastShown
              msg={msg.welcomeToast}
              onRegister={() => popupRegister()}
            />
          )}
      </div>
    );
  }
}
