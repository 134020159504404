import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { List } from 'immutable';
import './style.styl';
import { isAllowed } from '../../common/auth/selectors';
import { holdingsSectionConfig } from '../../common/config';
import PortfolioItemSelector from '../PortfolioItemSelector';
import TableBodyCell from '../tr-dashboard-holdings-section/TableBodyCell.react';
import { EmailAlertsCell } from '../tr-dashboard-holdings-section/cells/EmailAlertsCell.react.tsx';
import { isNasdaq } from '../../common/config';

const stats = holdingsSectionConfig.get('portfolioItemCols');

export default class PortfolioItem extends Component {
  // static propTypes = {
  //   changeEditable: PropTypes.func,
  //   cols: PropTypes.object,
  //   deleteItem: PropTypes.func,
  //   dependencies: PropTypes.any,
  //   editableId: PropTypes.string,
  //   enums: PropTypes.object,
  //   fetchPriceData: PropTypes.func,
  //   hideTooltip: PropTypes.func,
  //   item: PropTypes.object,
  //   mediaQuery: PropTypes.object,
  //   modes: PropTypes.object,
  //   msg: PropTypes.object,
  //   openPortfolioItem: PropTypes.func,
  //   openPortfolioItemAlerts: PropTypes.func,
  //   portfolioId: PropTypes.number,
  //   priceDependencies: PropTypes.any,
  //   setPurchasePrice: PropTypes.func,
  //   setShares: PropTypes.func,
  //   setStockLastRead: PropTypes.func,
  //   showPromotion: PropTypes.func,
  //   showTooltip: PropTypes.func,
  //   stats: PropTypes.instanceOf(List),
  //   onClickAlert: PropTypes.func,
  //   viewerPlan: PropTypes.string
  // shouldShowStockDeleteBtn
  // };

  render() {
    const {
      changeEditable,
      cols,
      dependencies,
      deleteItem,
      editableId,
      enums,
      fetchPriceData,
      hideTooltip,
      item,
      openPortfolioItem,
      openPortfolioItemAlerts,
      mediaQuery,
      modes,
      msg,
      portfolioId,
      priceDependencies,
      setPurchasePrice,
      setShares,
      setStockLastRead,
      showPromotion,
      showTooltip,
      onClickAlert,
      viewerPlan,
      stats,
      isImportedPortfolio,
      isCombinedPortfolio,
      shouldShowStockDeleteBtn
    } = this.props;

    if (!item) {
      return null;
    }

    return (
      <section className="portfolio-item">
        <header>
          <div className="details">
            <PortfolioItemSelector
              item={item}
              msg={enums}
              type="price-details"
            />
          </div>
          <h1>
            <PortfolioItemSelector item={item} msg={enums} type="ticker" />
          </h1>
          <h2>
            <PortfolioItemSelector item={item} msg={enums} type="name" />
          </h2>
        </header>
        <section className="section-box stats">
          <header>
            <h1>{msg.statsTitle}</h1>
            {isNasdaq ? <div className="email-alerts">
              Email Alerts{' '}
              <EmailAlertsCell onClickAlert={onClickAlert} stock={item} />
            </div> : null}
          </header>
          <ul>
            {stats.map((stat, index) => {
              const isRestricted =
                !isAllowed('premium', viewerPlan) &&
                holdingsSectionConfig.get('premium').includes(stat);
              return (
                <li className={stat} key={stat}>
                  <span className="label">{cols[stat]}</span>
                  <span className="value">
                    <TableBodyCell
                      changeEditable={changeEditable}
                      deleteItem={deleteItem}
                      dependencies={dependencies}
                      editableId={editableId}
                      enums={enums}
                      fetchPriceData={fetchPriceData}
                      field={stat}
                      hideTooltip={hideTooltip}
                      index={index + 1}
                      isRestricted={isRestricted}
                      key={stat}
                      mediaQuery={mediaQuery}
                      modes={modes}
                      msg={msg}
                      openPortfolioItem={openPortfolioItem}
                      openPortfolioItemAlerts={openPortfolioItemAlerts}
                      portfolioId={portfolioId}
                      priceDependencies={priceDependencies}
                      setPurchasePrice={setPurchasePrice}
                      setShares={setShares}
                      setStockLastRead={setStockLastRead}
                      showPromotion={showPromotion}
                      showTooltip={showTooltip}
                      stock={item}
                      onClickAlert={onClickAlert}
                      isImportedPortfolio={isImportedPortfolio}
                      isCombinedPortfolio={isCombinedPortfolio}
                    />
                  </span>
                </li>
              );
            })}
          </ul>
        </section>
        {shouldShowStockDeleteBtn && (
          <button className="delte-stock" onClick={deleteItem}>
            <span className="before">
              <span>+</span>
            </span>
            {msg.deleteBtn}
          </button>
        )}
      </section>
    );
  }
}

PortfolioItem.defaultProps = { stats };
