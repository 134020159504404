import { GetRealTimeQuotes } from 'sp/browser/components/RealTimePrices/API_Details_GetRealTimeQuotes';

const apiUrl = process.env.THEME === 'nasdaq'
  ? 'https://market.tipranks.com/api/details/GetDelayedQuotes'
  : 'https://market.tipranks.com/api/details/GetRealTimeQuotes';

/**
 * Note: Only the following data has been checked, if you plan on expanding usage, check if
 * more data adjusting is needed.
 */
export const getRealTimeQuotes = (tickers: string[]): Promise<GetRealTimeQuotes> => {
  return fetch(`${apiUrl}?tickers=` + tickers.join(',').toUpperCase())
    .then(data => data.json())
    .then((data: GetRealTimeQuotes) =>
      data.map(datum => ({
        ...datum,
        changePercent: datum.changePercent / 100,
      }))
    );
}

export async function getRealTimeQuotesWithoutAdapting(tickers: string[]) {
  const res = await fetch(`${apiUrl}?tickers=` + tickers.join(',').toUpperCase());
  const result = await res.json();
  return result as GetRealTimeQuotes;
}