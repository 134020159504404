import 'whatwg-fetch';

import * as classNames from 'classnames';
import * as React from 'react';
import { history } from 'sp/browser/history';
import { VariableSizeSpinner } from 'sp/browser/Spinner/index.react';
import { selectIsLoggedIn } from 'sp/common/auth/selectors';

import { getReq } from '../../common/api';
import { getIsTouch, openPopup } from '../../common/lib/utils';
import { Broker, BrokerId, brokers, securityQuestionType, Stages, urls } from './conf';
import msg from './msg';

// there are examples of how to test this code in https://www.trade.it/documentation/api#SecurityQuestion

interface Props {
  onSuccess: (broker: BrokerId, portfolioId: number) => void;
  broker?: string | Broker;
  isLoggedIn: boolean;
  isReauth?: boolean;
  makeTemporaryUser: any;
  onBrokerClick?: any;
  onError?: any;
  onLoading: () => void;
  onLoadingDone: () => void;
  /**
   * Indicates whether or not we should display only the list of brokers,
   * without the headers or section.
   */
  clean?: boolean;
  mainClassName?: string;
}

interface State {
  stage: Stages;
  isLoading: boolean;
  isTouch: boolean;
  broker?: Broker;
  securityQuestion?: any;
  user?: any;
  authUrl?: string;
  srv?: any;
  isSubmitting: boolean;
}

// TODO cleanup component from previous, dialog based code
export default class TrConnectExistingPortfolio extends React.PureComponent<
  Props,
  State
  > {
  static defaultProps = {
    onBrokerClick: () => null, // noop
    isReauth: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      stage: Stages.Brokers,
      isLoading: false,
      isTouch: getIsTouch() as boolean,
      isSubmitting: false,
    };
  }
  receivedAuthMessage = e => {
    let data;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
      return;
    }
    const verifier = data.oAuthVerifier;
    try {
      this.sync(verifier);
    } catch (e) {
      this.setStageError();
    }
  };
  setStageError = (additionalState: any = {}) => {
    this.setState({ stage: Stages.Error, ...additionalState });
    (history as any).replaceState(null, '/smart-portfolio/holdings/holdings');
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.receivedAuthMessage);
  }
  componentDidMount() {
    window.addEventListener('message', this.receivedAuthMessage, false);
    const { broker: brokerOrBrokerId, isReauth } = this.props;
    // TODO hacky code because i was not sure about the type of the props
    // being pased in. Need to make sure it is always Broker.
    let broker;
    if (typeof brokerOrBrokerId === 'string') {
      broker = brokers.find(b => brokerOrBrokerId === b.id);
    } else {
      broker = brokerOrBrokerId;
    }
    // if broker has been passed in props, then go straight to auth stage
    if (broker) {
      this.setState({ isLoading: true });
      this.onClickBroker(broker);
    }
  }

  // decide which type of question to ask
  getSecurityQuestionType = question => {
    if (question.challengeImage) return securityQuestionType.challengeImage;
    if (question.choices && question.choices.length)
      return securityQuestionType.choices;
    return securityQuestionType.open;
  };

  // set the security question
  setSecurityQuestion = securityQuestion =>
    this.setState({
      stage: Stages.SecurityQuestion,
      securityQuestion: {
        ...securityQuestion,
        type: this.getSecurityQuestionType(securityQuestion),
      },
    });

  // start syncing the portfolio
  sync = async (verifier: string) => {
    const { onError, onSuccess, onLoading, onLoadingDone, makeTemporaryUser } = this.props;
    const broker = this.getBroker().id;

    // notify container about the loading state
    onLoading();

    // try sync
    try {
      // create temp user if open user
      if (!selectIsLoggedIn()) { await makeTemporaryUser(); }
      //const res = await getReq(urls.sync({ broker, token, id }));
      const res = await getReq<number>(urls.syncWithVerifier({ verifier, broker }));
      // notify container we're done loading
      onLoadingDone();

      // if the response is not a number,
      // then we should show a security question
      if (isNaN(res)) {
        this.setSecurityQuestion(res);
      } else {
        onSuccess(broker, res);
      }
    } catch (e) {
      this.setStageError();
      onLoadingDone();
      onError(e);
    }
  };
  getBroker = () => {
    if (!this.state.broker) {
      throw new Error('No Broker');
    }
    return this.state.broker;
  };
  setAuthUrl = async ({
    stage = Stages.Auth,
    broker,
  }: {
      stage?: Stages;
      broker: Broker;
    }) => {
    const authUrl = await this.getAuthUrl(broker);
    if (authUrl) {
      this.setState({ authUrl, broker, stage });
    } else {
      this.setStageError();
    }
  };

  // handle click on one of the brokers
  onClickBroker = async (broker: Broker) => {
    const { onBrokerClick } = this.props;
    if (broker.inPopup) {
      const apiUrl = urls.auth(broker.id);
      const ref = openPopup('', 750, 800);
      const authUrl = await this.getAuthUrl(broker);
      if (!authUrl) {
        this.setStageError();
      } else {
        (ref as any).location.href = authUrl;
        this.setState({ isLoading: false, broker });
      }
    } else {
      this.setAuthUrl({ broker });
    }
    // notify container about broker click
    onBrokerClick(broker);
  };

  // submit answer of question
  submitAnswer = async event => {
    event.preventDefault();

    const { broker, user, srv, securityQuestion } = this.state;
    const { answer: answerEl } = this.refs;
    const { onError, onSuccess, onLoadingDone } = this.props;

    // orgenize answer
    // TODO why don't we pass userToken?
    const answer = {
      broker: this.getBroker().id,
      securityAnswer: (answerEl as any).value,
      srv: securityQuestion.srv,
    };
    // create the answer url
    // console.log('answer', answer, 'securityQuestion', securityQuestion);

    const answerUrl = urls.answerSecurityQuestion(answer);

    // try answering
    this.setState({ isSubmitting: true });
    try {
      const req = await fetch(answerUrl, { credentials: 'same-origin' });
      if (req.ok) {
        const result = Number(await req.text());
        onSuccess(this.getBroker().id, result);
      } else {
        this.setStageError({ isSubmitting: false });
        // onError(new CustomEvent(`Request to ${answerUrl} failed with ${req.status}`, { details: { req }});
      }
    } catch (e) {
      this.setStageError({ isSubmitting: false });
    }
    this.setState({ isSubmitting: false });
  };

  // produces view for open answer input
  renderOpenAnswer = () => (
    <label>
      <span className="label">{msg.answer}</span>
      <input ref="answer" type="text" />
    </label>
  );

  // produces view for a security question
  renderSecurityQuestion = () => {
    const { securityQuestion, isSubmitting } = this.state;

    const isSecurityQuestionTypeOpen =
      securityQuestion.type === securityQuestionType.open;

    const isSecurityQuestionTypeImage =
      securityQuestion.type === securityQuestionType.challengeImage;

    const isSecurityQuestionTypeChoices =
      securityQuestion.type === securityQuestionType.choices;
    return (
      <form onSubmit={this.submitAnswer}>
          <p>{securityQuestion.question}</p>

          {isSecurityQuestionTypeOpen && this.renderOpenAnswer()}

          {isSecurityQuestionTypeImage && (
            <div>
              <div className="challengeImage">
                {' '}
                <img
                  src={`data:image/png;base64,${securityQuestion.challengeImage}`}
                />{' '}
              </div>
              {this.renderOpenAnswer()}
            </div>
          )}

          {isSecurityQuestionTypeChoices && (
            <label>
              <span className="label"> {msg.answer} </span>
              <select ref="answer">
                {securityQuestion.choices.map(choice => (
                  <option value={choice}> {choice} </option>
                ))}
              </select>
            </label>
          )}

          <button disabled={isSubmitting}>{msg.submitAnswer}</button>
      </form>
    );
  };

  // produces view for broker button
  // renderBroker = (broker: Broker, i: number) =>
  //   <li key={i}>
  //     <button onClick={e => this.onClickBroker(broker)} >
  //       <img className="regular" src={broker.img} />
  //       <img className="hover" src={broker.imgHover} />
  //     </button>
  //   </li>

  renderError = () => (
    <div>
      <h3>Error synchronizing your {this.getBroker().name} portfolio.</h3>
      <p>
        Please contact &nbsp;
        <a
          href={
            (process.env.THEME.toLowerCase() === 'nasdaq'
              ? 'smart-portfolio'
              : '') + '/contact'
          }
        >
          support@tipranks.com
        </a>
        &nbsp;
        <span>for assistance.</span>
      </p>
    </div>
  );

  // produces view for list of brokers
  renderBrokers = () => {
    return <ExistingPortfolioBrokers onBrokerClick={this.onClickBroker} />;
  };

  getAuthUrl = (broker: Broker): Promise<string | void> => {
    const url = urls.auth(broker.id);
    if (!broker.inPopup) this.setState({ isLoading: true });
    return Promise.resolve()
      .then(async () => {
        let authUrl = (await getReq(url)) as string;
        // tradeit bug with tradestation where it returns mixed content.
        authUrl = authUrl.replace('http://', 'https://');
        if (!broker.inPopup) this.setState({ isLoading: false });
        return authUrl;
      })
      .catch(e => {
        if (!broker.inPopup) this.setState({ isLoading: false });
        this.setStageError();
      });
  };

  // produce view for loading stage
  renderLoading = () => (
    <div className="loading">
      <VariableSizeSpinner size={20} />
    </div>
  );

  renderTakeMeToPopup = () => {
    const { authUrl, broker } = this.state;

    return (
      <div className="loading">
        <a href={authUrl} target="_blank">
          Take me to {this.getBroker().name}.
        </a>
      </div>
    );
  };
  renderIframe = (broker: Broker) => {
    return (
      <div className="iframe">
        <iframe className="auth" src={this.state.authUrl} />
      </div>
    );
  };

  render() {
    const { isLoading, stage = Stages.Brokers, isTouch, broker } = this.state;
    const { isLoggedIn, clean, mainClassName } = this.props;
    const brokerName = (this.state.broker || ({} as Broker)).name;
    const isStageAuth = stage === Stages.Auth || stage === Stages.ReAuth;
    const isPopup = isStageAuth && this.getBroker().inPopup;
    const renderAuth = isStageAuth
      ? isPopup
        ? this.renderTakeMeToPopup
        : () => this.renderIframe(this.getBroker())
      : () => null;
    const header = msg.header[stage] && msg.header[stage]({ brokerName });
    const subheader = !isPopup && isStageAuth ? '' : msg.subheader[stage];

    const Main = (
      <main className={classNames('brokers-main', mainClassName)}>
        {stage !== Stages.Error && isLoading ? (
          this.renderLoading()
        ) : (
            <ul className="stages">
              {stage === Stages.Brokers && (
                <li className="stage-brokers"> {this.renderBrokers()} </li>
              )}
              {isStageAuth && (
                <li className="stage-securityQuestion"> {renderAuth()} </li>
              )}
              {stage === Stages.SecurityQuestion && (
                <li className="stage-securityQuestion">
                  {' '}
                  {this.renderSecurityQuestion()}{' '}
                </li>
              )}
              {stage === Stages.Error && (
                <li className="stage-error"> {this.renderError()} </li>
              )}
            </ul>
          )}
      </main>
    );

    return clean ? (
      Main
    ) : (
        <section
          className={classNames('tr-connect-existing-portfolio', { isTouch })}
        >
          <header>
            <h1>{header}</h1>
            {!isLoading && subheader && <h2>{subheader}</h2>}
          </header>
          {Main}
        </section>
      );
  }
}

interface BrokersProps {
  onBrokerClick: (broker: Broker) => Promise<void>;
}
export function ExistingPortfolioBrokers({ onBrokerClick }: BrokersProps) {
  return (
    <div>
      <ul className="brokers">
        {' '}
        {brokers.map((broker: Broker, i: number) => (
          <li key={i}>
            <button onClick={e => onBrokerClick(broker)}>
              <img className="regular" src={broker.img} />
              <img className="hover" src={broker.imgHover} />
            </button>
          </li>
        ))}{' '}
      </ul>
      <p className="terms">
        <span>By clicking you agree to TipRanks'</span> <a target="_blank" href="https://tipranks.com/terms">
          Terms of Use
        </a> and <a href="https://tipranks.com/privacypolicy" target="_blank">
          Privacy Policy
        </a>
      </p>
    </div>
  );
}
