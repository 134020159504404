import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './ColorCodedList.istyl';
import {
  defaultColorList, otherColor,
} from 'sp/browser/AllocationGraph/AllocationGraph';
import { ColorCodedListValue, Item } from "sp/browser/ColorCodedList";
import { StockLink } from 'sp/browser/StockLink/StockLink';
import { StockTypeEnum } from 'sp/common/types';
import { isCanadianTicker } from 'sp/browser/lib/utils';

export type CompanyItem = { ticker: string, type: StockTypeEnum } & Item;
export type CompanyColorItem = CompanyItem & { backgroundColor?: string; };
export type TickerCompanyPercentColoredListValue = (CompanyColorItem)[];

export class TickerCompanyPercentColoredList extends React.PureComponent<{
  value: TickerCompanyPercentColoredListValue;
}> {
  render() {
    const { value: list } = this.props;

    return (
      <ul className={classNames(styles.tickerCompanyPercent, styles.list)}>
        {list.map((item, index) => {
          const { id: companyName, ticker, value: percentText, type, backgroundColor = defaultColorList[index % list.length] } = item;
          return (
            <li className={styles.item} key={index} data-ticker={ticker} data-type={type}>
              <span
                className={styles.customColorBullet}
                title={companyName}
                style={{ backgroundColor: item.id.toLocaleLowerCase() === 'other' ? otherColor : backgroundColor }}
              />
              <div className={styles.a1}>
                {companyName.toLowerCase() !== 'other' && <StockLink ticker={ticker} type={type} className={styles.ticker} />}
                <span className={classNames({ [styles.fcanada]: isCanadianTicker(ticker) }, styles.mainText)} title={companyName}>{companyName}</span>
              </div>
              <span className={styles.value}>{percentText}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}