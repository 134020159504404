import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class Details extends Component {
  static propTypes = {
    enums: PropTypes.object,
    fetchPriceData: PropTypes.func,
    item: PropTypes.object,
    priceDependencies: PropTypes.any
  };

  render() {
    const { enums, item } = this.props;

    return (
      <div className="details">
        <PortfolioItemSelector item={item} msg={enums} type="price-details" />
      </div>
    );
  }
}
