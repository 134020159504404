import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import './style.styl';
import { frameName, Messenger } from './messenger';
import report from './analytics';
import cookies from 'js-cookie';
import { queryString } from 'sp/browser/lib/utils';

export default class TrExternalLogin extends Component {
  componentDidMount() {
    const { type } = this.props;
    this.messenger = new Messenger(window.location.origin, frameName);
    this.listener = this.messenger.listen(this.onMessage);
    report('saw', type);
  }

  componentWillUnmount() {
    if (this.messenger) this.messenger.unlisten(this.listener);
  }

  onMessage = ({ type, payload } = {}) => {
    if (type === 'signup-completed') {
      report.ga('/user/completed-sign-up', false);
    }

    if (type === 'authenticated') {
      const loginType = cookies.get('loginType');
      report('signedup', {
        loginType,
        type: payload.provider ? `Social (${payload.provider})` : 'regular'
      });
      report('signedupSmartPortfolio');
      this.props.onAuthenticated(payload);
    }

    if (type === 'locationChange') {
      const { href } = payload;
      const type = href.split('/')[2];
      report('saw', type);
    }
  };

  render({ popup, src, type } = this.props) {
    return (
      <div className="tr-external-login">
        <iframe
          ref="iframe"
          id={frameName}
          name={frameName}
          scrolling="no"
          src={queryString(src, {
            popup,
            msgType: type
          })}
        />
      </div>
    );
  }
}

TrExternalLogin.propTypes = {
  onAuthenticated: PropTypes.func,
  popup: PropTypes.bool,
  src: PropTypes.string,
  type: PropTypes.string
};

TrExternalLogin.defaultProps = {
  onAuthenticated(user) {
    console.log('authenticated: ' + user);
  },
  popup: true
};
