import * as React from 'react';

import { selectIsLoggedIn, selectIsPermanentLoggedIn } from '../../common/auth/selectors';
import { popupRegister, SocialAuthPopupType } from '../../common/lib/utils';
import { ReduxProps } from '../../common/types';
import {
  dataListQuerySelector,
  pendingStocksToAddSelector,
  selectActivePortfolio,
  selectActiveTickersLength,
  selectDataList,
  selectProccessStates,
  selectTheme,
} from '../dashboard/selectors';
import AddStock_ from '../tr-dashboard-add-stock';
import report from './analytics';
import { history } from 'sp/browser/history';

export interface AddStockContainerProps {
  mediaQuery: any; //PropTypes.object,
  msg: any; //PropTypes.object,
  noAnalytics?: any; //PropTypes.bool,
  shouldDisplayImportOption?: any; //PropTypes.bool
  onHasReachedLimit?: () => void;
}

export class AddStockContainer extends React.Component<
  AddStockContainerProps & ReduxProps
  > {
  state = { shouldApplAndCloseTempUser: false };

  componentDidMount() {
    if (!this.props.noAnalytics) report('saw');
    if (typeof window !== 'undefined') {
      setTimeout(() => window.scrollTo(0, 0), 250);
    }
  }

  componentDidUpdate() {
    const { shouldApplAndCloseTempUser } = this.state;
    if (shouldApplAndCloseTempUser) {
      this.setState({ shouldApplAndCloseTempUser: false });
      this.applyAndCloseTempUser();
    }
  }

  addPendingStocksToAdd = (...args) => {
    const { actions } = this.props;
    (actions.addPendingStocksToAdd as any)(...args); // TODO
  };

  fetchAutoComplete = (...args) => {
    const { actions } = this.props;
    (actions.fetchAutoComplete as any)(...args); // TODO
  };

  changeAutoCompleteQuery = (...args) => {
    const { actions } = this.props;
    (actions.changeAutoCompleteQuery as any)(...args); // TODO
  };

  deletePendingStocksToAdd = (...args) => {
    const { actions } = this.props;
    (actions.deletePendingStocksToAdd as any)(...args); // TODO
  };

  applyAndClose = () => async () => {
    const { actions } = this.props;
    const isLoggedIn = selectIsLoggedIn();
    report('added');
    actions.openDialog('analyzingPortfolio');
    if (!isLoggedIn) {
      // TODO awfuly similar to makeTemporaryUserWrapper, but without
      // this.forceUpdate(), why?
      await actions.makeTemporaryUser();
      await actions.fetchPortfolios();
      this.setState({ shouldApplAndCloseTempUser: true });
    } else {
      this.applyAndClosePermanentUser();
    }
  };

  applyAndCloseTempUser = async () => {
    const { actions, auth, dashboard, mediaQuery } = this.props;

    const theme = selectTheme(dashboard);
    await this.applyActions();
    history.push('/smart-portfolio/holdings/holdings-new');
  };

  applyAndClosePermanentUser = () => {
    this.applyActions();
    if (location.pathname.includes('welcome')) {
      history.push('/smart-portfolio/holdings');
    }
  };

  applyActions = async () => {
    const { actions, dashboard } = this.props;
    const activePortfolio = selectActivePortfolio(dashboard);
    const pendings = pendingStocksToAddSelector(dashboard);
    await actions.applyPendingStocksToAdd(activePortfolio.get('id'), pendings);
    await actions.getPortfolioItemsByTickers(
      activePortfolio.get('id'),
      pendings
    );
    actions.closeDialog();
    actions.closeDialog();
  };

  showImport = () => {
    const { actions } = this.props;
    actions.closeDialog();
    actions.openDialog('syncDialog');
  };

  render() {
    const {
      dashboard,
      msg,
      shouldDisplayImportOption,
      onHasReachedLimit,
      mediaQuery,
      auth,
      actions
    } = this.props;

    const activePortfolio = selectActivePortfolio(dashboard);
    const componentState = selectProccessStates(dashboard);
    const dataList = selectDataList(dashboard);
    const pendings = pendingStocksToAddSelector(dashboard);
    const query = dataListQuerySelector(dashboard);
    const isLoggedIn = selectIsLoggedIn();
    const hasHoldings = selectActiveTickersLength(dashboard) > 0;

    const AddStock = AddStock_ as any; // TODO

    return (
      <AddStock
        close={actions.closeDialog}
        fetchPortfolios={actions.fetchPortfolios}
        changePortfolio={actions.changePortfolio}
        activePortfolio={activePortfolio}
        addPendingStocksToAdd={this.addPendingStocksToAdd}
        applyAndClose={this.applyAndClose}
        autoCompleteState={componentState.get('autoComplete')}
        changeAutoCompleteQuery={this.changeAutoCompleteQuery}
        dataList={dataList}
        deletePendingStocksToAdd={this.deletePendingStocksToAdd}
        fetchAutoComplete={this.fetchAutoComplete}
        isLoggedIn={isLoggedIn}
        hasHoldings={!location.href.includes('welcome')}
        msg={msg.addStock}
        pendings={pendings}
        query={query}
        shouldDisplayImportOption={shouldDisplayImportOption}
        showImport={this.showImport}
        onHasReachedLimit={onHasReachedLimit}
        mediaQuery={mediaQuery}
        auth={auth}
        navigationOnDone={() => {
          if (location.pathname.includes('smart-portfolio/holdings/holdings')) { return; }
          history.push('/smart-portfolio/holdings/holdings');
        }}
      />
    );
  }
}

// export const AddStockContainerConnected = connect(mapStateToPropsNoMsg, mapDispatchToProps)(
//   (props: AddStockContainerProps) => <AddStockContainer
// );

export default AddStockContainer;
