import React from 'react';
import { Icon } from 'tipranks-icons';
import { isShittyBrowser } from 'sp/browser/lib/utils';

// TODO insert this into icomoon!
export const svgContent = (id, key, { width }) =>
  (({
    'dashboard-overview': () => {
      const sizeFactor = 1;
      return (
        <svg
          enableBackground="new 0 0 19.014 19.013"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          version="1.1"
          viewBox="0 0 19.014 19.013"
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="
            M8.498,1.982l-0.001,8.514l8.499,0.002c0,4.703-3.803,8.514-8.497,8.514C3.805,19.013,0,15.202,0,10.499
            c0-4.688,3.779-8.49,8.449-8.516C8.466,1.982,8.482,1.982,8.498,1.982z"
            className="filled"
          />

          <path
            d="M9.868,0c5.052,0,9.146,4.105,9.146,9.167l-9.152,0.01C9.861,9.178,9.831,0,9.868,0z"
            className="filled"
          />
        </svg>
      );
    },

    'dashboard-holdings': () => {
      const sizeFactor = 1;
      const style = {
        transform: 'translateY(1px)'
      };
      return (
        <svg
          enableBackground="new 0 0 19 16"
          style={style}
          version="1.1"
          viewBox="0 0 19 16"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="
            M11.045,14.597c0-0.072-0.06-0.131-0.133-0.131H8.087c-0.073,0-0.133,0.059-0.133,0.131v0.442
            c0,0.073-0.06,0.131-0.132,0.131H5.803c-0.073,0-0.133,0.06-0.133,0.132v0.565C5.67,15.941,5.73,16,5.803,16h7.394
            c0.073,0,0.132-0.059,0.132-0.132v-0.565c0-0.072-0.06-0.132-0.132-0.132h-2.02c-0.073,0-0.132-0.059-0.132-0.131V14.597
            L11.045,14.597z"
            className="filled"
          />

          <path
            d="
            M17.936,0H1.064C0.478,0,0,0.475,0,1.059v11.66c0,0.583,0.478,1.059,1.064,1.059h16.871
            c0.587,0,1.064-0.475,1.064-1.059V1.059C19,0.475,18.522,0,17.936,0z M9.5,12.862c-0.265,0-0.479-0.213-0.479-0.477
            s0.214-0.477,0.479-0.477s0.479,0.213,0.479,0.477S9.765,12.862,9.5,12.862z M18.194,11.395H0.806V1.059
            c0-0.14,0.119-0.257,0.259-0.257h16.871c0.14,0,0.259,0.118,0.259,0.257V11.395L18.194,11.395z"
            className="filled"
          />

          <path
            d="
            M16.033,3.227c-0.164-0.125-0.399-0.093-0.524,0.07l-2.751,3.583l-3.271-1.73
            C9.191,4.992,8.81,5.085,8.62,5.36L6.454,8.506c-0.019,0.023-0.08,0.055-0.107,0.057H3.194c-0.207,0-0.374,0.167-0.374,0.372
            c0,0.205,0.167,0.372,0.374,0.372h3.152c0.268,0,0.572-0.16,0.723-0.379l2.128-3.09l3.256,1.723c0.288,0.152,0.669,0.07,0.867-0.188
            l2.783-3.624C16.228,3.584,16.197,3.351,16.033,3.227z"
            className="filled"
          />
        </svg>
      );
    },

    'dashboard-analysis': () => {
      const sizeFactor = 1;
      return (
        <svg
          enableBackground="new 0 0 21.997 14"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          version="1.1"
          viewBox="0 0 21.997 14"
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg}"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="M12.478,4.397c-0.188,0-0.341,0.156-0.341,0.35v8.903c0,0.193,0.153,0.349,0.341,0.349h3.109
          c0.188,0,0.342-0.155,0.342-0.349V4.747c0-0.194-0.153-0.35-0.342-0.35H12.478L12.478,4.397z"
            className="filled"
          />

          <path
            d="M18.204,0.351v13.3c0,0.193,0.153,0.349,0.342,0.349h3.11c0.188,0,0.341-0.155,0.341-0.349v-13.3
          c0-0.094-0.036-0.184-0.1-0.249c-0.064-0.065-0.151-0.103-0.241-0.103h-3.11C18.357-0.001,18.204,0.157,18.204,0.351z"
            className="filled"
          />

          <path
            d="M6.409,1.283c-0.189,0-0.341,0.157-0.341,0.351V13.65c0,0.193,0.152,0.349,0.341,0.349h3.11
          c0.188,0,0.341-0.155,0.341-0.349V1.634c0-0.193-0.153-0.351-0.341-0.351H6.409z"
            className="filled"
          />

          <path
            d="M0.341,5.021C0.152,5.021,0,5.176,0,5.369v8.281c0,0.193,0.152,0.349,0.341,0.349h3.11
          c0.188,0,0.341-0.155,0.341-0.349V5.369c0-0.193-0.153-0.349-0.341-0.349H0.341z"
            className="filled"
          />
        </svg>
      );
    },

    'dashboard-insights': () => {
      const sizeFactor = 1.1;
      return (
        <svg
          enableBackground="new 0 0 26.003 29.002"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          id="Layer_1"
          version="1.1"
          viewBox="0 0 26.003 29.002"
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="
            M16.388,27.156c-0.09,0.287-0.378,0.465-0.678,0.465H15.7c-0.396,0-0.756,0.226-0.934,0.582l-0.007,0.015
            c-0.238,0.48-0.726,0.784-1.258,0.784h-0.999c-0.533,0-1.02-0.304-1.258-0.784l-0.007-0.015c-0.177-0.356-0.538-0.582-0.934-0.582
            h-0.01c-0.299,0-0.587-0.178-0.678-0.465c-0.15-0.478,0.199-0.916,0.649-0.916h5.474C16.189,26.24,16.538,26.679,16.388,27.156z
             M5.744,19.468l-1.936,1.953c-0.267,0.27-0.267,0.707,0,0.977C3.941,22.532,4.117,22.6,4.292,22.6s0.351-0.067,0.484-0.202
            l1.936-1.953c0.267-0.27,0.267-0.707,0-0.977S6.011,19.198,5.744,19.468z M4.106,13.12c0-0.381-0.307-0.69-0.685-0.69H0.685
            C0.307,12.43,0,12.739,0,13.12s0.307,0.69,0.685,0.69h2.737C3.799,13.811,4.106,13.501,4.106,13.12z M13.002,4.143
            c0.377,0,0.684-0.309,0.684-0.69V0.69c0-0.381-0.307-0.69-0.684-0.69c-0.378,0-0.685,0.31-0.685,0.69v2.762
            C12.317,3.834,12.624,4.143,13.002,4.143z M19.776,6.975c0.175,0,0.35-0.067,0.484-0.202l1.936-1.953
            c0.267-0.27,0.267-0.707,0-0.977c-0.267-0.27-0.701-0.27-0.968,0l-1.936,1.953c-0.267,0.27-0.267,0.707,0,0.977
            C19.426,6.907,19.601,6.975,19.776,6.975z M5.744,6.772c0.134,0.135,0.309,0.202,0.484,0.202s0.35-0.067,0.484-0.202
            c0.267-0.27,0.267-0.707,0-0.977L4.776,3.843c-0.267-0.27-0.701-0.27-0.968,0s-0.267,0.707,0,0.977L5.744,6.772z M25.319,12.43
            h-2.737c-0.378,0-0.685,0.31-0.685,0.69s0.307,0.69,0.685,0.69h2.737c0.378,0,0.685-0.31,0.685-0.69S25.697,12.43,25.319,12.43z
             M20.26,19.468c-0.268-0.27-0.701-0.27-0.968,0s-0.267,0.707,0,0.977l1.936,1.953c0.134,0.135,0.309,0.202,0.484,0.202
            s0.35-0.067,0.484-0.202c0.267-0.27,0.267-0.707,0-0.977L20.26,19.468z M15.739,24.514h-5.474c-0.378,0-0.685,0.31-0.685,0.69
            s0.307,0.69,0.685,0.69h5.474c0.377,0,0.685-0.31,0.685-0.69S16.116,24.514,15.739,24.514z M16.423,23.478
            c0,0.382-0.307,0.691-0.685,0.691h-5.474c-0.378,0-0.685-0.31-0.685-0.691c0-0.367,0.286-0.665,0.645-0.687
            c-0.426-3.885-4.409-4.75-4.409-9.326c0-4.004,3.217-7.25,7.186-7.25c3.968,0,7.185,3.246,7.185,7.25
            c0,4.576-3.983,5.441-4.409,9.326C16.138,22.813,16.423,23.11,16.423,23.478z M15.734,11.703c-0.233-0.703-0.945-1.51-2.208-1.685
            V9.185c0-0.285-0.23-0.518-0.513-0.518S12.5,8.899,12.5,9.185v0.849c-1.05,0.205-1.86,1.027-1.968,2.072
            c-0.1,0.975,0.462,2.257,2.375,2.661c1.041,0.22,1.64,0.811,1.565,1.541c-0.061,0.593-0.582,1.193-1.46,1.193
            c-1.042,0-1.593-0.546-1.748-1.014c-0.09-0.271-0.38-0.418-0.649-0.326c-0.269,0.091-0.414,0.384-0.324,0.655
            c0.233,0.703,0.945,1.51,2.208,1.685v0.834c0,0.286,0.23,0.518,0.513,0.518s0.513-0.231,0.513-0.518v-0.849
            c1.05-0.205,1.86-1.027,1.968-2.072c0.1-0.975-0.462-2.257-2.375-2.661c-1.041-0.22-1.64-0.811-1.565-1.54
            c0.061-0.594,0.582-1.194,1.46-1.194c1.042,0,1.593,0.546,1.748,1.014c0.09,0.271,0.379,0.418,0.65,0.327
            C15.679,12.269,15.824,11.975,15.734,11.703z"
            className="filled"
          />
        </svg>
      );
    },

    'dashboard-performance': () => {
      const sizeFactor = 0.9;
      return (
        <svg
          enableBackground="new 0 0 21.366 20.996"
          version="1.1"
          viewBox="0 0 21.366 20.996"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            clipRule="evenodd"
            d="
            M10.683,0l3.521,6.599l7.162,1.421l-4.986,5.5l0.905,7.477
            l-6.603-3.2l-6.603,3.2l0.905-7.477L0,8.02l7.162-1.421L10.683,0"
            className="filled"
            fillRule="evenodd"
          />

          <path
            clipRule="evenodd"
            d="
            M13.146,6.921l0.337,0.605L19.18,8.61l-3.965,4.19l0.72,5.696
            l-2.777-1.289L13.146,6.921z"
            fill="#373737"
            fillOpacity="1"
            fillRule="evenodd"
          />
        </svg>
      );
    },

    'dashboard-public': () => {
      const sizeFactor = 0.9;
      return (
        <svg
          enableBackground="new 0 0 21.366 20.996"
          version="1.1"
          viewBox="0 0 21.366 20.996"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path className="filled" fill-rule="evenodd" d="M19 9.5C19 4.262 14.738 0 9.5 0S0 4.262 0 9.5a9.474 9.474 0 0 0 3.084 6.998l-.01.008.309.26c.02.017.041.031.061.047.164.136.334.265.507.389.055.041.111.081.168.121a10.114 10.114 0 0 0 1.344.774 9.46 9.46 0 0 0 2.347.749l.064.011a9.483 9.483 0 0 0 2.394.109l.096-.007a9.713 9.713 0 0 0 .817-.111 9.392 9.392 0 0 0 2.265-.71c.026-.012.053-.023.079-.036a9.478 9.478 0 0 0 1.324-.756l.189-.135c.152-.109.301-.222.446-.34.032-.026.066-.048.098-.075l.316-.264-.01-.008A9.472 9.472 0 0 0 19 9.5zM.691 9.5C.691 4.642 4.643.691 9.5.691c4.857 0 8.809 3.951 8.809 8.809a8.788 8.788 0 0 1-2.967 6.585 2.548 2.548 0 0 0-.309-.185l-2.925-1.462a.768.768 0 0 1-.425-.689v-1.022c.068-.083.139-.178.213-.282a7.048 7.048 0 0 0 .904-1.769c.437-.209.72-.644.72-1.137V8.315c0-.3-.11-.59-.306-.819V5.884c.018-.18.081-1.191-.651-2.026-.637-.727-1.667-1.094-3.063-1.094-1.396 0-2.426.367-3.063 1.094-.732.835-.669 1.847-.651 2.026v1.612a1.259 1.259 0 0 0-.306.818v1.225c0 .38.171.735.463.975a6.52 6.52 0 0 0 1.068 2.207v.999a.771.771 0 0 1-.401.677l-2.732 1.49a2.373 2.373 0 0 0-.259.164A8.789 8.789 0 0 1 .691 9.5z"/>
        </svg>
      );
    },

    'dashboard-experts': () => {
      const sizeFactor = 1;
      return (
        <svg
          version="1.1"
          enableBackground="new 0 0 28.771 13.98"
          viewBox="0 0 28.771 13.98"
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          x="0px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <path
            d="
            M21.988,12.099c0,1.039-0.775,1.882-1.731,1.882H8.735c-0.956,0-1.73-0.843-1.73-1.882l-0.006-0.239
            c0.254-0.738,1.009-0.827,1.71-1.124c0.771-0.326,1.624-0.705,2.393-1.021c0.219-0.06,0.438-0.12,0.657-0.18
            c0.262-0.182,0.519-0.783,0.658-1.082l0.312-0.075c-0.07-0.396-0.314-0.426-0.415-0.705c-0.04-0.421-0.08-0.842-0.12-1.262
            c0.002,0.02-0.286-0.053-0.323-0.077c-0.407-0.255-0.415-1.293-0.454-1.726c-0.018-0.197,0.256-0.358,0.18-0.721
            c-0.445-2.119,0.192-3.11,1.2-3.439c0.7-0.284,2.007-0.813,3.226-0.06l0.302,0.281l0.489,0.085c0.246,0.143,0.402,0.614,0.402,0.614
            c0.129,0.521,0.142,0.565,0.13,1.101c-0.005,0.206-0.123,1.16-0.091,1.453c0.026,0.241,0.087,0.261,0.182,0.459
            c0.165,0.347,0.109,0.824,0.046,1.173c-0.034,0.191-0.107,0.463-0.219,0.619c-0.123,0.172-0.368,0.173-0.477,0.373
            c-0.156,0.289-0.068,0.693-0.167,1.006C16.507,7.905,16.222,7.93,16.2,8.393c0.14,0.02,0.279,0.04,0.419,0.06
            c0.14,0.299,0.396,0.9,0.658,1.082c0.219,0.06,0.438,0.12,0.657,0.18c0.769,0.316,1.623,0.695,2.393,1.021
            c0.701,0.297,1.407,0.402,1.66,1.142L21.988,12.099z"
            className="filled"
          />
          <g>
            <defs>
              <path
                d="
                M22.738,12.138c0,1.413-0.853,2.559-1.904,2.559H8.16c-1.052,0-1.904-1.146-1.904-2.559L6.25,11.812
                c0.28-1.004,1.11-1.125,1.881-1.529c0.848-0.443,1.787-0.958,2.631-1.389c0.242-0.082,0.482-0.163,0.724-0.245
                c0.288-0.246,0.569-1.064,0.723-1.471l0.343-0.104c-0.078-0.537-0.346-0.579-0.457-0.959c-0.044-0.571-0.088-1.144-0.132-1.716
                c0.002,0.027-0.314-0.072-0.356-0.104c-0.447-0.349-0.456-1.76-0.5-2.348c-0.019-0.269,0.282-0.488,0.198-0.98
                c-0.49-2.883,0.211-4.23,1.32-4.678c0.77-0.388,2.208-1.106,3.548-0.082l0.333,0.383l0.539,0.115
                c0.27,0.193,0.441,0.836,0.441,0.836c0.142,0.709,0.156,0.769,0.143,1.497c-0.004,0.28-0.135,1.578-0.1,1.976
                c0.029,0.329,0.095,0.354,0.199,0.625c0.181,0.472,0.121,1.121,0.051,1.596c-0.038,0.26-0.119,0.629-0.241,0.842
                c-0.136,0.233-0.406,0.235-0.525,0.508c-0.171,0.393-0.075,0.943-0.183,1.367c-0.124,0.481-0.437,0.515-0.461,1.144
                c0.154,0.027,0.307,0.055,0.461,0.082c0.153,0.406,0.436,1.225,0.723,1.471c0.242,0.082,0.482,0.163,0.724,0.245
                c0.845,0.431,1.785,0.945,2.631,1.389c0.772,0.404,1.548,0.549,1.827,1.553L22.738,12.138z M28.771,0.721H0v13.976h28.771V0.721z"
                id={`${key}SVGID_1_`}
              />
            </defs>
            <clipPath id={`${key}SVGID_2_`}>
              <use overflow="visible" xlinkHref={`#${key}SVGID_1_`} />
            </clipPath>
            <path
              clipPath={`url(#${key}SVGID_2_)`}
              d="
              M28.771,11.313c0,0.87-0.66,1.575-1.475,1.575h-9.817
              c-0.814,0-1.476-0.705-1.476-1.575L16,11.113c0.216-0.618,0.859-0.692,1.457-0.941c0.656-0.272,1.384-0.59,2.038-0.854
              c0.187-0.051,0.374-0.101,0.561-0.15c0.224-0.152,0.441-0.655,0.561-0.905l0.266-0.063c-0.061-0.331-0.268-0.356-0.354-0.591
              c-0.034-0.352-0.068-0.704-0.103-1.056c0.002,0.017-0.244-0.044-0.276-0.064c-0.346-0.214-0.353-1.083-0.386-1.444
              c-0.016-0.165,0.218-0.301,0.152-0.604c-0.379-1.773,0.164-2.604,1.023-2.879c0.596-0.238,1.709-0.681,2.748-0.05l0.258,0.235
              l0.417,0.071c0.209,0.119,0.342,0.515,0.342,0.515c0.11,0.437,0.121,0.473,0.111,0.921c-0.004,0.173-0.104,0.971-0.077,1.216
              c0.022,0.202,0.073,0.219,0.154,0.385c0.141,0.29,0.093,0.689,0.039,0.981c-0.029,0.16-0.092,0.388-0.187,0.518
              c-0.104,0.145-0.313,0.146-0.406,0.313c-0.134,0.241-0.058,0.58-0.142,0.842c-0.096,0.295-0.339,0.316-0.357,0.703
              c0.119,0.017,0.238,0.033,0.357,0.051c0.119,0.25,0.337,0.753,0.561,0.905c0.187,0.05,0.373,0.1,0.561,0.15
              c0.654,0.265,1.382,0.582,2.038,0.854c0.598,0.249,1.199,0.338,1.415,0.956V11.313z"
              className="filled"
            />
            <path
              clipPath={`url(#${key}SVGID_2_)`}
              d="
              M12.771,11.313c0,0.87-0.66,1.575-1.475,1.575H1.479
              c-0.814,0-1.476-0.705-1.476-1.575L0,11.113c0.216-0.618,0.859-0.692,1.457-0.941c0.656-0.272,1.384-0.59,2.038-0.854
              c0.188-0.051,0.374-0.101,0.561-0.15c0.223-0.152,0.441-0.655,0.561-0.905l0.266-0.063C4.821,7.867,4.614,7.842,4.528,7.607
              C4.494,7.256,4.46,6.903,4.426,6.552c0.002,0.017-0.244-0.044-0.276-0.064C3.804,6.273,3.797,5.404,3.764,5.043
              C3.748,4.878,3.981,4.742,3.916,4.439C3.537,2.666,4.08,1.836,4.939,1.561c0.596-0.238,1.709-0.681,2.748-0.05l0.258,0.235
              l0.417,0.071c0.209,0.119,0.342,0.515,0.342,0.515c0.11,0.437,0.121,0.473,0.111,0.921C8.812,3.426,8.711,4.224,8.738,4.469
              c0.022,0.202,0.073,0.219,0.154,0.385c0.141,0.29,0.093,0.689,0.039,0.981C8.902,5.995,8.84,6.223,8.745,6.353
              C8.641,6.497,8.432,6.498,8.339,6.666c-0.134,0.241-0.058,0.58-0.142,0.842C8.102,7.803,7.858,7.824,7.84,8.211
              c0.119,0.017,0.238,0.033,0.357,0.051c0.119,0.25,0.337,0.753,0.561,0.905c0.187,0.05,0.373,0.1,0.561,0.15
              c0.654,0.265,1.382,0.582,2.038,0.854c0.598,0.249,1.199,0.338,1.415,0.956V11.313z"
              className="filled"
            />
          </g>
        </svg>
      );
    },

    'dashboard-account': () => {
      const sizeFactor = 1;
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 14 14"
          style={{ enableBackground: 'new 0 0 14 14' }}
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
        >
          <path
            className="filled"
            fillRule="evenodd"
            d="M12.934 5.773c-.02-.182-.233-.32-.417-.32-.595 0-1.123-.35-1.344-.891a1.45 1.45 0 0 1 .363-1.608.362.362 0 0 0 .039-.491 6.403 6.403 0 0 0-1.028-1.04.36.36 0 0 0-.493.04c-.387.429-1.082.589-1.619.364A1.444 1.444 0 0 1 7.558.415a.36.36 0 0 0-.319-.38 6.48 6.48 0 0 0-1.46-.004.363.363 0 0 0-.322.373 1.445 1.445 0 0 1-.888 1.387c-.531.217-1.221.059-1.608-.366a.36.36 0 0 0-.489-.042c-.39.306-.743.656-1.051 1.04a.364.364 0 0 0 .039.495c.452.41.598 1.062.363 1.622-.224.535-.778.88-1.412.88a.352.352 0 0 0-.375.319 6.53 6.53 0 0 0-.003 1.479c.02.184.24.32.426.32a1.421 1.421 0 0 1 1.335.891 1.447 1.447 0 0 1-.363 1.608.362.362 0 0 0-.039.49c.302.385.648.736 1.027 1.041a.36.36 0 0 0 .494-.039c.388-.43 1.083-.59 1.618-.365.56.235.913.802.878 1.412a.362.362 0 0 0 .319.38 6.443 6.443 0 0 0 1.46.004.363.363 0 0 0 .322-.373 1.446 1.446 0 0 1 .888-1.387c.534-.218 1.221-.058 1.608.367.128.14.34.157.49.041a6.496 6.496 0 0 0 1.05-1.04.362.362 0 0 0-.039-.495 1.443 1.443 0 0 1-.364-1.622 1.453 1.453 0 0 1 1.328-.882l.081.002a.36.36 0 0 0 .38-.319 6.53 6.53 0 0 0 .002-1.479zm-6.44 2.906a2.17 2.17 0 0 1 0-4.339 2.17 2.17 0 0 1 0 4.339z"
          />
        </svg>
      );
    },

    'dashboard-dailyAnalystRatings': () => {
      const sizeFactor = 1;
      return <svg xmlns="http://www.w3.org/2000/svg" 
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          enableBackground="new 0 0 24 19"
          viewBox="0 0 24 19"
          >
      <path className="filled" fillRule="evenodd" d="M21.54 16.029v2.01a.441.441 0 0 1-.447.44.45.45 0 0 1-.313-.127l-2.39-2.319h-5.278c-1.146 0-2.076-.917-2.076-2.048v-.535h6.315c2.397 0 4.347-1.924 4.347-4.289v-.26a2.057 2.057 0 0 1 1.793 2.029v3.055c0 1.09-.863 1.98-1.951 2.044zm-4.354-3.686H8.763L4.95 16.044a.717.717 0 0 1-.775.148.703.703 0 0 1-.437-.649v-3.206C2.002 12.235.625 10.814.625 9.076V4.2C.625 2.396 2.108.933 3.937.933h13.249c1.829 0 3.311 1.463 3.311 3.267v4.876c0 1.804-1.482 3.267-3.311 3.267z" />
    </svg>}
,
    'dashboard-topInsiderStocks': () => {
      const sizeFactor = 1;
      return <svg xmlns="http://www.w3.org/2000/svg" 
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
          enableBackground="new 0 0 20 20"
          viewBox="0 0 20 20"
          >
      <path className="filled" fillRule="evenodd" d="M19.686 6.987a.53.53 0 0 1-.484.32h-3.295v11.99a.534.534 0 0 1-.53.537h-5.086a7.37 7.37 0 0 0 3.54-6.311c0-4.052-3.254-7.35-7.253-7.35-.296 0-.587.021-.874.055L12.176.71a.529.529 0 0 1 .786 0l6.633 5.699c.14.157.176.384.091.578zm-6.965 6.528c0 3.452-2.761 6.25-6.168 6.25S.384 16.967.384 13.515s2.762-6.25 6.169-6.25c3.407 0 6.168 2.798 6.168 6.25zm-7.72-2.59a1.949 1.949 0 0 0-.378 1.169c0 .33.071.62.212.869.141.247.324.441.55.578.226.139.54.278.942.419.446.16.735.292.864.396.13.104.194.258.194.46a.704.704 0 0 1-.194.5c-.129.138-.333.206-.608.206-.588 0-.934-.322-1.039-.966l-1.129.298c.228 1.025.819 1.588 1.775 1.688v.879h.73v-.884c.573-.061 1.011-.264 1.315-.609.303-.344.455-.774.455-1.291 0-.336-.073-.63-.22-.882a1.618 1.618 0 0 0-.591-.591 6.742 6.742 0 0 0-1.021-.455c-.394-.147-.653-.27-.772-.369-.121-.1-.181-.243-.181-.429 0-.182.063-.338.188-.466a.658.658 0 0 1 .496-.192c.411 0 .714.287.91.86l1.006-.418c-.24-.837-.769-1.304-1.585-1.404v-.682h-.73v.682c-.541.077-.937.289-1.189.634z" />
    </svg>}
,
    'dashboard-trendingStocks': () => {
      const sizeFactor = 1;
      return <svg xmlns="http://www.w3.org/2000/svg" 
        width={width * sizeFactor}
        height={isShittyBrowser ? width * sizeFactor : undefined}
        enableBackground="new 0 0 22 19"
        viewBox="0 0 22 19"
          >
      <path className="filled" fillRule="evenodd" d="M20.677.753H1.68c-.633 0-1.055.368-1.055.921s.422.921 1.055.921v11.1c0 .553.422.921 1.056.921h3.588L4.952 16.92c-.317.46-.106 1.013.422 1.29.528.184 1.161.092 1.372-.369l1.9-3.225h5.065l1.794 3.133c.212.461.95.645 1.372.461.528-.185.739-.738.528-1.198l-1.372-2.396h3.588c.634 0 1.056-.368 1.056-.921v-11.1c.633 0 1.055-.368 1.055-.921S21.31.753 20.677.753zm-2.111 7.414L17.194 6.97l-4.221 3.685a1.127 1.127 0 0 1-1.478 0L9.068 8.536l-3.483 3.04a1.124 1.124 0 0 1-1.477 0c-.422-.368-.422-.921 0-1.29l4.221-3.685a1.125 1.125 0 0 1 1.478 0l2.427 2.027 3.483-3.04-1.372-1.106h4.221v3.685z" />
    </svg>}
,
    'dashboard-topAnalystStocks': () => {
      const sizeFactor = 1;
      return <svg xmlns="http://www.w3.org/2000/svg" 
        width={width * sizeFactor}
        height={isShittyBrowser ? width * sizeFactor : undefined}
        enableBackground="new 0 0 20 13"
        viewBox="0 0 20 13"
          >
      <path className="filled" fillRule="evenodd" d="M8.33 9.465a.589.589 0 0 1 .063-.219L12.95.47a.59.59 0 0 1 .713-.286.59.59 0 0 1 .387.665l-1.775 9.733a.571.571 0 0 1-.084.209 2.06 2.06 0 0 1-2.68 1.53 2.042 2.042 0 0 1-1.181-1.045 2.059 2.059 0 0 1 0-1.811zM10.178.822a.589.589 0 0 1 0 1.178c-4.685 0-8.496 3.823-8.496 8.522a.59.59 0 0 1-1.177 0c0-5.349 4.34-9.7 9.673-9.7zm6.079 2.154a9.674 9.674 0 0 1 3.594 7.546.589.589 0 1 1-1.176 0 8.498 8.498 0 0 0-3.158-6.629.59.59 0 0 1 .74-.917z" />
    </svg>}
,
    'dashboard-screener': () => {
      const sizeFactor = 1.1;
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 22 22"
          style={{ enableBackground: 'new 0 0 22 22' }}
          width={width * sizeFactor}
          height={isShittyBrowser ? width * sizeFactor : undefined}
        >
          <g>
            <g>
              <path
                className="filled"
                style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                d="M3.5,18.8V1.5h-1V3H1.2v1h1.3v2.5H1.2v1h1.3v2.5H1.2c0,0.3,0,0.7,0,1c0.4,0,0.9,0,1.3,0v2.5H1.2v1
              c0.4,0,0.9,0,1.3,0v2.5H1.2v1h1.3v1.9h1.9v1.3h1v-1.3h2.5v1.3h1v-1.3h2.5v1.3c0.3,0,0.7,0,1,0c0-0.4,0-0.9,0-1.3h2.5v1.3h1v-1.3
              h2.5v1.3h1v-1.3h1.4v-1H3.5z M17.5,17.2h1.9V7.4h1.2c-0.7-1-1.4-2-2.1-2.9V4.4c-0.7,1-1.4,1.9-2,2.9l-0.1,0.1h1.2V17.2z
              M12.1,17.2H14v-6.7h1.1v0c-0.7-1-1.3-1.9-2-2.9c0,0-0.1,0-0.1-0.1c-0.7,1-1.3,1.9-2,2.9L11,10.4v0h1.1V17.2z M6.9,17.2h1.9v-3.5
              h1.1v0c-0.7-1-1.3-1.9-2-2.9c0,0-0.1,0-0.1-0.1c-0.7,1-1.3,2-2,2.9l-0.1,0.1h1.2V17.2z"
              />
            </g>
          </g>
        </svg>
      );
    },

    'dashboard-contact': () => (
      <img alt="" src={`/assets/img/icon-${id}-${process.env.THEME}.png`} />
    ),

    'dashboard-faq': () => (
      <img alt="" src={`/assets/img/icon-${id}-${process.env.THEME}.png`} />
    ),
    'dashboard-contactus': () => (
      <img alt="" className="svg" src={`/assets/img/contact-before-${process.env.THEME}.png`} width={width} height={isShittyBrowser ? width : undefined} />
    ),
    'dashboard-community': () => {
      const sizeFactor = .8;
      return (
        <svg xmlns="http://www.w3.org/2000/svg"
          style={{ enableBackground: 'new 0 0 24.18 24.188' }}
          id="Layer_1"
          width={width * sizeFactor}
          height={width * sizeFactor}
          viewBox="0 0 24.18 24.188">
          <path
            className="filled"
            id="_-e-Community_icon" data-name="-e-Community icon" d="M1390.46,362.9a2.639,2.639,0,0,0-2.32,3.9l-4.33,4.325a3.474,3.474,0,0,0-2.15-.743,3.53,3.53,0,0,0-2.16.742l-3.31-3.587a2.471,2.471,0,0,0,.36-1.29,2.58,2.58,0,1,0-1.26,2.193l3.59,3.307a3.506,3.506,0,0,0,0,4.309l-5.57,5.573a2.636,2.636,0,1,0,.87,1.953,2.67,2.67,0,0,0-.32-1.259l5.64-5.645a3.535,3.535,0,0,0,1.72.713v4.47a2.64,2.64,0,1,0,.88,0v-4.47a3.535,3.535,0,0,0,1.72-.713l3.38,3.509a2.044,2.044,0,0,0-.3,1.066,2.11,2.11,0,1,0,2.11-2.112,2.052,2.052,0,0,0-1.06.3l-3.51-3.384a3.508,3.508,0,0,0,0-4.31l4.25-4.253A2.638,2.638,0,1,0,1390.46,362.9Zm-17.74,3.345a1.28,1.28,0,1,1,1.28,1.278A1.278,1.278,0,0,1,1372.72,366.245Zm-1.18,19.095a1.76,1.76,0,1,1,1.76-1.76A1.763,1.763,0,0,1,1371.54,385.34Zm11.88-.88a1.76,1.76,0,1,1-1.76-1.76A1.763,1.763,0,0,1,1383.42,384.46Zm-1.76-7.92a2.64,2.64,0,1,1,2.64-2.64A2.64,2.64,0,0,1,1381.66,376.54Zm8.41,4.711a1.055,1.055,0,1,1-1.06-1.056A1.062,1.062,0,0,1,1390.07,381.251Zm0.39-13.951a1.76,1.76,0,1,1,1.76-1.76A1.763,1.763,0,0,1,1390.46,367.3Zm-0.86-3.3a2.4,2.4,0,0,0-2.4,2.4,2.335,2.335,0,0,0,.29,1.144l-3.93,3.931a3.183,3.183,0,0,0-3.92,0l-3.01-3.261a2.346,2.346,0,1,0-.82.821l3.26,3.006a3.2,3.2,0,0,0,0,3.918l-5.06,5.066a2.4,2.4,0,1,0,.79,1.775,2.445,2.445,0,0,0-.29-1.144l5.13-5.132a3.186,3.186,0,0,0,1.56.648v4.064a2.4,2.4,0,1,0,.8,0v-4.064a3.186,3.186,0,0,0,1.56-.648l3.08,3.189a1.9,1.9,0,0,0-.28.97,1.941,1.941,0,1,0,.95-1.648l-3.19-3.076a3.162,3.162,0,0,0,0-3.918l3.87-3.866A2.4,2.4,0,1,0,1389.6,364Zm-16.13,3.041a1.165,1.165,0,1,1,1.16,1.162A1.162,1.162,0,0,1,1373.47,367.041ZM1372.4,384.4a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,1372.4,384.4Zm10.8-.8a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,1383.2,383.6Zm-1.6-7.2a2.4,2.4,0,1,1,2.4-2.4A2.405,2.405,0,0,1,1381.6,376.4Zm7.64,4.283a0.96,0.96,0,1,1-.96-0.96A0.961,0.961,0,0,1,1389.24,380.683ZM1389.6,368a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,1389.6,368Z" transform="translate(-1368.91 -362.906)" />
        </svg>
      )
    }
  }[id] || (() => null))());
