import React from 'react';
import BarSlider from './BarSlider.react';
import { format } from './AllocChart/src/utils';
import getBlock, { defaultBlocks } from '../../../../BetaColors/BetaColors';
import { brandName } from '../../../../../common/config';

const truthyOrZero = x => x || x === 0;

const RiskContainer = ({
  blocks = defaultBlocks,
  blockMargin = 1,
  labels: { before, after } = { before: 0, after: 100 },
  isOrange,
  range: [x, y] = [0, 100],
  avg = 1.22,
  value = 2
}) => {
  const getBlockIndex = value => getBlock(value, { blocks }).index;
  const isSelected = (value, blockNum) => blockNum === getBlockIndex(value);
  const blockWidth = Math.round(100 * (100 / blocks.length)) / 100;
  const blockWidthWithMargin = `calc(${blockWidth}% - ${2 * blockMargin}px)`;
  const fmt = num => format.percent(blockWidth * getBlockIndex(num), 2, 1);
  const triangleLeft = num => `calc(${fmt(num)} + ${blockWidth / 2}%)`;

  return (
    <div className="portfolio-risk-container">
      <div className="risk-bar-container">
        <div className="risk-title">{before}</div>
        <div className="blocks">
          {blocks.map((backgroundColor, i) => (
            <div
              className="block"
              key={i}
              style={{
                backgroundColor,
                width: blockWidthWithMargin,
                margin: `0 ${blockMargin}px`,
                opacity: isSelected(avg, i) || isSelected(value, i) ? 1 : 0.3
              }}
            />
          ))}
          {truthyOrZero(avg) ? (
            <BarSlider
              value={avg}
              left={triangleLeft(avg)}
              text={`Avg. ${brandName} Portfolio`}
            />
          ) : null}
          {truthyOrZero(value) ? (
            <BarSlider
              value={value}
              text="My Portfolio"
              inverted
              left={triangleLeft(value)}
            />
          ) : null}
        </div>

        <div className="risk-title">{after}</div>
      </div>
    </div>
  );
};

export default RiskContainer;
