/* eslint-disable no-undef */
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Component from 'react-pure-render/component';

import Alerts from '../tr-dashboard-alerts';
import PortfolioItemSelector from '../PortfolioItemSelector';
import { EmailAlertsCell } from '../tr-dashboard-holdings-section/cells/EmailAlertsCell.react.tsx';
import { isNasdaq } from '../../common/config';

export default class PortfolioItemAlerts extends Component {
  static propTypes = {
    enums: PropTypes.object,
    item: PropTypes.object,
    msg: PropTypes.object,
    onClickAlert: PropTypes.func
  };

  header = () => {
    const { enums, item } = this.props;

    return item.get('type') === 'stock' ? (
      <div>
        <h1 className="ticker">
          <PortfolioItemSelector item={item} msg={enums} type="ticker" />
        </h1>
        <h2>
          <PortfolioItemSelector item={item} msg={enums} type="name" />
        </h2>
      </div>
    ) : (
      <div>
        <h1>
          <PortfolioItemSelector item={item} msg={enums} type="name" />
        </h1>
        <h2>
          <PortfolioItemSelector item={item} msg={enums} type="firm" />
        </h2>
      </div>
    );
  };


  render() {
    const { enums, item, msg, onClickAlert } = this.props;
    
    return (
      <section className="portfolio-item">
        <header>
          <div className="details">
            <PortfolioItemSelector
              item={item}
              msg={enums}
              type="price-details"
            />
          </div>
          {this.header()}
        </header>
        <section className="section-box alerts">
          <header className={isNasdaq ? "nasdaq" : ""}>
            {isNasdaq ? 
              <div className="email-alerts">
                Email Alerts{' '}
                <EmailAlertsCell onClickAlert={onClickAlert} stock={item} />
              </div> : null
            }

            <h1>
              <FormattedMessage
                defaultMessage={msg.alertsTitle}
                id={msg.alertsTitle}
                values={{
                  alertsIndicator: (
                    <PortfolioItemSelector
                      item={item}
                      msg={enums}
                      type={'alerts'}
                    />
                  )
                }}
              />
            </h1>
          </header>
          {item.get('alerts').size > 0 ? (
            <Alerts alerts={item.get('alerts')} enums={enums} />
          ) : (
            <div className="no-alerts">{msg.noAlerts}</div>
          )}
        </section>
      </section>
    );
  }
}
