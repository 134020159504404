import * as React from 'react';
import { Link } from 'react-router';
import { Loading } from './Loading.react';
import Clickable from 'tipranks-clickable';
import { InfoTooltip } from 'sp/browser/InfoTooltip';

// apiNames are the dependencies from ApiDependency if required.
export const AssetBox = (cls, apiNames) =>
  class AssetBox extends React.PureComponent<{}> {
    body: any;
    head: any;
    getBody() {
      const body = () => this.body();

      const BodyWithLoader: React.ReactNode = apiNames
        ? Loading(...apiNames)(body)
        : (body as any);

      return { BodyWithLoader };
    }

    getClass() {
      return cls;
    }

    headerSideLink(msg, to, onClick = () => null) {
      return (
        <span className="asset-box-header-link">
          <Clickable target="_blank" to={to} onClick={onClick}>
            {msg}
          </Clickable>
        </span>
      );
    }

    headerTooltip(msg) {
      return <InfoTooltip msg={msg} />;
    }

    render() {
      const wrapper = 'asset-box ' + this.getClass();
      const BodyWithLoader = this.getBody().BodyWithLoader as any;
      if (!this.head || !this.body)
        throw new TypeError(
          'Asset Box extender must implement a head and body methods'
        );
      return (
        <div className={wrapper}>
          <div className={'asset-box-title'}>{this.head()}</div>
          <div className={'asset-box-content'}>
            <BodyWithLoader />
          </div>
        </div>
      );
    }
  };

export default AssetBox;
