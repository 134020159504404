import assetIds from './assetIds';
import { disablePortfolioImport } from '../../../../../common/config';

export default {
  allocChart: {
    title: 'Asset Allocation',
    [assetIds.assetId_stock]: 'Stocks',
    [assetIds.assetId_etf]: 'ETFs',
    [assetIds.assetId_fund]: 'Funds',
    consumergoods: 'Consumer Goods',
    basicmaterials: 'Basic Materials',
    basicMaterials: 'Basic Materials',
    industrialgoods: 'Industrial Goods',
    allAss: 'My Assets',
    other: 'Other',
    default: 'N/A',
    noData: 'Your Holdings Chart is empty. {addButton} to start.'
  },

  holdingsChart: {
    stock: {
      title: 'Stock Distribution By Sector',
      tooltip:
        'View the allocation of your stocks by sector, and view your major holdings in each sector.',
      pieInner: 'Stock Distribution',
      noData: 'Currently, there are no stocks in your holdings'
    },

    etf: {
      title: 'ETF Distribution By Category',
      tooltip:
        'View the allocation of your ETFs by category, and view your major holdings in each category',
      pieInner: 'ETF Distribution',
      noData: 'Currently, there are no ETFs in your holdings'
    },

    fund: {
      title: 'Fund Distribution By Category',
      tooltip:
        'View the allocation of your Funds by category, and view your major holdings in each category',
      pieInner: 'Fund Distribution',
      noData: 'Currently, there are no funds in your holdings'
    }
  },

  warnings: {
    userWarnings: {
      warnings: {
        missingShares:
          `Enter amount of shares for each one of your holdings to have them calculated into your portfolio analysis ${!disablePortfolioImport ? '{or} {importLink}' : ''}`,
      },
      warning: 'Warning',
      values: {
        importLink: 'Import your existing portfolio',
        or: 'or'
      }
    }
  }
};
