import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { StockPriceChangeList } from '../StockPriceChangeList/StockPriceChangeList';

export class DailyGainersAndLosersSection extends Component {
  static propTypes = {
    dailyGainers: PropTypes.object,
    dailyLosers: PropTypes.object,
    isMarketOpened: PropTypes.bool,
    max: PropTypes.number,
    msg: PropTypes.object
  };

  render() {
    const { dailyGainers, dailyLosers, isMarketOpened, max, msg } = this.props;

    return (
      <section className="tr-dashboard-overview-daily-gainers-and-losers">
        <section>
          <header className="tr-dashboard-overview-header">
            <h1>{msg.dailyGainers}</h1>
          </header>
          <main>
            <StockPriceChangeList
              items={dailyGainers}
              max={max}
              noHoldingsMsg={msg.noHoldings}
            />
          </main>
        </section>
        <section>
          <header className="tr-dashboard-overview-header">
            <h1>{msg.dailyLosers}</h1>
          </header>
          <main>
            <StockPriceChangeList
              isLosers
              items={dailyLosers}
              max={max}
              noHoldingsMsg={msg.noHoldings}
            />
          </main>
        </section>
        {!isMarketOpened && (
          <div className="closed-market">{msg.closedMarket}</div>
        )}
      </section>
    );
  }
}
