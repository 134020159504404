import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { popupRegister, absImgSrc } from '../../common/lib/utils.ts';
import { selectIsLoggedIn, selectIsTemporaryLoggedIn, hasPayed } from '../../common/auth/selectors.ts';

import { selectPortfolioItems, selectTheme } from '../dashboard/selectors';

import defaultMsg from './en';
import report from './analytics';

export class TrDashboardSavePortfolios extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  static defaultProps = {
    msg: defaultMsg
  };
  isAnExemptPage() {
    const parts = window.location.pathname.split('/');
    const routeName = parts[parts.length - 1];
    const exemptRoutes = ['welcome', 'plans'];
    return exemptRoutes.indexOf(routeName) !== -1;
  }
  render() {
    const { actions, auth, dashboard, msg } = this.props;

    const isTemporaryLoggedIn = selectIsTemporaryLoggedIn(auth);
    const isLoggedIn = selectIsLoggedIn(auth);
    const itemsTotal = selectPortfolioItems(dashboard).size;
    const theme = selectTheme(dashboard);
    return (
      isLoggedIn &&
      isTemporaryLoggedIn && 
      !hasPayed(auth) && 
      itemsTotal > 0 &&
      !this.isAnExemptPage() && (
        <div className={classNames('tr-dashboard-save-portfolios')}>
          <div className="save-portfolio-inner">
            <img src={absImgSrc('save.png')} />
            <span className="desc">
              <span className="desc1">{msg.desc1}</span>
              <span className="desc2">{msg.desc2}</span>
            </span>
            <button
              onClick={() => {
                report('clicked');
                popupRegister();
              }}
            >
              {msg.savePortfolio}
            </button>
          </div>
        </div>
      )
    );
  }
}
