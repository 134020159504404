import { PerfGain } from '../periodic-graph/PeriodicPerformanceGraph';
import './PortfolioPerformanceBoxContainer.styl';

import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import {
  Dashboard,
  ReduxProps,
  ComponentStateKeys,
} from '../../../common/types';
import { PortfolioPerformanceBox, Props, ComponentData } from './PortfolioPerformanceBox';
export { ComponentData }
import { selectPortfolioPerformancePerMonth } from './selectors';
import { getMyPortfolioFromData } from './utils';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

/**
 * Used to recieve PerformancePeriodicGraphData from outside, but now does conversion from data to PerformancePeriodicGraphData here (like it should).
 */
@connect(({ dashboard }) => ({ dashboard }))
export class PortfolioPerformanceBoxContainer extends React.Component<
Partial<Props> & {
  mediaQuery: MediaQuery;
  componentData?: ComponentData;
}
> {
  componentDidMount() { }
  shouldComponentUpdate(nextProps, nextState) {
    const nextDashboard = (nextProps as ReduxProps).dashboard;
    const dashboard = (this.props as ReduxProps).dashboard;
    const {
      mediaQuery: currMediaQuery,
      componentData: currComponentData = getData(dashboard),
    } = this.props; const {
      mediaQuery: nextMediaQuery,
      componentData: nextComponentData = getData(nextDashboard),
    } = nextProps;

    return !(isEqual(currMediaQuery, nextMediaQuery) && isEqual(currComponentData, nextComponentData));
  }
  render() {
    const dashboard = (this.props as ReduxProps).dashboard;
    const { mediaQuery, componentData = getData(dashboard) } = this.props;
    const {
      myPortfolioData,
      bestPortfolioData,
      avgPortfolioData,
      snpPortfolioData,
    } = componentData;

    const yes = '1600';
    const itemNum = mediaQuery.get(yes) ? 12 : mediaQuery.get('768') ? 7 : 3;
    const xAxisLabelTextAlign = mediaQuery.get(yes) ? 'center' : 'right';
    // TODO we're passing container specific props with `{...this.props}` to the dumb component!!
    return (
      <PortfolioPerformanceBox
        className="portfolioPerformanceBoxContained"
        itemNum={itemNum}
        xAxisLabelTextAlign={xAxisLabelTextAlign}
        myPortfolioData={myPortfolioData}
        bestPortfolioData={bestPortfolioData}
        avgPortfolioData={avgPortfolioData}
        snpPortfolioData={snpPortfolioData}
        {...this.props}
      />
    );
  }
}
export function getData(dashboard: Dashboard) {
  return selectPortfolioPerformancePerMonth(dashboard, null);
}
export const apiNames: ComponentStateKeys[] = ['portfolioMonthlyGain'];
