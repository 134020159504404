import { StockType, PortfolioRecord } from '../../common/types';
import { groupBy, mapValues, get, maxBy, sumBy } from 'lodash';
import * as React from 'react';
import * as moment from 'moment';
import { selectPortfolioItems } from './selectors';
import {
  PortfolioItemRecord,
  PortfolioItemRecord_NotImmutable,
} from 'sp/common/types';
import { Map } from 'immutable';
import { List } from 'immutable';
import { Iterable } from 'immutable';
import { BetterRecord } from 'sp/common/immutableStuff';

export class PortfolioManager {
  memoization?: WeightedHoldings;
  _holdings: List<PortfolioItemRecord>;
  portfolioCashValue: () => number;
  constructor(
    items: List<PortfolioItemRecord>,
    portfolioCashValue: () => number,
  ) {
    this.portfolioCashValue = portfolioCashValue;
    this._holdings = items;
  }
  weighted() {
    if (this.memoization) return this.memoization;
    this.memoization = new WeightedHoldings(
      this._holdings,
      this.portfolioCashValue,
    );
    return this.memoization;
  }
}

type fuckme = Iterable<number, PortfolioItemRecord>;
type awesome = PortfolioItemRecord[];

export class WeightedHoldings {
  _weightedHoldings: WeightedHolding[];
  constructor(
    holdings: fuckme,
    portfolioCashValue: () => number,
    options: { userMode?: keyof typeof PortfolioMode } = {},
  ) {
    const stockPicker =
      options.userMode === PortfolioMode.StockPicker ||
      holdings.every(x => x.get('sharesTotal') === 0);

    if (!stockPicker) {
      holdings = holdings.filter(x => x.get('sharesTotal') > 0);
    }

    const betterHoldingsIterable: awesome = [];
    for (let j = 0; j < holdings.size; j++) {
      const holding = holdings.get(j);
      betterHoldingsIterable.push(holding);
    }

    const getWeights = getWeightGetter(
      betterHoldingsIterable,
      stockPicker,
      portfolioCashValue,
    );
    this._weightedHoldings = [];
    for (let j = 0; j < holdings.size; j++) {
      const holding = holdings.get(j);
      this._weightedHoldings.push(
        new WeightedHolding(getWeights(holding), holding),
      );
    }
  }

  /** Calculate the beta of the stocks, a portfolio's beta is the weighted average
   *
   * Our calculation is: Σ(holding*weight) where weight is the percentage of the holding in the portfolio.
   *
   * Beta is calculated using regression analysis, and you can think of beta as the tendency of a security's returns
   * to respond to swings in the market. A beta of 1 indicates that the security's price will move with the market.
   * A beta of less than 1 means that the security will be less volatile than the market.
   * A beta of greater than 1 indicates that the security's price will be more volatile than the market.
   * For example, if a stock's beta is 1.2, it's theoretically 20% more volatile than the market.
   * http://www.investopedia.com/terms/b/beta.asp
   */
  beta() {
    // for stocks without beta take 1 for not affecting the average
    if (this._weightedHoldings.length === 0) return null;
    return this._weightedHoldings.reduce(
      (acc, item) => acc + item.beta * item.betaPercent,
      0,
    );
  }

  /**
   * Calculate the most volatile holdings based on the stock's beta (see doc for beta).
   * this returns the most volatile stocks and ETFs between all the stocks in the portfolio.
   *
   * @param howManyHoldings
   * @param stockType - whether or not we want the most volatile stocks, etfs, bonds or all. Valid values to pass here
   * are "all" (default), "stocks", "etf", "bold"
   * @return {[WeightedHolding]} the most volatile holdings, in a
   */
  mostVolatile(howManyHoldings = 100, stockType = '') {
    const stocks = this.holdings(); // once was this.stocks(), until TIPRANKS-15495
    const filteredHoldings = stockType
      ? stocks.filter(x => x.type === stockType)
      : stocks.slice(0);

    const sortedHoldings = filteredHoldings
      .filter(x => x.beta > 0)
      .sort((x, y) => y.beta - x.beta)
      .slice(0, howManyHoldings);

    return sortedHoldings;
  }

  /**
   * Returns the stocks with the highest dividend yield.
   * A financial ratio that indicates how much a company pays out in dividends each year relative to its share price.
   * Dividend yield is represented as a percentage and can be calculated by dividing the dollar value of dividends paid
   * in a given year per share of stock held by the dollar value of one share of stock.
   * The formula for calculating dividend yield may be represented as follows: AnnualDividendsPerShare/PricePerShare
   * http://www.investopedia.com/terms/d/dividendyield.asp
   * @param howMany
   * @returns {[WeightedHolding]} the top dividend stocks
   */
  topDividendStocks(howMany = 100) {
    return this._weightedHoldings
      .filter(x => x.yield) // has a yield
      .sort((x, y) => y.yield - x.yield)
      .slice(0, howMany);
  }

  /**
   * Returns the stocks with the closest dividend date.
   * @param howMany
   * @returns {[WeightedHolding]} the next dividend stocks
   */
  nextDividendStocks(howMany = 100) {
    const stocks = this.stocks();
    return stocks
      .filter(x => x.dividendDate) // has a yield
      .sort(
        (x, y) =>
          new Date(x.dividendDate!).getTime() -
          new Date(y.dividendDate!).getTime(),
    )
      .slice(0, howMany);
  }

  /**
   * Returns the dividend yield, in percent, for the portfolio.
   * See the documentation for topDividendStocks for more information about what dividend yield means.
   *
   */
  dividendYield() {
    return this._weightedHoldings.reduce((p, c) => p + c.yield * c.percent, 0);
  }

  /**
   * returns the dividend yield in value, for the portfolio.
   */
  dividendYieldValue() {
    return this.dividendYield() * (this.totalValue() || 0);
  }
  /**
   * @returns {[WeightedHolding]} All the stocks in the holdings
   */
  stocks() {
    return this._weightedHoldings.filter(x => x.type === 'stock');
  }
  /**
   * @returns {[WeightedHolding]} All the etfs in the holdings
   */
  etfs() {
    return this._weightedHoldings.filter(x => x.type === 'etf');
  }

  /**
   * if fieldValue passed, returns all the holdings for a specific field that match fieldValue
   * else object containing holdings grouped by fieldName
   */
  byField(fieldName: keyof WeightedHolding /*, fieldValue?*/) {
    // return fieldValue
    //   ? this._weightedHoldings.filter(x => x[fieldName] === fieldValue)
    // : groupBy(this._weightedHoldings, x => x[fieldName]);
    return groupBy<WeightedHolding>(this._weightedHoldings, x => x[fieldName]);
  }

  /**
   * @param sector
   */
  bySector() {
    return this.byField('sector');
  }

  byType() {
    return this.byField('type');
  }

  byTypeAndSector() {
    const typeValues = this.byType();
    return mapValues(typeValues, byType => {
      const withSector = byType.filter(d => d.sector)
      const withoutSector = byType.filter(d => !d.sector)
      const data = groupBy(withSector, 'sector');
      if (withoutSector.length) data.other/*SectorText*/ = (data.other || []).concat(withoutSector);
      return data;
    });
  }

  /**
   * * @returns {[WeightedHolding]} All holdings in my portfolio
   */
  holdings() {
    return this._weightedHoldings.slice(0);
  }

  /**
   * returns the total value, in dollars for this portfolio or `null` if the portfolio has no holdings with value
   * and it is in StockPicker mode.
   * @returns {Number|null} the portfolio value of this portfolio or null if such a value is not applicable
   */
  totalValue() {
    return (
      this._weightedHoldings.reduce((p, c) => p + c.sharesValue, 0) || null
    );
  }

  /**
   * Calculates the Price-Earnings-Ratio (P/E) ratio for this portfolio. It is calculated as a weighted average of
   * the price/earnings ratios of all its stocks.
   *
   * The Price-to-Earnings Ratio or P/E ratio is a ratio for valuing a company that measures its current share price
   * relative to its per-share earnings. The PE ratio can be calculated as MarketValuePerShare/EarningsPerShare
   * http://www.investopedia.com/terms/p/price-earningsratio.asp
   * @returns {Number} the P/E ratio for this portfolio
   */
  pe() {
    return this._weightedHoldings.reduce((p, c) => p + c.pe * c.percent, 0);
  }

  mainSector() {
    const sectorsList = this.bySector(); // TODO iamsorry.jpeg

    return maxBy(Object.keys(sectorsList), key =>
      sumBy(sectorsList[key], x => x.sharesValue),
    );
  }
}

export type WeightedHoldingType = {
  percent: number;
  percentNoCash: number;
  typePercent: number;
  sectorPercent: number;
  percentAsStocks: number;
  betaPercent: number;
  beta: number;
  isSecondaryTicker: boolean;
  name: string;
  sector: number;
  dividendDate: Date | null;
  yield: number;
  sharesValue: number;
  ticker: string;
  type: StockType;
  pe: number;
  exchangeRate: number;
};

export class WeightedHolding {
  public percent: number;
  public percentNoCash: number;
  public typePercent: number;
  public sectorPercent: number;
  public percentAsStocks: number;
  public betaPercent: number;
  public beta: number;
  public isSecondaryTicker: boolean;
  public name: string;
  public sector: any;
  public dividendDate: Date | null;
  public yield: number;
  public sharesValue: number;
  public ticker: string;
  public type: StockType;
  public pe: number;
  public exchangeRate: number;

  constructor(
    weight: weightGetterResult,
    holding: PortfolioItemRecord,
  ) {
    const stock = holding.get('stock');
    const beta = Number(stock.getIn(['price', 'beta'], 0));
    const name = stock.get('name');
    const sector = stock.get('sector');
    const dividendDate = stock.get('dividendDate');
    const stockYield = stock.get('yield');
    const type = stock.get('type');
    const ticker = stock.get('ticker').toUpperCase();
    const pe = stock.get('pe');
    const exchangeRate = stock.get('exchangeRate');

    this.percent = weight.percent || 0;
    this.percentNoCash = weight.percentNoCash || 0;
    this.typePercent = weight.typePercent || 0;
    this.sectorPercent = weight.sectorPercent || 0;
    this.percentAsStocks = weight.percentAsStocks || 0;
    this.betaPercent = weight.betaPercent || 0;
    this.beta = beta || 0;
    this.type = type === 'secondaryticker' ? 'stock' : type;
    this.isSecondaryTicker = type === 'secondaryticker';
    this.name = name;
    this.sector = sector;
    this.dividendDate = dividendDate
      ? moment(dividendDate, 'YYYY-MM-DD').toDate()
      : null;
    this.yield = Number(stockYield) || 0;
    this.sharesValue = holding.get('sharesValue') || 0;
    this.ticker = ticker;
    this.pe = pe;
    this.exchangeRate = exchangeRate || 1;
  }
}

// TODO convert to enum
export const PortfolioMode = {
  // A stock picker is a user who is picking stocks but does not have an amount of stocks per holding specified.
  StockPicker: 'StockPicker',
  // An investor is a more advanced user who entered the amount of stocks por a portfolio item
  Investor: 'Investor',
};

const sumSharesValues = (arr: awesome): number =>
  arr ? arr.reduce((p, c) => p + c.get('sharesValue'), 0) : 0;
const numStocks = (arr: awesome): number => (arr ? arr.length : 0);
/**
 * get each holding's weight by percent of total portfolio, type (stock, etf, etc.) or sector (within the type)
 */
function getWeightGetter(
  holdings: awesome,
  isStockPicker: boolean,
  portfolioCashValue: () => number,
) {
  const getTotal = isStockPicker ? numStocks : sumSharesValues;

  const totalPortfolioValueNoCash = getTotal(holdings);
  const cashValue = portfolioCashValue();
  const totalPortfolioValue =
    totalPortfolioValueNoCash +
    (isStockPicker && cashValue > 0 ? 1 : cashValue);
  const holdingsByType = groupBy<PortfolioItemRecord>(holdings, item =>
    item.get('stock').get('type'),
  );
  // TODO find out what this was meant to fix, and where to refactor this to. - related to major holdings, of course.
  if (holdingsByType.stock || holdingsByType.secondaryticker) {
    holdingsByType.stock = (holdingsByType.stock || []).concat(
      holdingsByType.secondaryticker || [],
    );
    holdingsByType.secondaryticker = holdingsByType.stock;
  }

  const totalPortfolioValueStocksOnly = getTotal(holdingsByType.stock);

  // group by types
  const typePortfolioValues = mapValues(holdingsByType, getTotal);

  const sectorPortfolioValues = mapValues(holdingsByType, (holdings: _.List<BetterRecord<PortfolioItemRecord_NotImmutable>>) => {
    // group by and calculate sectors within each type
    const group = groupBy(holdings, x => x.getIn(['stock', 'sector']));
    const values = mapValues(group, getTotal);
    return values;
  });

  const holdingsWithBeta = holdings.filter(
    x => x.getIn(['stock', 'price', 'beta'], 0) != 0,
  );
  const betaPortfolioValue = getTotal(holdingsWithBeta);

  const weightGetter = (item: PortfolioItemRecord) => {
    const value = isStockPicker ? 1 : item.get('sharesValue');
    const stockType = item.get('stock').get('type');
    const stockSector = item.get('stock').get('sector');
    const sectorRatio = sectorPortfolioValues[stockType][stockSector];
    const ret = {
      percent: value / totalPortfolioValue,
      percentNoCash: value / totalPortfolioValueNoCash,
      percentAsStocks: value / totalPortfolioValueStocksOnly,
      typePercent: value / typePortfolioValues[item.get('stock').get('type')],
      sectorPercent: value / sectorRatio,
      betaPercent: betaPortfolioValue ? value / betaPortfolioValue : 0,
    };

    type weightGetterResult = keyof typeof ret;

    return ret;
  };

  return weightGetter;
}

type weightGetterResult = {
  percent: number;
  percentNoCash: number;
  percentAsStocks: number;
  typePercent: number;
  sectorPercent: number;
  betaPercent: number;
};
