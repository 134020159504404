import { Dashboard } from '../../common/types';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

// create a "selector creator" that uses lodash.isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectExperts = createDeepEqualSelector(
  myExperts => myExperts,
  (myExperts: any) => myExperts.getIn(['experts'])
);
export const selectBusyExperts = createDeepEqualSelector(
  dashboard => dashboard,
  (dashboard: any) => dashboard.get('busyExperts')
);
export const selectStats = createDeepEqualSelector(
  dashboard => dashboard,
  (dashboard: any) => dashboard.getIn(['data', 'stats'])
);
export const selectDeletedExperts = createDeepEqualSelector(
  dashboard => dashboard,
  (dashboard: any) => dashboard.get('deletedExperts')
);
export const selectExpertTypes = createDeepEqualSelector(
  dashboard => dashboard,
  (dashboard: any) => dashboard.getIn(['filters', 'expertTypes'])
);
