import React from 'react';

const svgImages = {
  search: ({ dim = [19, 19], color = '#fff' }) => ({
    title: 'search-icon',
    dim,
    viewBox: '0 0 19 19',
    elements: (
      <g>
        <circle
          stroke={color}
          strokeWidth="2"
          r="5.93182"
          cy="7.4761"
          cx="11.28069"
          fill="none"
        />
        <rect
          stroke={color}
          fill={color}
          transform="rotate(-46.08245849609375 4.646808624267575,14.25326633453369) "
          height="4.06636"
          width="6.183"
          y="12.22009"
          x="1.55531"
        />
      </g>
    )
  }),
  hamburger: {
    title: 'menu-icon',
    dim: [19, 18],
    elements: (
      <g>
        <rect
          stroke="#ffffff"
          fill="#ffffff"
          rx="1"
          x="0.5"
          y="0.4"
          width="17"
          height="3"
        />
        <rect
          stroke="#ffffff"
          fill="#ffffff"
          rx="1"
          x="0.5"
          y="7.2"
          width="17"
          height="3"
        />
        <rect
          stroke="#ffffff"
          fill="#ffffff"
          rx="1"
          x="0.5"
          y="14"
          width="17"
          height="3"
        />
      </g>
    )
  }
};

export default (imgName, type, props) => {
  if (!imgName) return null;

  let image = svgImages[imgName];
  if (image) {
    if (typeof image === 'function') {
      image = image(props);
    }

    const { dim: [width, height], elements, title, viewBox } = image;

    return (
      <svg viewBox={viewBox} className={title} width={width} height={height}>
        {elements}
      </svg>
    );
  }

  const imgSrc = require(`./${imgName}.png`);
  props = props || [];
  return type === 'img' ? <img src={imgSrc} {...props} /> : imgSrc;
};
