import { Dashboard, PortfolioRecord, DashboardGain } from '../types';
import { Map, Record } from 'immutable';
import { suits, TypesOfPortfolios } from 'sp/common/enums';
import { RouteNavState } from 'sp/browser/RouteNav/records';
import {
  dashboard as config,
  holdingsSectionConfig,
  companyConstants,
} from '../config';
import { List } from 'immutable';
import { Stats, Portfolio } from 'sp/common/records';
import { fromJS } from 'immutable';
import { Set } from 'immutable';
import {
  Dashboard_NotImmutable,
  Data_NotImmutable,
  UserInfoRecord,
} from 'sp/common/types';
import { createPortfolio } from 'sp/common/api-adapter';

export const componentStates_NotImmutable = {
  stats: suits.SUCCESS,
  experts: suits.SUCCESS,

  portfolios: suits.INITIAL,
  portfolioItems: suits.INITIAL,

  warnings: suits.SUCCESS,

  stocks: suits.SUCCESS,
  avgPortfolioStocks: suits.INITIAL,
  bestStocks: suits.INITIAL,

  newsByTickers: suits.SUCCESS,
  eventsByTickers: suits.SUCCESS,
  myportfolio: suits.SUCCESS,

  portfolioStats: suits.INITIAL,

  portfolio: suits.SUCCESS,
  addStock: suits.SUCCESS,
  userPerf: suits.SUCCESS,
  userInfo: suits.SUCCESS,
  deleteStock: suits.SUCCESS,
  addPortfolio: suits.SUCCESS,
  renamePortfolio: suits.SUCCESS,
  details: suits.SUCCESS,
  deletePortfolio: suits.SUCCESS,

  performanceComingSoon: suits.INITIAL,

  autoComplete: suits.SUCCESS,
  avgPortfolioItems: suits.SUCCESS,
  avgPortfolioDetails: suits.SUCCESS,
  bestPortfolioItems: suits.SUCCESS,
  bestDetails: suits.SUCCESS,
  portfolioBetaRisk: suits.SUCCESS,
  mgmtFees: suits.SUCCESS,
  fundsFeesByTickers: suits.SUCCESS,
  getNumPortfolios: suits.SUCCESS,
  changePassword: suits.SUCCESS,
  changeEmailFrequency: suits.SUCCESS,

  newsSentiments: suits.INITIAL,
  userFollowedStocks: suits.INITIAL,
  userFollowedExperts: suits.INITIAL,

  monthlyGainsForTickerWithDaysBack: Map() as Map<String, suits>,
  prices: Map() as Map<String, suits>,
};
export const componentStates = Map(componentStates_NotImmutable) as any;
const data_NotImmutable: Data_NotImmutable = {
  stats: new Stats() as any, // TODO remove any when Stats is instantiated using createRecord
  portfolios: List<PortfolioRecord>([])
    .push(createPortfolio({ id: companyConstants.get('avgPortfolioId') }))
    .push(createPortfolio({ id: companyConstants.get('bestPortfolioId') }))
    .push(
      createPortfolio({
        id: companyConstants.get('combinedUserPortfolio'),
        name: 'combinedUserPortfolio',
        type: 'combinedUserPortfolio',
      }),
  ),
  userInfo: Map() as any, // TODO this needs an initialState to work, instead of the
  experts: List(),
  stocks: List(),
  alerts: List(),
  userPerf: Map() as any, // TODO this needs an initialState to work, and I didn't have time to "move fast and break things" by adding one, so you should do it, dear reader :)
  screenerTotal: 0,
  gains: fromJS([{
    snpGains: { monthGains: [], symbol: 'SPY', portfolioId: -1 },
    averagePortfolioGains: { monthGains: [], portfolioId: -1 },
    bestPortfolioGains: { monthGains: [], portfolioId: -1 },
    portfolioMonthGains: [{ monthGains: [], portfolioId: -1 }],
  }] as DashboardGain) as any,
  mgmtFees: List(),
};
const initialState_NotImmutable: Dashboard_NotImmutable = {
  theme: process.env.THEME,
  apiSignature: Map() as any,
  routeNav: new RouteNavState() as any, // TODO remove any when Stats is instantiated using createRecord
  componentStates,
  activePortfolioId: -1,
  filters: Map({
    expertTypes: List(['analyst', 'blogger', 'insider', 'hedgefund']),
  }),
  deletedExperts: List(),
  busyExperts: List(),
  data: Map(data_NotImmutable) as any, // TODO figure out why no work
  editableId: '',
  editableValue: '',
  deletedPortfolios: List(),
  overviewPortfolioNewsTab: 'all',
  holdingsSortBy: 'type',
  isholdingsSortDescending: false,
  holdingsCols: List(holdingsSectionConfig.get('defaultCols')),
  holdingsColsPendings: List(holdingsSectionConfig.get('defaultCols')),
  dataList: List(),
  dataListQuery: '',
  pendingStocksToAdd: List(),
  stockAddedFlag: Set(),
  editablePortfolioId: -1,
  editablePortfolioValue: '',
  holdingsScroll: 0,
  holdingsPage: 0,
  performance: null,
  majorHoldingsType: 'stock',
  majorHoldingsSector: 'general',
  isMarketOpened: true,
  dismissdedWarningDialogs: List(),
  visibleMarketOverviewMarkets: List(['SPY', 'QQQ', 'GLD']),
  mraketOverviewDuration: '3m',
  assetAllocationType: 'stock',
  portfolioNewsTicker: '-1',
  importState: 'initial',
  tblType: 'basic',
  newsSentiments: fromJS({
    keywords: [],
    newsSentimentsTopStocks: [],
  }) as any,
  userFollowedStocks: List(),
  // this breaks consistency with userFollowedStocks. This also break my heart.
  userFollowedExperts: 0,
  monthlyGainsForTickerWithDaysBack: Map(),
  prices: Map(),
  basicTableType: 'all',
};
export const InitialState = Record(initialState_NotImmutable);

export const initialState: Dashboard = new InitialState() as any;
