import { PortfolioItemRecord, PortfolioItemRecord_NotImmutable } from '../../common/types';
import { isFinite as isFiniteLodash } from 'lodash';
import { BetterImmutableInterface } from 'sp/common/immutableStuff';

// this file has -ts appended to it because sometimes
// webpack confuses files with a different extension but
// same name in same directory and that causes stupid build failures.
// import { createSelector } from 'reselect';

export type LandmarkPriceType = 'YTDReturn' | '1yrReturn' | 'threeMonthsReturn';

export const calcLandmarkPercentange = (
  item: PortfolioItemRecord,
  landmarkPriceType: LandmarkPriceType,
) => {
  const stock = item.get('stock');
  const landmarkPrices = stock.get('landmarkPrices');
  if (!landmarkPrices) return null;
  const landmarkPrice = landmarkPrices.get(landmarkPriceType);
  const priceObj = stock.get('price');
  const price = priceObj.get('amount')!;
  const landmarkPricePerctangeDifference = (price / landmarkPrice - 1) * 100;
  return isFiniteLodash(landmarkPricePerctangeDifference)
    ? landmarkPricePerctangeDifference
    : null;
};

export function calc1YrReturn(item: BetterImmutableInterface<PortfolioItemRecord_NotImmutable>) {
  const regValue = item.getIn(['stock', '1yrReturn']) as number;
  const landmarkValue = calcLandmarkPercentange(item, '1yrReturn');
  return isFiniteLodash(landmarkValue) ? landmarkValue : regValue * 100;
}