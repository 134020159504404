import { fromJS } from 'immutable';
import classNames from 'classnames';
import React from 'react';
import { createSelector } from 'reselect';
import Component from 'react-pure-render/component';
import Table from './Table.react';
import { Suit, suitState } from '../components/suit';
import { holdingsSectionConfig } from '../../common/config';

const selectColls = createSelector(
  ({ isDesktop }) => isDesktop,
  ({ holdingsCols }) => holdingsCols,
  (isDesktop, holdingsCols) => {
    if (!isDesktop) {
      return fromJS([[], holdingsCols, []]);
    }

    let grouped = holdingsCols.groupBy(colId => {
      const colPosition = holdingsSectionConfig.getIn(['colOrder', colId]);
      // colPosition is `undefined` for values that dont exist in colOrder,
      // resulting in being in the `center` group
      if (colPosition < 0) return 'left';
      if (colPosition > 0) return 'right';
      return 'center';
    });

    // if (!grouped.get('center')) {
    //   return grouped.toList().splice(1, 0, fromJS([]));
    // }

    return grouped.valueSeq();
  }
);

export class AdvancedTable extends Component {
  // static propTypes = {
  //   children: PropTypes.element,
  //   activePage: PropTypes.number,
  //   changeEditable: PropTypes.func,
  //   deleteItem: PropTypes.func,
  //   dependencies: PropTypes.any,
  //   editableId: PropTypes.string,
  //   enums: PropTypes.object,
  //   fetchData: PropTypes.func,
  //   fetchPriceData: PropTypes.func,
  //   hideTooltip: PropTypes.func,
  //   holdingsCols: PropTypes.object,
  //   holdingsSortBy: PropTypes.string,
  //   insertBefore: PropTypes.func,
  //   isholdingsSortDescending: PropTypes.bool,
  //   isCombinedPortfolio: PropTypes.bool,
  //   isImportedPortfolio: PropTypes.bool,
  //   items: PropTypes.object,
  //   maxTable: PropTypes.number,
  //   mediaQuery: PropTypes.object,
  //   modes: PropTypes.object,
  //   msg: PropTypes.object,
  //   noDataMsg: PropTypes.object,
  //   openAddStockDialog: PropTypes.func,
  //   openHoldingsMetrics: PropTypes.func,
  //   openPortfolioItem: PropTypes.func,
  //   openPortfolioItemAlerts: PropTypes.func,
  //   openSyncDialog: PropTypes.func,
  //   portfolioId: PropTypes.number,
  //   priceDependencies: PropTypes.any,
  //   setPurchasePrice: PropTypes.func,
  //   setShares: PropTypes.func,
  //   setStockLastRead: PropTypes.func,
  //   showPromotion: PropTypes.func,
  //   showTooltip: PropTypes.func,
  //   sortHoldingsTable: PropTypes.func,
  //   tblType: PropTypes.string,
  //   onClickAlert: PropTypes.func,
  //   viewerPlan: PropTypes.string
  // };

  constructor(props) {
    super(props);
    this.state = {
      isDuplicateTabls: false,
      hoveredRow: ''
    };
  }
  onMouseEnterRow = (ticker, index) => {
    this.setState({ hoveredRow: ticker });
  };

  onMouseLeaveRow = () => {
    this.setState({ hoveredRow: '' });
  };

  render() {
    const {
      children,
      activePage,
      changeEditable,
      deleteItem,
      dependencies,
      editableId,
      enums,
      fetchData,
      fetchPriceData,
      hideTooltip,
      holdingsCols,
      holdingsSortBy,
      insertBefore,
      isholdingsSortDescending,
      isCombinedPortfolio,
      isImportedPortfolio,
      items,
      maxTable,
      mediaQuery,
      modes,
      msg,
      noDataMsg,
      openAddStockDialog,
      openHoldingsMetrics,
      openPortfolioItem,
      openPortfolioItemAlerts,
      openSyncDialog,
      portfolioId,
      priceDependencies,
      setPurchasePrice,
      setShares,
      setStockLastRead,
      showPromotion,
      showTooltip,
      sortHoldingsTable,
      tblType,
      onClickAlert,
      viewerPlan
    } = this.props;

    const isLoading = [
      dependencies.get('portfolios'),
      dependencies.get('portfolioItems')
    ];

    const isDesktop = mediaQuery.get('laptop');

    const { hoveredRow, isDuplicateTabls } = this.state;

    const colGroups = selectColls({ isDesktop, holdingsCols });

    return (
      <div
        className={classNames('tables-wrapper', { noData: items.size === 0 })}
        ref="tableWrappers"
      >
        <div
          className={classNames(
            'table-wrapper',
            // `hover-${hoveredRow}`,
            {
              isLoading: suitState(isLoading) === 'LOADING',
              duplicate: isDuplicateTabls
            }
          )}
        >
          <div className={'table-cols-groups'}>
            {colGroups.map((cols, index) => (
              <div className={`table-cols-group`} key={index}>
                <Table
                  activePage={activePage}
                  changeEditable={changeEditable}
                  deleteItem={deleteItem}
                  dependencies={dependencies}
                  editableId={editableId}
                  enums={enums}
                  fetchPriceData={fetchPriceData}
                  group={items}
                  hideTooltip={hideTooltip}
                  holdingsCols={cols}
                  holdingsSortBy={holdingsSortBy}
                  hoveredRow={hoveredRow}
                  index={0}
                  insertBefore={insertBefore}
                  isHoldingsSortDescending={isholdingsSortDescending}
                  isCombinedPortfolio={isCombinedPortfolio}
                  isImportedPortfolio={isImportedPortfolio}
                  maxTable={maxTable}
                  mediaQuery={mediaQuery}
                  modes={modes}
                  msg={msg}
                  onMouseEnterRow={this.onMouseEnterRow}
                  onMouseLeaveRow={this.onMouseLeaveRow}
                  openAddStockDialog={openAddStockDialog('table-footer')}
                  openPortfolioItem={openPortfolioItem}
                  openPortfolioItemAlerts={openPortfolioItemAlerts}
                  portfolioId={portfolioId}
                  priceDependencies={priceDependencies}
                  setPurchasePrice={setPurchasePrice}
                  setShares={setShares}
                  setStockLastRead={setStockLastRead}
                  showPromotion={showPromotion}
                  showTooltip={showTooltip}
                  sortHoldingsTable={sortHoldingsTable}
                  onClickAlert={onClickAlert}
                  viewerPlan={viewerPlan}
                />
              </div>
            ))}
          </div>
          {dependencies.get()}
          <Suit fetch={fetchData} name="holdings" state={isLoading}>
            <div>{items.size === 0 && children}</div>
          </Suit>
        </div>
        {!isImportedPortfolio && (
          <div className="add-stocks-btn">
            {isDesktop ? (
              <div className={`td sign ${tblType}`}>
                <div className={`td-inner ${tblType}`}>
                  <button onClick={openAddStockDialog('holdings-table-footer')}>
                    +
                  </button>
                </div>
              </div>
            ) : null}
            <div
              className={`td label ${tblType}`}
              colSpan={isDesktop ? holdingsCols.size : holdingsCols.size}
            >
              <div className="td-inner">
                <button onClick={openAddStockDialog('holdings-table-footer')}>
                  <span className="before">
                    <span>+</span>
                  </span>
                  {msg.addBtn}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
