import cookies from 'js-cookie';


export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export const openDialog = (dialogId, props) => {
  return () => ({
    type: OPEN_DIALOG,
    dialogId,
    props
  });
};

export const closeDialog = () => () => ({ type: CLOSE_DIALOG });

export const closeAllDialogs = () => {
  return () => ({
    type: CLOSE_ALL_DIALOGS
  });
};

export const UNSURE_SENT = 'UNSURE_SENT';
export const unsureSent = () => () => ({ type: UNSURE_SENT });
