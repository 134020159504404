import * as React from 'react';

// This is a bit of a hack to enable correct typings.
// You pass the actual Props to the first parameter, and the Connected Props to the second
// You then access the connected props only through `this.connected`, this allows you to not inject them,
// But still have correct typings for them.
export abstract class ConnectedComponent<Props = {}, ConnectedProps = {}, State = {}>
  extends React.Component<Props | (Props & ConnectedProps), State> {
  protected get connected() {
    return this.props as Readonly<ConnectedProps>;
  }
}

export abstract class ConnectedPureComponent<Props = {}, ConnectedProps = {}, State = {}>
  extends React.PureComponent<Props | (Props & ConnectedProps), State> {
  protected get connected() {
    return this.props as Readonly<ConnectedProps>;
  }
}

