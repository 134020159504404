import React from 'react';

import { imgSrc } from '../../common/lib/utils.ts';

export const alertsSettingsUpdated = ({ msg }) => (
  <div>
    <img className="icon" src={imgSrc('alertsSettingsUpdated')} />
    {msg.toasts.alertsSettingsUpdated}
  </div>
);
