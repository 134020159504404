import * as ReactCSSTransitionGroupOGT from 'react-addons-css-transition-group';
import * as React from 'react';
import { isShittyBrowser } from 'sp/browser/lib/utils';

export class ReactCSSTransitionGroup extends React.PureComponent<{
  children: any;
  [x: string]: any;
}> {
  Component: any = ({ children }) => children;
  componentWillMount() {
    if (!isShittyBrowser) this.Component = ReactCSSTransitionGroupOGT;
  }
  render() {
    const { Component } = this;
    return <Component {...this.props}>{this.props.children}</Component>;
  }
}
