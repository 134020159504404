const imageSrc = require('./image.png');
import { brandName } from '../../common/config';

export default {
  title: 'Have you ever wondered how you measure up as an investor?',
  imageSrc,
  undertitle: `Have you ever wondered how you measure up as an investor?`,
  form: {
    title:
      'Get deep insights into your investing strategy by answering the questionnaire below',
    sentences: [
      'I am {age} years old and my profession is {profession}',
      'My annual income is ${income}',
      'I usually invest in {term} term and my risk tolerance is {tolerance}'
    ],
    button: 'Done'
  },
  serverErrorMessage: 'Failed to update field.',
  inputs: {
    age: {
      pattern: /^\d{2,3}$/,
      errorMessage: 'Please enter an age between 18 and 120'
    },
    profession: {
      options: [
        'Accountant',
        'Banker',
        'Business Owner',
        'Consultant',
        'Executive',
        'Financial Consultant',
        'Financial Professional',
        'Insurance Agent',
        'Managerial',
        'Sales',
        'Marketing',
        'Stockbroker',
        'Retired',
        'Un-employed',
        'Other'
      ],
      errorMessage: 'This field is required'
    },
    income: {
      pattern: /^\d*$/,
      errorMessage: 'Please enter a valid number'
    },
    term: {
      options: ['Short', 'Intermediate', 'Long'],
      errorMessage: 'This field is required'
    },
    tolerance: {
      options: ['Very low', 'Low', 'Medium', 'High', 'Very High'],
      errorMessage: 'This field is required'
    }
  },
  success: {
    title: 'Thank you.',
    content:
      'We will provide you with the most accurate portfolio features for your trading needs.'
  }
};
