import { riskRange as defaultRange } from '../tr-business-rules/risk-level';

const getRange = ([x, y]) => num => Math.min(1, (Number(num) - x) / (y - x));
const getDomain = ([x, y]) => num =>
  Math.max(x, Math.min(y, x + num * (y - x)));

export const defaultBlocks = [
  '#308597',
  '#3c96a6',
  '#47a8b5',
  '#53bac4',
  '#5ba8b0',
  '#63979c',
  '#6c8688',
  '#747474',
  '#7f687e',
  '#895d89',
  '#935293',
  '#9e469d',
  '#8b3192',
  '#781e87',
  '#6b0d83'
];

export default (
  value,
  { range = defaultRange, blocks = defaultBlocks } = {}
) => {
  const numBlocks = blocks.length;
  const rn = getRange(range);
  const dm = getDomain([0, numBlocks]);
  const index = Math.round(dm(rn(value)));
  const color = blocks[index];
  return { index, color };
};
