import * as React from 'react';
import './index.styl';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export class TrDashboardWarning extends React.PureComponent<{}> {
  render() {
    const { children } = this.props;
    return (
      <div className="tr-dashboard-warning">
        <div className="indicator">
          <img src={reqImg('indicator.png')} />
        </div>
        <div className="content">{children}</div>
      </div>
    );
  }
}
export default TrDashboardWarning;
