import { PlaidEvents, TipRanksPlaidCB } from './PlaidTypes';

declare var Plaid: any;

function getPlaidConfig(token: string, events: PlaidEvents, setWasPlaidModalOpened: TipRanksPlaidCB['setWasPlaidModalOpened'], receivedRedirectUri?: string) {
  return {
    token,
    receivedRedirectUri,
    onLoad: function () {
      if (typeof setWasPlaidModalOpened === 'function') {
        setWasPlaidModalOpened()
      }
    },
    onSuccess: events.onSuccess,
    onExit: events.onExit,
    onEvent: function (eventName, metadata) {},
  };
}

export function openPlaidAuthPopUp(userPrivateToken: string, events: PlaidEvents, setWasPlaidModalOpened: TipRanksPlaidCB['setWasPlaidModalOpened'], receivedRedirectUri?: string) {
  const plaidConfig = getPlaidConfig(userPrivateToken, events, setWasPlaidModalOpened, receivedRedirectUri);
  const handler = Plaid && Plaid.create(plaidConfig);
  if (handler && typeof handler.open === 'function') {
    handler.open();
  }
}