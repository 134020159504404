import * as React from 'react';

import * as classNames from 'classnames';

import GlossaryLink from '../GlossaryLink/index';
import FancySelect from '../FancySelect/index.js';

import { selectViewerPlan, planWeight } from '../../common/auth/selectors';
import { AuthRecord, ScreenerType } from '../../common/types';
import { Reporter } from '../analytics';
import { events } from './analytics';
import * as toSlugCase from 'to-slug-case';

export interface FilterConf {
  label: string;
  apiLabel: string;
  tooltip: string;
  glossaryLink: string;
  options: Array<SelectOption> | ((dependsSelected: any[]) => SelectOption[]);
  disabled?: boolean;
  depends?: string;
  lockPlan?: 'open' | 'free' | 'premium' | 'ultimate';
  multiple?: boolean;
}

export interface FilterProps extends FilterConf {
  changeFilter: (apiLabel: string, value: string[]) => void;
  onLockedClick: React.EventHandler<React.SyntheticEvent<HTMLSelectElement>>;
  auth: AuthRecord;
  key: string | number;
  options: Array<SelectOption>;
  title?: string;
  report: Reporter<typeof events>;
  depValue: string[];
  stockType: ScreenerType;
}

export interface SelectOption {
  display: string;
  value: string | number;
  preview?: string;
}

export default class Filter extends React.PureComponent<FilterProps> {
  private lockMouseDown = (locked: boolean) => {
    const { onLockedClick, lockPlan, report, apiLabel, stockType } = this.props;
    // adapt for custom3
    const type = stockType === 'etfScreener' ? 'etf' : 'stock';
    const canLock =
      lockPlan && planWeight('premium') <= planWeight(lockPlan || 'open');
    if (!locked) {
      const currentPlan = selectViewerPlan(this.props.auth);
      return () => report('clickedUnlockedFeature', currentPlan);
    }
    return e => {
      // TODO: Allow passing plan to upgrade to in the future.
      // Currently you only get the ready dialogOpen function, but it's not general enough.

      if (e.button !== 0) {
        return true;
      }

      report('lockedPremiumClick', {});
      const slugApiLabel = toSlugCase(apiLabel);
      report.setLastPromotionTrigger(
        `sp-${type}-screener-filter-${slugApiLabel}`
      );

      e.preventDefault();
      e.target.blur();
      window.focus();

      onLockedClick(e);
      return false;
    };
  };

  public render() {
    const {
      label,
      apiLabel,
      options,
      depValue,
      tooltip,
      glossaryLink,
      lockPlan,
      title,
      changeFilter,
      auth,
      key,
      disabled,
      multiple = true
    } = this.props;

    const canLock = !!lockPlan && planWeight('premium') <= planWeight(lockPlan);
    const locked =
      !!canLock &&
      planWeight(selectViewerPlan(auth)) < planWeight(lockPlan || 'open');
    return (
      <label
        key={key}
        htmlFor={`filter_${label}`}
        className={classNames({ canLock, locked })}
      >
        <span
          className={classNames('labelText', 'hasTooltip')}
          data-tooltip={tooltip}
        >
          {label}
        </span>
        <span className="labelValue">
          <FancySelect
            depValue={depValue}
            title={title}
            disabled={disabled}
            onClick={this.lockMouseDown(locked)}
            multiple={multiple}
            label="Any"
            onChange={value => changeFilter(apiLabel, value)}
          >
            {!locked &&
              options.map(({ display, value, preview }) => (
                <option key={preview} value={value} data-preview={preview}>
                  {display}
                </option>
              ))}
          </FancySelect>
          <GlossaryLink
            forceInternal
            slug={glossaryLink}
            className="helpIndicator hasTooltip"
            data-tooltip="See detailed information"
          >
            ?
          </GlossaryLink>
        </span>
      </label>
    );
  }
}
