import './index.styl';

import { List } from 'immutable';
import * as React from 'react';
import * as classNames from 'classnames';
import { Link } from 'react-router';
import { dashboardPath } from 'sp/common/config';

import { TrDashboardMgmtFeesDesc } from '../tr-dashboard-mgmt-fees-desc';
import TrDashboardMgmtFeesImportantNote from '../tr-dashboard-mgmt-fees-important-note';
import TrDashboardMgmtFeesNoData from '../tr-dashboard-mgmt-fees-no-data';
import { defaultMsg } from './msg';
import { TrDashboardMgmtFeesOverviewTbl } from './TrDashboardMgmtFeesOverviewTbl';
import { MgmtFeeRecordJSRecord } from 'sp/common/types';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export default class TrDashboardMgmtFeesOverview extends React.PureComponent<{
  doAllMgmtFeesHaveShares: boolean;
  isHeaderTooltipShown: boolean;
  isImportantNoteShown: boolean;
  isLoading: boolean;
  isShownIfNoFees: boolean;
  isTableFundNameShown: boolean;
  mgmtFees: List<MgmtFeeRecordJSRecord>;
  //msg: PropTypes.shape(msgShape),
  saveAmount: number;
  savePercent: number;
  msg?: any;
}> {
  static defaultProps = {
    doAllMgmtFeesHaveShares: true,
    isHeaderTooltipShown: false,
    isImportantNoteShown: false,
    isLoading: false,
    isShownIfNoFees: false,
    isTableFundNameShown: false,
    mgmtFees: List(),
    msg: defaultMsg,
    saveAmount: 0,
    savePercent: 0
  };

  render() {return null}
  _render() {
    const {
      doAllMgmtFeesHaveShares,
      mgmtFees,
      isHeaderTooltipShown,
      isImportantNoteShown,
      isLoading,
      isShownIfNoFees,
      isTableFundNameShown,
      msg,
      saveAmount,
      savePercent
    } = this.props;

    const hasFees = mgmtFees.size > 0 && savePercent > 0;

    if (!hasFees && !isShownIfNoFees) return null;

    const noDataType = mgmtFees.size === 0 ? 'funds' : 'fees';

    return (
      <section className="tr-dashboard-mgmt-fees-overview">
        <header>
          <h1>{msg.headerH1}</h1>
          {isHeaderTooltipShown && <ToolTip msg={msg} />}
        </header>
        <main
          className={classNames({
            'has-fees': mgmtFees.size > 0,
            'is-loading': isLoading
          })}
        >
          {isLoading ? (
            <div className="loading">
              <img src={reqImg('loader.gif')} />
            </div>
          ) : mgmtFees.size > 0 ? (
            <div>
              {/* <div className="layout-block-1 layout-block-tbl">
                <TrDashboardMgmtFeesOverviewTbl
                  mgmtFees={mgmtFees}
                  isTableFundNameShown={isTableFundNameShown}
                  msg={msg}
                />
              </div> */}
              <div
                className={classNames(
                  `layout-block-1`,
                  `layout-block-desc-and-btn`
                )}
              >
                {savePercent > 0 ? (
                  <div className="tr-dashboard-mgmt-fees-desc-wrapper">
                    <TrDashboardMgmtFeesDesc
                      doAllMgmtFeesHaveShares={doAllMgmtFeesHaveShares}
                      saveAmount={saveAmount}
                      savePercent={savePercent}
                    />
                    <Btn msg={msg} />
                  </div>
                ) : (
                  <TrDashboardMgmtFeesNoData type={noDataType} />
                )}
              </div>
            </div>
          ) : (
            <TrDashboardMgmtFeesNoData type={noDataType} />
          )}
        </main>
        {!isLoading &&
          hasFees &&
          isImportantNoteShown && (
            <footer>
              <TrDashboardMgmtFeesImportantNote />
            </footer>
          )}
      </section>
    );
  }
}

class ToolTip extends React.PureComponent<{ msg: any }> {
  render() {
    const { msg } = this.props;

    return (
      <div className="tr-dashboard-mgmt-fees-overview-tool-tip">
        <div className="tool-tip-trigger">{msg.toolTipBtn}</div>
        <div className="tool-tip-layer">{msg.toolTip}</div>
      </div>
    );
  }
}
class Btn extends React.PureComponent<{ msg: any }> {
  render() {
    const { msg } = this.props;

    return (
      <Link
        className="tr-dashboard-mgmt-fees-overview"
        to={`/${dashboardPath}/analysis/fees`}
      >
        {msg.btn}
      </Link>
    );
  }
}
