import * as React from 'react';
import './WelcomeConnectIsDone.styl';
import VariableSizeSpinner from '../Spinner';


export const WelcomeConnectIsDone = () => (
  <div className={'WelcomeConnectIsDone'}>
    <span className="WelcomeConnectIsDone-orange">The best things come to those who wait.</span>
    <br />
    <span className="WelcomeConnectIsDone-smaller">Syncing may take up to 5 minutes...</span>
    <div className="WelcomeConnectIsDone-spinner-container">
      <VariableSizeSpinner size={50}/>
    </div>
  </div>
)