import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import initGraph from './js/graph';

export default class TrMarketOverview extends Component {
  static propTypes = {
    changedMarketOverviewMarketVisibility: PropTypes.func,
    changedMraketOverviewDuration: PropTypes.func,
    duration: PropTypes.string,
    visibleMarkets: PropTypes.object
  };

  componentDidMount() {
    const {
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      visibleMarkets,
      duration
    } = this.props;

    this.graph = initGraph({
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      visibleMarkets: visibleMarkets.toJS(),
      duration
    });
  }

  componentWillUnmount() {
    this.graph.unmount();
  }

  shouldComponentUpdate() {
    // we wnat only to decide the state of the UI on initial rendering, and let
    // initGraph code handle future state changes
    return false;
  }

  checkedIfVisible = market => {
    const { visibleMarkets } = this.props;
    return visibleMarkets.includes(market) ? `checked="checked"` : ``;
  };

  activateIfSelected = currentDuration => {
    const { duration } = this.props;
    return currentDuration === duration ? 'bottom_button_selected' : '';
  };

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <div class="market_wraper" id="mainBox">
              <div class="inner_market_wraper">
                  <div class="color_code">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        ${this.checkedIfVisible('SPY')}
                        data-id="SPY"
                        class="line_select css-checkbox"
                        id="checkbox68" />
                      <label
                        class="css-label chrome-style"
                        name="checkbox68_lbl"
                        for="checkbox68">
                        <span class="sp_color"></span>
                        <p class="color_text">S&P 500</p>
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        ${this.checkedIfVisible('QQQ')}
                        data-id="QQQ"
                        class="line_select css-checkbox"
                        id="checkbox69" />
                      <label
                        class="css-label chrome-style"
                        name="checkbox69_lbl"
                        for="checkbox69">
                        <span class="nasdaq_color "></span>
                        <p class="color_text">Nasdaq</p>
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        ${this.checkedIfVisible('GLD')}
                        data-id="GLD"
                        class="line_select css-checkbox"
                        id="checkbox70" />
                      <label
                        class="css-label chrome-style"
                        name="checkbox70_lbl"
                        for="checkbox70">
                        <span class="gold_color "></span>
                        <p class="color_text">Gold</p>
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        data-id="DIA"
                        ${this.checkedIfVisible('DIA')}
                        class="line_select css-checkbox"
                        id="checkbox71" />
                      <label
                        class="css-label chrome-style"
                        name="checkbox71_lbl"
                        for="checkbox71">
                        <span class="dow_color"></span>
                        <p class="color_text">Dow Jones</p>
                      </label>
                    </li>
                  </ul>
                  <div class="clear"></div>
                  <div id="chart_area_full"></div>
                  <div class="clear"></div>
                  <div class="bottom_buttons">
                    <ul>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('1d')}"
                          data-id="1d">
                          1D
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('5d')}"
                          data-id="5d">
                          5D
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('30d')}"
                          data-id="30d">
                          1M
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('3m')}"
                          data-id="3m">
                          3M
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('ytd')}"
                          data-id="ytd">
                          YTD
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('1y')}"
                          data-id="1y">
                          1Y
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('5y')}"
                          data-id="5y">
                          5Y
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
          </div>`
        }}
        id="mainBox"
      />
    );
  }
}
