import d3 from 'd3';
//import 'whatwg-fetch';
var fetch = window.fetch;
var temp_val, cur_text, text_group;

var active_line_data;

var main_div_width,
  margin,
  width,
  height,
  parseDate,
  x,
  y,
  xAxis,
  yAxis,
  line,
  svg,
  x_axis_fun,
  text_arr,
  text_arr_,
  text_arr_2,
  line_arr;

var current_data_ele = '';

if (typeof window !== 'undefined') {
  var data_1d = (window['data_1d'] = []),
    data_5d = (window['data_5d'] = []),
    data_30d = (window['data_30d'] = []),
    data_3m = (window['data_3m'] = []),
    data_ytd = (window['data_ytd'] = []),
    data_1y = (window['data_1y'] = []),
    data_5y = (window['data_5y'] = []);
}

const bubblePng = process.env.IS_BROWSER ? require(`./bubble.png`) : '';

// const Promise = require('bluebird');
// if (typeof window !== 'undefined') {
//   window.Promise = Promise;
//   Promise.config({longStackTraces: true, warnings: false});
//   Promise.setScheduler(fn => setTimeout(fn));
//   //Promise.onPossiblyUnhandledRejection(function(e) {
//   //  throw e;
//   //});
// }

const daysBack = (days, tickers) =>
  `/api/portfolio/fundamentals/?tickers=${tickers.join(',')}&daysBack=${days}`;

const removeLoader = () => {
  const el = document.getElementsByClassName('market_wraper_fees-loading')[0];
  el.className += ' hidden';
};

const fetchDays = (days, tickers) => {
  return fetch(daysBack(days, tickers), {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'get'
  }).then(res => res.json());
};

let changedMarketOverviewMarketVisibilityHandler,
  changedMraketOverviewDurationHandler,
  visibleMarketsArray;

export default ({
  changedMarketOverviewMarketVisibility,
  changedMraketOverviewDuration,
  visibleMarkets,
  mgmtFee,
  duration = '3m'
}) => {
  changedMarketOverviewMarketVisibilityHandler = changedMarketOverviewMarketVisibility;

  changedMraketOverviewDurationHandler = changedMraketOverviewDuration;

  visibleMarketsArray = visibleMarkets;

  if (typeof window === 'undefined') return;

  // Data Variables - from json

  var calcualtePercent = function(first_val, next_val) {
    first_val = parseFloat(first_val);
    next_val = parseFloat(next_val);
    var final_val = (next_val - first_val) * 100 / first_val;
    return Number(final_val.toFixed(2));
  };

  const ytd = () => {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    return day;
  };

  // 1d.json  - 1y.json  - 5d.json
  const tickers = [mgmtFee.get('fundSymbol'), mgmtFee.get('similarSymbol')];

  fetchDays(1, tickers).then(function(data) {
    window['data_1d'] = data_1d = active_line_data = data;
    if (duration === '1d') render_graph(data, 0);
  });

  fetchDays(5, tickers).then(function(data) {
    window['data_5d'] = data_5d = data;
    if (duration === '5d') render_graph(data, 0);
  });

  fetchDays(30, tickers).then(function(data) {
    window['data_30d'] = data_30d = data;
    if (duration === '30d') render_graph(data, 0);
  });

  fetchDays(91, tickers).then(function(data) {
    window['data_3m'] = data_3m = data;
    if (duration === '3m') render_graph(data, 0);
  });

  fetchDays(ytd(), tickers).then(function(data) {
    window['data_ytd'] = data_ytd = data;
    if (duration === 'ytd') render_graph(data, 0);
  });

  fetchDays(365, tickers).then(function(data) {
    if (data.some(d => d.prices.length === 0)) return;
    window['data_1y'] = data_1y = data;
    if (duration === '1y') render_graph(data, 0);
  });

  fetchDays(1825, tickers).then(function(data) {
    window['data_5y'] = data_5y = data;
    if (duration === '5y') render_graph(data, 0);
  });

  function resize() {
    render_graph(active_line_data, 1);
  }

  var clone = function(obj) {
    if (null == obj || 'object' != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  };

  var render_graph = function(data, update) {
    if (!data || data.some(x => !x)) return;

    // function for the x grid lines
    function make_x_axis() {
      return d3.svg
        .axis()
        .scale(x)
        .orient('bottom');
    }

    // function for the y grid lines
    function make_y_axis() {
      return d3.svg
        .axis()
        .scale(y)
        .orient('left');
    }
    removeLoader();
    if (update == 1) {
      var del_keys = [];
      d3.select('#chart_area_full > svg').remove();

      data = clone(data);
      data.forEach(function(d, k) {
        if (selected_lines.indexOf(d.ticker) == -1) {
          del_keys.push(k);
        }
      });
      // reverse order
      del_keys.reverse();
      del_keys.forEach(function(d1, k1) {
        data.splice(d1, 1);
        //data[d1] = {};
      });
    }

    var main_div = document.getElementById('mainBox');

    var main_div_width = main_div ? main_div.offsetWidth : 0;

    margin = { top: 2, right: 60, bottom: 30, left: 10 };
    width = main_div_width - margin.left - margin.right;
    height = 145;

    //var parseDate = d3.time.format("%Y%m%d").parse;
    parseDate = d3.time.format('%Y-%m-%dT%H:%M:%S').parse;

    //console.log(main_div_width);
    x = d3.time.scale().range([0, width]);

    y = d3.scale.linear().range([height, 15]);

    var color = d3.scale.category10();

    xAxis = d3.svg
      .axis()
      .scale(x)
      //.ticks(7)
      // .tickFormat(d3.time.format("%H:%M:%S"))
      .orient('bottom');

    // X - axis overlapp issue
    if (main_div_width <= 720) {
      xAxis.ticks(4);
    }

    yAxis = d3.svg
      .axis()
      .scale(y)
      .ticks(7)
      .orient('right')
      .tickFormat(d => d3.format('.2%')(d / 100));

    line = d3.svg
      .line()
      //.interpolate("basis")
      .x(function(d) {
        //  console.log('new-',d);
        return x(d.p_date);
      })
      .y(function(d) {
        return y(d.per_val);
      });

    svg = d3
      .select('#chart_area_full')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    data.forEach(function(d) {
      if (d.prices.length) {
        var starting_val = d['prices'][0]['price'];
        d['prices'].forEach(function(d2) {
          d2.p_date = parseDate(d2.date);
          d2.per_val = calcualtePercent(starting_val, d2.price);
        });
      }
    });
    var cities = data.filter(d => {
      return selected_lines.includes(d.ticker);
    });

    x.domain([
      d3.min(cities, function(c) {
        return d3.min(c.prices, function(v) {
          return v.p_date;
        });
      }),
      d3.max(cities, function(c) {
        return d3.max(c.prices, function(v) {
          return v.p_date;
        });
      })
    ]);

    var x_axis_values = [];
    var x_start = Date.parse(x.domain()[0]);
    var x_end = Date.parse(x.domain()[1]);
    // console.log(x.domain(),x_start,x_end);

    y.domain([
      d3.min(cities, function(c) {
        return d3.min(c.prices, function(v) {
          return v.per_val;
        });
      }),
      d3.max(cities, function(c) {
        return d3.max(c.prices, function(v) {
          return v.per_val;
        });
      })
    ]);
    // Tweaking Y axis domain for grid lines overflow issue

    var curr_domain = y.domain();
    //console.log(curr_domain);
    curr_domain[0] += 5 / 100 * curr_domain[0]; // -ve [0]
    curr_domain[1] += 5 / 100 * curr_domain[1]; // +ve [0]
    //console.log(curr_domain);
    y.domain(curr_domain);
    //

    if (current_data_ele == '5d') {
      xAxis
        .ticks(d3.time.day, 1)
        .tickFormat(
          (...args) =>
            args[0].getDay() === 0 || args[0].getDay() === 6
              ? ''
              : d3.time.format('%b %_d')(...args)
        );
    }
    if (current_data_ele == '30d') {
      xAxis.ticks(4);
      xAxis.tickFormat(d3.time.format('%b %_d'));

      x_axis_fun = make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('')
        .ticks(4);
    } else if (current_data_ele == '1d') {
      xAxis.ticks(4);
      xAxis.tickFormat(d3.time.format('%I%p'));

      x_axis_fun = make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('')
        .ticks(4);
    } else if (current_data_ele == '3m') {
      xAxis.ticks(4);
      xAxis.tickFormat(d3.time.format('%b %_d'));

      x_axis_fun = make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('')
        .ticks(4);
    } else if (current_data_ele == 'ytd') {
      xAxis.ticks(d3.time.month, 1);
      xAxis.tickFormat(d3.time.format('%b'));

      x_axis_fun = make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('')
        .ticks(d3.time.month, 1);
    } else {
      x_axis_fun = make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('');
    }

    // Draw the x Grid lines
    svg
      .append('g')
      .attr('class', 'x_g grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(x_axis_fun);

    // Draw the y Grid lines
    svg
      .append('g')
      .attr('class', 'y_g grid')
      .call(
        make_y_axis()
          .tickSize(-width, 0, 0)
          .tickFormat('')
          .ticks(6)
      );
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    // moving  x labels to top
    // select all the text elements for the xaxis
    // svg.selectAll(".x .tick text")
    //         .text(function(d1,v1){
    //           console.log(d1,v1)
    //         })
    //         .attr("y",6)

    // svg.selectAll("x .tick text")  // select all the text elements for the xaxis
    //         .attr("text",function(d,v){
    //           console.log(d,v);
    //         })

    svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(' + width + ',0)')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end');

    // covering spaces in AM PM
    text_arr = document.querySelectorAll('.x .tick text');
    for (i = 0; i < text_arr.length; i++) {
      cur_text = text_arr[i].textContent;
      if (cur_text.indexOf('AM') >= 0 || cur_text.indexOf('PM') >= 0) {
        text_arr[i].textContent = cur_text.replace(' ', '');
      }
    }

    var city = svg.selectAll('.city').data(cities);

    city.enter().append('g');

    city
      .attr('class', 'city')
      .append('path')
      .attr('class', 'line')
      .attr('d', function(d) {
        // console.log('d--d',d,d.prices,line(d.prices));
        return line(d.prices);
      })
      .style('stroke-width', '2px')
      .style('stroke', function(d) {
        //console.log(d);
        if (d.ticker == mgmtFee.get('fundSymbol')) {
          return '#5a4099';
        } else if (d.ticker == mgmtFee.get('similarSymbol')) {
          return '#009b7a';
        } else {
          return color(d.ticker);
        }
        //  return 'black';
      });

    text_group = city
      .append('g')
      .datum(function(d) {
        return { name: d.ticker, value: d.prices[d.prices.length - 1] };
      })
      .attr('transform', function(d) {
        return (
          'translate(' + x(d.value.p_date) + ',' + y(d.value.per_val) + ')'
        );
      });

    text_group
      .append('image')
      .attr('xlink:href', bubblePng)
      .attr('x', '5')
      .attr('y', '-10')
      .attr('width', '55')
      .attr('height', '20');
    text_group
      .append('text')
      //.datum(function(d) {return {name: d.ticker, value: d.prices[d.prices.length - 1]}; })
      // .attr("transform", function(d) {return "translate(" + x(d.value.p_date) + "," + y(d.value.per_val) + ")"; })
      //.attr("class","bubble me")
      .attr('x', 15)
      .attr('font-size', '10px')
      // .attr("font-weight","bold")
      .attr('dy', '.35em')
      .text(function(d) {
        // return d.name + '( '+d.value['per_val']+' %)';
        return d.value['per_val'] + '%';
      })
      .style('stroke', function(d) {
        if (d.ticker == mgmtFee.get('fundSymbol')) {
          return '#5a4099';
        } else if (d.ticker == mgmtFee.get('similarSymbol')) {
          return '#009b7a';
        } else {
          return color(d.ticker);
        }
      })
      .style('stroke-width', '0.5px');

    // Tweaks Tweaks
    if (current_data_ele == '1d') {
      text_arr_ = document.querySelectorAll('.x .tick text');
      text_arr_2 = document.querySelectorAll('.x_g .tick');
      //console.log(text_arr_);
      for (i = 0; i < text_arr_.length; i++) {
        //console.log()
        text_arr_[i].textContent = text_arr_[i].textContent.replace(/^0+/, '');
        if (i == 0) {
          text_arr_[i].setAttribute('x', '13');
        }
        if (i % 2 != 0) text_arr_[i].textContent = '';
      }

      for (i = 0; i < text_arr_2.length; i++) {
        //console.log()
        if (i % 2 != 0) text_arr_2[i].style.opacity = '0';
      }
    }

    //
    if (current_data_ele == 'ytd') {
      svg
        .selectAll('.x')
        .append('g')
        .attr('class', 'tick')
        .style('opacity', '1')
        .attr('transform', 'translate(0,0)')
        .append('text')
        .text('Jan 4')
        .attr('x', '13')
        .attr('y', '9')
        .style('text-anchor', 'middle')
        .attr('dy', '.71em');
    }
  };

  // buttons click
  var selected_lines = visibleMarketsArray;
  var classname = document.getElementsByClassName('data_select');
  var line_class = document.getElementsByClassName('line_select');

  //console.log(classname);
  var dataSelect = function() {
    clear_data_active();
    var attribute = this.getAttribute('data-id');
    this.className = 'data_select bottom_button_selected';
    current_data_ele = attribute;
    active_line_data = window['data_' + attribute];
    changedMraketOverviewDurationHandler({ duration: attribute });
    render_graph(window['data_' + attribute], 1);
  };
  var lineSelect = function() {
    // clear_line_active();
    //alert(this.getAttribute("data-class"));
    line_arr = document.getElementsByClassName('line_select');
    let c_count = 0;
    for (i = 0; i <= line_arr.length - 1; i++) {
      if (line_arr[i].checked) {
        c_count++;
      }
    }
    if (c_count != 0) {
      let curr_state = this.checked;
      let curr_id = this.getAttribute('data-id');
      if (!curr_state) {
        selected_lines.splice(selected_lines.indexOf(curr_id), 1);
      } else {
        selected_lines.push(curr_id);
      }
      changedMarketOverviewMarketVisibilityHandler({ markets: selected_lines });
      const currentPeriod = document
        .getElementsByClassName('bottom_button_selected')[0]
        .getAttribute('data-id');
      active_line_data = window['data_' + currentPeriod];
      let dataToRender = active_line_data.filter(({ ticker }) =>
        selected_lines.includes(ticker)
      );
      render_graph(dataToRender, 1);
      //console.log(selected_lines);
    } else {
      this.checked = 'checked';
    }
  };

  for (var i = 0; i < classname.length; i++) {
    classname[i].addEventListener('click', dataSelect, false);
  }
  for (var i = 0; i < line_class.length; i++) {
    line_class[i].addEventListener('click', lineSelect, false);
  }

  var clear_data_active = function() {
    var classname = document.getElementsByClassName('data_select');
    for (var i = 0; i < classname.length; i++) {
      classname[i].className = 'data_select';
    }
  };
  var clear_line_active = function() {
    var classname = document.getElementsByClassName('line_select');
    for (var i = 0; i < classname.length; i++) {
      classname[i].className =
        classname[i].getAttribute('data-class') + ' line_select';
    }
  };
  //
  window.addEventListener('resize', resize);
  return {
    unmount() {
      window.removeEventListener('resize', resize);
    }
  };
};
