import * as React from 'react';
import * as classNames from 'classnames';
import './info-tooltip.styl';
import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import { connect } from 'react-redux';
import { getGlobalActions } from '../lib/actions';

@connect(null, mapDispatchToProps)
export class InfoTooltip extends React.Component<{
  msg: string;
  className?: string;
  expectedWidth?: number | string;
  expectedHeight?: number | string;
}> {
  // contextTypes = {actions: React.PropTypes.object}
  static defaultProps = {
    expectedHeight: 100,
    expectedWidth: 350
  };
  onMouseEnter = () => {
    let { expectedWidth, expectedHeight } = this.props;
    expectedHeight = parseInt(expectedHeight as string);
    expectedWidth = parseInt(expectedWidth as string);
    getGlobalActions().showTooltip(
      'info',
      {
        msg: this.props.msg,
        expectedHeight,
        expectedWidth
      },
      this.refs.content
    );
  };

  onMouseLeave = e => {
    getGlobalActions().hideTooltip('info', e);
  };

  render() {
    const { className } = this.props;
    return (
      <div className={classNames(className, 'info-tooltip-component')}>
        <div
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className="info-tooltip-icon"
          ref="content"
        />
      </div>
    );
  }
}

export default InfoTooltip;
