import { fromJS } from 'immutable';

import {
  adaptAutoCompleteList,
  adaptNewPortfolio,
  adaptStats,
  adaptPortfolioItems,
  adaptPortfolioNews,
  adaptPortfolioStat,
  adaptPortfolioEvents,
  adaptPortfolioWarnings,
  adaptStocks,
  addNewsSentimentToStocks,
} from '../api-adapter';

import {
  addPortfolio as apiAddPortfolio,
  addPortfolioStocks,
  changeCashValue,
  changePortfolioHolding,
  changePurchasePrice,
  disableStockAlerts,
  enableStockAlerts,
  getAutoComplete,
  getDetails,
  getFundsFees as getFundsFeesServer,
  getIsMarketOpened,
  getNewsSentimentForTickers,
  getNumPortfolios as apiGetNumPortfolios,
  getPortfolioBetaRisk as apiGetPortfolioBetaRisk,
  getPortfolioEvents,
  getPortfolioGains as apiGetPortfolioGains,
  getPortfolioHoldingStockData as serverGetStocks,
  getPortfolioItems as serverGetPortfolioItems,
  getPortfolioItemsByTickers as serverGetPortfolioItemsByTickers,
  getPortfolioNews,
  getPortfolios,
  getPortfolioStats as apiGetPortfolioStats,
  getPortfolioWarnings as apiGetPortfolioWarnings,
  getReq,
  getStats,
  getUserExtendedProperties,
  getUserPerformance,
  importedPortfolio as importedPortfolioApi,
  importPortfolioStatus as importPortfolioStatussApi,
  importPortfolioTrigger as importPortfolioServer,
  makeUserAnExpert,
  removePortfolio,
  removePortfolioStock,
  renamePortfolio,
  sendFeedback,
  setStockLastRead as apiSetStockLastRead,
  updateImportedPortfolio as updateImportedPortfolioApi,
} from '../api';
import { List } from 'immutable';
import { TypesOfPortfolios } from 'sp/common/enums';
import { Map } from 'immutable';
import PortfolioRisk from 'sp/browser/dashboard/routes/analysis/portfolio/PortfolioRisk.react';
import { BetterRecord } from 'sp/common/immutableStuff';
import { RealTimePrices } from 'sp/browser/components/RealTimePrices';
import { BasicTableType } from 'sp/common/types';

export const fetchStart = name => `${name}_START`;
export const fetchSuccess = name => `${name}_SUCCESS`;
export const fetchError = name => `${name}_ERROR`;

export const appendSignature = <T>(args: any[] = []) => (res: T) => ({
  res,
  signature: args.join('.'),
  timestamp: new Date(),
});

export const IMPORT_PORTFOLIO = 'IMPORT_PORTFOLIO';
export const importPortfolio = () => () => ({
  type: 'IMPORT_PORTFOLIO',
  promise: importPortfolioServer(),
});

export const TOGGLE_ROUTE_NAV = 'TOGGLE_ROUTE_NAV';
export const toggleRouteNav = () => {
  return () => ({ type: TOGGLE_ROUTE_NAV });
};

export const ASSET_ALLOCATION_TYPE_CHANGED = 'ASSET_ALLOCATION_TYPE_CHANGED';
export const assetAllocationChange = (activeAsset: any) => {
  return () => ({ type: ASSET_ALLOCATION_TYPE_CHANGED, activeAsset });
};

export const CHANGE_OVERVIEW_MARKET = 'CHANGE_OVERVIEW_MARKET';
export const changedMarketOverviewMarketVisibility = (markets: any) => {
  return () => ({ type: CHANGE_OVERVIEW_MARKET, markets });
};

export const CHANGE_OVERVIEW_DURATION = 'CHANGE_OVERVIEW_DURATION';
export const changedMraketOverviewDuration = duration => {
  return () => ({ type: CHANGE_OVERVIEW_DURATION, duration });
};

export const DISMISS_WARNING_DIALOG = 'DISMISS_WARNING_DIALOG';
export const dismissWarningDialog = (name: string) => {
  return () => ({
    type: DISMISS_WARNING_DIALOG,
    name,
    time: new Date().getTime(),
  });
};

export const MAJOR_HOLDINGS_TYPE_CHANGED = 'MAJOR_HOLDINGS_TYPE_CHANGED';
export const majorHoldingsTypeChanged = majorHoldingsType => () => ({
  type: MAJOR_HOLDINGS_TYPE_CHANGED,
  majorHoldingsType,
});

export const MAJOR_HOLDINGS_SECTOR_CHANGED = 'MAJOR_HOLDINGS_SECTOR_CHANGED';
export const majorHoldingsSectorChanged = majorHoldingsSector => () => ({
  type: MAJOR_HOLDINGS_SECTOR_CHANGED,
  majorHoldingsSector,
});

export const CHANGE_EDITABLE_PORTFOLIO = 'CHANGE_EDITABLE_PORTFOLIO';
export const changeEditablePortfolio = (portfolioId: number) => () => ({
  type: CHANGE_EDITABLE_PORTFOLIO,
  portfolioId,
});

export const APPLY_HOLDINGS_METRICS_CHANGES = 'APPLY_HOLDINGS_METRICS_CHANGES';
export const applyHoldingsMetricsChanges = () => () => ({
  type: APPLY_HOLDINGS_METRICS_CHANGES,
});

export const CHANGE_EDITABLE_PORTFOLIO_VALUE =
  'CHANGE_EDITABLE_PORTFOLIO_VALUE';
export const changeEditablePortfolioValue = value => () => ({
  type: CHANGE_EDITABLE_PORTFOLIO_VALUE,
  value,
});

export const INSERT_BEFORE_HOLDINGS_TABLE = 'INSERT_BEFORE_HOLDINGS_TABLE';
export const insertBeforeHoldingsTable = (source, dest) => {
  return {
    type: INSERT_BEFORE_HOLDINGS_TABLE,
    dest,
    source,
  };
};

export const SET_PORTFOLIO_NEWS_TYPE = 'SET_PORTFOLIO_NEWS_TYPE';
export const setOverviewPortfolioNewsTab = type => {
  return () => ({
    type: SET_PORTFOLIO_NEWS_TYPE,
    newsType: type,
  });
};

export const CHANGE_PORTFOLIO_NEWS_TICKER = 'CHANGE_PORTFOLIO_NEWS_TICKER';
export const changePortfolioNewsTicker = ticker => {
  return () => ({
    type: CHANGE_PORTFOLIO_NEWS_TICKER,
    ticker,
  });
};

export const CHANGE_HOLDINGS_METRICS = 'CHANGE_HOLDINGS_METRICS';
export const changeHoldingsMetrics = selections => () => {
  return {
    type: CHANGE_HOLDINGS_METRICS,
    selections,
  };
};

export const CHANGE_EDITABLE = 'CHANGE_EDITABLE';
export const changeEditable = (id, val) => {
  return () => ({
    type: CHANGE_EDITABLE,
    id,
    val,
  });
};

export const CHANGE_FILTER = 'CHANGE_FILTER';
export const changeFilter = (id, val) => {
  return () => ({
    type: CHANGE_FILTER,
    payload: { id, val },
  });
};

export const ACTIVATE_PAGE = 'ACTIVATE_PAGE';
export const activatePage = page => () => ({
  type: ACTIVATE_PAGE,
  page,
});

export const CHANGE_PORTFOLIO = 'CHANGE_PORTFOLIO';
export const changePortfolio = portfolioId => {
  return () => ({
    type: CHANGE_PORTFOLIO,
    portfolioId,
  });
};

export const SORT_HOLDINGS_TABLE = 'SORT_HOLDINGS_TABLE';
export const sortHoldingsTable = field => {
  return () => ({
    type: SORT_HOLDINGS_TABLE,
    field,
  });
};

export const DELETE_PENDING_STOCKS_TO_ADD = 'DELETE_PENDING_STOCKS_TO_ADD';
export const deletePendingStocksToAdd = item => {
  return () => ({
    type: DELETE_PENDING_STOCKS_TO_ADD,
    item,
  });
};

export const GET_MY_RETURN_AVERAGE = 'GET_MY_RETURN_AVERAGE';
export const GET_MY_RETURN_AVERAGE_START = 'GET_MY_RETURN_AVERAGE_START';
export const GET_MY_RETURN_AVERAGE_ERROR = 'GET_MY_RETURN_AVERAGE_ERROR';
export const GET_MY_RETURN_AVERAGE_SUCCESS = 'GET_MY_RETURN_AVERAGE_SUCCESS';

/**
 * portfolioMonthlyGain
 */
export const getMyAverageReturn = (portfolioIds: number[]) => () => ({
  type: GET_MY_RETURN_AVERAGE,
  payload: {
    promise: getReq('api/portfolio/GetMyAverageReturn', { portfolioIds }).then(
      fromJS,
    ),
  },
});

export const IMPORT_PORTFOLIO_STATUS = 'IMPORT_PORTFOLIO_STATUS';
export const IMPORT_PORTFOLIO_STATUS_START = 'IMPORT_PORTFOLIO_STATUS_START';
export const IMPORT_PORTFOLIO_STATUS_ERROR = 'IMPORT_PORTFOLIO_STATUS_ERROR';
export const IMPORT_PORTFOLIO_STATUS_SUCCESS =
  'IMPORT_PORTFOLIO_STATUS_SUCCESS';
export const importPortfolioStatus = () => () => ({
  type: IMPORT_PORTFOLIO_STATUS,
  payload: { promise: importPortfolioStatussApi() },
});

export const RESET_PORTFOLIO_STATUS = 'RESET_PORTFOLIO_STATUS';
export const resetPortfolioStatus = () => ({
  type: RESET_PORTFOLIO_STATUS,
});

export const FETCH_IMPORTED_PORTFOLIOS = 'FETCH_IMPORTED_PORTFOLIOS';
export const FETCH_IMPORTED_PORTFOLIOS_START =
  'FETCH_IMPORTED_PORTFOLIOS_START';
export const FETCH_IMPORTED_PORTFOLIOS_ERROR =
  'FETCH_IMPORTED_PORTFOLIOS_ERROR';
export const FETCH_IMPORTED_PORTFOLIOS_SUCCESS =
  'FETCH_IMPORTED_PORTFOLIOS_SUCCESS';
export const fetchImportedPortfolios = () => () => ({
  type: FETCH_IMPORTED_PORTFOLIOS,
  payload: { promise: importedPortfolioApi() },
});

export const UPDATE_IMPORTED_PORTFOLIO = 'UPDATE_IMPORTED_PORTFOLIO';
export const UPDATE_IMPORTED_PORTFOLIO_START =
  'UPDATE_IMPORTED_PORTFOLIO_START';
export const UPDATE_IMPORTED_PORTFOLIO_ERROR =
  'UPDATE_IMPORTED_PORTFOLIO_ERROR';
export const UPDATE_IMPORTED_PORTFOLIO_SUCCESS =
  'UPDATE_IMPORTED_PORTFOLIO_SUCCESS';
export const updateImportedPortfolio = id => () => ({
  type: UPDATE_IMPORTED_PORTFOLIO,
  payload: { promise: updateImportedPortfolioApi(id) },
});

export const FETCH_PORTFOLIO_GAINS = 'FETCH_PORTFOLIO_GAINS';
export const FETCH_PORTFOLIO_GAINS_START = 'FETCH_PORTFOLIO_GAINS_START';
export const FETCH_PORTFOLIO_GAINS_ERROR = 'FETCH_PORTFOLIO_GAINS_ERROR';
export const FETCH_PORTFOLIO_GAINS_SUCCESS = 'FETCH_PORTFOLIO_GAINS_SUCCESS';
export const getPortfolioGains = portfolioId => {
  return () => ({
    type: FETCH_PORTFOLIO_GAINS,
    payload: {
      promise: apiGetPortfolioGains(portfolioId),
    },
  });
};

export const GET_FUNDS_FEES = 'GET_FUNDS_FEES';
export const GET_FUNDS_FEES_START = 'GET_FUNDS_FEES_START';
export const GET_FUNDS_FEES_ERROR = 'GET_FUNDS_FEES_ERROR';
export const GET_FUNDS_FEES_SUCCESS = 'GET_FUNDS_FEES_SUCCESS';
export const getFundsFees = (tickers: string[]) => () => ({
  type: GET_FUNDS_FEES,
  payload: {
    promise: getFundsFeesServer(tickers),
  },
});

export const APPLY_PENDING_STOCKS_TO_ADD = 'APPLY_PENDING_STOCKS_TO_ADD';
export const APPLY_PENDING_STOCKS_TO_ADD_START = fetchStart(
  APPLY_PENDING_STOCKS_TO_ADD,
);
export const APPLY_PENDING_STOCKS_TO_ADD_SUCCESS = fetchSuccess(
  APPLY_PENDING_STOCKS_TO_ADD,
);
export const APPLY_PENDING_STOCKS_TO_ADD_ERROR = fetchError(
  APPLY_PENDING_STOCKS_TO_ADD,
);
export const applyPendingStocksToAdd = (id, tickers) => {
  // TODO wtf is this fucking disgusting piece of crap
  const newItems = adaptPortfolioItems('client')(
    tickers.map(ticker => ({ ticker })),
  );
  return () => ({
    type: APPLY_PENDING_STOCKS_TO_ADD,
    payload: {
      data: { id, newItems: newItems },
      promise: addPortfolioStocks(id, tickers).then(() => ({
        id,
        newItems,
      })),
    },
  });
};

export const ADD_PORTFOLIO_ITEMS = 'ADD_PORTFOLIO_ITEMS';
export const ADD_PORTFOLIO_ITEMS_START = 'ADD_PORTFOLIO_ITEMS_START';
export const ADD_PORTFOLIO_ITEMS_ERROR = 'ADD_PORTFOLIO_ITEMS_ERROR';
export const ADD_PORTFOLIO_ITEMS_SUCCESS = 'ADD_PORTFOLIO_ITEMS_SUCCESS';
export const addPortfolioItems = (id, tickers) => {
  // TODO wtf is this fucking disgusting piece of crap
  const newItems = adaptPortfolioItems('client')(
    tickers.map(ticker => ({ ticker })),
  );
  return () => ({
    type: ADD_PORTFOLIO_ITEMS,
    payload: {
      data: {
        id,
        newItems,
      },
      promise: addPortfolioStocks(id, tickers).then(() => ({ id, newItems })),
    },
  });
};

export const CHANGE_AUTO_COMPLETE_QUERY = 'CHANGE_AUTO_COMPLETE_QUERY';
export const changeAutoCompleteQuery = query => {
  return () => ({
    type: CHANGE_AUTO_COMPLETE_QUERY,
    query,
  });
};

export const TOGGLE_TBL_TYPE = 'TOGGLE_TBL_TYPE';
export const toggleTblType = tblId => () => ({ type: TOGGLE_TBL_TYPE, tblId });

export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const DELETE_PORTFOLIO_START = fetchStart(DELETE_PORTFOLIO);
export const DELETE_PORTFOLIO_SUCCESS = fetchSuccess(DELETE_PORTFOLIO);
export const DELETE_PORTFOLIO_ERROR = fetchError(DELETE_PORTFOLIO);
export const deletePortfolio = portfolioId => () => ({
  type: DELETE_PORTFOLIO,
  payload: {
    data: { portfolioId },
    promise: removePortfolio(portfolioId).then(() => ({ portfolioId })),
  },
});

export const SET_SHARES = 'SET_SHARES';
export const SET_SHARES_START = fetchStart(SET_SHARES);
export const SET_SHARES_SUCCESS = fetchSuccess(SET_SHARES);
export const SET_SHARES_ERROR = fetchError(SET_SHARES);
export const setShares = (portfolioId, ticker, shares, prevShares) => () => ({
  type: SET_SHARES,
  payload: {
    data: { portfolioId, ticker, shares },
    promise: changePortfolioHolding(portfolioId, ticker, shares, prevShares),
  },
});

export const SET_PURCHASE_PRICE = 'SET_PURCHASE_PRICE';
export const SET_PURCHASE_PRICE_START = fetchStart(SET_PURCHASE_PRICE);
export const SET_PURCHASE_PRICE_SUCCESS = fetchSuccess(SET_PURCHASE_PRICE);
export const SET_PURCHASE_PRICE_ERROR = fetchError(SET_PURCHASE_PRICE);
export const setPurchasePrice = (
  portfolioId,
  ticker,
  purchasePrice,
  prevPurchasePrice,
) => () => ({
  type: SET_PURCHASE_PRICE,
  payload: {
    data: { portfolioId, ticker, purchasePrice },
    promise: changePurchasePrice(
      portfolioId,
      ticker,
      purchasePrice,
      // prevPurchasePrice
    ),
  },
});

export const SET_CASH_VALUE = 'SET_CASH_VALUE';
export const SET_CASH_VALUE_START = fetchStart(SET_CASH_VALUE);
export const SET_CASH_VALUE_SUCCESS = fetchSuccess(SET_CASH_VALUE);
export const SET_CASH_VALUE_ERROR = fetchError(SET_CASH_VALUE);
export const setCashValue = (portfolioId, cashValue, prevCashValue) => () => ({
  type: SET_CASH_VALUE,
  payload: {
    data: { portfolioId, cashValue },
    promise: changeCashValue(portfolioId, cashValue, prevCashValue),
  },
});

export const APPLY_PORTFOLIO_NAME = 'APPLY_PORTFOLIO_NAME';
export const APPLY_PORTFOLIO_NAME_START = fetchStart(APPLY_PORTFOLIO_NAME);
export const APPLY_PORTFOLIO_NAME_SUCCESS = fetchSuccess(APPLY_PORTFOLIO_NAME);
export const APPLY_PORTFOLIO_NAME_ERROR = fetchError(APPLY_PORTFOLIO_NAME);
export const applyPortfolioName = (portfolioId, portfolioName) => () => ({
  type: APPLY_PORTFOLIO_NAME,
  payload: {
    promise: renamePortfolio(portfolioId, portfolioName).then(() => ({
      portfolioId,
      portfolioName,
    })),
  },
});

export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const ADD_PORTFOLIO_START = fetchStart(ADD_PORTFOLIO);
export const ADD_PORTFOLIO_SUCCESS = fetchSuccess(ADD_PORTFOLIO);
export const ADD_PORTFOLIO_ERROR = fetchError(ADD_PORTFOLIO);
export const addPortfolio = (portfolioName, shouldRedirect) => () => ({
  type: ADD_PORTFOLIO,
  payload: {
    promise: apiAddPortfolio(portfolioName)
      .then(adaptNewPortfolio)
      .then(portfolio => ({
        portfolio,
        shouldRedirect,
      })),
  },
});

export const SET_STOCK_ALERT = 'SET_STOCK_ALERT';
export const SET_STOCK_ALERT_START = fetchStart(SET_STOCK_ALERT);
export const SET_STOCK_ALERT_SUCCESS = fetchSuccess(SET_STOCK_ALERT);
export const SET_STOCK_ALERT_ERROR = fetchError(SET_STOCK_ALERT);
export const setStockAlertValue = (portfolioId, portfolioItem, value) => () => {
  const fn = value ? enableStockAlerts : disableStockAlerts;
  return {
    type: SET_STOCK_ALERT,
    payload: {
      data: { portfolioId, ticker: portfolioItem.get('ticker'), value },
      promise: fn(portfolioId, portfolioItem.get('ticker')).then(res => ({
        portfolioId,
        portfolioItem,
        value,
      })),
    },
  };
};

export const GET_AUTO_COMPLETE = 'GET_AUTO_COMPLETE';
export const GET_AUTO_COMPLETE_START = fetchStart(GET_AUTO_COMPLETE);
export const GET_AUTO_COMPLETE_SUCCESS = fetchSuccess(GET_AUTO_COMPLETE);
export const GET_AUTO_COMPLETE_ERROR = fetchError(GET_AUTO_COMPLETE);
export const fetchAutoComplete = query => {
  return () => ({
    type: GET_AUTO_COMPLETE,
    payload: {
      promise: getAutoComplete(query)
        .then(adaptAutoCompleteList)
        .then(dataList => ({ dataList })),
    },
  });
};

const findInDataList = (
  list: List<BetterRecord<{ value: string; label: string }>>,
  query: string,
) => {
  const itemByTicker = list.find(item =>
    item
      .get('value')
      .toLowerCase() === query.toLowerCase(),
  );
  if (itemByTicker) return itemByTicker;
  const itemByCompanyName = list.find(item =>
    item
      .get('label')
      .toLowerCase()
      .includes(query.toLowerCase()),
  );
  if (itemByCompanyName) return itemByCompanyName;
  return null;
};

export const ADD_PENDING_STOCKS_TO_ADD = 'ADD_PENDING_STOCKS_TO_ADD';
export const addPendingStocksToAdd = (query, dataList) => {
  if (query.length < 1) {
    if (process.env.IS_RUN_LOCAL)
      console.warn('addPendingStocksToAdd dispatched empty action...');
    return { type: '' };
  }
  const result = findInDataList(dataList, query);
  if (result) {
    return () => ({
      type: ADD_PENDING_STOCKS_TO_ADD,
      result,
    });
  } else {
    return () => ({
      type: GET_AUTO_COMPLETE,
      payload: {
        promise: getAutoComplete(query)
          .then(adaptAutoCompleteList)
          .then(dataList => {
            const addToPending = findInDataList(dataList, query);
            return {
              dataList,
              addToPending,
            };
          }),
      },
    });
  }
};

export const GET_STATS = 'GET_STATS';
export const GET_STATS_START = fetchStart(GET_STATS);
export const GET_STATS_SUCCESS = fetchSuccess(GET_STATS);
export const GET_STATS_ERROR = fetchError(GET_STATS);
export const fetchStats = () => {
  return () => ({
    type: GET_STATS,
    payload: {
      promise: getStats()
        .then(adaptStats)
        .then(appendSignature()),
    },
  });
};

export const GET_PORTFOLIOS = 'GET_PORTFOLIOS';
export const GET_PORTFOLIOS_START = fetchStart(GET_PORTFOLIOS);
export const GET_PORTFOLIOS_SUCCESS = fetchSuccess(GET_PORTFOLIOS);
export const GET_PORTFOLIOS_ERROR = fetchError(GET_PORTFOLIOS);
export const fetchPortfolios = () => {
  return () => ({
    type: GET_PORTFOLIOS,
    payload: {
      promise: getPortfolios()
        .then(async res => {
          if (!res.isExpert) {
            await makeUserAnExpert();
          }
          return res;
        })
        .then(res => res.portfolios)
        .then(appendSignature()),
    },
  });
};

// type Foo = typeof fetchPortfolios;

// type AfterPromise<T, F extends () => () => T> = () => T;

// declare function promisify<T, F extends () => () => T>(func: F): AfterPromise<T, F>;

// const x = promisify(fetchPortfolios);

export const GET_PORTFOLIO_ITEMS = 'GET_PORTFOLIO_ITEMS';
export const GET_PORTFOLIO_ITEMS_START = fetchStart(GET_PORTFOLIO_ITEMS);
export const GET_PORTFOLIO_ITEMS_SUCCESS = fetchSuccess(GET_PORTFOLIO_ITEMS);
export const GET_PORTFOLIO_ITEMS_ERROR = fetchError(GET_PORTFOLIO_ITEMS);
export const getPortfolioItems = (...args) => {
  const [portfolioId] = args;
  return () => ({
    type: GET_PORTFOLIO_ITEMS,
    portfolioId,
    payload: {
      portfolioId,
      promise: serverGetPortfolioItems(portfolioId)
        .then(adaptPortfolioItems('getPortfolioHoldingUserData'))
        .then(items => ({
          portfolioId,
          items,
        }))
        .then(appendSignature(args)),
    },
  });
};

export const GET_PORTFOLIO_ITEMS_BY_TICKERS = 'GET_PORTFOLIO_ITEMS_BY_TICKERS';
export const GET_PORTFOLIO_ITEMS_BY_TICKERS_START = fetchStart(
  GET_PORTFOLIO_ITEMS_BY_TICKERS,
);
export const GET_PORTFOLIO_ITEMS_BY_TICKERS_SUCCESS = fetchSuccess(
  GET_PORTFOLIO_ITEMS_BY_TICKERS,
);
export const GET_PORTFOLIO_ITEMS_BY_TICKERS_ERROR = fetchError(
  GET_PORTFOLIO_ITEMS_BY_TICKERS,
);
export const getPortfolioItemsByTickers = (portfolioId, tickers) => {
  return () => ({
    type: GET_PORTFOLIO_ITEMS_BY_TICKERS,
    payload: {
      promise: serverGetPortfolioItemsByTickers(portfolioId, tickers)
        .then(adaptPortfolioItems('getPortfolioHoldingUserData'))
        .then(items => ({
          portfolioId,
          items,
        })),
    },
  });
};

export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERTS_START = 'GET_ALERTS_START';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';
export const getAlerts = tickers => () => ({
  type: GET_ALERTS,
  payload: {
    promise:
      // TODO what the fuck
      (serverGetPortfolioItemsByTickers as any)(tickers).then(
        adaptPortfolioItems('getAlerts'),
      ),
  },
});

export const GET_STOCKS = 'GET_STOCKS';
export const GET_STOCKS_START = fetchStart(GET_STOCKS);
export const GET_STOCKS_SUCCESS = fetchSuccess(GET_STOCKS);
export const GET_STOCKS_ERROR = fetchError(GET_STOCKS);
export const getPortfolioHoldingStockData = (
  tickers: string[],
  portfolioType = TypesOfPortfolios.ActivePortfolio,
  ...rest
) => {
  if (!tickers.length) {
    if (process.env.IS_RUN_LOCAL) {
      console.warn('getPortfolioHoldingStockData dispatched empty action...');
    }
    return { type: '' };
  }

  return () => ({
    type: GET_STOCKS,
    payload: {
      data: {
        tickers,
        portfolioType,
      },
      promise: serverGetStocks(tickers)
        .then(adaptStocks('getPortfolioHoldingStockData'))
        .then(stocks => ({ stocks }))
        .then(appendSignature(...[tickers, portfolioType, ...rest]))
        .then(data => ({ ...data, portfolioType })),
    },
  });
};

export const getStocksWithNewsSentiment = (tickers: string[], ...rest) => {
  const args = [tickers, ...rest];
  if (!tickers.length) {
    if (process.env.IS_RUN_LOCAL) {
      console.warn('getStocksWithNewsSentiment dispatched empty action...');
    }
    return { type: '' };
  }

  return () => ({
    type: GET_STOCKS,
    payload: {
      data: {
        type: 'newsSentiment',
      },
      promise: Promise.all([
        getNewsSentimentForTickers(tickers),
        serverGetStocks(tickers),
      ])
        .then(([newsSentiments, data]) => ({
          newsSentiments,
          stocks: adaptStocks('getPortfolioHoldingStockData')(data),
        }))
        .then(({ newsSentiments, stocks }) =>
          addNewsSentimentToStocks(stocks, newsSentiments),
      )
        .then(stocks => ({ stocks }))
        .then(appendSignature(args)),
    },
  });
};

export const GET_DETAILS = 'GET_DETAILS';
export const GET_DETAILS_START = fetchStart(GET_DETAILS);
export const GET_DETAILS_SUCCESS = fetchSuccess(GET_DETAILS);
export const GET_DETAILS_ERROR = fetchError(GET_DETAILS);
export const fetchDetails = (
  tickers: string[],
  opts: { portfolioType?: TypesOfPortfolios; silent?: boolean },
) => {
  if (!tickers.length) {
    // TODO find all places that this happens.
    if (process.env.IS_RUN_LOCAL)
      console.error('This code path shouldnt run!!');
    return;
  }

  return () => ({
    type: GET_DETAILS,
    data: opts,
    payload: {
      data: opts,
      promise: Promise.all([
        getIsMarketOpened(),
        getDetails(tickers),
      ]).then(([isMarketOpened, details]) => ({ isMarketOpened, details })),
    },
  });
};

export const GET_PORTFOLIO_NEWS = 'GET_PORTFOLIO_NEWS';
export const GET_PORTFOLIO_NEWS_START = fetchStart(GET_PORTFOLIO_NEWS);
export const GET_PORTFOLIO_NEWS_SUCCESS = fetchSuccess(GET_PORTFOLIO_NEWS);
export const GET_PORTFOLIO_NEWS_ERROR = fetchError(GET_PORTFOLIO_NEWS);
export const fetchPortfolioNews = (
  tickers: string[],
  sentiment: 'positive' | 'negative' | 'all',
) => {
  const args = [tickers, sentiment];
  if (!tickers.length) {
    return { type: '' };
  }

  return () => ({
    type: GET_PORTFOLIO_NEWS,
    payload: {
      promise: getPortfolioNews(tickers, sentiment === 'all' ? 'na' : sentiment)
        .then(adaptPortfolioNews)
        .then(appendSignature(args)),
    },
  });
};

export const DELETE_STOCK = 'DELETE_STOCK';
export const DELETE_STOCK_START = fetchStart(DELETE_STOCK);
export const DELETE_STOCK_SUCCESS = fetchSuccess(DELETE_STOCK);
export const DELETE_STOCK_ERROR = fetchError(DELETE_STOCK);
export const deleteStock = (portfolioId, ticker) => () => ({
  type: DELETE_STOCK,
  payload: {
    data: { portfolioId, ticker },
    promise: removePortfolioStock(portfolioId, ticker).then(() => ({
      portfolioId,
      ticker,
    })),
  },
});

export const SET_STOCK_LAST_READ = 'SET_STOCK_LAST_READ';
export const SET_STOCK_LAST_READ_START = fetchStart(SET_STOCK_LAST_READ);
export const SET_STOCK_LAST_READ_SUCCESS = fetchSuccess(SET_STOCK_LAST_READ);
export const SET_STOCK_LAST_READ_ERROR = fetchError(SET_STOCK_LAST_READ);
export const setStockLastRead = (alertId, stockId, ticker) => () => ({
  type: SET_STOCK_LAST_READ,
  payload: {
    promise: apiSetStockLastRead(alertId, stockId),
    data: { ticker },
  },
});

export const GET_PORTFOLIO_EVENTS = 'GET_PORTFOLIO_EVENTS';
export const GET_PORTFOLIO_EVENTS_START = fetchStart(GET_PORTFOLIO_EVENTS);
export const GET_PORTFOLIO_EVENTS_SUCCESS = fetchSuccess(GET_PORTFOLIO_EVENTS);
export const GET_PORTFOLIO_EVENTS_ERROR = fetchError(GET_PORTFOLIO_EVENTS);
export const fetchPortfolioEvents = (tickers: string[]) => {
  const args = [tickers];
  if (!tickers.length) {
    return;
  }

  return () => ({
    type: GET_PORTFOLIO_EVENTS,
    payload: {
      promise: getPortfolioEvents(tickers)
        .then(adaptPortfolioEvents)
        .then(appendSignature(args)),
    },
  });
};

export const SEND_COMING_SOON_FEEDBACK = 'SEND_COMING_SOON_FEEDBACK';
export const SEND_COMING_SOON_FEEDBACK_START = fetchStart(
  SEND_COMING_SOON_FEEDBACK,
);
export const SEND_COMING_SOON_FEEDBACK_ERROR = fetchError(
  SEND_COMING_SOON_FEEDBACK,
);
export const SEND_COMING_SOON_FEEDBACK_SUCCESS = fetchSuccess(
  SEND_COMING_SOON_FEEDBACK,
);
export const sendComingSoonFeedback = feedback => {
  return () => ({
    type: SEND_COMING_SOON_FEEDBACK,
    payload: {
      promise: sendFeedback(feedback),
    },
  });
};

export const GET_PORTFOLIO_STATS = 'GET_PORTFOLIO_STATS';
export const GET_PORTFOLIO_STATS_START = fetchStart(GET_PORTFOLIO_STATS);
export const GET_PORTFOLIO_STATS_ERROR = fetchError(GET_PORTFOLIO_STATS);
export const GET_PORTFOLIO_STATS_SUCCESS = fetchSuccess(GET_PORTFOLIO_STATS);
export const getPortfolioStats = id => {
  return () => ({
    type: GET_PORTFOLIO_STATS,
    payload: {
      promise: apiGetPortfolioStats(id).then(stats => ({
        stats: adaptPortfolioStat(stats),
        id,
      })),
      data: { id },
    },
  });
};

export const GET_PORTFOLIO_WARNINGS = 'GET_PORTFOLIO_WARNINGS';
export const GET_PORTFOLIO_WARNINGS_START = fetchStart(
  'GET_PORTFOLIO_WARNINGS',
);
export const GET_PORTFOLIO_WARNINGS_ERROR = fetchError(
  'GET_PORTFOLIO_WARNINGS',
);
export const GET_PORTFOLIO_WARNINGS_SUCCESS = fetchSuccess(
  'GET_PORTFOLIO_WARNINGS',
);
export const getPortfolioWarnings = id => () => ({
  type: GET_PORTFOLIO_WARNINGS,
  payload: {
    promise: apiGetPortfolioWarnings(id).then(adaptPortfolioWarnings),
  },
});

export const GET_PORTFOLIO_BETA_RISK = 'GET_PORTFOLIO_BETA_RISK';
export const GET_PORTFOLIO_BETA_RISK_START = fetchStart(
  'GET_PORTFOLIO_BETA_RISK',
);
export const GET_PORTFOLIO_BETA_RISK_ERROR = fetchError(
  'GET_PORTFOLIO_BETA_RISK',
);
export const GET_PORTFOLIO_BETA_RISK_SUCCESS = fetchSuccess(
  'GET_PORTFOLIO_BETA_RISK',
);
export const getPortfolioBetaRisk = beta => () => ({
  type: GET_PORTFOLIO_BETA_RISK,
  payload: {
    promise: apiGetPortfolioBetaRisk(
      beta,
    ).then(
      ({
        percentageAboveOtherPortfolios,
      }: {
          percentageAboveOtherPortfolios: number;
        }) =>
        //new PortfolioRisk
        Map({ above: percentageAboveOtherPortfolios.toFixed(2) }),
    ),
  },
});

export const GET_NUM_PORTFOLIOS = 'GET_NUM_PORTFOLIOS';
export const GET_NUM_PORTFOLIOS_START = fetchStart('GET_NUM_PORTFOLIOS');
export const GET_NUM_PORTFOLIOS_ERROR = fetchError('GET_NUM_PORTFOLIOS');
export const GET_NUM_PORTFOLIOS_SUCCESS = fetchSuccess('GET_NUM_PORTFOLIOS');
export const getNumPortfolios = () => () => ({
  type: GET_NUM_PORTFOLIOS,
  payload: {
    promise: apiGetNumPortfolios(),
  },
});

export const GET_MGMT_FEES = 'GET_MGMT_FEES';
export const GET_MGMT_FEES_START = 'GET_MGMT_FEES_START';
export const GET_MGMT_FEES_ERROR = 'GET_MGMT_FEES_ERROR';
export const GET_MGMT_FEES_SUCCESS = 'GET_MGMT_FEES_SUCCESS';

export const FETCH_USER_PERFORMANCE = 'FETCH_USER_PERFORMANCE';
export const FETCH_USER_PERFORMANCE_SUCCESS = 'FETCH_USER_PERFORMANCE_SUCCESS';
export const FETCH_USER_PERFORMANCE_ERROR = 'FETCH_USER_PERFORMANCE_ERROR';
export const FETCH_USER_PERFORMANCE_START = 'FETCH_USER_PERFORMANCE_START';
export const fetchUserPerformance = portfolioId => () => ({
  type: FETCH_USER_PERFORMANCE,
  payload: { promise: getUserPerformance(portfolioId).then(fromJS) },
});

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const fetchUserExtendedProperties = () => () => ({
  type: FETCH_USER_INFO,
  payload: { promise: getUserExtendedProperties().then(fromJS) },
});

export const REALTIME_PRICES_UPDATE = 'REALTIME_PRICES_UPDATE';
export const updatePricesInRealTime = (payload: RealTimePrices) => () => ({
  type: REALTIME_PRICES_UPDATE,
  payload
});

export const SET_BASIC_TABLE_TYPE = 'SET_BASIC_TABLE_TYPE';
export const setBasicTableType = (basicTableType: BasicTableType) => () => ({
  type: SET_BASIC_TABLE_TYPE,
  payload: { basicTableType },
});

