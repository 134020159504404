import * as classNames from 'classnames';
import * as React from 'react';
import {
  DashboardStock,
  PortfolioItemRecord
} from 'sp/common/types';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export default class TableRow extends React.PureComponent<{
  isHovered: boolean;
  index: number;
  holdingsCols: any;
  mediaQuery: MediaQuery;
  onMouseEnter: any;
  onMouseLeave: any;
  openPortfolioItem: any;
  stock: PortfolioItemRecord;
}> {
  static propTypes = {};

  static defaultProps = {
    isHovered: false,
    onMouseEnter: () => null,
    onMouseLeave: () => null
  };

  clickHandler = () => {
    const { mediaQuery, openPortfolioItem, stock } = this.props;
    if (!mediaQuery.get('laptop')) {
      openPortfolioItem(stock.get('ticker'));
    }
  };

  onMouseEnter = event => {
    const { onMouseEnter, stock, index } = this.props;
    const { target } = event;
    onMouseEnter(stock.get('ticker'), index);
  };

  onMouseLeave = () => {
    const { onMouseLeave } = this.props;
    onMouseLeave();
  };

  render() {
    const { children, stock, index, isHovered } = this.props;
    return (
      <tr
        className={classNames(
          `type-${stock.get('stock').get('type')}`,
          // `hover-${index}`,
          { hover: isHovered }
        )}
        key={stock.get('ticker')}
        onClick={this.clickHandler}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {children}
      </tr>
    );
  }
}
