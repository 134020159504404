import * as _ from 'lodash';

const THEME = process.env.THEME === 'nasdaq' ? 'Nasdaq' : 'TipRanks';

export const config = {
  // stock screener inner details custom3
  bestAnalystConsensus: {
    title: 'Best Performing Analyst Consensus',
    subtitle:
      'Access the top analyst consensus and price targets for your stocks',
    img: ''
  },
  bestTarget: {
    title: 'Best Performing Analyst Price Target',
    subtitle:
      'Access the top analyst consensus and price targets for your stocks',
    img: ''
  },
  hedgefundSentiment: {
    title: 'See the Hedge Fund Sentiment',
    subtitle:
      'Access the blogger sentiment, insider activity and hedge fund trends for your stocks',
    img: ''
  },
  insiderSentiment: {
    title: 'See the Insider Sentiment',
    subtitle:
      'Access the blogger sentiment, insider activity and hedge fund trends for your stocks',
    img: ''
  },
  bloggerSentiment: {
    title: 'See the Blogger Consensus',
    subtitle:
      'Access the blogger sentiment, insider activity and hedge fund trends for your stocks',
    img: ''
  },
  newsSentiment: {
    title: 'See the News Sentiment',
    subtitle: 'Get a snapshot of the current news sentiment for this stock',
    img: ''
  },
  // etf screener inner details
  managementFee: {
    title: 'Stop Losing Money on Management Fees',
    subtitle:
      'Reduce your fees by replacing your ETFs and mutual funds with lower cost alternatives',
    img: ''
  },
  mgmtFees: {
    title: 'Stop Losing Money on Management Fees',
    subtitle:
      'Reduce your fees by replacing your ETFs and mutual funds with lower cost alternatives',
    img: 'add-portfolio'
  },
  // screener filters custom3
  'stock-screener-filter': {
    title: 'Unlock TipRanks Premium Filters',
    subtitle: 'Screen stocks according to analyst and expert sentiment',
    img: 'screener-filters'
  },
  'etf-screener-filter': {
    title: 'Unlock TipRanks Premium Filters',
    subtitle: 'Screen stocks according to analyst and expert sentiment',
    img: 'screener-filters'
  },
  // general custom3
  alertsSettings: {
    title: 'Get More Notifications',
    subtitle:
      'Receive real-time email notifications of top analysts covering your stocks',
    img: ''
  },
  alertsSettingsRanking: {
    title: 'Get Higher Ratings!',
    subtitle:
      'Receive real-time email notifications of top analysts covering your stocks',
    img: ''
  },
  'crowd-insights': {
    title: `Learn from the Best Portfolios on ${THEME}`,
    subtitle:
      'Unlock the portfolios of investors that consistently outperformed the market',
    img: 'crowd-insights'
  },
  newsSentimentsTopStocks: {
    title: 'Find out Which Stocks Are Trending in the News',
    subtitle:
      'Go Premium and get stocks that are strongly trending in the news ',
    img: 'similar-stocks'
  },
  // holdings/news custom3
  portfolioNews: {
    title: 'Start the Day with Top News',
    subtitle:
      'Stay up to date with bullish and bearish news about your holdings at real-time',
    img: 'similar-stocks'
  },
  portfolios: {
    title: 'Add More Portfolios',
    subtitle: 'Create up to 5 portfolios to better organize your holdings',
    img: 'add-portfolio'
  },
  'sp-holdings-news-media-buzz': {
    title: 'Navigate Media Storms',
    subtitle:
      'Learn and get updated instantly about the current media buzz for your stocks',
    img: 'news-sentiments'
  },
  'sp-holdings-news-news-score': {
    title: 'One Score to Know It All',
    subtitle:
      'Understand the News Sentiment and Media Buzz instantly with News Score',
    img: 'news-sentiments'
  }
  // an example of syntax for reflecting something that exists:
  // get 'etf-screener-filter'() { return config['something-else']; }
};

export const title = _.mapValues(config, 'title');
export const subtitle = _.mapValues(config, 'subtitle');

export type TargetedWebPageKey = keyof typeof config;

export const noDifferentImageForResolutions = ['bullish-bearish-tabs'];

/*
 * supposedly, all possible "gopro" pages:
 * /crowdinsights/bestportfolios/sp-crowd-insights-best-portfolio
 * /crowdinsights/bestportfolios/add-portfolio-limit
 * /myholdings/basic/basicholdings-best-analyst-consensus-and-pt
 * /myholdings/basic/basicholdings-insider-sentiment
 * /myholdings/basic/basicholdings-hedge-fund-sentiment
 * /myholdings/basic/basicholdings-blogger-consensus
 * /myholdings/basic/add-portfolio-limit
 * /myholdings/details/detailedholdings-best-analyst-consensus
 * /myholdings/details/detailedholdings-best-analyst-pt
 * /myholdings/details/detailedholdings-insider-sentiment
 * /myholdings/details/detailedholdings-hedge-fund-sentiment
 * /myholdings/details/detailedholdings-blogger-consensus
 * /myholdings/details/add-portfolio-limit
 * /myholdings/news/sp-holdings-bullish-news
 * /myholdings/news/sp-holdings-bearish-news
 * /myholdings/news/sp-holdings-news-score
 * /myholdings/news/sp-holdings-media-buzz
 * /myholdings/news/sp-holdings-trending-similar-stocks
 * /myholdings/news/add-portfolio-limit (take the calendar button and use it here as well)
 * /overview/sp-overview-tab-bullish
 * /overview/sp-overview-tab-bearish
 * /overview/add-portfolio-limit
 * /myportfolioanalysis/etf/sp-management-fees
 * /myportfolioanalysis/etf/add-portfolio-limit
 * /myportfolioanalysis/portfolio/sp-management-fees
 * /myportfolioanalysis/portfolio/add-portfolio-limit
 * /crowdinsights/bestportfolios/sp-crowd-insights-best-portfolio
 * /crowdinsights/bestportfolios/add-portfolio-limit
 * /myperformance/
 * /screener/filters/sp-etf-screener-filter-mgmt-fees
 * /screener/filters/screenerfilter-best-analyst-consensus
 * /screener/filters/screenerfilter-blogger-consensus
 * /screener/filters/screenerfilter-insider-signal
 * /screener/filters/screenerfilter-hedge-fund-signal
 * /screener/filters/screenerfilter-news-sentiment
 * /screener/basicresults/sp-etf-screener-results-management-fees
 * /screener/basicresults/basicresults-top-analyst-consensus
 * /screener/basicresults/basicresults-top-analyst-pt
 * /screener/basicresults/basicresults-blogger-consensus
 * /screener/basicresults/basicresults-insider-signal
 * /screener/basicresults/basicresults-hedge-fund-signal
 * /screener/basicresults/basicresults-news-sentiment
 * /screener/detailsresults/detailedresults-top-analyst-consensus
 * /screener/detailsresults/detailedresults-top-analyst-pt
 * /screener/detailsresults/detailedresults-blogger-consensus
 * /screener/detailsresults/detailedresults-insider-signal
 * /screener/detailsresults/detailedresults-hedge-fund-signal
 * /screener/detailsresults/detailedresults-news-sentiment
 * /others/sp-sign-up-thank-you-plans-btn
*/
