export { default as headerMsg } from '../tr-dashboard-header/msg';

export default {
  general: {
    signup: 'Sign Up',
    login: 'Login',
    logout: 'Log out'
  },
  search: {
    mobileSearchPlaceHolder: 'Search Expert or Stock'
  },
  register: {
    title: 'Sign Up'
  },
  login: {
    title: 'Log in'
  },
  logout: {
    title: 'Log out'
  },
  'upgrade-light': {
    title: 'TipRanks'
  }
};

export const __hotReload = true;
