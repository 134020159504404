import * as classNames from 'classnames';
import * as React from 'react';
import { href, routeNavDefaultSvgParams } from 'sp/browser/RouteNav/utils';
import SvgImg from 'sp/browser/tr-svg-img/SvgImg.react';
import { dashboardPath, isOpenRoute } from 'sp/common/config';
import { AuthRecord } from 'sp/common/types';
import Clickable from 'tipranks-clickable';
import './index.styl';

export class RouteNavItem extends React.PureComponent<{
  msg: any,
  toggleRouteNav: any,
  activeRoute: any,
  register: any,
  theme: any,
  isLoggedIn: any,
  isHoldings: any,
  item: any,
  auth: AuthRecord,
}> {
  render() {
    const {
      msg,
      toggleRouteNav,
      activeRoute,
      register,
      theme,
      isLoggedIn,
      isHoldings,
      item,
      auth,
    } = this.props;

    const url = item.url
      ? item.url
      : item.urlFromAuth
        ? item.urlFromAuth(auth)
        : item.id;

    const isAbsoluteUrl = url && /^https?:\/\//i.test(url);
    const absolutelyForced = typeof item.forceAbsoluteness === 'boolean'
      ? item.forceAbsoluteness
        ? 'href'
        : 'to'
      : null;
    const locationDescriptor = item.disabled && item.disabled(auth)
      ? {
        disabled: true,
        title: item.whyDisabled(auth) || ''
      }
      : isAbsoluteUrl
        ? { target: '_blank', href: url }
        : url.startsWith('/')
          ? { [absolutelyForced || 'to']: url }
          : { [absolutelyForced || 'to']: href([dashboardPath, url]) };

    return <li className={classNames(`li-${item.id}`, item.className)} key={item.id}>
      <Clickable
        activeClassName="active"
        onClick={
          (/* isAuth(activeRoute) &&  */isLoggedIn && isHoldings) || isOpenRoute(item.id)
            ? toggleRouteNav
            : register
        }
        state={{ directLink: true }}
        {...locationDescriptor}
      >
        <div className="route-icon">
          <SvgImg
            id={`dashboard-${item.id}`}
            keyId={`navItem_${item.id}`}
            params={routeNavDefaultSvgParams({ width: '19', })}
          />
        </div>
        <span>{msg[item.id]}</span>
      </Clickable>
    </li>;
  }
}