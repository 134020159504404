
export const form = {
  name: 'public_name',
  desc: 'public_desc',
  portfolio: 'public_portfolio',
}

export const defaultPortfolioIdValue = -1;

export type OnboardingForm = {
  fullName: string,
  portfolioId: number,
  bio: string,
}

export type OnboardingPortfolioDatum = { id: number, name: string };

export type OnboardingPortfolios = OnboardingPortfolioDatum[];