import React from 'react';
import PropTypes from 'prop-types';
import Component from '../ConventionComponent.react';
import { AssetBox } from './AssetBox.react.tsx';
import { imgSrc } from '../../../../../common/lib/utils.ts';
import { Spacer } from './Spacer.react';
import { NoData } from './NoData.react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const messages = {
  2: '{ticker} decreased {percentage} in the last week',
  1: 'Analyst issues a {rating} rating on {ticker}'
};

const StockWarningsBody = NoData(({ warnings, msg }) => {
  return (
    <div className="stock-warning-wrapper">
      {warnings.map((warning, i) => {
        const { ticker, date } = warning;
        const {
          percentage = 0,
          ratingId = 0,
          rating = 'strongbuy'
        } = warning.data.toObject();
        const capitalise = str => str.charAt(0).toUpperCase() + str.slice(1);
        return (
          <div key={ticker + warning.warningType + date.toString()} className="warning">
            <img src={imgSrc('error')} />
            <FormattedMessage
              defaultMessage={messages[warning.warningType]}
              id={i.toString()}
              key={i}
              className="warnings-line-text"
              values={{
                rating: capitalise(rating),
                ticker: <b>{ticker}</b>,
                percentage: (
                  <span className="amount negative">
                    <FormattedNumber
                      style="percent"
                      value={percentage / 100}
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                    />
                  </span>
                )
              }}
            />
            <span className="warnings-line-right">
              {date.toLocaleDateString('en')}
            </span>
          </div>
        );
      })}
    </div>
  );
});

export default class StockWarnings extends AssetBox(
  'asset-box-stock-warnings',
  ['portfolioStats', 'warnings', 'details']
) {
  head() {
    return <h3>Stock Warnings (Stocks at Risk)</h3>;
  }
  body() {
    if (!this.props.warnings) {
      return <div>Loading...</div>;
    }
    const warnings = this.props.warnings.filter(x => x.data);
    return (
      <StockWarningsBody
        msg={this.props.msg}
        hasData={warnings.size}
        noDataMsg="Currently, no warnings"
        warnings={warnings.take(10)}
      />
    );
  }
}
