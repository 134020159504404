import * as classNames from 'classnames';
import * as React from 'react';
import * as styles from './WaitingNotice.istyl';
import * as arrows from './spinning-arrows.svg';


interface WaitingNoticeProps {
  className?: string;
}

export class WaitingNotice extends React.Component<WaitingNoticeProps> {
  public render() {
    return (
      <div className={classNames(this.props.className, styles.waitingNotice)}>
        <h2>
          We are waiting for confirmation from <strong>Interactive Brokers</strong>, this process usually takes up to 48 hours.
        </h2>
        <img src={arrows} alt="Still waiting" />
      </div>
    );
  }
}