export default {
  columnNames: {
    expert: 'Expert',
    expertType: 'Expert Type',
    sector: 'Sector',
    averageReturn: 'Average Return',
    successRate: 'Success Rate',
    alerts: 'Alerts',
    notifications: 'Notifications',
    delete: 'Delete'
  },
  columnNamesShort: {
    averageReturn: 'Av. Return'
  },
  expertTypes: {
    analyst: 'Analysts',
    blogger: 'Bloggers',
    insider: 'Insiders',
    hedgefund: 'Hedge Funds'
  },
  banner: {
    orangeText: 'Top Expert Center',
    whiteText:
      'Get insights from the best performing analysts, follow them and get an email notification',
    whiteTextExtra: ' whenever they post a recommendation',
    button: 'Visit Expert Center'
  }
};
