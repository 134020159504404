import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import GlossaryLink from '../GlossaryLink';

import { brandName } from '../../common/config';

import Loader from '../components/suit/Loader.react';
import PortfolioItemSelector from '../PortfolioItemSelector/PortfolioItemSelector.react';
// import { List } from 'immutable';
import { Portfolio } from './types';
import Company from './company';
import { Theme } from 'sp/common/types';
import { List } from 'immutable';

interface Props {
  aboveBeta: boolean;
  betaValue: number;
  companyBetaValue: number;
  companyPortfolioYield: number;
  companyTopDividendsStocks: List<any>;
  companyVolatileStocks: List<any>;
  getBlock: (beta: number) => { color: string };
  headIconGr: string;
  portfolioYield: number;
  theme: Theme;
  topDividendsStocks: List<any>;
  volatileStocks: List<any>;
  selectedPortfolio: Portfolio;
}

// TODO understand this.
function AboveBetaDescription({ aboveBeta, percentageColor }) {
  const risky = aboveBeta > 0.5; // WHAT
  return (
    <span>
      Your Portfolio is {risky ? 'more' : 'less'} volatile
      <br />
      than{' '}
      <span style={{ color: percentageColor }}>
        {aboveBeta > 0 ? (
          <FormattedNumber
            maximumFractionDigits={0}
            style="percent"
            value={risky ? aboveBeta : 1 - aboveBeta}
          />
        ) : (
          '-'
        )}
      </span>{' '}
      of other {brandName} portfolios
    </span>
  );
}

export class CrowdInsightsAside extends React.PureComponent<Props> {
  public render() {
    const {
      aboveBeta,
      betaValue,
      companyBetaValue,
      companyPortfolioYield,
      companyTopDividendsStocks,
      companyVolatileStocks,
      getBlock,
      headIconGr,
      portfolioYield,
      theme,
      topDividendsStocks,
      volatileStocks,
      selectedPortfolio
    } = this.props;
    return (
      <div className="lt2b-aside">
        <div className="lt2b-aside-block lt2b-aside-left-block">
          <div className="lt2b-aside-block-header">
            <h3 className="section-header-alt-1-h1">
              Portfolio Risk (Beta){' '}
              <small>
                <i className="fa fa-info-circle" />
              </small>
            </h3>
          </div>
          <div className="lt2b-above-beta">
            <AboveBetaDescription
              aboveBeta={aboveBeta}
              percentageColor={getBlock(betaValue).color}
            />
          </div>
          <div className="lt2b-aside-sub-block lt2b-myportfolio-risk">
            <div className="lt2b-datas-block">
              <span className="aside-sub-block-small-title">
                <span
                  className="real-value"
                  style={{ color: getBlock(betaValue).color }}
                >
                  {betaValue ? (
                    <FormattedNumber
                      maximumFractionDigits={2}
                      minimumFractionDigits={2}
                      value={betaValue}
                    />
                  ) : (
                    'N/A'
                  )}
                </span>
              </span>
              <h4>
                <img src={headIconGr} /> My Portfolio Risk
              </h4>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <table className="lt2b-aside-table">
                  <thead>
                    <tr>
                      <th>Most Volatile Stocks</th>
                      <th>Beta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {volatileStocks.size === 0 && (
                      <tr>
                        <td colSpan={2}>
                          <div className="no-data no-data-volatile">
                            No volatile stocks
                          </div>
                        </td>
                      </tr>
                    )}
                    {volatileStocks.map((item, index) => (
                      <tr key={item.get('ticker')}>
                        <td className="volatile-stocks-td">
                          <div className="volatile-stocks-wrapper">
                            <span className="volatile-stocks-index">
                              {index + 1}.
                            </span>{' '}
                            <span className="volatile-stocks-name">
                              {item.get('name')}
                            </span>{' '}
                            (<PortfolioItemSelector
                              item={item}
                              type="ticker"
                              target="_blank"
                            />)
                          </div>
                        </td>
                        <td>
                          <FormattedNumber
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            value={item.getIn(['stock', 'price', 'beta'])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="lt2b-aside-sub-block lt2b-before-graph">
            <div className="lt2b-datas-block">
              <span className="aside-sub-block-small-title">
                <span
                  className="real-value"
                  style={{ color: getBlock(companyBetaValue).color }}
                >
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={companyBetaValue}
                  />
                </span>
              </span>
              <h4>
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>
                    <b>
                      <Company theme={theme} icon />
                    </b>{' '}
                    Average Portfolio Risk
                  </span>
                ) : (
                  <span>
                    <b>
                      <span className="best-performing">Best Performing</span>{' '}
                      <Company theme={theme} />
                    </b>{' '}
                    Portfolio Risk
                  </span>
                )}
              </h4>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <table className="lt2b-aside-table">
                  <thead>
                    <tr>
                      <th>Most Volatile Stocks</th>
                      <th>Beta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyVolatileStocks.map((item, index) => (
                      <tr key={item.get('ticker')}>
                        <td>
                          {index + 1}. &nbsp;
                          {item.get('name')}
                          &nbsp; (<PortfolioItemSelector
                            item={item}
                            type="ticker"
                            target="_blank"
                          />)
                        </td>
                        <td>
                          <FormattedNumber
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            value={item.getIn(['stock', 'price', 'beta'])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="lt2b-aside-block lt2b-aside-right-block">
          <div className="lt2b-aside-block-header">
            <h3 className="section-header-alt-1-h1">
              Portfolio Dividends<small>
                <i className="fa fa-info-circle" />
              </small>
            </h3>
          </div>
          <div className="lt2b-aside-sub-block lt2b-my-dividend-yield">
            <div className="lt2b-datas-block">
              <span className="aside-sub-block-small-title">
                <span className="real-value">
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    style="percent"
                    value={portfolioYield}
                  />
                </span>
              </span>
              <h4>
                <img src={headIconGr} /> My Portfolio Dividend Yield
              </h4>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <table className="lt2b-aside-table lt2b-aside-sub-block2">
                  <thead>
                    <tr>
                      <th>Highest Yielding Stocks</th>
                      <th>Yield</th>
                      <th className="th-amount">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {volatileStocks.size === 0 && (
                      <tr>
                        <td colSpan={3}>
                          <div className="no-data no-data-volatile">
                            No dividend holdings
                          </div>
                        </td>
                      </tr>
                    )}
                    {topDividendsStocks.map((item, index) => (
                      <tr key={item.get('ticker')}>
                        <td>
                          {index + 1}. &nbsp;
                          {item.get('name')}
                          &nbsp; (<PortfolioItemSelector
                            item={item}
                            type="ticker"
                            target="_blank"
                          />)
                        </td>
                        <td>
                          <FormattedNumber
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            style="percent"
                            value={item.getIn(['stock', 'yield'])}
                          />
                        </td>
                        <td className="td-amount">
                          <FormattedNumber
                            currency="usd"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            style="currency"
                            value={item.getIn(['stock', 'dividend'])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="lt2b-aside-sub-block lt2b-avg-dividend-yield">
            <div className="lt2b-datas-block">
              <span className="aside-sub-block-small-title">
                <span className="real-value">
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    style="percent"
                    value={companyPortfolioYield}
                  />
                </span>
              </span>
              <h4>
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>
                    <b>
                      <Company theme={theme} icon />
                    </b>{' '}
                    Average Portfolio Dividend Yield
                  </span>
                ) : (
                  <span>
                    <b>
                      <span className="best-performing">Best Performing</span>{' '}
                      <Company theme={theme} />
                    </b>{' '}
                    Portfolio Dividend Yield
                  </span>
                )}
              </h4>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <table className="lt2b-aside-table lt2b-aside-sub-block2">
                  <thead>
                    <tr>
                      <th>Most Popular Dividend Stocks</th>
                      <th>Yield</th>
                      <th className="th-amount">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyTopDividendsStocks.map((item, index) => (
                      <tr key={item.get('ticker')}>
                        <td>
                          {index + 1}. &nbsp;
                          {item.get('name')}
                          &nbsp; (<PortfolioItemSelector
                            item={item}
                            type="ticker"
                            target="_blank"
                          />)
                        </td>
                        <td>
                          <FormattedNumber
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            style="percent"
                            value={item.getIn(['stock', 'yield'])}
                          />
                        </td>
                        <td className="td-amount">
                          <FormattedNumber
                            currency="usd"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            style="currency"
                            value={item.getIn(['stock', 'dividend'])}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <p>
              <GlossaryLink slug="dividends">Learn More&gt;</GlossaryLink>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CrowdInsightsAside;
