import { Actions, Plan } from '../../common/types';
import { connect } from 'react-redux';
import * as React from 'react';
import Clickable from 'tipranks-clickable';
import msg from './en';
import './auth-menu.styl';
import report from './analytics';
import OnOutsideClick from '../lib/on-outside-click.react';
import { Icon } from 'tipranks-icons';
import { shouldShowMyAccountBtn } from '../../common/config';
import { TipranksGoPremiumLink } from './PremiumButton';
import { TipranksGoUltimateLink } from './UltimateButton';

const emailRegex = /^.+@.+\..+$/;
const isEmail = str => emailRegex.test(str);

const plans = {
  open: 'open',
  free: 'free',
  premium: 'premium',
  ultimate: 'ultimate',
};

@connect(state => ({
  plan: state.auth.getIn(['users', 'viewer', 'plan']),
  email: state.auth.getIn(['users', 'viewer', 'loggedInUser', 'email']),
}))
export default class AuthMenu extends React.PureComponent<
{
  pageName?: string;
  actions: Actions; //PropTypes.object,
  email?: string; //PropTypes.string,
  isLoggedIn: boolean; //PropTypes.bool,
  plan?: Plan; //PropTypes.string,
  signUpOnly: boolean; //PropTypes.bool,
  premiumUpgradeUrl: string; //PropTypes.string
  className?: string;
},
{ hover: boolean }
> {
  state = {
    hover: false,
  };
  static defaultProps = {
    isLoggedIn: false,
  };

  onOutsideClick = () => {
    this.setState({ hover: false });
  };

  onClick = e => {
    if (e.target.classList.contains('account-button')) return;
    this.setState({ hover: true });
  };

  onRegister = () => {
    report('register');
    this.props.actions.onRegister();
  };

  onLogin = () => {
    report('login');
    this.props.actions.onLogin();
  };

  onLogout = () => {
    report('logout');
    this.props.actions.onLogout();
  };

  renderOpenUser() {
    const buttons = [
      <button key="register" onClick={this.onRegister}>
        Sign Up
      </button>,
    ];

    if (!this.props.signUpOnly) {
      (buttons.unshift as any)(' / ');
      buttons.unshift(
        <button key="login" onClick={this.onLogin}>
          Login
        </button>,
      );
    }

    return <div className="main-button">{buttons}</div>;
  }

  get planName() {
    return {
      [plans.free]: msg.free,
      [plans.premium]: msg.premium,
      [plans.ultimate]:
        process.env.THEME === 'nasdaq' ? msg.premium : msg.ultimate,
    }[this.props.plan || plans.free];
  }

  upgradeLinkTsx({ plan, pageName  } = this.props) {
    const isNasdaq = process.env.THEME === 'nasdaq';
    if (isNasdaq && (!plan || plan === plans.free || plan === plans.open)) {
      return (
        <span className="upgrade">
          <Clickable
            href={'/smart-portfolio/plans?llf=header-dropdown-upgrade-link'}
          >
            {msg.upgrade}
          </Clickable>
        </span>
      )
    }
    if (!plan || plan === plans.free || plan === plans.open) {
      return (
        <span className="upgrade">
          <TipranksGoPremiumLink
            pageName={pageName}
            featureOrOrigin={'header-dropdown-upgrade-link'}
            >
            {msg.upgrade}
          </TipranksGoPremiumLink>
        </span>
      )
    }
    if (plan === plans.premium && process.env.THEME !== 'nasdaq') {
      return (
        <span className="upgrade">
          <TipranksGoUltimateLink
            pageName={pageName}
            featureOrOrigin={'header-dropdown-upgrade-link'}
          >
            {msg.upgrade}
          </TipranksGoUltimateLink>
        </span>
      )
    }

    return null; // Should never be reached. But who am I to judge...
  }

  renderMenu({ plan } = this.props) {

    return (
      <ul>
        <li>
          <div className="welcomee">{msg.welcome}</div>
          <div className="email">
            {(isEmail(this.props.email) && this.props.email) ||
              (isEmail(localStorage.getItem('kmI')) &&
                localStorage.getItem('kmI'))}
          </div>
          <div>
            <span className="plan-name">{this.planName} Plan</span>
            {this.upgradeLinkTsx()}
          </div>
        </li>
        {!shouldShowMyAccountBtn ? null : (
          <li className="account">
            <Clickable
              className="account-button button"
              href="/account/settings"
            >
              <Icon icon="gear" size={18} />
              My Account
            </Clickable>
          </li>
        )}
        <li className="logout">
          <button onClick={this.onLogout}>
            <img src={require('./logout-icon.png')} />
            {msg.logOut}
          </button>
        </li>
      </ul>
    );
  }

  renderAuthorized() {
    return (
      <div>
        <div className="main-button">{this.planName}</div>
        <div className="plan-menu">{this.renderMenu()}</div>
      </div>
    );
  }

  renderComponent({ plan, isLoggedIn } = this.props) {
    if (!isLoggedIn) return this.renderOpenUser();
    switch (plan) {
      case plans.free:
      case plans.premium:
      case plans.ultimate:
        return this.renderAuthorized();

      case plans.open:
      default:
        return this.renderOpenUser();
    }
  }

  render() {
    const { isLoggedIn, plan } = this.props;
    return (
      <div
        className={`tr-auth-menu ${isLoggedIn ? plan : 'open'} ${this.state
          .hover
          ? 'hover'
          : ''} isLoggedIn-${isLoggedIn}`}
        onClick={this.onClick}
        onMouseEnter={this.onClick}
        onMouseLeave={this.onOutsideClick}
      >
        <OnOutsideClick
          style={{ display: 'inline-block', width: 'auto' }}
          listener={this.onOutsideClick}
        >
          {this.renderComponent()}
        </OnOutsideClick>
      </div>
    );
  }
}
