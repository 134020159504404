import React from 'react';
import PropTypes from 'prop-types';
import { AssetBox } from './AssetBox.react.tsx';
import BarSlider from './BarSlider.react';
import Spacer from './Spacer.react';
import RiskContainer from './RiskContainer.react';

export default class NoOfTrades extends AssetBox('asset-box-no-of-trades', [
  'portfolioStats'
]) {
  static propTypes = {
    changes: PropTypes.number,
    avgChanges: PropTypes.number
  };
  head() {
    return (
      <span>
        <h3>No. of Trades</h3>
        {this.headerTooltip(
          'All the trades you have made since the inception of your TipRanks portfolio.'
        )}
      </span>
    );
  }
  body() {
    return (
      <div>
        <RiskContainer isOrange={true} avg={29} value={this.props.changes} />
      </div>
    );
  }
}
