import { get } from 'lodash';
import * as React from 'react';
import Clickable from 'tipranks-clickable';

import { assetAllocationGloassary } from '../../../../decisions/index.ts';
import InfoTooltip from '../../../../InfoTooltip/index.tsx';
import AllocChart from './AllocChart/src/App.js';
import assetIds from './assetIds';
import HoldingsChart from './HoldingsChart/src/index.tsx';
import { Loading } from './Loading.react.tsx';
import msg from './msg';
import { NoData } from './NoData.react';

const interpolate = (str, items) => str.split(/[{}]/g).map(s => items[s] || s);

const HoldingsChartWithNoData = NoData(HoldingsChart);
const AllocChartWithNoData = NoData(AllocChart);

export default class AssetAllocation extends React.PureComponent {
  render() {
    return (
      <div className={`asset-allocation`}>
        <AssetAllocationHeader />
        <div className="asset-allocation-body">
          <AssetAllocationBody {...this.props} />
        </div>
      </div>
    );
  }
}

@Loading('portfolios', 'portfolioItems', 'stocks')
class AssetAllocationBody extends React.PureComponent {
  onAddStocksClicked = () => {
    const { onAddStocksClicked } = this.props;
    if (onAddStocksClicked) onAddStocksClicked();
  };

  render(
    {
      activeAsset,
      chartData,
      colors,
      onChange,
      holdingsChartData,
      isSwipe,
      actions
    } = this.props
  ) {
    return (
      <div>
        <div className="alloc-chart-component-wrapper">
          <AllocChartWithNoData
            active={activeAsset}
            hasData={chartData.stockAllocation.some(x => x.get('count'))}
            noDataMsg={msg.allocChart.noData}
            onAddStocksClicked={this.props.onAddStocksClicked}
            colors={colors.get('alloc')}
            initialActive={assetIds.assetId_stock}
            msg={msg.allocChart}
            onChange={onChange}
            chartData={chartData}
          />
        </div>
        <div className="holdings-chart-component-wrapper">
          <HoldingsChartWithNoData
            hasData={get(this.props, 'holdingsChartData.size')}
            noDataMsg={msg.holdingsChart[activeAsset].noData}
            colors={colors.get('holding')}
            isSwipe={isSwipe}
            msg={msg.holdingsChart[activeAsset]}
            asset={holdingsChartData}
            activeAsset={activeAsset}
          />
        </div>
      </div>
    );
  }
}

class AssetAllocationHeader extends React.PureComponent {
  render() {
    return (
      <header className="asset-allocation-header">
        <h2>Asset Allocation</h2>
        <InfoTooltip msg="View the distribution of your holdings among stocks, ETFs, and mutual funds. Click on an asset type for more details." />
        <Clickable target="_blank" to={assetAllocationGloassary}>
          Why asset allocation is important, learn more >
        </Clickable>
      </header>
    );
  }
}
