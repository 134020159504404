import reporter from '../analytics';

const events = {
  saw: 'saw-unsure-about-upgrading-pop-up',
  clickedDismiss: 'clicked-unsure-about-upgrading-pop-up-checkbox'
};

const report = reporter(events);

export default report;
