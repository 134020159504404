import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

if (process.env.IS_BROWSER) require('./style.styl');

export default class Confirm extends Component {
  static propTypes = {
    cancelHandler: PropTypes.func,
    cancelMsg: PropTypes.string,
    okHandler: PropTypes.func,
    okMsg: PropTypes.string,
    question: PropTypes.any,
    title: PropTypes.string
  };

  render() {
    const {
      cancelHandler,
      cancelMsg,
      okHandler,
      okMsg,
      question,
      title
    } = this.props;

    return (
      <section className="confirm">
        <header>
          <h1>{title}</h1>
        </header>
        <p className="question">{question}</p>
        <div className="controls">
          <button className="ok" onClick={okHandler}>
            {okMsg}
          </button>
          <button className="cancel" onClick={cancelHandler}>
            {cancelMsg}
          </button>
        </div>
      </section>
    );
  }
}
