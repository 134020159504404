import * as  React from 'react';
import Graph_ from './external';

export default class TrStockBehaviour extends React.PureComponent<{ ticker: string }> {
  render() {
    const { ticker } = this.props;
    const Graph = Graph_ as any;
    return <Graph ticker={ticker} />;
  }
}
