import { getReq, autocomplete } from '../../common/api';
import { createQueryString } from 'sp/common/lib/utils';

let cache = { url: '', data: null };

// TODO this is identical to browser/autocomplete
/**
 * this one fetches data for the global search in the header.
 */
export const getDataList = async (query, params) => {
  const url = autocomplete(
    createQueryString({ ...params, name: encodeURIComponent(query) }, `GetAutoCompleteNoSecondary`)
  );

  if (cache.url === url) {
    return cache.data;
  }

  cache.url = url;
  cache.data = await fetch(url).then(res => res.json());

  return cache.data;
};
