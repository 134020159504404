import { verifySuccessCBParameters } from './PlaidInputValidators';
import { PlaidSuccessConfig, TipRanksPlaidCB, UpdateActionName, FlowType } from './PlaidTypes';
import { BASE_URL } from './BASE_URL';
import { clearPlaidTokenFromStorage } from './TokenStorageManager';
import { responseToGeneralErrorPlaid } from './responseToGeneralErrorPlaid';
import { getGlobalActions } from "sp/browser/lib/actions";

export function removeOAuthStateIdFromUrl() { 
  if (typeof URLSearchParams !== 'undefined') {
    const { protocol, host, pathname }= window.location
    window.history.pushState(null, document.title,`${protocol}//${host}${pathname}`)
  }
} 

export async function onPlaidAuthSuccess(config: PlaidSuccessConfig, events: TipRanksPlaidCB) {
  if (!verifySuccessCBParameters(config, events)) { return; }
  getGlobalActions().closeDialog('plaidSyncLoader');
  const actionNameMap: { [key in FlowType]: UpdateActionName } = {
    create: 'CreatePortfolio',
    update: 'UpdatePortfolioAfterUpdate',
  };

  const { public_token, type, expertOrPortfolioId } = config;
  const url = `${BASE_URL}/api/${actionNameMap[type]}`;
  await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      public_token: public_token,
      [type === 'create' ? 'expertid' : 'expertportfolioid']: expertOrPortfolioId,
    }),
  })
    .then((res) => {
      if (res && typeof res.json === 'function') {
        res.json().then(handledResponse => {
          if (typeof events.onSyncEnd === 'function') {
            events.onSyncEnd(handledResponse);
            removeOAuthStateIdFromUrl();
          }
        }).catch((err) => {
          // response is not json...
          responseToGeneralErrorPlaid();
          if (typeof events.onSyncFailed === 'function') {
            events.onSyncFailed(err);
          }
        });
      }
    })
    .catch((err) => {
      responseToGeneralErrorPlaid();
      if (typeof events.onSyncFailed === 'function') {
        events.onSyncFailed(err);
      }
    });
  clearPlaidTokenFromStorage();
}
