export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const showToast = toastId => ({ dispatch }) => {
  setTimeout(() => {
    dispatch({
      type: HIDE_TOAST,
      toastId
    });
  }, 5000);
  return {
    type: SHOW_TOAST,
    toastId
  };
};
