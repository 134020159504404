import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import PurchasePriceCell from '../../tr-dashboard-holdings-section/cells/PurchasePriceCell.react';

export default class TrDashboardHoldingsDetailPurchasePrice extends Component {
  static propTypes = {
    item: PropTypes.object
  };

  render() {
    const { item } = this.props;

    return (
      <div className="tr-dahsobard-holdings-detail purchasePrice">
        <PurchasePriceCell {...this.props} stock={item} />
      </div>
    );
  }
}
