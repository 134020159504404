import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import ReturnVsBenchmarksTable from '../tr-return-vs-benchmarks-table';

export default class TrDashboardOverviewReturnVsBenchmarks extends Component {
  static propTypes = {
    gains: PropTypes.object,
    msg: PropTypes.object,
    shouldTransposeTable: PropTypes.bool
  };

  render() {
    const { gains, msg, shouldTransposeTable } = this.props;
    return (
      <section className="tr-dashboard-overview-return-vs-benchmarks">
        <header>
          <h1>{msg.returnVsBenchmarks}</h1>
        </header>
        <main>
          <ReturnVsBenchmarksTable
            gains={gains}
            shouldTransposeTable={shouldTransposeTable}
          />
        </main>
      </section>
    );
  }
}
