import * as React from 'react';
import * as styles from './ColorCodedList.istyl';
import { SectorEnum } from 'sp/browser/news-analysis/types';
import {
  defaultColorList, AllocationItem, otherColor,
} from 'sp/browser/AllocationGraph/AllocationGraph';
import { betterToFixed } from 'sp/common/lib/utils';

export type Item = { id: string; value: React.ReactNode };
export type ColorItem = Item & { backgroundColor?: string; };
export type ColorCodedListValue = (ColorItem)[];

// TODO add "outerStyles"
// TODO refactor this - Don't use a "value" in ColorItem, pass in the percentage
// and decide here how to present it.
export class ColorCodedList extends React.PureComponent<{
  value: ColorCodedListValue;
}> {
  render() {
    const { value } = this.props;
    const itemsLength = value.length;

    return (
      <ul className={styles.list}>
        {value.map((item, index) => {
          const { id, value, backgroundColor = defaultColorList[index % itemsLength] } = item;
          return (
            <li className={styles.item} key={index}>
              <span
                className={styles.customColorBullet}
                style={{ backgroundColor: item.id.toLocaleLowerCase() === 'other' ? otherColor : backgroundColor }}
              />
              <span className={styles.mainText}>{id}</span>
              <span className={styles.value}>{value}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}

export function toColorCodedList<T extends { percent: number }>(sortedItems: T[]) {
  return sortedItems.map(item => {
    const percent = item.percent * 100;
    const value = betterToFixed(percent);
    const newItem = {
      ...item as any, // TODO remove any when https://github.com/Microsoft/TypeScript/pull/13288
      // value: <span title={percent.toString()}>{value}%</span>,
      value: `${value}%`,
    };
    return newItem as (T & { value: string });
  });
}
