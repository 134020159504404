import * as  React from 'react';
import Component from 'react-pure-render/component';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export default class Bar extends React.PureComponent<{
  cursorStyle?: any,// PropTypes.object,
  max?: number,// PropTypes.number,
  min?: number,// PropTypes.number,
  style?: any,// PropTypes.object,
  value: number,// PropTypes.number
}> {

  static defaultProps = {
    max: 1,
    min: 0
  };

  render() {
    const { cursorStyle, max = Bar.defaultProps.min, min = Bar.defaultProps.min, style, value } = this.props;

    const percent = (value - min) / (max - min) * 100;

    return (
      <div className="bar" style={style}>
        <div
          className="cursor"
          style={{
            ...cursorStyle,
            paddingLeft: `${percent}%`
          }}
        >
          <img alt="" src={reqImg('bar-cursor.png')} />
        </div>
      </div>
    );
  }
}
