import reporter from '../analytics';

const events = {
  visited: 'visited-smart-portfolio-crowd-insights-page',
  clickedStockType: 'clicked-crowd-insights-equities-etfs-radio-btn',
  clickedSector: 'clicked-crowd-insights-sector-table'
};

const report = reporter(events);

export default report;
