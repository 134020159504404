import { Record, List, Map } from 'immutable';

import * as actions from './actions';

export const InitialState = Record({
  activeTooltips: new List(),
  tooltipArgs: new Map(),
  tooltipPositions: new Map()
});

const initialState = new InitialState();
const revive = state => initialState.merge(state);

export default (state = initialState, action) => {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.SHOW_TOOLTIP: {
      return state
        .set('activeTooltips', new List([action.tooltipId]))
        .setIn(['tooltipArgs', action.tooltipId], action.args)
        .setIn(['tooltipPositions', action.tooltipId], action.position);
    }

    case actions.HIDE_TOOLTIP: {
      const { clientX, clientY, force, tooltipId } = action;
      const byItem = itemId => itemId === tooltipId;

      const tooltipPositions = state.getIn(['tooltipPositions', tooltipId]);

      if (!tooltipPositions) return state;

      const tooltipLeft =
        tooltipPositions.left -
        (tooltipPositions.isNotWholyVisibleHorizontally ? 1 : 0) *
          tooltipPositions.expectedWidth;

      const tooltipTop =
        tooltipPositions.top -
        (tooltipPositions.isNotWholyVisibleVertically ? 1 : 0) *
          tooltipPositions.expectedHeight;

      /* added 10px in order give IE some help */
      const tooLeft = clientX < tooltipLeft;
      const tooRight =
        tooltipLeft + tooltipPositions.expectedWidth < clientX - 10;
      const tooHigh = clientY < tooltipTop;
      const tooLow = tooltipTop + tooltipPositions.expectedHeight < clientY;

      if (!tooLeft && !tooRight && !tooHigh && !tooLow && !force) {
        return state;
      }

      return state
        .update('activeTooltips', items =>
          items.delete(items.findIndex(byItem))
        )
        .deleteIn(['tooltipArgs', tooltipId])
        .deleteIn(['tooltipPositions', tooltipId]);
    }
  }

  return state;
};
