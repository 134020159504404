import * as classNames from 'classnames';
import * as React from 'react';
import { history } from 'sp/browser/history';
import { getPublicUrl, isLoadingSlug, pushPage } from 'sp/browser/pages/PublicPortfolio/Onboarding/utils';
import { ReduxProps } from 'sp/common/types';

import * as publicToRegisterStyles from '../../PublicPortfolioToRegister/styles.istyl';
import * as styles from './styles.istyl';
import { defaultPortfolioIdValue, OnboardingPortfolios } from 'sp/browser/pages/PublicPortfolio/Onboarding/config';
import { SecondOnboardingPageProps } from 'sp/browser/pages/PublicPortfolio/Onboarding/SecondOnboardingPage';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { selectPortfoliosWithPerformance, selectUserActualPortfolios, selectActivePortfolio, selectUserNeverMadePublicPortfolios } from 'sp/browser/dashboard/selectors';
import mapDispatchToProps from 'sp/common/app/mapDispatchToProps';
import mapStateToProps from 'sp/common/app/mapStateToProps';
import { connect } from 'react-redux';
import { ConnectedPureComponent } from 'sp/common/lib/ConnectedComponent';
import { selectLoggedInUser } from 'sp/common/auth/selectors';

@connect(mapStateToProps, mapDispatchToProps)
export class OnboardingPageWrapper extends ConnectedPureComponent<{
  page: number,
  render: (passedProps: SecondOnboardingPageProps) => JSX.Element,
}, ReduxProps> {
  state = {
    fullName: selectLoggedInUser(this.connected.auth).get('name') || '',
    portfolioId: selectActivePortfolio(this.connected.dashboard).get('id'),
    bio: '',
  }
  private get buttonText() {
    switch (this.props.page) {
      case 1: return 'Continue';
      case 2: return 'Confirm';
      case 3: return 'Go to your public profile';
      default: return 'Continue';
    }
  }
  render() {
    const { page, render } = this.props;
    const { auth } = this.connected;

    return <form
      onSubmit={this.onSubmit}
      className={styles.container}
      data-page={page}
    >
      <div className={styles.wave} />

      {render({
        onChangeForm: (partialForm) => this.setState(partialForm),
        form: this.state,
        getPortfolios: this.getPortfolios,
        initialFullName: selectLoggedInUser(this.connected.auth).get('name'),
      })}

      <button
        disabled={page === 3 && isLoadingSlug(auth)}
        type={page === 2 ? 'submit' : ''}
        className={classNames(publicToRegisterStyles.startNowButton, styles.startNowButton)}
        onClick={this.btnClick}
      >
        {this.buttonText}
      </button>

      <ul className={styles.indicators}>
        <li className={classNames({ [styles.active]: page === 1 })} onClick={this.goBack(1)}></li>
        <li className={classNames({ [styles.active]: page === 2 })} onClick={this.goBack(2)}></li>
        <li className={classNames({ [styles.active]: page === 3 })} onClick={this.goBack(3)}></li>
      </ul>
    </form>;
  }
  btnClick = () => {
    const { page } = this.props;
    const { auth } = this.connected;
    if (page === 1) { pushPage(2) }
    else if (page === 3) { window.location.assign(getPublicUrl(auth)); }
    // else if (page === 2) { }
  }
  goBack = (page: number) => {
    if (page > this.props.page) return;
    return () => pushPage(page);
  }
  onSubmit = (e) => {
    e.preventDefault();
    const {
      fullName,
      portfolioId: rawPortfolioId,
      bio
    } = this.state;

    // set default value
    const portfolioId = rawPortfolioId === defaultPortfolioIdValue
      ? this.getFirstPortfolioId()
      : rawPortfolioId;

    // product assumes this will never fail.
    getGlobalActions().setPortfolioPublic({
      fullName,
      portfolioId,
      bio: bio
    });

    pushPage(3);
  }
  getPortfolios = (): OnboardingPortfolios => {
    return selectUserNeverMadePublicPortfolios(this.connected.dashboard, this.connected.auth).toJS();
  }
  getFirstPortfolioId = () => this.getPortfolios()[0].id;
}
