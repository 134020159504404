import reporter from '../analytics';

const events = {
  saw: 'saw-edit-portfolio-screen',
  addPortfolio: 'add-portfolio-limit',
  clickedImportPortfolio: type => `clicked-import-portfolio-${type}`
};

const report = reporter(events);

export default report;
