import { history } from 'sp/browser/history';
import { Routes, AuthRecord } from 'sp/common/types';
import { selectLoggedInUser, selectUserPublicUrl } from 'sp/common/auth/selectors';
import { suits } from 'sp/common/enums';
import { requireAuthReplace } from 'sp/browser/lib/router-utils';

const route = ['smart-portfolio', 'onboarding'];

export const isOnOnboardingRoute = (routes: Routes) =>
  route.every(part => !!routes.find(({ path }) => path === part));

export const toOnboarding = (page = 1) => history.push(`/${route.join('/')}/${page}`);

export function pushPage(page: number) {
  history.push(`/smart-portfolio/onboarding/${page}`);
}

export function isLoadingSlug(auth: AuthRecord) {
  return selectLoggedInUser(auth).get('componentStates').get('portfoliosMadePublic') !== suits.SUCCESS;
}

export function getPublicUrl(auth: AuthRecord): string {
  return selectUserPublicUrl(auth)!;
}

// TODO documentation calls replaceRoute replaceState, so rename all instances to that...
export const onEnterOnboardingPage = (nextState, replaceRoute) => {
  if (process.env.THEME === 'nasdaq') history.replace('/smart-portfolio/holdings');
  requireAuthReplace(nextState, replaceRoute);
}