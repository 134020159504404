import { TargetedWebPageKey } from './config';
import { Actions, Plan } from '../../common/types';
import history from '../../browser/history';
import { getGlobalActions } from '../lib/actions';
import { lastLockedType } from './TargetedWebPage';

export const shouldConvert = {
  screenerToSlugCase: process.env.THEME === 'nasdaq'
};

// handles promotion in both nasdaq and web
export const promote = ({
  type: typeOrStr,
  plan
}: {
  type: string;
  plan: Plan;
}) => {
  // we do this hack here, because this is the first place I'd look for.
  // some places (like my holdings basic table) have a very convulted
  // "restriction" system, forcing us to use this switch case to fix
  // the type ONLY SOMETIMES.
  const type =
    {
      // 'bestAnalystConsensus': ''
    }[typeOrStr] || typeOrStr;
  if (process.env.THEME === 'nasdaq') {
    const lowerCaseType = type.toLowerCase();
    const trigger = localStorage.getItem('lastLockedFeature');
    // TODO extract /smart-portfolio from all paths (failed in dani's private branch `dani-sp/failed-history-pkg-upgrade`)
    const { pathname } = window.location || { pathname: '' };
    const appName = 'smart-portfolio';
    const indexOfUneededPath = pathname.indexOf(appName);
    let prevLocation = '';
    if (indexOfUneededPath === -1) {
      if (process.env.NODE_ENV === 'development')
        console.warn('This shouldnt get here');
    } else {
      prevLocation = pathname.slice(indexOfUneededPath).replace(appName, '');
      if (prevLocation.startsWith('/')) prevLocation = prevLocation.slice(1);
      if (prevLocation.endsWith('/')) prevLocation = prevLocation.slice(0, -1);
    }
    localStorage.setItem(lastLockedType, type);
    if (process.env.NOT_PROD)
      console.info(`Targeted: Setting ${lastLockedType} to ${type}...`);
    history.push({
      pathname: `/smart-portfolio/gopro/${prevLocation}/${trigger}`
    });
  } else {
    getGlobalActions().openDialog('promotion', { plan, type });
  }
};
