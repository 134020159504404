const categories = {
  all: {
    value: '',
    key: 'all',
    class: 'all'
  },
  ticker: {
    key: 'ticker',
    value: 'ticker'
  },
  analyst: {
    key: 'analyst',
    value: 'analyst'
  },
  blogger: {
    key: 'blogger',
    value: 'blogger'
  },
  insider: {
    key: 'insider',
    value: 'insider'
  },
  institution: {
    key: 'institution',
    value: 'institution'
  }
};

export const msg = {
  all: {
    label: 'All',
    icon2: 'search-all-grey',
    placeholder: 'Type Expert Name or Stock'
  },
  ticker: {
    label: 'Companies / Stocks',
    icon2: 'search-companies-grey',
    placeholder: 'Search for Companies…'
  },
  analyst: {
    label: 'Wall Street Analysts',
    icon: 'search-analyst',
    icon2: 'search-analysts-grey',
    placeholder: 'Search for Analysts…'
  },
  blogger: {
    label: 'Financial Bloggers',
    icon: 'search-blogger',
    icon2: 'search-bloggers-grey',
    placeholder: 'Search for Bloggers…'
  },
  insider: {
    label: 'Corporate Insiders',
    icon: 'search-insider',
    icon2: 'search-insiders-grey',
    placeholder: 'Search for Insiders…'
  },
  institution: {
    label: 'Hedge Funds',
    icon: 'search-hedgefund',
    icon2: 'search-hedge-grey',
    placeholder: 'Search for Hedge Funds…'
  }
};

export default categories;

const defaultOrder = [
  'all',
  'ticker',
  'analyst',
  'blogger',
  'insider',
  'institution'
];

export const getCategories = (...keys) => {
  keys = keys.length ? keys : defaultOrder;
  return keys.map(k => ({ ...categories[k] }));
};

export const defaultCategories = getCategories();

export const __hotReload = true;
