import * as classNames from 'classnames';
import * as React from 'react';
import Component from 'react-pure-render/component';

export const highlightChangeConfig = { duration: 2000 };

export default class HighlightChange extends React.PureComponent<{
  value: number; //PropTypes.any
}, { highlight: any }> {

  constructor(props) {
    super(props);
    this.state = { highlight: false };
  }

  componentWillReceiveProps(nextProps) {
    const { value: prev } = this.props;
    const { value: next } = nextProps;
    if (prev !== next) {
      this.setState({ highlight: true });
      setTimeout(() => {
        this.setState({ highlight: false });
      }, highlightChangeConfig.duration);
    } else {
      this.setState({ highlight: false });
    }
  }

  render() {
    const { children } = this.props;
    const { highlight } = this.state;

    return (
      <span className={classNames('change-highlight', { blink: highlight })}>
        {children}
      </span>
    );
  }
}
