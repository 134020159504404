import { AuthRecord, PortfolioPrivacy, Plan, Dashboard } from '../types';
import * as cookies from 'js-cookie';
import { createSelector } from 'reselect';
import { createPublicUrlFromSlug } from 'sp/browser/pages/PublicPortfolioToRegister/utils';
import { selectActivePortfolio } from 'sp/browser/dashboard/selectors';
import { List } from 'immutable';

export const selectIsOnboarding = () => !!cookies.get('TemporaryUserKey');

export const selectIsTemporaryLoggedIn = selectIsOnboarding;

export const selectViewer = (state: AuthRecord) => state.get('users').get('viewer');

export const selectViewerPlan = (state: AuthRecord) => {
  const a = state.get('users');
  const b = a.get('viewer');
  return b.get('plan');
}

export const selectViewerPlanId = (state: AuthRecord) => {
  const a = state.get('users');
  const b = a.get('viewer').get('loggedInUser');
  return b.get('planId');
}

export const selectLoggedInUser = createSelector(
  selectViewer,
  viewer => viewer.get('loggedInUser')
)

export const selectUserName = createSelector(
  selectLoggedInUser,
  selectIsTemporaryLoggedIn,
  (user, isTemporatyLoggedIn) => {
    if (isTemporatyLoggedIn) {
      return user.get('name') || '';
    }
    return (
      user.get('name') ||
      user.get('email') ||
      ''
    );
  }
);

export const selectForm = state => state.form;

export const selectViewerNextPlan = createSelector(
  selectViewerPlan,
  (plan): 'free' | 'premium' | 'ultimate' | undefined =>
    ({
      open: 'free',
      free: 'premium',
      premium: 'ultimate'
    }[plan])
);

export const hasPayed = createSelector(
  selectViewerPlan,
  plan => plan && plan !== 'free' && plan !== 'open'
);

export const selectIsPermanentLoggedIn = () => !!cookies.get('user');

// TODO rename to something that suggests that this includes onboarding users
// rename to selectIsLoggedInOrOnboarding
export const selectIsLoggedIn = () =>
  !!cookies.get('user') || !!cookies.get('TemporaryUserKey');

export const selectCameFromOnboarding = () =>
  !!cookies.get('CameFromOnboarding');

export const planWeight = (plan: Plan) => {
  switch (plan) {
    case 'open':
      return 0;
    case 'free':
      return 1;
    case 'premium':
      return 2;
    case 'ultimate':
      return 3;
    default: {
      if (process.env.IS_RUN_LOCAL) {
        console.warn('unidentified plan');
      }
      return 0;
    }
  }
};

export const isAllowed = (targetPlan, viewerPlan) =>
  planWeight(targetPlan) <= planWeight(viewerPlan);

export const isCurrentPlanGreaterThan = createSelector(
  (state: AuthRecord) => selectViewerPlan(state),
  (_, currentPlan) => currentPlan,

  (currentPlan, plan) => planWeight(plan) < planWeight(currentPlan)
);

export const selectPortfoliosEverMadePublic = createSelector(
  selectLoggedInUser,
  user => {
    return user.get('portfoliosMadePublic') || List();
  }
)

export const selectFirstCurrentlyPublicPortfolio = createSelector(
  selectPortfoliosEverMadePublic,
  portfoliosMadePublic => {
    if (!portfoliosMadePublic.size) return null;
    return portfoliosMadePublic
      .find(potentiallyPublicPortfolio => potentiallyPublicPortfolio.get('privacy') === PortfolioPrivacy.Public);
  }
)

export const selectUserPublicSlug = createSelector(
  selectFirstCurrentlyPublicPortfolio,
  publicPortfolio => publicPortfolio && publicPortfolio.get('slug')
);

export const selectUserPublicId = createSelector(
  selectFirstCurrentlyPublicPortfolio,
  publicPortfolio => publicPortfolio && publicPortfolio.get('id')
);

export const selectPublicPortfolioUrlById = createSelector(
  selectPortfoliosEverMadePublic,
  (_, portfolioId: number) => portfolioId,
  (publicPortfolios, portfolioId) => {
    const foundPortfolio = publicPortfolios.find(portfolio => portfolio.get('id') === portfolioId);
    if (!foundPortfolio) { return null; }
    return createPublicUrlFromSlug(foundPortfolio.get('id'), foundPortfolio.get('slug'));
  }
)

/**
 * Note: returns url for FIRST public portfolio
 * TODO when support for multiple public portfolios will land, change here
 */
export const selectUserPublicUrl = createSelector(
  selectUserPublicId,
  selectUserPublicSlug,
  (id, slug) => {
    if (slug) return createPublicUrlFromSlug(id!, slug)
    return null;
  });

export const selectLatestCreatedPublicPortfolio = createSelector(
  selectLoggedInUser,
  user => {
    return user.get('latestCreatedPublicPortfolio');
  }
)

export const selectPortfolioPrivacy = createSelector(
  (auth: AuthRecord, portfolioId: number) => ({ user: selectLoggedInUser(auth), portfolioId }),
  ({ user, portfolioId }) => {
    const portfolio = user.get('portfoliosMadePublic').find(portfolio => portfolio.get('id') === portfolioId);
    if (portfolio) return portfolio.get('privacy');
    return PortfolioPrivacy.Private;
  });

export const selectEverMadePortfolioPublic = createSelector(
  (auth: AuthRecord, portfolioId: number) => ({ user: selectLoggedInUser(auth), portfolioId }),
  ({ user, portfolioId }) => {
    const portfolio = user.get('portfoliosMadePublic').find(portfolio => portfolio.get('id') === portfolioId);
    return Boolean(portfolio);
  });

export const selectAnyPortfolioCurrentlyPublic = createSelector(
  selectLoggedInUser,
  user => user.get('portfoliosMadePublic').some(portfolio => portfolio.get('privacy') === PortfolioPrivacy.Public)
)

export const selectIsPortfolioMadePublicEver = createSelector(
  (auth: AuthRecord, portfolioId: number) => ({ user: selectLoggedInUser(auth), portfolioId }),
  ({ user, portfolioId }) => {
    const portfolio = user.get('portfoliosMadePublic').find(portfolio => portfolio.get('id') === portfolioId);
    return !!portfolio;
  });

export const selectHasMadeAnyPortfolioPublicEver = createSelector(
  selectLoggedInUser,
  (user) => {
    return !!user.get('portfoliosMadePublic').size;
  });

export const selectPortfolioHasPerformance = createSelector(
  (auth: AuthRecord, dashboard: Dashboard) => selectActivePortfolio(dashboard).get('id'),
  (auth: AuthRecord) => selectLoggedInUser(auth),
  (portfolioId, user) => {
    return !!user.get('portfoliosWithPerformance').find(portfolio => portfolio.get('id') === portfolioId);
  });

export const selectAnyPortfolioCurrentlyHasPerformance = createSelector(
  selectLoggedInUser,
  user => !!user.get('portfoliosWithPerformance').size
);