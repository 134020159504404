import * as React from 'react';
import { getGoProLink, getGoUltimateLink, buildEvents, getPlansLink } from '@tipranks/checkout-link';
import { selectIsPermanentLoggedIn } from '../../common/auth/selectors';
import { AvailableIcons } from 'tipranks-icons';

const forInstitutions: TipRanksLink = {
  id: 'forInstitutions',
  text: 'For Institutions',
  listArrows: true,
  items: [{
    id: 'api',
    text: 'API for Institutions',
    href: '/get-api',
  }],
};

const tipRanksNewsletter: TipRanksLink = {
  id: 'tipRanksNewsletter',
  text: 'TipRanks Newsletter',
  className: 'block-chosen',
  listArrows: true,
  items: [{
    id: 'SmartInvestor',
    text: 'Smart Investor',
    href: '/smart-investor/pricing/?llf=smart-investor-nl-menu',
  }],
};

const topStocks: TipRanksLink = {
  id: 'topStocks',
  text: 'Top Stocks',
  listArrows: true,
  items: [
    {
      id: 'topSmartScore',
      text: 'Top Smart Score stocks',
      href: '/stocks-to-buy?ref=sp-top-score-menu',
      tag: 'new',
    },
    {
      id: 'topRatedStocks',
      text: 'Analysts\' Top Stocks',
      href: '/stocks/top-rated',
    }, {
      id: 'hotInsiderStocks',
      text: 'Insiders\' Hot Stocks',
      href: '/insiders/hot-stocks',
      tag: 'popular',
    }, {
      id: 'trendingStocks',
      text: 'Trending Stocks',
      href: '/trending-stocks',
    },
  ],
};

const ratings: TipRanksLink = {
  id: 'ratingsLong',
  text: 'Daily Stock Ratings',
  href: '/stock-ratings',
};

const ratingsLong: TipRanksLink = {
  id: 'ratingsLong',
  text: 'Daily Stock Ratings',
  href: '/stock-ratings',
};

const insiderTrading: TipRanksLink = {
  id: 'insideTrading',
  text: 'Daily Insider Transactions',
  href: '/inside-trading',
};

const dailyFeeds: TipRanksLink = {
  id: 'dailyFeeds',
  text: 'Daily Feeds',
  listArrows: true,
  items: [
    ratingsLong,
    insiderTrading,
  ],
};

const dailyFeedsMobile: TipRanksLink = {
  id: 'dailyFeeds',
  text: 'Daily Feeds',
  items: [
    ratings,
    insiderTrading,
  ],
};

const screenerDescriptor = (sub: 'stocks' | 'etfs') => process.env.THEME === 'nasdaq'
  ? { to: `/smart-portfolio/screener/${sub}` }
  : { href: `/screener/${sub}` };

const screeners: TipRanksLink = {
  id: 'screeners',
  text: 'Screeners',
  listArrows: true,
  items: [
    {
      id: 'stockScreener',
      text: 'Stock Screener',
      tag: 'new',
      ...screenerDescriptor('stocks')
    },
    {
      id: 'stockCompare',
      text: 'Stock Comparison',
      href: '/compare-stocks',
      tag: 'new',
    }
  ]
};

const topExperts: TipRanksLink = {
  id: 'topExperts',
  text: 'Top Experts',
  icon: 'medal25',
  items: [
    {
      id: 'findExperts',
      text: 'Find & Follow Experts',
      items: [
        {
          id: 'smart-portfolio-experts',
          text: 'My Experts',
          icon: 'threeUsers',
          iconSize: 10,
          href: '/my-experts',
        },
      ],
    },
    {
      id: 'Top Lists',
      text: 'Top Lists',
      items: [
        {
          id: 'Expert Center',
          text: 'Expert Center',
          icon: 'medal25',
          href: '/top-experts',
        }, {
          id: 'topAnalysts',
          text: 'Top 25 Wall Street Analysts',
          icon: 'manWithTie',
          href: '/analysts/top',
        }, {
          id: 'topBloggers',
          text: 'Top 25 Financial Bloggers',
          icon: 'manWithSpeechBubble',
          href: '/bloggers/top',
        }, {
          id: 'topInsiders',
          text: 'Top 25 Corporate Insiders',
          icon: 'manWithDollar',
          href: '/insiders/top',
        }, {
          id: 'topHedgeFunds',
          text: 'Top 25 Hedge Fund Managers',
          icon: 'building',
          href: '/hedge-funds/top',
        }, 
        {
          id: 'topInvestors',
          text: 'Top Individual Investors',
          icon: 'threeUsers',
          iconSize: 10,
          href: '/investors/top',
          tag: 'new'
        },
        {
          id: 'Firm Top',
          text: 'Top Research Firms',
          href: '/firms/top',
          icon: 'medal25',
        },
      ],
    },
  ],
};

const calendars: TipRanksLink = {
  id: 'calendars',
  text: 'Calendars',
  icon: 'calendarNewMonth',
  items: [
    {
      id: 'calendars-text',
      text: 'Calendars',
      listArrows: true,
      items: [
        {
          id: 'earnings',
          text: 'Earnings Calendar',
          href: '/calendars/earnings',
        }, {
          id: 'dividends',
          text: 'Dividend Calendar',
          href: '/calendars/dividends',
        }, {
          id: 'holidays',
          text: 'Stock Market Holidays',
          href: '/calendars/market-holidays/2020', // TODO NEXT YEAR NEED TO BE CHANGE 2021
        }, {
          id: 'ipos',
          text: 'IPO Calendar',
          href: '/calendars/ipo/pricing',
        },
      ],
    },
  ],
};

const top25ExpertsMobile: TipRanksLink = {
  id: 'Top Lists',
  text: 'Top Experts',
  items: [
    {
      id: 'smart-portfolio-experts',
      text: 'My Experts',
      icon: 'threeUsers',
      iconSize: 10,
      href: '/my-experts',
    },
    {
      id: 'expert-center',
      text: 'Expert Center',
      icon: 'medal25',
      href: '/top-experts',
    }, {
      id: 'topAnalysts',
      text: 'Top 25 Analysts',
      icon: 'manWithTie',
      href: '/analysts/top',
    }, {
      id: 'topBloggers',
      text: 'Top 25 Bloggers',
      icon: 'manWithSpeechBubble',
      href: '/bloggers/top',
    }, {
      id: 'topInsiders',
      text: 'Top 25 Insiders',
      icon: 'manWithDollar',
      href: '/insiders/top',
    }, {
      id: 'topHedgeFunds',
      text: 'Top 25 Hedge Funds',
      icon: 'building',
      href: '/hedge-funds/top',
    }, 
    {
      id: 'top-investors',
      text: 'Top Individual Investors',
      icon: 'threeUsers',
      iconSize: 10,
      href: '/investors/top',
      tag: 'new'
    },
    {
      id: 'Firm Top',
      text: 'Top Research Firms',
      href: '/firms/top',
      icon: 'medal25',
      tag: 'new',
    },
  ],
};

const news: TipRanksLink = {
  id: 'news',
  text: 'News',
  icon: 'ScreenerNewsAlert',
  href: '/news',
};

const tools: TipRanksLink = {
  id: 'tools',
  text: 'Research Tools',
  icon: 'flask',
  items: [
    topStocks,
    screeners,
    // tipRanksNewsletter,
    dailyFeeds,
    forInstitutions,
  ],
};

const toolsMobile: TipRanksLink = {
  id: 'tools',
  text: 'Research Tools',
  items: [
    topStocks,
    news,
    dailyFeedsMobile,
    top25ExpertsMobile,
    screeners,
    calendars.items![0],
    // tipRanksNewsletter,
  ],
};

const myPortfolio: TipRanksLink = {
  id: 'my-portfolio',
  text: 'My Portfolio',
  items: [
    {
      id: 'smart-portfolio-overview',
      text: 'Overview',
      icon: 'pieChart',
      to: '/smart-portfolio/overview',
    }, {
      id: 'smart-portfolio-holdings',
      text: 'My Holdings',
      icon: 'dashboard',
      to: '/smart-portfolio/holdings',
    }, {
      id: 'smart-portfolio-analysis',
      text: 'My Portfolio Analysis',
      icon: 'fourBarsChart',
      to: '/smart-portfolio/analysis',
    }, {
      id: 'smart-portfolio-insights',
      text: 'Crowd Insights',
      icon: 'lightblubWithDollar',
      to: '/smart-portfolio/insights',
    },
  ],
};

const myPortfolioArea: TipRanksLink = {
  id: 'my-portfolio-area',
  text: 'My Area',
  items: [
    {
      id: 'smart-portfolio-performance',
      text: 'My Performance',
      icon: 'starHalf',
      to: '/smart-portfolio/performance',
    }, {
      id: 'my-public-portfolio',
      text: 'My Public Profile',
      tag: 'new',
      icon: 'manWithCircle',
      href: '/my-public-portfolio',
    },
  ],
};

const smartPortfolioMobile: TipRanksLink = {
  id: 'smart-portfolio-mobile',
  text: 'Smart Portfolio',
  items: [
    {
      id: 'smart-portfolio-overview',
      text: 'Overview',
      icon: 'pieChart',
      to: '/smart-portfolio/overview',
    }, {
      id: 'smart-portfolio-holdings',
      text: 'My Holdings',
      icon: 'dashboard',
      to: '/smart-portfolio/holdings',
    },
    {
      id: 'smart-portfolio-performance',
      text: 'My Performance',
      icon: 'starHalf',
      to: '/smart-portfolio/performance',
    },
    {
      id: 'more',
      text: 'More...',
      items: [
        {
          id: 'smart-portfolio-analysis',
          text: 'My Portfolio Analysis',
          icon: 'fourBarsChart',
          to: '/smart-portfolio/analysis',
        }, {
          id: 'smart-portfolio-insights',
          text: 'Crowd Insights',
          icon: 'lightblubWithDollar',
          to: '/smart-portfolio/insights',
        }, {
          id: 'my-public-portfolio',
          text: 'My Public Profile',
          tag: 'new',
          icon: 'manWithCircle',
          href: '/my-public-portfolio',
        },
      ],
    },
  ],
};

const SP_GO_PRO_HAMBURGER_MENU_LINK_LLF = 'sp-mobile-menu-right-tiprankspro-link';
const SP_GO_ULTIMATE_HAMBURGER_MENU_LINK_LLF = 'sp-side-bar-upgrade-link'
const SP_PLANS_PAGE_HAMBURGER_MENU_LINK_LLF = 'sp-plans-page-mobile-menu'
const accountAndMore: TipRanksLink = {
  id: 'account-and-more',
  text: 'Account & More',
  items: [{
    id: 'login',
    text: 'Login',
    href: '/sign-in',
  }, {
    id: 'logout',
    text: 'Log Out',
    href: '/logout',
  }, {
    id: 'my-account',
    text: 'My Account',
    href: '/account/settings',
    predicate: () => selectIsPermanentLoggedIn(),
  }, {
    id: 'upgrade',
    text: 'TipRanks Premium',
    href: getGoProLink(SP_GO_PRO_HAMBURGER_MENU_LINK_LLF),
    onMarketingFlowStartEvents: (pageName: string, events) =>
      buildEvents(pageName, SP_GO_PRO_HAMBURGER_MENU_LINK_LLF, events),
  }, {
    id: 'upgrade-ultimate-menu',
    text: 'TipRanks Ultimate',
    href: getGoUltimateLink(SP_GO_ULTIMATE_HAMBURGER_MENU_LINK_LLF),
    onMarketingFlowStartEvents: (pageName: string, events) =>
      buildEvents(pageName, SP_GO_ULTIMATE_HAMBURGER_MENU_LINK_LLF, events),
  }, {
    id: 'plans',
    text: 'Plans & Pricing',
    href: getPlansLink(SP_PLANS_PAGE_HAMBURGER_MENU_LINK_LLF),
    onMarketingFlowStartEvents: (pageName: string, events) =>
      buildEvents(pageName, SP_PLANS_PAGE_HAMBURGER_MENU_LINK_LLF, events),
  }, {
    id: 'more',
    text: 'More...',
    items: [{
      id: 'about-us',
      text: 'About Us',
      href: '/about',
    }, {
      id: 'faq',
      text: 'FAQ',
      href: '/faq',
    }, {
      id: 'api',
      text: 'API for Institutions',
      href: '/get-api'
    }]
  }]
};

const smartPortfolio: TipRanksLink = {
  id: 'smart-portfolio',
  text: 'Smart Portfolio',
  icon: 'dashboard',
  items: [
    myPortfolio,
    myPortfolioArea,
  ],
};

export type ExtraEvents = Parameters<typeof buildEvents>[2];
export interface TipRanksLink {
  id: string;
  text: React.ReactNode;
  href?: string;
  to?: string;
  className?: string;
  items?: TipRanksLink[];
  target?: string;
  tag?: string;
  icon?: AvailableIcons;
  iconSize?: number;
  listArrows?: boolean;
  predicate?: () => boolean;
  onMarketingFlowStartEvents?: (pageName: string, events?: ExtraEvents) =>
    ReturnType<typeof buildEvents>;
}

export default {
  headerLinks: [news, tools, smartPortfolio, topExperts, calendars],
  sideMenuLinks: [toolsMobile, smartPortfolioMobile, accountAndMore]
};
