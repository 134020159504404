import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import Editable from '../../Editable';
import { imgSrc } from '../../../common/lib/utils.ts';

import { currencySymbolFromTicker } from 'sp/browser/Money/Money';

const shortenToCurrencyK = (num, currency = '$') => {
  const str = num ? num.toFixed(2) : 0;
  return `${currency}${num >= 1000 ? `${str / 1000}K` : str}`;
};

export default class PurchasePriceCell extends Component {
  static propTypes = {
    changeEditable: PropTypes.func,
    editableId: PropTypes.string,
    isCombinedPortfolio: PropTypes.bool,
    isImportedPortfolio: PropTypes.bool,
    portfolioId: PropTypes.number,
    setPurchasePrice: PropTypes.func,
    stock: PropTypes.object
  };

  constructor(...args) {
    super(...args);
    this.state = { purchasePrice: '0' };
  }

  componentDidUpdate(prevProps) {
    if (this.isFirstActiveRender(prevProps)) {
      this.setState({ purchasePrice: this.purchasePrice().toString() });
    }
  }

  onChange = purchasePrice => this.setState({ purchasePrice });

  doneEditing = () => {
    const { changeEditable, portfolioId, setPurchasePrice } = this.props;
    const { purchasePrice } = this.state;
    const floatPurchasePrice = parseFloat(purchasePrice);

    changeEditable('');

    const isValid = 0.001 <= floatPurchasePrice && floatPurchasePrice <= 3e5;
    const haveChanged = floatPurchasePrice !== this.purchasePrice();
    if (isNaN(floatPurchasePrice) || !isValid || !haveChanged) return;

    setPurchasePrice(
      portfolioId,
      this.ticker(),
      floatPurchasePrice,
      this.purchasePrice()
    );
  };

  activate = () => {
    const { changeEditable, isCombinedPortfolio } = this.props;
    if (isCombinedPortfolio) return;
    const purchasePrice = this.purchasePrice();
    this.setState({ purchasePrice: purchasePrice.toString() });
    changeEditable(this.editableId(), purchasePrice);
  };

  purchasePrice = () => {
    const { stock } = this.props;
    const purchasePrice = stock.get('purchasePrice');
    return purchasePrice;
  };

  editableId = () => `purchasePrice_${this.ticker()}`;

  ticker = () => {
    const { stock } = this.props;
    return stock.get('ticker');
  };

  isActiveMode = () => {
    const { editableId } = this.props;
    return editableId === this.editableId();
  };

  isFirstActiveRender = ({ editableId: prevEditableId }) =>
    this.isActiveMode() && prevEditableId !== this.editableId();

  render() {
    const { isImportedPortfolio, isCombinedPortfolio } = this.props;
    const { purchasePrice } = this.state;

    if (isImportedPortfolio || isCombinedPortfolio) {
      return (
        <div>
          <span className="real-value">
            {shortenToCurrencyK(
              this.purchasePrice(),
              currencySymbolFromTicker(this.props.stock.get('ticker'))
            )}
          </span>
        </div>
      );
    }

    return (
      <div>
        <span className="real-value">
          <Editable
            activate={this.activate}
            doneEditing={this.doneEditing}
            editableValue={purchasePrice}
            freez={false}
            isActiveMode={this.isActiveMode()}
            max={3e5}
            min={0.001}
            onChange={this.onChange}
            type="number"
            value={shortenToCurrencyK(
              this.purchasePrice(),
              currencySymbolFromTicker(this.props.stock.get('ticker'))
            )}
          />
        </span>
        <button onClick={this.activate}>
          <img src={imgSrc('edit')} />
        </button>
      </div>
    );
  }
}
