import React from 'react';
import Component from 'react-pure-render/component';
import classnames from 'classnames';

import getImage from './images/index.js';

import Highlight from './highlight.react.js';
import Flag from './flag.tsx';
// import {noopScroll} from '../lib/side-effects';

const NoResults = () => (
  <div
    style={{
      textAlign: 'center',
      padding: '10px',
      color: '#c4c4c4'
    }}
  >
    No Results
  </div>
);

const mapToArray = map => {
  const arr = [];
  map.forEach((v, k) => arr.push([k, v]));
  return arr;
};

export default class SearchResults extends Component {
  componentDidUpdate() {
    // this is an IE10 hack for JIRA: TIPRANKS-10628
    // if (bowser.msie && bowser.version === 10) {
    //   noopScroll();
    // }
  }

  hasNoResults({ results } = this.props) {
    if (!results) return false;
    return [...results.values()].every(x => !x || x.length === 0);
  }

  render(
    {
      results = new Map(),
      query,
      focused,
      msg: { categories = {}, icons = {} } = {},
      shown,
      onResultClicked
    } = this.props
  ) {
    shown = shown && !this.hasNoResults();
    return (
      <div
        className="wrapper tr-search-results-wrapper"
        style={{ display: results.size && shown ? 'block' : 'none' }}
      >
        <ul>
          {mapToArray(results)
            .filter(([_, catResults]) => catResults.length > 0)
            .map(
              ([category, catResults]) =>
                !catResults ? null : (
                  <li key={category}>
                    <div className="categoryHeader">
                      {categories[category] || category}
                    </div>
                    <ul className="categoryList">
                      {catResults.map((result, i) => {
                        const icon = icons[category];
                        const isTicker = category === 'ticker';
                        return (
                          <li
                            className={classnames('categoryItem', {
                              focused: result.uid === focused
                            })}
                            key={i}
                          >
                            <a
                              to={result.to}
                              href={result.url}
                              onClick={onResultClicked}
                              className="link"
                            >
                              {isTicker ? (
                                <span className="cell before ticker">
                                  <Highlight highlight term={query}>
                                    {result.value}
                                  </Highlight>
                                </span>
                              ) : (
                                <span className="before icon">
                                  {getImage(icon, 'img')}
                                </span>
                              )}
                              <span
                                className={classnames('cell', 'option', {
                                  tickerOption: isTicker
                                })}
                              >
                                <Highlight term={query}>
                                  {result.option}
                                </Highlight>
                              </span>
                              {isTicker ? (
                                <Flag ticker={result.value} />
                              ) : (
                                <span className="cell label">
                                  <Highlight term={query}>
                                    {result.label}
                                  </Highlight>
                                </span>
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                )
            )}
        </ul>
      </div>
    );
  }
}

export const __hotReload = true;
