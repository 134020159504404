import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics';
import { ApiDecorator } from '../ApiDecorator';
import { imgSrc } from '../../common/lib/utils.ts';
import { TrDashboardSavePortfolios } from '../tr-dashboard-save-portfolios';
import UserWarningsContainer from '../tr-user-warnings-container/';
import ImportedPortfolioIndication from '../tr-imported-portfolio-indication';
import NoDataSection from '../tr-dashboard-holdings-section/NoDataSection.react';
import { WaitingNotice } from '../tr-dashboard-holdings-section/WaitingNotice';

import {
  selectActivePortfolio,
  selectPortfolioItems,
  selectTblType
} from '../dashboard/selectors';

import OverviewSectionContainer from './OverviewSectionContainer.react';
import TrDashboardHoldingsSectionContainer from '../tr-dashboard-holdings-section-container';
import { startRealTimePrices } from 'sp/browser/components/RealTimePrices'
import { syncSmartPortfolioWithPlaid } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { checkForPlaidTokenInStorage } from '../../common/PlaidProviderLogic';
import { identifyUser } from '../../common/api';


const youMayAlsoLikeItems = [
  {
    title: "Insider's Hot Stocks",
    desc: 'Stocks that exhibit strong buy indicators based on insider trading.',
    imgSrc: require('./img/1.png'),
    href: '/insiders/hot-stocks'
  },
  {
    title: "Analyst's Top Stocks",
    desc:
      'Review the top stocks recommended by best performing analysts from across the web.',
    imgSrc: require('./img/2.png'),
    href: '/stocks/top-rated'
  },
  {
    title: 'Stock Screener',
    desc:
      'Use TipRanks’ Stock Screener to quickly and easily find stocks by selecting key financial metrics.',
    imgSrc: require('./img/3.png'),
    href: '/screener/stocks'
  }
];

@ApiDecorator('portfolios', 'portfolioItems', 'stats')
export default class TrDashboardHoldingsContainer extends Component {
  state = {
    expertId: 0
  }
  static propTypes = {
    actions: PropTypes.object,
    apiState: PropTypes.string,
    dashboard: PropTypes.object,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object
  };
  subscribeHandle = () => null;

  componentDidMount() {
    const { dashboard, auth } = this.props;
    this.unsubscribeHandle =
      startRealTimePrices(dashboard, this.props.actions.updatePricesInRealTime);
    report('visited');

    const expertIdFromStore
      = auth
        .get('users')
        .get('viewer')
        .get('loggedInUser')
        .get('expertId');
    if (expertIdFromStore) {
      this.onSyncClick()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevExpertId = this.props.auth
      .get('users')
      .get('viewer')
      .get('loggedInUser')
      .get('expertId');
    const nextExpertId = nextProps.auth
      .get('users')
      .get('viewer')
      .get('loggedInUser')
      .get('expertId');
    if (prevExpertId !== nextExpertId) {
      this.onSyncClick()
    }
  }

  onSyncClick = () => {
    if (!checkForPlaidTokenInStorage()) { return; }
    const expertId = this.state.expertId
    syncSmartPortfolioWithPlaid(expertId, {
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        /* const fetchedPortfolios = */await this.props.actions.fetchPortfolios();
        if (Array.isArray(PortfolioIds) && PortfolioIds[0]) {
          this.props.actions.changePortfolio(PortfolioIds[0])
        }
      },
    })
  }

  componentWillUnmount() { this.unsubscribeHandle && this.unsubscribeHandle(); }

  render() {
    const { actions, apiState, dashboard, mediaQuery, msg } = this.props;

    const hasItems = selectPortfolioItems(dashboard).size > 0;
    const isLoading = apiState === 'LOADING';

    const activePortfolio = selectActivePortfolio(dashboard);
    const isPendingPortfolio = activePortfolio.get('type') === 'pending'

    const tblType = selectTblType(dashboard);

    return (
      <div className={`dashboard-holdings ${tblType}`}>
        {mediaQuery.get('laptop') || (!isLoading && hasItems)
          ? (
            <div>
              <div className="user-warnings-wrapper">
                {!activePortfolio.get('isImported') && (
                  <UserWarningsContainer {...this.props} />
                )}
              </div>
              <ImportedPortfolioIndication {...this.props} />
              <OverviewSectionContainer {...this.props} />
              <TrDashboardHoldingsSectionContainer {...this.props} />
              <div className="save-portfolio-laptop-and-up">
                <TrDashboardSavePortfolios
                  {...this.props}
                  msg={this.props.msg.savePortfolio}
                />
              </div>
              {process.env.THEME === 'nasdaq' ? null : (
                <div className="you-may-also-like-me">
                  <header>You May Also Like</header>
                  <ul>
                    {youMayAlsoLikeItems.map(item => (
                      <li key={item.href}>
                        <a href={`${item.href}?ref=my-holdings`}>
                          <img src={item.imgSrc} alt={item.title} />
                          <div>{item.desc}</div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )
          : isLoading
            ? (
              <div className="mobile-loader">
                Loading...
                <br />
                <img alt="" src={imgSrc('loader')} />
              </div>
            )
            : isPendingPortfolio
              ? (<WaitingNotice />)
              : (
                <NoDataSection
                  fetchPortfolios={actions.fetchPortfolios}
                  changePortfolio={actions.changePortfolio}
                  msg={msg.dashboard.holdingsNodataImport}
                  openAddStockDialog={() => actions.openDialog('addStock')}
                  openSyncDialog={() => actions.openDialog('syncDialog')}
                />
              )}
      </div>
    );
  }
}
