import * as classNames from 'classnames';
import * as React from 'react';
import { DialogItem } from './dialog-item.react';
import { selectDialogs, selectProps } from './selectors';
import report from './analytics';
import { selectTheme } from '../dashboard/selectors';
import {
  Actions,
  Dashboard,
  History,
  Messages,
  Routes,
  DialogStoreRecord,
} from '../../common/types';
import { getRoutes, classify } from '../../common/lib/utils';
import { history } from 'sp/browser/history';

const dialogCloser = 'dialogListWrapper';

const existsIn = obj => item => obj.hasOwnProperty(item);

export const hasDialogs = (dialogs, stateName) => (
  Child: any /* PureComponent<any, any>*/,
) =>
  class Dialogs extends React.PureComponent<
    {
      actions: Actions;
      dashboard: Dashboard;
      dialogs: DialogStoreRecord;
      history: History;
      msg: Messages;
      routes: Routes;
    },
    {}
    > {
    componentDidMount() {
      history.listenBefore(listener => {
        const { action } = listener;
        if (action === 'POP') {
          return this.popHandler();
        }
        return true;
      });
    }

    popHandler = () => {
      const { actions, dashboard } = this.props;
      const state = this.props[stateName];
      const items = selectDialogs(state).filter(existsIn(dialogs));
      if (items.size > 0) {
        actions.closeDialog();
        return false;
      }
      if (dashboard.get('routeNav').get('isActive')) {
        actions.toggleRouteNav();
        return false;
      }
      return true;
    };

    render() {
      const { actions, dashboard, msg, routes } = this.props;
      const state = this.props[stateName];
      const items = selectDialogs(state).filter(existsIn(dialogs));
      const props = selectProps(state);

      const styles = {
        activeDialog: items.size > 0,
      };

      const { route } = getRoutes(routes);

      return (
        <div
          className={classNames(
            'dialogs-wrapper',
            styles,
            `route-${classify(route)}`,
          )}
        >
          <div
            className={classNames('dialogs-wrapper-component', {
              active: items.size > 0,
            })}
          >
            <Child {...this.props} />
          </div>
          {items.size > 0 && [
            <div className="dialogs-overlay" key="dialogs-overlay" />,
            <ul
              key="dialogs-list"
              onClick={this.clickOutside}
              ref={dialogCloser}
            >
              {items.map((dialogId, index, arr) => (
                <li
                  className={classNames(`dialog-${dialogId}`, {
                    active: index === arr.size - 1,
                  })}
                  key={dialogId}
                >
                  <DialogItem
                    actions={actions}
                    dialogId={dialogId}
                    dialogProps={props.get(dialogId)}
                    dialogSystemTitle={
                      props.get(dialogId) &&
                      props.get(dialogId).dialogSystemTitle
                    }
                    msg={msg}
                    theme={selectTheme(dashboard)}
                  >
                    {dialogs[dialogId](this.props, props.get(dialogId))}
                  </DialogItem>
                </li>
              ))}
            </ul>,
          ]}
        </div>
      );
    }

    clickOutside = ({ target }) => {
      const { refs, props } = this;
      const { actions } = props;
      if (refs[dialogCloser] === target) {
        report('clickedOutside');
        actions.closeDialog();
      }
    };

  } as any;

export default hasDialogs;
