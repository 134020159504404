import { Dashboard, PortfolioItemRecord, PortfolioRecord, DashboardStock } from '../types';
import { selectPortfolios, selectActivePortfolio } from '../../browser/dashboard/selectors';
import { fromJS, Record, Map, List, Set } from 'immutable';
import { uniqBy, set, isNumber } from 'lodash';
import * as actions from './actions';
import * as expertActions from '../../browser/tr-dashboard-experts/actions';
import * as dialogActions from '../../browser/dialogs/actions';
import * as accountSettingsActions from '../../browser/AccountSettings/actions';
import * as portfolioPerformanceBoxActions from '../../browser/performance/portfolio-performance-box/actions';
import {
  PortfolioFundamentalsAPIResponse,
  emptyDatum,
} from '../../browser/performance/portfolio-performance-box/actions';
import { PortfolioGain, PortfolioItem, Stats, Stock } from '../records';
import { suits, TypesOfPortfolios } from '../enums';
// import diff from 'immutablediff';
import {
  dashboard as config,
  holdingsSectionConfig,
  companyConstants,
} from '../config';

import {
  adaptDetails,
  adaptPortfolios,
  adaptPortfoliosDetails,
} from '../api-adapter';

import { adaptNewsSentimentsAPIResponse } from '../../browser/news-analysis/adapter';
import { initialState, InitialState } from 'sp/common/dashboard/config';
import {
  revive,
  loadingSuit,
  errorSuit,
  successSuit,
  portfolioIndexById,
  portfolioItemByTicker,
  stockByTicker,
  byWight,
  apiSignature,
  data,
  portfolioData,
  setStateError,
  setStateLoading,
  setStateSuccess,
  updatePortfolioItems,
  portfolioGainPath,
} from 'sp/common/dashboard/utils';
import { REDUX_STORAGE_LOAD } from 'sp/common/auth/actions';
import { RealTimePrices } from 'sp/browser/components/RealTimePrices';
import { API_GetUserPortfolios_Response } from 'sp/common/api-types';

export default function dashboardReducer(
  state: Dashboard = initialState,
  action,
) {
  if (!(state instanceof InitialState)) return revive(state as any); // TODO

  state = state as Dashboard; // after revive, TSC gets confused and thinks the type is Dashboard & Map<any, any> :(

  switch (action.type) {
    case REDUX_STORAGE_LOAD: {
      return !action.payload.dashboard
        ? state
        : state
          .set('holdingsCols', List(action.payload.dashboard.holdingsCols))
          .set(
            'holdingsColsPendings',
            List(action.payload.dashboard.holdingsColsPendings),
        );
    }

    case 'FETCH_USER_PERFORMANCE_START': {
      return setStateLoading(state, 'userPerf');
    }

    case 'TOGGLE_TBL_TYPE': {
      const { tblId } = action;
      return state.set('tblType', tblId);
    }

    case 'FETCH_USER_PERFORMANCE_ERROR': {
      return setStateError(state, 'userPerf');
    }

    case 'FETCH_USER_PERFORMANCE_SUCCESS': {
      const data = action.payload;

      return state
        .setIn(['data', 'userPerf'], data)
        .setIn(...successSuit('userPerf'));
    }

    case 'FETCH_USER_INFO_START': {
      return setStateLoading(state, 'userInfo');
    }

    case 'FETCH_USER_INFO_ERROR': {
      return setStateError(state, 'userInfo');
    }

    case 'FETCH_USER_INFO_SUCCESS': {
      const data = action.payload;
      return state
        .setIn(['data', 'userInfo'], data)
        .setIn(...successSuit('userInfo'));
    }

    case 'GET_NEWS_SENTIMENTS_START': {
      return setStateLoading(state, 'newsSentiments');
    }

    case 'GET_NEWS_SENTIMENTS_ERROR': {
      return setStateError(state, 'newsSentiments');
    }

    case 'GET_NEWS_SENTIMENTS_SUCCESS': {
      const apiData = action.payload;
      const newsSentiments = adaptNewsSentimentsAPIResponse(apiData);

      return state
        .setIn(['newsSentiments'], newsSentiments)
        .setIn(...successSuit('newsSentiments'));
    }

    case 'GET_PRICES_START': {
      return state.setIn(
        [
          'componentStates',
          'prices',
          state.getIn(['portfolioNewsTicker']).toUpperCase(),
        ],
        suits.LOADING,
      );
    }

    case 'GET_PRICES_ERROR': {
      return state.setIn(
        [
          'componentStates',
          'prices',
          state.getIn(['portfolioNewsTicker']).toUpperCase(),
        ],
        suits.ERROR,
      );
    }

    case 'GET_PRICES_SUCCESS': {
      return state
        .setIn(
          ['prices', state.getIn(['portfolioNewsTicker']).toUpperCase()],
          action.payload,
      )
        .setIn(
          [
            'componentStates',
            'prices',
            state.getIn(['portfolioNewsTicker']).toUpperCase(),
          ],
          suits.SUCCESS,
      );
    }

    case actions.RESET_PORTFOLIO_STATUS: {
      return state.set('importState', '');
    }

    case actions.IMPORT_PORTFOLIO_STATUS_SUCCESS: {
      const { importState } = action.payload;
      return state.set('importState', importState);
    }

    case actions.TOGGLE_ROUTE_NAV: {
      return state.update('routeNav', (state) => state.update('isActive', isActive => !isActive));
    }

    case actions.GET_MGMT_FEES_START: {
      return setStateLoading(state, 'mgmtFees');
    }

    case actions.GET_MGMT_FEES_SUCCESS: {
      const mgmtFees = action.payload;
      return state
        .setIn(['data', 'mgmtFees'], mgmtFees)
        .setIn(...successSuit('mgmtFees'));
    }

    // "Always put things in api-adapter, only api adapter knows how the outside world looks"
    case actions.FETCH_PORTFOLIO_GAINS_SUCCESS: {
      const {
        expertPortfolioID,
        monthlyPerformance,
        quarterlyPerformance,
        sixMonthPerformance,
        yearlyPerformance,
        threeYearsPerformance,
        ytdPerformance,
      } = action.payload;

      const monthly = new PortfolioGain({ percent: monthlyPerformance });
      const quarterly = new PortfolioGain({ percent: quarterlyPerformance });
      const sixMonth = new PortfolioGain({ percent: sixMonthPerformance });
      const yearly = new PortfolioGain({ percent: yearlyPerformance });
      const threeYearly = new PortfolioGain({ percent: threeYearsPerformance });
      const ytd = new PortfolioGain({ percent: ytdPerformance });

      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.updateIn(
          portfolioGainPath(portfolios, expertPortfolioID),
          gain =>
            gain
              .set('monthly', monthly)
              .set('quarterly', quarterly)
              .set('sixMonth', sixMonth)
              .set('yearly', yearly)
              .set('threeYearly', threeYearly)
              .set('ytd', ytd),
        ),
      );
    }

    case actions.ASSET_ALLOCATION_TYPE_CHANGED: {
      const { activeAsset } = action;
      return state.set('assetAllocationType', activeAsset);
    }

    case actions.CHANGE_OVERVIEW_MARKET: {
      const { markets } = action;
      return state.set('visibleMarketOverviewMarkets', markets);
    }

    case actions.CHANGE_PORTFOLIO_NEWS_TICKER: {
      const { ticker } = action;
      return state.set('portfolioNewsTicker', ticker);
    }

    case actions.CHANGE_OVERVIEW_DURATION: {
      const { duration } = action;
      return state.set('mraketOverviewDuration', duration);
    }

    case actions.ACTIVATE_PAGE: {
      const { page } = action;
      return state.set('holdingsPage', page);
    }

    case actions.DISMISS_WARNING_DIALOG: {
      const { name, time } = action;
      return state.update('dismissdedWarningDialogs', dialogs =>
        dialogs.push(
          Map({
            name,
            time,
          }),
        ),
      );
    }

    case actions.MAJOR_HOLDINGS_TYPE_CHANGED: {
      const { majorHoldingsType } = action;
      return state.set('majorHoldingsType', majorHoldingsType);
    }

    case actions.MAJOR_HOLDINGS_SECTOR_CHANGED: {
      const { majorHoldingsSector } = action;
      return state.set('majorHoldingsSector', majorHoldingsSector);
    }

    case actions.CHANGE_EDITABLE: {
      const { id, val } = action;
      return state.set('editableId', id).set('editableValue', val);
    }

    case actions.GET_FUNDS_FEES_SUCCESS: {
      const fees = action.payload;
      return state.updateIn(['data', 'stocks'], stocks =>
        stocks.map(stock =>
          stock.update('managementFee', fee => {
            const serverFee = fees.find(
              fee => fee.symbol.toLowerCase() === stock.get('ticker'),
            );
            const rtrn = serverFee ? serverFee.managementFee : fee;
            return rtrn;
          }),
        ),
      );
    }

    case actions.GET_MY_RETURN_AVERAGE_SUCCESS: {
      const gains = action.payload;
      return state.setIn(['data', 'gains'], gains);
    }

    case actions.SET_SHARES_START: {
      const { portfolioId, ticker, shares } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.updateIn(
          [portfolioIndexById(portfolios, portfolioId), 'items'],
          items => {
            const itemsWithValues = items.update(
              portfolioItemByTicker(items, ticker),
              item =>
                item.set('sharesTotal', shares).update('sharesValue', value => {
                  const price = stockByTicker(ticker, state).getIn([
                    'price',
                    'amount',
                  ]);
                  return price
                    ? shares *
                    price *
                    parseFloat(item.getIn(['stock', 'exchangeRate']) || 1)
                    : value;
                }),
            );
            const totalValues = itemsWithValues.reduce(
              (total, item) => total + item.get('sharesValue'),
              0,
            );
            return itemsWithValues.map(item =>
              item.set(
                'percentPortfolio',
                item.get('sharesValue') / totalValues,
              ),
            );
          },
        ),
      );
    }

    case actions.SET_SHARES_SUCCESS: {
      const { price } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.update(
          portfolioIndexById(portfolios, state.get('activePortfolioId')),
          portfolio =>
            portfolio
              .update('items', items =>
                items.setIn(['stock', 'price', 'amount'], price),
            )
              .updateIn(['stats', 'changes'], x => x + 1),
        ),
      );
    }

    case actions.SET_PURCHASE_PRICE_START: {
      const { portfolioId, ticker, purchasePrice } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.updateIn(
          [portfolioIndexById(portfolios, portfolioId), 'items'],
          items =>
            items.update(portfolioItemByTicker(items, ticker), item =>
              item.set('purchasePrice', purchasePrice),
            ),
        ),
      );
    }

    case actions.SET_CASH_VALUE_START: {
      const { portfolioId, cashValue } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.setIn(
          [portfolioIndexById(portfolios, portfolioId), 'cashValue'],
          cashValue,
        ),
      );
    }

    case actions.CHANGE_FILTER: {
      const { payload } = action;
      return state.setIn(['filters', payload.id], payload.val);
    }

    case actions.CHANGE_PORTFOLIO: {
      const { portfolioId } = action;
      return state.set('activePortfolioId', portfolioId).set('holdingsPage', 0);
    }

    case actions.INSERT_BEFORE_HOLDINGS_TABLE: {
      const { dest, source } = action;
      return state.update('holdingsCols', cols => {
        const colsAfterDeletion = cols.delete(cols.indexOf(source));
        const destIndex = colsAfterDeletion.indexOf(dest);
        const left = colsAfterDeletion.slice(0, destIndex);
        const right = colsAfterDeletion.slice(
          destIndex,
          colsAfterDeletion.size,
        );
        return left.push(source).concat(right);
      });
    }

    case actions.SET_PORTFOLIO_NEWS_TYPE: {
      const { newsType } = action;
      return state.set('overviewPortfolioNewsTab', newsType);
    }

    case actions.SET_STOCK_ALERT_START: {
      const { portfolioId, ticker, value } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.updateIn(
          [portfolioIndexById(portfolios, portfolioId), 'items'],
          items =>
            items.setIn(
              [portfolioItemByTicker(items, ticker), 'isAlertsOn'],
              value,
            ),
        ),
      );
    }

    case actions.CHANGE_HOLDINGS_METRICS: {
      const { selections } = action;
      return state.set('holdingsColsPendings', selections);
    }

    case actions.APPLY_HOLDINGS_METRICS_CHANGES: {
      const newCols = state.get('holdingsColsPendings');
      return state.set(
        'holdingsCols',
        newCols.sort(byWight(holdingsSectionConfig.get('colOrder'))),
      );
    }

    case actions.SORT_HOLDINGS_TABLE: {
      const { field } = action;
      const prevField = state.get('holdingsSortBy');
      return state
        .set('holdingsSortBy', field)
        .update(
          'isholdingsSortDescending',
          val => (prevField === field ? !val : false),
      );
    }

    case actions.CHANGE_AUTO_COMPLETE_QUERY: {
      return state.set('dataListQuery', action.query);
    }

    case actions.APPLY_PENDING_STOCKS_TO_ADD_START: {
      const componentName = 'addStock';
      const { id, newItems } = action.payload;
      return state
        .updateIn(['data', 'portfolios'], portfolios =>
          portfolios.updateIn(
            [portfolioIndexById(portfolios, id), 'items'],
            items =>
              newItems.reduce(
                (items, newItem) =>
                  items.find(
                    item => item.get('ticker') === newItem.get('ticker'),
                  )
                    ? items
                    : items.push(newItem),
                items || List(),
              ),
          ),
      )
        .updateIn(['data', 'stocks'], stocks =>
          newItems.reduce(
            (stocks, newItem) =>
              stocks.find(
                stock =>
                  stock.get('ticker').toLowerCase() ===
                  newItem.get('ticker').toLowerCase(),
              )
                ? stocks
                : stocks.push(new Stock({ ticker: newItem.get('ticker') })),
            stocks,
          ),
      )
        .setIn(...loadingSuit(componentName))
        .set('pendingStocksToAdd', List());
    }

    case actions.APPLY_PENDING_STOCKS_TO_ADD_SUCCESS: {
      const componentName = 'addStock';
      return state
        .setIn(...successSuit(componentName))
        .update('stockAddedFlag', set => set.add(action.payload.id));
    }

    case actions.ADD_PORTFOLIO_ITEMS_SUCCESS: {
      const { id, newItems } = action.payload;
      return state
        .updateIn(['data', 'portfolios'], portfolios =>
          portfolios.updateIn(
            [portfolioIndexById(portfolios, id), 'items'],
            items =>
              newItems.reduce(
                (items, newItem) =>
                  items.find(
                    item => item.get('ticker') === newItem.get('ticker'),
                  )
                    ? items
                    : items.push(newItem),
                items || List(),
              ),
          ),
      )
        .updateIn(['data', 'stocks'], stocks =>
          newItems.reduce(
            (stocks, newItem) =>
              stocks.find(
                stock => stock.get('ticker') === newItem.get('ticker'),
              )
                ? stocks
                : stocks.push(new Stock({ ticker: newItem.get('ticker') })),
            stocks,
          ),
      );
    }

    case actions.APPLY_PENDING_STOCKS_TO_ADD_ERROR: {
      const componentName = 'addStock';
      return setStateError(state, componentName);
    }

    case actions.CHANGE_EDITABLE_PORTFOLIO_VALUE: {
      return state.set('editablePortfolioValue', action.value);
    }

    case actions.CHANGE_EDITABLE_PORTFOLIO: {
      const portfolioName =
        action.portfolioId > -1
          ? state
            .getIn(['data', 'portfolios'])
            .find(portfolio => portfolio.get('id') === action.portfolioId)
            .get('name')
          : '';
      return state
        .set('editablePortfolioId', action.portfolioId)
        .set('editablePortfolioValue', portfolioName);
    }

    case actions.ADD_PENDING_STOCKS_TO_ADD: {
      const { result } = action;
      return state
        .update(
          'pendingStocksToAdd',
          items =>
            items.includes(result.get('value').toLowerCase())
              ? items
              : items.push(result.get('value').toLowerCase()),
      )
        .set('dataListQuery', '')
        .set('dataList', List());
    }

    case actions.DELETE_PENDING_STOCKS_TO_ADD: {
      return state.update('pendingStocksToAdd', items =>
        items.delete(items.findIndex(i => i === action.item)),
      );
    }

    case actions.SET_STOCK_LAST_READ_START: {
      const { ticker } = action.payload;
      return state.updateIn(['data', 'portfolios'], portfolios =>
        portfolios.updateIn(
          [
            portfolios.findIndex(
              portfolio =>
                portfolio.get('id') === state.get('activePortfolioId'),
            ),
            'items',
          ],
          items =>
            items.setIn(
              [
                items.findIndex(item => item.get('ticker') === ticker),
                'readAllAlerts',
              ],
              true,
            ),
        ),
      );
    }

    /** ** GET_PORTFOLIOS ****/
    case actions.GET_PORTFOLIOS_START: {
      return setStateLoading(state, 'portfolios');
    }

    case actions.GET_PORTFOLIOS_SUCCESS: {
      const componentName = 'portfolios';
      const { res } = action.payload as { res: API_GetUserPortfolios_Response['portfolios'] };
      if (res.length === 0) {
        return state;
      }

      return state
        .setIn(...apiSignature(componentName, action.payload))
        .setIn(...successSuit(componentName))
        .updateIn(...data(componentName, adaptPortfolios(res)))
        .update('activePortfolioId', activePortfolioId => {
          const isCombinedActive =
            activePortfolioId === companyConstants.get('combinedUserPortfolio');

          if (isCombinedActive || res.find(x => x.id === activePortfolioId)) {
            return activePortfolioId;
          }
          return res[0].id;
        });
    }

    case actions.GET_PORTFOLIOS_ERROR: {
      return setStateError(state, 'portfolios');
    }
    /** ** GET_PORTFOLIOS ****/

    /** ** GET_EXPERTS ****/
    case expertActions.GET_EXPERTS_START: {
      return setStateLoading(state, 'experts');
    }

    case expertActions.GET_EXPERTS_SUCCESS: {
      const componentName = 'experts';
      const { payload } = action;
      return state
        .setIn(...apiSignature(componentName, payload))
        .setIn(...successSuit(componentName));
    }

    case expertActions.GET_EXPERTS_ERROR: {
      return setStateError(state, 'experts');
    }

    case expertActions.GET_USER_FOLLOWED_EXPERTS_START: {
      return setStateLoading(state, 'userFollowedExperts');
    }

    case expertActions.GET_USER_FOLLOWED_EXPERTS_SUCCESS: {
      const experts = action.payload.res;
      const followedExperts =
        experts.filter(ex => ex.get('isFollowing') && ex.get('rank')) || List();
      const count = followedExperts.size;
      return state
        .set('userFollowedExperts', count)
        .setIn(...successSuit('userFollowedExperts'));
    }

    case expertActions.GET_USER_FOLLOWED_EXPERTS_ERROR: {
      return setStateError(state, 'userFollowedExperts');
    }
    /** **GET_EXPERTS ****/

    /** ** GET_PORTFOLIO_ITEMS ****/
    case actions.GET_PORTFOLIO_ITEMS_START: {
      return setStateLoading(state, 'portfolioItems');
    }

    case actions.GET_PORTFOLIO_ITEMS_SUCCESS: {
      const componentName = 'portfolioItems';
      const { portfolioId, items } = action.payload.res;
      const stocks = state.getIn(['data', 'stocks']);

      // TODO is this even needed?!
      const uniqueItems = items
        .reduce((uniqueItems: Map<string, PortfolioItemRecord>, item) => {
          const ticker = item.get('ticker').toLowerCase();
          if (process.env.IS_RUN_LOCAL && uniqueItems.has(ticker)) {
            console.warn('Non unique items in GET_PORTFOLIO_ITEMS_SUCCESS?!');
          }
          return uniqueItems.set(ticker, item);
        }, Map())
        .toList();

      return state
        .setIn(...apiSignature(componentName, action.payload))
        .updateIn(...portfolioData(portfolioId, uniqueItems, 'items'))
        .updateIn(['data', 'stocks'], stocks =>
          items.reduce((stocks, item) => {
            const exists = stocks.some(
              stock =>
                stock.get('ticker').toLowerCase() ===
                item.get('ticker').toLowerCase(),
            );
            // if (process.env.IS_RUN_LOCAL && !exists) {
            //   console.warn(
            //     'the fact that this happens is really bad and is a sign of a bad architecture',
            //   );
            // }
            return exists
              ? stocks
              : stocks.push(
                new Stock({
                  ticker: item.get('ticker'),
                }),
              );
          }, stocks),
      )
        .setIn(...successSuit(componentName))
        .set('stockAddedFlag', Set());
    }

    case actions.GET_PORTFOLIO_ITEMS_ERROR: {
      return setStateError(state, 'portfolioItems');
    }
    /** **GET_PORTFOLIO_ITEMS ****/

    /** ** GET_STOCKS ****/
    case actions.GET_STOCKS_START: {
      const portfolioType = action.payload.portfolioType as TypesOfPortfolios;

      return setStateLoading(state, translateApiDecoratorStocks(portfolioType));
    }

    case actions.GET_STOCKS_ERROR: {
      const portfolioType = action.payload.portfolioType as TypesOfPortfolios;

      return setStateError(state, translateApiDecoratorStocks(portfolioType));
    }

    case actions.GET_STOCKS_SUCCESS: {
      const portfolioType = action.payload.portfolioType as TypesOfPortfolios;

      const componentName = translateApiDecoratorStocks(portfolioType);
      const stocks = action.payload.res.stocks;
      const count = action.payload.res.count;

      const byTicker = stock => stateStock =>
        stateStock.get('ticker').toLowerCase() ===
        stock.get('ticker').toLowerCase();

      return setStateSuccess(state, componentName)
        .setIn(...apiSignature(componentName, action.payload))
        .setIn(['data', 'screenerTotal'], count)
        .updateIn(['data', 'stocks'], stateStocks =>
          stocks.reduce((stateStocks, newStock) => {
            const index = stateStocks.findIndex(byTicker(newStock));
            return index > -1
              ? stateStocks.update(index, oldStock =>
                newStock.set('price', oldStock.get('price')),
              )
              : stateStocks.push(newStock);
          }, stateStocks),
      );
      // .setIn(...successSuit(componentName));
    }

    /** **GET_STOCKS ****/

    case actions.GET_PORTFOLIO_ITEMS_BY_TICKERS_SUCCESS: {
      const componentName = 'portfolioItems';
      const { portfolioId, items } = action.payload;
      const stocks = state.getIn(['data', 'stocks']);
      const modifiedItems = items.map(item => {
        const stock = stocks.find(
          stock =>
            stock.get('ticker').toLowerCase() ===
            item.get('ticker').toLowerCase(),
        );
        // TODO refactor this out
        // this basically says that if a race condition occurs, don't mind, just display bad data. Somebody will fix it later on...
        if (stock) {
          const sharesValue =
            stock.getIn(['price', 'amount']) *
            item.get('sharesTotal') *
            parseFloat(stock.get('exchangeRate') || 1);
          return item.set('sharesValue', sharesValue); // TODO this should be refactored out to a selector
        }
        return item;
      });
      return state
        .setIn(...apiSignature(componentName, action.payload))
        .updateIn(...portfolioData(portfolioId, modifiedItems, 'items'))
        .updateIn(['data', 'stocks'], stocks =>
          items.reduce((stocks, item) => {
            const exists = stocks.some(
              stock => stock.get('ticker') === item.get('ticker'),
            );
            return exists
              ? stocks
              : stocks.push(
                new Stock({
                  ticker: item.get('ticker'),
                }),
              );
          }, stocks),
      )
        .setIn(...successSuit(componentName))
        .set('stockAddedFlag', Set());
    }

    case actions.GET_ALERTS_SUCCESS: {
      return state.setIn(
        ['data', 'alerts'],
        action.payload.map(item =>
          Map({
            ticker: item.get('ticker'),
            alerts: item.get('alerts'),
          }),
        ),
      );
    }

    /** ** SUBSCRIBE_TO_EXPERT ****/
    case expertActions.SUBSCRIBE_TO_EXPERT_START: {
      const { person } = action.payload;
      return state.update('busyExperts', experts =>
        experts.push(person.get('id')),
      );
    }

    case expertActions.SUBSCRIBE_TO_EXPERT_SUCCESS: {
      const { person } = action.payload;
      return state
        .update('busyExperts', experts =>
          experts.delete(experts.indexOf(person.get('id'))),
      )
        .update('deletedExperts', experts =>
          experts.delete(experts.indexOf(person.get('id'))),
      )
        .updateIn(
          ['data', 'experts'],
          experts =>
            experts.findIndex(e => e.get('id') === person.get('id')) > -1
              ? experts
              : experts.push(person),
      );
    }

    case expertActions.SUBSCRIBE_TO_EXPERT_ERROR: {
      const { person } = action.payload;
      return state.update('busyExperts', experts =>
        experts.delete(experts.indexOf(person.get('id'))),
      );
    }
    /** ** SUBSCRIBE_TO_EXPERT ****/

    /** ** UN_SUBSCRIBE_TO_EXPERT ****/
    case expertActions.UN_SUBSCRIBE_TO_EXPERT_START: {
      const { person } = action.payload;
      return state.update('busyExperts', experts =>
        experts.push(person.get('id')),
      );
    }

    case expertActions.UN_SUBSCRIBE_TO_EXPERT_SUCCESS: {
      const { person } = action.payload;
      return state
        .update('deletedExperts', experts => experts.push(person.get('id')))
        .update('busyExperts', experts =>
          experts.delete(experts.indexOf(person.get('id'))),
      );
    }

    case expertActions.UN_SUBSCRIBE_TO_EXPERT_ERROR: {
      const { person } = action.payload;
      return state.update('busyExperts', experts =>
        experts.delete(experts.indexOf(person.get('id'))),
      );
    }
    /** ** UN_SUBSCRIBE_TO_EXPERT ****/

    /** ** GET_DETAILS ****/
    case actions.GET_DETAILS_START: {
      const { silent } = action.payload;
      if (silent) {
        return state;
      }
      return setStateLoading(state, 'details');
    }

    case actions.GET_DETAILS_SUCCESS: {
      const { isMarketOpened, details } = action.payload;
      const portfolioId = state.get('activePortfolioId');

      const detailsUpdateFn = adaptPortfoliosDetails(details);
      return state
        .setIn(...successSuit('details'))
        .set('isMarketOpened', isMarketOpened)
        .updateIn(['data', 'stocks'], adaptDetails(details))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(portfolioId, detailsUpdateFn))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(companyConstants.get('avgPortfolioId'), detailsUpdateFn))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(companyConstants.get('bestPortfolioId'), detailsUpdateFn));
    }

    case actions.GET_DETAILS_ERROR: {
      return setStateError(state, 'details');
    }
    /** ** GET_DETAILS ****/

    /** ** GET_PORTFOLIO_NEWS ****/
    case actions.GET_PORTFOLIO_NEWS_START: {
      return setStateLoading(state, 'newsByTickers');
    }

    case actions.GET_PORTFOLIO_NEWS_SUCCESS: {
      const { res } = action.payload;
      const componentName = 'newsByTickers';
      return state
        .setIn(...apiSignature(componentName, action.payload))
        .updateIn(...portfolioData(state.get('activePortfolioId'), res, 'news'))
        .setIn(...successSuit(componentName));
    }

    case actions.GET_PORTFOLIO_NEWS_ERROR: {
      return setStateError(state, 'newsByTickers');
    }
    /** ** GET_PORTFOLIO_NEWS ****/

    case actions.GET_STATS_START: {
      return state.setIn(['componentStates', 'stats'], 'loading');
    }

    case actions.GET_STATS_SUCCESS: {
      const componentName = 'stats';
      return state
        .setIn(...apiSignature(componentName, action.payload))
        .setIn(['data', componentName], action.payload.res)
        .setIn(['componentStates', componentName], 'success');
    }

    case actions.GET_STATS_ERROR: {
      return state.setIn(['componentStates', 'stats'], 'error');
    }

    /** ** GET_PORTFOLIO_EVENTS ****/
    case actions.GET_PORTFOLIO_EVENTS_START: {
      return setStateLoading(state, 'eventsByTickers');
    }

    case actions.GET_PORTFOLIO_EVENTS_SUCCESS: {
      const { res } = action.payload;
      const componentName = 'eventsByTickers';
      const stocks = state.getIn(['data', 'stocks']);
      const resWithStockTypes = res.map(event =>
        event.update('stock', stock => {
          const stateStock = stocks.find(
            item =>
              item.get('ticker').toLowerCase() ===
              stock.get('ticker').toLowerCase(),
          );
          return stateStock ? stock.set('type', stateStock.get('type')) : {};
        }),
      );
      return state
        .setIn(...apiSignature(componentName, action.payload))
        .updateIn(
          ...portfolioData(
            state.get('activePortfolioId'),
            resWithStockTypes,
            'events',
          ),
      )
        .setIn(...successSuit(componentName));
    }

    case actions.GET_PORTFOLIO_EVENTS_ERROR: {
      return setStateError(state, 'eventsByTickers');
    }
    /** ** GET_PORTFOLIO_EVENTS ****/

    /** ** GET_AUTO_COMPLETE ****/
    case actions.GET_AUTO_COMPLETE_START: {
      return setStateLoading(state, 'autoComplete');
    }

    case actions.GET_AUTO_COMPLETE_SUCCESS: {
      const componentName = 'autoComplete';
      const { addToPending, dataList } = action.payload;
      if (addToPending) {
        return state
          .update(
            'pendingStocksToAdd',
            items =>
              items.includes(addToPending.get('value').toLowerCase())
                ? items
                : items.push(addToPending.get('value').toLowerCase()),
        )
          .set('dataListQuery', '')
          .set('dataList', List())
          .setIn(...successSuit(componentName));
      }
      const suitState =
        dataList.size > 0
          ? successSuit(componentName)
          : errorSuit(componentName);

      return state.set('dataList', dataList).setIn(...suitState);
    }

    case actions.GET_AUTO_COMPLETE_ERROR: {
      return setStateError(state, 'autoComplete');
    }
    /** ** GET_AUTO_COMPLETE ****/

    /** ** DELETE_PORTFOLIO ****/
    case actions.DELETE_PORTFOLIO_START: {
      const componentName = 'deletePortfolio';
      const { portfolioId } = action.payload;
      const newState = state
        .setIn(...loadingSuit(componentName))
        .update('deletedPortfolios', portfolios =>
          portfolios.push(portfolioId),
      );

      return newState;
    }

    case actions.DELETE_PORTFOLIO_SUCCESS: {
      const componentName = 'deletePortfolio';
      const { portfolioId } = action.payload;
      const ALL_PORTFOLIOS_P_ID = -2;

      if (state.get('activePortfolioId') === portfolioId) {
        const firstPortfolio = state.getIn(['data', 'portfolios']).first();
        const portfolioIdToChangeTo = firstPortfolio
          ? firstPortfolio.get('id')
          : initialState.get('activePortfolioId');
        state = state.set('activePortfolioId', portfolioIdToChangeTo);
      } else if (state.get('activePortfolioId') === ALL_PORTFOLIOS_P_ID) {
        const portfolios = selectPortfolios(state as any);
        // I thought about checking the size like the JIRA specifies, but let's
        // just redirect to the first portfolio incase this happens with more then 2.
        // if (portfolios.size > 1) {
        const portfolioIdToChangeTo = portfolios.first().get('id');
        state = state.set('activePortfolioId', portfolioIdToChangeTo);
        // }
      }

      return setStateSuccess(state, componentName);
    }

    case actions.DELETE_PORTFOLIO_ERROR: {
      const componentName = 'deletePortfolio';
      const { portfolioId } = action.payload;
      return state
        .setIn(...errorSuit(componentName))
        .update('deletedPortfolios', portfolios =>
          portfolios.delete(portfolios.indexOf(portfolioId)),
      );
    }
    /** ** DELETE_PORTFOLIO ****/

    /** ** APPLY_PORTFOLIO_NAME ****/
    case actions.APPLY_PORTFOLIO_NAME_START: {
      const componentName = 'renamePortfolio';
      return setStateLoading(state, componentName);
    }

    case actions.APPLY_PORTFOLIO_NAME_SUCCESS: {
      const { portfolioId, portfolioName } = action.payload;
      const componentName = 'renamePortfolio';
      return state
        .setIn(...successSuit(componentName))
        .set('editablePortfolioId', -1)
        .updateIn(['data', 'portfolios'], portfolios =>
          portfolios.update(
            portfolios.findIndex(
              portfolio => portfolio.get('id') === portfolioId,
            ),
            portfolio => portfolio.set('name', portfolioName),
          ),
      );
    }

    case actions.APPLY_PORTFOLIO_NAME_ERROR: {
      const componentName = 'renamePortfolio';
      return setStateError(state, componentName);
    }
    /** ** APPLY_PORTFOLIO_NAME ****/

    /** ** ADD_PORTFOLIO ****/
    case actions.ADD_PORTFOLIO_START: {
      const componentName = 'addPortfolio';
      return setStateLoading(state, componentName);
    }

    case actions.ADD_PORTFOLIO_SUCCESS: {
      const componentName = 'addPortfolio';
      const { portfolio, shouldRedirect } = action.payload;
      return state
        .setIn(...successSuit(componentName))
        .update(
          'activePortfolioId',
          id => (shouldRedirect ? portfolio.get('id') : id),
      )
        .updateIn(['data', 'portfolios'], portfolios =>
          portfolios.push(portfolio),
      );
    }

    case actions.ADD_PORTFOLIO_ERROR: {
      const componentName = 'addPortfolio';
      return setStateError(state, componentName);
    }
    /** ** ADD_PORTFOLIO ****/

    /** ** DELETE_STOCK ****/
    case actions.DELETE_STOCK_START: {
      const componentName = 'deleteStock';
      const { portfolioId, ticker } = action.payload;
      const newState = state
        .setIn(...loadingSuit(componentName))
        .updateIn(['data', 'portfolios'], portfolios =>
          portfolios.update(
            portfolios.findIndex(
              portfolio => portfolio.get('id') === portfolioId,
            ),
            portfolio =>
              portfolio.update('items', items =>
                items.delete(
                  items.findIndex(item => item.get('ticker') === ticker),
                ),
              ),
          ),
      );

      return newState;
    }

    case actions.DELETE_STOCK_SUCCESS: {
      const componentName = 'deleteStock';

      return state
        .setIn(...successSuit(componentName))
        .update('stockAddedFlag', set => set.add(action.payload.portfolioId));
    }

    case actions.DELETE_STOCK_ERROR: {
      const componentName = 'deleteStock';
      return setStateError(state, componentName);
    }
    /** ** DELETE_STOCK ****/

    /** ** SEND_USR_FEEDBACK ***/
    case actions.SEND_COMING_SOON_FEEDBACK_START: {
      return setStateLoading(state, 'performanceComingSoon');
    }

    case actions.SEND_COMING_SOON_FEEDBACK_ERROR: {
      return setStateError(state, 'performanceComingSoon');
    }

    case actions.SEND_COMING_SOON_FEEDBACK_SUCCESS: {
      return setStateSuccess(state, 'performanceComingSoon');
    }
    /** ** GET_PORTFOLIO_STATS ***/
    /** ** GET_PORTFOLIO_STATS_SUCCESS ***/
    case actions.GET_PORTFOLIO_STATS_SUCCESS: {
      const { stats, id: expertPortfolioID_ } = action.payload;
      const expertPortfolioID = expertPortfolioID_ || state.get('activePortfolioId');
      // these come from FETCH_PORTFOLIO_GAINS_SUCCESS ¯\_(ツ)_/¯ (fuck you redux team)
      const portfolios = state.getIn(['data', 'portfolios']);
      const gain = portfolios.getIn(portfolioGainPath(portfolios, expertPortfolioID));
      const portfolioStats = stats.set('gain', gain);

      return state
        .setIn(...successSuit('portfolioStats'))
        .updateIn(...portfolioData(expertPortfolioID, portfolioStats, 'stats'));
    }
    /** ** GET_PORTFOLIO_STATS ***/
    /** ** GET_PORTFOLIO_STATS ***/

    /** ** GET_PORTFOLIO_WARNINGS ***/
    case actions.GET_PORTFOLIO_WARNINGS_SUCCESS: {
      const portfolioWarnings = action.payload;
      const portfolioId = state.get('activePortfolioId');
      return state
        .setIn(...successSuit('portfolioWarnings'))
        .updateIn(...portfolioData(portfolioId, portfolioWarnings, 'warnings'));
    }

    case actions.GET_PORTFOLIO_BETA_RISK_START: {
      return setStateLoading(state, 'portfolioBetaRisk');
    }

    case actions.GET_PORTFOLIO_BETA_RISK_ERROR: {
      return setStateError(state, 'portfolioBetaRisk');
    }
    case actions.GET_PORTFOLIO_BETA_RISK_SUCCESS: {
      const portfolioBetaRisk = action.payload;
      const portfolioId = state.get('activePortfolioId');
      return setStateSuccess(state, 'portfolioBetaRisk').updateIn(
        ...portfolioData(portfolioId, portfolioBetaRisk, 'risk'),
      );
    }

    // case 'REDUX_STORAGE_LOAD':
    case dialogActions.OPEN_DIALOG:
    case dialogActions.CLOSE_DIALOG: {
      return state.set('holdingsColsPendings', state.get('holdingsCols'));
    }

    case actions.GET_NUM_PORTFOLIOS_SUCCESS: {
      return state.setIn(['data', 'stats', 'numPortfolios'], action.payload);
    }
    /** ** GET_PORTFOLIO_WARNINGS ***/
    /** ** CHANGE_PASSWORD ***/
    case accountSettingsActions.CHANGE_PASSWORD_START: {
      return setStateLoading(state, 'changePassword');
    }

    case accountSettingsActions.CHANGE_PASSWORD_SUCCESS: {
      return setStateSuccess(state, 'changePassword');
    }

    case accountSettingsActions.CHANGE_PASSWORD_ERROR: {
      return setStateError(state, 'changePassword');
    }
    /** ** CHANGE_PASSWORD ***/
    /** ** CHANGE_EMAIL_FREQUENCY ***/
    case accountSettingsActions.CHANGE_EMAIL_FREQUENCY_START: {
      return setStateLoading(state, 'changeEmailFrequency');
    }

    case accountSettingsActions.CHANGE_EMAIL_FREQUENCY_SUCCESS: {
      return setStateSuccess(state, 'changeEmailFrequency');
    }

    case accountSettingsActions.CHANGE_EMAIL_FREQUENCY_ERROR: {
      return setStateError(state, 'changeEmailFrequency');
    }

    /** ** CHANGE_EMAIL_FREQUENCY ***/

    case accountSettingsActions.USER_FOLLOWED_STOCKS_START: {
      return setStateLoading(state, 'userFollowedStocks');
    }

    case accountSettingsActions.USER_FOLLOWED_STOCKS_SUCCESS: {
      return state
        .set('userFollowedStocks', fromJS(action.payload.stocks))
        .setIn(...successSuit('userFollowedStocks'));
    }

    case accountSettingsActions.USER_FOLLOWED_STOCKS_ERROR: {
      return setStateError(state, 'userFollowedStocks');
    }

    case portfolioPerformanceBoxActions.GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_START: {
      return action.tickers.reduce(
        (state, ticker) =>
          state.setIn(
            ['componentStates', 'monthlyGainsForTickerWithDaysBack', ticker],
            suits.LOADING,
          ),
        state,
      );
    }

    case portfolioPerformanceBoxActions.GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_ERROR: {
      return action.tickers.reduce(
        (state, ticker) =>
          state.setIn(
            ['componentStates', 'monthlyGainsForTickerWithDaysBack', ticker],
            suits.ERROR,
          ),
        state,
      );
    }
    case portfolioPerformanceBoxActions.GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_SUCCESS: {
      // update loading state
      const payload = action.payload as PortfolioFundamentalsAPIResponse;
      const responseTickers = Object.keys(payload);
      const state1 = action.tickers.reduce(
        (state, ticker) =>
          state.setIn(
            ['componentStates', 'monthlyGainsForTickerWithDaysBack', ticker],
            suits.SUCCESS,
          ),
        state,
      )(['monthlyGainsForTickerWithDaysBack']);
      const state2 = action.tickers.reduce(
        (state, ticker) =>
          state.setIn(['monthlyGainsForTickerWithDaysBack', ticker]),
        state1,
      ); //<-- notice state1
      return state2;
    }
    case actions.REALTIME_PRICES_UPDATE: {
      const details: RealTimePrices = action.payload;
      const portfolioId = selectActivePortfolio(state).get('id');

      const updateStock = (stock: DashboardStock) => {
        const detail = details.find(d => d.ticker === stock.get('ticker'));
        if (!detail) return stock;
        return stock.update('price', priceObj =>
          priceObj
            .set('amount', detail.priceObj.amount)
            .set('changeAmount', detail.priceObj.changeAmount)
            .set('changePercent', detail.priceObj.changePercent)
        );
      };
      const updateFn = (items: List<PortfolioItemRecord>): List<PortfolioItemRecord> =>
        items.map(item => item.update('stock', updateStock)).toList()

      return state
        .updateIn(['data', 'stocks'], (stocks: List<DashboardStock>) => stocks.map(updateStock))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(portfolioId, updateFn))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(companyConstants.get('avgPortfolioId'), updateFn))
        .updateIn(['data', 'portfolios'], updatePortfolioItems(companyConstants.get('bestPortfolioId'), updateFn));
    }
    case actions.SET_BASIC_TABLE_TYPE: {
      const { basicTableType } = action.payload;
      return state.set('basicTableType', basicTableType);
    }
  }
  return state;
}

type ApiDecoratorStocks = 'stocks' | 'avgPortfolioStocks' | 'bestStocks';
const translateApiDecoratorStocks = (
  portfolioType: TypesOfPortfolios,
): ApiDecoratorStocks => {
  switch (portfolioType) {
    case TypesOfPortfolios.ActivePortfolio:
      return 'stocks';
    case TypesOfPortfolios.AveragePortfolio:
      return 'avgPortfolioStocks';
    case TypesOfPortfolios.BestPortfolio:
      return 'bestStocks';
    default: {
      return 'stocks';
    }
  }
};
