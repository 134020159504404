import * as actions from './actions';
import searchCategories from './categories';

export const initialState = {
  query: '',
  category: '',
  list: [],
  results: new Map(),
  categoryObject: searchCategories.all,
  resultsShown: false
};

const externalSearchHandler = result => (window.location.href = result.url);
const noResultsHandler = (result, searchTerm) =>
  (window.location.href = `/no-results?term=${searchTerm}`);

const ignoreCaseMatch = (a, b) => {
  return b.match(new RegExp(a.replace(/[\/\\]/g, ''), 'i'));
};

const handleSearch = (foundResult, searchTerm) => {
  let handler;
  if (!foundResult) {
    handler = noResultsHandler;
  } else {
    handler = externalSearchHandler;
  }

  return handler(foundResult, searchTerm);
};

const mergeStateAndPayload = (state, payload) =>
  Object.assign({}, state, payload);
const noop = x => x;

const actionHandlers = {
  [actions.CLEAR_SEARCH](state) {
    return Object.assign({}, initialState, {
      categoryObject: state.categoryObject
    });
  },
  [actions.GET_DATA_LIST_ERROR]: noop,
  [actions.GET_DATA_LIST_SUCCESS]: mergeStateAndPayload,
  [actions.GET_DATA_LIST_START]: noop,
  [actions.PERFORM_SEARCH](state, { value }) {
    const foundResult = Array.prototype.concat
      .apply([], state.list)
      .find(
        r => ignoreCaseMatch(value, r.option) || ignoreCaseMatch(value, r.value)
      );

    handleSearch(foundResult, value);

    return state;
  },
  [actions.SET_CATEGORY]: mergeStateAndPayload,
  [actions.TOGGLE_SEARCH_RESULTS](state, payload) {
    const resultsShown =
      typeof payload.shown === 'undefined'
        ? !state.resultsShown
        : payload.shown;

    return Object.assign({}, state, { resultsShown });
  }
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
