import { Plan, SettingId } from '../../common/types';
import { fromJS } from 'immutable';
import { isNasdaq } from '../../common/config'

const config = {
  high: { plan: 'free' },
  low: { plan: 'free' },
  increase: { plan: 'free' },
  drop: { plan: 'free' },
  report: { plan: 'free' },
  dividend: { plan: 'free' },
  analyst: {
    plan: 'premium',
    affected: 'ranking'
  },
  blogger: {
    plan: 'premium',
    affected: 'ranking'
  },
  insider: {
    plan: 'premium',
    affected: 'ranking'
  },
  hedgefund: {
    plan: 'premium',
    affected: 'ranking'
  },
  ranking: {
    plan: 'premium',
    infoOnly: true,
    affect: ['analyst', 'blogger', 'insider', 'hedgefund']
  },
  analystConsensus: { plan: isNasdaq ? 'premium' : 'ultimate' },
  bestAnalystConsensus: { plan: 'ultimate' },
  bloggerSentiment: { plan: 'ultimate' },
  insiderSentiment: { plan: 'ultimate' },
  hedgefundSentiment: { plan: 'ultimate' },
  hedgefundActivity: { plan: 'ultimate' }
};

// export type EmailAlertSettingsKey = keyof typeof config;
type Config = { [key: string /*EmailAlertSettingsKey*/]: ConfigValue };
type ConfigValue = {
  plan: Plan;
  infoOnly?: boolean;
  affect?: ('analyst' | 'blogger' | 'insider' | 'hedgefund')[]; // TODO probably not the only types available
  affected?: 'ranking'; // TODO probably not the only types available
};
export const defaultSettings: { id: SettingId, info: string, val: boolean }[] = isNasdaq ? [
  { id: 'high', info: '', val: false },
  { id: 'low', info: '', val: false },
  { id: 'increase', info: '5', val: false },
  { id: 'drop', info: '5', val: false },
  { id: 'announcement', info: '', val: false },
  { id: 'report', info: '', val: false },
  { id: 'dividend', info: '', val: false },
  { id: 'analyst', info: '', val: false },
  { id: 'inider', info: '', val: false },
  { id: 'analystConsensus', info: '', val: false },
] : [
  { id: 'high', info: '', val: false },
  { id: 'low', info: '', val: false },
  { id: 'increase', info: '5', val: false },
  { id: 'drop', info: '5', val: false },
  { id: 'announcement', info: '', val: false },
  { id: 'report', info: '', val: false },
  { id: 'dividend', info: '', val: false },
  { id: 'analyst', info: '', val: false },
  { id: 'blogger', info: '', val: false },
  { id: 'inider', info: '', val: false },
  { id: 'hedgefund', info: '', val: false },
  { id: 'ranking', info: '', val: false },
  { id: 'analystConsensus', info: '', val: false },
];
export const settingsConfig = fromJS(config);
