import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

export default class NameCell extends Component {
  static propTypes = {
    event: PropTypes.object
  };

  render() {
    const { event } = this.props;

    return <span>{event.getIn(['stock', 'name'])}</span>;
  }
}
