import * as React from 'react';
import * as ReactDOM from 'react-dom';
import storage from 'redux-storage';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { AppContainer } from 'react-hot-loader';
import localStorage from './components/personsList/localstorage';
import store, { engine } from './store';
import { applyRouterMiddleware, Router } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { history } from './history.ts';
import { initAppInsights } from 'sp/browser/application-insights';
import { ReduxProps } from '../common/types';

initAppInsights();

if (process.env.NOT_PROD) require('../common/put-selectors-on-window.ts');

if (process.env.IS_BROWSER) {
  // Enabling ES7 `async/await` in browser:
  require('regenerator-runtime/runtime');

  // TODO remove these
  require('./hacks.js');
  require('./hacks.css');
}

const noop = () => null;

// Force redirect to HTTPS
if (
  process.env.NODE_ENV === 'production' &&
  window.location.protocol === 'http:' &&
  window.location.href.indexOf('http://localhost') !== 0
) {
  window.location.protocol = 'https';
}

// polyfill, presumably for an SSR that never came?
window.console = window.console || {
  log: noop,
  warn: noop,
  error: noop,
};

// TODO how is this different from custom6?
// tid
// this magic tid number is used by the payment system 
// as a transaction id (at the time of the comment, inside old-website) 
if (!localStorage.getItem('tid')) {
  localStorage.setItem('tid', Number(new Date()));
}

function reRender(storeState: ReduxProps) {
  const app = document.getElementById('app');
  const createRoutes = require('./createRoutes').default;
  const routes = createRoutes(storeState);
  const middleware = applyRouterMiddleware(useScroll());

  // TODO Don't include appcontainer on production build
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <IntlProvider locale="en">
          <Router
            history={history}
            render={middleware}
            routes={routes}
          />
        </IntlProvider>
      </Provider>
    </AppContainer>,
    app,
  );
}

storage.createLoader(engine)(store).then(() => reRender(store.getState()));

if (process.env.IS_RUN_LOCAL) {
  if (module.hot) {
    module.hot.accept('./createRoutes', () => reRender(store.getState()));
  }
}

// CRAZY EGG
setTimeout(() => {
  const a = document.createElement('script');
  const b = document.getElementsByTagName('script')[0];
  a.src =
    document.location.protocol +
    '//script.crazyegg.com/pages/scripts/0022/7387.js?' +
    Math.floor(new Date().getTime() / 3600000);
  a.async = true;
  a.type = 'text/javascript';
  b.parentNode.insertBefore(a, b);
}, 1);
