import * as React from 'react';
import { fromJS } from 'immutable';

import report from './analytics';

import AddPortfolio from '../AddPortfolio';
import DeletePortfolio from '../tr-dashboard-delete-portfolio';
import { dashboardPath, portfoliosConfig } from '../../common/config';
import {AnalyzingPortfolio} from '../AnalyzingPortfolio';
import PortfolioItemAlerts from '../PortfolioItemAlerts';
import WarningDialog from '../tr-dashboard-warning-dialog';
import { setLastLockedFeature, popupRegister } from '../../common/lib/utils.ts';
import ExternalLogin from '../tr-external-auth/login-form.react';
import LoginForm from '../tr-login-form';
import AddStockContainer from '../tr-dashboard-add-stock-container';
import ExternalRegister from '../tr-external-auth/signup-form.react';
import PromotionContainer from '../tr-dashboard-promotion-container';
import PortfoliosContainer from '../tr-dashboard-portfolios-container';
import PortfolioItemContainer from './dialogs/PortfolioItemContainer.react';
import AlertsSettingsContainer from '../tr-dashboard-alerts-settings-container';
import TrConnectExistingPortfolioContainer from '../tr-connect-existing-portfolio-container';
import Confirm from '../Confirm';
import MyDetails from '../my-details/index.react';
import { promote } from '../TargetedWebPage/actions';
import TrDashboardMgmtFeesComparisonContainer from '../tr-dashboard-mgmt-fees-comparison-container';

import HoldingsMetricsContainer from '../tr-dashboard-holdings-metrics-container';

import DismissableMissingSharesContainer from '../tr-dashboard-dismissable-missing-shares-container';

import {
  selectProccessStates,
  selectPortfolioItems,
  selectPortfolios,
  selectIsLimited,
  selectTheme
} from './selectors.ts';

import {
  selectForm,
  selectViewerPlan,
} from '../../common/auth/selectors.ts';

import localStorage from '../components/personsList/localstorage';
import { setStockAlertValue } from '../tr-dashboard-holdings-section/cells/EmailAlertsCell.react.tsx';
import { history } from 'sp/browser/history';

export const mgmtFeesComparison = (props, dialogArgs) => (
  <TrDashboardMgmtFeesComparisonContainer {...props} {...dialogArgs} />
);

export const addStock = (props, dialogArgs = {}) => (
  <AddStockContainer {...props} {...dialogArgs} />
);

export const portfolioItem = (props, args) => (
  <PortfolioItemContainer {...props} dialogArgs={args} />
);

const byTicker = ticker => stock => stock.get('ticker') === ticker;

const defaultSelectItem = ({ dashboard }, dialogArgs) => {
  const portfolioItems = selectPortfolioItems(dashboard);
  return portfolioItems.get(
    portfolioItems.findIndex(byTicker(dialogArgs.ticker))
  );
};

export const portfolioItemAlerts = (state, args) => {
  const { actions, dashboard, msg } = state;

  const onClickAlert = args.onClickAlert || setStockAlertValue;
  const selectItem = args.selectItem || defaultSelectItem;
  const item = selectItem(state, args);

  return (
    <PortfolioItemAlerts
      enums={msg.enums}
      item={item}
      msg={msg.portfolioItem}
      onClickAlert={onClickAlert}
    />
  );
};

export const alertsSettings = props => <AlertsSettingsContainer {...props} />;

// TODO this doesnt seem to ever be called for register
const onAuthenticatedDialog = (actions, type = '') => () => {
  actions.identifyUser().then(() => {
    actions.closeDialog();
    // history.push(`/${dashboardPath}/holdings`);
    actions.fetchPortfolios();
    window.location.reload();
  });
};

const defaultLoginProps = { type: 'login' };
export const login = (
  { actions, auth, dashboard, msg },
  { type } = defaultLoginProps
) =>
  !localStorage.getItem('preventExternalLogin') ? (
    <ExternalLogin
      onAuthenticated={onAuthenticatedDialog(actions)}
      theme={selectTheme(dashboard)}
      type={type || defaultLoginProps.type}
    />
  ) : (
      <LoginForm
        afterLogin={() => {
          actions.closeDialog('login');
          history.replace('/smart-portfolio');
        }}
        form={selectForm(auth)}
        login={actions.login}
        msg={msg.loginForm}
        onAuthFormFieldChange={actions.onAuthFormFieldChange}
        openRegister={() => {
          actions.closeDialog('login');
          popupRegister();
        }}
        restartForm={actions.restartForm}
      />
    );

const defaultRegisterProps = { type: 'signup' };
export const register = (
  { actions },
  { type: rawType } = defaultRegisterProps
) => {
  const type = rawType || defaultRegisterProps.type;
  return (
    <ExternalRegister
      onAuthenticated={onAuthenticatedDialog(actions, type)}
      type={type}
    />
  );
};

export const holdingsMetrics = props => <HoldingsMetricsContainer {...props} />;

export const analyzingPortfolio = ({ dashboard, msg }) => (
  <AnalyzingPortfolio
    msg={msg.analyzingPortfolio}
    theme={selectTheme(dashboard)}
  />
);

export const portfolios = props => <PortfoliosContainer {...props} />;

export const addPortfolio = (props) => {
  const { actions, auth, dashboard, msg, location } = props;
  const showPromotion = () => {
    if (selectViewerPlan(auth) !== 'ultimate') {
      setLastLockedFeature('add-portfolio-limit');
      promote({ plan: 'ultimate', type: 'portfolios' });
    }
  };
  const expertId = auth.get('users').get('viewer').get('loggedInUser').get('expertId')
  return (
    <AddPortfolio
      addPortfolio={actions.addPortfolio}
      fetchPortfolios={actions.fetchPortfolios}
      changePortfolio={actions.changePortfolio}
      close={() => actions.closeDialog('addPortfolio')}
      componentStates={selectProccessStates(dashboard)}
      editPortfolios={() => {
        actions.closeDialog('addPortfolio');
        actions.openDialog('portfolios');
      }}
      isLimited={selectIsLimited(dashboard, selectViewerPlan(auth))}
      isUltimate={selectViewerPlan(auth) === 'ultimate'}
      msg={msg.addPortfolio}
      expertId={expertId}
      openSyncDialog={() => { report('clickedImportPortfolio'); }}
      showPromotion={showPromotion}
      navigationOnDone={() => {
        if (location.pathname.includes('smart-portfolio/holdings/holdings')) { return; }
        history.push('/smart-portfolio/holdings/holdings');
      }}
    />
  );
};

export const deletePortfolio = (
  { actions, dashboard, msg },
  { portfolioId }
) => {
  const portfolios = selectPortfolios(dashboard);
  const portfolio = portfolios.find(
    portfolio => portfolio.get('id') === portfolioId
  );

  if (!portfolio) actions.closeDialog('deletePortfolio');

  return (
    <DeletePortfolio
      close={actions.closeDialog}
      deletePortfolio={() => actions.deletePortfolio(portfolioId)}
      msg={msg.deletePortfolio}
      portfolioName={portfolio.get('name')}
    />
  );
};

export const promotion = (props, { plan, type }) => (
  <PromotionContainer {...props} plan={plan} type={type} />
);

export const syncDialog = (props, { broker, isReauth } = {}) => (
  <TrConnectExistingPortfolioContainer
    {...props}
    broker={broker}
    isReauth={isReauth}
  />
);

export const missingSharesOnNavOut = ({ actions, msg }) => {
  const closeAndFillShares = () => {
    actions.closeDialog();
    history.push(`/${dashboardPath}/holdings`);
  };

  const controls = fromJS([
    {
      action: closeAndFillShares,
      label: msg.dialogs.missingSharesOnNavOut.positive
    }
  ]);

  return (
    <WarningDialog
      controls={controls}
      isDismissable
      dismissWarningDialog={() =>
        actions.dismissWarningDialog('missingSharesOnNavOut')
      }
      msg={msg.warningDialog}
      warning={msg.dialogs.missingSharesOnNavOut.warning}
    />
  );
};

export const confirmRemoval = (
  { actions, msg },
  { ticker, portfolio, actionWhenConfirm, actionWhenCancel }
) => {
  const controls = fromJS([
    {
      action: () => {
        actionWhenConfirm();
        actions.closeDialog();
      },
      label: msg.dialogs.confirmRemoval.positive
    },
    {
      action: () => actions.closeDialog(),
      label: msg.dialogs.confirmRemoval.negative
    }
  ]);

  return (
    <WarningDialog
      controls={controls}
      dismissWarningDialog={() => {
        actions.dismissWarningDialog('confirmRemoval');
        actionWhenCancel();
      }}
      msg={msg.dialogs.confirmRemoval}
      warning={msg.dialogs.confirmRemoval.warning
        .replace(/{ticker}/g, ticker.toUpperCase())
        .replace(/{portfolio}/g, portfolio)}
    />
  );
};

export const missingSharesOnNavIn = props => (
  <DismissableMissingSharesContainer {...props} />
);

export const goToHoldings = (props, dialogArgs = {}) => (
  <Confirm
    cancelHandler={props.actions.closeDialog}
    cancelMsg="Not Now"
    okHandler={() => {
      history.push(`/${dashboardPath}/holdings`);
      props.actions.closeDialog();
    }}
    okMsg="Go to My Holdings"
    question="See your measured performance as a portfolio manager by adding no. of shares to your holdings"
    title="Add Stock Holdings"
  />
);

export const myDetails = (props, dialogArgs = {}) => <MyDetails {...props} />;

export const plaidSyncLoader = props => (<div></div>);
