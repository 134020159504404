import * as actions from './actions';

export const initialState = { sideMenuOpen: false };

const actionHandlers = {
  [actions.TOGGLE_SIDE_MENU]: (state, payload) => ({
    sideMenuOpen: typeof payload !== 'undefined' ? payload : !state.sideMenuOpen
  }),

  [actions.OPEN_SIDE_MENU]: (state, payload) => ({
    sideMenuOpen: true
  }),

  [actions.CLOSE_SIDE_MENU]: (state, payload) => ({
    sideMenuOpen: false
  })
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
