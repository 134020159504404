import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Component from 'react-pure-render/component';
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';
import { holdingsSectionConfig, resrictions } from '../../common/config';
import report from './analytics';
import { Suit } from '../components/suit';
import { ApiDecorator } from '../ApiDecorator';
import { maybefetch } from '../../common/api-actions';
import PersonsList from '../tr-dashboard-experts/dashboard-experts.react';
import { ExpertFilters } from '../components/expertFilters';
import {
  selectProccessStates,
  selectActivePortfolio,
} from '../dashboard/selectors';
import { setLastLockedFeature, popupRegister } from '../../common/lib/utils.ts';

import {
  selectViewerNextPlan,
  selectIsPermanentLoggedIn,
  selectViewerPlan,
} from '../../common/auth/selectors.ts';
import { promote } from '../TargetedWebPage/actions.ts';
import {
  selectExperts,
  selectBusyExperts,
  selectStats,
  selectDeletedExperts,
  selectExpertTypes,
} from './selectors.ts';

@ApiDecorator('stats', 'experts')
export default class DashboardExperts extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object,
  };

  componentDidMount() {
    report('visited');
  }

  changeFilter = (...args) => {
    const { actions } = this.props;
    actions.changeFilter(...args);
    report('changedFilter');
  };

  openPortfolioItemAlerts = (_, item) => {
    const { actions } = this.props;
    actions.openDialog('portfolioItemAlerts', {
      id: item.id,
      onClickAlert: this.setExpertAlertValue,
      selectItem: this.selectExpert,
      dialogSystemTitle: 'Expert Notifications',
    });
  };

  openPortfolioItem = (_, item) => {
    const { actions } = this.props;
    actions.openDialog('portfolioItem', {
      stats: holdingsSectionConfig.get('personCols'),
    });
  };

  setExpertAlertValue = expert => {
    const { actions, auth, myExperts, dashboard } = this.props;

    const portfolioId = selectActivePortfolio(dashboard).get('id');
    const isLoggedIn = selectIsPermanentLoggedIn(dashboard);
    if (!isLoggedIn) {
      return popupRegister();
    }
    const expertsWithAlertsTotal = myExperts
      .get('experts')
      .map(expert => expert.get('isAlertsOn'))
      .filter(isAlertsOn => isAlertsOn).size;
    const viewerPlan = selectViewerPlan(auth);
    const limit = resrictions.getIn(['expertAlertsLimit', viewerPlan]);
    if (
      expert.get('isAlertsOn') ||
      expertsWithAlertsTotal < limit ||
      limit < 0
    ) {
      actions.toggleExpertFollow(expert);
    } else {
      setLastLockedFeature('sp-experts-follow-limit');
      promote({ plan: selectViewerNextPlan(auth), type: 'expertAlertsLimit' });
    }
  };

  selectExpert = (state, args) =>
    state.myExperts.experts.find(x => x.id === args.id);

  render() {
    const { actions, auth, myExperts, dashboard, msg, mediaQuery } = this.props;

    const experts = selectExperts(myExperts);
    const busyExperts = selectBusyExperts(dashboard);
    const stats = selectStats(dashboard);
    const deletedExperts = selectDeletedExperts(dashboard);
    const expertTypes = selectExpertTypes(dashboard);
    const componentStates = selectProccessStates(dashboard);

    const showTooltip = actions.showTooltip;
    const hideTooltip = actions.hideTooltip;
    const setStockLastRead = actions.setExpertLastRead;
    const openPortfolioItemAlerts = this.openPortfolioItemAlerts;
    const openPortfolioItem = this.openPortfolioItem;
    const { enums } = msg;

    const apiDependencies = [
      componentStates.get('experts'),
      componentStates.get('stats'),
    ];

    const noFilters = expertTypes.size === 0;
    const noFollowing = experts.size === 0;

    return (
      <div className="dashboard-experts">
        <Suit disableAnimation fetch={this.fetchData} state={apiDependencies}>
          <div>
            {noFollowing ? (
              <div className="note">
                <p>
                  <FormattedHTMLMessage
                    defaultMessage={msg.experts.noFollowing}
                    id={msg.experts.noFollowing}
                  />
                </p>
                <a href="/top-experts" target="_blank">
                  {msg.experts.expertCenter}
                </a>
              </div>
            ) : noFilters ? (
              <div className="note">{msg.experts.noFilters}</div>
            ) : (
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionAppear
                    transitionAppearTimeout={2000}
                    transitionEnterTimeout={2000}
                    transitionLeaveTimeout={2000}
                    transitionName="opacity-delay"
                  >
                    <PersonsList
                      settings={myExperts}
                      actions={actions}
                      auth={auth}
                      dashboard={dashboard}
                      expertTypes={expertTypes}
                      experts={experts}
                      onClickAlert={this.setExpertAlertValue}
                      mediaQuery={mediaQuery}
                      stats={stats}
                      showTooltip={showTooltip}
                      hideTooltip={hideTooltip}
                      setStockLastRead={setStockLastRead}
                      openPortfolioItem={openPortfolioItem}
                      openPortfolioItemAlerts={openPortfolioItemAlerts}
                      enums={enums}
                    />
                  </ReactCSSTransitionGroup>
                )}
          </div>
        </Suit>
      </div>
    );
  }

  fetchData = () => {
    maybefetch('experts', this.props, { force: true });
  };
}
