import { Routes } from "sp/common/types";
import { getGlobalActions } from "sp/browser/lib/actions";
import { selectIsOnPublicProileRoute, navigateToPublicPortfolioToRegister, getPublicPortfolioToRegisterUrl } from "sp/browser/pages/PublicPortfolioToRegister/utils";
import { history } from 'sp/browser/history';
import { selectIsLoggedIn } from "sp/common/auth/selectors";
import { dashboardPath } from "sp/common/config";

// TODO document usage
export function requireAuth(routes: Routes) {
  if (window.location.pathname === '/smart-portfolio/welcome') {
    getGlobalActions().openDialog('addStock');
  } else if (selectIsOnPublicProileRoute(routes)) {
    navigateToPublicPortfolioToRegister(routes);
  } else {
    history.push('/smart-portfolio/welcome');
  }
}

/**
 * Used by routes in createRoutes to make sure user is logge din
 * when trying to access locked sections of the app.
 */
export function requireAuthReplace(nextState, replaceRoute) {
  const isLoggedIn = selectIsLoggedIn();

  if (!isLoggedIn) {
    if (selectIsOnPublicProileRoute(nextState.routes)) {
      replaceRoute(getPublicPortfolioToRegisterUrl(nextState.routes))
    } else {
      replaceRoute(`/${dashboardPath}/welcome`);
    }
  }
};