import reporter from '../analytics';

const events = {
  visited: 'visited-smart-portfolio-overview-page',
  changedMarketOverviewMarketVisibility: 'clicked-market-overview-checkboxes',
  changedMraketOverviewDuration: 'clicked-market-overview-timeframe'
};

const report = reporter(events);

export default report;
