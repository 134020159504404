import { fromJS, List, Record } from 'immutable';
import { getColById } from './cols.ts';

export const deleteByVals = (...valsToDelete) => group =>
  valsToDelete.reduce(
    (group, valToDelete) =>
      group ? group.delete(group.findIndex(val => val === valToDelete)) : group,
    group
  );

export const unshiftVals = (...valsToUnshift) => group =>
  valsToUnshift.reduce(
    (group, valToUnshift) => (group ? group.unshift(valToUnshift) : group),
    group
  );

export const cols = new List([
  'name',
  'price',
  'value',
  'analystConsensus',
  'sincePurchaseGain',
  'alerts'
]);

const colslaptopS = cols.insert(4, 'target');

const detailGroups = fromJS([
  {
    width: 0.65,
    details: ['prices']
  },
  {
    width: 0.35,
    details: [
      'shares',
      'purchasePrice',
      'added',
      'gainSinceAdded',
      'addedPrice',
      'percent'
    ]
  },
  {
    width: 0.5,
    border: true,
    details: [
      'target',
      'hedgefundSentiment',
      'insiderSentiment',
      'bloggerSentiment',
      'highLow'
    ]
  },
  {
    width: 0.5,
    border: true,
    details: ['cap', 'earningsReport', 'pe', 'dividend', 'beta']
  }
]);

const detailGroupsLaptopS = fromJS([
  {
    width: 0.7,
    details: ['prices']
  },
  {
    width: 0.3,
    details: [
      'shares',
      'purchasePrice',
      'added',
      'gainSinceAdded',
      'addedPrice',
      'percent'
    ]
  },
  {
    width: 1 / 3,
    border: true,
    details: ['hedgefundSentiment', 'insiderSentiment', 'bloggerSentiment']
  },
  {
    width: 1 / 3,
    border: true,
    details: ['highLow', 'cap', 'earningsReport']
  },
  {
    width: 1 / 3,
    border: true,
    details: ['pe', 'dividend', 'beta']
  }
]);

export const getRawCols = mediaQuery =>
  mediaQuery.get('laptopS') ? colslaptopS : cols;

export const getCols = rawCols => mediaQuery => rawCols(mediaQuery).map(getColById);

export const getDetailGroups = mediaQuery =>
  (mediaQuery.get('laptopS') ? detailGroupsLaptopS : detailGroups).map(group =>
    group.set('details', group.get('details').map(getColById))
  );

// previously called consensusAndTargetTypes
export const basicTableTypes = {
  all: { id: 'all', minPlan: 'free' },
  best: { id: 'best', minPlan: 'premium', hasTooltip: true }
};

export const basicTableTypesList = [
  basicTableTypes.all,
  basicTableTypes.best
];
