import { createSelector } from 'reselect';

import { sortWeight } from '../../../../../common/config';

import {
  selectoHoldingsSortBy,
  selectIsHoldingsSortDescending,
  selectPortfolioItems,
  selectActivePortfolio
} from '../../../selectors';

import {
  descendtify,
  sortAlphabeticallyIn,
  sortByArraySizeIn,
  sortByDateIn,
  sortByListIndexIn,
  sortNumericallyIn
} from '../../../../../common/lib/sort';

const strategies = {
  name: sortAlphabeticallyIn(['stock', 'ticker']),
  price: sortNumericallyIn(['stock', 'price', 'amount']),
  target: sortNumericallyIn(['stock', 'target']),
  bestTarget: sortNumericallyIn(['stock', 'bestTarget']),
  analystConsensus: sortByListIndexIn(
    ['stock', 'analystConsensus', 'decision'],
    sortWeight.get('consensus')
  ),
  bestAnalystConsensus: (...args) => console.log('args', args),
  insiderSentiment: sortByListIndexIn(
    ['stock', 'insiderSentiment'],
    sortWeight.get('sentiment')
  ),
  highLow: sortNumericallyIn(['stock', 'price', 'ftWeekLow']),
  added: sortByDateIn(['addedDate']),
  shares: sortNumericallyIn(['sharesTotal']),
  value: sortNumericallyIn(['sharesValue']),
  percent: sortNumericallyIn(['percentPortfolio']),
  dividend: sortNumericallyIn(['stock', 'dividend']),
  yield: sortNumericallyIn(['stock', 'yield']),
  dividendDate: sortNumericallyIn(['stock', 'dividendDate']),
  earningsReport: sortNumericallyIn(['stock', 'earningsReport']),
  eps: sortNumericallyIn(['stock', 'eps']),
  sector: sortAlphabeticallyIn(['stock', 'sector']),
  cap: sortNumericallyIn(['stock', 'price', 'cap']),
  beta: sortNumericallyIn(['stock', 'price', 'beta']),
  hedgefundSentiment: sortByListIndexIn(
    ['stock', 'hedgefundSentiment'],
    sortWeight.get('sentiment')
  ),
  purchasePrice: sortNumericallyIn(['purchasePrice']),
  bloggerSentiment: sortByListIndexIn(
    ['stock', 'bloggerSentiment'],
    sortWeight.get('sentiment')
  ),
  pe: sortNumericallyIn(['stock', 'pe']),
  gainSinceAdded: sortNumericallyIn(['gainSinceAdded']),
  alerts: sortByArraySizeIn(['alerts']),
  emailAlerts: sortNumericallyIn(['isAlertsOn'])
};

const defaultStrategy = () => 0;

const sortBy = (field, isDescending) => {
  const strategy = strategies[field];
  if (!strategy) {
    console.warn(`No sorting strategy for ${field} field.`);
    return defaultStrategy;
  }
  return descendtify(strategy, isDescending);
};

export const itemsSelector = createSelector(
  selectPortfolioItems,
  selectoHoldingsSortBy,
  selectIsHoldingsSortDescending,
  (items, holdingsSortBy, isholdingsSortDescending) =>
    items.sort(sortBy(holdingsSortBy, isholdingsSortDescending))
);

// export const selectShareChangesCounter = createSelector(
//   selectActivePortfolio,
//   (activePortfolio) => activePortfolio.getIn(['stats', 'changes'])
// );
