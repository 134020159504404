import * as React from 'react';
import * as classNames from 'classnames';
import '../styles/distributionpicker.styl';
import * as format from './utils';
import { getSectorText } from 'sp/common/lib/utils';
import { BetterRecord } from 'sp/common/immutableStuff';

type T = BetterRecord<{ id: any, count: number, }>;
/**
 * Assyne 
 */
export default (props: {
  types: T[],
  picked: T,
  onChange: any,
  colors: any,
}) => (
    <div className="distribution-picker">
      <ul>
        {props.types.map((item, index) => {
          const id = item.get('id'); // TODO find the place that made this fix needed and fix that instead of here // .replace('Unknownsector', 'Unknown Sector');
          const isChecked = props.picked === item || props.picked === id;
          const className = id.replace(/\s+/g, '-').toLowerCase();
          const sectorName = getSectorText(item.get('id'), { fallback: null }) // for stocks
            || item.get('id'); // for etfs and funds
          return (
            <li key={id} className={classNames({ 'is-checked': isChecked })}>
              <label>
                <input
                  name="distributionPicker"
                  type="radio"
                  onChange={() => props.onChange(item, index)}
                  checked={isChecked}
                />
                <span className="radio-button" />
                <span
                  className={`box ${className}`}
                  style={{ backgroundColor: props.colors.get(index) }}
                />
                <span>{format.percentRounded(item.get('count'))}</span>{' '}
                <span title={sectorName}>{sectorName}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
