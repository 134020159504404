import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router';
import { List } from 'immutable';
import { isShittyBrowser } from 'sp/browser/lib/utils';
import DateCell from './cellsContent/DateCell.react';
import NameCell from './cellsContent/NameCell.react';
import EventCell from './cellsContent/EventCell.react';
import TickerCell from './cellsContent/TickerCell.react';
import { NameCombinedCell } from './cellsContent/NameCombinedCell';
import EventAndDateCell from './cellsContent/EventAndDateCell.react';

import { NoDataAddStocks } from '../dashboard/NoDataAddStocks.tsx';
import { Loader } from '../components/suit';

import { dashboardPath } from '../../common/config';

const colsSingle = [
  { name: 'ticker', component: TickerCell },
  { name: 'name', component: NameCell },
  { name: 'event', component: EventCell },
  { name: 'date', component: DateCell }
];

const colsCombined = [
  { name: 'nameCombined', component: NameCombinedCell },
  { name: 'eventAndDate', component: EventAndDateCell }
];

export default class PortfolioEvents extends Component {
  static propTypes = {
    dependencies: PropTypes.any,
    events: PropTypes.object,
    isCombinedCells: PropTypes.bool,
    max: PropTypes.number,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object,
    noData: PropTypes.bool,
    noDataMsg: PropTypes.object,
    openAddStockDialog: PropTypes.func
  };

  static defaultProps = {
    isCombinedCells: false,
    max: -1
  };

  render() {
    const {
      dependencies,
      events,
      isCombinedCells,
      max,
      mediaQuery,
      msg,
      noData,
      noDataMsg,
      openAddStockDialog
    } = this.props;

    const itemsByLimit = max >= 0 ? events.take(max) : events;

    const isMultipleTableView =
      mediaQuery.get('tablet') && !mediaQuery.get('laptopXS');

    const groups =
      isCombinedCells && !noData && isMultipleTableView
        ? itemsByLimit
          .groupBy(
            (item, index) =>
              itemsByLimit.size > 1 ? Math.floor(index % 2) : 0
          )
          .valueSeq()
        : new List([itemsByLimit]);

    const isLoading = dependencies.some(d => d === 'LOADING');
    const cols = isCombinedCells ? colsCombined : colsSingle;

    return (
      <section className={classNames('portfolio-events', { isCombinedCells })}>
        <header>
          <h1>
            <span>{msg.title}</span>
          </h1>
        </header>
        <div className="section-inner">
          <div className={classNames('table-wrapper')}>
            {groups.map((itemsByLimit, index) => (
              <table
                className={classNames({ singleTable: groups.size === 1 })}
                key={index}
              >
                <thead>
                  <tr>
                    {cols.map(({ name: col }) => (
                      <th className={col} key={col}>
                        <div className="th-inner-without-button">
                          {msg.cols[col]}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={cols.length}>
                        <div className="no-data">
                          <Loader name="events-calendar" />
                        </div>
                      </td>
                    </tr>
                  ) : noData ? (
                    <tr>
                      <td colSpan={cols.length}>
                        <NoDataAddStocks
                          msg={noDataMsg}
                          openAddStockDialog={openAddStockDialog}
                        />
                      </td>
                    </tr>
                  ) : itemsByLimit.size === 0 ? (
                    <tr>
                      <td colSpan={cols.length}>
                        <NoDataAddStocks
                          msg={msg.noNews}
                          openAddStockDialog={openAddStockDialog}
                        />
                      </td>
                    </tr>
                  ) : (
                          itemsByLimit.map((e, index) => (
                            <tr className={e.get('type')} key={index}>
                              {cols.map(
                                ({ component: CellContent, name: col }, index) => {
                                  return (
                                    <td className={classNames(col)} key={index}>
                                      <div className="td-inner">
                                        <CellContent event={e} msg={msg} />
                                      </div>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          ))
                        )}
                </tbody>
              </table>
            ))}
          </div>
          <div className="panel">
            <Link to={`/${dashboardPath}/holdings/calendar`}>
              <FormattedHTMLMessage id={msg.next} defaultMessage={msg.next} />
            </Link>
          </div>
        </div>
      </section>
    );
  }
}
