exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-highstocks-HighStock-styles__graph{position:relative}.src-browser-highstocks-HighStock-styles__price_bubble{border-radius:2px;font-size:11px;line-height:1;position:absolute;color:#fff;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);padding:4px 4px 4px 6px}.src-browser-highstocks-HighStock-styles__price_bubble:after{right:100%;top:50%;content:\" \";height:0;width:0;position:absolute;pointer-events:none;border:9px solid rgba(0,0,0,0);margin-top:-9px}.src-browser-highstocks-HighStock-styles__price_bubble.src-browser-highstocks-HighStock-styles__up{background-color:#91c27b}.src-browser-highstocks-HighStock-styles__price_bubble.src-browser-highstocks-HighStock-styles__up:after{border-right-color:#91c27b}.src-browser-highstocks-HighStock-styles__price_bubble.src-browser-highstocks-HighStock-styles__down{background-color:#b11617}.src-browser-highstocks-HighStock-styles__price_bubble.src-browser-highstocks-HighStock-styles__down:after{border-right-color:#b11617}", ""]);

// Exports
exports.locals = {
	"graph": "src-browser-highstocks-HighStock-styles__graph",
	"price_bubble": "src-browser-highstocks-HighStock-styles__price_bubble",
	"up": "src-browser-highstocks-HighStock-styles__up",
	"down": "src-browser-highstocks-HighStock-styles__down"
};