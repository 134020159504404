import * as React from 'react';
import { suits } from '../../common/enums';
import { maybefetch, getState } from '../../common/api-actions';

/**
 * ApiDecorator recieves a list of apiNames that correspond to keys in api-actions,
 * and runs the appropriate api-action, until the reducer sets the appropriate componentState
 * as success.
 * While we're loading the component's dependencies { apiState: suits.LOADING }, then it's suits.SUCCESS.
 * Note: `details` apiName is not tracked as part of the the "Promise.all" on all the apiNames,
 * the rationale, I presume, is that details is a "real-time" call.
 * TODO it'll probably be better for details to have a 5m or 15m debounce, instead of EACH time like ATM.
 */
export const ApiDecorator = (...apiNames) => Child =>
  class ApiDecorator extends React.Component {
    constructor(props) {
      super(props);
      this.state = { apiState: this.calcState(props) };
    }

    componentDidMount() {
      apiNames.forEach(apiName => maybefetch(apiName, this.props));
    }

    componentDidUpdate(prevProps) {
      apiNames.forEach(apiName =>
        maybefetch(apiName, this.props, { prevProps }),
      );
    }

    // apiState should be set before receiving props
    // with componentWillReceiveProps (and not componentDidReceiveProps)
    // see TIPRANKS-16945
    componentWillReceiveProps(nextProps) {
      this.setState({ apiState: this.calcState(nextProps) });
    }

    render() {
      return <Child {...this.props} {...this.state} />;
    }

    calcState = nextProps => {
      const isInitial = x => x === suits.INITIAL || !x;
      const isError = x => x === suits.ERROR;
      const isLoading = x => x === suits.LOADING;
      const out = (...names) => name => !names.includes(name);

      const toState = x => getState(x, nextProps);
      const apiStates = apiNames.filter(out('details')).map(toState);

      // if (process.env.NOT_PROD) {
      //   this.setState({
      //     debug_apiStates:
      //       apiNames.filter(out('details')).reduce((hash, name) => ({ ...hash, [name]: toState(name) }), {})
      //   });
      //   const compName = '';
      //   // if (process.env.IS_RUN_LOCAL) console.info(`${compName}@api(${debug_apiStates.map(d => `${d.name}:${d.state}`).join(',\n')})`, Child);
      // }

      if (apiStates.some(isLoading)) {
        return suits.LOADING;
      } else if (apiStates.some(isError)) {
        return suits.ERROR;
      } else if (apiStates.some(isInitial)) {
        return suits.INITIAL;
      }
      return suits.SUCCESS;
    };
  };
