import * as React from 'react';
import * as classNames from 'classnames';

// const changeHandler = onChange =>
//   ({ target }) =>
//     onChange(target.value);

// const keyPressed = doneEditing =>
//   ({ target, which }) =>
//     which === 13 && doneEditing(target.value, { goNext: true });

export default class Editable extends React.Component<
  {
    value: string; // React.PropTypes.string,
    onClickedWhenEditingPrevented: any; // React.PropTypes.func,
    onChange: any; // React.PropTypes.func,
    emptyContent: string; // React.PropTypes.string,
    preventEditing: boolean; // React.PropTypes.bool
    className: string;
    title: string;
    classNames?: string;
  },
  {
    isEditing: boolean;
    value: string;
  }
  > {
  inputRef?: HTMLInputElement;

  static defaultProps = {
    onChange: () => null,
    preventEditing: false,
    onClickedWhenEditingPrevented: () => null,
  };

  constructor(props) {
    super(props);
    this.state = { isEditing: false, value: props.value };
  }

  componentDidMount() { }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.inputRef &&
      this.state.isEditing &&
      prevState.isEditing !== this.state.isEditing
    ) {
      this.inputRef.focus();
    }
  }

  componentWillUnmount() { }

  get passProps() {
    return this.state.isEditing
      ? {
        className: `${this.props.className}-input`,
        value: this.state.value,
      }
      : {
        className: this.props.className,
        title: this.props.title,
      };
  }

  toggle = didChange => () => {
    const { onClickedWhenEditingPrevented, preventEditing } = this.props;
    const { isEditing } = this.state;

    if (preventEditing) {
      onClickedWhenEditingPrevented();
      return;
    }

    if (isEditing && didChange) {
      this.props.onChange(this.state.value);
      // todo: add error/success/start to actions for actual state changing.
    } else {
      this.setState({ value: this.props.value });
    }
    this.setState({ isEditing: !isEditing });
  };

  onKeypress = e => {
    if (e.which === 13) {
      this.toggle(true)();
    }
  };

  edit = e => {
    this.setState({ value: e.target.value });
  };

  render(
    { value, emptyContent, preventEditing, classNames: classNames_ } = this
      .props,
    { isEditing } = this.state,
  ) {
    const wrapperName = `${this.passProps.className}-wrapper`;
    const divText = value || emptyContent;
    return isEditing ? (
      <input
        placeholder={emptyContent}
        ref={ref => (this.inputRef = ref as HTMLInputElement)}
        onChange={this.edit}
        onBlur={this.toggle(true)}
        onKeyPress={this.onKeypress}
        {...this.passProps}
      />
    ) : (
        <div className={classNames(wrapperName, classNames_)}>
          {' '}
          <div {...this.passProps} onClick={this.toggle(false)}>
            {divText}
          </div>
          <img
            alt="edit"
            src="/assets/img/edit.png"
            onClick={this.toggle(false)}
          />
          {' '}
        </div>
      );
  }
}
