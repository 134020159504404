import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { promote } from '../../TargetedWebPage/actions.ts';
import PortfolioItem from '../../PortfolioItem';
import { maybefetch } from '../../../common/api-actions.ts';
import reportHoldings from '../../tr-dashboard-holdings-section-container/analytics';
import { BROWSER_DEVELOPMENT } from '../../../common/config.ts';
import { setStockAlertValue } from '../../tr-dashboard-holdings-section/cells/EmailAlertsCell.react.tsx';

import {
  selectActivePortfolio,
  selectEditableId,
  selectPortfolioItems,
  selectProccessStates,
  selectIsCombinedPortfolio,
} from '../selectors.ts';

import {
  selectViewerPlan,
  selectIsPermanentLoggedIn
} from '../../../common/auth/selectors.ts';

const byTicker = ticker => stock => {
  const lowercaseComparison =
    (stock.get('ticker') || '').toLowerCase() === (ticker || '').toLowerCase();
  const nonLowercaseComparison = stock.get('ticker') === ticker;
  if (BROWSER_DEVELOPMENT && !lowercaseComparison)
    console.warn('Lowercase ticker comparison issues found!');
  return nonLowercaseComparison;
};

const selectItem = (dashboard, dialogArgs) => {
  const portfolioItems = selectPortfolioItems(dashboard);
  return portfolioItems.get(
    portfolioItems.findIndex(byTicker(dialogArgs.ticker))
  );
};

export default class PortfolioItemContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    dialogArgs: PropTypes.object,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object
  };

  render() {
    const {
      actions,
      auth,
      dashboard,
      dialogArgs,
      mediaQuery,
      msg
    } = this.props;

    const activePortfolio = selectActivePortfolio(dashboard);
    const componentState = selectProccessStates(dashboard);

    const isImportedPortfolio = activePortfolio.get('isImported');
    const isCombinedPortfolio = selectIsCombinedPortfolio(dashboard);
    // product doesn't want users to remain without stocks, so we don't show delete btn
    // it's still deletable via actions though
    const isLastStock = this.getItems().size <= 1;
    const shouldShowStockDeleteBtn = !isLastStock && !isImportedPortfolio && !isCombinedPortfolio;

    return (
      <PortfolioItem
        stats={dialogArgs.stats}
        changeEditable={actions.changeEditable}
        cols={msg.dashboard.holdingsSection.cols}
        deleteItem={this.deleteStock}
        dependencies={componentState}
        editableId={selectEditableId(dashboard)}
        enums={msg.enums}
        fetchPriceData={this.fetchPriceData}
        hideTooltip={actions.hideTooltip}
        item={selectItem(dashboard, dialogArgs)}
        mediaQuery={mediaQuery}
        modes={activePortfolio.get('modes')}
        msg={msg.portfolioItem}
        openPortfolioItem={this.openPortfolioItem}
        openPortfolioItemAlerts={this.openPortfolioItemAlerts}
        portfolioId={activePortfolio.get('id')}
        priceDependencies={componentState.get('details')}
        setShares={this.setShares}
        setPurchasePrice={this.setPurchasePrice}
        setStockLastRead={actions.setStockLastRead}
        showPromotion={this.showPromotion}
        showTooltip={actions.showTooltip}
        onClickAlert={setStockAlertValue}
        viewerPlan={selectViewerPlan(auth)}
        shouldShowStockDeleteBtn={shouldShowStockDeleteBtn}
        isImportedPortfolio={isImportedPortfolio}
        isCombinedPortfolio={isCombinedPortfolio}
      />
    );
  }

  getItems = () => {
    return selectPortfolioItems(this.props.dashboard);
  };

  setShares = (portfolioId, ticker, shares, sharesTotal) => {
    const { actions } = this.props;
    reportHoldings('changedSharesTotal');
    if (shares === 0 && this.getItems().size > 1) {
      return this.deleteStock(ticker);
    } else if (shares !== 0) {
      actions.setShares(portfolioId, ticker, shares, sharesTotal);
      return Promise.resolve({ hasSetShares: true });
    }
  };

  setPurchasePrice = (...args) => {
    const { actions } = this.props;
    actions.setPurchasePrice(...args);
    reportHoldings('changedPurchasePrice');
  };

  openDialog = (...args) => {
    const { actions } = this.props;
    actions.openDialog(...args);
  };

  fetchData = () => {
    maybefetch('portfolioItems', this.props, { force: true });
  };

  openPortfolioItem = ticker => {
    this.openDialog('portfolioItem', { ticker });
  };

  openPortfolioItemAlerts = ticker => {
    this.openDialog('portfolioItemAlerts', { ticker });
  };

  fetchPriceData = () => {
    maybefetch('details', this.props, { force: true });
  };

  showPromotion = (plan, type) => {
    reportHoldings.setLastPromotionTrigger('clickedRestrictedTrigger', type);
    reportHoldings('clickedRestricted');
    promote({ type, plan });
  };

  deleteStock = () =>
    new Promise((resolve, reject) => {
      const { actions, dashboard, dialogArgs } = this.props;
      const activePortfolio = selectActivePortfolio(dashboard);
      const portfolioId = activePortfolio.get('id');
      const item = selectItem(dashboard, dialogArgs);
      const ticker = item.get('ticker');
      actions.openDialog('confirmRemoval', {
        ticker,
        portfolio: activePortfolio.get('name'),
        actionWhenConfirm: () => {
          reportHoldings('deleteStock');
          actions.deleteStock(portfolioId, ticker);
          resolve({ hasSetShares: true });
        },
        actionWhenCancel: () => resolve({ hasSetShares: false })
      });
    });
}
