import { percentField, isCanadianTicker } from '../lib/utils';
import './index.styl';
import * as classNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { MediaCoverageAnalysisHighStockChart } from 'sp/browser/highstocks/MediaCoverageAnalysisChart';

import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import { hasPayed as hasPayedSelector, hasPayed } from '../../common/auth/selectors';
import { suits } from '../../common/enums';
import { msg } from '../../common/intl/messages/en';
import * as helperStyles from '../../common/styles/common.istyl';
import { AuthRecord, Dashboard } from '../../common/types';
import { Actions } from '../../common/types';
import {
  selectPortfolioItems,
  selectPortfolioNewsTicker,
} from '../dashboard/selectors';
import { selectIsNewsSentimentsLoading } from '../dashboard/selectors';
import { VariableSizeSpinner } from '../Spinner/index.react';
import { report } from './analytics';
import { ArcGraph } from './ArcGraph';
import * as arcGraphStyles from './arcGraphStyles.istyl';
import { Box } from './Box';
import { GraphLegend } from './GraphLegend';
import * as styles from './index.istyl';
import { restrict } from './Restrictable';
import { TopStocks } from './TopStocks';
import {
  APICounts,
  Consensus,
  Keyword,
  MediaBuzzLegend,
  NewsSentimentLegend,
  Plan,
  PossibleRestriction,
  Price,
  SectorEnum,
  SentimentEnum,
  TopStock,
} from './types';
import { calcMediaBuzz, calcNewsSentiment } from './utils';
import {
  BullishBearishConsensusBar,
  ConsensusSentiment,
  NumberConsensusBar,
  TextVisualConsensus,
} from './VisualConsensus';
import * as mediaBuzzStyles from './VisualConsensus.istyl';
import * as visualConsensusStylesOGT from './VisualConsensus.istyl';
import * as visualConsensusStyles from './visualConsensusStyles.istyl';
import { WordCloud } from './WordCloud';
import { ApiDecorator } from 'sp/browser/ApiDecorator/ApiDecorator.react';
import { redrawOnResize } from 'sp/browser/lib/RedrawOnResize';
import { getSectorText } from 'sp/common/lib/utils';

const bearImg = (
  <img
    src="/assets/img/news-analysis/bear_small.png"
    className={classNames(styles.arcGraphImg)}
  />
);
const bullImg = (
  <img
    src="/assets/img/news-analysis/bull_small.png"
    className={classNames(styles.arcGraphImg)}
  />
);

interface NewsAnalysisContainerProps {
  mediaQuery?: any; // MediaQuery
  dashboard: Dashboard;
  auth: AuthRecord;
  actions: Actions;
  apiState?: string;
}

@connect(({ dashboard, auth }) => ({ dashboard, auth }), mapDispatchToProps)
@ApiDecorator('newsSentiments', 'prices')
export class NewsAnalysis extends React.PureComponent<
NewsAnalysisContainerProps,
{}
> {
  componentDidMount() {
    const { dashboard, actions } = this.props as any;
    const currTicker = selectPortfolioNewsTicker(dashboard);
    if (currTicker == -1) {
      const stocks = selectPortfolioItems(dashboard).map(portfolioItem =>
        portfolioItem!.get('stock').get('ticker'),
      );
      const firstTicker = stocks.get(0);
      actions.changePortfolioNewsTicker(firstTicker);
    }
    //sorry:(
    (report as any)('visited-smart-portfolio-news-analysis-page');
  }
  render() {
    const { dashboard, auth, mediaQuery } = this.props;
    const ticker = selectPortfolioNewsTicker(dashboard).toString();
    const noTicker = ticker == '-1';
    const foo = dashboard
      .get('data')
      .get('stocks')
      .find(
        stock =>
          (stock.get('ticker') || '').toLowerCase() === ticker.toLowerCase(),
      );
    const type = !!foo ? foo.get('type') : false;
    const isETF = noTicker ? false : type === 'etf';
    const isFund = noTicker ? false : type === 'fund';
    const isSecondaryTicker = noTicker ? false : type === 'secondaryticker';
    const shouldNotShowNewsAnalysis =
      (isCanadianTicker(ticker) && process.env.THEME === 'nasdaq') || isETF || isFund || noTicker || isSecondaryTicker;
    const msg = noTicker
      ? 'No data available.'
      : `No data available for ${ticker}.`;
    const apiFailure = this.props.apiState === 'ERROR';
    // we do this here so we won't have to call the API via apiDep
    if (shouldNotShowNewsAnalysis || apiFailure)
      return (
        <div className="news-items-wrapper">
          <div className="news-items">
            <ul>
              <div className="no-data-wrapper">
                <div className="no-data">
                  {msg}
                  <br />
                  News Analysis does not cover funds or portfolios as a whole.
                </div>
              </div>
            </ul>
          </div>
        </div>
      );
    const {
      companyName,
      bullishPercent,
      stockWeeklyAvgBuzz,
      mediaBuzzMax,
      keywords,
      newsSentimentsTopStocks,
      stockNewsScore,
      avgNewsScore,
      sectorAverageBullishPercent,
      counts: mediaCoverageAnalysisNewsPoints,
      sector,
      articlesInLastWeek,
      sectorId,
    } = dashboard.get('newsSentiments');

    const mediaCoverageAnalysisPrices = dashboard.get('prices').get(ticker)
      ? dashboard.get('prices').get(ticker)
      : null;
    const { consensusSentiment, newsSentimentLegend } = calcNewsSentiment({
      sectorAverageBullishPercent,
      bullishPercent,
    });
    const [mediaBuzzSentiment, mediaBuzzSentimentLegend] = calcMediaBuzz({
      thisWeekArticleCount: articlesInLastWeek,
      stockWeeklyAvgBuzz,
      mediaBuzzMax,
    });
    const hasPayed = hasPayedSelector(auth);
    // console.warn('debug has payed true')
    // const hasPayed = true;
    const isDone = selectIsNewsSentimentsLoading(dashboard) === suits.SUCCESS;

    return (
      <NewsAnalysisComponent
        sectorId={sectorId}
        ticker={ticker.toUpperCase()}
        keywords={keywords}
        newsSentimentsTopStocks={newsSentimentsTopStocks}
        mediaCoverageAnalysisPrices={mediaCoverageAnalysisPrices}
        mediaCoverageAnalysisNewsPoints={mediaCoverageAnalysisNewsPoints}
        stockNewsScore={stockNewsScore}
        avgNewsScore={avgNewsScore}
        newsSentiment={consensusSentiment}
        newsSentimentLegend={newsSentimentLegend}
        mediaBuzzSentiment={mediaBuzzSentiment}
        mediaBuzzSentimentLegend={mediaBuzzSentimentLegend}
        sector={sector}
        isLoading={!isDone}
        hasPayed={hasPayed}
        mediaQuery={mediaQuery}
      />
    );
  }
}

interface Props {
  // auth: Auth;
  mediaCoverageAnalysisNewsPoints: APICounts[];
  ticker: string;
  newsSentimentsTopStocks: TopStock[];
  mediaCoverageAnalysisPrices: Price[];
  keywords: Keyword[];
  stockNewsScore: number;
  avgNewsScore: number;
  newsSentiment: any; //: ConsensusSentiment;
  mediaBuzzSentiment: any; //: NumberSentiment;
  hasPayed: boolean;
  isLoading: boolean;
  newsSentimentLegend: NewsSentimentLegend;
  mediaBuzzSentimentLegend: MediaBuzzLegend;
  sector: SectorEnum;
  sectorId: number;
  mediaQuery?: any; // MediaQuery
}

export class NewsAnalysisComponent extends React.PureComponent<Props, {}> {
  render() {
    const { ticker, hasPayed } = this.props;
    return (
      <div className={styles.newsAnalysisWrapper}>
        <div className={classNames(styles.row, styles.firstLayout)}>
          <div className={classNames(styles.column)}>
            {this.renderNewsSentiment()}
            {this.renderMediaBuzz()}
          </div>
          <div className={classNames(styles.column)}>
            {this.renderNewsScore()}
          </div>
        </div>
        <div className={classNames(styles.row, styles.secondLayout)}>
          {this.renderMediaCoverageAnalysis()}
          <div className={classNames(helperStyles.showUpperDesktop)}>
            {this.renderKeywords()}
          </div>
        </div>
        <div
          className={classNames(
            styles.topStocksContainer,
            helperStyles.hideMobile,
          )}
        >
          {this.renderTopStocks()}
        </div>
      </div>
    );
  }
  renderNewsSentiment = () => {
    const {
      ticker,
      newsSentiment,
      newsSentimentLegend,
      isLoading,
      hasPayed,
      mediaQuery,
    } = this.props;
    const { sectorAverage, stock } = newsSentimentLegend;
    return (
      <Box
        mediaQuery={mediaQuery}
        tooltip={`The ratio of Bullish vs Bearish articles for ${ticker} as analyzed by TipRanks.`}
        spinner={{ isLoading, size: 40 }}
        header={
          <span>
            News Sentiment{' '}
            <span className={helperStyles.hideMobile}>in the last 7 days</span>
          </span>
        }
        className={classNames(styles.newsSentimentContainer, styles.box)}
      >
        <div className={classNames(styles.newsSentiment)}>
          <div className={classNames(styles.barContent)}>
            <div
              className={classNames(
                styles.graph,
                visualConsensusStyles.vcContainer,
              )}
            >
              <BullishBearishConsensusBar
                avgMarkerText={
                  <span>
                    Sector<br />Weekly Average
                  </span>
                }
                sentiment={newsSentiment}
                styles={visualConsensusStyles}
                consMarkerTextEle={this.getVisualConsensusMarker(
                  newsSentiment,
                  ticker,
                )}
              />
            </div>
            <div className={classNames(styles.row, styles.comparisonMiniTable)}>
              <VisualConsensusLegend
                title={ticker}
                bullish={stock.bullishPercent}
                bearish={stock.bearishPercent}
              />
              <VisualConsensusLegend
                title="Sector Average"
                bullish={sectorAverage.bullishPercent}
                bearish={sectorAverage.bearishPercent}
              />
            </div>
          </div>
        </div>
      </Box>
    );
  };
  /**
   * Note, there was code here that "restricted this component", you can find it in past commits.
   */
  renderMediaBuzz = () => {
    const {
      isLoading,
      ticker,
      mediaBuzzSentiment,
      mediaBuzzSentimentLegend,
      mediaQuery,
    } = this.props;
    const hasData =
      mediaBuzzSentimentLegend.thisWeekArticleCount !== 0 ||
      mediaBuzzSentimentLegend.weeklyAverageArticleCount !== 0;
    const shouldShowInBackground = !hasData;
    return (
      <Box
        mediaQuery={mediaQuery}
        tooltip="The amount of articles published about this company this week versus its weekly average. This indicates in real-time how focused the media is on this company."
        spinner={{ isLoading, size: 40 }}
        header={
          <span>
            Media Buzz{' '}
            <span className={helperStyles.hideMobile}>in the last 7 days</span>
          </span>
        }
        className={classNames(styles.box, styles.mediaBuzzContainer)}
      >
        <div className={classNames(styles.mediaBuzz)}>
          {hasData || shouldShowInBackground ? (
            <div
              className={classNames(styles.barContent)}
            >
              <div
                className={classNames(
                  styles.graph,
                  visualConsensusStyles.vcContainer,
                )}
              >
                <NumberConsensusBar
                  ticker={ticker}
                  sentiment={mediaBuzzSentiment}
                  styles={visualConsensusStyles}
                />
              </div>
              <div
                className={classNames(styles.row, styles.comparisonMiniTable)}
              >
                <div className={styles.column}>
                  <div className={styles.bold}>This Week:</div>
                  <div>
                    {mediaBuzzSentimentLegend.thisWeekArticleCount} articles
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.bold}>Weekly Average:</div>
                  <div>
                    {Math.floor(
                      mediaBuzzSentimentLegend.weeklyAverageArticleCount,
                    )}{' '}
                    articles
                  </div>
                </div>
              </div>
            </div>
          ) : (
              <span className={styles.noDataMediaBuzz}>
                No articles were published in the past 7 days.
              </span>
            )}
        </div>
      </Box>
    );
  };

  renderNewsScore = () => {
    const {
      isLoading,
      hasPayed,
      ticker,
      avgNewsScore,
      stockNewsScore,
      mediaQuery,
    } = this.props;
    const { consensus, text: sentimentText } = getNewsScoreSentimentText(
      stockNewsScore,
    );

    return (
      <Box
        mediaQuery={mediaQuery}
        tooltip="Combining News Sentiment and Media Buzz in a formula provides an actionable score."
        spinner={{ isLoading, size: 40 }}
        header="News Score"
        className={classNames(styles.box, styles.newsScoreContainer)}
      >
        <NoData
          hasPayed={hasPayed}
          number={stockNewsScore}
          content="Not enough data available to display News Score for this company"
        >
          <div className={classNames(styles.newsScore)}>
            <div
              className={classNames(styles.newsScoreContent, {
                [styles.blurred]: !hasPayed,
              })}
            >
              <h1
                className={classNames({
                  [helperStyles.buyColor]:
                    consensus === Consensus.StrongBuy ||
                    consensus === Consensus.Buy,
                  [helperStyles.holdColor]: consensus === Consensus.Hold,
                  [helperStyles.sellColor]:
                    consensus === Consensus.Sell ||
                    consensus === Consensus.StrongSell,
                })}
              >
                {sentimentText}
              </h1>
              <div className={styles.explainHolder}>
                <span className={styles.explanation}>
                  Based on a formula that combines this week’s News Sentiment
                  and Media Buzz
                </span>
              </div>
              <div
                className={classNames(arcGraphStyles.container, styles.graph)}
              >
                <ArcGraph
                  low={bearImg}
                  high={bullImg}
                  average="Sector Average"
                  averageValue={avgNewsScore}
                  value={stockNewsScore}
                  styles={arcGraphStyles}
                />
              </div>
            </div>
            {!hasPayed ? (
              <Restricted
                loading={isLoading}
                feature="sp-holdings-news-news-score"
              >
                Upgrade to see News Score for {ticker}
              </Restricted>
            ) : (
                ''
              )}
          </div>
        </NoData>
      </Box>
    );
  };
  renderMediaCoverageAnalysis = () => {
    const {
      isLoading: componentIsLoading,
      mediaCoverageAnalysisNewsPoints = [],
      ticker,
      mediaCoverageAnalysisPrices,
      mediaQuery,
    } = this.props;

    const isLoading = !mediaCoverageAnalysisPrices || componentIsLoading;

    return (
      <Box
        mediaQuery={mediaQuery}
        tooltip="The historical news coverage of this company in the last 7 days."
        spinner={{ isLoading, size: 40 }}
        header="Media Coverage Analysis"
        className={classNames(
          styles.macContainer,
          styles.box,
          helperStyles.hideMobile,
        )}
      >
        <NoData>
          <MCAWrapper
            mediaCoverageAnalysisNewsPoints={mediaCoverageAnalysisNewsPoints}
            mediaCoverageAnalysisPrices={mediaCoverageAnalysisPrices}
            ticker={ticker}
          />
        </NoData>
      </Box>
    );
  };
  renderKeywords = () => {
    const { isLoading, ticker, keywords, mediaQuery } = this.props;
    return (
      <Box
        mediaQuery={mediaQuery}
        spinner={{ isLoading, size: 40 }}
        header="Keywords mentioned last week"
        className={classNames(styles.box, styles.keywordsContainer)}
      >
        <div className={styles.mediaCoverageAnalysis}>
          <WordCloud ticker={ticker} height={200} keywords={keywords} />
        </div>
      </Box>
    );
  };
  renderTopStocks = () => {
    const {
      hasPayed,
      ticker,
      isLoading,
      newsSentimentsTopStocks = [],
      sectorId,
    } = this.props;

    return (
      <TopStocks
        hasPayed={hasPayed}
        stocks={newsSentimentsTopStocks}
        renderer={(stock: TopStock) => (
          <TextVisualConsensus
            avgMarkerText={
              <span>
                Sector<br />Average
              </span>
            }
            styles={visualConsensusStyles}
            sentiment={stock.sentiment}
          />
        )}
        header={`${getSectorText(sectorId) ||
          'Top'} Stocks - Trending in the News`}
        subheader="Showing the ratio of Bullish vs Bearish news over the past week"
        feature="newsSentimentsTopStocks"
        replacement={() => <SimilarStocksPromotion />}
      />
    );
  };

  getVisualConsensusMarker = (
    cons: ConsensusSentiment,
    ticker: string,
    fullText = true,
  ) => {
    const { actualSentiment } = cons;
    const visualConsensusColor = classNames({
      [visualConsensusStyles.bearishColor]:
        actualSentiment === SentimentEnum.Bearish,
      [visualConsensusStyles.bullishColor]:
        actualSentiment === SentimentEnum.Bullish,
    });
    return (
      <div
        className={classNames(
          mediaBuzzStyles.mediaBuzzStyles,
          visualConsensusColor,
          visualConsensusStylesOGT.mediaBuzzMarker,
        )}
      >
        {fullText ? (
          <span>
            {ticker} This<br />Week
          </span>
        ) : (
            ticker
          )}
      </div>
    );
  };
}

//TODO split this into another file
class SimilarStocksPromotion extends React.PureComponent<{}> {
  render() {
    const plan = Plan.Premium;

    return (
      <div
        onClick={_ => restrict(plan, 'newsSentimentsTopStocks')}
        className={classNames(styles.row, styles.similarStocksPromotion)}
      >
        <div
          className={styles.column}
          style={{ color: process.env.THEME === 'nasdaq' ? 'white' : 'black' }}
        >
          <h1>See Similar Stocks That are Trending in the News</h1>
          <button className={styles.restrictedBtn}>Upgrade Now</button>
        </div>
        <img
          className={styles.newsSentimentsUpgradeImg}
          src={imgSrc('news-sentiment-upgrade-news.png')}
        />
      </div>
    );
  }
}

//TODO split this into another file
@connect(({ auth }) => ({ auth }))
export class Restricted extends React.PureComponent<{
  auth?: AuthRecord;
  feature: PossibleRestriction;
  loading: boolean;
  className?: string;
}> {
  render() {
    const { auth, className, loading, children, feature } = this.props;
    return (
      <div
        className={classNames(styles.restricted, className)}
        onClick={_ => restrict(Plan.Premium, feature, auth)}
      >
        <img
          className={styles.banner}
          alt="Premium Banner"
          src={imgSrc('premium-badge.png')}
        />
        <img className={styles.lock} src={imgSrc('lock.png')} alt="lock" />
        {loading && (
          <div className={styles.spinner}>
            <VariableSizeSpinner size={40} />
          </div>
        )}
        <p>{children}</p>
        <button className={styles.restrictedBtn}>Upgrade Now</button>
      </div>
    );
  }
}
//TODO split this into another file
const VisualConsensusLegend = ({ title, bullish, bearish }) => (
  <div className={styles.column}>
    <div className={styles.bold}>{title}:</div>
    <div className={styles.legendItem}>
      <div className={classNames(styles.legend, styles.bullish)} />
      <span className={styles.label}>{percentField(bullish, 0)} Bullish</span>
    </div>
    <div className={styles.legendItem}>
      <div className={classNames(styles.legend, styles.bearish)} />
      <span className={styles.label}>{percentField(bearish, 0)} Bearish</span>
    </div>
  </div>
);

function imgSrc(imgName) {
  return (
    '/assets/img/news-analysis/' +
    (process.env.THEME === 'nasdaq' ? 'nasdaq/' : '') +
    imgName
  );
}

function getNewsScoreSentimentText(score: number) {
  if (!_.isFinite(score)) return { text: 'No Data', consensus: Consensus.Hold };
  if (score > 0.8)
    return { text: 'Strong Positive', consensus: Consensus.StrongBuy };
  if (score > 0.6) return { text: 'Positive', consensus: Consensus.Buy };
  if (score > 0.4) return { text: 'Neutral', consensus: Consensus.Hold };
  if (score > 0.2) return { text: 'Negative', consensus: Consensus.Sell };
  return { text: 'Strong Negative', consensus: Consensus.StrongSell };
}

interface NoDataProps {
  content?: React.ReactNode;
  number?: number;
  children?: React.ReactNode;
  hasPayed?: boolean;
}

// haspayed allows us to show restricted view even when theres not enough data
const NoData = ({ content, number, children, hasPayed }: NoDataProps) =>
  (_.isFinite(number) || !hasPayed
    ? children
    : <div className={styles.noData}>{content}</div>
  ) as JSX.Element;

const STOCK_PRICE_LABEL = 'Stock Price';
const positiveLegendColor = '#34900b';
const negativeLegendColor = '#cf1800';
const neutralLegendColor = '#ADB0B1';
const stockPriceLegendColor = '#3796BB';

// order is important
const defaultLegend = [
  { color: stockPriceLegendColor, label: STOCK_PRICE_LABEL },
  { color: neutralLegendColor, label: 'No Sentiment' },
  { color: negativeLegendColor, label: 'Negative' },
  { color: positiveLegendColor, label: 'Positive' },
];
const mediaCoverageAnalysisLegend = [
  {
    style: { borderRadius: 1 },
    color: stockPriceLegendColor,
    label: STOCK_PRICE_LABEL,
  },
  {
    style: { borderRadius: 1 },
    color: neutralLegendColor,
    label: 'Neutral News',
  },
  {
    style: { borderRadius: 1 },
    color: negativeLegendColor,
    label: 'Bearish News',
  },
  {
    style: { borderRadius: 1 },
    color: positiveLegendColor,
    label: 'Bullish News',
  },
];

const MCAWrapper = redrawOnResize(
  ({
    mediaCoverageAnalysisNewsPoints,
    mediaCoverageAnalysisPrices,
    ticker,
  }) => {
    return (
      <div className={styles.mediaCoverageAnalysis}>
        {mediaCoverageAnalysisNewsPoints.length > 0 && (
          <GraphLegend
            className={styles.legend}
            data={mediaCoverageAnalysisLegend}
          />
        )}
        <MediaCoverageAnalysisHighStockChart
          ticker={ticker}
          data={{
            news: mediaCoverageAnalysisNewsPoints,
            prices: mediaCoverageAnalysisPrices,
          }}
          height={190}
          colors={{ positive, negative, neutral }}
          className={styles.mcaMinHeight}
          noDataElement={
            <span className={styles.noData}>
              Not enough data available to display Media Coverage Analysis for
              this company.
            </span>
          }
        />
      </div>
    );
  },
);

const positive = 'rgba(52, 144 ,11, 0.75)';
const negative = 'rgba(207, 24 ,0, 0.75)';
const neutral = 'rgba(173, 176 ,177, 0.75)';
