exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@media (max-width:719px){.src-common-styles-common__hideMobile.src-common-styles-common__hideMobile{display:none}}@media (min-width:720px){.src-common-styles-common__showMobile{display:none}}.src-common-styles-common__showDesktop{display:block}@media (max-width:1023px){.src-common-styles-common__showDesktop{display:none}}@media (max-width:1365px){.src-common-styles-common__showUpperDesktop.src-common-styles-common__showUpperDesktop{display:none}}@media (min-width:1366px){.src-common-styles-common__showUpperDesktop.src-common-styles-common__showUpperDesktop{display:block}}@media (min-width:1366px){.src-common-styles-common__hideUpperDesktop{display:none}}@media (min-width:720px){.src-common-styles-common__showMobileBr{content:\" \"}.src-common-styles-common__showMobileBr:after{content:\" \"}}.src-common-styles-common__buyColor{color:#41a8b8}.src-common-styles-common__holdColor{color:#797979}.src-common-styles-common__sellColor{color:#90408f}@media (max-width:1919px){.src-common-styles-common__showUntil1920 span{display:none}}@media (min-width:1920px){.src-common-styles-common__showUntil1920 br{display:none}}", ""]);

// Exports
exports.locals = {
	"hideMobile": "src-common-styles-common__hideMobile",
	"showMobile": "src-common-styles-common__showMobile",
	"showDesktop": "src-common-styles-common__showDesktop",
	"showUpperDesktop": "src-common-styles-common__showUpperDesktop",
	"hideUpperDesktop": "src-common-styles-common__hideUpperDesktop",
	"showMobileBr": "src-common-styles-common__showMobileBr",
	"buyColor": "src-common-styles-common__buyColor",
	"holdColor": "src-common-styles-common__holdColor",
	"sellColor": "src-common-styles-common__sellColor",
	"showUntil1920": "src-common-styles-common__showUntil1920"
};