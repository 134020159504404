import { getReq, postReq, users, portfolio } from '../../common/api';

export const getExperts = () => getReq(`${portfolio('getExperts')}`);

export const subscribeToExpert = uid =>
  postReq(users('subscribeToExpert'), {
    uid
  });

export const unSubscribeToExpert = uid =>
  postReq(users('unSubscribeExpert'), {
    uid
  });

export const toggleExpertFollow = uid =>
  postReq(users('toggleExpertFollow'), { uid });

export const setExpertLastRead = (lastReadId, uid) =>
  postReq(`${users('setExpertLastRead')}`, {
    alertId: lastReadId,
    uid
  });
