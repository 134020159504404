exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-news-analysis-Tooltip-Tooltip__wrapper.src-browser-news-analysis-Tooltip-Tooltip__wrapper .src-browser-news-analysis-Tooltip-Tooltip__i{background:#6f6f6f;color:#fff}.src-browser-news-analysis-Tooltip-Tooltip__wrapper{position:relative}.src-browser-news-analysis-Tooltip-Tooltip__wrapper .src-browser-news-analysis-Tooltip-Tooltip__i{background:#fff;border:0;border-radius:50%;color:#000;cursor:help;font-family:Sylfaen,arial;font-weight:400;font-size:12px;height:12px;line-height:12px;width:12px;margin:0;padding:0;text-align:center}.src-browser-news-analysis-Tooltip-Tooltip__wrapper.src-browser-news-analysis-Tooltip-Tooltip__black .src-browser-news-analysis-Tooltip-Tooltip__i{background:#6f6f6f;color:#fff}.src-browser-news-analysis-Tooltip-Tooltip__content{display:none;background:#fff;border-radius:4px;-webkit-box-shadow:1px 1px 11px 0 rgba(50,50,50,.75);box-shadow:1px 1px 11px 0 rgba(50,50,50,.75);color:#5f5f5f;font-family:arial;line-height:1;margin-left:5px;max-width:18.75em;opacity:0;padding:15px;position:absolute;-webkit-transform:translate(-250px,-25.008px);-ms-transform:translate(-250px,-25.008px);transform:translate(-250px,-25.008px);vertical-align:top;visibility:hidden;z-index:1;width:300px;-webkit-transform:translate(0);-ms-transform:translate(0);transform:translate(0);-webkit-transition:all .25s ease-in-out;-o-transition:all .25s ease-in-out;transition:all .25s ease-in-out;z-index:1000}.src-browser-news-analysis-Tooltip-Tooltip__show.src-browser-news-analysis-Tooltip-Tooltip__show{opacity:1;visibility:visible;display:inline-block}", ""]);

// Exports
exports.locals = {
	"wrapper": "src-browser-news-analysis-Tooltip-Tooltip__wrapper",
	"i": "src-browser-news-analysis-Tooltip-Tooltip__i",
	"black": "src-browser-news-analysis-Tooltip-Tooltip__black",
	"content": "src-browser-news-analysis-Tooltip-Tooltip__content",
	"show": "src-browser-news-analysis-Tooltip-Tooltip__show"
};