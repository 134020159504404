import { PortfolioItemRecord } from '../../common/types';
import {
  calcLandmarkPercentange,
  LandmarkPriceType,
} from '../PortfolioItemSelector/utils';

export const getSortLandmarkPercentageFn = (type: LandmarkPriceType) => (
  foo: PortfolioItemRecord,
  bar: PortfolioItemRecord,
) => {
  const fooValue = calcLandmarkPercentange(foo, type);
  const barValue = calcLandmarkPercentange(bar, type);
  if (fooValue === null && barValue === null) return 0;
  if (fooValue === null) return 1;
  if (barValue === null) return -1;
  return barValue - fooValue;
};
