import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import drawGraph from './graph';
import './graph.styl';
import { HoldingOperation } from 'sp/common/api-types';
import { PerfStockNotRecord } from 'sp/common/components/performance/records';
import { PriceRequestFn } from 'sp/common/components/PriceFetchz/PriceFetchz';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

const arrowDown = require('../images/Arrow-down.png');
const arrowUp = require('../images/Arrow-up.png');

const defaultMsg = {
  totalRatings: 'Transactions'
};

const stylusVariables = require(`../../style-helpers/themes/${
  process.env.THEME
  }.json`);
const colorPositive = stylusVariables['$colorPositive'];
const colorNegative = stylusVariables['$colorNegative'];


type StockChartProps = {
  stock: PerfStockNotRecord;
  holdingOperations: HoldingOperation;
  mediaQuery: MediaQuery;
  timelineArticleClicked?: any;
  msg?: any;
  isLoading?: boolean;
  stockPrice?: number | null;
  stockChangeAmount?: number | null;
  stockChangePercent?: number | null;
  priceRequest: PriceRequestFn;
};

// TODO this calls the historical api endpoint a couple of times. replace with highcharts already!
export default class StockChart extends React.Component<StockChartProps> {
  static defaultProps = {
    isLoading: false,
    msg: defaultMsg,
    timelineArticleClicked: () => null
  };

  state = {
    arePricesLoading: true, hasRendered: false,
  };

  componentDidUpdate() {
    if (!this.state.arePricesLoading && !this.state.hasRendered) { this.renderGraph(false) }
  }

  componentDidMount() {
    const { stock, stockChangeAmount, stockChangePercent, stockPrice, priceRequest } = this.props;

    if (!this.hasLoadedPrices()) { priceRequest([stock.ticker]); }
    this.renderGraph(true)
  }

  componentWillUnmount() {
    this.timelineWillUnmount();
  }
  hasLoadedPrices = () =>
    this.props.stockPrice || this.props.stockChangeAmount || this.props.stockChangePercent
  timelineWillUnmount = () => null;

  render() {
    const { isLoading, msg, stock, mediaQuery, holdingOperations, stockChangeAmount: changeAmount, stockChangePercent: changePercent, stockPrice: price } = this.props;

    const { arePricesLoading } = this.state;
    const isTablet = mediaQuery.get('tablet');
    const isDataLoading = arePricesLoading || isLoading;
    const isNegative = changeAmount && changeAmount < 0;
    const ticker = stock.ticker;
    return (
      <div className="analyst-on-stock-timeline">
        <div id="main_box">
          {isDataLoading ? (
            <div className="loading">
              <img src="/assets/img/loader.gif" />
            </div>
          ) : (
              <div>
                <div id="title_box">
                  <div id="extra_box" />
                  <span id="graph_title_box">
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: '#666666 !important'
                      }}
                    >
                      {ticker.toUpperCase()}
                      &nbsp;&nbsp;
                    {price
                        ? `${
                        ticker
                          .toLowerCase()
                          .startsWith('tse:')
                          ? 'C'
                          : ''
                        }$${parseFloat(price.toString()).toFixed(2)}`
                        : '-'}
                    </span>
                    &nbsp;
                  <span
                      style={{
                        color: isNegative ? colorNegative : colorPositive,
                        fontSize: '11px !important'
                      }}
                    >
                      {isNegative ? (
                        <img src={arrowDown} />
                      ) : (
                          <img src={arrowUp} />
                        )}
                      &nbsp;
                    {changeAmount ? (
                        <FormattedNumber
                          maximumFractionDigits={2}
                          minimumFractionDigits={2}
                          value={changeAmount}
                        />
                      ) : (
                          ''
                        )}
                      ({Number(
                        changePercent
                      ).toFixed(2)}%)
                  </span>
                    &nbsp;&nbsp;
                  {isTablet && [
                      <span
                        style={{
                          color: '#8d8d8d !important',
                          fontSize: '12px'
                        }}
                      >
                        {msg.totalRatings}:
                    </span>,
                      <span>&nbsp;</span>,
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: '#666666 !important'
                        }}
                      >
                        {holdingOperations.operations.length}
                      </span>
                    ]}
                  </span>
                </div>
                <div id="graph_box">
                  <div id="extra_box_2" />
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }

  renderGraph = (forLoadingPrices: boolean = true) => {
    const { stock, timelineArticleClicked, holdingOperations } = this.props;

    if (stock.transactions.length > 0) {
      const { timelineWillUnmount } = drawGraph({ ...stock, transactions: holdingOperations.operations }, {
        timelineArticleClicked,
        timelinePricesSuccess: () => {
          this.setState({ arePricesLoading: false, })
          if (!forLoadingPrices) this.setState({ hasRendered: true })
        }
      }) as any;
      this.timelineWillUnmount = timelineWillUnmount;
    }
  }
}
export const __hotReload = true;
