import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../PortfolioItemSelector';

export class StockPriceChangeList extends Component {
  static propTypes = {
    isLosers: PropTypes.bool,
    items: PropTypes.object,
    max: PropTypes.number,
    noHoldingsMsg: PropTypes.string
  };

  render() {
    const { items, isLosers = false, max = 4, noHoldingsMsg } = this.props;

    if (items.size === 0) {
      return <div className="no-holdings">{noHoldingsMsg}</div>;
    }

    return (
      <ul
        className={classNames('portfolio-daily-gainers', {
          loseres: isLosers
        })}
      >
        {items.take(max).map(item => (
          <li key={item.get('ticker')}>
            <div className="company">
              <div className="ticker">
                <PortfolioItemSelector item={item} type="ticker" />
              </div>
              <div className="name">
                <PortfolioItemSelector item={item} type="name" />
              </div>
            </div>
            <div className="change">
              <div
                className={classNames('percent', {
                  negative: isLosers,
                  positive: !isLosers
                })}
              >
                <PortfolioItemSelector item={item} type="priceChangePercent" />
              </div>
              <div className="amount">
                <PortfolioItemSelector item={item} type="priceChange" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
