/**
 * This files exports get rexported under auth reducer to enable both of them to act on the same store
 */
import { getReq, postReq } from 'sp/common/api';
import { API_Res_SetPortfolioPublic } from 'sp/browser/pages/PublicPortfolio/Onboarding/api';
import { PortfolioPrivacy, AuthRecord } from 'sp/common/types';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { selectHasMadeAnyPortfolioPublicEver, selectIsPortfolioMadePublicEver } from 'sp/common/auth/selectors';

export type API_Req_SetPortfolioPublic = {
  fullName: string,
  portfolioId: number,
  bio: string,
}

export type API_Req_SetPortfolioPrivacy = { portfolioId: number, privacyLevel: PortfolioPrivacy }

export const SET_PORTFOLIO_PUBLIC = 'SET_PORTFOLIO_PUBLIC';
export const SET_PORTFOLIO_PUBLIC_START = 'SET_PORTFOLIO_PUBLIC_START';
export const SET_PORTFOLIO_PUBLIC_SUCCESS = 'SET_PORTFOLIO_PUBLIC_SUCCESS';
export const SET_PORTFOLIO_PUBLIC_ERROR = 'SET_PORTFOLIO_PUBLIC_ERROR';
export function setPortfolioPublic(data: API_Req_SetPortfolioPublic) {
  return {
    type: SET_PORTFOLIO_PUBLIC,
    data,
    payload: {
      data,
      promise: postReq<API_Res_SetPortfolioPublic>(`api/publicportfolio/create`, data)
        .then(({ slug }) => ({ slug, id: data.portfolioId })),
    }
  }
}

export const SET_PORTFOLIO_PRIVACY = 'SET_PORTFOLIO_PRIVACY';
export const SET_PORTFOLIO_PRIVACY_START = 'SET_PORTFOLIO_PRIVACY_START';
export const SET_PORTFOLIO_PRIVACY_SUCCESS = 'SET_PORTFOLIO_PRIVACY_SUCCESS';
export const SET_PORTFOLIO_PRIVACY_ERROR = 'SET_PORTFOLIO_PRIVACY_ERROR';
/**
 * the action itself, use attempt set portfolio privacy 
 */
export function setPortfolioPrivacy(data: API_Req_SetPortfolioPrivacy) {
  return {
    type: SET_PORTFOLIO_PRIVACY,
    data,
    payload: {
      data,
      promise: postReq<void>(`api/publicportfolio/setprivacy`, data)
        .then(() => data),
    }
  }
}