const checkStatus = response => {
  const error = new Error(response.statusText);

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  error.response = response;
  throw error;
};

export default class ServerPersist {
  constructor({ getUrl, setUrl, fetchOptions }) {
    this.getUrl = getUrl;
    this.setUrl = setUrl;
    this.options = fetchOptions;
  }

  load = state => {
    return fetch(this.getUrl, this.options)
      .then(checkStatus)
      .then(response => response.json())
      .catch(error => Promise.reject(error.message));
  };

  getSaveOptions(state) {
    return {
      method: 'POST',
      ...this.options,
      body: JSON.stringify(JSON.stringify(state)),
      headers: {
        ...this.options.headers,
        'Content-Type': 'application/json'
      }
    };
  }

  save = state => {
    return fetch(this.setUrl, this.getSaveOptions(state))
      .then(checkStatus)
      .then(() => state);
  };
}
