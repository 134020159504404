import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { FormattedDate, FormattedNumber } from 'react-intl';

import defaultMsg from './tr-return-vs-benchmarks-table.en';

export default class TrReturnVsBenchmarksTable extends Component {
  static propTypes = {
    gains: PropTypes.object,
    msg: PropTypes.object,
    shouldTransposeTable: PropTypes.bool
  };

  static defaultProps = {
    msg: defaultMsg,
    shouldTransposeTable: false
  };

  constructor(...args) {
    super(...args);
    this.state = { isExtended: false };
  }

  extendTable = () => this.setState({ isExtended: true });

  renderMonthValue = val => (
    <FormattedDate
      month="short"
      value={new Date(`${val.get('month')}/1/${val.get('year')}`)}
      year="numeric"
    />
  );

  renderHeadCell = col => {
    const { msg } = this.props;

    const isMonth = !msg[col];

    return (
      <th className={isMonth ? 'month' : col} key={col}>
        {msg[col] || this.renderMonthValue(col)}
      </th>
    );
  };

  isNull = val => !val || val === 0;

  renderNullVal = () => <span>-</span>;

  renderBodyCell = key => (...args) => {
    const { shouldTransposeTable } = this.props;

    const [row, col] = shouldTransposeTable ? args.reverse() : args;

    if (row === 'cat') {
      return this.renderHeadCell(col);
    }

    const signedClass = type => ({
      negative: row.get(type) < 0,
      positive: row.get(type) > 0
    });

    switch (col) {
      case 'month': {
        return (
          <th className="month" key={key}>
            {this.renderMonthValue(row)}
          </th>
        );
      }

      case 'activePortfolio': {
        const val = row.get('portfolio');
        return (
          <td
            className={classNames('activePortfolio', signedClass('portfolio'))}
            key={key}
          >
            {this.isNull(val) ? (
              this.renderNullVal()
            ) : (
              <FormattedNumber
                maximumFractionDigits={1}
                minimumFractionDigits={1}
                style="percent"
                value={val}
              />
            )}
          </td>
        );
      }

      case 'spy': {
        const val = row.get('spy');
        return (
          <td className={classNames('spy', signedClass('spy'))} key={key}>
            {this.isNull(val) ? (
              this.renderNullVal()
            ) : (
              <FormattedNumber
                maximumFractionDigits={1}
                minimumFractionDigits={1}
                style="percent"
                value={val}
              />
            )}
          </td>
        );
      }

      case 'majorHoldingsCompany': {
        const val = row.get('company');
        return (
          <td
            className={classNames(
              'majorHoldingsCompany',
              signedClass('company')
            )}
            key={key}
          >
            {this.isNull(val) ? (
              this.renderNullVal()
            ) : (
              <FormattedNumber
                maximumFractionDigits={1}
                minimumFractionDigits={1}
                style="percent"
                value={val}
              />
            )}
          </td>
        );
      }
    }

    return null;
  };

  render() {
    const { msg, gains, shouldTransposeTable } = this.props;

    const { isExtended } = this.state;
    const shouldCutTable = !isExtended && !shouldTransposeTable;

    const cols = shouldTransposeTable
      ? gains.reverse().unshift('cat')
      : ['month', 'activePortfolio', 'spy'];

    const rows = shouldTransposeTable ? ['activePortfolio', 'spy'] : gains;

    const limitedRows = shouldCutTable ? rows.take(3) : rows;

    return (
      <table className={classNames({ transopsed: shouldTransposeTable })}>
        <thead>
          <tr>{cols.map(this.renderHeadCell)}</tr>
        </thead>
        <tbody>
          {limitedRows.map(row => (
            <tr key={row}>
              {cols.map(col => this.renderBodyCell(col)(row, col))}
            </tr>
          ))}
        </tbody>
        {shouldCutTable && (
          <tfoot>
            <tr>
              <td colSpan={cols.length}>
                <button onClick={this.extendTable}>{msg.showMore}</button>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}
