import { msg as loginForm } from '../../../browser/tr-login-form';
import { msg as welcome } from '../../../browser/tr-dashboard-welcome/';
import { msg as registerForm } from '../../../browser/tr-register-form';
import dashboardOverviewMsg from '../../../browser/tr-dashboard-overview/en';
import { msg as returnVsBenchmarks } from '../../../browser/tr-return-vs-benchmarks-table';
import { msg as warningDialogMsg } from '../../../browser/tr-dashboard-warning-dialog';
import { msg as alertsMsg } from '../../../browser/tr-dashboard-alerts';
import savePortfolios from '../../../browser/tr-dashboard-save-portfolios/en';
import welcomeToast from '../../../browser/tr-dashboard-welcome-toast/en';
import { disablePortfolioImport } from '../../config';

// TODO remove all usages of title and subtitle in en.js
// part of the effort to break up en.js.
import { title, subtitle } from '../../../browser/TargetedWebPage/config.ts';

// TODO duplicated in AllocationGraph, because this file cannot be imported
// because it has a long dependcy tree which ends up making storybook choke on tipranks-analytics transpilation.
const sectors = {
  general: 'General',
  technology: 'Technology',
  services: 'Services',
  consumergoods: 'Consumer Goods',
  healthcare: 'Healthcare',
  financial: 'Financial',
  industrialgoods: 'Industrial Goods',
  utilities: 'Utilities',
  basicmaterials: 'Basic Materials',
  other: 'Other'
};

export const msg = {
  welcome: welcome.en,
  registerForm: registerForm.en,
  loginForm: loginForm.en,
  dashboardOverviewMsg: dashboardOverviewMsg.en,
  returnVsBenchmarks: returnVsBenchmarks.en,
  warningDialog: warningDialogMsg.en,
  alerts: alertsMsg.en,
  savePortfolio: savePortfolios.en,
  welcomeToast: welcomeToast.en,
  app: {
    footer: {
      footerPlaceholder: 'Footer Placeholder',
      powered: 'Powered by'
    },
    links: {
      dashboard: 'Dashboard'
    }
  },
  dialogs: {
    plaidSyncLoader: {
      title: 'Plaid'
    },
    addStock: {
      title: 'Add Stocks'
    },
    missingSharesOnNavOut: {
      title: 'Missing Shares',
      warning:
        'In order for your portfolio to be accurate please enter your number of shares for each holdings',
      positive: 'Add Amount of Shares'
    },
    missingSharesOnNavIn: {
      title: 'Missing Shares',
      warning:
        'In order for you to receive a complete Portfolio Analysis, please make sure that you update the number of shares for each of your holdings',
      positive: 'Complete My Portfolio'
    },
    confirmRemoval: {
      title: 'Remove Portfolio Item',
      warning:
        'You are deleting the symbol {ticker} from the portfolio {portfolio}. Smart Portfolio will record this as a Sell for the entire position in {ticker}',
      positive: 'Remove',
      negative: 'Cancel'
    },
    goToHoldings: {
      title: 'Add Holdings'
    },
    register: {
      title: 'Sign up'
    },
    login: {
      title: 'Log in'
    },
    addPortfolio: {
      title: 'Add Portfolio'
    },
    portfolioItem: {
      title: 'Stock'
    },
    portfolioItemAlerts: {
      title: 'Stock Alerts'
    },
    alertsSettings: {
      title: 'Email Alerts'
    },
    holdingsMetrics: {
      title: 'Holings Metrics'
    },
    portfolios: {
      title: 'Manage Portfolios'
    },
    deletePortfolio: {
      title: 'Delete Portfolio'
    },
    analyzingPortfolio: {
      title: 'Analyzing Portfolio'
    },
    promotion: {
      title:
        process.env.THEME === 'nasdaq'
          ? 'Smart Portfolio Pro'
          : 'TipRanks {nextPlan}'
    },
    syncDialog: {
      title: 'Sync Portfolio'
    },
    mgmtFeesComparison: {
      title: 'Management Fees'
    },
    unsureAboutUpgrading: {
      title: 'Unsure About Upgrading?'
    },
    myDetails: {
      title: 'Please enter you details.'
    },
  },
  userWarnings: {
    warnings: {
      missingShares:
      `Enter amount of shares for each one of your holdings to have them calculated into your portfolio analysis ${!disablePortfolioImport ? '{or} {importLink}' : ''}`
    },
    warning: 'Warning',
    values: {
      importLink: 'Import your existing portfolio',
      or: 'or'
    }
  },
  addPortfolio: {
    title: 'Add new portfolio',
    placeHolder: 'Enter Portfolio Name',
    label: 'Add Portfolio',
    done: 'Done',
    import: 'Import Portfolio',
    edit: 'Edit Portfolios'
  },
  toasts: {
    alertsSettingsUpdated: 'Preference Updated'
  },
  promotion: {
    or: 'or',
    planName: 'TipRanks {plan}',
    planDesc: 'The perfect membership for you',
    themePlan: {
      nasdaq: 'Smart Portfolio Pro'
    },
    pricesForTheme: {
      nasdaq: '24.95'
    },
    addOnly: 'add only',
    price: {
      default: {
        premium: '29.95',
        ultimate: '49.95'
      },
      premium: {
        ultimate: '20.00'
      }
    },
    period: 'Month',
    charged: process.env.THEME === 'nasdaq' ? '' : 'Charged annually',
    subscribe: 'Subscribe Now',
    seeAll: 'See all {plan} features and compare to other plans {plansLink}',
    seeAllByTheme: {
      nasdaq: 'See all Pro features and compare plans {plansLink}'
    },
    plansLink: 'Plans & Pricing'
  },
  portfolioSelect: {
    edit: '> Edit Portfolios',
    combinedUserPortfolio: 'All Portfolios'
  },
  deletePortfolio: {
    title: 'Delete Portfolio',
    question: 'Are you sure you want to delete {portfolio} Portfolio?',
    ok: 'Confirm',
    cancel: 'Cancel'
  },
  analyzingPortfolio: {
    message: 'Please wait while we analyze your portfolio'
  },
  portfolios: {
    title: 'Edit Portfolios',
    placeHolder: 'Add New Portfolio, Enter Portfolio Name',
    sync: 'Already have a portfolio on your broker platform? {syncLink}',
    syncLink: 'Synchronize Your Existing Portfolio',
    ok: 'Done'
  },
  calendar: {
    weekDaysShort: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
    today: 'Today',
    prev: 'Previous Month',
    next: 'Next Month',
    index: 'Index',
    earnings: 'Earnings Release',
    dividend: 'Ex-Dividend Date',
    total: 'Total Events'
  },
  portfolioCalendar: {
    title: 'Portfolio Calendar'
  },
  addStock: {
    title: 'Add stocks to your portfolio',
    noTicker: "Stock doesn't exist",
    inputPlaceHolder: 'Enter Symbol or Company Name',
    emptyPendingLists: 'Enter symbols above to add holdings to your portfolio',
    addBtn: 'Done',
    inputLabel: 'Add Symbol',
    reachedLimit:
      'Please proceed with building your portfolio. You can add more symbols later.',
    pendingStocksTitle: 'Your Chosen Stocks:',
    syncOr: 'or',
    syncTitle: 'Synchronize your existing portfolio:',
    syncText: 'Simply {import} from your supported broker',
    importLink: 'import your existing portfolio',
    terms: "I agree to TipRanks'",
    termsLink: 'Terms of use',
    privacyPolicyLink: 'Privacy Policy',
  },
  holdingsMetrics: {
    title: 'Customize Your Holdings Chart',
    description: 'Choose which fields will be shown:',
    ok: 'Save and Close'
  },
  enums: {
    noData: '-',
    relativeDate: {
      today: 'Today',
      yestrday: 'Yesterday'
    },
    sectors,
    riskLevel: {
      low: 'Low Risk',
      mid: 'Mid Risk',
      high: 'High Risk'
    },
    analystConsensus: {
      strongsell: 'Strong Sell',
      sell: 'Moderate Sell',
      neutral: 'Hold',
      buy: 'Moderate Buy',
      strongbuy: 'Strong Buy'
    },
    insiderSentiment: {
      1: 'Positive',
      2: 'Neutral',
      3: 'Negative'
    },
    bloggerSentiment: {
      1: 'Bullish',
      2: 'Neutral',
      3: 'Bearish'
    },
    newsSentiment: {
      1: 'Bearish',
      2: 'Bearish',
      3: 'Neutral',
      4: 'Bullish',
      5: 'Bullish'
    },
    hedgefundSentiment: {
      1: 'Positive',
      2: 'Neutral',
      3: 'Negative'
    },
    priceTarget: {
      buy: 'Upside',
      sell: 'Downside'
    },
    action: {
      buy: 'bought',
      sell: 'sold'
    },
    alerts: {
      default: {
        reiterated:
          '{personType} {personName} from {personFirm} reiterated a {rating} rating on {stockName}.',
        resumedcoverage:
          '{personName} resumed coverage of {stockName} with a {rating} rating.',
        upgraded:
          '{personType} {personName} from {personFirm} upgraded rating on {stockName} to {rating}.',
        maintained:
          "{personFirm}'s {personName} maintained a {rating} rating on {stockName}",
        rated:
          '{personName} gave {stockName} a {rating} rating on {personFirm}',
        initiatedcoverage:
          '{personName} gave {stockName} a {rating} rating on {personFirm}',
        downgraded:
          '{personType} {personName} from {personFirm} downgraded rating on {stockName} to {rating}.'
      },
      analyst: {
        reiterated:
          'Analyst {personName} from {personFirm} reiterated a {rating} rating on {stockName}.',
        maintained:
          'Analyst {personName} from {personFirm} maintained a {rating} rating on {stockName}.',
        upgraded:
          ' Analyst {personName} from {personFirm} upgraded their rating on {stockName} to {rating}.',
        downgraded:
          'Analyst {personName} from {personFirm} downgraded their rating on {stockName} to {rating}.',
        initiatedcoverage:
          'Analyst {personName} from {personFirm} initiated coverage on {stockName} with a {rating} rating.',
        resumedcoverage:
          'Analyst {personName} from {personFirm} resumed coverage on {stockName} with a {rating} rating.',
        default:
          'Analyst {personName} from {personFirm} has a {rating} rating on {stockName}.'
      },
      blogger: {
        buy:
          '{personFirm} contributor {personName} recommends buying {stockName}',
        hold:
          '{personFirm} contributor {personName} recommends holding {stockName}',
        sell:
          '{personFirm} contributor {personName} recommends selling {stockName}'
      },
      insider: {
        buy: '{personName} {action} {stockName} stocks',
        sell: '{personName} {action} {stockName} stocks'
      }
    },
    plans: {
      free: 'Basic',
      premium: 'Premium',
      ultimate: 'Ultimate',
      professional: 'Professional'
    },
    stockTypes: {
      stock: 'Stocks',
      fund: 'Mutual Funds',
      etf: 'ETFs',
      cash: 'Cash Value'
    }
  },
  alertsSettings: {
    title: 'Email Settings',
    description: `An important part of owning a Portfolio is having your finger on the market pulse. The Smart Portfolio enables you to decide which scenarios demand your immediate attention. Simply choose the email alerts that you would like to receive for your holdings.`,
    portfolioSelectText: 'Please select a Portfolio: {portfolioSelector}',
    warnings: {
      isAlertsDisabled:
        'All your email alerts on your {portfolioName} portfolio are disabled'
    },
    userWarnings: {
      warning: 'Note'
    },
    options: {
      high: 'Reaches a new 52-week high',
      low: 'Reaches a new 52-week low',
      increase: 'Increases more than {percentEditable}',
      drop: 'Decreases more than {percentEditable}',
      announcement: 'Company issues a press release',
      report: 'Company releases an earnings report',
      dividend: 'Company announces a new dividend',
      analyst: 'When an analyst releases a new rating on the stock',
      blogger: 'When a blogger publishes a new recommendation on the stock',
      insider: 'When an insider makes a transaction on the stock',
      hedgefund:
        'When hedge funds increase, decrease, initiate, or stop activity on the stock',
      ranking:
        'Apply above settings to experts, insiders & hedge funds with at least {rankingSelector} star ranking',
      analystConsensus: 'Changes in Analyst Consensus on the stock',
      bestAnalystConsensus:
        'Changes in Best Performing Analyst Consensus on the stock',
      bloggerSentiment: 'Changes in Financial Blogger Sentiment on the stock',
      insiderSentiment: 'Changes in Corporate Insider Sentiment on the stock',
      hedgefundSentiment: 'Changes in Hedge Fund Sentiment on the stock'
    },
    totalAlertsPredictions:
      'Expect to get about {total} email alerts per month',
    disable:
      'Email alerts are {isAlertsDisabled, select, false {enabled} true {disabled}}.',
    expertsAlertsTitle: 'General Email Settings',
    expertPredication:
      'You are following {expertsTotal} Experts, Expect to get about {expertsAlertsPrediction} email alerts per month from expert you follow',
    disableExperts:
      'Email alerts from experts you follow are {isAlertsDisabled, select, false {enabled} true {disabled}}.',
    doneBtnLabl: 'Save & Exit'
  },
  experts: {
    noFilters: 'Please check the options above to view results',
    noFollowing:
      'You are currently not following any experts.\nVisit the Top Expert Center and start following Best Performing Experts.',
    expertCenter: 'Visit Expert Center >'
  },
  portfolioItem: {
    alertsTitle: 'Alerts: {alertsIndicator}',
    statsTitle: 'Key Stats',
    emailAlerts: 'Email Alerts',
    noAlerts: 'Currently no alerts',
    deleteBtn: 'Delete Stock'
  },
  dashboard: {
    title: 'Smart Portfolio',
    all: 'All Portfolios',
    emailAlerts: 'Email Alerts',
    filters: {
      expertType: {
        legend: 'Showing:',
        options: {
          analyst: {
            label: 'Analysts',
            labelLong: 'Wall Street Analysts'
          },
          blogger: {
            label: 'Bloggers',
            labelLong: 'Financial Bloggers'
          },
          insider: {
            label: 'Insiders',
            labelLong: 'Corporate Insiders'
          },
          hedgefund: {
            label: 'Hedge Funds',
            labelLong: 'Hedge Fund Managers'
          }
        }
      }
    },
    nav: {
      portfolio: 'Portfolio',
      routes: {
        overview: 'Overview',
        holdings: 'My Holdings',
        welcome: 'Welcome',
        'holdings.holdings': 'Holdings',
        'holdings.news': 'Portfolio News',
        'holdings.opinions': 'Experts Opinions',
        'holdings.calendar': 'Portfolio Calendar',
        analysis: 'My Portfolio Analysis',
        dailyAnalystRatings: 'Daily Stock Ratings',
        topInsiderStocks: 'Top Insider Stocks',
        trendingStocks: 'Trending Stocks',
        topAnalystStocks: 'Top Analyst Stocks',
        insights: 'Crowd Insights',
        performance: 'My Performance',
        public: 'My Public Portfolio',
        'performance.comingsoon': 'My Performance - Coming Soon',
        experts: 'My Experts',
        contact: 'Contact Us',
        faq: 'FAQ',
        terms: 'Terms of Use',
        screener: 'Screener',
        account: 'My Account',
        contactus: 'Contact Us',
        'screener.stocks': 'Stocks',
        'screener.etfs': 'ETFs',
        'sign-up': 'Thank You!',
        gopro: 'Go Pro',
        community: 'Community',
        'onboarding': 'My Public Profile',
        // 'onboarding.1': 'My Public Profile',
        // 'onboarding.2': 'My Public Profile',
        // 'onboarding.3': 'My Public Profile',
      },
      routeHeaders: {
        experts: {
          before: 'My Experts',
          after: "Experts I'm Following"
        },
      },
      routeViaActiveSubRoute: {
        'investors._publicPortfolioId': 'Investor Profile',
        'investors._publicPortfolioId.register': 'Smart Portfolio',
      },
      contact: 'Contact Us',
      tour: 'Take a Tour',
      faq: 'FAQ',
      terms: 'Terms of Use',
      nasdaq: 'Nasdaq Home',
      commodities: 'Commodities'
    },
    addStocks: 'Add Stocks',
    addPortfolio: 'Add Portfolio',
    overviewSection: {
      stats: {
        amount: 'Total Holdings',
        dailyGain: 'Daily Return',
        monthlyGain: 'Monthly Gain',
        totalGain: 'Total Gain',
        cashValue: 'Cash Value'
      }
    },
    holdingsNodataImport: {
      title: 'Build your Portfolio Now',
      text: 'Simply {syncLink} from your supported Broker.',
      syncLink: 'import your existing portfolio',
      support: '',
      or: 'or',
      addStocks: 'Easily {syncLink} Manually',
      addStocksLink: 'Add Stocks'
    },
    holdingsNodata: {
      noData: 'Your Holdings chart is empty. {addButton} to start.',
      addStocks: 'Add Stocks'
    },
    holdingsSection: {
      title: 'My Holdings',
      menu: {
        basicTbl: 'Basic view',
        advancedTbl: 'Detailed view',
        settings: 'Manage Email Alerts'
      },
      showing: 'Showing',
      'menu.all': 'All Analyst Consensus & Price Target',
      'menu.best':
        '<strong>Best Performing</strong> Analyst Consensus & Price Target',
      'menu.best.tooltip':
        'See the consensus and average price targets of the most accurate analysts that have consistently outperformed the market.',
      coltooltips: {
        index: '',
        name: 'The name of this holding',
        price: 'Current stock price (real-time)',
        target:
          'The estimated price target of analysts on the holding, based on recommendations given in the last 3 months',
        bestTarget:
          'The estimated price target of 5 star analysts who have consistently beat the market on the holding, based on recommendations given in the last 3 months',
        analystConsensus:
          'An aggregated measure of Wall Street analyst ratings on the stock in the last 3 months',
        bestAnalystConsensus:
          'An aggregated measure of the opinion of analysts on the stock in the last 3 months. Measured based on expert rank and several other parameters',
        insiderSentiment:
          'The insider sentiment is a weighted average of company insider trades reported to the SEC, and their ranking based on TipRanks',
        highLow:
          "The holding's highest and lowest prices per share in the last 52 weeks",
        added: 'The date in which the holding was added to this portfolio',
        shares:
          'The quantity of shares of the holding you own. Edit this value to update the number of shares in your portfolio. Performance is measured based on this value',
        value: 'The market value of the holding in your portfolio',
        percent: 'The % of your portfolio this holding represents',
        spaceColumn: '',
        alerts:
          'Recent analyst and blogger ratings on this holding. The number by the bell icon represents the number of unread ratings',
        dividend:
          'The annual dividend is calculated as the last quarterly dividend * 4',
        yield:
          'The annual dividend is calculated as the last quarterly dividend * 4',
        dividendDate:
          'The last expected date in which an investor may purchase shares of the stock and receive a dividend',
        earningsReport:
          "The expected date of the company's next earnings report release",
        eps:
          'The last reported earnings per share value of the holding. Calculated by ((holding income) - (holding dividend on preferred stock) / (average outstanding shares))',
        sector:
          "The holding's sector. Categorized by the business model and type of the security",
        cap: "The market value of the company's outstanding shares",
        beta:
          "The holding's β value - a measure of volatility of the holding calculated via regression analysis. A beta of 1 is as theoretically risky as the market itself",
        hedgefundSentiment:
          'Based on hedge fund managers’ trades of this holding',
        purchasePrice: 'The price at which you purchased this holding',
        avgPurchasePrice: 'Average Purchase Price',
        bloggerSentiment:
          'Sentiment is based on financial blogger opinions on the holding in the past 3 months',
        pe: "The holding's Price/Earnings ratio",
        gainSinceAdded:
          'This value represents the return since the holding was added to TipRanks system, and is used to measure your performance as an investor',
        sincePurchaseGain:
          'This value represents the holding’s gain percentage according to your defined purchase price',
        addedPrice:
          'This represents the price of the holding when it was added to your Smart Portfolio or brokerage account'
      },
      addBtn: 'Add Stocks',
      tooltips: {
        missingShares:
          'Enter the amount of shares, for this holding to reflect in your portfolio analysis'
      }
    },
    screener: {
      title: 'Stock Screener',
      menu: {
        basicTbl: 'Basic view',
        advancedTbl: 'Detailed view',
        settings: 'Manage Email Alerts'
      },
      showing: 'Showing',
      'menu.all': 'All Analyst Consensus & Price Target',
      'menu.best':
        '<strong>Best Performing</strong> Analyst Consensus & Price Target',
      'menu.best.tooltip':
        'See the consensus and average price targets of the most accurate analysts that have consistently outperformed the market',
      coltooltips: {
        index: '',
        name: 'Name of security',
        // TODO get from OZ
        category: 'A specific scope of the assets an ETF is invested in',
        totalAssets:
          'Total market value of all financial assets managed by an ETF',
        '1yrReturn': 'The trailing performance of an ETF in the past year',
        '3yrReturn': 'The trailing performance of an ETF in the past 3 years',
        '5yrReturn': 'The trailing performance of an ETF in the past 5 years',
        issuer: 'The firm that creates and/or manages ETFs',
        etfType: 'The type of asset an ETF is invested in',
        investmentStyle:
          'The most specific scope of the assets an ETF is invested in',
        managementFee:
          'The annual fee an ETF charges its shareholders divided by total assets',
        price: 'Market price (delayed 15 minutes)',
        target:
          'Based on all price targets given by analysts in the past three months',
        bestTarget:
          'The estimated price target of 5 star analysts who have consistently beat the market on the holding, based on recommendations given in the last 3 months.',
        analystConsensus:
          'Consensus of all recommendations made by analysts in the past three months',
        bestAnalystConsensus:
          'An aggregated measure of the opinion of analysts on the stock in the last 3 months. Measured based on expert rank and several other parameters.',
        insiderSentiment:
          'The insider sentiment is a weighted average of company insider trades reported to the SEC, and their ranking based on TipRanks.',
        highLow:
          "The security's highest and lowest prices per share in the last 52 weeks.",
        added: 'The date in which the holding was added to this portfolio.',
        shares:
          'The quantity of shares of the holding you own. Edit this value to update the number of shares in your portfolio. Performance is measured based on this value.',
        value: 'The value of the holding in USD ($) in your portfolio.',
        percent: 'The % of your portfolio this holding represents.',
        spaceColumn: '',
        alerts:
          'Recent analyst and blogger ratings on this holding. The number by the bell icon represents the number of unread ratings.',
        dividend:
          'The annual dividend is calculated as the last quarterly dividend * 4.',
        yield:
          'The annual dividend is calculated as the last quarterly dividend * 4.',
        dividendDate:
          'The last expected date in which an investor may purchase shares of the stock and receive a dividend.',
        earningsReport:
          "The expected date of the company's next earnings report release.",
        eps:
          'The last reported earnings per share value of the holding. Calculated by ((holding income) - (holding dividend on preferred stock) / (average outstanding shares)).',
        sector: 'The sector the stock belongs to',
        cap: 'Total market value of all outstanding shares',
        beta:
          "The holding's β value - a measure of volatility of the holding calculated via regression analysis. A beta of 1 is as theoretically risky as the market itself.",
        hedgefundSentiment:
          'Based on hedge fund managers’ trades of this holding.',
        purchasePrice: 'The price at which you purchased this holding.',
        avgPurchasePrice: 'Average Purchase Price.',
        bloggerSentiment:
          'Sentiment is based on financial blogger opinions on the stock in the past 3 months.',
        newsSentiment:
          'Sentiment is based on financial news articles on the stock in the past 3 months.',
        pe: "The holding's Price/Earnings ratio.",
        gainSinceAdded:
          'This value represents the return since the holding was added to TipRanks system, and is used to measure your performance as an investor.',
        sincePurchaseGain:
          'This value represents the holding’s gain percentage according to your defined purchase price.',
        addedPrice:
          'This represents the price of the holding when it was added to your Smart Portfolio or brokerage account.'
      },
      addBtn: 'Add Stocks',
      tooltips: {
        missingShares:
          'Enter the amount of shares, for this holding to reflect in your portfolio analysis.'
      }
    },
    portfolioNews: {
      title: {
        mobileS: 'Portfolio News',
        tablet: '{name} {ticker} News',
        isSentimentMenuOutside: '{sentiment} {name} {ticker}',
        isSentimentMenuOutsideNeutral: 'All Holdings {sentimentNeutral} News'
      },
      sentimentTitle: '{sentiment} News for',
      'sentimentTitle.all': 'All',
      'sentimentTitle.positive': 'The Good',
      'sentimentTitle.negative': 'The Bad',
      'isSentimentMenuOutsideNeutral.all': '',
      'isSentimentMenuOutsideNeutral.positive': 'Good',
      'isSentimentMenuOutsideNeutral.negative': 'Bad',
      tickerLabel: 'Showing',
      allHoldings: 'All Holdings',
      menu: {
        all: 'All Portfolio News',
        analysis: 'News Analysis',
        bullish: 'Bullish News',
        bearish: 'Bearish News'
      },
      next: 'See All News >',
      at: 'at',
      disclaimer:
        '<span class="label">Important note</span>: Classification of bullish vs. bearish sentiment is done via a proprietary natural language analysis algorithm. We cannot guarantee full accuracy or completeness of the article sentiment. This information is intended to be used for research purposes - we do not provide an investment advisory service or any advice or recommendation with respect to buying or selling any securities.',
      noNews: {
        all: { noData: 'No news articles were found for {ticker}.' },
        analysis: { noData: 'Currently no news analysis on {ticker}.' },
        bullish: {
          noData: 'No Bullish news articles were found for {ticker}.'
        },
        bearish: {
          noData: 'No Bearish news articles were found for {ticker}.'
        },
        allHoldings: 'your holdings'
      }
    },
    portfolioEvents: {
      title: 'Upcoming Events',
      cols: {
        ticker: 'Ticker',
        name: 'Company Name',
        nameCombined: 'Company Name',
        event: 'Event Type',
        date: 'Date',
        eventAndDate: 'Event & Date'
      },
      next: 'Portfolio Calendar >',
      eventTypes: {
        dividends: 'Ex-Dividend Date',
        earnings: 'Earnings Release'
      },
      noNews: {
        noData: 'No upcoming events for the selected dates'
      }
    }
  },
  // TODO no usages found, remove and test.
  home: {
    title: 'TipRanks Dashboard',
    infoHtml: `TipRanks Dashboard Project. Please navigate to <a href="/smart-portfolio">/dashboard</a>`
  },
  notFound: {
    title: '404'
  },
  auth: {
    form: {
      button: {
        login: 'Login',
        signup: 'Sign up'
      },
      legend: 'Login / Sign Up',
      placeholder: {
        email: 'your@email.com',
        password: 'password'
      },
      wrongPassword:
        'The password you entered is incorrect or the email does not belong to any account.',
      emailExists: 'Email already exists.'
    },
    logout: {
      button: 'Logout'
    },
    login: {
      title: 'Login'
    },
    validation: {
      email: 'Email address is not valid.',
      password: 'Password must contain at least {minLength} characters.',
      required: `Please fill out {prop, select,
        email {email}
        password {password}
        other {'{prop}'}
      }.`
    }
  }
};

export const cols = {
  index: '',
  name: 'Company Name',
  ticker: 'Ticker',
  totalAssets: 'Total Assets',
  price: 'Price',
  priceChangePercent: 'Daily Change (%)',
  priceChange: 'Daily Change ($)',
  target: 'Analyst Price Target',
  targetPercent: 'Analyst Price Target (%)',
  bestTarget: 'Best Analyst Price Target',
  bestTargetPercent: 'Best Analyst Price Target (%)',
  analystConsensus: 'Analyst Consensus',
  bestAnalystConsensus: 'Best Analyst Consensus',
  insiderSentiment: 'Insider Signal',
  highLow: '52 Week Range',
  added: 'Date Added',
  shares: 'No. of Shares',
  value: 'Holding Value',
  percent: '% of Portfolio',
  spaceColumn: '',
  alerts: 'Alerts',
  emailAlerts: 'Email Alerts',
  dividend: 'Estimated Dividend',
  yield: 'Estimated Dividend Yield',
  dividendDate: 'Next Dividend Date',
  earningsReport: 'Next Earnings Report',
  eps: 'Last Reported EPS',
  sector: 'Sector',
  cap: 'Market Cap',
  category: 'Category',
  beta: 'Beta',
  hedgefundSentiment: 'Hedge Fund Signal',
  purchasePrice: 'Purchase Price',
  avgPurchasePrice: 'Average Purchase Price',
  bloggerSentiment: 'Blogger Consensus',
  newsSentimentScore: 'News Sentiment',
  newsSentiment: 'News Sentiment',
  pe: 'P/E',
  gainSinceAdded: 'Gain Since Added',
  sincePurchaseGain: 'Gain',
  addedPrice: 'Price When Added',
  etfType: 'Asset Type',
  issuer: 'Issuer',
  investmentStyle: 'Investment Style',
  managementFee: 'Management Fees',
  threeMonthsReturn: '3M Return',
  YTDReturn: 'YTD Return',
  '1yrReturn': '1 Year Return',
  '3yrReturn': '3 Years Return',
  '5yrReturn': '5 Years Return'
};

export const screenerCols = {
  ...cols,
  name: 'Name'
};

// TODO remove this fucking mess...
// here I use defineproperty to re-define old values, but give out a warning
// when one doesnt use them from the new place, hence deprecated code paths.
// TODO find all locations that use the deprecated, non-DRY cols code paths.
function warnDeprecated(fn) {
  return function () {
    return fn();
  };
}
function deprecate(obj, name, fn) {
  Object.defineProperty(obj, name, { get: warnDeprecated(fn) });
}
deprecate(msg.dashboard.holdingsSection, 'cols', _ => cols);
deprecate(msg.dashboard.screener, 'cols', _ => screenerCols);
deprecate(msg.promotion, 'title', _ => title);
deprecate(msg.promotion, 'subTitle', _ => subtitle);

export default msg;
