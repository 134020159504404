import { Stages } from './conf';

export default {
  header: {
    [Stages.Brokers]: () => 'Connect Your Existing Portfolio',
    [Stages.Auth]: ({ brokerName }) =>
      `Authenticate Your Account With ${brokerName}`,
    [Stages.ReAuth]: () => 'Please re-authenticate with your broker'
  },
  subheader: {
    // [ Stages.Brokers ]: 'Simply choose your existing broker account and have it analyzed:',
    [Stages.Auth]:
      "Please continue to the broker's website to authenticate your account, and then you will be automatically redirected back to TipRanks.",
    [Stages.ReAuth]:
      'To continue automatically updating your portfolios, your broker requires that you re-authenticate your account.'
  },
  footer: "By clicking you agree to TipRanks'",
  terms: 'Terms of use',
  loading: 'Loading',
  answer: 'Answer',
  submitAnswer: 'Submit Answer'
};
