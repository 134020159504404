import React from 'react';
import { actions } from './index';
import { connect } from 'react-redux';
import { get } from 'lodash';
import countries from './countries';
import countriesSpriteData from './countries-sprite';
import Component from 'react-pure-render/component';
import Reaptcha from 'reaptcha';
import { reCaptchaSiteKey } from '../../common/config';
import PropTypes from 'prop-types';

const report = () => null;

const flagFromCode = code => {
  const spriteData = countriesSpriteData[code.toLowerCase()];
  return (
    <div
      className="flag"
      style={{
        backgroundPosition:
          spriteData && `${spriteData.offset_x}px ${spriteData.offset_y}px`
      }}
    />
  );
};

const areaSelectWidth = 300;

class Select extends Component {
  static propTypes = {
    button: PropTypes.object,
    onSelect: PropTypes.func,
    options: PropTypes.array
  };

  constructor(...args) {
    super(...args);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    // this.sub = globalEvents('click').subscribe(this.close);
  }

  componentWillUnmount() {
    if (!this.sub) return;
    this.sub.dispose();
  }

  toggle = e => {
    this[this.state.isOpen ? 'close' : 'open'](e);
  };

  open(e) {
    setTimeout(() => this.setState({ isOpen: true }), 20);
  }

  close = e => {
    this.setState({ isOpen: false });
  };

  select(opt) {
    this.close();
    if (this.props.onSelect) {
      this.props.onSelect(opt);
    }
  }

  render({ button, options, ...props } = this.props, { isOpen } = this.state) {
    return (
      <div style={{ position: 'relative' }} {...props}>
        <div onClick={this.toggle}>{button}</div>
        {isOpen && (
          <ul style={{ position: 'absolute' }}>
            {options.map((opt, key) => (
              <li
                key={key}
                onClick={() => this.select(get(opt, 'props.value', opt))}
              >
                {opt}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.changeInput = this.changeInput.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.report = this.props.report || report;
  }

  componentDidMount() {
    this.setInitialStateFromAuth();
  }

  componentDidUpdate() {
    this.setInitialStateFromAuth();
  }

  setInitialStateFromAuth({ userEmail, userName, name, email } = this.props) {
    if (email == null && userEmail) {
      actions.setInput({ type: 'email', value: userEmail });
    }
    if (name == null && userName) {
      actions.setInput({ type: 'name', value: userName });
    }
  }

  changeInput(type) {
    return event => {
      const value = get(event, 'target.value', event);
      actions.setInput({ type, value });
    };
  }

  onClickSubmit(e) {
    return this.report('submit', { email: this.props.email });
  }

  displayError() {}

  onSubmit(e) {
    e.preventDefault();
    actions.postForm(this.props);
    // this.report('submitted', {email: this.props.email});
  }

  setToken = (token) => {
    actions.setInput({ type: 'reCaptchaToken', value: token });
  }

  render(
    {
      subject,
      name,
      email,
      country = {},
      phone,
      message,
      error,
      success,
      reCaptchaToken,
      loading,
      msg
    } = this.props
  ) {
    const isBtnDisabled = Boolean(loading) || !reCaptchaToken;

    const ph = msg.placeholders;
    return (
      <div className="wrapper">
        <form onSubmit={this.onSubmit}>
          <div className="inputWrapper">
            <Select
              className="select subjectSelect"
              button={
                <div>
                  <input
                    type="text"
                    onMouseDown={e => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    className="input selectInput"
                    value={get(subject, 'name')}
                    placeholder={ph.subject}
                  />
                  <div className="selectArrow" />
                  <div className="selectSpacer" />
                </div>
              }
              options={msg.subjects.map((sub, key) => (
                <div className="subjectOption" value={sub} key={key}>
                  {sub.name}
                </div>
              ))}
              onSelect={this.changeInput('subject')}
            />
          </div>

          <div className="spacer" />

          <div className="inputWrapper">
            <input
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              type="text"
              className="input"
              title={msg.errorMessages.name}
              required
              pattern=".{2,}"
              onChange={this.changeInput('name')}
              value={name}
              placeholder={ph.name}
            />
          </div>

          <div className="inputWrapper">
            <input
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              className="input"
              type="email"
              required
              onChange={this.changeInput('email')}
              value={email}
              placeholder={ph.email}
            />
          </div>

          <div className="inputWrapper areaWrapper">
            <Select
              className="select areaSelect"
              button={
                <div>
                  <div className="input selectInput areaInput">
                    {country.code && flagFromCode(country.code)}{' '}
                    <span className="dialCode">{country.dialCode}</span>
                  </div>
                  <div className="selectArrow" />
                  <div className="selectSpacer" />
                </div>
              }
              options={countries.map(x => {
                return (
                  <div value={x} className="areaOption" title={x.name}>
                    {flagFromCode(x.code)}
                    <div className="countryName">{x.name}</div>
                  </div>
                );
              })}
              onSelect={val => this.changeInput('country')(val)}
            />
            <div className="phoneInputWrapper">
              <input
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                pattern="[\d\-+\s]+"
                title={msg.errorMessages.phone}
                className="input phoneInput"
                type="tel"
                onChange={this.changeInput('phone')}
                value={phone}
                placeholder={ph.phone}
              />
            </div>
          </div>

          <div className="spacer" />

          {error && (
            <div className="error">
              {typeof error === 'string'
                ? msg.errorMessages[error]
                : msg.errorMessages.default}
            </div>
          )}

          <textarea
            pattern=".{3,}"
            className="textarea input"
            onChange={this.changeInput('message')}
            value={message}
            placeholder={ph.message}
            required
          />
          <div className="reCaptchaWrapper">
            <Reaptcha
              sitekey={reCaptchaSiteKey}
              onVerify={this.setToken}
              onExpire={() => this.setToken('')}
              onRender={() => this.setToken('')}
            />
          </div>
          <div className="buttonWrapper">
            {success && <div className="success">{msg.success}</div>}
            {!success && (
              <button
                disabled={isBtnDisabled}
                type="submit"
                onClick={this.onClickSubmit}
              >
                {msg.submitButton}
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

ContactForm.defaultProps = {
  country: { code: 'US', dialCode: '+1' },
  reCaptchaToken: ''
};

@connect(({ contactUs, auth }) => {
  if (!contactUs) return {};

  // TODO firstname and lastname dont exist any more.
  const firstName = get(auth, 'userInfo.firstName') || '';
  const lastName = get(auth, 'userInfo.lastName') || '';
  return {
    userEmail: get(auth, 'userInfo.email', ''),
    userName: firstName + !lastName ? '' : ' ' + lastName,
    subject: contactUs.subject,
    name: contactUs.name,
    email: contactUs.email,
    country: contactUs.country,
    phone: contactUs.phone,
    message: contactUs.message,
    reCaptchaToken: contactUs.reCaptchaToken,
    error: contactUs.error,
    success: contactUs.success
  };
})
export default class ConnectedContactForm extends ContactForm {
  render() {
    return super.render();
  }
}

export const __hotReload = true;
