import * as React from 'react';
import Component from 'react-pure-render/component';
import GlossaryLink from '../GlossaryLink';

import { defaultMsg } from './msg';

export class TrDashboardMgmtFeesNoData extends React.PureComponent<{
  type: 'funds' | 'fees';
  msg?: any;
}> {
  static defaultProps = {
    msg: defaultMsg,
    type: 'funds'
  };

  render() {
    const { msg, type } = this.props;

    return (
      <div className="tr-dashboard-mgmt-fees-no-data no-data">
        <div>{msg[`noData.${type}`]}</div>
        <GlossaryLink slug="mutual-funds-and-etfs" forceInternal>
          {msg.learnMore}
        </GlossaryLink>
      </div>
    );
  }
}
export default TrDashboardMgmtFeesNoData;
