import React from 'react';
import ReactDOM from 'react-dom';
import msg, { headerMsg } from './msg';
import SideMenu from './side-menu.react.js';
// import {actions} from './index';
import Slideout from 'slideout';
import classnames from 'classnames';
import rx from 'rx-lite';;
import report from './analytics';
import { get } from 'lodash';
import Component from 'react-pure-render/component';
import HeaderMenu from './menu-header.react';
import './slideout-styles.styl';
import './mobile-container.styl';
import { getSpCurrentPageName } from '../tr-payment'
import { HeaderTipranks } from '@tipranks/widget';
import { history } from 'sp/browser/history';


const { mobile: menuItems } = headerMsg;

const slideoutDuration = 420;

export default class MobileContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { slideout: false };

    if (this.props.theme !== "nasdaq") {
    const location = props.location;
    this.props.routing.update({ key: location.pathname, name: location.pathname }, {}, location, history);
    this.props.header.init();
    this.props.header.initOnBootstrap({});
    }
    
    this.slideoutStateObservable = new rx.Subject();

    this.toggleSideMenuSub = this.slideoutStateObservable
      .do(slideout => {
        this.slideout[slideout ? 'open' : 'close']();
      })
      .flatMap(
        slideout =>
          slideout
            ? rx.Observable.concat(
                rx.Observable.return(true),
                rx.Observable.return(true).delay(slideoutDuration)
              )
            : rx.Observable.return(false).delay(slideoutDuration)
      )
      .subscribe(slideout => this.setState({ slideout }));
  }

  componentDidMount() {
    const { actions } = this.props;
    this.slideout = new Slideout({
      panel: ReactDOM.findDOMNode(this.refs.panel),
      menu: ReactDOM.findDOMNode(this.refs.menu),
      padding: 265,
      touch: false,
      duration: slideoutDuration,
      tolerance: 5,
      side: 'right'
    });

    this.slideout.on('translateend', () => actions.openSideMenu());
    this.slideout.on('close', () => actions.closeSideMenu());
  }

  componentWillReceiveProps(
    nextProps
    // { mobileContainer: { sideMenuOpen: slideout } = {} } = {}
  ) {
    // this observable (emitter) is used because we want to delay closing the
    // menu for the animation. (if ths menu is being closed) we change the state
    // to hide it after 300ms
    this.slideoutStateObservable.onNext(nextProps.mobileContainer.sideMenuOpen);

    if (this.props.theme !== "nasdaq" && (this.props.location.pathname !== nextProps.location.pathname || this.props.location.search !== nextProps.location.search)) {
      const location = nextProps.location;
      this.props.routing.update({ key: location.pathname, name: location.pathname }, {}, location, history);
    }
  }

  componentWillUnmount() {
    this.toggleSideMenuSub.dispose();
  }

  onOpenMenu = slideout => {
    let nextSlideout;
    if (typeof slideout === 'boolean') {
      nextSlideout = slideout;
    } else {
      // only report on click event
      nextSlideout = !this.props.mobileContainer.sideMenuOpen;
      // report('clickedMenu', nextSlideout);
    }

    this.props.actions.toggleSideMenu(nextSlideout);
  };

  onPanelClicked = () => {
    this.slideoutStateObservable.onNext(false);
  };

  getMsg({ route } = this.props) {
    return {
      ...msg.general,
      ...(msg[route] || msg.home || {})
    };
  }

  render(
    { forceShowSlideout, slideout } = this.state,
    {
      autocomplete = [],
      auth,
      mobileUI: { sideMenu } = {},
      location,
      theme,
      myExperts
    } = this.props
  ) {
    const route = this.props.location.pathname;
    const loggedIn = false;
    const isDialog = get(location, 'routeConfig.isDialog');
    slideout = slideout || sideMenu;

    const items = menuItems;
    // .concat(loggedIn ? {
    // 	text: msg.general.logout,
    // 	onClick: authActions.logout,
    // 	id: 'logout'
    // } : {
    // 	text: msg.general.login,
    // 	to: '/login',
    // 	id: 'login'
    // });
    const pageName = getSpCurrentPageName(location.pathname);
    return (
      <div className={classnames('wrapper', { slideoutOpen: slideout })}>
        <div
          ref="menu"
          style={forceShowSlideout ? { display: 'block' } : {}}
          className={classnames('slideoutMenu', { slideoutMenuOpen: slideout })}
        >
          <SideMenu isLoggedIn={loggedIn} items={items} pageName={pageName}/>
        </div>
        <main
          ref="panel"
          onMouseDown={this.onPanelClicked}
          className={classnames('slideoutPanel', {
            slideoutPanelOpen: slideout,
            isDialog
          })}
        >
          {theme === "nasdaq" ?
            <HeaderMenu
              history={this.props.history}
              actions={this.props.actions}
              isSidebarOpen={forceShowSlideout}
              auth={auth}
              msg={this.getMsg()}
              goBack={() => this.props.history.goBack()}
              onOpenMenu={this.onOpenMenu}
              route={route}
              theme={theme}
              searchResults={autocomplete.list}
              myExperts={myExperts}
            />
          :
           <div className={'tr-app'}>
            <HeaderTipranks
              hasSearch
              hasSubHeader
              hasRight
              className={'client-components-header-header__header'}
              userStore={this.props.user}
              browserStore={this.props.browser}
              headerStore={this.props.header}
              hasTopRibbon={true}
            />
          </div>
  }
          <div className="mobile-content">{this.props.children}</div>
        </main>
      </div>
    );
  }
}

export const __hotReload = true;
