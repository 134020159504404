import React from 'react';
import AutoComplete from '../tr-dashboard-search/auto-complete.react';
import './search-header.styl';

import getImage from './images';

export default ({ goBack, msg, emitter, searchResults, onType, onSubmit }) => {
  // emitter for communication. submit event below triggers submission
  // in the autocomplete component.
  return (
    <div className="wrapper tr-search-header">
      <button className="arrowWrapper" onClick={goBack}>
        {getImage('arrow', 'svg', {
          dim: [20, 20]
        })}
      </button>
      <div className="tr-autocomplete">
        <AutoComplete
          onType={onType}
          onSubmit={onSubmit}
          results={searchResults}
          hasCategoryMenu={false}
          placeholder={msg.mobileSearchPlaceHolder}
          emitter={emitter}
        />
      </div>
      <button className="search" onClick={() => emitter.emit('submit')}>
        {getImage('search', 'svg', {
          dim: [25, 25],
          color: '#F89D03'
        })}
      </button>
    </div>
  );
};

export const __hotReload = true;
