import PortfolioItemSelector from '../PortfolioItemSelector/PortfolioItemSelector.react';
import { getReq } from '../../common/api';
import { Plan } from './types';
import { Restrictable } from './Restrictable';
import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './TopStocks.istyl';
import { Stock as NewsSentimentStock } from './types';
import { PortfolioItem, Stock } from '../../common/records';
import { VariableSizeSpinner } from '../Spinner/index.react';
import * as _ from 'lodash';
import { StockDetails } from 'sp/common/types';
import { PriceFetchz, PriceRequestor, StockPrice, PriceFetchzChild } from 'sp/common/components/PriceFetchz/PriceFetchz';

interface TopStockProps {
  renderer: (items: NewsSentimentStock) => React.ReactNode;
  feature: string; // TODO
  replacement?: () => React.ReactNode;
  header: React.ReactNode;
  subheader?: React.ReactNode;
  stocks: NewsSentimentStock[];
  className?: string;
  tooltip?: string;
  hasPayed?: boolean;
}

class TopStocksComponent extends PriceFetchzChild<TopStockProps, never> {
  public componentDidMount() {
    this.props.priceRequest(this.props.stocks.map(x => x.ticker));
  }
  public componentDidUpdate(prevProps) {
    if (
      _.difference(prevProps.stocks, this.props.stocks).length !==
      this.props.stocks.length
    )
      this.props.priceRequest(this.props.stocks.map(x => x.ticker));
  }

  public render() {
    const {
      header,
      stocks,
      feature,
      replacement = () => <span />,
      className,
      requestedPrices: prices,
      tooltip,
      subheader,
      hasPayed,
    } = this.props;
    return (
      <Restrictable
        hasPayed={hasPayed}
        className={classNames(styles.topStocks, className)}
        feature={feature}
        plan={Plan.Premium}
        replacement={replacement as any}
      >
        <div className={styles.topStocksTitle}>
          <h3>{header}</h3>
          {subheader ? <span>{subheader}</span> : ''}
        </div>
        <ul>
          {stocks.map((stock, i) => (
            <li key={stock.ticker || i}>
              <div className={styles.headline}>
                <div className={styles.companyInfo}>
                  <PortfolioItemSelector
                    className={styles.companyTicker}
                    item={
                      new PortfolioItem({
                        ticker: stock.ticker,
                        stock: new Stock({ hasLink: true }),
                      })
                    }
                    type="ticker"
                  />
                  <span className={styles.companyName}>
                    {stock.companyName}
                  </span>
                </div>
                <div className={styles.priceInfo}>
                  {!prices.retrievedPrice(stock.ticker) ? (
                    <VariableSizeSpinner size={40} />
                  ) : !prices.hasPrice(stock.ticker) ? (
                    <span>-</span>
                  ) : (
                        <span>
                          <strong>{this.price(stock.ticker).toFixed(2)}</strong>
                          <span
                            className={classNames({
                              [styles.negative]: this.change(stock.ticker) < 0,
                              [styles.positive]: this.change(stock.ticker) > 0,
                            })}
                          >
                            {this.change(stock.ticker) > 0
                              ? '▲'
                              : this.change(stock.ticker) < 0 ? '▼' : ''}
                            {Math.abs(this.change(stock.ticker))} ({Math.abs(this.changePercent(stock.ticker)).toFixed(2)}%)
                      </span>
                        </span>
                      )}
                </div>
              </div>
              {this.props.renderer(stock)}
            </li>
          ))}
        </ul>
      </Restrictable>
    );
  }
}



interface EmptyProps {
  message: string;
  isEmpty: boolean;
  isDataLoaded: boolean;
  className?: string;
}
export class EmptyMessageHolder extends React.PureComponent<EmptyProps, never> {
  render() {
    const { message, isDataLoaded, isEmpty, className } = this.props;
    if (isEmpty && isDataLoaded)
      return <EmptyMessage className={className} message={message} />;
    return <div>{this.props.children}</div>;
  }
}
interface EmptyMessageProps {
  message: string;
  className?: string;
}
export class EmptyMessage extends React.PureComponent<
  EmptyMessageProps,
  never
  > {
  render() {
    const { message, className = 'emptyContainer' } = this.props;
    return <div className={styles[className]}>{message}</div>;
  }
}

export const loadSpinnerGenerator = function (isLoaded: boolean) {
  if (isLoaded)
    return (
      fn: () => JSX.Element | string | (JSX.Element | string)[],
      size: number,
    ) => fn();
  return (
    fn: () => JSX.Element | string | (JSX.Element | string)[],
    size: number,
  ) => <VariableSizeSpinner size={size} />;
};

export const dataOrEmptyGenerator = function <T>(isLoaded: boolean) {
  if (isLoaded) return (fn: () => T[]) => fn();
  return (fn: () => T[]) => [];
};

export enum Detail {
  PreviousClose,
  Open,
  Bid,
  Ask,
  DaysRange,
  Volume,
  AvgVolume,
  MarketCap,
  Beta,
  PERatio,
  EPS,
  EarningsDate,
  DividendYield,
  ExDividendDate,
  OneYearPriceTarget,
  FiftyTwoWeekRange,
}

export class TopStocks extends PriceFetchz<TopStockProps> {
  render() {
    return (
      <TopStocksComponent
        priceRequest={this.requestPrices}
        requestedPrices={this.getRequestedPrices()}
        {...this.props}
      />
    );
  }
}
