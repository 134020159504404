import { Record } from 'immutable';
import { Form } from 'sp/common/types';
// Record is like class, but immutable and with default values.
// https://facebook.github.io/immutable-js/docs/#/Record
const Form: new () => Form = (Record as any)({
  state: 'SUCCESS',
  disabled: false,
  error: null,
  fields: new (Record({
    email: '',
    password: '',
  }))(),
});

export default Form;
