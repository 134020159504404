import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import defaultMsg from './en';
import Screens from './welcome-screens.react';

export default class Welcome extends Component {
  static propTypes = {
    mediaQuery: PropTypes.object,
    msg: PropTypes.object,
    screenAnimationDuration: PropTypes.number,
    startDashboard: PropTypes.func
  };

  static defaultProps = {
    msg: defaultMsg
  };

  startDashboard = () => {
    const { startDashboard } = this.props;
    startDashboard();
  };

  btnLists = () => {
    const { msg } = this.props;

    return [
      <button
        className="main-btn start-dashboard"
        key="start-dashboard"
        onClick={this.startDashboard}
      >
        {msg.startDashboard}
      </button>
    ];
  };

  render() {
    const { mediaQuery, screenAnimationDuration, msg } = this.props;

    return (
      <section className="welcome">
        <header>
          <h1>{msg.title}</h1>
        </header>
        <p className="description">{msg.description}</p>
        <div className="main-btns">
          {!mediaQuery.get('laptop') && this.btnLists()}
        </div>
        <section className="key-benefits">
          <div className="key-benefits-width-constraint">
            <h1>{msg.benefitsTitle}</h1>
            <ul className="benefits">
              {msg.benefits.map((benefit, index) => (
                <li key={index}>
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
            <div className="main-btns">
              {mediaQuery.get('laptop') && this.btnLists()}
            </div>
          </div>
          {mediaQuery.get('laptopXS') && (
            <Screens
              msg={msg}
              screenAnimationDuration={screenAnimationDuration}
            />
          )}
        </section>
        {mediaQuery.get('mobileXXL') &&
          !mediaQuery.get('laptopXS') && (
            <img alt="" className="tablet" src="/assets/img/tablet.png" />
          )}
      </section>
    );
  }
}
