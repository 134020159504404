import * as React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

export const PosNeg = ({ gain }: { gain: number }) => {
  const isPos = gain >= 0;
  const sign = isPos ? '+' : '-';
  const cssClass = isPos ? 'positive' : 'negative';
  const perc = Math.abs(gain * 100).toFixed(2);

  return (
    <span className={cssClass}>
      {sign}
      {perc}%
    </span>
  );
};

export const FormatDate = ({ date }: { date: Date | number | string }) => (
  <FormattedDate day="numeric" month="short" year="numeric" value={date} />
);
