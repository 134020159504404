import PropTypes from 'prop-types';
import React from 'react';
// import {actions} from './index';
import report from './analytics';
import BaseMenuItems from './menu-items.react.tsx';
import Component from 'react-pure-render/component';

import './side-menu.styl';

const MenuItems = BaseMenuItems;

export default class SideMenu extends Component {
  static propTypes = {
    auth: PropTypes.bool,
    items: PropTypes.array
  };

  onItemClick = ({ id, onClick }) => () => {
    report('clickedMenuItem', id);
    // actions.closeSideMenu();
    if (onClick) {
      onClick();
    }
  };

  render({ items, pageName } = this.props) {
    return (
      <nav>
        <MenuItems items={items} pageName={pageName}/>
      </nav>
    );
  }
}

export const __hotReload = true;
