// Main Header
import React from 'react';
import {
  defaultCategories,
  msg as categoriesMsg
} from '../tr-dashboard-search/categories.js';

import links from './links.tsx';

const getHeaderImage = () => null;

const topAnalysts = {
  id: 'analysts',
  text: 'Top Wall Street Analysts',
  to: '/analysts/top'
};

const topBloggers = {
  id: 'bloggers',
  text: 'Top Financial Bloggers',
  to: '/bloggers/top'
};

const topInsiders = {
  id: 'insiders',
  text: 'Top Corporate Insiders',
  to: '/insiders/top'
};

const topHedgefunds = {
  id: 'hedgefunds',
  text: 'Top Hedge Fund Managers',
  to: '/hedge-funds/top'
};

const topLists = {
  id: 'topLists',
  text: 'Top 25 Lists',
  items: [topAnalysts, topBloggers, topInsiders, topHedgefunds]
};

const toolsMobile = {
  id: 'toolsMobileHeader',
  text: 'Menu',
  onClick: () => null // for 'Clickable' to set it as a button. Would import the action itself but that causes weird issues, probably due to circular dependency.
};

const topMobile = {
  id: 'topListsWrapper',
  text: 'Top 25 Experts',
  to: '/top-experts'
};

const top = {
  id: 'topListsWrapper',
  text: 'Top 25',
  to: '/top-experts'
};

const trendingStocks = {
  id: 'trendingStocks',
  text: 'Trending Stocks',
  href: '/trending-stocks'
};

const topStocks = {
  id: 'topStocks',
  text: 'Top Stocks',
  items: [
    {
      id: 'topRatedStocks',
      text: "Analysts' Top Stocks",
      href: '/stocks/top-rated'
    },
    {
      id: 'hotInsiderStocks',
      text: "Insiders' Hot Stocks",
      href: '/insiders/hot-stocks',
      tag: 'popular'
    },
    Object.assign({ tag: 'new', trendingStocks })
  ]
};

const calendars = {
  text: 'Calendars',
  items: [
    {
      id: 'earnings',
      text: 'Earnings Calendar',
      href: '/calendars/earnings'
    },
    {
      id: 'dividends',
      text: 'Dividends Calendar',
      href: '/calendars/dividends'
    }
  ]
};

const insiderTrading = {
  id: 'insideTrading',
  text: 'Daily Insider Transactions',
  href: '/inside-trading'
};


const forInstitutions = {
  id: 'forInstitutions',
  text: 'For Institutions',
  items: [
    {
      id: 'api',
      text: 'API for Institutions',
      to: '/get-api'
    }
  ]
};

const dashboard = {
  id: 'dashboard',
  text: (
    <span>
      {getHeaderImage('dashboard', 'svg')}
      Smart Portfolio
    </span>
  ),
  href: '/smart-portfolio'
};

const innerDashboard = {
  id: 'dashboard',
  text: <span>Smart Portfolio</span>,
  href: '/smart-portfolio'
};

const dashboardMobile = {
  id: 'dashboard',
  text: 'Smart Portfolio',
  href: '/smart-portfolio'
};


const stocksMobile = {
  id: 'stocks',
  text: 'Top Recommended Stocks',
  href: '/stocks/top-rated'
};

const getApi = {
  id: 'getApi',
  text: 'API',
  href: '/get-api'
};

const stocks = {
  id: 'stocks',
  text: 'Top Stocks',
  href: '/stocks/top-rated'
};

const aboutTipranks = {
  id: 'aboutInner',
  text: '',
  items: [
    {
      id: 'aboutHash',
      text: 'About TipRanks',
      to: '/about'
    },
    {
      id: 'contact',
      text: 'Contact Us',
      to: '/contact',
      target: '_blank'
    }
  ]
};

const about = {
  id: 'about',
  text: 'About',
  to: '/about',
  items: [aboutTipranks]
};

const aboutMobile = {
  id: 'aboutMobile',
  text: 'About Us',
  href: '/about'
};

const faq = {
  id: 'faq',
  text: 'FAQ',
  href: '/faq',
  target: '_blank'
};


// Sub Header
const sub = [
  {
    id: 'top-analysts',
    text: 'Top 25 Analysts',
    to: '/analysts/top'
  },
  {
    id: 'top-hedge-funds',
    text: 'Top 25 Hedge Funds',
    to: '/hedge-funds/top'
  },
  {
    id: 'ratings',
    text: 'Analyst Daily Ratings',
    href: '/stock-ratings'
  },
  aboutMobile,
  faq
];

const search = {
  results: {
    categories: {
      ticker: 'Ticker / Company',
      analyst: 'Analysts',
      blogger: 'Bloggers',
      insider: 'Corporate Insiders',
      institution: 'Hedge Funds'
    },
    icons: {
      analyst: 'search-analyst',
      blogger: 'search-blogger',
      insider: 'search-insider',
      institution: 'search-hedgefund'
    }
  },
  categoryList: defaultCategories,
  autocomplete: {
    categories: categoriesMsg,
    loader: 'loading_smallest'
  }
};

export default {
  goPro: 'Go Pro',
  main: links.headerLinks,
  home: links.headerLinks,
  search,
  sub,
  mobile: links.sideMenuLinks
};

export const __hotReload = true;
