import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

//import { Col } from '../conf';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class TrDashboardHoldingsDetailSincePurchaseGain extends Component {
  static propTypes = {
    //col: PropTypes.instanceOf(Col),
    item: PropTypes.object
  };

  render() {
    const { col, item } = this.props;

    return (
      <div className="tr-dahsobard-holdings-detail sincePurchaseGain">
        <PortfolioItemSelector {...this.props} type={col.get('id')} />
      </div>
    );
  }
}
