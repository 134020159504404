import { brandName } from '../../common/config';

export const wat = {
  title: `Meet the New Smart&nbsp;Portfolio`,
  subtitle:
    'We have upgraded our portfolio tool to Smart Portfolio, a powerful new wealth management system from TipRanks',
  banner: {
    new: 'New!',
    title: `Smart Portfolio @&nbsp;${brandName} `,
    discover: 'Discover the ultimate tool to maximize your returns'
  },
  mobileBanner: {
    new: 'NEW!',
    title: `Smart&nbsp;Portfolio @&nbsp;${brandName} `
  },
  list: {
    title: 'Smart Portfolio will allow you to:',
    importInfo:
      'Import information directly and securely from your brokerage accounts',
    stayUpToDate:
      'Stay up-to-date with the latest and most relevant news on your holdings',
    compare: "Compare your portfolio's performance to other investors",
    optimize: 'Optimize your asset allocation, risk and dividends',
    alerts: 'Receive real-time alerts about your holdings'
  },
  note:
    '<b>Important Note:</b> Nasdaq Smart Portfolio is operated under the <a href="/terms" target="_blank">TipRanks Terms of Service</a>.<br>As a Nasdaq user, you are entitled to a FREE upgrade to Smart Portfolio.<br><br>Click \'Import My Nasdaq Portfolios\' to transfer your existing Nasdaq portfolios to the new Smart Portfolio or click `No Thanks` if you prefer not to import your Nasdaq Portfolio. <br><b>Questions?</b> <a href="mailto:support@tipranks.com">Contact Us</a>',
  noThanks: 'No Thanks',
  importButton: 'Import My Nasdaq Portfolios',
  buttonNote:
    'By clicking you agree to the <a href="/terms" target="_blank">\'terms of service\'</a>'
};

export default {
  title: 'Welcome to your Smart Portfolio',
  title_isPermanentLoggedIn: 'Congratulations!',
  undertitle: 'Add stocks to create your FREE Smart Portfolio',
  undertitle_isPermanentLoggedIn:
    'Your portfolio has been upgraded to Smart Portfolio',
  openSmartPortfolio: 'Open Smart Portfolio',
  addStocks: 'Add Stocks Manually',
  connectPortfolio: 'Connect Your Existing Portfolio',
  placeholder: 'Enter Symbol or Company Name',
  addSymbol: 'Add Symbol',
  terms: "By clicking you agree to TipRanks'",
  termsLink: 'Terms of service',
  connect: 'Simply connect your existing broker account and have it analyzed',
  seeAll: 'See all supported brokers',
  buttonSearch: 'Create Portfolio',
  buttonConnect: 'Connect Your Portfolio',
  mobileBanner: {
    new: 'NEW!',
    title: '<b>Tip</b>Ranks Smart&nbsp;Portfolio'
  },
  banner: {
    new: 'NEW!',
    title: `Smart Portfolio @ ${brandName} `,
    discover: 'Discover the ultimate tool to maximize your returns',
    getNotifications:
      'Get real-time notifications from the most accurate analysts',
    seeHowPerform: 'Compare your portfolio’s performance to other investors',
    learn: 'Optimize your asset allocation, risk, and dividend yield',
    alerts: 'Receive real-time alerts about important activity of your holdings'
  }
};
