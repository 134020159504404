import * as React from 'react';
import * as classNames from 'classnames';
import * as innerStyles from './styles.istyl';
import * as _ from 'lodash';
import { Br, FlexiBr } from 'sp/common/utils';

export type Styles = React.CSSProperties;
export type Percent = number; // number between 0 and 1.
export enum Position {
  Up,
  Down,
}

export interface BetaBarsGraphProps {
  avgPortfolioPercentValue: number;
  myPortfolioPercentValue: number;
  barCount?: number; // how many bars do you want?
}
/**
 * avgPortfolioPercentValue | myPortfolioPercentValue are 0-1 values.
 */
export class BetaBarsGraph extends React.PureComponent<BetaBarsGraphProps> {
  render() {
    const {
      barCount = 7,
      avgPortfolioPercentValue,
      myPortfolioPercentValue,
    } = this.props;

    return (
      <div className={classNames(innerStyles.BetaBarsGraph)}>
        {this.renderMarker(
          <div>
            Avg. {process.env.THEME === 'web' ? 'TipRanks' : 'Nasdaq'}
            <br />Portfolio
          </div>,
          avgPortfolioPercentValue,
          false,
        )}
        <div className={classNames(innerStyles.container)}>
          <div className={classNames(innerStyles.risk, innerStyles.leftRisk)}>
            Low<FlexiBr cls="showUntil1920" />Risk
          </div>
          <div className={classNames(innerStyles.bars)}>
            {Array.from({ length: barCount + 1 }).map((i, index) => (
              <div key={index} className={classNames(innerStyles.seperator)} />
            ))}
          </div>
          <div className={classNames(innerStyles.risk, innerStyles.rightRisk)}>
            High<FlexiBr cls="showUntil1920" />Risk
          </div>
        </div>
        {this.renderMarker(
          <div>
            My<br />Portfolio
          </div>,
          myPortfolioPercentValue,
          true,
        )}
      </div>
    );
  }
  renderMarker = (text, percent, isBelowGraph) => {
    // SUPER HACK :D
    let value = parseInt((percent * 100).toFixed(0));

    if (value < 0) value = 0;
    if (value > 100) value = 100;
    let left = `${value}%`;

    if (value > 85) left = `calc(${value}% - 52px)`; // TODO fix this hack. We're adjusting manually based on a width once extracted as to not overflow the right side.

    return (
      <div
        style={{
          left,
        }}
        className={classNames(innerStyles.marker, {
          [innerStyles.isBelowGraph]: isBelowGraph,
          [innerStyles.isAboveGraph]: !isBelowGraph,
          [innerStyles.left]: percent > 0 && percent < 0.3,
          [innerStyles.center]: percent > 0.3 && percent < 0.7,
          [innerStyles.right]: percent < 1 && percent > 0.7,
        })}
      >
        {text}
      </div>
    );
  };
}
