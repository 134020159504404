import './index.styl';

import * as classNames from 'classnames';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import * as moment from 'moment';

import { Tooltip } from '../../browser/news-analysis/Tooltip/Tooltip';
import {
  benchmark as benchmarkEnum,
  performanceDisplayTab,
  period as periodEnum,
} from '../../common/enums';
import { ExpertRating } from '../expert-rating/ExpertRating';
import GlossaryLink from '../GlossaryLink/index';
import { SuccessRate } from '../success-rate/SuccessRate';
import { TrProfilePicDropzone } from '../tr-profile-pic-dropzone/TrProfilePicDropzone';
import Editable from './editable.react';
import localMsg from './msg';
import { FormatDate, PosNeg } from './UtilComponents';
import { PortfolioManagerRecord_NotImmutable, StockPickerRecord_Obj, StockPickerNotRecord } from 'sp/common/components/performance/records';
import _ = require('lodash');
import { selectActivePortfolio } from '../dashboard/selectors';
import { BetterImmutableInterface } from 'sp/common/immutableStuff';
import { PortfolioRecord_NotImmutable } from 'sp/common/types';

const stylusVariables = require(`../style-helpers/themes/${process.env
  .THEME}.json`);
const colorPositive = stylusVariables['$colorPositive'];
const colorNegative = stylusVariables['$colorNegative'];

export type PersonStatsStockPicker = {
  stars: number;
  totalRanked: number;
  rank: number;
}

export class PersonStats extends React.PureComponent<{
  activePortfolioName: string;
  avgReturn: number | null; //={picker.get('averageReturn')}
  benchmark: number | null; //={ui.benchmark}
  creationDate: string; //={performance.get('creationDate')}
  displayTab: string; //={ui.performanceDisplayTab}
  goodTransactions: any; //={picker.get('goodTransactions')}
  hasEnteredHoldings: any; //={performance.get('everHadHolding')}
  isPermanentLoggedIn: boolean; //={isPermanentLoggedIn}
  onBenchmarkChange: any; //={this.onBenchmarkChange}
  onClickedWhenEditingPrevented: any; //={this.onClickedWhenEditingPrevented}
  onModeChange: any; //={this.onModeChange}
  onNameChange: any; //={this.onNameChange}
  onPeriodChange: any; //={this.onPeriodChange}
  openMyHoldingsDialog: any; //={this.openMyHoldingsDialog}
  period: any; //={ui.period}
  totalTransactions: any; //={picker.get('totalTransactions')}
  userName: string; //={selectUserName(auth)}
  userPic: any; //={userInfo.get('pictureUrl')}
  portfolioManager: PortfolioManagerRecord_NotImmutable | null;
  picker: StockPickerNotRecord;
  preventEditing?: boolean;
  isPublicPortfolio: boolean;
}> {
  state = { onHover: false };
  tabs = ['stockPicker', 'portfolioManager'];
  tabsContent = {
    stockPicker: ['successRate', 'avgReturn'],
    portfolioManager: ['portfolioGain', 'sharpeRatio'],
  };
  tabHeaders = {
    successRate: 'Success Rate',
    avgReturn: 'Average Return',
    portfolioGain: 'Portfolio Gain',
    sharpeRatio: 'Sharpe Ratio',
  };

  onModeChange = e => this.props.onModeChange(e.target.value);

  tabBtnClicked = tab => () => this.props.onModeChange(tab);

  onNameChange = name => this.props.onNameChange(name);

  setHover = () => this.setState({ onHover: true });
  setNoHover = () => this.setState({ onHover: false });

  getNoHoldingsImage = st =>
    st
      ? '/assets/img/MyPerformance-portfolio-manager-locked-hover.png'
      : '/assets/img/MyPerformance-portfolio-manager-locked.png';

  renderTabBoxes = (tab, opts) => {
    if (tab === 'stockPicker' || opts.hasPortfolioManager) {
      return this.tabsContent[tab].map(box => (
        <div key={box} className="tab-info">
          <div className="tab-info-head">{this.tabHeaders[box]}</div>
          {this.renderBox(box, opts)}
        </div>
      ));
    }
    if (opts.hasEnteredHoldings) {
      return (
        <div>
          <button className="holdingsButton">
            <img
              alt="Our team is measuring your performance performance. Your investment metrics will appear within 24 hours"
              src="/assets/img/MyPerformance-portfolio-manager-locked-Note.png"
            />
          </button>
        </div>
      );
    }
    const imgSrc = this.getNoHoldingsImage(this.state.onHover);
    return (
      <div>
        <button className="holdingsButton" onClick={opts.openMyHoldingsDialog}>
          <img
            alt="no holdings"
            src={imgSrc}
            width="280"
            onMouseOut={this.setNoHover}
            onMouseOver={this.setHover}
          />
        </button>
      </div>
    );
  };

  monthsPassed = d => moment().diff(d, 'months');

  renderBox = (boxId, props) => {
    let Comp;
    switch (boxId) {
      case 'portfolioGain': Comp = PortfolioGain; break;
      case 'sharpeRatio': Comp = SharpeRatio; break;
      case 'successRate': Comp = SuccessRateTab; break;
      case 'avgReturn': Comp = AverageReturn; break;
    };
    return <Comp {...props} />
  }

  render() {
    const {
      goodTransactions,
      totalTransactions,
      creationDate,
      userPic,
      userName,
      onBenchmarkChange,
      onPeriodChange,
      period,
      benchmark,
      displayTab,
      portfolioManager,
      picker,
      isPermanentLoggedIn,
      onClickedWhenEditingPrevented,
      preventEditing,
      isPublicPortfolio,
      activePortfolioName
    } = this.props;

    const hasStockPicker = picker !== null;
    const shouldDisplayStockPicker =
      displayTab === performanceDisplayTab.stockPicker;

    const stockPerformance = hasStockPicker
      ? {
        rating: picker.stars,
        rank: picker.rank,
        totalRanked: picker.totalRanked,
      }
      : {};

    const hasPortfolioManager = !!portfolioManager;
    const portfolioPerformance =
      hasPortfolioManager && displayTab === performanceDisplayTab.portfolioManager
        ? {
          rating: portfolioManager!.stars,
          rank: portfolioManager!.rank,
          totalRanked: portfolioManager!.totalRanked,
          sharpeRatio: portfolioManager!.sharpe,
          avgSharpeRatio: portfolioManager!.averageSharpe,
          portfolioGain: portfolioManager!.portfolioReturn,
        } : {};
    const monthsPassed = this.monthsPassed(new Date(creationDate));

    return (
      <div className="shadow-box shadow-box-profile">
        <PortfolioFace
          {...{
            activePortfolioName,
            userPic,
            userName,
            displayTab,
            onPeriodChange,
            onBenchmarkChange,
            onModeChange: this.onModeChange,
            onNameChange: this.onNameChange,
            isPermanentLoggedIn,
            onClickedWhenEditingPrevented,
            rating: 0,
            rank: 0,
            totalRanked: 0,
            preventEditing,
            isPublicPortfolio,
            ...(shouldDisplayStockPicker ? stockPerformance : portfolioPerformance),
          }}
        />
        <menu>
          {this.tabs.map(tab => (
            <div
              key={tab}
              className={classNames('tabItem', { active: displayTab === tab })}
            >
              <button key={tab} onClick={this.tabBtnClicked(tab)}>
                {localMsg.tabs[tab]}
              </button>
            </div>
          ))}
        </menu>
        <div className="tab-content">
          {this.tabs.map(tab => (
            <div
              className={classNames({ active: tab === displayTab })}
              key={tab}
            >
              <div>
                {this.renderTabBoxes(
                  tab,
                  Object.assign(
                    {
                      goodTrans: goodTransactions,
                      totTrans: totalTransactions,
                      hasPortfolioManager: hasPortfolioManager,
                      ...portfolioPerformance,
                    },
                    this.props,
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
        {shouldDisplayStockPicker ? (
          <div className="period-and-benchmark">
            <p>
              <span>Each position is measured over a</span>{' '}
              <select value={period.toString()} onChange={onPeriodChange}>
                <option value={periodEnum.monthly.toString()}>1 Month</option>
                <option value={periodEnum.quarterly.toString()}>
                  3 Months
                </option>
                <option value={periodEnum.yearly.toString()}>1 Year</option>
              </select>{' '}
              <span>period, the return is compared with </span>
              <select value={(benchmark || '').toString()} onChange={onBenchmarkChange}>
                <option value={benchmarkEnum.none.toString()}>
                  No Benchmark
                </option>
                <option value={benchmarkEnum.snp500.toString()}>
                  S&amp;P 500
                </option>
                <option value={benchmarkEnum.sector.toString()}>Sector</option>
              </select>.
            </p>
          </div>
        ) : hasPortfolioManager ? (
          monthsPassed === 0 ? (
            <div className="measureTime">
              Measured over a period less than one month
            </div>
          ) : (
              <div className="measureTime">
                Measured over a period of {monthsPassed} month{monthsPassed > 1 ? 's' : ''}
              </div>
            )
        ) : null}
        <GlossaryLink
          className={classNames('tab-url', {
            centered: !shouldDisplayStockPicker && hasPortfolioManager,
          })}
          slug="how-tipranks-measures-user-performance"
          forceInternal
        >
          Learn how your performance is measured &gt;
        </GlossaryLink>
      </div>
    );
  }
}

const AverageReturn = ({ avgReturn }) => (
  <div className="avgReturn-tab">
    <div className="avgReturn-tab-tooltip tab-tooltip">
      <Tooltip
        className="person-stats-tooltip"
        overrideWithTheme="web"
        content={localMsg.tooltips.averageReturn}
      />
    </div>
    <div className="tab-info-act">
      <PosNeg gain={avgReturn} />
    </div>
    <div className="bottom">Average return per transaction</div>
  </div>
);

const PortfolioGain = ({ portfolioGain, creationDate }) => (
  <div className="portfolioGain-tab">
    <div className="portfolioGain-tab-tooltip tab-tooltip">
      <Tooltip
        className="person-stats-tooltip"
        overrideWithTheme="web"
        content={localMsg.tooltips.portfolioGain}
      />
    </div>
    <div className="tab-info-act">
      <PosNeg gain={portfolioGain} />
    </div>
    <div className="bottom">
      Your portfolio return since{' '}
      <b>
        <FormatDate date={creationDate} />
      </b>
    </div>
  </div>
);

const SharpeRatio = ({ sharpeRatio, avgSharpeRatio }) => (
  <div className="sharpe-tab">
    <div className="sharpe-tab-tooltip tab-tooltip">
      <Tooltip
        className="person-stats-tooltip"
        overrideWithTheme="web"
        content={localMsg.tooltips.sharpeRatio}
      />
    </div>
    <div className="tab-info-act">
      <span className="sharpeValue">
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={sharpeRatio}
        />
      </span>
    </div>
    <div className="bottom">
      TipRanks average sharpe:{' '}
      <b>
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={avgSharpeRatio}
        />
      </b>
    </div>
  </div>
);

const SuccessRateTab = ({ goodTrans, totTrans }) => {
  goodTrans = goodTrans || 0;
  totTrans = totTrans || 0;
  const rate = totTrans === 0 ? 0 : goodTrans / totTrans;
  const isNegative = rate < 0.5;
  return (
    <div className="successRate-tab">
      <div className="successRate-tab-tooltip tab-tooltip">
        <Tooltip
          className="person-stats-tooltip"
          overrideWithTheme="web"
          content={localMsg.tooltips.successRate}
        />
      </div>
      <div className="tab-info-act">
        {totTrans === 0 ? (
          '-'
        ) : (
            <SuccessRate
              animated
              noBg
              radius={30}
              width={4}
              fontSize={16}
              rate={rate}
              style={{
                positive: [colorPositive, colorPositive],
                negative: [colorNegative, colorNegative],
                text: isNegative ? colorNegative : colorPositive,
                empty: '#898989',
              }}
            />
          )}
      </div>
      <div className="bottom">
        {goodTrans} <span>out of</span> {totTrans}{' '}
        <span>profitable transactions</span>
      </div>
    </div>
  );
};

export const PortfolioFace = function PortfolioFace({
  userName,
  userPic,
  rating,
  rank,
  displayTab,
  totalRanked,
  onModeChange,
  onNameChange,
  isPermanentLoggedIn,
  onClickedWhenEditingPrevented,
  isPublicPortfolio,
  preventEditing = false,
  activePortfolioName,
}: {
    userName: string;
    userPic: string;
    rating;
    rank;
    displayTab;
    totalRanked;
    onModeChange;
    onNameChange;
    isPermanentLoggedIn;
    onClickedWhenEditingPrevented;
    isPublicPortfolio: boolean;
    preventEditing?: boolean;
    activePortfolioName: string;
  }) {
  return (
    <div className="portfolio-face">
      <div className="profile-pic">
        <TrProfilePicDropzone
          preventEditing={preventEditing}
          profileSrcName={userPic}
        />
      </div>
      <div className={classNames('user-info')}>
        <Editable
          onChange={onNameChange}
          emptyContent="Enter Name"
          preventEditing={preventEditing || !isPermanentLoggedIn}
          onClickedWhenEditingPrevented={onClickedWhenEditingPrevented}
          className={classNames('name')}
          classNames={classNames({ preventEditing, publicPortfolio: isPublicPortfolio })}
          title={userName}
          value={userName}
        />
        <div className="mode">{activePortfolioName}</div>
        {/* <div className="mode">
          <select value={displayTab} onChange={onModeChange}>
            <option value="stockPicker">Stock Picker</option>
            <option value="portfolioManager">Portfolio Manager</option>
          </select>
        </div> */}
        <div>
          <div className="stars">
            <ExpertRating animate={false} size={16} rating={rating || 0} />
          </div>
          {!rating ? (
            <div className="ranking">Performance ranking pending</div>
          ) : (
              <div className="ranking">
                <span>Ranked </span>#<FormattedNumber value={rank || null} />
                <span> out of </span>
                <FormattedNumber value={totalRanked || null} />{' '}
                <span>
                  {process.env.THEME === 'nasdaq' ? "Nasdaq's" : "TipRanks'"}{' '}
                  Investors
              </span>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
