import reporter from '../analytics';

const lastLockedFeatureNames = {
  analyst: 'analyst-rating',
  blogger: 'blogger-rating',
  insider: 'insider-transaction',
  hedgefund: 'hedge-fund-activity',
  ranking: 'star-ranking',
  analystConsensus: 'analyst-consensus',
  bestAnalystConsensus: 'top-analyst-consensus',
  bloggerSentiment: 'blogger-consensus',
  insiderSentiment: 'insider-consensus',
  hedgefundSentiment: 'hedge-fund-consensus'
};

const events = {
  saw: 'saw-email-alerts-screen',
  clickedRestricted: () => ({
    event: 'clicked-locked-feature'
  }),
  clickedRestrictedAlertSettings: ({ field }) => ({
    event: 'clicked-locked-email-setting',
    props: { settingName: field }
  }),
  clickedRestrictedTrigger: type =>
    `sp-email-alerts-screen-${lastLockedFeatureNames[type]}`,
  changedEmailAlerts: ({ type, isDisabled }) => {
    const state = isDisabled ? 'disable' : 'enable';
    return `email-alerts-${state}-all-${type}-alerts`;
  }
};

const report = reporter(events);

export default report;
