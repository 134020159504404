export default {
  pathRoot: 'Financial Terms',
  subtitle: 'What is a ',
  subtitleVowel: 'What is an ',
  bottomBanner: {
    title: 'You might also like',
    banners: [
      //   {
      //     name: 'dailyInsider',
      //     src: 'dict/daily-insider-banner.png',
      //     caption: 'This day trading strategy returned 78% in 2015. You can too.',
      //     href: '/trading-center/daily-insider/?ref=glossary'
      // },
      {
        name: 'top2015',
        src: 'dict/top-2015-banner.png',
        caption: 'Trust only the best Wall Street analysts.',
        to: '/analysts/top'
      }
      // {
      //     name: 'goSector',
      //     src: 'dict/go-sector-banner.png',
      //     caption: 'This sector ETF rotation strategy consistently outperforms the market.',
      //     href: '/trading-center/go-sector/?ref=glossary'
      // }
    ]
  }
};
