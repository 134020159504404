import { fromJS } from 'immutable';
import { get, set } from 'lodash';

import localStorage from './components/personsList/localstorage';
import ServerPersist from './persistStateToServer';

import { selectIsPermanentLoggedIn } from '../common/auth/selectors.ts';
import { ReduxProps } from "sp/common/types";
import { persistUserStateToServer } from '../common/config';

const persistToServer = new ServerPersist({
  getUrl: '/api/users/GetUserState',
  setUrl: '/api/users/StoreUserState',
  fetchOptions: { credentials: 'same-origin' }
});

function shouldPersist(state) {
  return persistUserStateToServer && selectIsPermanentLoggedIn(state.auth);
}

const toPersist = [
  ['alertsSettings', 'settings'],
  ['dashboard', 'tblType'],
  ['dashboard', 'dismissdedWarningDialogs'],
  ['dashboard', 'holdingsCols'],
  ['performance', 'state'],
  ['ui', 'benchmark'],
  ['ui', 'period'],
  ['ui', 'performanceDisplayTab'],
  ['dashboard', 'assetAllocationType'],
  ['dashboard', 'mraketOverviewDuration'],
  ['dashboard', 'overviewPortfolioNewsTab'],
  ['dashboard', 'holdingsSortBy'],
  ['dashboard', 'isholdingsSortDescending'],
  ['dashboard', 'majorHoldingsType']
];

function selectPersistentState(state) {
  const persistentState = {};

  toPersist.forEach(path => {
    const val = get(state, path);
    if (val) {
      set(persistentState, path, val);
    }
  });
  return persistentState;
}

function mergePersistentState(state, persistentState) {
  toPersist.forEach(path => {
    const val = get(persistentState, path);
    if (val) {
      set(state, path, val);
    }
  });

  return state;
}

export default key => ({
  // this gets called when a user logs in as well, in which case we want to ignore the state in localStorage
  load(withLocal = true) {
    const getState = () => {
      if (!withLocal) return {};
      const stateFromStorage = localStorage.getItem(key);
      const state = stateFromStorage ? JSON.parse(stateFromStorage) : {};
      return state;
    };

    const state = getState();

    if (withLocal && !shouldPersist(state)) return Promise.resolve(state);

    if (persistUserStateToServer) {
      return persistToServer
        .load(state)
        .then(stateFromServer =>
          mergePersistentState(getState(), JSON.parse(stateFromServer))
        )
        .catch(() => state);
    }
    else {
      return Promise.resolve(state);
    }
  },
  /**
   * The actual persistence to localStorage function used by the redux-storage.
   * It saves the entire state on each ACTION, though there is a debounce that makes
   * sure it'll only get called at the end of a long series of ACTIONs.
   */
  save(state: ReduxProps) {
    const jsonState = JSON.stringify(state);
    localStorage.setItem(key, jsonState);

    if (shouldPersist(state)) {
      return persistToServer
        .save(selectPersistentState(state))
        .catch(e => console.error(e));
    }
    return Promise.resolve();
  }
});
