import reporter from '../analytics';

const events = {
  clickedAverage: 'clicked-crowd-insights-average-portfolio-radio-button',
  clickedBest: 'clicked-crowd-insights-best-portfolio-radio-button'
};

const report = reporter(events);

export default report;
