import * as classNames from 'classnames';
import * as React from 'react';
// styles
import * as tableStyles from '../../tr-dashboard-user-performance-container/table.istyl';
import * as styles from './MyPerfBox.istyl';
//more
import {
  PortfolioPerformanceBoxContainer,
  ComponentData as PerfBoxData,
} from 'sp/browser/performance/portfolio-performance-box/container';
import {
  AllocationBoxContainer,
  ComponentData as AlocBoxData,
} from 'sp/browser/performance/allocation-box/container';
import {
  VolatilityBoxContainer,
  ComponentData as VolaBoxData,
} from 'sp/browser/performance/volatility-box/container';
import {
  MyPerfAllocationNoData,
  MyPerfVolatilityNoData,
  MyPerfAllocationNoDataETFOrFund,
} from 'sp/browser/performance/MyPerfBox/utils';
import { InfoTooltip } from 'sp/browser/InfoTooltip';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export interface Props {
  mediaQuery: MediaQuery;
  portfolioPerformanceBox?: PerfBoxData;
  allocationBox?: AlocBoxData;
  volatilityBox?: VolaBoxData;
}

export enum MyPerfPage {
  Performance = 'Performance',
  Allocation = 'Allocation',
  Volatility = 'Volatility',
}

const devRequestedPage =
  process.env.NOT_PROD &&
  (() => {
    const parts = window.location.search.slice(1).split('&');
    for (let i = 0; i < parts.length; i++) {
      const [param, value] = parts[i].split('=');
      if (param === 'page') return MyPerfPage[value];
    }
  })();

export class MyPerfBox extends React.PureComponent<Props, { currPage: MyPerfPage; }> {
  // remember position because we don't have URLs for the boxes,
  // and the position 'resets' when changing orientation on iPad.
  rememberPosition(currPage: MyPerfPage) {
    this.setState({ currPage });
    sessionStorage.setItem(this.sessionKey, currPage);
  }
  sessionKey = 'performanceBoxCurrentPage';
  state = { currPage: devRequestedPage || MyPerfPage.Performance };

  componentDidMount() {
    const currPage = sessionStorage.getItem(this.sessionKey);
    if (currPage) this.rememberPosition(MyPerfPage[currPage]);
  }
  render() {
    const { mediaQuery } = this.props;
    return (
      <section
        className={classNames(
          'portfolioPerformanceBox',
          styles.myPerfBox,
          tableStyles.pageSection,
        )}
      >
        {mediaQuery.get('768') ? this.renderDesktop() : this.renderMobile()}
      </section>
    );
  }
  renderMobile() {
    const { mediaQuery } = this.props;
    return (
      <section className={classNames('')}>
        {this.renderHeader('Portfolio Analysis')}
        <main
          className={classNames(
            'section',
            tableStyles.pageSectionMainCoverage,
            'shadow-box',
          )}
        >
          <header className={classNames(styles.header, 'header')}>
            Allocation
          </header>
          {this.getPage(MyPerfPage.Allocation)}
        </main>
        {this.renderHeader('Portfolio Performance')}
        <main
          className={classNames(
            'section',
            tableStyles.pageSectionMainCoverage,
            'shadow-box',
          )}
        >
          <header className={classNames(styles.header, 'header')}>
            Performance Per Month
          </header>
          {this.getPage(MyPerfPage.Performance)}
        </main>
        <main
          className={classNames(
            'section',
            tableStyles.pageSectionMainCoverage,
            'shadow-box',
          )}
        >
          <header className={classNames(styles.header, 'header')}>
            Volatility
          </header>
          {this.getPage(MyPerfPage.Volatility)}
        </main>
      </section>
    );
  }
  renderDesktop() {
    const { currPage } = this.state;
    return (
      <div>
        {this.renderHeader('Portfolio Analysis')}
        <main
          className={classNames(
            'section',
            tableStyles.pageSectionMainCoverage,
            'shadow-box',
          )}
        >
          <header className={classNames(styles.header, 'header')}>
            {this.renderCheckbox(MyPerfPage.Allocation)}
            {this.renderCheckbox(MyPerfPage.Performance)}
            {this.renderCheckbox(MyPerfPage.Volatility)}
            {currPage === MyPerfPage.Performance && (
              <InfoTooltip
                className={styles.portfolioAnalysisTooltip}
                msg="Performance bar chart shows your monthly performance. You are able to compare your performance to the benchmarks found in the drop down menus."
              />
            )}
          </header>
          {this.getPage()}
        </main>
      </div>
    );
  }
  getPage(page: MyPerfPage = this.state.currPage) {
    const {
      mediaQuery,
      portfolioPerformanceBox,
      allocationBox,
      volatilityBox,
    } = this.props;

    switch (page) {
      case MyPerfPage.Performance: return (
        <PortfolioPerformanceBoxContainer
          mediaQuery={mediaQuery}
          componentData={portfolioPerformanceBox}
        />
      );
      case MyPerfPage.Allocation: return (
        <AllocationBoxContainer
          mediaQuery={mediaQuery}
          noDataETFOrFundPortfolio={<MyPerfAllocationNoDataETFOrFund />}
          noDataRegular={<MyPerfAllocationNoData />}
          componentData={allocationBox}
        />
      );
      case MyPerfPage.Volatility: return (
        <VolatilityBoxContainer
          mediaQuery={mediaQuery}
          componentData={volatilityBox}
        >
          <MyPerfVolatilityNoData />
          {/* iframe style no data message */}
        </VolatilityBoxContainer>
      );
    };
  }

  renderCheckbox = (type: MyPerfPage) => {
    const { currPage } = this.state;
    const typeStr = MyPerfPage[type];

    return (
      <span
        className={styles.checkboxWrapper}
        onClick={() => this.rememberPosition(type)}
      >
        <input
          type="checkbox"
          name="myperf"
          id={typeStr}
          checked={currPage === type}
          onChange={() => null /*to calm react from yacking about this*/}
        />
        <label htmlFor={typeStr}>{typeStr}</label>
      </span>
    );
  };
  renderHeader = (text) =>
    <div className={tableStyles.titleHolder}>
      <header className={'pageSectionHeaderCoverage'}>
        <h1 className={'pageSectionH1 pageSectionH1StockInfo'}>
          {text}
        </h1>
      </header>
    </div>
}
