import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import Component from 'react-pure-render/component';

export default class TrThankYou extends Component {
  render() {
    return (
      <div className="tr-thank-you">
        <div className="nasdaq-premium-page">
          <div className="header-area clearfix">
            <div className="logo-content floatlef">
              <a href="#">
                <img
                  className={classNames(
                    'logo-img',
                    'only-desktop',
                    'only-tab-large',
                    'only-tab-small'
                  )}
                  src="images/nasdaq-logo.png"
                  alt="Nasdaq Logo"
                />
                <img
                  className="logo-img only-phone-large only-phone-small"
                  src="images/nasdaq-logo-phone.png"
                  alt="Nasdaq Logo"
                />
              </a>
            </div>
            <div
              className={classNames(
                'close-button',
                'only-tab-small',
                'only-phone-large',
                'only-phone-small'
              )}
            >
              <button className="close-btn">
                <img src="images/close-button.png" alt="Close" />
              </button>
            </div>
          </div>
          <div className="promo-area">
            <h1
              className={classNames(
                'promo-title',
                'only-desktop',
                'only-tab-large',
                'only-tab-small'
              )}
            >
              Welcome to&nbsp;
              <span className="span">Smart Portfolio Pro</span>
            </h1>
            <h1 className="promo-title only-phone-large only-phone-small">
              Welcome to&nbsp;
              <br />
              <span className="span">Smart Portfolio Pro</span>
            </h1>
            <p className="promo-subtitle only-desktop only-tab-large">
              Our technology has helped thousands of investors to increase their
              returns
              <br />
              by knowing who to trust when it comes to financial advice.
            </p>
            <p className="promo-subtitle only-tab-small">
              Our technology has helped thousands of investors to increase their
              returns
              <br />
              by knowing who to trust when it comes to financial advice.
            </p>
            <div className="promo-container container">
              <div className="promo-content">
                <h4
                  className={classNames(
                    'feature-title',
                    'only-desktop',
                    'only-tab-large',
                    'only-tab-small'
                  )}
                >
                  Make the most of your Smart Portfolio:
                </h4>
                <h4
                  className={classNames(
                    'feature-title',
                    'only-phone-large',
                    'only-phone-small'
                  )}
                >
                  Make the most of your Smart Portfolio:
                </h4>
                <ul className="promo-list">
                  <li>Manage your holdings with multiple portfolios</li>
                  <li>See how you perform compared to other investors</li>
                  <li>Reduce your Mutual Fund & ETF management fees</li>
                  <li>Receive real-time expert notifications on your stocks</li>
                  <li>Discover what top experts say about your holdings</li>
                </ul>
                <p className="launch-link">
                  <Link to="/smart-portfolio/holdings">
                    Launch Smart Portfolio &gt;
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
