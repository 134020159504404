import storage from 'redux-storage';
import { combineReducers } from 'redux';

// Note we are composing all reducers. Web, native, whatever. Of course we can
// pass platform specific reducers in configureStore, but there is no reason to
// do that, until app is really large.
import ui from '../ui/reducer';
import auth from '../auth/reducer.ts';
import intl from '../intl/reducer';
import users from '../users/reducer';
import device from '../device/reducer';
import dashboard from '../dashboard/reducer.ts';
import { reducer as toasts } from '../../browser/Toasts';
import { reducer as dialogs } from '../../browser/dialogs';
import { reducer as tooltips } from '../../browser/Tooltips';
import { reducer as alertsSettings } from '../../browser/tr-dashboard-alerts-settings';
import portfolioCalendar from '../../browser/PortfolioCalendar/reducer';
import myExperts from '../../browser/tr-dashboard-experts/reducer';
import autocomplete from '../../browser/autocomplete/reducer';
import mobileContainer from '../../browser/mobile-container/reducer';
import contact from '../../browser/contact/reducer';
import dictionary from '../../browser/dictionary/reducer';
import stockScreener from '../../browser/tr-stock-screener-container/reducer';
import etfScreener from '../../browser/tr-etf-screener-container/reducer';
import { reducer as importedUser } from '../../browser/tr-dashboard-welcome-dev/actions';
import { reducer as performance } from '../components/performance/reducer';
import { reducer as crowdInsights } from '../../browser/tr-dashboard-avg-portfolio/reducer.ts';
import notifications from '../../browser/notifications/reducer.ts';
import publicPortfolioz from '../../browser/tr-dashboard-user-performance-container/public.reducer.ts';

import { selectNumHoldings } from '../../browser/dashboard/selectors';

const reducer = combineReducers({
  auth,
  device,
  intl,
  ui,
  users,
  dashboard,
  dialogs,
  toasts,
  tooltips,
  alertsSettings,
  portfolioCalendar,
  myExperts,
  autocomplete,
  mobileContainer,
  contact,
  dictionary,
  importedUser,
  stockScreener,
  etfScreener,
  performance,
  crowdInsights,
  notifications,
  publicPortfolioz,
});

const appReducer = storage.reducer((state, action) => {
  state = reducer(state, action);

  if (state.auth.getIn(['users', 'viewer', 'loggedInUser'])) {
    if (selectNumHoldings(state.dashboard) === 0) {
      state.auth = state.auth.setIn(
        ['users', 'viewer', 'loggedInUser', 'hasHoldings'],
        false,
      );
    } else {
      state.auth = state.auth.setIn(
        ['users', 'viewer', 'loggedInUser', 'hasHoldings'],
        true,
      );
    }
  }

  return state;
});

export default appReducer;
