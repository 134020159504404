import * as React from 'react';

const listeners = new Set<(e: any) => void>();

const hasWindow = typeof window !== 'undefined';

if (hasWindow) {
  window.addEventListener('click', e => listeners.forEach(fn => fn(e)));
}

type OutsideClickWrapperProps = {
  listener: React.EventHandler<any>;
  wrapperElement?: string | React.ComponentClass<any>;
} & React.HTMLProps<HTMLElement>;

export default class OutsideClickWrapper extends React.PureComponent<
  OutsideClickWrapperProps,
  {}
> {
  __fn__: OutsideClickWrapperProps['listener'];

  static defaultProps: Partial<OutsideClickWrapperProps> = {
    wrapperElement: 'div',
    listener: () => null
  };

  listen = () => {
    if (this.__fn__) listeners.add(this.__fn__);
  };

  unlisten = () => {
    listeners.delete(this.__fn__);
  };
  componentDidMount() {
    this.__fn__ = this.props.listener;
    this.listen();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  __onWrapperClick = e => {
    this.unlisten();
    setTimeout(this.listen, 50);
  };

  render({ wrapperElement, listener, ...rest } = this.props) {
    const El = wrapperElement || 'div';

    return <El onClick={this.__onWrapperClick} {...rest} />;
  }
}
