// guardAgainstMaxiumQuota
var setItem = localStorage.setItem;

Storage.prototype.setItem = function (key, value, firstAttempt) {
  firstAttempt = typeof firstAttempt === 'undefined' ? true : firstAttempt;
  try {
    setItem.call(this, key, value);
  } catch (err) {
    if (err.name === "QuotaExceededError") {
      this.clear();
      if (firstAttempt) {
        this.setItem(key, value, false);
      } else {
        // console.info('localStorage.setItem second attempt error:',err);
      }
    } else {
      throw err;
    }
  }
}

if (typeof window.console === 'undefined') {
  window.console = {
    log: function () { },
    error: function () { },
    info: function () { },
    warn: function () { }
  };
}

function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length == 2) {
    return parts.pop().split(';').shift();
  }
}

try {
  var isFirstTime = !localStorage.getItem('isFirstTime');
  var welcomeinfo = getCookie('welcomeinfo');
  if (isFirstTime && welcomeinfo) {
    localStorage.setItem('isFirstTime', true);
    location.href = '/user-logged-in';
  }
} catch (e) {
}