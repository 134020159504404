import * as actions from './actions';
import { Record, Map } from 'immutable';
import { period, benchmark, performanceDisplayTab } from '../enums';
import { trDropZoneStatus as uploadStatus } from '../../browser/tr-dropzone/types';
import { BetterRecord } from 'sp/common/immutableStuff';

const InitialState = Record({
  isSideMenuOpen: false,
  period: period.yearly,
  benchmark: benchmark.none,
  performanceDisplayTab: performanceDisplayTab.stockPicker,
  userProfile: Map({
    state: uploadStatus.initial,
    error: null,
    hasError: false,
    picture: null,
  }),
});

const initialState = new InitialState();

export default function uiReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.ON_SIDE_MENU_CHANGE: {
      const { isOpen } = action.payload;
      return state.set('isSideMenuOpen', isOpen);
    }

    case actions.TOGGLE_SIDE_MENU:
      return state.update('isSideMenuOpen', isSideMenuOpen => !isSideMenuOpen);

    case actions.SET_UI_PROPERTY: {
      const { key, value } = action.payload;
      return state.set(key, value);
    }

    case actions.TOGGLE_PHOTO_UPLOAD_STATE: {
      return state.set('userProfile', Map(action.payload.data));
    }
  }

  return state;
}
