export default {
  analystInfo: {
    jobTitle: 'Sell Side Analyst',
    analystInfo: 'Wall Street Analyst at {firm}',
    bloggerInfo: 'Financial Blogger at {firm}',
    ranking: 'Ranked #{rank} out of {totalAnalysts} Analysts',
    rankingBlogger: 'Ranked #{rank} out of {totalAnalysts} Bloggers',
    sector: 'Main Sector',
    rankingOverall: '(#{rankOverall} of {totalExperts} overall experts)',
    successRateLabel: 'Success Rate',
    successRateDesc:
      '{ratingsGoodTotal} out of {ratingsTotal} successful ratings',
    avgReturnLabel: 'Average Return',
    avgReturnDesc: 'Average return per recommendation',
    followedBy: 'Followed by {followingTotal} investors',
    'subscriptionButton.subscribed': 'Unfollow',
    'subscriptionButton.unsubscribed': 'Follow',
    calcDesc: {
      desc: `If you copied {analystName} recommendations since {firstRatingYear} and opened each position for the duration of {period}, then {successRate} of your transactions would have been profitable with an average return of {returnAvg} over
            {benchmarkVal, select,
                none {}
                other {the}
            } {benchmark}.`
    },
    tooltipSuccessRate:
      'How often the expert’s recommendations were profitable.',
    tooltipAvgReturn:
      'The average profit one would make by following the buy and sell ratings by this financial expert since Jan 2009. Hold ratings are used to close a position.'
  },
  analystStockCoverage: {
    title: 'Recent Activity',
    unlock: 'Unlock to see all ratings',
    unlockBtn: 'Unlock Now',
    'stockCols.company': 'Company',
    'stockCols.price': 'Price',
    'stockCols.lastTransaction': 'Last transaction',
    'stockCols.totalTransactions': 'No. of transactions',
    'stockCols.percentOfPortfolio': '% of Portfolio',
    'stockCols.status': 'Status',
    'stockCols.gain': 'Gain',
    'stockCols.gainSinceAdded': 'Gain Since Added',
    stockTotalRating: 'Ratings',
    seeAllRatingsBtn: 'See All Ratings',
    seeAllRatingsBtnActive: 'Minimize',
    'target.positive': 'Upside',
    'target.negative': 'Downside',
    seeAllRatingsBtnTfoot: 'Show All Ratings',
    successRateLbl: 'Success Rate on stock',
    avgReturnLbl: 'Average Profit on stock',
    relativeDate: {
      today: 'Today',
      yesterday: 'Yesterday'
    },
    actions: {
      initiatedcoverage: 'Initiated Coverage',
      resumedcoverage: 'Resumed Coverage',
      upgraded: 'Upgraded',
      downgraded: 'Downgraded',
      maintained: 'Reiterated',
      reiterated: 'Reiterated',
      hasstatus: 'Has Status',
      rated: 'Assigned', // 'Rated', TIPRANKS-12454
      last: 'Last'
    },
    calcDesc: {
      desc: `If you copied {analystName} {ticker} ratings since {firstRatingYear} and opened each position for the duration of {period}, then {successRate} of your transactions would have been profitable with an average return of {returnAvg} over
            {benchmarkVal, select,
                none {}
                other {the}
            } {benchmark}.`
    }
  }
};

export const __hotReload = true;
