import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Event } from '../../../common/types';

export default class DateCell extends React.PureComponent<
  {
    event: Event;
  },
  {}
  > {
  render() {
    const { event } = this.props;
    const date = event.get('date');
    return (
      <FormattedDate day="numeric" month="short" value={date} year="numeric" />
    );
  }
}
