import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { ApiDecorator } from '../ApiDecorator';
import PortfolioEvents from '../tr-dashboard-portfolio-events';
import { getState, maybefetch } from '../../common/api-actions';

import {
  selectActiveTickersLength,
  selectPortfolioEventsFuture
} from '../dashboard/selectors';

@ApiDecorator('eventsByTickers')
export default class PortfolioEventsContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object
  };

  openAddStockDialog = () => {
    const { actions } = this.props;
    actions.openDialog('addStock');
  };

  render() {
    const { dashboard, mediaQuery, msg } = this.props;

    const noData = selectActiveTickersLength(dashboard) === 0;

    return (
      <PortfolioEvents
        dependencies={[
          getState('eventsByTickers', this.props),
          getState('portfolioItems', this.props)
        ]}
        events={selectPortfolioEventsFuture(dashboard)}
        isCombinedCells={true}
        max={8}
        mediaQuery={mediaQuery}
        msg={msg.dashboard.portfolioEvents}
        noData={noData}
        noDataMsg={msg.dashboard.holdingsNodata}
        openAddStockDialog={this.openAddStockDialog}
      />
    );
  }
}
