import { PlaidFlowConfig, TipRanksPlaidCB, FlowType, InitActionName, PlaidEvents } from './PlaidTypes';
import { verifyInitParameters } from './PlaidInputValidators';
import { onPlaidAuthSuccess, removeOAuthStateIdFromUrl } from './onPlaidAuthSuccess';
import { openPlaidAuthPopUp } from './openPlaidAuthPopUp';
import { BASE_URL } from './BASE_URL';
import { setPlaidTokenToStorage, getPlaidTokenFromStorage, clearPlaidTokenFromStorage } from './TokenStorageManager';
import { responseToGeneralErrorPlaid } from './responseToGeneralErrorPlaid';
import { getGlobalActions } from "sp/browser/lib/actions";

export async function initPlaidAuthFlow(config: PlaidFlowConfig, trEvents: TipRanksPlaidCB = {}) {
  if (!verifyInitParameters(config, trEvents)) { return; }
  getGlobalActions().openDialog('plaidSyncLoader');
  const { type, expertOrPortfolioId } = config;
  const actionNameMap: { [key in FlowType]: InitActionName } = {
    create: 'GetLinkToken',
    update: 'GetLinkTokenForUpdate',
  }
  const REDIRECT_URL = `${window.location.protocol}//${window.location.host}/smart-portfolio/welcome`;

  const url 
    = `${BASE_URL}/api/${actionNameMap[type]}/${expertOrPortfolioId}?redirecturl=${REDIRECT_URL}`;

  const events: PlaidEvents = {
    onSuccess: (public_token: string) => onPlaidAuthSuccess(
      {
        public_token,
        expertOrPortfolioId,
        type,
      }, 
      trEvents,
    ),
    onExit: (err, metaData) => {
      responseToGeneralErrorPlaid();
      if (typeof trEvents.onSyncStopped === 'function') {
        trEvents.onSyncStopped(err, metaData);
      }
    },
  };
  const tokenFromStorage = getPlaidTokenFromStorage();
  if (tokenFromStorage) {
    openPlaidAuthPopUp(tokenFromStorage, events, trEvents.setWasPlaidModalOpened, window.location.href);
    return
  }

  await fetch(url, {
    method: 'GET',
  })
    .then((res) => res.json())
    .catch((err) => {
      responseToGeneralErrorPlaid();
      if (typeof trEvents.onSyncFailed === 'function') {
        trEvents.onSyncFailed(err);
      }
      clearPlaidTokenFromStorage();
    })
    .then((userPrivateToken) => {
      setPlaidTokenToStorage(userPrivateToken);
      openPlaidAuthPopUp(userPrivateToken, events, trEvents.setWasPlaidModalOpened);
      return null; // For TypeScript
    }
  );
}