import { List } from 'immutable';
import { createSelector } from 'reselect';
import { get } from 'lodash';

const sortBy = fn => {
  // if it's a string, assume it's a key and sort by it.
  const sorter = typeof fn === 'function' ? fn : x => x[fn];
  return sorter;
};

const typeOrder = ['analyst', 'blogger', 'insider', 'hedgefund'];

export const sortFns = {
  expert: sortBy('name'),
  expertType: sortBy(x => typeOrder.indexOf(x.type)),
  sector: sortBy(
    x =>
      x.getIn(['firm', 'sector']) === 'unknown'
        ? '000'
        : x.getIn(['firm', 'sector'])
  ),
  averageReturn: sortBy('returnAvg'),
  successRate: sortBy(x => x.successRate || 0),
  alerts: sortBy(x => get(x.alerts, 'ops.size', get(x, 'alerts.size', 0)))
};

const selectColumns = createSelector(
  myExperts => myExperts,
  ({ experts, sortBy, desc }) => {
    const sorted = experts.sortBy(sortFns[sortBy]);
    return desc ? sorted.reverse() : sorted;
  }
);

export const selectExpertLists = createSelector(
  selectColumns,
  (_, isMobile) => isMobile,
  (experts, isMobile) => {
    return isMobile
      ? experts.groupBy(x => x.type).sortBy((v, k) => k)
      : List([experts]);
  }
);
