import reporter from '../analytics';

const events = {
  clickedLogin: 'clicked-header-login-btn',
  clickedRegister: `clicked-header-signup-btn`
};

const report = reporter(events);

export default report;
