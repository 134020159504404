import {
  AssetType,
  Dashboard,
  PortfolioItemRecord,
} from '../../../common/types';
import '../../tr-dashboard-user-performance-container/index.styl';

import * as React from 'react';
import { AllocationBox, ComponentData, CompanyAllocationItem } from 'sp/browser/performance/allocation-box';
import { calculateScaleRatio } from 'sp/browser/tr-dashboard-avg-portfolio/utils';
import { calculateAnalysis } from 'sp/browser/tr-dashboard-avg-portfolio-container/utils';
import {
  selectoPortfolioAnalysis,
  selectWeightedHoldings,
} from 'sp/browser/dashboard/routes/analysis/portfolio/selectors';
import { connect } from 'react-redux';
import { sumBy, set, get, update } from 'lodash';
import { fromJS } from 'immutable';
import { List } from 'immutable';
import {
  AllocationItem,
} from 'sp/browser/AllocationGraph/AllocationGraph';
import * as d3 from 'd3';
import { getSectorText } from 'sp/common/lib/utils';
import { selectAllMajorPortfolioStocks } from 'sp/browser/dashboard/selectors';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export { ComponentData };

export interface Props /* extends React.HTMLAttributes<HTMLDivElement> */ {
  mediaQuery: MediaQuery;
  noDataRegular: React.ReactNode;
  noDataETFOrFundPortfolio: React.ReactNode;
  componentData?: ComponentData;
}
export type PropsWithConnect = Props & {
  dashboard: Dashboard;
};

@connect(({ dashboard }) => ({ dashboard }))
export class AllocationBoxContainer extends React.PureComponent<Props, {}> {
  render() {
    const {
      mediaQuery,
      dashboard,
      noDataETFOrFundPortfolio,
      noDataRegular,
    } = this.props as PropsWithConnect;
    const { componentData = getData(dashboard) } = this.props;
    const children = componentData.isETFOrFundPortfolio
      ? noDataETFOrFundPortfolio
      : noDataRegular;

    return (
      <AllocationBox
        mediaQuery={mediaQuery}
        {...componentData}
      >
        {children}
      </AllocationBox>
    );
  }
}


export function getData(dashboard: Dashboard): ComponentData {
  const portfolioAnalysis = selectoPortfolioAnalysis(dashboard);

  // ASSET ALLOCATION
  const _assetAllocation = portfolioAnalysis.chartData.stockAllocation;
  const equityPercent = _assetAllocation
    .find(a => a.get('id') === 'stock')
    .get('percent');
  const etfsPercent = _assetAllocation
    .find(a => a.get('id') === 'etf')
    .get('percent');
  const mutualfundsPercent = _assetAllocation
    .find(a => a.get('id') === 'fund')
    .get('percent');
  const cashPercent = _assetAllocation
    .find(a => a.get('id') === 'cash')
    .get('percent');
  const assetAllocation = [
    { id: AssetType.Equity, percent: Math.abs(equityPercent) },
    { id: AssetType.ETFs, percent: Math.abs(etfsPercent) },
    { id: AssetType.MutualFunds, percent: Math.abs(mutualfundsPercent) },
    { id: AssetType.Cash, percent: Math.abs(cashPercent) },
  ].sort((a, b) => (a.percent < b.percent ? 1 : -1));

  // SECTOR ALLOCATION
  // use old code to make this work.
  // TODO the same result can be achieved by looking at portfolioAnalysis.holdingsChartData.active :(
  const weighted = selectWeightedHoldings(dashboard);
  let bySector = weighted.byTypeAndSector().stock || [];
  // TODO why do some stock get '' as their sector??
  // --- MANUALLY ADJUST DATA --- //
  if (bySector['']) {
    bySector.general = bySector[''];
    delete bySector[''];
  }
  const sectorAllocation = Object.keys(bySector).map(x => ({
    id: getSectorText(x)!,
    percent: sumBy(bySector[x], ({ typePercent }) => typePercent),
  }));
  const mappedSectorAllocation = adjustPercentageTo100(sectorAllocation);

  // COMPANY ALLOCATION
  const majorHoldings_I = selectAllMajorPortfolioStocks(dashboard);
  const companyAllocation: CompanyAllocationItem[] = majorHoldings_I
    .map(mh => ({
      id: mh.get('stock').get('name'),
      percent: mh.get('percentPortfolio'),
      ticker: mh.get('ticker'),
      type: mh.get('stock').get('type'),
    }))
    .toJS()
    .sort((a, b) => (a.percent < b.percent ? 1 : -1));
  const mappedCompanyAllocation = adjustPercentageTo100(companyAllocation);

  const holdingByType = {
    stock: [],
    etf: [],
    fund: [],
    ...selectWeightedHoldings(dashboard).byType(),
  };
  const isETFOrFundPortfolio =
    holdingByType.stock.length === 0 &&
    (holdingByType.etf.length > 0 || holdingByType.fund.length > 0);

  return {
    assetAllocation: assetAllocation,
    sectorAllocation: mappedSectorAllocation,
    companyAllocation: mappedCompanyAllocation,
    isETFOrFundPortfolio,
  };
}

/**
 * Take an array of items that their percent doesn't add up to 100%,
 * and make it so that it'll add up to 100% :)
 * MOTHERFUCKING LEET HACK MODE. not really :(
 * Use when you don't know why it doesn't add up (or rounding issues), and "there's no time" to find out,
 * Because "we'd prefer the shortest path" to the feature.
 * @param items Item[] that has a percent of number.
 */
export function adjustPercentageTo100<T extends { percent: number }>(items: T[]): T[] {
  const total = items.reduce((prev, curr) => prev + curr.percent, 0);
  const mapper = d3.scale
    .linear()
    .domain([0, total])
    .range([0, 1]);
  return items
    .map(item => update(item, 'percent', mapper))
    .sort((a, b) => (a.percent < b.percent ? 1 : -1));
}