import { createNasdaqLink } from 'sp/browser/lib/utils';
import * as cookies from 'js-cookie';
import * as moment from 'moment';
import { isShittyBrowser } from 'sp/browser/lib/utils';

// TODO bring this back if needed
// import localStorage from '../../browser/components/personsList/localstorage';

import {
  Plan,
  ReduxProps,
  StockType,
  AssetTypeEnum,
  AssetType,
  StockTypeEnum,
} from '../types';
import { selectActivePortfolio } from '../../browser/dashboard/selectors';
import { getGlobalActions } from '../../browser/lib/actions';
import { Iterable } from 'immutable';
import { flatten, chain, isNumber, isString } from 'lodash';
import { SectorEnum } from 'sp/browser/news-analysis/types';
import { registerRoutePath, loginRoutePath } from 'sp/common/config';
export const communityNasdaqUrl = location.host.includes('qc')
  ? 'https://community-qc.nasdaq.com'
  : 'https://community.nasdaq.com';

// TODO why is this here
declare global {
  interface Screen {
    top: number;
    left: number;
  }

  interface Array<T> {
    includes(item: T): boolean;
  }

  interface Window {
    preventNavConfirm: boolean;
    useWelcomeInfo: boolean;
    cookie: typeof cookies;
    __state__: ReduxProps;
  }
}
// TODO Move this to some nasdaq specific place
declare const communityNasdaqPopup: Window;

// TODO document use of this shit
const imageOptions = {
  logo: 'logo.png',
  menu: 'menu.png',
  loader: 'loader.gif',
  loaderStatic: 'loader.png',
  error: 'error.png',
  noData: 'nodata-icon.png',
  warning: 'warning.png',
  'routeNavCloser-web': 'routeNavCloser-web.png',
  'routeNavCloser-nasdaq': 'routeNavCloser-nasdaq.png',
  bell: 'bell.png',
  infoTooltip: 'info-tooltip.png',
  activeBell: 'bell-active.png',
  bellSmaller: 'bell-smaller.png',
  activeBellSmaller: 'bell-active-smaller.png',
  delete: 'delete.png',
  tipranksDashboard: 'tipranks-dashboard.png',
  'analyzing-web': 'analyzing-web.gif',
  'analyzing-nasdaq': 'analyzing-nasdaq.gif',
  edit: 'edit.png',
  editBigger: 'edit-bigger.png',
  screen: 'screen.png',
  alertsSettingsUpdated: 'alertsSettingsUpdated.png',
  importLogos: 'import-logos.png',
  smardDashboard: 'smard-dashboard.png',
};

export type ImageOptions = keyof typeof imageOptions;

const path = {
  img: (name: ImageOptions) => imageOptions[name] || `${name}.png`,
};

export const getParameterByName = iName => {
  const name = iName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const imgSrc = (name: ImageOptions) => `/assets/img/${path.img(name)}`;
export const absImgSrc = (name: string) => `/assets/img/${name}`;

const percentFrom = total => 100 / total;

export const equalWidth = items => ({
  width: `${Math.floor(percentFrom(items.size))}%`,
});

export const equalWidthAndMargin = (arrSize, index, margin) => {
  const marginTotal = (arrSize - 1) * margin;
  const originalWidth = percentFrom(arrSize);
  const isLast = arrSize - 1 === index;
  return {
    marginRight: isLast ? 0 : `${margin}%`,
    width: `${originalWidth - marginTotal / arrSize}%`,
  };
};

export const colors = (name, theme = 'web') =>
  ({
    tipRanksAccent1: { web: '#f2990d' },
    colorAccent1: { web: '#ec8408', nasdaq: '#009ec2' },
  }[name][theme]);

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !(typeof MSStream !== 'undefined');

export const shouldIgnoreApi = <T>(
  ignoreApis: Iterable<any, string> = [] as any,
  name: string | string[],
  params: T[] = [],
) => ignoreApis.includes(`${name}.${params.join('.')}`);

export const bindFns = function (fns) {
  fns.forEach(fn => (this[fn] = this[fn].bind(this)));
};


export const setLastLockedFeature = featre =>
  localStorage.setItem('lastLockedFeature', featre);

const adaptKey = (key, adpter) =>
  adpter.hasOwnProperty(key) ? adpter[key] : [null];

//TODO move this to an team-redux-old-utils-fuck-you-guys.ts file
export const adapt = (res, adapter, name) =>
  Object.keys(adapter).reduce(
    (args, key) => {
      const [resKey, keyADapter] = adaptKey(key, adapter);
      if (resKey.length > 0 && !{}.hasOwnProperty.call(res, resKey)) {
        if (name !== 'client' && process.env.NODE_ENV !== 'production') {
          //console.warn(`Expected ${resKey} key on ${name} response`);
        }
        return args;
      }
      if (
        (resKey !== null && resKey.length > 0 && res[resKey]) ||
        resKey.length === 0
      ) {
        args[key] =
          keyADapter && typeof keyADapter === 'function'
            ? keyADapter(res[resKey])
            : res[resKey];
      }
      return args;
    },
    { source: name },
  ) as any;

export function getScreenWidth() {
  return window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
}
export function getScreenHeight() {
  return window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
}

// TODO move to nasdaq specific utils
export function openPopup(strUrl, width, height, strWindowName = 'TRWin') {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;

  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  const screenWidth = getScreenWidth();
  const screenHeight = getScreenHeight();
  const left = screenWidth / 2 - width / 2 + dualScreenLeft;
  const top = screenHeight / 2 - height / 2 + dualScreenTop;

  const windowFeatures = {
    dialog: 'yes',
    menubar: 'noe',
    modal: 'yes',
    resizable: 'no',
    scrollbars: 'no',
    status: 'no',
    toolbar: 'no',
    height,
    left,
    top,
    width,
  };

  const strWindowFeatures = Object.keys(windowFeatures)
    .map(key => `${key}=${windowFeatures[key]}`)
    .join(',');

  return window.open(strUrl, strWindowName, strWindowFeatures);
}
// procude true/false whether the device has touch screen
export const getIsTouch = () =>
  'ontouchstart' in window || navigator.maxTouchPoints;

type RegisterUserType = 'imported' | 'manual' | null;

export const isOldNasdaqHostname = () => window.location.hostname.includes('stocktracker');

export const openExternalLogin = (newUserType: RegisterUserType = null) => {
  return window.location.assign(`https://new.nasdaq.com/user/login?redirect_to=${encodeURIComponent(window.location.href)}`);
  // Code below kept for brevity. As of 2019-07-02 all nasdaq login should redirect and not open window regardless.
  // const strWindowName = 'communityQcNasdaq';
  // const screenWidth = getScreenWidth();
  // const screenHeight = getScreenHeight();
  // const width = Math.min(1000, screenWidth);
  // const height = Math.min(820, screenHeight);
  // const strUrl = `${communityNasdaqUrl}/common/templates/mobile-login.aspx`; // :

  // const communityNasdaqPopup = openPopup(strUrl, width, height, strWindowName);

  // const close = () => {
  //   cookies.remove('__trlogin', { domain: '.nasdaq.com' });
  //   if (cookies.get('TemporaryUserKey')) {
  //     cookies.set('CameFromOnboarding', 'true');
  //   }
  //   window.preventNavConfirm = true;
  //   communityNasdaqPopup && communityNasdaqPopup.close();

  //   window.location.href = '/user-logged-in';
  // };

  // window.onmessage = e => {
  //   if (e.data === 'nasdaq:logged-in') {
  //     return close();
  //   } else {
  //     // log(`[${strWindowName}] unrecognized data in onmessage`, e.data)
  //   }
  // };

  // if (isShittyBrowser) {
  //   setInterval(() => {
  //     if (
  //       cookies.get('__trlogin') ||
  //       (window.useWelcomeInfo && cookies.get('welcomeinfo'))
  //     ) {
  //       close();
  //     }
  //   }, 1e3);
  // }
};

const shouldDisableURL = {
  web: (ticker: string) => ticker.toUpperCase().indexOf('LSE:') === 0,
  nasdaq: (ticker: string) => ticker.indexOf(':') !== -1,
};

// TODO remove this, use gettickerurl.
export const getNasdaqSymbolUrl = aTicker => {
  const ticker = aTicker.toLowerCase();
  if (process.env.NOT_PROD && !ticker)
    console.warn('No ticker has been supplied to getNasdaqSymbolUrl!');
  return shouldDisableURL.nasdaq(ticker)
    ? ''
    : createNasdaqLink(`symbol/${ticker}`);
};

export const getTickerUrl = ({
  ticker = '',
  type = '',
}: { ticker: string; type: StockType | StockTypeEnum | ''; }) => {
  if (isNumber(type)) {
    switch (type) {
      case StockTypeEnum.Stock: { type = 'stock' };
      // case StockTypeEnum.Index: { type = '' };
      // case StockTypeEnum.Foreign: { type = '' };
      // case StockTypeEnum.Inactive: { type = '' };
      case StockTypeEnum.Etf: { type = 'etf' };
      case StockTypeEnum.Fund: { type = 'fund' };
      // case StockTypeEnum.Bond: { type = '' };
      case StockTypeEnum.SecondaryTicker: { type = 'secondaryticker' };
      case StockTypeEnum.CanadianStock: { type = 'stock' };
      case StockTypeEnum.CanadianETF: { type = 'etf' };
      case StockTypeEnum.CanadianFund: { type = 'fund' };
      case StockTypeEnum.NonUSStock: { type = 'stock' };
      case StockTypeEnum.NonUSETF: { type = 'etf' };
      case StockTypeEnum.NonUSFund: { type = 'fund' };
      // case StockTypeEnum.Commodity: { type = '' };
      // case StockTypeEnum.Cryptocurrency: { type = '' };
    }
  }
  if (shouldDisableURL[process.env.THEME](ticker)) return '';
  if (process.env.THEME === 'nasdaq')
    return createNasdaqLink(`symbol/${ticker}`);

  if (process.env.THEME === 'web') {
    if (
      ticker.toLowerCase().includes('TSE:') &&
      (type === 'fund' || type === 'etf')
    )
      return '';

    switch (type) {
      case 'stock':
        return `/stocks/${ticker}`;
      case 'etf':
        return `/etf/${ticker}`;
      default:
        return '';
    }
  }
};

export enum SocialAuthPopupType {
  Register = 'register', SignupSave = 'signupSave',
}

/**
 * Brings the the "please register" dialog to the front.
 * type controls a param sent to /components/social-auth,
 * used to show different messages and images.
 */
export const popupRegister = (type = SocialAuthPopupType.Register) =>
  process.env.THEME === 'nasdaq'
    ? openExternalLogin()
    : getGlobalActions().openDialog('register', type);

export const popupLogin = () =>
  process.env.THEME === 'nasdaq'
    ? openExternalLogin()
    : getGlobalActions().openDialog('login');

// perhaps extract this into a general function if date is needed in other reporting tools
export const getFirstVisitTime = () => moment().format('DD/MM/YYYY');

export const getRoutes = routes => {
  const rawRouteParts = routes
    .map(x => x.name || x.path) // we just want how the URL looks
    .filter(route => route !== '/' && route !== 'smart-portfolio')

  const routeParts = chain(rawRouteParts)
    .map((part: string) => part.split('/'))
    .flatten()
    .map((part: string) => part.replace(/:/g, '_'))
    .value();
  const activeRoute = routeParts[0];
  const route = routeParts.join('.');

  return {
    routeParts: rawRouteParts,
    activeRoute,
    route,
  };
};

export const classify = name => name.replace(/\./g, '-');

window.cookie = cookies;

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const noop = (...args) => null;

export const toFixed = (n: number, toFixed: number) =>
  parseFloat(n.toFixed(toFixed));

/**
 * dont use this, use props.params instead
 */
export const getQuery = (url: string = window.location.href): { [key: string]: any } => url.includes('?')
  ? url.split('?')[1].split('&').reduce((hash, pair) => ({ ...hash, ...({ [pair.split('=')[0]]: pair.split('=')[1] }) }), {})
  : {};


// Note: these are all lowercase temporarly until we stop translating by hand
// TODO when using SectorText everywhere and by key translation is not required, switch to uppercase
export enum SectorText {
  general = 'General',
  technology = 'Technology',
  services = 'Services',
  consumergoods = 'Consumer Goods',
  healthcare = 'Healthcare',
  financial = 'Financial',
  industrialgoods = 'Industrial Goods',
  utilities = 'Utilities',
  basicmaterials = 'Basic Materials',
  materials = 'Basic Materials',
  unknown = 'Unknown',
  other = 'Other'
};
const sectorsShort = {
  [SectorText.industrialgoods]: 'I. Goods',
  [SectorText.healthcare]: 'Healthcare',
  [SectorText.basicmaterials]: 'B. Materials',
  [SectorText.consumergoods]: 'C. Goods',
};
/*
const msg = {
  sectors: {
    general: 'General',
    technology: 'Technology',
    services: 'Services',
    consumergoods: 'Consumer Goods',
    healthcare: 'Healthcare',
    financial: 'Financial',
    industrialgoods: 'Industrial Goods',
    utilities: 'Utilities',
    basicmaterials: 'Basic Materials',
    other: 'Other',
  },

};*/

// list of all SectorIds
export const anyToSectorEnum = (sectorId: any): SectorText | undefined => {
  if (isNumber(sectorId) || sectorId === 'allSectors') {
    switch (parseInt(sectorId, 10)) {
      case 17343: return SectorText.basicmaterials;
      case 18731: return SectorText.consumergoods;
      case 17348: return SectorText.industrialgoods;
      case 17349: return SectorText.technology;
      case 17350: return SectorText.services;
      case 17346: return SectorText.financial;
      case 17347: return SectorText.healthcare;
      case 17351: return SectorText.utilities;
      case 7277: return SectorText.general;
      case SectorEnum.General: return SectorText.general;
      case SectorEnum.Services: return SectorText.services;
      case SectorEnum.IndustrialGoods: return SectorText.industrialgoods;
      case SectorEnum.Utilities: return SectorText.utilities;
      case SectorEnum.Healthcare: return SectorText.healthcare;
      case SectorEnum.Materials: return SectorText.basicmaterials;
      case SectorEnum.Technology: return SectorText.technology;
      case SectorEnum.Financial: return SectorText.financial;
      case SectorEnum.ConsumerGoods: return SectorText.consumergoods;
      default: {
        if (process.env.IS_RUN_LOCAL) { console.warn(`Attempted converting ${sectorId} to a sector identifier.`); }
      };
    }
  } else if (isString(sectorId)) {
    const sectorText = sectorId.toLowerCase();
    const retValue = SectorText[sectorText];
    if (process.env.IS_RUN_LOCAL && !retValue) { console.warn(`Attempted converting ${sectorText} to a sector identifier.`); }
    return retValue;
  }
  if (process.env.IS_RUN_LOCAL) { console.warn(`Attempted converting ${sectorId} to a sector identifier.`); }
}

// TODO: The typing on this is shit.
// The fallback system here makes no sense. It should either always return a text, or throw an error when it does not.
export const getSectorText = (sectorId: any, opts: { shortText?: boolean, fallback?: SectorText | null } = { shortText: false, fallback: SectorText.other }) => {
  const sector = anyToSectorEnum(sectorId);
  if (!sector) return opts.fallback;
  if (opts.shortText) return (sectorsShort[sector] as SectorText | undefined) || sector;
  return sector;
}

// from tipranks-utils

export const createQueryString = function createQueryString(params) {
  var url = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

  if (!params) {
    return url;
  }
  var urlParts = url.split('?');
  var originalParams = urlParts[1] ? urlParts[1].split('&') : [];
  var newParams = Object.keys(params).map(function (k) {
    return k + '=' + params[k];
  }).concat(originalParams).join('&');
  return [urlParts[0], newParams].join('/?');
};

export const retry = (fn, retries = 3, defaultCancel = () => { }) => {
  let cancel = defaultCancel;
  var res = Promise.resolve().then(_ => {
    const result = fn();
    try {
      if (typeof result.cancel === "function") {
        cancel = result.cancel;
      }
    } catch (e) { /* if .cancel is a getter and it threw, meh. */ }
    return result;
  }).catch(err => {
    if (retries === 1) {
      console.error(err);
      throw err;
    }
    return retry(fn, retries - 1);
  });
  res.cancel = cancel;
  return res;
};

// --- new utils --- //

/**
 * The one toFixed method to rule them all.
 * TODO replace all usages of import { format } from './utils.js';
 *
 */
export function betterToFixed(numb: any): string {
  if (parseFloat(numb) < 0.01 && parseFloat(numb) > 0) return '<0.01';
  if (numb.toString().includes('.')) {
    const val = parseFloat(numb).toFixed(2);
    // this makes 3.00$ to 3$, but product says users recognize this, it's a convention, and we shouldn't do this.
    // one day this sentiment / convention may change.
    // if (/\.00$/.test(val)) return parseFloat(numb).toFixed(0);
    return val;
  } // check for fraction
  return numb;
}

export function toAssetType(assetTypeEnum: AssetTypeEnum) {
  switch (assetTypeEnum) {
    case AssetTypeEnum.Cash: return AssetType.Cash;
    case AssetTypeEnum.Equity: return AssetType.Equity;
    case AssetTypeEnum.ETF: return AssetType.ETFs;
    case AssetTypeEnum.MutualFund: return AssetType.MutualFunds;
  }
}

export function isOnAuthDialog() {
  return [loginRoutePath, registerRoutePath].some(path => window.location.href.includes(
    `smart-portfolio/${path}`
  ));
}