import * as React from 'react';
import { apiCalls } from 'sp/common/config';
import { selectPortfolioItems } from 'sp/browser/dashboard/selectors';
import { getReq } from 'sp/common/api';
import { GetRealTimeQuotes } from 'sp/browser/components/RealTimePrices/API_Details_GetRealTimeQuotes';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { getRealTimeQuotes } from 'sp/browser/components/RealTimePrices/api';
import * as moment from 'moment';
import { Dashboard } from 'sp/common/types';

type Interval = number;
let intervalId: Interval | null = null;
const interval = 5e3;

type RealTimePrice = {
  ticker: string,
  priceObj: {
    amount: number,
    changeAmount: number,
    changePercent: number,
  }
}
export type RealTimePrices = RealTimePrice[];

const cancelError = 'RealTimePrices operation cancelled.'

export const startRealTimePrices = function startRealTimePrices(dashboard: Dashboard, priceUpdatedCb: (data: RealTimePrices) => void) {

  // TODO remove @types/node
  let timeout: any = -1;

  async function run() {
    const tickers: string[] = selectPortfolioItems(window.__state__.dashboard).map(item => item.get('ticker')).toJS();
    if (!tickers.length) {
      timeout = setTimeout(run, interval);
      return;
    }
    const res = await fetchPrices(tickers)
    if (!res.isMarketOpen) {
      timeout = setTimeout(run, inARoundHour())
    } else {
      priceUpdatedCb(res.data);
      timeout = setTimeout(run, interval);
    }
  }

  run();

  return () => clearTimeout(timeout);
};

// TODO figure out why this guy stopped working
// @connect(mapStateToProps, mapDispatchToProps)
// export class UsesRealTimePrices extends React.PureComponent<ReduxProps> {
//   private unsubscribeHandle: () => void = () => null;
//   componentDidMount() {
//     this.unsubscribeHandle =
//       startRealTimePrices(this.props.actions.updatePricesInRealTime);
//   }
//   componentWillUnmount() { this.unsubscribeHandle(); }
// }

const defaultValue = {
  isMarketOpen: false,
  isAfterMarketTime: false,
  isPreMarketTime: false,
  isDefaultValue: true,
  data: [],
};
function fetchPrices(tickers: string[]): Promise<{
  isMarketOpen: boolean,
  isAfterMarketTime: boolean,
  isPreMarketTime: boolean,
  data: RealTimePrices,
  isDefaultValue?: boolean,
}> {
  if (tickers.length)
    return getRealTimeQuotes(tickers)
      .then(res => {
        if (!res.length) return defaultValue;
        const data = res.map(datum => ({
          ticker: datum.ticker,
          priceObj: {
            amount: datum.price,
            changeAmount: datum.changeAmount,
            changePercent: datum.changePercent,
          }
        } as RealTimePrice))

        return {
          isMarketOpen: res.some(datum => datum.isMarketOpen),
          isAfterMarketTime: res[0].isAfterMarketTime,
          isPreMarketTime: res[0].isPreMarketTime,
          data,
        };
      })
  return Promise.resolve(defaultValue);
}

function inARoundHour() {
  return moment()
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .add(1, 'hour')
    .diff(moment());
}