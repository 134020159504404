import * as React from 'react';
import { ComponentClass, StatelessComponent } from 'react';
import * as helperStyles from './styles/common.istyl';
import * as classNames from 'classnames';

export interface InferableComponentDecorator {
  <P, TComponentConstruct extends ComponentClass<P> | StatelessComponent<P>>(
    component: TComponentConstruct
  ): TComponentConstruct;
}

export type ReactComponentDecorator<T> = (
  Component: ComponentClass<T>
) => ComponentClass<T>;

export const Br = ({ isForMobile }: { isForMobile: boolean }) => {
  const cls1 = {
    [helperStyles.showMobile]: isForMobile,
    [helperStyles.hideMobile]: !isForMobile
  };
  const cls2 = {
    [helperStyles.showMobile]: !isForMobile,
    [helperStyles.hideMobile]: isForMobile
  };
  return (
    <span>
      <br className={classNames(cls1)} />
      <span className={classNames(cls2)}>&nbsp;</span>
    </span>
  );
};

/**
 * FlexiBr shows and hides a Br depending on the resolution and configuration passed in.
 */
export const FlexiBr = ({ cls }: { cls: 'showUntil1920' }) => (
  <span className={helperStyles[cls]}>
    <br />
    <span>&nbsp;</span>
  </span>
);
