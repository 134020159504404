import * as React from 'react';
import { Omit } from '../src/common/types';
import * as classNames from 'classnames';
import * as styles from './SlidingCheckbox.istyl';

export type InputPropsWithoutType = Omit<React.HTMLProps<HTMLInputElement>, 'type'>;

export interface SlidingCheckbox {
  inputProps: any;
  wrapperClassName?: string;
  label?: string
}

export class SlidingCheckbox extends React.Component<SlidingCheckbox> {
  public render() {
    const { inputProps = {}, label, wrapperClassName } = this.props;
    return (
      <div className={classNames(wrapperClassName, styles.wrapper)}>
        {label && <span className={styles.label}>{label}</span>}
        <input
          type="checkbox"
          {...inputProps}
          className={classNames('customized', styles.theInput, inputProps.className)}
        />
        <div className={styles.fake}></div>
      </div>
    )
  }
}