import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import Names from './Names.react';

export default class NameCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    stock: PropTypes.object
  };

  render() {
    const { enums, stock } = this.props;

    return <Names enums={enums} item={stock} />;
  }
}
