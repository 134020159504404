import { promote, shouldConvert } from '../TargetedWebPage/actions';
import * as React from 'react';
import { List, Map } from 'immutable';

import './table.styl';
import { selectScreenerStocks } from './selectors';
// TODO wtf why does the compiler requires having .ts here?
import {
  getRawCols as defaultGetRawCols,
  getDetailGroups as defaultGetDetailGroups
} from './conf';
import { events } from './analytics';

import { getCols } from '../tr-dashboard-holdings-table/conf';
import {
  selectProccessStates,
  selectActivePortfolio
} from '../dashboard/selectors';
import { BasicTable } from '../tr-dashboard-holdings-table/index.js';

import {
  AuthRecord,
  Plan,
  ReduxProps,
  Screener,
  ScreenerType
} from '../../common/types';
import { Reporter } from '../analytics';
import {
  selectViewerPlan,
  selectIsPermanentLoggedIn
} from '../../common/auth/selectors';
import { openExternalLogin } from '../../common/lib/utils';
import * as toSlugCase from 'to-slug-case';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

const noop = () => { };

interface TableContainerProps extends ReduxProps {
  currentPage: number;
  getRawCols: (mediaQuery: MediaQuery) => Map<string, any>;
  getDetailGroups?: any;
  stockType?: ScreenerType;
  screener: Screener;
  report: Reporter<typeof events>;
}

// basic table that holds the screener
export class HoldingsTableContainer extends React.PureComponent<
  TableContainerProps
  > {
  private onAddPortfolioItemButtonClicked = (ticker: string) => {
    const { auth, report } = this.props;
    const isLoggedIn = selectIsPermanentLoggedIn();
    report('addResultToPortfolio', { ticker, isLoggedIn });
    if (isLoggedIn) {
      const { actions, dashboard } = this.props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      actions.addPortfolioItems(portfolioId, List([ticker]));
      return true;
    } else {
      // TODO Extract this
      openExternalLogin();
      return false;
    }
  };

  private showPromotion = (plan: Plan, field: string) => {
    const { auth, report, stockType = 'stockScreener' } = this.props;
    const currentPlan = selectViewerPlan(auth);
    const custom3 = shouldConvert.screenerToSlugCase
      ? 'basictable-' + toSlugCase(field)
      : field;
    report.setLastPromotionTrigger('clickedRestrictedTrigger', custom3);
    report('clickLockedSentiment');
    // TODO ATM this is NOT a generic component
    if (currentPlan !== 'premium') promote({ type: field, plan });
  };

  private openDialog = (...args) => {
    const { actions } = this.props;
    actions.openDialog(...args);
  };

  private activateStock = () => {
    this.props.report('clickedTd');
  };

  private openPortfolioItemAlerts = (ticker: string) =>
    this.openDialog('portfolioItemAlerts', { ticker });

  public render() {
    const {
      actions,
      mediaQuery,
      dashboard,
      msg,
      auth,
      screener,
      getRawCols = defaultGetRawCols,
      getDetailGroups = defaultGetDetailGroups,
      stockType,
      report
    } = this.props;
    const stocks = selectScreenerStocks(this.props, stockType);

    const activePortfolio = selectActivePortfolio(dashboard);
    return (
      <div>
        <BasicTable
          activateStock={this.activateStock}
          activePage={null}
          changeEditable={null}
          deleteItem={null}
          dependencies={selectProccessStates(dashboard)}
          editableId={null}
          enums={msg['enums']}
          getDetailGroups={getDetailGroups}
          getCols={getCols(getRawCols)}
          fetchData={null}
          fetchPriceData={null}
          hideTooltip={actions.hideTooltip}
          holdingsCols={null}
          holdingsSortBy={'sector'}
          insertBefore={null}
          isCombinedPortfolio={null}
          isImportedPortfolio={selectActivePortfolio(dashboard).get(
            'isImported'
          )}
          isholdingsSortDescending={true}
          items={stocks}
          maxTable={null}
          mediaQuery={mediaQuery}
          modes={activePortfolio.get('modes')}
          msg={msg['dashboard']['screener']}
          noDataMsg={msg['dashboard']['holdingsNodataImport']}
          onAddPortfolioItemButtonClicked={this.onAddPortfolioItemButtonClicked}
          openAddStockDialog={noop}
          openAlertsSettings={null}
          openHoldingsMetrics={null}
          openPortfolioItem={null}
          openPortfolioItemAlerts={this.openPortfolioItemAlerts}
          openSyncDialog={noop}
          portfolioId={selectActivePortfolio(dashboard).get('id')}
          priceDependencies={null}
          setPurchasePrice={null}
          setShares={null}
          setStockLastRead={null}
          shouldDisplayAddPortfolioItemButton={true}
          addToPortfolioOnClick={() => report('addedToPortfolio')}
          showPromotion={this.showPromotion}
          showTooltip={actions.showTooltip}
          sortHoldingsTable={noop}
          tblType={null}
          onClickAlert={null}
          viewerPlan={selectViewerPlan(auth)}
          screener
        >
          {screener &&
            screener.fields.filter(value => value !== '').size === 0 ? (
              <div className="noFilters">
                <h3>Find Investment Opportunities FAST!</h3>
                <p>
                  Use TipRanks’ {stockType === 'etfScreener' ? 'ETF' : 'Stock'}{' '}
                  Screener to quickly and easily find stocks by selecting key
                  financial metrics.
              </p>
                <img src={require('./img/search-Image-big.png')} alt="Search" />
              </div>
            ) : (
              <div className="noMatch">
                No results found, please try different search metrics.
            </div>
            )}
        </BasicTable>
      </div>
    );
  }
}
