// import * as React from 'react';\n
import React from 'react';
import { msg } from '../../common/intl/messages/en';
import { Dashboard } from '../../common/types';
import { connect } from 'react-redux';
import { selectTheme } from '../dashboard/selectors';
import AnalyzingPortfolio from '../AnalyzingPortfolio';
import Component from 'react-pure-render/component';

// TODO convert this file back to tsx
@connect(({ dashboard }) => ({ dashboard }))
export class ImportingPage extends Component {
  render() {
    // const { dashboard } = this.props;
    // const theme = selectTheme(dashboard);
    // const msg = msg.analyzingPortfolio;
    return <div />;
  }
}
