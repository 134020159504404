import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Component from 'react-pure-render/component';
import { isFinite } from 'lodash';
import Editable from '../../Editable';
import { imgSrc } from '../../../common/lib/utils.ts';
// TODO is this the right place for this?
import { selectShareChangesCounter } from '../../dashboard/routes/holdings/holdings/selectors';
import './SharesCell.styl';
import report from './analytics';

const expectedHeight = 68;
const expectedWidth = 181;

const shortenToK = num =>
  num >= 1e6 ? `${num / 1e6}M` : num >= 1e3 ? `${num / 1e3}K` : num;

export default class SharesCell extends Component {
  static propTypes = {
    changeEditable: PropTypes.func,
    editableId: PropTypes.string,
    hideTooltip: PropTypes.func,
    isCombinedPortfolio: PropTypes.bool,
    isImportedPortfolio: PropTypes.bool,
    modes: PropTypes.object,
    msg: PropTypes.object,
    nextStock: PropTypes.object,
    portfolioId: PropTypes.number,
    setShares: PropTypes.func,
    showTooltip: PropTypes.func,
    stock: PropTypes.object,
    mediaQuery: PropTypes.object
  };
  state = {
    shares: 0,
    // TODO document this
    hasUserRequestToChangeSharesNumber: false,
    haveJustRecordedATransaction: false
  };
  onKeyPress = e => {
    const char = !e.charCode ? e.which : e.charCode;
    // TIPRANKS-13889, disallow dot
    if (char === 46) {
      e.preventDefault();
    }
  };
  componentWillReceiveProps(nextProps, nextState) {
    const { state: newShares, hasUserRequestToChangeSharesNumber } = this.state;
    const oldShares = nextProps.stock.get('totalShares');
    const hasUserRequestToChangeSharesNumberCompleted = newShares === oldShares;
    if (
      hasUserRequestToChangeSharesNumber &&
      hasUserRequestToChangeSharesNumberCompleted
    ) {
      this.setState({
        haveJustRecordedATransaction: true
      });
      const cssTransitionTime = 500;
      const transactionAnimationTime = 2000 + cssTransitionTime;
      setTimeout(
        () => this.setState({ haveJustRecordedATransaction: false }),
        transactionAnimationTime
      );
      setTimeout(
        () => this.setState({ hasUserRequestToChangeSharesNumber: false }),
        transactionAnimationTime + cssTransitionTime
      );
    }
  }
  onChange = shares => this.setState({ shares: Number(shares).toFixed(0) });
  render() {
    const {
      mediaQuery,
      changeEditable,
      editableId,
      hideTooltip,
      isImportedPortfolio,
      modes,
      msg,
      nextStock,
      portfolioId,
      setShares,
      showTooltip,
      stock,
      isCombinedPortfolio
    } = this.props;

    const {
      shares,
      haveJustRecordedATransaction,
      hasUserRequestToChangeSharesNumber
    } = this.state;
    const numShareId = `numShare_${stock.get('ticker')}`;

    const doneEditing = (shares, { goNext } = {}) => {
      const intShares = parseInt(shares, 0);
      const isValid = intShares >= 0 && intShares <= 1e6;
      const hasChanged = intShares !== stock.get('sharesTotal');
      if (isNaN(intShares) || !isValid || !hasChanged) {
        changeEditable('');
        return;
      }
      if (goNext && nextStock) {
        const nextEditableId = `numShare_${nextStock.get('ticker')}`;
        changeEditable(nextEditableId, stock.get('sharesTotal'));
      } else {
        changeEditable('');
      }

      setShares(
        portfolioId,
        stock.get('ticker'),
        intShares,
        stock.get('sharesTotal')
      ).then(({ hasSetShares }) => {
        if (hasSetShares) {
          this.setState({ hasUserRequestToChangeSharesNumber: true });
        }
      });
    };

    const activate = () => {
      const { isCombinedPortfolio } = this.props;
      if (isCombinedPortfolio) return;
      this.setState({ shares: stock.get('sharesTotal').toString() });
      changeEditable(numShareId, stock.get('sharesTotal'));
    };

    const isMissingShares = modes.includes('missingShares');

    if (isImportedPortfolio || isCombinedPortfolio) {
      return (
        <div>
          <span className="real-value">
            {shortenToK(stock.get('sharesTotal'))}
          </span>
        </div>
      );
    }
    const isMobile = !mediaQuery.get('laptop');
    return (
      <div
        className={classNames({
          isMissingShares: isMissingShares && stock.get('sharesTotal') <= 0,
          isZeroShares: stock.get('sharesTotal') <= 0
        })}
      >
        {isMissingShares && stock.get('sharesTotal') <= 0 ? (
          <img
            className="warning"
            onMouseEnter={event =>
              showTooltip(
                'warning',
                {
                  expectedHeight,
                  expectedWidth,
                  centeredAbove: true,
                  tooltipMsg: msg.tooltips.missingShares
                },
                event.target
              )
            }
            onMouseLeave={event => hideTooltip('warning', { force: true })}
            src={imgSrc('warning')}
          />
        ) : null}
        <span className="real-value">
          <Editable
            activate={activate}
            doneEditing={doneEditing}
            editableValue={
              shares === -1 ? stock.get('sharesTotal').toString() : shares
            }
            freez={false}
            isActiveMode={editableId === numShareId}
            min={0}
            step={1}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            type="number"
            value={shortenToK(stock.get('sharesTotal'))}
          />
        </span>
        <button onClick={activate}>
          <img src={imgSrc('edit')} />
        </button>
        {hasUserRequestToChangeSharesNumber && (
          <div
            className={classNames(
              {
                showTooltip: haveJustRecordedATransaction,
                'transactionRecordedPoppingTooltip-mobile': isMobile
              },
              'transactionRecordedPoppingTooltip'
            )}
            onClick={() => report('clicked-transaction-recorded')}
          >
            Transaction Recorded!
          </div>
        )}
      </div>
    );
  }
}
