import { ImageOptions } from '../../../common/lib/utils';
import * as React from 'react';
import { imgSrc } from '../../../common/lib/utils';
import * as classNames from 'classnames';

const renderMsg = type =>
  ({
    loaderStatic: <span>Loading...</span>
  }[type] || null);

// TODO apparently it can work witohut name or type?
export default class Loader extends React.PureComponent<{
  name?: string;
  type?: ImageOptions;
}> {
  render() {
    const { name, type = 'loader' } = this.props;
    return (
      <div className={classNames('suit', 'suit-loader', `suit-${name}`)}>
        <img src={imgSrc(type)} />
        {renderMsg(type)}
      </div>
    );
  }
}
