import * as React from 'react';
import Component from 'react-pure-render/component';

import report from './analytics';

export default class RestrictedButton extends React.PureComponent<{
  analyticName: string; //PropTypes.string,
  enums: any; //PropTypes.object,
  plan: string; //PropTypes.string,
  type: string; //PropTypes.string
  showPromotion?: any; //PropTypes.func,
}> {

  onClick = () => {
    const { analyticName, plan, showPromotion, type } = this.props;
    report('click', { analyticName, props: { columnName: type } });
    showPromotion && showPromotion(plan, type);
  };

  render() {
    const { enums, plan } = this.props;
    return (
      <button
        className={`restriction-button restriction-button-${plan}`}
        onClick={this.onClick}
      >
        {enums.plans[plan]}
      </button>
    );
  }
}
