import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Component from 'react-pure-render/component';

import { defaultMsg } from './msg';
import './index.styl';

export default class TrDashboardMgmtFeesOverviewImportantNote extends React.PureComponent<{
  msg?: any;
}> {
  static defaultProps = {
    msg: defaultMsg
  };

  render() {
    const { msg } = this.props;

    return (
      <p className="tr-dashboard-mgmt-fees-overview-important-note">
        <strong>{msg.importantNoteLabel}:</strong>
        &nbsp;
        <span>{msg.importantNoteContent}</span>
      </p>
    );
  }
}
