import * as React from 'react';
import { connect } from 'react-redux';
import { suits } from '../../../../../common/enums';
import { ComponentStates, ComponentStatesObj } from 'sp/common/types';
import { imgSrc } from 'sp/common/lib/utils';
import { ConnectedPureComponent } from 'sp/common/lib/ConnectedComponent';

const styles = {
  top: '50%',
  position: 'absolute',
  transform: 'translateY(-50%)',
  MsTransform: 'translateY(-50%)',
  WebkitTransform: 'translateY(-50%)',
  textAlign: 'center',
  width: '100%'
};

const connector = connect(({ dashboard: { componentStates } }) => ({
  componentStates
}));

// TODO this stupid ass Loading component is responsible for the loading state
// OF ALL OF OUR FUCKING PAGES. Refactor this and assetbox awaYYYYYYYYYYYYYYy
export const Loading = (...apiName: (keyof ComponentStatesObj)[]) => Component =>
  connector(
    class Loading<T extends {}> extends ConnectedPureComponent<T, { componentStates: ComponentStates; }> {
      isLoading() {
        const { componentStates } = this.connected;
        return apiName.some(x => {
          const state = componentStates.get(x);
          return state === suits.LOADING || state === suits.INITIAL;
        });
      }

      render() {
        return this.isLoading()
          ? (
            // return true
            <div style={styles as any}>
              <img src={imgSrc('loader')} />
            </div>
          )
          : (
            <Component {...this.props} />
          );
      }
    }
  );

export default Loading;
