import * as React from 'react';

import Clickable from '../Clickable/clickable.react';
import { createNasdaqLink } from 'sp/browser/lib/utils';

interface Props {
  /**
   * Slug without the URL parts.
   * @example 'best-analyst-consensus'
   */
  slug: string;
  /**
   * Nasdaq links by default go to nasdaq.com, this flag forces the link to go internally.
   * @example See 'How tipranks measures your performance'.
   */
  forceInternal?: boolean;
}

// TODO theres a glossarylink and /browser/decisions/index.js, why...?
export default class GlossaryLink extends React.PureComponent<
  React.HTMLProps<HTMLAnchorElement> & Props & any
> {
  public render() {
    const { slug, forceInternal = false, ref, ...restProps } = this.props;

    // TODO remove this ugly monkeypatching
    const slugFix = slug.replace('dividends', 'dividend');
    const link: { to?: string; href?: string } = {};
    if (process.env.THEME === 'nasdaq') {
      if (forceInternal) {
        link.to = `/smart-portfolio/glossary/${slug[0]}/${slug}`;
      } else {
        link.href = createNasdaqLink(
          `investing/glossary/${slug[0]}/${slugFix}`
        );
      }
    } else {
      link.to = `/smart-portfolio/glossary/${slug[0]}/${slug}`;
    }

    const target = process.env.THEME === 'web' ? { target: '_blank' } : {};

    return (
      <Clickable {...target} {...restProps} {...link}>
        {this.props.children}
      </Clickable>
    );
  }
}
