import * as React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import TrProfilePic from '../tr-profile-pic';
import TrDropzone from '../tr-dropzone';
import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import './index.styl';
import { ReduxProps } from 'sp/common/types';
import { trDropZoneStatus } from 'sp/browser/tr-dropzone/types';
import { selectIsOnboarding } from 'sp/common/auth/selectors';
import { popupRegister } from 'sp/common/lib/utils';

@connect(
  s => ({
    picture: s.ui.getIn(['userProfile', 'picture'], null),
    state: s.ui.getIn(['userProfile', 'state'], null),
  }),
  mapDispatchToProps,
)
export class TrProfilePicDropzone extends React.PureComponent<{
  profileSrcName: string,
  preventEditing?: boolean,
  // from connect
  picture?: string,
  state?: keyof typeof trDropZoneStatus,
}> {
  static defaultProps = {
    preventEditing: false,
  };

  getSrcName = () => {
    const {
      profileSrcName: originalSrcName,
      state: currentStatus,
      picture: profileSrcName,
    } = this.props;

    return currentStatus === trDropZoneStatus.success ? profileSrcName : originalSrcName;
  };

  render() {
    const srcName = this.getSrcName();

    const { state: currentStatus, preventEditing } = this.props;
    if (currentStatus === trDropZoneStatus.loading) {
      return (
        <div className="tr-profile-pic-dropzone loading showbox">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        </div>
      );
    }

    if (preventEditing)
      return (
        <div className="tr-profile-pic-dropzone">
          <TrProfilePic isDropzone={!preventEditing} profileSrcName={srcName} />
        </div>
      );

    return (
      <div className="tr-profile-pic-dropzone" onClickCapture={this.checkUser}>
        <TrDropzone>
          <TrProfilePic isDropzone={!preventEditing} profileSrcName={srcName} />
        </TrDropzone>
      </div>
    );
  }
  checkUser = (event: React.SyntheticEvent<HTMLDivElement>) => {
    if (selectIsOnboarding()) {
      event.stopPropagation();
      popupRegister();
    }
  }
}
