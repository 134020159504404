import reporter from '../analytics';

const events = {
  mounted: 'visited-management-fees-page',
  comparedClicked: 'clicked-management-fees-compare',
  restrictedClick: 'clicked-locked-feature'
};

const report = reporter(events);

export default report;
