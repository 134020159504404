import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

//import { Col } from '../conf';

const reqImg = img => (process.env.IS_BROWSER ? require(`../img/${img}`) : '');

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class TrDashboardHoldingsDetailValue extends Component {
  static propTypes = {
    //col: PropTypes.instanceOf(Col),
    isMissingShares: PropTypes.bool,
    item: PropTypes.object,
    onClickTr: PropTypes.func,
    changeEditable: PropTypes.func
  };

  openAndEdit = () => {
    const { onClickTr, changeEditable, item } = this.props;
    changeEditable(`numShare_${item.get('ticker')}`, item.get('sharesTotal'));
  };

  render() {
    const { col, isMissingShares, item } = this.props;

    const hasShares = item.get('sharesTotal') > 0;
    const hasWarning = isMissingShares && !hasShares;

    return (
      <div className="tr-dahsobard-holdings-detail value">
        {hasWarning && (
          <div className="message visible-on-tr-hover">
            Add no. of shares to see your holdings amount
          </div>
        )}
        {hasWarning && <div className="missing-holder">
          <span style={{ color: 'red' }}>
            <img alt="" src={reqImg('warning.png')} />
          </span>
        </div>}
        <PortfolioItemSelector {...this.props} type="value" />
        {!hasShares && (
          <button className="edit" onClick={this.openAndEdit}>
            <img alt="" src="/assets/img/edit.png" />
          </button>
        )}
      </div>
    );
  }
}
