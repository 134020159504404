export const img = {
  // bestTarget: 1,
  // bloggerSentiment: 1,
  // insiderSentiment: 1,
  // hedgefundSentiment: 1,
  // bestAnalystConsensus: 1,
  // newsSentiment: 1,
  // screener: 1,
  // 'screener-filter': 1,
  'crowd-insights': 7,
  portfolios: 2,
  alertsSettings: 3,
  alertsSettingsRanking: 3,
  expertAlertsLimit: 3, // TODO: 4 is missing from source control. Should be 4.
  mgmtFees: 5,
  portfolioNews: 6,
  'etf-screener-filter': 8,
  managementFee: 8
};

export const noMobilePic = [];
