import React from 'react';
import Component from 'react-pure-render/component';
import { mediaQuery } from 'sp/common/lib/mediaQuery';

export default Child =>
  class Responsive extends Component {
    constructor(props) {
      super(props);
      this.state = {
        mediaQuery: mediaQuery()
      };
    }

    onResize() {
      const newMediaQuery = mediaQuery();
      if (!this.state.mediaQuery.equals(newMediaQuery)) {
        this.setState({
          ...this.state,
          mediaQuery: newMediaQuery
        });
      }
    }

    componentDidMount() {
      this.onResize = this.onResize.bind(this);
      window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    render() {
      const { mediaQuery } = this.state;
      return <Child {...this.props} mediaQuery={mediaQuery} />;
    }
  };
