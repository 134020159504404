import reporter from '../analytics';

const events = {
  saw: loginType => `saw-${loginType}-dialog`,
  signedupSmartPortfolio: 'user-completed-signup-smart-portfolio',
  signedup({ type, loginType } = {}) {
    return {
      event: `user-completed-${loginType}`,
      props: { type }
    };
  }
};

const report = reporter(events);

export default report;
