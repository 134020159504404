import * as React from 'react';
import { isCanadianTicker } from 'sp/browser/lib/utils';

export enum Currency {
  USD,
  CAD
}

type MoneyProps = {
  amount: number;
  currency: Currency;
  normalized?: boolean;
  toFixed?: number;
  renderZero?: boolean;
};

/**
 * Base component to represent money / currency.
 * TODO extract this component to a shared space, Website has exactly the same one.
 */
export function Money({
  amount,
  currency,
  normalized = false,
  toFixed = 2,
  renderZero = false,
  ...htmlProps
}: MoneyProps & any) { //React.HTMLProps<HTMLSpanElement>) {
  if (!amount && !renderZero) {
    return <span>-</span>;
  }
  return (
    <span {...htmlProps}>
      {sign(amount)}
      {currencySymbol(currency)}
      {normalized
        ? shortenNumber(Math.abs(amount), toFixed)
        : normalize(Math.abs(amount), toFixed)}
    </span>
  );
}

function sign(amount: number) {
  return amount < 0 ? '-' : '';
}

export function shortenNumber(n: number | string, toFixed: number = 2) {
  if (typeof n === 'string') {
    n = parseFloat(n);
  }
  if (n / 1e9 >= 1) {
    return `${(n / 1e9).toFixed(toFixed)}B`;
  } else if (n / 1e6 >= 1) {
    return `${(n / 1e6).toFixed(toFixed)}M`;
  } else if (n / 1e3 >= 1) {
    return `${(n / 1e3).toFixed(toFixed)}K`;
  }
  return Number.isNaN(n) ? 'N/A' : n.toFixed(toFixed);
}

function normalize(n: number, toFixed: number) {
  return n.toLocaleString('en-US', {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed
  });
}

export function currencySymbol(currency: Currency) {
  switch (currency) {
    case Currency.CAD:
      return 'C$';
    case Currency.USD:
      return '$';
  }
}

export function currencyFromTicker(ticker: string) {
  return isCanadianTicker(ticker) ? Currency.CAD : Currency.USD;
}

export function currencySymbolFromTicker(ticker: string) {
  return currencySymbol(
    ticker.toLowerCase().startsWith('tse:') ? Currency.CAD : Currency.USD
  );
}
