import cookie from 'js-cookie';
import React from 'react';

import { IndexRoute, Route } from 'react-router';

import { App } from './app/App.react.tsx';
import Home from './home/Page.react';
import Login from './auth/Login.react';
import { Logout } from './auth/Logout';
import DashboardPage from './DashboardPage';
import NotFound from './notfound/Page.react';
import Register from './auth/Register.react';
import SearchResults from './search-results/search-results.react';
import { dashboardPath, isNasdaq, registerRoutePath, loginRoutePath } from '../common/config';
import { checkForPlaidTokenInStorage } from '../common/PlaidProviderLogic';
import {
  selectIsLoggedIn,
  selectCameFromOnboarding,
  selectIsPermanentLoggedIn,
  selectViewer,
} from '../common/auth/selectors.ts';

import { selectActivePortfolio } from './dashboard/selectors.ts';
import { getPortfolios, identifyUser } from '../common/api';
import { onEnterPublicRegisterPage } from 'sp/browser/pages/PublicPortfolioToRegister/utils';
import { ReduxProps } from '../common/types';
import { onEnterOnboardingPage } from 'sp/browser/pages/PublicPortfolio/Onboarding/utils'
import { requireAuthReplace } from 'sp/browser/lib/router-utils';
import { onEnterPublicPortfolioPage } from 'sp/browser/tr-dashboard-user-performance-container/utils';

async function hasAtLeastPortfolio(viewer) {
  const cached =
    viewer.getIn(['loggedInUser', 'hasHoldings']) || cookie.get('hadHoldings');
  // hadHoldings cookie is new, it's possible that the user doesn't have it because they are from the old version.
  // Let's check and make sure, shall we?
  if (cached === undefined) {
    const res = await getPortfolios();
    return res.portfolios.length > 0;
  }
  return !!cached;
}

async function getHasHoldings(viewer) {
  const data = (await identifyUser({ shouldRefresh: true })) || {};

  return data.hasHoldings;
}

export default function createRoutes(storeState: ReduxProps) {
  // dont show welcome screen if user has portfolio
  const welcomeRouteRedirections = async (nextState, replaceRoute, done) => {
    const { auth, dashboard } = storeState;
    const viewer = selectViewer(auth);
    const hasHoldings = await getHasHoldings(viewer);
    const isLoggedIn = selectIsLoggedIn();
    const isPermanentlyLoggedIn = selectIsPermanentLoggedIn();
    const cameFromOnboarding = selectCameFromOnboarding();
    // TODO get state from getState above instead of this shit
    const activePortfolio = selectActivePortfolio(dashboard);

    // I don't really like doing cookie logic here.
    // But see no other choice.
    // They are coming. Don't blink.
    cookie.remove('CameFromOnboarding');

    if (isNasdaq && !checkForPlaidTokenInStorage() &&  isPermanentlyLoggedIn && (!hasHoldings || cameFromOnboarding)) {
      const onboardingType = activePortfolio.get('isImported')
        ? 'imported'
        : 'manual';
      localStorage.setItem('OnboardingAction', onboardingType);
      replaceRoute({
        state: nextState,
        pathname: `/smart-portfolio/sign-up/${onboardingType}`,
        query: nextState.location.query,
      });
    } else if (hasHoldings && isLoggedIn) {
      // Redirect to holdings if has any
      replaceRoute({
        state: nextState,
        pathname: '/smart-portfolio/holdings',
        query: nextState.location.query,
      });
    }
    done();
  };

  const defaultRoute = path => (nextState, replaceRoute) => {
    replaceRoute({
      state: nextState,
      pathname: `${nextState.location.pathname}/${path}`.replace(/\/+/g, '/'),
      query: nextState.location.query,
    });
  };
  // Note: if you change the routes, also change in dashboard.react.js
  return (
    <Route component={App} path="/">
      <IndexRoute component={Home} />
      <Route component={DashboardPage} onEnter={onEnterPublicPortfolioPage} path="investors">
        <Route onEnter={onEnterPublicRegisterPage} component={DashboardPage} path="register" />
        <Route component={DashboardPage} path=":publicPortfolioId(/:publicPortfolioSlug)">
          <Route onEnter={onEnterPublicRegisterPage} component={DashboardPage} path="register" />
        </Route>
      </Route>

      <Route component={DashboardPage} path={dashboardPath}>
        <IndexRoute onEnter={defaultRoute('welcome')} />

        <Route component={DashboardPage} path="onboarding" onEnter={onEnterOnboardingPage}>
          <Route component={DashboardPage} path="1" />
          <Route component={DashboardPage} path="2" />
          <Route component={DashboardPage} path="3" />
        </Route>

        <Route
          component={DashboardPage}
          path="welcome"
          onEnter={welcomeRouteRedirections}
        />
        <Route component={DashboardPage} path="welcome-dev" />
        <Route component={DashboardPage} path="gopro" name="go">
          <Route component={DashboardPage} path="**/*" name="pro" />
        </Route>
        <Route component={DashboardPage} name="sign-up" path="sign-up/:type" />
        <Route
          component={DashboardPage}
          name="importing"
          path="processing-import"
        />
        <Route component={DashboardPage} path="plans" />
        <Route component={DashboardPage} path="faq" />
        <Route
          component={DashboardPage}
          name="glossary"
          path="glossary/:letter/:slug"
        />
        <Route component={DashboardPage} path="contact" />

        <Route
          component={DashboardPage}
          onEnter={requireAuthReplace}
          path="overview"
        />

        <Route component={DashboardPage} onEnter={requireAuthReplace} path="holdings">
          <IndexRoute onEnter={defaultRoute('holdings')} />

          <Route component={DashboardPage} path="holdings" />
          <Route component={DashboardPage} path="holdings-new" />
          <Route component={DashboardPage} path="opinions" />
          <Route component={DashboardPage} path="news">
            <Route component={DashboardPage} path="all" />
            <Route component={DashboardPage} path="analysis" />
            <Route component={DashboardPage} path="bullish" />
            <Route component={DashboardPage} path="bearish" />
          </Route>
          <Route component={DashboardPage} path="calendar" />
          <Route component={DashboardPage} path="alerts-settings" />
        </Route>

        <Route component={DashboardPage} onEnter={requireAuthReplace} path="analysis">
          <IndexRoute onEnter={defaultRoute('overview')} />

          <Route component={DashboardPage} path="overview" />
          <Route component={DashboardPage} path="fees" />
        </Route>

        <Route
          component={DashboardPage}
          onEnter={requireAuthReplace}
          path="insights"
        />

        <Route component={DashboardPage} onEnter={requireAuthReplace} path="experts" />

        <Route component={DashboardPage} path="screener">
          <IndexRoute
            component={DashboardPage}
            onEnter={defaultRoute('stocks')}
          />

          <Route component={DashboardPage} path="stocks" />
          <Route component={DashboardPage} path="etfs" />
        </Route>

        <Route component={DashboardPage} path="account">
          <IndexRoute
            component={DashboardPage}
            onEnter={defaultRoute('settings')}
          />

          <Route component={DashboardPage} path="settings" />
        </Route>

        <Route
          component={DashboardPage}
          onEnter={requireAuthReplace}
          path="performance"
        >
          <IndexRoute component={DashboardPage} onEnter={defaultRoute('me')} />
          <Route component={DashboardPage} path="comingsoon" />
          <Route component={DashboardPage} path="me" />
        </Route>

        <Route component={DashboardPage} path="tour" />

        <Route component={DashboardPage} path="thank-you" />

        <Route component={DashboardPage} path="community" />
      </Route>

      <Route component={Login} path="login" />
      <Route component={Register} path={registerRoutePath} />
      <Route component={Logout} path={loginRoutePath} />
      <Route component={SearchResults} path="search" />
      <Route component={NotFound} path="*" />
    </Route>
  );
}