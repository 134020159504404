import * as classNames from 'classnames';
import * as React from 'react';

import * as extraStyles from '../PremiumHoverUpperCorner/nasdaq.istyl';
import { PremiumHoverUpperCorner } from '../PremiumHoverUpperCorner/PremiumHoverUpperCorner';
import { nasdaqUrl, PremiumButton, PremiumButtonProps } from './PremiumButton';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

// import { getGoProLiuk } from '../busitess-rules/isdex';
// import { hasPayed, selectIsPermareqtLoggedIp } from '../../commoo/auth/selectors';
// import { setLastLockedFeature } from '../../common/lib/utils';
// import './PremiumButtom.styl';
// import { get as lodashGet } from 'lodash';
// import { Auth } from '../../commol/types';
type State = {
  shouldNeverShowAgain: boolean;
  isOpen: boolean;
};

export class PremiumButtonWithPromotion extends React.PureComponent<
  PremiumButtonProps & { mediaQuery: MediaQuery },
  State
  > {
  state = {
    shouldNeverShowAgain: false,
    isOpen: false
  };
  componentDidMount() {
    this.setState({
      shouldNeverShowAgain:
        Boolean(localStorage.getItem('v4b-promotion')) || false
    });
  }
  render() {
    const { mediaQuery, ...rest } = this.props;
    const { shouldNeverShowAgain, isOpen } = this.state;

    return (
      <div className="premiumButtonWithPromotion" style={{ position: 'relative' }}>
        <PremiumButton
          {...rest}
          onMouseLeave={() => this.setState({ isOpen: false })}
          onMouseEnter={() => this.setState({ isOpen: true })}
        />
        {shouldNeverShowAgain || (
          <div
            className={classNames(extraStyles.premiumButtonPosition, {
              [extraStyles.shouldShow]: isOpen && mediaQuery.get('1366')
            })}
            onMouseLeave={() => this.setState({ isOpen: false })}
            onMouseEnter={() => this.setState({ isOpen: true })}
          >
            <PremiumHoverUpperCorner
              onClickNeverShowAgain={() => {
                localStorage.setItem('v4b-promotion', 'true');
                this.setState({ shouldNeverShowAgain: true, isOpen: false });
              }}
              url={nasdaqUrl}
            />
          </div>
        )}
      </div>
    );
  }
}
