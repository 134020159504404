import { brandName } from '../../common/config';
import { calculateScaleRatio } from './utils';
import * as React from 'react';
import { PureComponent, EventHandler, MouseEvent } from 'react';

import { FormattedNumber } from 'react-intl';
import * as classNames from 'classnames';

import { Portfolio } from './types';

import Loader from '../components/suit/Loader.react';
import PortfolioItemSelector from '../PortfolioItemSelector/PortfolioItemSelector.react';
import Company from './company';

import '../tr-dashboard-avg-portfolio-container/index.styl';
import { PortfolioItemRecord, StockType, Theme } from 'sp/common/types';
import { List } from 'immutable';
import { getSectorText, betterToFixed, getTickerUrl } from 'sp/common/lib/utils';
import { NoDataAddStocks } from 'sp/browser/dashboard/NoDataAddStocks';

type Distribution = List<Map<string, any>>;
export type CIM_majorHoldingsType = 'stock' | 'other';
type Sector = string;
type Dict = { [key: string]: any };

interface Props {
  addStocks: () => void;
  companyFunds: List<any>;
  companyMajorHoldings: List<any>;
  companyPortfolioItemsDistribution: List<Map<string, any>>;
  companyPortfolioStocksDistribution: Distribution;
  enums: {
    stockTypes: Dict;
    sectors: Dict;
    [key: string]: any;
  };
  hasAssetAllocation: boolean;
  hasItems: boolean;
  headIconGr: string;
  investmentFees: Map<string, any>;
  majorHoldings: List<PortfolioItemRecord>;
  majorHoldingsButStocks: List<any>;
  majorHoldingsSector: Sector;
  majorHoldingsType: CIM_majorHoldingsType;
  mediaQuery: any;
  onMajorHoldingsSectorChange: (newHoldingSector: Sector) => void;
  onMajorHoldingsTypeChange: (newHoldingType: CIM_majorHoldingsType) => void;
  portfolioItemUrl: (type: StockType, ticker: string) => string;
  portfolioItemsDistribution: Distribution;
  portfolioStocksDistribution: Distribution;
  sectors: string[];
  theme: Theme;
  trIconGr: string;
  selectedPortfolio: Portfolio;
}

export class CrowdInsightsMain extends React.PureComponent<Props> {
  public render() {
    const {
      addStocks,
      companyFunds,
      companyMajorHoldings,
      companyPortfolioItemsDistribution,
      companyPortfolioStocksDistribution,
      enums,
      hasAssetAllocation,
      hasItems,
      headIconGr,
      investmentFees,
      majorHoldings,
      majorHoldingsButStocks,
      majorHoldingsSector,
      majorHoldingsType,
      mediaQuery,
      onMajorHoldingsSectorChange,
      onMajorHoldingsTypeChange,
      portfolioItemUrl,
      portfolioItemsDistribution,
      portfolioStocksDistribution,
      sectors,
      theme,
      trIconGr,
      selectedPortfolio,
    } = this.props;

    return (
      <div className="lt2b-main">
        <div className="lt2b-asset-allocation">
          <div id="lt2b-aa-header">
            <h1>
              Asset Allocation<small>
                <i className="fa fa-info-circle" />
              </small>
            </h1>
          </div>
          <div className="lt2b-sub-aa">
            <h2>
              <span className="one-line">Stock Allocation&nbsp;</span>
              <span className="one-line">(Stock Distribution by Sector)</span>
              <small>
                <i className="fa fa-info-circle" />
              </small>
            </h2>
            <div className="lt2b-two-graph">
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <div className="lt2b-one-graph">
                  <div className="lt2b-graph-icon">
                    <img src={headIconGr} />
                    <p>My Portfolio</p>
                  </div>
                  <div className="lt2b-donuts-graph" id="lt2b-graph3" />
                  <div className="lt2b-legend-donuts-graph">
                    {(portfolioStocksDistribution.size === 0 || !hasItems) && (
                      <NoDataAddStocks
                        htmlProps={{ className: 'no-data-graph' }}
                        msg={{ addStocks: 'Add Stocks', noData: 'Your Holdings Chart is empty. {addButton} to start' }}
                        openAddStockDialog={addStocks}
                      />
                    )}
                    <ul className="lt2b-graph-legend">
                      {portfolioStocksDistribution
                        .filter(datum => !!datum.get('percent'))
                        .map(
                          (type, index) =>
                            type && (
                              <li key={type.get('id')}>
                                <span
                                  className={classNames(
                                    `lt2b-sq`,
                                    `lt2b-${index}-sq`,
                                  )}
                                />
                                {betterToFixed(type.get('percent') * 100)}% &nbsp;
                                {getSectorText(type.get('id'), { shortText: !mediaQuery.get('mobileM') })}
                              </li>
                            ),
                      )}
                    </ul>
                  </div>
                </div>
                <div className="lt2b-one-graph lt2b-graph-board">
                  <div
                    className={classNames(
                      `lt2b-graph-icon2`,
                      `lt2b-graph-icon2-fourth`,
                      `avg`,
                      selectedPortfolio === Portfolio.AveragePortfolio
                        ? 'avg-text'
                        : 'best-text',
                    )}
                  >
                    <p>
                      {selectedPortfolio === Portfolio.AveragePortfolio ? (
                        <span>
                          <b>
                            <Company theme={theme} />
                          </b>{' '}
                          Average Portfolio
                        </span>
                      ) : (
                          <span>
                            <b>
                              <span className="best-performing">
                                Best Performing
                            </span>{' '}
                              <Company theme={theme} />
                            </b>{' '}
                            Portfolio
                        </span>
                        )}
                    </p>
                  </div>
                  <div className="lt2b-donuts-graph" id="lt2b-graph4" />
                  <div className="lt2b-legend-donuts-graph">
                    <ul className="lt2b-graph-legend">
                      {companyPortfolioStocksDistribution
                        .filter(datum => !!datum.get('percent'))
                        .map(
                          (type, index) =>
                            type && (
                              <li key={type.get('id')}>
                                <span
                                  className={classNames(
                                    `lt2b-sq`,
                                    `lt2b-${index}-sq`,
                                  )}
                                />
                                {betterToFixed(type.get('percent') * 100)}% &nbsp;
                                {getSectorText(type.get('id'), { shortText: !mediaQuery.get('mobileM') })}
                              </li>
                            ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="lt2b-bordered-title">
              <span className="one-line">Asset Allocation&nbsp; </span>
              <span className="one-line">
                (Stocks, ETFs &amp; Mutual Funds){' '}
              </span>
              <small>
                <i className="fa fa-info-circle" />
              </small>
            </h2>
            <div className="lt2b-two-graph lt2b-last-two-graph">
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <div className="lt2b-one-graph">
                  <div className="lt2b-graph-icon lt2b-graph-icon-first">
                    <img src={headIconGr} />
                    <p>My Portfolio</p>
                  </div>
                  <div className="lt2b-donuts-graph" id="lt2b-graph1" />
                  <div className="lt2b-legend-donuts-graph">
                    {(portfolioStocksDistribution.size === 0 ||
                      !hasAssetAllocation) &&
                      false ? (
                        <div className="no-data no-data-graph">
                          Your Holdings Chart is empty &nbsp;
                        <button onClick={addStocks}>Add Stocks</button>&nbsp; to start.
                      </div>
                      ) : (
                        <ul className="lt2b-graph-legend">
                          {portfolioItemsDistribution.map(type => {
                            const val = type.get('percent') * 100;
                            return (
                              <li key={type.get('id')}>
                                <span
                                  className={classNames(
                                    `lt2b-sq`,
                                    `lt2b-${type.get('id')}-sq`,
                                  )}
                                />
                                {isNaN(val) ? (
                                  <span>--</span>
                                ) : (
                                    <span>
                                      {betterToFixed(val)}%
                                  </span>
                                  )}
                                &nbsp;
                              {enums.stockTypes[type.get('id')]}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                  </div>
                </div>
                <div className="lt2b-one-graph lt2b-graph-board">
                  <div
                    className={classNames(
                      `lt2b-graph-icon2`,
                      `lt2b-graph-icon2-second`,
                      selectedPortfolio === Portfolio.AveragePortfolio
                        ? 'avg-text'
                        : 'best-text',
                    )}
                  >
                    <p>
                      {selectedPortfolio === Portfolio.AveragePortfolio ? (
                        <span>
                          <b>
                            <Company theme={theme} />
                          </b>{' '}
                          Average Portfolio
                        </span>
                      ) : (
                          <span>
                            <b>
                              <span className="best-performing">
                                Best Performing
                            </span>{' '}
                              <Company theme={theme} />
                            </b>{' '}
                            Portfolio
                        </span>
                        )}
                    </p>
                  </div>
                  <div className="lt2b-donuts-graph" id="lt2b-graph2" />
                  <div className="lt2b-legend-donuts-graph">
                    <ul className="lt2b-graph-legend">
                      {companyPortfolioItemsDistribution
                        .filter(type => type.get('id') !== 'cash')
                        .map(type => (
                          <li key={type.get('id')}>
                            <span
                              className={classNames(
                                `lt2b-sq`,
                                `lt2b-${type.get('id')}-sq`,
                              )}
                            />
                            <span>
                              {betterToFixed(type.get('percent') * 100)}%
                            </span>
                            &nbsp;
                            {enums.stockTypes[type.get('id')]}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('lt2b-major-holdings', majorHoldingsType)}>
          <div className="lt2b-major-holdings-title">
            <h2 className="section-header-alt-1-h1">
              Major Holdings{' '}
              <small>
                <i className="fa fa-info-circle" />
              </small>
            </h2>
            <p className="major-holdings-type-radios">
              <label>
                <input
                  checked={majorHoldingsType === 'stock'}
                  className="lt2b-radio-head"
                  id="lt2b-radio-equities"
                  name="group1"
                  onChange={() => onMajorHoldingsTypeChange('stock')}
                  type="radio"
                  value="stock"
                />
                <span className="lt2b-radio-active" id="lt2b-radio1">
                  US Equities
                </span>
              </label>
              <label>
                <input
                  checked={majorHoldingsType === 'other'}
                  className="lt2b-radio-head"
                  id="lt2b-radio-etfs"
                  name="group1"
                  onChange={() => onMajorHoldingsTypeChange('other')}
                  type="radio"
                  value="other"
                />
                <span id="lt2b-radio2">ETFs &amp; Mutual Funds</span>
              </label>
            </p>
          </div>
          <div className="lt2b-btn-for-mobile">
            <button
              className={classNames(
                { 'lt2b-mob-btn-active': majorHoldingsType === 'stock' },
                'lt2b-mob-btn',
              )}
              id="lt2b-USE-btn"
              onClick={() => onMajorHoldingsTypeChange('stock')}
            >
              Equities
            </button>
            <button
              className={classNames(
                { 'lt2b-mob-btn-active': majorHoldingsType === 'other' },
                'lt2b-mob-btn',
              )}
              id="lt2b-ETF-btn"
              onClick={() => onMajorHoldingsTypeChange('other')}
            >
              ETFs &amp; Funds
            </button>
          </div>
          <div className="lt2b-select-sector">
            <span>Showing: </span>
            <form action="">
              <div className="lt2b-div-for-select">
                <select
                  id="lt2b-selectsector"
                  name="lt2b-selectsector"
                  onChange={({ target }) =>
                    onMajorHoldingsSectorChange(
                      (target as HTMLSelectElement).value,
                    )}
                >
                  {sectors.map(sector => (
                    <option key={sector} value={sector}>
                      {
                        getSectorText(sector) === 'General'
                          ? 'All Sectors'
                          : getSectorText(sector, { shortText: !mediaQuery.get('mobileM') })}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
          <div
            className={classNames('lt2b-first-table', {
              'lt2b-hidden': majorHoldingsType === 'other',
            })}
            id="lt2b-table1"
          >
            <div className="lt2b-lists-title">
              <div className="lt2b-title-with-icon">
                <img src={headIconGr} /> My Portfolio
              </div>
              <div className="lt2b-title-with-icon2">
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>
                    <b>
                      <Company theme={theme} />
                    </b>{' '}
                    Average Portfolio
                  </span>
                ) : (
                    <span>
                      <b>
                        <span className="best-performing">
                          Best Performing
                      </span>{' '}
                        <Company theme={theme} />
                      </b>{' '}
                      Portfolio
                  </span>
                  )}
              </div>
            </div>
            <div className="lt2b-first-list-tite">
              <span className="sector-tite">Sector</span>
              <span className="first-company-tite">Company Name</span>
              <span className="percent-title">% of Portfolio</span>
              <span className="lt2b-sec-cn second-company-tite">
                Company Name
              </span>
              <span className="percent-title second-percent-title">
                % of Portfolio
              </span>
            </div>
            <div className="lt2b-first-list">
              <ul className="lt2b-btn-list">
                {sectors
                  .filter(sectorId => sectorId !== 'other')
                  .map(sector => (
                    <li key={sector}>
                      <button
                        className={classNames('lt2b-btn-table', {
                          'lt2b-btn-active': majorHoldingsSector === sector,
                        })}
                        onClick={() => onMajorHoldingsSectorChange(sector)}
                      >
                        {
                          getSectorText(sector) === 'General'
                            ? 'All Sectors'
                            : getSectorText(sector)}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="lt2b-second-list">
              <div className="lt2b-title-with-icon-mob">
                <img src={headIconGr} />
                My Portfolio
              </div>
              <div className="lt2b-first-list-tite-mob">
                <span className="lt2b-c-name-mob">Company Name</span>
                <span className="lt2b-pr-port-mob">% of Portfolio</span>
              </div>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                <ol className="lt2b-company-name-list">
                  {majorHoldings.size === 0 && (
                    <div className="no-data no-data-major">
                      {majorHoldingsSector === 'general' ? (
                        <NoDataAddStocks
                          htmlProps={{ className: 'no-data-graph' }}
                          msg={{ addStocks: 'Add Stocks', noData: 'Your Holdings Chart is empty. {addButton} to start' }}
                          openAddStockDialog={addStocks}
                        />
                      ) : (
                          <div>
                            {majorHoldingsSector === 'other'
                              ? 'Currently, no stocks.'
                              : `Currently, no stocks in the ${getSectorText(majorHoldingsSector)} sector.`}
                          </div>
                        )}
                    </div>
                  )}
                  {majorHoldings.map(item => (
                    <li key={item.get('ticker')}>
                      <span className="float-right">
                        {betterToFixed(item.get('percentPortfolio') * 100)}%
                      </span>
                      <span className="item-company-name">
                        <span>{item.get('name')}</span>
                      </span>
                      &nbsp; (<PortfolioItemSelector
                        className="l2tb-table-link"
                        item={item}
                        type="ticker"
                        target="_blank"
                      />) &nbsp;
                    </li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="lt2b-second-list lt2b-second-list2">
              <div className="lt2b-title-with-icon-mob">
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>
                    <b>
                      <Company theme={theme} />
                    </b>{' '}
                    Average Portfolio
                  </span>
                ) : (
                    <span>
                      <b>
                        <span className="best-performing">
                          Best Performing
                      </span>{' '}
                        <Company theme={theme} />
                      </b>{' '}
                      Portfolio
                  </span>
                  )}
              </div>
              <div className="lt2b-first-list-tite-mob">
                <span className="lt2b-c-name-mob">Company Name</span>
                <span className="lt2b-pr-port-mob">% of Portfolio</span>
              </div>
              <Loader name="assetAllocation" />
              <div className="suit-content">
                {companyMajorHoldings.size > 0 ? (
                  <ol className="lt2b-company-name-list">
                    {companyMajorHoldings.map(item => (
                      <li key={item.get('ticker')}>
                        <span className="float-right" title={String(item.get('percentPortfolio') * 100)}>
                          {betterToFixed(item.get('percentPortfolio') * 100)}%
                        </span>
                        <span>{item.get('name')}</span>
                        &nbsp; (<PortfolioItemSelector
                          className="l2tb-table-link"
                          item={item}
                          type="ticker"
                          target="_blank"
                        />) &nbsp;
                      </li>
                    ))}
                  </ol>
                ) : (
                    <div className="no-company-mjr-holdings">
                      {majorHoldingsSector === 'other'
                        ? 'Currently, no stocks.'
                        : `Currently, no stocks in the ${getSectorText(majorHoldingsSector)} sector.`}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            className={classNames('lt2b-second-table', {
              'lt2b-hidden': majorHoldingsType === 'stock',
            })}
            id="lt2b-table2"
          >
            <div className="lt2b-lists-title">
              <div className="lt2b-title-with-icon">
                <img src={headIconGr} /> My Portfolio
              </div>
              <div className="lt2b-title-with-icon2">
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>
                    <b>
                      <Company theme={theme} />
                    </b>{' '}
                    Average Portfolio
                  </span>
                ) : (
                    <span>
                      <b>
                        <span className="best-performing">
                          Best Performing
                      </span>{' '}
                        <Company theme={theme} />
                      </b>{' '}
                      Portfolio
                  </span>
                  )}
              </div>
            </div>
            <div className="lt2b-second-list-tite">
              <span className="first-company-tite">Company Name</span>
              <span className="percent-title">% of Portfolio</span>
              {/*<span className="lt2b-inv-span">
                Investment Fee
              </span>*/}
              <span className="lt2b-sec-cn second-company-tite">
                Company Name
              </span>
              <span>% of Portfolio</span>
              {/*<span className="lt2b-inv-span">
                Investment Fee
              </span>*/}
            </div>

            <div className="lt2b-first-small-list">
              <div className="lt2b-title-with-icon-mob">
                <img src={headIconGr} />
                My Portfolio
              </div>
              <div className="lt2b-first-list-tite-mob">
                <span className="lt2b-c-name-mob">Company Name</span>
                <span className="lt2b-pr-port-mob">% of Portfolio</span>
              </div>
              {majorHoldingsButStocks.size === 0 && (
                <div className="no-data no-data-etfs">
                  No ETFs or Mutual Funds in your Portfolio.
                </div>
              )}
              <ol className="lt2b-small-list">
                {majorHoldingsButStocks.map(item => (
                  <li key={item.get('ticker')}>
                    <span className="padding-left float-right">
                      {betterToFixed(item.get('percentPortfolio') * 100)}%
                    </span>
                    <span>
                      <span className="name-and-ticker-etf">
                        <span>{item.get('name')}</span>
                      </span>
                      &nbsp; (<PortfolioItemSelector
                        className="l2tb-table-link"
                        item={item}
                        type="ticker"
                        target="_blank"
                      />)
                    </span>
                    &nbsp;
                    {/*<span className="float-right">
                        1.12%
                      </span>*/}
                  </li>
                ))}
              </ol>
            </div>
            <div className="lt2b-second-small-list lt2b-first-small-list2">
              <div className="lt2b-title-with-icon-mob" id="lt2b-icon-mob">
                <img src={trIconGr} />
                {selectedPortfolio === Portfolio.AveragePortfolio ? (
                  <span>Average Portfolio</span>
                ) : (
                    <span>
                      <b>
                        <span className="best-performing">Best Performing</span>
                      </b>{' '}
                      Portfolio
                  </span>
                  )}
              </div>
              <div className="lt2b-first-list-tite-mob">
                <span className="lt2b-c-name-mob">Company Name</span>
                <span className="lt2b-pr-port-mob">% of Portfolio</span>
              </div>
              {companyFunds.size ? (
                <ol className="company-funds">
                  {companyFunds.map(item => (
                    <li key={item.getIn(['stock', 'name'])}>
                      <div className="col col-1 col-name">
                        <span>{item.getIn(['stock', 'name'])}</span>
                        &nbsp;
                        <a
                          className="ticker"
                          href={`${getTickerUrl({
                            type: item.getIn(['stock', 'type']),
                            ticker: item.get('ticker'),
                          })}`}
                          target="_blank"
                        >
                          {item.getIn(['stock', 'ticker'])}
                        </a>
                      </div>
                      <div className="col col-2 col-percentPortfolio">
                        {betterToFixed(item.get('percentPortfolio') * 100)}%
                      </div>
                    </li>
                  ))}
                </ol>
              ) : (
                  <span id="company-funds" className="no-data no-data-etfs">
                    No ETFs or Mutual Funds in the Average {brandName} Portfolio.
                </span>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrowdInsightsMain;
