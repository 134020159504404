import React from 'react';
import Clickable from 'tipranks-clickable';
import Component from 'react-pure-render/component';

export default class ExternalClickable extends Component {
  render() {
    const { href, to } = this.props;
    const props =
      href && !to ? { target: '_blank', ...this.props } : this.props;
    return <Clickable {...props} />;
  }
}
