import { AppInsights } from 'applicationinsights-js';

export const initAppInsights = () => {
  // Setup Application Insights
  // if(!process.env.IS_RUN_LOCAL)
  const instrumentationKey =
    process.env.THEME === 'nasdaq'
      ? process.env.NOT_PROD
        ? '7b791000-4661-45a8-b868-2cbdd4e8cac6'
        : 'cfd63627-78e5-45ee-b8d1-e9d5af71c38d'
      : // tipranks
        process.env.NOT_PROD
        ? '4c625d91-f05a-4cd4-8ad3-4ad7da035493'
        : 'ab87e098-5ee0-4973-af3b-791f343657d4';

  (AppInsights.downloadAndSetup as any)({ instrumentationKey });
};
