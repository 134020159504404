import { createSelector } from 'reselect';
import { List } from 'immutable';

import {
  selectStocks,
  selectoHoldingsSortBy,
  selectIsHoldingsSortDescending,
  selectAlerts,
  addPercent,
} from '../dashboard/selectors';

import { PortfolioItem } from '../../common/records';
import { sortBy } from '../tr-dashboard-holdings-container/selectors';
import { PortfolioItemRecord } from 'sp/common/types';

// todo rename to items
export const selectScreenerStocks = createSelector(
  // superhack
  (props: any, stockType) => props[stockType].tickers,
  ({ dashboard }) => selectStocks(dashboard),
  ({ dashboard }) => selectoHoldingsSortBy(dashboard),
  ({ dashboard }) => selectIsHoldingsSortDescending(dashboard),
  ({ dashboard }) => selectAlerts(dashboard),
  (_, source = 'stockScreener') => source,
  (tickers, stocks, holdingsSortBy, isholdingsSortDescending, alerts, source) =>
    stocks
      .filter(stock => stock.get('source') === source)
      .filter(stock => tickers.some(ticker => ticker === stock.get('ticker')))
      .map(addPercent('target'))
      .map(addPercent('bestTarget'))
      .map(stock => {
        const alertHolder = alerts.find(
          alertsHolder => alertsHolder.get('ticker') === stock.get('ticker'),
        );
        const stockAlerts = alertHolder ? alertHolder.get('alerts') : List();
        return new PortfolioItem({
          ticker: stock.get('ticker'),
          name: stock.get('name'),
          stock,
          alerts: stockAlerts,
        });
      })
      .sort(sortBy('name', false)),
);

export const selectScreenerTickers = createSelector(
  selectScreenerStocks,
  items => items.map(item => item.get('ticker')),
);

export const selectScreenerStocksTotal = ({ dashboard }) =>
  dashboard.getIn(['data', 'screenerTotal']);
