import {
  BestTrade,
  HolidingOperationRecord,
  StockPickerNotRecord,
  PerfStock,
  PortfolioManagerRecord_NotImmutable,
  StockPickerRecord_Obj,
  PerfStockNotRecord,
} from '../../common/components/performance/records';
import * as React from 'react';
import { PersonStats } from 'sp/browser/tr-dashboard-user-performance-container/PersonStats';
import {
  FormatDate,
  PosNeg,
} from 'sp/browser/tr-dashboard-user-performance-container/UtilComponents';
import { FormattedNumber } from 'react-intl';
import ProgressBar from 'sp/browser/tr-dashboard-user-performance-container/ProgressBar';
import { MyPerfBox } from 'sp/browser/performance/MyPerfBox/MyPerfBox';
import {
  AnalystStockCoverage,
  StockGain,
} from 'sp/browser/tr-dashboard-user-performance-container/AnalystStockCoverage';
import * as styles from './styles.istyl';
import * as classNames from 'classnames';
import { ComponentData as AlocBoxData } from 'sp/browser/performance/allocation-box/container';
import { ComponentData as VolaBoxData } from 'sp/browser/performance/volatility-box/container';
import { ComponentData as PerfBoxData } from 'sp/browser/performance/portfolio-performance-box/container';
import { List } from 'immutable';
import { RiskTolerance, PortfolioPrivacy, AuthRecord, PortfolioRecord_NotImmutable } from 'sp/common/types';
import {
  WeightedHoldings,
  WeightedHolding,
  WeightedHoldingType,
} from 'sp/browser/dashboard/PortfolioManager';
import { HoldingOperation } from 'sp/common/api-types';
import { mediaQueryBetween } from 'sp/browser/lib/utils';
import { MediaQuery } from 'sp/common/lib/mediaQuery';
import { Icon } from 'tipranks-icons';
import { VariableSizeSpinner } from 'sp/browser/Spinner/index.react';

import { Link } from 'react-router';
import { toOnboarding } from 'sp/browser/pages/PublicPortfolio/Onboarding/utils';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { suits } from 'sp/common/enums';
import { PortfolioPrivacyBtnProps, PortfolioPrivacyBtn } from 'sp/browser/tr-dashboard-user-performance-container/PortfolioPrivacyBtn';
import { PortfolioShareLinks } from 'sp/browser/tr-dashboard-user-performance-container/PortfolioShareLinks';
import Clickable from '../Clickable/clickable.react';
import { Helmet } from 'react-helmet';
import { last } from 'lodash';
import { addPossession } from 'sp/browser/tr-dashboard-user-performance-container/utils';

export type PortfolioDataForMyPerfPage = {
  id: number;
  allocationBox: AlocBoxData;
  bestTrade: BestTrade;
  creationDate: string;
  dividendYield: number;
  hasEnteredHoldings: boolean;
  holdingOperations: HoldingOperation[];
  pickers: StockPickerNotRecord[];
  portfolioManager: PortfolioManagerRecord_NotImmutable | null;
  portfolioPerformanceBox: PerfBoxData;
  risk: RiskTolerance | null;
  sixMonthReturn: number;
  portfolioItemsGains: StockGain[];
  yearlyReturn: number;
  userName: string;
  userPic: string;
  volatilityBox: VolaBoxData;
  weightedHoldings: WeightedHoldingType[];
  ytdReturn: number;
  description?: string;
  name?: string;
  // TODO consider making MyPerformancePageProps generic (either public or private) to allow more fine grained interfaces
  portfolioPrivacyBtnProps?: PortfolioPrivacyBtnProps
}

export type MyPerformancePageProps = PortfolioDataForMyPerfPage & {
  activePortfolioName: string;
  benchmark: number;
  period: number;
  mediaQuery: MediaQuery;
  onBenchmarkChange: any;
  onModeChange: any;
  onPeriodChange: any;
  displayTab: string; // ui.performanceDisplayTab
  isPublicPortfolio: boolean;
  isMyPublicPortfolio: boolean;
  isPermanentLoggedIn: boolean;
  onClickedWhenEditingPrevented?: any;
  onNameChange?: any;
  openMyHoldingsDialog?: any;
  acts?: any;
  auth: AuthRecord;
};
export class MyPerformancePageComponent extends React.PureComponent<MyPerformancePageProps & {}> {
  private get thisMonthPerformance() {
    if (this.props.portfolioPerformanceBox.myPortfolioData.length === 0) { return 0; }
    // copied because sort is mutative :(
    const copiedPortfolioData = [...this.props.portfolioPerformanceBox.myPortfolioData];
    copiedPortfolioData.sort((a, b) => {
      const byYear = b.year - a.year;
      const byMonth = b.month - a.month;

      return byYear !== 0 ? byYear : byMonth;
    })
    const latestMonthData = copiedPortfolioData[0];
    if (!latestMonthData || !latestMonthData.gainValue) { return 0; }
    return latestMonthData.gainValue;
  }
  render() {
    const {
      activePortfolioName,
      acts,
      allocationBox,
      benchmark,
      bestTrade,
      creationDate,
      dividendYield,
      hasEnteredHoldings,
      isPermanentLoggedIn,
      mediaQuery,
      onBenchmarkChange,
      onClickedWhenEditingPrevented,
      onModeChange,
      onNameChange,
      onPeriodChange,
      openMyHoldingsDialog,
      holdingOperations,
      displayTab,
      period,
      portfolioManager,
      portfolioPerformanceBox,
      risk,
      sixMonthReturn,
      portfolioItemsGains,
      yearlyReturn,
      userName,
      volatilityBox,
      weightedHoldings,
      ytdReturn,
      userPic,
      isPublicPortfolio,
      portfolioPrivacyBtnProps,
    } = this.props;

    // risk profile
    const isRiskLow = risk === 'Low' || risk === 'Very low';
    const isRiskMedium = risk === 'Medium';
    const isRiskHigh = risk === 'High' || risk === 'Very High';
    const picker = this.chooseStockPickerAccordingToUI();
    const spStocks = picker.stocks;
    const avgReturn = picker.averageReturn;
    const goodTransactions = picker.goodTransactions;
    const totalTransactions = picker.totalTransactions;
    const stocksLength = picker.stocks.length;
    const portfolioItemsCount = portfolioItemsGains.length;

    const manager = portfolioManager || { portfolioReturn: 0 };

    // The reason this is here instead of inline in <title></title> is
    // https://github.com/nfl/react-helmet/issues/408
    const title = `${userName} Portfolio Performance`
    const description = `${addPossession(userName)} portfolio performance reached ${this.thisMonthPerformance.toFixed(2)}% gain in the last month. Follow ${addPossession(userName)} investing activity as well as more than 15,000 individual investors using TipRanks to make data-driven investment decisions every day.`;

    return (
      <div className="tr-dashboard-user-performance">
        {isPublicPortfolio && (
          <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
          </Helmet>
        )}
        {process.env.THEME !== 'nasdaq' && !isPublicPortfolio && <PortfolioPrivacyBtn {...portfolioPrivacyBtnProps!} />}
        {isPublicPortfolio && <PortfolioShareLinks slug={'hello'} />}
        <div className="portfolio-overview-container">
          <header className={'pageSectionHeaderCoverage'}>
            <h1 className={'pageSectionH1 pageSectionH1StockInfo'}>
              {isPublicPortfolio ? "Investor Profile" : 'My Performance'}
            </h1>
          </header>
          <div className="portfolio-overview">
            <PersonStats
              avgReturn={avgReturn}
              goodTransactions={goodTransactions}
              totalTransactions={totalTransactions || 0}
              creationDate={creationDate}
              hasEnteredHoldings={hasEnteredHoldings}
              userPic={userPic}
              userName={userName}
              onModeChange={onModeChange}
              onNameChange={onNameChange}
              onBenchmarkChange={onBenchmarkChange}
              onPeriodChange={onPeriodChange}
              benchmark={benchmark}
              period={period}
              displayTab={displayTab}
              openMyHoldingsDialog={openMyHoldingsDialog}
              isPermanentLoggedIn={isPermanentLoggedIn}
              onClickedWhenEditingPrevented={onClickedWhenEditingPrevented}
              preventEditing={isPublicPortfolio}
              picker={picker}
              portfolioManager={portfolioManager}
              isPublicPortfolio={isPublicPortfolio}
              activePortfolioName={activePortfolioName}
            />
            <div className="special-layout-wrapper">
              {this.renderBio(true)}
              <div className="shadow-box portfolio-details">
                <div className="title">Portfolio Details</div>
                <ul>
                  <li className="activeSince">
                    <span className="head">Active Since: </span>
                    <span className="value">
                      {creationDate && <FormatDate date={creationDate} />}
                    </span>
                  </li>
                  <li>
                    <span className="head">Risk Profile: </span>
                    <span className="value">
                      <span
                        className={classNames({
                          'active-risk-value': isRiskLow,
                        })}
                      >
                        Low
                      </span>{' '}
                      /&nbsp;
                      <span
                        className={classNames({
                          'active-risk-value': isRiskMedium,
                        })}
                      >
                        Medium
                      </span>{' '}
                      /&nbsp;
                      <span
                        className={classNames({
                          'active-risk-value': isRiskHigh,
                        })}
                      >
                        High
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="head">No. of Trades: </span>
                    <span className="value numOfTrades">
                      {totalTransactions || '-'}
                    </span>
                  </li>
                  <li>
                    <span className="head">Est. Portfolio Dividend Yield: </span>
                    <span className="value">
                      <FormattedNumber
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        style="percent"
                        value={dividendYield}
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="shadow-box portfolio-gain">
                <div className="title">Portfolio Gain</div>
                <ul>
                  <li className={classNames({ hasValue: !!sixMonthReturn })}>
                    <span className="head">6 Month Returns: </span>
                    <span className="value">
                      {sixMonthReturn ? (
                        <PosNeg gain={sixMonthReturn} />
                      ) : (
                          '+/- %'
                        )}
                    </span>
                  </li>
                  <li className={classNames({ hasValue: !!yearlyReturn })}>
                    <span className="head">12 Month Returns (TTM): </span>
                    <span className="value">
                      {yearlyReturn ? <PosNeg gain={yearlyReturn} /> : '+/- %'}
                    </span>
                  </li>
                  <li className={classNames({ hasValue: !!ytdReturn })}>
                    <span className="head">YTD Returns: </span>
                    <span className="value">
                      {ytdReturn ? <PosNeg gain={ytdReturn} /> : '+/- %'}
                    </span>
                  </li>
                </ul>
              </div>
              {bestTrade && bestTrade.ticker && (
                <div className="shadow-box best-trade">
                  <div className="title">Best Trade</div>
                  <ul>
                    <li>
                      <span className="head">Stock: </span>
                      <span className="value">
                        <span className="stockDisplayName">
                          {bestTrade.displayName}
                        </span>
                        <span className="stockDisplayTicker">
                          &nbsp;({bestTrade.ticker})
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="head">
                        {bestTrade.closeDate !== null
                          ? 'Date Range'
                          : 'Date Opened'}:{' '}
                      </span>
                      <span className="value executionTime">
                        {' '}
                        <FormatDate date={bestTrade.executionTime} />{' '}
                        {bestTrade.closeDate !== null && (
                          <span>
                            - <FormatDate date={bestTrade.closeDate as any} />
                          </span>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="head">Gain: </span>
                      <span className="value">
                        {bestTrade.gainPercent ? (
                          <PosNeg gain={bestTrade.gainPercent} />
                        ) : (
                            '+/- %'
                          )}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.renderBio(false)}
        <div className="table-wrapper">
          {process.env.THEME === 'nasdaq' /* gilad wants this off for a while in tipranks */ && acts && !isPublicPortfolio && mediaQuery.get('laptopXS') ? (
            <div className={styles.progressBarTable}>
              <ProgressBar {...acts} />
            </div>
          ) : (
              ''
            )}
          <MyPerfBox
            mediaQuery={mediaQuery}
            portfolioPerformanceBox={portfolioPerformanceBox}
            allocationBox={allocationBox}
            volatilityBox={volatilityBox}
          />
          <AnalystStockCoverage
            mediaQuery={mediaQuery}
            portfolioItemsCount={portfolioItemsCount}
            totalTransactions={totalTransactions || 0}
            weightedHoldings={weightedHoldings}
            spStocks={spStocks}
            holdingOperations={holdingOperations}
            portfolioItemsGains={portfolioItemsGains}
            stocksLength={stocksLength}
            isPublicPortfolio={isPublicPortfolio}
          />
        </div>
      </div>
    );
  }
  /**
   * The addition of the bio box caused the special-layout-wrapper
   * to be 4 boxes - too long. @eyal and I put a patch on SHIRLY'S DESIGN // it is her design daniz
   * by moving the bio box in tablet resolutions outside the list.
   */
  renderBio = (isInsideList: boolean) => {
    const { mediaQuery, description, isMyPublicPortfolio } = this.props;
    const yes = mediaQueryBetween(mediaQuery, '768', '1366');
    if (
      description &&
      (isInsideList || yes) &&
      !(isInsideList && yes)
    ) {
      return <div className={`shadow-box portfolio-description${isInsideList ? '' : '-outside'}`}>
        {/* isMyPublicPortfolio && (
          <Clickable className="editLink" href="/account/settings">
            <img src="/assets/img/edit.png" alt="edit" />
          </Clickable>
        ) */}
        <div className="title">Portfolio Description</div>
        <div className="contentaway">{description}</div>
      </div>
    }
  }

  chooseStockPickerAccordingToUI = () => {
    const { pickers, benchmark, period } = this.props;

    return pickers.find(
      picker =>
        picker.benchmark === benchmark &&
        picker.period === period,
    ) as StockPickerNotRecord || {
        stars: 0,
        period: 0,
        rank: 0,
        averageReturn: 0,
        totalRanked: 0,
        goodTransactions: 0,
        totalTransactions: 0,
        stocks: [] as any[],
        benchmark: 0,
      };
  }
}
