import reporter from '../analytics';

const events = {
  clickedContactUs: 'clicked-side-bar-contact-us',
  clickedFaq: 'clicked-side-bar-faq',
  clickedTakeTour: 'clicked-side-bar-take-a-tour'
};

const report = reporter(events);

export default report;
