import { Link } from 'react-router';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { AdvancedTable } from './AdvancedTable';
import NoDataSection from './NoDataSection.react';

import { exportToPortfolioCSV } from '../tr-portfolio-csv';
import { BasicTable } from '../tr-dashboard-holdings-table';

import { isIOS } from '../../common/lib/utils.ts';
import { holdingsSectionConfig } from '../../common/config';
import { isShittyBrowser } from 'sp/browser/lib/utils';

import {
  getRawCols,
  getCols
} from '../tr-dashboard-holdings-table/conf';
import { Icon } from 'tipranks-icons';
import { BasicTableType } from '../../common/types';
import { WaitingNotice } from './WaitingNotice';
import * as waitingNoticeStyles from './WaitingNotice.istyl'

const menuItems = (toggleTbl, openAlertsSettings) => [
  {
    click: () => toggleTbl('advanced'),
    id: 'advancedTbl',
    icon: 'detailedTable',
    alwaysEnabled: true,
    value: 'advanced',
    text: 'Detailed'
  },
  {
    click: () => toggleTbl('basic'),
    id: 'basicTbl',
    icon: 'basicTable',
    alwaysEnabled: true,
    value: 'basic',
    text: 'Basic'
  },
  {
    click: process.env.THEME === 'web' ? () => window.location.assign('/account/personal-alerts') : openAlertsSettings,
    id: 'settings',
    icon: 'envelope',
    text: 'Email Notifications'
  }
];

// TODO rename file
export default class HoldingsSection extends Component {
  // static propTypes = {
  //   activePage: PropTypes.number,
  //   cashPercent: PropTypes.number,
  //   cashValue: PropTypes.number,
  //   dependencies: PropTypes.any,
  //   editableId: PropTypes.string,
  //   enums: PropTypes.object,
  //   holdingsCols: PropTypes.object,
  //   holdingsSortBy: PropTypes.string,
  //   isAlertsDisabled: PropTypes.bool,
  //   isCombinedPortfolio: PropTypes.bool,
  //   isImportedPortfolio: PropTypes.bool,
  //   isholdingsSortDescending: PropTypes.bool,
  //   isPermanentLoggedIn: PropTypes.bool,
  //   items: PropTypes.object,
  //   mediaQuery: PropTypes.object,
  //   modes: PropTypes.object,
  //   msg: PropTypes.object,
  //   noDataMsg: PropTypes.object,
  //   pages: PropTypes.object,
  //   portfolioId: PropTypes.number,
  //   portfolioName: PropTypes.string,
  //   priceDependencies: PropTypes.any,
  //   tblType: PropTypes.oneOf(['basic', 'advanced']),
  //   viewerPlan: PropTypes.string,
  //   shouldDisplayAddStocksButton: PropTypes.bool,

  //   activateStock: PropTypes.func,
  //   activatePage: PropTypes.func,
  //   changeEditable: PropTypes.func,
  //   clickedExportCSV: PropTypes.func,
  //   deleteItem: PropTypes.func,
  //   fetchData: PropTypes.func,
  //   fetchPriceData: PropTypes.func,
  //   hideTooltip: PropTypes.func,
  //   insertBefore: PropTypes.func,
  //   openAddStockDialog: PropTypes.func,
  //   openAlertsSettings: PropTypes.func,
  //   openHoldingsMetrics: PropTypes.func,
  //   openPortfolioItem: PropTypes.func,
  //   openPortfolioItemAlerts: PropTypes.func,
  //   openRegister: PropTypes.func,
  //   openSyncDialog: PropTypes.func,
  //   setPurchasePrice: PropTypes.func,
  //   setShares: PropTypes.func,
  //   setStockLastRead: PropTypes.func,
  //   showPromotion: PropTypes.func,
  //   showTooltip: PropTypes.func,
  //   sortHoldingsTable: PropTypes.func,
  //   onClickAlert: PropTypes.func,
  //   toggleTblType: PropTypes.func
  // setBasicTableType,
  // basicTableType
  // };

  normalizePage = page => {
    const { pages } = this.props;
    const lastPge = pages.size - 1;
    if (page < 0) return 0;
    if (lastPge < page) return lastPge;
    return page;
  };

  activatePage = page => {
    const { activatePage } = this.props;
    return () => activatePage(this.normalizePage(page));
  };

  toggleTbl = tblId => {
    const { toggleTblType } = this.props;
    toggleTblType(tblId);
  };

  exportToCSV = () => {
    const {
      cashValue,
      cashPercent,
      clickedExportCSV,
      enums,
      holdingsCols,
      isPermanentLoggedIn,
      items,
      mediaQuery,
      openRegister,
      portfolioName,
      msg,
      viewerPlan,
      tblType,
      basicTableType
    } = this.props;

    clickedExportCSV();

    // if (!isPermanentLoggedIn) {
    //   openRegister();
    //   return;
    // }

    const cols = tblType === 'basic' ? getBasicTableCols(mediaQuery, basicTableType) : holdingsCols;

    exportToPortfolioCSV({
      cashValue,
      cashPercent,
      enums,
      fileName: `${portfolioName}.csv`,
      holdingsCols: cols,
      items,
      viewerPlan
    });
  };

  render() {
    const {
      activateStock,
      activePage,
      changeEditable,
      deleteItem,
      dependencies,
      editableId,
      enums,
      fetchData,
      fetchPriceData,
      getDetailGroups,
      hideTooltip,
      holdingsCols,
      holdingsSortBy,
      insertBefore,
      isAlertsDisabled,
      isCombinedPortfolio,
      isImportedPortfolio,
      isPendingPortfolio,
      isholdingsSortDescending,
      items,
      mediaQuery,
      modes,
      msg,
      noDataMsg,
      openAddStockDialog,
      openAlertsSettings,
      openHoldingsMetrics,
      openPortfolioItem,
      openPortfolioItemAlerts,
      openSyncDialog,
      pages,
      portfolioId,
      priceDependencies,
      setPurchasePrice,
      setShares,
      setStockLastRead,
      showPromotion,
      showTooltip,
      sortHoldingsTable,
      tblType,
      onClickAlert,
      viewerPlan,
      shouldDisplayAddStocksButton,
      setBasicTableType,
      basicTableType,
      auth,
      fetchPortfolios,
      changePortfolio,
    } = this.props;

    const TableComponent =
      mediaQuery.get('tablet') && tblType === 'basic'
        ? BasicTable
        : AdvancedTable;

    return (
      <section
        className={classNames('holdings-section', {
          'alerts-disabled': isAlertsDisabled
        })}
      >
        <h1>{msg.title}</h1>
        <menu className="top-menu">
          <ul>
            {menuItems(this.toggleTbl, openAlertsSettings).map(item => (
              <li
                className={classNames(item.id, {
                  desktopOnly: item.desktopOnly
                })}
                key={item.id}
              >
                {mediaQuery.get('laptop') && item.link ? (
                  <Link to={item.link}>{msg.menu[item.id]}</Link>
                ) : (
                    <button
                      className={classNames({ active: item.value === tblType })}
                      disabled={
                        (!item.alwaysEnabled && items.size === 0) ||
                        (item.id === 'settings' && isCombinedPortfolio)
                      }
                      onClick={item.click}
                      title={msg.menu[item.id]}
                    >
                      <Icon icon={item.icon} />
                      <span>{item.text}</span>
                    </button>
                  )}
              </li>
            ))}
          </ul>
        </menu>
        <TableComponent
          setBasicTableType={setBasicTableType}
          basicTableType={basicTableType}
          activateStock={activateStock}
          activePage={activePage}
          changeEditable={changeEditable}
          deleteItem={deleteItem}
          dependencies={dependencies}
          editableId={editableId}
          enums={enums}
          getCols={getCols(getRawCols)}
          getDetailGroups={getDetailGroups}
          fetchData={fetchData}
          fetchPriceData={fetchPriceData}
          hideTooltip={hideTooltip}
          holdingsCols={holdingsCols}
          holdingsSortBy={holdingsSortBy}
          insertBefore={insertBefore}
          isCombinedPortfolio={isCombinedPortfolio}
          isImportedPortfolio={isImportedPortfolio}
          isholdingsSortDescending={isholdingsSortDescending}
          items={items}
          maxTable={holdingsSectionConfig.get('maxTable')}
          mediaQuery={mediaQuery}
          modes={modes}
          msg={msg}
          noDataMsg={noDataMsg}
          openAddStockDialog={openAddStockDialog}
          openAlertsSettings={openAlertsSettings}
          openHoldingsMetrics={openHoldingsMetrics}
          openPortfolioItem={openPortfolioItem}
          openPortfolioItemAlerts={openPortfolioItemAlerts}
          openSyncDialog={openSyncDialog}
          portfolioId={portfolioId}
          priceDependencies={priceDependencies}
          setPurchasePrice={setPurchasePrice}
          setShares={setShares}
          setStockLastRead={setStockLastRead}
          showPromotion={showPromotion}
          showTooltip={showTooltip}
          sortHoldingsTable={sortHoldingsTable}
          tblType={tblType}
          onClickAlert={onClickAlert}
          viewerPlan={viewerPlan}
          shouldDisplayAddStocksButton={shouldDisplayAddStocksButton}
          tableSortable
        >
          {isPendingPortfolio
            ? (<div className={waitingNoticeStyles.tablePositioningContainer}>
              <WaitingNotice />
            </div>
            )
            : (
              <NoDataSection
                fetchPortfolios={fetchPortfolios}
                changePortfolio={changePortfolio}
                msg={noDataMsg}
                openAddStockDialog={openAddStockDialog('empty-holdings')}
                openSyncDialog={openSyncDialog('empty-holdings')}
                auth={auth}
              />
            )}
        </TableComponent>
        {mediaQuery.get('laptop') &&
          items.size > 0 &&
          pages.size > 1 && (
            <menu className="bottom-menu">
              <button
                className="back"
                disabled={activePage === 0}
                onClick={this.activatePage(activePage - 1)}
              >
                <img alt="" src="/assets/img/arrow.svg" />
              </button>
              <ul>
                {pages.map(page => (
                  <li className={classNames({ active: page === activePage })}>
                    <button
                      disabled={page === activePage}
                      onClick={this.activatePage(page)}
                    >
                      {page + 1}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                className="next"
                disabled={activePage === pages.size - 1}
                onClick={this.activatePage(activePage + 1)}
              >
                <img alt="" src="/assets/img/arrow.svg" />
              </button>
            </menu>
          )}
        <div className="controls-below-table">
          {items.size > 0 &&
            !isIOS() && (
              <button className="csv" onClick={this.exportToCSV}>
                Download
              </button>
            )}
          {!mediaQuery.get('laptop') && (
            <button
              className="alerts"
              onClick={process.env.THEME === 'web' ? () => window.location.assign('/account/settings') : openAlertsSettings}
            >
              Email Alert Settings
            </button>
          )}
          {mediaQuery.get('laptop') &&
            tblType !== 'basic' &&
            items.size > 0 && (
              <button className="metrics" onClick={openHoldingsMetrics}>
                Add Metrics to Chart
              </button>
            )}
        </div>
      </section>
    );
  }
}

export function getBasicTableCols(mediaQuery: MediaQuery, basicTableType: BasicTableType) {
  return getCols(getRawCols)(mediaQuery)
    .map((col: ColType) =>
      col.set('id', col.getIn(['alternatives', basicTableType], col.get('id')))
    )
    .map((col: ColType) => col.get('id'));
}