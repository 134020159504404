import * as React from 'react';
import Component from 'react-pure-render/component';
import '../styles/main.styl';
import DistributionPicker from './distributionpicker.react';
import { HoldingStats } from './holdingstats.react';
import AssetAllocationGraph from '../../AllocChart/src/assetallocationgraph.react';
import InfoTooltip from '../../../../../../InfoTooltip/index';

export default class HoldingsChart extends React.PureComponent<
  {
    asset: any;
    activeAsset: any;
    pickedIndex: any;
    colors: any;
    id: any;
    isSwipe: any;
    msg?: any;
  },
  {
    picked?: any;
    pickedIndex?: any;
    pickedColor?: any;
  }
> {
  constructor(props) {
    super(props);
    this.state = this.getInitialPicks(props);
  }

  getInitialPicks(props = this.props) {
    if (!props.asset) return {};
    const pickedIndex =
      this.state && props.activeAsset === this.props.activeAsset
        ? this.state.pickedIndex
        : 0;
    const picked = props.asset.get(pickedIndex);
    const pickedColor = props.colors.get(props.activeAsset).get(pickedIndex);
    return { picked, pickedColor, pickedIndex };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.getInitialPicks(nextProps));
  }

  onDistributionPicked = id => {
    let pickedIndex, picked;
    let { asset, colors, activeAsset } = this.props;
    colors = colors.get(activeAsset);
    if (id < 0) id += asset.size;
    // multimethods ftw
    if (typeof id === 'number') {
      picked = asset.find((x, i) => i === id);
      pickedIndex = id;
    } else if (typeof id === 'string') {
      const entry = asset.findEntry(x => x.get('id') === id);
      picked = entry[1];
      pickedIndex = entry[0];
    } else {
      picked = id;
      pickedIndex = asset.findIndex(x => x === id);
    }
    this.setState({
      pickedIndex,
      picked,
      pickedColor: colors.get(pickedIndex)
    });
  };

  render(
    { picked, pickedIndex, pickedColor } = this.state,
    { asset, colors, id, isSwipe, activeAsset, msg } = this.props
  ) {
    if (!asset) return null;
    colors = colors.get(activeAsset);
    return (
      <div className={`tr-holdings-graph`}>
        <div className="stock-distribution-header">
          {msg.title}
          <InfoTooltip
            msg={msg.tooltip}
            expectedHeight={undefined as any}
            expectedWidth={undefined as any}
          />
        </div>
        <div className="stock-distribution-body">
          <div className="graph-and-picker">
            <div className="graph-and-text">
              <div className="stock-distribution-text">{msg.pieInner}</div>
              <div className="asset-allocation-graph-wrapper">
                <AssetAllocationGraph
                  asset={asset}
                  animated={true}
                  onSliceClicked={this.onDistributionPicked}
                  activeDatum={picked.get('id')}
                  thickness={0.45}
                  colors={colors}
                  className="holdings-allocation-graph"
                  type="deep"
                />
              </div>
            </div>
            <DistributionPicker
              colors={colors}
              types={asset}
              picked={picked}
              onChange={this.onDistributionPicked}
            />
          </div>
          <div className={`holding-stats-wrapper picked-${pickedIndex}`}>
            <HoldingStats
              isSwipe={isSwipe}
              activeAsset={activeAsset}
              onChange={this.onDistributionPicked}
              pickedColor={pickedColor}
              pickedIndex={pickedIndex}
              asset={asset}
              picked={picked}
            />
          </div>
        </div>
      </div>
    );
  }
}
