// import { PublicPortfolio } from 'sp/browser/tr-dashboard-user-performance-container/public.profile.selectors';
import {
  FETCH_PUBLIC_PORTFOLIO_START,
  FETCH_PUBLIC_PORTFOLIO_ERROR,
  FETCH_PUBLIC_PORTFOLIO_SUCCESS,
} from 'sp/browser/tr-dashboard-user-performance-container/public.actions';
import { suits } from 'sp/common/enums';
import { PortfolioDataForMyPerfPage } from 'sp/browser/tr-dashboard-user-performance-container/component';
import { List } from 'immutable';
import { REDUX_STORAGE_LOAD } from 'sp/common/auth/actions';

export type PublicPortfolioStore = {
  loadingState: suits,
  publicPortfolioId: string | null,
  currentPublicPortfolio: PortfolioDataForMyPerfPage,
  errorCode: number | null;
}

export const initialState: PublicPortfolioStore = {
  loadingState: suits.INITIAL,
  publicPortfolioId: null,
  errorCode: null,
  currentPublicPortfolio: {
    id: 0,
    allocationBox: {
      assetAllocation: [] as any[],
      sectorAllocation: [] as any[],
      companyAllocation: [] as any[]
    },
    bestTrade: {
      portfolioID: 0,
      ticker: '',
      displayName: '',
      executionTime: '',
      closeDate: '',
      gainAmount: 0,
      gainPercent: 0,
      sharesTraded: 0,
      price: 0,
      status: null,
      stockType: 0,
      stockTypeId: 9
    },
    creationDate: '',
    dividendYield: 0,
    hasEnteredHoldings: true,
    pickers: [],
    portfolioManager: {
      rank: 0,
      stars: 0,
      dateStartedTrading: '',
      portfolioReturn: 0,
      sharpe: 0,
      totalRanked: 0,
      averageSharpe: 0,
    },
    portfolioPerformanceBox: {
      myPortfolioData: [],
      avgPortfolioData: [],
      bestPortfolioData: [],
      snpPortfolioData: [],
    },
    risk: null,
    sixMonthReturn: 0,
    portfolioItemsGains: [] as any[],
    yearlyReturn: 0,
    userName: '',
    userPic: '',
    volatilityBox: {
      betaValue: 0,
      volatilityItems: [] as any[],
      avgPortfolioPercentValue: 0,
      myPortfolioPercentValue: 0,
    },
    ytdReturn: 0,
    holdingOperations: [] as any[],
    weightedHoldings: [] as any[]
  },
};

export default function publicReducer(
  state = initialState,
  action: { type: string, payload: any, error?: any },
): PublicPortfolioStore {
  switch (action.type) {
    case REDUX_STORAGE_LOAD: {
      const restoredState = action.payload.publicPortfolioz;
      if (!restoredState) return state;
      return restoredState;
    }
    case FETCH_PUBLIC_PORTFOLIO_START:
      return {
        ...state,
        publicPortfolioId: action.payload.portfolioId,
        loadingState: suits.LOADING,
      };

    case FETCH_PUBLIC_PORTFOLIO_ERROR:
      if (action.error) {
        if (process.env.IS_RUN_LOCAL) {
          console.warn('An error has occured in the public portfolio reducer', action)
        }
      }
      return {
        ...state,
        publicPortfolioId: action.payload.portfolioId,
        loadingState: suits.ERROR,
        errorCode: action.payload.error.status
      };
    case FETCH_PUBLIC_PORTFOLIO_SUCCESS:
      return {
        currentPublicPortfolio: action.payload,
        publicPortfolioId: action.payload.portfolioId,
        loadingState: suits.SUCCESS,
        errorCode: null,
      };
    default: return state;
  }
}
