import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

export default class IndexCell extends Component {
  static propTypes = {
    deleteItem: PropTypes.func,
    index: PropTypes.number,
    isCombinedPortfolio: PropTypes.bool,
    isImportedPortfolio: PropTypes.bool,
    stock: PropTypes.object
  };

  render() {
    const {
      deleteItem,
      index,
      isCombinedPortfolio,
      isImportedPortfolio,
      stock,
      only
    } = this.props;

    return (
      <div
        className={classNames(`index-cell-content`, {
          importedPortfolio: isImportedPortfolio,
          combinedPortfolio: isCombinedPortfolio
        })}
      >
        <span className={classNames(`count`)}>{index + 1}</span>
        {!only && (
          <button onClick={() => deleteItem(stock.get('ticker'))}>
            <span>+</span>
          </button>
        )}
      </div>
    );
  }
}
