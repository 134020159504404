import Component from 'react-pure-render/component';
import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

export default class Page extends Component {
  static propTypes = {
    msg: PropTypes.object
  };

  render() {
    const { msg: { home: msg } } = this.props;

    return (
      <div className="home-page">
        <Helmet title={msg.title} />
        <p>
          <FormattedHTMLMessage
            defaultMessage={msg.infoHtml}
            id={msg.infoHtml}
          />
        </p>
      </div>
    );
  }
}
