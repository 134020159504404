import * as React from 'react';

const canada = require('./images/canada.png');
const usa = require('./images/usa.png');
const uk = require('./images/uk.png');

interface ICountry {
  flag: any;
  alt: string;
}

const getCountryObjByTicker = (ticker: string): ICountry  => {
  return ticker.toLowerCase().startsWith('tse:') ? 
      {flag: canada, alt: 'Canadian stock'} : 
      (
        ticker.toLowerCase().startsWith('gb:') ?  
          {flag: uk, alt: 'United Kindom'} :  
            {flag: usa, alt: 'American stock'}
      )
}

const Flag = ({ ticker = '' }) => {
  const country = getCountryObjByTicker(ticker);
  return <img
    src={country.flag}
    alt={country.alt}
  />
  };

export default Flag;
