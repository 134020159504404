import * as React from 'react';
import * as ReactSwipe from 'react-swipe';

const Arrow = (
  <svg viewBox="66.758 203.103 21.987 35.655" width="21.987" height="35.655">
    <g id="chevron-right">
      <polygon
        fill="#00B6C5"
        points="70.918,203.103 66.758,207.263 80.425,220.931 66.758,234.598 70.918,238.758 88.745,220.931"
      />
    </g>
  </svg>
);

export class Swipe extends React.Component <any, any>{
  swipe: any;

  constructor(props) {
    super(props);
    this.state = { 
      display: true, 
      active: this.props.slideToIndex || 0, 
      length: (this.props.children as any[]).length 
    };
  }

  componentDidMount() {
    // epic HACK: trigger resize manually due to:
    // https://tipranks.atlassian.net/browse/TIPRANKS-10491?filter=16103
    // this event causes the swipe library to rerun
    // see: jspm_packages/npm/swip-js-iso@2.0.1/swipe.js:258
    Rx.Observable.interval(500).take(4).subscribe(() => window.dispatchEvent(new Event('resize')));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ active: nextProps.slideToIndex });
    if ((this.props.children as any[]).length !== (nextProps.children as any[]).length) {
      this.setState({length: (nextProps.children as any[]).length});
      this.changeChildren();
    }
  }

  next = () => {
    this.swipe.next();
    this.setSlide((this.state.active + 1) % this.state.length);
  }

  prev = () => {
    this.swipe.prev();
    this.setSlide((this.state.active - 1) % this.state.length);
  }

  setSlide = active => {
    if (this.props.onChange) {
      this.props.onChange(active % this.state.length);
    }
    this.setState({ active: active });
  };


  changeChildren() {
    this.setState({ display: false });
    setTimeout(() => this.setState({ display: true }), 10);
  }

  render(
    { active, display } = this.state,
    { children, className } = this.props
  ) {
    const continuous = this.state.length > 1;
    return (
      <div className={className}>
        {display && (
          <ReactSwipe
            {...this.props}
            childCount={this.state.length}
            swipeOptions={{
              callback: this.setSlide,
              startSlide: active,
              continuous: continuous,
              stopPropagation: true
            }}
            ref={swipe => this.swipe = swipe}
          >
            {children}
          </ReactSwipe>
        )}
        <button className="prev" onClick={this.prev}>
          {Arrow}
        </button>
        <button className="next" onClick={this.next}>
          {Arrow}
        </button>
      </div>
    );
  }
}
