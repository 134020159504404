import { getGlobalActions } from '../../lib/actions';
import * as React from 'react';
import { Dashboard } from 'sp/common/types';
import {
  VolatilityBox,
  VolatilityItem,
} from 'sp/browser/performance/volatility-box';
import { selectoPortfolioAnalysis } from 'sp/browser/dashboard/routes/analysis/portfolio/selectors';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { riskRange } from 'sp/browser/tr-business-rules/risk-level';
import { selectVolatilityBoxStocksByBeta } from 'sp/browser/performance/volatility-box/selectors';
import { TypesOfPortfolios } from 'sp/common/enums';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export interface Props /* extends React.HTMLAttributes<HTMLDivElement>*/ {
  mediaQuery: MediaQuery;
  componentData?: ComponentData;
}
export type PropsWithConnect = Props & {
  dashboard: Dashboard;
};

@connect(({ dashboard }) => ({ dashboard /*, actions: getGlobalActions() */ }))
// @ApiDecorator('avgPortfolioItems') // TODO haha this will bite me in mp6
export class VolatilityBoxContainer extends React.Component<Props> {
  render() {
    const { mediaQuery, dashboard } = this.props as PropsWithConnect;
    const { componentData = getData(dashboard) } = this.props;

    return (
      <VolatilityBox
        mediaQuery={mediaQuery}
        forceShowNoData={false}
        {...componentData}
      >
        {this.props.children}
      </VolatilityBox>
    );
  }
}

export type ComponentData = {
  betaValue: number | null;
  volatilityItems: VolatilityItem[];
  avgPortfolioPercentValue: number | null;
  myPortfolioPercentValue: number | null;
};

export function getData(dashboard: Dashboard) {
  const { betaValue: myBetaValue } = selectoPortfolioAnalysis(dashboard);
  const { betaValue: avgBetaValue } = selectoPortfolioAnalysis(
    dashboard,
    TypesOfPortfolios.AveragePortfolio,
  );
  const volatilityItems = selectVolatilityBoxStocksByBeta(dashboard).map(s => ({
    ticker: s.ticker,
    companyName: s.name,
    betaValue: s.beta,
  }));
  return adjustData({ myBetaValue, avgBetaValue, volatilityItems });
}

export function adjustData({ myBetaValue, avgBetaValue, volatilityItems }) {
  // map from a beta value to a percent value for presentation
  const mapper = d3.scale
    .linear()
    .domain(riskRange)
    .range([0, 1]);
  const myPortfolioPercentValue =
    myBetaValue !== null ? mapper(myBetaValue) : null;
  const avgPortfolioPercentValue =
    avgBetaValue !== null ? mapper(avgBetaValue) : null;
  return {
    betaValue: myBetaValue,
    volatilityItems,
    avgPortfolioPercentValue,
    myPortfolioPercentValue,
  };
}