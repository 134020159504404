import 'whatwg-fetch';
import { retry, createQueryString } from 'sp/common/lib/utils';

const getPostRequestParams = data => ({
  method: 'post',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data),
  credentials: 'same-origin'
});

const getGetRequestParams = () => ({
  credentials: 'same-origin'
});

// refresh API calls and/or prevent chrome caching (which corrupts state when history back is used)
const setNoCache = (data, cache) => {
  data = data || {};
  if (
    typeof window !== 'undefined' &&
    window.location.search.includes('nocache=1')
  ) {
    data.nocache = 1;
  }
  if (!cache) {
    data.break = new Date().getTime();
  }
  return data;
};

function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

const request = {
  get: (url: string, data: object, { cache } = {}) =>
    retry(async () => {
      data = setNoCache(data, cache);
      var address = createQueryString(data, url); //.toLowerCase();
      const res = await fetch(address, getGetRequestParams(data));
      checkStatus(res);
      // responses are sometimes empty, so we don't use `res.json()``
      const resText = await res.text();
      return resText ? JSON.parse(resText) : null;
    }, 3),

  async post(url: string, data: object) {
    var address = url.toLowerCase();
    const res = await fetch(address, getPostRequestParams(data));
    checkStatus(res);
    const resText = await res.text();
    return resText ? JSON.parse(resText) : null;
  }
};

export default request;

export let __hotReload = true;
