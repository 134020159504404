import { fromJS } from 'immutable';

export const personsListConfig = fromJS({
  topExpertsLimit: 25,

  minMediaWidth: {
    small: 31.25,
    medium: 48.0625,
    big: 50,
    bigger: 65.625
  },

  emailSupport: 'support@tipranks.com',

  price: {
    ultimate: 599.0,
    premiumToUltimate: 240.0,
    ultimatePricePerMonth: 99.95,
    premiumToUltimateDiscount: -50.0,
    premiumCredit: -29.95
  },

  payment: {
    storeId: 477080,
    lang: 'ENGLISH',
    currency: 'USD',
    plan: {
      premium: {
        sku: 3256820
      },
      ultimate: {
        sku: 3249312
      },
      upgrade: {
        // coupon: 'TRUPGDIS2',
        sku: 3307180
      }
    }
  },

  restrictions: {
    'filtersGroup.sector': 'premium',
    'filtersGroup.ranking': 'ultimate',
    personsSubscriptions: {
      open: 0,
      free: 1,
      premium: 5,
      ultimate: -1
    },
    stocksSubscriptions: {
      open: 0,
      free: 1,
      premium: 5,
      ultimate: -1
    }
  },

  defaultPlan: 'open',

  plans: ['open', 'free', 'premium', 'ultimate'],

  personCard: {
    relevantDetails: {
      analyst: ['sector'],
      blogger: [],
      insider: [],
      hedgefund: []
    }
  },

  order: {
    sector: [
      'general',
      'services',
      'industrialgoods',
      'consumergoods',
      'utilities',
      'healthcare',
      'materials',
      'technology',
      'financial'
    ],
    period: ['monthly', 'quarterly', 'yearly', '2years']
  },

  filters: {
    groupsForType: {
      analyst: ['sector', 'ranking'],
      blogger: ['ranking'],
      insider: ['ranking'],
      hedgefund: ['hedgeFunds', 'tipRanksAndHedgeFunds']
    },
    groups: {
      sector: {
        fieldsets: ['sector'],
        plan: 'premium'
      },
      ranking: {
        fieldsets: ['period', 'benchmark'],
        plan: 'ultimate'
      },
      hedgeFunds: {
        fieldsets: ['about'],
        plan: 'free'
      },
      tipRanksAndHedgeFunds: {
        fieldsets: ['performanceAndRanking'],
        plan: 'free'
      }
    },
    fieldsets: {
      sector: {
        type: 'radio',
        fields: ['sectors']
      },

      period: {
        type: 'radio',
        fields: ['periods']
      },

      benchmark: {
        type: 'radio',
        fields: ['benchmarks']
      },
      about: {
        type: 'info'
      },
      performanceAndRanking: {
        type: 'info'
      }
    },
    defaults: {
      sector: 'general',
      period: 'yearly',
      benchmark: 'none'
    }
  }
});

export default personsListConfig;
