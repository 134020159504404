import { createNasdaqLink } from '../lib/utils';
import { Map } from 'immutable';
import * as classNames from 'classnames';
import * as React from 'react';
import Component from 'react-pure-render/component';
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';
import SvgImg from '../tr-svg-img';
import { selectTheme, selectPortfolios } from '../dashboard/selectors';
import PortfolioSelectContainer from '../tr-portfolio-select-container';
import report from './analytics';
import { appSideMenuOptions, links, isNasdaq, dashboardPath } from 'sp/common/config';
import { shouldShowPremiumButton, PremiumButton } from 'sp/browser/tr-dashboard-header/PremiumButton';
import { isOnOnboardingRoute } from 'sp/browser/pages/PublicPortfolio/Onboarding/utils';
import { RouteNavItem } from 'sp/browser/RouteNav/RouteNavItem';
import { routeNavDefaultSvgParams, noIconRoutes, href, byId, closeIfOpen } from 'sp/browser/RouteNav/utils';
import { selectIsLoggedIn } from 'sp/common/auth/selectors';
import { imgSrc } from 'sp/common/lib/utils';
import { tourLink } from 'sp/browser/decisions';
import { ReduxProps, Routes } from 'sp/common/types';
import './index.styl';
import { Link } from 'sp/common/components/Link';
import { ConnectedPureComponent } from 'sp/common/lib/ConnectedComponent';

const renderSubItem = (activeRoute, msg, theme, _subRouteName, isLaptop) => (
  item,
  index,
) => {
  // this is a quick hack to enable having 3-level nested subroutes,
  // like we have with news analysis. this code was written badly to begin with,
  // and hopefully refactored, so TODO!
  // TODO hack until holdings-new is removed
  const subRouteName = (_subRouteName.split('.').length > 2
    ? _subRouteName
      .split('.')
      .slice(0, -1)
      .join('.')
    : _subRouteName
  ).replace('-new', '');
  const isActiveSubRoute = subRouteName === `holdings.${item}`;
  const showBlackIcon = isActiveSubRoute || !isLaptop;
  const shouldShowIcon = noIconRoutes.every(
    route => !subRouteName.startsWith(`${route}.`),
  );
  return (
    <li className={item} key={item}>
      <div className="count">
        <span>4</span>
      </div>
      <Link
        activeClassName="active"
        className={classNames({
          active: subRouteName === `holdings.${item}`,
        })}
        to={href([dashboardPath, activeRoute, item])}
      >
        <div className="btn-inner">
          <div className="btn-icon">
            {shouldShowIcon && (
              <img
                alt=""
                src={`/assets/img/routes/${item}-${showBlackIcon
                  ? 'web'
                  : theme}.png`}
              />
            )}
          </div>
          <div className="btn-text">
            <span>{msg.routes[`${activeRoute}.${item}`]}</span>
          </div>
        </div>
      </Link>
    </li>
  );
};
const renderNav = (activeRoute, msg, theme, subRouteName, isLaptop) => {
  if (!activeRoute) return null;

  try {
    const route = appSideMenuOptions.find(byId(activeRoute));
    if (!route) {
      return null;
    }
    const subRoutes = route.routes;
    if (subRoutes && subRoutes.length > 1) {
      return (
        <ul className="sub-routes">
          {subRoutes.map(
            renderSubItem(activeRoute, msg, theme, subRouteName, isLaptop),
          )}
        </ul>
      );
    }
  } catch (ex) {
    if (location.port) console.warn('caught exception', ex);
    return <ul className="sub-routes" />;
  }

  return null;
};

export class RouteNav extends ConnectedPureComponent<{
  activeRoute: any, //PropTypes.string,
  activeSubRoute: any, //PropTypes.string,
  auth: any, //PropTypes.object,
  children: any, //PropTypes.any,
  dashboard: any, //PropTypes.object,
  history: any, //PropTypes.object,
  items: any, //PropTypes.instanceOf(List),
  mediaQuery: any, //PropTypes.object,
  msg: any, //PropTypes.object,
  toggleRouteNav: any, //PropTypes.func,
  panel: any, //PropTypes.any,
  register: any, //PropTypes.func,
  routeNavState: any, //PropTypes.object,
  isLaptop: boolean,
  routes: Routes,
}, ReduxProps> {

  static defaultProps = {
    activeRoute: '',
    msg: {
      routes: {},
      routeHeaders: {},
    },
    toggleRouteNav: () => null,
    panel: () => null,
    register: () => null,
    routeNavState: Map({ isActive: true }),
  };

  ref: HTMLElement | null = null;

  render() {
    const {
      activeRoute,
      activeSubRoute,
      auth,
      children,
      dashboard,
      isLaptop,
      msg,
      toggleRouteNav,
      panel,
      register: registerCallback,
      routeNavState,
    } = this.props;

    const subRouteName =
      msg.routeViaActiveSubRoute[activeSubRoute] || msg.routeHeaders[activeRoute] || msg.routes[activeRoute];

    const isLoggedIn = selectIsLoggedIn();
    const hasPortfolios = selectPortfolios(dashboard).size > 0;
    const theme = selectTheme(dashboard);

    const register = (...props) => {
      closeIfOpen(routeNavState, toggleRouteNav)();
      registerCallback(...props);
    };

    const shouldShowRouteInnerHeader = this.getShouldShowRouteInnerHeader();

    return (
      <section className="route-nav">
        <div
          className={classNames('routes', {
            active: routeNavState.get('isActive'),
          })}
        >
          <ReactCSSTransitionGroup
            component="div"
            transitionAppear
            transitionAppearTimeout={2000}
            transitionEnterTimeout={2000}
            transitionLeaveTimeout={2000}
            transitionName="translate-in"
          >
            <nav>
              <button
                className="nav-closer"
                onClick={closeIfOpen(routeNavState, toggleRouteNav)}
              >
                <img alt="" src={imgSrc(`routeNavCloser-${theme}` as ('routeNavCloser-web' | 'routeNavCloser-nasdaq'))} />
              </button>
              <ul className="sidemenu-nav">
                {this.shouldShowMyPortfoliosSelect() && (
                  <li className="li-portfolioSelect">
                    <label className="tr-portfolio-select-container-wrapper">
                      <PortfolioSelectContainer />
                    </label>
                  </li>
                )}
                {appSideMenuOptions
                  .filter(
                    item =>
                      item.predicate ? item.predicate(auth) : true,
                )
                  .map(item =>
                    <RouteNavItem
                      msg={msg.routes}
                      toggleRouteNav={toggleRouteNav}
                      activeRoute={activeRoute}
                      register={register}
                      theme={theme}
                      isLoggedIn={isLoggedIn}
                      isHoldings={hasPortfolios}
                      item={item}
                      auth={auth}
                    />
                  )
                }
              </ul>
              <div className="additional-btns">
                <ul>
                  {theme === 'nasdaq' && <li className="tour">
                    <a
                      className="link"
                      href={tourLink}
                      onClick={() => report('clickedTakeTour')}
                      target="_blank"
                    >
                      {msg.tour}
                    </a>
                  </li>}
                  {theme === 'nasdaq' && <li className="contact">
                    <Link
                      className="link"
                      to={links.contactUs}
                      onClick={(...args) => {
                        toggleRouteNav(...args);
                        report('clickedContactUs');
                      }}
                    >
                      {msg.contact}
                    </Link>
                  </li>}
                  {theme === 'nasdaq' && (
                    <li className="nasdaq">
                      <Link
                        className="link"
                        target="_blank"
                        to={createNasdaqLink()}
                        onClick={(...args) => {
                          toggleRouteNav(...args);
                          report('clickedFaq');
                        }}
                      >
                        {msg.nasdaq}
                      </Link>
                    </li>
                  )}
                  {theme === 'nasdaq' && (
                    <li className="commodities">
                      <Link
                        className="link"
                        target="_blank"
                        to={createNasdaqLink('markets/commodities.aspx')}
                        onClick={(...args) => {
                          toggleRouteNav(...args);
                          report('clickedFaq');
                        }}
                      >
                        {msg.commodities}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </ReactCSSTransitionGroup>
          <div className="powered-by footer">
            {shouldShowPremiumButton(auth) && process.env.THEME === 'nasdaq' && <div className="premium-button-container">
              <PremiumButton auth={auth} custom3Of="sidebar" />
            </div>}
            {isNasdaq && <div className="logo">
              <a href="https://tipranks.com" target="_blank">
                <img alt="TipRanks" src="/assets/img/powered-by.png" />
              </a>
            </div>}
            <ul>
              <li>
                {process.env.THEME === 'web' ? (
                  <a
                    className="link"
                    href="/faq"
                    onClick={(...args) => report('clickedFaq')}
                  >
                    {' '}
                    FAQ{' '}
                  </a>
                ) : (
                    <Link
                      className="link"
                      to="/smart-portfolio/faq"
                      onClick={(...args) => report('clickedFaq')}
                    >
                      {' '}
                      FAQ{' '}
                    </Link>
                  )}
              </li>
              <li>
                <Link
                  className="link"
                  to="/terms"
                  target="_blank"
                  onClick={(...args) => {
                    toggleRouteNav(...args);
                    report('clickedFaq');
                  }}
                >
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={classNames('route-inner', {
            active: routeNavState.get('isActive'),
          })}
          onClick={closeIfOpen(routeNavState, toggleRouteNav)}
        >
          {shouldShowRouteInnerHeader && (
            <ReactCSSTransitionGroup
              component="div"
              transitionAppear
              transitionAppearTimeout={2000}
              transitionEnterTimeout={2000}
              transitionLeaveTimeout={2000}
              transitionName="translate-right-in"
              className="transition_div"
            >
              <header
                ref={ref => {
                  this.ref = ref;
                }}
                className="route-inner-header"
                data-activeSubRoute={activeSubRoute}
                data-activeRoute={activeRoute}
              >
                <div className="title">
                  <button className="toggle-nav" onClick={toggleRouteNav}>
                    <img alt="" src={imgSrc('menu')} />
                  </button>
                  <span className="sub-route-name">
                    <div className="route-icon">
                      <SvgImg
                        id={`dashboard-${activeRoute}`}
                        keyId={`title_${activeRoute}`}
                        params={routeNavDefaultSvgParams(
                          {
                            height: '31',
                            width: '31',
                            theme,
                          }, /*  '2', */
                        )}
                      />
                    </div>
                    {subRouteName && (subRouteName.before || subRouteName)}
                  </span>
                  {subRouteName &&
                    subRouteName.after && (
                      <span className="sub-route-name">
                        {subRouteName.after}
                      </span>
                    )}
                  {panel}
                </div>
                {renderNav(
                  activeRoute,
                  msg,
                  theme,
                  activeSubRoute,
                  isLaptop,
                )}
              </header>
            </ReactCSSTransitionGroup>
          )}
          <div className="page-inner">{children}</div>
        </div>
      </section>
    );
  }

  getShouldShowRouteInnerHeader = () => {
    const { activeSubRoute, activeRoute, mediaQuery } = this.props;

    return (
      // new conversion pages dont need the routing when using desktop resolution, it just looks bad
      !(
        mediaQuery.get('laptop') &&
        (['sign-up', 'gopro'].indexOf(activeRoute) !== -1 ||
          ['investors._publicPortfolioId.register'].indexOf(activeSubRoute) !== -1)
      )
    );
  };

  shouldShowMyPortfoliosSelect = () => {
    const { auth, dashboard, routes } = this.props;
    const isLoggedIn = selectIsLoggedIn();
    const hasPortfolios = selectPortfolios(dashboard).size > 0;
    return !isOnOnboardingRoute(routes) && isLoggedIn && hasPortfolios;
  }
}
