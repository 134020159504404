import * as React from 'react';
import { svgContent } from './index';
import { isEqual } from 'lodash';

export default class SvgImg extends React.Component<{
  id: string;
  keyId: string;
  params: {
    fill: string;
    height: string;
    width: string;
  };
}> {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { id, keyId, params } = this.props;
    return (
      <div style={{ textAlign: 'center', width: `${params.width}px` }}>
        {svgContent(id, keyId, params)}
      </div>
    );
  }
}
