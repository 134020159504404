import React from 'react';
import Component from 'react-pure-render/component';
import PortfolioAllocation from './portfolioallocation.react';
import '../styles/main.styl';

export default class AllocChart extends Component {
  // static propTypes = {
  //   onChange: React.PropTypes.func,
  //   chartData: React.PropTypes.object
  // };

  constructor(props) {
    super(props);
    this.state = { active: props.initialActive };
  }

  onChange = active => {
    if (this.props.onChange) {
      this.props.onChange(active);
    }
    this.setState({ active });
  };

  render({ active, chartData, colors, msg } = this.props) {
    return (
      <div className="tr-alloc-graph">
        <PortfolioAllocation
          onChange={this.onChange}
          total={chartData.stockAllocation}
          asset={chartData.assetAllocation}
          colors={colors}
          msg={msg}
          state={active}
        />
      </div>
    );
  }
}
