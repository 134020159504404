import Component from 'react-pure-render/component';
import * as React from 'react';
import report from './analytics';
import { ApiDecorator } from '../ApiDecorator';
import { TrDashboardAvgPortfolio } from '../tr-dashboard-avg-portfolio';
import { selectoPortfolioAnalysis } from '../dashboard/routes/analysis/portfolio/selectors';
import { suits } from '../../common/enums';
import { VariableSizeSpinner } from '../Spinner/index.react.tsx';
import { Portfolio as PortfolioType } from '../tr-dashboard-avg-portfolio/types.ts';
import {
  selectAboveBeta,
  selectActivePortfolio,
  selectActivePortfolioMgmtFees,
  selectAvgCompanyFundsTopPortfolioPercent,
  selectBestCompanyFundsTopPortfolioPercent,
  selectAvgCompanyMostVolatileItems,
  selectBestCompanyMostVolatileItems,
  selectAvgCompanyTopDividendsItems,
  selectBestCompanyTopDividendsItems,
  selectDismissdedWarningDialogs,
  selectMajorAvgPortfolioItems,
  selectMajorBestPortfolioItems,
  selectMajorHoldingsSector,
  selectMajorHoldingsType,
  selectMajorPortfolioItems,
  selectMostVolatileItems,
  selectNumPortfolios,
  selectTheme,
  selectTopDividendsItems,
  selectCashValue,
  selectPortfolioItems
} from '../dashboard/selectors';
import { centComplement, calculateAnalysis } from './utils.ts';

@ApiDecorator(
  'avgPortfolioDetails',
  'avgPortfolioItems',
  'avgPortfolioStocks',
  'bestDetails',
  'bestPortfolioItems',
  'bestStocks',
  'details',
  'fundsFeesByTickers',
  'getNumPortfolios',
  'portfolioBetaRisk',
  'portfolioItems',
  'portfolios',
  'portfolioItems',
  'stocks'
)
export default class TrDashboardAvgPortfolioContainer extends Component {
  // static propTypes = {
  //   actions: PropTypes.object,
  //   apiState: PropTypes.string,
  //   dashboard: PropTypes.object,
  //   mediaQuery: PropTypes.object,
  //   msg: PropTypes.object
  // };

  componentDidMount() {
    this.maybeWarnOnMissingShares();
    report('visited');
  }

  onMajorHoldingsSectorChange = sector => {
    const { actions } = this.props;
    actions.majorHoldingsSectorChanged(sector);
    report('clickedSector');
  };

  onMajorHoldingsTypeChange = type => {
    const { actions } = this.props;
    actions.majorHoldingsTypeChanged(type);
    report('clickedStockType');
  };

  addStocks = () => {
    const { actions } = this.props;
    actions.openDialog('addStock');
  };

  // duplicated in other places...
  maybeWarnOnMissingShares = () => {
    const { actions, dashboard } = this.props;
    const portfolio = selectActivePortfolio(dashboard);
    const isMissingShares = portfolio.get('modes').includes('missingShares');
    const dismissdedWarningDialogs = selectDismissdedWarningDialogs(dashboard);

    const firstTime = name => !dismissdedWarningDialogs.includes(name);

    const navIn = 'missingSharesOnNavIn';
    if (isMissingShares && firstTime(navIn)) {
      actions.openDialog(navIn);
    }
  };

  render() {
    const {
      actions,
      apiState,
      dashboard,
      mediaQuery,
      msg,
      crowdInsights,
      auth
    } = this.props;

    const portfolioAnalysis = selectoPortfolioAnalysis(dashboard);
    const companyAvgPortfolioAnalysis = selectoPortfolioAnalysis(
      dashboard,
      'avg'
    ); // TODO use calculateAnalysis for this.
    const companyBestPortfolioAnalysis = selectoPortfolioAnalysis(
      dashboard,
      'best'
    ); // TODO use calculateAnalysis for this.

    const { betaValue, portfolioYield } = portfolioAnalysis;
    const {
      portfolioItemsDistribution,
      portfolioStocksDistribution
    } = calculateAnalysis(portfolioAnalysis);

    const {
      betaValue: companyAvgBetaValue,
      chartData: companyAvgChartData,
      portfolioYield: companyAvgPortfolioYield
    } = companyAvgPortfolioAnalysis;

    const {
      betaValue: companyBestBetaValue,
      chartData: companyBestChartData,
      portfolioYield: companyBestPortfolioYield
    } = companyBestPortfolioAnalysis;

    const { assetAllocation: companyAvgAsset } = companyAvgChartData;
    const { assetAllocation: companyBestAsset } = companyBestChartData;

    const aboveBeta = selectAboveBeta(dashboard);
    const companyFundsAvg = selectAvgCompanyFundsTopPortfolioPercent(dashboard);
    const companyFundsBest = selectBestCompanyFundsTopPortfolioPercent(
      dashboard
    );
    const companyAvgMajorHoldings = selectMajorAvgPortfolioItems(dashboard);
    const companyBestMajorHoldings = selectMajorBestPortfolioItems(dashboard);
    const companyAvgTopDividendsStocks = selectAvgCompanyTopDividendsItems(
      dashboard
    );
    const companyBestTopDividendsStocks = selectBestCompanyTopDividendsItems(
      dashboard
    );
    const companyAvgVolatileStocks = selectAvgCompanyMostVolatileItems(
      dashboard
    );
    const companyBestVolatileStocks = selectBestCompanyMostVolatileItems(
      dashboard
    );
    const investmentFees = selectActivePortfolioMgmtFees(dashboard);
    const majorHoldings = selectMajorPortfolioItems(dashboard);
    const majorHoldingsSector = selectMajorHoldingsSector(dashboard);
    const majorHoldingsType = selectMajorHoldingsType(dashboard);
    const numPortfolios = selectNumPortfolios(dashboard);
    const theme = selectTheme(dashboard);
    const topDividendsStocks = selectTopDividendsItems(dashboard);
    const volatileStocks = selectMostVolatileItems(dashboard);

    const companyAvgPortfolioStocksDistribution = companyAvgAsset.get('stock');
    const companyBestPortfolioStocksDistribution = companyBestAsset.get(
      'stock'
    );

    const cashValue = selectCashValue(dashboard);
    const hasItems = selectPortfolioItems(dashboard).size > 0;

    const hasAssetAllocation = cashValue > 0 || hasItems;

    const isAvg =
      crowdInsights.selectedPortfolio === PortfolioType.AveragePortfolio; // else, it's best.

    if (
      // this.state.loadingGrace ||
      this.props.apiState === suits.LOADING ||
      this.props.apiState === suits.INITIAL
    )
      return (
        <Container>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '30px'
            }}
          >
            <VariableSizeSpinner size={100} />
          </div>
        </Container>
      );

    return (
      <Container>
        <TrDashboardAvgPortfolio
          aboveBeta={aboveBeta}
          actions={actions}
          addStocks={this.addStocks}
          apiState={apiState}
          auth={auth}
          betaValue={betaValue}
          companyBetaValue={isAvg ? companyAvgBetaValue : companyBestBetaValue}
          companyFunds={isAvg ? companyFundsAvg : companyFundsBest}
          companyMajorHoldings={
            isAvg ? companyAvgMajorHoldings : companyBestMajorHoldings
          }
          companyPortfolioItemsDistribution={centComplement(
            isAvg ? companyAvgChartData.stockAllocation : companyBestChartData.stockAllocation
          )} stockAllocationstockAllocation
          companyPortfolioStocksDistribution={centComplement(
            isAvg
              ? companyAvgPortfolioStocksDistribution
              : companyBestPortfolioStocksDistribution
          )}
          companyPortfolioYield={
            isAvg ? companyAvgPortfolioYield : companyBestPortfolioYield
          }
          companyTopDividendsStocks={
            isAvg ? companyAvgTopDividendsStocks : companyBestTopDividendsStocks
          }
          companyVolatileStocks={
            isAvg ? companyAvgVolatileStocks : companyBestVolatileStocks
          }
          enums={msg.enums}
          hasAssetAllocation={hasAssetAllocation}
          hasItems={hasItems}
          investmentFees={investmentFees}
          majorHoldings={majorHoldings}
          majorHoldingsSector={majorHoldingsSector}
          majorHoldingsType={majorHoldingsType}
          mediaQuery={mediaQuery}
          numPortfolios={numPortfolios}
          onMajorHoldingsSectorChange={this.onMajorHoldingsSectorChange}
          onMajorHoldingsTypeChange={this.onMajorHoldingsTypeChange}
          portfolioItemsDistribution={portfolioItemsDistribution}
          portfolioStocksDistribution={portfolioStocksDistribution}
          portfolioYield={portfolioYield}
          selectedPortfolio={crowdInsights.selectedPortfolio}
          theme={theme}
          topDividendsStocks={topDividendsStocks}
          volatileStocks={volatileStocks}
        />
      </Container>
    );
  }
}

export const Container = ({ children }) => (
  <div className="tr-dashboard-avg-portfolio-container">{children}</div>
);
