import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import TrPlans from '../tr-plans';

export default class TrPlansContainer extends Component {
  static propTypes = {
    auth: PropTypes.object
  };

  render() {
    const { auth } = this.props;

    return (
      <div className="tr-plans-container">
        <TrPlans auth={auth} />
      </div>
    );
  }
}
