import * as cookies from 'js-cookie';
import {
  login as serverLogin,
  logout as serverLogout,
  register as serverRegister,
  identifyUser as serverIdentifyUser,
  makeTemporaryUser as serverMakeTemporaryUser
} from '../api';

import { dashboardPath } from '../config';
import { adaptUser } from '../api-adapter';
import report from '../../browser/auth/analytics';

import storageEngine from '../../browser/storageEngine';

export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const RESTART_FORM = 'RESTART_FORM';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const ON_AUTH_FORM_FIELD_CHANGE = 'ON_AUTH_FORM_FIELD_CHANGE';
const FORM_FIELD_MAX_LENGTH = 100;

export function onAuthFormFieldChange({ target: { name, value } }) {
  value = value.slice(0, FORM_FIELD_MAX_LENGTH);
  return {
    type: ON_AUTH_FORM_FIELD_CHANGE,
    payload: { name, value }
  };
}

export const restartForm = () => () => ({ type: RESTART_FORM });

export function logout(theme = process.env.THEME) {
  return {
    type: 'LOGOUT',
    payload: {
      promise: serverLogout(theme).then(async () => {
        if ((report as any).clear) (report as any).clear(); // TODO what is this?
        await report('logout'); // wait for analytic to be sent
        cookies.remove('km_ni', { path: '/', domain: '.tipranks.com' });
        cookies.remove('km_ai', { path: '/', domain: '.tipranks.com' });
        window.localStorage.clear();
        location.reload(true);
      })
    }
  };
}

const processUser = res => {
  // TODO figure out why this is async 
  (async () => {
    if (res.userInfo !== null) {
      await (report as any).setAlias(res.userInfo.email); // TODO why does setAlias doesn't exist?
    }
    report('visitedSite');
    (report as any).identify(); // TODO why is this not typed?
  })();
  return adaptUser(res);
};

// TODO: move this to tipranks-analytics

export const MAKE_TEMPORARY_USER = 'MAKE_TEMPORARY_USER';
export const MAKE_TEMPORARY_USER_SUCCESS = 'MAKE_TEMPORARY_USER_SUCCESS';
export const MAKE_TEMPORARY_USER_ERROR = 'MAKE_TEMPORARY_USER_ERROR';
export const MAKE_TEMPORARY_USER_START = 'MAKE_TEMPORARY_USER_START';
export const makeTemporaryUser = () => () => ({
  type: MAKE_TEMPORARY_USER,
  payload: { promise: serverMakeTemporaryUser() }
});

const tryLogin = (fields, validate, afterRegister = false) =>
  serverLogin(fields.get('email'), fields.get('password'))
    .then(processUser)
    .catch(response => {
      if (afterRegister) {
        throw validate.emailExists('email');
      }
      if (response.status === 401) {
        throw validate.wrongPassword('password');
      }
      throw response;
    });

const engine = storageEngine(window['trDashboardAppLocalStorageName']);
// TODO figure out both why both usages of this function
// attempt loading from localstorage AFTER signing in?
// I would bet in such a case localStorage would be empty..?
const loadFromStorageEngine = dispatch => () =>
  engine
    .load(false)
    .then(payload => dispatch({ type: REDUX_STORAGE_LOAD, payload }));

export function login(fields) {
  return ({ fetch, validate, dispatch }) => ({
    type: 'LOGIN',
    payload: {
      promise: tryLogin(fields, validate).then(data => {
        loadFromStorageEngine(dispatch)();
        return data;
      })
    }
  });
}

export function register(fields) {
  return ({ fetch, validate, dispatch }) => ({
    type: 'REGISTER',
    payload: {
      promise: serverRegister(fields.get('email'), fields.get('password'))
        .then((res) => {
          report('registered');
          report('registeredSmartPortfolio');
          return processUser(res);
        })
        .catch(async response => {
          if (response.status === 409) {
            try {
              const res = await tryLogin(fields, validate, true);
              const { error } = res as any; // TODO
              location.href = `/${dashboardPath}`;
            } catch (e) {
              throw validate.emailExists('emailExists');
            }
          } else {
            throw response;
          }
        })
    }
  });
}

export const IDENTIFY_USER = 'IDENTIFY_USER';
export const IDENTIFY_USER_START = 'IDENTIFY_USER_START';
export const IDENTIFY_USER_ERROR = 'IDENTIFY_USER_ERROR';
export const IDENTIFY_USER_SUCCESS = 'IDENTIFY_USER_SUCCESS';
export function identifyUser() {
  let isFirstRun = true;
  return ({ dispatch }) => {
    const prevUser = cookies.get('user');

    return {
      type: IDENTIFY_USER,
      payload: {
        promise: serverIdentifyUser()
          .then(processUser)
          .then(res => {
            // reload if there is a user cookie but userSettings2 return planId = 0
            if (cookies.get('user') && res.planId === 0) {
              location.reload(true);
              return null;
            }

            if (/*isFirstRun || */ prevUser !== cookies.get('user')) {
              loadFromStorageEngine(dispatch)();
              isFirstRun = false;
            }

            return res;
          })
      }
    };
  };
}

export const SET_FIRST_VISIT = 'SET_FIRST_VISIT';
export const setFirstVisit = () => () => ({ type: SET_FIRST_VISIT });

export const REDUX_STORAGE_LOAD = 'REDUX_STORAGE_LOAD';

export {
  setPortfolioPublic,
  SET_PORTFOLIO_PUBLIC_START,
  SET_PORTFOLIO_PUBLIC_SUCCESS,
  SET_PORTFOLIO_PUBLIC_ERROR,
  setPortfolioPrivacy,
  SET_PORTFOLIO_PRIVACY_START,
  SET_PORTFOLIO_PRIVACY_ERROR,
  SET_PORTFOLIO_PRIVACY_SUCCESS
} from 'sp/browser/pages/PublicPortfolio/Onboarding/actions';