export const SET_SORT = 'SET_SORT';
import {
  fetchStart,
  fetchSuccess,
  fetchError,
  appendSignature
} from '../../common/dashboard/actions';

import {
  subscribeToExpert as apiSubscribeToExpert,
  unSubscribeToExpert as apiUnSubscribeToExpert,
  toggleExpertFollow as apiToggleExpertFollow,
  getExperts,
  setExpertLastRead as apiSetExpertLastRead
} from './api';

import { adaptExperts } from './api-adapter';

export const TOGGLE_EXPERT_FOLLOW = 'TOGGLE_EXPERT_FOLLOW';
export const TOGGLE_EXPERT_FOLLOW_START = fetchStart(TOGGLE_EXPERT_FOLLOW);
export const TOGGLE_EXPERT_FOLLOW_SUCCESS = fetchSuccess(TOGGLE_EXPERT_FOLLOW);
export const TOGGLE_EXPERT_FOLLOW_ERROR = fetchError(TOGGLE_EXPERT_FOLLOW);
export const toggleExpertFollow = person => {
  return () => ({
    type: TOGGLE_EXPERT_FOLLOW,
    payload: {
      promise: apiToggleExpertFollow(person.get('id')).then(() => ({ person })),
      data: { person }
    }
  });
};

export const SET_EXPERT_LAST_READ = 'SET_EXPERT_LAST_READ';
export const SET_EXPERT_LAST_READ_START = fetchStart(SET_EXPERT_LAST_READ);
export const SET_EXPERT_LAST_READ_SUCCESS = fetchSuccess(SET_EXPERT_LAST_READ);
export const SET_EXPERT_LAST_READ_ERROR = fetchError(SET_EXPERT_LAST_READ);
export const setExpertLastRead = (alertId, x, y, person) => {
  return () => ({
    type: SET_EXPERT_LAST_READ,
    payload: {
      promise: apiSetExpertLastRead(alertId, person.get('id')).then(() => ({
        person
      })),
      data: { person }
    }
  });
};

export const SUBSCRIBE_TO_EXPERT = 'SUBSCRIBE_TO_EXPERT';
export const SUBSCRIBE_TO_EXPERT_START = fetchStart(SUBSCRIBE_TO_EXPERT);
export const SUBSCRIBE_TO_EXPERT_SUCCESS = fetchSuccess(SUBSCRIBE_TO_EXPERT);
export const SUBSCRIBE_TO_EXPERT_ERROR = fetchError(SUBSCRIBE_TO_EXPERT);
export const subscribeToExpert = person => {
  return () => ({
    type: SUBSCRIBE_TO_EXPERT,
    payload: {
      promise: apiSubscribeToExpert(person.get('id')).then(() => ({ person })),
      data: { person }
    }
  });
};

export const UN_SUBSCRIBE_TO_EXPERT = 'UN_SUBSCRIBE_TO_EXPERT';
export const UN_SUBSCRIBE_TO_EXPERT_START = fetchStart(UN_SUBSCRIBE_TO_EXPERT);
export const UN_SUBSCRIBE_TO_EXPERT_SUCCESS = fetchSuccess(
  UN_SUBSCRIBE_TO_EXPERT
);
export const UN_SUBSCRIBE_TO_EXPERT_ERROR = fetchError(UN_SUBSCRIBE_TO_EXPERT);
export const unSubscribeToExpert = person => {
  return () => ({
    type: UN_SUBSCRIBE_TO_EXPERT,
    payload: {
      promise: apiUnSubscribeToExpert(person.get('id')).then(() => ({
        person
      })),
      data: { person }
    }
  });
};

export const GET_EXPERTS = 'GET_EXPERTS';
export const GET_EXPERTS_START = fetchStart(GET_EXPERTS);
export const GET_EXPERTS_SUCCESS = fetchSuccess(GET_EXPERTS);
export const GET_EXPERTS_ERROR = fetchError(GET_EXPERTS);
export const fetchExperts = (loadingSuites = true) => {
  return () => ({
    type: GET_EXPERTS,
    payload: {
      promise: getExperts()
        .then(adaptExperts)
        .then(appendSignature())
      // .then(data => ({ data, loadingSuites }))
    }
  });
};

// copy pasta from GET_EXPERTS, we do it because fetchExperts was being called
// on poll in notificaitons, and that caused my experts page to refresh.
// so we seperated this into it's own action. @DOR...
export const GET_USER_FOLLOWED_EXPERTS = 'GET_USER_FOLLOWED_EXPERTS';
export const GET_USER_FOLLOWED_EXPERTS_START = fetchStart(
  GET_USER_FOLLOWED_EXPERTS
);
export const GET_USER_FOLLOWED_EXPERTS_SUCCESS = fetchSuccess(
  GET_USER_FOLLOWED_EXPERTS
);
export const GET_USER_FOLLOWED_EXPERTS_ERROR = fetchError(
  GET_USER_FOLLOWED_EXPERTS
);
export const userFollowedExperts = (loadingSuites = true) => {
  return () => ({
    type: GET_USER_FOLLOWED_EXPERTS,
    payload: {
      promise: getExperts()
        .then(adaptExperts)
        .then(appendSignature())
      // .then(data => ({ data, loadingSuites }))
    }
  });
};

export const setSort = id => {
  return {
    type: SET_SORT,
    payload: { id }
  };
};
