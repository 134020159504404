/* tslint:disable:import/first */
import { fromJS, List, OrderedMap, Map } from 'immutable';

import { set as lodashSet } from 'lodash';

import { adapt } from './lib/utils';
import { companyConstants } from './config';

import {
  Article,
  Consensus,
  ExpertsSet,
  Person,
  Portfolio,
  PortfolioEvent,
  PortfolioFeed,
  PortfolioItem,
  PortfolioItemAlert,
  PortfolioNews,
  PortfolioStats,
  PortfolioWarning,
  // TODO should this exist? cause it doesnt
  // PortfolioWarningDetail,
  Price,
  Stats,
  Stock,
  User,
  UserActivity,
} from './records';

import {
  EtfAssetTypeMapping,
  EtfCategoryMapping,
  InvestmentStrategyMapping,
  Issuer,
  ManagementFeesEnum,
  Plan,
  PlanEnum,
  PortfolioRecord,
  TradeItSyncStatus,
  PortfolioRecord_NotImmutable,
  PortfolioItemRecord,
  LoggedInUser_NotImmutable,
  PortfolioMadePublic, PlaidSyncStatus
} from './types';
import * as _ from 'lodash';
import { API_GetUserPortfolios_Response } from 'sp/common/api-types';
import { MarketCountry } from './enums';

// too precise time string makes IE cry
// example date string 2017-04-27T00:00:00
const newDate = (str, offset = '') => {
  const hack = str.replace('T00:00:00', 'T12:00:00'); // + 'Z'
  const result = new Date(`${hack.split('.')[0]}${offset}`);
  const resultUTC = new Date(
    result.getTime() + result.getTimezoneOffset() * 60000,
  );
  return result;
  // return new Date(str + 'Z0');
};

const adaptPerson = name => person =>
  new Person(
    adapt(
      person,
      {
        firm: ['firm', firm => new Stock({ name: firm })],
        name: ['expertName'],
        type: ['expertType'],
        rank: ['rank'],
      },
      name,
    ),
  );

const adaptStockFromAlert = name => alert =>
  new Stock(
    adapt(
      alert,
      {
        name: ['companyName'],
        ticker: ['ticker'],
      },
      name,
    ),
  );

const articleAdaptShape = {
  siteName: ['siteName'],
  url: ['articleUrl'],
};

const adaptArticleFromAlert = name => alert =>
  new Article(adapt(alert, articleAdaptShape, name));

export const adaptPortfolioItemAlert = (name, lastId = 0) => alert =>
  new PortfolioItemAlert(
    adapt(
      alert,
      {
        action: ['action'],
        date: ['effectiveDate', effectiveDate => newDate(effectiveDate)],
        hasBeenRead: ['', () => alert.operationId <= lastId],
        id: ['operationId'],
        name: ['companyName'],
        person: ['', () => adaptPerson(name)(alert)],
        stock: ['', () => adaptStockFromAlert(name)(alert)],
        type: ['rating'],
        article: ['', () => adaptArticleFromAlert(name)(alert)],
      },
      name,
    ),
  );

export const adaptPortfolioItemAlerts = (name, lastId) => ops =>
  List(ops.map(adaptPortfolioItemAlert(name, lastId)));

export const toLastId = (lastId, alert) =>
  lastId > alert.operationId ? lastId : alert.operationId;

export const hasReadAllAlerts = alerts =>
  alerts.lastRead >= alerts.ops.reduce(toLastId, 0);

const adaptPortfolioItem = (name: string) => item =>
  new PortfolioItem(
    adapt(
      item,
      {
        addedDate: ['addedOn', addedOn => newDate(addedOn)],
        alerts: [
          'alerts',
          ({ ops }) =>
            adaptPortfolioItemAlerts(name, item.alerts.lastRead)(ops),
        ],
        isAlertsOn: ['isSubscribedToEmailAlert'],
        name: ['companyName'],
        // PURCHASEPRICE PURCHASE PRICE This is the location purchase price is defined
        purchasePrice: [
          '',
          () => item.userDefinedPrice || item.avgPurchasePrice || 0,
        ],
        addedPrice: ['avgPurchasePrice'],
        readAllAlerts: ['alerts', hasReadAllAlerts],
        sharesTotal: ['numShares'],
        isSecondaryTicker: [
          'stockType',
          stockType => stockType === 'secondaryticker',
        ],
        ticker: ['ticker', ticker => ticker.toUpperCase().trim()],
      },
      name,
    ),
  );

export const adaptPortfolioItems = (name: string) => (items: any) =>
  List<PortfolioItemRecord>(items.map(adaptPortfolioItem(name)));

const adaptConsensus = name => consensus =>
  new Consensus(
    adapt(
      consensus,
      {
        decision: ['consensus'],
        distribution: [
          'distribution',
          distribution =>
            Object.keys(distribution).map(key => [key, distribution[key]]),
        ],
      },
      name,
    ),
  );

const adaptStock = name => stock =>
  new Stock(
    adapt(
      stock,
      {
        analystConsensus: ['analystConsensus', adaptConsensus(name)],
        bestAnalystConsensus: ['bestAnalystConsensus', adaptConsensus(name)],
        bloggerSentiment: ['bloggerSentimentData', data => data.rating],
        bloggerSentimentScore: [
          'bloggerSentimentData',
          data => data.bullishCount / (data.bearishCount + data.bullishCount),
        ],
        dividend: ['dividend'],
        expenseRatio: ['expenseRatio'],
        dividendDate: [
          'nextDividendDate',
          dividendDate => newDate(dividendDate.exDate),
        ],
        earningsReport: [
          'nextEarningsReport',
          earning => newDate(earning.date),
        ],
        eps: ['lastReportedEps', eps => newDate(eps.date)],
        hedgefundSentiment: ['hedgeFundSentimentData', data => data.rating],
        hedgefundSentimentScore: ['hedgeFundSentimentData', data => data.score],
        landmarkPrices: [
          'landmarkPrices',
          data =>
            fromJS({
              threeMonthsReturn:
                (data && data.threeMonthsAgo && data.threeMonthsAgo.p) || null,
              YTDReturn: (data && data.yearToDate && data.yearToDate.p) || null,
              '1yrReturn': (data && data.yearAgo && data.yearAgo.p) || null,
            }),
        ],
        '1yrReturn': ['oneYearReturn'],
        '3yrReturn': ['threeYearReturn'],
        '5yrReturn': ['fiveYearReturn'],
        id: ['stockUid'],
        insiderSentiment: ['insiderSentimentData', data => data.rating],
        insiderSentimentScore: [
          'insiderSentimentData',
          data => data.stockScore,
        ],
        name: ['companyName'],
        hasLink: ['shouldAddLinkToStockPage'],
        pe: ['peRatio'],
        sector: [
          'sectorId',
          sector => (sector === 'unknownsector' ? 'other' : sector),
        ],
        bestTarget: ['bestPriceTarget'],
        target: ['priceTarget'],
        ticker: ['ticker', ticker => ticker.toUpperCase().trim()],
        type: ['stockType'],
        isSecondaryTicker: [
          'stockType',
          stockType => stockType === 'secondaryticker',
        ],
        yield: ['dividendYield'],
        category: ['categoryID', index => EtfCategoryMapping[index]],
        etfType: ['etfType', index => EtfAssetTypeMapping[index]],
        issuer: ['issuer', index => Issuer[index]],
        investmentStyle: [
          'investmentStyle',
          index => InvestmentStrategyMapping[index],
        ],
        totalAssets: ['marketCap'],
        managementFee: ['expenseRatio'],
        newsSentiment: ['newsSentiment'], // screener only field
        newsSentimentScore: ['newsSentimentScore'], // screener only field
      },
      name,
    ),
  );

export const adaptStocks = name => stocks => List(stocks.map(adaptStock(name)));

export const addNewsSentimentToStocks = (stocks, newsSentiments) =>
  stocks.map(function addNewsSentimentToStock(stock) {
    const ticker = stock.get('ticker').toUpperCase();
    const _score = newsSentiments.filter(ns => ns.ticker === ticker);
    const { score = '-1' } = _score.length > 0 ? _score[0] : {};
    return stock.set('newsSentimentScore', score);
  });

/* DEPRECTAED METHODS AHEAD */

const urlName = name =>
  name
    .toLowerCase()
    .trim()
    .replace(/ /g, '-');

const rating = num => (num ? num : -1);

const personName = (first = '', last = '') => `${first} ${last}`.trim();

const byProp = (prop, val) => d => d[prop].toLowerCase() === val.toLowerCase();

const set = (to, from) => [['price', to], from];

const toRealInt = str => {
  if (str.includes('K')) {
    return str.split('K')[0] * 1000;
  }
  if (str.includes('M')) {
    return str.split('M')[0] * 1000000;
  }
  if (str.includes('B')) {
    return str.split('B')[0].replace(",", "") * 1000000000;
  }
};

export const adaptDetails = details => stocks => {
  const addedStocks = details.reduce((stocks, detail) => {
    const exists = stocks.some(
      stock =>
        stock.get('ticker').toLowerCase() === detail.ticker.toLowerCase(),
    );
    const testExistsBad = stocks.some(
      stock => stock.get('ticker') === detail.ticker,
    );
    return exists
      ? stocks
      : stocks.push(
        new Stock({
          ticker: detail.ticker,
        }),
      );
  }, stocks);

  return addedStocks.map(stock => {
    const dtl =
      details[details.findIndex(byProp('ticker', stock.get('ticker')))];
    
    return dtl
      ? stock
        .setIn(...set('amount', parseFloat(dtl.price)))
        .setIn(...set('cap', toRealInt(dtl.marketCap || '0')))
        .setIn(...set('beta', parseFloat(dtl.beta || 0)))
        .setIn(...set('changeAmount', parseFloat(dtl.changeAmount)))
        .setIn(...set('ftWeekHigh', parseFloat(dtl.high52Weeks)))
        .setIn(...set('ftWeekLow', parseFloat(dtl.low52Weeks)))
        .setIn(...set('changePercent', dtl.changePercent / 100))
        .set('exchangeRate', dtl.exchangeRate)
      : stock;
  });
};

export const adaptPortfoliosDetails = details => items => {
  const itemsWithValues = items.map(item => {
    const stockDetails =
      details[details.findIndex(byProp('ticker', item.get('ticker')))];

    return stockDetails
      ? item
        .set(
          'sharesValue',
          item.get('sharesTotal') *
          parseFloat(stockDetails.price) *
          parseFloat(stockDetails.exchangeRate || 1),
        )
        .setIn(
          ['stock', 'exchangeRate'],
          parseFloat(stockDetails.exchangeRate) || 1,
        )
      : item;
  });

  const totalValues = itemsWithValues.reduce(
    (total, item) => total + item.get('sharesValue'),
    0,
  );

  const itemWithSharesValueSize = items.filter(
    item => item.get('sharesTotal') > 0,
  ).size;

  const calcPercentPortfolio = item =>
    itemWithSharesValueSize > 0
      ? item.get('sharesValue') / totalValues
      : 1 / items.size;

  return itemsWithValues.map(item =>
    item.set('percentPortfolio', calcPercentPortfolio(item)),
  );
};

export const adaptStats = res =>
  new Stats({
    analystsTotal: res.TotalAnalysts || res.totalAnalysts,
    bloggersTotal: res.TotalBloggers || res.totalBloggers,
    insidersTotal: res.TotalRankedInsiders || res.totalRankedInsiders,
    hedgefundsTotal: res.TotalHedgeFunds || res.totalHedgeFunds,
  });

export const adaptNewPortfolio = res =>
  new Portfolio({
    id: res.id,
    name: res.name,
  });

export const adaptAutoCompleteList = arr => {
  return fromJS(arr.filter(x=> x.countryId !== MarketCountry.Britain));
}

const adaptPlan = (planId: PlanEnum): Plan =>
  ({
    0: 'open',
    1: 'free',
    3: 'premium',
    6: 'premium',
    5007: 'ultimate',
    2: 'ultimate',
    4: 'ultimate',
    5: 'ultimate',
    5001: 'ultimate',
    5002: 'ultimate',
    5003: 'ultimate',
    5004: 'ultimate',
    5005: 'ultimate',
    5006: 'ultimate',
  }[planId]);

export interface API_Res_UserSettingsResponse {
  isTemporary: boolean;
  isFirstTimeMigratedUser: boolean;
  isSpaasMigrated: boolean;
  planId: PlanEnum;
  expertId: number;
  features: Feature[];
  userInfo: UserInfo;
  portfolios: any[];
  hasHoldings: boolean;
  experts: Expert[];
  insiders: Expert[];
  bypassEmailLimit: boolean;
  areEmailAlertsActive: boolean;
  portfoliosWithPerformance: { id: number, name: string }[],
  publicPortfolios: PortfolioMadePublic[],
  addOns: AddOn[];
}

export interface AddOn {
  name: string;
  isDirectUserFeature: boolean;
}

interface Expert {
  expertUID: string;
  isFollowing: boolean;
}

interface UserInfo {
  userUid: string;
  email: string;
  firstName: string;
  segment: string;
  telephone: string;
  signUpDate: string;
}

interface Feature {
  feature: string;
  restrict: number;
}

export type AdaptUserRes = Pick<LoggedInUser_NotImmutable, 'expertId' | 'email' | 'hasHoldings' | 'id' | 'name' | 'plan' | 'planId' | 'isFirstTimeMigratedUser' | 'phone' | 'bypassEmailLimit' | 'areEmailAlertsActive' | 'signupDate' | 'portfoliosWithPerformance' | 'portfoliosMadePublic' | 'addOns' | 'isSpaasMigrated'>;

export const adaptUser = (res: API_Res_UserSettingsResponse): AdaptUserRes =>
  ({
    email: res.userInfo ? res.userInfo.email : '',
    hasHoldings: res.hasHoldings,
    id: res.userInfo ? res.userInfo.userUid : '',
    expertId: res.expertId,
    name: res.userInfo ? res.userInfo.firstName : '',
    plan: adaptPlan(res.planId),
    planId: res.planId,
    addOns: fromJS(res.addOns ?? []),
    isFirstTimeMigratedUser: res.isFirstTimeMigratedUser,
    isSpaasMigrated: res.isSpaasMigrated ? res.isSpaasMigrated : false,
    phone: res.userInfo ? res.userInfo.telephone : '',
    bypassEmailLimit: res.bypassEmailLimit,
    areEmailAlertsActive: res.areEmailAlertsActive,
    signupDate: res.userInfo ? res.userInfo.signUpDate : '',
    // add fromjs because of localstorage
    portfoliosWithPerformance: fromJS(res.portfoliosWithPerformance),
    portfoliosMadePublic: fromJS(res.publicPortfolios),
  });

export const expertType = expertType =>
  ({
    institutional: 'hedgefund',
    1: 'analyst',
  }[expertType] || expertType);

export const adaptAlertsSettingsIds = {
  high: 'high52weeks',
  low: 'low52weeks',
  increase: 'sharesincrease',
  drop: 'sharesdecrease',
  announcement: 'pressreleaseannouncement',
  report: 'earningsreport',
  dividend: 'dividend',
  analyst: 'analystrating',
  blogger: 'bloggerrating',
  insider: 'insidertransaction',
  hedgefund: 'hedgefundtransaction',
  analystConsensus: 'analystconsensuschange',
  bestAnalystConsensus: 'bestanalystconsensuschange',
  bloggerSentiment: 'financialbloggersentimentchange',
  insiderSentiment: 'insidersentimentchange',
  hedgefundSentiment: 'hedgefundsentiment',
};

export const adaptSettingsToApi = settings => {
  return {
  notifications: settings
    .filter(x => x.get('val'))
    .map(x => ({
      notification: adaptAlertsSettingsIds[x.get('id')],
      triggerValue: x.get('info'),
      isActive: true
    }))
    .toJS(),
  minimalStars: 0
  // Number(
  //   settings.find(x => x.get('id') === 'ranking').get('info'),
  // ),
}};

const toAlertSettingId = id =>
  Object.keys(adaptAlertsSettingsIds).reduce(
    (result, key) => (adaptAlertsSettingsIds[key] === id ? key : result),
  );

export const adaptPortfolioFeed = (arr, type) =>
  List(
    arr.map(
      item =>
        new PortfolioFeed({
          action: item.insiderOperationAction || item.operationAction,
          date: newDate(item.effectiveDate),
          stock: new Stock({
            ticker: item.ticker,
            name: item.displayName,
          }),
          priceTarget: item.priceTarget,
          person: new Person({
            firm: new Stock({
              name: item.firmName || item.displayName,
            }),
            role: item.insiderRole,
            photoId: item.pictureURL,
            name: personName(item.firstName, item.lastName),
            rank: rating(item.expertRank),
            type: type,
            urlName: urlName(item.firmName || item.displayName),
          }),
          type: item.operationRating,
        }),
    ),
  );

export const createPortfolio = data =>
  new Portfolio({
    id: data.id,
    name: data.name,
    siteName: data.siteName,
    tradeItSyncStatus: data.portfolioSyncStatus,
    plaidSyncStatus: data.portfolioSyncStatus,
    isImported: data.type === 'userimported',
    type: data.portfolioStatus || data.type,
    cashValue: data.cashValue || 0,
  });

export const adaptPortfolios = (fetchedPortfolios: API_GetUserPortfolios_Response['portfolios']) => (
  localPortfolios: List<PortfolioRecord>,
) => {
  const fetchedIds = fetchedPortfolios.map(p => p.id);
  const localIds: number[] = localPortfolios.map(p => p.get('id')).toJS();
  const allPortfolios = _.uniq(localIds.concat(fetchedIds));
  const shittyAllPortfolios = List(allPortfolios);

  return shittyAllPortfolios.map(currPortfolioId => {
    const fetchedPortfolio = fetchedPortfolios.find(p => p.id === currPortfolioId);
    const localPortfolio = localPortfolios.find(p => p.get('id') === currPortfolioId);

    if (fetchedPortfolio && localPortfolio) {
      return localPortfolio
        .set('cashValue', fetchedPortfolio.cashValue || 0)
        .set('name', fetchedPortfolio.name)
        .set('tradeItSyncStatus', fetchedPortfolio.portfolioSyncStatus)
        .set('plaidSyncStatus', fetchedPortfolio.portfolioSyncStatus);
    } else if (localPortfolio && !fetchedPortfolio) {
      return localPortfolio;
    } else {
      return createPortfolio(fetchedPortfolio);
    }
  });
};
const newsSentiment = sentiment =>
  ({
    positive: 'bullish',
    negative: 'bearish',
  }[sentiment] || 'all');

export const adaptPortfolioNews = (arr: any[]) =>
  List(
    arr.map(
      news =>
        new PortfolioNews({
          date: newDate(news.date),
          ticker: news.ticker.toLowerCase().trim(),
          site: news.siteName,
          stockType: news.stockType,
          pictureUrl: news.pictureUrl,
          addedOn: newDate(news.addedOn),
          text: news.title,
          url: news.urlString,
          sentiment: newsSentiment(news.sentiment),
        }),
    ),
  );

export const adaptFundamentals = arr =>
  arr.reduce(
    (orderedMap, stock) =>
      orderedMap.set(
        stock.ticker,
        OrderedMap({
          id: stock.ticker,
          data: stock.prices.map(
            price =>
              new Price({
                date: newDate(price.date),
                amount: price.price,
              }),
          ),
        }),
      ),
    OrderedMap(),
  );

export const adaptPortfolioEvents = req => {
  return fromJS(req)
    .entrySeq()
    .reduce(
      (all, [type, list]) =>
        all.concat(list.map(item => item.set('type', type))),
      List(),
    )
    .filter(event => event.get('date') || event.get('exDate'))
    .map(
      (event, index) =>
        new PortfolioEvent({
          id: index,
          date: newDate(event.get('date') || event.get('exDate')),
          stock: new Stock({
            bestAnalystConsensus: event.get('bpConsensus'),
            analystConsensus: event.get('consensus'),
            name: event.get('company'),
            price: new Price({
              amount: parseFloat(
                event.get('eps') || parseFloat(event.get('amount')),
              ),
            }),
            sector: event.get('sector'),
            marketCap: event.get('marketCap'),
            ticker: event.get('ticker').toLowerCase(),
          }),
          type: event.get('type'),
        }),
    );
};

export const adaptDashboard = data =>
  Map({
    user: new User({
      suggested: new ExpertsSet({
        analysts: List(
          data.suggested.analysts.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'analyst',
                urlName: '',
              }),
          ),
        ),
        bloggers: List(
          data.suggested.bloggers.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'blogger',
                urlName: '',
              }),
          ),
        ),
        hedgeFunds: List(
          data.suggested.hedgeFunds.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'hedgeFund',
                urlName: '',
              }),
          ),
        ),
        insiders: List(
          data.suggested.insiders.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'insider',
                urlName: '',
              }),
          ),
        ),
      }),
      experts: new ExpertsSet({
        analysts: List(
          data.experts.analysts.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'analyst',
                urlName: '',
              }),
          ),
        ),
        bloggers: List(
          data.experts.bloggers.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'blogger',
                urlName: '',
              }),
          ),
        ),
        hedgeFunds: List(
          data.experts.hedgeFunds.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'hedgeFund',
                urlName: '',
              }),
          ),
        ),
        insiders: List(
          data.experts.insiders.map(
            person =>
              new Person({
                firm: new Stock(person.firm),
                name: person.name,
                photoId: person.photoId,
                rank: rating(person.rating),
                type: 'insider',
                urlName: '',
              }),
          ),
        ),
      }),
      person: new Person(data.user),
    }),
  });

export const adaptAlertsSettings = res =>
  fromJS({
    list: res.notificationsData
      .map(x => ({
        id: toAlertSettingId(x.notification),
        val: true,
        info: x.triggerValue,
      }))
      .concat({
        id: 'ranking',
        value: res.minimalStars != null,
        info: res.minimalStars,
      }),
    expectedExpertEmails: res.expectedExpertEmails,
    expectedStockEmails: res.expectedStockEmails,
    numSubscriptionToExperts: res.numSubscriptionToExperts,
    isDisabled: !res.isEnabled,
    isDisabledExperts: !res.isEnabledExperts,
    weeklyDigestEnabled: res.weeklyDigestEnabled,
  });

// TODO :)
export const adaptPortfolioStat = res =>
  new PortfolioStats(
    (adapt as any)(
      res,
      {
        pe: ['avgPe'],
        yield: ['avgDividendAmount'],
        percent: ['dailyGainPercent'],
        amount: ['dailyGainAmount'],
        changes: ['portfolioChanges'],
      },
      'getPortfolioStats',
    ),
  );

export const adaptPortfolioWarnings = res =>
  List(res).map(
    item =>
      new PortfolioWarning(
        adapt(
          item,
          {
            ticker: ['ticker'],
            warningType: ['warningTypeID'],
            warningSubType: ['warningSubTypeID'],
            date: ['date', d => newDate(d)],
            data: [
              'fields',
              fields =>
                Map(
                  fields.reduce(
                    (acc, { value, key }) => lodashSet(acc, key, value),
                    {},
                  ),
                ),
            ],
          },
          'getPortfolioWarnings',
        ),
      ),
  );

// export const adaptUserActivity = (perf, index) =>
//   new UserActivity({
//     id: index,
//     ticker: perf.get('ticker'),
//     name: perf.get('displayName'),
//     gain: perf.get('gainPercent'),
//     itemType: perf.get('stockType'),
//     gainAmount: perf.get('gainAmount'),
//     amountAdded: perf.get('sharesTraded'),
//     dateOpened: perf.get('executionTime') && newDate(perf.get('executionTime')),
//     execPrice: perf.get('price'),
//     dateClosed: perf.get('closeDate') && newDate(perf.get('closeDate')),
//   });
