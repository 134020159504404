import * as _ from 'lodash';
import * as React from 'react';

import * as moment from 'moment';
import { Option } from '../dropdown/PerformanceDropdown';

const Highcharts = require('highcharts');

export const plotBandBackground = '#F9F9F9';

export type PeriodicGraphData = PeriodicGraphDatum[];
export type PerfDatum = {
  data: Value[];
} & Option;
export type PeriodicGraphDatum = {
  data: Value[];
} & Option;

export type Value = number | null;

export const defaultStyle = {
  fontFamily: 'Arial',
  spacingBottom: 15,
  spacingLeft: 10,
  spacingRight: 10,
  spacingTop: 10,
  height: null
};
const xAxisLabels = {
  formatter: labelFormatter,
  style: { color: '#4a4a4a', fontSize: '12px' },
  rotation: 0
};

const xAxis = {
  labels: xAxisLabels,
  lineWidth: 0,
  tickWidth: 0
};

const yAxis = {
  title: { text: '' },
  tickAmount: 3,
  gridLineWidth: 0,
  plotLines: [
    {
      value: 0,
      width: 2,
      color: 'rgb(230, 230, 230)'
    }
  ]
};

export type GraphAlignment = 'center' | 'right';
export class GeneralPeriodicGraph extends React.Component<{
  data: PeriodicGraphData;
  onRenderedChart: (chart: any) => void;
  itemNum: number;
  className?: string;
  style?: Partial<{
    fontFamily: string;
    spacingBottom: number;
    spacingLeft: number;
    spacingRight: number;
    spacingTop: number;
    height: number;
  }>;
  xAxisItems: any[];
  xAxisLabelTextAlign: GraphAlignment;
}> {
  key = 'the-highchart';
  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate() {
    this.renderChart();
  }
  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <div id={this.key} />
      </div>
    );
  }
  renderChart = () => {
    //TODO add a Total plotband that is constant.
    const {
      data: rawSeries,
      style: {
        fontFamily = defaultStyle.fontFamily,
        spacingBottom = defaultStyle.spacingBottom,
        spacingLeft = defaultStyle.spacingLeft,
        spacingRight = defaultStyle.spacingRight,
        spacingTop = defaultStyle.spacingTop,
        height = defaultStyle.height
      } = defaultStyle,
      xAxisItems: categories,
      xAxisLabelTextAlign
    } = this.props;
    const series = rawSeries
      .filter(Boolean)
      // TODO serie as PeriodicGraphData
      .map(serie =>
        _.update(serie as any, 'data', data => data.map(datum => datum || 0))
      ) as PerfDatum[];

    const allData = _.chain(series)
      .map(serie => (serie as PerfDatum).data)
      .flatten()
      .value();

    const maxPosStr = (_.chain(allData).map(Math.abs).max().value() || 0).toFixed(0);
    const maxPosInt = parseInt(maxPosStr);
    // const minPos = _.min(allData) as number; // The obvious, lame solution
    const minPos = -maxPosInt;
    const maxAbsPos = Math.max(maxPosInt, Math.abs(minPos));
    const padding = maxPosInt / 5;
    const yTickPositions = [minPos - padding, 0, maxPosInt + padding];

    const plotBands = series[0].data.map((datum, index) => ({
      from: index - 0.5,
      to: index + 0.5,
      ...basePlotBand
    }));

    Highcharts.chart(
      this.key,
      {
        chart: {
          type: 'column',
          spacingBottom,
          spacingLeft,
          spacingRight,
          spacingTop,
          style: { fontFamily, fontSize: '12px' },
          backgroundColor: 'transparent',
          height
        },
        tooltip: {
          formatter: function () {
            return `<em>${format(this.point.category)}</em><br /><b>${this.point
              .series.userOptions.name}</b>: ${this.y.toFixed(2)}%`;
          }
        },
        title: { text: '' },
        yAxis: {
          ...yAxis,
          tickPositions: yTickPositions,
          labels: {
            formatter: function () {
              let value = this.value;
              if (value === yTickPositions[0]) value = value + padding;
              else if (value === yTickPositions[1]) {
              } else if (value === yTickPositions[2]) value = value - padding;

              return value.toFixed(2) + '%';
            },
            style: { color: '#8f8f8f', fontSize: '12px' },
            x: -5
          }
        },
        xAxis: {
          ...xAxis,
          categories,
          plotBands
        },
        plotOptions: {
          series: {
            minPointLength: 3
            //   pointPadding: 2,
            //   groupPadding: 0.95,
          },
          column: {
            borderColor: 'transparent',
            // pointWidth: 6.8 * series.length,
            tooltip: {
              valueSuffix: '%'
            }
          }
        },
        credits: { enabled: false },
        legend: { enabled: false },
        series
      },
      this.onRenderedChart
    );
  };

  onRenderedChart = chart => {
    // our code here
    try {
      if (this.props.xAxisLabelTextAlign === 'right') {
        const plotBandWidth = chart.xAxis[0].plotLinesAndBands[0].svgElem.element.getBoundingClientRect()
          .width;
        chart.xAxis[0].update({
          ...xAxis,
          labels: {
            ...xAxisLabels,
            align: 'right',
            x: plotBandWidth / 2
          }
        });
        chart.yAxis[0].update(yAxis);
      }
      const { plotSizeX: chartWidth, series } = chart;
      // setTimeout(() => {
      //   // adjust cross-axis line on the zero y axis value, make it so that
      //   // it won't stick out of the plotband
      //   // Looks something like this "M 60 77 L 673 77", we adjust
      //   // 60 (x point) and 673 (width).
      //   const [a, x, b, c, width, d] =
      //     chart.yAxis[0].plotLinesAndBands[0].svgElem.element.attributes.d.value.split(' ');
      //   chart.yAxis[0].plotLinesAndBands[0].svgElem.element.attributes.d =
      //     `${a} ${x + 2} ${b} ${c} ${width - 2} ${d}`;
      // }, 59);
    } catch (e) {
      console.warn('Error rendering periodic graph', e);
    }
    if (this.props.onRenderedChart) this.props.onRenderedChart(chart);
  };
}

export const bandSeperator = 5;
const labelFormat = 'MMM YYYY';

function format(str: string): string {
  return moment(str).format(labelFormat);
}
export function labelFormatter() {
  return format(this.value);
}
const basePlotBand = {
  color: plotBandBackground,
  borderColor: 'white',
  borderWidth: 5
};
