export default {
  ad: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -644,
    offset_y: 0
  },
  ae: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1956,
    offset_y: 0
  },
  af: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2628,
    offset_y: 0
  },
  ag: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -36,
    offset_y: 0
  },
  ai: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -52,
    offset_y: 0
  },
  al: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -68,
    offset_y: 0
  },
  am: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -84,
    offset_y: 0
  },
  an: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -100,
    offset_y: 0
  },
  ao: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -116,
    offset_y: 0
  },
  ar: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -132,
    offset_y: 0
  },
  as: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -148,
    offset_y: 0
  },
  at: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -164,
    offset_y: 0
  },
  au: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -180,
    offset_y: 0
  },
  aw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -196,
    offset_y: 0
  },
  ax: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -212,
    offset_y: 0
  },
  az: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -228,
    offset_y: 0
  },
  ba: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -244,
    offset_y: 0
  },
  bb: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -260,
    offset_y: 0
  },
  bd: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -276,
    offset_y: 0
  },
  be: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -292,
    offset_y: 0
  },
  bf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -308,
    offset_y: 0
  },
  bg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -324,
    offset_y: 0
  },
  bh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -340,
    offset_y: 0
  },
  bi: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -356,
    offset_y: 0
  },
  bj: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -372,
    offset_y: 0
  },
  bm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -388,
    offset_y: 0
  },
  bn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -404,
    offset_y: 0
  },
  bo: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -420,
    offset_y: 0
  },
  br: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -436,
    offset_y: 0
  },
  bs: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -452,
    offset_y: 0
  },
  bt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -468,
    offset_y: 0
  },
  bv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -484,
    offset_y: 0
  },
  bw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -500,
    offset_y: 0
  },
  by: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -516,
    offset_y: 0
  },
  bz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -532,
    offset_y: 0
  },
  ca: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -548,
    offset_y: 0
  },
  catalonia: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -564,
    offset_y: 0
  },
  cc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -580,
    offset_y: 0
  },
  cd: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -596,
    offset_y: 0
  },
  cf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -612,
    offset_y: 0
  },
  cg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -628,
    offset_y: 0
  },
  ch: {
    image: '/sprites/flags.png',
    height: 11,
    width: 11,
    offset_x: -9,
    offset_y: 0
  },
  ci: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -660,
    offset_y: 0
  },
  ck: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -676,
    offset_y: 0
  },
  cl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -692,
    offset_y: 0
  },
  cm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -708,
    offset_y: 0
  },
  cn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -724,
    offset_y: 0
  },
  co: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -740,
    offset_y: 0
  },
  cr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -756,
    offset_y: 0
  },
  cs: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -772,
    offset_y: 0
  },
  cu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -788,
    offset_y: 0
  },
  cv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -804,
    offset_y: 0
  },
  cx: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -820,
    offset_y: 0
  },
  cy: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -836,
    offset_y: 0
  },
  cz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -852,
    offset_y: 0
  },
  de: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -868,
    offset_y: 0
  },
  dj: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -884,
    offset_y: 0
  },
  dk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -900,
    offset_y: 0
  },
  dm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -916,
    offset_y: 0
  },
  do: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -932,
    offset_y: 0
  },
  dz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -948,
    offset_y: 0
  },
  ec: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -964,
    offset_y: 0
  },
  ee: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -980,
    offset_y: 0
  },
  eg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -996,
    offset_y: 0
  },
  eh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1012,
    offset_y: 0
  },
  england: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1028,
    offset_y: 0
  },
  er: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1044,
    offset_y: 0
  },
  es: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1060,
    offset_y: 0
  },
  et: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1076,
    offset_y: 0
  },
  europeanunion: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1092,
    offset_y: 0
  },
  fam: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1108,
    offset_y: 0
  },
  fi: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1124,
    offset_y: 0
  },
  fj: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1140,
    offset_y: 0
  },
  fk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1156,
    offset_y: 0
  },
  fm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1172,
    offset_y: 0
  },
  fo: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1188,
    offset_y: 0
  },
  fr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1204,
    offset_y: 0
  },
  ga: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1220,
    offset_y: 0
  },
  gb: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1236,
    offset_y: 0
  },
  gd: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1252,
    offset_y: 0
  },
  ge: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1268,
    offset_y: 0
  },
  gf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1284,
    offset_y: 0
  },
  gh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1300,
    offset_y: 0
  },
  gi: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1316,
    offset_y: 0
  },
  gl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1332,
    offset_y: 0
  },
  gm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1348,
    offset_y: 0
  },
  gn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1364,
    offset_y: 0
  },
  gp: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1380,
    offset_y: 0
  },
  gq: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1396,
    offset_y: 0
  },
  gr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1412,
    offset_y: 0
  },
  gs: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1428,
    offset_y: 0
  },
  gt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1444,
    offset_y: 0
  },
  gu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1460,
    offset_y: 0
  },
  gw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1476,
    offset_y: 0
  },
  gy: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1492,
    offset_y: 0
  },
  hk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1508,
    offset_y: 0
  },
  hm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1524,
    offset_y: 0
  },
  hn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1540,
    offset_y: 0
  },
  hr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1556,
    offset_y: 0
  },
  ht: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1572,
    offset_y: 0
  },
  hu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1588,
    offset_y: 0
  },
  id: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1604,
    offset_y: 0
  },
  ie: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1620,
    offset_y: 0
  },
  il: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1636,
    offset_y: 0
  },
  in: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1652,
    offset_y: 0
  },
  io: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1668,
    offset_y: 0
  },
  iq: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1684,
    offset_y: 0
  },
  ir: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1700,
    offset_y: 0
  },
  is: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1716,
    offset_y: 0
  },
  it: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1732,
    offset_y: 0
  },
  jm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1748,
    offset_y: 0
  },
  jo: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1764,
    offset_y: 0
  },
  jp: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1780,
    offset_y: 0
  },
  ke: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1796,
    offset_y: 0
  },
  kg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1812,
    offset_y: 0
  },
  kh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1828,
    offset_y: 0
  },
  ki: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1844,
    offset_y: 0
  },
  km: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1860,
    offset_y: 0
  },
  kn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1876,
    offset_y: 0
  },
  kp: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1892,
    offset_y: 0
  },
  kr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1908,
    offset_y: 0
  },
  kw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1924,
    offset_y: 0
  },
  ky: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1940,
    offset_y: 0
  },
  kz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -20,
    offset_y: 0
  },
  la: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1972,
    offset_y: 0
  },
  lb: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -1988,
    offset_y: 0
  },
  lc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2004,
    offset_y: 0
  },
  li: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2020,
    offset_y: 0
  },
  lk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2036,
    offset_y: 0
  },
  lr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2052,
    offset_y: 0
  },
  ls: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2068,
    offset_y: 0
  },
  lt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2084,
    offset_y: 0
  },
  lu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2100,
    offset_y: 0
  },
  lv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2116,
    offset_y: 0
  },
  ly: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2132,
    offset_y: 0
  },
  ma: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2148,
    offset_y: 0
  },
  mc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2164,
    offset_y: 0
  },
  md: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2180,
    offset_y: 0
  },
  me: {
    image: '/sprites/flags.png',
    height: 12,
    width: 16,
    offset_x: -2196,
    offset_y: 0
  },
  mg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2212,
    offset_y: 0
  },
  mh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2228,
    offset_y: 0
  },
  mk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2244,
    offset_y: 0
  },
  ml: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2260,
    offset_y: 0
  },
  mm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2276,
    offset_y: 0
  },
  mn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2292,
    offset_y: 0
  },
  mo: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2308,
    offset_y: 0
  },
  mp: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2324,
    offset_y: 0
  },
  mq: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2340,
    offset_y: 0
  },
  mr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2356,
    offset_y: 0
  },
  ms: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2372,
    offset_y: 0
  },
  mt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2388,
    offset_y: 0
  },
  mu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2404,
    offset_y: 0
  },
  mv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2420,
    offset_y: 0
  },
  mw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2436,
    offset_y: 0
  },
  mx: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2452,
    offset_y: 0
  },
  my: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2468,
    offset_y: 0
  },
  mz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2484,
    offset_y: 0
  },
  na: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2500,
    offset_y: 0
  },
  nc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2516,
    offset_y: 0
  },
  ne: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2532,
    offset_y: 0
  },
  nf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2548,
    offset_y: 0
  },
  ng: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2564,
    offset_y: 0
  },
  ni: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2580,
    offset_y: 0
  },
  nl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2596,
    offset_y: 0
  },
  no: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2612,
    offset_y: 0
  },
  np: {
    image: '/sprites/flags.png',
    height: 11,
    width: 9,
    offset_x: 0,
    offset_y: 0
  },
  nr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2644,
    offset_y: 0
  },
  nu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2660,
    offset_y: 0
  },
  nz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2676,
    offset_y: 0
  },
  om: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2692,
    offset_y: 0
  },
  pa: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2708,
    offset_y: 0
  },
  pe: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2724,
    offset_y: 0
  },
  pf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2740,
    offset_y: 0
  },
  pg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2756,
    offset_y: 0
  },
  ph: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2772,
    offset_y: 0
  },
  pk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2788,
    offset_y: 0
  },
  pl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2804,
    offset_y: 0
  },
  pm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2820,
    offset_y: 0
  },
  pn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2836,
    offset_y: 0
  },
  pr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2852,
    offset_y: 0
  },
  ps: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2868,
    offset_y: 0
  },
  pt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2884,
    offset_y: 0
  },
  pw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2900,
    offset_y: 0
  },
  py: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2916,
    offset_y: 0
  },
  qa: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2932,
    offset_y: 0
  },
  re: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2948,
    offset_y: 0
  },
  ro: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2964,
    offset_y: 0
  },
  rs: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2980,
    offset_y: 0
  },
  ru: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -2996,
    offset_y: 0
  },
  rw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3012,
    offset_y: 0
  },
  sa: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3028,
    offset_y: 0
  },
  sb: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3044,
    offset_y: 0
  },
  sc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3060,
    offset_y: 0
  },
  scotland: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3076,
    offset_y: 0
  },
  sd: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3092,
    offset_y: 0
  },
  se: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3108,
    offset_y: 0
  },
  sg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3124,
    offset_y: 0
  },
  sh: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3140,
    offset_y: 0
  },
  si: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3156,
    offset_y: 0
  },
  sj: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3172,
    offset_y: 0
  },
  sk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3188,
    offset_y: 0
  },
  sl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3204,
    offset_y: 0
  },
  sm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3220,
    offset_y: 0
  },
  sn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3236,
    offset_y: 0
  },
  so: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3252,
    offset_y: 0
  },
  sr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3268,
    offset_y: 0
  },
  st: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3284,
    offset_y: 0
  },
  sv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3300,
    offset_y: 0
  },
  sy: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3316,
    offset_y: 0
  },
  sz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3332,
    offset_y: 0
  },
  tc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3348,
    offset_y: 0
  },
  td: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3364,
    offset_y: 0
  },
  tf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3380,
    offset_y: 0
  },
  tg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3396,
    offset_y: 0
  },
  th: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3412,
    offset_y: 0
  },
  tj: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3428,
    offset_y: 0
  },
  tk: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3444,
    offset_y: 0
  },
  tl: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3460,
    offset_y: 0
  },
  tm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3476,
    offset_y: 0
  },
  tn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3492,
    offset_y: 0
  },
  to: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3508,
    offset_y: 0
  },
  tr: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3524,
    offset_y: 0
  },
  tt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3540,
    offset_y: 0
  },
  tv: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3556,
    offset_y: 0
  },
  tw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3572,
    offset_y: 0
  },
  tz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3588,
    offset_y: 0
  },
  ua: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3604,
    offset_y: 0
  },
  ug: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3620,
    offset_y: 0
  },
  um: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3636,
    offset_y: 0
  },
  us: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3652,
    offset_y: 0
  },
  uy: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3668,
    offset_y: 0
  },
  uz: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3684,
    offset_y: 0
  },
  va: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3700,
    offset_y: 0
  },
  vc: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3716,
    offset_y: 0
  },
  ve: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3732,
    offset_y: 0
  },
  vg: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3748,
    offset_y: 0
  },
  vi: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3764,
    offset_y: 0
  },
  vn: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3780,
    offset_y: 0
  },
  vu: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3796,
    offset_y: 0
  },
  wales: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3812,
    offset_y: 0
  },
  wf: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3828,
    offset_y: 0
  },
  ws: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3844,
    offset_y: 0
  },
  ye: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3860,
    offset_y: 0
  },
  yt: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3876,
    offset_y: 0
  },
  za: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3892,
    offset_y: 0
  },
  zm: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3908,
    offset_y: 0
  },
  zw: {
    image: '/sprites/flags.png',
    height: 11,
    width: 16,
    offset_x: -3924,
    offset_y: 0
  }
};
