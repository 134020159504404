/**
 * Created by Benjamin on 1/14/2016.
 */

import React from 'react';

export default ({ theme }) => {
  if (theme === 'nasdaq') {
    return <span>Nasdaq</span>;
  }

  return (
    <span className="tipranks-swag-text">
      <span className="tip">Tip</span>
      <span className="ranks">Ranks</span>
    </span>
  );
};
