// Just return a value to define the module export.
// This example returns an object, but the module
// can return a function as the exported value.

type Options = typeof defaults;
// Private properties
let options = {} as Options;

const isBrowser = typeof document !== 'undefined';

const defaults = {
  url: '/assets/img/favicon-' + process.env.THEME + '.ico',
  color: '#eb361e',
  lineColor: '#ffffff',
  lineWidth: 1,
  radiusRelativeToSize: 1 / 4.5
};

let generatedFavicon;
let iconElement;

// Provate methods
const addFavicon = function (src) {
  const head = document.getElementsByTagName('head')[0];
  iconElement = document.createElement('link');
  iconElement.type = 'image/x-icon';
  iconElement.rel = 'icon';
  iconElement.href = src;

  // remove existing favicons
  const links = document.getElementsByTagName('link');
  for (let i = 0; i < links.length; i++) {
    const exists = typeof links[i] !== 'undefined';
    if (exists && (links[i].getAttribute('rel') || '').match(/\bicon\b/)) {
      head.removeChild(links[i]);
    }
  }

  head.appendChild(iconElement);
};

// generatefavicon
const generateIcon = function (cb) {
  const img = document.createElement('img');
  img.src = options.url;

  img.onload = function () {
    const lineWidth = options.lineWidth;
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const context = canvas.getContext('2d') as any;
    context.clearRect(0, 0, img.width, img.height);
    context.drawImage(img, 0, 0);

    let centerX =
      img.width - img.width * options.radiusRelativeToSize - lineWidth;
    let centerY = img.height * options.radiusRelativeToSize - lineWidth + 1;
    let radius = img.width * options.radiusRelativeToSize;

    context.fillStyle = options.color;
    context.strokeStyle = options.lineColor;
    context.lineWidth = lineWidth;

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, Math.PI * 2, false);
    context.closePath();
    context.fill();
    context.stroke();

    cb(null, context.canvas.toDataURL());
  };
};

const setOptions = function <K extends keyof Options>(
  userOptions?: Pick<Options, K>
) {
  if (!userOptions) {
    options = defaults;
    return;
  }

  options = {} as Options;
  for (const key in defaults) {
    if (defaults.hasOwnProperty(key))
      // TODO
      options[key as any] = userOptions.hasOwnProperty(key)
        ? userOptions[key as any]
        : defaults[key as any];
  }
};

export function init<K extends keyof Options>(userOptions: Pick<Options, K>) {
  if (!isBrowser) {
    return;
  }

  setOptions(userOptions);

  generateIcon(function (err, url) {
    generatedFavicon = url;
  });
  addFavicon(options.url);
}

export function add() {
  if (!isBrowser) {
    return;
  }

  if (!generatedFavicon || !iconElement) {
    generateIcon(function (err, url) {
      generatedFavicon = url;
      addFavicon(url);
    });
  } else {
    iconElement.href = generatedFavicon;
  }
}

export function remove() {
  if (!isBrowser) {
    return;
  }
  if (iconElement) iconElement.href = options.url;
}

export default { init, add, remove };
