import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import initGraph from './js/graph';

export default class TrMarketOverview extends Component {
  static propTypes = {
    changedMarketOverviewMarketVisibility: PropTypes.func,
    changedMraketOverviewDuration: PropTypes.func,
    duration: PropTypes.string,
    mgmtFee: PropTypes.object,
    visibleMarkets: PropTypes.object
  };

  static defaultProps = {
    duration: '1y'
  };

  componentDidMount() {
    const {
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      duration,
      mgmtFee,
      visibleMarkets
    } = this.props;

    this.graph = initGraph({
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      duration,
      mgmtFee,
      visibleMarkets: visibleMarkets.toJS()
    });
  }

  shouldComponentUpdate() {
    // we wnat only to decide the state of the UI on initial rendering, and let
    // initGraph code handle future state changes
    return false;
  }

  componentWillUnmount() {
    this.graph.unmount();
  }

  checkedIfVisible = market => {
    const { visibleMarkets } = this.props;
    return visibleMarkets.includes(market) ? 'checked="checked"' : '';
  };

  activateIfSelected = currentDuration => {
    const { duration } = this.props;
    return currentDuration === duration ? 'bottom_button_selected' : '';
  };

  render() {
    const { mgmtFee } = this.props;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <div
              class="market_wraper market_wraper_fees"
              id="mainBox"
            >
              <div class="market_wraper_fees-loading">
                <img src="/assets/img/loader.gif" />
              </div>
              <div class="inner_market_wraper">
                <div class="color_code">
                  <ul>
                    <li>
                      <input
                        ${this.checkedIfVisible(mgmtFee.get('fundSymbol'))}
                        class="line_select css-checkbox"
                        data-id="${mgmtFee.get('fundSymbol')}"
                        id="checkbox68"
                        type="checkbox"
                      />
                      <label
                        class="css-label chrome-style"
                        for="checkbox68"
                        name="checkbox68_lbl"
                      >
                        <span class="current_color"></span>
                        <p class="color_text">
                          ${mgmtFee.get('fundSymbol').toUpperCase()}
                        </p>
                      </label>
                    </li>
                    <li>
                      <input
                        ${this.checkedIfVisible(mgmtFee.get('similarSymbol'))}
                        class="line_select css-checkbox"
                        data-id="${mgmtFee.get('similarSymbol')}"
                        id="checkbox69"
                        type="checkbox"
                      />
                      <label
                        class="css-label chrome-style"
                        for="checkbox69"
                        name="checkbox69_lbl"
                      >
                        <span class="similar_color "></span>
                        <p class="color_text">
                          ${mgmtFee.get('similarSymbol').toUpperCase()}
                        </p>
                      </label>
                    </li>
                  </ul>
                  <div class="clear"></div>
                  <div id="chart_area_full"></div>
                  <div class="clear"></div>
                  <div class="bottom_buttons">
                    <ul>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('1d')}"
                          data-id="1d"
                        >
                          1D
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('5d')}"
                          data-id="5d"
                        >
                          5D
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('30d')}"
                          data-id="30d"
                        >
                          1M
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('3m')}"
                          data-id="3m"
                        >
                          3M
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('ytd')}"
                          data-id="ytd"
                        >
                          YTD
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('1y')}"
                          data-id="1y"
                        >
                          1Y
                        </a>
                      </li>
                      <li>
                        <a
                          class="data_select ${this.activateIfSelected('5y')}"
                          data-id="5y"
                        >
                          5Y
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          `
        }}
        id="mainBox"
      />
    );
  }
}
