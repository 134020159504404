import reporter from '../analytics';

const events = {
  confirmed: portfolioName => ({
    event: 'confirmed-portfolio-removal',
    props: { portfolioName }
  })
};

const report = reporter(events);

export default report;
