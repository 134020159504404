import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import './UserWarnings.styl';

export default class UserWarnings extends Component {
  static propTypes = {
    msg: PropTypes.object,
    warnings: PropTypes.object
  };

  render() {
    const { msg, warnings, isLoading, loadingText } = this.props;

    return (
      <ul className="user-warnings">
        {warnings.map((warning, index) => (
          <li key={index}>
            <span className="warning-text">
              <span className="warning-label">{msg.warning}:</span>
              &nbsp;
              <span>{isLoading ? loadingText : warning}</span>
            </span>
          </li>
        ))}
      </ul>
    );
  }
}
