import createEngine from './storageEngine';

import configureStore from '../common/configureStore';

window.trDashboardAppLocalStorageName = `dashboard-${window.__HASH_CODE__ ||
  'app'}`;

const initialState = window.__INITIAL_STATE__;

export const engine = createEngine(window.trDashboardAppLocalStorageName);
const store = configureStore({ engine, initialState });

Object.defineProperty(window, '__state__', {
  get() {
    return store.getState();
  }
});

window.__store__ = store;

export default store;
