import { isShittyBrowser } from '../../lib/utils';
import { isIOS } from '../../../common/lib/utils';
import './PeriodicPerformanceGraph.styl';

import * as classNames from 'classnames';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as React from 'react';

import { Option, PerformanceDropdownRO, MY_PORTFOLIO_OPT } from '../dropdown/PerformanceDropdown';
import {
  bandSeperator,
  GeneralPeriodicGraph,
  GraphAlignment,
  Value,
} from './PeriodicGraph';

const css = { fontSize: '12px', color: 'black' };

// TODO responsiveness in table, responsiveness in column width.
// the specific graph used in my performance
export class PeriodicGraphWithTable extends React.PureComponent<{
  className?: string;
  data: PerformancePeriodicGraphDatum[];
  itemNum: number;
  columns: Timestamp[];
  xAxisLabelTextAlign: GraphAlignment;
}> {
  state = { bandWidths: [] as number[], chartWidth: 0, plotBoxX: 0 };
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.state, nextState) || nextProps !== this.props;
  }
  render() {
    const {
      className,
      data: rawUnfilteredData,
      itemNum,
      xAxisLabelTextAlign,
      columns: xAxisItems,
    } = this.props;
    const { bandWidths, chartWidth, plotBoxX } = this.state;
    const rawData = rawUnfilteredData.filter(
      Boolean,
    ) as PerformancePeriodicGraphDatum[];
    const seriesLength = rawData.length;
    const allData = rawData.map(d => ({
      ...d,
      color: (d.style || ({} as any)).backgroundColor,
      // here we make sure data has "forward months"
      data: d.name === MY_PORTFOLIO_OPT.name && !d.data.length ? this.getZeroSeries() : adaptData(xAxisItems, d.data),
    }));
    const data = allData.map(serie => ({
      ...serie,
      data: serie.data.slice(-itemNum),
    }));

    const tableColumnLength = Math.max.apply(Math, rawData.map(d => d.data.length));

    return (
      <div className={classNames('performanceGraphContainer', className)}>
        <GeneralPeriodicGraph
          className={classNames('performanceGraph')}
          onRenderedChart={this.onRenderedChart}
          data={data}
          style={{
            height: 180,
          }}
          itemNum={itemNum}
          xAxisItems={xAxisItems}
          xAxisLabelTextAlign={xAxisLabelTextAlign}
        />
        <div className={classNames('table')}>
          {bandWidths &&
            chartWidth &&
            plotBoxX &&
            data.map(({ style, text, shortText, data: data_, name }, rowIndex) => {
              const data = data_.length ? data_ : Array.from({ length: tableColumnLength }, () => null);
              return (
                <div key={name} className={classNames('row')}>
                  <PerformanceDropdownRO
                    value={{ style, name, text: shortText || text }}
                  />
                  <ul
                    className={classNames('rowStart', {
                      withShittyIOS: isIOS(),
                      withShittyExplorer: isShittyBrowser,
                    })}
                    style={{ marginLeft: plotBoxX }}
                  >
                    {data.slice(-itemNum).map((gainValue, index) => (
                      <li
                        key={index}
                        className={classNames('column', {
                          positive: (gainValue || 0) > 0,
                          negative: (gainValue || 0) < 0,
                        })}
                        style={{
                          width:
                            parseFloat((bandWidths[rowIndex] || 15).toFixed(2)) - bandSeperator
                         /*, marginRight: bandSeperator */,
                        }}
                      >
                        {gainValue
                          ? (gainValue > 0 ? '+' : '') + parseFloat(gainValue.toString() || '0').toFixed(2) + '%'
                          : gainValue === null ? '-' : '0%'}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
  onRenderedChart = chart => {
    const bandWidths = chart.series.map(serie => serie.closestPointRangePx);
    const plotBoxX = chart.plotBox.x;
    const chartWidth = chart.plotBox.width;
    this.setState({ bandWidths, chartWidth, plotBoxX });
  };
  // getZeroSeries = (): PerfGain[] => {
  //   const { itemNum, columns } = this.props;

  //   return columns.map(t => {
  //     const date = new Date(t);
  //     return { year: date.getFullYear(), month: date.getMonth(), gainValue: 0 };
  //   });
  // }
  getZeroSeries = (): Value[] => {
    const { itemNum, columns } = this.props;

    return columns.map(t => null);
  }
}


export function getPeriodicTableColumns(allSeries: PerfGain[][], toSlice: number): Timestamp[] {
  return _.chain(allSeries)
    // TODO index from 1, as that's the way gains come from the server
    // I'm sorry this knowledge is encoded here instead of a reasonable place
    .map(d => d.map(datumToDate))
    .flatten()
    .uniq()
    .sort()
    .slice(-toSlice) // we slice here instead of in chartiq
    .value()
    ;
}
export function adaptData(periodicCols: Timestamp[], data: PerfGain[]): Value[] {
  return periodicCols.map(date =>
    _.get(data.find(d => moment(date).diff(datumToDate(d)) === 0), 'gainValue', null)
  );
}

const datumToDate = ({ year, month }: { year: number, month: number }) => (new Date(year, month, 0, 0, 0, 0, 0)).getTime();

const makeSureIsShort = str =>
  str.length > 7 && str.split(' ').length
    ? { str: str.replace(' ', '<br />'), shortAmount: str.split(' ').length }
    : { str, shortAmount: 0 };

export type PerformancePeriodicGraphDatum = { data: PerfGain[] } & Option;
export type PerformancePeriodicGraphData = PerformancePeriodicGraphDatum[];

// Change name if you need to export it
export type PerfGain = { year: number; month: number; gainValue: Value };

export type Timestamp = number;