import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { selectPortfolios } from '../dashboard/selectors';
import PortfolioSelect from '../components/portfolio-select';
import mapStateToProps from '../../common/app/mapStateToProps';
import mapDispatchToProps from '../../common/app/mapDispatchToProps';

@connect(mapStateToProps, mapDispatchToProps)
export default class TrPortfolioSelectContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object
  };

  onPortfolioChange = portfolioId => {
    const { actions, dashboard } = this.props;

    if (portfolioId === -3) {
      if (dashboard.get('routeNav')) actions.toggleRouteNav();
      actions.openDialog('portfolios');
    } else {
      actions.changePortfolio(portfolioId);
    }
  };

  render() {
    const { dashboard } = this.props;

    return (
      <PortfolioSelect
        activePortfolioId={dashboard.get('activePortfolioId')}
        onChange={this.onPortfolioChange}
        portfolios={selectPortfolios(dashboard)}
      />
    );
  }
}
