import * as React from 'react';
import { Map } from 'immutable';
import * as classNames from 'classnames';
import { isShittyBrowser } from 'sp/browser/lib/utils';

import Filters from './filters';
import { HoldingsTableContainer } from './table.container';
import './styles.styl';
import { selectScreenerStocks, selectScreenerStocksTotal } from './selectors';
import { filters as confFilters, getRawCols, maxShowMore, links } from './conf';

import report from './analytics';

import { isIOS } from '../../common/lib/utils';
import { exportToPortfolioCSV } from '../tr-portfolio-csv/index';
import { getCols } from '../tr-dashboard-holdings-table/conf';
import RelatedLinks from '../RelatedLinks/index';

import { ReduxProps } from '../../common/types';

import { ApiDecorator } from '../ApiDecorator/ApiDecorator.react';
import {
  selectIsPermanentLoggedIn,
  selectViewerPlan
} from '../../common/auth/selectors';
import { dashboardPath } from '../../common/config';
import ExternalRegister from '../tr-external-auth/signup-form.react';
import { VariableSizeSpinner } from '../Spinner/index.react';
import { history } from 'sp/browser/history';

interface StockScreenerProps extends ReduxProps { }

@ApiDecorator('details')
export default class StockScreener extends React.PureComponent<
StockScreenerProps,
{ fields: Map<string, string[]>; page: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      fields: Map(confFilters.map(({ apiLabel }) => [apiLabel, ''])),
      page: 1
    };
  }

  componentDidMount() {
    report('visited');
  }

  onSubmit = event => {
    const { actions } = this.props;
    event.preventDefault();
    report('clickedSearch');
    this.setState({ page: 1 }, () =>
      actions.changeStockScreenerFilters(this.state)
    );
  };

  onAuthenticatedDialog = () => {
    const { actions } = this.props;

    actions.identifyUser().then(() => {
      actions.closeDialog();
      history.push(`/${dashboardPath}/screener`);
      actions.fetchPortfolios();
    });
  };

  exportToCSV = () => {
    const {
      // clickedExportCSV,
      mediaQuery,
      msg,
      auth
    } = this.props;

    // clickedExportCSV();

    const cols = getCols(getRawCols)(mediaQuery).map(item => item.get('id'));
    const items = selectScreenerStocks(this.props, 'stockScreener');

    exportToPortfolioCSV({
      cashValue: 0,
      enums: msg['enums'],
      fileName: 'screener.csv',
      holdingsCols: cols,
      items,
      viewerPlan: selectViewerPlan(auth),
      limit: {
        open: 20,
        free: 20,
        premium: 100,
        ultimate: 100
      }
    });

    // TODO: ADD THE BUTTON
  };

  changeFilter = (label, value) => {
    this.setState({ fields: this.state.fields.set(label, value) });
  };

  handleShowMore = e => {
    const { actions } = this.props;
    e.preventDefault();
    report('clickedShowMore');
    this.setState({ page: this.state.page + 1 }, () =>
      actions.changeStockScreenerFilters(this.state)
    );
  };

  get hasFields() {
    return (
      this.state.fields &&
      this.state.fields.some((value = []) => value.length > 0) &&
      this.state.fields !== this.props.stockScreener.fields
    );
  }

  get hasMoreData() {
    return (
      this.state.page < maxShowMore &&
      this.state.page &&
      selectScreenerStocks(this.props, 'stockScreener').size > 0 &&
      this.state.page * 20 < selectScreenerStocksTotal(this.props)
    );
  }

  render() {
    const { actions, auth, stockScreener, mediaQuery } = this.props;

    const isPermanentLoggedIn = selectIsPermanentLoggedIn();
    const resultsTotal = selectScreenerStocksTotal(this.props);
    const items = selectScreenerStocks(this.props, 'stockScreener');
    const relatedLinks = process.env.THEME !== 'nasdaq' && (
      <div className="relatedLinks">
        <RelatedLinks links={links} />
      </div>
    );

    if (!isPermanentLoggedIn && process.env.THEME !== 'nasdaq') {
      return (
        <div className="tr-external-login-wrapper">
          <ExternalRegister
            onAuthenticated={this.onAuthenticatedDialog}
            type={'screener'}
          />
        </div>
      );
    }

    return (
      <main className="stockScreenerMain">
        <div className="filters">
          <h2>Filters</h2>
          <Filters
            stockType={'stockScreener'}
            filters={confFilters}
            auth={auth}
            openDialog={actions.openDialog}
            changeFilter={this.changeFilter}
            hasFields={this.hasFields}
            onSubmit={this.onSubmit}
            screener={stockScreener}
            selectedFields={this.state.fields}
            report={report}
          />

          {mediaQuery.get('laptopL') && relatedLinks}
        </div>
        <div className="table">
          <div className="resultsHeader">
            <h2>Results</h2>
            {selectScreenerStocks(this.props, 'stockScreener').size > 0 && (
              <div className="results-summary">
                Showing{' '}
                <span className="results-number">
                  {stockScreener.tickers.size}
                </span>{' '}
                of <span className="results-total">{resultsTotal}</span> Results
              </div>
            )}
          </div>
          <HoldingsTableContainer
            {...this.props}
            currentPage={this.state.page}
            screener={stockScreener}
            stockType="stockScreener"
            report={report}
            getRawCols={getRawCols}
          />
          {(this.hasMoreData || stockScreener.loadingMoreItems) && (
            <button
              className={classNames('showMore', {
                loading: stockScreener.loadingMoreItems
              })}
              onClick={this.handleShowMore}
            >
              <span className="text">Show More</span>
              <VariableSizeSpinner size={30} className="spinner" />
            </button>
          )}
          <div className="controls-below-table">
            {items.size > 0 &&
              !isIOS() && (
                <button
                  disabled={
                    !items
                      .get(0)
                      .get('stock')
                      .get('price')
                      .get('amount')
                  }
                  className="csv"
                  onClick={this.exportToCSV}
                >
                  Download
                </button>
              )}
          </div>

          {!mediaQuery.get('laptopL') && relatedLinks}
        </div>
      </main>
    );
  }
}
