import { List, Map } from 'immutable';
import { MediaQuery } from 'sp/common/lib/mediaQuery';
import { BetterRecord } from 'sp/common/immutableStuff';
import { trDropZoneStatus as uploadStatus } from '../browser/tr-dropzone/types';

// import { Actions } from 'sp/common/app/mapDispatchToProps';
import { suits } from 'sp/common/enums';
import { AddOn } from './api-adapter';

export interface UserProfile_NotImmutable {
  state: keyof typeof uploadStatus;
  error: any;
  hasError: boolean;
  picture: any;
}
// TODO convert these to enums one day
export type Period_UI = number;
export type Benchmark_UI = number;
export type PerfDisplayTab = string;

export interface UI_NotImmutable {
  isSideMenuOpen: boolean;
  period: Period_UI;
  benchmark: Benchmark_UI;
  performanceDisplayTab: PerfDisplayTab;
  userProfile: BetterRecord<UserProfile_NotImmutable>;
}

export type UI = BetterRecord<UI_NotImmutable>;

export type ReduxProps = { actions: Actions } & ReduxStore;
export interface ReduxStore {
  alertsSettings: AlertsSettings;
  auth: AuthRecord;
  autocomplete: Autocomplete;
  contact: Contact;
  crowdInsights: CrowdInsights;
  dashboard: Dashboard;
  device: Device;
  dialogs: DialogStoreRecord;
  dictionary: Dictionary;
  history: History;
  importedUser: ImportedUser;
  intl: Intl;
  mediaQuery: MediaQuery;
  mobileContainer: MobileContainer;
  msg: any;
  myExperts: MyExperts;
  //asdf
  performance: Performance;
  portfolioCalendar: PortfolioCalendar;
  stockScreener: Screener;
  etfScreener: Screener;
  toasts: Toasts;
  tooltips: Tooltips;
  ui: UI;
  users: Users;
}

type Message = { [key: string]: string };
export type ComponentState =
  | 'LOADING'
  | 'ERROR'
  | 'SUCCESS'
  | 'INITIAL'
  | 'INVALID';

export type ComponentStatesObj = {
  stats: ComponentState;
  experts: ComponentState;
  portfolios: ComponentState;
  warnings: ComponentState;
  stocks: ComponentState;
  newsByTickers: ComponentState;
  eventsByTickers: ComponentState;
  myportfolio: ComponentState;
  portfolioStats: ComponentState;
  portfolio: ComponentState;
  addStock: ComponentState;
  userPerf: ComponentState;
  userInfo: ComponentState;
  deleteStock: ComponentState;
  addPortfolio: ComponentState;
  renamePortfolio: ComponentState;
  details: ComponentState;
  deletePortfolio: ComponentState;
  performanceComingSoon: ComponentState;
  autoComplete: ComponentState;
  avgPortfolioStocks: ComponentState;
  avgPortfolioDetails: ComponentState;
  bestStocks: ComponentState;
  bestDetails: ComponentState;
  portfolioBetaRisk: ComponentState;
  mgmtFees: ComponentState;
  fundsFeesByTickers: ComponentState;
  getNumPortfolios: ComponentState;
  newsSentiments: ComponentState;
  userFollowedStocks: ComponentState;
  userFollowedExperts: ComponentState;
  changePassword: ComponentState;
  changeEmailFrequency: ComponentState;
  portfolioItems: ComponentState;
  avgPortfolioItems: ComponentState;
  bestPortfolioItems: ComponentState;

  monthlyGainsForTickerWithDaysBack: Map<String, ComponentState>;
  prices: Map<String, ComponentState>;
  // TODO these keys are without ComponentState
  userPref: never;
  alertsSettings: never;
  portfolioGains: never;
  portfolioMonthlyGain: never;
  avgPortfolioStats: never;
  stocksWithNewsSentiment: never;
};
export type ComponentStates = BetterRecord<ComponentStatesObj>;
export type ComponentStateKeys = keyof ComponentStatesObj;
export type ComponentStateKeysFlat = keyof Omit<
  Omit<ComponentStatesObj, 'prices'>,
  'monthlyGainsForTickerWithDaysBack'
  >;

export type ReactRouterLocation = any;
export interface AlertsSettings_NotImmutable {
  isDisabled: boolean;
  isDisabledExperts: boolean;
  totalAlertsPredictions: number;
  componentStates: BetterRecord<{
    alertsSettings: ComponentState;
    changeSettings: ComponentState;
  }>;
  settings: EmailAlertSettings;
  expectedExpertEmails: number;
  expectedStockEmails: number;
  numSubscriptionToExperts: number;
}

export type AlertsSettings = BetterRecord<AlertsSettings_NotImmutable>;
// TODO this is a mock enum, change to use actual values from server
// when they land
export enum PortfolioPrivacy {
  Private = 1,
  Public = 2,
  PublicUnlisted = 3,
}

export type PortfolioMadePublic = BetterRecord<{ id: number, slug: string, privacy: PortfolioPrivacy }>;
export type LoggedInUser_NotImmutable = {
  email: string;
  hasHoldings: boolean;
  id: string;
  expertId: number;
  isFirstTimeMigratedUser: boolean;
  isFirstVisit: boolean;
  name: string;
  name_old?: string;
  phone: string;
  bypassEmailLimit: boolean;
  areEmailAlertsActive: boolean;
  plan: Plan;
  planId: PlanEnum;
  signupDate: string;
  latestCreatedPublicPortfolio: BetterRecord<{ id: number, slug: string }>;
  portfoliosWithPerformance: List<BetterRecord<{ id: number, name: string }>>,
  portfoliosMadePublic: List<PortfolioMadePublic>,
  componentStates: BetterRecord<{
    portfoliosMadePublic: suits, // SET_PORTFOLIO_PUBLIC
    setPortfolioPrivacy: suits, // SET_PORTFOLIO_PRIVACY
  }>
  addOns: List<BetterRecord<AddOn>>;
  isSpaasMigrated: boolean;
}
export type LoggedInUserRecord = BetterRecord<LoggedInUser_NotImmutable>;

export interface UserRecord_NotImmutable {
  loggedInUser: LoggedInUserRecord;
  plan: 'open' | 'free' | 'premium' | 'ultimate';
  isFirstVisit: boolean;
  isFirstTimeMigratedUser: boolean;
  isSpaasMigrated: boolean;
}

export interface Form {
  state: ComponentState;
  disabled: boolean;
  error: null;
  fields: {
    email: string;
    password: string;
  };
}

export interface AuthAction {
  type: string;
  payload: any;
}
export interface Auth_NotImmutable {
  form: BetterRecord<Form>;
  didIdentify: boolean;
  redirecting: boolean;
  users: BetterRecord<{
    viewer: BetterRecord<UserRecord_NotImmutable>;
  }>;
  ver: number;
}
export type AuthRecord = BetterRecord<Auth_NotImmutable>;
export type Autocomplete = any;
export type Contact = {
  error: boolean;
  success: boolean;
  loading: boolean;
};
export type CrowdInsights = any;
export interface Stats_NotImmutable {
  numPortfolios: number;
  expertsTotal: number;
  investorsTotal: number;
  bloggersTotal: number;
  hedgefundsTotal: number;
  insidersTotal: number;
  sectorsTotal: any;
  analystsTotal: number;
}
export type StatsRecord = BetterRecord<Stats_NotImmutable>;
export type UserInfoRecord = BetterRecord<UserInfo_NotImmutable>;
export interface UserInfo_NotImmutable {
  age: number;
  profession: string;
  annualIncome: number;
  investmentTerm: string;
  riskTolerance: string;
  pictureUrl: string;
}
export interface GainDatum {
  gain: number;
  month: number;
  year: number;
}
export type GainDatumRecord = BetterRecord<GainDatum>;
export type GainDataRecord = BetterRecord<{
  portfolioId: number;
  monthGains: List<GainDatumRecord>;
}>;
export interface GainData {
  portfolioId: number;
  symbol?: string;
  monthGains: GainDatum[];
}
// TODO type the call that get's these
export type DashboardGain = {
  // portfolioMonthGains is the user's portfolios gains, and that's why it's a list.
  averagePortfolioGains: GainData;
  bestPortfolioGains: GainData;
  portfolioMonthGains: GainData[]; // TODO eventually change to something like userPortfolioGains
  snpGains: GainData;
}[];

export type DashboardGainRecord = BetterRecord<{
  // portfolioMonthGains is the user's portfolios gains, and that's why it's a list.
  // TODO it should have been a map between portfolioId to gains, but...
  // TODO there should have been a hashmap of "other portfolios" and "other gains", but instead... it's a map of this shit.
  averagePortfolioGains: GainDataRecord;
  bestPortfolioGains: GainDataRecord;
  portfolioMonthGains: List<GainDataRecord>; // TODO eventually change to something like userPortfolioGains
  snpGains: GainDataRecord;
}>;

export interface Data_NotImmutable {
  userInfo: UserInfoRecord;
  stocks: List<DashboardStock>;
  portfolios: List<PortfolioRecord>;
  mgmtFees: MgmtFeesDashboard;
  alerts: List<any>;
  experts: List<any>;
  gains: List<DashboardGainRecord>;
  screenerTotal: any;
  stats: StatsRecord;
  userPerf: Map<any, any>;
}
export type DashboardDataRecord = BetterRecord<Data_NotImmutable>;
export interface Dashboard_NotImmutable {
  activePortfolioId: number;
  apiSignature: Map<string, number>; // "apiName.param1.param2" -> timestamp
  assetAllocationType: string;
  busyExperts: List<any>;
  componentStates: ComponentStates;
  data: DashboardDataRecord; // Object
  dataList: any; // Array(0)
  dataListQuery: any; // ""
  deletedExperts: any; // Array(0)
  deletedPortfolios: any; // Array(0)
  dismissdedWarningDialogs: List<any>; // Array(27)
  editableId: any; // ""
  editablePortfolioId: any; // -1
  editablePortfolioValue: any; // ""
  editableValue: any; // ""
  filters: any; // Object
  holdingsCols: any; // Array(14)
  holdingsColsPendings: any; // Array(14)
  holdingsPage: any; // 0
  holdingsScroll: any; // 0
  holdingsSortBy: string; // "name"
  importState: any; // "initial"
  isMarketOpened: boolean; // true
  isholdingsSortDescending: boolean; // true
  majorHoldingsSector: any; // "general"
  majorHoldingsType: 'stock' | 'other';
  mraketOverviewDuration: any; // "3m"
  newsSentiments: any; // Object
  overviewPortfolioNewsTab: any; // "all"
  pendingStocksToAdd: any; // Array(0)
  performance: any; // null
  portfolioNewsTicker: any; // "-1"
  prices: any; // Object
  routeNav: any; // Object
  stockAddedFlag: any; // Array(0)
  tblType: HoldingsTableType;
  basicTableType: BasicTableType;
  theme: Theme; // "web"
  visibleMarketOverviewMarkets: any; // Array(3)
  userFollowedStocks: List<any>;
  userFollowedExperts: number;
  monthlyGainsForTickerWithDaysBack: BetterRecord<any>;
}
// TODO rename to DashboardRecord to keep consistency
export type Dashboard = BetterRecord<Dashboard_NotImmutable>;
// TODO add isSecondaryTicker to this.
export interface DashboardStock_NotImmutable {
  '1yrReturn': number; //0
  '3yrReturn': any; //0
  '5yrReturn': any; //0
  analystConsensus: any; //Object
  bestAnalystConsensus: any; //Object
  bestTarget: any; //166
  bestTargetPercent: any; //0.13838979563845832
  bloggerSentiment: any; //1
  bloggerSentimentScore: any; //0.865979381443299
  category: any; //0
  dividend: any; //0.63
  dividendDate: any; //null
  earningsReport: any; //Mon Jul 24 2017 2: any; //: any; //0 GMT+0300 (Jerusalem Daylight Time)
  eps: any; //Mon May 01 2017 2: any; //: any; //0 GMT+0300 (Jerusalem Daylight Time)
  etfType: any; //"1"
  exchangeRate: any; //1
  expenseRatio: any; //0
  hasLink: any; //true
  hedgefundSentiment: any; //1
  hedgefundSentimentScore: any; //0.9818
  id: any; //"9ff52fc5"
  insiderSentiment: any; //3
  insiderSentimentScore: any; //0.0341
  investmentStyle: any; //0
  isSecondaryTicker: any; //false
  issuer: any; //0
  landmarkPrices: BetterRecord<{
    threeMonthsReturn: number;
    YTDReturn: number;
    '1yrReturn': number;
  }>;
  managementFee: any; //0
  name: any; //"Apple"
  newsSentiment: any; //null
  newsSentimentScore: any; //0.5502
  pe: any; //17.05
  price: DashStockPriceRecord;
  sector: any; //"consumergoods"
  source: string; //"getPortfolioHoldingStockData"
  target: any; //164.07
  targetPercent: any; //0.1251542998216979
  ticker: any; //"AAPL"
  totalAssets: any; //759111943300
  type: StockType;
  yield: any; //0.017
  marketCap: number;

}
export type DashboardStock = BetterRecord<DashboardStock_NotImmutable>;
export type DashStockPriceRecord = BetterRecord<DashStockPriceRecord_NotImmutable>
export type DashStockPriceRecord_NotImmutable = {
  cap: number,
  date: Date | null,
  changePercent: number | null,
  changeAmount: number | null,
  ftWeekLow: number | null,
  beta: number,
  ftWeekHigh: number | null,
  amount: number | null,
}
export type Device = any;
export type Dictionary = any;
export type ImportedUser = any;
export type History = any;
export type Intl = any;
export type MobileContainer = any;
export type MyExperts = any;
export type RiskTolerance =
  | 'Very low'
  | 'Low'
  | 'Medium'
  | 'High'
  | 'Very High';
export type PortfolioCalendar = any;
export type PortfolioItemAlert = BetterRecord<{
  action: -1;
  date: null;
  id: -1;
  hasBeenRead: false;
  person: null;
  article: null;
  stock: null;
  type: -1;
}>;
export interface PortfolioNewsSetRecord_NotImmutable {
  all: List<any>;
  positive: List<any>;
  negative: List<any>;
}
export type PortfolioNewsSetRecord = BetterRecord<
  PortfolioNewsSetRecord_NotImmutable
  >;
export interface PortfolioItemRecord_NotImmutable {
  addedDate: Date;
  alerts: List<PortfolioItemAlert>;
  id: number;
  readAllAlerts: boolean;
  isAlertsOn: boolean;
  ticker: string;
  name: string;
  isSecondaryTicker: boolean; // TODO why is this here and not on the stock?
  stock: DashboardStock;
  portfolioNews: PortfolioNewsSetRecord;
  sharesTotal: number;
  percentPortfolio: number;
  sharesValue: number;
  purchasePrice: number;
  addedPrice: number;
  gainSinceAdded: number;
  sincePurchaseGain: number;
  sincePurchaseGainAmount: number;
}
export type PortfolioItemRecord = BetterRecord<
  PortfolioItemRecord_NotImmutable
  >;
export interface PortfolioRecord_NotImmutable {
  asset: List<any>;
  id: number;
  type: PortfolioType;
  tradeItSyncStatus: TradeItSyncStatus;
  plaidSyncStatus: PlaidSyncStatus;
  isImported: boolean;
  events: List<any>;
  feed: PortfolioFeedSetRecord;
  history: List<any>;
  name: string;
  siteName: string;
  news: List<any>;
  performance: PortfolioPerformanceSetRecord;
  risk: PortfolioRiskRecord;
  stats: PortfolioStatsRecord;
  modes: List<any>;
  tickers: List<any>;
  warnings: List<PortfolioWarning>;
  items: List<PortfolioItemRecord>;
  managementFees: number;
  managementFeesPercent: number;
  cashValue: number;
}
export type PortfolioRecord = BetterRecord<PortfolioRecord_NotImmutable>;
export interface PortfolioFeedSetRecord_NotImmutable {
  analyst: List<any>;
  insider: List<any>;
  blogger: List<any>;
}
export type PortfolioFeedSetRecord = BetterRecord<
  PortfolioFeedSetRecord_NotImmutable
  >;
export interface PortfolioPerformanceSetRecord_NotImmutable {
  past: List<any>;
}
export type PortfolioPerformanceSetRecord = BetterRecord<
  PortfolioPerformanceSetRecord_NotImmutable
  >;
export interface PortfolioRiskRecord_NotImmutable {
  userRisk: number;
  companyRisk: number;
  above: number;
}
export type PortfolioRiskRecord = BetterRecord<
  PortfolioRiskRecord_NotImmutable
  >;
export interface PortfolioStatsRecord_NotImmutable {
  pe: number;
  isImported: false; // TODO either get this from here, or from PortfolioRecord.
  id: number;
  gain: PortfolioGainSetRecord;
  amount: number;
  yield: number | null;
  changes: number | null;
  cashValue: number; // TODO either get this from here, or from PortfolioRecord.
}
export type PortfolioStatsRecord = BetterRecord<
  PortfolioStatsRecord_NotImmutable
  >;

export interface PortfolioGainSet_NotImmutable {
  daily: PortfolioGainRecord;
  monthly: PortfolioGainRecord;
  quarterly: PortfolioGainRecord;
  yearly: PortfolioGainRecord;
  threeYearly: PortfolioGainRecord;
  sixMonth: PortfolioGainRecord;
  ytd: PortfolioGainRecord;
}
export type PortfolioGainSetRecord = BetterRecord<
  PortfolioGainSet_NotImmutable
  >;
export interface PortfolioGain_NotImmutable {
  percent: number; // | null, //  TODO this can actually be null :O
  val: number; // | null, //  TODO this can actually be null :O
}
export type PortfolioGainRecord = BetterRecord<PortfolioGain_NotImmutable>;
export type PortfolioWarning = BetterRecord<{
  ticker: string;
  warningType: number;
  warningSubType: number;
  date: Date;
  data: Map<string, string>;
}>;
export type Screener = any;
export type Toasts = any;
export type Tooltips = any;
export type Users = any;
export type Messages = Message | { [key: string]: Messages };
export interface MgmtFeeDashboard_NotImmutable {
  expenseRatio: number;
  expense: number;
  fundName: string;
  fundSymbol: string;
  marketCap: number;
  sector: string;
  similarExpenseRatio: number;
  similarMarketCap: number;
  similarSector: string;
  similarSymbol: string;
  similarity: number;
  simlarFundName: string;
}
export type MgmtFeeDashboardRecord = BetterRecord<
  MgmtFeeDashboard_NotImmutable
  >;
export type MgmtFeesDashboard = List<MgmtFeeDashboardRecord>;
// TODO remove both
export type Event = BetterRecord<{
  date: Date;
  // TODO this has more
}>;
export interface MgmtFeeRecordsJS_NotImmutable {
  expenseRatio: number;
  expense: number;
  fundSymbol: string;
  type: string;
  fundName: string;
  simlarFundName: string;
  percentPortfolio: number;
  similarExpenseRatio: number;
  similarSymbol: string;
  similarity: number;
  saveAmount: number;
  savePercent: number;
  sector: string;
  similarSector: string;
  mktCap: number;
  similarMktCap: number;
}
export type MgmtFeeRecordJSRecord = BetterRecord<MgmtFeeRecordsJS_NotImmutable>;
// export type MgmtFeesRecordJS = List<MgmtFeeRecordJSRecord>;

// API Mappings
export enum ManagementFeesEnum {
  VeryLow = 1,
  Low = 2,
  Medium = 3,
  High = 4,
  VeryHigh = 5,
}

export enum EtfAssetTypeMapping {
  'Unknown' = 0,
  'Alternative' = 1,
  'Commodity' = 2,
  'Debt' = 3,
  'Equity' = 4,
}

export enum EtfCategoryMapping {
  'Unknown' = 0,
  'Allocation Strategy' = 1,
  'Commodity Funds' = 2,
  'Corporate Bonds' = 3,
  'Currency' = 4,
  'Diversified Bonds' = 5,
  'Government Bonds' = 6,
  'International' = 7,
  'Inverse' = 8,
  'Leveraged' = 9,
  'Long And Short Positions' = 10,
  'Money Markets' = 11,
  'Municipality Bonds' = 12,
  'Non-Traditional Bond' = 13,
  'Other' = 14,
  'Sector Fund' = 15,
  'Size and Style' = 16,
  'Target Date Bonds' = 17,
  'Target Return' = 18,
  'Term To Maturity' = 19,

  // Even though it's the same category, these should have differnet values
  // per AssetType.
  'InverseEquity' = 20,
  'InverseDebt' = 21,
  'InverseCommodity' = 22,
  'LeveragedEquity' = 23,
  'LeveragedDebt' = 24,
  'LeveragedCommodity' = 25,
}

export enum InvestmentStrategyMapping {
  'Unknown' = 0,
  'Aggressive Allocation' = 1,
  'Allocation--15% to 30% Equity' = 2,
  'Allocation--30% to 50% Equity' = 3,
  'Allocation--50% to 70% Equity' = 4,
  'Allocation--70% to 85% Equity' = 5,
  'Allocation--85%+ Equity' = 6,
  'Bank Loan' = 7,
  'Bear Market' = 8,
  'China Region' = 9,
  'Commodities Agriculture' = 10,
  'Commodities Broad Basket' = 11,
  'Commodities Energy' = 12,
  'Commodities Industrial Metals' = 13,
  'Commodities Miscellaneous' = 14,
  'Commodities Precious Metals' = 15,
  'Communications' = 16,
  'Conservative Allocation' = 17,
  'Consumer Cyclical' = 18,
  'Consumer Defensive' = 19,
  'Convertibles' = 20,
  'Corporate Bond' = 21,
  'Diversified Emerging Mkts' = 22,
  'Diversified Pacific/Asia' = 23,
  'Emerging Markets Bond' = 24,
  'Emerging-Markets Local-Currency Bond' = 25,
  'Energy Limited Partnership' = 26,
  'Equity Energy' = 27,
  'Equity Precious Metals' = 28,
  'Europe Stock' = 29,
  'Financial' = 33,
  'Foreign Large Blend' = 34,
  'Foreign Large Growth' = 35,
  'Foreign Large Value' = 36,
  'Foreign Small/Mid Blend' = 37,
  'Foreign Small/Mid Growth' = 38,
  'Foreign Small/Mid Value' = 39,
  'Global Real Estate' = 40,
  'Health' = 41,
  'High Yield Bond' = 42,
  'High Yield Muni' = 43,
  'India Equity ' = 44,
  'Industrials' = 45,
  'Inflation-Protected Bond' = 46,
  'Infrastructure' = 47,
  'Intermediate Government' = 48,
  'Intermediate-Term Bond' = 49,
  'Japan Stock' = 50,
  'Large Blend' = 51,
  'Large Growth' = 52,
  'Large Value' = 53,
  'Latin America Stock' = 54,
  'Long Government' = 55,
  'Long-Short Credit' = 56,
  'Long-Short Equity' = 57,
  'Long-Term Bond' = 58,
  'Long/Short Equity' = 59,
  'Managed Futures' = 60,
  'Market Neutral' = 61,
  'Mid-Cap Blend' = 62,
  'Mid-Cap Growth' = 63,
  'Mid-Cap Value' = 64,
  'Miscellaneous Region' = 65,
  'Miscellaneous Sector' = 66,
  'Moderate Allocation' = 67,
  'Money Market-Tax-Free' = 68,
  'Money Market-Taxable' = 69,
  'Multialternative' = 70,
  'Multicurrency' = 71,
  'Multisector Bond' = 72,
  'Muni California Intermediate' = 73,
  'Muni California Long' = 74,
  'Muni Massachusetts' = 75,
  'Muni Minnesota' = 76,
  'Muni National Interm' = 77,
  'Muni National Long' = 78,
  'Muni National Short' = 79,
  'Muni New Jersey' = 80,
  'Muni New York Intermediate' = 81,
  'Muni New York Long' = 82,
  'Muni Ohio' = 83,
  'Muni Pennsylvania' = 84,
  'Muni Single State Interm' = 85,
  'Muni Single State Long' = 86,
  'Muni Single State Short' = 87,
  'Natural Resources' = 88,
  'Nontraditional Bond' = 89,
  'Option Writing' = 90,
  'Pacific/Asia ex-Japan Stk' = 91,
  'Preferred Stock' = 92,
  'Prime Money Market' = 93,
  'Real Estate' = 94,
  'Retirement Income' = 95,
  'Short Government' = 96,
  'Short-Term Bond' = 97,
  'Single Currency' = 98,
  'Small Blend' = 99,
  'Small Growth' = 100,
  'Small Value' = 101,
  'Tactical Allocation' = 102,
  'Target Date 2000-2010' = 103,
  'Target Date 2011-2015' = 104,
  'Target Date 2016-2020' = 105,
  'Target Date 2021-2025' = 106,
  'Target Date 2026-2030' = 107,
  'Target Date 2031-2035' = 108,
  'Target Date 2036-2040' = 109,
  'Target Date 2041-2045' = 110,
  'Target Date 2046-2050 ' = 111,
  'Target Date 2051+' = 112,
  'Target-Date 2000-2010' = 113,
  'Target-Date 2015' = 114,
  'Target-Date 2020' = 115,
  'Target-Date 2025' = 116,
  'Target-Date 2030' = 117,
  'Target-Date 2035' = 118,
  'Target-Date 2040' = 119,
  'Target-Date 2045' = 120,
  'Target-Date 2050' = 121,
  'Target-Date 2055' = 122,
  'Target-Date 2060+' = 123,
  'Target-Date Retirement' = 124,
  'Technology' = 125,
  'Trading--Inverse Commodities' = 126,
  'Trading--Inverse Debt' = 127,
  'Trading--Inverse Equity' = 128,
  'Trading--Leveraged Commodities' = 129,
  'Trading--Leveraged Debt' = 130,
  'Trading--Leveraged Equity' = 131,
  'Trading--Miscellaneous' = 132,
  'Trading-Inverse Debt' = 133,
  'Trading-Leveraged Debt' = 134,
  'Trading-Leveraged Equity' = 135,
  'Ultrashort Bond' = 136,
  'Utilities' = 137,
  'Volatility' = 138,
  'World Allocation' = 139,
  'World Bond' = 140,
  'World Stock' = 141,
}

export enum Issuer {
  'Unknown' = 0,
  'iShares' = 1,
  'SPDR State Street Global Advisors' = 2,
  'ProShares' = 3,
  'PowerShares' = 4,
  'First Trust' = 5,
  'WisdomTree' = 6,
  'Guggenheim Investments' = 7,
  'Direxion Funds' = 8,
  'Vanguard' = 9,
  'Van Eck' = 10,
  'Global X Funds' = 11,
  'Deutsche Asset Management' = 12,
  'AdvisorShares' = 13,
  'Schwab ETFs' = 14,
  'ALPS' = 15,
  'Flexshares Trust' = 16,
  'PIMCO' = 17,
  'IndexIQ' = 18,
  'Fidelity Investments' = 19,
  'PowerShares DB' = 20,
  'United States Commodity Funds LLC' = 21,
  'Victory' = 22,
  'WBI Investments' = 23,
  'Columbia' = 24,
  'ETF Securities' = 25,
  'OppenheimerFunds' = 26,
  'Market Vectors' = 27,
  "O'Shares Investments" = 28,
  'QuantShares' = 29,
  'Teucrium' = 30,
  'CAMBRIA ETF TRUST' = 31,
  'KraneShares' = 32,
  'Lattice Strategies LLC' = 33,
  'Highland Funds' = 34,
  'Ark ETF Trust' = 35,
  'Pure Funds' = 36,
}

export enum TradeItSyncStatus {
  Ok = 1,
  OnRequested = 2,
  Expired = 3,
  NonRelevantToUI = 4,
  NonRelevantToUI2 = 5,
}
export enum PlaidSyncStatus {
  Active = 1,
  ReAuthenticationNeeded = 2,
  Failure = 3,
}

// TODO convert to enum
export type ScreenerType = 'stockScreener' | 'etfScreener';

export type Plan = 'open' | 'free' | 'premium' | 'ultimate' | 'nasdaqQuarterly'; // TODO make sure nasdaqQuaretrly should be here

export enum PlanEnum {
  Open = 0,
  Free = 1,
  Beta = 2,
  Premium = 3,
  Ultimate = 4,
  DashboardUltimate = 5,
  DashboardPremium = 6,
  GoSectorMonthly = 1337,
  GoSectorYearly = 1338,
  GoSectorTrial = 1339,
  GoSectorQuarterly = 1340,
  GuruPortfolioYearly = 5698,
  OneGuruPortfolioIssue = 5699,
  DailyInsiderMonthly = 7834,
  DailyInsiderYearly = 7835,
  DailyInsiderMonthlyTrial = 7836,
  TheBigShortQuarterly = 9001,
  TheBigShortTrial = 9002,
  TheBigShortMonthly = 9003,
  BioPortfolioTrial = 9201,
  BioPortfolioQuarterly = 9202,
  BioPortfolioMonthly = 9203,
  DividendInvestingTrial = 8001,
  DividendInvestingMonthly = 8002,
  DividendInvestingQuarterly = 8003,
  NasdaqPremiumBiYearly = 5001,
  NasdaqPremiumYearly = 5002,
  NasdaqPremiumMonthly = 5003,
}

export type ReduxAction = { type: string; payload: any };

/** Asset type from server, convert to text with toAssetType */
export enum AssetTypeEnum {
  Equity,
  ETF,
  MutualFund,
  Cash,
  // Note: These don't exist yet
  // Commodity,
  // CryptoCurrency
}

/** Asset type texts */
export enum AssetType {
  Equity = 'Equity',
  Cash = 'Cash',
  ETFs = 'ETFs',
  MutualFunds = 'Mutual Funds',
}

export type StockType = 'stock' | 'etf' | 'fund' | 'secondaryticker';

export type Theme = 'nasdaq' | 'web';
export type Plans = 'open' | 'free' | 'premium' | 'ultimate';

export interface ImmutableConstructor<BetterRecordType, Props> {
  new(props?: Partial<Props>): BetterRecordType;
}

// copied from DataObjectsEnums.cs
export enum StockTypeEnum {
  Stock = 1,
  Index = 2,
  Foreign = 3,
  Inactive = 4,
  Etf = 5,
  Fund = 6,
  Bond = 7,
  SecondaryTicker = 8,
  CanadianStock = 9,
  CanadianETF = 10,
  CanadianFund = 11,
  NonUSStock = 12,
  NonUSETF = 13,
  NonUSFund = 14,
  // Commodity = 15,
  // Cryptocurrency = 16,
}

export interface StockDetails {
  yLow: string;
  ticker: string;
  pe: string;
  marketCap: string;
  openPrice: string;
  eps: string;
  divPerYield: string;
  fiscalDiv: string;
  beta: string;
  shares: string;
  market: string;
  instOwn: string;
  low: string;
  high: string;
  price: string;
  yHigh: string;
  range: string;
  changeAmount: string;
  changePercent: string;
  average: string;
  volume: string;
  volumeAndAvg: string;
  prevClose: string;
  bid: string;
  ask: string;
  oneYearTargetEst: string;
  nextEarningDate: string;
  daysRange: string;
  range52Weeks: string;
  low52Weeks: string;
  high52Weeks: string;
  avgVol3Months: string;
}

export type StockDetailRecord = BetterRecord<StockDetails>;

export type Ticker = string;

export type SettingId =
  | 'high'
  | 'low'
  | 'increase'
  | 'drop'
  | 'announcement'
  | 'report'
  | 'dividend'
  | 'analyst'
  | 'blogger'
  | 'inider'
  | 'hedgefund'
  | 'ranking'
  | 'analystConsensus'
  | 'bestAnalystConsensus'
  | 'bloggerSentiment'
  | 'insiderSentiment'
  | 'hedgefundSentiment';

export type EmailAlertSetting = { id: SettingId; info: any; val: boolean };
export type EmailAlertSettings = List<EmailAlertSetting>;

export type Actions = any;

// TODO from api-types, should return there eventually
export type PortfolioType =
  | 'user'
  | 'combinedUserPortfolio'
  | 'new'
  | string
  | undefined;

export type Omit<T extends object, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Returns a version of type T where all properties which are also in U are optionalized.
 * Useful for makding props with defaults optional in React components.
 * Compare to flow's $Diff<> type: https://flow.org/en/docs/types/utilities/#toc-diff
 */
export type ObjDiff<T extends object, U extends object> = Omit<
  T,
  keyof U & keyof T
  > &
  { [K in (keyof U & keyof T)]?: T[K] };

export type DialogStoreRecord_NotImmutable = {
  activeItems: List<any>,
  props: BetterRecord<any>,
  unsureSent: boolean,
  lastUrlBeforeAnAuthPopup: string,
};
export type DialogStoreRecord = BetterRecord<DialogStoreRecord_NotImmutable>;

export type Routes = { path: string }[];

export type HoldingsTableType = 'basic' | 'advanced';
export type BasicTableType = 'all' | 'best';