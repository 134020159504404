import React from 'react';
import PropTypes from 'prop-types';
import { BarSlider } from './BarSlider.react';
import Spacer from './Spacer.react';
import { imgSrc } from '../../../../../common/lib/utils.ts';
import { dashboardPath } from '../../../../../common/config.ts';
import TipRanksText from './TipRanksText.react';
import { Link } from 'react-router';

export default class AveragePortfolioAd extends React.Component {
  static propTypes = {
    clickedCompare: PropTypes.func,
    theme: PropTypes.string
  };
  render() {
    const { clickedCompare, theme } = this.props;

    return (
      <div className="average-portfolio-add">
        <div className="add-left">
          <img src={imgSrc(`add-icon-${theme}`)} />
        </div>
        <div className="add-center">
          See the average <TipRanksText theme={theme} /> portfolio analysis and
          compare to yours
        </div>
        <div className="add-right">
          <Link
            className="button"
            to={`/${dashboardPath}/insights`}
            onClick={clickedCompare}
          >
            Compare
          </Link>
        </div>
      </div>
    );
  }
}
