import { fromJS, List, Map, Record } from 'immutable';

import * as actions from './actions';
import { suits } from '../../common/enums';
import { defaultSettings as defSettings } from './config.ts';

const defaultSettings = fromJS(defSettings);

export const InitialState = Record({
  isDisabled: false,
  isDisabledExperts: false,
  totalAlertsPredictions: 0,
  componentStates: new Map({
    alertsSettings: suits.LOADING,
    changeSettings: suits.LOADING
  }),
  settings: defaultSettings,
  expectedExpertEmails: 0,
  expectedStockEmails: 0,
  numSubscriptionToExperts: 0,
  weeklyDigestEnabled: false,
});

const setSuit = (component, state) => [['componentStates', component], state];
const errorSuit = name => setSuit(name, suits.ERROR);
const successSuit = name => setSuit(name, suits.SUCCESS);
const loadingSuit = name => setSuit(name, suits.LOADING);

const initialState = new InitialState();
const revive = state => initialState.merge(state);

export default function alertsSettingsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.TOGGLE_IS_DISABLED_START: {
      return state.update('isDisabled', val => !val);
    }

    case actions.TOGGLE_IS_DISABLED_EXPERTS_START: {
      return state.update('isDisabledExperts', val => !val);
    }

    case actions.GET_ALERTS_SETTINGS_START: {
      return state.setIn(...loadingSuit('alertsSettings'));
    }

    case actions.GET_ALERTS_SETTINGS_ERROR: {
      return state.setIn(...errorSuit('alertsSettings'));
    }

    case actions.GET_ALERTS_SETTINGS_SUCCESS: {
      const { payload } = action;
      const settings = defaultSettings.reduce(
        (list, item) =>
          list.push(
            payload.get('list').find(p => p.get('id') === item.get('id')) ||
            item
          ),
        new List()
      );

      return state
        .setIn(...successSuit('alertsSettings'))
        .set('isDisabled', payload.get('isDisabled'))
        .set('isDisabledExperts', payload.get('isDisabledExperts'))
        .set('expectedExpertEmails', payload.get('expectedExpertEmails'))
        .set('expectedStockEmails', payload.get('expectedStockEmails'))
        .set('weeklyDigestEnabled', payload.get('weeklyDigestEnabled'))
        .set(
          'numSubscriptionToExperts',
          payload.get('numSubscriptionToExperts')
        )
        .set('settings', settings);
    }

    case actions.CHANGE_SETTINGS_START: {
      if (!action.payload) return state;
      return state
        .set('settings', action.payload.settings)
        .set('weeklyDigestEnabled', action.payload.weeklyDigestEnabled)
        .setIn(...loadingSuit('changeSettings'));
    }

    case actions.CHANGE_SETTINGS_ERROR: {
      return state.setIn(...errorSuit('changeSettings'));
    }

    case actions.CHANGE_SETTINGS_SUCCESS: {
      return state.setIn(...successSuit('changeSettings'));
    }
  }

  return state;
}
