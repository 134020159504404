import { Map } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics';
import { holdingsSectionConfig } from '../../common/config';
import HoldingsMetrics from '../tr-dashboard-holdings-metrics';

import {
  selectHoldingsColsPendings,
  selectTheme
} from '../dashboard/selectors';

export default class TrDashboardHoldingsMetricsContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    report('saw');
  }

  render() {
    const { actions, dashboard, msg } = this.props;

    const theme = selectTheme(dashboard);

    const toMetricsObject = col =>
      new Map({
        id: col,
        plan: holdingsSectionConfig.get('premium').includes(col)
          ? 'premium'
          : 'free'
      });

    const cols = holdingsSectionConfig
      .get('cols')
      .filter(col => !holdingsSectionConfig.get('alwaysCols').includes(col))
      .map(toMetricsObject);

    return (
      <HoldingsMetrics
        applyChange={actions.applyHoldingsMetricsChanges}
        close={() => actions.closeDialog('holdingsMetrics')}
        cols={cols}
        colsNames={msg.dashboard.holdingsSection.cols}
        enums={msg.enums}
        msg={msg.holdingsMetrics}
        onChange={actions.changeHoldingsMetrics}
        selections={selectHoldingsColsPendings(dashboard)}
        theme={theme}
      />
    );
  }
}
