import * as classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import { Actions, MyExperts } from '../../common/types';
import Clickable from '../Clickable/clickable.react';
import { AvailableIcons, Icon } from 'tipranks-icons';
import { currencyFromTicker, Money } from '../Money/Money';
import { report } from './analytics';
import * as styles from './NotificationBell.istyl';
import {
  AnyNotification,
  ExpertTypeEnum,
  InsiderPosition,
  NotificationBase,
  NotificationsStoreState,
  NotificationTypeEnum,
  OperationActionEnum,
  RatingType,
} from './types';
import { queryString } from 'sp/browser/lib/utils';
import { selectIsPermanentLoggedIn } from 'sp/common/auth/selectors';

type NotificationBellProps = {
  notifications: NotificationsStoreState;
  actions: Actions;
  myExperts: MyExperts;
  // only used in the render, passed by the connect
  // userFollowedExperts: number,
};

@connect(
  ({ notifications, myExperts, dashboard: { userFollowedExperts } }) => ({
    notifications,
    myExperts,
    userFollowedExperts
  }),
  mapDispatchToProps
)
export class NotificationBell extends React.PureComponent<
NotificationBellProps
> {
  private notificationInterval: number;

  public componentDidMount() {
    if (this.notificationInterval) {
      window.clearInterval(this.notificationInterval);
    }
    this.pollNotifications();
    this.notificationInterval = window.setInterval(this.pollNotifications, 60e3);
  }

  public componentWillUnmount() {
    window.clearInterval(this.notificationInterval);
  }

  private get unreadNotifications() {
    return this.props.notifications.notifications.filter(
      notification => !notification.read
    );
  }

  private pollNotifications = () => {
    this.props.actions.getNotifications();
    this.props.actions.userFollowedStocks();
    this.props.actions.userFollowedExperts();
  };

  private clearNotifications = () => {
    this.props.actions.setNotificationsRead(new Date());
  };

  private onBellClick = e => {
    if ('ontouchstart' in window) {
      return;
    }
    e.stopPropagation();
    this.clearNotifications();
  };
  private reportNotificationPageView = e => report.ga('/notifications', false);

  public render() {
    const { notifications: notificationStore, myExperts } = this.props;
    const expertsFollowingSize = (this.props as any).userFollowedExperts;
    const unreadNotifications = this.unreadNotifications.length;
    const stockNotifications = this.unreadNotifications.filter(
      ({ isCausedByStock }) => isCausedByStock
    );
    const expertNotifications = this.unreadNotifications.filter(
      ({ isCausedByExpert }) => isCausedByExpert
    );

    const stocksFollowing = notificationStore.stocks;
    return (
      <div
        className={styles.inbox}
        onMouseEnter={this.reportNotificationPageView}
      >
        <button
          onClick={this.onBellClick}
          className={classNames(styles.bellButton, {
            [styles.showUnreadBadge]: unreadNotifications > 0
          })}
          data-unread-notifications={
            unreadNotifications > 9 ? '9+' : unreadNotifications
          }
        >
          <Icon size={20} icon="bell" />
        </button>

        <div className={styles.notificationArea}>
          <header>
            <h3>Notifications</h3>
            <Clickable onClick={this.clearNotifications}>
              Mark all as read
            </Clickable>
          </header>
          {notificationStore.notifications.length !== 0 && (
            <main>
              <ol>
                {' '}
                {/* Notice me senpai! */}
                {notificationStore.notifications.map(item => {
                  const rendered = renderItem(item);
                  if (!rendered) { return null; }
                  return (
                    <li
                      key={item.id}
                      className={classNames(styles.notificationItem, {
                        [styles.read]: item.read,
                        [styles.unread]: !item.read
                      })}
                    >
                      {rendered}
                    </li>
                  )
                })}
              </ol>
            </main>
          )}
          <footer>
            {selectIsPermanentLoggedIn() &&
              <Clickable href="/account/settings?ref=notifications-window" className={styles.emailAlerts}>
                <strong>Email Alerts</strong>
                <RelatedLink icon="envelope" text="Customize alerts settings" />
              </Clickable>}
            <PromotionalLinks
              isFollowingStocks={stocksFollowing.length > 0}
              isFollowingExperts={expertsFollowingSize > 0}
            />
          </footer>
        </div>
      </div>
    );
  }
}
export interface RelatedLinkProps {
  to?: string;
  href?: string;
  icon?: AvailableIcons;
  refUrl?: string;
  text?: string;
  values?: any;
  target?: '_blank' | '_self';
}

export class RelatedLink extends React.Component<RelatedLinkProps> {
  public render() {
    const { to, refUrl, href, icon, values, text, target = href ? '_blank' : this.props.target } = this.props;

    return (<div key={to || href} className={styles.RelatedLink}>
      <Clickable
        to={to && refUrl ? queryString(to, { ref: refUrl }) : to}
        href={href && refUrl ? queryString(href, { ref: refUrl }) : href}
        target={target}
      >
        {icon && <Icon icon={icon} className={styles.iconWrapper} />}
        <span className={styles.textWrapper}>{text}&nbsp;></span>
      </Clickable>
    </div>);
  }
}


function PromotionalLinks({ isFollowingStocks, isFollowingExperts }: { isFollowingStocks: boolean, isFollowingExperts: boolean }) {
  if (!isFollowingStocks && !isFollowingExperts) {
    return (
      <div className={styles.notFollowing}>
        <p><span>Stay on top of your holdings, open your <Clickable href="/smart-portfolio?ref=notifications-window">Smart Portfolio</Clickable> today!</span></p>
        <div className={styles.divider}><hr /><span>or</span></div>
        <p>
          <span>Start following <Clickable href="/top-experts?ref=notifications-window">Best Performing Experts</Clickable></span>
          <Clickable className={classNames(styles.button)} href="/top-experts?ref=notifications-window">Start ></Clickable>
        </p>
      </div>
    );
  }
  if (isFollowingStocks && !isFollowingExperts) {
    return (
      <Clickable href="/top-experts?ref=notifications-window">
        Currently, you are not following any experts. Start following <span className={styles.linkLike}>Top Experts</span>.
        <span className={classNames(styles.button)}>Start ></span>
      </Clickable>
    );
  }
  if (!isFollowingStocks && isFollowingExperts) {
    return (
      <Clickable to="/smart-portfolio/holdings/holdings?ref=notifications-window">
        <p>Stay on top of your holdings, open your <span className={styles.linkLike}>Smart Portfolio</span> today!</p>
        <span className={styles.button}>Start ></span>
      </Clickable>
    );
  }
  return (
    <Clickable href="/top-experts?ref=notifications-window">
      <p>Follow in the steps of the best performing experts, visit <span className={styles.linkLike}>Expert Center</span>. </p>
      <span className={styles.button}>Visit ></span>
    </Clickable>
  );
}

function renderItem(item: AnyNotification) {
  switch (item.type) {
    case NotificationTypeEnum.AnalystOnStock: {
      const {
        name,
        company,
        stars,
        targetCompany,
        ticker,
        rating
      } = item.notification;
      return (
        <div>
          <IconWithTime icon="manWithTie" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink company={targetCompany} ticker={ticker} />{' '}
            received a <Rating rating={rating} /> rating by{' '}
            <Stars stars={stars} /> analyst{' '}
            <Clickable
              target="_self"
              href={`/analysts/${slugify(name)}?ref=notifications-window`}
            >
              {name}
            </Clickable>{' '}
            from <strong>{company}</strong>{' '}
          </p>
        </div>
      );
    }

    case NotificationTypeEnum.BloggerOnStock: {
      const {
        name,
        company,
        stars,
        ticker,
        rating,
        targetCompany
      } = item.notification;
      return (
        <div>
          <IconWithTime icon="manWithSpeechBubble" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink company={targetCompany} ticker={ticker} />{' '}
            received a <Rating rating={rating} /> recommendation by{' '}
            <Stars stars={stars} /> contributor{' '}
            <Clickable
              target="_self"
              href={`/bloggers/${slugify(name)}?ref=notifications-window`}
            >
              {name}
            </Clickable>{' '}
            from <strong>{company}</strong>{' '}
          </p>
        </div>
      );
    }

    case NotificationTypeEnum.InsiderOnStock: {
      const {
        name,
        rating,
        targetCompany,
        ticker,
        totalValue
      } = item.notification;
      return (
        <div>
          <IconWithTime icon="manWithDollar" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink company={targetCompany} ticker={ticker} />{' '}
            {insiderTitle(item.notification.position)}{' '}
            <Clickable
              target="_self"
              href={`/insiders/${slugify(name)}?ref=notifications-window`}
            >
              {name}
            </Clickable>{' '}
            {rating === RatingType.Buy ? 'bought' : 'sold'} shares{' '}
            {totalValue !== 0 ? (
              <span>
                worth{' '}
                <Money
                  amount={totalValue}
                  currency={currencyFromTicker(ticker)}
                  normalized
                />
              </span>
            ) : (
                ''
              )}
          </p>
        </div>
      );
    }

    case NotificationTypeEnum.Earning: {
      return (
        <div>
          <IconWithTime icon="calendar" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink
              company={item.notification.targetCompany}
              ticker={item.notification.ticker}
            />{' '}
            announced <strong>earnings report</strong> to be released on{' '}
            <strong>
              <time dateTime={moment(item.notification.date).toISOString()}>
                {moment(item.notification.date).format('MMM DD, YYYY')}
              </time>
            </strong>
          </p>
          <Clickable
            className={styles.extraPage}
            target="_self"
            href={`/stocks/${item.notification.ticker.toLowerCase()}/earnings-calendar?ref=notification-window`}
          >
            See Details >
          </Clickable>
        </div>
      );
    }

    case NotificationTypeEnum.Dividend: {
      return (
        <div>
          <IconWithTime icon="calendar" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink
              company={item.notification.targetCompany}
              ticker={item.notification.ticker}
            />{' '}
            announced <strong>Ex-Dividend date</strong> to be{' '}
            <strong>
              <time dateTime={moment(item.notification.date).toISOString()}>
                {moment(item.notification.date).format('MMM DD, YYYY')}
              </time>
            </strong>
          </p>
          <Clickable
            className={styles.extraPage}
            target="_self"
            href={`/stocks/${item.notification.ticker.toLowerCase()}/dividends-calendar?ref=notification-window`}
          >
            See Details >
          </Clickable>
        </div>
      );
    }

    case NotificationTypeEnum.NewFollowingExpert: {
      // return null;
      try {
        const type = expertTypeToString(item.notification.expertType);
        return (
          <div>
            <IconWithTime icon="circleTick" {...item} />
            <p className={styles.notification}>
              You are {item.notification.isFollow ? 'now' : 'no longer'} following{' '}
              <Stars stars={item.notification.stars} /> {type.replace('-', ' ')}{' '}
              <Clickable
                target="_self"
                href={`/${type}s/${slugify(
                  item.notification.name
                )}?ref=notifications-window`}
              >
                {item.notification.name}
              </Clickable>
            </p>
          </div>
        );
      } catch {
        return null;
      }
    }

    case NotificationTypeEnum.NewFollowingStock: {
      // return null;
      return (
        <div>
          <IconWithTime icon="circleTick" {...item} />
          <p className={styles.notification}>
            You are {item.notification.isFollow ? 'now' : 'no longer'} following{' '}
            <StockCompanyLink
              ticker={item.notification.ticker}
              company={item.notification.targetCompany}
            />
          </p>
        </div>
      );
    }

    case NotificationTypeEnum.PriceDropWarning:
    case NotificationTypeEnum.PriceIncrease: {
      const { ticker, targetCompany, triggerValue } = item.notification;
      return (
        <div>
          <IconWithTime icon="warning" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink ticker={ticker} company={targetCompany} /> stock
            price is going {triggerValue > 0 ? 'up' : 'down'} by over 5.00%{' '}
            <span
              className={classNames({
                [styles.green]: triggerValue > 0,
                [styles.red]: triggerValue < 0
              })}
            >
              ({triggerValue.toFixed(3)}%)
            </span>
          </p>
          {triggerValue < 0 && (
            <Clickable
              className={styles.extraPage}
              target="_self"
              href="/smart-portfolio/analysis/overview?ref=notification-window"
            >
              View Warnings >
            </Clickable>
          )}
        </div>
      );
    }

    case NotificationTypeEnum.FiftyTwoWeekHigh:
    case NotificationTypeEnum.FiftyTwoWeekLow: {
      const {
        ticker,
        targetCompany,
        triggerValue,
        newPrice
      } = item.notification;
      return (
        <div>
          <IconWithTime icon="warning" {...item} />
          <p className={styles.notification}>
            <StockCompanyLink ticker={ticker} company={targetCompany} /> just
            went below its 52 week {triggerValue > 0 ? 'high ' : 'low '}
            and is currently trading at{' '}
            <Money
              currency={currencyFromTicker(ticker)}
              amount={newPrice}
              normalized
            />
          </p>
        </div>
      );
    }

    default: {
      console.error(
        `Unfamiliar type ${
        (item as { type: string }).type
        }, this shouldn't happen.`
      );
    }
  }
}

type IconWithTimeProps<T extends NotificationTypeEnum, U> = {
  icon: AvailableIcons;
  notification: {
    ticker?: string;
    name?: string;
  };
} & NotificationBase<T, U>;

function IconWithTime<T extends NotificationTypeEnum, U>({
  icon,
  date,
  isCausedByExpert,
  isCausedByStock,
  notification
}: IconWithTimeProps<T, U>) {
  const ticker = (notification.ticker || '').toUpperCase();
  const expert = notification.name || '';
  const following = isCausedByExpert
    ? isCausedByStock ? `${ticker} and ${expert}` : `${expert}`
    : `${ticker}`;
  return (
    <p
      className={styles.iconTime}
      title={
        isCausedByExpert || isCausedByStock
          ? `You're getting this notification because you're following ${
          following
          }`
          : undefined
      }
    >
      <Icon
        className={classNames({
          [styles.green]: icon === 'circleTick',
          [styles.red]: icon === 'warning',
          [styles.warning]: icon === 'warning'
        })}
        size={16}
        icon={icon}
      />{' '}
      <time dateTime={date.toISOString()}>
        {moment(date).format('MMM DD, YYYY')}
      </time>
    </p>
  );
}

function StockCompanyLink({
  ticker,
  company
}: {
    ticker: string;
    company: string;
  }) {
  return (
    <Clickable
      target="_self"
      href={`/stocks/${ticker.toLowerCase()}?ref=notification-window`}
    >
      {company} ({ticker.toUpperCase()})
    </Clickable>
  );
}

function Rating({
  rating,
  ing = false
}: {
    rating: RatingType;
    ing?: boolean;
  }) {
  const action = ing ? RatingType[rating].toLowerCase() : RatingType[rating];
  return (
    <strong className={styles[action.toLowerCase()]}>
      {action}
      {ing ? 'ing' : ''}
    </strong>
  );
}

function actioned(action: OperationActionEnum) {
  return OperationActionEnum[action].toLowerCase();
}

function insiderTitle(position: InsiderPosition) {
  if (position.isDirector) {
    return 'Director';
  }
  if (position.isOfficer) {
    return 'Officer';
  }
  if (position.isTenPercentOwner) {
    return '>10% Owner';
  }
  return 'Insider';
}

function slugify(expertName: string) {
  return expertName.toLowerCase().replace(/ /g, '-');
}

function expertTypeToString(type: ExpertTypeEnum) {
  switch (type) {
    case ExpertTypeEnum.Analyst:
      return 'analyst';
    case ExpertTypeEnum.Blogger:
      return 'blogger';
    case ExpertTypeEnum.Insider:
      return 'insider';
    case ExpertTypeEnum.Institutional:
      return 'hedge-fund';
    default:
      throw new Error(`Unknown expert type ${ExpertTypeEnum[type]} (${type}).`);
  }
}

function getTrimmedRanking(num: number) {
  return Math.min(5, Math.max(num, 0));
}
export function Stars({ stars }: { stars: number | null }) {
  if (!stars) {
    return <span className={styles.unranked}>unranked</span>;
  }
  // Round to nearest .1
  const formattedStars = Math.round(stars * 10) / 10;
  return <span className={styles.stars}>{formattedStars} star</span>;
}
