import { VariableSizeSpinner } from '../Spinner/index.react';
import { SmarterAnalystBadge } from '../SmarterAnalystBadge/SmarterAnalystBadge';
import {
  NewsletterDescriptionProps,
  PremiumNewsletter,
  conf as newsletterConf
} from './newsletter-conf';
import { Icon } from 'tipranks-icons';
import Clickable from '../Clickable/clickable.react';
import * as React from 'react';
import * as classNames from 'classnames';
import { AlertsSettings, ComponentState, EmailAlertSetting } from '../../common/types';
import * as styles from './style.istyl';
import { Label as EmailAlertLabel } from '../tr-dashboard-alerts-settings/utils';
import { msg } from '../../common/intl/messages/en.js';
import { Spinner } from '../Spinner/index';
import { isIOS } from '../../common/lib/utils';

enum Tab {
  EmailAlerts,
  PremiumNewsletter
}

export enum MailFrequency {
  OnePerDay,
  AllRelevant
}

type State = {
  activeTab: Tab;
};
interface AlertsProps {
  emailAlertSettings: EmailAlertSetting[];
  bypassEmailLimit: boolean;
  emailAlertsActive: boolean;

  onClickManageStockNotifications: () => void;
  onClickManageExpertNotifications: () => void;
  onChangeEmailFrequency: (frequency: MailFrequency) => void;
  toggleEmailAlerts: (switchTo: boolean) => void;

  stocksFollowing: number;
  expertsFollowing: number;

  emailFrequencyServerState: ComponentState;
}
export class AlertsAndNewsletter extends React.PureComponent<
  AlertsProps,
  State
  > {
  state = {
    activeTab: Tab.EmailAlerts
  };

  private changeTab(tab: Tab) {
    this.setState({ activeTab: tab });
  }
  public render() {
    const { activeTab } = this.state;
    const {
      emailAlertSettings,
      onClickManageStockNotifications,
      onClickManageExpertNotifications,
      stocksFollowing,
      expertsFollowing,
      emailFrequencyServerState,
      onChangeEmailFrequency,
      bypassEmailLimit,
      emailAlertsActive,
      toggleEmailAlerts
    } = this.props;
    return (
      <div className={styles.tabbedSection}>
        <menu>
          <button
            className={classNames({
              [styles.activeTab]: activeTab === Tab.EmailAlerts,
              [styles.withShittyIOS]: isIOS()
            })}
            onClick={e => this.changeTab(Tab.EmailAlerts)}
          >
            <Icon icon="bell" className={styles.bellIcon} /> Email Alerts
          </button>
          <button
            className={classNames({
              [styles.activeTab]: activeTab === Tab.PremiumNewsletter,
              [styles.withShittyIOS]: isIOS()
            })}
            onClick={e => this.changeTab(Tab.PremiumNewsletter)}
          >
            <Icon icon="ribbon" className={styles.ribbonIcon} /> Premium
            Newsletter
          </button>
        </menu>
        {activeTab === Tab.EmailAlerts && (
          <Alerts
            emailAlertSettings={emailAlertSettings}
            onClickManageStockNotifications={onClickManageStockNotifications}
            onClickManageExpertNotifications={onClickManageExpertNotifications}
            stocksFollowing={stocksFollowing}
            expertsFollowing={expertsFollowing}
            emailFrequencyServerState={emailFrequencyServerState}
            onChangeEmailFrequency={onChangeEmailFrequency}
            bypassEmailLimit={bypassEmailLimit}
            emailAlertsActive={emailAlertsActive}
            toggleEmailAlerts={toggleEmailAlerts}
          />
        )}
        {activeTab === Tab.PremiumNewsletter && <Newsletter />}
      </div>
    );
  }
}

class Alerts extends React.PureComponent<
  AlertsProps,
  { tempAlertsOnOff: boolean }
  > {
  public state = {
    tempAlertsOnOff: false
  };
  private changeMailFrequency = (newFrequency: MailFrequency) => {
    this.props.onChangeEmailFrequency(newFrequency);
  };

  public render() {
    const {
      emailAlertSettings,
      emailAlertsActive,
      onClickManageStockNotifications,
      onClickManageExpertNotifications,
      onChangeEmailFrequency,
      stocksFollowing,
      expertsFollowing,
      emailFrequencyServerState,
      bypassEmailLimit,
      toggleEmailAlerts
    } = this.props;

    const stockNotifications = emailAlertSettings;
    return (
      <section>
        <h2>
          <Following stocks={stocksFollowing} experts={expertsFollowing} />
        </h2>

        <div>
          <ul className={styles.followMotivator}>
            {expertsFollowing === 0 && (
              <li>
                <div>
                  <h4>Start following experts</h4>
                  <p>
                    Visit the{' '}
                    <Clickable href="/top-experts">Expert Center</Clickable> to
                    find and follow top performing financial experts.
                  </p>
                </div>
              </li>
            )}
            {stocksFollowing === 0 && (
              <li>
                <div>
                  <h4>Start following stocks</h4>
                  <p>
                    Visit the{' '}
                    <Clickable href="/stocks/top-rated">
                      Most Recommended Stocks
                    </Clickable>{' '}
                    to follow stocks recommended by top analysts, or search a
                    company name or ticker to follow
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>

        {emailAlertSettings.length ? (
          <div>
            <h4>
              You are getting notifications for stocks:{' '}
              {!emailAlertsActive && <small>(Disabled)</small>}
            </h4>
            <ul
              className={classNames(styles.coloredBullets, {
                [styles.blue]: emailAlertsActive,
                [styles.gray]: !emailAlertsActive
              })}
            >
              {emailAlertSettings.map(setting => (
                <li key={setting.id}>
                  <EmailAlertLabel
                    msg={msg.alertsSettings.options[setting.id]}
                    value={setting.info}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
            <h4>You are not getting notifications for stocks.</h4>
          )}

        <div className={styles.manageLinks}>
          <span className={styles.disableAll}>
            <Icon icon={emailAlertsActive ? 'bell' : 'crossedBell'} size={16} />{' '}
            Email alerts are {emailAlertsActive ? 'enabled' : 'disabled'}{' '}
            <Clickable onClick={() => toggleEmailAlerts(!emailAlertsActive)}>
              {emailAlertsActive ? 'Disable' : 'Enable'}
            </Clickable>
          </span>
          {expertsFollowing !== 0 && (
            <Clickable
              className={styles.manageExperts}
              to="/smart-portfolio/experts"
            >
              <Icon icon="gear" size={16} className={styles.icon} /> Manage
              experts you follow
            </Clickable>
          )}{' '}
          {stocksFollowing !== 0 && (
            <Clickable onClick={onClickManageStockNotifications}>
              <Icon icon="gear" size={16} /> Manage email notifications
            </Clickable>
          )}
        </div>
        <div className={styles.mailFrequency}>
          <h4>
            Email Frequency:{' '}
            <VariableSizeSpinner
              className={classNames({
                [styles.spinnerShown]: emailFrequencyServerState === 'LOADING'
              })}
              size={15}
            />
          </h4>
          <form>
            <label>
              <input
                name="mailFrequency"
                onChange={e =>
                  this.changeMailFrequency(MailFrequency.OnePerDay)
                }
                type="radio"
                defaultChecked={!bypassEmailLimit}
              />{' '}
              <span className="label">One mail per day</span>
            </label>
            <label>
              <input
                name="mailFrequency"
                onChange={e =>
                  this.changeMailFrequency(MailFrequency.AllRelevant)
                }
                type="radio"
                defaultChecked={bypassEmailLimit}
              />{' '}
              <span className="label">All relevant mails</span>
            </label>
          </form>
          {!bypassEmailLimit && (
            <p>
              To avoid sending you too many emails, the service is, by default,
              limited to one email per day.
            </p>
          )}
        </div>
      </section>
    );
  }
}

type NewsletterState = {
  selectedNewsletter: PremiumNewsletter;
};

class Newsletter extends React.PureComponent<{}, NewsletterState> {
  public constructor(props: {}) {
    super(props);
    this.state = {
      selectedNewsletter: PremiumNewsletter.BioPortfolio
    };
  }

  public render() {
    return (
      <section className={styles.newsletter}>
        <header>
          <h1>Premium Newsletter</h1>
          <select
            onChange={e =>
              this.setState({ selectedNewsletter: Number(e.target.value) })
            }
            value={this.state.selectedNewsletter}
          >
            {Object.keys(newsletterConf).map(key => (
              <option key={key} value={key}>
                {camelSpace(PremiumNewsletter[key])}
              </option>
            ))}
          </select>
        </header>
        <NewsletterDescription
          {...newsletterConf[this.state.selectedNewsletter]}
        />
      </section>
    );
  }
}

function NewsletterDescription({
  color,
  iconSrc,
  headerColoredPart,
  headerNormalPart,
  subheader,
  points,
  annualReturn,
  learnMoreHref,
  sideImageSrc
}: NewsletterDescriptionProps) {
  return (
    <article className={styles.newsletterDescription}>
      <img src={sideImageSrc} alt={headerColoredPart + headerNormalPart} />
      <h3>
        <img src={iconSrc} alt={headerColoredPart + headerNormalPart} />
        <b style={{ color }}>{headerColoredPart}</b>
        {headerNormalPart}
      </h3>
      <h4>{subheader}</h4>
      <ul>
        {points.map(point => (
          <li key={point} style={{ color }}>
            <span>{point}</span>
          </li>
        ))}
      </ul>
      <p>
        Annual Return{' '}
        <strong style={{ color }}>{annualReturn.toFixed(1)}%</strong>
      </p>
      <Clickable href={learnMoreHref}>Learn More ></Clickable>
      <div className={styles.smarterAnalyst}>
        <SmarterAnalystBadge />
      </div>
    </article>
  );
}

function Following({ stocks, experts }: { stocks: number; experts: number }) {
  const maybePluralExpert = experts === 1 ? 'expert' : 'experts';
  const maybePluralStock = stocks === 1 ? 'stock' : 'stocks';
  if (stocks && experts) {
    return (
      <span>
        You are following <strong>{experts}</strong> {maybePluralExpert} and{' '}
        <strong>{stocks}</strong> {maybePluralStock}
      </span>
    );
  }
  if (stocks && !experts) {
    return (
      <span>
        You are following <strong>{stocks}</strong> {maybePluralStock}
      </span>
    );
  }
  if (!stocks && experts) {
    return (
      <span>
        You are following <strong>{experts}</strong> {maybePluralExpert}
      </span>
    );
  }
  return <span>You are not following any experts or stocks</span>;
}

function camelSpace(str: string) {
  return str.replace(/[A-Z]/g, match => ` ${match}`).trim();
}
