import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import Portfolios from '../tr-dashboard-portfolios';
import report from './analytics';

import { companyConstants } from '../../common/config';

import {
  selectEditablePortfolio,
  selectEditablePortfolioValue,
  selectPortfolios,
  selectProccessStates,
  selectIsLimited
} from '../dashboard/selectors';

import {
  selectViewerNextPlan,
  selectViewerPlan
} from '../../common/auth/selectors.ts';
import { promote } from '../TargetedWebPage/actions';
import { setLastLockedFeature } from '../../common/lib/utils.ts';
import { history } from 'sp/browser/history';

export default class TrDashboardPortfoliosContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    report('saw');
  }

  addPortfolio = (...args) => {
    this.props.actions.addPortfolio(...args);
  };

  render() {
    const { actions, auth, dashboard, msg } = this.props;

    const outSpecialPortfolios = portfolio =>
      portfolio.get('id') !== companyConstants.get('combinedUserPortfolio') &&
      portfolio.get('id') !== companyConstants.get('bestPortfolioId');

    return (
      <Portfolios
        fetchPortfolios={actions.fetchPortfolios}
        changePortfolio={actions.changePortfolio}
        addPortfolio={this.addPortfolio}
        applyPortfolioName={actions.applyPortfolioName}
        changeEditablePortfolioId={actions.changeEditablePortfolio}
        changeEditablePortfolioValue={actions.changeEditablePortfolioValue}
        changePortfolio={actions.changePortfolio}
        close={() => actions.closeDialog('portfolios')}
        deletePortfolio={portfolioId =>
          actions.openDialog('deletePortfolio', { portfolioId })
        }
        editablePortfolioId={selectEditablePortfolio(dashboard)}
        editableValue={selectEditablePortfolioValue(dashboard)}
        isLimited={selectIsLimited(dashboard, selectViewerPlan(auth))}
        isUltimate={selectViewerPlan(auth) === 'ultimate'}
        msg={msg.portfolios}
        openSyncDialog={() => {
          actions.closeDialog();
          actions.openDialog('syncDialog');
          report('clickedImportPortfolio', 'edit-portfolio');
        }}
        portfolios={selectPortfolios(dashboard).filter(outSpecialPortfolios)}
        showPromotion={() => {
          if (selectViewerNextPlan(auth)) {
            actions.closeDialog();
            setLastLockedFeature('add-portfolio-limit');
            promote({ plan: 'ultimate', type: 'portfolios' });
          }
        }}
        state={selectProccessStates(dashboard)}
        navigationOnDone={() => {
          if (location.pathname.includes('smart-portfolio/holdings/holdings')) { return; }
          history.push('/smart-portfolio/holdings/holdings');
        }}
      />
    );
  }
}
