import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

export default class PortfolioSelectOption extends Component {
  static propTypes = {
    portfolioId: PropTypes.number,
    portfolioName: PropTypes.string
  };

  render() {
    const { portfolioId, portfolioName } = this.props;

    return <option value={portfolioId}>{portfolioName}</option>;
  }
}
