import name from './name.react';
import price from './price.react';
import value from './value.react';
import target from './target.react';
import alerts from './alerts.react';
import shares from './shares.react';
import highLow from './high-low.react.js';
import DefaultDetail from './default.react';
import purchasePrice from './purchase-price.react';
import analystConsensus from './analyst-consensus.react';
import sincePurchaseGain from './since-purchase-gain.react';

export default detail =>
  ({
    alerts,
    DefaultDetail,
    analystConsensus,
    bestAnalystConsensus: analystConsensus,
    bestTarget: target,
    highLow,
    name,
    price,
    sincePurchaseGain,
    target,
    value,
    shares,
    purchasePrice
  }[detail] || DefaultDetail);
