import Component from 'react-pure-render/component';
import { Helmet } from 'react-helmet';
import * as React from 'react';
import { Link } from 'react-router';

export default class NotFound extends Component {
  componentDidMount() {
    window.location.href = '/404';
  }

  render() {
    const { msg: { notFound: msg } } = this.props;

    return (
      <div className="notfound-page">
        <Helmet title={msg.title} />
        <h1>{msg.header}</h1>
        <p>{msg.message}</p>
        <a href="/">{msg.continueMessage}</a>
      </div>
    );
  }
}
