import * as React from 'react';

import '../styles/holdingstats.styl';
import * as format from './utils';

import { Swipe } from './swipe.react';
import { TickerLink } from '../../TickerLink.react';
import { getSectorText } from 'sp/common/lib/utils';
import { stockLimitBeforeJoinToOther } from 'sp/common/config';
import { StockType } from 'sp/common/types';
import { AssetTypeText } from 'sp/browser/dashboard/routes/analysis/portfolio/selectors';

const tableLeftHeader = {
  stock: 'Company Name',
  etf: 'ETF Name',
  fund: 'Fund Name'
};

export type HoldingStatsItem = {
  name
}
export type HoldingStatsCompany = {
  name: string,
  count: number,
  ticker: string,
  type: StockType
};

export const HoldingStats = ({
  activeAsset,
  asset,
  picked,
  pickedColor,
  pickedIndex,
  onChange,
  isSwipe
}: {
    activeAsset: AssetTypeText,
    asset,
    picked,
    pickedColor,
    pickedIndex,
    onChange,
    isSwipe
  }) => {
  const isStock = activeAsset === 'stock';

  const slides = asset.map((item, index) => {
    const sectorOrCategoryName = isStock
      ? getSectorText(item.get('id'))
      : item.get('id'); // fund name's are already display-able.

    return <div key={index} className={`holding-stats-container ${item === picked ? 'active' : ''}`}>
      <div
        className={`holding-stats ${item === picked ? 'picked' : ''}`}
      >
        <h4>
          <span>Major holdings in</span> <span
            className="sector-name"
            style={{ color: pickedColor }}
          >
            {sectorOrCategoryName}
          </span>
        </h4>
        <table>
          <thead>
            <tr><th>{tableLeftHeader[activeAsset]}</th><th>% of {isStock ? 'Sector' : 'Category'}</th></tr>
          </thead>
          <tbody>
            {item.get('companies').take(stockLimitBeforeJoinToOther).map((c, i) => {
              const holding = c.toObject(); // TODO figure out how to get rid of this toJS
              const { name, ticker, count } = holding;
              return <tr key={ticker}>
                <td>
                  {Number(i) + 1}. <b title={name}>{name}</b> (<TickerLink holding={holding} />)
                </td>
                <td>
                  {format.percentRounded(count)}
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  }).toArray()

  return (
    isSwipe && asset.size > 1 ? 
    <Swipe
      onChange={onChange}
      slideToIndex={pickedIndex}
    >
      {slides}
    </Swipe> :
    <div className="holding-stats-group" onChange={onChange}>{slides}</div>
  );
};

export default HoldingStats;
