import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Component from 'react-pure-render/component';

import { ApiDecorator } from '../ApiDecorator';
import PortfolioNews from '../tr-dashboard-portfolio-news';
import { getState, maybefetch } from '../../common/api-actions';
 import { isAllowed } from '../../common/auth/selectors';
import { setLastLockedFeature, popupRegister } from '../../common/lib/utils.ts';
import {
  selectViewerPlan,
  hasPayed,
  selectIsPermanentLoggedIn,
} from '../../common/auth/selectors.ts';

import report from './analytics';
import { promote } from '../TargetedWebPage/actions';
import { shouldShowSitePic, freeTabs } from './conf.ts';
import history from '../../browser/history.ts';

import {
  selectActiveTickersLength,
  selectPortfolioItems,
  selectPortfolioNews,
  selectPortfolioNewsTicker,
} from '../dashboard/selectors';

import './index.styl';
import { selectIsOnboarding } from 'sp/common/auth/selectors';

@ApiDecorator('newsByTickers')
export class TrDashboardPortfolioNewsContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    isRoute: PropTypes.bool,
    max: PropTypes.number,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object,
    tabId: PropTypes.string, // 'all' | 'analysis' | 'bullish' | 'bearish'
    isInsidePortfolioNews: PropTypes.bool,
  };

  static defaultProps = {
    isRoute: false,
    isInsidePortfolioNews: false,
  };

  componentDidMount() {
    const { isRoute } = this.props;
    if (isRoute) report('visited');
  }

  onTickerChange = ticker => {
    const { actions } = this.props;
    actions.changePortfolioNewsTicker(ticker);
  };

  isNavigationAllowedToTab = tab =>
    process.env.THEME === 'nasdaq'
      ? freeTabs.indexOf(tab) !== -1 || hasPayed(this.props.auth)
      : freeTabs.indexOf(tab) !== -1 || !selectIsOnboarding();

  // only used in the portfolio news inside my holdings
  navigateToTab = tab => {
    const selectedTabUrl = {
      all: '/smart-portfolio/holdings/news/all',
      analysis: '/smart-portfolio/holdings/news/analysis',
      bullish: '/smart-portfolio/holdings/news/bullish',
      bearish: '/smart-portfolio/holdings/news/bearish',
    }[tab];
    history.push(selectedTabUrl);
  };

  // used by both portfolio news'
  navigateToLockedPage = tab => {
    if (!selectIsPermanentLoggedIn()) {
      popupRegister();
    } else {
      const { isInsidePortfolioNews } = this.props;
      // TODO extract triggers from
      const selectedTabFeature = isInsidePortfolioNews
        ? {
          all: 'sp-holdings-tab-all',
          analysis: 'sp-holdings-tab-analysis',
          bullish: 'sp-holdings-tab-bullish',
          bearish: 'sp-holdings-tab-bearish',
        }[tab]
        : {
          all: 'sp-overview-tab-all',
          bullish: 'sp-overview-tab-bullish',
          bearish: 'sp-overview-tab-bearish',
        }[tab];
      setLastLockedFeature(selectedTabFeature);
      promote({ plan: 'premium', type: 'portfolioNews' });
    }
  };

  setTab = tab => {
    const { actions, auth, dashboard, isInsidePortfolioNews } = this.props;
    if (this.isNavigationAllowedToTab(tab)) {
      report('clickedNewsType', tab);
      actions.setOverviewPortfolioNewsTab(tab);
      if (isInsidePortfolioNews) {
        this.navigateToTab(tab);
      }
    } else {
      this.navigateToLockedPage(tab);
    }
  };

  // TODO this is not implemented anywhere!
  clickedNewsItemLink = type => {
    report('clickedNewsItemLink', { type });
  };

  fetchData = () => {
    maybefetch('newsByTickers', this.props, { force: true });
  };

  openAddStockDialog = () => {
    const { actions } = this.props;
    actions.openDialog('addStock');
  };

  render() {
    const {
      dashboard,
      isRoute,
      max,
      mediaQuery,
      msg,
      tabId,
      isInsidePortfolioNews,
    } = this.props;

    const noData = selectActiveTickersLength(dashboard) === 0;
    const stocks = selectPortfolioItems(dashboard).map(portfolioItem =>
      portfolioItem.get('stock').set('name', portfolioItem.get('name')),
    );

    const viewPort = (mediaQuery => {
      if (mediaQuery.get('tablet')) return 'tablet';
      return 'mobileS';
    })(mediaQuery);

    const isLoading =
      getState('newsByTickers', this.props) === 'LOADING' ||
        getState('portfolioItems', this.props) === 'LOADING'
        ? 'LOADING'
        : '';

    return (
      <div className="tr-dashboard-portfolionews-container">
        <PortfolioNews
          clickedNewsItemLink={this.clickedNewsItemLink}
          dependencies={isLoading}
          fetchData={this.fetchData}
          isSentimentMenuOutside={!isRoute}
          max={max}
          msg={msg.dashboard.portfolioNews}
          news={selectPortfolioNews(dashboard)}
          noData={noData}
          noDataMsg={msg.dashboard.holdingsNodata}
          onTickerChange={this.onTickerChange}
          openAddStockDialog={this.openAddStockDialog}
          tabId={tabId}
          setTab={this.setTab}
          shouldShowSitePic={shouldShowSitePic}
          stocks={stocks}
          ticker={selectPortfolioNewsTicker(dashboard)}
          viewPort={viewPort}
          isInsidePortfolioNews={isInsidePortfolioNews}
          mediaQuery={mediaQuery}
        />
        <div className="disclaimer">
          <FormattedHTMLMessage
            defaultMessage={msg.dashboard.portfolioNews.disclaimer}
            id={msg.dashboard.portfolioNews.disclaimer}
          />
        </div>
      </div>
    );
  }
}
