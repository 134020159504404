import request from '../lib/http';

export const SET_SORT = 'SET_SORT';
import {
  fetchStart,
  fetchSuccess,
  fetchError,
  appendSignature,
} from '../../common/dashboard/actions';

import { get } from 'lodash';

// import {
//   subscribeToExpert as apiSubscribeToExpert,
//   unSubscribeToExpert as apiUnSubscribeToExpert,
//   toggleExpertFollow as apiToggleExpertFollow,
//   getExperts,
//   setExpertLastRead as apiSetExpertLastRead} from './api';

export const SET_CONTACT_INPUT = 'SET_CONTACT_INPUT';
export const setInput = payload => ({
  type: SET_CONTACT_INPUT,
  payload,
});

export const ON_CONTACT_ERROR = 'ON_CONTACT_ERROR';
export const onError = () => ({
  type: ON_CONTACT_ERROR,
});

export const CONTACT_FORM_SUBMITTED = 'CONTACT_CONTACT_FORM_SUBMITTED';
export const formSubmitted = () => ({
  type: CONTACT_FORM_SUBMITTED,
});

export const CONTACT_FORM_VALIDATION_FAILED =
  'CONTACT_CONTACT_FORM_VALIDATION_FAILED';
export const formValidationFailed = () => ({
  type: CONTACT_FORM_VALIDATION_FAILED,
});

const validationRegex = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phone: /^[\d-+\s]{3,}$/,
  minimum2Characters: /^.{2,}$/,
};

const validators = {
  name(str) {
    return str && str.length > 1;
  },
  email(str) {
    return str && validationRegex.email.test(str);
  },
  phone(str) {
    return !str || validationRegex.phone.test(str);
  },
  organization(str) {
    return !str || validationRegex.minimum2Characters.test(str);
  },
  position(str) {
    return !str || validationRegex.minimum2Characters.test(str);
  },
  message(str) {
    return str && str.length > 1;
  }
};

const validateProps = props => {
  for (const k in props) {
    const validate = validators[k];
    if (validate && !validate(props[k])) {
      return { valid: false, message: k };
    }
  }
  return { valid: true };
};

const defaultApiUrl = '/api/general/sendcontactform';

export const POST_CONTACT_FORM = 'POST_CONTACT_FORM';
export const POST_CONTACT_FORM_START = fetchStart(POST_CONTACT_FORM);
export const POST_CONTACT_FORM_SUCCESS = fetchSuccess(POST_CONTACT_FORM);
export const POST_CONTACT_FORM_ERROR = fetchError(POST_CONTACT_FORM);
export const postForm = (props, opts = {}) => ({ dispatch }) => {
  const apiUrl = props.apiUrl || opts.apiUrl || defaultApiUrl;
  const validationResult = validateProps(props);

  if (!validationResult.valid) {
    return {
      type: CONTACT_FORM_VALIDATION_FAILED,
      payload: { error: validationResult.message },
    };
  }

  const dialCode = get(props, 'country.dialCode');
  const phoneNumber = props.phone;

  let phone = '';
  if (dialCode) {
    phone += `${dialCode} `;
  }

  if (phoneNumber) {
    phone += phoneNumber;
  }

  props = {
    typeId: get(props, 'subject.type') || get(props, 'typeId') || 0,
    type: get(props, 'subject.name') || get(props, 'type') || 'User Inquiry',
    name: props.name,
    email: props.email,
    organization: props.organization,
    position: props.position,
    subject: phone,
    title: props.title || '-',
    phone,
    message: props.message,
    reCaptchaToken: props.reCaptchaToken 
  };

  return {
    type: POST_CONTACT_FORM,
    payload: {
      promise: request.post(apiUrl, props).then(() => true),
    },
  };
};
