import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Actions, Messages, Theme } from '../../common/types';
import report from './analytics';

export class DialogItem extends React.PureComponent<
  {
    actions: Actions; //PropTypes.object,
    dialogId: string; //PropTypes.string,
    dialogProps: any; //PropTypes.object,
    dialogSystemTitle: string; //PropTypes.string,
    msg: Messages; //PropTypes.object,
    theme: Theme; //PropTypes.string
  },
  {}
> {
  keyPress = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === 27) {
      this.props.actions.closeDialog();
    }
  };

  componentDidMount() {
    const { actions } = this.props;

    if (typeof document !== 'undefined') {
      document.addEventListener('keyup', this.keyPress);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress);
    }
  }

  plan = () => {
    const { dialogProps } = this.props;
    if (!dialogProps) return null;
    return <span className="plan">{dialogProps.plan}</span>;
  };

  renderLogo = () => {
    const { theme } = this.props;

    const themes = {
      web: 'smart-dashboard.png',
      nasdaq: 'nasdaq-logo.png'
    };

    return <img alt="" src={`/assets/img/${themes[theme]}`} />;
  };

  closeDialog = () => {
    const { actions, dialogId } = this.props;
    actions.closeDialog();
    report('clickedClose', dialogId);
  };

  render() {
    const { actions, children, dialogSystemTitle, dialogId, msg } = this.props;
    const dialogMsg = msg.dialogs[dialogId] || {};
    const title = dialogSystemTitle || dialogMsg.title;
    if (!title) {
      console.error('Error: No title provided in dialog.');
    }
    return (
      <section className="dialog-section">
        <header className="smart-dashboard-dialog-header">
          {this.renderLogo()}
          <menu>
            <button onClick={this.closeDialog}>
              <span>+</span>
            </button>
          </menu>
          <h1>
            <FormattedMessage
              defaultMessage={title}
              id={title}
              values={{
                nextPlan: this.plan() || ''
              }}
            />
          </h1>
        </header>
        <div className="dialog-content">{children}</div>
      </section>
    );
  }
}
