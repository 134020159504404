import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import Calendar from '../Calendar';

export default class PortfolioCalendar extends Component {
  static propTypes = {
    calendarMsg: PropTypes.object,
    date: PropTypes.object,
    events: PropTypes.object,
    monthsTotal: PropTypes.number,
    msg: PropTypes.object,
    nextMonth: PropTypes.func,
    prevMonth: PropTypes.func
  };

  render() {
    const {
      calendarMsg,
      date,
      events,
      monthsTotal,
      nextMonth,
      prevMonth
    } = this.props;

    const diff = Math.round(moment(new Date()).diff(date, 'months', true));

    const fnNext = diff - (monthsTotal - 1) > -3 ? nextMonth : null;

    const fnPrev = diff < 3 ? prevMonth : null;

    return (
      <div className="events-calendar">
        <Calendar
          date={date}
          events={events}
          fnNext={fnNext}
          fnPrev={fnPrev}
          monthsTotal={monthsTotal}
          msg={calendarMsg}
        />
      </div>
    );
  }
}
