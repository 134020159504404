import * as React from 'react';
import { StockDetails } from "sp/common/types";
import { getReq } from "sp/common/api";
import _ = require('lodash');
import { isNumber } from 'lodash';
import { getRealTimeQuotesWithoutAdapting } from 'sp/browser/components/RealTimePrices/api';

export class PriceFetchzChild<Props, State> extends React.PureComponent<Props & PriceRequestor, State> {
  public requestPrices(tickers: string[]) { }
  public getRequestedPrices() { }

  protected price = (ticker: string) =>
    (this.props.requestedPrices.prices[ticker] || { price: 0 }).price;

  protected change = (ticker: string) =>
    (this.props.requestedPrices.prices[ticker] || { change: 0 }).change;

  protected changePercent = (ticker: string) =>
    (this.props.requestedPrices.prices[ticker] || { changePercent: 0 })
      .changePercent;

  protected get isLoadingPrices() {
    return !this.props.requestedPrices.hasLoaded;
  }
};

// TODO using this fucks TS.
// export function standardPriceFetchzWrap<T extends PriceRequestor>(Component: React.ComponentClass<T>, PriceFetchz: typeof PriceFetchzChild) {
//   return class StandardPriceFetchzWrap extends PriceFetchz<T, {}> {
//     render() {
//       return (
//         <Component
//           priceRequest={this.requestPrices}
//           requestedPrices={this.getRequestedPrices()}
//           {...this.props}
//         />
//       );
//     }
//   }
// }

// TODO why is this reducer-based and not promise based??
export const createPriceFetchz = ({ priceRefreshTimer = null, firstPriceRequest = 0 }: { priceRefreshTimer: number | null, firstPriceRequest: number }) =>
  class PriceFetchz<T> extends React.PureComponent<T, PriceFetchState> {
    private priceRefreshTimer: number;
    private firstPriceRequest: number;
    constructor(props: T) {
      super(props);
      this.state = {
        pricesToGet: [],
        container: new StockPriceContainer(false, {}),
      };
    }
    public getPricesAndUpdate = async (tickers: string[]) => {
      if (tickers.length > 0) {
        const response = await getRealTimeQuotesWithoutAdapting(tickers);
        this.setState(state => ({
          ...(state as object),
          container: new StockPriceContainer(
            true,
            response.reduce<{ [ticker: string]: StockPrice }>(
              (prevPrices, detail) => ({
                ...prevPrices,
                [detail.ticker]: {
                  price: detail.price,
                  change: detail.changeAmount,
                  changePercent: detail.changePercent,
                  marketCap: detail.marketCap,
                },
              }),
              {},
            ),
          ),
        }));
      }
    };

    public componentDidMount() {
      // request prices after ${firstPriceRequest} seconds - for the first time.
      this.firstPriceRequest = window.setTimeout(
        this.leFetch, firstPriceRequest,
      );
      if (isNumber(priceRefreshTimer)) this.priceRefreshTimer = window.setInterval(
        this.leFetch, priceRefreshTimer,
      );
    }

    public componentDidUpdate(prevProps, prevState) {
      if (_.difference(this.state.pricesToGet, prevState.pricesToGet).length > 0)
        this.leFetch();
    }

    public componentWillUnmount() {
      clearInterval(this.priceRefreshTimer);
      clearInterval(this.firstPriceRequest);
    }

    public getRequestedPrices = () => {
      return this.state.container;
    };

    public requestPrices = (tickers: string[]) => {
      const alreadyRequested = this.state.pricesToGet.map(x => x.ticker);
      const newTickers = tickers.filter(x => alreadyRequested.indexOf(x) === -1);
      if (newTickers.length > 0) {
        this.setState(state => ({
          ...(state as object),
          pricesToGet: state.pricesToGet.concat(
            newTickers.map(ticker => ({ component: '', ticker })),
          ),
        }));
      }
    };

    public leFetch = () => {
      this.getPricesAndUpdate(
        _.uniq(this.state.pricesToGet.map(x => x.ticker)),
      )
    }
  }

export type StockDetailAPIResponse = StockDetails[];

interface TickerComponent {
  component: string;
  ticker: string;
}
export class StockPriceContainer {
  constructor(
    public hasLoaded: boolean = true,
    public prices: { [ticker: string]: StockPrice },
  ) { }
  /// this asks if the price was retrieved from the server
  retrievedPrice(ticker: string) {
    return this.prices[ticker] !== undefined;
  }
  /// this asks if the price was retrived from the server, and also if the server actually returned a price
  hasPrice(ticker: string) {
    return this.retrievedPrice(ticker) && this.prices[ticker] !== null;
  }

  getPrice(ticker: string) {
    return this.prices[ticker];
  }
}

export interface PriceFetchState {
  pricesToGet: TickerComponent[];
  container: StockPriceContainer;
}

export interface TickerPropedComponent {
  ticker: string;
}
export interface StockPrice {
  price: number;
  change: number;
  marketCap: string;
  changePercent: number;
}

export type PriceRequestFn = (ticker: string[]) => void;

export interface PriceRequestor {
  priceRequest: PriceRequestFn;
  requestedPrices: StockPriceContainer;
}

export const PriceFetchz = createPriceFetchz({
  priceRefreshTimer: 15e3,
  firstPriceRequest: 3e3
})