import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { selectUserInfo } from '../dashboard/selectors';
import { selectPortfolioItems } from '../dashboard/selectors';
import { suits } from 'sp/common/enums';
import {
  PriceDetails,
  Performance,
  StockPickerNotRecord,
  PerfStock,
  PerfStockNotRecord,
} from 'sp/common/components/performance/records';
import { Dashboard, PortfolioItemRecord, StockDetails, StockDetailRecord, UI } from 'sp/common/types';
import { selectWeightedHoldings } from 'sp/browser/dashboard/routes/analysis/portfolio/selectors';
import * as _ from 'lodash';

/** 
 * Just a name for a common type
 */
type PerfSelectorState = { ui: UI; performance: Performance; dashboard: Dashboard };
// type Fn = (data: { ui: UI, performance: Performance, portfolioItems: any[] }) => any[];
const selectStockPickerAccordingToUI = createSelector(
  (state: PerfSelectorState) =>
    state.ui,
  (state: PerfSelectorState) =>
    state.performance,
  (ui: UI, performance: Performance) => {
    const stockPicker = performance.get('stockPicker');
    if (stockPicker.length === 0) { return null }
    return stockPicker.find(
      picker =>
        picker.benchmark === ui.get('benchmark') &&
        picker.period === ui.get('period'),
    ) as StockPickerNotRecord;
  },
);

export type GainSinceAddedHash = { [ticker: string]: number };
export const selectStockPickerIncluded = createSelector(
  (state: PerfSelectorState, gainSinceAdded: GainSinceAddedHash) =>
    selectStockPickerAccordingToUI(state),
  ({ performance }: PerfSelectorState) => performance.get('details'),
  function assembleGainSinceAddedHash({ dashboard }: PerfSelectorState) {
    return selectPortfolioItems(dashboard).reduce((hash, item) => {
      // TODO handle amount might be null
      const amount = item
        .get('stock')
        .get('price')
        .get('amount')!;
      const addedPrice = item.get('addedPrice');
      const gainSinceAdded = (amount - addedPrice) / addedPrice || 0;
      const ticker = item.get('ticker').toLowerCase();

      hash[ticker] = gainSinceAdded;

      return hash;
    }, {} as GainSinceAddedHash)
  },
  function assembleStockPicker(stockPicker: StockPickerNotRecord | null, performanceDetails: List<StockDetailRecord>, gainSinceAddeds: GainSinceAddedHash) {
    if (!stockPicker) return null;
    return {
      ...stockPicker,
      stocks: stockPicker.stocks.map(stock => {
        const ticker = stock.ticker.toLowerCase();
        const details = performanceDetails.find(
          x => x.get('ticker').toLowerCase() === ticker,
        );

        const priceDetails = {
          price: details ? parseFloat(details.get('price')) : null,
          changeAmount: details ? parseFloat(details.get('changeAmount')) : null,
          changePercent: details ? parseFloat(details.get('changePercent')) : null,
        };
        const gainSinceAdded = gainSinceAddeds[ticker] || 0;

        return {
          ...stock,
          get priceDetails(): typeof priceDetails { return details as any }, // TODO TS weird error, it thinks details should be a BetterRecord<StockDetails>
          get gainSinceAdded() { return gainSinceAdded }
        }
      })
    }
  },
);

// TODO looks wasteful, find out if it really needs ALL the tickers
export const selectTickers = createSelector(
  (performance: Performance) => performance.get('stockPicker'),
  stockPicker => {
    return _.chain(stockPicker)
      .map(sp => sp.stocks.map(s => s.ticker))
      .flatten()
      .uniq()
      .value();
  },
);


export const selectHasPhoto = createSelector(
  ({ dashboard }) => selectUserInfo(dashboard).get('pictureUrl'),
  ({ ui }) => ui.getIn(['userProfile', 'picture']),
  (originalImg, uploadedImg) => !!originalImg || !!uploadedImg,
);

export const selectMyPerfLoadingState = performance => performance.get('dataState');

export const selectStockData = createSelector(
  ({ dashboard }) => selectPortfolioItems(dashboard),
  ({ dashboard }) =>
    selectPortfolioItems(dashboard).map(x => {
      return { ticker: x.get('ticker'), gain: x.get('sincePurchaseGain') };
    }),
  ({ performance }) => performance.get('holdingOperations'),
  ({ dashboard }) => selectWeightedHoldings(dashboard).holdings(),
  selectStockPickerIncluded,
  (items, stockGains, stockOperations, stockWeighted, picker) => {
    if (!picker) return [];
    return items.map(item => {
      const ticker = item.get('ticker');
      const tickerSearch = ticker.toLowerCase();
      const firstOperation = stockOperations.find(
        x => x.ticker.toLowerCase() === tickerSearch,
      );
      const gain = (stockGains.find(
        x => x.ticker.toLowerCase() === tickerSearch,
      ) || {}
      ).gain;
      const operations = firstOperation!.operations || [];

      return {
        ticker,
        picker: picker
          .stocks
          .find(s => s.ticker.toLowerCase() === tickerSearch),
        gain,
        operations,
        weighted: stockWeighted.filter(
          x => x.ticker.toLowerCase() === tickerSearch,
        )[0],
      };
    });
  },
);

export const selectHasDetails = ({ performance }) =>
  performance.getIn(['fields', 'tolerance']).value !== null;

export const selectHasHoldings = ({ performance }) =>
  performance.get('everHadHolding');

const selectStage = (hasPhoto, hasDetails, hasHoldings) => {
  const hasSomething = true;
  const stages = [hasSomething, hasDetails, hasHoldings, hasPhoto];
  const indexOfInProgressState = stages.indexOf(false);
  if (indexOfInProgressState === -1) return stages.length; // stage 4
  return indexOfInProgressState;
};

export const selectProgressState = createSelector(
  selectHasPhoto,
  selectHasDetails,
  selectHasHoldings,
  selectStage,
);

export const selectIsLoadingFields = (performance: Performance) => {
  const fields = [
    performance.get('fields').get('age').get('state') === suits.LOADING,
    performance.get('fields').get('income').get('state') === suits.LOADING,
    performance.get('fields').get('profession').get('state') === suits.LOADING,
    performance.get('fields').get('term').get('state') === suits.LOADING,
    performance.get('fields').get('tolerance').get('state') === suits.LOADING,
  ];

  return fields.every(Boolean);
};
