import React from 'react';
import Component from 'react-pure-render/component';
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';

import './style.styl';
import { selectToasts } from './selectors';

export default (toasts, stateName) => Child =>
  class Toasts extends Component {
    render() {
      const state = this.props[stateName];

      const toastsItems = selectToasts(state);

      return (
        <div className="toasts-wrapper-component">
          <div className="toasts-rest-wrapper">
            <Child {...this.props} />
          </div>
          <ul className="toasts">
            {toastsItems.map(toast => {
              const Toast = toasts[toast];
              return (
                <ReactCSSTransitionGroup
                  component="li"
                  transitionAppear
                  transitionAppearTimeout={1000}
                  transitionEnterTimeout={1000}
                  transitionLeaveTimeout={1000}
                  transitionName="translate-bottom-in"
                >
                  <div className="toast">
                    <Toast {...this.props} />
                  </div>
                </ReactCSSTransitionGroup>
              );
            })}
          </ul>
        </div>
      );
    }
  };
