import * as React from 'react';

import TrConnectExistingPortfolio from '../tr-connect-existing-portfolio/index.react';
import { selectIsLoggedIn } from '../../common/auth/selectors';
import { Broker, BrokerId } from '../tr-connect-existing-portfolio/conf';
import { Actions } from '../../common/types';
import { makeTemporaryUserWrapper } from '../../common/auth';
import { last } from 'lodash';
import { history } from 'sp/browser/history';

interface Props {
  actions: Actions;
  broker: Broker;
  isReauth: boolean;
}

export default class TrConnectExistingPortfolioContainer extends React.PureComponent<
  Props,
  {}
  > {
  // static propTypes = {
  //   actions: PropTypes.object,
  //   auth: PropTypes.object,
  //   broker: PropTypes.string,
  //   history: PropTypes.object,
  //   isReauth: PropTypes.boolean
  // }

  // TODO: what todo when on error
  onError = e => console.log('Error importing portfolio', e);

  // handle success
  onSuccess = async (brokerId: BrokerId, portfolioId: number) => {
    const { actions } = this.props;

    // close dialog any way
    actions.closeDialog();

    // try to fetch portfolios and move to the newer one
    try {
      const { payload } = await (actions.fetchPortfolios as any)();

      const portfolios = payload.res as any[]; // TODO as Portfolio[];
      actions.changePortfolio(portfolioId);

      history.push('/smart-portfolio/holdings#imported');
    } catch (e) {
      console.error('e', e);
    }
  };

  // handle loading stage by showing the 'analyzing portfolio' dialog
  // if you ever want to not open any dialog on loading, then make sure to
  // not closing it when loading is done. see `onLoadingDone` method
  onLoading = () => {
    const { actions } = this.props;
    actions.openDialog('analyzingPortfolio');
    // show proccessing import page in the background to log it in analytics
    history.push('/smart-portfolio/processing-import');
  };

  // handle done of loading by closing the 'analyzing portfolio' dialog
  onLoadingDone = () => {
    const { actions } = this.props;
    actions.closeDialog();
  };

  render() {
    const { actions, broker, isReauth } = this.props;

    // should we really need selectIsLoggedIn and not selectIsPermanentLoggedIn?
    const isLoggedIn = selectIsLoggedIn();

    return (
      <TrConnectExistingPortfolio
        isReauth={isReauth}
        broker={broker}
        isLoggedIn={isLoggedIn}
        makeTemporaryUser={makeTemporaryUserWrapper.bind(this)}
        onError={this.onError}
        onSuccess={this.onSuccess}
        onLoading={this.onLoading}
        onLoadingDone={this.onLoadingDone}
      />
    );
  }
}
