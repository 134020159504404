import * as React from 'react';
import { selectViewerNextPlan } from '../../common/auth/selectors';
import { setLastLockedFeature } from '../../common/lib/utils';
import './PremiumButton.styl';
import { get as lodashGet } from 'lodash';
// TODO extract PremiumButton styles from header.styl / nasdaq style
// ATM, importing just this button without header will result in a non-styled
// component
import { AuthRecord } from '../../common/types';
import { getGoProLink, buildEvents } from '@tipranks/checkout-link';
import { getSpCurrentPageName } from '../tr-payment/index';

// TODO check with oz if this seperation is needed, perhaps smart-portflio would like this aswell.
const custom3 = {
  nasdaq: {
    sidebar: 'premium-leftpanel-btn',
    topbar: 'premium-header-btn'
  },
  web: {}
};
type Custom3 = keyof typeof custom3.nasdaq | keyof typeof custom3.web;

export type PremiumButtonProps = {
  pageName?: string;
  auth: AuthRecord;
  custom3Of: Custom3;
  custom3Override?: string;
} & any; //React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement>;

export const nasdaqUrl = '/get-smart-portfolio/2/index.html';

export class PremiumButton extends React.PureComponent<PremiumButtonProps> {
  render() {
    const { custom3Of, onMouseEnter, onMouseLeave, custom3Override, pageName } = this.props;
    const theme = process.env.THEME;
    const custom3Value = custom3Override || lodashGet(custom3, `${theme}.${custom3Of}`);
    if (theme === 'web')
      return (
        <div className="goPro">
          <TipranksGoPremiumLink className="goProLink" target="_self" featureOrOrigin={custom3Value} pageName={pageName}>
            Go Pro
          </TipranksGoPremiumLink>
        </div>
      );
    if (theme === 'nasdaq') {
      return (
        <a
          onClick={() => {
            window.preventNavConfirm = true;
            if (custom3Value) setLastLockedFeature(custom3Value);
          }}
          href={nasdaqUrl}
          className="nasdaq-go-pro-button"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          Premium
        </a>
      );
    }
    return null;
  }
}

export function TipranksGoPremiumLink(props) {
  const { featureOrOrigin, queryParameters, pageName = getSpCurrentPageName('sp-go-pro-something-went-wrong'), ...restOfProps } = props
  const prefix = 'sp-';
  const prefixedFeatureOrOrigin = 
    String(featureOrOrigin).startsWith('sp-')
    ? featureOrOrigin
    : prefix + featureOrOrigin;


  const events
    = buildEvents(
      pageName,
      prefixedFeatureOrOrigin,
      { ...props },
    );
  return (
    <a
      {...restOfProps}
      {...events}
      href={getGoProLink(prefixedFeatureOrOrigin, queryParameters)}
    />
  )
}

export function shouldShowPremiumButton(auth: AuthRecord) {
  return selectViewerNextPlan(auth) !== 'ultimate' && !!selectViewerNextPlan(auth);
}
