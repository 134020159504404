import { history } from 'sp/browser/history';
import { selectIsPermanentLoggedIn } from 'sp/common/auth/selectors';
import { Routes } from 'sp/common/types';

export function navigateToPublicPortfolioToRegister(routes: Routes) {
  if (!window.location.href.includes('register')) // are we on the page already?
    history.push(getPublicPortfolioToRegisterUrl(routes));
}

export function getPublicPortfolioToRegisterUrl(routes: Routes) {
  return '/' + window.location.pathname.split('/').filter(Boolean).join('/') + '/register';
}

export function navigateToWelcomePage() {
  history.replace('/smart-portfolio/welcome');
}

export const selectIsOnPublicProileRoute = (routes: Routes) =>
  window.location.href.includes('/investors/');

export const createPublicUrlFromSlug = (id: number, slug: string) =>
  [window.location.origin, 'experts/investors', id, slug].join('/')

// TODO documentation calls replaceRoute replaceState, so rename all instances to that...
export const onEnterPublicRegisterPage = (nextState, replaceRoute) => {
  if (process.env.THEME === 'nasdaq') history.replace('/smart-portfolio/holdings');
  if (selectIsPermanentLoggedIn()) replaceRoute('/smart-portfolio/welcome')
}