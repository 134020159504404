import React from 'react';
import Popup from './popup.react';

import { communityNasdaqUrl } from '../../common/lib/utils.ts';

export default props => {
  const src =
    process.env.THEME === 'web' || window.login
      ? `/social-auth/${props.path || 'signup'}/`
      : `${communityNasdaqUrl}`;

  return <Popup {...props} src={src} />;
};
