import {
  fetchStart,
  fetchSuccess,
  fetchError
} from '../../common/dashboard/actions';

import { getDataList as apiGetDataList } from './api';

import { adaptDataList } from './api-adapter';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const clearSearch = person => {
  return {
    type: CLEAR_SEARCH
  };
};

export const PERFORM_SEARCH = 'PERFORM_SEARCH';
export const performSearch = (value, results) => {
  return {
    type: PERFORM_SEARCH,
    payload: {
      value,
      results
    }
  };
};

// const getSearchParams = category => ({
// 		ticker: { topTickers: 10 },
// 		analyst: { topAnalysts: 10 },
// 		blogger: { topBloggers: 10 },
// 		insider: { topInsiders: 10 },
// 		institution: { topInstitutions: 10 },
// 		'analyst,blogger': { topAnalysts: 5, topBloggers: 5 }
// }[category] || {
//     topTickers: 6,
//     topAnalysts: 3,
//     topBloggers: 3,
//     topInsiders: 3,
//     topInstitutions: 3
//   });

const getSearchParams = () => ({
  topTickers: 10,
  topAnalysts: 10,
  topBloggers: 10,
  topInsiders: 10,
  topInstitutions: 10
});

export const GET_DATA_LIST = 'GET_DATA_LIST';
export const GET_DATA_LIST_START = fetchStart(GET_DATA_LIST);
export const GET_DATA_LIST_SUCCESS = fetchSuccess(GET_DATA_LIST);
export const GET_DATA_LIST_ERROR = fetchError(GET_DATA_LIST);
export const getDataList = (query, category) => {
  return {
    type: GET_DATA_LIST,
    payload: {
      promise: apiGetDataList(query, getSearchParams()).then(data =>
        adaptDataList({ query, category, data })
      )
    }
  };
};

export const SET_CATEGORY = 'SET_CATEGORY';
export const setCategory = categoryObject => {
  return {
    type: SET_CATEGORY,
    payload: {
      categoryObject
    }
  };
};

export const TOGGLE_SEARCH_RESULTS = 'TOGGLE_SEARCH_RESULTS';
export const toggleSearchResults = shown => {
  return {
    type: TOGGLE_SEARCH_RESULTS,
    payload: {
      shown
    }
  };
};
