import React from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';
import msg from './en';
import Component from 'react-pure-render/component';
import ExpertRating from '../expert-rating/ExpertRating.tsx';
import {
  imgSrc,
  detail,
  appRoute,
  personRating,
  sectorId as getSectorId
} from 'sp/browser/lib/utils';
import PersonType from '../components/personsList/PersonType.react';
import { Person } from '../../common/records';
import { FormattedNumber } from 'react-intl';
import AlertsCell from '../tr-dashboard-holdings-section/cells/AlertsCell.react';
import Switch from '../components/switch';
import { createSelector } from 'reselect';

import classnames from 'classnames';
import { anyToSectorEnum, getSectorText } from '../../common/lib/utils';

const Spiv = ({ className, ...props }) => (
  <div className={className} style={{ display: 'inline-block' }}>
    <div {...props} />
  </div>
);

export class ExpertName extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.person.id !== this.props.person.id ||
      nextProps.stats !== this.props.stats
    );
  }

  render({ person, stats } = this.props) {
    const firmName = person.getIn(['firm', 'name']);
    const name = person.get('name');

    return (
      <div className="expert-name">
        <img src={imgSrc(person)} />
        <Spiv className="expert-details">
          <div className="expert-name-and-firm">
            <a target="_blank" href={appRoute(person)}>
              {name},
            </a>
            <span>{firmName}</span>
          </div>
          <ExpertRating
            size={16}
            animate={false}
            rating={personRating(person, stats)}
          />
        </Spiv>
      </div>
    );
  }
}

export const ExpertSector = ({ person }) => {
  const firm = person.get('firm');
  const sector = firm.get('sector');
  const sectorId = anyToSectorEnum(sector);
  const value = getSectorText(sector);
  return (
    <div className={`expert-sector ${sectorId}`}>
      <span>{value}</span>
    </div>
  );
};

export const ExpertAverageReturn = ({ person, withTitle }) => {
  const returnAvg = person.get('returnAvg');
  return (
    <div
      className={classnames('expert-average-return', 'percentage', {
        positive: returnAvg > 0
      })}
    >
      <FormattedNumber
        maximumFractionDigits={1}
        minimumFractionDigits={1}
        value={returnAvg}
        style="percent"
      />
      {withTitle && (
        <div className="cell-title">{msg.columnNamesShort.averageReturn}</div>
      )}
    </div>
  );
};

export const ExpertSuccessRate = ({ person, withTitle }) => {
  const successRate = person.get('successRate');
  const noData = typeof successRate !== 'number';
  return (
    <div
      className={classnames('expert-success-rate', 'percentage', {
        positive: successRate >= 0.5,
        noData
      })}
    >
      {noData ? (
        '-'
      ) : (
          <FormattedNumber
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            value={successRate}
            style="percent"
          />
        )}
      {withTitle && (
        <div className="cell-title">{msg.columnNames.successRate}</div>
      )}
    </div>
  );
};

export const ExpertType = ({ person }) => (
  <div className="expert-type">
    <PersonType person={person} />
  </div>
);

export const ExpertAlerts = props => (
  <div className="expert-alerts">
    <AlertsCell
      {...props}
      name={props.person.name}
      expectedHeight={430}
      expectedWidth={550}
    />
  </div>
);

export const ExpertNotifications = props => {
  const onChange = () => props.onClickAlert(props.person);
  return (
    <div className="expert-notifications">
      {!props.withTitle && <span className="cell-title">Email Alerts:</span>}{' '}
      <Switch checked={props.person.isFollowing} onChange={onChange} />{' '}
      {props.withTitle && <span className="cell-title">Email Alerts</span>}
    </div>
  );
};

export const ExpertDeleteButton = ({ onClick }) => (
  <div className="expert-delete-button">
    <button onClick={onClick}>
      <img src={imgSrc('delete')} />
    </button>
  </div>
);

export let __hotReload = true;
