import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import hasToasts from '../Toasts';
import hasTooltips from '../Tooltips';
import * as toasts from '../dashboard/toasts';
import * as tooltips from '../dashboard/tooltips';
import Dashboard from '../dashboard/Dashboard.react';

@hasToasts(toasts, 'toasts')
@hasTooltips(tooltips, 'tooltips')
export default class DashboardPage extends Component {
  static childContextTypes = {
    actions: PropTypes.object
  };

  static propTypes = {
    actions: PropTypes.object,
    msg: PropTypes.object
  };

  getChildContext({ actions } = this.props) {
    return { actions };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.identifyUser();
  }

  render() {
    const { msg } = this.props;

    return (
      <div className="dashboard-page">
        <Helmet title={msg.dashboard.title} />
        <Dashboard {...this.props} />
      </div>
    );
  }
}
