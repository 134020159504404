import {
  EtfAssetTypeMapping,
  EtfCategoryMapping,
  InvestmentStrategyMapping
} from '../../common/types';

export const typeToCategories: Map<
  EtfAssetTypeMapping,
  EtfCategoryMapping[]
> = new Map([
  [
    EtfAssetTypeMapping.Equity,
    [
      EtfCategoryMapping['Size and Style'],
      EtfCategoryMapping['International'],
      EtfCategoryMapping['Sector Fund'],
      EtfCategoryMapping['LeveragedEquity'],
      EtfCategoryMapping['InverseEquity']
    ]
  ],
  [
    EtfAssetTypeMapping.Debt,
    [
      EtfCategoryMapping['Corporate Bonds'],
      EtfCategoryMapping['Diversified Bonds'],
      EtfCategoryMapping['Municipality Bonds'],
      EtfCategoryMapping['Target Return'],
      EtfCategoryMapping['Government Bonds'],
      EtfCategoryMapping['Term To Maturity'],
      EtfCategoryMapping['Non-Traditional Bond'],
      EtfCategoryMapping['Target Date Bonds'],
      EtfCategoryMapping['InverseDebt'],
      EtfCategoryMapping['LeveragedDebt']
    ]
  ],
  [
    EtfAssetTypeMapping.Alternative,
    [
      EtfCategoryMapping['Allocation Strategy'],
      EtfCategoryMapping['Long And Short Positions'],
      EtfCategoryMapping['Currency'],
      EtfCategoryMapping['Money Markets'],
      EtfCategoryMapping['Other']
    ]
  ],
  [
    EtfAssetTypeMapping.Commodity,
    [
      EtfCategoryMapping['Commodity Funds'],
      EtfCategoryMapping['InverseCommodity'],
      EtfCategoryMapping['LeveragedCommodity']
    ]
  ]
]);

export const categoryToInvestmentStyle: Map<
  EtfCategoryMapping,
  InvestmentStrategyMapping[]
> = new Map([
  [
    EtfCategoryMapping['Size and Style'],
    [
      InvestmentStrategyMapping['Large Growth'],
      InvestmentStrategyMapping['Large Blend'],
      InvestmentStrategyMapping['Large Value'],
      InvestmentStrategyMapping['Small Blend'],
      InvestmentStrategyMapping['Mid-Cap Growth'],
      InvestmentStrategyMapping['Small Growth'],
      InvestmentStrategyMapping['Mid-Cap Value'],
      InvestmentStrategyMapping['Small Value'],
      InvestmentStrategyMapping['Mid-Cap Blend']
    ]
  ],
  [
    EtfCategoryMapping['International'],
    [
      InvestmentStrategyMapping['World Stock'],
      InvestmentStrategyMapping['Diversified Emerging Mkts'],
      InvestmentStrategyMapping['Foreign Large Blend'],
      InvestmentStrategyMapping['Foreign Large Value'],
      InvestmentStrategyMapping['Foreign Large Growth'],
      InvestmentStrategyMapping['Global Real Estate'],
      InvestmentStrategyMapping['Miscellaneous Region'],
      InvestmentStrategyMapping['Europe Stock'],
      InvestmentStrategyMapping['Foreign Small/Mid Growth'],
      InvestmentStrategyMapping['China Region'],
      InvestmentStrategyMapping['Pacific/Asia ex-Japan Stk'],
      InvestmentStrategyMapping['Foreign Small/Mid Blend'],
      InvestmentStrategyMapping['Foreign Small/Mid Value'],
      InvestmentStrategyMapping['Japan Stock'],
      InvestmentStrategyMapping['Latin America Stock'],
      InvestmentStrategyMapping['Diversified Pacific/Asia'],
      InvestmentStrategyMapping['India Equity ']
    ]
  ],
  [
    EtfCategoryMapping['Corporate Bonds'],
    [
      InvestmentStrategyMapping['Bank Loan'],
      InvestmentStrategyMapping['Corporate Bond'],
      InvestmentStrategyMapping['High Yield Bond'],
      InvestmentStrategyMapping['Preferred Stock']
    ]
  ],
  [
    EtfCategoryMapping['Allocation Strategy'],
    [
      InvestmentStrategyMapping['Allocation--50% to 70% Equity'],
      InvestmentStrategyMapping['World Allocation'],
      InvestmentStrategyMapping['Allocation--30% to 50% Equity'],
      InvestmentStrategyMapping['Allocation--70% to 85% Equity'],
      InvestmentStrategyMapping['Tactical Allocation'],
      InvestmentStrategyMapping['Allocation--15% to 30% Equity'],
      InvestmentStrategyMapping['Allocation--85%+ Equity'],
      InvestmentStrategyMapping['Conservative Allocation'],
      InvestmentStrategyMapping['Moderate Allocation'],
      InvestmentStrategyMapping['Aggressive Allocation']
    ]
  ],
  [
    EtfCategoryMapping['Diversified Bonds'],
    [
      InvestmentStrategyMapping['Emerging Markets Bond'],
      InvestmentStrategyMapping['Emerging-Markets Local-Currency Bond'],
      InvestmentStrategyMapping['Multisector Bond'],
      InvestmentStrategyMapping['World Bond']
    ]
  ],
  [
    EtfCategoryMapping['Other'],
    [
      InvestmentStrategyMapping['Multialternative'],
      InvestmentStrategyMapping['Real Estate'],
      InvestmentStrategyMapping['Managed Futures'],
      InvestmentStrategyMapping['Convertibles'],
      InvestmentStrategyMapping['Option Writing'],
      InvestmentStrategyMapping['Bear Market'],
      InvestmentStrategyMapping['Trading--Miscellaneous'],
      InvestmentStrategyMapping['Volatility']
    ]
  ],
  [
    EtfCategoryMapping['Municipality Bonds'],
    [
      InvestmentStrategyMapping['High Yield Muni'],
      InvestmentStrategyMapping['Muni California Intermediate'],
      InvestmentStrategyMapping['Muni California Long'],
      InvestmentStrategyMapping['Muni Massachusetts'],
      InvestmentStrategyMapping['Muni Minnesota'],
      InvestmentStrategyMapping['Muni National Interm'],
      InvestmentStrategyMapping['Muni National Long'],
      InvestmentStrategyMapping['Muni National Short'],
      InvestmentStrategyMapping['Muni New Jersey'],
      InvestmentStrategyMapping['Muni New York Intermediate'],
      InvestmentStrategyMapping['Muni New York Long'],
      InvestmentStrategyMapping['Muni Ohio'],
      InvestmentStrategyMapping['Muni Pennsylvania'],
      InvestmentStrategyMapping['Muni Single State Interm'],
      InvestmentStrategyMapping['Muni Single State Long'],
      InvestmentStrategyMapping['Muni Single State Short']
    ]
  ],
  [
    EtfCategoryMapping['Target Return'],
    [
      InvestmentStrategyMapping['Inflation-Protected Bond'],
      InvestmentStrategyMapping['Retirement Income']
    ]
  ],
  [
    EtfCategoryMapping['Government Bonds'],
    [
      InvestmentStrategyMapping['Intermediate Government'],
      InvestmentStrategyMapping['Long Government'],
      InvestmentStrategyMapping['Short Government']
    ]
  ],
  [
    EtfCategoryMapping['Long And Short Positions'],
    [
      InvestmentStrategyMapping['Long-Short Equity'],
      InvestmentStrategyMapping['Long-Short Credit'],
      InvestmentStrategyMapping['Market Neutral'],
      InvestmentStrategyMapping['Long/Short Equity']
    ]
  ],
  [
    EtfCategoryMapping['Term To Maturity'],
    [
      InvestmentStrategyMapping['Intermediate-Term Bond'],
      InvestmentStrategyMapping['Long-Term Bond'],
      InvestmentStrategyMapping['Short-Term Bond'],
      InvestmentStrategyMapping['Ultrashort Bond']
    ]
  ],
  [
    EtfCategoryMapping['Sector Fund'],
    [
      InvestmentStrategyMapping['Technology'],
      InvestmentStrategyMapping['Natural Resources'],
      InvestmentStrategyMapping['Energy Limited Partnership'],
      InvestmentStrategyMapping['Health'],
      InvestmentStrategyMapping['Equity Energy'],
      InvestmentStrategyMapping['Financial'],
      InvestmentStrategyMapping['Infrastructure'],
      InvestmentStrategyMapping['Utilities'],
      InvestmentStrategyMapping['Equity Precious Metals'],
      InvestmentStrategyMapping['Industrials'],
      InvestmentStrategyMapping['Consumer Cyclical'],
      InvestmentStrategyMapping['Communications'],
      InvestmentStrategyMapping['Consumer Defensive'],
      InvestmentStrategyMapping['Miscellaneous Sector']
    ]
  ],
  [
    EtfCategoryMapping['LeveragedCommodity'],
    [InvestmentStrategyMapping['Trading--Leveraged Commodities']]
  ],
  [
    EtfCategoryMapping['LeveragedDebt'],
    [InvestmentStrategyMapping['Trading-Leveraged Debt']]
  ],
  [
    EtfCategoryMapping['LeveragedEquity'],
    [InvestmentStrategyMapping['Trading-Leveraged Equity']]
  ],
  [
    EtfCategoryMapping['Commodity Funds'],
    [
      InvestmentStrategyMapping['Commodities Broad Basket'],
      InvestmentStrategyMapping['Commodities Precious Metals'],
      InvestmentStrategyMapping['Commodities Energy'],
      InvestmentStrategyMapping['Commodities Agriculture'],
      InvestmentStrategyMapping['Commodities Industrial Metals'],
      InvestmentStrategyMapping['Commodities Miscellaneous']
    ]
  ],
  [
    EtfCategoryMapping['Non-Traditional Bond'],
    [InvestmentStrategyMapping['Nontraditional Bond']]
  ],
  [
    EtfCategoryMapping['Target Date Bonds'],
    [
      InvestmentStrategyMapping['Target Date 2000-2010'],
      InvestmentStrategyMapping['Target Date 2011-2015'],
      InvestmentStrategyMapping['Target Date 2016-2020'],
      InvestmentStrategyMapping['Target Date 2021-2025'],
      InvestmentStrategyMapping['Target Date 2026-2030'],
      InvestmentStrategyMapping['Target Date 2031-2035'],
      InvestmentStrategyMapping['Target Date 2036-2040'],
      InvestmentStrategyMapping['Target Date 2041-2045'],
      InvestmentStrategyMapping['Target Date 2046-2050 '],
      InvestmentStrategyMapping['Target Date 2051+'],
      InvestmentStrategyMapping['Target-Date 2000-2010'],
      InvestmentStrategyMapping['Target-Date 2015'],
      InvestmentStrategyMapping['Target-Date 2020'],
      InvestmentStrategyMapping['Target-Date 2025'],
      InvestmentStrategyMapping['Target-Date 2030'],
      InvestmentStrategyMapping['Target-Date 2035'],
      InvestmentStrategyMapping['Target-Date 2040'],
      InvestmentStrategyMapping['Target-Date 2045'],
      InvestmentStrategyMapping['Target-Date 2050'],
      InvestmentStrategyMapping['Target-Date 2055'],
      InvestmentStrategyMapping['Target-Date 2060+'],
      InvestmentStrategyMapping['Target-Date Retirement']
    ]
  ],
  [
    EtfCategoryMapping['InverseEquity'],
    [InvestmentStrategyMapping['Trading--Inverse Equity']]
  ],
  [
    EtfCategoryMapping['InverseDebt'],
    [InvestmentStrategyMapping['Trading-Inverse Debt']]
  ],
  [
    EtfCategoryMapping['InverseCommodity'],
    [InvestmentStrategyMapping['Trading--Inverse Commodities']]
  ],
  [
    EtfCategoryMapping['Currency'],
    [
      InvestmentStrategyMapping['Multicurrency'],
      InvestmentStrategyMapping['Single Currency']
    ]
  ],
  [
    EtfCategoryMapping['Money Markets'],
    [
      InvestmentStrategyMapping['Money Market-Taxable'],
      InvestmentStrategyMapping['Money Market-Tax-Free'],
      InvestmentStrategyMapping['Prime Money Market']
    ]
  ]
]);

export const categoryToCategory = new Map([
  [EtfCategoryMapping.LeveragedCommodity, EtfCategoryMapping.Leveraged],
  [EtfCategoryMapping.LeveragedDebt, EtfCategoryMapping.Leveraged],
  [EtfCategoryMapping.LeveragedEquity, EtfCategoryMapping.Leveraged],
  [EtfCategoryMapping.InverseCommodity, EtfCategoryMapping.Inverse],
  [EtfCategoryMapping.InverseDebt, EtfCategoryMapping.Inverse],
  [EtfCategoryMapping.InverseEquity, EtfCategoryMapping.Inverse]
]);
