import * as React from 'react';
import * as styles from './styles.istyl';

export function SmarterAnalystBadge() {
  return (
    <span className={styles.smarterAnalystBadge}>
      <small>Powered by</small> <b>Smarter</b>
      <span>Analyst</span>
    </span>
  );
}
