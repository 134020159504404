import './assets/styles/radio.styl';

import * as React from 'react';

import { selectViewerPlan, planWeight } from '../../common/auth/selectors';
import { setLastLockedFeature } from '../../common/lib/utils';
import report from './analytics';
import Company from './company';
import { ActionCreator, Portfolio } from './types';
import { Theme } from 'sp/common/types';

interface Props {
  theme: Theme;
  numPortfolios: number;
  changeSelection: ActionCreator;
  restrictedChange: ActionCreator;
  selectedPortfolio?: Portfolio;
  auth: any;
}

interface ChangeOptions {
  restricted?: boolean;
}

export default class PortfolioSelector extends React.PureComponent<Props> {
  private change(
    portfolio: Portfolio,
    { restricted = false }: ChangeOptions = {}
  ) {
    const { auth } = this.props;
    return e => {
      const canLock = restricted;
      const locked =
        canLock && planWeight(selectViewerPlan(auth)) < planWeight('premium');

      if (locked) {
        setLastLockedFeature('sp-crowd-insights-best-portfolio');
        return this.props.restrictedChange();
      }

      report(
        portfolio === Portfolio.AveragePortfolio
          ? 'clickedAverage'
          : 'clickedBest'
      );
      this.props.changeSelection(portfolio);
    };
  }

  public render() {
    const { numPortfolios, selectedPortfolio, theme } = this.props;
    return (
      <div className="portfolio-selector">
        <p className="intro">Compare your portfolio to:</p>
        <p className="labels">
          <label>
            <input
              name="portfolio-selector"
              type="radio"
              checked={selectedPortfolio === Portfolio.AveragePortfolio}
              onChange={this.change(Portfolio.AveragePortfolio)}
            />
            <span className="label-text">
              <span className="label-description">
                <span>
                  <span className="type">Average</span>{' '}
                  <Company theme={theme} /> Portfolio
                </span>
                <small>
                  Based on {numPortfolios} <Company theme={theme} /> Portfolios
                </small>
              </span>
            </span>
          </label>
          <label
            className="hasTooltip"
            data-tooltip="Top 10% of portfolios who consistently outperform the markets."
          >
            <input
              name="portfolio-selector"
              type="radio"
              checked={selectedPortfolio === Portfolio.BestPerformingPortfolio}
              onChange={this.change(Portfolio.BestPerformingPortfolio, {
                restricted: true
              })}
            />
            <span className="label-text">
              <span className="label-description best">
                <span>
                  <strong className="type">Best Performing</strong>{' '}
                  <Company theme={theme} /> Portfolios
                </span>
                <small>
                  Based on portfolios that generated the{' '}
                  <span className="highest-return">highest return</span>
                </small>
              </span>
            </span>
          </label>
        </p>
      </div>
    );
  }
}
