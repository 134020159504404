import reporter from '../analytics';

const names = type =>
  ({
    mgmtFees: 'management-fees',
    screener: 'screener-filter'
  }[type] || type);

const events = {
  saw: `saw-upgrade-pop-up`,
  sawType: type => `saw-${names(type)}-upgrade-pop-up`,
  clickedUpgrade: type => `clicked-upgrade-pop-up-${type}`
};

const report = reporter(events);

export default report;
