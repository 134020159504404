import reporter from '../analytics';

const events = {
  visited: 'visited-smart-portfolio-news-page',
  clickedNewsType: type => `clicked-${type}-news`,
  clickedNewsItemLink: 'clicked-news-article-link'
};

const report = reporter(events);

export default report;
