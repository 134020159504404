import * as React from 'react';
import { Link as ReactRouterLink, RoutePattern } from 'react-router';
import { LocationDescriptor } from 'history';

/** Specially typed react-router Link */
export const Link: ProperLink = ReactRouterLink as any;

// The types for react-router link extend React.LinkHTMLAttributes, which
// strangely doesn't contain the `target` attribute, although react-router
// supports it https://github.com/ReactTraining/react-router/blob/24e7b4fdf5a80548d55488458cd54c386a688c89/modules/Link.js#L81
// so we type it ourselves ;(

type ProperLink = ProperLink.ProperLink;

declare namespace ProperLink {
  interface ProperLinkProps extends React.AnchorHTMLAttributes<ProperLink> {
    activeStyle?: React.CSSProperties;
    activeClassName?: string;
    onlyActiveOnIndex?: boolean;
    to: RoutePattern | LocationDescriptor | ((...args: any[]) => LocationDescriptor);
  }

  interface ProperLink extends React.ComponentClass<ProperLinkProps> { }
  interface LinkElement extends React.ReactElement<ProperLinkProps> { }
}
