import request from '../lib/http';

const errorHandler = e => {
  if (e.response.status > 400) {
    console.log('dictionary slug not found');
  }
};

import {
  fetchStart,
  fetchSuccess,
  fetchError,
  appendSignature
} from '../../common/dashboard/actions';

export const DOWNLOAD_PAGE = 'DOWNLOAD_PAGE';
export const DOWNLOAD_PAGE_START = fetchStart(DOWNLOAD_PAGE);
export const DOWNLOAD_PAGE_SUCCESS = fetchSuccess(DOWNLOAD_PAGE);
export const DOWNLOAD_PAGE_ERROR = fetchError(DOWNLOAD_PAGE);
export const downloadPage = slug => {
  const url = `/api/knowledgedictionary/FromPath?path=${slug}`;
  return {
    type: DOWNLOAD_PAGE,
    payload: {
      promise: request.get(url).then(res => ({ ...res, slug }))
    }
  };
};
