import { List } from 'immutable';
import { Link } from 'react-router';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getNasdaqSymbolUrl } from '../../common/lib/utils.ts';
import { defaultMsg } from './msg';

import { dashboardPath } from '../../common/config';
import { TrDashboardWarning } from '../tr-dashboard-warning';
import { TrDashboardMgmtFeesDesc } from '../tr-dashboard-mgmt-fees-desc';
import { TrHowWeFindSimilarFunds } from '../tr-how-we-find-similar-funds';
import { TrDashboardMgmtFeesStats } from '../tr-dashboard-mgmt-fees-stats';
import { TrDashboardMgmtFeesNoData } from '../tr-dashboard-mgmt-fees-no-data';

import TrDashboardMgmtFeesImportantNote from '../tr-dashboard-mgmt-fees-important-note';
import './index.styl';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export class TrDashboardMgmtFeesRoute extends Component {
  static propTypes = {
    doAllMgmtFeesHaveShares: PropTypes.bool,
    isFundBoxMoreDetailed: PropTypes.bool,
    isHowWeFindSimilarFundsShown: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRestricted: PropTypes.bool,
    isRestrictedBoxMoreDetailed: PropTypes.bool,
    mgmtFees: PropTypes.object,
    //msg: PropTypes.shape(msgShape),
    onCompareClicked: PropTypes.func,
    onRestrictedClick: PropTypes.func,
    saveAmount: PropTypes.number,
    savePercent: PropTypes.number
  };

  static defaultProps = {
    doAllMgmtFeesHaveShares: true,
    isFundBoxMoreDetailed: false,
    isHowWeFindSimilarFundsShown: false,
    isLoading: false,
    isRestricted: true,
    isRestrictedBoxMoreDetailed: false,
    mgmtFees: new List(),
    msg: defaultMsg,
    saveAmount: 0,
    savePercent: 0
  };

  onCompareClicked = ticker => {
    const { onCompareClicked } = this.props;
    return () => onCompareClicked(ticker);
  };

  url = ({ type, fundSymbol }) =>
    process.env.THEME === 'nasdaq'
      ? getNasdaqSymbolUrl(fundSymbol)
      : `/${type}${type === 'etf' ? '' : 's'}/${fundSymbol}`;

  renderFund = ({
    expenseRatio,
    fundName,
    fundSymbol,
    isFirst,
    isRestricted,
    mgmtFee,
    type
  }) => {
    const {
      isFundBoxMoreDetailed,
      isRestrictedBoxMoreDetailed,
      msg,
      onRestrictedClick
    } = this.props;

    return (
      <div className={classNames('fund', { isRestricted })}>
        {isFirst &&
          isFundBoxMoreDetailed && (
            <div className="caption">{msg[`fundCaption.${type}`]}</div>
          )}
        <div className="fund-inner">
          {isRestricted ? (
            <button onClick={onRestrictedClick}>
              <div className="save-money">
                <img
                  alt={msg.saveMoney}
                  src={reqImg('restricted-before.png')}
                />
              </div>
              <span className="restricted-txt">
                {isRestrictedBoxMoreDetailed && (
                  <div className="restricted-txt-detailed">
                    {msg['restrictedBtn.tablet']}
                  </div>
                )}
                {!isRestrictedBoxMoreDetailed && msg.restrictedBtn}
              </span>
            </button>
          ) : (
            <div>
              <div className="ticker-and-name">
                <a
                  href={this.url({ type: mgmtFee.get('type'), fundSymbol })}
                  target="_blank"
                >
                  {fundSymbol}
                </a>
                {isFundBoxMoreDetailed && (
                  <span className="name">{fundName}</span>
                )}
              </div>
              <div className="fund-box-more-info">
                {msg[`fees.${isFundBoxMoreDetailed ? 'longer' : 'shorter'}`]}:
                &nbsp;
                <span className="fees-value">
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={expenseRatio * 100}
                  />%
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderLi = (mgmtFee, index) => {
    const {
      doAllMgmtFeesHaveShares,
      isFundBoxMoreDetailed,
      isRestricted,
      msg
    } = this.props;

    return (
      <li key={mgmtFee.get('fundSymbol')}>
        <div className="li-inner">
          <div className="funds-comparison">
            {this.renderFund({
              mgmtFee,
              expenseRatio: mgmtFee.get('expenseRatio'),
              isFirst: index === 0,
              fundSymbol: mgmtFee.get('fundSymbol'),
              fundName: mgmtFee.get('fundName'),
              type: 'current'
            })}
            <div className="arrow">
              {isFundBoxMoreDetailed ? (
                <div>
                  <span>{msg.changeTo}</span>
                  <img alt="" src={reqImg('arrow-detailed.png')} />
                </div>
              ) : (
                <img alt="" src={reqImg('arrow.png')} />
              )}
            </div>
            {this.renderFund({
              expenseRatio: mgmtFee.get('similarExpenseRatio'),
              fundName: mgmtFee.get('simlarFundName'),
              fundSymbol: mgmtFee.get('similarSymbol'),
              isFirst: index === 0,
              isRestricted,
              mgmtFee,
              type: 'similar'
            })}
          </div>
          <div className="dl-and-btn">
            <TrDashboardMgmtFeesStats
              doAllMgmtFeesHaveShares={doAllMgmtFeesHaveShares}
              mgmtFee={mgmtFee}
            />
            <button
              className="compare"
              onClick={this.onCompareClicked(mgmtFee.get('fundSymbol'))}
            >
              <span>{msg.compareBtn}</span>
            </button>
          </div>
        </div>
      </li>
    );
  };

  render() {
    const {
      doAllMgmtFeesHaveShares,
      isHowWeFindSimilarFundsShown,
      isLoading,
      mgmtFees,
      msg,
      saveAmount,
      savePercent
    } = this.props;

    if (isLoading) {
      return (
        <div className="tr-dashboard-mgmt-fees-route is-loading">
          <img alt="" src={reqImg('loader.gif')} />
        </div>
      );
    }

    const hasFees = mgmtFees.size > 0;
    if (!hasFees) {
      return <TrDashboardMgmtFeesNoData />;
    }

    return (
      <section className="tr-dashboard-mgmt-fees-route">
        {!doAllMgmtFeesHaveShares && (
          <div className="tr-dashboard-warning-wrapper">
            <TrDashboardWarning>
              <FormattedMessage
                id={msg.warning}
                defaultMessage={msg.warning}
                values={{
                  addSharesLbl: (
                    <Link to={`/${dashboardPath}/holdings/holdings`}>
                      {msg.addSharesLbl}
                    </Link>
                  )
                }}
              />
            </TrDashboardWarning>
          </div>
        )}
        <header>
          <h1>{msg.headerH1}</h1>
        </header>
        <main>
          <div className="tr-dashboard-mgmt-fees-desc-wrapper">
            <TrDashboardMgmtFeesDesc
              doAllMgmtFeesHaveShares={doAllMgmtFeesHaveShares}
              saveAmount={saveAmount}
              savePercent={savePercent}
            />
          </div>
          <ol>{mgmtFees.map(this.renderLi)}</ol>
          <div className="list-footer">
            <div className="li-before">{msg.listFooterLbl}</div>
            <div className="list-footer-content">{msg.listFooter}</div>
          </div>
        </main>
        <footer>
          <div className="tr-dashboard-mgmt-fees-important-note-wrapper">
            <TrDashboardMgmtFeesImportantNote />
          </div>
          {isHowWeFindSimilarFundsShown && <TrHowWeFindSimilarFunds />}
        </footer>
      </section>
    );
  }
}

export default TrDashboardMgmtFeesRoute;
