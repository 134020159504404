import { PortfolioRecord } from '../../common/types';
import * as React from 'react';
import * as classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import AutoComplete_ from '../autocomplete/AutoComplete.react';
import { ImportLogos } from '../tr-import-logos/ImportLogos';
import Loader from '../components/suit/Loader.react';

import { getResolutionDependentPendingLimit, disablePortfolioImport } from '../../common/config';
import { imgSrc } from '../../common/lib/utils';
import { MediaQuery } from 'sp/common/lib/mediaQuery';
import { syncSmartPortfolioWithPlaid } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { identifyUser } from '../../common/api';
import VariableSizeSpinner from '../Spinner';

const preventDefault = () => null;

export class AddStock extends React.PureComponent<{
  activePortfolio?: PortfolioRecord; //PropTypes.object,
  dataList?: any; //PropTypes.object,
  msg?: any; //PropTypes.object,
  pendings?: any; //PropTypes.object,
  query: string; //PropTypes.string,
  autoCompleteState: string; //PropTypes.string,
  isLoggedIn: boolean; //PropTypes.bool,
  shouldDisplayImportOption: boolean; //PropTypes.bool,
  hasHoldings: boolean;
  addPendingStocksToAdd: (...args: any[]) => any;
  applyAndClose: (...args: any[]) => any;
  changeAutoCompleteQuery: (...args: any[]) => any;
  deletePendingStocksToAdd: (...args: any[]) => any;
  fetchAutoComplete: (...args: any[]) => any;
  showImport: (...args: any[]) => any;
  onHasReachedLimit?: (...args: any[]) => any;
  fetchPortfolios?: () => Promise<number[]>
  changePortfolio?: (portfolioId: number) => void
  close?: () => void
  navigationOnDone?: () => void
  mediaQuery: MediaQuery;
  auth: any
}, { expertId: number, isLoadingPlaid: boolean, agreed: boolean, error: boolean, hasReachedLimit: boolean }> {
  state = {
    expertId: 0,
    isLoadingPlaid: false,
    agreed: false,
    error: false,
    hasReachedLimit: false
  };
  static defaultProps = {
    shouldDisplayImportOption: false
  };
  componentDidMount() {
    const expertIdFromStore
      = this.props.auth
        .get('users')
        .get('viewer')
        .get('loggedInUser')
        .get('expertId');
    if (expertIdFromStore) {
      this.setState({ expertId: expertIdFromStore })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.pendings.size !== this.props.pendings.size) {
      this.setState({ hasReachedLimit: this.isAtPendingLimit() });
    }
  }

  onSyncClicked = () => {
    this.setState({ isLoadingPlaid: true })
    const { fetchPortfolios, changePortfolio, close, navigationOnDone } = this.props;
    syncSmartPortfolioWithPlaid(this.state.expertId, {
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        if (typeof fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await fetchPortfolios();
        }
        if (Array.isArray(PortfolioIds) && PortfolioIds[0] && typeof changePortfolio === 'function') {
          changePortfolio(PortfolioIds[0])
        }
        if (typeof close === 'function') {
          close()
        }
        if (typeof navigationOnDone === 'function') {
          navigationOnDone()
        }
        this.setState({ isLoadingPlaid: false });
      },
      onSyncFailed: () => {
        this.setState({ isLoadingPlaid: false })
      },
      onSyncStopped: () => {
        this.setState({ isLoadingPlaid: false })
      },
    })
  }

  render() {
    const {
      activePortfolio,
      applyAndClose,
      autoCompleteState,
      changeAutoCompleteQuery,
      dataList,
      deletePendingStocksToAdd,
      fetchAutoComplete,
      hasHoldings,
      isLoggedIn,
      msg,
      pendings,
      query,
      shouldDisplayImportOption
    } = this.props;

    const { hasReachedLimit, agreed, error, isLoadingPlaid } = this.state;

    const isLoadingList = autoCompleteState;
    const AutoComplete = AutoComplete_ as any;

    const connectPortfolioMsg 
    = isLoadingPlaid ? (
      <span className={'add-stock-connecting-msg'}>
        Connecting to your existing portfolio, please wait…
        <VariableSizeSpinner className={'add-stock-connecting-spinner'}size={15}/>
      </span>
       ) : (
        !disablePortfolioImport && (
          <span>Already have a portfolio in another platform? <button onClick={this.onSyncClicked}>Synchronize Your Existing Portfolio</button></span>
        )
      )

    return (
      <div
        className={classNames('add-stock-dialog', {
          hasReachedLimit,
          shouldDisplayImportOption,
          'add-stock-no-pendings': pendings.size === 0
        })}
      >
        <h2>{msg.title}</h2>
        <form onSubmit={this.getFormSubmittedCb(query.trim())}>
          <label>
            <div className="search-wrapper">
              <div className="input-wrapper">
                <div className="indicators">
                  {isLoadingList === 'LOADING' && <Loader />}
                  {isLoadingList === 'ERROR' && (
                    <img alt="" src={imgSrc('error')} />
                  )}
                </div>
                <AutoComplete
                  autoFocus
                  disabled={hasReachedLimit}
                  list={dataList}
                  listUpdater={fetchAutoComplete}
                  onChange={changeAutoCompleteQuery}
                  onSelected={this.optionSelected}
                  placeHolder={msg.inputPlaceHolder}
                  value={query}
                />
              </div>
              <button>+</button>
            </div>
          </label>
        </form>
        <div
          className={classNames('pending-stocks', {
            shouldDisplayImportOption
          })}
        >
          {!shouldDisplayImportOption && (
            <h3 className="pendingStocksTitle">{msg.pendingStocksTitle}</h3>
          )}
          {pendings.size > 0 ? (
            <ul className="pendings-stocks-list">
              {pendings.map(item => (
                <li key={item}>
                  {item}
                  <button onClick={() => deletePendingStocksToAdd(item)}>
                    +
                  </button>
                </li>
              ))}
            </ul>
          ) : (
              <div>{this.renderNoPending()}</div>
            )}
        </div>
        <div className={'pendings-stocks-link-portfolio'}>
          {connectPortfolioMsg}
          </div>
        <div className="controls">
          {hasReachedLimit && (
            <div className="message">
              Please proceed with building your portfolio. <br />You can add
              more symbols later.
            </div>
          )}
          {
            /*isLoadingList === 'ERROR' &&
            <div className="message">
              {msg.noTicker}
            </div>
          */ ''
          }
          {(!shouldDisplayImportOption || pendings.size > 0) && (
            <button
              disabled={pendings.size === 0}
              onClick={this.applyAndClose(pendings, activePortfolio)}
            >
              {msg.addBtn}
            </button>
          )}
          <div className={classNames('terms-link', { error })}>
            {!hasHoldings &&
              process.env.THEME === 'nasdaq' && (
                <div>
                  <label>
                    <input
                      onChange={this.toggleAgreement}
                      type="checkbox"
                      value={agreed ? 'true' : 'false'}
                    />
                    <span className="label">
                      <span>{msg.terms}</span>{' '}
                      <a href="https://tipranks.com/terms" target="_blank">{msg.termsLink}</a>{' '}
                      <span>and</span>{' '}
                      <a href="https://tipranks.com/privacypolicy" target="_blank">{msg.privacyPolicyLink}</a>{' '}
                    </span>
                  </label>
                  <div className="message">
                    * Please accept the Terms of Use and Privacy Policy
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }

  setError = () => this.setState({ error: true });

  getFormSubmittedCb = query => {
    const { addPendingStocksToAdd, dataList } = this.props;
    const { hasReachedLimit } = this.state;
    return event => {
      event.preventDefault();
      if (hasReachedLimit) {
        if (this.props.onHasReachedLimit) this.props.onHasReachedLimit();
      } else if (!hasReachedLimit) {
        const q = query.includes(' ') ? query.split(' ')[0] : query;
        addPendingStocksToAdd(q, dataList);
      }
    };
  };

  applyAndClose = (...args) => {
    const { applyAndClose, hasHoldings, isLoggedIn } = this.props;
    return (...event) => {
      const { agreed } = this.state;
      if (!hasHoldings && !agreed && process.env.THEME === 'nasdaq') {
        this.setError();
        return;
      }
      return applyAndClose(...args)(...event);
    };
  };

  optionSelected = query => {
    const { pendings } = this.props;
    const { hasReachedLimit } = this.state;
    this.getFormSubmittedCb(query)({ preventDefault });
  };

  isAtPendingLimit = () =>
    this.props.pendings.size >=
    getResolutionDependentPendingLimit(this.props.mediaQuery);

  renderNoPending = () => {
    const { showImport, msg, shouldDisplayImportOption } = this.props;
    return shouldDisplayImportOption ? (
      <div className="no-pendings-import">
        <div className="or">
          <div className="orLine">
            <div>{msg.syncOr}</div>
          </div>
        </div>
        <div className="title">{msg.syncTitle}</div>
        <div className="text">
          <FormattedMessage
            id={msg.syncText}
            defaultMessage={msg.syncText}
            values={{
              import: <button onClick={showImport}>{msg.importLink}</button>
            }}
          />
        </div>
        <div>
          <ImportLogos />
        </div>
      </div>
    ) : (
        <div className="no-pendings">{msg.emptyPendingLists}</div>
      );
  };

  toggleAgreement = (event: any) =>
    this.setState({ agreed: event.target.checked });
}
export default AddStock;
