import './App.styl';
import 'tipranks-icons/dist/style.css';

import * as classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import mapStateToProps from '../../common/app/mapStateToProps';
import {
  selectIsPermanentLoggedIn,
  selectViewer,
} from '../../common/auth/selectors';
import RouterHandler from '../../common/components/RouterHandler.react';
import { brandName } from '../../common/config';
import { Helmet } from 'react-helmet';
import { getFirstVisitTime } from '../../common/lib/utils';
import { ReactRouterLocation, ReduxProps } from '../../common/types';
import * as dialogs from '../dashboard/dialogsCatalogue';
import { selectTheme } from '../dashboard/selectors';
import isResponsive from '../decorators/Responsive/index';
import { hasDialogs } from '../dialogs/index.react';
import { setGlobalActions } from '../lib/actions';
import { init as initFaviconNotification } from '../lib/favicon-notification';
import Header from '../tr-dashboard-header/index';
import NasdaqHeader from '../tr-dashboard-header/header-nasdaq.react'
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';
import { updateLocationFromManagerAndCacheInLocalStorage } from './getLocationFromManagerAndCacheInLocalStorage';


@connect(mapStateToProps, mapDispatchToProps)
@isResponsive
@hasDialogs(dialogs, 'dialogs')
export class App extends React.PureComponent<ReduxProps & ReactRouterLocation, {}> {
  componentDidMount() {
    updateLocationFromManagerAndCacheInLocalStorage()
    const { actions, auth } = this.props;


    setGlobalActions(actions);

    const viewer = selectViewer(auth);
    const isPermanentLoggedIn = selectIsPermanentLoggedIn();
    const isFirstVisit = viewer.get('isFirstVisit');

    if (!isPermanentLoggedIn) {
      if (isFirstVisit) {
        localStorage.setItem('firstVisit', getFirstVisitTime());
        actions.setFirstVisit();
      }
    }

    if (process.env.THEME !== 'nasdaq') {
      initFaviconNotification({
        color: '#FF0000',
        radiusRelativeToSize: 0.2,
        lineWidth: 1,
      });
    }
  }


  renderMain = () => <RouterHandler {...this.props} />;

  render() {
    const { dashboard, notifications, location: { pathname }, msg, auth } = this.props;

    const theme = selectTheme(dashboard);

    // TODO if you need it, annotate it
    // window.appProps = this.props;

    const unread = notifications.notifications.filter(
      n => !n.read
    ).length;

    const unreadText = unread === 0
      ? ''
      : unread > 9
        ? '(9+)'
        : `(${unread})`;

    return (
      // Pass data-pathname to allow route specific styling.
      <div
        className={classNames(
          'page',
          auth.getIn(['users', 'viewer', 'loggedInUser', 'email'])
            ? 'logged-in'
            : '',
          auth.getIn(['users', 'viewer', 'plan']),
        )}
        data-pathname={pathname}
      >
        <Helmet
          meta={[
            {
              name: `${brandName} Dashboard`,
              content: `${brandName} Dashboard`,
            },
          ]}
          titleTemplate={`${unreadText} %s | ${brandName}`}
        />
        {/* Pathname enforces rerender so activeClassName is updated. */}
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          transitionName="translate-top-in"
        >
          { theme === 'nasdaq' ? 
            <NasdaqHeader {...this.props} theme={theme}>
              {this.renderMain()}
            </NasdaqHeader>
          : <Header {...this.props} theme={theme}>
              {this.renderMain()}
          </Header>
          }
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
