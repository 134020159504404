const bioPortfolioIcon = require('./images/bio-portfolio-icon.png');
const dailyInsiderIcon = require('./images/daily-insider-icon.png');
const smallCapRocketsIcon = require('./images/small-cap-rocket-icon.png');
const theBigShortIcon = require('./images/the-big-short-icon.png');
const goSectorIcon = require('./images/go-sector-icon.png');
const guruPortfolioIcon = require('./images/guru-portfolio-icon.png');

const bioPortfolioRight = require('./images/bio-portfolio.png');
const dailyInsiderRight = require('./images/daily-insider.png');
const smallCapRocketsRight = require('./images/small-cap-rockets.png');
const theBigShortRight = require('./images/the-big-short.png');
const goSectorRight = require('./images/go-sector.png');
const guruPortfolioRight = require('./images/guru-portfolio.png');

export enum PremiumNewsletter {
  BioPortfolio,
  DailyInsider,
  SmallCapRockets,
  TheBigShort,
  GoSector,
  GuruPortfolio
}

export type NewsletterDescriptionProps = {
  color: string;
  iconSrc: string;
  headerColoredPart: string;
  headerNormalPart: string;
  subheader: string;
  points: string[];
  annualReturn: number;
  learnMoreHref: string;
  sideImageSrc: string;
};

export const conf: { [key: number]: NewsletterDescriptionProps } = {
  [PremiumNewsletter.BioPortfolio]: {
    annualReturn: 110.5,
    color: 'hsl(139, 71%, 37%)',
    headerColoredPart: 'Bio',
    headerNormalPart: 'Portfolio',
    iconSrc: bioPortfolioIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/bioportfolio-biotech-investment-newsletter?ref=tr-my-account',
    points: [
      'Weekly Newsletter',
      'Biotech Long/Short',
      'For bio Investors',
      'Volatility Level: High'
    ],
    sideImageSrc: bioPortfolioRight,
    subheader:
      'Take human emotions out of the most difficult trading sector and start earning big'
  },
  [PremiumNewsletter.DailyInsider]: {
    annualReturn: 85.4,
    color: 'hsl(184, 55%, 49%)',
    headerColoredPart: 'Daily',
    headerNormalPart: 'Insider',
    iconSrc: dailyInsiderIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/the-daily-insider-day-trading-newsletter?ref=tr-my-account',
    points: [
      'Daily Newsletter',
      'Market Timing',
      'For Day Traders',
      'Volatility Level: High'
    ],
    sideImageSrc: dailyInsiderRight,
    subheader:
      'Professional day-trading strategy based on predictive insider transactions'
  },
  [PremiumNewsletter.SmallCapRockets]: {
    annualReturn: 71.1,
    color: 'hsl(204, 70%, 53%)',
    headerColoredPart: 'SmallCap',
    headerNormalPart: 'Rockets',
    iconSrc: smallCapRocketsIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/small-cap-stocks-newsletter?ref=tr-my-account',
    points: [
      'Daily Newsletter',
      'Small-Cap Stocks',
      'For Day Traders',
      'Volatility Level: Medium'
    ],
    sideImageSrc: smallCapRocketsRight,
    subheader:
      '24 hour trades for small-cap stocks based on real time news analysis'
  },
  [PremiumNewsletter.TheBigShort]: {
    annualReturn: 45.9,
    color: 'hsl(0, 92%, 63%)',
    headerColoredPart: 'TheBIG',
    headerNormalPart: 'SHORT',
    iconSrc: theBigShortIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/the-big-short-selling-newsletter?ref=tr-my-account',
    points: [
      'Daily Newsletter',
      'Short Selling',
      'Swing Traders',
      'Volatility Level: Medium'
    ],
    sideImageSrc: theBigShortRight,
    subheader:
      'Take advantage of the volatile markets by short selling doomed stocks'
  },
  [PremiumNewsletter.GoSector]: {
    annualReturn: 39.7,
    color: 'hsl(292, 55%, 51%)',
    headerColoredPart: 'Go',
    headerNormalPart: 'Sector',
    iconSrc: goSectorIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/gosector?ref=tr-my-account',
    points: [
      'Weekly Newsletter',
      'Sector Rotation',
      'Passive Investors',
      'Volatility Level: Low'
    ],
    sideImageSrc: goSectorRight,
    subheader:
      'Sector ETF rotation & market strategy based on advanced algorithms'
  },
  [PremiumNewsletter.GuruPortfolio]: {
    annualReturn: 19.6,
    color: 'hsl(32, 61%, 51%)',
    headerColoredPart: 'Guru',
    headerNormalPart: 'Portfolio',
    iconSrc: guruPortfolioIcon,
    learnMoreHref:
      'https://www.smarteranalyst.com/investment-newsletters/guru-portfolio?ref=tr-my-account',
    points: [
      'Quarterly Newsletter',
      'Value Investing',
      'Long-Term Investors',
      'Volatility Level: Low'
    ],
    sideImageSrc: guruPortfolioRight,
    subheader:
      'Quarterly clone portfolio of the top stocks selected by hedge fund managers'
  }
};
