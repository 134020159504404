import * as React from 'react';
import {
  brokers,
  Broker,
  BrokerId
} from '../tr-connect-existing-portfolio/conf';
import { brokers as brokerImgList } from '../tr-connect-existing-portfolio/images';
import * as classNames from 'classnames';

export class ImportLogos extends React.PureComponent<{
  onClickBroker?: (broker: Broker) => void;
  openSyncDialog?: Function;
  className?: string;
}> {
  onClickBroker = ({ id: broker }) =>
    this.props.openSyncDialog && this.props.openSyncDialog({ broker });
  render() {
    const { onClickBroker = this.onClickBroker, className } = this.props;

    return (
      <ul className={classNames('welcome-brokers', className)}>
        {brokers.map(broker => (
          <li key={broker.name}>
            <button>
              <img className="regular" src={brokerImgList[broker.id].regular} />
              <img className="hover" src={brokerImgList[broker.id].hover} />
            </button>
          </li>
        ))}
      </ul>
    );
  }
}
