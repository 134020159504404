// import {getReporter} from '../analytics/report';

// const menuItemNames = {
// 	topListsWrapper: 'top-experts',
// 	toolsMobileHeader: 'mobiletools',
// 	aboutMobile: 'about'
// };

// const events = {
// 	clickedMenu: bool => `clicked-${bool ? '' : 'close-'}menu-mobile`,
// 	clickedMenuItem: id => {
// 		id = menuItemNames[id] || id;
// 		return `clicked-mob-${id}-header`
// 	}
// };

// export default getReporter(events);

export default (...args) => console.log('analytics', args);

export const __hotReload = true;
