export default {
  headerH1: 'How We Find Similar Funds',
  mainP:
    'Instead of investing in ETFs and mutual funds that charge high management fees, move your investments to similar holdings to forego the extra charges. TipRanks finds similar ETFs and mutual funds by identifying overlaps in sectors, geographies, return, and overall investment philosophies. Similar funds we suggest have behaved more than 98% identically to their counterpart.',
  factorsTitle:
    "TipRanks' Fund Similarity is measured by a combination of the following factors:",
  'factorTitle.sector': 'Sector',
  'factorTitle.philosophy': 'Investment Philosophy',
  'factorTitle.correlation': 'Price Behavior Correlation',
  'factorDesc.sector':
    'Investment funds often practice sector investing, or specializing in a specific sector.',
  'factorDesc.philosophy':
    'Funds often abide by a set of guiding principles that shape and inform an investment.',
  'factorDesc.correlation':
    'A serial correlations in returns, volume, and volatility measured over the last 12 months.'
};
