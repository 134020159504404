exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-tr-imported-portfolio-indication-styles__wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.src-browser-tr-imported-portfolio-indication-styles__headline{padding:4px 0 4px 5px;white-space:normal;-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center;word-break:break-word}.src-browser-tr-imported-portfolio-indication-styles__button,.src-browser-tr-imported-portfolio-indication-styles__buttonWrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.src-browser-tr-imported-portfolio-indication-styles__button{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:5px}.src-browser-tr-imported-portfolio-indication-styles__rotate:before{display:inline-block;-webkit-animation:src-browser-tr-imported-portfolio-indication-styles__iRotate 1.5s linear;animation:src-browser-tr-imported-portfolio-indication-styles__iRotate 1.5s linear;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite}.src-browser-tr-imported-portfolio-indication-styles__bold{color:#ff0}", ""]);

// Exports
exports.locals = {
	"wrapper": "src-browser-tr-imported-portfolio-indication-styles__wrapper",
	"headline": "src-browser-tr-imported-portfolio-indication-styles__headline",
	"button": "src-browser-tr-imported-portfolio-indication-styles__button",
	"buttonWrapper": "src-browser-tr-imported-portfolio-indication-styles__buttonWrapper",
	"rotate": "src-browser-tr-imported-portfolio-indication-styles__rotate",
	"iRotate": "src-browser-tr-imported-portfolio-indication-styles__iRotate",
	"bold": "src-browser-tr-imported-portfolio-indication-styles__bold"
};