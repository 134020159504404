import { createSelector } from 'reselect';
import categories from './categories';
import { take } from 'lodash';
import { get } from 'lodash';
import { flatMap } from 'lodash';

export const selectResults = state => state.results;
export const selectCategory = state => state.categoryObject;

const mapToArray = map => {
  const arr = [];
  map.forEach((v, k) => arr.push([k, v]));
  return arr;
};

const getCategoryItemAmounts = category =>
  ({
    ticker: { ticker: 10 },
    analyst: { analyst: 10 },
    blogger: { blogger: 10 },
    insider: { insider: 10 },
    institution: { institution: 10 }
  }[category] || {
    ticker: 6,
    analyst: 3,
    blogger: 3,
    insider: 3,
    institution: 3
  });

export const selectSearchResults = createSelector(
  state => state,
  selectResults,
  selectCategory,
  ({ categoryObject, results }) => {
    if (!results || results.size === 0) {
      return results;
    }

    const categoryItemAmounts = getCategoryItemAmounts(
      get(categoryObject, 'key')
    );

    return new Map(
      mapToArray(results)
        .filter(
          ([key]) =>
            key === categoryObject.key ||
            categoryObject.key === categories.all.key
        )
        .map(([key, items]) => [
          key,
          take(items, categoryItemAmounts[key] || 0)
        ])
    );
  }
);

export const selectSearchResultsArray = createSelector(
  selectSearchResults,
  resultsMap => flatMap([...resultsMap].map(([key, items]) => items))
);
