import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import OverviewStat from './overview-stat.react';

export default class OverviewSection extends Component {
  static propTypes = {
    activePortfolioStats: PropTypes.object,
    changeEditable: PropTypes.func,
    editableId: PropTypes.string,
    isCombinedPortfolio: PropTypes.bool,
    msg: PropTypes.object,
    setCashValue: PropTypes.func,
    stats: PropTypes.object
  };

  render() {
    const {
      activePortfolioStats,
      changeEditable,
      editableId,
      isCombinedPortfolio,
      msg,
      setCashValue,
      stats
    } = this.props;

    return (
      <ul>
        {stats.map(stat => (
          <OverviewStat
            activePortfolio={activePortfolioStats}
            changeEditable={changeEditable}
            editableId={editableId}
            isCombinedPortfolio={isCombinedPortfolio}
            key={stat}
            msg={msg}
            setCashValue={setCashValue}
            stat={stat}
          />
        ))}
      </ul>
    );
  }
}
