import { PlaidFlowConfig, TipRanksPlaidCB, PlaidSuccessConfig } from './PlaidTypes';

export function verifyInitParameters(config: PlaidFlowConfig, trEvents: TipRanksPlaidCB = {}) {
  if (!config || !config.expertOrPortfolioId || !config.type) { 
    if (typeof trEvents.onSyncFailed === 'function') {
      trEvents.onSyncFailed(new Error('Invalid configuration provided'))
    }
    return false;
  } else {
    return true;
  }
}

export function verifySuccessCBParameters(config: PlaidSuccessConfig, events: TipRanksPlaidCB) {
  if (!config || !config.expertOrPortfolioId || !config.type) { 
    if (typeof events.onSyncFailed === 'function') {
      events.onSyncFailed(new Error('Invalid configuration provided'))
    }
    return false;
  } else {
    return true;
  }
}