import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './GraphLegend.istyl';

export type LegendData = {
  display?: string;
  style?: any;
  color?: string;
  label: string;
}[];

export const GraphLegend = (props: {
  data: LegendData;
  className?: string;
}): JSX.Element => {
  return (
    <div className={classNames(styles.legend, props.className)}>
      {props.data.map(datum => {
        const { display = '', style = {} } = datum;

        return (
          <div
            className={styles.legendItem}
            key={datum.label}
            style={{ display }}
          >
            <aside
              style={{
                backgroundColor: datum.color,
                width: 9,
                height: 9,
                display: 'inline-block',
                ...style
              }}
            />
            <span>{datum.label}</span>
          </div>
        );
      })}
    </div>
  );
};
