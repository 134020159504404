import { Repeat } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics';
import { ApiDecorator } from '../ApiDecorator';
import PortfolioCalendar from '../PortfolioCalendar';
import { getState, maybefetch } from '../../common/api-actions';

import PortfolioEvents from '../tr-dashboard-portfolio-events';

import { selectDate } from '../PortfolioCalendar/selectors';

import {
  selectActiveTickersLength,
  selectPortfolioEvents
} from '../dashboard/selectors';

const incrementMonth = (month, amount) => {
  const result = month + amount;
  return result < 12 ? result : result - 12;
};

const byDate = (date, monthsTotal) => item => {
  const itemMonth = new Date(item.get('date')).getMonth();
  let dateMonths = [date.getMonth()];
  Repeat(null, monthsTotal - 1).forEach((item, index) =>
    dateMonths.push(incrementMonth(date.getMonth(), index + 1))
  );
  return dateMonths.includes(itemMonth);
};

@ApiDecorator('portfolios', 'portfolioItems', 'eventsByTickers')
export default class PortfolioCalendarContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    isRoute: PropTypes.bool,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object,
    portfolioCalendar: PropTypes.object
  };

  static defaultProps = {
    isRoute: false
  };

  componentDidMount() {
    const { isRoute } = this.props;
    if (isRoute) report('visited');
  }

  openAddStockDialog = () => {
    const { actions } = this.props;
    actions.openDialog('addStock');
  };

  render() {
    const {
      actions,
      dashboard,
      mediaQuery,
      msg,
      portfolioCalendar
    } = this.props;

    const noData = selectActiveTickersLength(dashboard) === 0;

    const dependencies = [
      getState('portfolioItems', this.props),
      getState('eventsByTickers', this.props)
    ];

    const monthsTotal = mediaQuery.get('laptopXS') ? 2 : 1;

    const date = selectDate(portfolioCalendar);

    const events = selectPortfolioEvents(dashboard);

    const itemsByLimit = date
      ? events.filter(byDate(date, monthsTotal))
      : events;

    return (
      <section className="portfolio-calendar-tab">
        <header>
          <h1>{msg.portfolioCalendar.title}</h1>
        </header>
        <PortfolioCalendar
          calendarMsg={msg.calendar}
          date={date}
          events={itemsByLimit}
          monthsTotal={monthsTotal}
          msg={msg.portfolioCalendar}
          nextMonth={actions.nextCalendar}
          prevMonth={actions.prevCalendar}
        />

        <PortfolioEvents
          dependencies={dependencies}
          events={itemsByLimit}
          mediaQuery={mediaQuery}
          msg={msg.dashboard.portfolioEvents}
          noData={noData}
          noDataMsg={msg.dashboard.holdingsNodata}
          openAddStockDialog={this.openAddStockDialog}
        />
      </section>
    );
  }
}
