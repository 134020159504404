import reporter from '../analytics';

const events = {
  clickedClose: dialogId => `clicked-close-${dialogId}-pop-up`,
  clickedOutside: 'clicked-anywhere-but-dialog'
};

const report = reporter(events);

export default report;
