import {
  StockType,
  RiskTolerance,
  ComponentState,
  StockDetailRecord,
} from '../../types';
import { Record, List } from 'immutable';
import { set } from 'lodash';
import { suits } from 'sp/common/enums';
import { createRecord } from 'sp/common/records';
import { HoldingOperation } from 'sp/common/api-types';
import { BetterRecord } from 'sp/common/immutableStuff';

export type PerformanceField_NotImmutable<Type, Value> = {
  type: Type;
  value: Value;
  state: ComponentState;
};
export const InputField = Record({
  value: null,
  type: '',
  state: suits.INITIAL,
});

export type PerformanceField<Type, Value> = BetterRecord<
  PerformanceField_NotImmutable<Type, Value>
  >;
export interface PerformanceFields_NotImmutable {
  age: PerformanceField<'age', number>;
  profession: PerformanceField<'profession', string>;
  income: PerformanceField<'income', string>;
  term: PerformanceField<'term', string>;
  tolerance: PerformanceField<'tolerance', RiskTolerance>;
}
export type PerformanceFields = BetterRecord<PerformanceFields_NotImmutable>;

export const Fields = Record({
  age: new InputField({ type: 'age' }),
  profession: new InputField({ type: 'profession' }),
  income: new InputField({ type: 'income' }),
  term: new InputField({ type: 'term' }),
  tolerance: new InputField({ type: 'tolerance' }),
  // state: suits.INITIAL,
});

export type PerformanceForm = BetterRecord<{
  state: ComponentState;
  fields: PerformanceFields;
}>;
export const Form = Record({
  state: suits.INITIAL,
  fields: new Fields(),
});

export interface TradeRecord_NotImmutable {
  gainAmount: number;
  gainPercent: number;
  name: string;
  ticker: string;
  from: any;
  to: any;
}
export type TradeRecord = BetterRecord<TradeRecord_NotImmutable>;
export const Trade = Record({
  gainAmount: 0,
  gainPercent: 0,
  name: '',
  ticker: '',
  from: null,
  to: null,
});

export interface PortfolioManagerRecord_NotImmutable {
  rank: number | null;
  stars: number | null;
  dateStartedTrading: string | null;
  portfolioReturn: number | null;
  sharpe: number | null;
  totalRanked: number | null;
  averageSharpe: number | null;
}
export type PortfolioManagerRecord = BetterRecord<
  PortfolioManagerRecord_NotImmutable
  >;

export interface PerfTransactionRecord_NotImmutable {
  executionTime: any;
  closeDate: number;
  gainAmount: number;
  gainPercent: number;
  price: number;
  action: number;
  status: any;
}
export type PerfTransactionRecord = BetterRecord<
  PerfTransactionRecord_NotImmutable
  >;

export const Transaction = Record({
  executionTime: null,
  closeDate: null,
  gainAmount: null,
  gainPercent: null,
  price: null,
  action: null,
  status: null,
});

export interface PriceDetailsRecord_NotImmutable {
  price: number;
  changeAmount: number;
  changePercent: number;
}
export type PriceDetailsRecord = BetterRecord<PriceDetailsRecord_NotImmutable>;

export const PriceDetails = Record({
  price: null,
  changeAmount: null,
  changePercent: null,
});

export interface PerfStock_NotImmutable {
  ticker: string;
  type: StockType;
  stockTypeId: number;
  gainSinceAdded: number;
  isOpen: boolean;
  lastTransaction: string;
  name: string;
  priceDetails: PriceDetailsRecord;
  totalTransactions: number;
  transactions: List<TransactionStock>;
}
// export type PerfStockRecord = BetterRecord<PerfStock_NotImmutable>; // TODO identify when this stock is used, and when DashboardStock is used, and whether they are actually the same.
export type PerfStock = BetterRecord<PerfStock_NotImmutable>; // TODO identify when this stock is used, and when DashboardStock is used, and whether they are actually the same.

export const Stock = Record({
  ticker: '',
  name: '',
  priceDetails: new PriceDetails(),
  transactions: List(),
  totalTransactions: null,
  lastTransaction: null,
  isOpen: null,
});

export interface StockPickerRecord_Obj {
  averageReturn: number | null;
  rank: number | null;
  totalRanked: number | null;
  benchmark: number | null;
  period: number | null;
  goodTransactions: number | null;
  totalTransactions: number | null;
  stars: number | null;
  stocks: List<PerfStock>;
}

export type AssembledPerfStock = PerfStockNotRecord & {
  priceDetails: {
    price: number | null;
    changeAmount: number | null;
    changePercent: number | null;
  };
  gainSinceAdded: number | null;
}

export interface StockPickerNotRecord {
  averageReturn: number | null;
  rank: number | null;
  totalRanked: number | null;
  benchmark: number | null;
  period: number | null;
  goodTransactions: number | null;
  totalTransactions: number | null;
  stars: number | null;
  stocks: AssembledPerfStock[];
}
export interface PerfStockNotRecord {
  ticker: string;
  type?: StockType;
  stockTypeId: number;
  gainSinceAdded: number;
  isOpen: boolean;
  lastTransaction: string;
  name: string;
  totalTransactions: number;
  transactions: BestTrade[];
}[];

export interface BestTrade {
  closeDate?: string;
  displayName: string;
  executionTime: string;
  gainAmount: number;
  gainPercent: number;
  price: number;
  sharesTraded: number;
  stockTypeId?: number;
  ticker: string;
  portfolioID?: number;
  stockType?: number;
  status?: any;
}
export type TransactionStock = BestTrade;
export type BestTradeRecord = BetterRecord<BestTrade>;

export const BestTrade = (data: Partial<BestTrade> = {}) => ({
  ticker: '',
  displayName: '',
  executionTime: -1,
  sharesTraded: -1,
  price: 0,
  gainPercent: 0,
  gainAmount: 0,
  closeDate: '',
  portfolioID: -1,
  stockType: -1,
  stockTypeId: -1, // added for website, has to be here not fuck up immutable on REDUX_LOAD
  status: null,
  ...data,
});

export interface HolidingOperationRecord_NotImmutable {
  ticker: string;
  displayName: string;
  executionTime: string;
  sharesTraded: number;
  price: number;
  action: number;
  rating: number;
  stockTypeId: number;
}
export type HolidingOperationRecord = BetterRecord<{
  ticker: string;
  operations: BetterRecord<HolidingOperationRecord_NotImmutable>;
}>;

export const HolidingOperation = Record({
  ticker: '',
  displayName: '',
  executionTime: null,
  sharesTraded: 0,
  price: 0,
  action: 0,
  rating: 0,
  stockTypeId: 0, // added for website, has to be here not fuck up immutable on REDUX_LOAD03:29 PM
});

export interface StockOperationsRecord_NotImmutable {
  ticker: string;
  operations: List<HolidingOperationRecord>;
}
export type StockOperationsRecord = BetterRecord<
  StockOperationsRecord_NotImmutable
  >;

export const StockOperations = Record({
  ticker: '',
  operations: List(),
});

export const componentState = {
  loading: 'loading',
  success: 'Success',
  error: 'Error',
};

export interface Performance_NotImmutable {
  form: PerformanceForm;
  details: List<StockDetailRecord>;
  creationDate: string;
  name: string;
  portfolioManager: PortfolioManagerRecord_NotImmutable | null;
  stockPicker: StockPickerNotRecord[];
  fields: BetterRecord<PerformanceFields_NotImmutable>;
  holdingOperations: HoldingOperation[];
  everHadHolding: boolean;
  dataState: string; //keyof typeof componentState;
  bestTrade: BestTrade;
}
export type Performance = BetterRecord<Performance_NotImmutable>;

export const InitialState = Record({
  form: new Form() as any,
  details: List(),
  creationDate: '',
  name: '',
  portfolioManager: null,
  stockPicker: [],
  bestTrade: BestTrade(),
  fields: new Fields() as any,
  holdingOperations: [],
  everHadHolding: false,
  dataState: componentState.loading,
} as Performance_NotImmutable);
