import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { imgSrc } from '../../../common/lib/utils.ts';
import { companyConfig } from '../../../common/config';

const renderFetchAgain = fetch =>
  !!fetch ? (
    <div>
      <p className="description">
        There was an issue uploading your data. Please click on the link below
        to reload.
      </p>
      <button onClick={fetch}>Try Again</button>
      <p className="recurring">
        In the event of a recurring issue please &nbsp;
        <a href={`mailto:${companyConfig.get('supportEmail')}`} target="_blank">
          contact us
        </a>
      </p>
    </div>
  ) : (
    <span>Error</span>
  );

export default class ErrorMsg extends Component {
  static propTypes = {
    fetch: PropTypes.func,
    name: PropTypes.string
  };

  render() {
    const { fetch, name = '' } = this.props;
    return (
      <div className={classNames('suit', 'suit-error', `suit-${name}`)}>
        {renderFetchAgain(fetch)}
      </div>
    );
  }
}
