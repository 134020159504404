import { BROWSER_DEVELOPMENT } from 'sp/common/config';

const handler = err => {
  if (BROWSER_DEVELOPMENT) {
    console.error(err);
    console.error(err.stack);
  }
};

const getLocalStorage = storage => ({
  getItem(key) {
    let ret;
    try {
      ret = storage.getItem(key);
    } catch (e) {
      handler(e);
    }
    return ret;
  },
  setItem(key, value) {
    let ret;
    try {
      ret = storage.setItem(key, value);
    } catch (e) {
      handler(e);
    }
    return ret;
  },
  getObject(key) {
    let ret;
    try {
      ret = JSON.parse(storage.getItem(key));
    } catch (e) {
      handler(e);
    }
    return ret;
  },
  setObject(key, value) {
    let ret;
    try {
      ret = storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      handler(e);
    }
    return ret;
  },
  removeItem(key) {
    let ret;
    try {
      ret = storage.removeItem(key);
    } catch (e) {
      handler(e);
    }
    return ret;
  }
});

export default getLocalStorage(localStorage);
