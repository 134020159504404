import * as d3 from 'd3';

const themeColors = {
  web: ['#289dc8', '#b908c4', '#97bb18', '#a6d6e0'],
  nasdaq: ['#03cef9', '#07a9d4', '#144977', '#a6d6e0']
};

const defaultColorsList = themeColors[process.env.THEME];

// const themeSectorColors = {
//   web: [
//     '#e27a0a',
//     '#eba223',
//     '#ebc21d',
//     '#c9c9c9',
//     '#1ad28f'
//   ],
//   nasdaq: [
//     '#16ACD4',
//     '#3C5761',
//     '#C6E6F2',
//     '#4F92AB',
//     '#03CEF8',
//     '#6DCFF6',
//     '#1A4A5C',
//     '#0F7692',
//     '#039FC1',
//     '#CCCCCC'
//   ]
// }
export const makeGraph = ({
  distribution,
  theme,
  element,
  size,
  donutWidth = 13,
  colors = defaultColorsList
}) => {
  const toD3Js = (distribution, transform = x => x) =>
    distribution.every(type => type.percent === 0)
      ? [
          {
            count: 100,
            label: 'none'
          }
        ]
      : distribution
          .map(datum =>
            transform({ ...datum, count: datum.percent * 100, label: datum.id })
          )
          .sort((a, b) => (a.count < b.count ? 1 : -1));

  const data = toD3Js(distribution);
  const width = size;
  const height = size;

  const radius = Math.min(width, height) / 2;

  const sectorColors = index => colors[index] || '#c9c9c9';

  const svg = d3
    .select(element)
    .html('')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const arc = d3.svg
    .arc()
    .innerRadius(radius - donutWidth)
    .outerRadius(radius);

  const pie = (d3.layout.pie().value(d => (d as any).count || 0) as any).sort(
    null
  );

  svg
    .selectAll('path')
    .data((pie as any)(data))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr(
      'fill',
      ({ data }, i) => (data.label === 'none' ? '#848484' : sectorColors(i))
    );
};
