import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import OverviewStatValue from './overview-stat-value.react';

export default class OverviewStat extends Component {
  static propTypes = {
    activePortfolio: PropTypes.object,
    changeEditable: PropTypes.func,
    editableId: PropTypes.string,
    isCombinedPortfolio: PropTypes.bool,
    msg: PropTypes.object,
    setCashValue: PropTypes.func,
    stat: PropTypes.string
  };

  render() {
    const {
      activePortfolio,
      changeEditable,
      editableId,
      isCombinedPortfolio,
      msg,
      setCashValue,
      stat
    } = this.props;

    // if (stat === 'cashValue' && (activePortfolio.get('isImported'))) {
    //   return null;
    // }

    return (
      <li className={stat} key={stat}>
        <div className="label">{msg[stat]}</div>
        <div className="value">
          <OverviewStatValue
            activePortfolio={activePortfolio}
            changeEditable={changeEditable}
            editableId={editableId}
            isCombinedPortfolio={isCombinedPortfolio}
            isImportedPortfolio={activePortfolio.get('isImported')}
            setCashValue={setCashValue}
            stat={stat}
          />
        </div>
      </li>
    );
  }
}
