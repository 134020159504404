import * as React from 'react';
import { selectViewerNextPlan } from '../../common/auth/selectors';
import './PremiumButton.styl';
// TODO extract PremiumButton styles from header.styl / nasdaq style
// ATM, importing just this button without header will result in a non-styled
// component
import { AuthRecord } from '../../common/types';
import { getGoUltimateLink, buildEvents } from '@tipranks/checkout-link';
import { getSpCurrentPageName } from '../tr-payment/index';

// TODO check with oz if this seperation is needed, perhaps smart-portflio would like this aswell.
const custom3 = {
  nasdaq: {
    sidebar: 'premium-leftpanel-btn',
    topbar: 'premium-header-btn'
  },
  web: {}
};
type Custom3 = keyof typeof custom3.nasdaq | keyof typeof custom3.web;

export type UltimateButtonProps = {
  pageName?: string;
  auth: AuthRecord;
  custom3Of: Custom3;
} & React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement>;

export const nasdaqUrl = '/get-smart-portfolio/2/index.html';

export class UltimateButton extends React.PureComponent<UltimateButtonProps> {
  render() {
    const { auth, custom3Of, onMouseEnter, onMouseLeave, pageName } = this.props;
    const theme = process.env.THEME;

    if (theme === 'nasdaq') { return null; }

    const custom3Value = 'goulti-btn';

    return (
      <div className="goUltimate">
        <TipranksGoUltimateLink className="goUltimateLink" target="_self" featureOrOrigin={custom3Value} pageName={pageName}>
          Go Ultimate
        </TipranksGoUltimateLink>
      </div>
    );
  }
}

export function TipranksGoUltimateLink(props) {
  const { featureOrOrigin, queryParameters, pageName = getSpCurrentPageName('sp-go-pro-something-went-wrong'), ...restOfProps } = props
  const prefix = 'sp-';
  const prefixedFeatureOrOrigin = 
    String(featureOrOrigin).startsWith('sp-')
    ? featureOrOrigin
    : prefix + featureOrOrigin;


  const events
    = buildEvents(
      pageName,
      prefixedFeatureOrOrigin,
      { ...props },
    );

  
  return (
    <a
      {...restOfProps}
      {...events}
      href={getGoUltimateLink(prefixedFeatureOrOrigin, queryParameters)}
    />
  )
}

export function shouldShowUltimateButton(auth: AuthRecord) {
  return selectViewerNextPlan(auth) === 'ultimate';
}
