import reporter from '../analytics';

const events = {
  visited: `visited-smart-portfolio-holdings-page`,
  clickedImportPortfolio: 'clicked-import-portfolio-warning-bar'
};

const report = reporter(events);

export default report;
