import React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import getImage from './images';
import Component from 'react-pure-render/component';

class CategoryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false, didChange: false };
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
    this.props.onOpen && this.props.onOpen();
  };

  onWindowClick = () => {
    this.setState({ menuOpen: false });
  };

  onChange(cat) {
    this.props.onChange(cat);
    this.setState({ menuOpen: false, didChange: true });
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillMount() {
    this.setCategory();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  componentWillReceiveProps(nextProps) {
    this.setCategory(nextProps);
  }

  setCategory({ categories, selectedCategory, defaultCategory } = this.props) {
    const selectedCategoryObject =
      typeof selectedCategory === 'string'
        ? categories.find(cat => cat.key === selectedCategory)
        : selectedCategory;

    this.setState({ selectedCategoryObject });
  }

  renderButton(
    { selectedCategoryObject, didChange } = this.state,
    { msg } = this.props
  ) {
    const icon = get(msg, [selectedCategoryObject.key, 'icon2']);

    const props = didChange ? { className: 'buttonIcon' } : {};

    return (
      <button onClick={this.toggleMenu} {...props}>
        {didChange
          ? getImage(icon, 'img')
          : getImage('hamburger', 'svg', {
              color: '#fff'
            })}
        <div className="arrow" />
      </button>
    );
  }

  render(
    { categories, onChange, msg = {}, className } = this.props,
    { menuOpen, didChange, selectedCategoryObject } = this.state
  ) {
    return (
      <div className={`wrapper ${className} category-menu`}>
        {this.renderButton()}
        {menuOpen && (
          <ul>
            {categories.map((cat, i) => {
              const { label = cat.key, icon2 } = msg[cat.key] || {};
              const selected = selectedCategoryObject === cat;
              return (
                <li
                  key={i}
                  onClick={() => this.onChange(cat)}
                  className={classnames({ selected })}
                >
                  {getImage(icon2, 'img')}
                  {label}
                  {selected && (
                    <span className="checkmark">
                      {getImage('checkmark-green', 'img')}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default CategoryMenu;

export const __hotReload = true;
