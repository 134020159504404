import { Map } from 'immutable';

export const screenAndMinWidth = size => {
  if (typeof window === 'undefined') return false;
  const matchMedia = window.matchMedia(
    `screen and (min-width: ${size * 16}px)`,
  );
  return matchMedia.matches;
};
// le sane version
export const pixelScreenAndMinWidth = size => {
  if (typeof window === 'undefined') return false;
  const matchMedia = window.matchMedia(`screen and (min-width: ${size}px)`);
  return matchMedia.matches;
};

export const mediaQueryKeyOrder: MediaQueryKey[] = [
  'mobileS',
  'mobile360',
  'mobileM',
  'mobileL',
  'mobileXL',
  'mobile568',
  'mobileXXL',
  'laptopXXS',
  'tablet',
  'laptop',
  'laptopXS',
  'laptopS',
  'laptopM',
  'laptopL',
  'desktop1600',
  'desktop',
  '4k',
];

export const getMediaQueryObj = () => ({
  // use these from now on, and if you don't have one, simply add it
  '320': pixelScreenAndMinWidth(320),
  '360': pixelScreenAndMinWidth(360),
  '768': pixelScreenAndMinWidth(768),
  '1024': pixelScreenAndMinWidth(1024),
  '1366': pixelScreenAndMinWidth(1366),
  '1600': pixelScreenAndMinWidth(1600),
  '1920': pixelScreenAndMinWidth(1920),
  // LEGACY.
  mobileS: screenAndMinWidth(20), // equals 320px, but don't fucking use it.
  mobile360: screenAndMinWidth(22.5), // equals 360px, but don't fucking use it.
  mobileM: screenAndMinWidth(23.4375), // equals 375px, but don't fucking use it.
  mobileL: screenAndMinWidth(26.5625), // equals 425px, but don't fucking use it.
  mobileXL: screenAndMinWidth(34), // equals 544px, but don't fucking use it.
  mobile568: screenAndMinWidth(35.5), // equals 568px, but don't fucking use it.
  mobileXXL: screenAndMinWidth(42), // equals 672px, but don't fucking use it.
  laptopXXS: screenAndMinWidth(45), // equals 720px, but don't fucking use it.
  tablet: screenAndMinWidth(48), // equals 768px, but don't fucking use it.
  laptop: screenAndMinWidth(64), // equals 1024px, but don't fucking use it.
  laptopXS: screenAndMinWidth(75), // equals 1200px, but don't fucking use it.
  laptopS: screenAndMinWidth(81.25), // equals 1300px, but don't fcucking use it.
  laptopM: screenAndMinWidth(85.375), // equals 1366px, but don't fucking use it.
  laptopL: screenAndMinWidth(90), // equals 1440px, but don't fucking use it.
  desktop1600: screenAndMinWidth(100), // equals 1600px, but don't fucking use it.
  desktop: screenAndMinWidth(110), // equals 1760px, but don't fucking use it.
  '4k': screenAndMinWidth(160), // equals 2560px, but don't fcucking use it.
});

export type MediaQuery = Map<MediaQueryKey, boolean>;
const onlyToGetTyping = getMediaQueryObj();
export type MediaQueryKey = keyof typeof onlyToGetTyping;
export const mediaQuery = () => Map(getMediaQueryObj()) as MediaQuery;
export const getMediaQuery = mediaQuery;