import { List, Map, fromJS } from 'immutable';
import { GET_STOCKS_SUCCESS, GET_STOCKS_START } from './actions.ts';

// immutable js doesnt deepEquals structs that are not
// from immutablejs, so we adapt the structures and then check
function fieldsEquals(m1, m2) {
  const adapt = val => fromJS(val);
  return m1.map(adapt).equals(m2.map(adapt));
}

const actionHandlers = {
  // make sure we catch only our own screener actions and not act
  // on the other screener's actions.
  [GET_STOCKS_START]: (state, { page, subtype } = {}) =>
    subtype !== 'etf'
      ? state
      : {
          ...state,
          loadingTable: page === 1,
          loadingMoreItems: page !== state.page
        },
  [GET_STOCKS_SUCCESS]: (state, { res, fields, page, subtype } = {}) =>
    subtype !== 'etf'
      ? state
      : {
          ...state,
          fields: fields || Map(),
          tickers: fieldsEquals(fields, state.fields)
            ? state.tickers
                .concat(res.stocks.map(stock => stock.get('ticker')))
                .toOrderedSet()
                .toList()
            : res.stocks.map(stock => stock.get('ticker')),
          page: fields.equals(state.fields) ? page : 1,
          loadingTable: false,
          loadingMoreItems: false
        }
};

export const initialState = {
  tickers: List(),
  fields: Map(),
  page: 1,
  loadingTable: false,
  loadingMoreItems: false
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
