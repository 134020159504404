import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import Component from 'react-pure-render/component';
import { Link } from 'react-router';

import { makeGraph } from './assets/js/pie';
import getBlock from '../BetaColors/BetaColors';
import { portfolioItemUrl } from '../decisions/index.ts';

import PortfolioItemSelector from '../PortfolioItemSelector/PortfolioItemSelector.react';

import CompanyName from './company.tsx';

// import Header from './header.tsx';
import Header from './PortfolioSelector.tsx';
import { CrowdInsightsMain } from './CrowdInsightsMain.tsx';
import { CrowdInsightsAside } from './aside.tsx';
import { promote } from '../TargetedWebPage/actions.ts';
import { trIconGr, headIconGr } from './utils.ts';
import '../tr-dashboard-avg-portfolio/assets/styles/lt2bstyle.styl';

export class TrDashboardAvgPortfolio extends Component {
  // static propTypes = {
  //   aboveBeta: PropTypes.string,
  //   addStocks: PropTypes.func,
  //   apiState: PropTypes.string,
  //   betaValue: PropTypes.number,
  //   companyBetaValue: PropTypes.number,
  //   companyFunds: PropTypes.object,
  //   companyMajorHoldings: PropTypes.object,
  //   companyPortfolioItemsDistribution: PropTypes.object,
  //   companyPortfolioStocksDistribution: PropTypes.object,
  //   companyPortfolioYield: PropTypes.number,
  //   companyTopDividendsStocks: PropTypes.object,
  //   companyVolatileStocks: PropTypes.object,
  //   enums: PropTypes.object,
  //   hasAssetAllocation: PropTypes.bool,
  //   hasItems: PropTypes.bool,
  //   investmentFees: PropTypes.object,
  //   majorHoldings: PropTypes.object,
  //   majorHoldingsSector: PropTypes.string,
  //   majorHoldingsType: PropTypes.string,
  //   mediaQuery: PropTypes.object,
  //   numPortfolios: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   onMajorHoldingsSectorChange: PropTypes.func,
  //   onMajorHoldingsTypeChange: PropTypes.func,
  //   portfolioItemsDistribution: PropTypes.object,
  //   portfolioStocksDistribution: PropTypes.object,
  //   portfolioYield: PropTypes.number,
  //   theme: PropTypes.string,
  //   topDividendsStocks: PropTypes.object,
  //   volatileStocks: PropTypes.object
  // };

  componentDidMount() {
    const {
      companyPortfolioItemsDistribution,
      companyPortfolioStocksDistribution,
      mediaQuery,
      portfolioItemsDistribution,
      portfolioStocksDistribution,
      theme
    } = this.props;

    makeGraph({
      companyPortfolioItemsDistribution,
      companyPortfolioStocksDistribution,
      mediaQuery,
      portfolioItemsDistribution,
      portfolioStocksDistribution,
      theme,
      size: 110
    });
  }

  componentDidUpdate(prevProps) {
    const {
      companyPortfolioItemsDistribution,
      companyPortfolioStocksDistribution,
      mediaQuery,
      portfolioItemsDistribution,
      portfolioStocksDistribution,
      theme
    } = this.props;

    const {
      portfolioCompanyItemsDistribution: prevCompanyPortfolioItemsDistribution,
      portfolioCompanyStocksDistribution: prevCompanyPortfolioStocksDistribution,
      portfolioItemsDistribution: prevPortfolioItemsDistribution,
      portfolioStocksDistribution: prevPortfolioStocksDistribution
    } = prevProps;

    if (
      portfolioItemsDistribution !== prevPortfolioItemsDistribution ||
      companyPortfolioItemsDistribution !==
      prevCompanyPortfolioItemsDistribution ||
      portfolioStocksDistribution !== prevPortfolioStocksDistribution ||
      companyPortfolioStocksDistribution !==
      prevCompanyPortfolioStocksDistribution
    ) {
      makeGraph({
        companyPortfolioItemsDistribution,
        companyPortfolioStocksDistribution,
        mediaQuery,
        portfolioItemsDistribution,
        portfolioStocksDistribution,
        theme
      });
    }
  }

  companyName = ({ icon } = { icon: false }) => {
    const { theme } = this.props;
    return <CompanyName icon={icon} theme={theme} />;
  };

  render() {
    const {
      actions,
      aboveBeta,
      addStocks,
      auth,
      apiState,
      betaValue,
      companyBetaValue,
      companyFunds,
      companyMajorHoldings,
      companyPortfolioItemsDistribution,
      companyPortfolioStocksDistribution,
      companyPortfolioYield,
      companyTopDividendsStocks,
      companyVolatileStocks,
      enums,
      hasAssetAllocation,
      hasItems,
      investmentFees,
      majorHoldings,
      majorHoldingsSector,
      majorHoldingsType,
      mediaQuery,
      numPortfolios,
      onMajorHoldingsSectorChange,
      onMajorHoldingsTypeChange,
      portfolioItemsDistribution,
      portfolioStocksDistribution,
      portfolioYield,
      topDividendsStocks,
      volatileStocks,
      theme,
      selectedPortfolio
    } = this.props;

    const sectors = Object.keys(enums.sectors);

    const isLoading = apiState === 'LOADING';

    const majorHoldingsButStocks = majorHoldings
      .filter(item => item.getIn(['stock', 'type']) !== 'stock')
      .take(5);

    return (
      <div
        className={classNames('tr-dashboard-avg-portfolio', { loading: true })}
      >
        <div id="lt2b-plugin">
          <Header
            numPortfolios={numPortfolios}
            company={this.companyName()}
            auth={auth}
            changeSelection={actions.changeSelectedPortfolio}
            restrictedChange={() =>
              promote({ plan: 'premium', type: 'crowd-insights' })
            }
            selectedPortfolio={selectedPortfolio}
            theme={theme}
          />
          <CrowdInsightsMain
            {...{
              addStocks,
              companyFunds,
              companyMajorHoldings,
              companyPortfolioItemsDistribution,
              companyPortfolioStocksDistribution,
              enums,
              hasAssetAllocation,
              hasItems,
              headIconGr,
              investmentFees,
              majorHoldings,
              majorHoldingsButStocks,
              majorHoldingsSector,
              majorHoldingsType,
              mediaQuery,
              onMajorHoldingsSectorChange,
              onMajorHoldingsTypeChange,
              portfolioItemUrl,
              portfolioItemsDistribution,
              portfolioStocksDistribution,
              sectors,
              theme,
              trIconGr,
              selectedPortfolio
            }}
          />
          <CrowdInsightsAside
            {...{
              aboveBeta,
              betaValue,
              companyBetaValue,
              companyPortfolioYield,
              companyTopDividendsStocks,
              companyVolatileStocks,
              getBlock,
              headIconGr,
              portfolioYield,
              theme,
              topDividendsStocks,
              volatileStocks,
              selectedPortfolio
            }}
          />
        </div>
      </div>
    );
  }
}
export default TrDashboardAvgPortfolio;
