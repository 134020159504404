import * as classNames from 'classnames';
import * as React from 'react';

import { PerformanceDropdown } from '../dropdown';
import {
  EMPTY_OPT,
  MY_PORTFOLIO_OPT,
  NONE,
  Option
} from '../dropdown/PerformanceDropdown';
import * as styles from './PerformanceDropdownTrio.istyl';

export interface Props {
  options: Option[];
  onSelectCancellable: (index: number, value: Option) => boolean;
  className?: string;
}
export interface State {
  selectedOption0?: Option;
  selectedOption1?: Option;
  options: Option[];
}

export class PerformanceDropdownTrio extends React.PureComponent<Props, State> {
  state: State = { options: [] };

  constructor(props) {
    super(props);

    const options = (this.props.options || [undefined, undefined]) as Option[];
    this.state = {
      options,
      selectedOption0: options[0],
      selectedOption1: options[1]
    };
  }

  render() {
    const { className } = this.props;
    let {
      options: optionsRaw,
      selectedOption0 = EMPTY_OPT,
      selectedOption1 = EMPTY_OPT
    } = this.state;

    const isDisabled = (selectedOption, option) =>
      selectedOption.text !== NONE.text && selectedOption.text === option.text;

    return (
      <div className={classNames(styles.performanceDropdownTrio, className)}>
        <PerformanceDropdown
          readOnly
          value={MY_PORTFOLIO_OPT}
          className={classNames(styles.dropdown)}
        />
        <PerformanceDropdown
          value={selectedOption0}
          options={optionsRaw.map(option => ({
            ...option,
            isDisabled: isDisabled(selectedOption1, option)
          }))}
          className={classNames(styles.dropdown)}
          onSelectCancellable={selectedOption0 =>
            this.onSelect(0, selectedOption0)
          }
        />
        <PerformanceDropdown
          value={selectedOption1}
          options={optionsRaw.map(option => ({
            ...option,
            isDisabled: isDisabled(selectedOption0, option)
          }))}
          className={classNames(styles.dropdown)}
          onSelectCancellable={selectedOption1 =>
            this.onSelect(1, selectedOption1)
          }
        />
      </div>
    );
  }
  onSelect = (index, value) => {
    const state = {
      selectedOption0: this.state.selectedOption0,
      selectedOption1: this.state.selectedOption1
      // TODO convert this infrastracture to be array based
    };
    state[`selectedOption${index}`] = value;
    this.setState(state);
    if (this.props.onSelectCancellable) {
      return this.props.onSelectCancellable(index, value);
    }
    return true;
  };
  getSelectedOptions = () => [
    this.state.selectedOption0,
    this.state.selectedOption1
  ];
}
