import * as React from 'react';
import { ReduxProps } from '../../common/types';
import { Map } from 'immutable';
import * as classNames from 'classnames';
import { isShittyBrowser } from 'sp/browser/lib/utils';
import {
  filters,
  maxShowMore,
  getRawCols,
  getDetailGroups,
  links
} from './conf';
import report from './analytics';

import { getCols } from '../tr-dashboard-holdings-table/conf';
import { exportToPortfolioCSV } from '../tr-portfolio-csv/index';
import ExternalRegister from '../tr-external-auth/signup-form.react';
import Filters from '../tr-stock-screener-container/filters';
import { VariableSizeSpinner } from '../Spinner/index.react';
import { HoldingsTableContainer } from '../tr-stock-screener-container/table.container';
import RelatedLinks from '../RelatedLinks/index';

import { dashboardPath } from '../../common/config';
import { isIOS } from '../../common/lib/utils';
import {
  selectIsPermanentLoggedIn,
  selectViewerPlan
} from '../../common/auth/selectors';
import {
  selectScreenerStocksTotal,
  selectScreenerStocks
} from '../tr-stock-screener-container/selectors';
import { ApiDecorator } from 'sp/browser/ApiDecorator/ApiDecorator.react';
import { history } from 'sp/browser/history';

// TODO figure out where history is coming from, annotate
type Props = ReduxProps & { history: any };

@ApiDecorator('details')
export default class ETFScreenerContainer extends React.PureComponent<
Props,
{ fields: Map<string, string[]>; page: number }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: Map<string, string[]>(
        filters.map(({ apiLabel }) => [apiLabel, []])
      ),
      page: 1
    };
  }

  private onAuthenticatedDialog = () => {
    const { actions } = this.props;

    actions.identifyUser().then(() => {
      actions.closeDialog();
      history.push(`/${dashboardPath}/screener/etfs`);
      actions.fetchPortfolios();
    });
  };

  private changeFilter = (label: string, value: string[]) => {
    this.setState({
      fields: this.state.fields && this.state.fields.set(label, value)
    });
  };

  private handleShowMore = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { actions } = this.props;
    e.preventDefault();
    report('clickedShowMore');
    this.setState({ page: this.state.page + 1 }, () =>
      actions.changeETFScreenerFilters(this.state)
    );
  };

  private onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    const { actions } = this.props;
    event.preventDefault();
    report('clickedSearch');

    this.setState({ page: 1 }, () =>
      actions.changeETFScreenerFilters(this.state)
    );
  };

  private exportToCSV = () => {
    const {
      // clickedExportCSV,
      mediaQuery,
      msg,
      auth
    } = this.props;

    // clickedExportCSV();

    const cols = getCols(getRawCols)(mediaQuery).map(item => item.get('id'));
    const items = selectScreenerStocks(this.props, 'etfScreener');

    exportToPortfolioCSV({
      cashValue: 0,
      enums: msg['enums'],
      fileName: 'screener.csv',
      holdingsCols: cols,
      items,
      viewerPlan: selectViewerPlan(auth),
      limit: {
        open: 20,
        free: 20,
        premium: 100,
        ultimate: 100
      }
    });

    // TODO: ADD THE BUTTON
  };

  get hasFields() {
    return (
      typeof this.state.fields !== 'undefined' &&
      this.state.fields.some(value => !!value && value.length > 0) &&
      !this.state.fields.equals(this.props.etfScreener.fields)
    );
  }

  get hasMoreData() {
    return (
      this.state.page < maxShowMore &&
      this.state.page &&
      selectScreenerStocks(this.props, 'etfScreener').size > 0 &&
      this.state.page * 20 < selectScreenerStocksTotal(this.props)
    );
  }

  public render() {
    const { actions, auth, mediaQuery, etfScreener } = this.props;

    const isPermanentLoggedIn = selectIsPermanentLoggedIn();
    const resultsTotal = selectScreenerStocksTotal(this.props);
    const items = selectScreenerStocks(this.props, 'etfScreener');
    const relatedLinks = process.env.THEME !== 'nasdaq' && (
      <div className="relatedLinks">
        <RelatedLinks links={links} />
      </div>
    );

    if (process.env.THEME !== 'nasdaq' && !isPermanentLoggedIn) {
      return (
        <div className="tr-external-login-wrapper">
          <ExternalRegister
            onAuthenticated={this.onAuthenticatedDialog}
            type={'screener'}
          />
        </div>
      );
    }

    return (
      <main className="stockScreenerMain">
        <div className="filters">
          <h2>Filters</h2>
          <Filters
            filters={filters}
            auth={auth}
            openDialog={actions.openDialog}
            changeFilter={this.changeFilter}
            hasFields={this.hasFields}
            onSubmit={this.onSubmit}
            screener={etfScreener}
            selectedFields={this.state.fields as Map<string, string[]>}
            stockType="etfScreener"
            report={report}
          />
          {mediaQuery.get('laptopL') && relatedLinks}
        </div>
        <div className="table">
          <div className="resultsHeader">
            <h2>Results</h2>
            {selectScreenerStocks(this.props, 'etfScreener').size > 0 && (
              <div className="results-summary">
                Showing{' '}
                <span className="results-number">
                  {etfScreener.tickers.size}
                </span>{' '}
                of <span className="results-total">{resultsTotal}</span> Results
              </div>
            )}
          </div>
          <HoldingsTableContainer
            {...this.props}
            screener={etfScreener}
            stockType="etfScreener"
            currentPage={this.state.page || 1}
            getRawCols={getRawCols}
            getDetailGroups={getDetailGroups}
            report={report}
          />
          {(this.hasMoreData || etfScreener.loadingMoreItems) && (
            <button
              className={classNames('showMore', {
                loading: etfScreener.loadingMoreItems
              })}
              onClick={this.handleShowMore}
            >
              <span className="text">Show More</span>
              <VariableSizeSpinner size={30} className="spinner" />
            </button>
          )}
          <div className="controls-below-table">
            {items.size > 0 &&
              !isIOS() && (
                <button
                  disabled={
                    !items
                      .get(0)
                      .get('stock')
                      .get('price')
                      .get('amount')
                  }
                  className="csv"
                  onClick={this.exportToCSV}
                >
                  Download
                </button>
              )}
          </div>
          {!mediaQuery.get('laptopL') && relatedLinks}
        </div>
      </main>
    );
  }
}
