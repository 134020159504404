import { BrokerId } from '../tr-connect-existing-portfolio/conf';
import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './style.istyl';
import '../tr-connect-existing-portfolio/index.styl';

import TrConnectExistingPortfolio from '../tr-connect-existing-portfolio/index.react';

type SynchronizeExistingPortfolioProps = {
  onLoading: () => void;
  onLoadingDone: () => void;
  onSuccess: (brokerId: BrokerId) => Promise<void>;
};

export class SynchronizeExistingPortfolio extends React.PureComponent<
  SynchronizeExistingPortfolioProps,
  {}
> {
  public render() {
    const { onLoading, onSuccess, onLoadingDone } = this.props;
    return (
      <section className={styles.synchronizeExistingPortfolio}>
        <h1>Synchronize your existing portfolio</h1>
        <article>
          <div>
            <h4>Display and Analyze Your Existing Portfolio</h4>
            <ul className={classNames(styles.coloredBullets, styles.orange)}>
              <li>
                <span>Compare your portfolio performance to others'</span>
              </li>
              <li>
                <span>
                  Get the top-ranked analysts' and bloggers' opinions on your
                  holdings
                </span>
              </li>
              <li>
                <span>
                  Learn how to optimize your portfolio and minimize risks
                </span>
              </li>
              <li>
                <span>
                  Receive alerts regarding important stock activity in your
                  portfolio
                </span>
              </li>
            </ul>
          </div>

          <div>
            <h4>Choose Broker:</h4>
            <TrConnectExistingPortfolio
              mainClassName={styles.brokers}
              isLoggedIn
              makeTemporaryUser
              onLoading={onLoading}
              onSuccess={onSuccess}
              onLoadingDone={onLoadingDone}
              clean
            />
          </div>
        </article>
      </section>
    );
  }
}
