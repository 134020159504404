import { groupBy } from 'lodash';

const kebabCase = str =>
  str
    .trim()
    .replace(/ /g, '-')
    .toLowerCase();

const buildUrl = (category, item) => {
  const view = {
    ticker: 'stocks',
    insider: 'insiders',
    institution: 'hedge-funds',
    etf: 'etf',
    analyst: 'analysts',
    blogger: 'bloggers'
  }[category];
  return `/${view}/${kebabCase(item)}`;
};

export const adaptDataList = ({ query, category, data }) => {
  const list = data
    // .filter(option =>
    // 	!bowser.msie || bowser.version > 9 || option.category !== 'etf')
    .map(option => {
      const opt =
        option.category === 'ticker' || option.category === 'etf'
          ? option.label
          : option.value;
      const url = buildUrl(option.category, option.value);
      return {
        option: opt,
        category: option.category === 'etf' ? 'ticker' : option.category,
        url,
        value: option.value,
        label: option.label,
        uid: option.uid
      };
    });

  const grouped = groupBy(list, 'category');
  const results = new Map(
    ['ticker', 'analyst', 'blogger', 'insider', 'institution'].map(cat => [
      cat,
      grouped[cat]
    ])
  );
  return { query, category, list, results };
};
