import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { companyConstants } from '../../../common/config';

import { defaultMsg } from './msg';
import PortfolioSelectOption from './portfolio-select-option.react';

const portfolioChanged = onChange => ({ target }) =>
  onChange(parseInt(target.value, 10));

const portfolioName = (portfolio, msg) =>
  portfolio.get('type') === 'combinedUserPortfolio'
    ? msg[portfolio.get('type')]
    : portfolio.get('name');

export default class PortfolioSelect extends Component {
  static propTypes = {
    activePortfolioId: PropTypes.number,
    hideAllPortfolios: PropTypes.bool,
    hideEditPortfolio: PropTypes.bool,
    //msg: PropTypes.shape(msgShape),
    onChange: PropTypes.func,
    portfolios: PropTypes.object
  };

  static defaultProps = {
    hideAllPortfolios: false,
    hideEditPortfolio: false,
    msg: defaultMsg
  };

  filterPortfolios = portfolio => {
    const { hideAllPortfolios } = this.props;

    if (portfolio.get('id') === companyConstants.get('bestPortfolioId')) {
      return false;
    }
    return (
      portfolio.get('name') !== 'combinedUserPortfolio' || !hideAllPortfolios
    );
  };

  render() {
    const {
      activePortfolioId,
      hideEditPortfolio,
      msg,
      onChange,
      portfolios
    } = this.props;

    return (
      <select
        className={classNames('portfolio', { hideEditPortfolio })}
        onChange={portfolioChanged(onChange)}
        style={{
          fontFamily: 'arial'
        }}
        value={activePortfolioId}
      >
        {portfolios
          .filter(this.filterPortfolios)
          .map(portfolio => (
            <PortfolioSelectOption
              key={portfolio.get('id')}
              portfolioId={portfolio.get('id')}
              portfolioName={portfolioName(portfolio, msg)}
            />
          ))}
        {!hideEditPortfolio && (
          <PortfolioSelectOption
            key={-3}
            portfolioId={-3}
            portfolioName={msg.edit}
          />
        )}
      </select>
    );
  }
}
