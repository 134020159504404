import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import * as cells from './cells';
import RestrictedButton from '../RestrictedButton';
import Loader from '../components/suit/Loader.react.tsx';
import { holdingsSectionConfig } from '../../common/config';

export default class TableBodyCell extends Component {
  static propTypes = {
    mediaQuery: PropTypes.object,
    dependencies: PropTypes.object,
    enums: PropTypes.object,
    field: PropTypes.string,
    isRestricted: PropTypes.bool,
    showPromotion: PropTypes.func
  };

  render() {
    const {
      dependencies,
      enums,
      field,
      showPromotion,
      isRestricted
    } = this.props;

    const CellContent = cells[field] ? cells[field] : cells.defaultCell;

    const isStockLoading =
      holdingsSectionConfig.get('stockData').indexOf(field) > -1 &&
      dependencies.get('stocks') === 'LOADING';

    const isDetailsLoading =
      holdingsSectionConfig.get('detailsData').indexOf(field) > -1 &&
      dependencies.get('details') === 'LOADING';

    return (
      <div
        className={classNames('table-body-cell', field, {
          restrictred: isRestricted
        })}
      >
        {isStockLoading || isDetailsLoading ? (
          <div className="td-inner td-inner-loader">
            <Loader name="holdingsCell" type="loaderStatic" />
          </div>
        ) : (
          <div className={classNames('td-inner')}>
            <CellContent {...this.props} />
          </div>
        )}
      </div>
    );
  }
}
