import React from 'react';
import { portfolioItemUrl } from '../../../../decisions/index.ts';
import { getTickerUrl } from 'sp/common/lib/utils';

const pathsMap = {
  fund: '/funds',
  stock: '/stocks',
  etf: '/etf',
};

const holdingTypePath = type => pathsMap[type] || type;
// TODO add to target what the compiler wants
export const TickerLink = ({ holding, ...props }) => {
  const href = getTickerUrl(holding);
  const hasLink = !!href;
  const tickerText = holding.ticker.toUpperCase();
  if (hasLink) {
    return (
      <a href={href} target="_blank">
        {tickerText}
      </a>
    );
  } else {
    return <span>{tickerText}</span>;
  }
};
export default TickerLink;
