import Clickable from 'tipranks-clickable';
import * as React from 'react';
import PropTypes from 'prop-types';
import './menu-items.styl';
import * as classNames from 'classnames';
import { svgContent } from './svg-collection';
import { chunk } from 'lodash';
import { TipRanksLink } from './links';
import { Icon } from 'tipranks-icons';

interface HeaderMenuProps {
  items: TipRanksLink[];
  level?: number;
}

function HeaderLink({ text, to = '', href, tag, icon = 'circleQuestionMark', items }: TipRanksLink) {
  return (
    <div
      className={classNames(
        'header-item-text',
        tag ? `tag tag-${tag}` : '',
        { hasItems: Boolean(items) }
      )}
    >
      {(to || href)
        ? (
          <Clickable
            className={'link'}
            to={to}
            href={href}
            type={to ? 'to' : 'href'}
          >
            <Icon className={'mainIcon'} icon={icon} />
            {text}
          </Clickable>
        )
        : (
          <div className="link">
            <Icon className={'mainIcon'} icon={icon} />
            {text}
          </div>
        )
      }
    </div>
  );
}

export default class HeaderMenu extends React.PureComponent<HeaderMenuProps> {
  static defaultProps = {
    level: 0,
  }
  isActive(id: string) {
    return id === 'smart-portfolio';
  }

  organizeItems(items: TipRanksLink[]) {
    return chunk(items, 2);
  }

  render() {
    const { items: mainItems } = this.props

    return (
      <div className={`header-items header-items-level-0`}>
        <ul>
          {mainItems.map((mainItem) => {
            const { items, id, className } = mainItem;
            const organizedItems = items && this.organizeItems(items);
            const hasItems = Boolean(items && items.length);
            return (
              <li
                className={classNames(id, {
                  'is-active': this.isActive(id)
                })}
                key={id}
              >
                <HeaderLink {...mainItem} />
                {hasItems && (
                  <div className="header-items header-items-level-1">
                    {
                      organizedItems!.map(itemGroup => (
                        <ul key={itemGroup.map((item) => item.id).join(',')}>
                          {
                            itemGroup.map((props) => (
                              <MenuItem
                                key={props.id}
                                {...props}
                                level={2}
                              />
                            ))
                          }
                        </ul>
                      ))
                    }
                  </div>
                  )
                }
              </li>
            );
          }
          )}
        </ul>
      </div>
    );
  }
}

interface NestedLink extends TipRanksLink {
  level: number;
}

const MenuItem = ({
  text,
  items,
  href,
  to,
  tag,
  id,
  className,
  level,
  icon,
  iconSize,
  listArrows = false,
} = {} as NestedLink) => {
  const key = items && level <= 2 ? 'hasItems' : '';
  return (<li className={classNames(id, { hasIcon: Boolean(icon) })}>
    {icon && <Icon icon={icon} size={iconSize} />}
    {text
      ? (
        <div className={classNames(`header-item-text`, key, { [`tag tag-${tag}`]: !!tag })}>
          <Clickable
            className={`link text`}
            type={!href && !to ? 'div' : null}
            href={href}
            to={to}>
            {text}
          </Clickable>
        </div>
      )
      : <div style={{ marginBottom: '-10px' }} />}
    {items &&
      <div className={classNames('header-items', `header-items-level-${level}`, { arrows: listArrows })}>
        <ul className={classNames(`list`, `level-${level}`)}>
          {items.map((props, i) => <MenuItem key={i} {...props} className={className} level={level + 1} />)}
        </ul>
      </div>
    }
  </li>);
};


export const __hotReload = true;
