import * as React from 'react';
import * as classNames from 'classnames';
import * as _ from 'lodash';
import { findDOMNode } from 'react-dom';
import './PerformanceDropdown.styl';
import * as helperStyles from '../../../common/styles/common.istyl';
import { brandName } from 'sp/common/config';

export interface State {
  isOpen: boolean;
  selectedOption: Option;
}

export type Option = {
  style?: any; //React.CSSProperties;
  name: string;
  text: any; //React.ReactNode;
  shortText?: any; //React.ReactNode;
  isDisabled?: boolean;
  // TODO how can we annotate this?
  value?: any;
  color?: string;
};

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  options?: Option[];
  value?: Option;
  onPerfSelect?: (option: Option) => any;
  onSelectCancellable?: (option: Option) => boolean;
  passedState?: Partial<State>; // for storybook outside contorl
  readOnly?: boolean;
}

const noneBackgroundPattern =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAKElEQVQoU2NkgID/DAwMjFA2Ch8kiFMSpBJZF1aTsBqLbC1MwUC6AQAtJBAHAUlIggAAAABJRU5ErkJggg=='; // http://bit.ly/1gUWIDd

const disabledBackgroundColor = '#ebebeb';
const colors = require(`../common/colors.${process.env.THEME}.json`);
export const optionId = {
  SNP_ID: 'S&P 500',
  AVG_PORTFOLIO_ID: `Avg. ${brandName}`,
  BEST_PORTFOLIO_ID: `Best ${brandName}`,
  NONE_ID: '',
  MY_PORTFOLIO_OPTION_ID: 'My Portfolio',
}

export const SNP_OPT: Option = {
  name: optionId.SNP_ID,
  style: { backgroundColor: colors.$SNP },
  text: (
    <span>
      S&P<br className={helperStyles.showMobile} /> 500
    </span>
  ),
};
export const AVG_PORTFOLIO_OPT: Option = {
  name: optionId.AVG_PORTFOLIO_ID,
  style: { backgroundColor: colors.$LE_AVG_PORTFOLIO },
  text: `Average ${brandName} Portfolio`,
  shortText: (
    <span>
      Avg.<br className={helperStyles.showMobile} />
      {brandName}
    </span>
  ),
};
export const BEST_PORTFOLIO_OPT: Option = {
  name: optionId.BEST_PORTFOLIO_ID,
  style: { backgroundColor: colors.$LE_BEST_PORTFOLIO },
  text: `Best Performing ${brandName} Portfolios`,
  shortText: `Best ${brandName}`,
};
export const NONE: Option = {
  name: optionId.NONE_ID,
  text: 'None',
  style: { background: `url(${noneBackgroundPattern}) repeat` },
}; // none uses a special gradient.
// export const ADD_PORTFOLIO = { color: '#7f8c8f', text: 'Add Portfolio' };
export const MY_PORTFOLIO_OPT: Option = {
  name: optionId.MY_PORTFOLIO_OPTION_ID,
  style: { backgroundColor: colors.$MY_PORTFOLIO_OPTION },
  text: (
    <span>
      My <br className={helperStyles.showMobile} />Portfolio
    </span>
  ),
};
export const EMPTY_OPT: Option = {
  name: optionId.NONE_ID,
  style: { backgroundColor: colors.$EMPTY_OPTION },
  text: '',
  color: '',
};

export const defaultPerformanceDropdownOptions: Option[] = [
  AVG_PORTFOLIO_OPT,
  SNP_OPT,
  BEST_PORTFOLIO_OPT,
  NONE,
];

export class PerformanceDropdown extends React.PureComponent<Props, State> {
  // TODO this probably shouldn't be here, but taken out to some config file
  ref: HTMLDivElement;
  state: State = {
    isOpen: false,
    selectedOption: EMPTY_OPT,
  };
  static defaultProps = {
    readOnly: false,
  };
  constructor(props) {
    super(props);

    const { options = [], value: selectedOption, ...restProps } = props;

    this.state = {
      ...this.state,
      selectedOption: this.getSelectedOption(),
      ...(_.pick(restProps, Object.keys(this.state)) as Partial<State>), // for storybook
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }
  render() {
    const {
      options = [],
      className,
      readOnly,

      passedState: {
        isOpen = this.state.isOpen,
        selectedOption: selectedOptionPossibly = this.state.selectedOption,
      } = this.state,
    } = this.props;

    const selectedOption = readOnly
      ? this.getSelectedOption()
      : selectedOptionPossibly;

    return (
      <div
        className={classNames('performanceDropdown', className, {
          ['readOnly']: readOnly,
        })}
        ref={ref => (this.ref = ref as HTMLDivElement)}
      >
        <LegendBox style={selectedOption.style} onClick={this.toggleOpen} />
        <div
          className={classNames('selectPreview', {
            ['notReadOnly']: !readOnly,
          })}
          onClick={this.toggleOpen}
        >
          <div className={classNames('selectedOption')}>
            {selectedOption.text}
          </div>
          <div className={classNames('arrowDown')} />
          {isOpen && (
            <div className={classNames('selectMenu')}>
              {options.map((opt: Option) => {
                const style: any = { ...opt.style };
                if (opt.isDisabled)
                  style.backgroundColor = disabledBackgroundColor;

                return (
                  <div
                    key={opt.value}
                    data-value={opt.value || opt.text}
                    className={classNames('option', {
                      ['isDisabled']: opt.isDisabled,
                    })}
                    onClick={e => {
                      if (!opt.isDisabled) this.onPerfSelect(opt);
                      e.stopPropagation();
                    }}
                  >
                    <LegendBox style={style} />
                    <span>{opt.text}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
  handleOutsideClick = ({ target }) =>
    this.state.isOpen &&
    !this.ref.contains(target) &&
    this.setState({ isOpen: false });
  toggleOpen = (event?) =>
    this.props.readOnly || this.setState({ isOpen: !this.state.isOpen });
  onPerfSelect = (selectedOption: Option) => {
    this.toggleOpen();
    if (this.props.onSelectCancellable) {
      const shouldChange = this.props.onSelectCancellable(selectedOption);
      if (shouldChange) this.setState({ selectedOption });
    } else if (this.props.onPerfSelect) {
      this.props.onPerfSelect(selectedOption);
      this.setState({ selectedOption });
    } else {
      this.setState({ selectedOption });
    }
  };
  getSelectedOption = () => {
    const { options = [], value: selectedOption, ...restProps } = this.props;

    return selectedOption || (options.length && options[0]) || EMPTY_OPT;
  };
}

export const PerformanceDropdownRO = ({ value }: { value: Option }) => (
  <PerformanceDropdown readOnly options={[value]} />
);

class LegendBox extends React.PureComponent<
  { style?: any /*React.CSSProperties*/ } & any //React.HTMLAttributes<HTMLDivElement>
  > {
  render() {
    const { style, ...rest } = this.props;
    return <div className={classNames('legendBox')} style={style} {...rest} />;
  }
}
