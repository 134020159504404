exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-news-analysis-box__box.src-browser-news-analysis-box__box,.src-browser-news-analysis-box__box.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header{background-color:#fff}.src-browser-news-analysis-box__box.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header{position:relative;color:#333;padding-left:15px;border-bottom:1px solid #d6d6d6}.src-browser-news-analysis-box__box{border:1px solid #d6d6d6;-webkit-box-orient:vertical;-ms-flex-direction:column;flex-direction:column}.src-browser-news-analysis-box__box,.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-direction:normal}.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header{background:#ebebeb;color:#5a5858;font-family:Proxima Nova Alt Semibold;line-height:26px;padding-left:5px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-negative:0;flex-shrink:0;-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;-webkit-box-orient:horizontal;-ms-flex-direction:row;flex-direction:row}.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header h3{font-size:16px}.src-browser-news-analysis-box__box .src-browser-news-analysis-box__header .src-browser-news-analysis-box__tooltip{margin:3px 3px 3px auto}.src-browser-news-analysis-box__box>div{-webkit-box-flex:1;-ms-flex:1 0 auto;flex:1 0 auto}.src-browser-news-analysis-box__center{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100px}", ""]);

// Exports
exports.locals = {
	"box": "src-browser-news-analysis-box__box",
	"header": "src-browser-news-analysis-box__header",
	"tooltip": "src-browser-news-analysis-box__tooltip",
	"center": "src-browser-news-analysis-box__center"
};