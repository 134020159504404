export default {
  tabs: {
    stockPicker: 'Stock Picker',
    portfolioManager: 'Portfolio Manager'
  },
  tooltips: {
    portfolioGain:
      'The total gain of your portfolio since added to Smart Portfolio.',
    sharpeRatio:
      'A measure of your returns and associated risk you take on compared to returns of risk-free assets.',
    recentActivity:
      'A log of your recent transactions on assets in your portfolio. It takes up to 24 hours for transactions to appear in your Recent Activity list.',
    successRate:
      'How often your transactions yielded a positive return in relation to the benchmark.',
    averageReturn: 'The average return per transaction in this portfolio.'
  }
};
