import React from 'react';
import classNames from 'classnames';
import Component from 'react-pure-render/component';
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';

import './style.styl';

import {
  selectActiveTooltips,
  selectTooltipArgs,
  selectoTooltipPositions,
} from './selectors';

export default (tooltips, stateName) => Child =>
  class Tooltips extends Component {
    render() {
      const state = this.props[stateName];

      const { actions } = this.props;

      const activeTooltips = selectActiveTooltips(state);
      const tooltipsArgs = selectTooltipArgs(state);
      const tooltipsPositions = selectoTooltipPositions(state);

      const style = position => ({
        left: position.left,
        top: position.top,
      });

      return (
        <div className="tooltips-wrapper-component">
          <div
            className={classNames('rest-wrapper', {
              active: activeTooltips.size > 0,
            })}
          >
            <Child {...this.props} />
          </div>
          {/* <ReactCSSTransitionGroup
            className="tooltips"
            component="ul"
            transitionAppear
            transitionAppearTimeout={1000}
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={1000}
            transitionName="opacity-200">*/}
          <ul className="tooltips">
            {activeTooltips.map(tooltip => {
              const Tooltip = tooltips[tooltip];
              const args = tooltipsArgs.get(tooltip);
              const position = tooltipsPositions.get(tooltip);
              return (
                <li
                  className={classNames('tooltip', {
                    isNotWholyVisibleHorizontally:
                      position.isNotWholyVisibleHorizontally,
                    isNotWholyVisibleVertically:
                      position.isNotWholyVisibleVertically,
                    centeredAbove: args.centeredAbove,
                  })}
                  key={tooltip}
                  onMouseLeave={() =>
                    actions.hideTooltip(tooltip, { force: true })}
                  style={style(position)}
                >
                  <Tooltip {...this.props} {...args} />
                </li>
              );
            })}
          </ul>
          {/* </ReactCSSTransitionGroup>*/}
        </div>
      );
    }
  };
