import { Theme } from '../../../common/types';
import * as classnames from 'classnames';
import * as React from 'react';
import * as styles from './Tooltip.istyl';
import * as classNames from 'classnames';
import { MediaQuery, getMediaQuery } from 'sp/common/lib/mediaQuery';
// TODO to use this, one should probably convert responsive to tsx
// import isResponsive from '../decorators/Responsive/Responsive.react';

interface Props {
  content: any; //React.ReactNode;
  mediaQuery?: MediaQuery;
  overrideWithTheme?: Theme;
  isMobile?: boolean;
  outerStyles?: { [key: string]: string };
  className?: string;
}

const aestheticOffset = 25;
enum Side {
  Left,
  Right
}

// @isResponsive
export class Tooltip extends React.PureComponent<
  Props & any,
  { show: boolean; sideToShow: Side }
  > {
  state = { show: false, sideToShow: Side.Left };
  tooltipContentBox: any; //HTMLElement | null;

  initSideToShow = () => {
    const boundingClientRect = this.tooltipContentBox
      ? this.tooltipContentBox.parentElement
        ? this.tooltipContentBox.parentElement.getBoundingClientRect()
        : false
      : false;
    const overflowingRight = boundingClientRect
      ? window.outerWidth < boundingClientRect.right + aestheticOffset
      : false;
    const overflowingLeft = boundingClientRect
      ? 0 > boundingClientRect.left - aestheticOffset
      : false;
    let { sideToShow } = this.state;
    if (sideToShow === Side.Left && overflowingLeft) {
      sideToShow = Side.Right;
    } else if (sideToShow === Side.Right && overflowingRight) {
      sideToShow = Side.Left;
    }
    this.setState({ sideToShow });
  };

  componentDidMount() {
    this.initSideToShow();
    window.addEventListener('resize', this.initSideToShow);
    window.addEventListener('orientationchange', this.initSideToShow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.initSideToShow);
    window.removeEventListener('orientationchange', this.initSideToShow);
  }

  public render() {
    const {
      mediaQuery = getMediaQuery(),
      overrideWithTheme: theme = process.env.THEME,
      content,
      outerStyles = {},
      className = '',
      ...restProps
    } = this.props;
    const {
      isMobile = !mediaQuery.get('laptop'),
    } = this.props;
    const themeOverride = {
      [styles.black]: theme === 'web'
    }; /* white is default */
    return (
      <div
        className={classNames(
          themeOverride,
          styles.wrapper,
          outerStyles.wrapper,
          className
        )}
        onClick={() => isMobile && alert(content)}
        onMouseOver={() => !isMobile && this.setState({ show: true })}
        onMouseOut={() => !isMobile && this.setState({ show: false })}
        {...restProps}
      >
        <div className={classNames(styles.i, outerStyles.i)}>i</div>
        <div
          ref={ref => (this.tooltipContentBox = ref)}
          className={classNames(
            classNames(styles.content, outerStyles.content),
            {
              [classNames(styles.show, outerStyles.show)]: this.state.show
            }
          )}
          style={{ right: this.state.sideToShow === Side.Right ? '' : '100%' }}
        >
          {content}
        </div>
      </div>
    );
  }
}
