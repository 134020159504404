import React from 'react';
import { escapeRegExp } from 'lodash';

export default ({
  term,
  highlightedClass = '',
  highlightedStyle = {},
  className = '',
  children
}) => {
  if (typeof term !== 'string') {
    return <span className={className}>{children}</span>;
  }

  term = term.toLowerCase();
  const { length } = term;

  const parts = children
    .split(new RegExp(`(?=${escapeRegExp(term)})`, 'ig')) // find terms
    .map(x => [x.slice(0, length), x.slice(length)]) // separate terms
    .reduce((arr, x) => arr.concat(x), []); // flatten

  return (
    <span className={className}>
      {parts.map((p, i) => (
        <span key={i}>
          {p.toLowerCase() === term ? (
            <span
              className={`highlighted ${highlightedClass}`}
              style={highlightedStyle}
            >
              {p}
            </span>
          ) : (
              p
            )}
        </span>
      ))}
    </span>
  );
};

export const __hotReload = true;
