import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import MarketOverview from '../tr-market-overview';

export default class TrDashboardOverviewMarketOverview extends Component {
  static propTypes = {
    changedMarketOverviewMarketVisibility: PropTypes.func,
    changedMraketOverviewDuration: PropTypes.func,
    duration: PropTypes.string,
    msg: PropTypes.object,
    visibleMarkets: PropTypes.object
  };

  render() {
    const {
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      duration,
      msg,
      visibleMarkets
    } = this.props;

    return (
      <section className="tr-dashboard-overview-market-overview">
        <header>
          <h1>{msg.marketOverview}</h1>
        </header>
        <main>
          <MarketOverview
            changedMarketOverviewMarketVisibility={
              changedMarketOverviewMarketVisibility
            }
            changedMraketOverviewDuration={changedMraketOverviewDuration}
            duration={duration}
            visibleMarkets={visibleMarkets}
          />
        </main>
      </section>
    );
  }
}
