import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

const defaultProps = {
  className: 'maybe-button'
};

export default class MaybeButton extends Component {
  static propTypes = {
    children: PropTypes.any,
    when: PropTypes.bool // Not passed = false
  };

  render() {
    const { when: isButton, children, ...restProps } = this.props;

    return isButton ? (
      <button {...defaultProps} {...restProps}>
        {children}
      </button>
    ) : (
      <div {...defaultProps} {...restProps}>
        {children}
      </div>
    );
  }
}
