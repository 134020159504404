import * as React from 'react';
import * as classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ConnectedPureComponent } from 'sp/common/lib/ConnectedComponent';
import { AuthRecord, Dashboard } from 'sp/common/types';
import { selectActivePortfolio } from 'sp/browser/dashboard/selectors';

interface NoDataAddStocksProps {
  msg: { addStocks: string; noData: string };
  htmlProps?: any; //React.HTMLProps<HTMLDivElement>;
  openAddStockDialog: () => void;
}

@connect(({ dashboard }) => ({ dashboard }))
export class NoDataAddStocks extends ConnectedPureComponent<NoDataAddStocksProps, { dashboard: Dashboard }> {
  get shouldAllowAddingStocks() {
    try {
      const activePortfolio = selectActivePortfolio(this.connected.dashboard);
      const shouldAllowAddingStocks = !activePortfolio.get('isImported') && activePortfolio.get('type') !== 'pending';
      return shouldAllowAddingStocks;
    } catch (e) {
      console.error(e);
      // fuck you and fuck your readiness
      // This is here because *sometimes* selectActivePortfolio would throw because there's no data yet.
      return false;
    }
  }

  get noDataJsx() {
    const { msg, openAddStockDialog } = this.props;

    if (this.shouldAllowAddingStocks) {
      return (
        <FormattedMessage
          defaultMessage={msg.noData}
          id={msg.noData}
          values={{
            addButton: <button onClick={openAddStockDialog}>{msg.addStocks}</button>
          }}
        />
      ) 
    } else {
      return (
        String(msg.noData).includes('{addButton}')
        ? 'Your Holdings don\'t contain any stocks.' 
        : (
          <FormattedMessage
            defaultMessage={msg.noData}
            id={msg.noData}
          />
        )
      )
    }
  }
  componentDidMount() {
  }
  render() {
    const { htmlProps = {} } = this.props;


    return (
      <div {...htmlProps} className={classNames('no-data', htmlProps.className)}>
        {this.noDataJsx}
      </div>
    );
  }
}

export default NoDataAddStocks;
