export default {
  title: 'Log in to your account',
  forgot: 'Forgot your password? Click {forgotPassowrdLink}.',
  input: {
    email: 'Email',
    password: 'Password'
  },
  forgotPassowrdLink: 'here',
  submit: 'Log In',
  errorMessage:
    'The password you entered is incorrect or the email does not belong to any account.',
  notYetUser: "Don't have an account? {registerBtn}",
  registerBtnLabel: 'Sign up'
};
