import { WeightedHoldings } from '../dashboard/PortfolioManager';
import { Money } from '../Money/Money';
import {
  Actions,
  AuthRecord,
  Dashboard,
  Period_UI,
  PerfDisplayTab,
  Benchmark_UI,
  UI,
  PortfolioPrivacy,
  Routes,
} from '../../common/types';
import * as classNames from 'classnames';
import * as React from 'react';
import * as cookies from 'js-cookie';
import * as _ from 'lodash';
import { FormattedNumber } from 'react-intl';
import {
  selectIsPermanentLoggedIn,
  selectUserName,
  selectLoggedInUser,
  selectHasMadeAnyPortfolioPublicEver,
  selectIsPortfolioMadePublicEver,
  selectPortfolioPrivacy,
  selectUserPublicUrl,
  selectPortfolioHasPerformance,
  selectIsOnboarding,
  selectEverMadePortfolioPublic,
  selectPublicPortfolioUrlById,
} from '../../common/auth/selectors';
import {
  BestTrade,
  componentState,
  Performance,
} from '../../common/components/performance/records';
import { suits, uiProperties } from '../../common/enums';
import { popupRegister, getQuery } from '../../common/lib/utils';
import { ApiDecorator } from '../ApiDecorator/ApiDecorator.react';
import {
  selectIsCombinedPortfolio,
  selectPortfolioItems,
  selectUserInfo,
  selectActivePortfolioGains,
  selectActivePortfolio,
} from '../dashboard/selectors';
import { VariableSizeSpinner } from '../Spinner/index.react';
import { AnalystStockCoverage } from './AnalystStockCoverage';
import ProgressBar from './ProgressBar';
import {
  selectMyPerfLoadingState,
  selectProgressState,
  selectStockPickerIncluded,
  selectIsLoadingFields,
} from './selectors';
import { FormatDate, PosNeg } from './UtilComponents';
import './index.styl';
import { MyPerfBox } from 'sp/browser/performance/MyPerfBox/MyPerfBox';
import { get } from 'lodash';
import { selectWeightedHoldings } from 'sp/browser/dashboard/routes/analysis/portfolio/selectors';
import {
  getData as portfolioPerformanceBoxGetData,
} from 'sp/browser/performance/portfolio-performance-box/container';
import { getData as allocationBoxGetData } from 'sp/browser/performance/allocation-box/container';
import { getData as volatilityBoxGetData } from 'sp/browser/performance/volatility-box/container';
import {
  MyPerformancePageComponent, MyPerformancePageProps, PortfolioDataForMyPerfPage
} from 'sp/browser/tr-dashboard-user-performance-container/component';
import { ComponentData as AlocBoxData } from 'sp/browser/performance/allocation-box/container';
import { periodicPerformanceGraphMockData } from 'sp/browser/performance/periodic-graph/mock';
import { getMyPortfolioFromData } from 'sp/browser/performance/portfolio-performance-box/utils';
import { List, Map } from 'immutable';
import { PortfolioManager } from 'sp/browser/dashboard/PortfolioManager';
import { Portfolio } from 'sp/common/records';
import { connect } from 'react-redux';
import { adaptStocks } from 'sp/common/api-adapter';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { PublicPortfolioStore } from 'sp/browser/tr-dashboard-user-performance-container/public.reducer';
import { lab } from 'd3';
import mapDispatchToProps from 'sp/common/app/mapDispatchToProps';
import { MediaQuery } from 'sp/common/lib/mediaQuery';
import { navigateToPublicPortfolioToRegister, navigateToWelcomePage } from 'sp/browser/pages/PublicPortfolioToRegister/utils';
import { history } from 'sp/browser/history';
import { ConnectedComponent } from '../../common/lib/ConnectedComponent';
import { dashboard } from '../../common/config';

// TODO split this into a controller for each portfolio type, private and sharted.
@connect(state => ({ ...state }), mapDispatchToProps)
@ApiDecorator(
  'extraPerformanceData',
  'myPerformanceFields',
  // 'userPref',
  'userInfo',
  'portfolios',
  'stocks',
  'details',
  'performanceTickerDetails',
  'portfolioMonthlyGain',
  'portfolioGains',
  // below Added because of volatility box :\
  'avgPortfolioItems',
  'avgPortfolioStocks',
  'avgPortfolioDetails',
)
export class MyPrivatePerformancePageContainer extends ConnectedComponent<SharedData, {
  auth: AuthRecord;
  dashboard: Dashboard;
  performance: Performance;
}> {
  componentDidUpdate() {
    // TODO it's hack to be doing this here
    // it's here because it relies upon values returned by the api decorator,
    // so they aren't available at didMount
    this.displayQuestionnaireIfNeeded();
  }

  render() {
    const {
      mediaQuery,
      ui,
    } = this.props;

    const {
      auth, dashboard, performance
    } = this.connected;

    const isCombinedPortfolio = selectIsCombinedPortfolio(dashboard);
    if (isCombinedPortfolio) {
      return this.renderAllPortfolios();
    }

    const shouldDisplayLoader = selectMyPerfLoadingState(performance) === componentState.loading;
    if (shouldDisplayLoader) {
      return (
        <div className="main-loader">
          <VariableSizeSpinner size={100} />
        </div>
      );
    }

    const data = this.getData();

    const progressState = selectProgressState({ dashboard, performance, ui });
    const acts = {
      progress: progressState,
      actThree: this.openMyHoldingsDialog,
      actTwo: this.openMyDetailsDialog,
    };
    const sharedData = getSharedData(this.props);

    return (
      <MyPerformancePageComponent
        {...data}
        {...sharedData}
        auth={auth}
        acts={acts}
        openMyHoldingsDialog={this.openMyHoldingsDialog}
        onNameChange={this.onNameChange}
        isPublicPortfolio={false}
        isMyPublicPortfolio={false}
        onClickedWhenEditingPrevented={popupRegister}
        activePortfolioName={selectActivePortfolio(this.connected.dashboard).get('name')}
      />
    );
  }

  getData = (): PortfolioDataForMyPerfPage => {
    const { ui, actions } = this.props;
    const {auth, dashboard, performance} = this.connected;

    const portfolioItems = selectPortfolioItems(dashboard);
    const userInfo = selectUserInfo(dashboard);
    const weighted = selectWeightedHoldings(dashboard);
    const bySector = weighted.byTypeAndSector().stock;
    const hasStocks = bySector && Object.keys(bySector).length;
    const weightedSectors = !bySector
      ? []
      : Object.keys(bySector).map(x => ({
        id: x,
        percent: _.sumBy(bySector[x], ({ typePercent }) => typePercent),
      }));
    const userRisk = performance.get('fields').get('tolerance');
    const picker = selectStockPickerIncluded({ dashboard, performance, ui });
    const activePortfolioGains = selectActivePortfolioGains(dashboard);
    const activePortfolio = selectActivePortfolio(dashboard);
    const portfolioId = activePortfolio.get('id');

    return {
      id: 0,
      allocationBox: allocationBoxGetData(dashboard),
      bestTrade: performance.get('bestTrade') || BestTrade(),
      creationDate: performance.get('creationDate'),
      dividendYield: weighted.dividendYield(),
      hasEnteredHoldings: performance.get('everHadHolding'),
      holdingOperations: performance.get('holdingOperations'),
      pickers: picker ? [picker] : [], // this is done so to ease public portfolio pickers passing in.
      portfolioItemsGains: portfolioItems.map(x => ({ ticker: x.get('ticker'), gain: x.get('sincePurchaseGain'), })).toJS(),
      portfolioManager: performance.get('portfolioManager'),
      portfolioPerformanceBox: portfolioPerformanceBoxGetData(dashboard),
      risk: userRisk.get('state') !== suits.SUCCESS ? null : userRisk.get('value'),
      sixMonthReturn: get(activePortfolioGains, 'sixMonth.percent', null),
      yearlyReturn: get(activePortfolioGains, 'yearly.percent', null),
      userName: selectUserName(auth),
      userPic: userInfo.get('pictureUrl'),
      volatilityBox: volatilityBoxGetData(dashboard),
      weightedHoldings: weighted.holdings(),
      ytdReturn: get(activePortfolioGains, 'ytd.percent', null),
      portfolioPrivacyBtnProps: {
        btnDisabled: false,
        portfolioPrivacyLevel: selectPortfolioPrivacy(auth, portfolioId),
        everMadeThisPortfolioPublic: selectEverMadePortfolioPublic(auth, portfolioId),
        portfolioPublicUrl: selectPublicPortfolioUrlById(auth, portfolioId),
        onChangePrivacy: (privacyLevel: PortfolioPrivacy) => actions.setPortfolioPrivacy({ portfolioId, privacyLevel }),
        // onChangePrivacy: (privacyLevel: PortfolioPrivacy) => attemptSetPortfolioPrivacy(auth, { portfolioId, privacyLevel }),
        setPortfolioPrivacyComponentState: selectLoggedInUser(auth).get('componentStates').get('setPortfolioPrivacy'),
        hasntMadeAnyPortfolioPublicYet: !selectHasMadeAnyPortfolioPublicEver(auth),
      },
    };
  };

  onNameChange = name => {
    this.props.actions.changeUserName(name);
  };

  openMyHoldingsDialog = e => {
    this.props.actions.openDialog('goToHoldings');
  };

  openMyDetailsDialog = e => {
    this.props.actions.openDialog('myDetails');
  };

  renderAllPortfolios() {
    return (
      <div className="all-portfolios-selected">
        <div className="important-note">Important Note</div>
        For detailed performance analysis, <br />
        please select a specific portfolio.
        <div className="select-portfolio">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPEAAAAtCAMAAABI6X1vAAABd1BMVEUAAADlJiY3Nzf////shAjlJiYAAAD//8fH//+HAAAAAIdONTUAAE+nTwAAABan5/////f//+cvAAD//+///9/76taUZjBPp+Hnt2+fPwC35/+Hz/en1++/v7//358AN3cnR2/Hl2c/Pz8AADdHAADv//8AX6/chR3n//8vj88Ah8fzzZX3v3HvqFS/cRdJHwD3//9MpNP//9IAT6f/z48ALIzvt2fHhzfPjy8AAC/ehyBHJxeMLwAXAADf///P///D8/+v6/+j3/+Pz/+Hx/93w/f+9vZnt+/72toXd7//77f1ra0AR5/veHgAAG/rr2MAAF8/NjZVNDR5MTHmLy+nLCykLCzKKSneJycAFyfmNSEAAB9bLxvsgAm7awBnDQDX9/+Px+/v9+dvp9f/78+Pp8fnz6//56dHb6cXd483X4+nb2/tbW0/T2efb18nP1/fn0cXJ0eHhz9fPyc/Nx8XABynVxevXwCXPwBYPgA5NACHFwBPAADYsOv0AAAAAnRSTlMAIqPzjNwAAAI5SURBVHja7dhpU9NQFAZg7GlOi0IMKRRcolKFUspSaqsUW9lFlFUFRQT3fd8X+PHeEyZnmiHM2HTSmeB5p5lk3vZ+eObeLE1T5LCm6YCIWMQiFrGIRSxiEYtYxCIWcb3iZDoa3owlaxcnBzsgvOkYTNYsThM4xOS0Mqyu1yKOQrgTjURu3dX+L/Hte5ov8ScsTatdrPlonDawj1Hl89McuPPs7SRA9gEW407DoxqfrXVN5dhejhwQL3H7CcSMpxj1OXClrbXlOBjbLVPAYW3Dc/2+9g/xEhfM1+XiuFts740usx8S3cP6udPUrHz5/gcRR1oRkXsexQUtgY1XjwJiplAlRUc372h+xaP6jPp4iZcr/cZlVDl/yp51/bcj5p5HcXHjIR3M54IRW6hiAVxbJI4/cWynOF4w53Oeq3r2zIWzJ6HLvNgXay7NJp7QqraXNvfOKC5e7vb2QHBJ7U3xO82/eKCSgXx5aWK/WP/4GC5hJ53p9M1SHNpYzL0ziov2H7jx/nlfUGKLpriuOTaG0M7c/lVNGaiSVYm5ZzEXYLz48A0XrgY2ySmAus5jOu0ovT1eYmf12t/a4ilb7O5p4wJU8r/UTwKKZdV5rV6270z58sqkp9gYqrpCQWIbMUNid08bFwVT7WmOA8/Wmq/78c/SNO1GsdNTrG46V/guBJAd3hwhMfcs5gKyXyubbyYa9swlz9WHXBxZXatFPBb2f4vyRkDe+sibPRGLWMQiFrGIRSxiEQcj/gty56bIBM0GJgAAAABJRU5ErkJggg==" />
        </div>
      </div>
    );
  }

  displayQuestionnaireIfNeeded() {
    const {
      ui,
      actions,
    } = this.props;
    const {
      dashboard,
      performance,
    } = this.connected;
    if (selectIsOnboarding()) return;
    const dataState = selectMyPerfLoadingState(performance);
    const isLoadingFields = selectIsLoadingFields(performance);
    const progressState = selectProgressState({ dashboard, performance, ui });
    let timesShownToUser =
      parseInt(cookies.get('myDetailsQuesCounter') || '', 10) || 0; // TODO use localstorage, why cookie..? no need to use a third party library when localstorage is good enough
    const hasShownQuestionnaireThisSession =
      sessionStorage.getItem('myDetailsQues') === 'true';
    if (
      !hasShownQuestionnaireThisSession &&
      dataState !== componentState.loading &&
      !isLoadingFields &&
      progressState === 1 &&
      timesShownToUser <= 3
    ) {
      actions.openDialog('myDetails');
      timesShownToUser += 1;
      cookies.set('myDetailsQuesCounter', timesShownToUser.toString());
      sessionStorage.setItem('myDetailsQues', 'true');
    }
  }
}

export class PublicPortfolioPageContainer extends React.PureComponent<{
  // the following "optionals" are because typescript can't figure out that when we
  // use PublicPortfolioPageContainer, there is no way these three will be undefined.
  publicPortfolioId?: string;
  publicPortfolioz?: PublicPortfolioStore;
  routes?: Routes;
  actions: Actions;
  auth: AuthRecord;
} & SharedData> {
  componentDidMount() {
    const { actions, publicPortfolioz, publicPortfolioId = '', routes } = this.props;
    if (!publicPortfolioId && routes) navigateToPublicPortfolioToRegister(routes);
    if (
      (!publicPortfolioz || publicPortfolioId !== publicPortfolioz.publicPortfolioId) && publicPortfolioId
    ) {
      const portfolio = actions.fetchPublicPortfolio(Number(publicPortfolioId))
    }
  }
  componentDidUpdate() {
    const { actions, publicPortfolioz, publicPortfolioId = '', routes } = this.props;
    if (publicPortfolioz) {
      if (publicPortfolioz.errorCode && routes) {
        if (selectIsPermanentLoggedIn()) {
          return navigateToWelcomePage();
        }
        navigateToPublicPortfolioToRegister(routes);
      }
    }
  }
  render() {
    const { auth, publicPortfolioz } = this.props;
    if (!publicPortfolioz || publicPortfolioz.loadingState === suits.LOADING) {
      return (
        <div className="main-loader">
          <VariableSizeSpinner size={100} />
        </div>
      );
    }

    const sharedData = getSharedData(this.props);
    const currentUser = auth.get('users').get('viewer').get('loggedInUser');
    const portfoliosMadePublic = currentUser.get('portfoliosMadePublic');

    const isMyPublicPortfolio = portfoliosMadePublic.some(item => item.get('id') === publicPortfolioz.currentPublicPortfolio.id);
    return <MyPerformancePageComponent
      {...publicPortfolioz.currentPublicPortfolio}
      {...sharedData}
      auth={auth}
      isPublicPortfolio={true}
      isMyPublicPortfolio={isMyPublicPortfolio}
      activePortfolioName={publicPortfolioz.currentPublicPortfolio.name || ''}
    />;
  }
}

export class MyPerformancePageContainer extends React.PureComponent<{
  auth: AuthRecord;
  mediaQuery: MediaQuery;
  ui: UI;
  actions: Actions;
  isPublicPortfolio: boolean;
  publicPortfolioId?: string,
  publicPortfolioz: PublicPortfolioStore,
  routes: Routes,
}, {}> {
  render() {
    const {
      auth,
      mediaQuery,
      ui,
      actions,
      isPublicPortfolio,
      publicPortfolioz,
      publicPortfolioId,
      routes,
    } = this.props;

    const benchmark = ui.get('benchmark');
    const period = ui.get('period');
    const displayTab = ui.get('performanceDisplayTab');

    const storeData = { actions, ui, period, displayTab, benchmark, mediaQuery };
    const eventHandlers = {
      onPeriodChange: this.onPeriodChange,
      onBenchmarkChange: this.onBenchmarkChange,
      onModeChange: this.onModeChange,
    };
    const publicPageData = !isPublicPortfolio ? {} : {
      publicPortfolioId,
      publicPortfolioz,
      routes,
    };
    const MyPerfComponent = isPublicPortfolio
      ? PublicPortfolioPageContainer
      : MyPrivatePerformancePageContainer;

      if (isPublicPortfolio) {
    return <PublicPortfolioPageContainer
      auth={auth}
      {...storeData}
      {...eventHandlers}
      {...publicPageData}
    />
      }
    return <MyPrivatePerformancePageContainer
      auth={auth}
      {...storeData}
      {...eventHandlers}
      {...publicPageData}
    />
  }
  onModeChange = tab => {
    this.props.actions.setUiProperty(uiProperties.performanceDisplayTab, tab);
  };

  onBenchmarkChange = e => {
    this.props.actions.setUiProperty(
      uiProperties.benchmark,
      Number(e.target.value),
    );
  };

  onPeriodChange = e => {
    this.props.actions.setUiProperty(
      uiProperties.period,
      Number(e.target.value),
    );
  };
}

function getSharedData(data: { [key: string]: any } & SharedData) {
  const {
    mediaQuery,
    ui,
    actions,
    isPublicPortfolio,
    publicPortfolioId,
    onPeriodChange,
    onBenchmarkChange,
    onModeChange,
    period, displayTab, benchmark,
  } = data;

  const isPermanentLoggedIn = selectIsPermanentLoggedIn();
  return {
    mediaQuery,
    ui,
    actions,
    isPublicPortfolio,
    publicPortfolioId,
    onPeriodChange,
    onBenchmarkChange,
    onModeChange,
    period, displayTab, benchmark,
    isPermanentLoggedIn
  };
}

type SharedData = {
  actions: Actions;
  mediaQuery: MediaQuery;
  ui: UI;
  period: Period_UI;
  displayTab: PerfDisplayTab;
  benchmark: Benchmark_UI;
}

export default MyPerformancePageContainer;
