import Porthole from 'porthole';

export const frameName = 'trSocialAuth';

export class Messenger {
  windowProxy: typeof Porthole.WindowProxy;

  constructor(url: string, frameName: string) {
    this.getWindowProxy(url, frameName);
  }

  getWindowProxy(url?: string, frameName?: string) {
    return (this.windowProxy = new Porthole.WindowProxy(url, frameName));
  }

  post(data) {
    this.windowProxy.post(data);
  }

  listen(listener: EventListener) {
    return this.windowProxy.addEventListener(({ data }) => listener(data));
  }

  unlisten(listener) {
    return this.windowProxy.removeEventListener(listener);
  }
}

export default Messenger;
