import * as React from 'react';
import { ImportLogos } from '../tr-import-logos/ImportLogos';
import msg from './en';

export type OnClickBroker = ({ id: Broker }) => void;

export class WelcomeConnectExistingPortfolio extends React.Component<{
  isLoading: boolean;
  onClickBroker: OnClickBroker;
  onClick: () => void;
}> {
  state = {
    agreed: false,
    error: false
  };

  setError = () => this.setState({ error: true });

  toggleAgreement = event => this.setState({ agreed: event.target.checked });

  onClick = e => {
    e.preventDefault();
    const { agreed } = this.state;
    if (process.env.THEME === 'nasdaq' && !agreed) {
      this.setError();
      return;
    }
    this.props.onClick();
    // report('clickedWelcomeCreatePortfolio', { type: 'yodlee' });
  };

  render() {
    const { onClickBroker, isLoading } = this.props;
    const { agreed, error } = this.state;

    return (
      <div className="welcome-connect">
        <h3>
          {
            isLoading 
              ? 'Connecting to your existing portfolio, please wait...' 
              : 'Simply connect your existing broker account and have it analyzed'
          }
        </h3>
        <ImportLogos onClickBroker={onClickBroker} />
        <div className="footerBrokers">
          <span>{msg.termsBrokers}</span>{' '}
          <a target="_blank" href="https://tipranks.com/terms">
            {msg.termsLink}
          </a>{' '}
          <span>and</span>{' '}
          <a target="_blank" href="https://tipranks.com/privacypolicy">
            {msg.privacyPolicyLink}
          </a>.
        </div>
      </div>
    );
  }
}
