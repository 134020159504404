import { List } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import OverviewSection from '../tr-portfolio-overview';

export default class TrDashboardOverviewPortfolioOverview extends Component {
  static propTypes = {
    allCombinedPortfolio: PropTypes.object,
    msg: PropTypes.object,
    portfolioName: PropTypes.string,
    portfolioOverviewStatsMsg: PropTypes.object
  };

  render() {
    const {
      allCombinedPortfolio,
      msg,
      portfolioName,
      portfolioOverviewStatsMsg
    } = this.props;
    return (
      <section className="tr-dashboard-overview-portfolio-overview">
        <header>
          <h1>
            <span className="portfolio-label">{msg.portfolioOverview}:</span>
            &nbsp;
            {portfolioName}
          </h1>
        </header>
        <main>
          <OverviewSection
            activePortfolioStats={allCombinedPortfolio}
            msg={portfolioOverviewStatsMsg}
            stats={
              new List(['amount', 'dailyGain', 'monthlyGain', 'totalGain'])
            }
          />
        </main>
      </section>
    );
  }
}
