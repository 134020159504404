import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './styles.istyl';
import { Icon } from 'tipranks-icons';

export class FirstOnboardingPage extends React.PureComponent {
  render() {
    return <main>
      <h1>Welcome to TipRanks Investing Community</h1>
      <h2>Share your investing performance with the world</h2>

      <div className={classNames(styles.sectionBody, styles.bullets)}>
        <ul className={styles.bulletPoints}>
          <li>
            <div className={styles.ellipsis}>
              <Icon icon="magnifiyingGlassWithMan" />
            </div>
            <div className={styles.bullet}>
              <h3>Your profile will be visible to the world</h3>
              <p>You will have a shareable page on TipRanks: www.tipranks.com/investors/your-name</p>
            </div>
          </li>
          <li>
            <div className={styles.ellipsis}>
              <Icon icon="spLock" />
            </div>
            <div className={styles.bullet}>
              <h3>Investments are shown only in percentages</h3>
              <p>Cash and dollar value of your investments remain private</p>
            </div>
          </li>
          <li>
            <div className={styles.ellipsis}>
              <Icon icon="peopleChanging" />
            </div>
            <div className={styles.bullet}>
              <h3>You can change your mind at any time</h3>
              <p>You can take down your public profile page at any time</p>
            </div>
          </li>
        </ul>
        <div className={styles.typicalTRimage}></div>
      </div>
    </main>;
  }
}