import * as React from 'react';
import * as classNames from 'classnames';
import TrDropzone_ from '../tr-dropzone';

export default class ProgressBar extends React.PureComponent<{
  actTwo: any;
  actThree: any;
  progress: any;
}> {
  progression(number, isNext, isComplete, tooltip, lastStep = false) {
    return (
      <div
        className={classNames('progress-step', {
          isNext,
          lastStep,
          firstStep: number === 1,
          isComplete
        })}
      >
        <div
          className={classNames(
            'progress-tooltip',
            `progress-tooltip-${number}`
          )}
        >
          {tooltip}
        </div>
        <div className={classNames('progress-state-title')}>
          <span className={classNames({ isComplete })}>
            {isComplete ? '' : number.toString()}
          </span>
        </div>
        <div className={classNames('progress-state-fill')} />
      </div>
    );
  }

  render() {
    const { actTwo, actThree, progress } = this.props;
    const TrDropzone = TrDropzone_ as any;
    return (
      <div className="progressBar">
        <div className="progressText">Progress</div>
        {this.progression(
          1,
          false,
          progress > 0,
          <div>Opened your portfolio!</div>
        )}
        {this.progression(
          2,
          progress === 1,
          progress > 1,
          <div>
            Fill in <b onClick={actTwo}>your details</b>
          </div>
        )}
        {this.progression(
          3,
          progress === 2,
          progress > 2,
          <div>
            Add amount of shares to <b onClick={actThree}>My Holdings</b>
          </div>
        )}
        {this.progression(
          4,
          progress === 3,
          progress > 3,
          <div>
            Upload{' '}
            <TrDropzone>
              <b> photo</b>
            </TrDropzone>{' '}
            to your portfolio
          </div>,
          true
        )}
      </div>
    );
  }
}
