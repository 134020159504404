import { colors } from "sp/common/lib/utils";

export const noIconRoutes = ['screener'];

export const href = path => `/${path.join('/')}`;

export const color = (isActive, theme) =>
  isActive ? colors('colorAccent1', theme) : '#fff';

export const closeIfOpen = (routeNavState, toggleRouteNav) => () =>
  routeNavState.get('isActive') && toggleRouteNav(routeNavState);

export const byId = activeRouts => i => i.id === activeRouts;

export const routeNavDefaultSvgParams = (props) => {
  return ({
    height: '27',
    width: '27',
    ...props,
  });
}