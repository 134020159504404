import { selectTblType } from '../dashboard/selectors';
import reporter from '../analytics';

const restrictions = {
  bestAnalystConsensus: (tblType: 'basic' | 'advanced') =>
    `sp-holdings-${tblType}table-top-analyst-consensus`,
  bloggerSentiment: (tblType: 'basic' | 'advanced') =>
    `sp-holdings-${tblType}table-blogger-sentiment`,
  insiderSentiment: (tblType: 'basic' | 'advanced') =>
    `sp-holdings-${tblType}table-insider-sentiment`,
  hedgefundSentiment: (tblType: 'basic' | 'advanced') =>
    `sp-holdings-${tblType}table-hedgefund-sentiment`,
  bestTarget: (tblType: 'basic' | 'advanced') =>
    `sp-holdings-${tblType}table-top-analyst-pt`
};

const defaultRestriction = 'sp-holdings-table-restricted-btn';

const events = {
  activateStock: 'my-holdings-toggle-stock',
  clickedRestricted: 'clicked-locked-feature',
  clickedRestrictedTrigger: type =>
    restrictions[type](selectTblType(window.__state__.dashboard)) ||
    defaultRestriction,
  changedSharesTotal: ' changed-number-of-shares',
  changedPurchasePrice: ' changed-purchase-price',
  clickedAddStocks: type => `clicked-${type}-add-stocks`,
  clickedImportPortfolio: (type = 'empty-holdings') =>
    `clicked-import-portfolio-${type}`,
  deleteStock: 'confirmed-stock-removal',
  clickedExportCSV: 'clicked-download-csv'
};

const report = reporter(events);

export default report;
