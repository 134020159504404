import { PortfolioPrivacy } from "sp/common/types";
import { suits } from "sp/common/enums";
import * as React from 'react';
import * as styles from './styles.istyl';
import { Icon } from 'tipranks-icons';
import * as classNames from "classnames";
import { VariableSizeSpinner } from "sp/browser/Spinner/index.react";
import { toOnboarding } from "sp/browser/pages/PublicPortfolio/Onboarding/utils";
import ReactSelect from 'react-select';
import { OptionProps } from 'react-select/lib/components/Option';

export interface PortfolioPrivacyBtnProps {
  btnDisabled: boolean;
  portfolioPrivacyLevel: PortfolioPrivacy;
  portfolioPublicUrl: string | null;
  onChangePrivacy: (privacy: PortfolioPrivacy) => void;
  setPortfolioPrivacyComponentState: suits;
  hasntMadeAnyPortfolioPublicYet: boolean;
  everMadeThisPortfolioPublic: boolean;
}

class OptionComponent extends React.Component<OptionProps<any>> {
  public render() {
    const { isSelected, selectOption } = this.props;
    const option = this.props.data;
    
    const tickLoadingOrNothing = isSelected 
        ? <Icon className={styles.tick} icon="tick" />
          : null;

    return (<div onClick={_ => {
      selectOption!(option);
    }}
      className={styles.OptionComponent}>
      <span className={styles.option} data-value={option.value}
          >{option.label}{tickLoadingOrNothing}
      </span>
    </div> 
    );
  }
}

const ValueComponent = (option: any) => {
  const { data } = option;
  return (
    <span className={styles.selectedValue}>{data.label}</span>
  );
};


export class PortfolioPrivacyBtn extends React.PureComponent<PortfolioPrivacyBtnProps, {}> {
  render() {
    const {
      btnDisabled,
      portfolioPrivacyLevel,
      portfolioPublicUrl,
      onChangePrivacy,
      setPortfolioPrivacyComponentState,
      hasntMadeAnyPortfolioPublicYet,
      everMadeThisPortfolioPublic,
    } = this.props;
    const selectedValue = opts[portfolioPrivacyLevel]!;
    const openOnClick = !btnDisabled && !everMadeThisPortfolioPublic; // if private redirect to "make your profile public" onboarding
    const amIActive = (value) => value === selectedValue.value;
    const page = hasntMadeAnyPortfolioPublicYet ? 1 : 2;
    const showPlanetLink =
      !btnDisabled
      && portfolioPublicUrl
      // && portfolioPrivacyLevel !== PortfolioPrivacy.Private // TODO use this line when we'll support PublicUnlisted
      && portfolioPrivacyLevel === PortfolioPrivacy.Public;

    return <div className={classNames({ [styles.disabled]: btnDisabled }, styles.wrapper)}>
      {showPlanetLink && <a className={styles.myPublicProfileLink} href={portfolioPublicUrl!}>
        <Icon className={styles.planet} icon="globe" />
        <span>My Public<br />Profile</span>
      </a>}
      <div className={styles.whoCanSeeThis}>Who can see this portfolio?</div>
      <ReactSelect
        disabled={btnDisabled}
        className={classNames(styles.portfolioPrivacyLevelBtn)}
        arrowRenderer={() => <span className={styles.arrow}></span>}
        options={optsList}
        value={selectedValue}
        classNamePrefix="tr"
        searchable={false}
        clearable={false}
        isSearchable={false}
        isLoading={false}
        openMenuOnClick={true}
        loadingPlaceholder={<VariableSizeSpinner size={12} />}
        components= {{
          Option: OptionComponent,
          SingleValue: ValueComponent
        }}
        onChange={privacyLevel => !amIActive(privacyLevel.value) && onChangePrivacy(privacyLevel.value as PortfolioPrivacy)}
        onMenuOpen={() => openOnClick && toOnboarding(page)}
        onValueClick={(value, event) => alert(value)}
        openMenuOnFocus={false}
      />
    </div>
  }
}
const opts = {
  [PortfolioPrivacy.Public]: { value: PortfolioPrivacy.Public, label: <span><Icon className={styles.icon} icon="globe" />Public</span> },
  // TODO document this
  [PortfolioPrivacy.PublicUnlisted]: { value: PortfolioPrivacy.Private, label: <span><Icon className={styles.icon} icon="spLock" />Only Me</span> },
  [PortfolioPrivacy.Private]: { value: PortfolioPrivacy.Private, label: <span><Icon className={styles.icon} icon="spLock" />Only Me</span> },
}
const optsList = [
  opts[PortfolioPrivacy.Public],
  opts[PortfolioPrivacy.Private],
];