const PLAID_TOKEN_KEY = "P-TOKEN";
const isServer = typeof window === "undefined";
const _20_MINUTES = 1000/*ms*/ * 60/*min.*/ * 20;

interface PlaidTokenData {
  token: string;
  timeStamp: number;
} 

export function setPlaidTokenToStorage(token: string) {
  if (isServer || typeof token !== "string") { return; }
  const tokenData: PlaidTokenData = { token, timeStamp: Date.now() };
  localStorage.setItem(PLAID_TOKEN_KEY, JSON.stringify(tokenData));
}
export function clearPlaidTokenFromStorage() {
  if (isServer) { return }
  localStorage.removeItem(PLAID_TOKEN_KEY);
  
}
export function checkForPlaidTokenInStorage(): Boolean {
  const token = getPlaidTokenFromStorage();
  return Boolean(token)
}
export function getPlaidTokenFromStorage(): string | null {
  if (isServer) { return null; }
  const tokenDataStr = localStorage.getItem(PLAID_TOKEN_KEY);
  if (tokenDataStr) {
    const { token, timeStamp }: PlaidTokenData = JSON.parse(tokenDataStr)
    const nowTimeStamp = Date.now();
    const timeGap = Math.abs(timeStamp - nowTimeStamp);
    const isTokenValid = timeGap < _20_MINUTES
    return isTokenValid ? token : null
  } else {
    return null;
  }
}