import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import './OverviewSection.styl';

import OverviewSection from '../tr-portfolio-overview';
import { overViewSectionConfig, companyConstants } from '../../common/config';

import {
  selectActivePortfolio,
  selectEditableId,
  selectPortfolioOverviewStats
} from '../dashboard/selectors';

export default class OverviewSectionContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  render() {
    const { actions, dashboard, msg } = this.props;

    const activePortfolio = selectActivePortfolio(dashboard);
    const activePortfolioStats = selectPortfolioOverviewStats(dashboard);
    const isCombinedPortfolio =
      activePortfolio.get('id') ===
      companyConstants.get('combinedUserPortfolio');

    return (
      <div className="overview-section-container">
        <div className="overview-section">
          <OverviewSection
            activePortfolioStats={activePortfolioStats}
            changeEditable={actions.changeEditable}
            editableId={selectEditableId(dashboard)}
            isCombinedPortfolio={isCombinedPortfolio}
            msg={msg.dashboard.overviewSection.stats}
            setCashValue={actions.setCashValue}
            stats={overViewSectionConfig.get('stats')}
          />
        </div>
      </div>
    );
  }
}
