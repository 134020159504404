export default function mapStateToProps(state) {
  return {
    ...state,
    msg: state.intl ? state.intl.messages[state.intl.selectedLanguage] : {}
  };
}
// this is just an identity function for convention
export function mapStateToPropsNoMsg(state) {
  return state;
}
