import * as classNames from 'classnames';
import * as React from 'react';
import msg from './en';
import Slider from 'react-slick';
import './WelcomeBanner.styl';
import Spinner from '../Spinner/index.react';
import { absImgSrc } from '../../common/lib/utils';
import { MediaQuery, MediaQueryKey, mediaQueryKeyOrder } from 'sp/common/lib/mediaQuery';

const baseSettings = {
  dots: true,
  infinite: true,
  speed: 750,
  autoplaySpeed: 4000,
  arrows: false,
  draggable: false, //on desktop
  touchMove: false,
  fade: true,
  useCSS: true,
  pauseOnHover: false,
  dotsClass: 'slick-dots',
  autoplay: true
};

const preloaded = {
  '375': require(`./img/${process.env.THEME}/375 (1).png`),
  '1024': require(`./img/${process.env.THEME}/1024 (1).png`),
  '1366': require(`./img/${process.env.THEME}/1366 (1).png`),
  '1920': require(`./img/${process.env.THEME}/1920 (1).png`)
};

export interface WelcomeBannerProps {
  className?: string;
  mediaQuery: MediaQuery;
}

export class WelcomeBanner extends React.PureComponent<WelcomeBannerProps> {
  static defaultProps = { msg: msg.banner };
  slider: any = null; // TODO how to handle both HTMLElement | null

  move(slideNumber: number) {
    (this.slider as any).slickGoTo(slideNumber);
  }

  getCurrRes() {
    const { mediaQuery } = this.props;
    const res = getCurrentResolution(mediaQuery);
    const correspondingRes = {
      mobile360: 375,
      mobileM: 375,
      mobileL: 375,
      mobileXL: 375,
      mobileXXL: 375,
      laptopXXS: 375,
      tablet: 1024,
      laptop: 1024,
      laptopXS: 1024,
      laptopS: 1024,
      laptopM: 1366,
      laptopL: 1366,
      desktop1600: 1920,
      desktop: 1920,
      '4k': 1920
    };
    return correspondingRes[res];
  }

  render() {
    const { className } = this.props;
    const currRes = this.getCurrRes();

    return !currRes ? null : (
      <div className="welcome-banner-container">
        <div className={classNames('r' + currRes, 'welcome-banner', className)}>
          <Slider
            {...baseSettings}
            autoplay
            ref={c => (this.slider = c)}
            customPaging={i => <div onClick={() => this.move(i)} />}
          >
            <img src={preloaded[currRes]} alt="" />
            <img
              src={absImgSrc(
                `welcomebanner/${process.env.THEME}/${currRes} (2).png`
              )}
              alt=""
            />
            <img
              src={absImgSrc(
                `welcomebanner/${process.env.THEME}/${currRes} (3).png`
              )}
              alt=""
            />
            <img
              src={absImgSrc(
                `welcomebanner/${process.env.THEME}/${currRes} (4).png`
              )}
              alt=""
            />
            <img
              src={absImgSrc(
                `welcomebanner/${process.env.THEME}/${currRes} (5).png`
              )}
              alt=""
            />
          </Slider>
        </div>
      </div>
    );
  }
}

function getCurrentResolution(mediaQuery: MediaQuery): MediaQueryKey {
  let count = 0;
  let key = mediaQueryKeyOrder[count];
  while (mediaQuery.get(key) && count < mediaQueryKeyOrder.length) {
    const nextKey = mediaQueryKeyOrder[++count];
    if (mediaQuery.get(nextKey)) key = nextKey;
  }
  return key;
}
