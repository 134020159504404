import { Portfolio } from './types';

export const CHANGE_SELECTED_PORTFOLIO = 'CHANGE_SELECTED_PORTFOLIO';

export function changeSelectedPortfolio(newPortfolio: Portfolio) {
  return {
    type: CHANGE_SELECTED_PORTFOLIO,
    payload: newPortfolio
  };
}
