import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import { Loader, ErrorMsg, suitState } from './index';
import { suits } from '../../../common/enums';
import { ReactCSSTransitionGroup } from 'sp/browser/react-addons-css-transition-group';

const animate = children => (
  <ReactCSSTransitionGroup
    component="div"
    transitionAppear
    transitionAppearTimeout={2000}
    transitionEnterTimeout={2000}
    transitionLeaveTimeout={2000}
    transitionName="opacity-1000"
  >
    {children}
  </ReactCSSTransitionGroup>
);

export default class Suit extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    disableAnimation: PropTypes.bool,
    fetch: PropTypes.func,
    loaderType: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.any,
  };

  render() {
    const {
      children,
      className,
      disableAnimation = false,
      fetch,
      loaderType,
      name = '',
      state,
    } = this.props;

    const states = Array.isArray(state) ? state : [state];

    const wholeState = suitState(states);

    switch (wholeState) {
      case suits.LOADING: {
        return <Loader name={name} type={loaderType} />;
      }

      case suits.ERROR: {
        return <ErrorMsg fetch={fetch} name={name} />;
      }
    }

    return disableAnimation ? (
      <div className={className}>{children}</div>
    ) : (
      animate(children)
    );
  }
}
