import { initialState } from 'sp/common/dashboard/config';
import { List } from 'immutable';
import { holdingsSectionConfig } from 'sp/common/config';
import { suits, TypesOfPortfolios } from 'sp/common/enums';
import {
  Dashboard,
  ComponentStateKeys,
  ReduxProps,
  ComponentStateKeysFlat,
  PortfolioItemRecord,
  PortfolioRecord,
  DashboardStock,
} from 'sp/common/types';
import { Map } from 'immutable';
import { selectProccessStates } from 'sp/browser/dashboard/selectors';
import { Iterable } from 'immutable';

/**
 * TODO document this fucking bullshit
 * @param state Dashboard
 */
export const revive = (state: Dashboard) =>
  (initialState as any) // FUCK IMMUTABLE !!!!!!
    .merge(state)
    .set(
      'holdingsCols',
      state.get('holdingsCols')
        ? List(state.get('holdingsCols'))
        : List(holdingsSectionConfig.get('defaultCols')),
  );

export const apiSignature = (
  name: string,
  payload: { signature: string; timestamp: Date },
) => [
    ['apiSignature', `${name}.${payload.signature}`],
    (payload.timestamp || new Date()).getTime(),
  ];

export const setSuit = (component: string, state: any): [string[], any] => [
  ['componentStates', component],
  state,
];

export const data = (name, datum) => [['data', name], datum];

export const byProp = (prop, val) => item => item.get(prop) === val;

/**
 * overrides previous portfolio(s) data with new datum...
 */
export const portfolioData = (
  id: number,
  datum: Iterable<number, PortfolioItemRecord>,
  prop?: string,
) =>
  data('portfolios', (portfolios: List<PortfolioRecord>) => {
    const path: (number | string)[] = [portfolios.findIndex(byProp('id', id))];
    if (prop) {
      path.push(prop);
    }
    return portfolios.setIn(path, datum) as typeof portfolios;
  });

export const portfolioIndexById = (portfolios, id) =>
  portfolios.findIndex(portfolio => portfolio.get('id') === id);

export const portfolioItemByTicker = (items, ticker) =>
  items.findIndex(item => item.get('ticker') === ticker);

export const stockByTicker = (ticker, state) =>
  state.getIn(['data', 'stocks']).find(stock => stock.get('ticker') === ticker);

export const byWight = arr => (a, b) => {
  const weightA = arr.get(a) || 0;
  const weightB = arr.get(b) || 0;
  if (weightA < weightB) return -1;
  if (weightA > weightB) return 1;
  return 0;
};

// --- the old, magicky way of doing things --- //
// "le composition of le functions"
export const errorSuit = name => setSuit(name, suits.ERROR);
export const successSuit = name => setSuit(name, suits.SUCCESS);
export const loadingSuit = name => setSuit(name, suits.LOADING);
// --- the normal, javascript way of not fucking up the whole codebase and giving a shit about your peers --- //
export const setComponentState = (
  dashboard: Dashboard,
  name: ComponentStateKeysFlat,
  suit: suits,
) =>
  dashboard.set(
    'componentStates',
    dashboard.get('componentStates').set(name, suit),
  );
export const setStateError = (
  dashboard: Dashboard,
  name: ComponentStateKeysFlat,
) => setComponentState(dashboard, name, suits.ERROR);
export const setStateLoading = (
  dashboard: Dashboard,
  name: ComponentStateKeysFlat,
) => setComponentState(dashboard, name, suits.LOADING);
export const setStateSuccess = (
  dashboard: Dashboard,
  name: ComponentStateKeysFlat,
) => setComponentState(dashboard, name, suits.SUCCESS);

// TODO write a generic stock updating function that encapsulates the knowledge needed to update stock information
// export function updateStocks<T extends { ticker: string }>(stockDetails: T[]): (state: Dashboard) => Dashboard {
//   return (dashboard: Dashboard) => 
//     dashboard
//       .updateIn(['data', 'stocks'], (stocks: List<DashboardStock>) => 
//       stocks.map(stock => {
//         const detail = stockDetails.find(d => d.ticker === stock.get('ticker'))
//         if(!detail) return stock;
//         const 
//         for (let i = 0; i < ; i++) {

//         }

//         return stock;
//       })
//     )
//       .updateIn(['data', 'portfolios'], updateDetails(details, portfolioId))
//       .updateIn(['data', 'portfolios'], updateDetails(details, companyConstants.get('avgPortfolioId')))
//       .updateIn(['data', 'portfolios'], updateDetails(details, companyConstants.get('bestPortfolioId')));
// }


export const updatePortfolioItems = (portfolioId: number, updateFn: (items: List<PortfolioItemRecord>) => List<PortfolioItemRecord>) =>
  (portfolios: List<PortfolioRecord>) =>
    portfolioId > -1
      ? portfolios.updateIn(
        [portfolioIndexById(portfolios, portfolioId), 'items'],
        updateFn,
      )
      : portfolios;

export const portfolioGainPath = (portfolios: List<PortfolioRecord>, expertPortfolioID: number) => [portfolioIndexById(portfolios, expertPortfolioID), 'stats', 'gain'];
