import { List } from 'immutable';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

 import { isAllowed } from '../../common/auth/selectors';

import Details from './details';

export default class DlWrapper extends Component {
  static propTypes = {
    details: PropTypes.instanceOf(List),
    enums: PropTypes.object,
    isMissingShares: PropTypes.bool,
    item: PropTypes.object,
    msg: PropTypes.object,
    showPromotion: PropTypes.func,
    viewerPlan: PropTypes.string
  };

  onRestrictionButtonClick = (plan, detail) => {
    const { showPromotion } = this.props;
    return () => showPromotion(plan, detail);
  };

  render() {
    const {
      details,
      enums,
      msg,
      viewerPlan,
      item,
      isMissingShares
    } = this.props;

    return (
      <div className="dl-wrapper">
        {details.map(detail => {
          const DetailCell = Details(detail.get('id'));
          const isRestricted = !isAllowed(detail.get('minPlan'), viewerPlan);
          const isMissingSharesWarning =
            detail.get('missingShareIndication') &&
            item.get('sharesTotal') === 0;

          return (
            <dl
              className={classNames({
                doesNeedFakeLoader: detail.get('doesNeedFakeLoader'),
                missingIndication: isMissingSharesWarning,
                noTitle: detail.get('noTitle')
              })}
              key={detail.get('id')}
            >
              <dt>
                <div className="tooltip">
                  {msg.coltooltips[detail.get('id')]}
                </div>
                {msg.cols[detail.get('id')]}
              </dt>
              <dd>
                {detail.get('doesNeedFakeLoader') && (
                  <div className="fake-loading">
                    <img
                      alt="Loading..."
                      src="/assets/img/loading_smallest.gif"
                    />
                  </div>
                )}
                {isMissingSharesWarning && (
                  <div className="message">
                    Add no. of shares to get accurate analysis on your portfolio
                  </div>
                )}
                {isRestricted ? (
                  <button
                    className="upgrade"
                    onClick={this.onRestrictionButtonClick(
                      'premium',
                      detail.get('id')
                    )}
                  >
                    Premium
                  </button>
                ) : (
                  <div className="detail-content">
                    <DetailCell
                      {...this.props}
                      msg={enums}
                      col={detail}
                      visualized
                    />
                  </div>
                )}
              </dd>
            </dl>
          );
        })}
      </div>
    );
  }
}
