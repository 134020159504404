export default {
  title: 'Join now to create your\nPersonalized Smart Portfolio',
  terms: 'By clicking “Sign Up” you agree to TipRanks.com {termLink}',
  input: {
    email: 'Email',
    password: 'Password'
  },
  submit: 'Sign Up',
  termLinkLabel: 'terms and conditions',
  errorMessage:
    'The password you entered is incorrect or the email does not belong to any account.',
  alreadyUser: 'Already have an account? {loginBtn}',
  loginBtnLabel: 'Login'
};
