import analyticsFactory from 'tipranks-analytics';

import { BROWSER_DEVELOPMENT, dashboardPath } from '../common/config';
import { selectActivePortfolio } from './dashboard/selectors';
import localStorage from './components/personsList/localstorage';
import { msg } from 'sp/common/intl/messages/en';

const routeNames = msg.dashboard.nav.routes;

// TODO shouldn't access store directly, store data should be passed in
const getReporter = analyticsFactory({
  state: {
    location: {
      get path() {
        const { pathname } = location;
        // this is a bit much
        const dottedPathname = pathname
          .split('/')
          .filter(Boolean)
          .join('.');
        return (
          routeNames[dottedPathname.replace(`${dashboardPath}.`, '')] ||
          pathname
        );
      },
      get url() {
        return location.href;
      }
    },
    auth: {
      get planId() {
        const state = window.__state__;

        return state.auth.getIn(['users', 'viewer', 'plan']);
      }
    }
  },
  storage: {
    getItem(id) {
      // TODO clean this piece of crap toilet code
      if (id === 'currentPlan') {
        const state = window.__state__;
        return state.auth.getIn(['users', 'viewer', 'plan']);
      } else {
        return localStorage.getItem(id);
      }
    },
    setItem: (...args) => localStorage.setItem(...args)
  },
  defaultProps: {
    get portfolioName() {
      const active = selectActivePortfolio(window.__state__.dashboard);
      return active.get('name');
    },
    _origin: location.origin,
    get isImported() {
      const isImported = selectActivePortfolio(window.__state__.dashboard).get(
        'isImported'
      );
      return isImported ? 'true' : 'false';
    }
  },
  gaUa: "",//process.env.THEME === 'nasdaq' ? 'UA-82198348-1' : 'UA-38500593-6',
  apiUrl: '//d.tipranks.com/api/proxy/proxy',
  loadCrazyEggOutsideIsraelOnly: false,
  logger: false, //BROWSER_DEVELOPMENT && console,
  kissmetricsKeys: {
    internal: '',
    api: ''
  }
});

export default getReporter;