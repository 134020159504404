import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

//import { Col } from '../conf';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class TrDashboardHoldingsDetailHighLow extends Component {
  static propTypes = {
    //col: PropTypes.instanceOf(Col),
    item: PropTypes.object,
    msg: PropTypes.object,
    showPromotion: PropTypes.func
  };

  render() {
    const { col, item, msg, showPromotion } = this.props;

    return (
      <div className="highLow">
        <PortfolioItemSelector
          showPromotion={showPromotion}
          item={item}
          msg={msg}
          type={col.get('id')}
          visualized
        />
      </div>
    );
  }
}
