import {
  ComponentStates,
  Dashboard,
  DashboardStock,
  MgmtFeeDashboardRecord,
  MgmtFeeRecordJSRecord,
  MgmtFeesDashboard,
  PortfolioGainSet_NotImmutable,
  PortfolioGainSetRecord,
  PortfolioItemRecord,
  PortfolioItemRecord_NotImmutable,
  PortfolioRecord,
  ReduxProps,
  DashboardGainRecord,
  AuthRecord,
  Dashboard_NotImmutable,
} from '../../common/types';
/*  this file contains functions that take this.props.dashboard and return
    things you want. All logic whos job is to select someting from the state
    shoud be here */

import { List, Map, Repeat, OrderedSet, Record } from 'immutable';
import { createSelector } from 'reselect';
// import { createSelector as createDebugSelector } from 'reselect-change-memoize';
import { portfoliosConfig } from '../../common/config';

import { MgmtFee, Portfolio, PortfolioStats } from '../../common/records';

import {
  apiCalls,
  companyConstants,
  holdingsSectionConfig,
} from '../../common/config';

import {
  descendtify,
  sortByDateIn,
  sortNumericallyIn,
  sortAlphabeticallyIn,
  sortAbsNumericallyIn,
} from '../../common/lib/sort';

import { CIM_majorHoldingsType } from 'sp/browser/tr-dashboard-avg-portfolio/CrowdInsightsMain';
import { isNumber } from 'lodash';
import { selectLoggedInUser, selectPortfoliosEverMadePublic } from 'sp/common/auth/selectors';
import { BetterImmutableInterface } from 'sp/common/immutableStuff';

const byProp = (prop, val) => item => item.get(prop) === val;

const notIn = arr => item =>
  !arr.map(x => x.toLowerCase()).includes(item.get('value').toLowerCase());

const sharesTotal = ['sharesTotal'];
const sharesValue = ['sharesValue'];
const changePercent = ['stock', 'price', 'changePercent'];

const outStocksWithNoShares = item => item.getIn(sharesTotal) > 0;

export const addPercent = type => stock =>
  stock.updateIn([`${type}Percent`], () => {
    const target = stock.getIn([type]);
    const amount = stock.getIn(['price', 'amount']);
    return target && amount ? target / amount - 1 : 0;
  });

export const selectStocks = (state: Dashboard) =>
  state.get('data').get('stocks');

export const selectAlerts = (state: Dashboard) =>
  state.get('data').get('alerts');

const isMissingShares = createSelector(
  (portfolio: PortfolioRecord) => portfolio.get('items'),
  items => {
    const itemsWithSharesSize = items.filter(outStocksWithNoShares).size;
    return 0 < itemsWithSharesSize && itemsWithSharesSize < items.size;
  },
);

const pushIf = (item, cond) => arr => (cond ? arr.push(item) : arr);

// this is the only selector who needs the entire state (needs media query)
// returns list of all active columns of the holdings tables
export const selectHoldingsCols = createSelector(
  ({ dashboard }) => dashboard.get('holdingsCols'),
  ({ mediaQuery }) => mediaQuery,
  (holdingsCols, mediaQuery) => {
    if (mediaQuery.get('laptop')) {
      return holdingsCols.toOrderedSet().toList();
    }
    if (mediaQuery.get('mobileXXL')) {
      return holdingsSectionConfig.get('mobileXXLFields');
    }
    if (mediaQuery.get('mobileXL')) {
      return holdingsSectionConfig.get('mobileXLFields');
    }
    if (mediaQuery.get('mobileL')) {
      return holdingsSectionConfig.get('mobileLFields');
    }
    if (mediaQuery.get('mobileM')) {
      return holdingsSectionConfig.get('phabletFields');
    }
    return holdingsSectionConfig.get('mobileFields');
  },
);

export const selectHoldingsPage = (state: Dashboard) =>
  state.get('holdingsPage');

export const selectAllPortfolios = (state: Dashboard) =>
  state.get('data').get('portfolios');

export const selectPortfolioNewsTicker = (state: Dashboard) =>
  state.get('portfolioNewsTicker');

// select List of all portfolios
export const selectPortfoliosWithCombined = createSelector(
  (state: Dashboard) => state.get('data').get('portfolios'),
  (state: Dashboard) => state.get('deletedPortfolios'),
  (portfolios, deleted) => {
    const outDeletedPortfolios = portfolio =>
      !deleted.includes(portfolio.get('id'));

    const outAvgPortfolio = portfolio =>
      companyConstants.get('avgPortfolioId') !== portfolio.get('id');

    return portfolios.filter(outDeletedPortfolios).filter(outAvgPortfolio);
  },
);

const outCombinedPortfolios = portfolio =>
  portfolio.get('id') !== companyConstants.get('combinedUserPortfolio') &&
  portfolio.get('id') !== companyConstants.get('bestPortfolioId');

export const selectPortfolios = createSelector(
  selectPortfoliosWithCombined,
  portfolios => {
    const combinedPortfolio = portfolios.find(
      portfolio =>
        portfolio!.get('id') === companyConstants.get('combinedUserPortfolio'),
    );
    const withoutCombined = portfolios.filter(outCombinedPortfolios);
    const withCombined = combinedPortfolio
      ? withoutCombined.concat([combinedPortfolio])
      : withoutCombined;
    if (withoutCombined.size === 1) {
      return withoutCombined;
    }
    return withCombined.sortBy(portfolio => {
      return portfolio!.get('id') ===
        companyConstants.get('combinedUserPortfolio')
        ? Math.max.apply(
          Math,
          portfolios.map(portfolio => portfolio.get('id')).toJS(),
        ) + 1
        : portfolio!.get('id');
    });
  },
);

export const selectUserActualPortfolios = createSelector(
  selectPortfoliosWithCombined,
  portfolios => portfolios.filter(outCombinedPortfolios),
);

export const selectUserNeverMadePublicPortfolios = createSelector(
  selectUserActualPortfolios,
  (_, auth: AuthRecord) => selectPortfoliosEverMadePublic(auth),
  (actualPortfolios, portfoliosMadePublic) =>
    actualPortfolios.filter(portfolio =>
      !portfoliosMadePublic.some(publicPortfolio =>
        portfolio.get('id') === publicPortfolio.get('id'))))

export const selectNonImportedPortfolios = createSelector(
  selectUserActualPortfolios,
  portfolios => portfolios.filter(portfolio => !portfolio!.get('isImported')),
);

export const selectPortfoliosWithPerformance = createSelector(
  (dashboard: Dashboard, auth: AuthRecord) => selectUserActualPortfolios(dashboard),
  (dashboard, auth: AuthRecord) => selectLoggedInUser(auth),
  (portfolios, user) => portfolios
    .map(p => user.get('portfoliosWithPerformance').find(p2 => p.get('id') === p2.get('id')))
    .filter(Boolean)
    .toJS()
);

// export const selectCombinedPortfolio = createSelector(
//   selectPortfolios,
//   portfolios => portfolios.find(portfolio => portfolio.get('id') === companyConstants.get('combinedUserPortfolio'))
// )
//
// export const selectAllPortfoliosExceptCombined = createSelector(
//   selectPortfolios,
//   portfolios => portfolios.filter(portfolio => portfolio.get('id') !== companyConstants.get('combinedUserPortfolio'))
// )

export const selectAllCashValues = createSelector(
  selectPortfolios,
  portfolios =>
    portfolios.reduce(
      (total = 0, portfolio) => total + portfolio!.get('cashValue'),
      0,
    ),
);

export const selectActivePortfolio = createSelector(
  selectPortfolios,
  (state: Dashboard) => state.get('activePortfolioId'),
  selectStocks,
  selectAllCashValues,
  (portfolios, activePortfolioId, stocks, allCashValues) => {
    const portfolio =
      portfolios.find(byProp('id', activePortfolioId)) || new Portfolio();

    const itemsWithPe = portfolio.get('items').filter(item => {
      const stock = stocks.find(
        stock => stock.get('ticker') === item!.get('ticker'),
      );
      return stock && stock.get('pe') > 0;
    });
    const totalPe = itemsWithPe.reduce((total, item) => {
      const stock = stocks.find(
        stock => stock.get('ticker') === item!.get('ticker'),
      );
      return total + stock.get('pe');
    }, 0);
    const pe = totalPe / itemsWithPe.size;

    const updateCashValue = cashValue =>
      portfolio.get('id') === companyConstants.get('combinedUserPortfolio')
        ? allCashValues
        : cashValue;

    return (portfolio as any)
      .update('modes', pushIf('missingShares', isMissingShares(portfolio)))
      .setIn(['stats', 'pe'], pe)
      .update('cashValue', updateCashValue) as PortfolioRecord;
  },
);

export const selectAvgPortfolio = createSelector(
  selectAllPortfolios,
  portfolios => {
    const portfolio = portfolios.find(
      portfolio =>
        portfolio.get('id') === companyConstants.get('avgPortfolioId'),
    );
    if (!portfolio) {
      if (process.env.IS_RUN_LOCAL)
        console.warn('Using an empty portfolio for `selectAvgPortfolio` :(');
      return new Portfolio();
    }

    return portfolio;
  },
);

export const selectBestPortfolio = createSelector(
  selectAllPortfolios,
  portfolios => {
    const portfolio = portfolios.find(
      portfolio =>
        portfolio!.get('id') === companyConstants.get('bestPortfolioId'),
    );
    if (!portfolio) {
      if (process.env.IS_RUN_LOCAL)
        console.warn('Using an empty portfolio for `selectBestPortfolio` :(');
      return new Portfolio();
    }

    return portfolio;
  },
);

export const selectActivePortfolioGains = createSelector(
  selectActivePortfolio,
  portfolio =>
    portfolio
      .get('stats')
      .get('gain')
      .toJS() as PortfolioGainSet_NotImmutable,
);

export const selectSomePerformanceGains = createSelector(
  (dashboard: Dashboard) => dashboard.getIn(['data', 'gains'])
  , function adjustGains(gains: DashboardGainRecord, defaultValue = 0) {
    const baseIterator = gains.getIn([0, 'snpGains', 'monthGains']);
    const pickMonths = type =>
      gains.getIn([
        0, // for some reason gains comes back from server as list ¯\_(ツ)_/¯
        type,
      ]);
    const pickMonth = (months, month, path: string[] = []) => {
      if (months && months.size) {
        const value = months
          .getIn(path.concat('monthGains'))
          .find(
            pMonth =>
              pMonth.get('month') === month.get('month') &&
              pMonth.get('year') === month.get('year'),
        );
        if (value)
          return value.get('gain');
      }
      return defaultValue;
    }

    const portfolioMonthGains = pickMonths('portfolioMonthGains');
    const avgPortfolioGains = pickMonths('averagePortfolioGains');
    const bestPortfolioGains = pickMonths('bestPortfolioGains');

    return (
      // TODO rewrite this to include months from all, like in portfolio performance box.
      baseIterator
        .map(aDate =>
          Map({
            month: aDate.get('month'),
            year: aDate.get('year'),
            spy: aDate.get('gain'),
            company: pickMonth(avgPortfolioGains, aDate),
            portfolio: pickMonth(portfolioMonthGains, aDate, ['0']), // returns as list because supports returning gains for multiple portfolios at once.
            bestPortfolioGains: pickMonth(bestPortfolioGains, aDate),
          }),
      )
    );
  }
);

export const selectAnnualGainOnlySinceRelevant = createSelector(
  selectSomePerformanceGains,
  gains => {
    const monthsInYear = 12;
    const onlySinceRelevant = gains.filter(
      gain => gain.get('portfolio') && gain.get('portfolio') !== 0,
    );
    const lastGain = onlySinceRelevant.first();
    const next = Map({
      month: lastGain ? lastGain.get('month') : new Date().getMonth(),
      year: lastGain ? lastGain.get('year') : new Date().getFullYear(),
    });
    return Repeat(
      null,
      monthsInYear - onlySinceRelevant.size,
    ).reduce((gains, gain, index) => {
      const nextMonth = next.get('month') + index + 1;
      return gains.unshift(
        Map({
          month: (nextMonth - 1) % monthsInYear + 1,
          year: next.get('year') + (nextMonth > monthsInYear ? 1 : 0),
          spy: null,
          company: null,
          portfolio: null,
        }),
      );
    }, onlySinceRelevant);
  },
);

// select to column by which the holdings table is sorted by
export const selectoHoldingsSortBy = (dashboard: Dashboard) =>
  dashboard.get('holdingsSortBy');

// return true/false if the current sort descend/ascend
export const selectIsHoldingsSortDescending = dashboard =>
  dashboard.get('isholdingsSortDescending');

// select all componentStates
export const selectProccessStates = (dashboard: Dashboard) =>
  dashboard.get('componentStates');

export const selectIsNewsSentimentsLoading = dashboard =>
  dashboard.getIn(['componentStates', 'newsSentiments']);

export const selectIsPriceLoadingFor = (ticker, dashboard) =>
  dashboard.getIn(['componentStates', 'prices', ticker]);

export const selectIsMgmtFeesLoading = createSelector(
  selectProccessStates,
  states => states.get('mgmtFees') === 'LOADING',
);

export const selectPasswordChangeState = createSelector(
  selectProccessStates,
  (state: ComponentStates) => state.get('changePassword'),
);
export const selectEmailBombardmentServerState = createSelector(
  selectProccessStates,
  (state: ComponentStates) => state.get('changeEmailFrequency'),
);

export const selectIsUserPerfLoading = createSelector(
  selectProccessStates,
  states =>
    states.get('userPerf') === 'LOADING' ||
    states.get('userInfo') === 'LOADING',
);

export const selectIsUserPerfSuccess = createSelector(
  selectProccessStates,
  states =>
    states.get('userPerf') === 'SUCCESS' ||
    states.get('userInfo') === 'SUCCESS',
);

// select all stats
export const selectPortfolioStats = createSelector(
  selectActivePortfolio,
  portfolio => portfolio.get('stats'),
);

export const selectAvgPortfolioStats = createSelector(
  selectAvgPortfolio,
  portfolio => portfolio.get('stats'),
);

export const selectBestPortfolioStats = createSelector(
  selectBestPortfolio,
  portfolio => portfolio.get('stats'),
);

// select warnings of the current portfolio
export const selectPortfolioWarnings = createSelector(
  selectActivePortfolio,
  portfolio => portfolio.get('warnings'),
);

// select the type of news to show: positive/negative/all
export const selectOverviewPortfolioNewsTab = (state: Dashboard) =>
  state.get('overviewPortfolioNewsTab');

// select news of the current portfolio
export const selectPortfolioNews = createSelector(
  selectActivePortfolio,
  portfolio =>
    portfolio
      .get('news')
      .sort(descendtify(sortByDateIn(['addedOn']), true))
      .sort(descendtify(sortByDateIn(['date']), true)),
);

// select events of the current portfolio, sorted choronlogiclaly
export const selectPortfolioEvents = createSelector(
  selectActivePortfolio,
  portfolio =>
    portfolio
      .get('events')
      .map(event => event.set('date', new Date(`${event.get('date')}`)))
      .sort(descendtify(sortByDateIn(['date']), false)),
);

// select all upcoming events of the current portfolio
export const selectPortfolioEventsFuture = createSelector(
  selectPortfolioEvents,
  events =>
    events.filter(event => event.get('date').getTime() > new Date().getTime()),
);

// select the current editable item
export const selectEditableId = (state: Dashboard) => state.get('editableId');

// select the ignoeApis list
export const ignoreApisSelector = createSelector(
  (state: Dashboard) => state.get('apiSignature'),
  apiSignature =>
    apiSignature
      .entrySeq()
      .filter(
        ([name, time]: [string, number]) =>
          new Date().getTime() - time < apiCalls.memoizationDuration,
    )
      .map(([name, time]) => name),
);

// select list of tickers whose pending to add to current portfolio
export const pendingStocksToAddSelector = (state: Dashboard) =>
  state.get('pendingStocksToAdd');

// select the query by which the user search stocks
export const dataListQuerySelector = (state: Dashboard) => state.get('dataListQuery');

const isToday = date => date.toDateString() === new Date().toDateString();

export const adaptPortfolioItemsInTheSelectorForSomeReason = (
  stocks: List<DashboardStock>,
  isMarketOpened: boolean,
  cashValue: number,
  listOfPortfolioItems: List<PortfolioItemRecord>,
) => {
  // we're performing some initial .map here, because we need them
  // to count totalPortfolioValue, and only then continue with the
  // rest of the preparations.
  const items = listOfPortfolioItems
    .map(item => {
      const stock = stocks.find(
        stock => stock.get('ticker') === item.get('ticker'),
      );
      if (process.env.IS_RUN_LOCAL && !stock) console.warn('This is part of the "i assume the data should be here without actually expressing that depedency via code" architecture of the smart portfolio. This whole code is bad and should be refactored.');
      return stock ? item!.set('stock', stock) : item;
    })
    .map(function setSharesValue(item) {
      const stock = stocks.find(
        stock =>
          stock.get('ticker').toLowerCase() ===
          item.get('ticker').toLowerCase(),
      );
      if (!stock) return item.set('sharesValue', 0);
      const stockPrice = stock.getIn(['price', 'amount']) || 0;
      const exchangeRate = stock.get('exchangeRate');

      return item.set('sharesValue', isNumber(stockPrice)
        ? stockPrice * item.get('sharesTotal') * parseFloat(exchangeRate || 1)
        : 0);
    });
  const totalPortfolioValue =
    items.reduce(
      (total, item) => total + item.get('sharesValue'),
      0,
    ) + cashValue;

  const itemWithSharesValueSize = items.filter(
    item => item.get('sharesTotal') > 0,
  ).size;

  return items
    .map(item => {

      // TODO handle amount is zero
      const amount = item
        .get('stock')
        .get('price')
        .get('amount')!;

      let gainSinceAdded =
        (amount - item.get('addedPrice')) / item.get('addedPrice');

      const sincePurchaseGainAmount =
        (amount - item.get('purchasePrice')) * item.get('sharesTotal');

      const ticker = item.get('stock').get('ticker');
      const purchasePrice = item.get('purchasePrice');
      const sincePurchaseGain = (amount - purchasePrice) / purchasePrice;
      // if (process.env.NOT_PROD) console.info(`Calculating gain (sincePurchaseGain) for ${ticker}, (stock.price.amount{${amount}} - purchasePrice{${purchasePrice}}) / purchasePrice{${purchasePrice}}, `)

      const hasBeenAddedToday = isToday(new Date(item.get('addedDate')));

      // TODO COPIED INTO PERF SELECTORS
      if (gainSinceAdded === 0 && hasBeenAddedToday) {
        gainSinceAdded = item
          .get('stock')
          .get('price')
          .get('changePercent')!;
      }

      /* TIPRANKS-12383:
      /* ***************
      /* holdings table: gain since added - when user adds a stock while the
      /* markets are closed, show gain since added = 0
      /* *************** */
      if (!isMarketOpened && hasBeenAddedToday) {
        gainSinceAdded = 0;
      }

      return item
        .set(
          'percentPortfolio',
          itemWithSharesValueSize > 0
            ? item.get('sharesValue') / totalPortfolioValue
            : 1 / items.size,
      )
        .set('sincePurchaseGain', sincePurchaseGain)
        .set('sincePurchaseGainAmount', sincePurchaseGainAmount)
        .set('gainSinceAdded', gainSinceAdded)
        .update('stock', addPercent('target'))
        .update('stock', addPercent('bestTarget'));
    })
    .sort(sortAlphabeticallyIn(['stock', 'ticker']));
};

const selectPortfolioItemsWrapper = (
  portfolio: PortfolioRecord,
  stocks: List<DashboardStock>,
  isMarketOpened = false,
) => {
  return adaptPortfolioItemsInTheSelectorForSomeReason(
    stocks,
    isMarketOpened,
    portfolio.get('cashValue'),
    portfolio.get('items'),
  );
};

export const selectIsMarketOpened = (state: Dashboard) => state.get('isMarketOpened');

// select the items in the current portfolio, together with the stocks info (evet PortfolioItem has stock property)
export const selectPortfolioItems = createSelector(
  selectActivePortfolio,
  selectStocks,
  selectIsMarketOpened,
  selectPortfolioItemsWrapper,
);

export const selectPortfolioBeta = createSelector(
  selectPortfolioItems,
  items => {
    const result = items.reduce(
      (total, item) =>
        total! +
        item!.get('percentPortfolio') *
        item!
          .get('stock')
          .get('price')
          .get('beta'),
      0,
    );
    return result;
  },
);

export const selectActivePortfolioMgmtFees = createSelector(
  selectPortfolioItems,
  items => {
    const outItemsWithoutMgmtFees = item =>
      item.get('stock').get('managementFee') > 0;

    const itemsWithManagementFee = items.filter(outItemsWithoutMgmtFees);

    const mgmtFeesAmount = itemsWithManagementFee.reduce(
      (total, item) =>
        total! +
        item!.get('sharesValue') * item!.get('stock').get('managementFee'),
      0,
    );

    const percentWithFees = item =>
      item.get('percentPortfolio') /
      itemsWithManagementFee.reduce(
        (total, item) => total! + item!.get('percentPortfolio'),
        0,
      );

    const mgmtFeesTotal = itemsWithManagementFee.reduce(
      (total, item) =>
        total! +
        percentWithFees(item) * item!.get('stock').get('managementFee'),
      0,
    );

    return Map({
      amount: mgmtFeesAmount,
      percent: mgmtFeesTotal,
    });
  },
);

export const selectPortfolioDailyGainers = createSelector(
  selectPortfolioItems,
  items => {
    const changePercentPath = ['stock', 'price', 'changePercent'];

    const outLosers = item => item.getIn(changePercentPath) > 0;

    return items
      .filter(outLosers)
      .sort(descendtify(sortNumericallyIn(changePercentPath)));
  },
);

export const selectPortfolioDailyLosers = createSelector(
  selectPortfolioItems,
  items => {
    const changePercentPath = ['stock', 'price', 'changePercent'];

    const outGainers = item => item.getIn(changePercentPath) < 0;

    return items.filter(outGainers).sort(sortNumericallyIn(changePercentPath));
  },
);

export const selectAvgPortfolioItems = createSelector(
  selectAvgPortfolio,
  selectStocks,
  selectPortfolioItemsWrapper,
);

export const selectBestPortfolioItems = createSelector(
  selectBestPortfolio,
  selectStocks,
  selectPortfolioItemsWrapper,
);

export const selectMajorHoldingsType = (state: Dashboard) => state.get('majorHoldingsType');

export const selectMajorHoldingsSector = (state: Dashboard) =>
  state.get('majorHoldingsSector');

const selectMajorPortfolioItemsWrapper = (
  items: List<PortfolioItemRecord>,
  requestedType: CIM_majorHoldingsType,
  sector: string,
  take = 20,
) => {
  const byType = (item: PortfolioItemRecord) => {
    const itemType = item.get('stock').get('type');
    const isStock = ['stock', 'secondaryticker'].includes(itemType!); // TODO non-null assertion operator is bad.
    switch (requestedType) {
      case 'stock':
        return isStock;
      case 'other':
        return !isStock;
      default: {
        if (process.env.NOT_PROD) { console.warn('requestedType problem in selectMajorPortfolioItemsWrapper'); }
        return !isStock;
      }
    }
  }
  // const byrequestedType = (item: PortfolioItemRecord) =>
  //   item.get('stock').get('requestedType') === requestedType || (requestedType === 'other' && item.get('stock').get('requestedType') !== 'stock');

  const bySector = (item: PortfolioItemRecord) =>
    sector === 'general' ||
    requestedType === 'other' ||
    item.get('stock').get('sector') === sector;

  const outItemsWithoutShares = (item: PortfolioItemRecord) =>
    item.get('percentPortfolio') > 0;

  const majorItems = items
    .filter(byType)
    .filter(bySector)
    .filter(outItemsWithoutShares)
    .sort(descendtify(sortNumericallyIn(['percentPortfolio'])))
    .take(take);

  return majorItems;
};

export const selectMajorPortfolioItems = createSelector(
  selectPortfolioItems,
  selectMajorHoldingsType,
  selectMajorHoldingsSector,
  selectMajorPortfolioItemsWrapper,
);
export const selectMajorPortfolioStocks = createSelector(
  selectPortfolioItems,
  () => 'stock',
  selectMajorHoldingsSector,
  selectMajorPortfolioItemsWrapper,
);

export const selectAllMajorPortfolioStocks = createSelector(
  selectPortfolioItems,
  () => 'stock',
  () => 'general',
  selectMajorPortfolioItemsWrapper,
);

export const selectMajorAvgPortfolioItems = createSelector(
  selectAvgPortfolioItems,
  selectMajorHoldingsType,
  selectMajorHoldingsSector,
  selectMajorPortfolioItemsWrapper,
);

export const selectMajorBestPortfolioItems = createSelector(
  selectBestPortfolioItems,
  selectMajorHoldingsType,
  selectMajorHoldingsSector,
  selectMajorPortfolioItemsWrapper,
);

const selectMostVolatileItemsWrapper = items => {
  const outItemsWithoutBeta = item =>
    item.getIn(['stock', 'price', 'beta']) >= 0;

  return items
    .filter(outItemsWithoutBeta)
    .sort(descendtify(sortAbsNumericallyIn(['stock', 'price', 'beta'])))
    .take(3);
};

export const selectMostVolatileItems = createSelector(
  selectPortfolioItems,
  selectMostVolatileItemsWrapper,
);

export const selectAvgCompanyMostVolatileItems = createSelector(
  selectAvgPortfolioItems,
  selectMostVolatileItemsWrapper,
);

export const selectBestCompanyMostVolatileItems = createSelector(
  selectBestPortfolioItems,
  selectMostVolatileItemsWrapper,
);

export const selectAvgPortfolioFunds = createSelector(
  selectAvgPortfolioItems,
  portfolioItems =>
    portfolioItems.filter(
      item => ['etf', 'fund'].includes(item!.get('stock').get('type')!), // TODO: non-null assertion operator is bad
    ),
);

export const selectBestPortfolioFunds = createSelector(
  selectBestPortfolioItems,
  portfolioItems =>
    portfolioItems.filter(
      item => ['etf', 'fund'].includes(item!.get('stock').get('type')!), // TODO: non-null assertion operator is bad
    ),
);

export const selectAvgCompanyFundsByPortfolioPercent = createSelector(
  selectAvgPortfolioFunds,
  funds =>
    funds.sort(
      (itemA, itemB) =>
        itemB.get('percentPortfolio') - itemA.get('percentPortfolio'),
    ),
);

export const selectBestCompanyFundsByPortfolioPercent = createSelector(
  selectBestPortfolioFunds,
  funds =>
    funds.sort(
      (itemA, itemB) =>
        itemB.get('percentPortfolio') - itemA.get('percentPortfolio'),
    ),
);

export const selectAvgCompanyFundsTopPortfolioPercent = createSelector(
  selectAvgCompanyFundsByPortfolioPercent,
  funds => funds.take(5),
);

export const selectBestCompanyFundsTopPortfolioPercent = createSelector(
  selectBestCompanyFundsByPortfolioPercent,
  funds => funds.take(5),
);

const selectTopDividendsItemsWrapper = items => {
  const outItemsWithoutYield = item => item.getIn(['stock', 'yield']);

  return items
    .filter(outItemsWithoutYield)
    .sort(descendtify(sortNumericallyIn(['stock', 'yield'])))
    .take(3);
};

export const selectTopDividendsItems = createSelector(
  selectPortfolioItems,
  selectTopDividendsItemsWrapper,
);

export const selectAvgCompanyTopDividendsItems = createSelector(
  selectAvgPortfolioItems,
  selectTopDividendsItemsWrapper,
);

export const selectBestCompanyTopDividendsItems = createSelector(
  selectBestPortfolioItems,
  selectTopDividendsItemsWrapper,
);

// select list of tickers from the current portfolio
export const selectActiveTickers = createSelector(selectPortfolioItems, items =>
  items.map(item => item.get('ticker')).toJS(),
);

export const selectActiveTickersLength = createSelector(
  selectPortfolioItems,
  items => items.size,
);

export const selectAvgPortfolioTickers = createSelector(
  selectAvgPortfolioItems,
  items => items.map(item => item.get('ticker')).toJS(),
);

export const selectBestPortfolioTickers = createSelector(
  selectBestPortfolioItems,
  items => items.map(item => item.get('ticker')).toJS(),
);

// select data from autocomplete
export const selectDataList = (state: Dashboard) =>
  state.get('dataList').map(item =>
    item.update(
      'label',
      currentValue =>
        `${currentValue}${item
          .get('value')
          .toLowerCase()
          .startsWith('tse:')
          ? ' (Toronto)'
          : ''}`,
    ),
  );
// select the user selection of stocks, before applying it to table
export const selectHoldingsColsPendings = (state: Dashboard) =>
  state.get('holdingsColsPendings');

// select the portfolio ID which is editable
export const selectEditablePortfolio = (state: Dashboard) =>
  state.get('editablePortfolioId');

// select the next editable portfolio name
export const selectEditablePortfolioValue = (state: Dashboard) =>
  state.get('editablePortfolioValue');

export const selectCashValue = createSelector(
  selectActivePortfolio,
  portfolio => portfolio.get('cashValue'),
);

// select current portfolio overview stats
export const selectPortfolioOverviewStats = createSelector(
  selectPortfolioItems,
  selectActivePortfolio,
  (items, activePortfolio) => {
    const sumOf = <T extends {}>(fn: (...args: T[]) => number) => (
      total: number,
      ...args: T[]
    ) => total + fn(...args);
    // TODO replace any with PortfolioItem
    const toSumOfChangePercent = sumOf<any>(item => item.getIn(changePercent));

    const toSumOfValues = sumOf<any>(item => item.getIn(sharesValue));

    const toSumOfSharesChanges = sumOf<any>(
      item =>
        item.getIn(changePercent) *
        item.getIn(sharesValue)
    );

    const devide = (a, b) => (b !== 0 ? a / b : 0);

    const itemsWithShare = items.filter(outStocksWithNoShares);

    const amount =
      itemsWithShare.reduce(toSumOfValues, 0) +
      activePortfolio.get('cashValue');

    const dailyGainVal = itemsWithShare.reduce(toSumOfSharesChanges, 0);
    const gain = activePortfolio.get('stats').get('gain');

    const dailyGainPercent =
      itemsWithShare.size > 0
        ? devide(dailyGainVal, amount)
        : items.reduce(toSumOfChangePercent, 0) / items.size;

    const monthlyPercent = gain.get('monthly').get('percent');
    const quarterlyPercent = gain.get('quarterly').get('percent');
    const threeYearlyPercent = gain.get('threeYearly').get('percent');

    const ret = (new PortfolioStats() as any)
      .set('amount', amount)
      .set('cashValue', activePortfolio.get('cashValue'))
      .set('isImported', activePortfolio.get('isImported'))
      .set('id', activePortfolio.get('id'))
      .setIn(['gain', 'daily', 'percent'], dailyGainPercent)
      .setIn(['gain', 'daily', 'val'], dailyGainVal)
      .setIn(
        ['gain', 'monthly', 'percent'], monthlyPercent)
      .setIn(
        ['gain', 'quarterly', 'percent'], quarterlyPercent)
      .setIn(
        ['gain', 'threeYearly', 'percent'], threeYearlyPercent)
      .setIn(['gain', 'monthly', 'val'], gain.get('monthly').get('val'))
      .setIn(['gain', 'quarterly', 'val'], gain.get('quarterly').get('val'));

    return ret;
  },
);

export const selectAboveBeta = createSelector(
  selectActivePortfolio,
  portfolio => portfolio.get('risk').get('above'),
);

const aWeek = 6.048e8;

export const selectDismissdedWarningDialogs = createSelector(
  (state: BetterImmutableInterface<Dashboard_NotImmutable>) => state.get('dismissdedWarningDialogs'),
  dismissdedWarningDialogs =>
    dismissdedWarningDialogs
      .filter(dialog => new Date().getTime() - dialog.get('time') < aWeek)
      .map(dialog => dialog.get('name')),
);

export const selectVisibleMarketOverviewMarkets = (state: Dashboard) =>
  state.get('visibleMarketOverviewMarkets');

export const selectMraketOverviewDuration = (state: Dashboard) =>
  state.get('mraketOverviewDuration');

export const selectAssetAllocationType = (state: Dashboard) =>
  state.get('assetAllocationType');

const insertCommas = num =>
  num ? num.toString().replace(/\B(?=(\d{3})+\b)/g, ',') : null;
export const selectNumPortfolios = (state: Dashboard) =>
  insertCommas(state.getIn(['data', 'stats', 'numPortfolios']));

export const selectImportState = (state: Dashboard) => state.get('importState');

export const selectMgmtFees = createSelector(
  (state: Dashboard) => state.get('data').get('mgmtFees'),
  selectPortfolioItems,
  (mgmtFees: List<MgmtFeeDashboardRecord>, items) =>
    items
      .filter(
        item =>
          !['stock', 'secondaryticker'].includes(
            item!.get('stock').get('type')!, // TODO: non-null assertion operator is bad
          ),
    )
      .map(item => {
        const fundSymbol = item!.get('ticker');
        const lowercaseFundSymbol = item!.get('ticker').toLowerCase();
        const expenseRatio = item!.get('stock').get('expenseRatio');
        const mgmtFee =
          mgmtFees.find(
            mgmtFee =>
              !mgmtFee
                ? false
                : mgmtFee.get('fundSymbol').toLowerCase() ===
                lowercaseFundSymbol,
          ) ||
          Map({
            expenseRatio,
            similarExpenseRatio: expenseRatio || 0,
          });
        const fundName = item!.get('name');
        const percentPortfolio = item!.get('percentPortfolio');
        const similarSymbolRaw = mgmtFee.get('similarSymbol');
        const similarSymbol =
          similarSymbolRaw && similarSymbolRaw.toLowerCase();
        const expense = expenseRatio * item!.get('sharesValue');
        const similarExpense =
          mgmtFee.get('similarExpenseRatio') * item!.get('sharesValue');
        const saveAmount = Math.round(expense - similarExpense);
        const savePercent = expenseRatio - mgmtFee.get('similarExpenseRatio');
        return new MgmtFee({
          expenseRatio: expenseRatio,
          similarExpenseRatio: mgmtFee.get('similarExpenseRatio') || 0,
          similarity: mgmtFee.get('similarity') || 0,
          sector: mgmtFee.get('sector') || '',
          similarSector: mgmtFee.get('similarSector') || '',
          mktCap: mgmtFee.get('marketCap') || 0,
          similarMktCap: mgmtFee.get('similarMktCap' as any) || 0, // TODO figure this one out
          fundSymbol,
          fundName,
          simlarFundName: mgmtFee.get('simlarFundName') || '',
          percentPortfolio,
          similarSymbol,
          expense,
          saveAmount,
          savePercent,
          type: item!.get('stock').get('type'),
        });
      }) as List<MgmtFeeRecordJSRecord>,
);

export const selectHighestMgmtFees = createSelector(
  selectMgmtFees,
  mgmtFees =>
    mgmtFees
      .sortBy(mgmtFee => -1 * mgmtFee!.get('expenseRatio'))
      .take(3) as List<MgmtFeeRecordJSRecord>,
);

export const selectMostSaveableMgmtFees = createSelector(
  selectMgmtFees,
  mgmtFees =>
    mgmtFees
      .filter(mgmtFees => mgmtFees.get('similarity') > 0)
      .sortBy(mgmtFees => -1 * mgmtFees.get('savePercent')),
);

export const selectMgmtFeesSaveAmount = createSelector(
  selectMostSaveableMgmtFees,
  mgmtFees =>
    mgmtFees.reduce((total, mgmtFee) => mgmtFee.get('saveAmount') + total, 0),
);

export const selectMgmtFeesSavePercent = createSelector(
  selectMostSaveableMgmtFees,
  mgmtFees =>
    mgmtFees.reduce((total, mgmtFee) => mgmtFee.get('savePercent') + total, 0) /
    mgmtFees.size,
);

export const selectDoAllMgmtFeesHaveShares = createSelector(
  selectMostSaveableMgmtFees,
  selectPortfolioItems,
  (mgmtFees, items) =>
    mgmtFees.every(mgmtFee => {
      const item = items.find(
        item => item!.get('ticker') === mgmtFee.get('fundSymbol'),
      );
      return item ? item.get('sharesTotal') > 0 : false;
    }),
);

export const selectUserInfo = (state: Dashboard) =>
  state.get('data').get('userInfo');

export const selectTheme = (state: Dashboard) => state.get('theme');

// TODO rename to TableType everywhere
export const selectTblType = (state: Dashboard) => state.get('tblType');

export const selectBasicTableType = (state: Dashboard) => state.get('basicTableType');

export const selectNumHoldings = createSelector(
  selectAllPortfolios,
  portfolios => {
    return portfolios.reduce((acc = 0, portfolio) => {
      const items = portfolio.get('items');
      return acc + (items && items.size) || 0;
    }, 0);
  },
);

export const selectIsCombinedPortfolio = createSelector(
  selectActivePortfolio,
  p => p.get('id') === companyConstants.get('combinedUserPortfolio'),
);

// viewerPlan = selectViewerPlan(auth)
export function selectIsLimited(dashboard: Dashboard, viewerPlan: any) {
  const plansLimit = portfoliosConfig.getIn(['plansLimit', viewerPlan]);
  return (
    plansLimit > -1 && selectNonImportedPortfolios(dashboard).size >= plansLimit
  );
}

export const selectUserFollowedStocksCount = (state: Dashboard) =>
  state.get('userFollowedStocks').count();
