import './index.styl';
import React from 'react';
import PropTypes from 'prop-types';

import { Map, List } from 'immutable';
import msg from './en';
import Component from 'react-pure-render/component';
import ExpertRating from '../expert-rating/ExpertRating.tsx';
import { imgSrc, personTypeImgSrc } from 'sp/browser/lib/utils';
import PersonType from '../components/personsList/PersonType.react';
import { Person } from '../../common/records';
import { FormattedNumber } from 'react-intl';
import Switch from '../components/switch';
import { createSelector } from 'reselect';

import {
  ExpertName,
  ExpertType,
  ExpertSector,
  ExpertAverageReturn,
  ExpertSuccessRate,
  ExpertAlerts,
  ExpertNotifications,
  ExpertDeleteButton
} from './columns.react';

import { selectExpertLists, sortFns } from './selectors';
import classnames from 'classnames';

import { selectTheme } from '../dashboard/selectors';

const Spiv = ({ className, ...props }) => (
  <div className={className} style={{ display: 'inline-block' }}>
    <div {...props} />
  </div>
);

class ExpertRow extends Component {
  static propTypes = {
    actions: PropTypes.object,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    showTooltip: PropTypes.func,
    hideTooltip: PropTypes.func,
    openPortfolioItem: PropTypes.func,
    stats: PropTypes.any,
    person: PropTypes.instanceOf(Person),
    mediaQuery: PropTypes.instanceOf(Map)
  };

  getColumns({ person, stats, actions, isMobile, isTablet } = this.props) {
    const columns = [
      <ExpertName person={person} stats={stats} isMobile={isMobile} />,
      !isTablet && <ExpertType person={person} />,
      !isTablet && <ExpertSector person={person} />,
      !isMobile && <ExpertAverageReturn person={person} withTitle={isTablet} />,
      !isMobile && <ExpertSuccessRate person={person} withTitle={isTablet} />,
      <ExpertAlerts {...this.props} stock={person} />,
      !isMobile && (
        <ExpertNotifications
          {...this.props}
          withTitle={isTablet}
          stock={person}
        />
      ),
      <ExpertDeleteButton onClick={() => actions.unSubscribeToExpert(person)} />
    ];

    return columns.filter(Boolean);
  }

  render() {
    return (
      <div
        // onClick={() => this.props.openPortfolioItem(this.props.person)}
        className="list-row"
      >
        {this.getColumns()}
      </div>
    );
  }
}

const Banner = ({ dashboard, mediaQuery, isTablet }) => {
  const theme = selectTheme(dashboard);
  if (theme !== 'web') return <div />;
  const isLong = isTablet || mediaQuery.get('desktop');
  return (
    <div className="expert-center-banner">
      <div>
        <div className="title">
          <div className="badge">
            <img src={imgSrc('persons-list/banner-badge')} />
          </div>
          <div className="orange-text">{msg.banner.orangeText}</div>
        </div>
        <div className="white-text">
          {msg.banner.whiteText}
          {isLong && msg.banner.whiteTextExtra}.
        </div>
        <a className="button" target="_blank" href="/top-experts">
          {msg.banner.button}
        </a>
      </div>
    </div>
  );
};

const ExpertListBody = props => {
  let rows = props.experts.map(expert => (
    <ExpertRow
      key={expert.id}
      person={expert}
      actions={props.actions}
      isMobile={props.isMobile}
      isTablet={props.isTablet}
      stats={props.stats}
      showTooltip={props.showTooltip}
      hideTooltip={props.hideTooltip}
      onClickAlert={props.onClickAlert}
      openPortfolioItem={props.openPortfolioItem}
      openPortfolioItemAlerts={props.openPortfolioItemAlerts}
      mediaQuery={props.mediaQuery}
      setStockLastRead={props.setStockLastRead}
    />
  ));

  const banner = (
    <Banner
      mediaQuery={props.mediaQuery}
      isTablet={props.isTablet}
      dashboard={props.dashboard}
    />
  );
  if (props.isTablet) {
    if (props.isFirst) {
      rows = rows.push(banner);
    }
  } else {
    rows = rows.splice(5, 0, banner);
  }

  return <div className="list-body">{rows}</div>;
};

const columns = [
  'expert',
  'expertType',
  'sector',
  'averageReturn',
  'successRate',
  'alerts',
  'notifications',
  'delete'
];

const sortBy = fn => {
  // if it's a string, assume it's a key and sort by it.
  const sorter = typeof fn === 'function' ? fn : x => x[fn];
  return sorter;
};

const ExpertListHeadMobile = props => (
  <div className="list-head-mobile">
    <img src={personTypeImgSrc(props.type)} />
    {msg.expertTypes[props.type]}
  </div>
);

const ExpertListHeadDesktop = props => (
  <div className="list-row">
    {columns.map(c => {
      const sortFn = sortFns[c];
      const active = props.settings.sortBy === c;
      return (
        <div key={c} className="list-head-cell">
          {React.createElement(
            sortFn ? 'button' : 'div',
            {
              className: classnames({
                desc: props.settings.desc && active,
                active
              }),
              onClick: () => props.actions.setSort(c)
            },
            msg.columnNames[c]
          )}
        </div>
      );
    })}
  </div>
);

const ExpertListHead = props => (
  <div className="list-head">
    {props.isTablet ? (
      <ExpertListHeadMobile {...props} />
    ) : (
      <ExpertListHeadDesktop {...props} />
    )}
  </div>
);

const ExpertList = props => (
  <div
    className={classnames('list', {
      isMobile: props.isMobile,
      isTablet: props.isTablet
    })}
  >
    <ExpertListHead {...props} />
    <ExpertListBody {...props} />
  </div>
);

export default class DashboardExperts extends Component {
  static propTypes = {
    actions: PropTypes.object,
    auth: PropTypes.any,
    busyExperts: PropTypes.any,
    dashboard: PropTypes.object,
    deletedExperts: PropTypes.any,
    expertTypes: PropTypes.any,
    experts: PropTypes.any,
    mediaQuery: PropTypes.any,
    stats: PropTypes.any,
    showTooltip: PropTypes.func,
    hideTooltip: PropTypes.func,
    onClickAlert: PropTypes.func,
    setStockLastRead: PropTypes.func,
    openPortfolioItem: PropTypes.func,
    openPortfolioItemAlerts: PropTypes.func,
    enums: PropTypes.object,
    settings: PropTypes.object
  };

  isMobile() {
    return !this.props.mediaQuery.get('mobileXXL');
  }

  isTablet() {
    return !this.props.mediaQuery.get('laptopXS');
  }

  renderExpertsList(props) {
    return (
      <div key={props.type} className="experts-list">
        <ExpertList {...props} />
      </div>
    );
  }

  render() {
    const isMobile = this.isMobile();
    const isTablet = this.isTablet();
    const expertLists = selectExpertLists(this.props.settings, isTablet);
    return (
      <div>
        {expertLists.map((list, type) =>
          this.renderExpertsList({
            ...this.props,
            experts: list,
            type,
            isMobile,
            isTablet,
            isFirst: type === expertLists.keys().next().value
          })
        )}
      </div>
    );
  }
}

export let __hotReload = true;
