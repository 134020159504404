import * as React from 'react';
import * as styles from './MyPerfBox.istyl';
import { Br } from 'sp/common/utils';

const defOpts = {
  line1: (
    <span>
      Your portfolio could not yet<Br isForMobile />be analysed.
    </span>
  ),
  line2: (
    <span>
      Please read what is required below in the Recent Activity section<Br isForMobile />to
      have your performance measured.
    </span>
  ),
};

const MyPerfSectionNoData = ({
  imgSrc,
  line1 = defOpts.line1,
  line2 = defOpts.line2,
}: {
    imgSrc: string;
    line1?: React.ReactNode;
    line2?: React.ReactNode;
  }) => (
    <div className={styles.noDataContainer}>
      <img src={imgSrc} />
      <div className={styles.headings}>
        {line1 && <div className={styles.firstHeading}>{line1}</div>}
        {line2 && <div className={styles.secondHeading}>{line2}</div>}
      </div>
    </div>
  );

export const MyPerfVolatilityNoData = ({ }) => (
  <MyPerfSectionNoData
    line1="Volatility analysis is currently not available for this portfolio"
    line2=""
    imgSrc={require(`./images/nodata-${process.env.THEME}-volatility.jpg`)}
  />
);
export const MyPerfAllocationNoData = ({ }) => (
  <MyPerfSectionNoData
    imgSrc={require(`./images/nodata-${process.env.THEME}-allocation.png`)}
  />
);

export const MyPerfAllocationNoDataETFOrFund = ({ }) => (
  <MyPerfSectionNoData
    line1="Allocation Analysis supports primarily stock portfolios."
    line2="ETF and Mutual Fund portfolios are not analyzed in this section."
    imgSrc={require(`./images/nodata-${process.env.THEME}-allocation.png`)}
  />
);
