import { AuthRecord, ReduxAction, PortfolioMadePublic, PortfolioPrivacy, LoggedInUser_NotImmutable, LoggedInUserRecord } from 'sp/common/types';
import { SET_PORTFOLIO_PUBLIC_START, SET_PORTFOLIO_PUBLIC_SUCCESS, SET_PORTFOLIO_PUBLIC_ERROR } from 'sp/common/auth/actions';
import { selectLoggedInUser } from 'sp/common/auth/selectors';
import { API_Res_SetPortfolioPublic, API_Res_Modified_SetPortfolioPublic } from 'sp/browser/pages/PublicPortfolio/Onboarding/api';
import { suits } from 'sp/common/enums';
import { initialState, InitialState } from 'sp/common/auth/config';
import { fromJS, List } from 'immutable';
import { SET_PORTFOLIO_PRIVACY_START, SET_PORTFOLIO_PRIVACY_SUCCESS, SET_PORTFOLIO_PRIVACY_ERROR, API_Req_SetPortfolioPrivacy, API_Req_SetPortfolioPublic } from 'sp/browser/pages/PublicPortfolio/Onboarding/actions';

// TODO should be probably moved somewhere else, since selectors are in auth anway.
export function setPortfolioPublicReducer(state: AuthRecord = initialState, action: ReduxAction): AuthRecord {
  if (!(state instanceof InitialState)) return initialState;
  return state.setIn(['users', 'viewer', 'loggedInUser'], reducer(
    state.getIn(['users', 'viewer', 'loggedInUser']), action
  ));
}

function reducer(user, action) {
  switch (action.type) {
    case SET_PORTFOLIO_PUBLIC_START: {
      return user
        .update('componentStates', state => state.set('portfoliosMadePublic', suits.LOADING));
    }
    case SET_PORTFOLIO_PUBLIC_ERROR: {
      return user
        .update('componentStates', state => state.set('portfoliosMadePublic', suits.ERROR));
    }
    case SET_PORTFOLIO_PUBLIC_SUCCESS: {
      const payload: API_Res_Modified_SetPortfolioPublic = action.payload;
      const { id, slug } = payload;
      const newPublicPortfolio = fromJS({ id, slug, privacy: PortfolioPrivacy.Public });
      return user
        .update('portfoliosMadePublic', portfoliosMadePublic => (portfoliosMadePublic || List()).push(newPublicPortfolio))
        .set('latestCreatedPublicPortfolio', fromJS({ id, slug }))
        .update('componentStates', state => state.set('portfoliosMadePublic', suits.SUCCESS));
    }
    case SET_PORTFOLIO_PRIVACY_START: {
      return user
        .update('componentStates', state => state.set('setPortfolioPrivacy', suits.LOADING));
    }
    case SET_PORTFOLIO_PRIVACY_ERROR: {
      return user
        .update('componentStates', state => state.set('setPortfolioPrivacy', suits.ERROR));
    }
    case SET_PORTFOLIO_PRIVACY_SUCCESS: {
      const payload: API_Req_SetPortfolioPrivacy = action.payload;
      const { portfolioId, privacyLevel } = payload;
      return user
        .update('portfoliosMadePublic', state => {
          // TODO get immutable stuff findIndex working
          const portfolioIndex = state.findIndex(p => p!.get('id') === portfolioId);
          return state.update(portfolioIndex, portfolio => portfolio.set('privacy', privacyLevel));
        })
        .update('componentStates', state => state.set('setPortfolioPrivacy', suits.SUCCESS));
    }
  }
  return user;
}