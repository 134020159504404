import { Record } from 'immutable';

import messages from './messages';
import * as actions from './actions';

const InitialState = Record({
  availableLanguages: ['en', 'es'],
  messages,
  selectedLanguage: 'en'
});
const initialState = new InitialState();

const revive = state =>
  initialState.set('selectedLanguage', state.selectedLanguage);

export default function intlReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.CHANGE_LANG: {
      return state.set('selectedLanguage', action.lang);
    }
  }

  return state;
}
