import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class Names extends Component {
  static propTypes = {
    enums: PropTypes.object,
    item: PropTypes.object
  };

  render() {
    const { enums, item } = this.props;

    return (
      <div className="names">
        <div className="ticker">
          <PortfolioItemSelector item={item} msg={enums} type="ticker" />
        </div>
        <div className="name" title={item.get('name')}>
          <PortfolioItemSelector item={item} msg={enums} type="name" />
        </div>
      </div>
    );
  }
}
