import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import Details from './Details.react';

export default class PriceCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    fetchPriceData: PropTypes.func,
    priceDependencies: PropTypes.any,
    stock: PropTypes.object
  };

  render() {
    const { enums, fetchPriceData, priceDependencies, stock } = this.props;

    return (
      <Details
        enums={enums}
        fetchPriceData={fetchPriceData}
        item={stock}
        priceDependencies={priceDependencies}
      />
    );
  }
}
