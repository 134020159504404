import React from 'react';
import Alerts from '../tr-dashboard-alerts';

export const alerts = ({ msg, stock, dashboard, name }) => (
  <Alerts
    alerts={stock.get('alerts')}
    stats={dashboard.getIn(['data', 'stats'])}
    enums={msg.enums}
    name={name}
    msg={msg.alerts}
  />
);

export const warning = ({ tooltipMsg }) => (
  <div className="warning-tooltip">{tooltipMsg}</div>
);

export const info = ({ msg }) => (
  <div className="info-tooltip-content">{msg}</div>
);
