import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

const changeHandler = onChange => ({ target }) => onChange(target.value);

const keyPressed = doneEditing => ({ target, which }) =>
  which === 13 && doneEditing(target.value, { goNext: true });

export default class Editable extends Component {
  static propTypes = {
    activate: PropTypes.func,
    doneEditing: PropTypes.func,
    editableValue: PropTypes.string,
    freez: PropTypes.bool,
    isActiveMode: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.any
  };

  componentDidMount() {
    const { isActiveMode } = this.props;
    const { input } = this.refs;
    if (isActiveMode) {
      input.focus();
      this.addOutsideListener();
    }
  }

  componentDidUpdate(prevProps) {
    const { isActiveMode } = this.props;
    const { input } = this.refs;
    const { isActiveMode: prevIsActiveMode } = prevProps;
    if (isActiveMode && !prevIsActiveMode) {
      setTimeout(() => {
        input.focus();
        input.select();
        // input.setSelectionRange(0, input.value.length);
      }, 250);
      this.addOutsideListener();
    }
    if (!isActiveMode && prevIsActiveMode) {
      this.removeOutsideListener();
    }
  }

  componentWillUnmount() {
    this.removeOutsideListener();
  }

  onKeyPress = e => {
    const { onKeyPress } = this.props;
    if (this.props.onKeyPress) onKeyPress(e);
    keyPressed(this.props.doneEditing)(e);
  };

  doneEditing = ({ target }) => {
    const { doneEditing, editableValue } = this.props;

    const { input } = this.refs;

    if (target !== input /* && isActiveMode && !freez*/) {
      doneEditing(input ? input.value : editableValue);
    }
  };

  removeOutsideListener() {
    /* we're removing the listener in with a delay in order to prevent the
     * input element from unmounting before React has a chance of firing the
     * action. */
    setTimeout(
      () => document.removeEventListener('click', this.doneEditing),
      250
    );
  }

  addOutsideListener() {
    document.addEventListener('click', this.doneEditing);
  }

  render() {
    const {
      activate,
      editableValue,
      freez,
      isActiveMode,
      onChange,
      type,
      value
    } = this.props;

    return isActiveMode ? (
      <input
        {...this.props}
        disabled={freez}
        onChange={changeHandler(onChange)}
        onFocus={({ target }) => {
          target.value = target.value;
        }}
        onKeyPress={this.onKeyPress}
        ref="input"
        value={type === 'number' && editableValue === 0 ? '' : editableValue}
      />
    ) : (
      <span onClick={activate || (() => null)}>{value}</span>
    );
  }
}
