/* eslint-disable react/jsx-boolean-value */
import { createLinkCmp } from '@tipranks/project/lib/hooks/link/link';
import { setNavLink } from '@tipranks/ui/lib/atom/button/button';
import { BaseRoutingStore, HeaderTipranks, TrBrowserStore, TrHeaderStore, TRUserStore, TRMarketStore } from '@tipranks/widget';
import { TrPortfolioStore } from "@tipranks/project/lib/stores/trPortfolio";
import * as React from 'react';
import { history } from 'sp/browser/history';
import { screenAndMinWidth } from 'sp/common/lib/mediaQuery';
import MobileContainer from '../mobile-container/mobile-container.react';
import * as cookies from 'js-cookie';
import './header.styl';
import apis from "@tipranks/apis/lib/api";

const trBrowserStore = new TrBrowserStore();
const trUserStore = new TRUserStore(trBrowserStore);
const trRoutingStore = new BaseRoutingStore();
const trMarketStore = new TRMarketStore(trRoutingStore);
const trHeaderStore = new TrHeaderStore(trRoutingStore, trUserStore, trBrowserStore, {} as any, trMarketStore);
const trPortfolioStore = new TrPortfolioStore(trRoutingStore, trUserStore, trHeaderStore)

const ribbonConfig = {
  color: "white",
  bg: "#1e77f9",
  bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
  content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
  contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
  btnText: "Click Here",
  action: async () => { 
    trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
    const migrate = await trPortfolioStore.migrateToV2();
    console.log ('migrate:', JSON.stringify(migrate));
    const getUserSettings2 = await apis.users.me({});
    window.location.href = '/smart-portfolio/holdings';
  },
  hideOn: ["notLoggedIn"],
  cookieName: "noSpRibbon",
  isActive: false,
  byPlan: {
		open: {
			color: "white",
			bg: "#1e77f9",
      bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
			content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			btnText: "Click Here",
      action: async () => { 
        trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
        const migrate = await trPortfolioStore.migrateToV2();
        console.log ('migrate:', JSON.stringify(migrate));
        const getUserSettings2 = await apis.users.me({});
        window.location.href = '/smart-portfolio/holdings';
      },
		},
		basic: {
			color: "white",
			bg: "#1e77f9",
      bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
			content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			btnText: "Click Here",
      action: async () => { 
        trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
        const migrate = await trPortfolioStore.migrateToV2();
        console.log ('migrate:', JSON.stringify(migrate));
        const getUserSettings2 = await apis.users.me({});
        window.location.href = '/smart-portfolio/holdings';
      },
		},
    plus: {
			color: "white",
			bg: "#1e77f9",
      bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
			content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			btnText: "Click Here",
      action: async () => { 
        trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
        const migrate = await trPortfolioStore.migrateToV2();
        console.log ('migrate:', JSON.stringify(migrate));
        const getUserSettings2 = await apis.users.me({});
        window.location.href = '/smart-portfolio/holdings';
      },
		},
		premium: {
      color: "white",
			bg: "#1e77f9",
      bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
			content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			btnText: "Click Here",
      action: async () => { 
        trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
        const migrate = await trPortfolioStore.migrateToV2();
        console.log ('migrate:', JSON.stringify(migrate));
        const getUserSettings2 = await apis.users.me({});
        window.location.href = '/smart-portfolio/holdings';
      },
		},
		ultimate: {
			color: "white",
			bg: "#1e77f9",
      bgImage: "https://static.tipranks.com/downloads/bg_gradient_blue.png",
			content: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			contentMobile: "We will soon launch a new and improved version of the Smart Portfolio, if you'd like to switch to the beta version",
			btnText: "Click Here",
      action: async () => { 
        trHeaderStore.setRibbonConfig({...trHeaderStore.topRibbonConfig, ...{ btnText: "Reloading..."}})
        const migrate = await trPortfolioStore.migrateToV2();
        console.log ('migrate:', JSON.stringify(migrate));
        const getUserSettings2 = await apis.users.me({});
        window.location.href = '/smart-portfolio/holdings';
      },
		}
	},
  id: "sp"
}
setNavLink(createLinkCmp(trRoutingStore as any));
class DesktopApp extends React.Component<any> {
  constructor(props) {
    super(props);
    const location = props.location;
    trRoutingStore.update({ key: location.pathname, name: location.pathname }, {}, location, history);
    trHeaderStore.init();
    trHeaderStore.setRibbonConfig (ribbonConfig)
    trHeaderStore.initOnBootstrap({} as any);
  }

  public componentDidMount(){
    const isSpaasMigrated
    = this.props.auth
      .get('users')
      .get('viewer')
      .get('isSpaasMigrated')

    const sp_migration_eligible = cookies.get('sp_migration_eligible');
      
    const isActive =  (window.location.hostname.includes("www") || window.location.hostname.includes("dev3") ||
    window.location.hostname.includes("preprod4") ||
      window.location.hostname.includes("qa8") ||
      window.location.hostname.includes("localhost")) &&
    !isSpaasMigrated && sp_migration_eligible?.toLocaleLowerCase() === 'true'

    const config = {...ribbonConfig, ...{isActive: isActive}};
    trHeaderStore.setRibbonConfig (config)
  }

  public componentWillReceiveProps(nextProps: any) {
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      const location = nextProps.location;
      trRoutingStore.update({ key: location.pathname, name: location.pathname } as any, {}, location, history);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={'tr-app'}>
          <HeaderTipranks
            hasSearch={true}
            hasSubHeader={true}
            hasRight={true}
            className={'client-components-header-header__header'}
            userStore={trUserStore}
            browserStore={trBrowserStore}
            headerStore={trHeaderStore}
            hasTopRibbon={true}
          />
        </div>
        {this.props.children}
      </React.Fragment>
    );
  }
}

class MobileApp extends React.Component<any> {

  constructor(props) {
    super(props);
    const location = props.location;
    trRoutingStore.update({ key: location.pathname, name: location.pathname }, {}, location, history);
    trHeaderStore.init();
    trHeaderStore.setRibbonConfig (ribbonConfig)
    trHeaderStore.initOnBootstrap({} as any);
  }

  render() {
    return (
      <div className="app-mobile">
        <MobileContainer 
          browser={trBrowserStore} 
          user={trUserStore} 
          routing={trRoutingStore} 
          header={trHeaderStore} 
          {...this.props}
        >
          {this.props.children}
        </MobileContainer>
      </div>
    );
  }
}

export default class Header extends React.Component<any> {
  renderMobile() {
    return <MobileApp {...this.props} />;
  }

  renderDesktop() {
    return (
      <DesktopApp
        {...this.props}
        auth={this.props.auth}
        actions={this.props.actions}
        autocomplete={this.props.autocomplete}>
        {this.props.children}
      </DesktopApp>
    );
  }

  render() {
    // TIPRANKS-16378
    // state doesnt seem to be correct with the right resolution
    // on load. rotating the screen solves it by firing the action.
    // below is a nice fat semi-hack
    // return this.props.mediaQuery.get('laptop') ?
    return screenAndMinWidth(64) ? this.renderDesktop() : this.renderMobile();
  }
}
