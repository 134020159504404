export default {
  app: {
    footer: {
      footerPlaceholder: 'Marcador de posición de pie de página'
    },
    links: {
      dashboard: 'Tablero'
    }
  },
  dialogs: {
    addStock: {
      title: 'Add Stocks'
    },
    addPortfolio: {
      title: 'Add Portfolio'
    },
    portfolioItem: {
      title: 'Stock'
    },
    alertsSettings: {
      title: 'Email Alerts'
    },
    holdingsMetrics: {
      title: 'Holings Metrics'
    },
    portfolios: {
      title: 'Manage Portfolios'
    },
    deletePortfolio: {
      title: 'Delete Portfolio'
    },
    analyzingPortfolio: {
      title: 'Analyzing Portfolio'
    },
    promotion: {
      title: 'TipRanks Premium'
    }
  },
  addPortfolio: {
    title: 'Add new portfolio',
    placeHolder: 'Enter Portfolio Name',
    label: 'Add Portfolio',
    done: 'Done',
    import: 'Import Portfolio',
    edit: 'Edit Portfolios'
  },
  toasts: {
    alertsSettingsUpdated: 'Preference Updated'
  },
  promotion: {
    title: {
      holdings_bestTarget: 'Reveal Best Analyst Price Target on your holdings',
      holdings_bloggerSentiment: 'Reveal Blogger Sentiment on your holdings',
      holdings_insiderSentiment: 'Reveal Insider Sentiment on your holdings',
      holdings_hedgefundSentiment:
        'Reveal Hedge Fund Sentiment on your holdings',
      holdings_bestAnalystConsensus:
        'Reveal Best Analyst Consensus on your holdings',
      portfolios: 'Add more Portfolios to your Dashboard',
      alertsSettings: 'Get more Notifications!'
    },
    benefit: 'Get much more with the {plan} Membership',
    planName: 'TipRanks {plan}',
    price: {
      premium: '29.95',
      ultimate: '49.95'
    },
    period: 'month',
    charged: 'Charged annually',
    subscribe: 'Subscribe Now',
    seeAll: 'See all {plan} features and compare to other plans {plansLink}',
    plansLink: 'Plans & Pricing >'
  },
  portfolioSelect: {
    edit: '> Editar Portafolios'
  },
  deletePortfolio: {
    title: 'Delete Portfolio',
    question: 'Are you sure you want to delete {portfolio} Portfolio?',
    ok: 'Confirm',
    cancel: 'Cancel'
  },
  analyzingPortfolio: {
    message: 'please wait while we analyze your portfolio'
  },
  portfolios: {
    title: 'Edit Portfolios',
    placeHolder: 'Add New Portfolio, Enter, Name',
    sync: 'Already have a portfolio on your broker platform? {syncLink}',
    syncLink: 'Synchronize Your Existing Portfolio',
    ok: 'Done'
  },
  calendar: {
    weekDaysShort: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
    today: 'Today',
    prev: 'Previous Month',
    next: 'Next Month',
    index: 'Index',
    earnings: 'Earnings Report',
    dividend: 'Dividend',
    total: 'Total Events'
  },
  portfolioCalendar: {
    title: 'Portfolio Calendar'
  },
  addStock: {
    title: 'Add stocks to your portfolio',
    inputPlaceHolder: 'Enter Ticker or Company Name',
    emptyPendingLists: 'Enter tickers above to add holdings to your portfolio',
    addBtn: 'Done',
    inputLabel: 'Add Ticker',
    reachedLimit:
      'Please proceed with building your portfolio. You can add more tickers later.'
  },
  holdingsMetrics: {
    title: 'Customize Your Holdings Chart',
    description: 'Choose which fields will be shown:',
    ok: 'Save and Close'
  },
  enums: {
    noData: '-',
    sectors: {
      general: 'todos los Sectores',
      services: 'Servicios',
      industrialgoods: 'Bienes industriales',
      utilities: 'Utilidades',
      healthcare: 'Cuidado de la salud',
      materials: 'Materiales basicos',
      technology: 'Tecnología',
      financial: 'Financiero',
      consumergoods: 'Bienes de consumo'
    },
    analystConsensus: {
      strongsell: 'Venta fuerte',
      sell: 'Venta moderada',
      neutral: 'Sostener',
      buy: 'Comprar moderada',
      strongbuy: 'Compra fuerte'
    },
    insiderSentiment: {
      1: 'Positivo',
      2: 'Neutral',
      3: 'Negativo'
    },
    bloggerSentiment: {
      1: 'Alcista',
      2: 'Neutral',
      3: 'Osuno'
    },
    hedgefundSentiment: {
      1: 'Positivo',
      2: 'Neutral',
      3: 'Negativo'
    },
    priceTarget: {
      buy: 'Al revés',
      sell: 'Desventaja'
    },
    alerts: {
      reiterated:
        '{personType} {personName} de {personFirm} reiteró una {rating} rating en {stockName}.',
      upgraded:
        '{personType} {personName} de {personFirm} Puntuación mejorado en {stockName} a {rating}.',
      maintained:
        "{personFirm}'s {personName} mantenido una {rating} rating en {stockName}",
      rated: '{personName} dio {stockName} anu {rating} rating en {personFirm}',
      initiatedcoverage:
        '{personName} dio {stockName} una {rating} rating en {personFirm}',
      downgraded:
        '{personName} dio {stockName} una {rating} rating en {personFirm}'
    },
    plans: {
      free: 'BASIC',
      premium: 'Prima',
      ultimate: 'Último'
    }
  },
  alertsSettings: {
    title: 'Manage Email Notifications',
    description:
      'Here you can manage your email alerts settings, decide in which scenarios you will get norification by mail.\nThis settings is per portfolio (for each portfolio you can configure different settings).',
    portfolioSelectText:
      'Alert me when one of my stocks in {portfolioSelector} portfolio:',
    options: {
      high: 'Shares reach a new 52-week high',
      low: 'Shares frop to a new 52-week low',
      increase: 'Shares increase more than {percentEditable}',
      drop: 'Shares drop more than {percentEditable}',
      announcement: 'Makes a press release announcement',
      report: 'Release an earnings report',
      dividend: 'Announce a new dividend',
      analyst: 'When an analyst publishes a rating on a stock',
      blogger: 'When a blogger publishes a rating on a stock',
      insider: 'When an inisder makes a transaction on a stock',
      hedgefund: 'When a hedge fund makes a transctions on a stock',
      ranking:
        'Apply above settings to experts, insiders & hedge funds with at least {rankingSelector} star ranking',
      analystConsensus: 'Analyst Consensus changes',
      bestAnalystConsensus: 'Best Performing Analyst Consensus changes',
      bloggerSentiment: 'Financial Bloger Sentiment changes',
      insiderSentiment: 'Corporate Insider Sentiment changes'
    },
    totalAlertsPredictions:
      'Expect to get about {total} email alerts on {portfolioName} portfolio this week',
    disable:
      'Disable all email alerts from {portfolioName} portfolio this week',
    expertsAlertsTitle: 'My Experts Notifications',
    expertPredication:
      'You are following {expertsTotal} Experts, Expect to get about {expertsAlertsPrediction} email alerts from expert you follow',
    disableExperts: 'Disable all email alerts from experts you follow'
  },
  portfolioItem: {
    alertsTitle: 'Alerts: {alertsIndicator}',
    statsTitle: 'Key Stats',
    emailAlerts: 'Email Alerts'
  },
  dashboard: {
    title: 'Tablero',
    all: 'todos los portafolios',
    emailAlerts: 'Alertas de correo electrónico',
    filters: {
      expertType: {
        legend: 'Demostración:',
        options: {
          analyst: {
            label: 'Los analistas',
            labelLong: 'Los analistas de Wall Street'
          },
          blogger: {
            label: 'bloggers',
            labelLong: 'Los bloggers financieros'
          },
          insider: {
            label: 'Insiders',
            labelLong: 'la información corporativa'
          },
          hedgefund: {
            label: 'Los fondos de cobertura',
            labelLong: 'Hedge Fund Manager'
          }
        }
      }
    },
    nav: {
      routes: {
        overview: 'Visión de conjunto',
        holdings: 'Mis Holdings',
        'holdings.holdings': 'Valores en cartera',
        'holdings.news': 'cartera Noticias',
        'holdings.opinions': 'Experts Opinions',
        'holdings.calendar': 'opiniones de los expertos',
        analysis: 'Análisis de la cartera',
        'analysis.portfolio': 'Mi portafolio',
        'analysis.avgportfolio': 'Agosto Tipranks Cartera',
        'analysis.top10portfolio': 'Top 10% Performing TipRanks Portfolio',
        'analysis.hedgefundportfolio':
          'Global 10% Realización de TipRanks Cartera',
        performance: 'mi Rendimiento',
        experts: 'Mis Expertos',
        account: 'Mi cuenta'
      }
    },
    addPortfolio: 'Add Portfolio',
    overviewSection: {
      stats: {
        amount: 'Cantidad total',
        dailyGain: 'ganancia diaria'
      }
    },
    holdingsSection: {
      title: 'Mis Holdings',
      menu: {
        add: 'Añadir Stocks',
        metrics: 'Añadir métricas en Chart',
        settings: 'Configuración de alertas de correo electrónico'
      },
      cols: {
        index: '',
        name: 'nombre de empresa',
        price: 'Precio',
        target: 'Analista de precios Objetivo',
        bestTarget: 'Mejor Analista objetivo de precio',
        analystConsensus: 'Consenso de los Analistas',
        bestAnalystConsensus: 'Mejor consenso de los analistas',
        insiderSentiment: 'El sentimiento de información privilegiada',
        highLow: '52 Semana de Alta / Baja',
        added: 'Fecha Alta',
        shares: 'No. de Acciones',
        value: 'Acciones Valor ($)',
        percent: '% de la cartera',
        alerts: 'Alertas',
        emailAlerts: 'Alertas de correo electrónico',
        dividend: 'dividendo',
        yield: 'Rentabilidad por dividendo',
        dividendDate: 'próximo dividendo Fecha',
        earningsReport: 'Informe Siguiente ganancias',
        eps: 'Última EPS Informó',
        sector: 'sector',
        cap: 'Capitalización del Mercado',
        beta: 'Beta',
        hedgefundSentiment: 'Los fondos de cobertura sentimiento',
        purchasePrice: 'precio de compra',
        avgPurchasePrice: 'Media Precio de Compra',
        bloggerSentiment: 'El sentimiento de Blogger',
        pe: 'PE'
      },
      addBtn: 'Add Stock'
    },
    portfolioNews: {
      title: 'Cartera Noticias',
      menu: {
        all: 'Todo Noticias',
        positive: 'La buena nueva',
        negative: 'Las malas noticias'
      },
      next: 'See More'
    },
    portfolioEvents: {
      title: 'Próximos Eventos',
      cols: {
        ticker: 'ticker',
        name: 'Nombre de la empresa',
        event: 'Evento',
        date: 'Fecha'
      },
      next: 'Cartera Calendario',
      eventTypes: {
        dividends: 'dividendo: {price}',
        earnings: 'Las ganancias de informe: {price}'
      }
    }
  },
  home: {
    title: 'TipRanks Dashboard',
    infoHtml:
      'TipRanks Dashboard Project. Please navigate to <a href="/smart-portfolio">/dashboard</a>'
  },
  notFound: {
    title: '404'
  },
  auth: {
    form: {
      button: {
        login: 'Login',
        signup: 'Sign up'
      },
      legend: 'Login / Sign Up',
      placeholder: {
        email: 'your@email.com',
        password: 'password'
      },
      wrongPassword:
        'The password you entered is incorrect or the email does not belong to any account.'
    },
    logout: {
      button: 'Logout'
    },
    login: {
      title: 'Login'
    },
    validation: {
      email: 'Email address is not valid.',
      password: 'Password must contain at least {minLength} characters.',
      required: `Please fill out {prop, select,
        email {email}
        password {password}
        other {'{prop}'}
      }.`
    }
  }
};
