import * as React from 'react';
import { NoDataAddStocks } from 'sp/browser/dashboard/NoDataAddStocks';

const styles: React.CSSProperties = {
  top: '50%',
  position: 'absolute',
  transform: 'translateY(-50%)',
  msTransform: 'translateY(-50%)',
  WebkitTransform: 'translateY(-50%)',
  textAlign: 'center',
  width: '100%',
};

export const NoData = (Component: any) => {
  return class NoData extends React.PureComponent<{
    hasData: boolean;
    noDataMsg?: string;
    style?: React.CSSProperties;
    onAddStocksClicked: () => void;
  }> {
    static displayName = Component.displayName || Component.name;
    render() {
      const { hasData, noDataMsg = 'No Data', style = {}, onAddStocksClicked = () => { } } = this.props;
      return hasData
        ? <Component {...this.props} />
        : <NoDataAddStocks
          htmlProps={{ style: { ...styles, ...style } }}
          msg={{ noData: noDataMsg, addStocks: 'Add stocks' }}
          openAddStockDialog={onAddStocksClicked}
        />;
    }
  };
};

export default NoData;
