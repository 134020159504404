import React from 'react';
import { BarSlider } from './BarSlider.react';
import { brandName } from '../../../../../common/config';

const defaultViewRange = [0, 1];

const range = ([x, y]) => num => Math.min(1, (Number(num) - x) / (y - x));
const domain = ([x, y]) => num => x + num * (y - x);
const truthyOrZero = x => x || x === 0;

const RiskContainer = ({
  viewRange = defaultViewRange,
  labels: { before, after } = { before: 0, after: 100 },
  isOrange,
  range: [x, y] = [0, 100],
  avg = 1.22,
  value = 2
}) => {
  const rn = range([x, y]);
  const dm = domain(viewRange);
  const fmt = num => dm(rn(num)) * 100 + '%';
  return (
    <div className="portfolio-risk-container">
      <div className="risk-bar-container">
        <div className="risk-title">{before}</div>
        <div className={isOrange ? 'orange-risk-bar' : 'risk-bar'}>
          {truthyOrZero(avg) ? (
            <BarSlider
              value={avg}
              left={fmt(avg)}
              text={`${brandName} Average Portfolio`}
            />
          ) : null}
          {truthyOrZero(value) ? (
            <BarSlider
              value={value}
              text="My Portfolio"
              inverted
              left={fmt(value)}
            />
          ) : null}
        </div>
        <div className="risk-title">{after}</div>
      </div>
    </div>
  );
};

export default RiskContainer;
