import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import * as numeral from 'numeral';
import { FormattedNumber } from 'react-intl';

import DailyGain from './daily-gain.react';

import Editable from '../Editable';
import HighlightChange from '../HighlightChange';
import { imgSrc } from '../../common/lib/utils.ts';

export default class OverviewStatValue extends Component {
  static propTypes = {
    activePortfolio: PropTypes.object,
    changeEditable: PropTypes.func,
    editableId: PropTypes.string,
    isCombinedPortfolio: PropTypes.bool,
    isImportedPortfolio: PropTypes.bool,
    setCashValue: PropTypes.func,
    stat: PropTypes.string
  };

  constructor(...args) {
    super(...args);
    this.state = { cashValue: '0' };
  }

  editableId = () => `cashValue`;

  activate = () => {
    const { activePortfolio, changeEditable, isCombinedPortfolio } = this.props;
    if (isCombinedPortfolio) return;
    const value = activePortfolio.get('amount');
    this.setState({ cashValue: value.toString() });
    changeEditable(this.editableId(), value);
  };

  doneEditing = () => {
    const { changeEditable, activePortfolio, setCashValue } = this.props;
    const { cashValue } = this.state;
    const floatCashValue = parseFloat(cashValue);

    changeEditable('');

    const isValid = 0 <= floatCashValue && floatCashValue <= 999999;
    const haveChanged = floatCashValue !== activePortfolio.get('cashValue');
    if (isNaN(floatCashValue) || !isValid || !haveChanged) return;

    setCashValue(
      activePortfolio.get('id'),
      floatCashValue,
      activePortfolio.get('cashValue')
    );
  };

  isActiveMode = () => {
    const { editableId } = this.props;
    return editableId === this.editableId();
  };

  onChange = cashValue => this.setState({ cashValue });

  componentDidUpdate(prevProps) {
    if (this.isFirstActiveRender(prevProps)) {
      const { activePortfolio } = this.props;
      this.setState({
        cashValue: activePortfolio.get('cashValue')
      });
    }
  }

  isFirstActiveRender = ({ editableId: prevEditableId }) =>
    this.isActiveMode() && prevEditableId !== this.editableId();

  render() {
    const { activePortfolio, stat } = this.props;

    switch (stat) {
      case 'amount': {
        const val = activePortfolio.get('amount');
        if (isNaN(val)) return null;
        const value = numeral(val).format('$0,0.00');
        if (!value || value === 0) {
          return <span>-</span>;
        }
        return (
          // TIPRANKS-13894, using maximumFractionDigits causes weird stuff to happen in IE9
          <HighlightChange value={value}>{value}</HighlightChange>
        );
      }

      case 'dailyGain': {
        return <DailyGain activePortfolio={activePortfolio} />;
      }

      case 'monthlyGain': {
        return (
          <DailyGain
            activePortfolio={activePortfolio}
            percentOnly
            period="monthly"
          />
        );
      }

      case 'totalGain': {
        return (
          <DailyGain
            activePortfolio={activePortfolio}
            percentOnly
            period="threeYearly"
          />
        );
      }

      case 'cashValue': {
        const { isCombinedPortfolio, isImportedPortfolio } = this.props;
        const { cashValue } = this.state;
        const value = cashValue;
        const val = activePortfolio.get('cashValue');
        if (isNaN(val)) return null;
        const fmtValue = numeral(val).format('$0,0.00');

        return (
          <div>
            <span className="real-value">
              <Editable
                activate={this.activate}
                doneEditing={this.doneEditing}
                editableValue={value}
                freez={isImportedPortfolio}
                isActiveMode={this.isActiveMode()}
                min={0.0}
                max={999999}
                onChange={this.onChange}
                type="number"
                value={fmtValue}
              />
            </span>
            {!isCombinedPortfolio &&
              !isImportedPortfolio && (
                <button onClick={this.activate}>
                  <img src={imgSrc('edit')} />
                </button>
              )}
          </div>
        );
      }
    }

    return null;
  }
}
