// A copy paste from website project, probably should be extracted to npm.
export async function updateLocationFromManagerAndCacheInLocalStorage() {
  if (typeof window === undefined) { return ''; }
  const cachedCountryName = localStorage.getItem('TRLocationManagerCountry');
  if (cachedCountryName) {
    return cachedCountryName;
  }

  try {
    const res = await fetch('//trlocationmanager.azurewebsites.net/api/location/get');
    const json = await res.json();
    const countryName = (json.country as string).toLowerCase();
  
    localStorage.setItem('TRLocationManagerCountry', countryName);
  
    return countryName;
  } catch {
    return ''
  }
}