import * as React from 'react';
import Component from 'react-pure-render/component';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { defaultMsg } from './msg';
import './index.styl';

export class TrDashboardMgmtFeesStats extends React.PureComponent<{
  doAllMgmtFeesHaveShares: boolean;
  mgmtFee: any;
  msg?: any;
}> {
  static propTypes = {};

  static defaultProps = {
    msg: defaultMsg
  };

  render() {
    const { doAllMgmtFeesHaveShares, mgmtFee, msg } = this.props;
    return (
      <div className="tr-dashboard-mgmt-fees-stats">
        <dl>
          <dt className="similarity">{msg.similarity}</dt>
          <dd className="similarity">
            <FormattedNumber
              maximumFractionDigits={1}
              minimumFractionDigits={1}
              value={mgmtFee.get('similarity') * 100}
            />%
            <span className="dd-secondary">{msg.veryHigh}</span>
          </dd>
        </dl>
        <dl>
          <dt className="save">{msg.save}</dt>
          <dd className="save">
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={mgmtFee.get('savePercent') * 100}
            />%
            <span className="dd-secondary">
              {0 < mgmtFee.get('saveAmount') && (
                <FormattedMessage
                  id={msg.amountPerYear}
                  defaultMessage={msg.amountPerYear}
                  values={{
                    price: (
                      <FormattedNumber
                        currency="usd"
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        style="currency"
                        value={mgmtFee.get('saveAmount')}
                      />
                    )
                  }}
                />
              )}
            </span>
          </dd>
        </dl>
      </div>
    );
  }
}

export default TrDashboardMgmtFeesStats;
