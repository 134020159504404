import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './styles.istyl';
import { Icon } from 'tipranks-icons';
import { connect } from 'react-redux';
import { ReduxProps } from 'sp/common/types';
import { isLoadingSlug, getPublicUrl } from 'sp/browser/pages/PublicPortfolio/Onboarding/utils';
import { VariableSizeSpinner } from 'sp/browser/Spinner/index.react';
import mapDispatchToProps from 'sp/common/app/mapDispatchToProps';
import mapStateToProps from 'sp/common/app/mapStateToProps';
import { ConnectedPureComponent } from 'sp/common/lib/ConnectedComponent';
import { selectLatestCreatedPublicPortfolio } from 'sp/common/auth/selectors';
import { createPublicUrlFromSlug } from 'sp/browser/pages/PublicPortfolioToRegister/utils';

@connect(mapStateToProps, mapDispatchToProps)
export class ThirdOnboardingPage extends ConnectedPureComponent<{}, ReduxProps> {
  render() {
    const { auth } = this.connected;
    const latestCreatedPublicPortfolio = selectLatestCreatedPublicPortfolio(auth);
    const url = createPublicUrlFromSlug(latestCreatedPublicPortfolio.get('id'), latestCreatedPublicPortfolio.get('slug'));

    return <main>
      <h1>Congratulations!</h1>
      <h2>You joined the TipRanks Community</h2>
      <div className={classNames(styles.sectionBody)}>
        <img src={require('./images/1.png')} className={styles.step3image} />
        <div className={styles['public-portfolio-at']}>Your public profile is now available at:</div>
        {isLoadingSlug(auth)
          ? <VariableSizeSpinner className={styles['user-public-portfolio-url']} size={20} />
          : <a className={styles['user-public-portfolio-url']} href={url}>{url}</a>
        }
      </div>
    </main>;
  }
}