import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import Component from 'react-pure-render/component';

import HighlightChange from '../HighlightChange';
import * as numeral from 'numeral';

const abs = val => (val ? Math.abs(val) : null);

export default class DailyGain extends Component {
  static propTypes = {
    activePortfolio: PropTypes.object,
    percentOnly: PropTypes.bool,
    period: PropTypes.string
  };

  render() {
    const { activePortfolio, percentOnly, period = 'daily' } = this.props;

    const gain = type => activePortfolio.getIn(['gain', period, type]);

    const gainValueStyle = classNames({
      negative: gain('percent') < 0,
      positive: gain('percent') > 0
    });

    const valueVal = gain('val');
    const isValidValueVal = valueVal && valueVal !== 0;
    const valuePercent = gain('percent');
    const isValidPercentVal = valuePercent && valuePercent !== 0;

    if (isNaN(valueVal)) return null;

    return (
      <div className={gainValueStyle}>
        {isValidValueVal && !percentOnly ? (
          <span className="val">
            <HighlightChange value={valueVal}>
              {numeral(abs(valueVal)).format('$0,0.00')}
            </HighlightChange>
          </span>
        ) : null}
        &nbsp;
        <span
          className={classNames('percent', {
            secondery: isValidValueVal && !percentOnly
          })}
        >
          {isValidPercentVal ? (
            <HighlightChange value={valuePercent}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                style="percent"
                value={abs(valuePercent)}
              />
            </HighlightChange>
          ) : (
              <span>-</span>
            )}
        </span>
      </div>
    );
  }
}
