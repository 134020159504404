import { brokers as brokerImgs } from './images';

export const tradeIt = {
  apiKey: 'cae833c3bbed41cd89833a787e95bc3',
  callbackURL: `${location.origin}/welcome/tradeit/success.html`
};

interface SecurityQuestionDetails {
  userToken?: string;
  userId?: string;
  securityAnswer?: string;
  srv?: string;
  broker?: string;
}
export const urls = {
  terms: '/terms',
  auth: broker => `/api/TradeItAuthentication/GetLink?broker=${broker}`,
  answerSecurityQuestion: ({
    userToken,
    userId,
    securityAnswer,
    srv,
    broker
  }: SecurityQuestionDetails) =>
    `/api/TradeItAuthentication/AnswerSecurityQuestion?userToken=` +
    `${userToken}&userId=${userId}&securityAnswer=${securityAnswer}&srv=${
      srv
    }&broker=${broker}`,
  syncWithVerifier: ({ broker, verifier }) =>
    `/api/TradeItAuthentication/SuccessWithVerifier?oAuthVerifier=${
      verifier
    }&broker=${broker}`
};

/*  brokers order
    =============
    if you add a new item to the brokers list,
    then make sure you also add:
    * `${broker}.png` and `${broker}-hover.png`
      files to './assets/img' directory.
    * an import of the new files on ./images */
export class Broker {
  constructor(
    public id: BrokerId,
    public name: string,
    public inPopup: boolean = false
  ) {}
  get img() {
    return brokerImgs[this.id].regular;
  }
  get imgHover() {
    return brokerImgs[this.id].hover;
  }
  toString() {
    return this.name;
  }
}

export type BrokerId =
  | 'ib'
  | 'etrade'
  | 'fidelity'
  | 'td'
  | 'optionshouse'
  | 'scottrade'
  | 'schwab'
  | 'tradestation'
  | 'robinhood'
  | 'drivewealth'
  | 'vanguard'
  | 'capital one'
  | 'ally'
  // test
  | 'dummy';
export const brokers = [
  new Broker('ib', 'Interactive Brokers', true),
  new Broker('etrade', 'E*Trade', true),
  new Broker('fidelity', 'Fidelity', true),
  // new Broker('td', 'TD Ameritrade'),
  // new Broker('optionshouse', 'Optionshouse'),
  new Broker('scottrade', 'Scottrade'),
  // new Broker('schwab', 'Charles Schwab'),
  new Broker('tradestation', 'TradeStation', true),
  new Broker('robinhood', 'Robinhood'),
  // new Broker('tradeking', 'TradeKing'),
  // new Broker('drivewealth', 'DriveWealth'),
  new Broker('capital one', 'Capital One', true),
  new Broker('ally', 'Ally Invest')
];

if (window.location.href.includes('super-secret-dummy-broker')) {
  brokers.push(new Broker('dummy', 'Forgivness Brokerage Inc'));
}

// stage enum
export enum Stages {
  Brokers,
  Auth,
  ReAuth,
  SecurityQuestion,
  Error
}

// question type enum
export const securityQuestionType = {
  open: 'open',
  challengeImage: 'challengeImage',
  choices: 'choices'
};

export const connectExistingPortfoliosPath = 'connect';
