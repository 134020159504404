import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as raf from 'raf';

// get configuration
export const DEFAULT_COLOR = '#f2990d';
export const DEFAULT_SIZE = 21;
export const DEFAULT_ANIMATED = true;
export const ITEMS_TOTAL = 5;
export const STARS_POINTS = 5;
export const STARS_INSET = 0.5;
export const MARGIN_FACTOR = 0.4;
export const FRAME_CHANGE = 0.01;
export const MAX_RATING = 100;
export const partSize = MAX_RATING / ITEMS_TOTAL / 100;
export const EMPTY_COLOR = 'transparent';
export const LINE_WIDTH = 1.8;

export interface ExpertRatingProps {
  rating: number;
  animate: boolean;
  size: number;
  color?: string;
  title?: string;
}

export class ExpertRating extends React.Component<ExpertRatingProps> {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  color: string;
  itemSize: number;
  rating: number;
  itemWidthAndMargin: number;
  itemMargin: number;

  static defaultProps: ExpertRatingProps = {
    animate: DEFAULT_ANIMATED,
    color: DEFAULT_COLOR,
    size: DEFAULT_SIZE,
    rating: -1
  };

  render() {
    return (
      <canvas
        title={this.props.title}
        className="expert-rating"
        {...this.calcWidth()}
        ref="canvas"
      />
    );
  }

  clearCanvas() {
    const { height, width } = this.canvas;
    this.ctx.clearRect(0, 0, width, height);
  }

  calcStop(i: number, rating: number) {
    const range = {
      from: (i - 1) * partSize,
      to: partSize * i
    };
    if (rating < range.from) {
      return 0;
    }
    if (range.to < rating) {
      return 1;
    }
    return (rating - (i - 1) * partSize) / partSize;
  }

  drawStarPoint(p: number, rDotM: number, intNegR: number) {
    const piOverP = Math.PI / p;
    const negRDotM = 0 - rDotM;
    this.ctx.rotate(piOverP);
    this.ctx.lineTo(0, negRDotM);
    this.ctx.rotate(piOverP);
    this.ctx.lineTo(0, intNegR);
  }

  drawStarPoints(p: number, rDotM: number, intNegR: number, count: number = 1) {
    this.drawStarPoint(p, rDotM, intNegR);
    if (count < p) {
      this.drawStarPoints(p, rDotM, intNegR, count + 1);
    }
  }

  drawStar(
    cx: number,
    cy: number,
    r: any,
    p: number,
    m: number,
    index: number,
    rating: number
  ) {
    const intPosR = parseInt(r, 10);
    const intNegR = 0 - intPosR;
    const rDotM = parseInt((r * m).toString(), 10);
    const grd = this.ctx.createLinearGradient(intNegR, 0, intPosR, 0);
    const stop = this.calcStop(index, rating);
    grd.addColorStop(0, this.color);
    grd.addColorStop(stop, this.color);
    grd.addColorStop(stop, EMPTY_COLOR);
    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.translate(cx, cy);
    this.ctx.moveTo(0, intNegR);
    this.drawStarPoints(p, rDotM, intNegR);
    this.ctx.strokeStyle = this.color;
    this.ctx.lineWidth = this.itemSize / DEFAULT_SIZE * LINE_WIDTH;
    this.ctx.fillStyle = grd;
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.restore();
  }

  drawStars(rating: number, cx: number, cy: number, index: number) {
    const i = ITEMS_TOTAL - index;
    const r = this.itemSize / 2;
    this.drawStar(cx, cy, r, STARS_POINTS, STARS_INSET, i, rating);
    if (index > 0) {
      const newCx = cx + this.itemWidthAndMargin;
      const newIndex = index - 1;
      this.drawStars(rating, newCx, cy, newIndex);
    }
  }

  animate(frmRating: number) {
    const { animate } = this.props;
    return () => {
      this.clearCanvas();
      const cx = this.itemSize / 2;
      const cy = this.itemSize / 2;
      const total = ITEMS_TOTAL - 1;
      this.drawStars(frmRating, cx, cy, total);
      if (frmRating < this.rating) {
        raf(this.animate(animate ? frmRating + FRAME_CHANGE : this.rating));
      }
    };
  }

  draw() {
    const { color, rating, animate } = this.props;
    this.color = color as string;
    this.rating = rating;
    this.itemWidthAndMargin = this.itemSize + this.itemMargin;
    raf(this.animate(animate ? 0 : rating));
  }

  componentDidMount() {
    const { color, rating } = this.props;
    this.canvas = ReactDOM.findDOMNode(this.refs.canvas) as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.draw();
    // this.draw(color, rating > -1 ? rating : 0);
  }

  componentDidUpdate() {
    const { color, rating } = this.props;
    if (rating > -1) {
      this.draw();
      // this.draw(color, rating);
    }
  }

  calcWidth() {
    const { size } = this.props;
    this.itemSize = parseInt(size.toString(), 10);
    this.itemMargin = this.itemSize * MARGIN_FACTOR;
    return {
      width:
        ITEMS_TOTAL * this.itemSize + this.itemMargin * (ITEMS_TOTAL - 1) + 5,
      height: this.itemSize
    };
  }
}

export default ExpertRating;
