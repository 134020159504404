import classNames from 'classnames';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Component from 'react-pure-render/component';
import { companyConstants } from '../../common/config';
import CircleButton from './circle-button.react';
import { basicTableTypes, basicTableTypesList } from './conf';
import TrDashboardHoldingsThInner from './th-inner.react';
import TrDashboardHoldingsTdInner from './td-inner.react';
import TrDashbaordHoldingsTdInnerExpanded from './td-inner-expanded.react';
import { isAllowed } from '../../common/auth/selectors';
import { BasicTableType } from '../../common/types';

// TODO rename file
export class BasicTable extends Component {
  // static propTypes = {
  //   children: PropTypes.element,
  //   activateStock: PropTypes.func,
  //   changeEditable: PropTypes.func,
  //   deleteItem: PropTypes.func,
  //   dependencies: PropTypes.object,
  //   editableId: PropTypes.string,
  //   enums: PropTypes.object,
  //   getCols: PropTypes.func,
  //   hideTooltip: PropTypes.func,
  //   isImportedPortfolio: PropTypes.bool,
  //   isDesktop: PropTypes.bool,
  //   isRestricted: PropTypes.bool,
  //   items: PropTypes.object,
  //   mediaQuery: PropTypes.object,
  //   modes: PropTypes.object,
  //   msg: PropTypes.object,
  //   nextStock: PropTypes.string,
  //   onAddPortfolioItemButtonClicked: PropTypes.func,
  //   openAddStockDialog: PropTypes.func,
  //   openPortfolioItemAlerts: PropTypes.func,
  //   openSyncDialog: PropTypes.func,
  //   portfolioId: PropTypes.number,
  //   setShares: PropTypes.func,
  //   setPurchasePrice: PropTypes.func,
  //   setStockLastRead: PropTypes.func,
  //   showPromotion: PropTypes.func,
  //   showTooltip: PropTypes.func,
  //   shouldDisplayAddPortfolioItemButton: PropTypes.bool,
  //   shouldDisplayAddStocksButton: PropTypes.bool,
  //   sortHoldingsTable: PropTypes.func,
  //   tableSortable: PropTypes.bool,
  //   viewerPlan: PropTypes.string
  //basicTableType
  // setBasicTableType
  // };

  static defaultProps = {
    onAddPortfolioItemButtonClicked: () => null
  };

  constructor(...args) {
    super(...args);
    this.state = {
      activeTr: '',
    };
  }

  onClickTr = activeTrNext => {
    const { activateStock } = this.props;
    const { activeTr } = this.state;
    this.setState({
      activeTr: activeTrNext === activeTr ? '' : activeTrNext
    });
    activateStock();
  };

  onTypeChange = (basicTableType: BasicTableType) => {
    const { showPromotion, viewerPlan, setBasicTableType } = this.props;
    const minPlan = basicTableTypes[basicTableType].minPlan;
    if (!isAllowed(minPlan, viewerPlan)) {
      showPromotion(minPlan, 'bestAnalystConsensus');
    } else {
      setBasicTableType(basicTableType);
    }
  };

  isActiveTr = item => {
    const { activeTr } = this.state;
    return activeTr === item.get('ticker');
  };

  render() {
    const {
      changeEditable,
      deleteItem,
      dependencies,
      editableId,
      enums,
      getCols,
      getDetailGroups,
      hideTooltip,
      isDesktop,
      isImportedPortfolio,
      items,
      isRestricted,
      mediaQuery,
      modes,
      msg,
      nextStock,
      onAddPortfolioItemButtonClicked,
      openAddStockDialog,
      openPortfolioItemAlerts,
      portfolioId,
      setPurchasePrice,
      setShares,
      setStockLastRead,
      showPromotion,
      showTooltip,
      shouldDisplayAddPortfolioItemButton,
      shouldDisplayAddStocksButton,
      sortHoldingsTable,
      tableSortable,
      viewerPlan,
      screener,
      basicTableType,
      children
    } = this.props;

    const isLoading =
      [dependencies.get('portfolios'), dependencies.get('portfolioItems')].some(
        state => state === 'LOADING'
      ) && !screener;

    const isDetailsLoading = dependencies.get('details') === 'LOADING';

    const colsToShow = getCols(mediaQuery).map((col: ColType) =>
      col.set(
        'id',
        col.getIn(['alternatives', basicTableType], col.get('id'))
      )
    );

    return (
      <section className={classNames('tr-dashboard-holdings-table')}>
        <aside>
          <menu>
            <span className="menu-title">{msg.showing}</span>
            <ul>
              {basicTableTypesList.map((type: BasicTableType) => (
                <li
                  className={classNames({
                    isRestricted: !isAllowed(type.minPlan, viewerPlan),
                    premium: type.minPlan === 'premium'
                  })}
                  key={type.id}
                >
                  <label className="v2">
                    <input
                      checked={basicTableType === type.id}
                      onChange={() => this.onTypeChange(type.id)}
                      type="radio"
                    />
                    <span className="label">
                      <span>
                        <FormattedHTMLMessage
                          defaultMessage={msg[`menu.${type.id}`]}
                          id={msg[`menu.${type.id}`]}
                        />
                      </span>
                    </span>
                  </label>
                  {type.hasTooltip && (
                    <div className="tooltip-wrapper">
                      <button>i</button>
                      <div className="tooltip">
                        {msg[`menu.${type.id}.tooltip`]}
                      </div>
                    </div>
                  )}
                  <div
                    onClick={() => this.onTypeChange(type.id)}
                    className="after"
                  >
                    Premium
                  </div>
                </li>
              ))}
            </ul>
          </menu>
        </aside>
        <main>
          <table>
            <thead>
              <tr>
                {colsToShow.map(col => (
                  <th className={col.get('id')} key={col.get('id')}>
                    <TrDashboardHoldingsThInner
                      col={col}
                      msg={msg}
                      onClick={sortHoldingsTable}
                      tableSortable={tableSortable}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            {items.map(item => (
              <tbody
                className={classNames({
                  active: this.isActiveTr(item)
                })}
                key={item.get('ticker')}
              >
                <tr className={classNames('pointable')}>
                  {colsToShow.map((col, index) => (
                    <td className={col.get('id')} key={col.get('id')}>
                      {!isImportedPortfolio &&
                        !shouldDisplayAddPortfolioItemButton &&
                        items.size > 1 &&
                        portfolioId !==
                        companyConstants.get('combinedUserPortfolio') &&
                        index === 0 && (
                          <CircleButton
                            item={item}
                            onClick={deleteItem}
                            type="delete"
                          />
                        )}
                      {shouldDisplayAddPortfolioItemButton &&
                        !isImportedPortfolio &&
                        portfolioId !==
                        companyConstants.get('combinedUserPortfolio') &&
                        index === 0 && (
                          <CircleButton
                            item={item}
                            onClick={onAddPortfolioItemButtonClicked}
                            type="add"
                          />
                        )}
                      <TrDashboardHoldingsTdInner
                        changeEditable={changeEditable}
                        onClickTr={this.onClickTr}
                        col={col}
                        enums={enums}
                        isRestricted={isRestricted}
                        item={item}
                        getDetailGroups={getDetailGroups}
                        hideTooltip={hideTooltip}
                        mediaQuery={mediaQuery}
                        isDetailsLoading={isDetailsLoading}
                        isMissingShares={modes.includes('missingShares')}
                        isCombinedPortfolio={
                          portfolioId ===
                          companyConstants.get('combinedUserPortfolio')
                        }
                        msg={msg}
                        openPortfolioItemAlerts={openPortfolioItemAlerts}
                        setPurchasePrice={setPurchasePrice}
                        setStockLastRead={setStockLastRead}
                        showPromotion={showPromotion}
                        showTooltip={showTooltip}
                        onClick={this.onClickTr}
                      />
                    </td>
                  ))}
                </tr>
                {this.isActiveTr(item) && (
                  <tr>
                    <td colSpan={colsToShow.size}>
                      <TrDashbaordHoldingsTdInnerExpanded
                        basicTableType={basicTableType}
                        enums={enums}
                        getDetailGroups={getDetailGroups}
                        item={item}
                        modes={modes}
                        msg={msg}
                        changeEditable={changeEditable}
                        nextStock={nextStock}
                        editableId={editableId}
                        isMissingShares={modes.includes('missingShares')}
                        onAddPortfolioItemButtonClicked={
                          onAddPortfolioItemButtonClicked
                        }
                        portfolioId={portfolioId}
                        setShares={setShares}
                        isImportedPortfolio={isImportedPortfolio}
                        isCombinedPortfolio={
                          portfolioId ===
                          companyConstants.get('combinedUserPortfolio')
                        }
                        openPortfolioItemAlerts={openPortfolioItemAlerts}
                        setPurchasePrice={setPurchasePrice}
                        setStockLastRead={setStockLastRead}
                        showPromotion={showPromotion}
                        showTooltip={showTooltip}
                        shouldDisplayAddPortfolioItemButton={
                          shouldDisplayAddPortfolioItemButton
                        }
                        stock={item}
                        onClick={this.onClickTr}
                        hideTooltip={hideTooltip}
                        mediaQuery={mediaQuery}
                        viewerPlan={viewerPlan}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
          </table>
        </main>
        <footer>
          {items.size === 0 ?
            (isLoading ? (
              <div className="loading">
                <img alt="Loading..." src="/assets/img/loader-big.gif" />
              </div>
            ) : children) :
            (!isImportedPortfolio && portfolioId !== companyConstants.get('combinedUserPortfolio')) &&
            <div className="add-stocks-btn">
              {isDesktop &&
                <div className="td sign">
                  <div className="td-inner">
                    <button
                      onClick={openAddStockDialog('holdings-table-footer')}
                    >
                      +
                      </button>
                  </div>
                </div>
              }
              {(!isImportedPortfolio && portfolioId !== companyConstants.combinedUserPortfolio) && shouldDisplayAddStocksButton &&
                <div className="td label">
                  <div className="td-inner">
                    <button
                      onClick={(openAddStockDialog('holdings-table-footer'))}
                    >
                      <span className="before"><span>+</span></span>
                      {msg.addBtn}
                    </button>
                  </div>
                </div>
              }
            </div>
          }
        </footer>
      </section>
    );
  }
}
