import { setLastLockedFeature } from '../../common/lib/utils';
import * as React from 'react';
import Clickable from '../Clickable/clickable.react';
import * as classnames from 'classnames';
import { Link } from 'react-router';
// import { LinkPlansFromFeatureOrOrigin } from '@tipranks/marketing-links';
import { getSpCurrentPageName } from '../tr-payment';
import { getPlansLink, buildEvents } from '@tipranks/checkout-link';

// it doesnt extends React.HTMLAttributes<HTMLElement>
interface PlansLinkProps {
  className?: string;
  onClick?: (e: React.MouseEventHandler<HTMLElement>) => void;
  custom3?: string;
  target?: string; // TODO
  queryParameters?: { [key: string]: any };
  originOrFeature?: string;
}
// TODO
const HackLink = Link as any;

export class PlansLink extends React.PureComponent<PlansLinkProps, {}> {
  static defaultProps = {
    target: '_blank'
  };
  // args in case they're needed, we don't use them
  onClick = e => {
    const { onClick, custom3 } = this.props;
    if (onClick) onClick(e);
    if (custom3) setLastLockedFeature(custom3);
  };
  render() {
    if(process.env.THEME !== 'nasdaq') {
      const { originOrFeature, custom3, queryParameters, ...props } = this.props;
      const featureOrOrigin = originOrFeature || `sp-${custom3}` || 'unknown-smart-portfolio';
      const pageName = getSpCurrentPageName('sp-to-plans-something-went-wrong');
      const events
        = buildEvents(
          pageName,
          featureOrOrigin,
          { ...props },
        );
      return (
        <Clickable
        {...props}
        {...events}
        to={getPlansLink(featureOrOrigin, queryParameters)}
      />
      )
    }
    const { className, children, } = this.props;
    return (
      <HackLink
        className={className}
        to={'/smart-portfolio/plans'}
        onClick={this.onClick}
      >
        {children}
      </HackLink>
    );
  }
}
