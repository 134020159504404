import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export default class EventCell extends Component {
  static propTypes = {
    event: PropTypes.object,
    msg: PropTypes.object
  };

  render() {
    const { event, msg } = this.props;

    return (
      <span className="event-name">
        <FormattedMessage
          defaultMessage={msg.eventTypes[event.get('type')]}
          id={msg.eventTypes[event.get('type')]}
          values={{
            price: event.getIn(['stock', 'price', 'amount']) ? (
              <FormattedNumber
                currency="usd"
                style="currency"
                value={event.getIn(['stock', 'price', 'amount'])}
              />
            ) : (
              '-'
            )
          }}
        />
      </span>
    );
  }
}
