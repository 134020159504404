import React from 'react';
import Component from 'react-pure-render/component';
import './style.styl';

export default ({ checked, onChange }) => (
  <label className="tr-switch-super-specific-classname">
    <input type="checkbox" checked={checked} onChange={onChange} />
    <div className="switch">
      <div className={'wheel'} />
      <span>ON</span>
      <span>OFF</span>
    </div>
  </label>
);
