import * as React from 'react';
import Component from 'react-pure-render/component';
import { promote } from '../TargetedWebPage/actions';

import report from './analytics.ts';

import { ApiDecorator } from '../ApiDecorator';
// TODO remove default export
import TrDashboardHoldingsSection from '../tr-dashboard-holdings-section';
import { selectIsDisabled } from '../tr-dashboard-alerts-settings/selectors';

import { getDetailGroups } from '../tr-dashboard-holdings-table/conf';

import {
  selectPageItems,
  selectPages,
  itemsSelector
} from '../tr-dashboard-holdings-container/selectors';

import {
  selectIsPermanentLoggedIn,
  selectViewerPlan
} from '../../common/auth/selectors.ts';

import { popupRegister } from '../../common/lib/utils.ts';

import {
  selectActivePortfolio,
  selectEditableId,
  selectHoldingsCols,
  selectHoldingsPage,
  selectIsHoldingsSortDescending,
  selectProccessStates,
  selectTblType,
  selectoHoldingsSortBy,
  selectBasicTableType
} from '../dashboard/selectors';

import { apiCalls, holdingsSectionConfig } from '../../common/config';
import { maybefetch } from '../../common/api-actions';
import { setStockAlertValue } from '../tr-dashboard-holdings-section/cells/EmailAlertsCell.react.tsx';
import { BasicTableType } from '../../common/types';

@ApiDecorator('stocksWithNewsSentiment', 'details', 'alertsSettings')
export default class TrDashboardHoldingsSectionContainer extends Component {
  // TODO move everything non lifecycly below render
  setBasicTableType = (basicTableType: BasicTableType) => {
    this.props.actions.setBasicTableType(basicTableType);
  }
  setShares = (portfolioId, ticker, shares, sharesTotal) => {
    const { actions } = this.props;
    report('changedSharesTotal');
    if (shares === 0 && this.getItems().size > 1) {
      return this.deleteStock(ticker);
    } else if (shares !== 0) {
      actions.setShares(portfolioId, ticker, shares, sharesTotal);
      return Promise.resolve({ hasSetShares: true });
    }
  };

  setPurchasePrice = (...args) => {
    const { actions } = this.props;
    actions.setPurchasePrice(...args);
    report('changedPurchasePrice');
  };

  fetchData = () => {
    maybefetch('portfolioItems', this.props, { force: true });
  };

  fetchPriceData = () => {
    maybefetch('details', this.props, { force: true });
  };

  openDialog = (...args) => {
    const { actions } = this.props;
    actions.openDialog(...args);
  };

  openAddStockDialog = type => () => {
    this.openDialog('addStock');
    report('clickedAddStocks', type);
  };

  openAlertsSettings = () => {
    this.props.actions.openDialog('alertsSettings');
  };

  openHoldingsMetrics = () => {
    this.openDialog('holdingsMetrics');
  };

  openPortfolioItem = ticker => {
    this.openDialog('portfolioItem', { ticker });
  };

  openPortfolioItemAlerts = ticker => {
    this.openDialog('portfolioItemAlerts', { ticker });
  };

  deleteStock = ticker =>
    new Promise((resolve, reject) => {
      const { actions, dashboard } = this.props;

      const activePortfolio = selectActivePortfolio(dashboard);

      actions.openDialog('confirmRemoval', {
        ticker,
        portfolio: activePortfolio.get('name'),
        actionWhenConfirm: () => {
          report('deleteStock');
          actions.deleteStock(activePortfolio.get('id'), ticker);
          resolve({ hasSetShares: true });
        },
        actionWhenCancel: () => resolve({ hasSetShares: false })
      });
    });

  showPromotion = (plan, type) => {
    const { auth } = this.props;
    const currentPlan = selectViewerPlan(auth);
    if (currentPlan !== 'ultimate') {
      report.setLastPromotionTrigger('clickedRestrictedTrigger', type);
      report('clickedRestricted');
      promote({ type, plan });
    }
  };

  openSyncDialog = () => args => {
    this.openDialog('syncDialog', args);
    report('clickedImportPortfolio');
  };

  activatePage = page => {
    const { actions } = this.props;
    actions.activatePage(page);
  };

  toggleTblType = tblId => {
    const { actions } = this.props;
    actions.toggleTblType(tblId);
  };

  activateStock = () => {
    report('activateStock');
  };

  sortHoldingsTable = (...args) => {
    const { actions, auth } = this.props;
    const currentPlan = selectViewerPlan(auth);
    const [colId] = args;
    const isWrongPlan = !['premium', 'ultimate'].includes(currentPlan);
    const isLimitedCol = holdingsSectionConfig.get('premium').includes(colId);
    if (isWrongPlan && isLimitedCol) {
      this.showPromotion('premium', colId);
    } else {
      actions.sortHoldingsTable(...args);
    }
  };

  openRegister = () => {
    const { actions } = this.props;
    popupRegister();
  };

  clickedExportCSV = () => {
    report('clickedExportCSV');
  };

  getItems = () => {
    const {
      actions,
      alertsSettings,
      auth,
      dashboard,
      mediaQuery,
      msg
    } = this.props;
    return this.props.mediaQuery.get('laptop')
      ? selectPageItems(dashboard)
      : itemsSelector(dashboard);
  };

  render() {
    const {
      actions,
      alertsSettings,
      auth,
      dashboard,
      mediaQuery,
      msg
    } = this.props;

    const items = this.getItems();
    const activePortfolio = selectActivePortfolio(dashboard);
    const isPendingPortfolio = activePortfolio.get('type') === 'pending'

    const isCombinedPortfolio =
      activePortfolio.get('type') === 'combinedUserPortfolio';

    const componentState = selectProccessStates(dashboard);

    return (
      <TrDashboardHoldingsSection
        activateStock={this.activateStock}
        activatePage={this.activatePage}
        changeEditable={actions.changeEditable}
        cashPercent={0}
        cashValue={activePortfolio.get('cashValue')}
        clickedExportCSV={this.clickedExportCSV}
        deleteItem={this.deleteStock}
        dependencies={componentState}
        editableId={selectEditableId(dashboard)}
        enums={msg.enums}
        fetchData={this.fetchData}
        fetchPriceData={this.fetchPriceData}
        hideTooltip={actions.hideTooltip}
        holdingsCols={selectHoldingsCols(this.props)}
        holdingsSortBy={selectoHoldingsSortBy(dashboard)}
        getDetailGroups={getDetailGroups}
        insertBefore={actions.insertBeforeHoldingsTable}
        isAlertsDisabled={selectIsDisabled(alertsSettings)}
        isholdingsSortDescending={selectIsHoldingsSortDescending(dashboard)}
        isImportedPortfolio={activePortfolio.get('isImported')}
        isCombinedPortfolio={isCombinedPortfolio}
        isPendingPortfolio={isPendingPortfolio}
        isPermanentLoggedIn={selectIsPermanentLoggedIn(auth)}
        items={items}
        openRegister={this.openRegister}
        mediaQuery={mediaQuery}
        modes={activePortfolio.get('modes')}
        msg={msg.dashboard.holdingsSection}
        noDataMsg={msg.dashboard.holdingsNodataImport}
        openAddStockDialog={this.openAddStockDialog}
        openAlertsSettings={this.openAlertsSettings}
        openHoldingsMetrics={this.openHoldingsMetrics}
        openPortfolioItem={this.openPortfolioItem}
        openPortfolioItemAlerts={this.openPortfolioItemAlerts}
        openSyncDialog={this.openSyncDialog}
        activePage={selectHoldingsPage(dashboard)}
        pages={selectPages(dashboard)}
        portfolioId={activePortfolio.get('id')}
        portfolioName={activePortfolio.get('name')}
        priceDependencies={componentState.get('details')}
        setPurchasePrice={this.setPurchasePrice}
        setShares={this.setShares}
        setStockLastRead={actions.setStockLastRead}
        showPromotion={this.showPromotion}
        showTooltip={actions.showTooltip}
        sortHoldingsTable={this.sortHoldingsTable}
        syncScroll={actions.syncScrollHoldings}
        tblType={selectTblType(dashboard)}
        onClickAlert={setStockAlertValue}
        toggleTblType={this.toggleTblType}
        viewerPlan={selectViewerPlan(auth)}
        shouldDisplayAddStocksButton
        setBasicTableType={this.setBasicTableType}
        basicTableType={selectBasicTableType(dashboard)}
        auth={auth}
        fetchPortfolios={actions.fetchPortfolios}
        changePortfolio={actions.changePortfolio}
      />
    );
  }
}
