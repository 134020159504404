import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import defaultMsg from './en';
import MarketOverview from './tr-dashboard-overview-market-overview.react';

import PortfolioOverview from './tr-dashboard-overview-portfolio-overview.react';

import ReturnVsBenchmarks from './tr-dashboard-overview-return-vs-benchmarks.react';

import { DailyGainersAndLosersSection } from './DailyGainersAndLosersSection';

export default class DashboardOverview extends Component {
  static propTypes = {
    allCombinedPortfolio: PropTypes.object,
    changedMarketOverviewMarketVisibility: PropTypes.func,
    changedMraketOverviewDuration: PropTypes.func,
    dailyGainers: PropTypes.object,
    dailyLosers: PropTypes.object,
    gains: PropTypes.object,
    isMarketOpened: PropTypes.bool,
    marketOverviewDuration: PropTypes.string,
    maxDailyGainersAndLosers: PropTypes.number,
    msg: PropTypes.object,
    portfolioName: PropTypes.string,
    portfolioOverviewStatsMsg: PropTypes.object,
    shouldShownMarketOverview: PropTypes.bool,
    shouldTransposeTable: PropTypes.bool,
    visibleMarketOverviewMarkets: PropTypes.object
  };

  static defaultProps = {
    shouldShownMarketOverview: false
  };

  render() {
    const {
      allCombinedPortfolio,
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      dailyGainers,
      dailyLosers,
      gains,
      isMarketOpened,
      marketOverviewDuration,
      maxDailyGainersAndLosers,
      msg = defaultMsg,
      portfolioOverviewStatsMsg,
      portfolioName,
      shouldShownMarketOverview,
      shouldTransposeTable,
      visibleMarketOverviewMarkets
    } = this.props;

    return (
      <section className="tr-dashboard-overview">
        {shouldShownMarketOverview && (
          <MarketOverview
            changedMarketOverviewMarketVisibility={
              changedMarketOverviewMarketVisibility
            }
            changedMraketOverviewDuration={changedMraketOverviewDuration}
            duration={marketOverviewDuration}
            msg={msg}
            visibleMarkets={visibleMarketOverviewMarkets}
          />
        )}

        <PortfolioOverview
          allCombinedPortfolio={allCombinedPortfolio}
          msg={msg}
          portfolioName={portfolioName}
          portfolioOverviewStatsMsg={portfolioOverviewStatsMsg}
        />

        <DailyGainersAndLosersSection
          dailyGainers={dailyGainers}
          dailyLosers={dailyLosers}
          isMarketOpened={isMarketOpened}
          max={maxDailyGainersAndLosers}
          msg={msg}
        />

        <ReturnVsBenchmarks
          gains={gains}
          msg={msg}
          shouldTransposeTable={shouldTransposeTable}
        />
      </section>
    );
  }
}
