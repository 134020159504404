import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import './AddPortfolio.styl';
import AddPortfolioForm from '../AddPortfolioForm';
import { disablePortfolioImport } from '../../common/config';
import { syncSmartPortfolioWithPlaid } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { identifyUser } from '../../common/api'
import * as classNames from 'classnames';
import VariableSizeSpinner from '../Spinner';

const controls = (editPortfolios, openSyncDialog) => {
  const arr = [
    {
      id: 'edit',
      action: editPortfolios,
    }
  ];
  if (!disablePortfolioImport) {
    arr.unshift(
      {
        id: 'import',
        action: openSyncDialog,
      }
    )
  }
  return arr;
}


export default class AddPortfolio extends Component {
  state = {
    isLoadingPlaid: false,
  }
  // static propTypes = {
  //   addPortfolio: PropTypes.func,
  //   close: PropTypes.func,
  //   componentStates: PropTypes.any,
  //   editPortfolios: PropTypes.func,
  //   isLimited: PropTypes.bool,
  //   isUltimate: PropTypes.bool,
  //   msg: PropTypes.object,
  //   openSyncDialog: PropTypes.func,
  //   showPromotion: PropTypes.func
  // };

  onSyncClick = () => {
    this.setState({ isLoadingPlaid: true } ); 
    const { expertId, changePortfolio, close, fetchPortfolios, navigationOnDone } = this.props;
    syncSmartPortfolioWithPlaid(expertId, {
      onSyncEnd: async (res) => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        if (typeof fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await fetchPortfolios();
        }
        if (Array.isArray(PortfolioIds) && PortfolioIds[0] && typeof changePortfolio === 'function') {
          changePortfolio(PortfolioIds[0])
        }
        this.setState({ isLoadingPlaid: false });
        
        if (typeof close === 'function') {
          close()
        }
        if (typeof navigationOnDone === 'function') {
          navigationOnDone()
        }
      },
      onSyncFailed: () => {
        this.setState({ isLoadingPlaid: false } ); 
      },
      onSyncStopped: () => {
        this.setState({ isLoadingPlaid: false } ); 
      },
    })
  }

  render() {
    const {
      addPortfolio,
      close,
      componentStates,
      editPortfolios,
      isLimited,
      isUltimate,
      msg,
      showPromotion,
    } = this.props;

    return (
      <section className="add-portfolio">
        <header>
          <h1>{msg.title}</h1>
        </header>
        <AddPortfolioForm
          addPortfolio={addPortfolio}
          done={msg.done}
          isLimited={isLimited}
          isUltimate={isUltimate}
          onSubmit={close}
          placeHolder={msg.placeHolder}
          shouldRedirect
          showPromotion={showPromotion}
          state={componentStates.get('addPortfolio')}
        />
        <p className={classNames('connecting-msg', {'isLoading': this.state.isLoadingPlaid})}>
          Connecting to your existing portfolio, please wait…
          <VariableSizeSpinner size={15} className={'addPortfolio-spinner'}/>
        </p>
        <div className={classNames({'isLoading': this.state.isLoadingPlaid})}>
        </div>
        <ul className="controls">
          {controls(editPortfolios, this.onSyncClick).map(control => (
            <li key={control.id}>
              <button onClick={control.action}>{msg[control.id]}</button>
            </li>
          ))}
        </ul>
      </section>
    );
  }
}
