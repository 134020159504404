import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

// RouterHandler is back since suggested solution via React.cloneElement sucks.
// This is just syntax sugar for react-router 1.0.0 filtering children in props.
// Note React does not validate propTypes that are specified via cloneElement.
// It is recommended to make such propTypes optional.
export default class RouterHandler extends Component {
  // static propTypes = {
  //   children: PropTypes.object
  // }

  render() {
    const { children } = this.props;
    // No children means nothing to render.
    if (!children) return null;

    // That makes nested routes working.
    const propsWithoutChildren = { ...this.props };
    delete propsWithoutChildren.children;
    return React.cloneElement(children, propsWithoutChildren);
  }
}
