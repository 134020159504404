import 'd3';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import initGraph from './js/area_chart';

const durations = ['5d', '1m', '3m', 'ytd', '1y', '5y'];

export default class TrStockBehaviourExternal extends Component {
  static propTypes = {
    ticker: PropTypes.string
  };

  constructor(...args) {
    super(...args);
    this.state = { activeDuration: '1y', shouldShowNoDataMessage: false };
  }

  componentDidMount() {
    const { ticker } = this.props;

    initGraph(ticker, {
      toggleNoDataMessage: this.toggleNoDataMessage
    });
  }

  toggleNoDataMessage = shouldShowNoDataMessage =>
    this.setState({ shouldShowNoDataMessage });

  render() {
    const { activeDuration, shouldShowNoDataMessage } = this.state;

    return (
      <div className="tr-stock-behaviour-external">
        {shouldShowNoDataMessage && (
          <div className="no-data-wrapper">
            <div className="no-data">No data</div>
          </div>
        )}
        <div className="market_wraper" id="mainBox">
          <div id="buttons_div">
            <div className="color_code">
              <div className="bottom_buttons">
                <ul>
                  {durations.map(duration => (
                    <li key={duration}>
                      <a
                        className={classNames('data_select', {
                          bottom_button_selected: activeDuration === duration
                        })}
                        data-id={duration}
                      >
                        {duration.toUpperCase()}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div id="area_chart" />
        </div>
      </div>
    );
  }
}
