import { getReq, postReq } from '../../api';
import { mapKeys, invert, pickBy } from 'lodash';

import { selectActivePortfolio } from '../../../browser/dashboard/selectors';

import { selectTickers } from '../../../browser/tr-dashboard-user-performance-container/selectors';
import { ComponentStateKeys } from 'sp/common/types';

const apiUrl = '/api/portfolio';
const userApiUrl = '/api/users';

const urls = {
  get: `${apiUrl}/getUserExtendedProperties`,
  set: `${apiUrl}/setUserExtendedProperties`,
  getExtraData: `${apiUrl}/getExtraPerformanceData`,
  changeUserName: `${userApiUrl}/changeUserName`,
};

const keyMap = {
  age: 'age',
  profession: 'profession',
  income: 'annualIncome',
  term: 'investmentTerm',
  tolerance: 'riskTolerance',
};

Object.assign(keyMap, invert(keyMap));

export const postField = arr =>
  postReq(
    urls.set,
    arr.map(({ value, type }) => ({
      propertyName: keyMap[type],
      propertyValue: value || null,
    })),
  );

export const getFields = () => getReq(urls.get);

export const getExtraPerformanceData = portfolioId =>
  getReq(`${urls.getExtraData}/?portfolioId=${portfolioId}`);

export const adapt = obj =>
  mapKeys(
    pickBy(obj, (v, k) => keyMap[k] !== undefined),
    (value, key) => keyMap[key],
  );

export const changeUserName = name => postReq(urls.changeUserName, { name });

let isDirty = true;
export const apiDependency = (apiName: ComponentStateKeys) => {
  switch (apiName) {
    case 'myPerformanceFields':
      return ({ tryFetch, props, prevProps, force }) => {
        const { actions } = props;
        setTimeout(() => (isDirty = false), 1);
        return tryFetch(actions.getFields, {
          force: false,
          isValid: true,
          isDirty,
        });
      };

    case 'extraPerformanceData':
      return ({ tryFetch, props, prevProps, force }) => {
        const { actions, dashboard } = props;
        const portfolioId = selectActivePortfolio(props.dashboard).get('id');
        const isDirty =
          !prevProps ||
          portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

        return tryFetch(() => actions.extraPerformanceData(portfolioId), {
          force: false,
          isValid: true,
          isDirty,
        });
      };

    case 'performanceTickerDetails':
      return ({ tryFetch, props, prevProps, force }) => {
        const { actions, dashboard, performance } = props;
        const tickers = selectTickers(performance);
        const prevTickers = prevProps && selectTickers(prevProps.performance);
        const isDirty = tickers !== prevTickers && tickers.size > 0;

        return tryFetch(
          () => actions.fetchTransactionsPrices(tickers, { silent: false }),
          { force: false, isValid: true, isDirty },
        );
      };

    case 'userExtendedInfo':
      return ({ tryFetch, props, prevProps, force }) => {
        const { actions, dashboard, performance } = props;
        const tickers = selectTickers(performance);
        const prevTickers = prevProps && selectTickers(prevProps.performance);
        const isDirty = tickers !== prevTickers && tickers.size > 0;

        return tryFetch(
          () => actions.fetchTransactionsPrices(tickers, { silent: false }),
          { force: false, isValid: true, isDirty },
        );
      };
    default:
      return () => Promise.resolve(null);
  }
};
