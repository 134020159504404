
export type NotificationsStoreState = {
  notifications: AnyNotification[];
  statistics: {
    totalExpertUnread: number;
    totalStockUnread: number;
  };
  stocks: string[];
};

export enum ExpertTypeEnum {
  Analyst = 1,
  Writer = 2,
  Blogger = 3,
  ExpertFirm = 4,
  ForexCommod = 5,
  Inactive = 6,
  Insider = 7,
  Institutional = 8,
  Unknown = 9,
  User = 10
}
export enum OperationActionEnum {
  Initiated = 1,
  Resumed = 2,
  Upgraded = 3,
  Downgraded = 4,
  Maintained = 5,
  Reiterated = 6,
  HasStatus = 7,
  Assigned = 8,
  Last = 9
}

export enum RatingType {
  Buy = 1,
  Hold = 2,
  Sell = 3
}

export interface GetNotificationsResponse {
  notifications: AnyNotification[];
  statistics: {
    totalExpertUnread: number;
    totalStockUnread: number;
  };
}

export enum NotificationTypeEnum {
  AnalystOnStock = 0,
  BloggerOnStock = 1,
  InsiderOnStock = 2,
  Earning = 3,
  Dividend = 4,
  NewFollowingStock = 5,
  NewFollowingExpert = 6,
  PriceDropWarning = 7,
  PriceIncrease = 8,
  FiftyTwoWeekLow = 9,
  FiftyTwoWeekHigh = 10,
  // this is 999 because we might have some more notifications in the future
  // and I don't want unknown to become those types
  // However, I like nice numbers. ~Nitzan
  Unknown = 999
}

export type AnyNotification =
  | ExpertOnStock
  | BloggerOnStock
  | InsiderOnStock
  | Earning
  | Dividend
  | FollowingStock
  | FollowingExpert
  | PriceDropWarning
  | PriceIncrease
  | FiftyTwoWeekHigh
  | FiftyTwoWeekLow;

export interface NotificationBase<NotificationType extends NotificationTypeEnum, NotificationStructure = { ticker: string; targetCompany: string }> {
  type: NotificationType;
  date: Date;
  read: boolean;
  isCausedByStock: boolean;
  isCausedByExpert: boolean;
  id: string;
  notification: NotificationStructure;
}

export interface InsiderPosition {
  isTenPercentOwner: boolean;
  isDirector: boolean;
  isOfficer: boolean;
  isOther: boolean;
  officerTitle: string;
}

interface ExpertOnStock extends NotificationBase<NotificationTypeEnum.AnalystOnStock, {
  name: string;
  stars: number;
  rating: RatingType;
  action: OperationActionEnum;
  ticker: string;
  company: string;
  targetCompany: string;
}
  > { }
interface BloggerOnStock extends NotificationBase<NotificationTypeEnum.BloggerOnStock, {
  ticker: string;
  targetCompany: string;
  company: string;
  name: string;
  stars: number;
  rating: RatingType;
}
  > /**
 * Site the blogger belongs to.
 */ { }

interface InsiderOnStock extends NotificationBase<NotificationTypeEnum.InsiderOnStock, {
  ticker: string;
  targetCompany: string;
  name: string;
  stars: number;
  position: InsiderPosition;
  rating: RatingType;
  totalValue: number;
}
  > { }
interface Earning extends NotificationBase<NotificationTypeEnum.Earning, {
  ticker: string;
  targetCompany: string;
  date: string;
}
  > { }
interface Dividend extends NotificationBase<NotificationTypeEnum.Dividend, {
  ticker: string;
  targetCompany: string;
  date: string;
}
  > { }
interface FollowingStock extends NotificationBase<NotificationTypeEnum.NewFollowingStock, {
  ticker: string;
  targetCompany: string;
  isFollow: boolean;
}
  > { }
interface FollowingExpert extends NotificationBase<NotificationTypeEnum.NewFollowingExpert, {
  name: string;
  stars: number;
  expertType: ExpertTypeEnum;
  isFollow: boolean;
}
  > { }
interface PriceNotificationStructure {
  ticker: string;
  targetCompany: string;
  newPrice: number;
  stockId: number;
  close: number;
  open: number;
  isIncrease: boolean;
  extraData: number;
  triggerValue: number;
}

interface PriceDropWarning extends NotificationBase<NotificationTypeEnum.PriceDropWarning, PriceNotificationStructure> { }
interface PriceIncrease extends NotificationBase<NotificationTypeEnum.PriceIncrease, PriceNotificationStructure> { }
interface FiftyTwoWeekLow extends NotificationBase<NotificationTypeEnum.FiftyTwoWeekLow, PriceNotificationStructure> { }
interface FiftyTwoWeekHigh extends NotificationBase<NotificationTypeEnum.FiftyTwoWeekHigh, PriceNotificationStructure> { }
