import { postReq } from '../../common/api';
import { getGlobalActions } from '../lib/actions';
import * as React from 'react';
import './WelcomeCSVThingy.styl';
import { makeTemporaryUserWrapper } from 'sp/common/auth';
import { selectActivePortfolio } from 'sp/browser/dashboard/selectors';
import history from '../history';
import * as _ from 'lodash';
// import parse, { Datum } from '@tipranks/portfolio-parser';
import * as csvParseCb from 'csv-parse';
import { promisify as bluebirdPromisify } from 'bluebird';
import * as classNames from 'classnames';
import { connect } from 'react-redux';

const csvParseP = bluebirdPromisify(csvParseCb) as any;
// we're using this local state and the whole system because they'll scrap this whole
// thing and using the redux thing would have made building this twice as time costly.
enum CSVUploadState {
  Initial = 'initial',
  Uploading = 'uploading',
  Failed = 'failed' /*, Success = 'success'*/
}
enum CSVErrorType {
  NoError = 'No Error',
  BadFormat = 'Badly formatted file',
  TooManyStocks = 'Too many stocks'
}

@connect()
export default class WelcomeCSVThingy extends React.Component<
any,
{
  filename: string;
  csvState: CSVUploadState;
  userStartedPlayingGif: boolean;
  csvError: CSVErrorType;
}
> {
  fileInput: any;
  state = {
    filename: '',
    csvState: CSVUploadState.Initial,
    csvError: CSVErrorType.NoError,
    userStartedPlayingGif: false
  };

  render() {
    const { mediaQuery } = this.props;
    const { filename, csvState, userStartedPlayingGif, csvError } = this.state;
    const hasFailed = csvState === CSVUploadState.Failed;

    return (
      <div className="famot">
        <h3>
          Import your portfolio from a CSV file<br />(Google Finance Compatible)
        </h3>
        <div className="famot-wrapper">
          <div className="famot-stuff">
            <input
              ref={ref => (this.fileInput = ref)}
              type="file"
              id="inputfile"
              className="inputfile"
              onChange={this.chooseFile}
              accept="csv"
            />
            <label htmlFor="inputfile" className="inputfile">
              CHOOSE FILE
            </label>
            <div className="famot-filename" title={filename}>
              {filename}
            </div>
          </div>
          <div className="famot-stuff">
            <button
              className="famot-confirm"
              onClick={this.confirmSelection}
              disabled={filename === '' || csvState === CSVUploadState.Failed}
            >
              CONFIRM
            </button>
            {/* <div className="famot-tos">I agree to TipRanks' Terms and services</div> */}
          </div>
        </div>
        {hasFailed && (
          <div className="famot-failure">
            <div className="famot-content-wrapper">
              <div className="useless-circle" />
              <div className="actual-content">
                {csvError === CSVErrorType.BadFormat ? (
                  <div>
                    The file format is incorrect. <br />Please upload a CSV file
                    and rearrange columns as in the example portfolio below
                    <img
                      onClick={() =>
                        this.setState({ userStartedPlayingGif: true })
                      }
                      className={classNames('famot-failure-picture', {
                        playGif: userStartedPlayingGif
                      })}
                    />
                    <div style={{ textAlign: 'right' }}>
                      Start by logging into your{' '}
                      <a href="https://finance.google.com/finance/portfolio">
                        Google Finance
                      </a>{' '}
                      Account
                    </div>
                  </div>
                ) : csvError === CSVErrorType.TooManyStocks ? (
                  <div>
                    There was a problem with the file.<br />There were too many
                    stocks, we support up to 128 stocks in the import process.
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <div className="famot-footer">
          <p>Please notice that in the process of scanning your spreadsheet, your
          holdings on Smart Portfolio may appear different than your holdings on
          Google Portfolio. We advise you to check your holdings following the
          import.</p>
          <p>By clicking "confirm" you agree to TipRanks'{' '}
            <a href="https://tipranks.com/terms" target="_blank">Terms of Use</a>{' '}
            and{' '}
            <a href="https://tipranks.com/privacypolicy" target="_blank">Privacy Policy</a>{' '}
            </p>
        </div>
      </div>
    );
  }
  chooseFile = async fileEvent => {
    this.setState({ csvState: CSVUploadState.Initial });
    const currentTarget = fileEvent.currentTarget;
    const value = currentTarget.value;
    const seperator = value.indexOf('\\') !== -1 ? '\\' : '/';
    this.setState({
      filename: value.split(seperator).pop()
    });
    // try {
    //   await this._readFile();
    // } catch (e) {
    //   console.error('Problem parsing CSV!', e);
    //   this.setState({ csvState: CSVUploadState.Failed });
    // }
  };

  confirmSelection = () => {
    const actions = getGlobalActions();
    const readingFile = this._readFile();
    // open loading dialog, if still loading file after 60ms, to avoid
    // a "flash" of dialog.
    let isReadingFileStill = true;
    setTimeout(() => {
      if (isReadingFileStill) actions.openDialog('analyzingPortfolio');
    }, 59); // supposedly, under 60ms user won't see it
    readingFile
      .then(data => {
        isReadingFileStill = false;
        if (data.length > 128)
          throw new Error(CSVErrorType.TooManyStocks); // thanks Sagit
        else return data;
      })
      .then(this.success)
      .then(() => {
        history.push('/smart-portfolio/holdings/holdings-new');
        actions.closeDialog();
      })
      .catch(e => {
        isReadingFileStill = false;
        if (e.message === CSVErrorType.TooManyStocks)
          this.setState({ csvError: CSVErrorType.TooManyStocks });
        else {
          this.setState({ csvError: CSVErrorType.BadFormat });
          console.warn('Error', e);
        }
        this.setState({ csvState: CSVUploadState.Failed });
        actions.closeDialog();
      });
  };
  /*uploadCSVData = async (data: { symbol: string, shares: number }[]) => {
    this.setState({ csvState: CSVUploadState.Uploading });
    const body = { holdings: data };
    const res = await fetch('/api/portfolio/csvimport', { body, method: 'POST' })
    if (res.status === 200) {
      return this.success(res.json());
    } else {
      throw new Error(`API Call CSVImport returned status code different from 200 (${res.status}`);
    }
  }*/
  success = async (data: Datum[]) => {
    getGlobalActions().openDialog('analyzingPortfolio');
    // it's not actually needed for anything to signal that we succeeded, there will be a redirect.
    // this.setState({ csvState: CSVUploadState.Success });
    // TODO this works only in the welcome page right now.
    // ie. this code needs to be adjusted to work on Logged In Users.
    const actions = getGlobalActions();
    const { dashboard } = this.props;

    await actions.makeTemporaryUser();
    const portfolioRes = await (actions.fetchPortfolios as any)();
    const activePortfolioId = _.get<number>(
      portfolioRes,
      'payload.res[0].id',
      -1
    );
    try {
      const res = await postReq('/api/portfolio/AddPortfolioStockWithShares', {
        id: activePortfolioId,
        stocks: data
      });
      await actions.getPortfolioItemsByTickers(activePortfolioId, data);
    } catch (e) {
      // actions.logout();
      throw e;
    }
  };
  // helper methods
  _readFile = (): Promise<Datum[]> =>
    new Promise((resolve, reject) => {
      const file = this.fileInput.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const text = reader.result;
        try {
          const result = parse(text as string);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = e => reject(e);
      // reader.onabort = e => reject(e);
      reader.readAsText(file);
    });
}
function parse(csv: string) {
  return parseCSV(csv);
}
async function parseCSV(text: string) {
  const csv = (await csvParseP(text)) as string[][];
  const columns = csv[0]; // first row is table headers
  const dataPoints = csv.slice(1);
  // find the columns in the table
  const symbolIndex = columns.findIndex(col => col.toLowerCase() === 'symbol');
  const sharesIndex = columns.findIndex(col => col.toLowerCase() === 'shares');
  // parse the table
  const value = dataPoints
    .filter(csvLineHasMustHaveColumns)
    .map(datums => ({
      ticker: datums[symbolIndex],
      shares: parseInt(datums[sharesIndex], 10)
    }));
  // tell 'em the types
  if (value.length === 0) throw new Error('No entries in the CSV file found.');

  return value as Datum[];

  function csvLineHasMustHaveColumns(csvLine: string[]) {
    return (
      !!csvLine[symbolIndex] && csvLine[sharesIndex] !== '' // has to exist
    ); // has to have a value (zero is fine)
  }
}

export type Datum = { ticker: string; shares: number };
