import reporter from '../analytics';

const events = {
  visited: `visited-smart-portfolio-welcome-page`,
  // clickedStartDashboard: `clicked-welcome-start-dashboard`,
  // clickedTakeTour: 'clicked-welcome-take-a-tour',
  clickedWelcomeCreatePortfolio: 'clicked-welcome-create-portfolio'
};

const report = reporter(events);

export default report;
