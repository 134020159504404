import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { FormattedDate, FormattedMessage } from 'react-intl';

import defaultMsg from './en';
import { appRoute, personRating } from 'sp/browser/lib/utils';
import ExpertRating from '../expert-rating/ExpertRating.tsx';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getTickerUrl } from '../../common/lib/utils.ts';

@connect(({ dashboard: { data } }) => ({ stats: data.get('stats') }))
export default class TrDashboardAlerts extends Component {
  static propTypes = {
    alerts: PropTypes.object,
    enums: PropTypes.object,
    msg: PropTypes.object,
    stats: PropTypes.object
  };

  static defaultProps = {
    msg: defaultMsg
  };

  strong = txt => <strong>{txt}</strong>;

  consensus = type => (
    <span className={classNames(type, `rating`)}>{type}</span>
  );

  link = person => (
    <a href={appRoute(person)} target="_blank">
      {person.get('name')}
    </a>
  );

  getAlertMsg(alert) {
    const { alerts } = this.props.enums;

    const msg =
      get(alerts, [alert.get('person').get('type'), alert.get('type')]) ||
      get(alerts, [alert.get('person').get('type'), alert.get('action')]) ||
      get(alerts, [alert.get('person').get('type'), 'default']) ||
      get(alerts, ['default', alert.get('type')]) ||
      get(alerts, ['default', alert.get('action')]);
    return msg;
  }

  render() {
    const { alerts, enums, name: propsName, stats, msg } = this.props;

    const stock = alerts.first().get('stock');

    // HACK: https://tipranks.atlassian.net/browse/TIPRANKS-12671
    // alerts were only for stocks, added this kinda hackily
    const name = propsName || stock.get('ticker');

    return (
      <section className="tr-dashboard-alerts">
        <header>
          <h1>
            <span>
              <span className="name" title={name}>
                {name}
              </span>{' '}
              <span>Notifications</span>
            </span>
          </h1>
        </header>
        <main>
          <ul>
            {alerts.map((alert, index) => {
              const stock = alert.get('stock');
              const ticker = stock.get('ticker');
              const type = stock.get('type');
              const href = getTickerUrl({ type: type, ticker: ticker });
              const stockNameLinkProps = !!href
                ? { href, target: '_blank' }
                : { className: 'no-link' };
              return (
                <li
                  className={classNames({
                    hasBeenRead: alert.get('hasBeenRead')
                  })}
                  key={index}
                >
                  <div
                    className={classNames(
                      'date',
                      alert.getIn(['person', 'type'])
                    )}
                  >
                    <FormattedDate
                      className="date"
                      day="numeric"
                      month="short"
                      value={alert.get('date')}
                      year="numeric"
                    />
                  </div>
                  <span className="rating">
                    <FormattedMessage
                      defaultMessage={this.getAlertMsg(alert)}
                      id={alert.get('action')}
                      values={{
                        personFirm: (
                          <strong>
                            {alert.getIn(['person', 'firm', 'name'])}
                          </strong>
                        ),
                        personName: (
                          <span>
                            {this.link(alert.get('person'))}{' '}
                            <ExpertRating
                              animate={false}
                              name={alert.getIn(['person', 'name'])}
                              rating={personRating(alert.get('person'), stats)}
                              size={12}
                            />
                          </span>
                        ),
                        personType: alert.getIn(['person', 'type']),
                        rating: (
                          <strong>{this.consensus(alert.get('type'))}</strong>
                        ),
                        action: (
                          <strong>
                            <span
                              className={classNames(
                                'rating',
                                alert.get('type')
                              )}
                            >
                              {enums.action[alert.get('type')]}
                            </span>
                          </strong>
                        ),
                        stockName: (
                          <strong>
                            <a {...stockNameLinkProps}>
                              {alert.getIn(['stock', 'name'])}
                            </a>
                          </strong>
                        )
                      }}
                    />
                  </span>
                  <div className="article">
                    {((
                      url = alert.getIn(['article', 'url']),
                      site = alert.getIn(['article', 'siteName'])
                    ) =>
                      !url ||
                      url.includes('tipranks.com') ||
                      !site ||
                      site.includes('tipranks.com') ? (
                        ''
                      ) : (
                        <a href={url} target="_blank">
                          {site}>
                        </a>
                      ))()}
                  </div>
                </li>
              );
            })}
          </ul>
        </main>
      </section>
    );
  }
}
