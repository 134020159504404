import { Map } from 'immutable';
import { bindActionCreators } from 'redux';

import * as uiActions from '../ui/actions';
import * as authActions from '../auth/actions';
import * as intlActions from '../intl/actions';
import * as dialogsActions from '../../browser/dialogs/actions';
import * as toastsActions from '../../browser/Toasts/actions';
import * as tooltipsActions from '../../browser/Tooltips/actions';
import * as alertsSettingsActions from '../../browser/tr-dashboard-alerts-settings/actions';
import * as portfolioCalendarActions from '../../browser/PortfolioCalendar/actions';
import * as myPerformanceActions from '../../common/components/performance/actions';
import * as myExpertsActions from '../../browser/tr-dashboard-experts/actions';
import * as autoCompleteActions from '../../browser/autocomplete/actions';
import * as mobileContainerActions from '../../browser/mobile-container/actions';
import * as contactActions from '../../browser/contact/actions';
import * as dictionaryActions from '../../browser/dictionary/actions';
import * as stockScreenerActions from '../../browser/tr-stock-screener-container/actions';
import * as dashboardActions from '../dashboard/actions';
import * as etfScreenerActions from '../../browser/tr-etf-screener-container/actions';
import * as crowdInsightsActions from '../../browser/tr-dashboard-avg-portfolio/actions';
import { actions as importedUserActions } from '../../browser/tr-dashboard-welcome-dev/actions';
import * as newsSentimentsActions from '../../browser/news-analysis/actions';
import * as accountSettingsActions from '../../browser/AccountSettings/actions';
import * as notificationActions from '../../browser/notifications/actions';
import * as portfolioPerformanceBoxActions from '../../browser/performance/portfolio-performance-box/actions';
import * as publicPortfolioActions from '../../browser/tr-dashboard-user-performance-container/public.actions';

const actions = [
  authActions,
  uiActions,
  dashboardActions,
  dialogsActions,
  toastsActions,
  alertsSettingsActions,
  tooltipsActions,
  intlActions,
  portfolioCalendarActions,
  myPerformanceActions,
  myExpertsActions,
  autoCompleteActions,
  mobileContainerActions,
  contactActions,
  dictionaryActions,
  importedUserActions,
  stockScreenerActions,
  etfScreenerActions,
  crowdInsightsActions,
  newsSentimentsActions,
  accountSettingsActions,
  notificationActions,
  portfolioPerformanceBoxActions,
  publicPortfolioActions,
];

export type ActionResult<T> = Promise<{
  type: string;
  payload: T;
}>;

export type Actions = typeof authActions &
  typeof uiActions &
  typeof dialogsActions &
  typeof toastsActions &
  typeof alertsSettingsActions &
  typeof tooltipsActions &
  typeof intlActions &
  typeof portfolioCalendarActions &
  typeof myPerformanceActions &
  typeof myExpertsActions &
  typeof autoCompleteActions &
  typeof mobileContainerActions &
  typeof contactActions &
  typeof dictionaryActions &
  typeof stockScreenerActions &
  typeof dashboardActions &
  typeof importedUserActions &
  typeof etfScreenerActions &
  typeof crowdInsightsActions &
  typeof newsSentimentsActions &
  typeof accountSettingsActions &
  typeof notificationActions &
  typeof publicPortfolioActions;

export default function mapDispatchToProps(dispatch) {
  const creators = Map<string, any>()
    .merge(...actions)
    .filter(value => typeof value === 'function')
    .toObject();

  return {
    actions: bindActionCreators(creators, dispatch),
    dispatch,
  };
}
