import { Icon } from 'tipranks-icons';
import Clickable from '../Clickable/clickable.react';
import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './style.istyl';

export class LearningCenter extends React.PureComponent<
  {},
  { showingMore: boolean }
> {
  public constructor(props: never) {
    super(props);
    this.state = { showingMore: false };
  }

  private toggleShowMore = () =>
    this.setState(state => ({ showingMore: !state.showingMore }));

  public render() {
    const showMoreClass = classNames({
      [styles.moreHidden]: !this.state.showingMore
    });

    const iconSize = 15;
    return (
      <section className={styles.learningCenter}>
        <h1>TipRanks Learning Center</h1>
        <p>
          Learn how to use and benefit from TipRanks' tools. Click any of the
          topics below and start exploring.
        </p>
        <ul>
          <li>
            <Clickable href="https://learning.tipranks.com/stock-analysis.html?series=B.C.F">
              <Icon size={iconSize} icon="barChart" />
              Stock Analysis Page
            </Clickable>
          </li>
          <li>
            <Clickable href="https://learning.tipranks.com/smart-portfolio.html?series=R.S.A">
              <Icon size={iconSize} icon="pieChart" />
              TipRanks Smart Portfolio
            </Clickable>
          </li>
          <li>
            <Clickable href="https://learning.tipranks.com/trending-stocks.html?series=C.A.M">
              <Icon size={iconSize} icon="lineChart" />
              Find investment inspiration with Trending Stocks
            </Clickable>
          </li>
          <li>
            <Clickable href="https://learning.tipranks.com/daily-analyst-ratings.html?series=E.N.A">
              <Icon size={iconSize} icon="speechBubble" />
              Find fresh investment ideas with Daily Stock Ratings
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/analysts-top-stocks.html?series=A.C.M">
              <Icon size={iconSize} icon="lineChart" />
              Generate new investment ideas with Analysts' Top Stocks
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/analyst-page-analysis.html?series=N.A.E">
              <Icon size={iconSize} icon="manWithTie" />
              Best rated analysts with Analyst Analysis Page
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/top-wall-street-analysts.html?series=D.J.K">
              <Icon size={iconSize} icon="medal" />
              Top 25 Wall Street Analysts
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/top-corporate-insiders.html?series=J.D.K">
              <Icon size={iconSize} icon="medal" />
              Top 25 Corporate Insiders
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/top-hedge-fund-managers.html?series=K.D.J">
              <Icon size={iconSize} icon="medal" />
              Top 25 Hedge Fund Managers
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/hf-page-analysis.html?series=P.E.L">
              <Icon size={iconSize} icon="building" />
              Hedge Fund Analysis Page
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/insider-hot-stocks.html?series=M.A.C">
              <Icon size={iconSize} icon="manWithDollar" />
              Fresh investment ideas with Insider Hot Stocks
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/daily-transactions.html?series=E.C.I">
              <Icon size={iconSize} icon="speechBubble" />
              Discover investment opportunities with Daily Insider Transactions
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/insider-analysis-page.html?series=O.B.N">
              <Icon size={iconSize} icon="manWithSpeechBubble" />
              Follow best rated insiders with Insider Analysis Page
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/dividend-calendar.html?series=L.E.P">
              <Icon size={iconSize} icon="calendar" />
              Track all your dividends with TipRanks' Dividend Calendar
            </Clickable>
          </li>
          <li className={showMoreClass}>
            <Clickable href="https://learning.tipranks.com/earning-calendar.html?series=F.B.C">
              <Icon size={iconSize} icon="calendar" />
              How to benefit from TipRanks' Earnings Calendar
            </Clickable>
          </li>
        </ul>
        {!this.state.showingMore && (
          <Clickable onClick={this.toggleShowMore}>See more</Clickable>
        )}
      </section>
    );
  }
}
