import { get } from 'lodash';

const unavailable = '-';

// export function format(msg, scope) {
//   return msg.replace(/\{([^}]*)\}/g,(m, m1) => {
//     const args = m1.replace(/\s+/g, '').split(',');
//
//     const value = scope[args.shift()];
//
//     if (args[0]) {
//       args[1] = args[1] || args[0];
//     }
//
//     if (!args.length) {
//       return value;
//     }
//
//     const formatter = get(formatters, args);
//
//     if (!formatter) {
//       console.error('no formatter for msg: ', msg);
//       return value;
//     }
//
//     return formatter(value);
//   });
// }

// TODO fix all files refrencing this file with
export const format = {};

const zeroIfNaN = x => (isNaN(x) ? 0 : Number(x));
const isUnavailable = x => (x !== 0 && !x) || x === unavailable;

format.currency = (amount, precision = 2) =>
  isUnavailable(amount) ? null : `$${zeroIfNaN(amount).toFixed(precision)}`;
// format(`{amount, number, ${currency}}`, {amount: zeroIfNaN(amount)});
format.number = (num, precision = 0) =>
  isUnavailable(num) ? null : zeroIfNaN(num).toFixed(precision);
format.percent = (perc, precision = 2, multiplier = 100) =>
  isUnavailable(perc)
    ? null
    : zeroIfNaN(perc * multiplier).toFixed(precision) + '%';

format.percentRounded = (perc, precision = 2) => {
  // solves issues with 0.9999999999999999 that sometimes happen
  perc = perc.toFixed(10);

  const lower = Math.pow(10, -(2 + precision));
  const upper =
    Number(
      Array(2 + precision)
        .fill(9)
        .join('')
    ) * lower;

  if (perc > upper && perc < 1) {
    return `>${(upper * 100).toFixed(precision)}%`;
  }

  if (perc < lower && perc > 0) {
    return `<${(lower * 100).toFixed(precision)}%`;
  }

  return format.percent(perc, precision);
};

format.capitalize = str => (str ? str[0].toUpperCase() + str.slice(1) : '');

format.camelToReadable = str =>
  str ? format.capitalize(str.replace(/[A-Z]/g, x => ` ${x}`).trim()) : '';
//
// const formatters = {
//   number: {
//     number: format.number,
//     USD: format.currency,
//     currency: format.currency,
//     percent: format.percent
//   }
// };

export const html = (...args) => ({ __html: format(...args) });

export let __hotReload = true;
