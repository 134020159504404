import * as classNames from 'classnames';
import * as React from 'react';
import { form, OnboardingForm, OnboardingPortfolios } from 'sp/browser/pages/PublicPortfolio/Onboarding/config';

import * as styles from './styles.istyl';

export type SecondOnboardingPageProps = {
  onChangeForm: (partialForm: Partial<OnboardingForm>) => void,
  form: OnboardingForm,
  getPortfolios: () => OnboardingPortfolios,
  initialFullName: string | undefined;
}

export class SecondOnboardingPage extends React.PureComponent<SecondOnboardingPageProps> {
  checkboxRef: HTMLInputElement | null;

  render() {
    const { getPortfolios, onChangeForm, initialFullName, form: { fullName, portfolioId, bio } } = this.props;

    return <main>
      <h1>Introduce Yourself to Other Investors!</h1>
      <h2>Write a little bit about yourself so investors get a taste of who you are and how you invest.</h2>
      <div
        className={classNames(styles.sectionBody, styles.theForm)}>
        <div className={styles.rowOrCollapse}>
          <div className={styles.field}>
            <label>Enter full name</label>
            <input
              required
              pattern="[a-zA-Z-._0-9 ]+"
              type="text"
              placeholder=""
              name={form.name}
              value={(initialFullName && initialFullName.length > 0) ? initialFullName : fullName}
              disabled={!!initialFullName}
              maxLength={25}
              onChange={({ currentTarget }) => {
                onChangeForm({ fullName: currentTarget.value })
                // this is here because we want to clear the message once the input is valid
                // if we don't, each onChange will have the previous customValidity message,
                // which will popup the old tooltip until the input loses focus.
                if (currentTarget.validity.patternMismatch) {
                  currentTarget.setCustomValidity('Please use only letters, numbers, dots or hypens.');
                } else if (currentTarget.validity.valueMissing || currentTarget.validity.tooShort) {
                  currentTarget.setCustomValidity('Please fill out your full name.');
                } else {
                  currentTarget.setCustomValidity('');
                }
              }} />
          </div>
          <div className={styles.field}><label>Choose portfolio to publish</label>
            <select
              value={portfolioId}
              onChange={({ target: { value: portfolioId } }) => onChangeForm({ portfolioId: parseInt(portfolioId) })}
              required
              name={form.portfolio}
            >
              {getPortfolios().map(portfolio => <option key={portfolio.id} value={portfolio.id}>{portfolio.name}</option>)}
            </select>
          </div>
        </div>
        <div className={classNames(styles.field, styles.describeYourself)}>
          <label>Describe this Portfolio</label>
          <textarea
            onChange={({ target: { value: bio } }) => onChangeForm({ bio })}
            required
            name={form.desc}
            maxLength={250}
            placeholder="This is my investment portfolio&hellip;" value={bio}></textarea>
          <div className={styles.bioLimit}>{bio.length}/250</div>
        </div>
        <div className={styles.tos}>
          <input ref={ref => this.checkboxRef = ref} type="checkbox" name="agree" required />
          <label htmlFor="agree" onClick={() => this.checkboxRef!.click()}>I agree to TipRanks’&nbsp;</label>
          <a className="link" href="/terms" > Terms of Use </a>
        </div>
      </div>
    </main>;
  }
}