import * as React from 'react';
import { fromJS, List, Map } from 'immutable';

import { getColById } from '../tr-dashboard-holdings-table/cols';

import { FilterConf } from './filter';
import { MediaQuery, MediaQueryKey } from 'sp/common/lib/mediaQuery';

declare function require(url: string): string;

const img1 = require('../RelatedLinks/img/optimize.png');
const img2 = require('../RelatedLinks/img/speech.png');
const img3 = require('../RelatedLinks/img/bars.png');
const img4 = require('../RelatedLinks/img/head-cash.png');
// TODO type col name
// TODO type cols
// TODO type groups
export const cols = {
  mobileS: List(['name', 'price']),
  tablet: List(['name', 'price', 'sector']),
  laptop: List(['name', 'price', 'sector', 'cap']),
  laptopL: List(['name', 'price', 'sector', 'analystConsensus', 'target']),
  desktop: List([
    'name',
    'price',
    'sector',
    'cap',
    'analystConsensus',
    'target'
  ])
};
const groups = {
  mobileS: fromJS([
    {
      width: 1,
      details: [
        'cap',
        'sector',
        'analystConsensus',
        'bestAnalystConsensus',
        'bloggerSentiment',
        'insiderSentiment',
        'hedgefundSentiment',
        'newsSentiment',
        'yield'
      ]
    }
  ]),
  mobileL: fromJS([
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 1,
      border: true,
      details: [
        'cap',
        'sector',
        'analystConsensus',
        'bestAnalystConsensus',
        'bloggerSentiment',
        'insiderSentiment',
        'hedgefundSentiment',
        'newsSentiment',
        'yield'
      ]
    }
  ]),
  tablet: fromJS([
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.5,
      border: true,
      details: [
        'cap',
        'analystConsensus',
        'bestAnalystConsensus',
        'bestTarget',
        'bloggerSentiment'
      ]
    },
    {
      width: 0.5,
      border: true,
      details: [
        'insiderSentiment',
        'hedgefundSentiment',
        'newsSentiment',
        'highLow',
        'yield'
      ]
    }
  ]),
  laptopL: fromJS([
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.5,
      border: true,
      details: [
        'cap',
        'bestAnalystConsensus',
        'bestTarget',
        'bloggerSentiment',
        'insiderSentiment'
      ]
    },
    {
      width: 0.5,
      border: true,
      details: [
        'hedgefundSentiment',
        'newsSentiment',
        'highLow',
        'yield',
        'beta'
      ]
    }
  ]),
  desktop: fromJS([
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.38,
      border: true,
      details: ['bestAnalystConsensus', 'bestTarget', 'bloggerSentiment']
    },
    {
      width: 0.31,
      border: true,
      details: ['insiderSentiment', 'hedgefundSentiment', 'newsSentiment']
    },
    {
      width: 0.31,
      border: true,
      details: ['highLow', 'yield', 'beta']
    }
  ])
};

const latestMatching = (colsObj: typeof cols | {}) => (
  mediaQuery: MediaQuery
): Map<string, any> => {
  const keys = Object.keys(colsObj) as MediaQueryKey[];
  const lastMatchingMediaQuery =
    keys.findIndex(key => !mediaQuery.get(key));
  const key = lastMatchingMediaQuery === -1
    ? keys.length - 1
    : lastMatchingMediaQuery - 1;

  return colsObj[keys[key]];
};

export const getRawCols = latestMatching(cols);

export const getDetailGroups = mediaQuery =>
  latestMatching(groups)(mediaQuery).map(
    group => group && group.set('details', group.get('details').map(getColById))
  );

const makeOption = (display, value, preview = display) => ({
  display,
  value,
  preview
});

export const maxShowMore = 5;

export const filters: FilterConf[] = [
  {
    label: 'Market Cap',
    apiLabel: 'marketCap',
    options: [
      makeOption('Mega (Over $200B)', 'mega', 'Mega'),
      makeOption('Large ($10B to $200B)', 'large', 'Large'),
      makeOption('Medium ($2B to $10B)', 'medium', 'Medium'),
      makeOption('Small ($300M to $2B)', 'small', 'Small'),
      makeOption('Micro (Under $300M)', 'micro', 'Micro')
    ],
    tooltip: 'Total market value of all outstanding shares',
    glossaryLink: 'market-cap'
  },
  {
    label: 'Sector',
    apiLabel: 'sector',
    options: [
      makeOption('Financial', 0),
      makeOption('Basic Materials', 6),
      makeOption('Consumer Goods', 3),
      makeOption('Healthcare', 1),
      makeOption('Industrial Goods', 8),
      makeOption('Services', 4),
      makeOption('Technology', 7),
      makeOption('Utilities', 5)
    ],
    tooltip: 'The sector to which a company belongs',
    glossaryLink: 'sector'
  },
  {
    label: 'Dividend Yield',
    apiLabel: 'dividendYield',
    options: [
      makeOption('None (0%)', 'none', 'None'),
      makeOption('Positive (>0%)', 'positive', 'Positive'),
      makeOption('High (>2.5%)', 'high', 'High'),
      makeOption('Very High (>5%)', 'veryHigh', 'Very High')
    ],
    tooltip:
      'A ratio of how much a company pays out each year relative to its share price',
    glossaryLink: 'dividend-yield'
  },
  {
    label: 'Analyst Consensus',
    apiLabel: 'analystConsensus',
    options: [
      makeOption('Strong Buy', 5),
      makeOption('Moderate Buy', 4),
      makeOption('Hold', 3),
      makeOption('Moderate Sell', 2),
      makeOption('Strong Sell', 1)
    ],
    tooltip:
      'Consensus of all recommendations made by analysts in the past three months',
    glossaryLink: 'analyst-consensus'
  },
  {
    label: 'Best Analyst Consensus',
    apiLabel: 'bestAnalystConsensus',
    options: [
      makeOption('Strong Buy', 5),
      makeOption('Moderate Buy', 4),
      makeOption('Hold', 3),
      makeOption('Moderate Sell', 2),
      makeOption('Strong Sell', 1)
    ],
    tooltip:
      'Consensus of all recommendations made by top performing analysts in the past three months',
    glossaryLink: 'analyst-consensus',
    lockPlan: 'premium'
  },
  {
    label: 'Blogger Consensus',
    apiLabel: 'bloggerConsensus',
    options: [
      makeOption('Bullish', 1),
      makeOption('Neutral', 2),
      makeOption('Bearish', 3)
    ],
    tooltip:
      'Consensus of all recommendations made by bloggers in the past three months',
    glossaryLink: 'blogger-consensus',
    lockPlan: 'premium'
  },
  {
    label: 'Insider Signal',
    apiLabel: 'insiderSignal',
    options: [
      makeOption('Positive', 1),
      makeOption('Neutral', 2),
      makeOption('Negative', 3)
    ],
    tooltip: 'Confidence of corporate insiders in their own company',
    glossaryLink: 'insider-signal',
    lockPlan: 'premium'
  },
  {
    label: 'Hedge Fund Signal',
    apiLabel: 'hedgeFundSignal',
    options: [
      makeOption('Positive', 1),
      makeOption('Neutral', 2),
      makeOption('Negative', 3)
    ],
    tooltip: 'Conviction of hedge fund managers in the company they invest in',
    glossaryLink: 'hedge-fund-signal',
    lockPlan: 'premium'
  },
  {
    label: 'News Sentiment',
    apiLabel: 'newsSentiment',
    options: [
      makeOption('Very Bullish', 5),
      makeOption('Bullish', 4),
      makeOption('No Sentiment', 3),
      makeOption('Bearish', 2),
      makeOption('Very Bearish', 1)
    ],
    tooltip: 'The ratio of Bullish vs. Bearish news articles for a company',
    glossaryLink: 'news-sentiment',
    lockPlan: 'premium'
  }
];

export const links = [
  {
    text: 'Which stocks do 5-Star analysts recommend?',
    icon: img1,
    href: '/stocks/top-rated'
  },
  {
    text: "See today's stock ratings",
    icon: img2,
    href: '/stock-ratings'
  },
  {
    text: 'Find the trending stocks in your sector',
    icon: img3,
    href: '/trending-stocks'
  },
  {
    text: 'Find out which stocks Insiders are trading today',
    icon: img4,
    href: '/inside-trading'
  }
];
