import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import Names from './Names.react';
import Details from './Details.react';

export default class InfoCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    openPortfolioItem: PropTypes.func,
    priceDependencies: PropTypes.any,
    stock: PropTypes.object
  };

  render() {
    const { enums, openPortfolioItem, priceDependencies, stock } = this.props;

    return (
      <button
        className="link"
        onClick={() => openPortfolioItem(stock.get('ticker'))}
      >
        <Names enums={enums} item={stock} />
        <Details
          enums={enums}
          item={stock}
          priceDependencies={priceDependencies}
        />
      </button>
    );
  }
}
