import React from 'react';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class HighLowCell extends Component {
  // static propTypes = {
  //   enums: PropTypes.object,
  //   fetchPriceData: PropTypes.func,
  //   priceDependencies: PropTypes.any,
  //   stock: PropTypes.object
  // };

  render() {
    const { enums, stock } = this.props;

    return (
      <PortfolioItemSelector
        item={stock}
        msg={enums}
        type="highLow"
        visualized
      />
    );
  }
}
