import { getReq } from '../../common/api';
import { adaptStocks } from '../../common/api-adapter.ts';
import { appendSignature } from '../../common/dashboard/actions';

export const GET_STOCKS = 'GET_STOCKS';

export const GET_STOCKS_START = 'GET_STOCKS_START';
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS';
export const GET_STOCKS_ERROR = 'GET_STOCKS_ERROR';

// todo
const makeApiCall = (fields, page) => {
  const qs = fields
    .filter(v => v.length > 0)
    .entrySeq()
    .toArray()
    .map(([key, value]) => [key, value.join(`&${key}=`)])
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return getReq(`/api/Screener/GetStocks?${qs}&page=${page}`)
    .then(({ count, data }) => ({
      stocks: adaptStocks('stockScreener')(data),
      count
    }))
    .then(appendSignature());
};

export const changeStockScreenerFilters = ({ fields, page }) => ({
  type: GET_STOCKS,
  payload: {
    data: { subtype: 'stocks', page },
    promise: (async () => {
      let res = await makeApiCall(fields, page);
      res = { ...res, fields, page, subtype: 'stocks' };
      return res;
    })()
  }
});
