import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import AlertsCell from '../../tr-dashboard-holdings-section/cells/AlertsCell.react';

export default class TrDashboardHoldingsDetailAlerts extends Component {
  static propTypes = {
    item: PropTypes.object
  };

  render() {
    const { item } = this.props;

    return (
      <div className="tr-dahsobard-holdings-detail alerts">
        <AlertsCell
          {...this.props}
          expectedHeight={450}
          expectedWidth={400}
          stock={item}
        />
      </div>
    );
  }
}
