export default {
  marketOverview: 'Market Overview',
  portfolioOverview: 'Overview',
  dailyGainers: 'Daily Gainers',
  dailyLosers: 'Daily Losers',
  returnVsBenchmarks: 'My Return vs Benchmarks',
  portfolioSizeTimeLine: 'My Portfolio Size Over Time',
  portfolioSizeTimeLineNote:
    "This graph will become active once your portfolio has accumulated a sufficient amount of data.\nYou will receive the full view in a few weeks once it's fully compiled.",
  noHoldings: 'Currently no holdings to show',
  closedMarket: 'US markets are currently closed.'
};
