import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { html } from 'sp/browser/lib/utils';
import { actions } from './index.js';
// import BottomBanner from './bottom-banner.react';
import msg from './msg';
import { get } from 'lodash';
import { setActions } from './index';
import report from './analytics';
import { history } from 'sp/browser/history';
import { Helmet } from 'react-helmet';
import { isNasdaq } from '../../common/config';

class KnowledgeDictionary extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    routeParams: PropTypes.object
  };

  componentDidMount() {
    setActions(this.props.actions);
    if (
      this.props.glossary &&
      (this.props.glossary.noData ||
        this.props.glossary.slug !== this.props.slug)
    ) {
      this.downloadPage(this.props, this.props, true);
    }
  }

  downloadPage(prevProps, nextProps, force) {
    const prevSlug = get(prevProps, 'slug', prevProps.routeParams.slug);
    const nextSlug = get(nextProps, 'slug', nextProps.routeParams.slug);

    if (force || nextSlug !== prevSlug) {
      actions.downloadPage(nextSlug);
    }
  }

  componentDidUpdate(prevProps) {
    this.downloadPage(prevProps, this.props);
  }

  dangerousHtmlClick = event => {

    const isATag = event.target && event.target.href;
    const isInternalHref = () => event.target.href.includes(location.origin);

    if (isATag && isInternalHref()) {
      event.preventDefault();
      history.push({ pathname: event.target.href.split(location.origin)[1] });
    }
    // const hasAnalytic = e.target.className.match(/banner-(small|medium)/);
    // if (hasAnalytic) {
    //   const analyticName = e.target.src.split('/').pop().split('.').shift();
    //   report('contentBannerClicked', {
    //     name: analyticName,
    //     term: this.props.routeParams.slug,
    //     isTablet: isMedia.test('(min-width: 651px)')
    //   });
    // }
    // const dataAnalytic = e.target.getAttribute('data-analytic');
    // if (dataAnalytic) {
    //   report('dataAnalytic', { analytic: dataAnalytic });
    // }
  };

  bannerClick = name => {};

  render() {
    const { glossary = {}, hasTitle } = this.props;
    return (
      <div className="wrapper">
        {glossary.noData ? (
          <div className="no-data" />
        ) : (
          <article className="article knowledge-dictionary">
            {hasTitle !== false &&
              glossary.post_title && (
                <div>
                  <h1 className="title">{glossary.post_title}</h1>
                  <div className="path">
                    {msg.pathRoot} > {glossary.post_title}
                  </div>
                </div>
              )}
            <div
              onClick={this.dangerousHtmlClick}
              className="content"
              {...html(glossary.post_content)}
            />
          </article>
        )}
        {/*hasBanners !== false && <div className="bottomBanner">
        <BottomBanner onClick={this.bannerClick} msg={msg.bottomBanner}/>
      </div>*/}
      </div>
    );
  }
}

export default class DictionaryPage extends React.Component {
  componentWillMount() {
    report('visited');
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          {isNasdaq ? <meta name="robots" content="noindex" /> : <link rel="canonical" href={`https://www.tipranks.com${window.location.pathname.replace("smart-portfolio/", "")}`} /> }
        </Helmet>
      <div className="dictionary-page">
        <KnowledgeDictionary {...this.props} glossary={this.props.dictionary} />
      </div>
      </React.Fragment>
    );
  }
}

export { KnowledgeDictionary };

export let __hotReload = true;
