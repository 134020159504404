import * as React from 'react';

import TrDashboardMgmtFeesOverview from '../tr-dashboard-mgmt-fees-overview';

import {
  selectHighestMgmtFees,
  selectIsMgmtFeesLoading,
  selectMgmtFeesSaveAmount,
  selectDoAllMgmtFeesHaveShares,
  selectMgmtFeesSavePercent
} from '../dashboard/selectors';
import { Dashboard } from 'sp/common/types';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export default class TrDashboardMgmtFeesOverviewContainer extends React.PureComponent<{
  dashboard: Dashboard;
  mediaQuery: MediaQuery;
}> {
  render() {
    const { dashboard, mediaQuery } = this.props;

    const mgmtFees = selectHighestMgmtFees(dashboard);
    const saveAmount = selectMgmtFeesSaveAmount(dashboard);
    const isLoading = selectIsMgmtFeesLoading(dashboard);

    return (
      <TrDashboardMgmtFeesOverview
        doAllMgmtFeesHaveShares={selectDoAllMgmtFeesHaveShares(dashboard)}
        isHeaderTooltipShown={mediaQuery.get('tablet')}
        isImportantNoteShown={mediaQuery.get('tablet')}
        isLoading={isLoading}
        isShownIfNoFees={mediaQuery.get('laptop')}
        isTableFundNameShown={mediaQuery.get('laptop')}
        mgmtFees={mgmtFees}
        saveAmount={saveAmount}
        savePercent={selectMgmtFeesSavePercent(dashboard)}
      />
    );
  }
}
