import { fromJS, List, Record, Map } from 'immutable';
import { PlaidSyncStatus } from './types';
import {
  DashboardStock,
  DashboardStock_NotImmutable,
  MgmtFeeRecordJSRecord,
  MgmtFeeRecordsJS_NotImmutable,
  PortfolioFeedSetRecord,
  PortfolioFeedSetRecord_NotImmutable,
  PortfolioGainRecord,
  PortfolioGainSet_NotImmutable,
  PortfolioGainSetRecord,
  PortfolioItemRecord,
  PortfolioItemRecord_NotImmutable,
  PortfolioNewsSetRecord,
  PortfolioPerformanceSetRecord,
  PortfolioPerformanceSetRecord_NotImmutable,
  PortfolioRecord,
  PortfolioRecord_NotImmutable,
  PortfolioRiskRecord,
  PortfolioRiskRecord_NotImmutable,
  PortfolioStatsRecord,
  PortfolioStatsRecord_NotImmutable,
  TradeItSyncStatus,
  PortfolioNewsSetRecord_NotImmutable,
  DashStockPriceRecord_NotImmutable,
  DashStockPriceRecord,
} from './types';
import {
  ImmutableConstructor,
  PortfolioGainRecord as PortfolioGainType,
  PortfolioGainSetRecord as PortfolioGainSetType,
  PortfolioFeedSetRecord as PortfolioFeedSetType,
  PortfolioNewsSetRecord as PortfolioNewsSetType,
  PortfolioPerformanceSetRecord as PortfolioPerformanceSetType,
  PortfolioItemAlert as PortfolioItemAlertType,
  PortfolioItemRecord as PortfolioItemType,
  PortfolioRiskRecord as PortfolioRiskType,
  PortfolioStatsRecord as PortfolioStatsType,
  PortfolioRecord as PortfolioRecordType,
  PortfolioGain_NotImmutable,
} from 'sp/common/types';

export const createRecord = <BetterRecordName, BetterRecordProps>(
  props: BetterRecordProps,
): ImmutableConstructor<BetterRecordName, BetterRecordProps> =>
  Record(props) as any;

// TODO figure out if it's ok to put zero instead of null to satisfy the typesystem.
export const PortfolioGain = createRecord<
  PortfolioGainRecord,
  PortfolioGain_NotImmutable
  >({
    percent: 0,
    val: 0,
  });

export const PortfolioGainSet = createRecord<
  PortfolioGainSetRecord,
  PortfolioGainSet_NotImmutable
  >({
    daily: new PortfolioGain(),
    monthly: new PortfolioGain(),
    quarterly: new PortfolioGain(),
    yearly: new PortfolioGain(),
    sixMonth: new PortfolioGain(),
    threeYearly: new PortfolioGain(),
    ytd: new PortfolioGain(),
  });

export const Price = createRecord<DashStockPriceRecord, DashStockPriceRecord_NotImmutable>({
  cap: 0,
  date: null,
  changePercent: null,
  changeAmount: null,
  ftWeekLow: null,
  beta: 0,
  ftWeekHigh: null,
  amount: null,
});

export const Consensus = Record({
  decision: null,
  distribution: [],
});

export const Stock = createRecord<DashboardStock, DashboardStock_NotImmutable>({
  analystConsensus: new Consensus(),
  bestAnalystConsensus: new Consensus(),
  exchangeRate: 1,
  hasLink: false,
  dividend: null, // ???
  dividendDate: null, // a date representing when the next company divident execution will be
  earningsReport: null, // a date representing when the next company earnings report will be
  eps: null, // Earnings per share, http://www.investopedia.com/terms/e/eps.asp
  id: '', // the stock's UID for usage with the database
  insiderSentiment: null, // insider sentiment, specified in our backend's might enum,
  insiderSentimentScore: 0,
  hedgefundSentiment: null, // hedge fund sentiment, specified in our backend's might enum,
  hedgefundSentimentScore: 0,
  bloggerSentiment: null, // blogger sentiment, specified in our backend's might enum,
  bloggerSentimentScore: 0,
  name: '', // The company's name, for example Apple
  price: new Price(),
  pe: 0, // pe :)
  sector: '', // The stock's sector, these correspond to our database SectorIDs as a string
  bestTarget: 0, // the stock's price target, according to TipRanks Best analysts.
  target: 0, // the stock's price target, according to TipRanks analysts.
  targetPercent: 0,
  bestTargetPercent: 0,
  ticker: '', // The stock's ticker string - 1-5 letters, always lowe-cased by an adapter
  type: 'stock', // the stock's type, either "stock" or "etf", we intend to support mutual funds later
  isSecondaryTicker: false,
  yield: null, // the stock's dividend yield
  expenseRatio: 0,
  newsSentiment: null,
  newsSentimentScore: null,
  '1yrReturn': 0,
  '3yrReturn': 0,
  '5yrReturn': 0,
  category: 0,
  etfType: '1',
  issuer: 0,
  investmentStyle: 0,
  totalAssets: 0,
  managementFee: 0,
  landmarkPrices: fromJS({
    YTDReturn: 0,
    threeMonthsReturn: 0,
    '1yrReturn': 0,
  }),
  marketCap: 0,
  source: '', // Which adapter did you come from?
});

export const PortfolioHistoryItem = Record({
  id: -1,
  date: null,
  stock: null,
  price: 0,
  action: 0,
  shares: 0,
  value: 0,
});

export const PortfolioEvent = Record({
  id: -1,
  date: null,
  stock: new Stock(),
  type: null,
});

export const Person = Record({
  firm: null,
  location: '',
  id: 0,
  name: '',
  photoId: '',
  rank: 0,
  rankOverall: 0,
  rankSector: 0,
  ratingsTotal: 0,
  isFollowing: null,
  isAlertsOn: null, // same as isFollowing for compatibility
  portfolioValue: 0,
  followingTotal: 0,
  ratingsGoodTotal: 0,
  returnAvg: 0,
  successRate: 0,
  operationsDistribution: List(),
  role: '',
  type: '',
  urlName: '',
  alerts: List(),
  readAllAlerts: null,
  instanceOf: ['person'],
});

export const PortfolioFeedSet = createRecord<
  PortfolioFeedSetRecord,
  PortfolioFeedSetRecord_NotImmutable
  >({
    analyst: List(),
    insider: List(),
    blogger: List(),
  });

export const PortfolioFeed = Record({
  action: -1,
  date: null,
  person: new Person(),
  priceTarget: null,
  stock: null,
  type: 0,
});

export const PortfolioNews = Record({
  date: null,
  sentiment: -1,
  stockType: 'stock',
  addedOn: null,
  site: '',
  pictureUrl: null,
  ticker: '',
  text: '',
  url: '',
});

export const PortfolioNewsSet = createRecord<
  PortfolioNewsSetRecord,
  PortfolioNewsSetRecord_NotImmutable
  >({
    all: List(),
    positive: List(),
    negative: List(),
  });

export const PortfolioPerformance = Record({
  date: null,
  price: 0,
});

export const PortfolioPerformanceSet = createRecord<
  PortfolioPerformanceSetRecord,
  PortfolioPerformanceSetRecord_NotImmutable
  >({
    past: List(),
  });

export const Article = Record({
  url: null,
  siteName: null,
});

export const PortfolioItemAlert = Record({
  action: -1,
  date: null,
  id: -1,
  hasBeenRead: false,
  person: null,
  article: null,
  stock: null,
  type: -1,
});

export const ExpertsSet = Record({
  analysts: List(),
  bloggers: List(),
  insiders: List(),
  hedgeFunds: List(),
});

export const PortfolioItem = createRecord<
  PortfolioItemRecord,
  PortfolioItemRecord_NotImmutable
  >({
    addedDate: new Date(),
    alerts: List(),
    id: -1,
    readAllAlerts: true,
    isAlertsOn: false,
    ticker: '',
    name: '',
    isSecondaryTicker: false,
    stock: new Stock(),
    portfolioNews: new PortfolioNewsSet(),
    sharesTotal: 0,
    percentPortfolio: 0,
    sharesValue: 0,
    purchasePrice: 0,
    addedPrice: 0,
    gainSinceAdded: 0,
    sincePurchaseGain: 0,
    sincePurchaseGainAmount: 0,
  });

export const PortfolioRisk = createRecord<
  PortfolioRiskRecord,
  PortfolioRiskRecord_NotImmutable
  >({
    userRisk: 0,
    companyRisk: 0,
    above: 0,
  });

export const PortfolioAssetDatum = Record({
  id: '',
  count: 0,
});

export const PortfolioAsset = Record({
  id: '',
  data: List(),
});

export const PortfolioStats = createRecord<
  PortfolioStatsRecord,
  PortfolioStatsRecord_NotImmutable
  >({
    pe: 0,
    isImported: false,
    id: 0,
    gain: new PortfolioGainSet(),
    amount: 0,
    yield: null,
    changes: null,
    cashValue: 0,
  });

export const PortfolioWarning = Record({
  ticker: '',
  warningType: 0,
  warningSubType: 0,
  date: null,
  data: Map(),
});

export const Portfolio = createRecord<
  PortfolioRecord,
  PortfolioRecord_NotImmutable
  >({
    asset: List(),
    id: -1,
    type: '',
    tradeItSyncStatus: TradeItSyncStatus.Ok,
    plaidSyncStatus: PlaidSyncStatus.Active,
    isImported: false,
    events: List(),
    feed: new PortfolioFeedSet(),
    history: List(),
    name: '',
    siteName: '',
    news: List(),
    performance: new PortfolioPerformanceSet(),
    risk: new PortfolioRisk(),
    stats: new PortfolioStats(),
    modes: List(), // caculated on selection can hold 'missingShares'
    tickers: List(),
    warnings: List(),
    items: List(),
    managementFees: 0,
    managementFeesPercent: 0,
    cashValue: 0,
  });

export const User = Record({
  experts: new ExpertsSet(),
  suggested: new ExpertsSet(),
  person: new Person(),
  portfolios: List(),
  type: 'free',
});

export const Stats = Record({
  numPortfolios: 27133, // TODO why 27133?
  expertsTotal: 0,
  investorsTotal: 0,
  bloggersTotal: 0,
  hedgefundsTotal: 0,
  insidersTotal: 0,
  sectorsTotal: {},
  analystsTotal: 0,
});

export const MgmtFee = createRecord<
  MgmtFeeRecordJSRecord,
  MgmtFeeRecordsJS_NotImmutable
  >({
    expenseRatio: 0,
    expense: 0,
    fundSymbol: '',
    type: '',
    fundName: '',
    simlarFundName: '',
    percentPortfolio: 0,
    similarExpenseRatio: 0,
    similarSymbol: '',
    similarity: 0,
    saveAmount: 0,
    savePercent: 0,
    sector: '',
    similarSector: '',
    mktCap: 0,
    similarMktCap: 0,
  });

export const UserActivity = Record({
  id: null,
  ticker: null,
  name: null,
  gain: null,
  gainAmount: null,
  dateOpened: null,
  isOpened: null,
  amountAdded: null,
  execPrice: null,
  dateClosed: null,
  itemType: null,
});
