import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

const max = alerts =>
  alerts.reduce(
    (max, alert) => (alert.get('id') > max ? alert.get('id') : max),
    0
  );

export default class AlertsCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    expectedHeight: PropTypes.number,
    expectedWidth: PropTypes.number,
    hideTooltip: PropTypes.func,
    mediaQuery: PropTypes.object,
    openPortfolioItemAlerts: PropTypes.func,
    setStockLastRead: PropTypes.func,
    showTooltip: PropTypes.func,
    stock: PropTypes.object,
    name: PropTypes.string
  };

  static defaultProps = {
    expectedHeight: 430,
    expectedWidth: 400
  };

  render() {
    const {
      hideTooltip,
      enums,
      mediaQuery,
      openPortfolioItemAlerts,
      setStockLastRead,
      showTooltip,
      stock,
      name,
      expectedHeight,
      expectedWidth
    } = this.props;

    const hasAlerts = stock && stock.get('alerts').size > 0;
    const isMobile = !mediaQuery.get('tablet');

    const enter = () => {
      if (!hasAlerts || isMobile) {
        return;
      }

      showTooltip(
        'alerts',
        {
          expectedHeight,
          expectedWidth,
          stock,
          name
        },
        this.refs.btn
      );
    };

    const leave = event => {
      if (!hasAlerts || isMobile) {
        return;
      }

      hideTooltip('alerts', event);
    };

    const click = event => {
      event.preventDefault();
      event.stopPropagation();

      if (isMobile) {
        openPortfolioItemAlerts(stock.get('ticker'), stock);
      } else {
        enter();
      }

      if (!stock.get('readAllAlerts')) {
        setStockLastRead(
          max(stock.get('alerts')),
          stock.getIn(['stock', 'id']),
          stock.get('ticker'),
          stock
        );
      }
    };

    return (
      <div
        className={classNames('button', {
          active: hasAlerts
        })}
        onMouseEnter={enter}
        onMouseLeave={leave}
        ref="btn"
      >
        <PortfolioItemSelector
          item={stock}
          msg={enums}
          props={{
            isColorful: mediaQuery.get('tablet'),
            onClick: click
          }}
          type="alerts"
        />
      </div>
    );
  }
}
