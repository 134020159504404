export default {
  title: 'Your Smart Portfolio Awaits',
  description:
    'Here you can control your holdings, get relevant news, view personalized analytics and compare your performance to others.',
  benefitsTitle: 'Key Benefits',
  benefits: [
    'See what top analysts say about your stocks',
    'Compare your portfolio to similar portfolios',
    'Learn how to increase returns and reduce risk from the most accurate investors'
  ],
  startDashboard: 'Start Your Smart Portfolio',
  tour: 'Take a Tour',
  screens: [
    {
      _id: 'overview',
      title: 'Overview',
      text:
        'At a glance, see how your portfolio stacks up against the S&P 500 and other TipRanks users.'
    },
    {
      _id: 'holdings',
      title: 'My Holdings',
      text:
        'View your portfolio holdings with fundamentals, relevant news and upcoming events.'
    },
    {
      _id: 'analysis',
      title: 'Portfolio Analysis',
      text:
        'View statistics on your holdings (asset allocation, risk, dividends, and more) and compare to other TipRanks portfolios.'
    }
  ]
};
