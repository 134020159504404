export const sortAlphabeticallyIn = path => (itemA, itemB) => {
  if (itemA.getIn(path) < itemB.getIn(path)) return -1;
  if (itemA.getIn(path) > itemB.getIn(path)) return 1;
  return 0;
};

export const sortNumericallyIn = path => (itemA, itemB) =>
  itemA.getIn(path) - itemB.getIn(path);


export const sortAbsNumericallyIn = path => (itemA, itemB) =>
  Math.abs(itemA.getIn(path)) - Math.abs(itemB.getIn(path));

export const sortByArraySizeIn = path => (itemA, itemB) =>
  itemA.getIn(path).size - itemB.getIn(path).size;

export const sortByDateIn = path => (a, b) =>
  new Date(a.getIn(path)).getTime() - new Date(b.getIn(path)).getTime();

export const sortByListIndexIn = (path, list) => (itemA, itemB) =>
  list.indexOf(itemA.getIn(path)) - list.indexOf(itemB.getIn(path));

export const descendtify = (fn, isDescending = true) => (...items) =>
  fn(...items) * (isDescending ? -1 : 1);
