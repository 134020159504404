import reporter from '../analytics';

const events = {
  clickedRestricted: 'clicked-locked-feature',
  clickedRestrictedTrigger: type => type,
  'visited-smart-portfolio-news-analysis-page':
    'visited-smart-portfolio-news-analysis-page'
};

export const report = reporter(events);
