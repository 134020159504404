import * as React from 'react';
import { Theme } from 'sp/common/types';

const CompanyName = ({
  icon = false,
  theme
}: {
  icon?: boolean;
  theme: Theme;
}) => {
  const names = {
    web: 'TipRanks',
    nasdaq: 'Nasdaq'
  };

  const icons = {
    web: () => null,
    nasdaq: () => (
      <img
        alt=""
        src="/assets/img/nasdaq-icon.png"
        style={{
          marginRight: '5px',
          verticalAlign: 'middle'
        }}
      />
    )
  };

  return (
    <span>
      {icon && icons[theme]()}
      {names[theme]}
    </span>
  );
};

export default CompanyName;
