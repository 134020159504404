import { fromJS } from 'immutable';

import assetIds from './assetIds';

const yellows = [
  '#ebc21d',
  '#eba223',
  '#e27a0a',
  '#c45f15',
  '#b04110',
  '#acacac'
];

const purples = [
  '#d70979',
  '#b908c4',
  '#8413e2',
  '#620fc0',
  '#3409ac',
  '#acacac'
];

const blues = [
  '#403ce6',
  '#156ec8',
  '#289dc8',
  '#25c4e6',
  '#19e6da',
  '#acacac'
];

const greens = [
  '#00ca85',
  '#05b33e',
  '#97bb18',
  '#00923e',
  '#00764e',
  '#acacac'
];

const sectorsNasdaq = [
  '#3C5761',
  '#4F92AB',
  '#16ACD4',
  '#03CEF8',
  '#6DCFF6',
  '#C6E6F2',
  '#1A4A5C',
  '#0F7692',
  '#039FC1',
  '#CCCCCC'
];

const cashColor = '#a6d6e0';

const themes = {
  web: {
    alloc: {
      total: [
        [assetIds.assetId_stock, yellows[1]],
        [assetIds.assetId_etf, purples[1]],
        [assetIds.assetId_fund, greens[1]],
        ['cash', cashColor]
      ],
      [assetIds.assetId_stock]: yellows,
      [assetIds.assetId_etf]: purples,
      [assetIds.assetId_fund]: greens,
      cash: cashColor
    },
    holding: {
      [assetIds.assetId_stock]: blues,
      [assetIds.assetId_etf]: blues,
      [assetIds.assetId_fund]: blues,
      cash: cashColor
    }
  },
  nasdaq: {
    alloc: {
      total: [
        [assetIds.assetId_stock, '#144977'],
        [assetIds.assetId_etf, '#07a9d4'],
        [assetIds.assetId_fund, '#03cef9'],
        ['cash', cashColor]
      ],
      [assetIds.assetId_stock]: sectorsNasdaq,
      [assetIds.assetId_etf]: sectorsNasdaq,
      [assetIds.assetId_fund]: sectorsNasdaq,
      cash: cashColor
    },
    holding: {
      [assetIds.assetId_stock]: sectorsNasdaq,
      [assetIds.assetId_etf]: sectorsNasdaq,
      [assetIds.assetId_fund]: sectorsNasdaq,
      cash: cashColor
    }
  }
};

export default theme => fromJS(themes[theme]);
