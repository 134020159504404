const nasdaqLogo = require('./images/nasdaq-logo.png');
const nasdaqLogoPhone = require('./images/nasdaq-logo-phone.png');
const mostPopular = require('./images/most-popular.png');
const subscriptionBtn1 = require('./images/subcription-btn-1.png');
const subscriptionBtnTab1 = require('./images/subcription-btn-tab-1.png');
const subscriptionBtnPhone1 = require('./images/subcription-btn-phone-1.png');
const subscriptionBtn2 = require('./images/subcription-btn-2.png');
const subscriptionBtnTab2 = require('./images/subcription-btn-tab-2.png');
const subscriptionBtnPhone2 = require('./images/subcription-btn-phone-2.png');
const subscriptionBtn3 = require('./images/subcription-btn-3.png');
const subscriptionBtnTab3 = require('./images/subcription-btn-tab-3.png');
const subscriptionBtnPhone3 = require('./images/subcription-btn-phone-3.png');

export { nasdaqLogo };
export { nasdaqLogoPhone };
export { mostPopular };
export { subscriptionBtn1 };
export { subscriptionBtnTab1 };
export { subscriptionBtnPhone1 };
export { subscriptionBtn2 };
export { subscriptionBtnTab2 };
export { subscriptionBtnPhone2 };
export { subscriptionBtn3 };
export { subscriptionBtnTab3 };
export { subscriptionBtnPhone3 };

const map = {
  'images/nasdaq-logo.png': nasdaqLogo,
  'images/nasdaq-logo-phone.png': nasdaqLogoPhone,
  'images/most-popular.png': mostPopular,
  'images/subcription-btn-1.png': subscriptionBtn1,
  'images/subcription-btn-tab-1.png': subscriptionBtnTab1,
  'images/subcription-btn-phone-1.png': subscriptionBtnPhone1,
  'images/subcription-btn-2.png': subscriptionBtn2,
  'images/subcription-btn-tab-2.png': subscriptionBtnTab2,
  'images/subcription-btn-phone-2.png': subscriptionBtnPhone2,
  'images/subcription-btn-3.png': subscriptionBtn3,
  'images/subcription-btn-tab-3.png': subscriptionBtnTab3,
  'images/subcription-btn-phone-3.png': subscriptionBtnPhone3
};

export { map };
export default map;
