import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import msg from './msg';
import Highlight from './highlight.react';
import './search-results.styl';
import Flag from '../tr-dashboard-search/flag.tsx';

export default class SearchResults extends Component {
  static propTypes = {
    results: PropTypes.array,
    searchTerm: PropTypes.string
  };

  getText(str) {
    const { autocomplete: { query } = {} } = this.props;
    return query ? <Highlight term={query}>{str}</Highlight> : str;
  }

  render({ autocomplete: { list } = {} } = this.props) {
    const hasResults = list && list.length;
    const items = hasResults ? list : msg.trendingStocksItems;
    return (
      <div className="tr-search-results">
        <h2>{list && hasResults ? msg.suggestions : msg.trendingStocks}</h2>
        <dl>
          {items.map(({ label, value, url, category }, i) => (
            <a href={url} className="result" key={i}>
              {value && <dt>{this.getText(value)}</dt>}
              {label && <dd>{this.getText(label)}</dd>}
              {category === 'ticker' && <Flag ticker={value} />}
            </a>
          ))}
        </dl>
      </div>
    );
  }
}

SearchResults.routeConfig = {
  hasFooter: false
};

export const __hotReload = true;
