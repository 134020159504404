import * as moment from 'moment';
import * as classNames from 'classnames';
import * as React from 'react';
import * as numeral from 'numeral';
import { isFinite as _isFinite, isNumber as _isNumber, isNumber } from 'lodash';
import RestrictedButton from '../RestrictedButton';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { portfolioItemUrl } from '../decisions/index';
import NoData from './NoData.react';
import Bar from '../bar';
import AlertsIndicator from '../AlertsIndicator';
import HighlightChange from '../HighlightChange';
import TrStockBehaviour from '../tr-stock-behaviour';
import Clickable from '../Clickable/clickable.react.js';
import { calcLandmarkPercentange, calc1YrReturn } from './utils';
import { getTickerUrl, betterToFixed } from 'sp/common/lib/utils';
import { PortfolioItemRecord, PortfolioItemRecord_NotImmutable, DashboardStock, DashboardStock_NotImmutable } from 'sp/common/types';
import { isCanadianTicker } from 'sp/browser/lib/utils';
import { currencySymbolFromTicker } from 'sp/browser/Money/Money';

const restrictedValue = (
  value,
  type,
  text,
  msg,
  isRestricted,
  showPromotion
) => {
  if ((_isNumber(value) && !_isFinite(value))) {
    return <NoData />;
  } else if (isRestricted) {
    return (
      <RestrictedButton
        analyticName="column"
        enums={msg}
        plan="premium"
        showPromotion={showPromotion}
        type={type}
      />
    );
  } else {
    return text;
  }
};

export default class PortfolioItemSelector extends React.PureComponent<{
  item: PortfolioItemRecord; // PropTypes.object,
  type: string; // PropTypes.string,
  msg?: any; // PropTypes.object,
  target?: any;
  className?: any;
  showPromotion?: any;
  visualized?: boolean; // PropTypes.bool
  props?: any; // PropTypes.object,
  isRestricted?: boolean; // PropTypes.bool,
}, {
    description: string,
    descriptionStatus: string,
  }> {
  static initialDescription = 'Loading description, please wait.';

  constructor(props) {
    super(props);
    this.state = {
      description: PortfolioItemSelector.initialDescription,
      descriptionStatus: 'closed'
    };
  }
  getDescription(item) {
    if (this.state.description !== PortfolioItemSelector.initialDescription)
      return this.state.description;
    fetch(`/api/funds/getetfoverviewdata?ticker=${item.get('ticker')}`)
      .then(x => x.json())
      .then(x => this.setState({ description: normalize(x.description.description, item) }))
      .catch(() => this.setState({ description: 'No Description Available' }))
    return this.state.description;
    function normalize(description, item) {
      if (description === '-' || !description) {
        const divYield = item.getIn(['stock', 'dividend']);
        const divYieldStr = divYield
          ? ` with a ${normalizeNumber(divYield)} dividend yield`
          : '';
        return `The ${item.getIn(['stock', 'category'])} fund ${item.get(
          'name'
        )} manages ${normalizeNumber(
          item.getIn(['stock', 'totalAssets'])
        )} in assets${divYieldStr}.`;
      }
      return description;
    }
  }

  getDescriptionData = item => /* [string, bool]*/ {
    const data = this.getDescription(item);
    const words = data.split(' ');
    const shouldShowMore = words.length > 40;
    if (this.state.descriptionStatus === 'closed') {
      return [words.slice(0, 40).join(' '), shouldShowMore]; // first 40 words
    } else {
      return [data, shouldShowMore];
    }
  };
  renderDescription(item) {
    const [text, shouldShowMore] = this.getDescriptionData(item);
    const hasMore = this.state.descriptionStatus === 'closed' && shouldShowMore;
    return (
      <div className="etf-description">
        <strong>Description:</strong> {text + (hasMore ? '… ' : '')}
        <span>
          {hasMore ? (
            <a
              onClick={this.descriptionClicked(item)}
              href={`https://www.tipranks.com/etf/${item.get('ticker')}`}
            >
              Show More&hellip;
            </a>
          ) : null}
        </span>
      </div>
    );
  }
  descriptionClicked = item => e => {
    if (this.state.descriptionStatus === 'closed') {
      this.setState({ descriptionStatus: 'open' });
      e.preventDefault();
    } else {
      // don't prevent default link
    }
  };
  render() {
    const {
      item,
      msg,
      props,
      type,
      isRestricted,
      showPromotion,
      visualized,
      className,
      target: linkTarget,
      ...restProps
    } = this.props;

    const getProps = name => props && props[name];
    const getPrice = (type: string): number => item.getIn(['stock', 'price', type]) || 0;

    switch (type) {
      case 'ticker': {
        const ticker = item.get('ticker');
        if (!ticker) {
          return null;
        }

        const type = item.getIn(['stock', 'type']);
        const href = getTickerUrl({ type, ticker });
        if (href) {
          const onClick = event => {
            event.stopPropagation();
            return false;
          };
          return (
            <Clickable
              href={href}
              onClick={onClick}
              className={className}
              target={linkTarget}
            >
              {ticker.toUpperCase()}
            </Clickable>
          );
        } else {
          return (
            <span className={classNames(className, 'no-link')}>
              {ticker.toUpperCase()}
            </span>
          );
        }
      }

      case 'firm': {
        return <span>{item.getIn(['firm', 'name'])}</span>;
      }

      case 'name': {
        return <span className={className}>{item.get('name')}</span>;
      }

      case 'sector': {
        const value = item.getIn(['stock', 'sector']);
        const text = msg.sectors[value] || value;
        return (
          <span {...restProps}>{value ? text : <NoData />}</span>
        );
      }

      case 'alerts': {
        return (
          <AlertsIndicator
            alerts={item.get('alerts')}
            isColorful={getProps('isColorful')}
            readAllAlerts={item.get('readAllAlerts')}
            onClick={getProps('onClick')}
          />
        );
      }

      case 'price': {
        const amount = getPrice('amount');
        const value = isNumber(amount) ? parseFloat(amount.toFixed(2)) : 0;
        return (
          <HighlightChange value={value}>
            <span>
              {currencySymbolFromTicker(item.get('ticker'))}{value}
            </span>
          </HighlightChange>
        );
      }

      case 'totalAssets': {
        const value = item.getIn(['stock', 'totalAssets']) || 0;
        return (
          <span>
            <b>${normalizeNumber(value)}</b>
          </span>
        );
      }

      case 'priceChange': {
        const value = getPrice('changeAmount') || 0;
        return (
          <HighlightChange value={value}>
            <span
              className={classNames('preventBefore', {
                positive: value > 0,
                negative: value < 0
              })}
            >
              <span>
                {currencySymbolFromTicker(item.get('ticker'))}
                {numeral(Math.abs(value))
                  .format('0.00a')
                  .toUpperCase()}
              </span>
            </span>
          </HighlightChange>
        );
      }

      case 'price-details': {
        if (!item.get('ticker')) {
          return null;
        }

        const value = getPrice('changePercent');

        return (
          <div>
            <div className="amount">
              <PortfolioItemSelector item={item} msg={msg} type="price" />
            </div>
            <div className={getChangeStyle(getPrice('changePercent'))}>
              {value === 0 ? (
                <div>UNCH</div>
              ) : (
                  <div>
                    {visualized && value > 0 && <span>&#9650;</span>}
                    {visualized && value < 0 && <span>&#9660;</span>}
                    <span className="change-amount">
                      <PortfolioItemSelector
                        item={item}
                        msg={msg}
                        type="priceChange"
                        visualized={visualized}
                      />
                    </span>
                    <span className="change-percent">
                      <PortfolioItemSelector
                        item={item}
                        msg={msg}
                        type="priceChangePercent"
                      />
                    </span>
                  </div>
                )}
            </div>
          </div>
        );
      }

      case 'target': {
        return target(item, 'target', getPrice, msg);
      }

      case 'bestTarget': {
        return target(
          item,
          'bestTarget',
          getPrice,
          msg,
          isRestricted,
          showPromotion
        );
      }

      case 'threeMonthsReturn': {
        return getLandmarkPriceComponentFor(item, 'threeMonthsReturn');
      }
      case 'YTDReturn': {
        return getLandmarkPriceComponentFor(item, 'YTDReturn');
      }
      case '1yrReturn': {
        return getChangeComponent(calc1YrReturn(item));
      }
      case '3yrReturn': {
        return getChangeComponent(item.getIn(['stock', '3yrReturn']) * 100);
      }
      case '5yrReturn': {
        return getChangeComponent(item.getIn(['stock', '5yrReturn']) * 100);
      }

      case 'description': {
        return this.renderDescription(item);
      }

      case 'priceChangePercent': {
        const value = getPrice('changePercent') || 0;
        return (
          <HighlightChange value={value}>
            {Math.abs(value * 100).toFixed(2)}%
          </HighlightChange>
        );
      }

      case 'analystConsensus': {
        const value = item.getIn(['stock', 'analystConsensus', 'decision']);
        const text = msg.analystConsensus[value];
        return (
          <span className={classNames(value)}>
            {value ? text : <NoData />}
          </span>
        );
      }

      case 'bestAnalystConsensus': {
        const value = item.getIn(['stock', 'bestAnalystConsensus', 'decision']);
        const text = msg.analystConsensus[value];

        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );

        return <span className={classNames(value)}>{el}</span>;
      }

      case 'insiderSentiment': {
        const value = item.getIn(['stock', 'insiderSentiment']);
        const text = msg.insiderSentiment[value];

        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );

        return (
          <span className={classNames(`insiderSentiment_${value}`)}>
            <span className="bar-label">{el}</span>
            {visualized &&
              _isFinite(value) &&
              !isRestricted && (
                <Bar
                  style={{
                    background:
                      'linear-gradient(to right, #90408f 0%, #19a4ae 100%)',
                    filter:
                      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#90408f', endColorstr='#19a4ae',GradientType=1 )"
                  }}
                  value={item.getIn(['stock', 'insiderSentimentScore']) || 0}
                />
              )}
          </span>
        );
      }

      case 'bloggerSentiment': {
        const value = item.getIn(['stock', 'bloggerSentiment']);
        const text = msg.bloggerSentiment[value];

        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );

        return (
          <span className={classNames(`bloggerSentiment_${value}`)}>
            <span className="bar-label">{el}</span>
            {visualized &&
              _isFinite(value) &&
              !isRestricted && (
                <Bar
                  style={{
                    background:
                      'linear-gradient(to right, #90408f 0%, #19a4ae 100%)',
                    filter:
                      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#90408f', endColorstr='#19a4ae',GradientType=1 )"
                  }}
                  value={item.getIn(['stock', 'bloggerSentimentScore'])}
                />
              )}
          </span>
        );
      }

      case 'newsSentimentScore': {
        const value = item.getIn(['stock', 'newsSentimentScore']);
        if (!_isFinite(value)) {
          return <NoData />;
        }
        const enumz = value > 0.6 ? 1 : value < 0.4 ? 3 : 2;
        const text = msg.bloggerSentiment[enumz];
        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );
        return (
          <span className={classNames(`newsSentiment_${enumz}`)}>
            <span className="bar-label">{el}</span>
            {visualized &&
              _isFinite(value) &&
              !isRestricted && (
                <Bar
                  style={{
                    background:
                      'linear-gradient(to right, #90408f 0%, #19a4ae 100%)',
                    filter:
                      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#90408f', endColorstr='#19a4ae',GradientType=1 )"
                  }}
                  value={value}
                />
              )}
          </span>
        );
      }

      case 'newsSentiment': {
        const value = item.getIn(['stock', 'newsSentiment']);
        const text = msg.newsSentiment[value];
        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );

        return (
          <span className={classNames(`newsSentimentScreener_${value}`)}>
            <span className="bar-label">{el}</span>
            {visualized && value && !isRestricted ?
              <Bar
                style={{
                  background:
                    'linear-gradient(to right, #90408f 0%, #19a4ae 100%)',
                  filter:
                    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#90408f', endColorstr='#19a4ae',GradientType=1 )"
                }}
                value={item.getIn(['stock', 'newsSentiment']) / 5}
              />
              : '-'}
          </span>
        );
      }

      case 'hedgefundSentiment': {
        if (isCanadianTicker(item.get('ticker'))) {
          // This weekly ugly hack is `sponsored` by Benji.
          return <span>—</span>;
        }
        const datum = item.getIn(['stock', 'hedgefundSentimentScore']);
        const value = datum < 0.4 ? 3 : datum >= 0.4 && datum < 0.6 ? 2 : 1;

        const text = msg.hedgefundSentiment[value];

        const el = restrictedValue(
          value,
          type,
          text,
          msg,
          isRestricted,
          showPromotion
        );

        return (
          <span className={classNames(`hedgefundSentiment_${value}`)}>
            <span className="bar-label">{el}</span>
            {visualized &&
              value &&
              !isRestricted && (
                <Bar
                  style={{
                    background:
                      'linear-gradient(to right, #90408f 0%, #19a4ae 100%)',
                    filter:
                      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#90408f', endColorstr='#19a4ae',GradientType=1 )"
                  }}
                  value={item.getIn(['stock', 'hedgefundSentimentScore'])}
                />
              )}
          </span>
        );
      }

      case 'low': {
        const val = getPrice('ftWeekLow');
        if (isNaN(val)) return null;
        return (
          <span>
            {currencySymbolFromTicker(item.get('ticker'))}
            {numeral(getPrice('ftWeekLow'))
              .format(val < 1e4 ? '0.00a' : '0a')
              .toUpperCase()}
          </span>
        );
      }

      case 'highLow': {
        return (
          <span>
            <span className="bar-label-before">
              <PortfolioItemSelector item={item} msg={msg} type="low" />
            </span>
            {visualized ? (
              <Bar
                max={getPrice('ftWeekHigh')}
                min={getPrice('ftWeekLow')}
                value={getPrice('amount')}
              />
            ) : (
                <span>-</span>
              )}
            <span className="bar-label-after">
              <PortfolioItemSelector item={item} msg={msg} type="high" />
            </span>
          </span>
        );
      }

      case 'high': {
        const val = getPrice('ftWeekHigh');
        if (isNaN(val)) return null;
        return (
          <span>
            {currencySymbolFromTicker(item.get('ticker'))}
            {numeral(getPrice('ftWeekHigh'))
              .format(val < 1e4 ? '0.00a' : '0a')
              .toUpperCase()}
          </span>
        );
      }

      case 'added': {
        const value = item.get('addedDate');

        const addedDate = moment(value);
        const today = moment(new Date());

        if (addedDate.isSame(today, 'day')) {
          return <span>{msg.relativeDate.today}</span>;
        }

        if (addedDate.add(1, 'days').isSame(today, 'day')) {
          return <span>{msg.relativeDate.yestrday}</span>;
        }

        return (
          <span
            className="date"
          ><FormattedDate
              day="numeric"
              month="short"
              value={value}
              year="numeric"
            /></span>
        );
      }

      case 'shares': {
        return <FormattedNumber value={item.get('sharesTotal') || 0} />;
      }

      // holding value
      case 'value': {
        const sharesValue = item.get('sharesValue');
        const hasValue = !!sharesValue;
        const exchangeRate = item.get('stock').get('exchangeRate');
        const value = !hasValue ? 0 : sharesValue / (exchangeRate === 0 ? 1 : exchangeRate);
        const label = currencySymbolFromTicker(item.get('ticker')) + betterToFixed(value).toLocaleString();
        return <HighlightChange value={value}>{label}</HighlightChange>;
      }

      case 'purchasePrice': {
        const value = item.get('purchasePrice');
        if (!value) {
          return <NoData />;
        }
        return (
          <HighlightChange value={value}>
            <span>
              {currencySymbolFromTicker(item.get('ticker'))}
              {numeral(value)
                .format('0.00a')
                .toUpperCase()}
            </span>
          </HighlightChange>
        );
      }

      case 'addedPrice': {
        const value = item.get('addedPrice');
        if (!value) {
          return <NoData />;
        }
        return (
          <HighlightChange value={value}>
            <span>
              {currencySymbolFromTicker(item.get('ticker'))}
              {numeral(value)
                .format('0.00a')
                .toUpperCase()}
            </span>
          </HighlightChange>
        );
      }

      case 'gainSinceAdded': {
        const value =
          !isFinite(item.get('gainSinceAdded')) || !getPrice('amount')
            ? 0
            : item.get('gainSinceAdded');

        const changeStyle = {
          positive: value > 0,
          negative: value < 0
        };

        return (
          <span className={classNames(changeStyle)}>
            <HighlightChange value={value}>
              <span className="change-amount">
                {visualized && value > 0 && <span>&#9650;</span>}
                {visualized && value < 0 && <span>&#9660;</span>}
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  style="percent"
                  value={visualized ? Math.abs(value) : value}
                />
              </span>
            </HighlightChange>
          </span>
        );
      }

      case 'sincePurchaseGain': {
        const value =
          !isFinite(item.get('sincePurchaseGain')) || !getPrice('amount')
            ? 0
            : item.get('sincePurchaseGain');

        const valueAmount =
          !isFinite(item.get('sincePurchaseGainAmount')) || !getPrice('amount')
            ? 0
            : item.get('sincePurchaseGainAmount');

        const changeStyle = {
          positive: value > 0,
          negative: value < 0
        };

        return (
          <span className={classNames(changeStyle)}>
            <HighlightChange value={value}>
              <div className="change-percent">
                {visualized && value > 0 && <span>&#9650;</span>}
                {visualized && value < 0 && <span>&#9660;</span>}
                <span className="amount">
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    style="percent"
                    value={visualized ? Math.abs(value) : value}
                  />
                </span>
              </div>
              {valueAmount !== 0 && (
                <div className="change-amount">
                  ({visualized && value > 0 && <span>+</span>}
                  {visualized && value < 0 && <span>-</span>}
                  <span>
                    {currencySymbolFromTicker(item.get('ticker'))}
                    {numeral(visualized ? Math.abs(valueAmount) : valueAmount)
                      .format('0.00a')
                      .toUpperCase()}
                  </span>)
                </div>
              )}
            </HighlightChange>
          </span>
        );
      }

      case 'dividend': {
        const value = item.getIn(['stock', 'dividend']);
        if (!value) {
          return <NoData />;
        }
        return (
          <HighlightChange value={value}>
            {currencySymbolFromTicker(item.get('ticker'))}{value}
          </HighlightChange>
        );
      }

      case 'percent': {
        const value = item.get('percentPortfolio');

        if (isNaN(item.get('sharesTotal')) || isNaN(value)) {
          return <NoData />;
        }

        return (
          <HighlightChange value={value}>
            {value > 0 && value < 0.0001 ? (
              <span>&lt; 0.01%</span>
            ) : value > 0.9999 && value < 1 ? (
              <span>&gt; 99.99%</span>
            ) : (
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    style="percent"
                    value={value}
                  />
                )}
          </HighlightChange>
        );
      }

      case 'cap': {
        const value = getPrice('cap');
        if (!value || isNaN(value)) {
          return <NoData />;
        }
        return (
          <span>
            {currencySymbolFromTicker(item.get('ticker'))}
            {numeral(value)
              .format('0.00a')
              .toUpperCase()}
          </span>
        );
      }

      case 'category': {
        return (
          <span className={className}>{item.getIn(['stock', 'category'])}</span>
        );
      }

      case 'beta': {
        if (!getPrice('beta')) {
          return <NoData />;
        }
        return (
          <span>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={getPrice('beta')}
            />
          </span>
        );
      }

      case 'yield': {
        const value = item.getIn(['stock', 'yield']);
        if (!value) {
          return <NoData />;
        }
        return (
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            style="percent"
            value={value}
          />
        );
      }

      case 'managementFee': {
        const value = item.getIn(['stock', 'managementFee']) as number | null;
        if (!value) {
          return <NoData />;
        }
        const adaptedValue = value / 100;
        return (
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            style="percent"
            value={adaptedValue}
          />
        );
      }

      case 'dividendDate': {
        const value = item.getIn(['stock', 'dividendDate']);
        if (!value) {
          return <NoData />;
        }
        return (
          <span
            className="date"
          ><FormattedDate
              day="numeric"
              month="short"
              value={item.getIn(['stock', 'dividendDate'])}
              year="numeric"
            /></span>
        );
      }

      case 'earningsReport': {
        const value = item.getIn(['stock', 'earningsReport']);
        if (!value) {
          return <NoData />;
        }
        return (
          <span
            className="date"
          ><FormattedDate
              day="numeric"
              month="short"
              value={value}
              year="numeric"
            /></span>
        );
      }

      case 'eps': {
        const value = item.getIn(['stock', 'eps']);
        if (!value) {
          return <NoData />;
        }
        return (
          <span
            className="date"
          ><FormattedDate
              day="numeric"
              month="short"
              value={value}
              year="numeric"
            /></span>
        );
      }

      case 'prices': {
        return <TrStockBehaviour ticker={item.get('ticker')} />;
      }
    }

    const value = item.getIn(['stock', type]);

    if (!value) {
      return <NoData />;
    }

    return <span>{value}</span>;
  }
}

function normalizeNumber(data, toFixed = 2) {
  if (!data) {
    return '--';
  }
  const n = parseFloat(data);
  if (n / 1e9 >= 1) {
    return `${(n / 1e9).toFixed(toFixed)}B`;
  } else if (n / 1e6 >= 1) {
    return `${(n / 1e6).toFixed(toFixed)}M`;
  } else if (n / 1e3 >= 1) {
    return `${(n / 1e3).toFixed(toFixed)}K`;
  }
  return Number.isNaN(n) ? 'N/A' : n.toFixed(toFixed);
}

function getChangeStyle(percent) {
  const changeStyle = {
    positive: percent > 0,
    negative: percent < 0
  };
  return classNames('change', changeStyle);
}

function getChangeComponent(value) {
  return value !== null ? (
    <span className={getChangeStyle(value)}>{value.toFixed(2)}%</span>
  ) : (
      <span>--</span>
    );
}

function getLandmarkPriceComponentFor(item, landmarkPriceType) {
  const value = calcLandmarkPercentange(item, landmarkPriceType);
  return getChangeComponent(value);
}

/**
 * analyst price target
 */
function target(item: PortfolioItemRecord, targetType: (keyof DashboardStock_NotImmutable), price, msg, isRestricted = false, showPromotion = undefined) {
  const target: number = item.get('stock').get(targetType);
  const amount = price('amount');
  const value = item.getIn(['stock', `${targetType}Percent`]);

  if (!target || target === 0) {
    return <NoData />;
  }

  if (isRestricted) {
    return (
      <RestrictedButton
        analyticName="column"
        enums={msg}
        plan="premium"
        showPromotion={showPromotion}
        type={`${targetType}`}
      />
    );
  }

  const type = value < 0 ? 'sell' : 'buy';
  const currency = '$'; // TODO check if we have canadian price targets
  return (
    <div className={type}>
      <div className="amount">
        {currencySymbolFromTicker(item.get('ticker')) + betterToFixed(target)}
      </div>
      <div>
        <span className="change-amount">
          <span>
            <HighlightChange value={value}>
              (<FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                style="percent"
                value={value}
              />
              &nbsp;
              {msg.priceTarget[type]})
            </HighlightChange>
          </span>
        </span>
      </div>
    </div>
  );
};
