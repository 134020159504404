import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Component from 'react-pure-render/component';

import { imgSrc } from '../../common/lib/utils.ts';
import defaultMsg from './tr-register-form.en';

const smartDashboardImg = () =>
  process.env.IS_BROWSER ? require('./img/smart-dashboard.png') : '';

export default class TrRegisterForm extends Component {
  static propTypes = {
    afterRegister: PropTypes.func,
    form: PropTypes.object,
    msg: PropTypes.object,
    onAuthFormFieldChange: PropTypes.func,
    openLogin: PropTypes.func,
    register: PropTypes.func,
    restartForm: PropTypes.func
  };

  componentDidMount() {
    const { restartForm } = this.props;
    restartForm();
  }

  submit = async event => {
    event.preventDefault();
    const { afterRegister, form, register } = this.props;
    const { error } = await register(form.fields);
    if (!error) {
      afterRegister();
    }
  };

  render() {
    const {
      form,
      msg = defaultMsg,
      onAuthFormFieldChange,
      openLogin
    } = this.props;

    return (
      <section className="tr-register-form">
        <div className="smart-dashboard-dialog-content">
          <header>
            <h1>
              <FormattedHTMLMessage defaultMessage={msg.title} id={msg.title} />
            </h1>
          </header>
          <div className="form-wrapper">
            <form onSubmit={this.submit}>
              <label className="user-name">
                <input
                  autoFocus={true}
                  disabled={form.state === 'LOADING'}
                  name="email"
                  onChange={onAuthFormFieldChange}
                  placeholder={msg.input.email}
                  required={true}
                  type="email"
                  value={form.fields.email}
                />
              </label>
              <label className="password">
                <input
                  disabled={form.state === 'LOADING'}
                  name="password"
                  onChange={onAuthFormFieldChange}
                  placeholder={msg.input.password}
                  required={true}
                  type="password"
                  value={form.fields.password}
                />
              </label>
              <button disabled={form.state === 'LOADING'}>
                {form.state === 'LOADING' ? (
                  <img src={imgSrc('loaderStatic')} />
                ) : (
                  msg.submit
                )}
              </button>
            </form>
            <div className="terms">
              <FormattedMessage
                defaultMessage={msg.terms}
                id={msg.terms}
                values={{
                  termLink: (
                    <a href="/terms" target="_blank">
                      {msg.termLinkLabel}
                    </a>
                  )
                }}
              />
            </div>
            <div
              className={classNames('error-message', { active: form.error })}
            >
              {form.error ? form.error.message || msg.errorMessage : '-'}
            </div>
          </div>
        </div>
        <footer>
          <span className="already-user">
            <FormattedMessage
              defaultMessage={msg.alreadyUser}
              id={msg.alreadyUser}
              values={{
                loginBtn: (
                  <button onClick={openLogin}>{msg.loginBtnLabel}</button>
                )
              }}
            />
          </span>
        </footer>
      </section>
    );
  }
}
