import * as classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'tipranks-icons';
import * as innerStyles from './PremiumHoverUpperCorner.istyl';

type Props = {
  onClickNeverShowAgain: () => void;
  url: string;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  className?: string;
  styles?: { [key: string]: string };
};

export class PremiumHoverUpperCorner extends React.PureComponent<Props> {
  render() {
    const {
      onClickNeverShowAgain,
      url,
      styles: outerStyles = {},
      className,
      onMouseLeave,
      onMouseEnter
    } = this.props;

    return (
      <div
        className={classNames(
          className,
          innerStyles.premiumHoverUpperCorner,
          outerStyles.premiumHoverUpperCorner
        )}
        style={{
          // ...style,
          backgroundImage: `url(${require('./img/premium-hover-background.jpg')})`
        }}
        onMouseOver={onMouseLeave}
        onMouseOut={onMouseEnter}
      >
        <div className={classNames(innerStyles.mainBody, outerStyles.mainBody)}>
          <div
            className={classNames(
              innerStyles.smartPortfolioPro,
              outerStyles.smartPortfolioPro
            )}
          >
            {' '}
            Smart Portfolio PRO
          </div>
          <div
            className={classNames(
              innerStyles.neverShowAgain,
              outerStyles.neverShowAgain
            )}
          >
            <button
              className={classNames(
                innerStyles.webkitAppearanceNone,
                outerStyles.webkitAppearanceNone
              )}
              onClick={onClickNeverShowAgain}
            >
              <span>Never show again</span>
              <div
                className={classNames(
                  innerStyles.crossIcon,
                  outerStyles.crossIcon
                )}
              >
                {' '}
                X{' '}
              </div>
            </button>
          </div>
          <ul>
            <li>
              <Icon icon="tick" className={innerStyles.tick} /> Get expert
              sentiment for your stocks
            </li>
            <li>
              <Icon icon="tick" className={innerStyles.tick} /> Get the news
              sorted to bullish and bearish sentiments
            </li>
            <li>
              <Icon icon="tick" className={innerStyles.tick} /> Manage multiple
              portfolios
            </li>
            <li>
              <Icon icon="tick" className={innerStyles.tick} /> Compare yourself
              to the best investors on NASDAQ
            </li>
          </ul>
        </div>
        <a
          className={classNames(
            innerStyles.roundedRectangle1065,
            innerStyles.webkitAppearanceNone,
            outerStyles.roundedRectangle1065,
            innerStyles.clickToLearnMore
          )}
          href={url}
        >
          Click to Learn More >
        </a>
      </div>
    );
  }
}
