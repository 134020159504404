import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import './tr-dashboard-holdings-metrics.styl';

const plans = ['free', 'premium'];
const byPlan = plan => col => col.get('plan') === plan;

const changeHandler = (col, onChange, selections) => ({ target }) =>
  onChange(
    target.checked
      ? selections.push(col)
      : selections.delete(selections.indexOf(col))
  );

export default class TrDashboardHoldingsMetrics extends Component {
  static propTypes = {
    applyChange: PropTypes.func,
    close: PropTypes.func,
    cols: PropTypes.object,
    colsNames: PropTypes.object,
    enums: PropTypes.object,
    msg: PropTypes.object,
    onChange: PropTypes.func,
    selections: PropTypes.object,
    theme: PropTypes.string
  };

  render() {
    const {
      cols,
      colsNames,
      enums,
      msg,
      onChange,
      selections,
      theme
    } = this.props;

    return (
      <section className="holdings-metrics">
        <header>
          <h1>{msg.title}</h1>
        </header>
        <p className="description">{msg.description}</p>
        <ul className="plans">
          {plans.map(plan => (
            <li key={plan}>
              <div className="plan-badge-wrapper">
                <span className={classNames('plan-badge', plan)}>
                  {
                    enums.plans[
                      plan === 'premium' && process.env.THEME === 'nasdaq'
                        ? 'professional'
                        : plan
                    ]
                  }
                </span>
              </div>
              <ul className="plan-cols">
                {cols.filter(byPlan(plan)).map(col => (
                  <li key={col}>
                    <label>
                      <input
                        checked={selections.includes(col.get('id'))}
                        onChange={changeHandler(
                          col.get('id'),
                          onChange,
                          selections
                        )}
                        type="checkbox"
                      />
                      <span className="label">{colsNames[col.get('id')]}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="controls">
          <button onClick={this.applyChange}>{msg.ok}</button>
        </div>
      </section>
    );
  }

  applyChange = () => {
    const { applyChange, close, cols } = this.props;
    applyChange(cols);
    close();
  };
}
