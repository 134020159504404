import * as tradestation from './assets/img/tradestation.png';
import * as tradestationHover from './assets/img/tradestation-hover.png';

import * as tradier from './assets/img/tradier.png';
import * as tradierHover from './assets/img/tradier-hover.png';

import * as ib from './assets/img/ib.png';
import * as ibHover from './assets/img/ib-hover.png';

import * as robinhood from './assets/img/robinhood.png';
import * as robinhoodHover from './assets/img/robinhood-hover.png';

import * as td from './assets/img/td.png';
import * as tdHover from './assets/img/td-hover.png';

import * as optionshouse from './assets/img/optionshouse.png';
import * as optionshouseHover from './assets/img/optionshouse-hover.png';

import * as scottrade from './assets/img/scottrade.png';
import * as scottradeHover from './assets/img/scottrade-hover.png';

import * as fidelity from './assets/img/fidelity.png';
import * as fidelityHover from './assets/img/fidelity-hover.png';

import * as etrade from './assets/img/etrade.png';
import * as etradeHover from './assets/img/etrade-hover.png';

import * as schwab from './assets/img/schwab.png';
import * as schwabHover from './assets/img/schwab-hover.png';

import * as allyinvest from './assets/img/allyinvest.png';
import * as allyinvestHover from './assets/img/allyinvest-hover.png';

import * as drivewealth from './assets/img/drivewealth.png';
import * as drivewealthHover from './assets/img/drivewealth-hover.png';

import * as capitalOne from './assets/img/capitalOne.png';
import * as CapitalOneHover from './assets/img/capitalOne-hover.png';

import * as dummy from './assets/img/dummy.jpg';
import * as dummyHover from './assets/img/dummy-hover.jpg';

export const brokers = {
  ib: { regular: ib, hover: ibHover },
  schwab: { regular: schwab, hover: schwabHover },
  tradestation: { regular: tradestation, hover: tradestationHover },
  tradier: { regular: tradier, hover: tradierHover },
  robinhood: { regular: robinhood, hover: robinhoodHover },
  td: { regular: td, hover: tdHover },
  optionshouse: { regular: optionshouse, hover: optionshouseHover },
  scottrade: { regular: scottrade, hover: scottradeHover },
  fidelity: { regular: fidelity, hover: fidelityHover },
  etrade: { regular: etrade, hover: etradeHover },
  ally: { regular: allyinvest, hover: allyinvestHover },
  drivewealth: { regular: drivewealth, hover: drivewealthHover },
  'capital one': { regular: capitalOne, hover: CapitalOneHover },
  dummy: { regular: dummy, hover: dummyHover }
};
