import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import defaultMsg from './tr-dashboard-warning-dialog.en';

const smartDashboardImg = () =>
  process.env.IS_BROWSER ? require('./img/smart-dashboard.png') : '';

export default class TrDashboardWarningDialog extends Component {
  static propTypes = {
    controls: PropTypes.object,
    dismissWarningDialog: PropTypes.func,
    isDismissable: PropTypes.bool,
    msg: PropTypes.object,
    onChangedDismiss: PropTypes.func,
    warning: PropTypes.string
  };

  static defaultProps = {
    onChangedDismiss: () => null
  };

  constructor(props) {
    super(props);
    this.state = {
      hide: false
    };
  }

  clickHandler = action => () => {
    action({
      dismiss: this.state.hide
    });
  };

  componentWillUnmount() {
    const { dismissWarningDialog } = this.props;
    if (this.state.hide) {
      dismissWarningDialog();
    }
  }

  changedDismiss = ({ target }) => {
    const { onChangedDismiss } = this.props;
    this.setState({ hide: target.checked });
    onChangedDismiss();
  };

  render() {
    const {
      controls,
      isDismissable = false,
      msg = defaultMsg,
      warning
    } = this.props;

    const { hide } = this.state;

    return (
      <section className="tr-dashboard-warning-dialog">
        <main>
          <p>
            <span>{warning}</span>
          </p>
        </main>
        <menu>
          <ul>
            {controls.map((control, index) => (
              <li key={index}>
                <button onClick={this.clickHandler(control.get('action'))}>
                  {control.get('label')}
                </button>
              </li>
            ))}
          </ul>
          {isDismissable && (
            <div className="dismiss-section">
              <label>
                <input
                  onChange={this.changedDismiss}
                  ref="dismiss"
                  type="checkbox"
                  value={hide}
                />
                <span className="label">{msg.dismiss}</span>
              </label>
            </div>
          )}
        </menu>
      </section>
    );
  }
}
