import { postReq, getReq } from '../../common/api';
type PasswordChange = {
  oldPassword: string;
  newPassword: string;
};

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const changePassword = ({
  oldPassword,
  newPassword
}: PasswordChange) => ({
  type: CHANGE_PASSWORD,
  payload: {
    promise: postReq('/api/Users/ChangePassword', { oldPassword, newPassword })
  }
});

export const CHANGE_PHONE = 'CHANGE_PHONE';
export const CHANGE_PHONE_START = 'CHANGE_PHONE_START';
export const CHANGE_PHONE_SUCCESS = 'CHANGE_PHONE_SUCCESS';
export const CHANGE_PHONE_ERROR = 'CHANGE_PHONE_ERROR';

export const changePhone = (telephone: string) => ({
  type: CHANGE_PHONE,
  payload: {
    promise: postReq('/api/Users/SetUserTelephone', { telephone }).then(() => ({
      telephone
    }))
  }
});

export const CHANGE_EMAIL_FREQUENCY = 'CHANGE_EMAIL_FREQUENCY';
export const CHANGE_EMAIL_FREQUENCY_START = 'CHANGE_EMAIL_FREQUENCY_START';
export const CHANGE_EMAIL_FREQUENCY_SUCCESS = 'CHANGE_EMAIL_FREQUENCY_SUCCESS';
export const CHANGE_EMAIL_FREQUENCY_ERROR = 'CHANGE_EMAIL_FREQUENCY_ERROR';

export const changeEmailFrequency = (shouldBombard: boolean) => ({
  type: CHANGE_EMAIL_FREQUENCY,
  payload: {
    promise: postReq('/api/Users/SetNotificationsBypassSetting', {
      shouldBombard
    }).then(() => ({ shouldBombard }))
  }
});

export const USER_FOLLOWED_STOCKS = 'USER_FOLLOWED_STOCKS';
export const USER_FOLLOWED_STOCKS_START = 'USER_FOLLOWED_STOCKS_START';
export const USER_FOLLOWED_STOCKS_SUCCESS = 'USER_FOLLOWED_STOCKS_SUCCESS';
export const USER_FOLLOWED_STOCKS_ERROR = 'USER_FOLLOWED_STOCKS_ERROR';

export interface FollowedStocksResponse {
  stocks: string[];
}

export const userFollowedStocks = () => ({
  type: USER_FOLLOWED_STOCKS,
  payload: {
    promise: getReq<FollowedStocksResponse>('/api/users/followingStocks')
  }
});
