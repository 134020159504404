import './style.styl';

import * as classNames from 'classnames';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { FormattedRelative } from 'react-intl';
import { Link } from 'react-router';
import { DEFAULT_COLOR, ExpertRating } from 'sp/browser/expert-rating/ExpertRating';
import { Tooltip } from 'sp/browser/news-analysis/Tooltip/Tooltip';
import { SuccessRate } from 'sp/browser/success-rate';
import { profileSrc } from 'sp/browser/tr-profile-pic/index.react';
import { portfolioBenchmarkID, portfolioPeriodID } from 'sp/common/enums';
import { absImgSrc, getSectorText } from 'sp/common/lib/utils';

interface IDateable {
    effectiveDate?: any,
}
interface IBenchmarkable {
    portfolioPeriodID?: number,
    portfolioBenchmarkID?: number,
}
type Percent = number; // symbolizes a number between 0.00-1.00.
interface SPRank {
    score: number,
    averageReturn: Percent,
    goodTransactions: number,
    totalTransactions: number,
    successRate: number,
    portfolioPeriodID: number,
    portfolioBenchmarkID: number,
    localRank: number,
    stars: number,
}

interface GetCompetitionDataServerResponseDatumRaw {
    name: string,
    slug: string,
    userEmail: string,
    userName: string,
    expertPortfolioID: number,
    expertID: number,
    pmRanks: {
        successRate: number,
        score: number,
        sharpe: number,
        portfolioReturn: number,
        stars: number,
    }[],
    spRanks: SPRank[],
    transactions: TransactionRaw[],
    alerts: { ticker: string, operations: AlertData[] }[],
}

interface GetMultiplePortfolioGainsRes {
    expertPortfolioID: number,
    weeklyPerformance: number,
    twoWeekPerformance: number,
    monthlyPerformance: number,
    quarterlyPerformance: number,
    yearlyPerformance: number,
}
interface GetCompetitionDataDatum
    extends GetCompetitionDataServerResponseDatumRaw,
    GetMultiplePortfolioGainsRes {
    imageURL: string,
}

type AlertData = {
    expertPortfolioOperationID: number,
    displayName: string,
    executionTime: string,
    sharesTraded: number,
    price: number,
    action: number,
    rating: number,
}

type Alert = {
    ticker: string,
} & AlertData

interface Player {
    averageReturn: number,
    expertPortfolioID: number,
    hasAlerts: boolean;
    alerts: Alert[];
    imageURL: string;
    lastTransaction: Date | null;
    mainSector: number;
    managerRank: number | null;
    name: string;
    oneMonth: number;
    oneWeek: number;
    portfolioGain: number;
    sharpeRatio: number;
    sharpeRatioRank: number;
    stockPickerRank: number | null;
    successRate: number | null;
    threeMonths: number;
    tradesPerWeek: number;
    transactions: Transaction[];
    twoWeeks: number;
    debugData: any;
    slug: string;
}


type TransactionRaw = {
    stockID: number;
    sharesTraded: number;
    effectiveDate: string;
    executionPrice: number;
    normalizedSnpProfit: number;
    normalizedProfit: number;
    indexProfit: number;
    ticker: string;
    displayName: string;
    actionId: ActionId;
    ratingId: number;
    sectorId: number;
    portfolioPeriodID: number;
    stockProfit: number;
}

type Transaction = {
    effectiveDate: string;
    ticker: string;
    displayName: string;
    actionId: ActionId;
    shares: number;
}

interface CommunityPageState {
    data: GetCompetitionDataDatum[];
    // players: GetCompetitionDataServerResponseDatum[],
    loadingState: 'initial' | 'loading' | 'success' | 'error';
    alerts: { [expertPortfolioID: string]: Date };
    sortBy: string,
}

type API_GetExpertImagesRes = { [portfolioId: string]: string };

const alertsStorageKey = 'CompetitionAlerts';
const sortByStorageKey = 'CompetitionSortBy';

export class Community extends React.Component<{}, CommunityPageState> {
    state: CommunityPageState = {
        loadingState: 'initial',
        data: [],
        alerts: {},
        sortBy: (localStorage.getItem(sortByStorageKey) as string) || 'stockPickerRank'
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevState.sortBy !== this.state.sortBy) localStorage.setItem(sortByStorageKey, this.state.sortBy)
    }
    componentDidMount() {
        const alerts = (JSON.parse(localStorage.getItem(alertsStorageKey) as string) as {}) || {};
        this.setState({ alerts, loadingState: 'loading' });
        let portfolioIds;
        let expertIds;
        fetch('/api/portfolio/GetCompetitionData')
            .then(res => res.json() as Promise<GetCompetitionDataServerResponseDatumRaw[]>)
            .then(players => {
                portfolioIds = players
                    .map(player => player.expertPortfolioID)
                    .join(',');
                expertIds = players
                    .map(player => player.expertID)
                    .join(',');
                return Promise.all([
                    Promise.resolve(players),
                    fetch(`/api/portfolio/GetMultiplePortfolioGains?portfolioIds=${portfolioIds}`).then(res => res.json()) as Promise<GetMultiplePortfolioGainsRes[]>,
                    fetch(`/api/portfolio/GetExpertImages?expertIds=${expertIds}`).then(res => res.json()).catch(err => ({})) as Promise<API_GetExpertImagesRes>,
                ]);
            })
            .then(data => {
                const [players, gains, imageURLs] = data;

                // c# server return a json with ExceptionMethod when there's an error.
                if ((gains as any).ExceptionMethod)
                    throw `GetMultiplePortfolioGains failed, one of these portfolioIds is problematic: ${portfolioIds}.`;
                if (imageURLs.ExceptionMethod)
                    throw `GetExpertImages failed, one of these portfolioIds is problematic: ${portfolioIds}.`;
                return data;
            })
            .then(([players, gains, imageURLs]) => {

                return players.map(player => {
                    const thisPlayersGain = gains.find(
                        gain => gain.expertPortfolioID === player.expertPortfolioID,
                    );
                    const thisPlayersImageURL = imageURLs[player.expertID];
                    const rawCompetitonData = {
                        ...player,
                        ...thisPlayersGain,
                        imageURL: thisPlayersImageURL,
                    } as GetCompetitionDataDatum

                    return rawCompetitonData;
                });
            })
            .then(data => {
                window['rawCompetitonData'] = data;

                this.setState({ data, loadingState: 'success' })
            })
            .catch(e => {
                console.error(e);
                this.setState({ loadingState: 'error' });
            });
    }
    render() {
        const { loadingState, alerts, sortBy = 'stockPickerRank' } = this.state;
        if (loadingState === 'loading') return <div className="community-loader load8">Loading...</div>;
        if (loadingState === 'error')
            return <b>Error while communicating with server, please talk to Daniel.</b>
        if (loadingState === 'initial')
            return <i>Attempting to begin loading data :)</i>;

        const { players: playerData, kings } = this.getAdaptServerData()
        let players = _.sortBy(playerData, sortBy).reverse()
        if (['stockPickerRank', 'managerRank', 'sharpeRatioRank', 'mainSector'].includes(sortBy)) players = players.reverse()

        // TODO remove this
        window['players'] = players
        window['data'] = playerData
        window['Community'] = Community

        return (
            <div className="community-container">
                <div className="controls">
                    <div className="control">
                        <input
                            onChange={() =>
                                this.setState({ sortBy: 'sharpeRatioRank' })}
                            type="radio"
                            name="competition-type"
                            className="bestReturns"
                            id="bestReturns"
                            checked={sortBy === 'sharpeRatioRank'}
                        />
                        <label htmlFor="bestReturns">Best Returns</label>
                    </div>
                    <div className="control">
                        <input
                            onChange={() => this.setState({ sortBy: 'managerRank' })}
                            type="radio"
                            name="competition-type"
                            className="highestReturn"
                            id="highestReturn"
                            checked={sortBy === 'managerRank'}
                        />
                        <label htmlFor="highestReturn">Highest Returns</label>
                    </div>
                    <div className="control">
                        <input
                            onChange={() => this.setState({ sortBy: 'stockPickerRank' })}
                            type="radio"
                            name="competition-type"
                            className="stockpicking"
                            id="stockpicking"
                            checked={sortBy === 'stockPickerRank'}
                        />
                        <label htmlFor="stockpicking">Stock Picking</label>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th onClick={this.setSort} data-sort="name" className="name">Name</th>
                            <th onClick={this.setSort} data-sort="stockPickerRank" className="stockPicker stockPickingKing">Stock Picker</th>
                            <th onClick={this.setSort} data-sort="successRate" className="successRate">Success Rate</th>
                            <th onClick={this.setSort} data-sort="averageReturn" className="averageReturn">Avg. Return</th>
                            <th onClick={this.setSort} data-sort="managerRank" className="managerRank">MNGR. Rank</th>
                            <th onClick={this.setSort} data-sort="portfolioGain" className="portfolioGain highestReturnsKing">Portfolio Gain</th>
                            <th onClick={this.setSort} data-sort="sharpeRatio" className="sharpeRatio bestReturnsKing">Sharpe Ratio</th>
                            <th onClick={this.setSort} data-sort="mainSector" className="mainSector">Main Sector</th>
                            <th onClick={this.setSort} data-sort="tradesPerWeek" className="tradesPerWeek">Trades per Week</th>
                            <th onClick={this.setSort} data-sort="oneWeek" className="oneWeek">1W</th>
                            <th onClick={this.setSort} data-sort="twoWeeks" className="twoWeeks">2W</th>
                            <th onClick={this.setSort} data-sort="oneMonth" className="oneMonth">1M</th>
                            <th onClick={this.setSort} data-sort="threeMonths" className="threeMonths">3M</th>
                            <th onClick={this.setSort} data-sort="hasAlerts" className="alerts">Alerts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.map((player: Player) => {
                            const firstUnclosedTrade = this.findFirstUnclosedTrade(player.transactions)
                            const has = {
                                oneWeek: true,// moment(firstUnclosedTrade.effectiveDate).diff(moment(), 'weeks') > 1,
                                twoWeeks: true,// moment(firstUnclosedTrade.effectiveDate).diff(moment(), 'weeks') > 2,
                                oneMonth: true,// moment(firstUnclosedTrade.effectiveDate).diff(moment(), 'weeks') > 4,
                                threeMonths: true,// moment(firstUnclosedTrade.effectiveDate).diff(moment(), 'weeks') > 12,
                            };
                            const imageURL = profileSrc(player.imageURL);

                            return <tr
                                key={player.expertPortfolioID}
                                data-expertPortfolioID={player.expertPortfolioID}
                                data-good-transactions={player.debugData.goodTransactions}
                                data-total-transactions={player.debugData.totalTransactions}
                                data-number-first-trade-date={player.debugData.firstTradeDate}
                                data-number-of-weeks-trading={player.debugData.numWeeksTrading}
                            >
                                <td className={classNames('name', '')}>
                                    <div className="wrapper">
                                        <div
                                            style={{ backgroundImage: `url(${imageURL})` }}
                                            className={classNames('initials-avatar', {
                                                stockPickingKing: player.expertPortfolioID === kings.stockPickerKing,
                                                highestReturnsKing: player.expertPortfolioID === kings.highestReturnsKing,
                                                bestReturnsKing: player.expertPortfolioID === kings.bestReturnsKing,
                                            })}
                                        >
                                            {!imageURL && (player.name || '')
                                                .split(' ')
                                                .map(str => str[0])
                                                .join('')
                                                .toUpperCase()}
                                        </div>
                                        <div className="value">
                                            <Link to={`/investors/${player.slug}`}>{player.name}</Link>
                                        </div>
                                    </div>
                                </td>
                                <td className={classNames('stockPicker', '')}>
                                    {player.stockPickerRank !== null ? <ExpertRating
                                        color={DEFAULT_COLOR}
                                        animate={false}
                                        rating={1 - player.stockPickerRank / players.length}
                                        size={12}
                                        title={'Rating: ' + ((1 - player.stockPickerRank / players.length) * 5).toFixed(2)}
                                    /> : <ZeTooltip />}
                                </td>
                                <td
                                    className={classNames(
                                        'successRate',
                                        getMarketColorClassName(player.successRate),
                                    )}
                                >
                                    {player.successRate !== null ? <SuccessRate
                                        animated={false}
                                        noBg
                                        radius={25}
                                        width={6}
                                        fontSize={13}
                                        rate={player.successRate}
                                        style={{
                                            positive: ['#23a306', '#23a306'],
                                            negative: ['#cf1800', '#cf1800'],
                                            text:
                                                player.successRate < 0.5 ? '#cf1800' : '#23a306',
                                            empty: '#898989',
                                        }}
                                    /> : '--'}
                                </td>
                                <td
                                    className={classNames(
                                        'averageReturn',
                                        getMarketColorClassName(player.averageReturn),
                                    )}
                                >
                                    {getSign(player.averageReturn)}
                                </td>
                                <td className={classNames('managerRank', '')}>
                                    {player.managerRank !== null ? <ExpertRating
                                        color={DEFAULT_COLOR}
                                        animate={false}
                                        rating={1 - player.managerRank / players.length}
                                        size={12}
                                        title={'Rating: ' + ((1 - player.managerRank / players.length) * 5).toFixed(2)}
                                    /> : <ZeTooltip />}
                                </td>
                                <td
                                    className={classNames(
                                        'portfolioGain',
                                        getMarketColorClassName(player.portfolioGain),
                                    )}
                                >
                                    {getSign(player.portfolioGain)}

                                </td>
                                <td className={classNames('sharpeRatio', { hasValue: !!player.sharpeRatio })}>
                                    {player.sharpeRatio !== null ? player.sharpeRatio : '--'}
                                </td>
                                <td className={classNames('mainSector', '')}>
                                    {getSectorText(player.mainSector) || '--'}
                                </td>
                                <td className={classNames('tradesPerWeek', '')}>
                                    {player.tradesPerWeek ? `${player.tradesPerWeek} Trade(s)` : <div>Less than a <span title="Trade Per Week">TPW</span></div>}
                                </td>
                                <td
                                    className={classNames('oneWeek', { [getMarketColorClassName(player.oneWeek)]: has.oneWeek })}>
                                    {!has.oneWeek ? '0%' : getSign(player.oneWeek)}
                                </td>
                                <td
                                    className={classNames('twoWeeks', { [getMarketColorClassName(player.twoWeeks)]: has.twoWeeks })}>
                                    {!has.twoWeeks ? '0%' : getSign(player.twoWeeks)}
                                </td>
                                <td
                                    className={classNames('oneMonth', { [getMarketColorClassName(player.oneMonth)]: has.oneMonth })}>
                                    {!has.oneMonth ? '0%' : getSign(player.oneMonth)}
                                </td>
                                <td
                                    className={classNames('threeMonths', { [getMarketColorClassName(player.threeMonths)]: has.threeMonths })}>
                                    {!has.threeMonths ? '0%' : getSign(player.threeMonths)}
                                </td>
                                <td className={classNames('alerts', '')}>
                                    <HackyAlerts
                                        hasAlerts={player.hasAlerts}
                                        data={player.alerts}
                                        expertName={player.name}
                                        title="Last Transactions"
                                        onClick={() => this.clearAlert(player.expertPortfolioID)}
                                    />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
    setSort = (event?: any) =>
        this.setState({
            sortBy: !!event
                ? event.target.dataset.sort
                : 'stockPicking'
        })

    getAdaptServerData = () =>
        //hack to make HMR work when I change the adapter
        this.adaptServerData(this.state.data);

    adaptServerData = (
        _data: GetCompetitionDataDatum[],
    ): {
            players: Player[], kings: {
                bestReturnsKing: number,
                highestReturnsKing: number,
                stockPickerKing: number,
            }
        } => {
        const data = _data
        // we're no longer excluding if one doesn't have enough data.
        // .filter(
        //     datum =>
        //       datum.pmRanks.length > 0 ||
        //       console.warn(
        //         `Excluding ${datum.name} because it doesn't have pmRanks`,
        //       ),
        //   )
        //   .filter(
        //     datum =>
        //       datum.spRanks.length > 0 ||
        //       console.warn(
        //         `Excluding ${datum.name} because it doesn't have spRanks`,
        //       ),
        //   )
        //   .filter(
        //     datum =>
        //       datum.transactions.length > 0 ||
        //       console.warn(
        //         `Excluding ${datum.name} because it doesn't have transactions`,
        //       ),
        //   );

        const kings = {
            bestReturnsKing: -1,
            highestReturnsKing: -1,
            stockPickerKing: -1,
        };
        if (!data.length) return { players: [], kings };

        const stockPickerRanks = data
            .map(datum => ({
                expertPortfolioID: datum.expertPortfolioID,
                localRank: _.get(this.selectOurBenchmarkPeriod(datum.spRanks), '[0].localRank', null),
            }))
            .sort((a, b) => ((a.localRank || 9999) > (b.localRank || 9999) ? 1 : -1));
        const highestReturnsRanks = data
            .map(datum => ({
                expertPortfolioID: datum.expertPortfolioID,
                stars: _.get(datum, 'pmRanks[0].portfolioReturn', null)
            }))
            .sort((a, b) => ((a.stars || -1) < (b.stars || -1) ? 1 : -1));
        const bestReturnsRanks = data
            .map(datum => ({
                expertPortfolioID: datum.expertPortfolioID,
                sharpe: _.get(datum, 'pmRanks[0].sharpe', null),
            }))
            .sort((a, b) => ((a.sharpe || -1) < (b.sharpe || -1) ? 1 : -1));

        const players = data.map(datum => {
            const spRanks = this.selectOurBenchmarkPeriod(datum.spRanks)[0];
            const transactions = this.selectOurBenchmarkPeriod(datum.transactions)!
                .map(t => ({
                    effectiveDate: t.effectiveDate,
                    ticker: t.ticker,
                    displayName: t.displayName,
                    actionId: t.actionId,
                    shares: t.sharesTraded,
                    sectorId: t.sectorId,
                    successfulTrade: t.normalizedProfit > 0
                }))
                .sort(this.sortByEffectiveDate)
            // calculate main sector
            const transactionSectors: {[key: number]: number} = {};
            transactions.forEach(t => {
                if (transactionSectors[t.sectorId]) transactionSectors[t.sectorId] += 1;
                else transactionSectors[t.sectorId] = 1;
            });
            const highestValues = _.values(transactionSectors).sort((a, b) => a < b ? 1 : -1);
            const highestValue = highestValues[0];
            const mainSector = parseInt(
                _.findKey(
                    transactionSectors,
                    value => highestValue === value,
                ) as string,
                10,
            );
            const { goodTransactions = null, totalTransactions = null } = spRanks || {};
            const successRate = !(goodTransactions && totalTransactions) ? null : goodTransactions / totalTransactions;
            let tradesPerWeek: any = null;
            let numWeeksTrading: any = null;
            let firstTradeDate: any = null;
            const trades = this.getTrades(transactions)
            if (trades.length && totalTransactions) {
                const tradesByWeek = trades
                    .reduce(
                    (prev, curr) =>
                        _.update(
                            prev,
                            moment(curr.effectiveDate).format('YYYY w'),
                            (counter = 0) => counter + 1,
                        ),
                    {},
                );
                // unused, unless someone would want the tradesPerWeek to account
                // only the ACTIVE TRADED weeks.
                // const numberOfWeeksTraded = Object.keys(tradesByWeek).length
                firstTradeDate = trades[0].effectiveDate
                const realNumWeeksTrading = Math.abs(moment(firstTradeDate).diff(moment(), 'weeks'))
                numWeeksTrading = realNumWeeksTrading > 1 ? realNumWeeksTrading : 1;
                tradesPerWeek = numWeeksTrading
                    ? Math.floor(
                        totalTransactions / numWeeksTrading
                    )
                    : 0;
            }
            const lastTransaction = datum.transactions.length
                ? new Date(datum.transactions[0].effectiveDate)
                : null;
            const getAlert = () => this.getHasAlerts(datum.expertPortfolioID, lastTransaction);
            const alerts = _.flatten(datum.alerts
                .map(rawAlert => rawAlert.operations.map(op => ({ ticker: rawAlert.ticker, ...op })))
            ).sort((a, b) => moment(a.executionTime).isBefore(b.executionTime) ? 1 : -1);
            const sharpeRatio = _.chain(datum)
                .thru(val => _.get(val, 'pmRanks[0].sharpe', null) as any)
                .thru(val => val ? parseFloat(val).toFixed(2) : null as any)
                .value();

            const portfolioGain = _.chain(datum)
                .thru(val => _.get(val, 'pmRanks[0].portfolioReturn', null) as any)
                .thru(val => val ? parseFloat((val * 100) as any).toFixed(2) : null as any)
                .value();
            const averageReturn = spRanks
                ? parseFloat((spRanks.averageReturn * 100).toFixed(2))
                : 0;

            return {
                name: datum.name.replace('TR_Competition_', '').replace('_', ' '),
                slug: datum.slug,
                expertPortfolioID: datum.expertPortfolioID,
                sharpeRatio,
                portfolioGain,
                successRate,
                averageReturn,
                mainSector,
                tradesPerWeek,
                oneWeek: parseFloat((datum.weeklyPerformance * 100).toFixed(2)),
                twoWeeks: parseFloat((datum.twoWeekPerformance * 100).toFixed(2)),
                oneMonth: parseFloat((datum.monthlyPerformance * 100).toFixed(2)),
                threeMonths: parseFloat((datum.quarterlyPerformance * 100).toFixed(2)),
                stockPickerRank: datum.spRanks.length ? stockPickerRanks.findIndex(
                    datum2 => datum.expertPortfolioID === datum2.expertPortfolioID,
                ) : null,
                managerRank: datum.pmRanks.length ? highestReturnsRanks.findIndex(
                    datum2 => datum.expertPortfolioID === datum2.expertPortfolioID,
                ) : null,
                sharpeRatioRank: bestReturnsRanks.findIndex(
                    datum2 => datum.expertPortfolioID === datum2.expertPortfolioID,
                ),
                lastTransaction,
                transactions,
                imageURL: datum.imageURL,
                debugData: { goodTransactions, totalTransactions, numWeeksTrading, firstTradeDate },
                alerts,
                get hasAlerts() { return getAlert() },
            };
        });
        const isNotKing = (expertPortfolioID: number) =>
            Object.keys(_.pickBy(kings, (val, key) => val === expertPortfolioID)).length === 0
        kings.bestReturnsKing = bestReturnsRanks[0].expertPortfolioID;
        kings.highestReturnsKing = isNotKing(highestReturnsRanks[0].expertPortfolioID)
            ? highestReturnsRanks[0].expertPortfolioID
            : highestReturnsRanks[1].expertPortfolioID
        kings.stockPickerKing = isNotKing(stockPickerRanks[0].expertPortfolioID)
            ? stockPickerRanks[0].expertPortfolioID
            : isNotKing(stockPickerRanks[1].expertPortfolioID)
                ? stockPickerRanks[1].expertPortfolioID
                : stockPickerRanks[2].expertPortfolioID;

        return {
            players,
            kings
        };
    };

    selectOurBenchmarkPeriod<T>(data: (T & IBenchmarkable)[]): (T & IBenchmarkable)[] {
        const filtered = data.filter(
            datum =>
                (datum.portfolioPeriodID ? datum.portfolioPeriodID === portfolioPeriodID.yearly : true) &&
                (datum.portfolioBenchmarkID ? datum.portfolioBenchmarkID === portfolioBenchmarkID.none : true)
        );

        return filtered;
    };

    getHasAlerts = (expertPortfolioID: number, lastTransaction: Date | null) => {
        const { alerts } = this.state
        const date = alerts[expertPortfolioID]
        const whenLastAlertCleared = moment(
            date,
        );
        const hasAlerts = !!date && lastTransaction
            ? whenLastAlertCleared.isBefore(lastTransaction)
            : true;
        return hasAlerts;
    }

    clearAlert = (expertPortfolioID: number) => {
        this.setState(
            {
                alerts: {
                    ...this.state.alerts,
                    [expertPortfolioID]: new Date().toISOString(),
                },
            },
            () => localStorage.setItem(alertsStorageKey, JSON.stringify(this.state.alerts)),
        );
    };

    getTrades = (transactions: Transaction[]) => transactions
        .filter(t => !!t.shares)
        .sort(this.sortByEffectiveDate)

    sortByEffectiveDate = (a: IDateable, b: IDateable) => new Date(a.effectiveDate) < new Date(b.effectiveDate) ? 1 : -1
    findFirstUnclosedTrade = (transactions: Transaction[]) => transactions
        .sort(this.sortByEffectiveDate)
        // filter for position that WERENT closed.
        .find(t1 => 0 < transactions
            .filter(t2 => t2.ticker.toLowerCase() === t1.ticker.toLowerCase())
            // count open's and close's. if above zero, position is eventually not closed.
            // open's count as a plus one, closes as minus one.
            .reduce((prev, curr) => prev + ({
                [ActionId.Closed]: -1,
                [ActionId.Reopened]: 1,
                [ActionId.Opened]: 1,
            }[curr.actionId]), 0)
        ) as Transaction
}

function getMarketColorClassName(n: number | null) {
    return n ? classNames({
        bullGreen: n > 0,
        bearRed: n < 0,
    }) : '';
}

function getSign(n: number) {
    if (n === 0) return '0%'
    else if (!n) return '--';
    const sign = n > 0 ? '+' : n < 0 ? '' : '';
    return sign + n + '%';
}

class HackyAlerts extends React.Component<
    {
        onClick: () => void;
        hasAlerts: boolean;
        expertName: string;
        data: Alert[];
        title: string;
        // expertRating: number;
    },
    { visible: boolean }
    > {
    state = { visible: false };
    render() {
        const {
      onClick,
            hasAlerts,
            expertName,
            data,
            title,
            // expertRating,
        } = this.props;
        const { visible: active } = this.state;
        const theData = data.slice(0, 7)
        const hasData = theData.length

        return (
            <div
                className="hackyAlerts-container"
                onMouseEnter={() => this.setState({ visible: true })}
                onMouseLeave={() => {
                    onClick();
                    this.setState({ visible: false })
                }}
            >
                <img
                    className="hackyAlerts"
                    onClick={() => this.props.onClick()}
                    src={absImgSrc(hasAlerts ? 'bell-active.png' : 'bell.png')}
                />
                <div className={classNames('floatguy-container', { active })}
                    onMouseEnter={() => this.setState({ visible: true })}
                    onMouseLeave={() => {
                        onClick();
                        this.setState({ visible: false })
                    }}
                >
                    <div
                        className={classNames('floatguy')}
                    >
                        <header>
                            <h1>
                                {title}
                            </h1>
                        </header>
                        {!hasData ? 'No recent transactions, this person should buy some stocks! :)' :
                            <ol>
                                {theData.map((datum, index) => {
                                    const action = translateActionText(datum.action);
                                    const { displayName: item, sharesTraded } = datum;
                                    return (
                                        <li key={index} data-action-id={datum.action} data-date={datum.executionTime}>
                                            {/* <ExpertRating
                        animate={false}
                        color={DEFAULT_COLOR}
                        rating={expertRating}
                        size={12}
                      /> */}
                                            <span>
                                                <span>{expertName} {action} {Math.abs(sharesTraded)} {item} shares{' '}</span>
                                                <FormattedRelative value={datum.executionTime} />.
                      </span>
                                        </li>
                                    );
                                })}
                            </ol>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// ExpertPortfolioActionEnum
export enum ActionId {
    Opened = 1,
    Increased = 2,
    Reduced = 3,
    Closed = 4,
    NoChange = 5,
    UserDefinePrice = 6,
    Reopened = 7,
    SplitAdjustment = 8,
}

function translateActionText(action: ActionId) {
    return {
        [ActionId.Opened]: 'bought',
        [ActionId.Increased]: 'bought',
        [ActionId.Reopened]: 'bought',
        [ActionId.Reduced]: 'sold',
        [ActionId.Closed]: 'sold',
        [ActionId.NoChange]: 'maintained',
    }[action]
}

const noRankMsg = 'Portfolio must contain at least 5 transactions (non-closing transactions) where each transaction contains at least 6 shares traded';

const ZeTooltip = () => <Tooltip style={{ display: 'inline-block' }} content={noRankMsg} isMobile={false} />
