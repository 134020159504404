export const supportNumber = '866-3887782';

export default {
  title: 'Contact Us',
  undertitle:
    'Please do not hesitate to contact us with any questions or feedback.',
  subjects: [
    { type: 1, name: 'Subscriptions and Plans' },
    { type: 2, name: 'Analyst or Blogger Inquiry' },
    { type: 3, name: 'Press and Business Inquiry' },
    { type: 4, name: 'Technical issues' },
    { type: 6, name: 'My Portfolio' },
    { type: 5, name: 'Other (Specify)' }
  ],
  placeholders: {
    subject: 'Please select your inquiry subject',
    name: 'Name',
    email: 'Email Address',
    phone: 'Phone Number',
    message: 'Message'
  },
  errorMessages: {
    name: 'Please enter your full name',
    email: 'Please enter a valid email address',
    phone: 'Please enter a valid phone number',
    default: 'An error has occured'
  },
  success: 'Thank you for contacting us.',
  submitButton: 'Send',

  callUs: 'Call us'
};

export const __hotReload = true;
