import { PlansLink } from '../components/PlansLink';
import { isIOS } from '../../common/lib/utils';
import { BluesnapCustomParams } from '../tr-payment/types';
import { SmarterAnalystBadge } from '../SmarterAnalystBadge/SmarterAnalystBadge';
import {
  ComponentState,
  Plan,
  PlanEnum,
  TradeItSyncStatus
} from '../../common/types';
import { Icon } from 'tipranks-icons';
import * as React from 'react';
import Clickable from '../Clickable/clickable.react';
import * as classNames from 'classnames';
import {TrProfilePicDropzone} from '../tr-profile-pic-dropzone/TrProfilePicDropzone';

import OnOutsideClick from '../lib/on-outside-click.react';
import * as styles from './style.istyl';
import * as moment from 'moment';

type AccountDetailsProps = BasicInfoWidgetProps &
  ExtendedInfoWidgetProps &
  PlanWidgetProps;

export class AccountDetails extends React.PureComponent<
  AccountDetailsProps,
  {}
  > {
  public render() {
    const {
      memberSince,
      name,
      email,
      synchronizedAccounts,
      onPasswordChange,
      onNameChange,
      onPhoneChange,
      onReauthenticate,
      phoneNumber,
      userPic,
      passwordServerState,
      plan,
      bluesnapUrlGen
    } = this.props;

    return (
      <section className={styles.details}>
        <BasicInfoWidget {...{ memberSince, name, email, userPic }} />
        <ExtendedInfoWidget
          {...{
            synchronizedAccounts,
            name,
            onPasswordChange,
            onNameChange,
            onPhoneChange,
            phoneNumber,
            passwordServerState,
            onReauthenticate
          }}
        />
        <PlanWidget plan={plan} bluesnapUrlGen={bluesnapUrlGen} />
      </section>
    );
  }
}

function DateTime({
  date,
  format = 'DD MMM, YYYY'
}: {
    date: Date;
    format?: string;
  }) {
  return (
    <time dateTime={date.toISOString()} title={date.toDateString()}>
      {moment(date).format(format)}
    </time>
  );
}

type ClickableHeaderProps = {
  children?: {};
  linkText: string;
  onClick: React.EventHandler<
  React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>
  >;
} & React.HTMLProps<HTMLHeadingElement>;
type LinkingHeaderProps = {
  children?: {};
  linkText: string;
  href: string;
} & React.HTMLProps<HTMLHeadingElement>;

type BasicInfoWidgetProps = {
  name: string;
  email: string;
  memberSince: Date;
  userPic: string;
};

function ClickableHeader({
  children,
  linkText,
  onClick,
  ...htmlProps
}: ClickableHeaderProps);
function ClickableHeader({
  children,
  linkText,
  href,
  ...htmlProps
}: LinkingHeaderProps);
function ClickableHeader({
  children,
  linkText,
  href,
  onClick,
  ...htmlProps
}: any) {
  const clickableProps = href ? { href } : { onClick };
  return (
    <h4 {...htmlProps}>
      <Clickable {...clickableProps}>
        {children} <small>{linkText}</small>
      </Clickable>
    </h4>
  );
}

function BasicInfoWidget({
  memberSince,
  name,
  email,
  userPic
}: BasicInfoWidgetProps) {
  return (
    <article className={styles.basicInfo}>
      <div className={styles.picContainer}>
        <TrProfilePicDropzone profileSrcName={userPic} />
      </div>
      <div className={styles.infoContainer}>
        <h3>{name}</h3>
        <p>{email}</p>
        <small>
          Member Since: <DateTime date={memberSince} />
        </small>
      </div>
    </article>
  );
}

type ExtendedInfoWidgetProps = {
  name: string;
  phoneNumber: string;
  synchronizedAccounts: Array<{
    broker: Broker;
    authentication: TradeItSyncStatus;
    id: number;
  }>;
  onPasswordChange: React.EventHandler<React.SyntheticEvent<HTMLFormElement>>;
  onNameChange: React.EventHandler<React.SyntheticEvent<HTMLFormElement>>;
  onPhoneChange: React.EventHandler<React.SyntheticEvent<HTMLFormElement>>;
  onReauthenticate: (broker: Broker) => void;

  passwordServerState: ComponentState;
};
type ExtendedInfoWidgetState = {
  editingName: boolean;
  editingPassword: boolean;
  editingPhoneNumber: boolean;

  passwordError: string;
};

export enum Broker {
  'etrade',
  'tradestation',
  'tradeking',
  'td',
  'scottrade',
  'schwab',
  'fidelity',
  'robinhood',
  'dummy', // heh
  'undefined', // lol
  '[object Object]', // plol
  'Forgivness Brokerage Inc' // damnit Benny!
}
type Nullable<T> = {[K in keyof T]: T[K] | null };
type PasswordRefs = {
  old: HTMLInputElement;
  new: HTMLInputElement;
  repeat: HTMLInputElement;
};

class ExtendedInfoWidget extends React.PureComponent<
  ExtendedInfoWidgetProps,
  ExtendedInfoWidgetState
  > {
  public state: ExtendedInfoWidgetState = {
    editingName: false,
    editingPassword: false,
    editingPhoneNumber: false,
    passwordError: ''
  };

  private passwordRefs: PasswordRefs = {} as PasswordRefs;

  private toggle = <K extends Exclude<keyof ExtendedInfoWidgetState, 'passwordError'>>(key: K, newState?: ExtendedInfoWidgetState[K]) => () => {
    this.setState(state => ({
      [key]: typeof newState === 'boolean' ? newState : !state[key]
    }) as any);
  };

  public componentWillReceiveProps(nextProps: ExtendedInfoWidgetProps) {
    const { passwordServerState: oldPasswordState } = this.props;
    const { passwordServerState: newPasswordState } = nextProps;

    if (oldPasswordState !== newPasswordState) {
      if (newPasswordState === 'SUCCESS') {
        this.toggle('editingPassword', false);
      }
      if (newPasswordState === 'ERROR') {
        // This does not account for database failures or account blocks.
        // Yey redux.
        this.setState({
          passwordError: 'Your old password is incorrect. Please try again.'
        });
      }
    }
  }

  private closeAndClearPasswordForm = () => {
    this.toggle('editingPassword', false);
    // Disgusting, I know.
    // I wanted to keep things outside of React's management. If you think I'm a moron, feel free to tell me ~ Dor.
    if (this.passwordRefs) {
      this.passwordRefs.old.value = '';
      this.passwordRefs.new.value = '';
      this.passwordRefs.repeat.value = '';
    }
  };

  public render() {
    const {
      synchronizedAccounts,
      name,
      onPasswordChange,
      onPhoneChange,
      onNameChange,
      phoneNumber,
      onReauthenticate
    } = this.props;

    return (
      <article className={styles.extendedInfo}>
        <OnOutsideClick listener={() => this.toggle('editingName', false)}>
          <ClickableHeader
            linkText={this.state.editingName ? 'Cancel' : 'Edit'}
            onClick={this.toggle('editingName')}
          >
            Name
          </ClickableHeader>
          <form
            className={classNames(styles.phoneChange, {
              [styles.active]: this.state.editingName
            })}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              onNameChange(e);
              this.toggle('editingName', false);
            }}
          >
            <input
              required
              placeholder="Full name"
              maxLength={35}
              defaultValue={name}
              name="newName"
            />{' '}
            <button>Save</button>
          </form>
          <p>{name}</p>
        </OnOutsideClick>
        <OnOutsideClick listener={this.closeAndClearPasswordForm}>
          <ClickableHeader
            linkText={this.state.editingPassword ? 'Cancel' : 'Change'}
            onClick={() => {
              if (this.state.editingPassword) {
                // This is needed because we want to clear the password fields.
                return this.closeAndClearPasswordForm();
              }
              return this.toggle('editingPassword')();
            }}
          >
            Password
          </ClickableHeader>
          <form
            className={classNames(styles.passwordChange, {
              [styles.active]: this.state.editingPassword
            })}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              const oldPassword = e.currentTarget.oldPassword.value;
              const newPassword = e.currentTarget.newPassword.value;
              const repeatPassword = e.currentTarget.repeatNewPassword.value;
              if (oldPassword === newPassword) {
                return this.setState({
                  passwordError: 'Old and new passwords cannot be the same'
                });
              }
              if (newPassword !== repeatPassword) {
                return this.setState({
                  passwordError: 'Passwords do not match'
                });
              }
              onPasswordChange(e);
              // No state toggling here, the state of this is kept in Redux.
            }}
            onInput={e => this.setState({ passwordError: '' })}
          >
            <label>
              <span>Old password</span>{' '}
              <input
                ref={ref => ref && (this.passwordRefs.old = ref)}
                required
                name="oldPassword"
                type="password"
              />
            </label>
            <label>
              <span>New password</span>{' '}
              <input
                ref={ref => ref && (this.passwordRefs.new = ref)}
                required
                name="newPassword"
                type="password"
              />
            </label>
            <label>
              <span>Repeat password</span>{' '}
              <input
                ref={ref => ref && (this.passwordRefs.repeat = ref)}
                required
                name="repeatNewPassword"
                type="password"
              />
            </label>
            <div>
              <span className={styles.errorMessage}>
                {this.state.passwordError}
              </span>
              {this.props.passwordServerState === 'LOADING' && (
                <span>Processing, please wait...</span>
              )}
              <button>Save</button>
            </div>
          </form>
          {/* Such amazing */}
          <p> *********** </p>
        </OnOutsideClick>
        <div className={styles.synchronizedAccounts}>
          <h4>Synchronized Accounts</h4>
          {synchronizedAccounts.length > 0 ? (
            <ul>
              {synchronizedAccounts.map(({ broker, authentication, id }) => (
                <li key={id}>
                  <span>
                    {brokerDisplay(broker)} ({id})
                  </span>
                  {authentication === TradeItSyncStatus.Ok && (
                    <Icon
                      data-tooltip="Status: Active"
                      className={classNames(styles.tickIcon, styles.hasTooltip)}
                      icon="circleTick"
                    />
                  )}
                  {authentication === TradeItSyncStatus.Expired && (
                    <Clickable onClick={() => onReauthenticate(broker)}>
                      <Icon
                        data-tooltip="Reauthentication Required"
                        className={classNames(
                          styles.warnIcon,
                          styles.hasTooltip
                        )}
                        icon="warning"
                      />
                    </Clickable>
                  )}
                </li>
              ))}
            </ul>
          ) : (
              <span>—</span>
            )}
        </div>
        {/*<div>
          <h4>Next Billing Date</h4>
          <p><DateTime date={nextBillingDate} /></p>
          <small className={styles.creditCard}>
            <span className={styles.asterisks}>**** **** ****</span> <span className={styles.lastFourDigits}>{lastFourDigits}</span>
          </small>
        </div>*/}

        <OnOutsideClick
          listener={() => this.toggle('editingPhoneNumber', false)}
        >
          <ClickableHeader
            linkText={
              this.state.editingPhoneNumber
                ? 'Cancel'
                : phoneNumber ? 'Edit' : 'Add'
            }
            onClick={this.toggle('editingPhoneNumber')}
          >
            Phone Number
          </ClickableHeader>
          <form
            className={classNames(styles.phoneChange, {
              [styles.active]: this.state.editingPhoneNumber
            })}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              onPhoneChange(e);
              this.toggle('editingPhoneNumber', false);
            }}
          >
            <input
              name="tel"
              required
              placeholder="Phone number"
              maxLength={35}
              defaultValue={phoneNumber}
              type="tel"
            />{' '}
            <button>Save</button>
          </form>
          <p>{phoneNumber || '—'}</p>
        </OnOutsideClick>
      </article>
    );
  }
}

function brokerDisplay(broker: Broker) {
  switch (broker) {
    case Broker.fidelity:
      return 'Fidelity';
    case Broker.etrade:
      return 'E-Trade';
    case Broker.dummy:
      return 'Dummy Broker';
    case Broker.robinhood:
      return 'RobinHood';
    case Broker.schwab:
      return 'Charles Schwab';
    case Broker.scottrade:
      return 'Scott Trade';
    case Broker.td:
      return 'TD';
    case Broker.tradeking:
      return 'TradeKing';
    case Broker.tradestation:
      return 'TradeStation';
    case Broker.undefined:
      return 'Nasal Demons';
    case Broker['[object Object]']:
      return 'Lack of Fundamental JavaScript understanding';
    case Broker['Forgivness Brokerage Inc']:
      return 'Is there a possibly?!';
    default:
      return 'N/A.';
  }
}

type PlanWidgetProps = {
  bluesnapUrlGen: <K extends keyof BluesnapCustomParams>(
    customOverride: Pick<BluesnapCustomParams, K>
  ) => string;
  plan: Plan;
};
function PlanWidget({ plan, bluesnapUrlGen }: PlanWidgetProps) {
  const renamedPlan: 'open' | 'basic' | 'premium' | 'ultimate' =
    {
      free: 'basic'
    }[plan] || plan;

  const urlGen =
    plan === 'free'
      ? ({ custom3 }: { custom3: string }) => `/go-pro?llf=${custom3}`
      : bluesnapUrlGen;

  const Header =
    renamedPlan === 'ultimate'
      ? 'h4'
      : ({ children }) => (
        <ClickableHeader
          linkText="Upgrade"
          target="_self"
          href={urlGen({ custom3: 'my-account-upgrade-link' })}
        >
          {children}
        </ClickableHeader>
      );

  return (
    <article className={styles.planWidget}>
      <div>
        <Header>
          My Plan:{' '}
          <span className={classNames(styles.plan, styles[renamedPlan])}>
            <Icon
              icon="ribbon"
              size={30}
              className={classNames({
                [styles.withShittyIOS]: isIOS()
              })}
            />{' '}
            <span>{renamedPlan}</span>
          </span>
        </Header>
      </div>
      {renamedPlan === 'basic' && (
        <div>
          <h4>Get more with TipRanks Premium</h4>
          <ul className={classNames(styles.coloredBullets, styles.orange)}>
            <li>
              <span>Get top expert consensus and sentiment for all stocks</span>
            </li>
            <li>
              <span>Receive real-time alerts from top financial experts</span>
            </li>
            <li>
              <span>
                Get the most recommended stocks by top analysts and more
              </span>
            </li>
          </ul>
        </div>
      )}
      {renamedPlan === 'premium' && (
        <div>
          <h4>Get more with TipRanks Ultimate</h4>
          <ul className={classNames(styles.coloredBullets, styles.orange)}>
            <li>
              <span>
                Tailor expert performance stats to your investing style
              </span>
            </li>
            <li>
              <span>Find out which stocks are traded highly by Insiders</span>
            </li>
            <li>
              <span>
                Find top performing experts according to your investing style
              </span>
            </li>
            <li>
              <span>
                Take control of multiple portfolios on Smart Portfolio
              </span>
            </li>
          </ul>
        </div>
      )}
      {/*{renamedPlan === 'ultimate' && <div>
        <h4>Learn more about our Investment Newsletters</h4>
        <p>The best trading strategies for novice &amp; professional traders</p>
      </div>}*/}

      {renamedPlan !== 'ultimate' && (
        <div className={styles.upgrade}>
          <Clickable
            className={styles.button}
            href={urlGen({ custom3: 'my-account-upgrade-button' })}
          >
            Upgrade
          </Clickable>
          <PlansLink className={styles.link} custom3="my-account-upgrade-plans">
            See plans &amp; pricing
          </PlansLink>
        </div>
      )}
      {/*{renamedPlan === 'ultimate' && <div className={styles.upgrade}>
        <Clickable
          className={styles.button}
          href="https://www.smarteranalyst.com/investment-newsletters/"
        >Visit Investment Center</Clickable>
        <SmarterAnalystBadge />
      </div>}*/}
    </article>
  );
}
