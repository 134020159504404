import { List } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'react-simple-pie-chart';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

const colors = {
  buy: '#46a5ac',
  hold: '#747474',
  sell: '#90408f'
};

const c3Config = data => {
  if (data.toJS) data = data.toJS();
  const mapped = data.map(([id, datum]) => ({
    color: colors[id],
    value: datum
  }));
  return mapped;
};

export default class AnalystConsensusCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    isRestricted: PropTypes.bool,
    stock: PropTypes.object
  };

  render() {
    const { enums, stock, isRestricted } = this.props;

    const analystConsensus = stock.getIn(['stock', 'analystConsensus']);

    const isPieShown =
      analystConsensus.get('decision') && analystConsensus.get('distribution');

    return (
      <div>
        <div className="pie-chart">
          {isPieShown && (
            <ul className="legend">
              {analystConsensus.get('distribution').map(([type, count]) => (
                <li className={type} key={type}>
                  <span className="type">{type}</span>
                  <span className="count">{count}</span>
                </li>
              ))}
            </ul>
          )}
          {isPieShown && (
            <PieChart
              className="pie-chart"
              slices={c3Config(
                stock.getIn(['stock', 'analystConsensus', 'distribution'])
              )}
            />
          )}
        </div>

        <div className="pie-chart-value">
          <PortfolioItemSelector
            isRestricted={isRestricted}
            item={stock}
            msg={enums}
            type="analystConsensus"
          />
        </div>
      </div>
    );
  }
}
