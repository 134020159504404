import * as React from 'react';
import { StockLink } from '../../StockLink/StockLink';

export class NameCombinedCell extends React.PureComponent<{ event: any }, {}> {
  render() {
    const { event } = this.props;
    const stock = event.get('stock');
    return (
      <span>
        <StockLink className="ticker" stock={stock} target="_blank" />
        <br />
        <span className="name">{event.getIn(['stock', 'name'])}</span>
      </span>
    );
  }
}
