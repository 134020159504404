import { Repeat } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Component from 'react-pure-render/component';

import { ImportLogos } from '../tr-import-logos/ImportLogos';
import { disablePortfolioImport } from '../../common/config';
import { syncSmartPortfolioWithPlaid } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { identifyUser } from '../../common/api';
import './NoDataSection.styl';
import VariableSizeSpinner from '../Spinner';

const button = (action, msg) => <button onClick={action}>{msg}</button>;

export default class NoDataSection extends Component<
  {},
  { expertId: number, isLoadingPlaid: boolean }
> {
  state = { expertId: 0, isLoadingPlaid: false };
  static propTypes = {
    msg: PropTypes.object,
    openAddStockDialog: PropTypes.func,
    openSyncDialog: PropTypes.func
  };
  componentDidMount() {
    if(this.props.auth) {
      const expertIdFromStore
        = this.props.auth
          .get('users')
          .get('viewer')
          .get('loggedInUser')
          .get('expertId');
      if (expertIdFromStore) {
        this.setState({ expertId: expertIdFromStore })
      }
    }
  }

  onSyncClick = () => {
    const expertId = this.state.expertId
    this.setState({ isLoadingPlaid: true })
    const { fetchPortfolios, changePortfolio } = this.props;
    syncSmartPortfolioWithPlaid(expertId, {
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        if (typeof fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await fetchPortfolios();
        }
        if (Array.isArray(PortfolioIds) && PortfolioIds[0] && typeof changePortfolio === 'function') {
          changePortfolio(PortfolioIds[0])
        }
        this.setState({ isLoadingPlaid: false });
      },
      onSyncFailed: () => {
        this.setState({ isLoadingPlaid: false })
      },
      onSyncStopped: () => {
        this.setState({ isLoadingPlaid: false })
      },
    })
  }

  render() {
    const { msg, openAddStockDialog, openSyncDialog } = this.props;
    const { isLoadingPlaid } = this.state;

    return (
      <div className="holdings-no-data">
        <div className="title">{msg.title}</div>
        {!disablePortfolioImport ? 
        <div>
          <div>
            {isLoadingPlaid ? (
              <span className={'holdings-no-data-msg'}>
                Connecting to your existing portfolio, please wait...
                <VariableSizeSpinner className={'holdings-no-data-spinner'} size={15}/>
              </span>
            )
            : (
              <FormattedMessage
                defaultMessage={msg.text}
                id={msg.text}
                values={{
                  syncLink: button(this.onSyncClick, msg.syncLink)
                }}
              />
            )}
            </div>
            <div className="support">{msg.support}</div>
            <div className="logos">
              <ImportLogos openSyncDialog={openSyncDialog} />
            </div>
            <div className="or-wrapper">
              <div className="or">{msg.or}</div>
            </div>
          </div> : null
        }
        <FormattedMessage
          id={msg.addStocks}
          defaultMessage={msg.addStocks}
          values={{
            syncLink: button(openAddStockDialog, msg.addStocksLink)
          }}
        />
      </div>
    );
  }
}
