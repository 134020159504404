import { List } from 'immutable';

export const img = imgName =>
  process.env.IS_BROWSER ? require(`./assets/img/${imgName}`) : '';

export const trIconGr = img(
  `${process.env.THEME === 'nasdaq' ? 'nasdaq' : 'tr'}-icon-gr.png`
);

export const headIconGr = img('head-icon-gr.png');

export function calculateScaleRatio(items: List<any>, key: string, targetTotal = 100) {
  const actualTotal = items
    .map(item => item.get(key))
    .filter(Boolean)
    .reduce((x, y) => x + y, 0);
  return targetTotal / actualTotal;
}
