import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import report from './analytics';

import Confirm from '../Confirm';

const okHandler = (deletePortfolio, close, portfolioName) => () => {
  report('confirmed', portfolioName);
  deletePortfolio();
  close();
};

export default class TrDashboardDeletePortfolio extends Component {
  static propTypes = {
    close: PropTypes.func,
    deletePortfolio: PropTypes.func,
    msg: PropTypes.object,
    portfolioName: PropTypes.string
  };

  render() {
    const { close, deletePortfolio, msg, portfolioName } = this.props;

    return (
      <Confirm
        cancelHandler={close}
        cancelMsg={msg.cancel}
        okHandler={okHandler(deletePortfolio, close, portfolioName)}
        okMsg={msg.ok}
        question={
          <FormattedMessage
            defaultMessage={msg.question}
            id={msg.question}
            values={{
              portfolio: <span className="portfolio">'{portfolioName}'</span>
            }}
          />
        }
        title={msg.title}
      />
    );
  }
}
