import './menu-header.styl';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as rx from 'rx-lite';;
import * as Slideout from 'slideout';
import * as EE from 'tiny-emitter';
import Clickable from 'tipranks-clickable';
import * as classNames from 'classnames';

import { selectIsPermanentLoggedIn } from '../../common/auth/selectors';
import { openExternalLogin } from '../../common/lib/utils';
import { Actions, MyExperts } from '../../common/types';
import { createNasdaqLink, premiumUpgradeUrl, getScrollPosition } from '../lib/utils';
import { NotificationBell } from '../notifications/NotificationBell';
import { NotificationsStoreState } from '../notifications/types';
import AuthMenu from '../tr-dashboard-header/auth-menu.react';
import image from './images';
import RegisterHeader from './register-header.react';
import SearchHeader from './search-header.react';
import { history } from 'sp/browser/history';

const Buttons = ({
  auth,
  onOpenMenu,
  msg,
  actions,
  myExperts,
  isLoggedIn,
  theme,
  notifications
}) => (
    <div className="mobile-header-buttons">
      <AuthMenu
        className="mobile-auth-menu"
        isLoggedIn={isLoggedIn}
        signUpOnly={true}
        premiumUpgradeUrl={premiumUpgradeUrl(auth, 'ultimate')}
        actions={actions}
      />

      {process.env.THEME !== 'nasdaq' && (
        <NotificationBell
          notifications={notifications}
          actions={actions}
          myExperts={myExperts}
        />
      )}

      {theme === 'web' && (
        <Clickable className="search link" href="/search">
          {image('search', 'svg')}
        </Clickable>
      )}

      {theme === 'web' && (
        <label className="menu link">
          <button
            onClick={() => {
              onOpenMenu();
            }}
          />
          {image('hamburger', 'svg')}
        </label>
      )}
    </div>
  );

const slideoutDuration = 420;

interface HeaderMenuProps {
  onOpenMenu?: (openState: boolean) => void;
  goBack?: () => void;
  msg: { [key: string]: any };
  searchResults: any[];
  route: string;
  style?: any; //React.CSSProperties;
  auth: any; //Auth;
  actions: Actions;
  isSidebarOpen: boolean;

  notifications: NotificationsStoreState;
  myExperts: MyExperts;
}

const HeaderMenu = class HeaderMenu extends React.PureComponent<
  HeaderMenuProps,
  { slideout: boolean }
  > {
  private toggleSlideoutObservable: rx.Subject<boolean>;
  private searchEmitter: any;
  private subscriptionToDispose: rx.IDisposable;
  private slideout: Slideout;
  private slideoutHeader: JSX.Element;

  constructor(props) {
    super(props);

    this.state = { slideout: false };

    this.toggleSlideoutObservable = new rx.Subject();

    this.searchEmitter = new EE();

    this.subscriptionToDispose = this.toggleSlideoutObservable
      .do(slideout => {
        this.slideout[slideout ? 'open' : 'close']();
        if (this.props.onOpenMenu) {
          this.props.onOpenMenu(false);
        }
      })
      .map(
        slideout =>
          slideout
            ? rx.Observable.return(true)
            : rx.Observable.return(false).delay(slideoutDuration + 50)
      )
      .switch()
      .subscribe(slideout => this.setState({ slideout: slideout }));
  }

  componentWillUnmount() {
    this.subscriptionToDispose.dispose();
  }

  componentDidMount() {
    this.slideout = new Slideout({
      panel: ReactDOM.findDOMNode(this.refs.panel),
      menu: ReactDOM.findDOMNode(this.refs.search),
      get padding() {
        // this will probably not work everywhere
        return window.innerWidth;
      },
      duration: slideoutDuration,
      tolerance: 50,
      side: 'right',
      touch: false
    });
    this.toggleRouteHeader(this.props.route);

    this.slideout.on('open', () => {
      const el = document.querySelector('.slideout-open');
      if (el) el.className += ' menu-header-slideout';
    });

    this.slideout.on('close', () => {
      const el = document.querySelector('.menu-header-slideout');
      if (el)
        el.className = el.className.replace('menu-header-slideout', '').trim();
    });
  }

  componentWillReceiveProps({ route }) {
    if (this.props.route === route) {
      return;
    }
    // console.log(route)
    this.toggleRouteHeader(route);
  }

  goBack = () => {
    if (this.props.goBack) this.props.goBack();
  };

  toggleRouteHeader(route) {
    route = route.path || route;
    if (
      route &&
      route.match(
        /search|register|login|upgrade|upgrade-ultimate|subscription-limit|confirm-ultimate/
      )
    ) {
      this.toggleSlideoutObservable.onNext(true);
    } else {
      this.toggleSlideoutObservable.onNext(false);
    }
  }

  getSlideoutHeader = ({ msg, route, searchResults, actions } = this.props) => {
    // we save the current header so that when we go back from a route with one of these headers
    // the right header is still rendered under the main header, so that during the header
    // closing animation, the header still shows and doesn't disappear at the beginning of
    // the animation.
    this.slideoutHeader =
      {
        '/register': <RegisterHeader title={msg.signup} goBack={this.goBack} />,
        '/login': <RegisterHeader title={msg.login} goBack={this.goBack} />,
        // upgrade: <RegisterHeader msg={msg} goBack={this.goBack}/>,
        // 'upgrade-ultimate': <RegisterHeader msg={msg} goBack={this.goBack}/>,
        // 'subscription-limit': <RegisterHeader msg={msg} goBack={this.goBack}/>,
        // 'confirm-ultimate': <RegisterHeader msg={msg} goBack={this.goBack}/>,
        '/search': (
          <SearchHeader
            onType={actions.getDataList}
            onSubmit={actions.performSearch}
            searchResults={searchResults}
            msg={msg}
            goBack={this.goBack}
            emitter={this.searchEmitter}
          />
        )
        // 'upgrade-light': <UpgradeLightHeader msg={msg} goBack={this.goBack} />,
        // 'registerEmailOnly': <RegisterHeader msg={{ title: 'Sign Up' }} goBack={this.goBack}/>
      }[route] || this.slideoutHeader;

    return this.slideoutHeader;
  };

  getTopOffset({ isSidebarOpen } = this.props) {
    return isSidebarOpen && getScrollPosition().y;
  }

  render(
    { onOpenMenu, msg, auth, style, actions, notifications, myExperts } = this
      .props,
    { slideout /*initialSlideout*/ } = this.state
  ) {
    const { route } = this.props;
    const theme = process.env.THEME;
    return (
      <header
        style={style}
        className={classNames('tr-menu-header', {
          slideoutOpen: slideout,
          shouldHideOnIphone5s: route === 'registerEmailOnly'
        })}
      >
        <div
          ref="panel"
          className={classNames('slideoutPanel', {
            slideoutPanelOpen: slideout
            // slideoutPanelClosed: initialSlideout // I'm unsure when this is used. The variable it counts on is never set.
          })}
        >
          <div className="leftSide">
            <a href={process.env.THEME === 'nasdaq' ? createNasdaqLink() : '/'}>
              {process.env.THEME === 'web' ? (
                image('logo', 'img')
              ) : (
                  <img
                    alt=""
                    className="mobile-logo-size-adjustment"
                    src={`/assets/img/${
                      process.env.THEME
                      }-logo-header-mobile.png`}
                  />
                )}
            </a>
          </div>
          <div className="rightSide">
            <Buttons
              isLoggedIn={selectIsPermanentLoggedIn()}
              actions={{
                onLogin: () => {
                  if (process.env.THEME === 'web') {
                    history.push({ pathname: '/login' });
                    return;
                  }

                  openExternalLogin();
                },
                onRegister: () => {
                  if (process.env.THEME === 'web') {
                    history.push({ pathname: '/register' });
                    return;
                  }

                  openExternalLogin();
                },
                onLogout: () => this.props.actions.logout()
              }}
              onOpenMenu={onOpenMenu}
              auth={auth}
              notifications={notifications}
              theme={process.env.THEME}
              msg={msg}
              myExperts={myExperts}
            />
          </div>
        </div>
        <div
          className={classNames('slideoutMenu', { slideoutMenuOpen: slideout })}
          ref="search"
        >
          {this.getSlideoutHeader()}
        </div>
      </header>
    );
  }
};

export default HeaderMenu;

export const __hotReload = true;
