/* eslint-disable camelcase,no-var,prefer-arrow-callback,no-restricted-syntax,dot-notation,eqeqeq,spaced-comment,yoda,vars-on-top,no-prototype-builtins,no-else-return,no-lonely-if,one-var,no-empty, prefer-const, no-undef,no-plusplus */
import d3 from 'd3';

export default (ticker, { toggleNoDataMessage }) => {
  var data_arr = {
    data_5d: {},
    data_1m: {},
    data_3m: {},
    data_ytd: {},
    data_1y: {},
    data_5y: {}
  };
  var active_data;
  var is_hover_displayed = false;
  var last_point_x_value = 0;
  // TODO import this
  var monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  var date_vals_arr = [];
  var url_source = ticker;
  if (
    typeof url_source === 'undefined' ||
    url_source == '' ||
    url_source == null
  ) {
    url_source = 'aapl';
  }

  function resize() {
    draw_area_chart(active_data);
  }

  function return_filtered_data(data) {
    var dates = [];
    var filtered_data = [];
    data.forEach(function(d, k) {
      var date_val = d.date.split('T');
      if (dates.indexOf(date_val[0] + 'T00:00:00') == -1) {
        dates.push(date_val[0] + 'T00:00:00');
        filtered_data.push({
          date: date_val[0] + 'T00:00:00',
          price: d.price
        });
      }
    });
    return filtered_data;
  }

  d3.select(window).on('resize', resize);

  // Fetch data for "5D"
  const historicalPriceUrl = (ticker, days) =>
    `/api/stocks/getHistoricalPrice/${ticker}/?daysBack=${days}`;

  d3.json(historicalPriceUrl(url_source, 7), function(error, data) {
    data_arr['data_5d'] = active_data = return_filtered_data(data);
    //console.log(_this);
  });
  // Fetch data for "1M"
  d3.json(historicalPriceUrl(url_source, 30), function(error, data) {
    data_arr['data_1m'] = return_filtered_data(data);
  });
  // Fetch data for "3M"
  d3.json(historicalPriceUrl(url_source, 90), function(error, data) {
    data_arr['data_3m'] = return_filtered_data(data);
  });
  // Fetch data for "YTD"
  d3.json(historicalPriceUrl(url_source, get_ytd_value()), function(
    error,
    data
  ) {
    data_arr['data_ytd'] = return_filtered_data(data);
  });
  // Fetch data for "1Y"
  d3.json(historicalPriceUrl(url_source, 365), function(error, data) {
    data_arr['data_1y'] = return_filtered_data(data);
    draw_area_chart(data_arr['data_1y']);
  });
  // Fetch data for "5Y"
  d3.json(historicalPriceUrl(url_source, 1825), function(error, data) {
    data_arr['data_5y'] = return_filtered_data(data);
    // console.log(data_arr['data_5y']);
  });

  function clone(obj) {
    if (null == obj || 'object' != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }
  // Fetch no. of days from Jan 1st
  function get_ytd_value() {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    return day;
  }

  function draw_area_chart(data) {
    if (!data.length) {
      toggleNoDataMessage(true);
      return;
    } else {
      toggleNoDataMessage(false);
    }
    is_hover_displayed = false;
    d3.select('#area_chart > svg').remove();
    const mainBoxEl = document.getElementById('mainBox');
    if (!mainBoxEl) return;
    var box_width = mainBoxEl.offsetWidth - 10;
    var x_ticks = 5;
    if (box_width < 350) {
      x_ticks = 2;
      if (document.getElementById('area_chart'))
        document.getElementById('area_chart').style.margin = '44px 0px 0px 0px';
    } else {
      if (document.getElementById('area_chart'))
        document.getElementById('area_chart').style.margin = '0px';
    }
    let y_min_max = d3.extent(data, function(d) {
      return d.price;
    });
    // in order not to fiddle around too much with fiverr code
    const labelLength = Math.max.apply(Math, y_min_max).toString().length;
    const aestheticallyPleasingMultiplier = 1.2;
    const extraLabelWidth =
      labelLength >= 6 ? -labelLength * aestheticallyPleasingMultiplier : 0;

    var margin = {
        top: 20,
        right: extraLabelWidth,
        bottom: 20,
        left: 50
      },
      width = box_width,
      height = 120;

    var last_point = {};

    var parseDate = d3.time.format('%Y-%m-%dT%H:%M:%S').parse;

    var x = d3.time.scale().range([0, width - 70]);

    var y = d3.scale.linear().range([height, 0]);

    var y1 = d3.scale.linear().range([height, 0]);

    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient('bottom')
      .ticks(x_ticks)
      .tickFormat(d3.time.format('%b %d, %Y'));

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient('right');

    var area = d3.svg
      .area()
      .x(function(d) {
        return x(d.date);
      })
      .y0(height)
      .y1(function(d) {
        return y(d.price);
      });

    var svg = d3
      .select('#area_chart')
      .append('svg')
      .attr('width', width - margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + 0 + ',' + margin.top + ')');

    data.forEach(function(d) {
      d.real_price = d.price;
      if (d.date instanceof Date) {
      } else {
        d.date = parseDate(d.date);
      }
      last_point = d;
      d.price = +d.price;
    });
    const diff_value = y_min_max[1] - y_min_max[0];
    y_min_max[1] += 15 / 100 * diff_value;
    x.domain(
      d3.extent(data, function(d) {
        return d.date;
      })
    );
    // y.domain([0, d3.max(data, function(d) { return d.price; })]);
    y.domain(y_min_max);

    // Draw the x Grid lines
    svg
      .append('g')
      .attr('class', 'x_g grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        make_x_axis()
          .tickSize(-height, 0, 0)
          .ticks(5)
          .tickFormat('')
      );

    // Draw the y Grid lines
    svg
      .append('g')
      .attr('class', 'y_g grid')
      .call(
        make_y_axis()
          .tickSize(-width - 10, 10, 0)
          .ticks(5)
          .tickFormat('')
      );
    svg
      .append('path')
      .datum(data)
      .attr('class', 'area') // .area
      .attr('d', area);

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    // Adding circles
    date_vals_arr = [];
    data.forEach(function(d, k) {
      let prev_x_val;

      var date_val =
        monthNames[d.date.getMonth()] +
        ' ' +
        d.date.getDate() +
        ', ' +
        d.date.getFullYear();
      if (date_vals_arr.indexOf(date_val) == -1) {
        date_vals_arr.push(date_val);

        if (k == 0) {
          prev_x_val = 0;
        } else {
          prev_x_val = x(data[k - 1].date);
        }
        svg
          .append('circle')
          .attr('class', 'data_point_circle')
          .attr('data-date-obj', d.date)
          .attr('data-y-price', y(d.real_price))
          .attr('data-price', d.real_price)
          .attr('data-x-val', x(d.date))
          .attr('data-date', date_val)
          .attr('data-prev-x-val', prev_x_val)
          .attr('cx', x(d.date))
          .attr('cy', y(d.real_price))
          .attr('r', '4')
          .attr('fill', '#000');
      }
    });

    last_point_x_value = x(last_point.date);
    svg
      .append('circle')
      .attr('cx', x(last_point.date))
      .attr('cy', y(last_point.price))
      .attr('r', '4')
      .attr('fill', '#ff9c0d');

    // Markers
    svg
      .append('g')
      .attr('class', 'y_axis_labels_g')
      .append('text')
      .attr('class', 'y_axis_labels')
      .attr('x', width - 60)
      .attr('y', height - 2)
      .text(function(d) {
        const canadianCurrency = ticker.toLowerCase().startsWith('tse:')
          ? 'C'
          : '';
        return canadianCurrency + '$' + y_min_max[0].toFixed(2);
      });
    svg
      .attr('class', 'y_axis_labels_g')
      .append('text')
      .attr('class', 'y_axis_labels')
      .attr('x', width - 60)
      .attr('y', -2)
      .text(function(d) {
        const canadianCurrency = ticker.toLowerCase().startsWith('tse:')
          ? 'C'
          : '';
        return canadianCurrency + '$' + y_min_max[1].toFixed(2);
      });

    const adjust = Math.abs(extraLabelWidth);
    let max_point_marker = svg
      .append('g')
      .attr('class', 'max_point_marker_g')
      .attr(
        'transform',
        'translate(' + (width - 65) + ',' + (y(last_point.price) - 7) + ')'
      );
    max_point_marker
      .append('rect')
      .attr('class', 'max_point_marker_rect')
      .attr('width', 45 + adjust * 1.7)
      .attr('height', '16.5')
      .attr('x', 10);

    max_point_marker
      .append('polygon')
      .attr('class', 'max_point_marker_polygon')
      .attr('transform', 'translate(10,0)')
      .attr('points', '-10,8.25 0,0 0,16.5');

    max_point_marker
      .append('text')
      .attr('class', 'max_point_marker_text')
      .attr('transform', 'translate(47,0)')
      .attr('x', 5 + adjust * 1.7)
      .attr('y', '12')
      .text(function(d) {
        const canadianCurrency = ticker.toLowerCase().startsWith('tse:')
          ? 'C'
          : '';
        return canadianCurrency + '$' + last_point.price.toFixed(2);
      });

    // Tweaks
    svg.selectAll('.y_g .tick line').style('stroke-dasharray', '3, 3');

    // Hover code
    svg
      .append('rect')
      .on('mousemove', function(d) {
        // var curr_x_value = d3.event.pageX - 22;
        var curr_x_value = d3.event.offsetX;
        let data_point_circles = document.querySelectorAll(
          '.data_point_circle'
        );
        for (var i = 0; i < data_point_circles.length; i++) {
          var circle_x_value = data_point_circles[i].getAttribute('data-x-val');
          var circle_prev_x_value = data_point_circles[i].getAttribute(
            'data-prev-x-val'
          );
          if (
            curr_x_value >= circle_prev_x_value &&
            curr_x_value <= circle_x_value
          ) {
            var x_circle = data_point_circles[i].getAttribute('data-x-val');
            var y_circle = data_point_circles[i].getAttribute('data-y-price');
            var price_circle = data_point_circles[i].getAttribute('data-price');
            var date_circle = data_point_circles[i].getAttribute('data-date');
            data_point_circles[i].style.display = 'block';
            show_hover_lines(x_circle, y_circle, price_circle, date_circle, i);
          } else {
            // data_point_circles[i].style.display = "none";
          }
        }
      })
      .on('mouseleave', function(d) {
        hide_circles();
        hide_hover_data();
      })
      .on('touchmove', function(d) {
        d3.event.preventDefault();
        d3.event.stopPropagation();
        touches = d3.touches(this);
        var curr_x_value = touches[0][0] - 22;
        let data_point_circles = document.querySelectorAll(
          '.data_point_circle'
        );
        for (var i = 0; i < data_point_circles.length; i++) {
          var circle_x_value = data_point_circles[i].getAttribute('data-x-val');
          var circle_prev_x_value = data_point_circles[i].getAttribute(
            'data-prev-x-val'
          );
          if (
            curr_x_value >= circle_prev_x_value &&
            curr_x_value <= circle_x_value
          ) {
            var x_circle = data_point_circles[i].getAttribute('data-x-val');
            var y_circle = data_point_circles[i].getAttribute('data-y-price');
            var price_circle = data_point_circles[i].getAttribute('data-price');
            var date_circle = data_point_circles[i].getAttribute('data-date');
            data_point_circles[i].style.display = 'block';
            show_hover_lines(x_circle, y_circle, price_circle, date_circle, i);
          } else {
            // data_point_circles[i].style.display = "none";
          }
        }
      })
      .on('touchend', function(d) {
        hide_circles();
        hide_hover_data();
      })
      .attr('class', 'hover_rect')
      .attr('width', width - 70)
      .attr('height', height + margin.top + margin.bottom)
      .attr('x', '0')
      .attr('y', '0')
      .attr('fill', 'transparent');

    // Hover lines

    svg
      .append('g')
      .attr('class', 'horizontal_line_g')
      .attr('id', 'horizontal_line_g_hover')
      .attr('transform', 'translate(0,20)')
      .append('line')
      .attr('class', 'hover_lines horizontal_line')
      .attr('id', 'horizontal_line_hover')
      .style('stroke-dasharray', '2,2')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', x(data[data.length - 1].date))
      .attr('y2', 0);

    svg
      .append('g')
      .attr('class', 'vertical_line_g')
      .attr('id', 'vertical_line_g_hover')
      .attr('transform', 'translate(50,0)')
      .append('line')
      .attr('class', 'hover_lines vertical_line')
      .attr('id', 'vertical_line_hover')
      .style('stroke-dasharray', '2,2')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', height);
    // Hover marker
    let hover_marker = svg
      .append('g')
      .attr('id', 'hover_marker_g')
      .attr('class', 'hover_lines hover_marker_g')
      .attr('transform', 'translate(' + (width - 65) + ',' + 0 + ')');
    hover_marker
      .append('rect')
      .attr('class', 'hover_marker_rect')
      .attr('width', '45')
      .attr('height', '16.5')
      .attr('x', '9.9');

    hover_marker
      .append('polygon')
      .attr('class', 'hover_marker_polygon')
      .attr('transform', 'translate(10,0)')
      .attr('points', '-10,8.25 0,0 0,16.5');

    hover_marker
      .append('text')
      .attr('class', 'hover_marker_text')
      .attr('id', 'hover_marker_text_id')
      .attr('transform', 'translate(47,0)')
      .attr('x', '5')
      .attr('y', '12')
      .text(function(d) {
        return '';
      });

    // Hover text block in x axis
    let hover_x_axis_marker = svg
      .append('g')
      .attr('id', 'hover_x_axis_g')
      .attr('class', 'hover_lines hover_x_axis_g')
      .attr('transform', 'translate(' + 0 + ',' + height + ')');
    hover_x_axis_marker
      .append('rect')
      .attr('class', 'hover_x_axis_rect')
      .attr('width', '80')
      .attr('height', '16.5')
      .attr('x', 0)
      .attr('y', 5);

    hover_x_axis_marker
      .append('text')
      .attr('class', 'hover_x_axis_text')
      .attr('id', 'hover_x_axis_text_id')
      .attr('transform', 'translate(47,0)')
      .attr('x', '22')
      .attr('y', '17')
      .text(function(d) {
        return '';
      });

    // move hover lines
    function show_hover_lines(x, y, price, date, ii) {
      hide_circles();
      let data_point_circles = document.querySelectorAll('.data_point_circle');
      data_point_circles[ii].style.display = 'block';
      if (is_hover_displayed == false) {
        is_hover_displayed = true;
        show_hover_data();
      }
      let h_line_g = document.getElementById('horizontal_line_g_hover');
      let v_line_g = document.getElementById('vertical_line_g_hover');
      let hover_marker_g = document.getElementById('hover_marker_g');
      let hover_text_marker_g = document.getElementById('hover_x_axis_g');
      h_line_g.setAttribute('transform', 'translate(0,' + y + ')');
      v_line_g.setAttribute('transform', 'translate(' + x + ',0)');
      hover_marker_g.setAttribute(
        'transform',
        'translate(' + (width - 65) + ',' + (y - 7) + ')'
      );
      let last_point_position = last_point_x_value - 80;
      let text_position;
      if (x - 40 > last_point_position) {
        text_position = last_point_position;
      } else if (x - 40 < 0) {
        text_position = 0;
      } else {
        text_position = x - 40;
      }
      hover_text_marker_g.setAttribute(
        'transform',
        'translate(' + text_position + ',' + height + ')'
      );
      const canadianCurrency = ticker.toLowerCase().startsWith('tse:')
        ? 'C'
        : '';
      document.getElementById('hover_marker_text_id').textContent =
        canadianCurrency + '$' + parseFloat(price).toFixed(2);
      document.getElementById('hover_x_axis_text_id').textContent = date;
    }

    function show_hover_data() {
      let hover_data = document.querySelectorAll('.hover_lines');
      for (var i = 0; i < hover_data.length; i++) {
        hover_data[i].style.display = 'block';
      }
    }

    function hide_hover_data() {
      is_hover_displayed = false;
      let hover_data = document.querySelectorAll('.hover_lines');
      for (var i = 0; i < hover_data.length; i++) {
        hover_data[i].style.display = 'none';
      }
    }

    function hide_circles() {
      let data_point_circles = document.querySelectorAll('.data_point_circle');
      for (var i = 0; i < data_point_circles.length; i++) {
        data_point_circles[i].style.display = 'none';
      }
    }
    // function for the x grid lines
    function make_x_axis() {
      return d3.svg
        .axis()
        .scale(x)
        .orient('bottom');
    }

    // function for the y grid lines
    function make_y_axis() {
      return d3.svg
        .axis()
        .scale(y1)
        .orient('left');
    }

    // Tweaks
    let x_axis_labels = document.querySelectorAll('.x text');
    for (var i = 0; i < x_axis_labels.length; i++) {
      let translate_val = x_axis_labels[i].parentNode
        .getAttribute('transform')
        .replace('translate(', '')
        .replace(',0)', '');
      if (translate_val > 574) {
        x_axis_labels[i].parentNode.style.display = 'none';
      }
      if (i == 0 && translate_val < 30) {
        x_axis_labels[i].setAttribute('x', '30');
      }
    }
  }

  // Click events for buttons

  var clear_data_active = function() {
    var classname = document.getElementsByClassName('data_select');
    for (var i = 0; i < classname.length; i++) {
      classname[i].className = 'data_select';
    }
  };
  var dataSelect = function() {
    clear_data_active();
    var attribute = this.getAttribute('data-id');
    this.className = 'data_select bottom_button_selected';
    active_data = data_arr['data_' + attribute];
    draw_area_chart(active_data);
  };
  var classname = document.getElementsByClassName('data_select');
  for (var i = 0; i < classname.length; i++) {
    classname[i].addEventListener('click', dataSelect);
  }
};
