import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { StockLink } from 'sp/browser/StockLink/StockLink';

export default class TickerCell extends Component {
  // // static propTypes = {
  // //   event: PropTypes.object
  // };

  render() {
    const { event } = this.props;
    const stock = event.get('stock');
    const hasLink = !!stock;

    return !hasLink ? (
      <span>{stock.get('ticker')}</span>
    ) : (
      <StockLink stock={stock} target="_blank">
        {stock.get('ticker')}
      </StockLink>
    );
  }
}
