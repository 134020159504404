export default {
  headerH1: 'Management Fees',
  tblCaption: 'Funds with highest fees',
  tblThName: 'Fund Name',
  tblThPercent: '% of Portfolio',
  tblThFees: 'Investment Fees',
  btn: 'Show Me How',
  toolTipBtn: 'i',
  toolTip: `ETFs and Mutual Funds charge management fees. Consequently,
    management fees reduce returns you otherwise would have earned. TipRanks
    suggests comparable funds that will maximize your returns by reducing
    overall fees.`
};
