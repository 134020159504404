import * as actions from './actions';

export const initialState = {};

const actionHandlers = {
  [actions.SET_CONTACT_INPUT](state, { type, value }) {
    return { ...state, [type]: value };
  },
  [actions.ON_CONTACT_ERROR](state) {
    return { ...state, error: true, loading: false };
  },
  [actions.CONTACT_FORM_SUBMITTED](state) {
    return { ...state, error: false, success: false, loading: true };
  },
  [actions.CONTACT_FORM_VALIDATION_FAILED](state, maybeError) {
    const { error = '' } = maybeError || {};

    return { ...state, error, success: false, loading: false };
  },
  [actions.POST_CONTACT_FORM_START](state) {
    return { ...state, error: false, success: false, loading: true };
  },
  [actions.POST_CONTACT_FORM_ERROR](state) {
    return { ...state, error: true, success: false, loading: false };
  },
  [actions.POST_CONTACT_FORM_SUCCESS](state) {
    return { ...state, error: false, success: true, loading: false };
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
