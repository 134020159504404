import { ComponentState } from '../../common/types';
import { createSelector } from 'reselect';
import { BetterRecord } from 'sp/common/immutableStuff';

export const selectIsDisabled = state => state.get('isDisabled');

export const selectIsDisabledExperts = state => state.get('isDisabledExperts');

export const selectSettings = state => state.get('settings');

export const selectIsWeeklyDigestEnabled = state => state.get('weeklyDigestEnabled');

export const selectExpectedExpertEmail = state =>
  state.get('expectedExpertEmails');

export const selectExpectedStockEmails = state =>
  state.get('expectedStockEmails');

export const selectNumSubscriptionToExperts = state =>
  state.get('numSubscriptionToExperts');

export const selectTotalAlertsPredictions = state =>
  state.get('totalAlertsPredictions');

export const selectComponentStates = (
  state: any
): BetterRecord<{ [key: string]: ComponentState }> =>
  state.get('componentStates');

export const selectComponentState = createSelector(
  selectComponentStates,
  state => state.get('alertsSettings')
);
