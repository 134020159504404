import React from 'react';
import Component from 'react-pure-render/component';
import Search from '../tr-dashboard-search';
import {
  imgSrc,
  popupLogin,
  popupRegister
} from '../../common/lib/utils.ts';
import MobileContainer from '../mobile-container/mobile-container.react';
import { selectIsPermanentLoggedIn } from '../../common/auth/selectors.ts';

import './header.styl';
import links from './links.tsx';
import AuthMenu from './auth-menu.react.tsx';
import HeaderMenu from './menu-items.react.tsx';
import { premiumUpgradeUrl } from '../lib/utils.ts';
import { PremiumButtonWithPromotion } from './PremiumButtonWithPromotion.tsx';
import { NotificationBell } from '../../browser/notifications/NotificationBell.tsx';
import { createNasdaqLink } from 'sp/browser/lib/utils';
import { shouldShowPremiumButton } from 'sp/browser/tr-dashboard-header/PremiumButton';
import { shouldShowUltimateButton, UltimateButton } from 'sp/browser/tr-dashboard-header/UltimateButton';
import { screenAndMinWidth } from 'sp/common/lib/mediaQuery';
import { getSpCurrentPageName } from '../tr-payment'

class DesktopApp extends Component {
  // static propTypes = {
  //   actions: PropTypes.object,
  //   children: PropTypes.any,
  //   theme: PropTypes.string
  // };

  onRegister = () => popupRegister();

  onLogin = () => popupLogin();

  onLogout = () => this.props.actions.logout();

  render() {
    const { actions, auth, autocomplete, theme, mediaQuery, location = {} } = this.props;
    const pageName = getSpCurrentPageName(location.pathname);
    return (
      <div className="app-desktop">
        <header className="app">
          <div className="main-header">
            <h1>
              <a
                href={process.env.THEME === 'nasdaq' ? createNasdaqLink() : '/'}
              >
                <img alt="" src={imgSrc(`${theme}-logo-header`)} />
              </a>
            </h1>
            {theme === 'web' && (
              <Search actions={actions} autocomplete={autocomplete} />
            )}
            {shouldShowPremiumButton(auth) && <PremiumButtonWithPromotion
              pageName={pageName}
              auth={auth}
              custom3Of="topbar"
              custom3Override="gopro-btn"
              mediaQuery={mediaQuery}
            />}
            {shouldShowUltimateButton(auth) && <UltimateButton
              pageName={pageName}
              auth={auth}
              custom3Of="topbar"
            />}
            {theme === 'web' && <HeaderMenu items={links.headerLinks} />}
            {process.env.THEME !== 'nasdaq' && <NotificationBell />}
            <AuthMenu
              pageName={pageName}
              premiumUpgradeUrl={premiumUpgradeUrl(auth, 'ultimate')}
              actions={this}
              isLoggedIn={selectIsPermanentLoggedIn(auth)}
            />
          </div>
        </header>
        {this.props.children}
      </div>
    );
  }
}

class MobileApp extends Component {
  // static propTypes = {
  //   children: PropTypes.any
  // };

  render() {
    return (
      <div className="app-mobile">
        <MobileContainer {...this.props}>{this.props.children}</MobileContainer>
      </div>
    );
  }
}

export default class NasdaqHeader extends Component {
  // static propTypes = {
  //   actions: PropTypes.object,
  //   autocomplete: PropTypes.object,
  //   auth: PropTypes.object,
  //   children: PropTypes.object,
  //   links: PropTypes.oneOfType([PropTypes.array, PropTypes.boolean]),
  //   mediaQuery: PropTypes.object,
  //   pathname: PropTypes.string,
  //   viewer: PropTypes.object
  // };

  renderMobile() {
    return <MobileApp {...this.props} />;
  }

  renderDesktop() {
    return (
      <DesktopApp
        {...this.props}
        auth={this.props.auth}
        actions={this.props.actions}
        autocomplete={this.props.autocomplete}
      >
        {this.props.children}
      </DesktopApp>
    );
  }

  render() {
    // TIPRANKS-16378
    // state doesnt seem to be correct with the right resolution
    // on load. rotating the screen solves it by firing the action.
    // below is a nice fat semi-hack
    // return this.props.mediaQuery.get('laptop') ?
    return screenAndMinWidth(64) ? this.renderDesktop() : this.renderMobile();
  }
}
