import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { img, noMobilePic } from './conf';
import { getBlueSnapPaymentUrl, getSpCurrentPageName } from '../tr-payment';
import { PlansLink } from '../components/PlansLink.tsx';
import { connect } from 'react-redux';
import { Cashier, getConcatenatedOrigin } from '@tipranks/checkout-link';

const reqImg = img => {
  if (process.env.IS_BROWSER) {
    try {
      return require(`./img/${img}`);
    } catch (e) {
      // Perhaps you forgot to make a nasdaq equivalent for the web version?
      console.error('No file named', img);
    }
  }
  return '';
};

@connect(({ auth }) => ({ auth }))
export default class TrDashboardPromotion extends Component {
  static propTypes = {
    clickedUpgrade: PropTypes.func,
    currentPlan: PropTypes.string,
    email: PropTypes.string,
    enums: PropTypes.object,
    isLaptop: PropTypes.object,
    isTablet: PropTypes.bool,
    msg: PropTypes.object,
    name: PropTypes.string,
    plan: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    isTablet: false,
  };

  get featureName() {
    const type = `${this.props.type}` || 'unknown-popup';
    const normalizedType = type.startsWith('sp-') ? type : `sp-${type}`
    return normalizedType
  }
  get custom2(): Cashier {
    return this.props.plan === 'ultimate' ? 'popup-ultimate' : 'popup-premium';
  }

  paymentUrl = () => {
    const { currentPlan, plan, theme, auth } = this.props;

    const isUpgrade = currentPlan === 'premium' && plan === 'ultimate';

    return getBlueSnapPaymentUrl(
      auth,
      // TODO this seems like a thing that needs to be inside of getBlueSnapPaymentUrl
      theme === 'nasdaq' ? 'nasdaqMonthly' : plan,
      isUpgrade,
      {
        custom2: this.custom2,
        custom3: this.featureName,
      },
      {
        cashier: this.custom2,
        featureOrOrigin: this.featureName,
        pageName: getSpCurrentPageName(),
      }
    );
  };

  headerH1FontSize = () => {
    const { isTablet, type } = this.props;

    return {
      mgmtFees: `${isTablet ? 36 : 32}px`,
    }[type];
  };

  imgByType = () => {
    const { isTablet, isLaptop, theme, type } = this.props;

    if (!isTablet && noMobilePic.includes(type)) return null;

    const screen = (() => {
      if (isLaptop) return '-laptop';
      if (isTablet) return '-tablet';
      return '';
    })();

    const src = `${img[type] || 1}${screen}-${theme}.png`;

    return (
      <div className="img-type-wrapper">
        <img alt="" src={reqImg(src)} />
      </div>
    );
  };

  render() {
    const {
      clickedUpgrade,
      currentPlan,
      enums,
      isTablet,
      msg,
      plan,
      theme,
      type,
    } = this.props;

    const pricesForPlan = msg.price[currentPlan] || msg.price.default;
    const isUpgrade = currentPlan === 'premium' && plan === 'ultimate';

    return (
      <section
        className={classNames(
          'tr-dashboard-promotion',
          `promotion-${plan}`,
          'hasTypeImg',
          `promotion-type-${type}`,
          `promotion-theme-${theme}`,
        )}
      >
        <header>
          <h1
            style={{
              fontSize: this.headerH1FontSize(),
              lineHeight: this.headerH1FontSize(),
            }}
          >
            {msg.title[type]}
          </h1>
          {isTablet && <h2>{msg.subTitle[type]}</h2>}
        </header>
        <div className="middle-section">
          <div className="middle-section-content-wrapper">
            <div className="middle-section-content-wrapper-inner">
              <div className="middle-section-content">
                <div className="price-box">
                  <div className="plan-name">
                    {theme === 'web' ? (
                      <FormattedMessage
                        defaultMessage={msg.planName}
                        id={msg.planName}
                        values={{
                          plan: enums.plans[plan],
                        }}
                      />
                    ) : (
                        <FormattedMessage
                          defaultMessage={msg.themePlan[theme]}
                          id={msg.themePlan[theme]}
                        />
                      )}
                  </div>
                  <div className="plan-desc">{msg.planDesc}</div>
                  <div className="price-per-month">
                    <div className="price">
                      <div>
                        {isUpgrade && <span>{msg.addOnly}</span>}
                        &nbsp;
                        <span>
                          ${theme === 'web'
                            ? pricesForPlan[plan]
                            : msg.pricesForTheme[theme]}
                        </span>
                        <span className="period">
                          /
                          {msg.period}
                        </span>
                      </div>
                      <div className="charged">{msg.charged}</div>
                    </div>
                  </div>
                  <a
                    className={`subscribe ${plan}`}
                    href={this.paymentUrl()}
                    onClick={clickedUpgrade('subscribe-now')}
                    target="'_blank'"
                  >
                    {msg.subscribe}
                  </a>
                </div>
              </div>
              <div className="or">
                <div className="content">{msg.or}</div>
              </div>
              <p className="see-plans">
                <FormattedMessage
                  defaultMessage={
                    theme === 'web' ? msg.seeAll : msg.seeAllByTheme[theme]
                  }
                  id={theme === 'web' ? msg.seeAll : msg.seeAllByTheme[theme]}
                  values={{
                    plansLink: (
                      <PlansLink
                        onClick={clickedUpgrade('plans-and-pricing')}
                        originOrFeature={this.featureName}
                        queryParameters={{ origin: this.custom2 + getConcatenatedOrigin() }}
                      >
                        {msg.plansLink}
                      </PlansLink>
                    ),
                    plan: theme === 'web' ? enums.plans[plan] : 'Pro',
                  }}
                />
              </p>
            </div>
          </div>
          {isTablet && this.imgByType()}
        </div>
        {!isTablet && this.imgByType()}
      </section>
    );
  }
}
