import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Component from 'react-pure-render/component';

import Editable from '../Editable';
import { imgSrc } from '../../common/lib/utils.ts';
import AddPortfolioForm from '../AddPortfolioForm';
import { disablePortfolioImport } from '../../common/config';

import { syncSmartPortfolioWithPlaid, PlaidOAuthTransitionPopUp } from '../tr-dashboard-welcome-2/SmartPortfolioPlaidUtils';
import { identifyUser } from '../../common/api'
import VariableSizeSpinner from '../Spinner';

const portfolioControls = (deletePortfolio, editPortfolio) =>
  [
    editPortfolio && {
      action: editPortfolio,
      id: 'edit',
      label: <img src={imgSrc('editBigger')} />
    },
    deletePortfolio && {
      action: deletePortfolio,
      id: 'delete',
      label: <img src={imgSrc('delete')} />
    }
  ].filter(Boolean);

const doneEditing = (
  portfolio,
  applyPortfolioName,
  changeEditablePortfolioId
) => val => {
  applyPortfolioName(portfolio.get('id'), val);
};

export default class Portfolios extends Component<
  {},{ checkedId: string, expertId: number, isLoadingPlaid: boolean }
  > 
{
  state = { checkedId: 'search-radio', expertId: 0, isLoadingPlaid: false };
  static propTypes = {
    addPortfolio: PropTypes.func,
    applyPortfolioName: PropTypes.func,
    changeEditablePortfolioId: PropTypes.func,
    changeEditablePortfolioValue: PropTypes.func,
    changePortfolio: PropTypes.func,
    close: PropTypes.func,
    deletePortfolio: PropTypes.func,
    editablePortfolioId: PropTypes.number,
    editableValue: PropTypes.string,
    isLimited: PropTypes.bool,
    isUltimate: PropTypes.bool,
    msg: PropTypes.object,
    openSyncDialog: PropTypes.func,
    portfolios: PropTypes.object,
    showPromotion: PropTypes.func,
    state: PropTypes.any
  };

  componentDidMoint() {
    if(this.props.auth) {
      const expertIdFromStore
        = this.props.auth
          .get('users')
          .get('viewer')
          .get('loggedInUser')
          .get('expertId');
      if (expertIdFromStore) {
        this.setState({ expertId: expertIdFromStore })
      }
    }
  }

  onSyncClick = () => {
    const expertId = this.state.expertId
    this.setState({ isLoadingPlaid: true })
    const { fetchPortfolios, changePortfolio, close, navigationOnDone } = this.props;
    syncSmartPortfolioWithPlaid(expertId, {
      onSyncEnd: async res => {
        const { PortfolioIds } = res;
        await identifyUser({ shouldRefresh: true })
        if (typeof fetchPortfolios === 'function') {
          /* const fetchedPortfolios = */await fetchPortfolios();
        }
        if (Array.isArray(PortfolioIds) && PortfolioIds[0] && typeof changePortfolio === 'function') {
          changePortfolio(PortfolioIds[0])
        }
        if (typeof close === 'function') {
          close()
        }
        if (typeof navigationOnDone === 'function') {
          navigationOnDone()
        }
        this.setState({ isLoadingPlaid: false });
      },
      onSyncFailed: () => {
        this.setState({ isLoadingPlaid: false })
      },
      onSyncStopped: () => {
        this.setState({ isLoadingPlaid: false })
      },
    })
  }

  render() {
    const {
      addPortfolio,
      applyPortfolioName,
      changeEditablePortfolioId,
      changeEditablePortfolioValue,
      close,
      deletePortfolio,
      editablePortfolioId,
      editableValue,
      isLimited,
      isUltimate,
      msg,
      openSyncDialog,
      portfolios,
      showPromotion,
      state
    } = this.props;
    const { isLoadingPlaid } = this.state;

    const isEditable = portfolioId => portfolioId === editablePortfolioId;

    const renameLoading = state.get('renamePortfolio') === 'LOADING';

    return (
      <section className="portfolios">
        <header>
          <h1>{msg.title}</h1>
        </header>
        <div className="inner-contnet">
          <AddPortfolioForm
            addPortfolio={addPortfolio}
            isLimited={isLimited}
            isUltimate={isUltimate}
            placeHolder={msg.placeHolder}
            shouldRedirect
            showPromotion={showPromotion}
            state={state.get('addPortfolio')}
          />
          <ul className="portfolio-list">
            {portfolios.map(portfolio => (
              <li
                className={classNames({
                  editable: isEditable(portfolio.get('id')),
                  loading: renameLoading
                })}
                key={portfolio.get('id')}
                onClick={this.close(portfolio.get('id'))}
              >
                <ul
                  className="portfolio-controls"
                  onClick={event => event.stopPropagation()}
                >
                  {portfolioControls(
                    portfolios.size > 1 && deletePortfolio,
                    () => changeEditablePortfolioId(portfolio.get('id'))
                  )
                    .filter(
                      control =>
                        !(control.id === 'edit' && portfolio.get('isImported'))
                    )
                    .map(control => (
                      <li key={control.id}>
                        <button
                          onClick={() =>
                            editablePortfolioId < 0
                              ? control.action(portfolio.get('id'))
                              : null
                          }
                        >
                          {control.label}
                        </button>
                      </li>
                    ))}
                </ul>
                <div className={classNames('portfolio-name')}>
                  <Editable
                    doneEditing={doneEditing(
                      portfolio,
                      applyPortfolioName,
                      changeEditablePortfolioId
                    )}
                    editableValue={editableValue}
                    freez={renameLoading || portfolio.get('isImported')}
                    isActiveMode={isEditable(portfolio.get('id'))}
                    onChange={changeEditablePortfolioValue}
                    type="text"
                    value={portfolio.get('name')}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        {!disablePortfolioImport && <div className="sync">
          {isLoadingPlaid ? (<React.Fragment>
            <span className={'edit-portfolios-connect-msg'}>
              Connecting to your existing portfolio, please wait...
              <VariableSizeSpinner className={'edit-portfolios-connect-spinner'} size={15} />
            </span>
            <PlaidOAuthTransitionPopUp />
          </React.Fragment>
            ) : (
              <FormattedMessage
                defaultMessage={msg.sync}
                id={msg.sync}
                values={{
                  syncLink: <button onClick={this.onSyncClick}>{msg.syncLink}</button>
                }}
              />
            )
          }
        </div>}
        <div className="controls">
          <button onClick={close}>{msg.ok}</button>
        </div>
      </section>
    );
  }

  close = portfolioId => ({ target }) => {
    const { changePortfolio, close } = this.props;
    if (target.tagName !== 'INPUT') {
      changePortfolio(portfolioId);
      close();
    }
  };
}
