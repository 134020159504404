import { fromJS, List, Record } from 'immutable';
import { createRecord } from 'sp/common/records';
import { BetterRecord } from 'sp/common/immutableStuff';

export type AlternativesType_NotImmutable = {
  best: any,
}
export type AlternativesType = BetterRecord<AlternativesType_NotImmutable>;
const Alternatives = createRecord<AlternativesType, AlternativesType_NotImmutable>({
  best: null
});

export type ColType_NotImmutable = {
  id: any//null,
  isActionable: boolean,
  isSortable: boolean,
  alternatives: any//null,
  hasLoader: boolean,
  minPlan: any//'open',
  missingShareIndication: boolean,
  noTitle: boolean,
  doesNeedFakeLoader: boolean
}
export type ColType = BetterRecord<ColType_NotImmutable>;

export const Col = createRecord<ColType, ColType_NotImmutable>({
  id: null,
  isActionable: false,
  isSortable: true,
  alternatives: null,
  hasLoader: false,
  minPlan: 'open',
  missingShareIndication: false,
  noTitle: false,
  doesNeedFakeLoader: false
});

export const colDefinitions = {
  name: new Col({ id: 'name', isActionable: true }),
  price: new Col({ id: 'price', hasLoader: true }),
  totalAssets: new Col({ id: 'totalAssets' }),
  '1yrReturn': new Col({ id: '1yrReturn' }),
  '3yrReturn': new Col({ id: '3yrReturn' }),
  '5yrReturn': new Col({ id: '5yrReturn' }),
  description: new Col({ id: 'description' }),
  etfType: new Col({ id: 'etfType' }),
  issuer: new Col({ id: 'issuer' }),
  investmentStyle: new Col({ id: 'investmentStyle' }),
  managementFee: new Col({ id: 'managementFee', minPlan: 'premium' }),
  value: new Col({ id: 'value', hasLoader: true, isActionable: true }),
  analystConsensus: new Col({
    id: 'analystConsensus',
    alternatives: new Alternatives({ best: 'bestAnalystConsensus' }),
    isActionable: true
  }),
  target: new Col({
    id: 'target',
    alternatives: new Alternatives({ best: 'bestTarget' })
  }),
  bestTarget: new Col({ id: 'bestTarget', minPlan: 'premium' }),
  sincePurchaseGain: new Col({ id: 'sincePurchaseGain', hasLoader: true }),
  alerts: new Col({ id: 'alerts', isActionable: true }),
  prices: new Col({ id: 'prices', noTitle: true, doesNeedFakeLoader: true }),
  shares: new Col({ id: 'shares', missingShareIndication: true }),
  purchasePrice: new Col({ id: 'purchasePrice' }),
  added: new Col({ id: 'added' }),
  gainSinceAdded: new Col({ id: 'gainSinceAdded' }),
  addedPrice: new Col({ id: 'addedPrice' }),
  percent: new Col({ id: 'percent' }),
  highLow: new Col({ id: 'highLow' }),
  cap: new Col({ id: 'cap', hasLoader: true }),
  category: new Col({ id: 'category' }),
  earningsReport: new Col({ id: 'earningsReport' }),
  pe: new Col({ id: 'pe' }),
  dividend: new Col({ id: 'dividend' }),
  beta: new Col({ id: 'beta' }),
  newsSentimentScore: new Col({ id: 'newsSentimentScore' }),
  bestAnalystConsensus: new Col({ id: 'bestAnalystConsensus', minPlan: 'premium' }),
  newsSentiment: new Col({ id: 'newsSentiment', minPlan: 'premium' }),
  hedgefundSentiment: new Col({ id: 'hedgefundSentiment', minPlan: 'premium' }),
  insiderSentiment: new Col({ id: 'insiderSentiment', minPlan: 'premium' }),
  bloggerSentiment: new Col({ id: 'bloggerSentiment', minPlan: 'premium' }),
  sector: new Col({ id: 'sector' }),
  marketCap: new Col({ id: 'marketCap', hasLoader: true }),
  yield: new Col({ id: 'yield' }),
};
export type ColId = keyof typeof colDefinitions;

export const getColById = (colId: ColId) => colDefinitions[colId];
