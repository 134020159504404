import PropTypes from 'prop-types';
import * as React from 'react';
import msg from './en';
import { Map } from 'immutable';
import { imgSrc } from 'sp/browser/lib/utils';
import Component from 'react-pure-render/component';

const style = {
  type: (type, isExample) => ({
    display: 'block',
    marginTop: type === 'insider' && !isExample ? '2px' : '3px'
  }),
  img: {
    marginRight: '3px',

    verticalAlign: '-2px'
  }
};

class PersonType extends Component {
  render({ isExample, person } = this.props) {
    return (
      <span style={style.type(person.get('type'), isExample)}>
        <img style={style.img} src={imgSrc(person, 'type')} />
        {msg.personTypes[person.get('type')]}
      </span>
    );
  }
}

PersonType.propTypes = {
  isExample: PropTypes.bool,
  person: PropTypes.object
};

export default PersonType;

export let __hotReload = true;
