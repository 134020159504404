import { DashboardStock } from '../../common/types';
import * as classNames from 'classnames';
import { fromJS, List } from 'immutable';
import * as React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import Component from 'react-pure-render/component';
import { Link } from 'react-router';
import { Tooltip } from '../../browser/news-analysis/Tooltip/Tooltip';
import GlossaryLink from '../GlossaryLink/index';
import { SuccessRate } from '../success-rate/SuccessRate';
import msg from './en';
import localMsg from './msg';
import StockChart from './StockChart/StockChart';
import * as styles from './table.istyl';
import { descendtify } from '../../common/lib/sort';
import { StockLink } from 'sp/browser/StockLink/StockLink';
import {
  HolidingOperationRecord,
  PerfStock,
  PerfStockNotRecord,
  AssembledPerfStock,
} from 'sp/common/components/performance/records';
import { WeightedHolding } from 'sp/browser/dashboard/PortfolioManager';
import { HoldingOperation, PerfByTickerOperations } from 'sp/common/api-types';
import * as _ from 'lodash';
import { PriceFetchzChild, createPriceFetchz } from 'sp/common/components/PriceFetchz/PriceFetchz';
import { betterToFixed } from 'sp/common/lib/utils';
import { MediaQuery } from 'sp/common/lib/mediaQuery';
import { BetterRecord } from 'sp/common/immutableStuff';

const stylusVariables = require(`../style-helpers/themes/${process.env
  .THEME}.json`);
const colorPositive = stylusVariables['$colorPositive'];
const colorNegative = stylusVariables['$colorNegative'];

const analyst = {
  firm: '',
  location: '',
  id: '',
  name: '',
  photoId: '',
  rank: 0,
  rankOverall: 0,
  rankSector: 0,
  ratingsTotal: 0,
  portfolioValue: 0,
  followingTotal: 0,
  ratingsGoodTotal: 0,
  returnAvg: 0.199,
  operationsDistribution: [],
  role: '',
  type: 'analyst',
  urlName: '',
  instanceOf: ['person', 'analyst'],
  sector: 'technology',
};

type Analyst = typeof analyst;

const stockLimit = 9;

const strategies = {
  // name: sortAlphabeticallyIn(['ticker']),
  // target: sortNumericallyIn(['target']),
  // rating: sortByListIndexIn(
  //   ['lastRating', 'rating'],
  //   ['buy', 'hold', 'sell']
  // ),
  // action: sortAlphabeticallyIn(['lastRating', 'actionId']),
  // date: sortByDateIn(['lastRating', 'ratingDate']),
  // ratingsTotal: sortNumericallyIn(['ratingsTotalWithHold'])
};

const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  return uri.match(re)
    ? uri.replace(re, `$1${key}=${value}$2`)
    : `${uri}${separator}${key}=${value}`;
};

const appRoute = str => '/' + str;

const analystOnStockHref = (analyst: Analyst, stock: PerfStockNotRecord) =>
  updateQueryStringParameter(
    appRoute(analyst),
    'isTraded',
    (stock as any).isTraded, // TODO figure this out
  );

const noop = () => null;

export type StockGain = { ticker: string; gain: number };

const sortableCols = [
  'name',
  'ratings',
  'rating',
  'target',
  'ratingsTotal',
  'action',
  'date',
  // 'gainSinceAdded'
];

export type AnalystStockCoverageProps = {
  portfolioItemsCount: number;
  spStocks: PerfStockNotRecord[];
  stocksLength: number;
  tickerClicked?: any; //func
  activeStockTicker?: string;
  mediaQuery: MediaQuery;
  weightedHoldings: WeightedHolding[];
  holdingOperations: HoldingOperation[];
  portfolioItemsGains: StockGain[];
  totalTransactions: number;
  allStocksClicked?: any; //func
  // coverageTableStockClicked?: any; //func
  coverageTableThClicked?: any; //func
  fetchStockData?: any; //func
  filtersChanged?: any; //func
  promoteAnalystOnStock?: any; //func
  timelineArticleClicked?: any; //func
  isPublicPortfolio: boolean;
  analyst?: BetterRecord<{
    firm: string;
    location: string;
    id: string;
    name: string;
    photoId: string;
    rank: number;
    rankOverall: number;
    rankSector: number;
    ratingsTotal: number;
    portfolioValue: number;
    followingTotal: number;
    ratingsGoodTotal: number;
    returnAvg: number;
    operationsDistribution: any[];
    role: string;
    type: string;
    urlName: string;
    instanceOf: string[];
    sector: string;
  }>;
  filters?: any;
  isHd?: boolean;
  isLaptopUri?: boolean;
  isTablet?: boolean;
  msg?: any;
}
export interface AnalystStockCoverageState {
  activeStock: string | null,
  activeCol: string,
  descend: boolean,
  isAllRatings: boolean,
}
export class AnalystStockCoverageComponent extends PriceFetchzChild<AnalystStockCoverageProps, AnalystStockCoverageState> {
  state = {
    activeStock: '',
    activeCol: 'date',
    descend: true,
    isAllRatings: true,
  };

  get type() {
    return (this.props.analyst as any).type;
  }

  static defaultProps = {
    allStocksClicked: noop,
    coverageTableThClicked: noop,
    tickerClicked: noop,
    // coverageTableStockClicked: noop,
    analyst,
    fetchStockData: noop,
    filters: {},
    filtersChanged: noop,
    isHd: true,
    isLaptopUri: false,
    isTablet: false,
    msg: msg.analystStockCoverage,
    promoteAnalystOnStock: noop,
    stocks: [],
    activeStockTicker: '',
    timelineArticleClicked: noop,
  };

  render() {
    const {
      isHd,
      isTablet,
      msg,
      spStocks,
      mediaQuery,
      portfolioItemsCount,
      totalTransactions,
      stocksLength,
    } = this.props;

    const { isAllRatings, activeCol, descend } = this.state;

    const isBlogger = this.type === 'blogger';

    const stocksCols = [
      true && 'company',
      // (is1366) && 'price',
      mediaQuery.get('1024') && 'lastTransaction',
      mediaQuery.get('1024') && 'totalTransactions',
      mediaQuery.get('768') && 'percentOfPortfolio',
      true && 'status',
      true && 'gainSinceAdded',
      // (true) && 'gain',
    ].filter(Boolean) as string[];

    const areThereMore = !isAllRatings && stocksLength > stockLimit;
    const thRealClassName = col => styles[`thRealInner-${col}`];
    const maybeLimit = stocks =>
      areThereMore
        ? stocks // stocks.take(stockLimit)
        : stocks;
    const tableBody = maybeLimit(spStocks)
      /* .sort(this.sortBy())*/
      .sort(
        (a, b) =>
          new Date(b.lastTransaction).getTime() -
          new Date(a.lastTransaction).getTime()
      ).map((stock, stockIndex) =>
        this.maybeAnalystOnStockTimeline(
          stock,
          [
            this.row(
              stock,
              stockIndex,
              stocksCols,
              stockIndex,
              areThereMore,
            ),
          ],
          stocksCols,
        ),
    );

    return (
      <section className={styles.pageSection}>
        <div className={styles.titleHolder}>
          <header className={'pageSectionHeaderCoverage'}>
            <h1 className={'pageSectionH1 pageSectionH1StockInfo'}>
              {msg.title}
            </h1>
            <div className={`${styles.headerTooltip}`}>
              <Tooltip
                overrideWithTheme="web"
                content={localMsg.tooltips.recentActivity}
              />
            </div>
          </header>
        </div>
        {stocksLength === 0 ? (
          this.renderNoData(portfolioItemsCount, totalTransactions)
        ) : (
            <main
              className={`${styles.pageSectionMainCoverage} ${styles.paddingBottom} shadow-box`}
            >
              <table className={styles.table}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {stocksCols.map((col, index) => {
                      const colName = msg[`stockCols.${col}`]; // this is here because it destroys my editor if its inline.
                      return (
                        <th
                          className={`${styles.th} ${styles.col} ${styles[
                            'col-' + col
                          ]}`}
                          key={`_${col}`}
                        >
                          <div
                            className={classNames(
                              styles.thInner,
                              styles['thInner-' + col],
                            )}
                          >
                            <div>
                              {sortableCols.includes(col) ? (
                                <button
                                  className={classNames(
                                    styles.thBtn,
                                    styles.sortBtn,
                                    styles[`sortBtn-${col}`],
                                    {
                                      [styles.activeCol]: activeCol === col,
                                      [styles.activeColDescend]:
                                        activeCol === col && descend,
                                    },
                                  )}
                                  onClick={this.sortStocks(col)}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{ __html: colName }}
                                  />
                                </button>
                              ) : (
                                  <div
                                    className={classNames(
                                      styles.thBtn,
                                      thRealClassName(col),
                                    )}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{ __html: colName }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableBody}
                </tbody>
                {areThereMore && (
                  <tfoot>
                    <tr className={styles.tr}>
                      <td colSpan={stocksCols.length}>
                        <button
                          className={styles.seeAllRatingsBtnTfoot}
                          onClick={this.unlimitStocks}
                        >
                          {msg.seeAllRatingsBtnTfoot}
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </main>
          )}
      </section>
    );
  }

  row = (stock: AssembledPerfStock, index, stocksCols, stockIndex, areThereMore) => {
    const { spStocks, stocksLength } = this.props;
    const { activeStock } = this.state;
    const isActive = activeStock === stock.ticker;
    const smallRowContent = !this.props.mediaQuery.get('1024');
    const rowContent = [
      <tr
        className={`${styles.dataRow} ${areThereMore && stockLimit <= index
          ? styles.rowHidden
          : 0}`}
        itemScope
        onClick={this.activateStock(stock)}
        key={stock.ticker}
        itemType="https://schema.org/Corporation"
      >
        {stocksCols.map((col, colIndex, cols) => (
          <td
            className={classNames(
              `${styles.td} ${styles.col} ${styles['col-' + col]}`,
              {
                [styles.tdActive]: isActive,
                [styles.tdFirstChild]: stockIndex === 0, // REALLY?!????
                [styles.tdNotLastChild]:
                  !smallRowContent && stockIndex !== stocksLength - 1, // REALLY?!????
              },
            )}
            key={`_${col}`}
          >
            <div
              className={classNames(styles.tdInner, styles['tdInner-' + col], {
                [styles.tdInnerActive]: isActive,
                [styles.notFirstTdInner]: stockIndex !== stocksLength - 1,
                [styles.lastTdInner]: stockIndex === stocksLength - 1,
                [styles.lastTdInnerInRow]: colIndex === cols.length - 1,
                [styles.firstTdInnerInRow]: colIndex === 0,
              })}
            >
              <div className={styles[col]}>{this.cell(stock, col)}</div>
            </div>
          </td>
        ))}
      </tr>,
    ];
    if (smallRowContent) {
      rowContent.push(
        <tr
          className={`${areThereMore && stockLimit <= index
            ? styles.rowHidden
            : 0}`}
        >
          <td
            colSpan={stocksCols.length}
            className={classNames(styles.td, styles.smallWindowTdRow, {
              [styles.tdActive]: isActive,
              [styles.tdNotLastChild]: stockIndex !== stocksLength - 1,
            })}
          >
            <div
              className={classNames(styles.smallWindowTdWrapper, {
                [styles.smallWindowTdWrapperActive]: isActive,
              })}
            >
              <div className={styles.smallWindowTdContent}>
                Total of <b>{stock.totalTransactions}</b> transactions,
                last transaction on{' '}
                <b>
                  <FormattedDate
                    day="numeric"
                    month="short"
                    year="numeric"
                    value={stock.lastTransaction}
                  />
                </b>
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    return rowContent;
  };

  renderNoData(portfolioItemsCount: number, numTransactions: number) {
    if (this.props.isPublicPortfolio) {
      return (
        <div className={styles.noDataRecentActivity}>
          <p>The performance of this Public Portfolio has not been measured yet.</p>
          <p>Please come back later to see the updated analysis.</p>
        </div>
      );
    }
    // can't do anything meaningful with numTransactions here
    if (portfolioItemsCount < 2) {
      return (
        <div className={styles.noDataRecentActivity}>
          <p>
            {' '}
            Please add more holdings in order to have your performance measured.{' '}
          </p>
          <p>
            {' '}
            After you add stocks, please allow 24 hours for analysis to happen.{' '}
          </p>
          <p>
            <Link to={'/smart-portfolio/holdings/holdings'}>
              Add holdings to your portfolio >
            </Link>
          </p>
        </div>
      );
    } else if (portfolioItemsCount >= 2) {
      return (
        <div className={styles.noDataRecentActivity}>
          <p> Our team is measuring your performance. </p>
          <p> Your investment metrics will appear within 24 hours. </p>
          <p>
            <GlossaryLink
              slug="how-tipranks-measures-user-performance"
              forceInternal
            >
              Learn how your performance is measured >
            </GlossaryLink>
          </p>
        </div>
      );
    } else {
      return (
        <div className={styles.noDataRecentActivity}>
          <p>
            Please add holdings to your portfolio to see your measured
            performance{' '}
          </p>
          <p>
            <Link to={'/smart-portfolio/holdings/holdings'}>
              Add holdings to your portfolio >
            </Link>
          </p>
        </div>
      );
    }
  }

  componentWillReceiveProps(props) {
    if (props.isTablet) {
      this.setState({ activeStock: props.activeStockTicker });
    }
  }

  activateStock = (stock: AssembledPerfStock) => {
    const { analyst /*, coverageTableStockClicked*/, isTablet } = this.props;

    return (event) => {
      // coverageTableStockClicked(stock);
      const ticker = stock.ticker;
      const { fetchStockData } = this.props;
      const { activeStock } = this.state;
      this.setState({ activeStock: activeStock === ticker ? null : ticker });
      // fetchStockData(ticker);
    };
  };

  successRateVal = (stock: AssembledPerfStock) => {
    const totalTransactions = stock.transactions.length;
    const goodTransactions = stock
      .transactions
      .reduce((prev, curr) => prev + (curr.gainPercent >= 0 ? 1 : 0), 0);
    const result =
      totalTransactions === 0 ? 0 : goodTransactions / totalTransactions;
    return result;
  };

  calcDesc = (stock: AssembledPerfStock) => {
    const { analyst, filtersChanged, filters, msg, spStocks } = this.props;

    return (
      <div className={styles.calcDesc}>
        <div className={styles.calcDescInnerWrapper}>
          {/* <CalcDesc
            analyst={analyst}
            filtersChanged={filtersChanged}
            filters={filters}
            msg={msg.calcDesc}
            returnAvgVal={stock.get('returnAvg')}
            stocks={stocks}
            successRateVal={this.successRateVal(stock)}
            ticker={stock.get('ticker')} />*/}
        </div>
      </div>
    );
  };

  cell = (stock: AssembledPerfStock, col) => {
    const {
      analyst,
      msg,
      tickerClicked,
      spStocks,
      holdingOperations,
      weightedHoldings,
      portfolioItemsGains,
    } = this.props;
    const { activeStock } = this.state;
    // console.log(stock);
    const isActive = activeStock === stock.ticker;

    const weightedHolding = weightedHoldings.find(
      x => x.ticker.toLowerCase() === stock.ticker.toLowerCase(),
    );
    const holdingOperation = holdingOperations.find(
      x => x.ticker.toLowerCase() === stock.ticker.toLowerCase(),
    );

    const isActivelyTraded = holdingOperation && holdingOperation.operations && holdingOperation.operations.length > 0 ? 
                                holdingOperation.operations[0].isActivelyTraded : true;

    switch (col) {
      case 'company': {
        return (
          <div className={styles.tickerWrapper}>
            <div className={styles.ticker}>
              <StockLink ticker={stock.ticker} type={stock.type || stock.stockTypeId} isActive={isActivelyTraded} />
            </div>
            <div className={styles.name}>
              <span>{stock.name}</span>
            </div>
          </div>
        );
      }

      case 'price': {
        const details = stock.priceDetails;
        const price = details.price;
        const changeAmount = details.changeAmount || 0;
        const changePercent = (details.changePercent || 0) / 100;
        return (
          <div>
            <div className={styles.priceValue}>
              {stock
                .ticker
                .toLowerCase()
                .startsWith('tse:') && 'C'}${price}
            </div>
            <div
              className={classNames(styles.change, {
                positive: changeAmount >= 0,
                negative: changeAmount < 0,
              })}
            >
              {changeAmount >= 0 ? '▲' : '▼'}
              <span className={styles.changeAmount}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={changeAmount}
                />
              </span>{' '}
              (<span className={styles.changePercent}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  style="percent"
                  value={changePercent}
                />
              </span>)
            </div>
          </div>
        );
      }

      case 'lastTransaction': {
        const lastDate = stock.lastTransaction;
        if (lastDate === null) {
          return <span />;
        }
        return (
          <FormattedDate
            day="numeric"
            month="short"
            value={lastDate}
            year="numeric"
          />
        );
      }

      case 'totalTransactions': {
        if (holdingOperation) {
          return <span>{holdingOperation.operations.length}</span>;
        }
        return (
          <span>
            {stock.totalTransactions}
          </span>
        );
      }

      case 'percentOfPortfolio': {
        const isOpen = stock.isOpen;
        if (!isOpen || !weightedHolding || !weightedHolding.percent) {
          return <span>-</span>;
        }

        return betterToFixed(weightedHolding.percent * 100) + '%';
      }

      case 'status': {
        const isOpen = stock.isOpen;
        return (
          <span
            className={classNames({
              positive: isOpen,
              negative: !isOpen,
            })}
          >
            [ {isOpen ? 'Open' : 'Closed'} ]
          </span>
        );
      }

      case 'gainSinceAdded': {
        const gain = stock.gainSinceAdded;

        return (
          <span
            className={classNames({
              positive: gain >= 0,
              negative: gain < 0,
            })}
          >
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              style="percent"
              value={gain}
            />
          </span>
        );
      }

      case 'gain': {
        const itemGain = portfolioItemsGains.find(
          x => x.ticker.toLowerCase() === stock.ticker.toLowerCase(),
        );
        if (itemGain) {
          return (
            <span
              className={classNames({
                positive: itemGain.gain >= 0,
                negative: itemGain.gain < 0,
              })}
            >
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                style="percent"
                value={itemGain.gain}
              />
            </span>
          );
        }
        return <span>-</span>;
      }

      default: {
        return stock['col'];
      }
    }
  };


  successRate = (stock: AssembledPerfStock) => {
    return (
      <SuccessRate
        animated={false}
        noBg
        radius={35}
        width={6}
        fontSize={18}
        rate={this.successRateVal(stock)}
        style={{
          positive: [colorPositive, colorPositive],
          negative: [colorNegative, colorNegative],
          text:
            this.successRateVal(stock) < 0.5 ? colorNegative : colorPositive,
          empty: '#898989',
        }}
      />
    );
  };

  avgReturn = (stock: AssembledPerfStock) => {
    const transactions = stock.transactions;
    const totalGain = transactions.reduce(
      (a, x) => a + x.gainPercent,
      0,
    );
    const gain = totalGain / stock.totalTransactions;
    const sentimentClass = gain <= 0 ? 'negative' : 'positive';
    return (
      <div className={`${styles.analystFigureValue} ${sentimentClass}`}>
        {gain > 0 && '+'}
        {(gain * 100).toFixed(2)}%
      </div>
    );
  };

  analystOnStockTimeline = (stock: AssembledPerfStock) => {
    const { holdingOperations } = this.props;
    // TODO make sure this actually can't be null
    const stockOperations = holdingOperations.find(x => x.ticker === stock.ticker)!;

    let isLoading = false;
    let stockPrice = stock.priceDetails && stock.priceDetails.price
    let stockChangeAmount = stock.priceDetails && stock.priceDetails.changeAmount
    let stockChangePercent = stock.priceDetails && stock.priceDetails.changePercent
    // TODO this part came out badly, fix it!
    if (!stockPrice || !stockChangeAmount || stockChangePercent) {
      isLoading = this.isLoadingPrices;
      stockPrice = this.price(stock.ticker);
      stockChangeAmount = this.change(stock.ticker);
      stockChangePercent = this.changePercent(stock.ticker);
    }

    return (
      <div className="stock-graph">
        <StockChart
          priceRequest={this.props.priceRequest}
          stock={stock}
          isLoading={isLoading}
          mediaQuery={this.props.mediaQuery}
          holdingOperations={stockOperations}
          stockPrice={stockPrice}
          stockChangeAmount={stockChangeAmount}
          stockChangePercent={stockChangePercent}
        />
      </div>
    );
  };

  maybeAnalystOnStockTimeline = (stock: AssembledPerfStock, rows: any[], stocksCols: any[]) => {
    const { msg } = this.props;
    const { activeStock } = this.state;
    const good = stock
      .transactions
      .reduce((prev, curr) => prev + (curr.gainPercent >= 0 ? 1 : 0), 0);
    const tot = stock.totalTransactions;
    return activeStock === stock.ticker
      ? rows.concat([
        <tr key={`${stock.ticker}_timeline`}>
          <td
            className={classNames(styles.td, styles['td-Timeline'], {
              [styles.tdFirstChild]: false,
              [styles.tdNotLastChild]: true,
            })}
            colSpan={stocksCols.length}
          >
            <div
              className={classNames(styles.tdInner, styles['tdInner-graph'])}
            >
              {this.analystOnStockTimeline(stock)}
              <div className={styles.figures}>
                <div className={`${styles.figure} ${styles.successRate}`}>
                  <div className={styles.lbl}>
                    <div>{msg.successRateLbl}</div>
                    <div className={styles.sub}>
                      <b>{good}</b> out of <b>{tot}</b> profitable positions
                        on <b>{activeStock}</b>
                    </div>
                  </div>
                  <div className={styles.val}>
                    {tot ? this.successRate(stock) : '-'}
                  </div>
                </div>
                <div className={`${styles.figure} ${styles.avgReturn}`}>
                  <div className={styles.lbl}>
                    <div>{msg.avgReturnLbl}</div>
                    <div className={styles.sub}>
                      Average return per transaction for {activeStock}
                    </div>
                  </div>
                  <div className={styles.val}>{this.avgReturn(stock)}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>],
      )
      : rows;
  };


  sortStocks = (col: string) => () => {
    const { coverageTableThClicked } = this.props;
    const { activeCol, descend } = this.state;
    coverageTableThClicked(col);
    this.setState({
      activeCol: col,
      descend: activeCol === col ? !descend : false,
    });
  };

  sortBy = () => {
    const { activeCol, descend } = this.state;
    const strategy = strategies[activeCol];
    if (!strategy) {
      console.warn(`No sorting strategy for ${activeCol} field.`);
      return 'date';
    }
    return descendtify(strategy, descend);
  };

  unlimitStocks = () => {
    this.setState({ isAllRatings: true });
  };

}

export const __hotReload = true;

const PriceFetchz = createPriceFetchz({
  priceRefreshTimer: null, firstPriceRequest: 0,
});

export class AnalystStockCoverage extends PriceFetchz<AnalystStockCoverageProps> {
  render() {
    return (
      <AnalystStockCoverageComponent
        priceRequest={this.requestPrices}
        requestedPrices={this.getRequestedPrices()}
        {...this.props}
      />
    );
  }
}