import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

export default class DefaultCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    field: PropTypes.string,
    isRestricted: PropTypes.bool,
    showPromotion: PropTypes.func,
    stock: PropTypes.object
  };

  render() {
    const { enums, field, stock, isRestricted, showPromotion } = this.props;

    if (field === 'spaceColumn') {
      return null;
    }

    return (
      <PortfolioItemSelector
        showPromotion={showPromotion}
        isRestricted={isRestricted}
        item={stock}
        msg={enums}
        type={field}
        visualized
      />
    );
  }
}
