/// <reference path="../../../typings/main.d.ts" />

import { List } from 'immutable';
import ReactDOM from 'react-dom';
import rx from 'rx-lite';;
import React from 'react';
import PropTypes from 'prop-types';
import ReactDatalist from 'react-datalist';
import Component from 'react-pure-render/component';
import './index.styl';

const changeHandler = (callBack, inputChangeSubscription) => event => {
  callBack(event.target.value);
  inputChangeSubscription.onNext(event);
};

const optionSelected = callBack => option => callBack(option);

const toEventTargetValue = ({ target }) => target.value.trim();

const toVal = s => `${s.get('value')} - ${s.get('label')}`;

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.acId = `datalist_${new Date().getTime()}`;
    this.inputChangeSubscription = new rx.Subject();
  }

  static propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    list: PropTypes.instanceOf(List),
    listUpdater: PropTypes.func,
    onChange: PropTypes.func,
    onSelected: PropTypes.func,
    placeHolder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    list: new List(),
    listUpdater() {},
    onChange() {},
    placeHolder: '',
    type: 'search',
    value: ''
  };

  componentDidMount() {
    const { autoFocus, listUpdater } = this.props;
    this.inputChangeSubscription
      .map(toEventTargetValue)
      .debounce(750)
      // .distinctUntilChanged()
      .filter(val => val.length > 0)
      .subscribe(val => listUpdater(val));

    if (autoFocus) {
      const input = ReactDOM.findDOMNode(
        this.refs.datalist
      ).getElementsByClassName('react-datalist-input')[0];

      setTimeout(() => input.focus(), 1e3);
    }
    this.hideAutoComplete();
  }

  componentWillUnmount() {
    this.inputChangeSubscription.dispose();
  }

  componentDidUpdate() {
    const { listUpdater, value } = this.props;
    if (this.ctrl) {
      this.ctrl.setFilter(value);
      if (value.length === 0) {
        // listUpdater('');
      }
    }
    this.hideAutoComplete();
  }

  getController(ctrl) {
    this.ctrl = ctrl;
  }

  hideAutoComplete = () => {
    const { datalist } = this.refs;
    const { theInput } = datalist.refs;
    theInput.setAttribute('type', 'search');
    theInput.setAttribute('autocomplete', 'off');
    theInput.setAttribute('autocorrect', 'off');
    theInput.setAttribute('autocapitalize', 'off');
    theInput.setAttribute('spellcheck', 'false');
  };

  render() {
    const {
      disabled,
      list,
      onChange,
      onSelected,
      placeHolder,
      value
    } = this.props;

    return (
      <div className="auto-complete">
        <ReactDatalist
          disabled={disabled}
          forcePoly
          getController={this.getController.bind(this)}
          initialFilter={value}
          list={this.acId}
          onInputChange={changeHandler(onChange, this.inputChangeSubscription)}
          onOptionSelected={onSelected}
          options={value.length > 0 ? list.map(toVal).toJS() : []}
          placeholder={placeHolder}
          ref="datalist"
        />
      </div>
    );
  }
}
