import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectIsPermanentLoggedIn } from '../../../common/auth/selectors';
import { selectActivePortfolio } from '../../dashboard/selectors';
import { popupRegister } from '../../../common/lib/utils';
import { getGlobalActions } from '../../lib/actions';

/* TODO annotate stock */
export type OnClickAlert = (stock: any, value: boolean) => void;

export class EmailAlertsCell extends React.PureComponent<
  { stock: any; onClickAlert: OnClickAlert },
  {}
> {
  onClickAlert = () => {
    const { stock, onClickAlert } = this.props;

    onClickAlert(stock, !stock.get('isAlertsOn'));
  };
  render() {
    const { stock } = this.props;
    const isPermanentLoggedIn = selectIsPermanentLoggedIn();
    // it was decided that onboarding users should just
    // see "off" email alerts to increase conversion
    // (the user won't email alerts anyway, so why show him the real state?)

    const stockType = stock.get('stock').get('type');
    const disabled = ['etf', 'fund'].includes (stockType);
    const title = disabled ? 'Email Alerts are not available for ETFs & Funds' : '';
    const active = isPermanentLoggedIn && stock.get('isAlertsOn') && !disabled;
    return (
      <button className={classNames({ active })} onClick={this.onClickAlert} title={title} />
    );
  }
}
export default EmailAlertsCell;

export const setStockAlertValue = (item, value) => {
  const actions = getGlobalActions();
  const dashboard = window.__state__.dashboard;
  const portfolioId = selectActivePortfolio(dashboard).get('id');
  const isLoggedIn = selectIsPermanentLoggedIn();
  if (!isLoggedIn) {
    return popupRegister();
  }
  actions.setStockAlertValue(portfolioId, item, value);
};
