import reporter from '../../../../analytics';

const events = {
  visited: `visited-smart-portfolio-analysis-page`,
  clickedVolatilityGlossary: 'clicked-portfolio-analysis-reduce-risk',
  clickedDividendsGlossary: 'clicked-portfolio-analysis-increase-dividends',
  clickedCompare: 'Clicked-portfolio-analysis-compare-portfolio-btn',
  clickedLearnMore: type => `Clicked-portfolio-analysis-${type}-more`
};

const report = reporter(events);

export default report;
