// TODO once everything is TS, remove only uppercase, rename to something better.
export enum suits {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INITIAL = 'INITIAL',
  INVALID = 'INVALID',
  // Loading = 'LOADING',
  // Error = 'ERROR',
  // Success = 'SUCCESS',
  // Initial = 'INITIAL',
  // Invalid = 'INVALID',
}

export enum personTypes {
  analyst = 'analyst',
  blogger = 'blogger',
  insider = 'insider',
  hedgefund = 'hedgefund',
}

export const period = {
  monthly: 4,
  quarterly: 2,
  yearly: 3,
  twoyears: 1,
};

export const portfolioPeriodID = period;

export const benchmark = {
  snp500: 2,
  none: 1,
  sector: 3,
};
export const portfolioBenchmarkID = benchmark;

export const performanceDisplayTab = {
  stockPicker: 'stockPicker',
  portfolioManager: 'portfolioManager',
};

export const uiProperties = {
  performanceDisplayTab: 'performanceDisplayTab',
  benchmark: 'benchmark',
  period: 'period',
};

export enum TypesOfPortfolios {
  ActivePortfolio = 'active',
  AveragePortfolio = 'avg',
  BestPortfolio = 'best',
}


export enum MarketCountry {
  American = 1,
  Canadian = 2,
  Britain = 3,
}