export const _isNotWholyVisibleVertically = (window, rect, args) =>
  window.innerHeight - rect.top < args.expectedHeight;

export const _isNotWholyVisibleHorizontally = (window, rect, args) =>
  window.innerWidth - rect.left < args.expectedWidth;

export const _getLeft = (args, rect, isNotWholyVisibleHorizontally) => {
  if (isNotWholyVisibleHorizontally) {
    return rect.left;
  }
  if (args.centeredAbove) {
    return rect.left + rect.width / 2;
  }
  return rect.right;
};
``;

export const _getTop = (args, rect, isNotWholyVisibleVertically) => {
  if (isNotWholyVisibleVertically) {
    return rect.bottom - args.expectedHeight < 0
      ? args.expectedHeight
      : rect.bottom;
  }
  return rect.top;
};

export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const _genShowTooltip = window => (
  tooltipId,
  paramArgs,
  target
) => () => {
  const defaultArgs = {
    expectedHeight: 0,
    expectedWidth: 0
  };

  const rect = target.getBoundingClientRect();
  const args = { ...defaultArgs, ...paramArgs };

  const isNotWholyVisibleVertically = _isNotWholyVisibleVertically(
    window,
    rect,
    args
  );

  const isNotWholyVisibleHorizontally = _isNotWholyVisibleHorizontally(
    window,
    rect,
    args
  );

  return {
    type: SHOW_TOOLTIP,
    tooltipId,
    args,
    position: {
      left: _getLeft(args, rect, isNotWholyVisibleHorizontally),
      top: _getTop(args, rect, isNotWholyVisibleVertically),
      expectedHeight: args.expectedHeight,
      expectedWidth: args.expectedWidth,
      isNotWholyVisibleHorizontally,
      isNotWholyVisibleVertically
    }
  };
};
export const showTooltip = _genShowTooltip(window);

export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
export const hideTooltip = (
  tooltipId,
  { clientX, clientY, force = false }
) => () => {
  return {
    type: HIDE_TOOLTIP,
    tooltipId,
    clientX,
    clientY,
    force
  };
};
