import * as classnames from 'classnames';
import { PlansLink } from '../components/PlansLink';
import * as React from 'react';
import * as classNames from 'classnames';
import * as helperStyles from '../../common/styles/common.istyl';
import { Br } from '../../common/utils';
import { Link } from 'react-router';
import reporter from '../analytics';
import { ReduxProps } from '../../common/types';
import { selectIsPermanentLoggedIn } from '../../common/auth/selectors';

import './index.styl';

const report = reporter({
  'visited-smart-portfolio-thanks-for-sign-up':
    'visited-smart-portfolio-thanks-for-sign-up'
});

const bulletpoints320 = [
  {
    title: 'Let Wall Street Work For You',
    subtitle: 'Get expert sentiment for your stocks',
    href:
      '/smart-portfolio/holdings/holdings?utm_term=Let%20Wall%20Street%20Work%20for%20You%20link'
  },
  {
    title: 'Benefit From Crowd Insights',
    subtitle: 'Learn from thousands of investors',
    href: '/smart-portfolio/insights?utm_term=Benefit%20From%20Crowd%20Insights'
  },
  {
    title: 'Get In-Depth Portfolio Analysis',
    subtitle: 'Learn how to allocate investments',
    href:
      '/smart-portfolio/analysis/overview?utm_term=Get%20In%20Depth%20Portfolio%20Analysis'
  },
  {
    title: 'Measure Your Performance',
    subtitle: 'Improve your investing performance',
    href:
      '/smart-portfolio/performance/me?utm_term=Measure%20Your%20Performance'
  },
  {
    title: 'Stock Screener',
    subtitle: 'Find great investsment ideas easily',
    href: '/smart-portfolio/screener/stocks?utm_term=Stock%20Screener'
  },
  {
    title: 'News Analysis',
    subtitle: 'Navigate media storms with TipRanks’ new tools',
    href: '/smart-portfolio/holdings/news/analysis?utm_term=News%20Analysis'
  }
];

const bulletpoints720 = [
  {
    title: 'Let Wall Street Work For You',
    subtitle:
      'Discover stock sentiment from thousands of financial experts on your stocks',
    href:
      '/smart-portfolio/holdings/holdings?utm_term=Let%20Wall%20Street%20Work%20for%20You%20link'
  },
  {
    title: 'Benefit From Crowd Insights',
    subtitle: 'Learn from thousands of portfolios in one single screen',
    href: '/smart-portfolio/insights?utm_term=Benefit%20From%20Crowd%20Insights'
  },
  {
    title: 'Get In-Depth Portfolio Analysis',
    subtitle:
      'Ever wanted to diversify your portfolio? Easily learn how to allocate your investments',
    href:
      '/smart-portfolio/analysis/overview?utm_term=Get%20In%20Depth%20Portfolio%20Analysis'
  },
  {
    title: 'Measure Your Performance',
    subtitle:
      'Improve your investing performance, find out which transactions failed and which succeeded',
    href:
      '/smart-portfolio/performance/me?utm_term=Measure%20Your%20Performance'
  },
  {
    title: 'Stock Screener',
    subtitle:
      'Find great investment ideas quickly and easily using expert sentiment',
    href: '/smart-portfolio/screener/stocks?utm_term=Stock%20Screener'
  },
  {
    title: 'News Analysis',
    subtitle:
      'Navigate media storms with TipRanks’ proprietary News Analysis tool',
    href: '/smart-portfolio/holdings/news/analysis?utm_term=News%20Analysis'
  }
];

const vImg = require('./img/v.png');

export class SignUpThankYou extends React.PureComponent {
  renderBulletPoint = ({ title, subtitle, href }) => (
    <li className="suty_bulletpoint">
      <div className="suty_bulletwrapper">
        <img src={vImg} />
      </div>
      <Link to={href} className="suty_title">
        {title}
      </Link>
      <div className="suty_subtitle">{subtitle}</div>
    </li>
  );

  // only allow url of type /smart-portfolio/sign-up/manual or imported
  componentWillMount() {
    const type = window.location.pathname.split('/').pop() as any;
    // redirect if user is not signed (this is the "thanks for signing up page")
    if (!selectIsPermanentLoggedIn()) window.location.href = '/smart-portfolio';
    // we only have two types of thank you sign up page
    if (['manual', 'imported'].indexOf(type) === -1)
      window.location.href = '/404';
    report('visited-smart-portfolio-thanks-for-sign-up');
  }
  public render() {
    return (
      <div className="suty_wrapper">
        <div className="suty_header">
          Thank you for joining<Br isForMobile />
          Smart Portfolio!
        </div>
        <div className="suty_subheader">
          Outsmart the market with Smart<Br isForMobile />
          Portfolio analytical tools
        </div>
        <div className="suty_le720pictureWrapper">
          <ul
            className={classNames(helperStyles.showMobile, 'suty_bulletpoints')}
          >
            {bulletpoints320.map(this.renderBulletPoint)}
          </ul>
          <ul
            className={classNames(helperStyles.hideMobile, 'suty_bulletpoints')}
          >
            {bulletpoints720.map(this.renderBulletPoint)}
          </ul>
          <div
            className={classNames('suty_posterImg', helperStyles.hideMobile)}
          />
        </div>
        <div className="suty_superNiceWrapper">
          <div className="suty_superNiceBackground" />
          <div
            className={classNames('suty_viewPlansBtn', helperStyles.showMobile)}
          >
            <PlansLink custom3="sp-sign-up-thank-you-plans-btn">
              View Plans & Pricing
            </PlansLink>
          </div>
          <div className="suty_slogan">
            <span className={helperStyles.showMobile}>
              Get access to better tools
            </span>
            <span className={helperStyles.hideMobile}>
              Want to get access to better tools?
            </span>
          </div>
          <div className="suty_subslogan">
            Go Pro and unleash the full potential of Smart Portfolio Pro!
          </div>
          <div
            className={classNames(
              'suty_viewPlansBtn',
              'suty_viewPlansBtn2',
              helperStyles.hideMobile
            )}
          >
            <PlansLink custom3="sp-sign-up-thank-you-plans-btn">
              View Plans & Pricing
            </PlansLink>
          </div>
        </div>
        <div className="suty_something" />
      </div>
    );
  }
}
