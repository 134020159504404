import * as React from 'react';
import { equalWidthAndMargin } from '../../common/lib/utils';
import { factors } from './conf';
import { defaultMsg } from './msg';
import './index.styl';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export class TrHowWeFindSimilarFunds extends React.PureComponent<{
  msg?: any;
}> {
  static defaultProps = {
    msg: defaultMsg
  };

  renderFactor = (factorId, index, arr) => {
    const { msg } = this.props;

    return (
      <li key={factorId} style={equalWidthAndMargin(arr.size, index, 1)}>
        <section>
          <header>
            <img src={reqImg(`${factorId}.png`)} />
            <h1>{msg[`factorTitle.${factorId}`]}</h1>
          </header>
          <main>
            <p>{msg[`factorDesc.${factorId}`]}</p>
          </main>
        </section>
      </li>
    );
  };

  render() {
    const { msg } = this.props;

    return (
      <section className="tr-how-we-find-similar-funds">
        <header>
          <h1>{msg.headerH1}</h1>
        </header>
        <main>
          <p>{msg.mainP}</p>
          <div className="factors">
            <div className="factors-title">{msg.factorsTitle}</div>
            <ul>{factors.map(this.renderFactor)}</ul>
          </div>
        </main>
      </section>
    );
  }
}
export default TrHowWeFindSimilarFunds;
