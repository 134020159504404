import { clearPlaidTokenFromStorage } from './TokenStorageManager';
import { getGlobalActions } from "sp/browser/lib/actions";
import { removeOAuthStateIdFromUrl } from './onPlaidAuthSuccess';

export function responseToGeneralErrorPlaid(options: { alertText?: string, useAlert?: boolean } = {}) {
  const { alertText, useAlert = true } = options;
  getGlobalActions().closeDialog('plaidSyncLoader');
  if (useAlert) {
    alert(alertText || 'Could not sync selected portfolio');
  }
  removeOAuthStateIdFromUrl();
  clearPlaidTokenFromStorage();
}