import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import TrDashboardMgmtFeesComparison from '../tr-dashboard-mgmt-fees-comparison';

import {
  selectDoAllMgmtFeesHaveShares,
  selectMgmtFees
} from '../dashboard/selectors';

export default class TrDashboardMgmtFeesComparisonContainer extends Component {
  static propTypes = {
    dashboard: PropTypes.object,
    mediaQuery: PropTypes.object,
    mgmtFee: PropTypes.string
  };

  render() {
    const { dashboard, mediaQuery, mgmtFee } = this.props;

    const mgmtFees = selectMgmtFees(dashboard);
    const mgmtFeeItem = mgmtFees.find(
      item => item.get('fundSymbol') === mgmtFee
    );

    return (
      <TrDashboardMgmtFeesComparison
        doAllMgmtFeesHaveShares={selectDoAllMgmtFeesHaveShares(dashboard)}
        isFundBoxMoreDetailed={mediaQuery.get('tablet')}
        mgmtFee={mgmtFeeItem}
      />
    );
  }
}
