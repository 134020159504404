import d3 from 'd3';

export const makeGraph = ({
  companyPortfolioItemsDistribution,
  companyPortfolioStocksDistribution,
  mediaQuery,
  portfolioItemsDistribution,
  portfolioStocksDistribution,
  theme
}) => {
  const toD3Js = (distribution, transform = x => x) =>
    distribution.every(type => type.get('percent') === 0)
      ? [
          {
            count: 100,
            label: 'none'
          }
        ]
      : distribution
          .map(type =>
            transform(
              type
                .set('count', type.get('percent') * 100)
                .set('label', type.get('id'))
            )
          )
          .toJS();

  const itemsToD3Js = distribution => toD3Js(distribution);
  const stocksToD3Js = distribution => toD3Js(distribution);

  // Datas for donuts graphs
  const graph1 = itemsToD3Js(portfolioItemsDistribution);
  const graph2 = itemsToD3Js(companyPortfolioItemsDistribution);
  const graph3 = stocksToD3Js(portfolioStocksDistribution);
  const graph4 = stocksToD3Js(companyPortfolioStocksDistribution);

  const size = mediaQuery.get('mobileXL') ? 130 : 108;

  const width = size;
  const height = size;

  const radius = Math.min(width, height) / 2;

  const donutWidth = mediaQuery.get('mobileXL') ? 15 : 11;

  const themeColors = {
    web: ['#289dc8', '#b908c4', '#97bb18', '#a6d6e0'],
    nasdaq: ['#03cef9', '#07a9d4', '#144977', '#a6d6e0']
  };

  const color = d3.scale
    .ordinal()
    .domain(['stock', 'etf', 'fund'])
    .range(themeColors[theme]);

  const themeSectorColors = {
    web: ['#e27a0a', '#eba223', '#ebc21d', '#c9c9c9', '#1ad28f'],
    nasdaq: [
      '#16ACD4',
      '#3C5761',
      '#C6E6F2',
      '#4F92AB',
      '#03CEF8',
      '#6DCFF6',
      '#1A4A5C',
      '#0F7692',
      '#039FC1',
      '#CCCCCC'
    ]
  };

  const sectorColors = index => themeSectorColors[theme][index] || '#c9c9c9';

  const svg = d3
    .select('#lt2b-graph1')
    .html('')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const svg1 = d3
    .select('#lt2b-graph2')
    .html('')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const svg2 = d3
    .select('#lt2b-graph3')
    .html('')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const svg3 = d3
    .select('#lt2b-graph4')
    .html('')
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  const arc = d3.svg
    .arc()
    .innerRadius(radius - donutWidth)
    .outerRadius(radius);

  const pie = d3.layout
    .pie()
    .value(d => d.count || 0)
    .sort(null);

  svg
    .selectAll('path')
    .data(pie(graph1))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr(
      'fill',
      (d, i) => (d.data.label === 'none' ? '#848484' : color(d.data.label))
    );

  svg1
    .selectAll('path')
    .data(pie(graph2))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr('fill', (d, i) => color(d.data.label));

  svg2
    .selectAll('path')
    .data(pie(graph3))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr(
      'fill',
      (d, i) => (d.data.label === 'none' ? '#848484' : sectorColors(i))
    );

  svg3
    .selectAll('path')
    .data(pie(graph4))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr('fill', (d, i) => sectorColors(i));
};
