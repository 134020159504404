import '../styles/assetallocationgraph.styl';

import * as d3 from 'd3';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as immutable from 'immutable';

import Component from 'react-pure-render/component';
import { PureComponent } from 'react';
import { List } from 'immutable';

class Chart {
  [x: string]: any;

  constructor(props) {
    Object.assign(
      this,
      {
        thickness: 0.4
      },
      props
    );
  }

  create(el, props, state) {
    this.onSliceClicked = state.onSliceClicked;

    this.width = props.width;
    this.height = props.height;
    this.radius = Math.min(this.width, this.height) / 2;

    this.svg = d3
      .select(el)
      .append('svg')
      .append('g');

    // clices
    this.svg.append('g').attr('class', 'slices');

    // labels
    this.svg.append('g').attr('class', 'labels');

    // lines
    this.svg.append('g').attr('class', 'lines');

    this.pie = d3.layout
      .pie()
      .sort(null as any)
      .value(d => (d as any).count);

    this.arc = d3.svg
      .arc()
      .outerRadius(this.radius * 0.75)
      .innerRadius(this.radius * (1 - this.thickness));

    this.svg.attr(
      'transform',
      `translate(${this.width / 2}, ${this.height / 2 - 10})`
    );

    this.key = d => d.data.id;

    this.update(el, state);
  }

  update(el, state) {
    const { activeDatum, asset, colors } = state;
    const assetIds = asset.map(a => a.get('id'));

    // hack, something breaks if we don't do this lol
    if (asset.size > 0 && asset.every(x => !x.get('count'))) {
      return;
    }

    if (activeDatum) {
      const total = asset.reduce(
        (total, datum) => total + datum.get('count'),
        0
      );
      const halfIfActive = id => (id === activeDatum ? 0.5 : 1);
      const countOf = count => count / total;
      const activeOrEarlier = (datum, i) => i <= assetIds.indexOf(activeDatum);
      const takePercentOfOffset = (offset, datum) =>
        offset + countOf(datum.get('count')) * halfIfActive(datum.get('id'));
      const offsetPercent = asset
        .filter(activeOrEarlier)
        .reduce(takePercentOfOffset, 0);
      const pieChartRotation = offsetPercent * 2 * Math.PI - 2 * Math.PI * 0.13;
      this.pie
        .startAngle(-pieChartRotation)
        .endAngle(Math.PI * 2 - pieChartRotation);
    }

    this.color = d3.scale
      .ordinal()
      .domain(assetIds.toJS())
      .range(colors.toJS());

    const assetData = asset.size ? asset.toJS() : [{ id: '', count: 1 }];
    const getColor = asset.size ? this.color : () => '#acacac';
    /* ------- PIE SLICES -------*/
    this.slice = this.svg
      .select('.slices')
      .selectAll('path.slice')
      .data(this.pie(assetData), this.key);

    this.slice
      .enter()
      .insert('path')
      .style('fill', d => getColor(d.data.id))
      .attr('class', 'slice')
      .on(
        'click',
        ({ data }) =>
          typeof this.onSliceClicked === 'function' &&
          data.id !== 'cash' &&
          this.onSliceClicked(data.id)
      );

    const arc = this.arc;
    this.slice
      .transition()
      .duration(1000)
      .attrTween('d', function(d) {
        this._current = this._current || d;
        const interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return t => arc(interpolate(t));
      });

    this.slice.exit().remove();
  }

  destroy(el) {
    d3
      .select(el)
      .selectAll('*')
      .remove();
  }
}

function getWidthOf(el) {
  const { width = 0 } = window.getComputedStyle(el) || { width: 0 };
  const intWidth = parseInt(width as any, 10);
  return intWidth > 10 ? intWidth : getWidthOf(el.parentElement) - 10;
}

export class AssetAllocationGraph extends PureComponent<{
  activeDatum: string;
  asset: List<any>;
  colors: List<any>;
  onSliceClicked: any;
  size?: number;
  thickness: number;
  style?: any;
  type: string;
  animated: boolean;
  className?: string;
}> {
  width: any;
  el: any;
  d3Chart: Chart;
  componentDidMount() {
    this.d3Chart = new Chart(this.props);
    const { type } = this.props;
    this.el = ReactDOM.findDOMNode(this.refs[type]);
    this.width = getWidthOf(this.el);
    this.createGraph();
    window.addEventListener('resize', this.onResize);
  }

  createGraph() {
    this.d3Chart.destroy(this.el);
    const size = this.props.size || getWidthOf(this.el);
    this.d3Chart.create(
      this.el,
      {
        width: size,
        height: size
      },
      this.getChartState()
    );
  }

  updateGraph() {
    this.d3Chart.update(this.el, this.getChartState());
  }

  destroyGraph() {
    this.d3Chart.destroy(this.el);
  }

  onResize = () => {
    const width = getWidthOf(this.el);
    if (width === this.width) {
      return;
    }
    this.width = width;
    this.d3Chart.destroy(this.el);
    this.createGraph();
  };

  componentWillUpdate(nextProps, nextState) {
    this.onResize();
  }

  componentDidUpdate() {
    this.props.animated ? this.updateGraph() : this.createGraph();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.destroyGraph();
  }

  getChartState() {
    return this.props;
  }

  render() {
    const { type, style, className = 'asset-allocation-graph' } = this.props;
    return <div style={style} className={className} ref={type} />;
  }
}

export default AssetAllocationGraph;

export const __hotReload = true;
