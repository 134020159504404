import { StockTypeEnum } from '../../../common/types';
import '../../tr-dashboard-user-performance-container/index.styl';

import * as React from 'react';
import * as classNames from 'classnames';
import {
  ColorCodedList,
  ColorCodedListValue,
  toColorCodedList
} from 'sp/browser/ColorCodedList';
import {
  AllocationGraph,
  AllocationItem,
} from 'sp/browser/AllocationGraph/AllocationGraph';
import { SectorEnum } from 'sp/browser/news-analysis/types';
import * as styles from './styles.istyl';
import { Icon } from 'tipranks-icons';
import { TickerCompanyPercentColoredList, TickerCompanyPercentColoredListValue } from 'sp/browser/ColorCodedList/TickerCompanyPercentColoredList';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export interface AllocationBoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
  ComponentData {
  mediaQuery: MediaQuery;
  onClickArrow?: (direction: ArrowDirection) => any;
}
export interface ComponentData {
  assetAllocation: AllocationItem[];
  sectorAllocation: AllocationItem[];
  companyAllocation: CompanyAllocationItem[];
  isETFOrFundPortfolio?: boolean;
}

export type CompanyAllocationItem = { id: string | 'other'; ticker: string, percent: number, type: StockTypeEnum };
export enum AllocationType {
  Company = 'Company Allocation',
  Sector = 'Sector Allocation',
  Asset = 'Asset Allocation',
}
export type ArrowDirection = 'backArrow' | 'forwardArrow';
export class AllocationBoxRaw extends React.PureComponent<
  AllocationBoxProps,
  { mobileCurrAllocation: AllocationType }
  > {
  shouldUseArrowsCauseMobile = () => {
    return !this.props.mediaQuery.get('768');
  };
  state = { mobileCurrAllocation: AllocationType.Company };
  render() {
    const {
      mediaQuery,
      sectorAllocation,
      companyAllocation,
      assetAllocation,
      children = <div>No Data Available ¯\_(ツ)_/¯</div>,
      isETFOrFundPortfolio
    } = this.props;

    const { mobileCurrAllocation } = this.state;

    const showAssetAllocation = this.shouldUseArrowsCauseMobile()
      ? mobileCurrAllocation === AllocationType.Asset
      : true;
    const showSectorAllocation = this.shouldUseArrowsCauseMobile()
      ? mobileCurrAllocation === AllocationType.Sector
      : true;
    const showCompanyAllocation = this.shouldUseArrowsCauseMobile()
      ? mobileCurrAllocation === AllocationType.Company
      : true;

    // check if should show nodata message
    if (
      isETFOrFundPortfolio /*assetAllocation.length === 0 && */ || // Asset allocation always contains 4 items, regardless of no data :D
      (sectorAllocation.length === 0 && companyAllocation.length === 0) ||
      (process.env.NOT_PROD &&
        window.location.search.toLowerCase().indexOf('nodata') !== -1)
    )
      return <div className={styles.container}>{children}</div>;

    return (
      <div className={classNames(styles.container, styles.hasData)}>
        <Icon
          icon="shirlyArrow"
          className={classNames(styles.arrow, styles.backArrow, styles.oppositeDay)}
          onClick={() => this.onClick('backArrow')}
        />
        <ul className={styles.graphsContainer}>
          {showCompanyAllocation &&
            this.renderAllocationInfo(
              AllocationType.Company,
              companyAllocation,
              toColorCodedList(companyAllocation)
            )}
          {showSectorAllocation &&
            this.renderAllocationInfo(
              AllocationType.Sector,
              sectorAllocation,
              toColorCodedList(sectorAllocation)
            )}
          {showAssetAllocation &&
            this.renderAllocationInfo(
              AllocationType.Asset,
              assetAllocation,
              toColorCodedList(assetAllocation)
            )}
        </ul>
        <Icon
          icon="shirlyArrow"
          className={classNames(styles.arrow, styles.forwardArrow)}
          onClick={() => this.onClick('forwardArrow')}
        />
      </div>
    );
  }
  renderAllocationInfo<T extends TickerCompanyPercentColoredListValue | ColorCodedListValue>(
    title: AllocationType,
    distribution: (CompanyAllocationItem | AllocationItem)[],
    colorList: T,
  ) {
    const { mediaQuery } = this.props;
    const size = mediaQuery.get('768') ? 175 : 200; // smaller screens get one big allocation graph
    const lineThickness = mediaQuery.get('768') ? 17 : 23;

    const ColoredList = title === AllocationType.Company
      ? TickerCompanyPercentColoredList
      : ColorCodedList;
    return (
      <li className={styles.allocationItemWrapper}>
        <div className={styles.allocationItem} style={{ width: `${size}px` }}>
          <AllocationGraph
            center={title}
            portfolioStocksDistribution={distribution}
            lineThickness={lineThickness}
            size={size}
            className={styles.graph}
          />
          <ColoredList value={colorList} />
        </div>
      </li>
    );
  };
  onClick = (direction: ArrowDirection) => {
    const { mobileCurrAllocation } = this.state;
    if (this.shouldUseArrowsCauseMobile()) {
      if (mobileCurrAllocation === AllocationType.Asset)
        this.setState({ mobileCurrAllocation: AllocationType.Company });
      if (mobileCurrAllocation === AllocationType.Company)
        this.setState({ mobileCurrAllocation: AllocationType.Sector });
      if (mobileCurrAllocation === AllocationType.Sector)
        this.setState({ mobileCurrAllocation: AllocationType.Asset });
    }
    this.props.onClickArrow && this.props.onClickArrow(direction);
  };
}

/**
 * Wrapper on AllocationBoxRaw, a UI component that displays pie charts of {company,sector,asset} allocation.
 * The wrapper does:
 * 1. getLimitedStockDistribution on the props so allocationBoxRaw wouldn't have to think about it.
 * 2. filters items with zero percent.
 */
export class AllocationBox extends React.PureComponent<AllocationBoxProps> {
  render() {
    // Note that we don't filter on assetAllocation, because product wants
    // zero's on it ¯\_(ツ)_/¯
    const assetAllocation = this.props.assetAllocation;

    const sectorAllocation = this.props.sectorAllocation
      .filter(item => item.percent);

    const companyAllocation = this.props.companyAllocation
      .filter(item => item.percent);

    return (
      <AllocationBoxRaw
        {...this.props}
        assetAllocation={getLimitedStockDistribution(assetAllocation, 7)}
        sectorAllocation={getLimitedStockDistribution(sectorAllocation, 7)}
        companyAllocation={getLimitedStockDistribution(companyAllocation, 7)}
      />
    );
  }
}

// TODO I don't like the whole usage of 'Other' string to work with the "other" value.
// it's like a special case of a value, and it conceptually lives only in the implementation
// not in the typesystem or anywhere obvious.
export function getLimitedStockDistribution<T extends AllocationItem>(
  sortedStocks: T[],
  maxLength: number
): T[] {
  const readyStocks = sortedStocks.slice(0, maxLength);
  const shouldLimitStocksReturned = sortedStocks.length > maxLength;
  const otherStockIndex = sortedStocks.findIndex(
    d => d.id.toLowerCase() === 'other'
  );
  const otherStock =
    otherStockIndex !== -1 ? readyStocks.splice(otherStockIndex, 1)[0] : null;

  if (shouldLimitStocksReturned) {
    const othersTotalPercent = sortedStocks
      .slice(maxLength - 1)
      .reduce((acc, cur) => acc + cur.percent, 0);

    if (otherStock) {
      otherStock.percent += othersTotalPercent;
      readyStocks.push(otherStock);
    } else {
      readyStocks[maxLength - 1] = {
        id: 'Other',
        percent: othersTotalPercent
      } as T;
    }
  } else if (otherStock) {
    readyStocks.push(otherStock);
  }

  return readyStocks;
}
