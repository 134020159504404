import * as classNames from 'classnames';
import * as raf from 'raf';
import * as React from 'react';

const stylusVariables = require(`../style-helpers/themes/${
  process.env.THEME
  }.json`);
const colorPositive = stylusVariables['$colorPositive'];
const colorNegative = stylusVariables['$colorNegative'];

const styles = {
  img: {
    position: 'absolute',
    left: 0,
    transform: 'translate(23px, 3px)'
  },
  successRate: {
    fontFamily: 'ProximaNovaA',
    position: 'relative',
    display: 'inline-flex'
  }
};

const G_SELL_COLOUR_MAX_RATE = 0.5;
const rotationOffset = 1.5;
const fullCircle = 2 * Math.PI;
const animFrame = 0.01;

export const defaultProps = {
  fontSize: 12,
  animated: true,
  noBg: true,
  radius: 30,
  style: {
    background: '',
    positive: [colorPositive, colorPositive],
    text: '#fff',
    negative: [colorNegative, colorNegative],
    empty: '#000'
  },
  width: 4
};

export type Color = string;

export class SuccessRateRaw extends React.PureComponent<{
  animated?: boolean; //PropTypes.bool,
  noBg?: boolean; //PropTypes.bool,
  fontSize?: number; //PropTypes.number,
  radius: number; //PropTypes.number,
  rate: number; //PropTypes.number, // as percent
  style?: Partial<{
    background: Color;
    positive: [Color, Color];
    text: Color;
    negative: [Color, Color];
    empty: Color;
  }>; //PropTypes.object,
  width?: number; //PropTypes.number
}> {
  ctx: any;
  posColor: any;
  negColor: any;
  emptyColor: any;
  center: any;
  diameter: any;
  canvasSize: any;
  animOffset: any;
  canvas: HTMLCanvasElement;

  static defaultProps = defaultProps;

  rafId = 0;

  componentDidMount() {
    const { animated, radius, rate, width = defaultProps.width } = this.props;
    const style = this.getStyle();

    this.ctx = this.canvas.getContext('2d');
    this.posColor = { color1: style.positive[0], color2: style.positive[1] };
    this.negColor = { color1: style.negative[0], color2: style.negative[1] };
    this.emptyColor = { color1: style.empty, color2: style.empty };
    this.center = radius + width;
    this.diameter = radius * 2;
    this.canvasSize = this.diameter + width * 2;
    this.animOffset = animated ? 0 : rate;

    this.canvas.setAttribute('height', this.canvasSize);
    this.canvas.setAttribute('width', this.canvasSize);

    this.drawCircle(this.emptyColor, 1, false);
    this.animateArc(
      rate >= G_SELL_COLOUR_MAX_RATE ? this.posColor : this.negColor
    );
  }

  componentDidUpdate() {
    const { rate } = this.props;

    this.animateArc(
      rate >= G_SELL_COLOUR_MAX_RATE ? this.posColor : this.negColor
    );
  }

  render() {
    const { noBg, rate, children, fontSize } = this.props;

    return (
      <div
        style={styles.successRate as any}
        className={classNames(
          `success-rate`,
          `${rate >= 0.5 ? 'positive' : 'negative'}`
        )}
      >
        {noBg ? null : (
          <img style={styles.img as any} src="/images/gradient.png" />
        )}
        <canvas ref={ref => (this.canvas = ref as HTMLCanvasElement)} />
        <div
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            fontSize
          }}
        >
          {children}
        </div>
      </div>
    );
  }

  fmyPercent = i => `${Math.round(i * 100)}%`;

  drawCircle = (color, percent, isText) => {
    const { fontSize, radius, style, width } = this.props;

    const endAngle = percent * fullCircle + rotationOffset;
    this.ctx.beginPath();
    this.ctx.lineWidth = width;
    var grd = this.ctx.createLinearGradient(0, 0, 0, this.diameter);
    grd.addColorStop(0, color.color1);
    grd.addColorStop(1, color.color2);
    this.ctx.strokeStyle = grd;
    this.ctx.arc(
      this.center,
      this.center,
      radius * 0.75,
      rotationOffset,
      endAngle
    );
    // this part used to pu text inside the canvas, but now we're doing it on the outside.
    // this.ctx.fillStyle = color.color2;
    // this.ctx.textAlign = 'center';
    // this.ctx.textBaseline = 'middle';
    // this.ctx.font = `${fontSize}px ${font('Proxima Nova Alt Regular')}`;
    // if (isText) {
    //   this.ctx.fillStyle = style.text;
    //   this.ctx.fillText(
    //     this.fmyPercent(percent),
    //     this.canvasSize / 2,
    //     this.canvasSize / 2
    //   );
    // }
    this.ctx.stroke();
  };

  drawBg = () => {
    const { noBg, radius, width } = this.props;

    if (noBg) return;

    this.ctx.beginPath();
    this.ctx.lineWidth = width;
    var grd = this.ctx.createLinearGradient(0, 0, this.diameter, 0);
    grd.addColorStop(0, '#262626');
    grd.addColorStop(0.5, '#404040');
    grd.addColorStop(1, '#262626');
    this.ctx.fillStyle = grd;
    this.ctx.arc(this.center, this.center, radius, 0, fullCircle);
    this.ctx.fill();
  };

  animateArc = color => {
    const { rate } = this.props;

    const step = () => {
      try {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawBg();
        this.drawCircle(this.emptyColor, 1, false);
        this.drawCircle(color, this.animOffset, true);
        if (this.animOffset >= rate) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.drawBg();
          this.drawCircle(this.emptyColor, 1, false);
          this.drawCircle(color, rate, true);
          return;
        }
        this.animOffset += animFrame * (this.animOffset > rate ? -1 : 1);
        if (rate >= 0 && rate <= 1) {
          this.rafId = raf(step);
        }
      } catch (err) {
        // TODO error don't do anything, no idea when it happens yet.
        // silence it from production, deal with it in time in DEV
        // if (process.env.NOT_PROD) console.error('Success Rate step error', err);
      }
    };

    raf.cancel(this.rafId);
    this.rafId = raf(step);
  };

  getStyle = () => {
    const { style = defaultProps.style } = this.props;

    return {
      background: style.background || defaultProps.style.background,
      positive: style.positive || defaultProps.style.positive,
      text: style.text || defaultProps.style.text,
      negative: style.negative || defaultProps.style.negative,
      empty: style.empty || defaultProps.style.empty
    };
  };
}

// here for compatibility
// export const SuccessRate = (props) => <SuccessRateRaw {...props}>{props.rate}%</SuccessRateRaw>
export const SuccessRate = props => (
  <SuccessRateRaw {...props}>{(props.rate * 100).toFixed(0)}%</SuccessRateRaw>
);
// export const SuccessRate = (props) => <SuccessRateRaw {...props}>{(props.rate * 100).toFixed(0)}%</SuccessRateRaw>

export const __hotReload = true;

const font = family =>
  ({
    'Proxima Nova Alt Light': 'Proxima Nova Alt Light',
    'Proxima Nova Alt Semibold': 'Proxima Nova Alt Semibold',
    'Proxima Nova Alt Bold': 'Proxima Nova Alt Bold',
    'Proxima Nova Alt Regular': 'Proxima Nova Alt'
  }[family] || console.warn(`${family} is not a font name`));
