import { Record, Map } from 'immutable';
import { suits } from 'sp/common/enums';
import { LoggedInUser_NotImmutable, AuthRecord, UserRecord_NotImmutable } from 'sp/common/types';
import Form from './form';
import { List } from 'immutable';
import { fromJS } from 'immutable';
import { setPortfolioPrivacy } from 'sp/common/auth/actions';

const User = Record({
  // these come striaght from the server in api-adapter.ts#adaptUser
  plan: 'open',
  isFirstVisit: true,
  isFirstTimeMigratedUser: true,
  hasHoldings: false,
  isSpaasMigrated: false,
  email: '',
  id: '',
  expertId: 0,
  name: '',
  name_old: '',
  planId: 0,
  phone: '',
  bypassEmailLimit: false,
  emailAlertsActive: false,
  signupDate: '',
  areEmailAlertsActive: false,
  addOns: List([]),

  // TODO should these be seperated?
  // these come from other places / app internal
  portfoliosWithPerformance: List([]),
  // when this is empty, user didnt make any portfolio publi
  portfoliosMadePublic: List([]),
  latestCreatedPublicPortfolio: Map(),

  // TODO should we stay with this componentStates system? what are the possible alternatives?
  componentStates: fromJS({
    portfoliosMadePublic: suits.INITIAL,
    setPortfolioPrivacy: suits.INITIAL
  })
} as LoggedInUser_NotImmutable);

const Users = Record({
  loggedInUser: new User() as any, // TODO remove when User uses createRecord or 
  plan: 'open',
  isFirstVisit: true,
  isFirstTimeMigratedUser: true,
  isSpaasMigrated: false
  
} as UserRecord_NotImmutable);

export const InitialState: new () => AuthRecord = (Record as any)({
  form: new Form(),
  didIdentify: false,
  redirecting: true,
  users: Map({ viewer: new Users() }),
  ver: 0,
});

export const initialState = new InitialState();
