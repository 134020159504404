import * as actions from './actions';
import initialState from './initial-state';

const processPost = post => {
  return post;
};

const actionHandlers = {
  [actions.DOWNLOAD_PAGE_START](state) {
    return state;
  },
  [actions.DOWNLOAD_PAGE_SUCCESS](state, post) {
    return processPost(post);
  },
  [actions.DOWNLOAD_PAGE_ERROR](state, error) {
    if (error.response.status > 400) {
      console.log('dictionary slug not found');
    }
    return state;
  }
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
