import reporter from '../analytics';

const events = {
  visited: `visited-smart-portfolio-experts-page`,
  changedFilter: `clicked-my-experts-filter-checkboxes`,
  clickedUnfollow: 'clicked-my-experts-remove-expert',
  clickedExpertCenter: `clicked-my-experts-top25-banner`
};

const report = reporter(events);

export default report;
