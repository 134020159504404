import * as React from 'react';
import * as moment from 'moment';
import { List } from 'immutable';
import * as classNames from 'classnames';
import Component from 'react-pure-render/component';

const isSameDay = (a: Date, b: Date) => a.getDate() === b.getDate();

const isSameMonth = (a: Date, b: Date) => a.getMonth() === b.getMonth();

const isSameYear = (a: Date, b: Date) => a.getFullYear() === b.getFullYear();

export class Calendar extends React.PureComponent<
  {
    date: any; //React.PropTypes.object,
    events: any; //React.PropTypes.instanceOf(List),
    fnNext: any; //React.PropTypes.func,
    fnPrev: any; //React.PropTypes.func,
    monthsTotal: number; //React.PropTypes.number,
    msg: any; //React.PropTypes.object
  },
  {}
> {
  static defaultProps = {
    date: new Date(),
    events: List(),
    monthsTotal: 1
  };

  msg(str) {
    const { msg } = this.props;
    return msg[str];
  }

  renderTh(weekDay, i) {
    return <th key={i}>{weekDay}</th>;
  }

  byDate = (day: moment.Moment) => {
    return event => {
      return (
        event.get('date') instanceof Date &&
        day &&
        isSameYear(event.get('date'), day.toDate()) &&
        isSameMonth(event.get('date'), day.toDate()) &&
        isSameDay(event.get('date'), day.toDate())
      );
    };
  };

  getRelevantEvent = day => {
    const { events } = this.props;
    return events.filter(this.byDate(day));
  };

  renderToday = () => {
    return (
      <div className="event-wrapper event-wrapper-today">
        <div className="event">{this.msg('today')}</div>
      </div>
    );
  };

  renderEvent = events => {
    const multipleEvents = events.size > 1;
    return (
      <div className="event-wrapper">
        <div
          className={classNames('event', { 'multiple-events': multipleEvents })}
        >
          <span>
            {multipleEvents
              ? events.size
              : events.first().getIn(['stock', 'ticker'])}
          </span>
        </div>
      </div>
    );
  };

  renderDay = (day, i) => {
    const date = moment(day);
    const hasDate = !isNaN(date as any); // TODO not sure why they did this, but kept unchange cause had no time to test
    const relevantEvents = this.getRelevantEvent(day);
    const hasEvent = relevantEvents.size > 0;
    const hasMultipleEvents = relevantEvents.size > 1;
    const diffFromToday = date.diff(moment(new Date()), 'days', true);
    const isToday = diffFromToday > -1 && diffFromToday < 0;

    return (
      <td
        className={classNames(
          hasEvent ? relevantEvents.first().get('type') : '',
          {
            'has-event-or-today': hasEvent || isToday,
            'has-multiple-events': hasMultipleEvents,
            'has-event': hasEvent,
            today: isToday
          }
        )}
        key={i}
      >
        <div className="cell">
          <span className="day-label">{hasDate ? date.format('DD') : ''}</span>

          {isToday ? this.renderToday() : null}
          {hasEvent ? this.renderEvent(relevantEvents) : null}
        </div>
      </td>
    );
  };

  renderWeek = (week, i) => {
    return <tr key={i}>{week.map(this.renderDay)}</tr>;
  };

  // watch out: mutation ahaed
  getMonthMatrix(date) {
    let lastDate = moment(date);
    let currentDate = moment(date);
    let result = [[]];
    let currentWeek = result[result.length - 1];

    const pushEmptyWeeks = month => {
      while (month.length < 6) {
        month.push([null, null, null, null, null, null, null]);
      }
    };

    const pushEmptyFirstDays = (week, weekDay) => {
      while (week.length < weekDay.day()) {
        week.push(null);
      }
    };

    const pushWeeks = (month, weekDay) => {
      while (weekDay.month() === date.month()) {
        if (weekDay.week() !== lastDate.week()) {
          currentWeek = month[month.length - 1];
          month.push([]);
          currentWeek = month[month.length - 1];
        }

        month[month.length - 1].push(moment(weekDay));
        lastDate = moment(weekDay);
        weekDay.add(1, 'days');
      }
    };

    const pushEmptyLastDays = week => {
      while (week.length < 7) {
        week.push(null);
      }
    };

    pushEmptyFirstDays(currentWeek, currentDate);
    // pushWeeks(result, currentDate, lastDate);
    pushWeeks(result, currentDate);
    pushEmptyLastDays(currentWeek);
    pushEmptyWeeks(result);

    return List(result);
  }

  renderMonth = (month: Date, index: number) => {
    const curremtMonth = moment(month);
    const weekDaysShort = this.msg('weekDaysShort');
    const weeks = this.getMonthMatrix(curremtMonth);
    return (
      <div className="calendar-month" key={index}>
        <table>
          <caption>
            {curremtMonth.format('MMMM')}
            &nbsp;
            {curremtMonth.year()}
          </caption>
          <thead>
            <tr>{weekDaysShort.map(this.renderTh)}</tr>
          </thead>
          <tbody>{weeks.map(this.renderWeek)}</tbody>
        </table>
      </div>
    );
  };

  monthsTotalToMonths(date, total, arr = List()) {
    return total === 0
      ? arr
      : this.monthsTotalToMonths(
          moment(date)
            .add({ months: 1 })
            .toDate(),
          total - 1,
          arr.push(
            moment(date)
              .startOf('month')
              .toDate()
          )
        );
  }

  render() {
    const { date, events, fnNext, fnPrev, monthsTotal, msg } = this.props;
    const months = this.monthsTotalToMonths(date, monthsTotal).sort(
      (month1, month2) => (month1 < month2 ? 1 : -1)
    );

    return (
      <div className="calendar">
        <div>{months.reverse().map(this.renderMonth)}</div>
        <div className="index">
          <div className="total">
            {msg.total}:
            <strong>{events.size}</strong>
          </div>
          <div className="title">{msg.index}:</div>
          <ul>
            {['earnings', 'dividend'].map(type => (
              <li className={type} key={type}>
                {msg[type]}
                {type === 'dividend' && (
                  <div className="tooltip-wrapper">
                    <button>i</button>
                    <div className="tooltip">
                      The Ex-Dividend Date - If you purchase a stock on its
                      ex-dividend date or after, you will not receive the next
                      dividend payment. Instead, the seller gets the dividend.
                      If you purchase before the ex-dividend date, you get the
                      dividend.
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <menu>
          <button className="prev" disabled={!fnPrev} onClick={fnPrev}>
            {msg.prev}
          </button>
          <button className="next" disabled={!fnNext} onClick={fnNext}>
            {msg.next}
          </button>
        </menu>
      </div>
    );
  }
}
export default Calendar;
