import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import rx from 'rx-lite';
import Selector from 'tr-selector';
import EE from 'tiny-emitter';
import CategoryMenu from './category-menu.react.js';
import searchCategories, {
  msg as categoryMsg,
  defaultCategories
} from './categories';
import classnames from 'classnames';
import Component from 'react-pure-render/component';

const defaultOpts = {
  categories: [
    {
      label: 'All',
      value: '',
      class: 'all'
    }
  ],
  onType: () => console.warn('onType not provided to AutoComplete component'),
  onSubmit: () =>
    console.warn('onSubmit not provided to AutoComplete component'),
  placeholder: 'Search Expert or Stock'
};

const keyDownObservables = (keydownObservable, ...codes) =>
  codes.map(code =>
    keydownObservable.filter(x => {
      const keycode = x.keyCode || x.charCode;
      return Array.isArray(code)
        ? code.some(c => c === keycode)
        : code === keycode;
    })
  );

const addCategorySelectorToScope = (scope, opts) => {
  const defaultPlaceholder = scope.placeholder;
  const categorySelector = (scope.categorySelector = new Selector(
    opts.categories,
    0
  ));

  categorySelector.subscribe(
    val => (scope.placeholder = get(val, 'placeholder') || defaultPlaceholder)
  );

  scope.isCategorySelected = cat => categorySelector.isSelected(cat);

  scope.getSelectedCategory = () =>
    get(categorySelector.getSelected(), 'class') ||
    get(categorySelector.getSelected(), 'value');

  return categorySelector;
};

const linkSearch = (
  submitEventObservable,
  valueChangeObservable,
  keydownObservable,
  optionClickedObservable,
  categoryChangeObservable,
  ctrl,
  scope,
  opts
) => {
  opts = Object.assign({}, defaultOpts, opts);

  const categorySelector = addCategorySelectorToScope(scope, opts);

  const searchCategory = () =>
    opts.category || get(categorySelector.getSelected(), 'value', '');

  const onType = term =>
    rx.Observable.fromPromise(opts.onType(term, searchCategory()))
      .catch(rx.Observable.return(term)) // soft failure if error is thrown
      .retry(5);

  const onSubmit = term =>
    rx.Observable.fromPromise(opts.onSubmit(term))
      .catch(rx.Observable.return(term)) // soft failure if error is thrown
      .retry(5);

  // Observables start here
  const submit = submitEventObservable.share();

  const searchTerm = valueChangeObservable
    // ignoring slashes, jira #7490
    // ignoring spaces, jira #11008
    .map(x => x && x.replace(/[\/\\]/g, '').trim());

  const throttledSearchTerm = searchTerm.throttle(350).distinctUntilChanged();

  const booleanSearchTerm = throttledSearchTerm.map(Boolean);

  const keydown = keydownObservable;

  // translate keycodes to specific keydown keys
  const [enter, backspace, arrowKeys] = keyDownObservables(keydown, 13, 8, [
    38,
    40
  ]);
  //

  const categoryChanged = categoryChangeObservable.do(val => {
    ctrl.closeCategoryMenu();
    categorySelector.select(val);
    ctrl.focusSearchElement();
  });

  const categoryChangedSearch = categoryChanged
    .withLatestFrom(booleanSearchTerm, (a, b) => b)
    .filter(Boolean);

  // enter pressed or submit button clicked
  const submitEvent = rx.Observable.merge(enter, submit)
    .throttle(500)
    .share();
  const arrowKeysUpOrDown = arrowKeys
    .do(e => e.preventDefault())
    // turns the keycode to +-1, how convenient
    .map(e => (get(e, 'keyCode') || get(e, 'charCode')) - 39);
  //
  // const arrowKeysSearch = arrowKeys
  // 		.filter(() => !get(scope, 'results.length') && !scope.resultsShown)
  // 		.withLatestFrom(valueChangeObservable, (a, b) => b)
  // 		.filter(Boolean);

  const searchInit = rx.Observable
    // .merge(throttledSearchTerm, arrowKeysSearch);
    .merge(throttledSearchTerm, categoryChangedSearch)
    .withLatestFrom(throttledSearchTerm, (a, b) => b);

  const relevantSearch = searchInit.filter(Boolean).share();

  const searcher = relevantSearch
    .flatMapLatest(onType)
    .share() // don't want to make multiple requests
    .do(data => ctrl.onDataReceived(data))
    .share();

  const focusedIndex = rx.Observable.merge(
    arrowKeysUpOrDown,
    searcher.map(() => null)
  ).scan(null, (index, dir) => {
    const length = get(scope, 'results.length') || 0;
    if (!dir) {
      return null;
    }

    if (index !== null) {
      index += dir;
    }

    if (index < 0) {
      index = length - 1;
    }

    return index % length;
  });

  const focusedObservable = focusedIndex
    .map(ind => {
      const option = get(scope.results[ind], 'option', null);
      const id = get(scope.results[ind], 'uid', null);
      ctrl.setFocusedOption(id);
      return option;
    })
    .startWith(null);

  const pauser = new rx.Subject();

  const submission = submitEvent
    // get list item's value
    .withLatestFrom(throttledSearchTerm, focusedObservable, (a, b, c) => c || b)
    .filter(Boolean)
    // if a search is in progress, this will pause the submission event.
    // one loading is complete, if submission was made during search,
    // only one item will be in the sequence using `throttle`.
    .pausableBuffered(pauser) // aka 'not loading'
    .throttle(1);

  // option in dropdown clicked
  const optionClicked = optionClickedObservable.filter(Boolean);

  const submitStream = rx.Observable.merge(optionClicked, submission);

  const isSearchingObservable = new rx.Subject();

  relevantSearch.map(() => false).subscribe(isSearchingObservable);
  searcher.map(() => true).subscribe(isSearchingObservable);

  const submitted = submitStream
    .do(data => ctrl.submitted(data))
    .pausableBuffered(isSearchingObservable)
    .throttle(1)
    .flatMapLatest(onSubmit)
    .share();

  arrowKeys.subscribe(() => {
    ctrl.openDataList(true);
  });

  const toggleLoading = rx.Observable.merge(
    booleanSearchTerm,
    categoryChangedSearch.map(() => true),
    searcher.map(() => false),
    submitted.map(() => false)
  ).subscribe(val => {
    ctrl.toggleLoading(val);
    pauser.onNext(!val);
  });

  const toggleDataList = rx.Observable.merge(
    booleanSearchTerm.filter(x => !x),
    submitted.map(() => false),
    searcher.map(() => true)
  ).subscribe(val => ctrl.openDataList(val));

  // backspace.subscribe(() => input.focus());

  categoryChanged.subscribe(val => {
    ctrl.closeCategoryMenu();
    categorySelector.select(val);
  });

  submitted.share().subscribe(term => {
    scope.searchTerm = '';
    categorySelector.selectDefault();
  });
};

class SearchController {
  constructor(emitter) {
    if (!emitter || !emitter.emit) {
      throw new Error('SearchController must be initialized with an emitter');
    }
    this.emit = emitter.emit.bind(emitter);
    this.list = [];
  }

  submitted(data) {
    this.emit('submitted', data);
  }

  onDataReceived(data) {
    if (data) {
      this.emit('onDataReceived', data);
    }
  }

  closeCategoryMenu() {
    this.emit('closeCategoryMenu');
  }

  openCategoryMenu() {
    this.emit('openCategoryMenu');
  }

  focusSearchElement() {
    this.emit('focusSearchElement');
  }

  setFocusedOption(id) {
    this.emit('setFocusedOption', id);
  }

  toggleLoading(value) {
    this.emit('toggleLoading', value);
  }

  toggleDataList() {
    this.emit('toggleDataList');
  }

  openDataList(shouldOpen) {
    if (shouldOpen) {
      this.emit('openDataList');
    } else {
      this.emit('submitted');
    }
  }
}

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = { results: props.results || [], value: '' };
    this.subject = new rx.Subject();
    this.emitter = new EE();
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);

    const submitEventObservable = this.getObservable('submit');
    const valueChangeObservable = this.getObservable('change');
    const keyDownObservable = this.getObservable('keydown');
    const optionClickedObservable = rx.Observable.empty();
    const categoryChangeObservable = this.getObservable('categoryChanged');

    const emitter = {
      emit: (...args) => {
        this.props.emitter.emit(...args);
        this.emitter.emit(...args);
      }
    };

    this.props.emitter.on('submit', this.onSubmit);

    this.emitter.on('toggleLoading', loading => this.setState({ loading }));
    this.emitter.on('focusSearchElement', () => this.refs.input.focus());
    this.emitter.on('submitted', () => this.setState({ value: '' }));

    const ctrl = (this.ctrl = new SearchController(emitter));

    const opts = this.props;

    const self = this;

    // adapter between the old, angular code that handles these and react.
    const scope = {
      get results() {
        return self.state.results;
      },
      set placeholder(val) {
        self.setState({ placeholder: val });
      },
      get defaultPlaceholder() {
        return self.props.defaultPlaceholder;
      },
      set isCategorySelected(fn) {
        self.isCategorySelected = fn;
      },
      set getSelectedCategory(fn) {
        self.getSelectedCategory = fn;
      },
      set categorySelector(selector) {
        self.categorySelector = selector;
      },
      get resultsShown() {
        const { resultsShown } = self.props;
        return typeof resultsShown === 'undefined'
          ? false // if not supplied to the component at all, noop
          : resultsShown;
      }
    };

    linkSearch(
      submitEventObservable,
      valueChangeObservable,
      keyDownObservable,
      optionClickedObservable,
      categoryChangeObservable,
      ctrl,
      scope,
      opts
    );
  }

  onWindowClick = e => {
    e.stopPropagation();
  };

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  componentWillReceiveProps({ selectedCategory, results }) {
    if (selectedCategory && selectedCategory !== this.props.selectedCategory) {
      this.subject.onNext({ type: 'categoryChanged', value: selectedCategory });
    }

    if (results) {
      this.setState({
        results:
          !selectedCategory || selectedCategory === searchCategories.all.key
            ? results
            : results.filter(({ category }) => selectedCategory === category)
      });
    }
  }

  getObservable(_type) {
    return this.subject.filter(({ type }) => type === _type).pluck('value');
  }

  onChange = event => {
    const { value } = event.target;
    this.setState({ value });
    this.subject.onNext({ type: 'change', value });
  };

  onSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    this.subject.onNext({ type: 'submit', value: event });
  };

  onKeyDown = event => {
    this.subject.onNext({ type: 'keydown', value: event });
  };

  onCategoryChange = event => {
    this.props.emitter.emit('categoryChanged', event);
    this.categorySelector.select(event);
  };

  onCategoryMenuOpen = () => {
    this.props.emitter.emit('categoryMenuOpen');
  };

  renderCategoryMenu({ categories = [] } = this.props) { }

  static propTypes = {
    categories: PropTypes.array,
    emitter: PropTypes.object,
    placeholder: PropTypes.string,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  };

  render(
    {
      placeholder,
      categories = defaultCategories,
      msg = { categories: categoryMsg },
      selectedCategory
    } = this.props
  ) {
    placeholder =
      placeholder ||
      get(
        msg,
        ['categories', selectedCategory, 'placeholder'],
        categoryMsg.all.placeholder
      );

    const { loading, value } = this.state;

    const hasCategoryMenu =
      this.props.hasCategoryMenu !== false && categories.length > 1;

    const loader = get(msg, 'loader', 'loading');

    return (
      <div className="wrapper tr-auto-complete-wrapper">
        {hasCategoryMenu && (
          <div className="categoryMenuWrapper">
            <CategoryMenu
              className="categoryMenu"
              selectedCategory={selectedCategory || searchCategories.all.key}
              onOpen={this.onCategoryMenuOpen}
              onChange={this.onCategoryChange}
              msg={msg.categories}
              categories={categories}
            />
          </div>
        )}
        <form onSubmit={this.onSubmit}>
          <input
            ref="input"
            value={value}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            placeholder={placeholder}
          />
          <img
            src="/assets/img/loading_smallest.gif"
            className={classnames('loader', { loading })}
          />
        </form>
      </div>
    );
  }
}

export default AutoComplete;

export const __hotReload = true;
