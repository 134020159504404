import { getGlobalActions } from '../lib/actions';
import report from './analytics';
import * as React from 'react';
import * as styles from './styles.istyl';
import { PlansLink } from '../components/PlansLink';
import * as classNames from 'classnames';
import msg from '../../common/intl/messages/en';
import * as helperStyles from '../../common/styles/common.istyl';
import { premiumUpgradeUrl, premiumUpgradeUrl2 } from '../lib/utils';
import { connect } from 'react-redux';
import { AuthRecord } from '../../common/types';
import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import { config, noDifferentImageForResolutions } from './config';
import { Actions } from '../../common/types';
import * as _ from 'lodash';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export const lastLockedType = 'lastLockedType';
// the new "locked" screen to convert users to paying customers.
@connect(({ auth }) => ({ auth }), mapDispatchToProps)
export class TargetedWebPage extends React.PureComponent<{
  params: { trigger: string; type: string };
  mediaQuery: MediaQuery;
  // TODO don't request things that come via connect, use a differente
  // component, a container for that.
  auth: AuthRecord;
}> {
  state = { type: '' };
  // TODO devise new way of making sure page exists
  componentDidMount() {
    // Make sure any dialogs opened were closed
    if (getGlobalActions().closeDialog) getGlobalActions().closeDialog();
    this.onMount();
  }
  componentDidUpdate() {
    this.onMount();
  }
  render() {
    const { auth, mediaQuery } = this.props;
    const datum = caseInsensitiveGetter(config, this.state.type);
    const { title = '', subtitle = '', img = 'mockup' } = datum || {};
    const upgradeURL = premiumUpgradeUrl2(this.props.auth, 'nasdaqQuarterly');
    let filename;
    try {
      filename = this.getImg(img);
    } catch (e) {
      if (process.env.NOT_PROD) console.warn('No image found!');
      filename = this.getImg('mockup');
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>{title}</div>
        <div className={styles.subheader}>{subtitle}</div>
        <div className={styles.picture1366wrapper}>
          <div
            className={classNames(
              helperStyles.showUpperDesktop,
              styles.feelItImg,
            )}
          >
            <img src={filename} />
          </div>
          <div className={styles.rightSide}>
            <div className={styles.pkgName}>Smart Portfolio Pro</div>
            <div className={styles.pleasebuy}>
              The perfect membership for you
            </div>
            <div className={styles.money}>
              <div className={styles.amount}>$24.95</div>
              /Month
            </div>
            <div className={styles.frequency}>Charged Quarterly</div>
            <div className={styles.background720wrapper}>
              <div
                className={classNames(
                  helperStyles.hideMobile,
                  styles.le720background,
                )}
              />
              <a className={styles.noTextDecoration} href={upgradeURL}>
                <div className={styles.subscribeBtn}>Subscribe Now</div>
              </a>
              <div className={classNames(styles.plansPageLinkWrapper)}>
                <div
                  className={classNames(
                    styles.morepleasebuy,
                    helperStyles.hideMobile,
                  )}
                >
                  See all Pro features and compare plans
                </div>
                <PlansLink className={styles.plansPageLink}>
                  <span className={helperStyles.showMobile}>
                    Or visit Plans & Pricing >>
                  </span>
                  <span className={helperStyles.hideMobile}>
                    Visit Plans & Pricing >>
                  </span>
                </PlansLink>
              </div>
              <div
                className={classNames(
                  helperStyles.hideUpperDesktop,
                  styles.feelItImg,
                )}
              >
                <img src={filename} />
              </div>
              <div className={styles.bottomSlicedBackgroundColor} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  getImg = (img: string /* But is it really :string? */) => {
    const { mediaQuery } = this.props;
    const rawRes =
      noDifferentImageForResolutions.indexOf(this.state.type) !== -1
        ? ''
        : mediaQuery.get('desktop')
          ? 1920
          : mediaQuery.get('laptopM')
            ? 1366
            : mediaQuery.get('tablet') ? 768 : 320;
    const res = rawRes ? rawRes + '-' : '';
    const filename = res + img + '.png';
    return reqImg(filename);
  };
  onMount = () => {
    const type = localStorage.getItem(lastLockedType) || '';
    if (process.env.NOT_PROD)
      console.info('Heyo! targeted web page type is:', type);
    this.setState(
      { type },
      () => process.env.NOT_PROD && console.info('TYPE IS NOW', type),
    );
  };
}

function reqImg(img) {
  return process.env.IS_BROWSER ? require(`./img/${img}`) : '';
}

// helper function so I wont
// have to mess with the en.js dictionairy.
function caseInsensitiveGetter(obj: {}, rawProp: string) {
  const prop = rawProp.toLowerCase();
  const list = Object.keys(obj)
    .map(key => ({ key, ikey: key.toLowerCase() }))
    .filter(d => d.ikey === prop);

  if (list.length > 0) return obj[list[0].key];
  else {
    if (process.env.NOT_PROD) console.warn('No key for', rawProp);
    return '';
  }
}
