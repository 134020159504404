import reporter from '../analytics';

export const events = {
  visited: 'visited-screener-page',
  clickedUnlockedFeature: plan => `clicked-screener-filter-${plan}`,
  lockedPremiumClick: () => ({
    event: 'clicked-locked-feature',
    props: { feature: 'screener-filter-premium' }
  }),
  clickedSearch: 'clicked-screener-search-button',
  clickedShowMore: 'clicked-screener-show-more',
  clickedTd: 'clicked-screener-expand-result',
  addedToPortfolio: ({ ticker }) => ({
    event: 'added-screener-result-to-portfolio',
    props: { ticker }
  }),
  clickLockedSentiment: 'clicked-screener-sentiment-locked',
  addResultToPortfolio: 'added-screener-result-to-portfolio',
  clickRelatedLink: 'clicked-related-link',
  clickedRestrictedTrigger: type => type
};

const report = reporter(events);

export default report;
