import * as React from 'react';
import { range } from 'lodash';
import { FormattedMessage } from 'react-intl';

export type LabelProps = {
  msg: string;
  value: any;
  isEditing?: boolean;
  isDisabled?: boolean;
  onChangePercentEditable?: () => void;
  onChangeRankingSelector?: () => void;
};

export const Label = ({
  msg,
  isDisabled,
  onChangePercentEditable,
  value,
  isEditing = false,
  onChangeRankingSelector
}: LabelProps) => (
  <FormattedMessage
    defaultMessage={msg}
    id={msg}
    values={{
      percentEditable: (
        <span className="number-input">
          {isEditing ? (
            <input
              max={10}
              min={1}
              step={1}
              onChange={onChangePercentEditable}
              readOnly={isDisabled}
              type="number"
              value={value || 1}
            />
          ) : (
            value + '%'
          )}
        </span>
      ),
      rankingSelector: (
        <span className="start-select">
          {isEditing ? (
            <select onChange={onChangeRankingSelector} value={value}>
              {range(1, 6).map(rank => (
                <option key={rank} value={rank - 1}>
                  {rank}
                </option>
              ))}
            </select>
          ) : (
            value
          )}
        </span>
      )
    }}
  />
);
