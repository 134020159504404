import { Keyword } from './types';
import * as React from 'react';
import { scale } from 'd3';
import * as styles from './WordCloud.istyl';

interface Props {
  keywords: Keyword[];
  ticker?: string;
  height?: number;
}

export class WordCloudContent extends React.PureComponent<Props> {
  private opacityScale: scale.Quantize<number>;
  private fontSizeScale: scale.Quantize<number>;

  shouldComponentUpdate(newProps) {
    return this.props.ticker !== newProps.ticker;
  }

  constructor(props) {
    super(props);

    const keywords = props.keywords || [];

    const sortedKeywords = [...keywords].sort(
      (k1, k2) => k1.mentions - k2.mentions
    );

    const firstMention = sortedKeywords[0];
    const lastMention = sortedKeywords[sortedKeywords.length - 1];

    const minMentions = firstMention ? firstMention.mentions : 0;
    const maxMentions = lastMention ? lastMention.mentions : 0;

    this.opacityScale = scale
      .quantize<number>()
      .domain([minMentions, maxMentions])
      .range([0.4, 0.65, 0.8, 1]);

    this.fontSizeScale = scale
      .quantize<number>()
      .domain([minMentions, maxMentions])
      .range([13, 18, 20, 30]);
  }

  keywordStyle(keyword: Keyword, ratio: number) {
    return {
      opacity: this.opacityScale(keyword.mentions),
      fontSize: resize(
        keyword,
        this.fontSizeScale(keyword.mentions) / ratio,
        180
      )
    };
  }

  render({ keywords = [], height = 220 } = this.props) {
    const sizes = keywords
      .map(x => x.mentions)
      .map(this.fontSizeScale)
      .reduce((x, y) => x + y, 0);
    const ratio = sizes / height;
    const containerWidth = 425;

    return (
      <ul className={styles.wordCloudContent}>
        {keywords.map((keyword, i) => {
          const importanceStyle = this.keywordStyle(keyword, ratio);

          const rand = Math.random();
          const remainingWidth = Math.max(
            containerWidth -
              ((rand < 0.5 ? rand : 1 - rand) * containerWidth +
                phraseWidth(keyword, importanceStyle.fontSize)),
            0
          );

          const pos =
            rand < 0.5
              ? {
                  marginLeft: `${rand *
                    100 *
                    remainingWidth /
                    containerWidth}%`,
                  float: 'left'
                }
              : {
                  marginRight: `${(1 - rand) *
                    100 *
                    remainingWidth /
                    containerWidth}%`,
                  float: 'right'
                };

          return (
            <li key={i}>
              <span style={Object.assign({}, importanceStyle, pos)}>
                {keyword.phrase}
              </span>
            </li>
          );
        })}
      </ul>
    );
  }
}

export class WordCloud extends React.PureComponent<Props> {
  render() {
    const { keywords = [] } = this.props;
    const hasData = 0 < keywords.length;

    return (
      <div className={styles.wordCloud}>
        <div className={styles.list}>
          {!hasData ? (
            <div className={styles.noData}>No popular keywords found</div>
          ) : (
            <WordCloudContent {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

const size30ProximaNovaWidths = {
  '0': 15,
  '1': 15,
  '2': 15,
  '3': 15,
  '4': 15,
  '5': 15,
  '6': 15,
  '7': 15,
  '8': 15,
  '9': 15,
  a: 13,
  A: 13,
  b: 15,
  B: 15,
  c: 14,
  C: 14,
  d: 15,
  D: 15,
  e: 13,
  E: 13,
  f: 10,
  F: 10,
  g: 15,
  G: 15,
  h: 15,
  H: 15,
  i: 8,
  I: 8,
  j: 9,
  J: 9,
  k: 15,
  K: 15,
  l: 8,
  L: 8,
  m: 23,
  M: 23,
  n: 15,
  N: 15,
  o: 15,
  O: 15,
  p: 15,
  P: 15,
  q: 15,
  Q: 15,
  r: 10,
  R: 10,
  s: 12,
  S: 12,
  t: 8,
  T: 8,
  u: 15,
  U: 15,
  v: 15,
  V: 15,
  w: 22,
  W: 22,
  x: 15,
  X: 15,
  y: 15,
  Y: 15,
  z: 13,
  Z: 13,
  ' ': 8,
  '.': 7,
  '?': 14,
  '!': 9,
  '-': 10,
  _: 15
};
function getLetterSize(letter) {
  return size30ProximaNovaWidths[letter] || 20;
}

function phraseWidth(keyword: Keyword, size: number): number {
  return keyword.phrase
    .split('')
    .map(letter => getLetterSize(letter) * size / 30)
    .reduce((a, b) => a + b);
}

function randomAwayFrom(target: number, delta: number): number {
  const r = Math.random();
  if (Math.abs(r - target) > delta) return r;
  return randomAwayFrom(target, delta);
}

function resize(keyword: Keyword, size: number, maxSize: number): number {
  while (phraseWidth(keyword, size) > maxSize) {
    size--;
  }
  return size;
}
