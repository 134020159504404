import React from 'react';
import PropTypes from 'prop-types';
import { suits } from '../../common/enums';
import report from './analytics';
import Component from 'react-pure-render/component';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const infoSrc = require('./info.png');

import classnames from 'classnames';
import { dashboardPath } from '../../common/config';
import { memoize, merge } from 'lodash';

// import injectTapEventPlugin from 'react-tap-event-plugin';

import { ApiDecorator } from '../ApiDecorator/ApiDecorator.react';

// injectTapEventPlugin();

import './index.styl';

import msg from './en';

const inputStateProps = {
  [suits.INVALID]: props => ({
    errorText: msg.inputs[props.type].errorMessage,
  }),
  [suits.ERROR]: props => ({ errorText: msg.serverErrorMessage }),
  [suits.LOADING]: props => ({}),
  [suits.SUCCESS]: () => ({}),
};

const getInputProps = props => {
  const getter = inputStateProps[props.state];
  const stateProps = getter ? getter(props) : null;
  return merge({ name: props.type }, stateProps);
};

const ErrorMessage = props => (
  <div className="error-message">{props.children || props.errorText}</div>
);

export const Select = props => {
  const { errorText } = getInputProps(props);
  const val = props.value || 'def';
  return (
    <div className={classnames({ hasError: Boolean(errorText) })}>
      <select onChange={props.handleChange} value={val}>
        <option disabled value="def" />
        {props.options.map((opt, i) => (
          <option
            key={i}
            value={opt}
            dangerouslySetInnerHTML={{ __html: `${opt}` }}
          />
        ))}
      </select>
      <ErrorMessage>{errorText}</ErrorMessage>
    </div>
  );
};

export const Text = props => {
  const { errorText } = getInputProps(props);
  return (
    <div className={classnames({ hasError: Boolean(errorText) })}>
      <input
        onChange={props.handleChange}
        type="text"
        value={props.value}
        placeholder={props.placeholder}
      />
      <ErrorMessage>{errorText}</ErrorMessage>
    </div>
  );
};

const FormInput = props => {
  const isSelect = Boolean(props.options);
  const loading = props.loading;
  return (
    <div
      className={classnames('field', props.type, { select: isSelect, loading })}
    >
      {isSelect ? <Select {...props} /> : <Text {...props} />}
    </div>
  );
};

@ApiDecorator('myPerformanceFields')
export default class MyPerformanceDialog extends Component {
  componentDidMount() {
    report('visited');
  }

  componentDidUpdate() {
    const state = this.props.performance.getIn(['form', 'state']);
    if (state === suits.SUCCESS) {
      this.props.actions.resetMyDetails();
      this.props.actions.closeDialog();
    }
  }

  handleChange = memoize(type => persist => {
    const { updateField, updateFieldServer } = this.props.actions;
    const action = persist ? updateFieldServer : updateField;
    return (e, ind, value) => action({ type, value: value || e.target.value });
  });

  getInputs() {
    const fields = this.props.performance.get('form').get('fields');
    const inputsProps = fields.toMap().map(value =>
      value
        .toMap()
        .merge({
          handleChange: this.handleChange(value.type)(false),
          handleChangeServer: this.handleChange(value.type)(true),
          loading: value.state === suits.LOADING,
        })
        .merge(msg.inputs[value.type]),
    );

    const inputs = inputsProps.map(input => FormInput(input.toJS())).toObject();

    return inputs;
  }

  onSubmit = e => {
    e.preventDefault();
    const { updateFieldServer, postForm } = this.props.actions;
    const fields = this.props.performance.get('form').get('fields');
    return updateFieldServer(fields);
  };

  allFormsFilled() {
    const fields = this.props.performance.get('form').get('fields');
    return fields.every(
      f => f.get('state') === suits.SUCCESS && f.get('value'),
    );
  }

  isLoading(inputName) {
    const fields = this.props.performance.get('form').get('fields');
    const state = this.props.performance.get('form').get('state');
    if (inputName) {
      return (
        fields.find(f => f.get('type') === inputName).get('state') ===
        suits.LOADING
      );
    }
    return (
      state === suits.LOADING ||
      fields.some(f => f.get('state') === suits.LOADING)
    );
  }

  renderSentence = sentence => {
    const inputs = this.getInputs();
    return sentence
      .split(/[{}]/)
      .filter(Boolean)
      .map((part, key, arr) => {
        const isInput = inputs[part];
        const props = isInput
          ? { children: inputs[part] }
          : { dangerouslySetInnerHTML: { __html: part } };
        return (
          <span key={key} style={{ display: 'inline-block' }}>
            <span {...props} />
            {isInput && key === arr.length - 1 ? '.' : ''}
          </span>
        );
      });
  };

  render() {
    const state = this.props.performance.getIn(['form', 'state']);

    return (
      <div className={classnames('my-performance-dialog-details', state)}>
        {state !== suits.SUCCESS ? (
          <div>
            <div className="form-title">{msg.form.title}</div>
            <form onSubmit={this.onSubmit}>
              {msg.form.sentences.map((sentence, i) => (
                <div className="sentence">
                  {this.renderSentence(sentence)}
                </div>
              ))}
              <button>{msg.form.button}</button>
            </form>
          </div>
        ) : (
            <div className="success">
              <h3 className="success-title">{msg.success.title}</h3>
              <div className="success-content">{msg.success.content}</div>
            </div>
          )}
      </div>
    );
  }
}
