// import { List as _List, Map as _Map, Iterable } from 'immutable';

// Type definition for immutable 3.8 is broken.
// This is a remedy.
// export interface List<T> extends _List<T> {
//   map<Y>(
//     mapper: (value: T, key: number, iter?: List<T>) => Y
//   ): List<Y>

//   filter(
//     predicate: (value: T, key: number, iter?: List<T>) => boolean
//   ): List<T>
// }

// export interface Map<K, V> extends _Map<K, V> {
//   map<Y>(
//     mapper: (value: V, key: K, iter?: Map<K, V>) => Y
//   ): Iterable<K, Y>

//   filter(
//     predicate: (value: V, key: K, iter?: Map<K, V>) => boolean
//   ): Iterable<K, V>
// }

export enum Portfolio {
  AveragePortfolio,
  BestPerformingPortfolio
}

export interface Action {
  type: string;
  payload?: {};
}

export type ActionCreator = (...data: any[]) => Action;
