import { throttle } from 'lodash';
import * as React from 'react';
import { ComponentClass } from 'react';

interface State {
  resizing: boolean;
  width: number;
}

declare type NonVoidClassDecorator = (target: any) => any;

// TODO should probably rename to redrawPageOnResize, since it dependes on the windows width, and not the parent container, as one would expect
// <T>(Comp: ComponentClass<T>) => ComponentClass<T>
export const redrawOnResize: NonVoidClassDecorator = (
  Comp: ComponentClass<any>,
) =>
  class extends React.PureComponent<any, State> {
    // export const redrawOnResize = <T extends {}>(Comp: ComponentClass<T>): ComponentClass<T> => class extends React.PureComponent<T, State> {
    state = { resizing: false, width: 0 };

    private resize = event => {
      const width = this.getWindowWidth();
      if (this.state.width !== width) {
        this.setState(state => ({ width, resizing: true }));
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => this.setState(s => ({ ...s, resizing: false })),
          1000,
        );
      }
    };
    private throttledResize = throttle(this.resize, 250);
    private timeout?: any; // TODO timeout type

    componentDidMount() {
      const width = this.getWindowWidth();
      this.setState({ width });
      window.addEventListener('resize', this.throttledResize);
      window.addEventListener('orientationchange', this.throttledResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.throttledResize);
      window.removeEventListener('orientationchange', this.throttledResize);
      clearTimeout(this.timeout);
      this.throttledResize.cancel();
    }

    getWindowWidth() {
      return window.innerWidth;
    }

    render() {
      return this.state.resizing ? (
        <div
          style={{
            fontFamily: 'Proxima Nova Alt',
            textAlign: 'center',
          }}
        >
          Resizing, please wait...
        </div>
      ) : (
        <Comp {...this.props} />
      );
    }
  };

export const redrawWithChildrenOnResize = <T extends {}>(
  Comp: ComponentClass<T>,
): ComponentClass<T> =>
  class extends React.PureComponent<T, State> {
    state = { resizing: false, width: 0 };

    private resize = event => {
      const width = this.getWindowWidth();
      if (this.state.width !== width) {
        this.setState(state => ({ width, resizing: true }));
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => this.setState(s => ({ ...s, resizing: false })),
          1000,
        );
      }
    };

    private throttledResize = throttle(this.resize, 250);
    private timeout?: any; // TODO timeout type

    componentDidMount() {
      const width = this.getWindowWidth();
      this.setState({ width });
      window.addEventListener('resize', this.throttledResize);
      window.addEventListener('orientationchange', this.throttledResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.throttledResize);
      window.removeEventListener('orientationchange', this.throttledResize);
      clearTimeout(this.timeout);
      this.throttledResize.cancel();
    }

    getWindowWidth() {
      return window.innerWidth;
    }

    render() {
      return this.state.resizing ? (
        <Comp {...this.props}>
          <div
            style={{
              fontFamily: 'Proxima Nova Alt',
              textAlign: 'center',
            }}
          >
            Resizing, please wait...
          </div>
        </Comp>
      ) : (
        <Comp {...this.props} />
      );
    }
  };
