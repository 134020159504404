import { Map } from 'immutable';
import { getReq } from '../../common/api';
import { adaptStocks } from '../../common/api-adapter';
import { appendSignature } from '../../common/dashboard/actions';
import { categoryToCategory } from './filtersConf';
export const GET_STOCKS = 'GET_STOCKS';

export const GET_STOCKS_START = 'GET_STOCKS_START';
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS';
export const GET_STOCKS_ERROR = 'GET_STOCKS_ERROR';

// todo
const makeApiCall = (fields, page) => {
  // Takes {foo: ['bar', 'baz']} and turns it into "foo=bar&foo=baz"
  const qs = fields
    .filter(v => v.length > 0)
    .entrySeq()
    .toArray()
    .map(([key, value]) => [key, value.join(`&${key}=`)])
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return getReq<any>(`/api/Screener/GetETFs?${qs}&page=${page}`)
    .then(({ count, data }) => ({
      stocks: adaptStocks('etfScreener')(data),
      count
    }))
    .then(appendSignature());
};

export const changeETFScreenerFilters = ({
  fields,
  page
}: {
  fields: Map<string, any>;
  page: number;
}) => {
  const mappedFields = fields.map((selected, label) => {
    if (label !== 'category') {
      return selected;
    }
    const mapped = categoryToCategory.get(selected[0]);
    return mapped ? [mapped] : selected;
    // This has a problem with the .equals() which makes the show more not working.
    // To fix this, make categoryToCategory.get() return an array, then equals will return true.
  });
  const subtype = 'etf';
  return {
    type: GET_STOCKS,
    payload: {
      data: { subtype, page, fields: mappedFields },
      promise: (async () => {
        let res = await makeApiCall(mappedFields, page);
        return { ...res, fields: mappedFields, page, subtype };
      })()
    }
  };
};
