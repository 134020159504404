import { ceilToNearest } from './utils';
import {
  APITopStock,
  NewsSentimentAPIResponse,
  NewsSentimentStoreProps,
  SectorEnum,
  SentimentEnum,
  TopStock,
  NewsSentimentAPIResponseAdapted
} from './types';

const adaptKeywords = keywords =>
  keywords.map(keyword => ({
    phrase: keyword.text,
    mentions: keyword.grade
  }));

function adaptNewsSentimentTopStocks(
  stocks: APITopStock[],
  sectorAverageBullishPercent: number
): TopStock[] {
  return stocks.map(stock => {
    const actualSentiment =
      Math.round(stock.bearishPercent) === Math.round(stock.bullishPercent)
        ? SentimentEnum.Neutral
        : stock.bearishPercent < stock.bullishPercent
          ? SentimentEnum.Bullish
          : SentimentEnum.Bearish;
    const percent =
      actualSentiment === SentimentEnum.Bullish
        ? stock.bullishPercent
        : stock.bearishPercent;
    const majoritySentiment =
      sectorAverageBullishPercent > 0.5
        ? SentimentEnum.Bullish
        : SentimentEnum.Bearish;

    return {
      // TODO is this needed at all?
      // initial values
      change: 0,
      changePercent: 0,
      price: 0,
      ticker: stock.ticker,
      companyName: stock.companyName,
      sentiment: {
        actualSentiment,
        majoritySentiment,
        percent,
        avg: sectorAverageBullishPercent
      }
    };
  });
}

// TODO this is a very hacky way of going around the existing system of things,
// review this code with benji and decide best course of action.
export const adaptNewsSentimentsAPIResponse = ({
  wordCloud: keywords,
  buzz,
  sentiment,
  sector,
  sectorAverageBullishPercent,
  score,
  counts,
  companyName,
  sectorId
}: NewsSentimentAPIResponse): NewsSentimentAPIResponseAdapted => ({
  sectorId,
  companyName,
  keywords: adaptKeywords(keywords),
  bullishPercent: sentiment && sentiment.bullishPercent,
  newsSentimentsTopStocks: adaptNewsSentimentTopStocks(
    sector,
    sectorAverageBullishPercent
  ),
  stockNewsScore: score,
  avgNewsScore: sectorAverageBullishPercent,
  sectorAverageBullishPercent,
  counts,
  sector: adaptSector(sector),
  articlesInLastWeek: buzz.articlesInLastWeek,
  stockWeeklyAvgBuzz: buzz.weeklyAverage,
  mediaBuzzMax: ceilToNearest(10)(buzz.weeklyAverage * 2)
});

function adaptSector(sector: any): SectorEnum {
  const val = SectorEnum[SectorEnum[sector]];
  if (!val && location.port) console.warn('Sector undefined!');
  return val;
}
