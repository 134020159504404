import * as React from 'react';
import * as classNames from 'classnames';
import './spinner.styl';

// TODO: Clean this disgusting mess up.

// for 125, viewBox should be 10 10 80 80
export default ({ size, className }: { size: number; className?: string }) => (
  <svg
    width={125}
    height={125}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox={`0 0 ${125 / size * 100} ${125 / size * 100}`}
    className={classNames(className, 'tr-spinner')}
  >
    <rect x="0" y="0" width="100" height="100" fill="none" className="bk" />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(0 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(30 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(60 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(90 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(120 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(150 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(180 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(210 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(240 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(270 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(300 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(330 50 50) translate(0 -30)"
    />
  </svg>
);

export const NewsAnalysisSpinner = ({
  size,
  className
}: {
  size: number;
  className?: string;
}) => (
  <svg
    width={40}
    height={40}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    viewBox={`0 0 ${40 / size * 100} ${40 / size * 100}`}
    className={classNames(className, 'tr-spinner')}
  >
    <rect x="0" y="0" width="100" height="100" fill="none" className="bk" />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(0 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(30 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(60 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(90 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(120 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(150 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(180 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(210 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(240 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(270 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(300 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(330 50 50) translate(0 -30)"
    />
  </svg>
);

export const VariableSizeSpinner = ({
  size,
  className
}: {
  size: number;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid"
    viewBox={`0 0 100 100`}
    className={classNames(className, 'tr-spinner')}
  >
    <rect x="0" y="0" width={100} height={100} fill="none" className="bk" />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(0 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(30 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(60 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(90 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(120 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(150 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(180 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(210 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(240 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(270 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(300 50 50) translate(0 -30)"
    />
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      rx="5"
      ry="5"
      className="spinner-leaf"
      transform="rotate(330 50 50) translate(0 -30)"
    />
  </svg>
);
