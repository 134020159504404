import * as actions from './actions';
import { merge } from 'lodash';
import Map from 'immutable';
import { State } from './records.js';

export const initialState = new State();

const negate = x => !x;

const getUpdater = updates => (state, payload) => {
  return state.update('experts', experts =>
    experts.update(experts.findIndex(e => e.id === payload.id), expert =>
      updates.reduce(
        (ret, { field, updater }) => ret.update(field, updater),
        expert
      )
    )
  );
};

const toggleExpertReadAllAlerts = getUpdater([
  {
    field: 'readAllAlerts',
    updater: negate
  }
]);

// what's the purpose of having two differnt fields for indicating whether alerts are on?
const toggleExpertIsFollowing = getUpdater([
  {
    field: 'isFollowing',
    updater: negate
  },
  {
    field: 'isAlertsOn',
    updater: negate
  }
]);

const actionHandlers = {
  [actions.SET_SORT](state, payload) {
    const nextSortBy = typeof payload === 'string' ? payload : payload.id;
    return state.merge({
      sortBy: nextSortBy,
      desc: state.sortBy === nextSortBy && !state.desc
    });
  },
  [actions.SUBSCRIBE_TO_EXPERT_SUCCESS](state, { person }) {
    return state.update('experts', experts => experts.push(person));
  },
  [actions.UN_SUBSCRIBE_TO_EXPERT_START](state, { person }) {
    return state.update('experts', experts =>
      experts.delete(experts.indexOf(person))
    );
  },
  [actions.TOGGLE_EXPERT_FOLLOW_START](state, { person }) {
    return toggleExpertIsFollowing(state, person);
  },
  [actions.TOGGLE_EXPERT_FOLLOW_SUCCESS](state, payload) {
    return state;
  },
  [actions.TOGGLE_EXPERT_FOLLOW_ERROR](state, { person }) {
    return toggleExpertIsFollowing(state, person);
  },
  [actions.GET_EXPERTS_SUCCESS](state, payload) {
    return state.set('experts', payload.res);
  },
  [actions.SET_EXPERT_LAST_READ_START](state, { person }) {
    return toggleExpertReadAllAlerts(state, person);
  }
};

export default function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
