import * as classNames from 'classnames';
import * as React from 'react';
import Clickable from 'sp/browser/Clickable/clickable.react';
import { Omit } from 'sp/common/types';
import * as styles from './styles.istyl';

import * as facebook from './images/facebook.svg';
import * as twitter from './images/twitter.svg';
import * as stockTwits from './images/stocktwits.svg';
import { Icon } from 'tipranks-icons';
import { isIOS } from 'sp/common/lib/utils';

// Following function was copied from https://stackoverflow.com/a/30810322 and reviewed by Dor.
function copyTextToClipboard(text) {
  const textArea = document.createElement('textarea');

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = '0';

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (successful) {
      alert('The link was copied to your clipboard');
    } else {
      alert('Oops, unable to copy');
    }
  } catch (err) {
    document.body.removeChild(textArea);
    alert('Oops, unable to copy');
  }
}

export class PortfolioShareLinks extends React.Component<{ slug: string }> {
  public render() {
    const message = 'Check out the performance of my portfolio on TipRanks!'
    const url = window.location.href.replace(window.location.host, 'www.tipranks.com');
    return <div className={classNames(styles.shareLinkWrapper)}>
      {!isIOS() && <Clickable
        title="Copy URL"
        className={classNames(styles.copyUrl, styles.shareLink)}
        onClick={() => copyTextToClipboard(window.location.href)}
      >
        <span className={classNames(styles.copyUrl, styles.circle)}>
          <Icon icon="chainLink" />
        </span>
      </Clickable>}
      <ShareLink
        baseUrlGenerator={(url, message) => `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${message}`}
        urlToShare={url}
        className={styles.facebook}
        message={message}
        htmlProps={{
          title: 'Share on Facebook'
        }}
      >
        <img src={facebook} alt="Share on Facebook" />
      </ShareLink>
      <ShareLink
        baseUrlGenerator={(url, message) => `https://twitter.com/home?status=${message}+${url}`}
        urlToShare={url}
        className={styles.twitter}
        message={message}
        htmlProps={{
          title: 'Share on Twitter'
        }}
      >
        <img src={twitter} alt="Share on Twitter" />
      </ShareLink>
      <ShareLink
        baseUrlGenerator={(url, message) => `https://api.stocktwits.com/widgets/share?&body=${message}+${url}`}
        urlToShare={url}
        className={styles.stockTwits}
        message={message}
        htmlProps={{
          title: 'Share on StockTwits'
        }}
      >
        <img src={stockTwits} alt="Share on StockTwits" />
      </ShareLink>
    </div>
  }
}

interface ShareLinkProps {
  baseUrlGenerator: (urlToShare: string, message: string) => string;
  urlToShare: string;
  htmlProps?: any; //Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'>; // type mismatch :( don't pass ref to htmlProps.
  className?: string;
  message?: string;
}

class ShareLink extends React.Component<ShareLinkProps> {
  public render() {
    const { baseUrlGenerator, urlToShare, message = '', htmlProps = {}, className, children } = this.props;
    return (
      <Clickable
        {...htmlProps}
        href={baseUrlGenerator(encodeURIComponent(urlToShare), encodeURIComponent(message))}
        target="_blank"
        className={classNames(className, htmlProps.className, styles.shareLink)}
      >
        <span className={classNames(styles.circle, className)}>{children}</span>
      </Clickable>
    );
  }
}