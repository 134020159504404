import * as React from 'react';
import * as moment from 'moment';
import * as classNames from 'classnames';
import * as styles from './styles.istyl';
// Why doesn't .stockChart exist on HighCharts
import * as HighCharts from 'highcharts/highstock';
import * as _ from 'lodash';
const highChart = require('highcharts/highstock');

// declare module "highcharts/highstock" {
//   interface AxisOptions {
//     height?: LeNumber;
//     top?: LeNumber;
//     resize?: {
//       enabled: boolean
//     };
//   }
// }
// declare module "highcharts" {
//   interface SeriesChart {
//     fillColor?: any
//   }
//   interface IndividualSeriesOptions {
//     floor?: number;
//     ceiling?: number;
//     turboThreshold?: number;
//     fillColor?: any;
//     dataGrouping?: any;
//   }
// }

const labelFormat = 'MMM YYYY';
function format(str: string): string {
  return moment(str).format(labelFormat);
}
export function labelFormatter() {
  return format(this.value);
}

// TODO Seperate priceLabel functionality into a PriceHighStock
export class HighStock extends React.Component<{
  withoutDefault?: boolean;
  options: HighCharts.Options;
  onRenderedChart?: (chart: any) => any;
  height?: number;
  className?: string;
  priceLabel?: {
    value: number;
    isPositive: boolean; // otherwise it's negative.
  };
}> {
  id = 'highStockResearch';
  private chart;
  priceBubbleRef: HTMLDivElement;

  protected getDefaultOptions({ height }) {
    return {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: null,
        height
      },
      title: { text: '' },
      // disable activated by default, non-relevant-for-now features.
      scrollbar: { enabled: false },
      navigator: { enabled: false },
      rangeSelector: { enabled: false }
    };
  }
  componentDidUpdate() {
    this.componentDidMount();
  }
  componentDidMount() {
    const { withoutDefault, options: userOptions, height } = this.props;
    const defaultOptions = withoutDefault
      ? {}
      : this.getDefaultOptions({ height });

    const options = {
      ...defaultOptions,
      ...userOptions
    };
    this.chart = highChart.stockChart(this.id, options, this.onRenderedChart);
  }
  render() {
    const { priceLabel, className } = this.props;

    return (
      <div className={classNames(styles.graph, className)}>
        <div id={this.id} />
        {priceLabel && (
          <div
            ref={ref => (this.priceBubbleRef = ref as HTMLDivElement)}
            className={classNames(styles.price_bubble, {
              [styles.up]: priceLabel.isPositive,
              [styles.down]: !priceLabel.isPositive
            })}
          >
            {priceLabel.value.toFixed(2)}
          </div>
        )}
      </div>
    );
  }
  onRenderedChart = chart => {
    const left = chart.plotBox.x + chart.plotBox.width;
    const seriesData = chart.series[0].data.length
      ? chart.series[0].data
      : chart.series[0].points.length ? chart.series[0].points : [];
    if (seriesData.length) {
      const { plotX, plotY } = seriesData[seriesData.length - 1];
      const top = chart.plotBox.y + plotY;
      // we're setting the DOM directly to not cause a rerender (that'll create a loop).
      this.priceBubbleRef.style.top = `${top}px`;
      this.priceBubbleRef.style.left = `${left}px`;
    }

    this.props.onRenderedChart && this.props.onRenderedChart(chart);
  };
}
