export default {
  welcome: 'Welcome',
  plan: '{plan} Plan',
  mySmartPortfolio: 'My Smart Portfolio',
  logOut: 'Log Out',
  upgrade: 'Upgrade',
  free: 'Basic',
  premium: process.env.THEME === 'nasdaq' ? 'Professional' : 'Premium',
  ultimate: 'Ultimate'
};
