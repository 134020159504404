import * as React from 'react';
import report from './analytics';
import AddStockContainer from '../tr-dashboard-add-stock-container';
import msg from './en';
import globalMsg from '../../common/intl/messages/en';
import { ReduxProps } from '../../common/types';

export default class WelcomeSearch extends React.Component<ReduxProps> {
  ref: HTMLElement;
  get msg() {
    const { addStock } = globalMsg;
    return { addStock: { ...addStock, addBtn: msg.buttonSearch } };
  }

  onAddStocksSubmitted = () => {
    report('clickedWelcomeCreatePortfolio', { type: 'manual' });
  };

  componentDidMount() {
    const button = this.ref.querySelector('.controls button');
    if (button) button.addEventListener('click', this.onAddStocksSubmitted);
  }

  componentDidUpdate() {
    // const { auth, history } = this.props;
    // const viewer = selectViewer(auth);
    // const hasHoldings = viewer.getIn(['loggedInUser', 'hasHoldings']);
    // if (hasHoldings) {
    //   history.push('/smart-portfolio/holdings');
    // }
  }

  componentWillUnmount() {
    const button = this.ref && this.ref.querySelector('.controls button');
    if (button) button.removeEventListener('click', this.onAddStocksSubmitted);
  }

  render() {
    // TODO
    return (
      <div ref={ref => (this.ref = ref as HTMLElement)}>
        <AddStockContainer
          {...this.props}
          shouldDisplayImportOption={true}
          noAnalytics={true}
          msg={this.msg}
        />
      </div>
    );
  }
}
