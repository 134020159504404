import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { holdingsSectionConfig } from '../../common/config';

const isDropbale = (holdingsCols, dest) => {
  if (!dest) return false;

  const alwaysColsIndices = holdingsSectionConfig
    .get('alwaysCols')
    .map(col => holdingsCols.indexOf(col));

  const isRightMost = holdingsSectionConfig.getIn(['colOrder', dest]) > 0;

  const fieldIndex = holdingsCols.indexOf(dest) - (isRightMost ? 1 : 0);

  return !alwaysColsIndices.includes(fieldIndex);
};

const isDragable = field =>
  !holdingsSectionConfig.get('alwaysCols').includes(field);

const dragStart = field => event => event.dataTransfer.setData('Text', field);

const drop = (holdingsCols, insertBefore, fieldDest) => event => {
  // prevent ff from navigating to url
  event.preventDefault();

  const fieldSource = event.dataTransfer.getData('Text');
  event.target.classList.remove('drag-over');
  if (
    fieldSource === fieldDest ||
    !isDropbale(holdingsCols, event.target.dataset.colId)
  )
    return;
  insertBefore(fieldSource, fieldDest);
};

const dragEnter = holdingsCols => event => {
  event.preventDefault();
  if (isDropbale(holdingsCols, event.target.dataset.colId)) {
    event.target.classList.add('drag-over');
  }
};

const dragLeave = event => {
  event.target.classList.remove('drag-over');
};

export default class TableHeadCell extends Component {
  static propTypes = {
    field: PropTypes.string,
    holdingsCols: PropTypes.object,
    insertBefore: PropTypes.func,
    isSortedBy: PropTypes.bool,
    msg: PropTypes.object,
    sortHoldingsTable: PropTypes.func
  };

  render() {
    const {
      field,
      holdingsCols,
      insertBefore,
      isSortedBy,
      msg,
      sortHoldingsTable
    } = this.props;

    const isDisabled =
      holdingsSectionConfig.get('disableSort').indexOf(field) > -1;
    // TODO how to better solve this?
    const classNameFieldHack =
      {
        '1yrReturn': 'oneYearReturn'
      }[field] || field;
    return (
      <th
        className={classNames(classNameFieldHack, {
          active: isSortedBy
        })}
      >
        <div
          className={classNames('th-inner', { draggable: isDragable(field) })}
          data-col-id={field}
          draggable={isDragable(field)}
          onDragEnter={dragEnter(holdingsCols)}
          onDragLeave={dragLeave}
          onDragOver={event => event.preventDefault()}
          onDragStart={dragStart(field)}
          onDrop={drop(holdingsCols, insertBefore, field)}
        >
          <button
            disabled={isDisabled}
            onClick={() => sortHoldingsTable(field)}
          >
            <span className="before" />
            <span>{msg.cols[field]}</span>
          </button>
        </div>
      </th>
    );
  }
}
