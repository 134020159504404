import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import MaybeButton from '../maybe-button';

import { Col } from './conf';

export default class TrDashboardHoldingsThInner extends Component {
  static propTypes = {
    col: PropTypes.object,
    msg: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    tableSortable: PropTypes.bool
  };

  onClick = () => {
    const { col, onClick } = this.props;
    onClick(col.get('id'));
  };

  render() {
    const { col, msg, tableSortable } = this.props;
    return (
      <div className="cell-inner th-inner">
        <div className="tooltip">{msg.coltooltips[col.get('id')]}</div>
        <MaybeButton
          onClick={this.onClick}
          when={tableSortable && col.get('isSortable')}
        >
          {msg.cols[col.get('id')]}
        </MaybeButton>
      </div>
    );
  }
}
