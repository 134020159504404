import reporter from '../analytics';

const events = {
  logout: 'logged-out',
  registered: 'user-completed-signup',
  registeredSmartPortfolio: 'user-completed-signup-smart-portfolio',
  visitedSite: 'visited-smart-portfolio'
};

const report = reporter(events);

export default report;
