import { List } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'react-simple-pie-chart';
import Component from 'react-pure-render/component';

//import { Col } from '../conf';

import PortfolioItemSelector from '../../PortfolioItemSelector';

const colors = {
  buy: '#46a5ac',
  hold: '#747474',
  sell: '#90408f'
};

const c3Config = data => {
  if (data.toJS) data = data.toJS();
  const mapped = data.map(([id, datum]) => ({
    color: colors[id],
    value: datum
  }));
  return mapped;
};

export default class TrDashboardHoldingsDetailAnalystConsensus extends Component {
  static propTypes = {
    //col: PropTypes.instanceOf(Col),
    item: PropTypes.object
  };

  render() {
    const { col, item, showExpanded } = this.props;

    const analystConsensus = item.getIn(['stock', col.get('id')]);

    const isPieShown =
      showExpanded &&
      analystConsensus.get('decision') &&
      analystConsensus.get('distribution');

    return (
      <div className="tr-dahsobard-holdings-detail analystConsensus">
        {isPieShown && (
          <ul className="legend">
            {analystConsensus.get('distribution').map(([type, count]) => (
              <li key={type} className={type}>
                <span className="type">{type}</span>
                <span className="count">{count}</span>
              </li>
            ))}
          </ul>
        )}
        {isPieShown && (
          <div className="pie-chart-wrapper">
            <PieChart
              className="pie-chart"
              slices={c3Config(
                item.getIn(['stock', col.get('id'), 'distribution'])
              )}
            />
          </div>
        )}
        <PortfolioItemSelector {...this.props} type={col.get('id')} />
      </div>
    );
  }
}
