import { settingsConfig } from './config';

import {
  adaptAlertsSettings,
  adaptSettingsToApi
} from '../../common/api-adapter';

import {
  getPortfolioNotifications,
  setPortfolioNotifiactionStatus,
  setPortfolioNotifiactionStatusExperts,
  setPortfolioNotifications
} from '../../common/api';

const fetchStart = name => `${name}_START`;
const fetchSuccess = name => `${name}_SUCCESS`;
const fetchError = name => `${name}_ERROR`;

export const TOGGLE_IS_DISABLED = 'TOGGLE_IS_DISABLED';
export const TOGGLE_IS_DISABLED_START = fetchStart(TOGGLE_IS_DISABLED);
export const TOGGLE_IS_DISABLED_SUCCESS = fetchSuccess(TOGGLE_IS_DISABLED);
export const TOGGLE_IS_DISABLED_ERROR = fetchError(TOGGLE_IS_DISABLED);
export const toggleIsDisabled = (id, isDisabled) => () => ({
  type: TOGGLE_IS_DISABLED,
  payload: { promise: setPortfolioNotifiactionStatus(id, isDisabled) }
});

export const TOGGLE_IS_DISABLED_EXPERTS = 'TOGGLE_IS_DISABLED_EXPERTS';
export const TOGGLE_IS_DISABLED_EXPERTS_START = fetchStart(
  'TOGGLE_IS_DISABLED_EXPERTS'
);
export const TOGGLE_IS_DISABLED_EXPERTS_SUCCESS = fetchSuccess(
  'TOGGLE_IS_DISABLED_EXPERTS'
);
export const TOGGLE_IS_DISABLED_EXPERTS_ERROR = fetchError(
  'TOGGLE_IS_DISABLED_EXPERTS'
);
export const toggleIsDisabledExperts = isDisabled => () => ({
  type: TOGGLE_IS_DISABLED_EXPERTS,
  payload: { promise: setPortfolioNotifiactionStatusExperts(isDisabled) }
});

export const GET_ALERTS_SETTINGS = 'GET_ALERTS_SETTINGS';
export const GET_ALERTS_SETTINGS_START = fetchStart(GET_ALERTS_SETTINGS);
export const GET_ALERTS_SETTINGS_SUCCESS = fetchSuccess(GET_ALERTS_SETTINGS);
export const GET_ALERTS_SETTINGS_ERROR = fetchError(GET_ALERTS_SETTINGS);
export const fetchAlertsSettings = (...args) => {
  const [portfolioId] = args;
  return () => ({
    type: GET_ALERTS_SETTINGS,
    payload: {
      promise: getPortfolioNotifications(portfolioId).then(adaptAlertsSettings)
    }
  });
};

export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
export const CHANGE_SETTINGS_START = fetchStart(CHANGE_SETTINGS);
export const CHANGE_SETTINGS_SUCCESS = fetchSuccess(CHANGE_SETTINGS);
export const CHANGE_SETTINGS_ERROR = fetchError(CHANGE_SETTINGS);
export const changeSettings = (portfolioId, settings, id, val, field, weeklyDigestEnabled: boolean) => {

  const index = settings.findIndex(x => x.get('id') === id);
  const path = [index, field];
  const newSettings = settings.setIn(path, val);

  const affect = settingsConfig.getIn([id, 'affect']);
  
  if ((Number(val) > 10 || Number(val) < 0)) {
    return () => ({
      type: CHANGE_SETTINGS,
      payload: null
    });
  }
  
  const newNewSettings = affect 
    ? affect.reduce(
      (settings, affected) =>
        settings.setIn(
          [settings.findIndex(x => x.get('id') === affected), field],
          val
        ),
      newSettings
    )
    : newSettings;

  return () => ({
    type: CHANGE_SETTINGS,
    payload: {
      promise: setPortfolioNotifications(
        portfolioId,
        adaptSettingsToApi(newNewSettings),
        weeklyDigestEnabled
      ),
      data: { settings: newNewSettings, weeklyDigestEnabled }
    }
  });
};

export const changeWeeklyDigest = (portfolioId, settings, weeklyDigestEnabled: boolean) => () => ({
  type: CHANGE_SETTINGS,
  payload: {
    promise: setPortfolioNotifications(
      portfolioId,
      adaptSettingsToApi(settings),
      weeklyDigestEnabled,
    ),
    data: { settings, weeklyDigestEnabled },
  }
})