import React from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';
import Dropzone from 'react-dropzone';
import Component from 'react-pure-render/component';
import { trDropZoneStatus } from './types';
import mapDispatchToProps from '../../common/app/mapDispatchToProps';
import { connect } from 'react-redux';

@connect(s => ({
  picture: s.ui.getIn(['userProfile', 'picture']),
  state: s.ui.getIn(['userProfile', 'state']),
}), mapDispatchToProps)
export default class TrDropzone extends Component {
  // static propTypes = {
  //   children: PropTypes.element,
  //   picture: PropTypes.string,
  //   state: PropTypes.string
  // };

  onDrop = files => {
    const { togglePhotoUploadState } = this.props.actions;
    const req = request.post('/api/portfolio/uploadUserExpertImage');
    files.forEach(file => req.attach(file.name, file));
    req.end(this.onUploadEnd);
    togglePhotoUploadState({ state: trDropZoneStatus.loading });
  };

  onUploadEnd = (err, { body }) => {
    const { togglePhotoUploadState } = this.props.actions;
    togglePhotoUploadState({
      state: trDropZoneStatus.success,
      error: err,
      hasError: !!err,
      picture: body.Picture,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <div className="tr-dropzone">
        <Dropzone
          accept="image/*"
          className="tr-dropzone"
          activeClassName="tr-dropzone-active"
          multiple={false}
          onDrop={this.onDrop}
        >
          {children}
        </Dropzone>
      </div>
    );
  }
}
