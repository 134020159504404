import { API_GetUserPortfolios_Response } from '../../api-types';
import { set, values, flatMap } from 'lodash';
import { suits } from '../../enums';
import { getDetails } from '../../api';
import { companyConstants } from '../../../common/config';

import {
  postField,
  getFields as apiGetFields,
  adapt,
  getExtraPerformanceData,
  changeUserName as apiChangeUserName,
} from './api';
import { ActionResult } from 'sp/common/app/mapDispatchToProps';

export const UPDATE_FIELD = 'UPDATE_FIELD';
export const UPDATE_FIELD_START = 'UPDATE_FIELD_START';
export const UPDATE_FIELD_SUCCESS = 'UPDATE_FIELD_SUCCESS';
export const UPDATE_FIELD_ERROR = 'UPDATE_FIELD_ERROR';
export const UPDATE_FIELD_SERVER = 'UPDATE_FIELD_SERVER';
export const UPDATE_FIELD_SERVER_START = 'UPDATE_FIELD_SERVER_START';
export const UPDATE_FIELD_SERVER_SUCCESS = 'UPDATE_FIELD_SERVER_SUCCESS';
export const UPDATE_FIELD_SERVER_ERROR = 'UPDATE_FIELD_SERVER_ERROR';
export const GET_FIELDS = 'GET_FIELDS';
export const GET_FIELDS_START = 'GET_FIELDS_START';
export const GET_FIELDS_SUCCESS = 'GET_FIELDS_SUCCESS';
export const GET_FIELDS_ERROR = 'GET_FIELDS_ERROR';
export const RESTART_FORM = 'RESTART_FORM';
export const RESTART_FORM_START = 'RESTART_FORM_START';
export const RESTART_FORM_SUCCESS = 'RESTART_FORM_SUCCESS';
export const RESTART_FORM_ERROR = 'RESTART_FORM_ERROR';
export const POST_FORM = 'POST_FORM';
export const POST_FORM_START = 'POST_FORM_START';
export const POST_FORM_SUCCESS = 'POST_FORM_SUCCESS';
export const POST_FORM_ERROR = 'POST_FORM_ERROR';
export const GET_EXTRA_PERFORMANCE_DATA = 'GET_EXTRA_PERFORMANCE_DATA';
export const GET_EXTRA_PERFORMANCE_DATA_START =
  'GET_EXTRA_PERFORMANCE_DATA_START';
export const GET_EXTRA_PERFORMANCE_DATA_SUCCESS =
  'GET_EXTRA_PERFORMANCE_DATA_SUCCESS';
export const GET_EXTRA_PERFORMANCE_DATA_ERROR =
  'GET_EXTRA_PERFORMANCE_DATA_ERROR';
export const FETCH_TRANSACTIONS_PRICES = 'FETCH_TRANSACTIONS_PRICES';
export const FETCH_TRANSACTIONS_PRICES_START =
  'FETCH_TRANSACTIONS_PRICES_START';
export const FETCH_TRANSACTIONS_PRICES_SUCCESS =
  'FETCH_TRANSACTIONS_PRICES_SUCCESS';
export const FETCH_TRANSACTIONS_PRICES_ERROR =
  'FETCH_TRANSACTIONS_PRICES_ERROR';
export const CHANGE_USER_NAME = 'CHANGE_USER_NAME';
export const CHANGE_USER_NAME_START = 'CHANGE_USER_NAME_START';
export const CHANGE_USER_NAME_SUCCESS = 'CHANGE_USER_NAME_SUCCESS';
export const CHANGE_USER_NAME_ERROR = 'CHANGE_USER_NAME_ERROR';
export const RESET_MY_DETAILS = 'RESET_MY_DETAILS';
export const RESET_MY_DETAILS_START = 'RESET_MY_DETAILS_START';
export const RESET_MY_DETAILS_SUCCESS = 'RESET_MY_DETAILS_SUCCESS';
export const RESET_MY_DETAILS_ERROR = 'RESET_MY_DETAILS_ERROR';
export const NOOP = 'NOOP';
export const NOOP_START = 'NOOP_START';
export const NOOP_SUCCESS = 'NOOP_SUCCESS';
export const NOOP_ERROR = 'NOOP_ERROR';
export const UPLOADED_PHOTO = 'UPLOADED_PHOTO';
export const UPLOADED_PHOTO_START = 'UPLOADED_PHOTO_START';
export const UPLOADED_PHOTO_SUCCESS = 'UPLOADED_PHOTO_SUCCESS';
export const UPLOADED_PHOTO_ERROR = 'UPLOADED_PHOTO_ERROR';

const notEmpty = Boolean;

const patterns = {
  age: age => Number(age) >= 18 && Number(age) <= 120,
  profession: notEmpty,
  income: income => /^\d*$/.test(income) && income > 0,
  term: notEmpty,
  tolerance: notEmpty,
};

const apiUpdateField = payload =>
  postField(payload)
    .then(() => payload)
    .catch(e => Promise.reject(Object.assign(e, payload)));

const validateInput = payload => {
  const validator = patterns[payload.type];
  return validator && validator(payload.value) !== false;
};

export function resetMyDetails() {
  return {
    type: RESET_MY_DETAILS,
    payload: {},
  };
}

export function getFields(): ActionResult<any> {
  return {
    type: GET_FIELDS,
    payload: {
      promise: apiGetFields(),
    },
  } as any;
}

export function updateField(data) {
  return {
    type: UPDATE_FIELD,
    payload: data,
  };
}

export function updateFieldServer(data) {
  data = data.remove('undefined'); // :(((
  const dataWithState = data
    .toMap()
    .map(x => x.set('state', validateInput(x) ? suits.SUCCESS : suits.INVALID));
  const allValid = dataWithState.every(x => x.get('state') === suits.SUCCESS);
  let promise;
  if (!allValid) {
    const error = new Error('failed to validate input.') as any;
    error.data = dataWithState;
    promise = Promise.reject(error);
  } else {
    promise = apiUpdateField(values(data.toJS())).then(() => dataWithState);
  }
  return {
    type: UPDATE_FIELD_SERVER,
    payload: {
      promise,
      data,
    },
  };
}

export function restartForm() {
  return {
    type: RESTART_FORM,
  };
}

export function postForm(payload) {
  const isValid = payload.fields.every(x => x.state === suits.SUCCESS);
  return {
    type: POST_FORM,
    payload: {
      isValid,
    },
  };
}

export function extraPerformanceData(
  portfolioId,
): ActionResult<API_GetUserPortfolios_Response> {
  if (
    portfolioId === companyConstants.get('combinedUserPortfolio') ||
    portfolioId === -1
  ) {
    if (process.env.IS_RUN_LOCAL)
      console.warn('shouldnt be running extraPerformanceData');
    return { type: NOOP, payload: null } as any;
  }
  return {
    type: GET_EXTRA_PERFORMANCE_DATA,
    payload: {
      promise: getExtraPerformanceData(portfolioId),
    },
  } as any;
}

export function fetchTransactionsPrices(tickers) {
  return {
    type: FETCH_TRANSACTIONS_PRICES,
    payload: {
      promise: getDetails(tickers),
    },
  };
}

export function changeUserName(name) {
  return {
    type: CHANGE_USER_NAME,
    payload: {
      promise: apiChangeUserName(name),
      data: { name },
    },
  };
}
