export default {
  headerH1: 'Management Fees',
  listFooterLbl: '*',
  listFooter: 'Your other funds are already optimal',
  'fees.shorter': 'Fees',
  'fees.longer': 'Management Fees',
  compareBtn: 'Compare',
  'fundCaption.current': 'Your Current Funds',
  'fundCaption.similar': 'Similar Funds with Lower Fees',
  changeTo: 'Change to',
  warning:
    'To see the exact amount, please enter number of shares for each one of your holdings {addSharesLbl}',
  addSharesLbl: 'Add No. of Shares >',
  'restrictedBtn.tablet': 'Upgrade to see similar\nfunds with lower fees',
  restrictedBtn: 'Premium Members',
  saveMoney: 'Save Money'
};
