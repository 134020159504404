import reporter from '../analytics';

const events = {
  addPortfolio: 'add-portfolio-limit',
  clickedAddPortfolio: 'clicked-locked-feature',
  clickedLockedAddPortfolio: 'clicked-locked-add-portfolio'
};

const report = reporter(events);

export default report;
