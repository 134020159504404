import * as React from 'react';
import * as classNames from 'classnames';

import * as styles from './ArcGraph.istyl';

interface ArcGraphProps {
  low: React.ReactNode;
  high: React.ReactNode;
  average?: React.ReactNode;
  averageValue?: number;
  value: number;
  className?: string;
  styles?: typeof styles;
}

export function ArcGraph({
  low,
  high,
  value,
  average,
  averageValue,
  className,
  styles: outerStyles = {}
}: ArcGraphProps) {
  const degreeMapper = mapRange(-90, 90);
  return (
    <div
      className={classNames(
        styles.graphContainer,
        outerStyles.graphContainer,
        className
      )}
    >
      <div
        className={classNames(styles.arcContainer, outerStyles.arcContainer)}
      >
        <div className={classNames(styles.arc, outerStyles.arc)}>
          <img src="/assets/img/news-analysis/signal-arc.png" />
          {value ? (
            <div
              className={classNames(styles.needle, outerStyles.needle)}
              style={{
                transform: `rotate(${degreeMapper(value)}deg)`,
                WebkitTransform: `rotate(${degreeMapper(value)}deg)`
              }}
            />
          ) : (
            ''
          )}
          {averageValue && (
            <div
              className={classNames(
                styles.avgIndicator,
                outerStyles.avgIndicator
              )}
              style={{
                transform: `rotate(${degreeMapper(averageValue)}deg)`,
                WebkitTransform: `rotate(${degreeMapper(averageValue)}deg)`
              }}
            >
              <div
                className={classNames(
                  styles.indicatorText,
                  outerStyles.indicatorText
                )}
              >
                {average}
              </div>
              <div
                className={classNames(styles.indicator, outerStyles.indicator)}
              />
            </div>
          )}
        </div>
        <div className={classNames(styles.highLow, outerStyles.highLow)}>
          <span className={classNames(styles.low, outerStyles.low)}>{low}</span>
          <span className={classNames(styles.high, outerStyles.high)}>
            {high}
          </span>
        </div>
      </div>
    </div>
  );
}

function mapRange(low: number, high: number) {
  return (value: number) => low + value * (high - low);
}
