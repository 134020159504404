import { dashboardPath } from '../../common/config';
import { postReq } from '../../common/api';

const apiUrls = {
  migratePortfolio: 'api/portfolio/MigratePortfolio',
  discardExternalPortfolios: 'api/portfolio/DiscardExternalPortfolios'
};

const api = {
  async migratePortfolio() {
    const data = await postReq(apiUrls.migratePortfolio);
  },

  async discardExternalPortfolios() {
    const data = await postReq(apiUrls.discardExternalPortfolios);
  }
};

const redirectToHoldings = history => () =>
  history.replace(`/${dashboardPath}/holdings`);

export const MIGRATE_PORTFOLIO = 'MIGRATE_PORTFOLIO';
export const migratePortfolio = () => {
  return () => {
    return {
      type: MIGRATE_PORTFOLIO,
      payload: {
        // promise: Promise.resolve().then(redirectToHoldings(history))
        promise: api.migratePortfolio()
      }
    };
  };
};

export const DISCARD_PORTFOLIOS = 'DISCARD_PORTFOLIOS';
export const discardExternalPortfolios = () => {
  return () => {
    return {
      type: DISCARD_PORTFOLIOS,
      payload: {
        // promise: Promise.resolve().then(redirectToHoldings(history))
        promise: api.discardExternalPortfolios()
      }
    };
  };
};

export const actions = {
  migratePortfolio,
  discardExternalPortfolios
};

const actionHandlers = {
  MIGRATE_PORTFOLIO_START(state, payload) {
    return { loading: true };
  },
  MIGRATE_PORTFOLIO_SUCCESS(state, payload) {
    return { loading: false };
  },
  MIGRATE_PORTFOLIO_ERROR(state, payload) {
    return { loading: false };
  },

  DISCARD_PORTFOLIOS_START(state, payload) {
    return { loading: true };
  },
  DISCARD_PORTFOLIOS_SUCCESS(state, payload) {
    return { loading: false };
  },
  DISCARD_PORTFOLIOS_ERROR(state, payload) {
    return { loading: false };
  }
};

const initialState = { loading: false };

export function reducer(state = initialState, action = {}) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
