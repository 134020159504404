import 'whatwg-fetch';
// import URI from 'urijs';

const local = 'http://localhost:8000/';

function ensureAbsoluteUrl(input) {
  return input;
  // if (typeof input !== 'string') return input;
  // if (URI(input).is('absolute')) return input;
  // return URI(local + input).normalize().toString();
}

// Wrapper over isomorphicFetch making relative urls absolute. We don't want
// hardcode fetch urls since they are different when app is deployed or not.
export default function fetch(input, init) {
  input = ensureAbsoluteUrl(input);
  return isomorphicFetch(input, init);
}
