import { makeTemporaryUser } from "sp/common/api";
import { initPlaidAuthFlow, TipRanksPlaidCB, updatePlaidPortfolio } from "../../common/PlaidProviderLogic";
import { responseToGeneralErrorPlaid } from '../../common/PlaidProviderLogic/responseToGeneralErrorPlaid';

export async function syncSmartPortfolioWithPlaid(expertId: number, events: TipRanksPlaidCB & { onNoExpertId?: () => void } = {}) {
  let newExpertId = 0;
  const { onNoExpertId, ...otherEvents } = events;
  if (expertId === 0) {
    try {
      const _makeTemporaryUser = makeTemporaryUser as unknown as () => Promise<null | { expertId?: number }>; // ts hack
      const tempUserRes = await _makeTemporaryUser()
      newExpertId = tempUserRes && tempUserRes.expertId ? tempUserRes.expertId : 0;
      if (newExpertId === 0) {
        if (typeof onNoExpertId === 'function') {
          onNoExpertId()
        }
        return null;
      }
    } catch (err) {
      responseToGeneralErrorPlaid();
      if (typeof events.onSyncFailed === 'function') {
        events.onSyncFailed(err);
      }
      return null
    }
  }

  initPlaidAuthFlow(
    { 
      expertOrPortfolioId:  String(newExpertId || expertId),
      type: 'create',
    },
    { ...otherEvents },
  );
}

export async function updateAuthSyncSmartPortfolioWithPlaid(portfolioId: number, events: TipRanksPlaidCB & { onNoPortfolioId?: () => void } = {}) {
  const { onNoPortfolioId, ...otherEvents } = events;
  if (portfolioId === 0 && typeof onNoPortfolioId === 'function') {
    onNoPortfolioId()
    return null;
  }  
  initPlaidAuthFlow(
    { 
      expertOrPortfolioId: String(portfolioId),
      type: 'update',
    },
    { ...otherEvents },
  );
}

export { updatePlaidPortfolio } 