import React from 'react';
import AutoComplete from './auto-complete.react';
import Component from 'react-pure-render/component';
import Emitter from 'tiny-emitter';
import SearchResults from './search-results.react';
import { msg as categoriesMsg, defaultCategories } from './categories';
import { selectSearchResults, selectSearchResultsArray } from './selectors';
import { mapValues } from 'lodash';
import './style.styl';

import getImage from './images';

const resultsMsg = {
  categories: mapValues(categoriesMsg, ({ label }) => label),
  icons: mapValues(categoriesMsg, ({ icon }) => icon)
};

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.emitter = new Emitter();
    this.emitter
      .on('categoryChanged', cat => this.props.actions.setCategory(cat))
      .on('toggleDataList', this.toggleSearchResults)
      .on('openDataList', this.openSearchResults)
      .on('onDataReceived', this.openSearchResults)
      .on('categoryMenuOpen', this.closeSearchResults)
      .on('closeCategoryMenu', this.openSearchResults)
      .on('setFocusedOption', this.setFocusedOption);
  }

  toggleSearchResults = bool => {
    this.props.actions.toggleSearchResults(bool);
  };

  closeSearchResults = () => this.toggleSearchResults(false);
  openSearchResults = () => this.toggleSearchResults(true);

  setFocusedOption = id => {
    this.setState({ focusedIndex: id });
  };

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () => {
    if (this.props.autocomplete.resultsShown) {
      this.props.actions.toggleSearchResults(false);
    }
  };

  onClick = e => {
    e.stopPropagation();
  };

  onPerformSearch = () => {
    const { actions, autocomplete } = this.props;
    if (autocomplete.query) {
      actions.performSearch(autocomplete.query, autocomplete.results);
    }
  };

  render(
    { actions, autocomplete } = this.props,
    { focusedIndex } = this.state
  ) {
    const searchResults = selectSearchResults(autocomplete);
    const searchResultsArray = selectSearchResultsArray(autocomplete);
    return (
      <div className="tr-search" onClick={this.onClick}>
        <div className="stretch">
          <div className="searchWrapper">
            <AutoComplete
              emitter={this.emitter}
              onType={actions.getDataList}
              onSubmit={actions.performSearch}
              categories={defaultCategories}
              hasCategoryMenu={false}
              results={searchResultsArray}
              selectedCategory={autocomplete.categoryObject.key}
            />
          </div>
          <button className="search" onClick={this.onPerformSearch}>
            {getImage('search', 'svg', {
              dim: [20, 20],
              color: '#4a4a4a'
            })}
          </button>
          <div className="searchResults">
            <SearchResults
              focused={focusedIndex}
              shown={autocomplete.resultsShown}
              msg={resultsMsg}
              query={autocomplete.query}
              results={searchResults}
            />
          </div>
        </div>
      </div>
    );
  }
}
