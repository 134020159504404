import { Record, Map } from 'immutable';

import * as actions from './actions';
import * as performanceActions from '../components/performance/actions';
import * as accountSettingsActions from '../../browser/AccountSettings/actions';

import Form from './form';
import { AuthRecord, LoggedInUser_NotImmutable, ReduxAction } from 'sp/common/types';
import { suits } from 'sp/common/enums';
import { combineReducers } from 'redux';
import { setPortfolioPublicReducer } from 'sp/browser/pages/PublicPortfolio/Onboarding/reducer';
import { initialState, InitialState } from 'sp/common/auth/config';
import { compose, composeReducers } from 'sp/browser/lib/utils';
import { API_Res_UserSettingsResponse, AdaptUserRes } from 'sp/common/api-adapter';
import { fromJS } from 'immutable';

function authReducer(
  state: AuthRecord = initialState,
  action: ReduxAction,
): AuthRecord {
  if (!(state instanceof InitialState))
    return (initialState as any).mergeDeep(state); // TODO :(

  switch (action.type) {
    case actions.ON_AUTH_FORM_FIELD_CHANGE: {
      const { name, value } = action.payload;
      return state.setIn(['form', 'fields', name], value);
    }

    case actions.MAKE_TEMPORARY_USER_SUCCESS: {
      return state.update('ver', (ver: number) => ver + 1);
    }

    case actions.SET_FIRST_VISIT: {
      return state.setIn(['users', 'viewer', 'isFirstVisit'], false);
    }

    case actions.LOGOUT_SUCCESS: {
      // TODO remove mergeIn
      return (state as any)
        .mergeIn(path.user, {
          email: '',
          id: '',
          name: '',
          plan: 'open',
        })
        .setIn(['users', 'viewer', 'plan'], 'open');
    }

    case actions.LOGIN_START:
      return state
        .setIn(['form', 'state'], 'LOADING')
        .setIn(['form', 'disabled'], true);

    case actions.LOGIN_SUCCESS: {
      const payload: AdaptUserRes = action.payload;

      let newState = state
        .setIn(['form', 'state'], 'SUCCESS')
        .setIn(['users', 'viewer', 'plan'], payload.plan);

      if (payload.email) {
        newState = newState
          .updateIn(path.user, updateUserWith(payload));
      }

      return newState;
    }

    case actions.LOGIN_ERROR: {
      const error = action.payload;
      return state
        .setIn(['form', 'state'], 'ERROR')
        .setIn(['form', 'disabled'], false)
        .setIn(['form', 'error'], error);
    }

    case actions.RESTART_FORM: {
      return state.set('form', new (Form as any)()); // TODO!!!
    }

    case actions.REGISTER_START:
      return state
        .setIn(['form', 'state'], 'LOADING')
        .setIn(['form', 'disabled'], true);

    case actions.REGISTER_SUCCESS: {
      const payload: AdaptUserRes = action.payload;
      return state
        .setIn(['form', 'state'], 'SUCCESS')
        .updateIn(path.user, updateUserWith(payload))
        .setIn(['users', 'viewer', 'plan'], payload.plan);
    }

    case actions.REGISTER_ERROR: {
      const error = action.payload;
      return state
        .setIn(['form', 'state'], 'ERROR')
        .setIn(['form', 'disabled'], false)
        .setIn(['form', 'error'], error);
    }

    case performanceActions.CHANGE_USER_NAME_START: {
      const { name } = action.payload;
      const namePath = [...path.user, 'name'];
      const oldName = state.getIn(namePath);
      if (name === oldName) {
        return state;
      }
      const nameCache = [...path.user, 'name_old'];
      return state.setIn(namePath, name).setIn(nameCache, oldName);
    }

    case performanceActions.CHANGE_USER_NAME_ERROR: {
      const namePath = [...path.user, 'name'];
      const nameCache = [...path.user, 'name_old'];
      const oldName = state.getIn(nameCache);
      return state.setIn(namePath, oldName).deleteIn(nameCache);
    }
    case performanceActions.CHANGE_USER_NAME_SUCCESS: {
      const nameCache = [...path.user, 'name_old'];
      return state.deleteIn(nameCache);
    }

    case actions.IDENTIFY_USER_ERROR: {
      console.error('error in IDENTIFY_USER_ERROR', action.payload);
      return state;
    }

    case actions.IDENTIFY_USER_SUCCESS: {
      const payload: AdaptUserRes = action.payload;

      if (
        state.getIn([...path.user, 'email']) &&
        !payload.email
      ) {
        return state.updateIn(path.user, updateUserWith(payload));
      }

      const newState = state
        .set('didIdentify', true)
        .setIn(['users', 'viewer', 'plan'], payload.plan)
        .setIn(
          ['users', 'viewer', 'isFirstTimeMigratedUser'],
          payload.isFirstTimeMigratedUser,
        )
        .setIn(
          ['users', 'viewer', 'isSpaasMigrated'],
          payload.isSpaasMigrated,
        )
        .updateIn(path.user, updateUserWith(payload));

      if (
        ['plans', 'faq', 'contact', 'thank-you', 'screener'].some(url =>
          location.href.includes(url),
        )
      )
        return newState;

      // TODO: For now keeping this. Change later.
      // This behavior should not be in the reducer. Move it to createRoutes or something of that sort.

      if (payload.isFirstTimeMigratedUser) {
        if (location.pathname !== '/smart-portfolio/welcome-dev') {
          // this should happen after state has changed, so we use
          // a promise which makes it run in a new job
          setTimeout(() => {
            if (location.pathname !== '/smart-portfolio/welcome-dev') {
              location.href = '/smart-portfolio/welcome-dev';
            }
          }, 2000);
        }
      }

      return newState;
    }

    /** ** CHANGE_TELEPHONE ***/
    case accountSettingsActions.CHANGE_PHONE_SUCCESS: {
      const { telephone } = action.payload;
      return state.setIn(
        [...path.user, 'phone'],
        telephone,
      );
    }
    /** ** CHANGE_TELEPHONE ***/
    /** ** CHANGE_EMAIL_FREQUENCY ***/
    case accountSettingsActions.CHANGE_EMAIL_FREQUENCY_SUCCESS: {
      const { shouldBombard } = action.payload;
      return state.setIn(
        [...path.user, 'bypassEmailLimit'],
        shouldBombard,
      );
    }
    /** ** CHANGE_EMAIL_FREQUENCY ***/
  }

  return state;
}

// --- utility functions

const path = {
  user: ['users', 'viewer', 'loggedInUser']
}

function updateUserWith(payload) {
  return user => user
    // list taken from adaptUser
    .set('email', payload.email)
    .set('hasHoldings', payload.hasHoldings)
    .set('id', payload.id)
    .set('name', payload.name)
    .set('plan', payload.plan)
    .set('planId', payload.planId)
    .set('expertId', payload.expertId)
    .set('isFirstTimeMigratedUser', payload.isFirstTimeMigratedUser)
    .set('isSpaasMigrated', payload.isSpaasMigrated)
    .set('phone', payload.phone)
    .set('bypassEmailLimit', payload.bypassEmailLimit)
    .set('areEmailAlertsActive', payload.areEmailAlertsActive)
    .set('signupDate', payload.signupDate)
    // these fromJS and defaults are the reason this utility function exists
    .set('portfoliosWithPerformance', fromJS(payload.portfoliosWithPerformance || []))
    .set('portfoliosMadePublic', fromJS(payload.portfoliosMadePublic || []));
}

// --- main export

export default composeReducers(authReducer, setPortfolioPublicReducer);