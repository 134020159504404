import moment from 'moment';
import { Record } from 'immutable';

import * as actions from './actions';

export const InitialState = Record({
  date: new Date()
});

const initialState = new InitialState();
const revive = state => initialState.merge(state);

export default function portfolioCalendarReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.NEXT_CALENDAR: {
      return state.update('date', date =>
        moment(date)
          .add(1, 'months')
          .toDate()
      );
    }

    case actions.PREV_CALENDAR: {
      return state.update('date', date =>
        moment(date)
          .subtract(1, 'months')
          .toDate()
      );
    }
  }

  return state;
}
