export default {
  trendingStocks: 'Trending Stocks',
  suggestions: 'Suggestions',
  trendingStocksItems: [    
    {
      label: 'Amazon.Com, Inc. (AMZN)',
      url: '/stocks/amzn',
      category: 'ticker',
    },
    {
      label: 'Alibaba Group (BABA)',
      url: '/stocks/baba',
      category: 'ticker',
    },
    {
      label: 'Aurora Cannabis (ACB)',
      url: '/stocks/acb',
      category: 'ticker',
    },
    {
      label: 'Advanced Micro Devices (AMD)',
      url: '/stocks/amd',
      category: 'ticker',
    },
    {
      label: 'Nvidia Corporation (NVDA)',
      url: '/stocks/nvda',
      category: 'ticker',
    },
    {
      label: 'Tesla Motors (TSLA)',
      url: '/stocks/tsla',
      category: 'ticker',
    },
    {
      label: 'Walt Disney Company (DIS)',
      url: '/stocks/dis',
      category: 'ticker',
    },
    {
      label: 'Microsoft Corp (MSFT)',
      url: '/stocks/msft',
      category: 'ticker',
    },
    {
      label: 'Apple (AAPL)',
      url: '/stocks/aapl',
      category: 'ticker',
    },
    {
      label: 'Facebook Inc (FB)',
      url: '/stocks/fb',
      category: 'ticker',
    },
    {
      label: 'Salesforce (CRM)',
      url: '/stocks/crm',
      category: 'ticker',
    },
    {
      label: 'Netflix (NFLX)',
      url: '/stocks/nflx',
      category: 'ticker',
    },
  ]
};

export const __hotReload = true;
