import * as storage from 'redux-storage';
import migrate from 'redux-storage-decorator-migrate';
import promiseMiddleware from 'redux-promise-middleware';
import { applyMiddleware, compose, createStore } from 'redux';
import fetch from './fetch';
import validate from './validate';
import appReducer from './app/reducer';
import injectDependencies from './lib/injectDependencies';
import localStorage from '../browser/components/personsList/localstorage';
import history from '../browser/history.ts';

import { BROWSER_DEVELOPMENT } from './config.ts';
import { REALTIME_PRICES_UPDATE } from './dashboard/actions';

if (process.env.IS_BROWSER)
  window.console.todo = BROWSER_DEVELOPMENT ? console.info : () => null;

// function getDebugSessionKey() {
//   const matches = window.location.href.match(/[?&]debug_session=([^&]+)\b/);
//   return (matches && matches.length > 0) ? matches[1] : null;
// }

export default function configureStore({ deps, engine, initialState }) {
  // Inject services for actions.
  const now = () => Date.now();
  const dependenciesMiddleware = injectDependencies(
    { ...deps, fetch, now, history },
    { validate },
  );

  let middleware = [
    dependenciesMiddleware,
    promiseMiddleware({
      promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'],
    }),
  ];

  if (engine) {
    // The order is important.
    engine = storage.decorators.immutablejs(engine, [
      'dashboard',
      // 'performance',
      'dialogs',
      'alertsSettings',
    ]);
    engine = storage.decorators.filter(engine, [
      ['dashboard', 'activePortfolioId'],
      ['dashboard', 'deletedExperts'],
      ['dashboard', 'data'],
      ['dashboard', 'deletedPortfolios'],
      ['dashboard', 'overviewPortfolioNewsTab'],
      ['dashboard', 'holdingsSortBy'],
      ['dashboard', 'isholdingsSortDescending'],
      ['dashboard', 'holdingsCols'],
      ['dashboard', 'holdingsColsPendings'],
      ['dashboard', 'majorHoldingsType'],
      ['dashboard', 'majorHoldingsSector'],
      ['dashboard', 'dismissdedWarningDialogs'],
      ['dashboard', 'visibleMarketOverviewMarkets'],
      ['dashboard', 'mraketOverviewDuration'],
      ['dashboard', 'assetAllocationType'],
      ['performance'],
      ['dashboard', 'tblType'],
      // 'dialogs',
      'myExperts',
      'alertsSettings',
      'auth',
      ['dialogs', 'unsureSent'],
      ['ui', 'period'],
      ['ui', 'benchmark'],
      ['ui', 'performanceDisplayTab'],
    ]);
    engine = storage.decorators.debounce(engine, 1500);
    // engine = migrate(engine, 2);
    // engine.addMigration(1, state => console.log(state) || state);
    middleware.push(storage.createMiddleware(engine, [REALTIME_PRICES_UPDATE]));
  }

  if (typeof window !== 'undefined' && window.location.search.includes('redux-logger')) {
    const createLogger = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
    });
    // Logger must be the last middleware in chain.
    middleware = [...middleware, logger];
  }

  const createReduxStore =
    BROWSER_DEVELOPMENT && window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line no-undef
      ? compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__(), // eslint-disable-line no-undef
        // persistState(getDebugSessionKey())
      )
      : applyMiddleware(...middleware);

  const store = createReduxStore(createStore)(appReducer, initialState);

  // Enable hot reload where available.
  if (module.hot) {
    // eslint-disable-line no-undef
    // Enable Webpack hot module replacement for reducers.
    module.hot.accept('./app/reducer', () => {
      // eslint-disable-line no-undef
      const nextAppReducer = require('./app/reducer').default; // eslint-disable-line no-undef
      store.replaceReducer(nextAppReducer);
    });
  }

  return store;
}
