import Copmonent from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import { settingsConfig } from './config.ts';
import { planName } from '../decisions/index.ts';

import PortfolioSelect from '../components/portfolio-select';
import SvgImg from '../tr-svg-img';
import UserWarnings from '../tr-user-warnings';
import { suitState } from '../components/suit';

import { isAllowed } from '../../common/auth/selectors';
import { suits } from '../../common/enums';
import { Label } from './utils.tsx';

import { Icon } from 'tipranks-icons';
import { SlidingCheckbox } from '../../../SlidingCheckbox/SlidingCheckbox';

const plans = ['free', 'premium', 'ultimate'];

const byPlan = plan => option =>
  settingsConfig.getIn([option.get('id'), 'plan']) === plan;

const isHidden = option => settingsConfig.getIn([option.get('id'), 'infoOnly']);

export default class TrDashboardAlertsSettings extends Copmonent {
  static propTypes = {
    changePortfolio: PropTypes.func,
    changeSettings: PropTypes.func,
    close: PropTypes.func.isRestricted,
    componentState: PropTypes.any,
    enums: PropTypes.object,
    expectedExpertEmail: PropTypes.number,
    expectedStockEmails: PropTypes.number,
    isDisabled: PropTypes.bool,
    isDisabledExperts: PropTypes.bool,
    msg: PropTypes.object,
    numSubscriptionToExperts: PropTypes.number,
    portfolioId: PropTypes.number,
    portfolioName: PropTypes.string,
    portfolioSelectMsg: PropTypes.object,
    portfolios: PropTypes.object,
    settings: PropTypes.object,
    showPromotion: PropTypes.func,
    showToast: PropTypes.func,
    theme: PropTypes.string,
    toggleIsDisabled: PropTypes.func,
    toggleIsDisabledExperts: PropTypes.func,
    totalAlertsPredictions: PropTypes.number,
    viwerPlan: PropTypes.string
  };

  render() {
    const {
      changePortfolio,
      changeSettings,
      close,
      componentState,
      enums,
      expectedExpertEmail,
      expectedStockEmails,
      isDisabled: isAlertsDisabled,
      isDisabledExperts: isAlertsDisabledExperts,
      isEnabledWeeklyDigest,
      msg,
      numSubscriptionToExperts,
      portfolioId,
      portfolioName,
      portfolioSelectMsg,
      portfolios,
      settings,
      showPromotion,
      showToast,
      theme,
      toggleIsDisabled,
      toggleIsDisabledExperts,
      toggleWeeklyDigest,
      viwerPlan
    } = this.props;

    const isDisabled =
      suitState(componentState) !== suits.SUCCESS || isAlertsDisabled;

    const isRestrictedFn = plan =>
      plan !== 'free' && !isAllowed(plan, viwerPlan);

    const apply = fn => {
      fn();
      showToast('alertsSettingsUpdated');
    };

    const warnings = isAlertsDisabled
      ? new List([
        <FormattedMessage
          defaultMessage={msg.warnings.isAlertsDisabled}
          id={msg.warnings.isAlertsDisabled}
          values={{ portfolioName: <strong>{portfolioName}</strong> }}
        />
      ])
      : new List();

    return (
      <section
        className={classNames('alerts-settings', {
          isAlertsDisabled,
          isAlertsDisabledExperts
        })}
      >
        <section className="email-alerts">
          <header>
            <h1>{msg.title}</h1>
          </header>
          {/* <div className="user-warnings-wrapper">
            <UserWarnings msg={msg.userWarnings} warnings={warnings} />
          </div> */}
          {/* <div className="inner-section">
            <label>
              <h2>Weekly Digest</h2>
              <SlidingCheckbox
                label={isEnabledWeeklyDigest ? 'Enabled' : 'Disabled'}
                inputProps={{
                  onChange: toggleWeeklyDigest,
                  checked: isEnabledWeeklyDigest
                }} />
            </label>
            <p className="description">
              Your Smart Portfolio weekly overview, this service will send you a weekly overview regarding your portfolio performance and activity over the last week.
            </p>
          </div> */}
          <div className="inner-section">
            <label>
              <h2>Alerts &amp; Notifications</h2>
              <SlidingCheckbox
                label={isAlertsDisabled ? 'Disabled' : 'Enabled'}
                inputProps={{
                  onChange: toggleIsDisabled,
                  checked: !isAlertsDisabled
                }} />
            </label>
            <p className="description">
              <FormattedHTMLMessage
                defaultMessage={msg.description}
                id={msg.description}
              />
            </p>
            {/* <menu>
              <FormattedMessage
                defaultMessage={msg.portfolioSelectText}
                id={msg.portfolioSelectText}
                values={{
                  portfolioSelector: (
                    <PortfolioSelect
                      activePortfolioId={portfolioId}
                      hideAllPortfolios
                      hideEditPortfolio
                      msg={portfolioSelectMsg}
                      onChange={changePortfolio}
                      portfolios={portfolios}
                    />
                  )
                }}
              />
            </menu> */}
            <ul className="plans">
              {plans.map(plan => (
                <li key={plan}>
                  <span className={classNames('plan-badge', plan)}>
                    {planName(plan, enums)}
                  </span>
                  <ul className="plan-options">
                    {settings.filter(byPlan(plan)).map(option => {
                      const isRestricted = isRestrictedFn(plan);
                      const handler = (type, field, promotionType) => ({
                        target
                      }) =>
                        isRestricted
                          ? showPromotion(plan, promotionType, option.get('id'))
                          : apply(() => {
                            
                            // console.log (target, type, field, settings, option.get('id'))
                            changeSettings(
                              portfolioId,
                              settings,
                              option.get('id'),
                              target[type],
                              field,
                              isEnabledWeeklyDigest,
                            )
                            });
                      return (
                        <li
                          className={classNames(option.get('id'), {
                            hidden: isHidden(option)
                          })}
                          key={option.get('id')}
                        >
                          <label>
                            <input
                              checked={option.get('val')}
                              disabled={isDisabled || isHidden(option)}
                              onChange={handler('checked', 'val')}
                              type="checkbox"
                            />
                            <span className="label">
                              <Label
                                isEditing
                                isDisabled={isDisabled}
                                msg={msg.options[option.get('id')]}
                                onChangePercentEditable={handler(
                                  'value',
                                  'info'
                                )}
                                onChangeRankingSelector={handler(
                                  'value',
                                  'info',
                                  'alertsSettingsRanking'
                                )}
                                value={option.get('info')}
                              />
                            </span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
            <p className="prediction">
              <FormattedMessage
                defaultMessage={msg.totalAlertsPredictions}
                id={msg.totalAlertsPredictions}
                values={{
                  portfolioName: <strong>{portfolioName}</strong>,
                  total: <strong>{expectedStockEmails}</strong>
                }}
              />
            </p>
            <button className="disable portfolio" onClick={toggleIsDisabled}>
              <Icon
                className="bellIcon"
                icon={isAlertsDisabled ? 'crossedBell' : 'bell'}
                size={20}
              />{' '}
              <FormattedMessage
                defaultMessage={msg.disable}
                id={msg.disable}
                values={{
                  portfolioName: <strong>{portfolioName}</strong>,
                  isAlertsDisabled
                }}
              />
              <button>{isAlertsDisabled ? 'Enable' : 'Disable'}</button>
            </button>
          </div>
        </section>
        {theme === 'web' && (
          <section className="experts-alerts">
            <header>
              <h1>
                {/*<div className="icon">
                  <SvgImg
                    id="dashboard-experts"
                    keyId={'navItem_dashboard-experts'}
                    params={{
                      color: '#000',
                      height: 27,
                      width: 27
                    }}
                  />
                </div>*/}
                <Icon icon="envelope" size={13} /> {msg.expertsAlertsTitle}
              </h1>
            </header>
            <div className="inner-section">
              {/*<p className="prediction">
                <FormattedMessage
                  defaultMessage={msg.expertPredication}
                  id={msg.expertPredication}
                  values={{
                    expertsTotal: <strong>{numSubscriptionToExperts}</strong>,
                    expertsAlertsPrediction: <strong>{expectedExpertEmail}</strong>
                  }}
                />
              </p>*/}
              <p className="disable">
                <Icon
                  className="bellIcon"
                  icon={isAlertsDisabledExperts ? 'crossedBell' : 'bell'}
                  size={20}
                />{' '}
                Email alerts are{' '}
                {isAlertsDisabledExperts ? 'disabled' : 'enabled'}.
                <button onClick={toggleIsDisabledExperts}>
                  {isAlertsDisabledExperts ? 'Enable' : 'Disable'}
                </button>
              </p>
            </div>
          </section>
        )}
        <div className="controls">
          <button onClick={close}>{msg.doneBtnLabl}</button>
        </div>
      </section>
    );
  }
}
