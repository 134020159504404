import { add, remove } from '../lib/favicon-notification';
import { USER_FOLLOWED_STOCKS_SUCCESS } from '../AccountSettings/actions';
import { ReduxAction } from '../../common/types';
import { fromJS, Record, Map } from 'immutable';

import {
  GET_NOTIFICATIONS_SUCCESS,
  SET_NOTIFICATIONS_READ_SUCCESS
} from './actions';
import { FollowedStocksResponse } from '../AccountSettings/actions';
import { GetNotificationsResponse, NotificationsStoreState } from './types';

const initialState: NotificationsStoreState = {
  notifications: [],
  statistics: {
    totalExpertUnread: 0,
    totalStockUnread: 0
  },
  stocks: []
};

export default function NotficiationReducer(
  state: NotificationsStoreState = initialState,
  action: ReduxAction
): NotificationsStoreState {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      // I didn't want to do it.
      // Blame Nitzan.
      // fucking cry baby.
      const unread = (payload as GetNotificationsResponse).notifications.filter(
        n => !n.read
      ).length;

      if (unread > 0) {
        add();
      } else {
        remove();
      }

      return {
        ...state,
        notifications: (payload as GetNotificationsResponse).notifications.map(
          (notification, i) => ({
            ...notification,
            date: new Date(notification.date)
          })
        )
      };
    }
    case SET_NOTIFICATIONS_READ_SUCCESS: {

      return {
        ...state,
        statistics: (payload as GetNotificationsResponse).statistics,
        notifications: state.notifications.map(notif => ({
          ...notif,
          read: notif.date < payload.readUpToDate
        }))
      };
    }
    case USER_FOLLOWED_STOCKS_SUCCESS: {
      return {
        ...state,
        stocks: (payload as FollowedStocksResponse).stocks
      };
    }
    default: {
      return state;
    }
  }
}
