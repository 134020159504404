import { Record, List, Map } from 'immutable';

import { CHANGE_PORTFOLIO } from '../../common/dashboard/actions';
import * as actions from './actions';
import { selectHasUnsureBeenSent } from './selectors';
import { DialogStoreRecord_NotImmutable } from '../../common/types';
import { isOnAuthDialog } from 'sp/common/lib/utils';

export const InitialState = Record({
  activeItems: List(),
  props: Map(),
  unsureSent: false,
  lastUrlBeforeAnAuthPopup: '',
} as DialogStoreRecord_NotImmutable);

const initialState = new InitialState();
const revive = state => initialState.merge(state);

export default (state = initialState, action) => {
  if (!(state instanceof InitialState)) return revive(state);

  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return state
        .set('lastUrlBeforeAnAuthPopup', '') // TODO
        .update(
          'activeItems',
          items =>
            items.includes(action.dialogId)
              ? items
              : items.push(action.dialogId)
        )
        .setIn(['props', action.dialogId], action.props);
    }

    case actions.UNSURE_SENT: {
      return state.set('unsureSent', true);
    }

    case actions.CLOSE_DIALOG: {
      const dialogId = state.get('activeItems').last();

      // TODO this is quite hacky
      // in mobile, the register / login dialog have their own route,
      // so this also gets them back to their original place.
      // if (window.matchMedia('(max-width: 1023px)').matches && isOnAuthDialog())
      //   history.back();

      return state
        .update('activeItems', items => items.pop())
        .deleteIn(['prop', dialogId]);
    }

    case actions.CLOSE_ALL_DIALOGS: {
      return state.update('activeItems', () => List());
    }
  }
  return state;
};
