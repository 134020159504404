import { List, Map } from 'immutable';
import { CHANGE_SELECTED_PORTFOLIO } from './actions';
import { Portfolio, Action } from './types';

const actionHandlers = {
  [CHANGE_SELECTED_PORTFOLIO]: (state, payload) => ({
    ...state,
    selectedPortfolio: payload
  })
};

export const initialState = { selectedPortfolio: Portfolio.AveragePortfolio };

export function reducer(
  state: typeof initialState = initialState,
  action: Action
) {
  const { payload } = action;
  const handler = actionHandlers[action.type];
  const newState = handler ? handler(state, payload) : state;
  return newState;
}
