import { PlaidSyncStatus } from '../../common/types';

export default {
  headline: ({ name }) => `Your portfolio is automatically synced with ${name || 'N/A'}`
};

export const getButtonText = ({
  status,
  isLoading,
  isTablet
}: {
  status: PlaidSyncStatus;
  isLoading: boolean;
  isTablet: boolean;
}) => {
  switch (status) {
    case PlaidSyncStatus.Active: 
    case PlaidSyncStatus.Failure: {
      return isLoading ? 'Updating...' : 'Update' + (isTablet ? ' now' : '');
    }
    case PlaidSyncStatus.ReAuthenticationNeeded: {
      return isLoading
        ? 'Reauthenticating...'
        : 'Reauthenticate' + (isTablet ? ' now' : '');
    }
    default: {
      return '';
    }
  }
};
