import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import SahresCell from '../../tr-dashboard-holdings-section/cells/SharesCell.react';

export default class TrDashboardHoldingsDetailAlerts extends Component {
  static propTypes = {
    item: PropTypes.object
  };

  render() {
    const { item } = this.props;

    return (
      <div className="tr-dahsobard-holdings-detail shares">
        <SahresCell {...this.props} stock={item} />
      </div>
    );
  }
}
