import { getReq } from 'sp/common/api';
import { API_PublicPortfolio_GetPortfolio_Response, API_Stock1 } from 'sp/browser/tr-dashboard-user-performance-container/api.publicportfolio.getportfolio';
import { PortfolioDataForMyPerfPage } from 'sp/browser/tr-dashboard-user-performance-container/component';
import { adjustData as adjustVolatilityBoxData } from 'sp/browser/performance/volatility-box/container';
import { StockTypeEnum } from 'sp/common/types';
import { update, set } from 'lodash';
import { StockPickerNotRecord, AssembledPerfStock } from 'sp/common/components/performance/records';
import { getSectorText, toAssetType } from 'sp/common/lib/utils';
// import { PublicPortfolio } from 'sp/browser/tr-dashboard-user-performance-container/public.profile.selectors';

export const FETCH_PUBLIC_PORTFOLIO = 'FETCH_PUBLIC_PORTFOLIO';
export const FETCH_PUBLIC_PORTFOLIO_START = 'FETCH_PUBLIC_PORTFOLIO_START';
export const FETCH_PUBLIC_PORTFOLIO_ERROR = 'FETCH_PUBLIC_PORTFOLIO_ERROR';
export const FETCH_PUBLIC_PORTFOLIO_SUCCESS = 'FETCH_PUBLIC_PORTFOLIO_SUCCESS';

export type Payload_Public = {};

export function fetchPublicPortfolio(
  publicPortfolioId: number,
): Promise<any> {
  return {
    type: FETCH_PUBLIC_PORTFOLIO,
    data: { publicPortfolioId },
    payload: {
      data: { publicPortfolioId },
      promise: getReq(`/api/publicportfolio/getportfoliobyid`, { id: publicPortfolioId }).then((data: API_PublicPortfolio_GetPortfolio_Response) => {
        const res: PortfolioDataForMyPerfPage = {
          name: data.name,
          id: publicPortfolioId,
          risk: data.statedRisk,
          allocationBox: {
            assetAllocation: data.allocation.byAssetType
              .map(item => ({ id: toAssetType(item.assetTypeEnum), percent: item.percent })),
            companyAllocation: data.allocation.byCompany
              .map(item => ({
                id: item.companyName,
                percent: item.percent,
                type: StockTypeEnum[StockTypeEnum[item.holdings[0].stockTypeId]],
                // we only show the most significant (weighted) ticker for now (nitzan merges secondary tickers like GOOG and GOOGL, and this array would contain both, sorted by weight)
                ticker: item.holdings[0].ticker
              })),
            sectorAllocation: data.allocation.bySector
              .map(item => ({ id: getSectorText(item.sectorEnum)!, percent: item.percent })),
          },
          bestTrade: data.bestTrade,
          creationDate: data.creationDate,
          dividendYield: data.dividendYield,
          hasEnteredHoldings: true,
          holdingOperations: data.operations,
          pickers: data.stockPickerRank.map(picker => update(picker, 'stocks', (stocks: API_Stock1[]) => stocks.map(stock => ({
            ...stock, gainSinceAdded: (
              data.holdings.find(holding => holding.ticker === stock.ticker) || { gainSinceAdded: null }
            ).gainSinceAdded,
            priceDetails: { changeAmount: null, changePercent: null, price: null }
          }) as AssembledPerfStock)) as any), // TODO TS error 'cant convert X to Y' ??
          portfolioManager: data.portfolioManagerRank,
          portfolioPerformanceBox: {
            avgPortfolioData: data.averageReturns[0].averagePortfolioGains.monthGains
              .map(item => ({ ...item, gainValue: item.gain != null ? item.gain * 100 : null })),
            bestPortfolioData: data.averageReturns[0].bestPortfolioGains.monthGains
              .map(item => ({ ...item, gainValue: item.gain != null ? item.gain * 100 : null })),
            myPortfolioData: (data.averageReturns[0].portfolioMonthGains[0] || { monthGains: [] }).monthGains
              .map(item => ({ ...item, gainValue: item.gain != null ? item.gain * 100 : null })),
            snpPortfolioData: data.averageReturns[0].snpGains.monthGains
              .map(item => ({ ...item, gainValue: item.gain != null ? item.gain * 100 : null })),
          },
          sixMonthReturn: data.timeBasedPerformance.sixMonthPerformance,
          portfolioItemsGains: [], // TODO
          yearlyReturn: data.timeBasedPerformance.yearlyPerformance,
          userPic: data.userPicture,
          volatilityBox: adjustVolatilityBoxData({
            myBetaValue: data.volatility.portfolioBeta,
            avgBetaValue: data.avgPortfolioVolatilityBeta.portfolioBeta,
            volatilityItems: data.volatility.mostVolatile.map(
              item => ({ ...item, companyName: item.displayName, betaValue: item.beta })),
          }),
          weightedHoldings: data.holdings.map(holding => ({
            // TODO remove spread
            // stockId: ;
            // ticker: string;
            // numOfShares: ;
            // beta: ;
            // yield: ;
            // price: ;
            // sector: ;
            // holdingValue: ;
            // holdingWeight: ;
            // displayName: string;
            // stockTypeId: ;
            // assetType: ;
            // weight: ;
            // hasShares: boolean;
            // betaWeight: ;
            // hasBeta: boolean;
            // hasYield: boolean;
            // gainSinceAdded: ;
            ...holding,
            percent: holding.weight,
            percentNoCash: holding.weight,
            typePercent: 0,
            sectorPercent: 0,
            percentAsStocks: 0,
            betaPercent: 0,
            type: StockTypeEnum[StockTypeEnum[holding.stockTypeId]],
            isSecondaryTicker: StockTypeEnum[StockTypeEnum[holding.stockTypeId]] === StockTypeEnum.SecondaryTicker,
            name: holding.displayName,
            dividendDate: null,
            sharesValue: 0,
            pe: 0,
            exchangeRate: 1,
          })),
          ytdReturn: data.timeBasedPerformance.ytdPerformance,
          description: data.description,
          userName: data.userName,
          // portfolioName: data.name,
        };
        return res;
      })
    },
  } as any;
}