import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import { dashboard as config } from '../../../common/config';
import { List } from 'immutable';
import { equalWidth } from '../../../common/lib/utils.ts';

const is = option => checked => checked === option;

const changeHandler = (option, checked, onChange) => ({ target }) =>
  onChange(
    'expertTypes',
    target.checked
      ? checked.push(option)
      : checked.delete(checked.indexOf(option))
  );

const renderOption = (checked, onChange, msg) => (option, index, options) => (
  <li key={option} style={equalWidth(options)}>
    <label>
      <input
        checked={checked.some(is(option))}
        name="expertTypes"
        onChange={changeHandler(option, checked, onChange)}
        type="checkbox"
      />
      <span className="label">
        <span className="text">
          <span className="regular">{msg[option].label}</span>
          <span className="long">{msg[option].labelLong}</span>
        </span>
      </span>
    </label>
  </li>
);

export default class ExpertFilters extends Component {
  static propTypes = {
    checked: PropTypes.instanceOf(List),
    msg: PropTypes.object,
    onChange: PropTypes.func
  };

  resetFilters = () => {
    const { onChange } = this.props;
    const options = config.getIn(['filters', 'expertType', 'options']);
    onChange('expertTypes', options);
  };

  componentWillUnmount() {
    this.resetFilters();
  }

  render() {
    const { checked, msg, onChange } = this.props;
    const options = config.getIn(['filters', 'expertType', 'options']);
    return (
      <form>
        <fieldset>
          <legend>
            <span>{msg.legend}</span>
          </legend>
          <ul>{options.map(renderOption(checked, onChange, msg.options))}</ul>
        </fieldset>
      </form>
    );
  }
}
