import React from 'react';
import PropTypes from 'prop-types';
import Component from '../ConventionComponent.react';
import PortfolioPE from './PortfolioPE.react';
import PortfolioRisk from './PortfolioRisk.react';
import PortfolioDividends from './PortfolioDividends.react';
import AssetAllocation from './AssetAllocation.react';
import StockWarnings from './StockWarnings.react';
import NoOfTrades from './NoOfTrades.react';
import AveragePortfolioAd from './AveragePortfolioAd.react';
import { getState } from '../../../../../common/api-actions';
import { suits } from '../../../../../common/enums';
import { ApiDecorator } from '../../../../../browser/ApiDecorator/ApiDecorator.react';
import en from '../../../../../common/intl/messages/en.js';
import UserWarningsContainer from '../../../../tr-user-warnings-container/';
import colors from './colors';
import msg from './msg';
import report from './analytics';
import { selectoPortfolioAnalysis } from './selectors';

import { Map, Record, List, fromJS } from 'immutable';

import { mapValues } from 'lodash';
import { values } from 'lodash';
import { orderBy } from 'lodash';
import { get } from 'lodash';

import TrDashboardMgmtFeesOverviewContainer from '../../../../tr-dashboard-mgmt-fees-overview-container';

import {
  selectActivePortfolio,
  selectAssetAllocationType,
  selectDismissdedWarningDialogs,
  selectTheme,
} from '../../../selectors';

if (process.env.IS_BROWSER) {
  require('./AnalysisPortfolio.styl');
}

@ApiDecorator(
  'portfolios',
  'portfolioItems',
  'stocks',
  'details',
  'portfolioStats',
  'avgPortfolioStats',
  'warnings',
  'eventsByTickers',
  'mgmtFees',
)
// Rename to MyPortfolioAnalysisPage
export default class AnalysisPortfolio extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
  };

  maybeWarnOnMissingShares = () => {
    const { actions, dashboard } = this.props;
    const portfolio = selectActivePortfolio(dashboard);
    const isMissingShares = portfolio.get('modes').includes('missingShares');
    const dismissdedWarningDialogs = selectDismissdedWarningDialogs(dashboard);

    const firstTime = name => !dismissdedWarningDialogs.includes(name);

    const navIn = 'missingSharesOnNavIn';
    if (isMissingShares && firstTime(navIn)) {
      actions.openDialog(navIn);
    }
  };

  componentDidMount() {
    this.maybeWarnOnMissingShares();
    report('visited');
  }

  splitDataToComponents(activeData, avgData) {
    const { dashboard, actions } = this.props;

    const theme = selectTheme(dashboard);

    const {
      chartData,
      holdingsChartData,
      changes,
      pe,
      dividendValues,
      upcomingDividends,
      portfolioYield,
      portfolioYieldValue,
      betaValue,
      volatileStocks,
      warnings,
    } = activeData;

    const activeAsset = selectAssetAllocationType(dashboard);
    return {
      AssetAllocation: {
        onChange: this.onAssetAllocationChange,
        onAddStocksClicked: () => actions.openDialog('addStock'),
        chartData,
        holdingsChartData: fromJS(holdingsChartData[activeAsset]),
        isSwipe: !this.props.mediaQuery.get('laptop'),
        activeAsset,
        colors: colors(theme),
      },
      PortfolioPE: {
        pe,
        avgPe: avgData.pe,
      },
      NoOfTrades: {
        changes,
        avgChanges: avgData.changes,
      },
      AveragePortfolioAd: {
        clickedCompare: () => report('clickedCompare'),
      },
      PortfolioDividends: {
        dividends: dividendValues,
        coming: upcomingDividends,
        portfolioYield,
        portfolioYieldValue,
        clickedDividendsGlossary: () => report('clickedDividendsGlossary'),
      },
      PortfolioRisk: {
        beta: betaValue,
        volatileStocks,
        clickedVolatilityGlossary: () => report('clickedVolatilityGlossary'),
      },
      StockWarnings: {
        warnings,
      },
    };
  }

  renderMobile(props) {
    return (
      <div className="portfolio-analysis-wrapper">
        <div className="user-warnings-wrapper">
          <UserWarningsContainer {...this.props} msg={msg.warnings} />
        </div>
        <div className="portfolio-analysis mobile">
          <AssetAllocation {...props.AssetAllocation} />
          <div className="TrDashboardMgmtFeesOverviewContainer-wrapper">
            <TrDashboardMgmtFeesOverviewContainer {...this.props} />
          </div>
          <AveragePortfolioAd
            {...props.AveragePortfolioAd}
            theme={props.theme}
          />
          <PortfolioRisk {...props.PortfolioRisk} />
          <PortfolioDividends {...props.PortfolioDividends} />
          <StockWarnings
            {...props.StockWarnings}
            msg={this.props.msg.enums.analystConsensus}
          />
        </div>
      </div>
    );
  }

  renderDesktop(props) {
    return (
      <div className="portfolio-analysis-wrapper">
        <div className="user-warnings-wrapper">
          <UserWarningsContainer {...this.props} msg={msg.warnings} />
        </div>
        <div className="portfolio-analysis">
          <main className="left-area-portfolio-analysis">
            <AssetAllocation {...props.AssetAllocation} />
            <div className="TrDashboardMgmtFeesOverviewContainer-wrapper">
              <TrDashboardMgmtFeesOverviewContainer {...this.props} />
            </div>
            <AveragePortfolioAd
              {...props.AveragePortfolioAd}
              theme={props.theme}
            />
          </main>
          <aside className="right-area-portfolio-analysis">
            <PortfolioRisk {...props.PortfolioRisk} />
            <PortfolioDividends {...props.PortfolioDividends} />
            <StockWarnings
              {...props.StockWarnings}
              msg={this.props.msg.enums.analystConsensus}
            />
            <PortfolioPE {...props.PortfolioPE} />
          </aside>
        </div>
      </div>
    );
  }

  onAssetAllocationChange = activeAsset => {
    const { actions } = this.props;
    actions.assetAllocationChange(activeAsset);
  };

  render() {
    const { dashboard, mediaQuery } = this.props;

    const theme = selectTheme(dashboard);

    if (typeof window === 'undefined') return null;
    const activeData = selectoPortfolioAnalysis(dashboard);
    const avgData = selectoPortfolioAnalysis(dashboard, 'avg');
    const props = {
      ...this.splitDataToComponents(activeData, avgData),
      theme,
    };
    return this.props.mediaQuery.get('laptopS')
      ? this.renderDesktop(props)
      : this.renderMobile(props);
  }
}
