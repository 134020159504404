import * as FileSaver from 'file-saver';

import { getSectorText } from '../../common/lib/utils';
import { holdingsSectionConfig } from '../../common/config';

import {
  EtfAssetTypeMapping,
  EtfCategoryMapping,
  InvestmentStrategyMapping,
  Issuer,
} from '../../common/types';
import * as msgs from '../../common/intl/messages/en.js';
import { isAllowed } from 'sp/common/auth/selectors';
import { currencySymbolFromTicker } from 'sp/browser/Money/Money';
import { calc1YrReturn } from 'sp/browser/PortfolioItemSelector/utils';
import { isCanadianTicker } from 'sp/browser/lib/utils';
// import { isShittyBrowser } from 'sp/browser/lib/utils';

// this file makes me oof.

const toDateString = date =>
  date && `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;

const toDollar = (datum, decimals = 2, currency = '$') => {
  const sign = datum > 0 ? '' : '-';
  const value = Math.abs(datum).toFixed(decimals);
  return `${sign}${currency}${value}`;
};

const toPercent = (datum: number, decimals = 2) =>
  `${(datum * 100).toFixed(decimals)}%`;

const naIfNaN = datum => (isNaN(datum) ? '0' : datum);

const colsMap = enums => ({
  name: obj => `"${obj.getIn(['stock', 'name'])}"`, // we quote the name, in case it has a comma http://www.creativyst.com/Doc/Articles/CSV/CSV01.htm#FileFormat
  ticker: obj => obj.getIn(['stock', 'ticker']).toUpperCase(),
  price: obj =>
    toDollar(obj.getIn(['stock', 'price', 'amount']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  priceChangePercent: obj =>
    toPercent(obj.getIn(['stock', 'price', 'changePercent'])),
  priceChange: obj =>
    toDollar(obj.getIn(['stock', 'price', 'changeAmount']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  target: obj => !!obj.getIn(['stock', 'target']) ? toDollar(obj.getIn(['stock', 'target']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))) : '-',
  targetPercent: obj => toPercent(obj.getIn(['stock', 'targetPercent'])),
  bestTarget: obj =>
    toDollar(obj.getIn(['stock', 'bestTarget']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  bestTargetPercent: obj =>
    toPercent(obj.getIn(['stock', 'bestTargetPercent'])),
  analystConsensus: obj =>
    enums.analystConsensus[
    obj.getIn(['stock', 'analystConsensus', 'decision'])
    ],
  bestAnalystConsensus: obj =>
    enums.analystConsensus[
    obj.getIn(['stock', 'bestAnalystConsensus', 'decision'])
    ],
  insiderSentiment: obj =>
    enums.insiderSentiment[obj.getIn(['stock', 'insiderSentiment'])],
  highLow: obj =>
    `${obj.getIn(['stock', 'price', 'ftWeekLow'])}-${obj.getIn([
      'stock',
      'price',
      'ftWeekHigh',
    ])}`,
  added: obj => toDateString(obj.getIn(['addedDate'])),
  shares: obj => obj.getIn(['sharesTotal']),
  value: obj => !!obj.getIn(['sharesValue']) ? toDollar(obj.getIn(['sharesValue']), 2) : '-',
  percent: obj => toPercent(obj.getIn(['percentPortfolio'])),
  dividend: obj =>
    toDollar(obj.getIn(['stock', 'dividend']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  yield: obj => toPercent(obj.getIn(['stock', 'yield'])),
  dividendDate: obj => toDateString(obj.getIn(['stock', 'dividendDate'])),
  earningsReport: obj => toDateString(obj.getIn(['stock', 'earningsReport'])),
  eps: obj => toDateString(obj.getIn(['stock', 'eps'])),
  sector: obj => getSectorText(obj.getIn(['stock', 'sector'])),
  cap: obj => naIfNaN(obj.getIn(['stock', 'price', 'cap'])),
  beta: obj => obj.getIn(['stock', 'price', 'beta']),
  hedgefundSentiment: obj => {
    const score = obj.getIn(['stock', 'hedgefundSentimentScore']);
    if (typeof score !== 'number' || isCanadianTicker(obj.getIn(['stock', 'ticker']))) {
      return '-';
    }
    const sentiment = score < 0.4 ? 3 : score >= 0.4 && score < 0.6 ? 2 : 1;
    return enums.hedgefundSentiment[sentiment];
  },
  purchasePrice: obj =>
    toDollar(obj.getIn(['purchasePrice']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  addedPrice: obj => toDollar(obj.getIn(['addedPrice']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  bloggerSentiment: obj =>
    enums.bloggerSentiment[obj.getIn(['stock', 'bloggerSentiment'])],
  newsSentimentScore: obj => {
    const score = obj.getIn(['stock', 'newsSentimentScore']);
    if (typeof score !== 'number') {
      return '-';
    }
    const sentiment = score > 0.6 ? 1 : score < 0.4 ? 3 : 2;
    return enums.bloggerSentiment[sentiment];
  },
  pe: obj => obj.getIn(['stock', 'pe']),
  gainSinceAdded: obj => toPercent(obj.getIn(['gainSinceAdded'])),
  sincePurchaseGain: obj => toPercent(obj.getIn(['sincePurchaseGain'])),
  investmentStyle: obj =>
    InvestmentStrategyMapping[obj.getIn(['stock', 'investmentStyle'])],
  category: obj => obj.getIn(['stock', 'category']),
  totalAssets: obj =>
    toDollar(obj.getIn(['stock', 'totalAssets']), 2, currencySymbolFromTicker(obj.getIn(['stock', 'ticker']))),
  '1yrReturn': obj => `${(calc1YrReturn(obj) || 0).toFixed(2)}%`,
  '3yrReturn': obj => toPercent(obj.getIn(['stock', '3yrReturn'])),
  '5yrReturn': obj => toPercent(obj.getIn(['stock', '5yrReturn'])),

  restricted: () => 'Restricted',
});

const colToColEntrySeq = obj => col => [col, obj];

const valViewByKey = (colsMap, key, obj) =>
  (colsMap[key] || (() => obj.get(key)))(obj);

const outEntrySeqByKey = colsMap => key => colsMap[key];

const entrySeqToValView = colsMap => ([key, obj]) =>
  valViewByKey(colsMap, key, obj);

const toRestrict = viewerPlan => ([key, obj]) =>
  holdingsSectionConfig.get('premium').includes(key) &&
    !isAllowed('premium', viewerPlan)
    ? ['restricted', obj]
    : [key, obj];

const toColsWithSimilar = colIndex => (cols, currentSimilar, similarIndex) =>
  cols.insert(colIndex + similarIndex + 1, currentSimilar);

const similarColsToCols = (cols, currentCol) =>
  cols.includes(currentCol.col)
    ? currentCol.similar.reduce(
      toColsWithSimilar(cols.indexOf(currentCol.col)),
      cols,
    )
    : cols;

const attachSimilarCols = cols =>
  [
    { col: 'name', similar: ['ticker'] },
    { col: 'price', similar: ['priceChangePercent', 'priceChange'] },
    { col: 'target', similar: ['targetPercent'] },
    { col: 'bestTarget', similar: ['bestTargetPercent'] },
  ].reduce(similarColsToCols, cols);

export const exportToPortfolioCSV = ({
  cashValue,
  enums,
  fileName,
  holdingsCols,
  items,
  limit = {} as { [key: string]: number },
  viewerPlan,
}) => {
  const cols = holdingsCols.update(attachSimilarCols);
  const colsValueMap = colsMap(enums);

  const maxItems = limit[viewerPlan] || Infinity;

  const keyNames = cols
    .filter(outEntrySeqByKey(colsValueMap))
    .map(key => msgs.cols[key])
    .join(',');

  const objToLine = obj =>
    cols
      .filter(outEntrySeqByKey(colsValueMap))
      .map(colToColEntrySeq(obj))
      .map(toRestrict(viewerPlan))
      .map(entrySeqToValView(colsValueMap))
      .join(',');

  const cashValueLink = cashValue
    ? cols
      .filter(outEntrySeqByKey(colsValueMap))
      .map(col =>
        (({
          name: () => 'Cash ($)',
          value: () => cashValue,
          percentPortfolio: '',
        }[col] || (() => null))()),
    )
      .join(',')
    : '';

  const youAreLimited =
    (viewerPlan === 'open' || viewerPlan === 'free') && items.size > maxItems
      ? `You are limited to ${maxItems} items. Please upgrade your TipRanks plan to export more items`
      : '';

  const dataTable = items
    .map(objToLine)
    .slice(0, maxItems)
    .push(cashValueLink)
    .push(youAreLimited)
    .unshift(keyNames)
    .join('\n');

  const isSafari = navigator.userAgent.indexOf('Safari') > -1;

  const type = isSafari ? 'application/octet-stream' : 'text/csv;charset=utf-8';

  const blob = new Blob([dataTable], { type });
  FileSaver.saveAs(blob, fileName);

  // const csv = dataTable.match(/^data:text\/csv/i) ?
  //   dataTable :
  //   'data:text/csv;charset=utf-8,' + dataTable;
  //
  // const data = encodeURI(csv);
  //
  // if (isShittyBrowser) {
  //   const blob = new Blob([dataTable],{
  //     type: "text/csv;charset=utf-8;"
  //   });
  //   navigator.msSaveBlob(blob, fileName);
  // } else {
  //   const link = document.createElement('a');
  //   link.setAttribute('href', data);
  //   link.setAttribute('download', fileName);
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }
};

export default exportToPortfolioCSV;
