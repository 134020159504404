import { mapValues } from 'lodash';
import { values } from 'lodash';
import { PortfolioManager } from '../../../PortfolioManager';
import { fromJS, List } from 'immutable';
import assetIds from './assetIds';
import { get } from 'lodash';
import { memoize } from 'lodash';
import { createSelector } from 'reselect';

import {
  selectPortfolioStats,
  selectAvgPortfolioStats,
  selectBestPortfolioStats,
  selectPortfolioWarnings,
  selectPortfolioItems,
  selectBestPortfolioItems,
  selectAvgPortfolioItems,
  selectActivePortfolio,
  selectCashValue,
} from '../../../selectors';
import { Dashboard } from 'sp/common/types';
import { TypesOfPortfolios } from 'sp/common/enums';
import * as _ from 'lodash';
import { SectorText } from 'sp/common/lib/utils';
import { stockLimitBeforeJoinToOther } from 'sp/common/config';
import { BetterRecord } from 'sp/common/immutableStuff';

/** Old representation of asset types
 * TODO move to AssetType enum.
*/
export type AssetTypeText = 'stock' | 'etf' | 'fund' | 'cash';

type PortfolioAnalysisStockAllocation = {
  id: AssetTypeText;
  count: number;
  percent: number;
};

type PortfolioAnalysisStockAllocationImmutable = List<
  BetterRecord<PortfolioAnalysisStockAllocation>
  >;

// takes an array and returns a trimmed array where the last item is the accumulation
// using the provided accumulator of the last <arr.length - index> items in the origin array.
const groupFromIndex = (arr, index, fn, init) => {
  if (arr.length <= index) {
    return arr;
  }
  const last = arr.slice(index).reduce(fn, init);
  return arr.slice(0, index).concat(last);
};

export const selectPortfolioManager = createSelector(
  (dashboard: Dashboard, type = TypesOfPortfolios.ActivePortfolio) =>
    ({
      active: selectPortfolioItems,
      avg: selectAvgPortfolioItems,
      best: selectBestPortfolioItems,
    }[type](dashboard)),
  selectActivePortfolio,
  (items, portfolio) =>
    new PortfolioManager(items, () => portfolio.get('cashValue')),
);

export const selectWeightedHoldings = createSelector(
  selectPortfolioManager,
  manager => manager.weighted(),
);

export const selectPortfolioBeta = createSelector(
  selectWeightedHoldings,
  holdings => holdings.beta(),
);
/** a magic number used throughout my portfolio analysis / crowd insights
 * TODO document
 */
const selectPortfolioAnalysis = createSelector(
  selectWeightedHoldings,
  selectPortfolioBeta,
  selectCashValue,
  (myHoldings, betaValue, cashValue, ...args) => {
    const assetsByType = myHoldings.byType();

    const assetsBySectors = myHoldings.byTypeAndSector();

    const histogram = mapValues(assetsBySectors, byType => {
      const mappedValues = values(
        mapValues(byType, (bySector, id) => ({
          id: !id ? 'other' : id,
          count: bySector.length,
          percent:
            bySector.reduce((total, holding) => total + holding.percent, 0)
        })),
      );

      const sortedValues = mappedValues.sort((a, b) => a.percent >= b.percent ? -1 : 1);
      const otherIndex = _.findIndex(sortedValues, d => d.id === 'other');
      const initialOther = otherIndex !== -1 && sortedValues.length > stockLimitBeforeJoinToOther
        ? sortedValues.splice(otherIndex, 1)[0]
        : { id: 'other', count: 0, percent: 0 };

      return groupFromIndex(
        sortedValues,
        stockLimitBeforeJoinToOther,
        (acc, cur) => {
          acc.count += cur.count;
          acc.percent += cur.percent;
          return acc;
        },
        initialOther,
      );
    });

    const weightedHoldingsByTicker = myHoldings
      .holdings()
      .reduce((acc, cur) => {
        acc[cur.ticker.toLowerCase()] = cur.typePercent;
        return acc;
      }, {});

    const weightedHoldingsByTypeAndSector = myHoldings.byTypeAndSector();

    const holdingsChartData = mapValues(assetsBySectors, (allData, typeId) => {
      const sectorValues = values(
        mapValues(allData, (sectorData, sectorId/*TODO : keyof typeof SectorText*/ = 'other') => {
          const count = weightedHoldingsByTypeAndSector[typeId][
            sectorId
          ].reduce((acc, h) => acc + h.typePercent, 0);
          return {
            id: sectorId,
            count,
            sharesValue: weightedHoldingsByTypeAndSector[typeId][
              sectorId
            ].reduce((acc, h) => acc + h.sharesValue, 0),
            companies: values(
              mapValues(sectorData, ({ ticker, name, type }: any) => ({
                count: weightedHoldingsByTicker[ticker.toLowerCase()],
                name: name,
                type,
                ticker: ticker.toUpperCase(),
              })),
            ),
          };
        }),
      );

      const sortedValues = sectorValues.sort((a, b) => a.count >= b.count ? -1 : 1);
      const otherKey = sortedValues.findIndex(d => d.id === 'other')
      const other = otherKey !== -1 && sortedValues.length > stockLimitBeforeJoinToOther
        ? sortedValues.splice(otherKey, 1)[0]
        : { id: 'other', count: 0, companies: [], };
      const organized = groupFromIndex(
        sortedValues,
        stockLimitBeforeJoinToOther,
        (acc, cur) => {
          acc.count += cur.count;
          acc.companies.push(...cur.companies);
          return acc;
        },
        other,
      );

      return organized.map(x => {
        x.companies = x.companies.sort((a, b) => a.count >= b.count ? -1 : 1);
        return x;
      });
    });
    const { stock: stocks = [], etf: etfs = [], fund: funds = [] } = histogram;

    const stockPercent = parseFloat(
      (assetsByType[assetIds.assetId_stock] || [])
        .reduce((sum, x) => sum + x.percent, 0).toString(),
    );
    const etfPercent = parseFloat(
      (assetsByType[assetIds.assetId_etf] || [])
        .reduce((sum, x) => sum + x.percent, 0).toString(),
    );
    const fundPercent = parseFloat(
      (assetsByType[assetIds.assetId_fund] || [])
        .reduce((sum, x) => sum + x.percent, 0).toString(),
    );

    // TODO as number - can it be done better?
    const stockCount = get(
      assetsByType,
      [assetIds.assetId_stock, 'length'],
      0,
    ) as number;
    const etfCount = get(
      assetsByType,
      [assetIds.assetId_etf, 'length'],
      0,
    ) as number;
    const fundCount = get(
      assetsByType,
      [assetIds.assetId_fund, 'length'],
      0,
    ) as number;
    const count = stockCount + etfCount + fundCount;

    const possiblyCashPercent = 1 - stockPercent - etfPercent - fundPercent;
    const cashPercent =
      count > 0 ? (cashValue > 0 ? possiblyCashPercent : 0) : 0;

    // TODO refactor to use "AssetType"
    const stockAllocation = [
      {
        id: assetIds.assetId_stock,
        count: stockCount,
        percent: stockPercent,
      },
      {
        id: assetIds.assetId_etf,
        count: etfCount,
        percent: etfPercent,
      },
      {
        id: assetIds.assetId_fund,
        count: fundCount,
        percent: fundPercent,
      },
      {
        id: 'cash',
        count: count * cashPercent,
        percent: cashPercent,
      },
    ];

    const chartData = {
      stockAllocation: fromJS(stockAllocation) as List<BetterRecord<{ id: string, count: number, percent: number }>>,
      assetAllocation: fromJS({
        [assetIds.assetId_stock]: stocks,
        [assetIds.assetId_etf]: etfs,
        [assetIds.assetId_fund]: funds,
      }) as BetterRecord<{ stock: any, etf: any, fund: any }>,
    };

    return {
      chartData,
      holdingsChartData,
      pe: myHoldings.pe(),
      dividendValues: myHoldings.topDividendStocks(3),
      upcomingDividends: myHoldings.nextDividendStocks(3),
      portfolioYield: myHoldings.dividendYield(),
      portfolioYieldValue: myHoldings.dividendYieldValue(),
      betaValue,
      volatileStocks: myHoldings.mostVolatile(3),
    };
  },
);

const selectPortfolioWarningsList = createSelector(
  selectPortfolioWarnings,
  warnings =>
    warnings
      .toSeq()
      .sortBy(x => x!.get('date'))
      .reverse()
      .toList(),
);
export const selectoPortfolioAnalysis = createSelector(
  selectPortfolioAnalysis,
  (
    state: Dashboard,
    type: TypesOfPortfolios = TypesOfPortfolios.ActivePortfolio,
  ) =>
    ({
      [TypesOfPortfolios.ActivePortfolio]: selectPortfolioStats,
      [TypesOfPortfolios.AveragePortfolio]: selectAvgPortfolioStats,
      [TypesOfPortfolios.BestPortfolio]: selectBestPortfolioStats,
    }[type](state)),
  selectPortfolioWarningsList,
  (analysis, stats, warnings) => {
    return {
      warnings,
      ...analysis,
      ...stats.toJS<any>(),
    };
  },
);

export default selectoPortfolioAnalysis;
