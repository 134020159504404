import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import * as images from './images';
import htmlString from 'raw-loader!./index.html';
import report from './analytics';

import { getBlueSnapPaymentUrl } from '../tr-payment';
import { connect } from 'react-redux';

@connect(({ auth }) => ({ auth }))
export default class TrPlans extends Component {
  componentWillMount() {
    report('visited');
    this.__html = htmlString.replace(/src="([^"]*)"/g, (m, m1) =>
      m.replace(m1, images.map[m1]),
    );
  }

  componentDidMount() {
    const { container } = this.refs;

    const monthly = container.querySelector('.subscription-monthly');
    monthly.href = this.getPaymentLink('nasdaqQuarterly');
    monthly.target = '_blank';

    const yearly = container.querySelector('.subscription-yearly');
    yearly.href = this.getPaymentLink('nasdaqYearly');
    yearly.target = '_blank';

    const biyearly = container.querySelector('.subscription-biyearly');
    biyearly.href = this.getPaymentLink('nasdaqBiyearly');
    biyearly.target = '_blank';
  }

  getPaymentLink = type => getBlueSnapPaymentUrl(this.props.auth, type);

  get user() {
    const { auth } = this.props;
    const email = auth.getIn(['users', 'viewer', 'loggedInUser', 'email']);
    const name = auth.getIn(['users', 'viewer', 'loggedInUser', 'name']);
    return { email, firstName: name };
  }

  render() {
    return (
      <div
        className="tr-plans"
        dangerouslySetInnerHTML={{ __html: this.__html }}
        ref="container"
      />
    );
  }
}
