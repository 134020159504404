import {
  ComponentStateKeys,
  ComponentStateKeysFlat,
  ReduxProps,
} from './types';
import { List } from 'immutable';

import { companyConstants } from './config';
import { suits, TypesOfPortfolios } from './enums';
import {
  ignoreApisSelector,
  selectActivePortfolio,
  selectActiveTickers,
  selectAvgPortfolio,
  selectAvgPortfolioTickers,
  selectBestPortfolioTickers,
  selectOverviewPortfolioNewsTab,
  selectPortfolioNewsTicker,
  selectProccessStates,
  selectStocks,
  selectUserInfo,
  selectActiveTickersLength,
  selectAboveBeta,
} from '../browser/dashboard/selectors';
import {
  selectIsLoggedIn,
  selectIsPermanentLoggedIn,
  selectIsTemporaryLoggedIn,
  selectViewer,
} from './auth/selectors';
import { selectScreenerStocks } from '../browser/tr-stock-screener-container/selectors';
import { selectPortfolioBeta } from '../browser/dashboard/routes/analysis/portfolio/selectors';
import { apiDependency as myPerformanceDependencyHandlers } from '../common/components/performance/api';
import { shouldIgnoreApi } from 'sp/common/lib/utils';
import { isNumber, difference } from 'lodash';

// TODO
const shouldFetch = (force, isDirty, hasCacheExpired, isValid) =>
  (force || (hasCacheExpired && isDirty)) && isValid;

const tryFetch = (
  action: () => void,
  { force = true, isDirty = true, hasCacheExpired = true, isValid = true },
) => {
  if (shouldFetch(force, isDirty, hasCacheExpired, isValid)) {
    // TODO type hack, we do this because we DO GET a promise from tryFetch, but the automatic typing
    // of actions only gives us correct signatures, but incorrect return value for now.
    const res: Promise<any> = action() as any;

    return res;
  }
  return Promise.resolve(null);
};

let hasFetchedPortfolios = false;
let hasFetchedPortfolioItems = false;

const fetchedItems = new Set<number>();

export const maybefetch = (
  name: ComponentStateKeys,
  props: ReduxProps,
  {
    prevProps,
    force = false,
    silent = false,
  }: { prevProps?: ReduxProps; force?: boolean; silent?: boolean } = {
      prevProps: undefined,
    },
) => {
  const itemsById = (
    portfolioId: number,
    type,
    props,
    prevProps,
    force,
  ) => () => {
    const { actions, dashboard } = props;
    const apiSignatures = ignoreApisSelector(dashboard);
    const actionArgs = [portfolioId, type];

    const action = () => {
      fetchedItems.add(portfolioId);
      return actions.getPortfolioItems(...actionArgs);
    };

    const isDirty = !fetchedItems.has(portfolioId);
    const isValid = isNumber(portfolioId);
    const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

    return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
  };

  switch (name) {
    case 'portfolios': {
      const { actions, auth /* , dashboard */ } = props;
      // const apiSignatures = ignoreApisSelector(dashboard);
      const user = selectViewer(auth);
      const actionArgs = [auth.getIn(['loggedInUser', 'id'])];

      const action = () => actions.fetchPortfolios(...actionArgs);

      const isValid = true;
      const hasCacheExpired = true; //! shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty = !prevProps || !user.equals(selectViewer(prevProps.auth));

      // race confition!
      if (!hasFetchedPortfolios) {
        setTimeout(() => {
          if (isDirty) {
            hasFetchedPortfolios = true;
            // we want to allow another fetch in the future
            setTimeout(() => {
              hasFetchedPortfolios = false;
            }, 10e3);
          }
        }, 1);
        return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
      }
    }

    case 'userPref': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');

      const action = () => actions.fetchUserPerformance(portfolioId);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId !== -1;

      const hasCacheExpired = true;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'userInfo': {
      const { actions } = props;

      const action = () => actions.fetchUserExtendedProperties();

      const isDirty = !prevProps || !selectUserInfo(prevProps.dashboard);
      const isValid = selectIsPermanentLoggedIn();
      const hasCacheExpired = true;
      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'stats': {
      const { actions, dashboard } = props;
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [];

      const action = () => actions.fetchStats(...actionArgs);

      const isValid = true;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty = !prevProps;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'experts': {
      const { actions, dashboard, auth } = props;
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [];

      const action = () => actions.fetchExperts(...actionArgs);

      const isValid = selectIsLoggedIn();
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty = dashboard.get('componentStates').get('experts') !== 'LOADING';

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'eventsByTickers': {
      const { actions, dashboard } = props;
      const tickers = selectActiveTickers(dashboard);
      // const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers];
      const action = () => actions.fetchPortfolioEvents(tickers);

      const isValid = selectActiveTickersLength(dashboard) > 0;
      const hasCacheExpired = true; //! shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty =
        !prevProps ||
        difference(tickers, selectActiveTickers(prevProps.dashboard)).length !==
        0;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'fundsFeesByTickers': {
      const { actions, dashboard } = props;
      const tickers = selectActiveTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers];
      const action = () => actions.getFundsFees(tickers);

      const isValid = selectActiveTickersLength(dashboard) > 0;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty =
        !prevProps ||
        difference(tickers, selectActiveTickers(prevProps.dashboard)).length !==
        0;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'newsByTickers': {
      const { actions, dashboard } = props;
      const activeTickers = selectActiveTickers(dashboard);
      // const apiSignatures = ignoreApisSelector(dashboard);
      const selectedTicker = selectPortfolioNewsTicker(dashboard);
      const currentTab = selectOverviewPortfolioNewsTab(dashboard);
      const sentiment = {
        bullish: 'positive',
        bearish: 'negative',
        all: 'all',
      }[currentTab];

      const tickers =
        selectedTicker === '-1' ? activeTickers : [selectedTicker];
      const actionArgs = [tickers];
      const action = () => actions.fetchPortfolioNews(tickers, sentiment);

      const isValid = selectActiveTickersLength(dashboard) > 0;
      const hasCacheExpired = true; //! shouldIgnoreApi(apiSignatures, name, actionArgs);

      const isDirty =
        !prevProps ||
        difference(activeTickers, selectActiveTickers(prevProps.dashboard))
          .length !== 0 ||
        selectedTicker !== selectPortfolioNewsTicker(prevProps.dashboard) ||
        currentTab !== selectOverviewPortfolioNewsTab(prevProps.dashboard);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'alertsSettings': {
      const isOnboardingUser = selectIsTemporaryLoggedIn();
      if (isOnboardingUser) return;
      const { actions, dashboard, auth } = props;
      const portfolio = selectActivePortfolio(dashboard);
      const portfolioId = portfolio.get('id');
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [portfolioId];

      const action = () => actions.fetchAlertsSettings(...actionArgs);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'portfolioItems': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      // const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [portfolioId, 'active'];

      const action = () => actions.getPortfolioItems(...actionArgs);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      if (prevProps) hasFetchedPortfolioItems = false;

      const isCombined =
        portfolioId === companyConstants.get('combinedUserPortfolio');
      const isValid = portfolioId >= 0 || isCombined;

      const hasCacheExpired = true; //!shouldIgnoreApi(apiSignatures, name, actionArgs);

      force = force || (isCombined && dashboard.get('stockAddedFlag').size > 0);

      if (!hasFetchedPortfolioItems) {
        setTimeout(() => {
          if (isDirty) {
            hasFetchedPortfolioItems = true;
          }
        }, 1);
        return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
      }
    }

    case 'portfolioGains': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [portfolioId];

      const action = () => (actions.getPortfolioGains as any)(...actionArgs);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;

      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'portfolioMonthlyGain': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [[portfolioId]];

      const action = () => actions.getMyAverageReturn([portfolioId]);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;

      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'avgPortfolioItems': {
      itemsById(
        companyConstants.get('avgPortfolioId'),
        'avg',
        props,
        prevProps,
        force,
      )();
    }

    case 'bestPortfolioItems': {
      itemsById(
        companyConstants.get('bestPortfolioId'),
        'best',
        props,
        prevProps,
        force,
      )();
    }

    case 'portfolioStats': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');

      const action = () =>
        actions.getPortfolioStats(selectActivePortfolio(dashboard).get('id'));

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;

      const hasCacheExpired = true;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'avgPortfolioStats': {
      const { actions, dashboard } = props;
      const portfolioId = selectAvgPortfolio(dashboard).get('id');

      const action = () =>
        actions.getPortfolioStats(selectAvgPortfolio(dashboard).get('id'));

      const isDirty =
        !prevProps ||
        portfolioId !== selectAvgPortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;

      const hasCacheExpired = true;

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'stocks': {
      const { actions, dashboard } = props;
      const tickers = selectActiveTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers, TypesOfPortfolios.ActivePortfolio];
      const action = () =>
        actions.getPortfolioHoldingStockData(
          tickers,
          TypesOfPortfolios.ActivePortfolio,
        );

      const isDirty =
        !prevProps ||
        difference(tickers, selectActiveTickers(prevProps.dashboard)).length !==
        0;
      const isValid =
        selectActiveTickersLength(dashboard) > 0 &&
        selectProccessStates(dashboard).get('stocks') !== suits.LOADING;

      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'avgPortfolioStocks': {
      const { actions, dashboard } = props;
      const tickers = selectAvgPortfolioTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers, TypesOfPortfolios.AveragePortfolio];
      const action = () =>
        actions.getPortfolioHoldingStockData(
          tickers,
          TypesOfPortfolios.AveragePortfolio,
        );

      const prevAvgTickers =
        prevProps && selectAvgPortfolioTickers(prevProps.dashboard);
      const isDirty =
        !prevProps || difference(tickers, prevAvgTickers).length !== 0;

      const isValid =
        tickers.length > 0 &&
        selectProccessStates(dashboard).get('avgPortfolioStocks') !== suits.LOADING;

      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'bestStocks': {
      const { actions, dashboard } = props;
      const tickers = selectBestPortfolioTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers, TypesOfPortfolios.BestPortfolio];
      const action = () =>
        actions.getPortfolioHoldingStockData(
          tickers,
          TypesOfPortfolios.BestPortfolio,
        );

      const isDirty =
        !prevProps ||
        difference(tickers, selectBestPortfolioTickers(prevProps.dashboard))
          .length !== 0;

      const isValid =
        tickers.length > 0 &&
        selectProccessStates(dashboard).get('bestStocks') !== suits.LOADING;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'portfolioBetaRisk': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      // put 0 instead of null in portfolioRisk - don't send null to the backend
      //, it'll return 400, we'll get stuck.
      const portfolioRisk = selectPortfolioBeta(dashboard, 'active') || 0;

      const isDirty =
        !prevProps ||
        !selectAboveBeta(dashboard) ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id') ||
        dashboard.get('stockAddedFlag').has(portfolioId);

      const isValid =
        portfolioRisk !== 0 &&
        selectProccessStates(dashboard).get('portfolioBetaRisk') !==
        suits.LOADING;

      const action = () => actions.getPortfolioBetaRisk(portfolioRisk);
      return tryFetch(action, { force, isDirty, isValid });
    }

    case 'details': {
      const { actions, dashboard } = props;
      const portfolioTickers = selectActiveTickers(dashboard);
      // TODO should this be here @madara?
      // this seems like a relic from piggybacking on top of GET_DETAILS and GET_STOCKS to
      // finish the screeners faster (which I'm not sure was appropriate either).
      // TODO does this bring in extra tickers each call?
      const stockScreenerTickers = selectScreenerStocks(props, 'stockScreener');
      const etfScreenerTickers = selectScreenerStocks(props, 'etfScreener');
      const apiSignatures = ignoreApisSelector(dashboard);
      const tickers = portfolioTickers
        .concat(stockScreenerTickers.map(stock => stock.get('ticker')).toJS())
        .concat(etfScreenerTickers.map(stock => stock.get('ticker')).toJS());

      const actionArgs = [tickers, { silent }];
      const action = () =>
        actions.fetchDetails(tickers, {
          silent,
          portfolioType: TypesOfPortfolios.ActivePortfolio,
        });
      const isDirty =
        !prevProps ||
        difference(portfolioTickers, selectActiveTickers(prevProps.dashboard))
          .length !== 0 ||
        !stockScreenerTickers.equals(
          selectScreenerStocks(prevProps, 'stockScreener'),
        ) ||
        !etfScreenerTickers.equals(
          selectScreenerStocks(prevProps, 'etfScreener'),
        );

      const isValid = tickers.length > 0;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, {
        force,
        isDirty,
        hasCacheExpired,
        isValid,
      });
    }

    case 'avgPortfolioDetails': {
      const { actions, dashboard } = props;
      const tickers = selectAvgPortfolioTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [
        tickers,
        { silent, portfolioType: TypesOfPortfolios.AveragePortfolio },
      ];

      const action = () =>
        actions.fetchDetails(tickers, {
          silent,
          portfolioType: TypesOfPortfolios.AveragePortfolio,
        });

      const isDirty =
        !prevProps ||
        difference(tickers, selectAvgPortfolioTickers(prevProps.dashboard))
          .length !== 0;

      const isValid = tickers.length > 0;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'bestDetails': {
      const { actions, dashboard } = props;
      const tickers = selectBestPortfolioTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);
      const actionArgs = [
        tickers,
        { silent, portfolioType: TypesOfPortfolios.BestPortfolio },
      ];

      const action = () =>
        actions.fetchDetails(tickers, {
          silent,
          portfolioType: TypesOfPortfolios.BestPortfolio,
        });

      const isDirty =
        !prevProps ||
        difference(tickers, selectBestPortfolioTickers(prevProps.dashboard))
          .length !== 0;

      const isValid = tickers.length > 0;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'warnings': {
      const { actions, dashboard } = props;
      const portfolioId = selectActivePortfolio(dashboard).get('id');
      const action = () => actions.getPortfolioWarnings(portfolioId);

      const isDirty =
        !prevProps ||
        portfolioId !== selectActivePortfolio(prevProps.dashboard).get('id');

      const isValid = portfolioId >= 0;

      return tryFetch(action, { force, isDirty, isValid });
    }

    case 'getNumPortfolios': {
      const { actions } = props;
      const action = () => actions.getNumPortfolios();
      const isDirty = !prevProps;
      return tryFetch(action, { force, isDirty });
    }

    case 'newsSentiments': {
      const { actions, dashboard } = props;
      const ticker = selectPortfolioNewsTicker(dashboard);
      const prevTicker =
        (prevProps && selectPortfolioNewsTicker(prevProps.dashboard)) || null;

      const isDirty = ticker !== prevTicker;

      const isValid = ticker !== '-1';

      const action = () => actions.getNewsSentiments(ticker);

      return tryFetch(action, { force, isDirty, isValid });
    }

    case 'prices': {
      const { actions, dashboard } = props;
      const ticker = selectPortfolioNewsTicker(dashboard);
      const prevTicker =
        prevProps && selectPortfolioNewsTicker(prevProps.dashboard);

      const isDirty = ticker !== prevTicker;

      const isValid = ticker !== '-1';

      const action = () => actions.getPrices(ticker);

      return tryFetch(action, { force, isDirty, isValid });
    }

    case 'stocksWithNewsSentiment': {
      const { actions, dashboard } = props;
      const tickers = selectActiveTickers(dashboard);
      const apiSignatures = ignoreApisSelector(dashboard);

      const actionArgs = [tickers];
      const action = () => actions.getStocksWithNewsSentiment(tickers);

      const isDirty =
        !prevProps ||
        difference(tickers, selectActiveTickers(prevProps.dashboard)).length !==
        0 ||
        (tickers.length === 0 && getState('stocks', props) === suits.INITIAL);

      const isValid = true;
      const hasCacheExpired = !shouldIgnoreApi(apiSignatures, name, actionArgs);

      return tryFetch(action, { force, isDirty, hasCacheExpired, isValid });
    }

    case 'userFollowedStocks': {
      const { actions, dashboard } = props;
      const apiSignatures = ignoreApisSelector(dashboard);

      const action = () => actions.userFollowedStocks();

      const isDirty =
        !prevProps || getState('userFollowedStocks', props) !== suits.SUCCESS;

      const isValid = getState('userFollowedStocks', props) !== suits.LOADING;
      const hasCacheExpired = false;

      return tryFetch(action, { force, isDirty, isValid });
    }
    default: {
      return myPerformanceDependencyHandlers(name)({
        tryFetch,
        props,
        prevProps,
        force,
      });
    }
  }
};

export function getState(name: ComponentStateKeys, { dashboard }: ReduxProps) {
  return selectProccessStates(dashboard).get(name);
}
