import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { List } from 'immutable';

import { defaultMsg } from './msg';

import MarketOverview from '../tr-market-overview-fees';
import TrDashboardMgmtFeesStats from '../tr-dashboard-mgmt-fees-stats';
import { toPostfix } from 'sp/browser/lib/utils';

import { equalWidthAndMargin } from '../../common/lib/utils.ts';

export default class TrDashboardMgmtFeesComparison extends Component {
  static propTypes = {
    changedMarketOverviewMarketVisibility: PropTypes.func,
    changedMraketOverviewDuration: PropTypes.func,
    doAllMgmtFeesHaveShares: PropTypes.bool,
    isFundBoxMoreDetailed: PropTypes.bool,
    mgmtFee: PropTypes.object,
    msg: PropTypes.object
  };

  static defaultProps = {
    changedMarketOverviewMarketVisibility: () => null,
    changedMraketOverviewDuration: () => null,
    doAllMgmtFeesHaveShares: true,
    isFundBoxMoreDetailed: false,
    msg: defaultMsg
  };

  headerH1FundName = ticker => <span className="ticker">{ticker}</span>;

  renderMktCap = mktCap => toPostfix(mktCap);

  renderFundBox = ({
    expenseRatio,
    fundName,
    fundSymbol,
    mktCap,
    sector,
    type
  }) => {
    const { isFundBoxMoreDetailed, msg } = this.props;

    const isSimilar = type === 'similar';

    return (
      <section
        className={classNames('fund-box', { isSimilar })}
        style={equalWidthAndMargin(2, 0, 3)}
      >
        <header>
          <h1>{fundSymbol}</h1>
          {isFundBoxMoreDetailed && <h2>{fundName}</h2>}
        </header>
        <main>
          <dl>
            <dt>{msg[`fees.${isFundBoxMoreDetailed ? 'long' : 'short'}`]}</dt>
            <dd>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={expenseRatio * 100}
              />%
            </dd>
          </dl>
          <dl>
            <dt>{msg.cat}</dt>
            <dd>{sector}</dd>
          </dl>
          <dl>
            <dt>{msg.mktCap}</dt>
            <dd>{this.renderMktCap(mktCap)}</dd>
          </dl>
        </main>
      </section>
    );
  };

  render() {
    const {
      changedMarketOverviewMarketVisibility,
      changedMraketOverviewDuration,
      doAllMgmtFeesHaveShares,
      mgmtFee,
      msg
    } = this.props;

    return (
      <section className="tr-dashboard-mgmt-fees-comparison">
        <header>
          <h1>
            <FormattedMessage
              defaultMessage={msg.headerH1}
              id={msg.headerH1}
              values={{
                fundSymbol: this.headerH1FundName(mgmtFee.get('fundSymbol')),
                similarSymbol: this.headerH1FundName(
                  mgmtFee.get('similarSymbol')
                )
              }}
            />
          </h1>
        </header>
        <main>
          <div className="prices-and-funds-boxes">
            <div className="prices">
              <div className="prices-title">{msg.priceTitle}</div>
              <MarketOverview
                changedMarketOverviewMarketVisibility={
                  changedMarketOverviewMarketVisibility
                }
                changedMraketOverviewDuration={changedMraketOverviewDuration}
                mgmtFee={mgmtFee}
                visibleMarkets={
                  new List([
                    mgmtFee.get('fundSymbol'),
                    mgmtFee.get('similarSymbol')
                  ])
                }
              />
            </div>
            <div className="funds-boxes">
              {this.renderFundBox({
                expenseRatio: mgmtFee.get('expenseRatio'),
                fundName: mgmtFee.get('fundName'),
                fundSymbol: mgmtFee.get('fundSymbol'),
                itemType: mgmtFee.get('type'),
                mktCap: mgmtFee.get('mktCap'),
                sector: mgmtFee.get('sector'),
                type: 'current'
              })}
              {this.renderFundBox({
                expenseRatio: mgmtFee.get('similarExpenseRatio'),
                fundName: mgmtFee.get('simlarFundName'),
                fundSymbol: mgmtFee.get('similarSymbol'),
                itemType: mgmtFee.get('type'),
                mktCap: mgmtFee.get('similarMktCap'),
                sector: mgmtFee.get('similarSector'),
                type: 'similar'
              })}
            </div>
          </div>
          <div className="tr-dashboard-mgmt-fees-stats-wrapper">
            <TrDashboardMgmtFeesStats
              doAllMgmtFeesHaveShares={doAllMgmtFeesHaveShares}
              mgmtFee={mgmtFee}
            />
          </div>
        </main>
      </section>
    );
  }
}
