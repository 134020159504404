import { List } from 'immutable';

import {
  expertType,
  toLastId,
  adaptPortfolioItemAlert
} from '../../common/api-adapter.ts';
import { Person, Stock } from '../../common/records';
import { get } from 'lodash';

const hasReadAllAlerts = alerts =>
  alerts && alerts.lastRead >= alerts.ops.reduce(toLastId, 0);

const adaptExpertAlerts = name => alerts =>
  alerts
    ? List(alerts.ops.map(alert => adaptPortfolioItemAlert(name)(alert)))
    : List();

export const adaptExperts = arr =>
  new List(
    arr.map(p => {
      const ratingsTotal = get(p, 'recommendations.total', 0);
      const ratingsGoodTotal = get(p, 'recommendations.good', 0);
      const successRate = ratingsGoodTotal / ratingsTotal;
      return new Person({
        photoId: p.pictureUrl,
        name: p.name,
        role: p.insiderPosition,
        type: expertType(p.expertType || p.expertTypeID),
        portfolioValue: p.hedgeFundValue,
        followingTotal: p.numUsersSubscribedToExpert,
        ratingsTotal,
        ratingsGoodTotal,
        rank: get(p, 'rank.ranked'),
        id: p.uid,
        isFollowing: p.isFollowing,
        isAlertsOn: p.isFollowing,
        alerts: adaptExpertAlerts(name)(p.alerts), // inconsistent api
        readAllAlerts: hasReadAllAlerts(p.alerts),
        returnAvg: p.averageReturn || 0.5 - Math.random(),
        successRate: isNaN(successRate) ? null : successRate,
        firm: new Stock({
          name: p.insiderCompanyName || p.hedgeFundName || p.firm,
          sector: p.sectorId
        })
      });
    })
  ).filter(x => x.rank);
