import { List } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'react-simple-pie-chart';
import Component from 'react-pure-render/component';

import PortfolioItemSelector from '../../PortfolioItemSelector';

const colors = {
  buy: '#46a5ac',
  hold: '#747474',
  sell: '#90408f'
};

const c3Config = data => {
  if (data.toJS) data = data.toJS();
  const mapped = data.map(([id, datum]) => ({
    color: colors[id],
    value: datum
  }));
  return mapped;
};

export default class BestAnalystConsensusCell extends Component {
  static propTypes = {
    enums: PropTypes.object,
    isRestricted: PropTypes.bool,
    showPromotion: PropTypes.func,
    stock: PropTypes.object
  };

  render() {
    const { enums, stock, isRestricted, showPromotion } = this.props;

    const analystConsensus = stock.getIn(['stock', 'bestAnalystConsensus']);

    const isPieShown =
      analystConsensus.get('decision') && analystConsensus.get('distribution');

    return (
      <div>
        {!isRestricted && (
          <div className="pie-chart">
            {isPieShown && (
              <ul className="legend">
                {analystConsensus.get('distribution').map(([type, count]) => (
                  <li key={type} className={type}>
                    <span className="type">{type}</span>
                    <span className="count">{count}</span>
                  </li>
                ))}
              </ul>
            )}
            {isPieShown ? (
              <PieChart
                className="pie-chart"
                slices={c3Config(
                  stock.getIn(['stock', 'bestAnalystConsensus', 'distribution'])
                )}
              />
            ) : null}
          </div>
        )}

        <PortfolioItemSelector
          isRestricted={isRestricted}
          showPromotion={showPromotion}
          item={stock}
          msg={enums}
          type="bestAnalystConsensus"
        />
      </div>
    );
  }
}
