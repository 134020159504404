import * as React from 'react';
import * as classNames from 'classnames';
import * as styles from './styles.istyl';
import { getTickerUrl } from 'sp/common/lib/utils';
import { BetaBarsGraph } from 'sp/browser/BetaBarsGraph';
import { isNumber } from 'lodash';
import { MediaQuery } from 'sp/common/lib/mediaQuery';

export type VolatilityItem = {
  ticker: string;
  companyName: string;
  betaValue: number;
};

export interface VolatilityBoxProps {
  betaValue: number | null;
  volatilityItems: VolatilityItem[];
  avgPortfolioPercentValue: number | null;
  myPortfolioPercentValue: number | null;
  mediaQuery: MediaQuery;
  forceShowNoData?: boolean;
}

export class VolatilityBox extends React.Component<VolatilityBoxProps> {
  render() {
    const {
      betaValue,
      volatilityItems,
      avgPortfolioPercentValue,
      myPortfolioPercentValue,
      mediaQuery,
      children = <div>No Data Available ¯\_(ツ)_/¯</div>,
      forceShowNoData,
    } = this.props;

    // check if should show nodata message
    if (
      betaValue === null ||
      !isNumber(betaValue) ||
      avgPortfolioPercentValue === null ||
      !isNumber(avgPortfolioPercentValue) ||
      myPortfolioPercentValue === null ||
      !isNumber(myPortfolioPercentValue) ||
      volatilityItems.length === 0 ||
      forceShowNoData
    )
      return <section className={styles.container}>{children}</section>;

    return <section className={classNames(styles.container, styles.hasData)}>
      <main>
        <h1>{betaValue.toFixed(2)}</h1>
        <h2>My Portfolio Risk (Beta)</h2>
        <BetaBarsGraph
          avgPortfolioPercentValue={avgPortfolioPercentValue}
          myPortfolioPercentValue={myPortfolioPercentValue}
        />
      </main>
      <footer>
        <h3>My Most Volatile Stocks by Beta</h3>
        <ol>
          {volatilityItems.map(({ companyName, ticker, betaValue }, index) =>
            <li key={ticker} className={styles.item}>
              <span className={styles.number}>{index + 1}.&nbsp;</span>
              <span className={styles.companyName} title={companyName}>{companyName}</span>&nbsp;
              <span className={styles.ticker}>({ticker})</span>
              <span className={styles.betaValue}>{betaValue.toFixed(2)}</span>
            </li>)}
        </ol>
      </footer>
    </section>;
  }
}
