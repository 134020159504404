import reporter from '../analytics';

const feature = feature => feature;

const events = {
  click: ({ analyticName, props = {} }) => ({
    event: `clicked-locked-${analyticName}`,
    props
  })
};

const report = reporter(events);

export default report;
