import { GetNotificationsResponse } from './types';
import { getReq, postReq } from '../../common/api';
import * as moment from 'moment';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const getNotifications = () => () => ({
  type: GET_NOTIFICATIONS,
  payload: { promise: getReq('api/notifications/list') }
});

export const SET_NOTIFICATIONS_READ = 'SET_NOTIFICATIONS_READ';
export const SET_NOTIFICATIONS_READ_SUCCESS = 'SET_NOTIFICATIONS_READ_SUCCESS';
export const SET_NOTIFICATIONS_READ_ERROR = 'SET_NOTIFICATIONS_READ_ERROR';
export const SET_NOTIFICATIONS_READ_START = 'SET_NOTIFICATIONS_READ_START';
export const setNotificationsRead = (readUpToDate: Date) => () => ({
  type: SET_NOTIFICATIONS_READ,
  payload: {
    promise: postReq<GetNotificationsResponse>(
      'api/notifications/setlastread',
      { date: moment.utc(readUpToDate).toISOString() }
    )
  }
});
