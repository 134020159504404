import { ConsensusSentiment, NumberSentiment } from './VisualConsensus';
import {
  Article,
  MediaBuzzLegend,
  NewsSentimentLegend,
  SentimentEnum
} from './types';
import * as moment from '../../../node_modules/moment/moment.js'; ///TODO!

// TODO for dani - name everything properly and document the damn thign

interface Props1 {
  sectorAverageBullishPercent: number;
  bullishPercent: number;
}
interface Props2 {
  thisWeekArticleCount: number;
  stockWeeklyAvgBuzz: number;
  mediaBuzzMax: number;
}

const outBeforeLastWeek = (date: any) =>
  moment().diff(date.jsDate, 'days') <= 7;

const articlesLength = (articles: Article[]) => {
  return articles.filter(outBeforeLastWeek).length;
};

const positiveArticles = (articles: Article[]) => {
  const lastWeekPositiveArticles = articles
    .filter(outBeforeLastWeek)
    .filter(isPositive);
  return lastWeekPositiveArticles;
};

export const positiveRatio = (articles: Article[]) => {
  const total = articles.filter(d => !isNeutral(d)).filter(outBeforeLastWeek)
    .length;
  if (total === 0) return 0;
  return positiveArticles(articles).length / total;
};

const isPositive = article => article.enumSentiment === SentimentEnum.Bullish;

const isNeutral = article => article.enumSentiment === SentimentEnum.Neutral;

export const calcNewsSentiment = ({
  sectorAverageBullishPercent,
  bullishPercent
}: Props1): {
  consensusSentiment: ConsensusSentiment;
  newsSentimentLegend: NewsSentimentLegend;
} => {
  const avgBullishPercent = sectorAverageBullishPercent;
  const avgBearishPercent = 1 - sectorAverageBullishPercent;
  const bearishPercent = bullishPercent !== null ? 1 - bullishPercent : null;
  const majoritySentiment =
    sectorAverageBullishPercent === 0.5
      ? SentimentEnum.Neutral
      : sectorAverageBullishPercent > 0.5
        ? SentimentEnum.Bullish
        : SentimentEnum.Bearish;
  const actualSentiment =
    bullishPercent === null || bullishPercent === 0.5
      ? SentimentEnum.Neutral
      : bullishPercent > 0.5 ? SentimentEnum.Bullish : SentimentEnum.Bearish;
  const percent =
    actualSentiment === SentimentEnum.Bullish ? bullishPercent : bearishPercent;

  return {
    consensusSentiment: {
      actualSentiment,
      percent,
      majoritySentiment,
      avg: sectorAverageBullishPercent
    },
    newsSentimentLegend: {
      stock: {
        bullishPercent,
        bearishPercent
      },
      sectorAverage: {
        bullishPercent: avgBullishPercent,
        bearishPercent: avgBearishPercent
      }
    }
  };
};

export const calcMediaBuzz = ({
  thisWeekArticleCount,
  stockWeeklyAvgBuzz,
  mediaBuzzMax
}: Props2): [NumberSentiment, MediaBuzzLegend] => {
  const maxBuzz = Math.max(mediaBuzzMax, thisWeekArticleCount);
  const stock = thisWeekArticleCount / maxBuzz;
  const avg = stockWeeklyAvgBuzz / maxBuzz;

  return [
    {
      avg,
      sentiment: SentimentEnum.Bullish,
      min: 0,
      max: maxBuzz,
      value: stock
    },
    {
      thisWeekArticleCount,
      weeklyAverageArticleCount: stockWeeklyAvgBuzz
    }
  ];
};

export function ceilToNearest(to) {
  return n => Math.ceil(n / to) * to;
}
