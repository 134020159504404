import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { defaultMsg } from './msg';

import {
  minExactSaveAmount,
  minSaveAmountToShowAsDollars,
  maximumFractionDigits,
  minimumFractionDigits
} from './conf';

export class TrDashboardMgmtFeesDesc extends React.PureComponent<{
  doAllMgmtFeesHaveShares: boolean;
  saveAmount: number;
  savePercent: number;
  msg?: any;
}> {
  static defaultProps = {
    msg: defaultMsg
  };

  renderCurrency = () => {
    const { msg, saveAmount } = this.props;

    const showLessThan = saveAmount < minExactSaveAmount;
    const displaySaveAmount = Math.max(saveAmount, 10);

    const saveAmountComponent = () => (
      <span className="save-amount">
        <FormattedNumber
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          currency="usd"
          style="currency"
          value={displaySaveAmount}
        />
      </span>
    );

    return (
      <span>
        {showLessThan ? (
          <FormattedMessage
            defaultMessage={msg.descLess}
            id={msg.descLess}
            values={{
              saveAmount: saveAmountComponent()
            }}
          />
        ) : (
          saveAmountComponent()
        )}
      </span>
    );
  };

  renderPercent = () => {
    const { msg, savePercent } = this.props;

    return (
      <span>
        <span className="save-amount">
          <FormattedNumber
            maximumFractionDigits={maximumFractionDigits}
            minimumFractionDigits={minimumFractionDigits}
            value={savePercent * 100}
          />%
        </span>
        &nbsp;
        {msg.savePercentAfter}
      </span>
    );
  };

  render() {
    const { doAllMgmtFeesHaveShares, msg, saveAmount } = this.props;

    return (
      <p className="tr-dashboard-mgmt-fees-desc">
        <FormattedMessage
          defaultMessage={msg.desc}
          id={msg.desc}
          values={{
            saveAmount:
              doAllMgmtFeesHaveShares &&
              minSaveAmountToShowAsDollars <= saveAmount
                ? this.renderCurrency()
                : this.renderPercent()
          }}
        />
      </p>
    );
  }
}
export default TrDashboardMgmtFeesDesc;
