import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import TableRow from './TableRow.react';
 import { isAllowed } from '../../common/auth/selectors';
import TableHeadCell from './TableHeadCell.react';
import TableBodyCell from './TableBodyCell.react';
import { holdingsSectionConfig } from '../../common/config';

export default class Table extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    changeEditable: PropTypes.func,
    deleteItem: PropTypes.func,
    dependencies: PropTypes.object,
    editableId: PropTypes.string,
    enums: PropTypes.object,
    fetchPriceData: PropTypes.func,
    group: PropTypes.object,
    hideTooltip: PropTypes.func,
    holdingsCols: PropTypes.object,
    holdingsSortBy: PropTypes.string,
    hoveredRow: PropTypes.string,
    isCombinedPortfolio: PropTypes.bool,
    isImportedPortfolio: PropTypes.bool,
    index: PropTypes.number,
    insertBefore: PropTypes.func,
    isHoldingsSortDescending: PropTypes.bool,
    maxTable: PropTypes.number,
    mediaQuery: PropTypes.object,
    modes: PropTypes.object,
    msg: PropTypes.object,
    onMouseEnterRow: PropTypes.func,
    onMouseLeaveRow: PropTypes.func,
    openAddStockDialog: PropTypes.func,
    openPortfolioItem: PropTypes.func,
    openPortfolioItemAlerts: PropTypes.func,
    portfolioId: PropTypes.number,
    priceDependencies: PropTypes.any,
    setPurchasePrice: PropTypes.func,
    setShares: PropTypes.func,
    setStockLastRead: PropTypes.func,
    showPromotion: PropTypes.func,
    showTooltip: PropTypes.func,
    sortHoldingsTable: PropTypes.func,
    onClickAlert: PropTypes.func,
    viewerPlan: PropTypes.string
  };

  static defaultProps = {
    hoveredRow: '',
    onMouseEnterRow: () => null,
    onMouseLeaveRow: () => null
  };

  // TIPRANKS-13861
  onTouchMove = e => {
    const focusedInput = document.activeElement;
    if (focusedInput.tagName === 'INPUT') {
      focusedInput.blur();
    }
  };

  render() {
    const {
      activePage,
      changeEditable,
      deleteItem,
      dependencies,
      editableId,
      enums,
      fetchPriceData,
      group,
      hideTooltip,
      holdingsCols,
      holdingsSortBy,
      hoveredRow,
      isImportedPortfolio,
      isCombinedPortfolio,
      index,
      insertBefore,
      isHoldingsSortDescending,
      maxTable,
      mediaQuery,
      modes,
      msg,
      onMouseEnterRow,
      onMouseLeaveRow,
      openAddStockDialog,
      openPortfolioItem,
      openPortfolioItemAlerts,
      portfolioId,
      priceDependencies,
      setPurchasePrice,
      setShares,
      setStockLastRead,
      showPromotion,
      showTooltip,
      sortHoldingsTable,
      onClickAlert,
      viewerPlan
    } = this.props;

    const isDesktop = mediaQuery.get('laptop');

    return (
      <div onTouchMove={this.onTouchMove}>
        <table>
          <thead>
            <tr
              className={classNames({
                descending: isHoldingsSortDescending
              })}
            >
              {holdingsCols.map(col => (
                <TableHeadCell
                  field={col}
                  holdingsCols={holdingsCols}
                  insertBefore={insertBefore}
                  isSortedBy={col === holdingsSortBy}
                  key={col}
                  msg={msg}
                  sortHoldingsTable={sortHoldingsTable}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {group.map((stock, colIndex) => (
              <TableRow
                isHovered={hoveredRow === stock.get('ticker')}
                key={stock.get('ticker')}
                index={colIndex}
                mediaQuery={mediaQuery}
                onMouseEnter={onMouseEnterRow}
                onMouseLeave={onMouseLeaveRow}
                openPortfolioItem={openPortfolioItem}
                openPortfolioItemAlerts={openPortfolioItemAlerts}
                stock={stock}
              >
                {holdingsCols.map(field => {
                  const isRestricted =
                    !isAllowed('premium', viewerPlan) &&
                    holdingsSectionConfig.get('premium').includes(field);
                  return (
                    <td className={classNames(field)} key={field}>
                      <TableBodyCell
                        changeEditable={changeEditable}
                        deleteItem={deleteItem}
                        dependencies={dependencies}
                        editableId={editableId}
                        enums={enums}
                        fetchPriceData={fetchPriceData}
                        field={field}
                        hideTooltip={hideTooltip}
                        index={activePage * maxTable + colIndex + index * 10}
                        isImportedPortfolio={isImportedPortfolio}
                        isCombinedPortfolio={isCombinedPortfolio}
                        isRestricted={isRestricted}
                        mediaQuery={mediaQuery}
                        modes={modes}
                        msg={msg}
                        nextStock={group.get(colIndex + 1)}
                        openPortfolioItem={openPortfolioItem}
                        openPortfolioItemAlerts={openPortfolioItemAlerts}
                        portfolioId={portfolioId}
                        priceDependencies={priceDependencies}
                        setPurchasePrice={setPurchasePrice}
                        setShares={setShares}
                        setStockLastRead={setStockLastRead}
                        showPromotion={showPromotion}
                        showTooltip={showTooltip}
                        stock={stock}
                        onClickAlert={onClickAlert}
                        only={group.size <= 1}
                      />
                    </td>
                  );
                })}
              </TableRow>
            ))}
          </tbody>
          {!isImportedPortfolio ? (
            <tfoot>
              <tr>
                {isDesktop ? (
                  <td className="sign">
                    <div className="td-inner">
                      <button onClick={openAddStockDialog}>+</button>
                    </div>
                  </td>
                ) : null}
                <td
                  className="label"
                  colSpan={isDesktop ? holdingsCols.size : holdingsCols.size}
                >
                  <div className="td-inner">
                    <button onClick={openAddStockDialog}>
                      <span className="before">
                        <span>+</span>
                      </span>
                      {msg.addBtn}
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
    );
  }
}
