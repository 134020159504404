import * as classNames from 'classnames';
import * as React from 'react';
import { AssetBox } from './AssetBox.react.tsx';
import Spacer from './Spacer.react';
import BarSlider from './BarSlider.react';
import Batteries from './Batteries.react';
import { NoData } from './NoData.react';
import { TickerLink } from './TickerLink.react';
import { get } from 'lodash';
import riskLevel, { riskRange } from '../../../../tr-business-rules/risk-level';
import { riskGlossary } from '../../../../decisions/index.ts';
import report from './analytics';

const MostVolatileStocks = NoData(({ stocks }) => {
  return (
    <ol className="risk-items">
      {(stocks || []).map((stock, i) => (
        <li key={i}>
          {stock.name} (<TickerLink holding={stock} />),{' '}
          <span className="beta">
            Beta: <span className="value">{stock.beta.toFixed(2)}</span>
          </span>
        </li>
      ))}
    </ol>
  );
});

const getRiskDesc = num =>
  ({
    low: <span className="risk positive">Low Risk</span>,
    mid: <span className="risk neutral">Mid Risk</span>,
    high: <span className="risk negative">High Risk</span>
  }[riskLevel(num)]);

/*
export interface PortfolioRiskProps {
  beta: number,
  clickedVolatilityGlossary: any,
  volatileStocks: WeightedHolding[],
}
*/
export default class PortfolioRisk extends AssetBox(
  'asset-box-portfolio-risk',
  ['portfolioItems', 'stocks']
) {
  constructor(...props) {
    super(...props);
    this.state = { ...this.state, show: false };
  }

  show = () => {
    report('clickedLearnMore', 'volatility');
    this.setState({ show: true });
  };

  head() {
    const { clickedVolatilityGlossary } = this.props;
    return (
      <span className="section-header-alt-1-h1">
        <h3>My Portfolio Volatility</h3>
        {this.headerTooltip(
          "View your portfolio's beta, or how closely correlated your holdings are to market volatility. You can also explore ways to reduce your portfolio's beta."
        )}
        {this.headerSideLink(
          'Reduce Risk >',
          riskGlossary,
          clickedVolatilityGlossary
        )}
      </span>
    );
  }
  body() {
    let { beta } = this.props;
    let hasBeta = true;

    if (beta) {
      beta = beta.toFixed(2);
    } else {
      beta = 'N/A';
      hasBeta = false;
    }

    const { show } = this.state;

    return (
      <div>
        <div className="beta-value">{beta}</div>
        <div className="portfolio-risk">My Portfolio Risk (Beta)</div>
        {/*<div className="tipranks-average-risk">TipRanks Average Portfolio Risk: <span className="data-point">1.22</span> {getRiskDesc(1.22)}</div>*/}
        <Spacer />
        <Batteries
          labels={{ before: 'Low Risk', after: 'High Risk' }}
          range={riskRange}
          avg={1.22}
          value={hasBeta && beta}
        />
        <Spacer />
        {show ? (
          <div className={classNames(`most-volatile-stocks`)}>
            <h4>My Most Volatile Stocks</h4>
            <MostVolatileStocks
              noDataMsg="No volatile stocks"
              hasData={
                get(this.props, 'volatileStocks.length') ||
                get(this.props, 'volatileStocks.size')
              }
              stocks={this.props.volatileStocks}
            />
          </div>
        ) : (
            <div className="learn-more">
              <button onClick={this.show}>See Volatile Stocks</button>
            </div>
          )}
      </div>
    );
  }
}
