import * as React from 'react';
import * as styles from './styles.istyl';
import { getGlobalActions } from 'sp/browser/lib/actions';
import { popupRegister } from 'sp/common/lib/utils';
import { history } from 'sp/browser/history';

export class PublicPortfolioToRegister extends React.PureComponent {
  render() {
    return <div className={styles.publicPortfolioToRegister}>
      <h1>Open Your Smart Portfolio Today!</h1>
      <h2>Sign up for <span>FREE</span> and upgrade your investing practices to a whole new level</h2>
      <div className={styles.listAndPicture}>
        <ul>
          <li>
            <h3>TipRanks Stock and Expert Analysis Tools</h3>
            <span>Get detailed stock analysis, find best performing analysts
  and experts to follow.</span>
          </li>
          <li>
            <h3>Smart Portfolio - Portfolio Analysis Tools</h3>
            <span>Have your portfolio analyzed, get bullish/bearish news
  and alerts for your investments.</span>
          </li>
          <li>
            <h3>Investing Community</h3>
            <span>
              Invest within a vibrant community, become a community leader,
  benefit from crowd wisdom.
            </span>
          </li>
        </ul>
        <div className={styles.img} />
      </div>
      <button className={styles.startNowButton} onClick={() => popupRegister()}>Start Now</button>
    </div>
  }
}
