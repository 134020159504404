import { get } from 'lodash';
import { fromJS } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { ApiDecorator } from '../ApiDecorator';
import DashboardOverview from '../tr-dashboard-overview';
import { TrDashboardPortfolioNewsContainer } from '../tr-dashboard-portfolio-news-container/index.react';

import { dashboardPath, portfolioNewsConfig } from '../../common/config';

import PortfolioEventsContainer from '../tr-dashboard-holdings-container/PortfolioEventsContainer.react';

import report from './analytics';

import {
  selectActivePortfolio,
  selectAnnualGainOnlySinceRelevant,
  selectIsMarketOpened,
  selectMraketOverviewDuration,
  selectPortfolioDailyGainers,
  selectPortfolioDailyLosers,
  selectPortfolioOverviewStats,
  selectPortfolios,
  selectVisibleMarketOverviewMarkets
} from '../dashboard/selectors';

const portfolioName = (portfolio, msg) =>
  portfolio.get('name') === 'combinedUserPortfolio'
    ? msg.combinedUserPortfolio
    : portfolio.get('name');

@ApiDecorator(
  'portfolios',
  'portfolioItems',
  'stocks',
  'details',
  'portfolioMonthlyGain',
  'portfolioGains'
)
export default class DashboardOverviewContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    dashboard: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    mediaQuery: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    report('visited');
    this.redirectIfNoHoldingsAndNotDirectlyAccessed();
  }

  changedMarketOverviewMarketVisibility = ({ markets } = {}) => {
    const { actions } = this.props;
    actions.changedMarketOverviewMarketVisibility(fromJS(markets));
    report('changedMarketOverviewMarketVisibility');
  };

  changedMraketOverviewDuration = ({ duration } = {}) => {
    const { actions } = this.props;
    actions.changedMraketOverviewDuration(duration);
    report('changedMraketOverviewDuration');
  };

  redirectIfNoHoldingsAndNotDirectlyAccessed() {
    // const {
    //   history,
    //   location
    // } = this.props;
    //
    // const directLink = get(location, 'state.directLink');
    // const portfolios = selectPortfolios(this.props.dashboard);
    // const noHoldings =
    //   portfolios.size === 0 ||
    //   (
    //     portfolios.size === 1 &&
    //     portfolios.first().get('items').size === 0
    //   );
    //
    // if (!directLink && noHoldings) {
    //   history.replace(`/${dashboardPath}/holdings`);
    // }
  }

  renderOptionalSections = () => {
    const props = {
      ...this.props,
      isRoute: false
    };

    const sections = [];
    const overviewPortfolioNewsTab = this.props.dashboard.get(
      'overviewPortfolioNewsTab'
    );

    sections.push(
      <TrDashboardPortfolioNewsContainer
        key="news"
        max={portfolioNewsConfig.get('maxItems')}
        tabId={overviewPortfolioNewsTab}
        {...props}
      />
    );
    sections.push(<PortfolioEventsContainer key="events" {...props} />);

    return sections;
  };

  render() {
    const { dashboard, mediaQuery, msg } = this.props;

    const portfolio = selectActivePortfolio(dashboard);
    const maxDailyGainersAndLosers = mediaQuery.get('mobileL') ? 4 : 4;

    const visibleMarketOverviewMarkets = selectVisibleMarketOverviewMarkets(
      dashboard
    );

    return (
      <div className="tr-dashboard-overview-container">
        <div style={{ fontSize: 12, marginBottom: 20}}>
        Nasdaq Smart Portfolio is an online service used to track the value of a portfolio of securities.  Nasdaq does not accept deposits and does not invest funds on behalf of users of Nasdaq Smart Portfolio.  If you are contacted by any person who claims to work for Nasdaq and solicits funds from you, you should terminate contact with that person and contact law enforcement.
        </div>
        <DashboardOverview
          allCombinedPortfolio={selectPortfolioOverviewStats(dashboard)}
          changedMarketOverviewMarketVisibility={
            this.changedMarketOverviewMarketVisibility
          }
          changedMraketOverviewDuration={this.changedMraketOverviewDuration}
          dailyGainers={selectPortfolioDailyGainers(dashboard)}
          dailyLosers={selectPortfolioDailyLosers(dashboard)}
          gains={selectAnnualGainOnlySinceRelevant(dashboard)}
          isMarketOpened={selectIsMarketOpened(dashboard)}
          marketOverviewDuration={selectMraketOverviewDuration(dashboard)}
          maxDailyGainersAndLosers={maxDailyGainersAndLosers}
          msg={msg.dashboardOverviewMsg}
          portfolioName={portfolioName(portfolio, msg.portfolioSelect)}
          portfolioOverviewStatsMsg={msg.dashboard.overviewSection.stats}
          shouldShowPortfolioSizeTimeLine={mediaQuery.get('tablet')}
          shouldShownMarketOverview={mediaQuery.get('tablet')}
          shouldTransposeTable={mediaQuery.get('tablet')}
          visibleMarketOverviewMarkets={visibleMarketOverviewMarkets}
        />
        <div className="optional-sections">{this.renderOptionalSections()}</div>
      </div>
    );
  }
}
