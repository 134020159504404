export const personsListMsg = {
  topExpertsLimit: 25,

  plans: {
    open: '',
    free: 'Free',
    premium: 'Premium',
    ultimate: 'Ultimate'
  },

  registerTitles: {
    restriction: `#Invest with Confidence#
            Please create your free account`,
    'subscription.auth': `Sign up here to follow experts, insiders,\nhedge funds and stocks`
  },

  upgradeTitles: {
    default: '*Upgrade your account today*',
    'filtersGroup.sector': `*Upgrade your account today*
        to filter by sector`,
    'filtersGroup.ranking': `*Upgrade your account today*
        to filter by ranking parameters`
  },

  personTypes: {
    analyst: 'Analyst',
    blogger: 'Blogger',
    insider: 'Corporate Insider',
    hedgefund: 'Hedge Fund Manager',
    investor: 'Investor'
  },

  personTypesPlural: {
    analyst: 'Analysts',
    blogger: 'Bloggers',
    insider: 'Corporate Insiders',
    hedgefund: 'Hedge Fund Managers',
    investor: 'Investors'
  },

  personTypesTitle: {
    analyst: 'Sell-Side Analysts',
    blogger: 'Financial Bloggers',
    insider: 'Corporate Insiders',
    hedgefund: 'Hedge Fund Managers',
    investor: 'Investors'
  },

  actions: {
    analyst: {
      buy: 'Buy',
      hold: 'Hold',
      sell: 'Sell'
    },
    blogger: {
      buy: 'Buy',
      hold: 'Hold',
      sell: 'Sell'
    },
    insider: {
      buy: 'Stocks bought',
      sell: 'Stocks sold'
    },
    hedgefund: {
      buy: 'Stocks bought',
      sell: 'Stocks sold'
    }
  },


  periods: {
    '2years': '2 Years',
    yearly: '1 Year',
    quarterly: '3 Months',
    monthly: '1 Month'
  },

  benchmarks: {
    snp500: 'S&P 500',
    none: 'No Benchmark',
    sector: 'Sector'
  },

  filters: {
    back: 'Top {topExpertsLimit} Experts Center',
    btn: `Filtering\nOptions`,
    title: 'Filtering Options:',
    titleInfo: 'Learn More:',
    filterDescription: {
      analyst: `Showing top analysts from {sector} with ratings measured over {period} and {benchmark} {benchmarkType, select,
                none {}
                other {as a benchmark}}`,
      blogger: `Showing top bloggers with ratings measured over {period} and {benchmark} {benchmarkType, select,
                none {}
                other {as a benchmark}}`,
      insider: `Showing top corporate insiders with transactions measured over {period} and {benchmark} {benchmarkType, select,
                none {}
                other {as a benchmark}}`,
      hedgefund: `Showing top performing hedge fund managers with transactions measured over the past 3 years`
    },
    groups: {
      sector: 'By Sector',
      ranking: 'By Ranking Parameters',
      hedgeFunds: 'Hedge Funds',
      tipRanksAndHedgeFunds: 'TipRanks & Hedge Funds'
    },
    fieldsets: {
      sector: {
        label: ''
      },
      period: {
        label: 'Adjust how ratings are measured over a time period of:',
        tooltip: {
          analyst:
            'TipRanks measures the stock performance over a one-year time period, starting from the recommendation’s date. You can see the best performing experts in longer, or shorter, time periods by setting this parameter.',
          blogger:
            'TipRanks measures the stock performance over a one-year time period, starting from the recommendation’s date. You can see the best performing experts in longer, or shorter, time periods by setting this parameter.',
          insider:
            'TipRanks measures the stock performance over a one-year time period, starting from the transaction’s date. You can see the best performing insiders in longer, or shorter, time periods by setting this parameter.'
        }
      },
      benchmark: {
        label: 'Compare return to:',
        tooltip: {
          analyst:
            'TipRanks allows you to compare each recommendation to the performance of the S&P 500, or to the sector of the company, to determine if the Expert outperforms the market or not.',
          blogger:
            'TipRanks allows you to compare each recommendation to the performance of the S&P 500, or to the sector of the company, to determine if the Expert outperforms the market or not.',
          insider:
            'TipRanks allows you to compare each transaction to the performance of the S&P 500, or to the sector of the company, to determine if the insider outperforms the market or not.'
        }
      },
      about: {
        label: 'About',
        info:
          'Hedge funds are a type of investment vehicle that pools funds and employs several strategies to earn an active return for its investors. Hedge funds may be aggressively managed or use derivatives as leverage in both the domestic and international markets with the goal of generating high returns. Because hedge funds often have low correlations to traditional portfolios of stocks and bonds, allocating an exposure to hedge funds can add diversity to your portfolio.'
      },
      performanceAndRanking: {
        label: 'Performance & Ranking',
        info:
          'TipRanks measures the performance of hedge funds based on information submitted to the SEC. Any hedge fund managing over $100M is required to submit a 13F form to the SEC which lists their current holdings at the end of each fiscal quarter. TipRanks uses this information to determine how each hedge fund performed in comparison to other hedge funds and to the S&P 500. Top hedge funds are determined by those that generate the highest return based on their stock portfolio.'
      }
    }
  },

  expertsList: {
    seoTitle: {
      analyst: 'Top {topExpertsLimit} Analysts | TipRanks',
      blogger: 'Top {topExpertsLimit} Bloggers | TipRanks',
      insider: 'Top {topExpertsLimit} Corporate Insiders | TipRanks',
      hedgefund: 'Top {topExpertsLimit} Hedge Fund Managers | TipRanks'
    },
    seoDescription: {
      analyst:
        "Want to see the top-ranked financial analysts out there on the web? View TipRanks' Top {topExpertsLimit} Analysts.",
      blogger:
        "Want to see the top-ranked financial bloggers out there on the web? View TipRanks' Top {topExpertsLimit} Bloggers.",
      insider:
        'See the Corporate Insiders whose buy / sell transactions have consistently outperformed the markets.',
      hedgefund:
        'See the {topExpertsLimit} hedge fund managers that generated the best returns on their assets under management.'
    },
    title: 'Top {expertsLimit} {expertType}',
    expertType: {
      analyst: 'Wall Street Analysts',
      blogger: 'Bloggers',
      insider: 'Corporate Insiders',
      hedgefund: 'Hedge Fund Managers',
      investor: 'Investor'
    },
    desc: {
      analyst:
        'Find the best performing Analysts in each sector. Follow them so you never miss a recommendation.',
      blogger:
        'See who the best performing financial Bloggers are in the market. Follow them to never miss their stock picks.',
      insider:
        'See the Corporate Insiders with the highest returns on their transactions. Follow their activity to maximize your returns.',
      hedgefund:
        'See the Hedge Fund managers with the best portfolio returns. View their quarterly activity to maximize your returns.'
    },
    banners: {
      button: 'Visit Now',
      analyst: [
        {
          title: 'Hot Analyst Stocks',
          desc: 'Find the best stocks by top analysts',
          icon: 'upChart'
        },
        {
          title: 'Daily Recommendations',
          desc: 'See what analysts are recommending today',
          icon: 'dailyRecBubbles'
        }
      ],
      insider: [
        {
          title: 'Hot INsider Stocks',
          desc: 'Find the best stocks by top analysts',
          icon: 'chartMagGlass'
        },
        {
          title: '<span>Daily</span>Insider',
          desc: 'The DailyInsider Newsletter returned 46% - Try it out!',
          icon: 'dailyInsiderLogo'
        }
      ]
    }
  },

  topExperts: {
    seoTitle: 'Top Financial Experts | TipRanks',
    seoDescription:
      'Top {topExpertsLimit} lists of the best performing sell-side analysts, hedge funds, corporate insiders and financial bloggers according to TipRanks financial accountability engine.',
    title: 'Top {expertsLimit} Center',
    desc1: 'TipRanks measures and ranks experts based on their performance.',
    desc2: 'Now you can follow the best of them with one click!',
    analystsTitle: 'Sell-Side Analysts',
    tooltip: {
      analyst:
        'Sell-side analysts work for a brokerage firm and evaluate companies based on various investment criteria typically recommending to Buy, Sell, or Hold a stock. View and follow the analysts whose ratings consistently outperform the market within each sector. ',
      blogger:
        'Financial bloggers provide investing advice independent of any firm or corporation and are often featured on prominent blogs such as Seeking Alpha and Motley Fool. View and follow the bloggers who provide the most accurate stock predictions and opinions. ',
      insider:
        'Corporate Insiders are often C-level executives or board members of their companies and are required to report any transaction they make in the company’s stock to the SEC. View and follow the top corporate insiders whose publicly disclosed transactions of shares of their own companies have outperformed the market. ',
      hedgefund:
        'Hedge fund managers control and supervise funds from multiple investors. View which hedge funds have consistently outperformed the market based on the fund’s transactions as reported on a quarterly basis. '
    },
    analystsBtn: 'See Top {expertsLimit} Analysts',
    bloggersTitle: 'Funancial Bloggers',
    bloggersBtn: 'See Top {expertsLimit} Bloggers',
    insidersTitle: 'Corporate Insiders',
    insidersBtn: 'See Top {expertsLimit} Insiders',
    hedgefundsTitle: 'Hedge Fund Managers',
    hedgefundsBtn: 'See Top {expertsLimit} Hedge funds',
    btns: {
      analyst: 'See Top {expertsLimit} Analysts',
      blogger: 'See Top {expertsLimit} Bloggers',
      insider: 'See Top {expertsLimit} Insiders',
      hedgefund: 'See Top {expertsLimit} Hedge Funds'
    },
    howTitle: 'How We Rank Financial Experts',
    howDesc:
      'By tracking and measuring the performance of over 50,000 financial experts, TipRanks is able to identify the top 25 experts within each sector who consistently outperform the markets. Now that we’ve done the work for you and crunched all the data, all you need to do is browse the top experts and follow your favorites to be instantly notified whenever they recommend to buy or sell your stocks.',
    starsLabel: ['TipRanks ', 'Star Ranking', 'TM'],
    criteriaTitle:
      'TipRanks Star Ranking is calculated based on the combinations of the following factors:',
    criteriaRateTitle: 'Expert Success Rate',
    criteriaRateDesc:
      'The percentage of an analyst’s ratings resulting in a positive return',
    criteriaReturnTitle: 'Expert Average Return',
    criteriaReturnDesc: 'The average return per recommendation',
    criteriaStatsTitle: 'Statistical Significance',
    criteriaStatsDesc:
      'The more data we collected on an expert, the more statistically significant his measurement is.'
  },

  expertCenter: {
    title: 'Top Expert Center',
    text:
      'Get insights from the best performing analysts, follow them and get an email notification whenever they post a recommendation',
    button: 'Visit Expert Center'
  },

  personCard: {
    performanceTitle: {
      analyst: 'Analyst Performance',
      blogger: 'Blogger Performance',
      insider: 'Insider Performance',
      hedgefund: 'Hedge Fund Performance'
    },
    deleted: {
      text: 'You are unsubscribed from',
      button: 'Undo'
    },
    rankSector: 'Ranked #{rankSector} in {sector} sector',
    ranktype: 'Ranked #{rankType} out of {totalType} {type}',
    statSuccessRateTitle: {
      analyst: 'Success Rate',
      blogger: 'Success Rate',
      insider: 'Profitable Transaction Rate',
      hedgefund: 'Portfolio Value'
    },
    statSuccessRateDesc: {
      analyst: '{good} out of {total}\nsuccessful ratings',
      blogger: '{good} out of {total}\nsuccessful ratings',
      insider: '{good} out of {total}\nprofitable transactions',
      hedgefund: 'Total assets\nunder management'
    },
    statAvgReturnTitle: 'Average Return',
    statAvgReturnDesc: {
      analyst: 'Average return\nper recommendation',
      blogger: 'Average return\nper recommendation',
      insider: 'Average return\nper transaction',
      hedgefund: '3 Years\n(annualized)'
    },
    ratingDistributionLabel: {
      analyst: 'Rating distribution:',
      blogger: 'Opinion distribution:',
      insider: 'Transaction type distribution:',
      hedgefund: 'Transaction type distribution:'
    },
    followingTotal: 'Followed by {total} Investors',
    tooltip1: 'To Reveal Top Analyst',
    tooltip2: 'Upgrade Your Account Now!',
    note: 'Premium',
    linkToProfile: 'See Full Profile >'
  }
};

export default personsListMsg;
