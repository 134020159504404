import { List } from 'immutable';
import { createSelector } from 'reselect';

import { holdingsSectionConfig, sortWeight } from '../../common/config';

import {
  selectHoldingsPage,
  selectIsHoldingsSortDescending,
  selectPortfolioItems,
  selectoHoldingsSortBy
} from '../dashboard/selectors';

import {
  descendtify,
  sortAlphabeticallyIn,
  sortByDateIn,
  sortByListIndexIn,
  sortNumericallyIn
} from '../../common/lib/sort';

import { getSortLandmarkPercentageFn } from './utils.ts';

const getStrongBuyShare = item => {
  const distribution = item.getIn([
    'stock',
    'bestAnalystConsensus',
    'distribution'
  ]);
  const total = distribution.reduce((total, share) => total + share[1], 0);
  const strongBuy = distribution.find(share => share[0] === 'buy');
  return strongBuy && total > 0 ? strongBuy[1] / total : 0;
};

const getDecision = item =>
  item.getIn(['stock', 'bestAnalystConsensus', 'decision']);

const strategies = {
  name: sortAlphabeticallyIn(['stock', 'ticker']),
  price: sortNumericallyIn(['stock', 'price', 'amount']),
  target: sortNumericallyIn(['stock', 'targetPercent']),
  bestTarget: sortNumericallyIn(['stock', 'bestTargetPercent']),
  analystConsensus: sortByListIndexIn(
    ['stock', 'analystConsensus', 'decision'],
    sortWeight.get('consensus')
  ),
  bestAnalystConsensus: (a, b) => {
    const decisionWeightA = sortWeight.get('consensus').indexOf(getDecision(a));
    const decisionWeightB = sortWeight.get('consensus').indexOf(getDecision(b));
    if (decisionWeightA < decisionWeightB) return -1;
    if (decisionWeightB < decisionWeightA) return 1;

    const strongBuyShareA = getStrongBuyShare(a);
    const strongBuyShareB = getStrongBuyShare(b);
    if (strongBuyShareA < strongBuyShareB) return -1;
    if (strongBuyShareB < strongBuyShareA) return 1;
    return 0;
  },
  insiderSentiment: sortByListIndexIn(
    ['stock', 'insiderSentiment'],
    sortWeight.get('sentiment')
  ),
  type: sortByListIndexIn(['stock', 'type'], sortWeight.get('type')),
  highLow: sortNumericallyIn(['stock', 'price', 'ftWeekLow']),
  added: sortByDateIn(['addedDate']),
  shares: sortNumericallyIn(['sharesTotal']),
  value: sortNumericallyIn(['sharesValue']),
  percent: sortNumericallyIn(['percentPortfolio']),
  dividend: sortNumericallyIn(['stock', 'dividend']),
  yield: sortNumericallyIn(['stock', 'yield']),
  dividendDate: sortNumericallyIn(['stock', 'dividendDate']),
  earningsReport: sortNumericallyIn(['stock', 'earningsReport']),
  eps: sortNumericallyIn(['stock', 'eps']),
  sector: sortAlphabeticallyIn(['stock', 'sector']),
  cap: sortNumericallyIn(['stock', 'price', 'cap']),
  beta: sortNumericallyIn(['stock', 'price', 'beta']),
  newsSentimentScore: (foo, bar) => {
    const fooValue = foo.get('stock').get('newsSentimentScore');
    const barValue = bar.get('stock').get('newsSentimentScore');
    if (fooValue === null && barValue === null) return 0;
    if (fooValue === null) return 1;
    if (barValue === null) return -1;
    return barValue - fooValue;
  },
  threeMonthsReturn: getSortLandmarkPercentageFn('threeMonthsReturn'),
  YTDReturn: getSortLandmarkPercentageFn('YTDReturn'),
  '1yrReturn': getSortLandmarkPercentageFn('1yrReturn'),
  hedgefundSentiment: sortByListIndexIn(
    ['stock', 'hedgefundSentiment'],
    sortWeight.get('sentiment')
  ),
  purchasePrice: sortNumericallyIn(['purchasePrice']),
  addedPrice: sortNumericallyIn(['addedPrice']),
  bloggerSentiment: sortByListIndexIn(
    ['stock', 'bloggerSentiment'],
    sortWeight.get('sentiment')
  ),
  pe: sortNumericallyIn(['stock', 'pe']),
  gainSinceAdded: sortNumericallyIn(['gainSinceAdded']),
  sincePurchaseGain: sortNumericallyIn(['sincePurchaseGain']),
  alerts: (a, b) => {
    const readAllA = a.get('readAllAlerts');
    const readAllB = b.get('readAllAlerts');
    if (readAllA === readAllB) {
      return a.get('alerts').size - b.get('alerts').size;
    }
    if (a.get('readAllAlerts')) return -1;
    if (b.get('readAllAlerts')) return 1;
    return 0;
  },
  emailAlerts: sortNumericallyIn(['isAlertsOn'])
};

const defaultStrategy = () => 0;

export const sortBy = (field, isDescending) => {
  const strategy = strategies[field];
  return !strategy ? defaultStrategy : descendtify(strategy, isDescending);
};

export const itemsSelector = createSelector(
  selectPortfolioItems,
  selectoHoldingsSortBy,
  selectIsHoldingsSortDescending,
  (items, holdingsSortBy, isholdingsSortDescending) =>
    items.sort(sortBy(holdingsSortBy, isholdingsSortDescending))
);

export const selectGroupedItems = createSelector(itemsSelector, items =>
  items.groupBy((item, index) =>
    Math.floor(index / holdingsSectionConfig.get('maxTable'))
  )
);

export const selectPageItems = createSelector(
  selectGroupedItems,
  selectHoldingsPage,
  (groupedItems, page) =>
    groupedItems.get(groupedItems.size - 1 < page ? 0 : page, new List())
);

export const selectPages = createSelector(selectGroupedItems, groupedItems =>
  groupedItems.map((_, index) => index)
);
