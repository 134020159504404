export { PlanEnum as Plan } from '../../common/types';
export interface Keyword {
  phrase: string;
  mentions: number;
  // importance is computed from mentions
}

export enum Sentiment {
  Bearish,
  Bullish,
  Neutral
}

export interface Article {
  title: string;
  url: string;
  date: String;
  jsDate: Date;
  siteName: string;
  // icon is computed from siteName
  enumSentiment: Sentiment;
  sentiment: String;
}

export interface Price {
  price: Number;
  date: String;
}
// TODO join with all other sector creators / translators
export enum SectorEnum {
  Financial = 0,
  Healthcare = 1,
  Conglomerates = 2,
  ConsumerGoods = 3,
  Services = 4,
  Utilities = 5,
  Materials = 6,
  Technology = 7,
  IndustrialGoods = 8,
  General = 9,
  Unknown = 10,
  AllSectors = -1
}

export enum NewsTab {
  Bullish,
  Bearish,
  AllNews
}

export type TopStock = {
  ticker: string;
  companyName: string;
  sentiment: any; // ConsensusSentiment;
};

export interface NewsItem {
  type: NewsTab;
  date: Date;
  siteName: string;
  title: string;
  url: string;
}

export interface NewsSentimentLegend {
  stock: { bullishPercent: number | null; bearishPercent: number | null };
  sectorAverage: { bullishPercent: number; bearishPercent: number };
}

export interface MediaBuzzLegend {
  thisWeekArticleCount: number;
  weeklyAverageArticleCount: number;
}

export interface APIBuzz {
  articlesInLastWeek: number;
  weeklyAverage: number;
}

export interface APICounts {
  buy: number;
  sell: number;
  all: number;
  weekStart: string;
  neutral: number;
}

export interface APITopStock {
  ticker: string;
  companyName: string;
  bullishPercent: number;
  bearishPercent: number;
}

export interface APISentiment {
  bullishPercent: number;
  bearishPercent: number;
}

export interface APIKeyword {
  ticker: string;
  text: string;
  effectiveDate: string;
  grade: number;
  wordCloudEventID: number;
  addedOn: string;
}

export interface NewsSentimentAPIResponse {
  sectorId: number;
  ticker: string;
  companyName: string;
  buzz: APIBuzz;
  articlesInLastWeek: number;
  sentiment: APISentiment;
  sector: APITopStock[];
  sectorAverageBullishPercent: number;
  score: number;
  wordCloud: APIKeyword[];
  counts: APICounts[];
}

export type NewsSentimentStoreProps = NewsSentimentAPIResponseAdapted & {
  mediaCoverageAnalysisPrices: Price[];
};

export interface NewsSentimentAPIResponseAdapted {
  sectorId: number;
  companyName: string;
  bullishPercent: number;
  stockWeeklyAvgBuzz: number;
  mediaBuzzMax: number;
  keywords: Keyword[];
  newsSentimentsTopStocks: TopStock[];
  stockNewsScore: number;
  avgNewsScore: number;
  sectorAverageBullishPercent: number;
  counts: APICounts[];
  sector: SectorEnum; // TODO add type
  articlesInLastWeek: number;
}

export enum SentimentEnum {
  Bullish,
  Bearish,
  Neutral
}

export interface TopStocksProps {
  stocks: any[];
}
export interface Stock {
  ticker: string;
  companyName: string;
}

// TODO
export type PossibleRestriction = string;

export enum Consensus {
  StrongBuy,
  Buy,
  Hold,
  Sell,
  StrongSell
}
