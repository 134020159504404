exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-news-analysis-WordCloud__wordCloud .src-browser-news-analysis-WordCloud__list{height:220px}.src-browser-news-analysis-WordCloud__wordCloud .src-browser-news-analysis-WordCloud__list .src-browser-news-analysis-WordCloud__noData{color:#b5b5b5;line-height:220px;text-align:center}.src-browser-news-analysis-WordCloud__wordCloudContent{font-family:Proxima Nova Alt Bold;font-weight:700;list-style-type:none;color:#3696ab}.src-browser-news-analysis-WordCloud__wordCloudContent li{clear:both}.src-browser-news-analysis-WordCloud__wordCloudContent span{text-align:center}", ""]);

// Exports
exports.locals = {
	"boxHeight": "220px",
	"tabletViewPort": "(min-width:720px)",
	"wordCloud": "src-browser-news-analysis-WordCloud__wordCloud",
	"list": "src-browser-news-analysis-WordCloud__list",
	"noData": "src-browser-news-analysis-WordCloud__noData",
	"wordCloudContent": "src-browser-news-analysis-WordCloud__wordCloudContent"
};