import { suits } from '../../../common/enums';

if (process.env.IS_BROWSER) require('./Suit.styl');

export { default as Suit } from './Suit.react';
export { default as Loader } from './Loader.react.tsx';
export { default as ErrorMsg } from './ErrorMsg.react';

const is = state => s => s === state;

export const suitState = state => {
  const states = Array.isArray(state) ? state : [state];

  if (states.some(is(suits.LOADING))) {
    return suits.LOADING;
  }

  if (states.some(is(suits.ERROR))) {
    return suits.ERROR;
  }

  return suits.SUCCESS;
};
