import * as React from 'react';
import * as classNames from 'classnames';

import Clickable from '../Clickable/clickable.react.js';
import report from '../tr-stock-screener-container/analytics';

import './index.styl';

interface LinkConf {
  text?: string; // Optional if children are passed.
  icon: string;
  href?: string; // One of
  to?: string; // these
  type?: 'href' | 'to';
}
export default class RelatedLinks extends React.PureComponent<
  { links: Array<LinkConf>; className?: string },
  {}
> {
  public render() {
    const { links, className } = this.props;
    return (
      <div className={classNames('related-links', className)}>
        <h3>Related Links</h3>
        <ul>
          {links.map(linkConf => (
            <li key={linkConf.text}>
              <IconedLink {...linkConf}>{linkConf.text} ></IconedLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

type IconedLinkProps = LinkConf & any; //React.HTMLAttributes<HTMLAnchorElement>;

class IconedLink extends React.PureComponent<IconedLinkProps> {
  onClick = e => {
    report('clickRelatedLink');
  };

  public render() {
    const { href, to, type, icon, text, children, ...restProps } = this.props;
    return (
      <Clickable {...restProps} {...{ href, to, type }} onClick={this.onClick}>
        <img src={icon} />
        <span>{children || text}</span>
      </Clickable>
    );
  }
}
