import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { defaultMsg } from './msg';

const reqImg = img => (process.env.IS_BROWSER ? require(`./img/${img}`) : '');

export const profileSrc = profileSrcName =>
  profileSrcName &&
  `//az712682.vo.msecnd.net/expert-pictures/${profileSrcName}`;

export default class TrProfilePic extends Component {
  // static propTypes = {
  //   alt: PropTypes.string,
  //   isDropzone: PropTypes.bool,
  //   //msg: PropTypes.shape(msgShape),
  //   profileSrcName: PropTypes.string
  // };

  static defaultProps = {
    alt: '',
    isDropzone: false,
    msg: defaultMsg,
  };

  src = () => {
    const { profileSrcName } = this.props;
    return profileSrc(profileSrcName) || reqImg('default.png');
  };

  render() {
    const { alt, isDropzone, msg, profileSrcName } = this.props;

    return (
      <div className={classNames('tr-profile-pic', { isDropzone })}>
        <img alt={alt} src={this.src()} />
        {isDropzone &&
          !profileSrcName && (
            <div className="dropzone-label">
              <span>{msg.dropzoneLabel}</span>
            </div>
          )}
      </div>
    );
  }
}
