import { get } from 'lodash';

const unavailable = '-';

export const zeroIfNaN = x => (isNaN(x) ? 0 : Number(x));
export const isUnavailable = x => (x !== 0 && !x) || x === unavailable;

export function currency(amount, precision = 2) {
  return isUnavailable(amount)
    ? null
    : `$${zeroIfNaN(amount).toFixed(precision)}`;
}
export function number(num, precision = 0) {
  return isUnavailable(num) ? null : zeroIfNaN(num).toFixed(precision);
}
export function percent(perc, precision = 2) {
  return isUnavailable(perc)
    ? null
    : zeroIfNaN(perc * 100).toFixed(precision) + '%';
}

export function percentRounded(perc, precision = 2) {
  perc = perc.toFixed(10);

  const lower = Math.pow(10, -(2 + precision));
  const upper =
    Number(
      Array(2 + precision)
        .fill(9)
        .join('')
    ) * lower;

  if (perc > upper && perc < 1) {
    return `>${upper * 100}%`;
  }

  if (perc < lower && perc > 0) {
    return `<${lower * 100}%`;
  }

  return percent(perc, precision);
}

export function capitalize(str) {
  return str ? str[0].toUpperCase() + str.slice(1) : '';
}

export function camelToReadable(str) {
  return str ? capitalize(str.replace(/[A-Z]/g, x => ` ${x}`).trim()) : '';
}

// export const html = (...args) => ({ __html: format(...args) });

export let __hotReload = true;
