import { getReq } from '../../../common/api';

const apigetMonthlyPricesForTickerWithDaysBack = (tickers, days) =>
  getReq(
    `/api/portfolio/fundamentals/?tickers=${tickers.join(',')}&daysBack=${days}`
  );

// const fetchDays = (days, tickers) => {
//   return fetch(daysBack(days, tickers), {
//     credentials: 'same-origin',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     method: 'get'
//   }).then(res => res.json());
// }

export const GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK =
  'GET_MONTHLY_GAINS_FOR_TICERKERS_WITH_DAYS_BACK';
export const GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_SUCCESS =
  'GET_MONTHLY_GAINS_FOR_TICERKERS_WITH_DAYS_BACKUCCESS';
export const GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_ERROR =
  'GET_MONTHLY_GAINS_FOR_TICERKERS_WITH_DAYS_BACKRROR';
export const GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK_START =
  'GET_MONTHLY_GAINS_FOR_TICERKERS_WITH_DAYS_BACKTART';
// returns Type PortfolioFundamentalsAPIResponse
export const getMonthlyPricesForTickerWithDaysBack = (tickers, days) => () => ({
  type: GET_MONTHLY_GAINS_FOR_TICKERS_WITH_DAYS_BACK,
  tickers,
  days,
  payload: {
    promise: apigetMonthlyPricesForTickerWithDaysBack(tickers, days)
  }
});
export const emptyDatum: PortfolioFundamentalsAPIDatum = {
  ticker: '',
  prices: []
};
export type PortfolioFundamentalsAPIDatum = {
  ticker: string;
  prices: { date: string; price: number }[];
};
export type PortfolioFundamentalsAPIResponse = PortfolioFundamentalsAPIDatum[];
