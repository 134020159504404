exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arc{zoom:.7}@media (min-width:1024px){.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arc{zoom:1}}.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arc .src-browser-news-analysis-arcGraphStyles__needle{left:calc(50% - 4px)}.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arc .src-browser-news-analysis-arcGraphStyles__avgIndicator{line-height:.89;letter-spacing:.1px;color:#686767}.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arc .src-browser-news-analysis-arcGraphStyles__avgIndicator .src-browser-news-analysis-arcGraphStyles__indicatorText{font-family:arial;margin-bottom:2px}@media (min-width:720px) and (max-width:1024px){.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__arcGraphImg{width:14px}}.src-browser-news-analysis-arcGraphStyles__container .src-browser-news-analysis-arcGraphStyles__graphContainer .src-browser-news-analysis-arcGraphStyles__arcContainer .src-browser-news-analysis-arcGraphStyles__highLow{margin-top:0}", ""]);

// Exports
exports.locals = {
	"container": "src-browser-news-analysis-arcGraphStyles__container",
	"graphContainer": "src-browser-news-analysis-arcGraphStyles__graphContainer",
	"arcContainer": "src-browser-news-analysis-arcGraphStyles__arcContainer",
	"arc": "src-browser-news-analysis-arcGraphStyles__arc",
	"needle": "src-browser-news-analysis-arcGraphStyles__needle",
	"avgIndicator": "src-browser-news-analysis-arcGraphStyles__avgIndicator",
	"indicatorText": "src-browser-news-analysis-arcGraphStyles__indicatorText",
	"arcGraphImg": "src-browser-news-analysis-arcGraphStyles__arcGraphImg",
	"highLow": "src-browser-news-analysis-arcGraphStyles__highLow"
};