/* eslint-disable camelcase,no-var,prefer-arrow-callback,no-restricted-syntax,dot-notation,eqeqeq,spaced-comment,yoda,vars-on-top,no-prototype-builtins,no-else-return,no-lonely-if,one-var,no-empty, prefer-const, no-undef,no-plusplus,consistent-return,radix,operator-assignment, func-names */
import d3 from 'd3';
import moment from 'moment';

const buyIcon = require('../images/buy-icon.png');
const holdIcon = require('../images/hold-icon.png');
const sellIcon = require('../images/sell-icon.png');
const arrowLeftNew = require('../images/arrow_left_new.png');
const arrowRightNew = require('../images/arrow_right_new.png');

const yearBack = moment()
  .subtract(1, 'year')
  .toDate();

export default function (
  r_data,
  { timelineArticleClicked, timelinePricesSuccess }
) {
  var active_line_data = {};

  var active_rating_data = {};

  var filter_rating_data = [];

  var full_data_min_max = [];
  var rating_min_max = [];
  var parseDate = d3.time.format('%Y-%m-%dT%H:%M:%S').parse;
  if (!document.getElementById('main_box')) return;
  var main_div_width = document.getElementById('main_box').offsetWidth;
  var is_mobile = false;
  var mobile_max_points = 100;
  var stock_total_points = 0;
  var data_start_point = 0;
  var max_max_date_range = 0;
  var x_start = 45;
  var data_end_point = mobile_max_points;
  var rating_original_data = [];
  var stock_original_data = [];

  var div = d3
    .select('body')
    .append('div')
    .attr('class', 'timeline-tooltip')
    .style('opacity', 0)
    .style('display', 'none');

  var prices_arr = [];
  var x_ticks = 10;
  var y_ticks = 5;
  d3.select(window).on('resize', resize);

  // alert(main_div_width);
  // if(main_div_width <= 640){
  div.html('');
  // if (main_div_width <= 350) {
  if (main_div_width <= 435) {
    is_mobile = true;
  }

  var clone = function (obj) {
    if (null == obj || 'object' != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  };

  function search(nameKey, myArray, key) {
    for (var i = 0; i < myArray.length; i++) {
      // console.log('---',myArray[i][key],myArray[i]);
      const d1 = moment(myArray[i][key]);
      const d2 = moment(nameKey);
      if (d1.isSame(d2, 'day')) {
        return myArray[i];
      }
    }
  }

  function mobile_data_change() {
    active_line_data = [];
    filter_rating_data = [];
    x_ticks = 3;
    y_ticks = 4;
    x_start = 0;
    // active_line_data stock_original_data --- active_rating_data = rating_original_data
    // mobile_max_points data_start_point data_end_point
    //

    // console.log(data_start_point,data_end_point);
    var temp_end_date = 0;
    var i = 0;
    let temp_start_date;
    clone(stock_original_data).forEach(function (v, k) {
      // console.log(k,v);
      if (k >= data_start_point && k <= data_end_point) {
        if (i == 0) {
          temp_start_date = v.d_timestamp;
        }

        active_line_data.push(v);
        temp_end_date = v.d_timestamp;
        i++;
      }
    });
    // console.log(temp_start_date,temp_end_date);
    clone(rating_original_data).forEach(function (v /*, k*/) {
      if (
        Date.parse(v.o_date) <= temp_end_date &&
        Date.parse(v.o_date) >= temp_start_date
      ) {
        filter_rating_data.push(v);
      }
    });
    // console.log(active_line_data,filter_rating_data);
  }

  // d3.json("data/3_RatingData.json", function(r_error, r_data) {
  (() => {
    // console.log(r_data);
    // r_data.ratings.reverse();
    if (!r_data || r_data.length === 0) return;
    rating_original_data = r_data;
    active_rating_data = clone(rating_original_data);
    rating_min_max = d3.extent(active_rating_data['transactions'], function (d) {
      return d.executionTime;
    });

    const getTransactionType = (action /*sharesTraded*/) => {
      switch (action) {
        case 1:
        case 2:
        case 7:
          return 'buy';
        case 3:
        case 4:
          return 'sell';
        default:
          return 'hold';
      }
    };

    active_rating_data['transactions'].forEach(function (d /*, k*/) {
      let temp_r = {};
      let dateWithoutMillisecondsLol = d.executionTime.match(/[^.]*/)[0];
      temp_r.date = parseDate(dateWithoutMillisecondsLol);
      temp_r.o_date = dateWithoutMillisecondsLol;
      temp_r.action = d.action;
      temp_r.sharesTraded = d.sharesTraded;
      temp_r.link = '';
      temp_r.type = getTransactionType(d.action, d.sharesTraded);
      temp_r.price = d.price;
      filter_rating_data.push(temp_r);
    });

    // render_graph(active_line_data,0);
    // console.log(rating_min_max);
    d3.json(
      `/api/stocks/getHistoricalPrice/?daysBack=3500&name=${r_data.ticker}`,
      function (error, data) {
        // console.log(data);
        //  data.reverse();
        if (!data) return;
        timelinePricesSuccess();
        stock_original_data = data;
        // console.log(data);
        var new_data_arr = [];

        var prev_price = 0;
        stock_original_data.forEach(function (d /*, k*/) {
          let temp_p_d = {};
          temp_p_d.d_timestamp = Date.parse(d.date);
          temp_p_d.d_price = d.price;
          prices_arr.push(temp_p_d);
          d.d_timestamp = new Date(d.date);
          // console.log(prev_price);
          const earliest = new Date(rating_min_max[0]);
          earliest.setMonth(earliest.getMonth() - 1);
          const dDate = new Date(d.date);
          if (dDate >= earliest || dDate >= yearBack) {
            new_data_arr.push(d);
          }
        });

        // console.log(prices_arr);
        filter_rating_data.forEach(function (d1 /*, k1*/) {
          // console.log(d1.o_date);
          // console.log('==============',Date.parse(d1.o_date));
          let prev_time = 1 * 24 * 60 * 60 * 1000;
          var val;
          var subD = 0;
          do {
            var dDate = moment(d1.o_date)
              .subtract('day', subD)
              .toDate();
            val = search(dDate, prices_arr, 'd_timestamp');
            subD++;
          } while (!val && subD < 7);
          // console.log(Date.parse(d1.o_date),prev_time,Date.parse(d1.o_date) - prev_time);
          var val2 = search(
            Date.parse(d1.o_date) + prev_time,
            prices_arr,
            'd_timestamp'
          );
          // return false;
          if (val && !d1.price) {
            d1.price = val.d_price;
          } else if (val2 && !d1.price) {
            d1.price = val2.d_price;
          }
        });
        data_start_point = stock_original_data.length - mobile_max_points;
        data_end_point = stock_total_points = stock_original_data.length;

        rating_original_data = clone(filter_rating_data);
        stock_original_data = new_data_arr;
        active_line_data = clone(stock_original_data);
        filter_rating_data = clone(rating_original_data);
        max_max_date_range = active_line_data.length;

        full_data_min_max = [
          d3.min(active_line_data, function (d) {
            return d.price;
          }),
          d3.max(active_line_data, function (d) {
            return d.price;
          })
        ];

        // Filtering data for Mobile View

        if (is_mobile) {
          mobile_data_change();
        }
        // console.log(filter_rating_data);
        // console.log(active_line_data,active_rating_data);
        // console.log(filter_rating_data);
        //
        render_graph(active_line_data, 0);
      }
    );
  })();

  function resize() {
    // console.log(active_line_data);
    render_graph(active_line_data, 1);
  }

  function render_graph(data, update) {
    if (update == 1) {
      d3.select('#graph_box > svg').remove();
    }

    // Set the dimensions of the canvas / graph
    if (!document.getElementById('main_box')) return;
    main_div_width = document.getElementById('main_box').offsetWidth - 20;
    main_div_width = main_div_width === 0 ? 320 : main_div_width;

    // console.log(main_div_width);
    let temp_val_2 = 4;
    if (main_div_width <= 900 && main_div_width >= 620) {
      temp_val_2 = 6;
    } else if (main_div_width <= 620 && main_div_width >= 520) {
      temp_val_2 = 8;
    } else if (main_div_width <= 520) {
      temp_val_2 = 10;
    }

    if (main_div_width <= 1200 && !is_mobile) {
      x_ticks = 5;
    }

    let temp_val = 0;
    var margin = {
      top: 5,
      right: temp_val / 100 * main_div_width,
      bottom: 30,
      left: 10
    },
      width = main_div_width - margin.left - margin.right + 1,
      height = 185 - margin.top - margin.bottom;

    var monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    // Parse the date / time
    // var parseDate = d3.time.format("%Y-%m").parse;

    // Set the ranges
    // var x = d3.time.scale().range([40, width-((temp_val_2/100)*width)]);
    var x = d3.time.scale().range([x_start, width - 0 / 100 * width]);
    var y = d3.scale.linear().range([height - 15, 5]);
    // Define the axes
    var xAxis = d3.svg
      .axis()
      .scale(x)
      .orient('bottom')
      .ticks(x_ticks)
      .tickFormat(d3.time.format('%b %Y'));

    var yAxis = d3.svg
      .axis()
      .scale(y)
      .orient('left')
      .ticks(y_ticks)
      .tickFormat(function (d) {
        // var prefix = d3.formatPrefix(d);
        let value = d;
        const decimalParts = d.toString().split('.');
        if (decimalParts.length === 2) {
          // has decimal point
          value = parseInt(value * 10) / 10;
        }
        return value;
      });

    // Define the line
    var valueline = d3.svg
      .line()
      .x(function (d) {
        return x(d.date);
      })
      .y(function (d) {
        return y(d.price);
      });

    // Adds the svg canvas
    var border = 1;
    // var bordercolor = 'lightgrey';
    // function for the x grid lines
    function make_x_axis() {
      return d3.svg
        .axis()
        .scale(x)
        .orient('bottom')
        .ticks(x_ticks);
    }

    // function for the y grid lines
    function make_y_axis() {
      return d3.svg
        .axis()
        .scale(y)
        .orient('left')
        .ticks(y_ticks);
    }

    d3.select('#graph_box > svg').remove();
    var svgg = d3
      .select('#graph_box')
      .append('svg')
      .attr('width', '100%')
      .attr('height', height + margin.top + margin.bottom)
      .attr('border', border);

    // var borderPath = svgg.append("rect")
    //                 .attr("x", 0)
    //                 .attr("y", 0)
    //                 .attr("height", height + margin.top + margin.bottom - 5)
    //                 .attr("width", width + margin.left + margin.right - 3)
    //                 .style("stroke-dasharray", ("3, 3"))
    //                 .style("stroke", bordercolor)
    //                 .style("fill", "none")
    //                 .style("stroke-width", border);

    var svg = svgg
      .append('g')
      .attr('width', '100%')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    if (update != 1 || is_mobile) {
      data.forEach(function (d) {
        if (d.date instanceof Date) {
        } else {
          d.date = parseDate(d.date);
          if (!d.price) {
            d.price = +d.price;
          }
        }
      });
    }

    // console.log(data);return false;
    // Scale the range of the data
    let xDomain = d3.extent(data, d => d.date);
    if (!is_mobile && xDomain[0] > yearBack) {
      xDomain[0] = yearBack;
    }

    x.domain(xDomain);
    if (is_mobile) {
      y.domain(full_data_min_max);
    } else {
      y.domain([
        d3.min(data, function (d) {
          return d.price;
        }),
        d3.max(data, function (d) {
          return d.price;
        })
      ]);
    }

    // Tweaking Y axis domain for grid lines overflow issue
    // console.log(x.domain());
    // let curr_dom = x.domain();
    let curr_domain = y.domain();
    // curr_domain[1] += 1;
    // x.domain(curr_domain)
    // console.log(curr_domain);
    curr_domain[0] -= 20 / 100 * curr_domain[0]; // -ve [0]
    // curr_domain[0] = 0;
    curr_domain[1] += 20 / 100 * curr_domain[1]; // +ve [0]
    // console.log(curr_domain);
    y.domain(curr_domain);
    //

    // Draw the x Grid lines
    svg
      .append('g')
      .attr('class', 'x_g grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
      make_x_axis()
        .tickSize(-height, 0, 0)
        .tickFormat('')
      );

    // Draw the y Grid lines
    svg
      .append('g')
      .attr('class', 'y_g grid')
      .call(
      make_y_axis()
        .tickSize(-width, 0, 0)
        .tickFormat('')
      );
    //        console.log('d',data);
    // Add the valueline path.
    svg
      .append('path')
      .attr('class', 'line')
      .attr('d', valueline(data))
      .attr('stroke', '#EC8408');

    // svg.selectAll("dot")
    //     .data(filter_rating_data)
    //   .enter().append("circle")
    //     .attr("r", 3.5)
    //     .attr("cx", function(d) { return x(d.date); })
    //     .attr("fill","#fff")
    //     .attr("stroke","#A326BD")
    //     .attr("stroke-width","2px")
    //     .attr("cy", function(d) { return y(d.price); });

    // Add the X Axis

    let svg_group = svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + (height + 6) + ')');

    svg_group.call(xAxis);

    // if (is_mobile) {
    //   svg_group.append("rect")
    //     .attr("x", 26)
    //     .attr("y", 0)
    //     .attr("height", 17)
    //     .attr("width", 30)
    //     .style("fill-opacity", "0.8")
    //     .style("stroke-opacity", "0.8")

    //   .style("fill", "#DEDEDE")
    //     .style("stroke-width", 5);

    //   document.getElementById('extra_box').style.display = 'block';
    //   // document.getElementById('extra_box_2').style.display = 'block';
    // }

    // Add the images
    svg
      .selectAll('.stock_icons')
      .data(filter_rating_data)
      .enter()
      .append('g')
      .attr('class', 'stock_icons_g')
      .append('image')
      .attr('class', 'stock_icons')
      .attr('xlink:href', function (d) {
        if (d.type == 'buy') {
          return buyIcon;
        } else if (d.type == 'hold') {
          return holdIcon;
        } else if (d.type == 'sell') {
          return sellIcon;
        }
      })
      .attr('x', function (d) {
        var img_w = x(d.date) - 15;
        var div_w = width - 50;
        var tmp = 0;
        if (img_w >= div_w) {
          tmp = img_w - 20;
        } else {
          tmp = img_w;
        }
        // console.log(width,x(d.date) - 15);
        if (tmp < 0) tmp = 0;
        return parseInt(tmp);
      })
      .attr('y', function (d) {
        var img_h = y(d.price);
        var div_h = height - 32;
        // console.log(img_h,div_h);
        var tmp = 0;
        if (img_h >= div_h) {
          tmp = img_h - 15;
        } else {
          tmp = img_h;
        }
        if (d.type == 'buy') {
          tmp -= 36;
        }
        // console.log(width,x(d.date) - 15);
        return parseInt(tmp);
      })
      .attr('width', '30')
      .attr('height', '39');

    // Add the Y Axis
    let svg_group_y = svg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(40,0)');
    // if (is_mobile) {
    //   var borderPath = svg_group_y.append('rect')
    //     .attr('x', -14)
    //     .attr('y', 0)
    //     .attr('height', 172)
    //     .attr('width', 30)
    //     .style('fill-opacity', '0.8')
    //     .style('stroke-opacity', '0.8')

    //     .style('fill', '#DEDEDE')
    //     .style('stroke-width', 5);
    // }

    svg_group_y.call(yAxis);

    // Tweaks
    svg.selectAll('.y_g .tick line').style('stroke-dasharray', '3, 3');
    svg.selectAll('.x .tick text').attr('y', '7');
    svg.selectAll('.y .tick text').attr('dy', '-.3em');

    if (!is_mobile) {
      // Tooltip stuff after this
      svg
        .selectAll('.stock_icons_g')
        .data(filter_rating_data)
        .on('mouseover', function (d) {
          window.close_pop();
          div
            .style('display', 'block')
            .transition()
            .duration(500)
            .style('opacity', 0);
          div
            .transition()
            .duration(200)
            .style('opacity', 1);
          const currency =
            (r_data.ticker.toLowerCase().startsWith('tse:') ? 'C' : '') + '$';
          const price = d.price.toFixed(2);
          div
            .html(
            `<span class="div_price">${currency}${price}</span>` +
            `<span class="div_action">${toActionText({ action: d.action, trades: Math.abs(d.sharesTraded) })}</span>` +
            `<span class="div_date">${d.date.getDate()} ${
            monthNames[d.date.getMonth()]
            } </span>`
            )
            .style('left', function () {
              if (d3.event.pageX >= width - 50) {
                return d3.event.pageX - 135 + 'px';
              } else {
                return d3.event.pageX + 'px';
              }
            })
            .style('top', d3.event.pageY - 28 + 'px');
        })
        .on('mouseout', function (/*d*/) {
          //   console.log('on mouse out');
          div.style('opacity', 0);
        });

      d3
        .select('.timeline-tooltip')
        .on('mousemove', function (/*d*/) {
          div.style('display', 'block').style('opacity', 1);
        })
        .on('mouseleave', () => div.style('opacity', 0));

      d3
        .select('svg')

        .on('mouseout', function (/*d*/) {
          // console.log('on tool out');
          div.style('opacity', 0);
        });
      //
    }

    if (is_mobile) {
      // Add arrow images
      svg
        .append('image')
        .attr('xlink:href', arrowLeftNew)
        .attr('class', 'arrow_icons')
        .attr('id', 'arrow_left')
        .attr('x', 0)
        .attr('y', 55)
        .attr('width', 32)
        .attr('height', 38);
      svg
        .append('image')
        .attr('xlink:href', arrowRightNew)
        .attr('class', 'arrow_icons')
        .attr('id', 'arrow_right')
        .attr('x', width - 37)
        .attr('y', 55)
        .attr('width', 32)
        .attr('height', 38);

      if (data_end_point == stock_total_points) {
        document.getElementById('arrow_right').style.display = 'none';
        document.getElementById('arrow_left').style.display = 'block';
      } else {
        document.getElementById('arrow_left').style.display = 'block';
        document.getElementById('arrow_right').style.display = 'block';
      }
      // click events
      var right_arrow_ele = document.getElementById('arrow_right');
      var left_arrow_ele = document.getElementById('arrow_left');
      // Right Arrow click event
      right_arrow_ele.addEventListener(
        'click',
        function () {
          //
          // console.log('R before', data_start_point, data_end_point);
          data_start_point = data_start_point + mobile_max_points;
          data_end_point = data_end_point + mobile_max_points;
          if (data_end_point >= stock_total_points) {
            data_end_point = stock_total_points;
            document.getElementById('arrow_right').style.display = 'none';
          }
          if (data_start_point < 0) {
            data_start_point = 0;
          }
          // console.log('R after', data_start_point, data_end_point);
          if (data_end_point <= stock_total_points) {
            mobile_data_change();
            render_graph(active_line_data, 1);
          }
        },
        false
      );
      // Left Arrow click event
      left_arrow_ele.addEventListener(
        'click',
        function () {
          //
          // console.log('L before', data_start_point, data_end_point);
          const prevStart = data_start_point;
          if (data_start_point > 0) {
            data_start_point = data_start_point - mobile_max_points;

            if (data_start_point <= 0) {
              data_start_point = 0;
              document.getElementById('arrow_left').style.display = 'none';
            }

            data_end_point = data_end_point - (prevStart - data_start_point);
            if (data_end_point < 0) {
              data_end_point = mobile_max_points;
            }

            mobile_data_change();
            render_graph(active_line_data, 1);
          } else {
            document.getElementById('arrow_left').style.display = 'none';
          }

          // console.log(data_start_point);
        },
        false
      );
    }

    // Tweaks

    svg
      .selectAll('.y_g')
      .append('g')
      .attr('class', 'tick')
      .style('opacity', '1')
      .attr('transform', 'translate(0,0)')
      .append('line')
      .attr('x2', '1587')
      .attr('y2', '0')
      .style('stroke-dasharray', '3, 3');

    svg
      .selectAll('.y_g')
      .append('g')
      .attr('class', 'tick')
      .style('opacity', '1')
      .attr('transform', 'translate(0,' + height + ')')
      .append('line')
      .attr('x2', '1587')
      .attr('y2', '0')
      .style('stroke-dasharray', '3, 3');
    if (!is_mobile) {
      svg
        .selectAll('.x_g')
        .append('g')
        .attr('class', 'tick')
        .style('opacity', '1')
        .attr('transform', 'translate(33,0)')
        .append('line')
        .attr('y2', '-162')
        .attr('x2', '0');

      svg
        .selectAll('.x_g')
        .append('g')
        .attr('class', 'tick')
        .style('opacity', '1')
        .attr('transform', 'translate(40,0)')
        .append('line')
        .attr('y2', '-162')
        .attr('x2', '0');
    }
    let y_grid_lines_arr = document.querySelectorAll('.y_g .tick');
    for (let i = 0; i < y_grid_lines_arr.length - 1; i++) {
      y_grid_lines_arr[i].setAttribute(
        'transform',
        y_grid_lines_arr[i].getAttribute('transform').replace('(0', '(4')
      );
    }

    let x_axis_arr = document.querySelectorAll('.x .tick text');
    for (let i = 0; i < x_axis_arr.length; i++) {
      x_axis_arr[i].setAttribute('y', '3');
    }

    let y_tick_arr = document.querySelectorAll('.y .tick text');
    for (let i = 0; i < y_tick_arr.length; i++) {
      var yTick = y_tick_arr[i];
      yTick.textContent =
        (r_data.ticker.toLowerCase().startsWith('tse:') ? 'C' : '') +
        '$' +
        yTick.textContent;
    }

    if (!is_mobile) {
      let x_text_arr = document.querySelectorAll('.x .tick');
      for (let i = 0; i < x_text_arr.length; i++) {
        let x_trans_val = x_text_arr[i]
          .getAttribute('transform')
          .replace('translate(', '')
          .replace(',0)', '');
        x_trans_val = parseInt(x_trans_val);
        if (x_trans_val >= width - 30) {
          x_text_arr[i].setAttribute(
            'transform',
            'translate(' + (x_trans_val - 15) + ',0)'
          );
        }
      }
    }
    if (is_mobile) {
      let y_text_arr = document.querySelectorAll('.y .tick text');
      for (let i = 0; i < y_text_arr.length; i++) {
        y_text_arr[i].setAttribute(
          'x',
          parseInt(y_text_arr[i].getAttribute('x')) + 19
        );
        y_text_arr[i].style.fill = '#000 !important';
      }

      let x_text_arr = document.querySelectorAll('.x .tick');
      for (let i = 0; i < x_text_arr.length; i++) {
        let x_trans_val = x_text_arr[i]
          .getAttribute('transform')
          .replace('translate(', '')
          .replace(',0)', '');
        x_trans_val = parseInt(x_trans_val);
        x_text_arr[i].setAttribute(
          'transform',
          x_text_arr[i].getAttribute('transform').replace('(0,0)', '(26,0)')
        );

        if (x_trans_val >= width - 30) {
          x_text_arr[i].style.display = 'none';
        }
      }

      // var borderPath = svgg.append("rect")
      //             .attr("x", 26)
      //             .attr("y", 0)
      //             .attr("height", 172)
      //             .attr("width", 30)
      //             .style("fill-opacity", "0.8")
      //             .style("stroke-opacity", "0.8")

      //             .style("fill", "#DEDEDE")
      //             .style("stroke-width", 5);
    }

    // benji hack
    var ival = setInterval(function () {
      var line = document.querySelector('path.line');
      if (!line) return;
      if (line.getAttribute('d')) return clearInterval(ival);
      var img = document.querySelector('#arrow_left');
      if (img) {
        var evt = new MouseEvent('click');
        img.dispatchEvent(evt);
      }
    });
  }

  window.clickedArticle = () => {
    timelineArticleClicked();
    window.close_pop();
  };

  // click event
  window.close_pop = function () {
    div
      .transition()
      .duration(500)
      .style('opacity', 0);
  };

  return {
    timelineWillUnmount: () => {
      d3.selectAll('body .timeline-tooltip').remove();
      // window.close_pop();
    }
  };
}

export const __hotReload = true;

function toActionText({ action, trades }) {
  if (action == 2 && !trades) return 'Bought Share(s)';
  if (action == 3 && !trades) return 'Sold Share(s)';

  let regularAction = {
    1: 'Opened Position',
    2: 'Bought ## Share(s)',
    3: 'Sold ## Share(s)',
    4: 'Closed Position',
    5: 'No Change',
    6: 'Defined Price',
    7: 'Reopened Position',
    8: ''
  };

  return regularAction[action].replace(
    '##',
    trades
  );
}