import React from 'react';
import { ContactForm } from './contact-form.react.js';
import { html } from 'sp/browser/lib/utils';
// import {provider} from '../lib/connect-provider';
import msg from './en';
import { setActions } from './index';
import report from './analytics';

// @provider(props => props)
export default class Contact extends React.Component {
  componentWillMount() {
    report('visited');
  }

  componentDidMount() {
    setActions(this.props.actions);
  }

  render() {
    return (
      <div className="wrapper contact-page">
        <div className="inner">
          <h1>{msg.title}</h1>
          <div className="undertitle" {...html(msg.undertitle)} />
          <div className="formWrapper">
            <div className="form">
              <ContactForm {...this.props.contact} msg={msg} />
            </div>
            <div className="maryKateWrapper">
              <div className="maryKate">
                <div className="blue">
                  <p>Call us toll-free</p>
                  <a href="tel:1.866.388.7782">1.866.388.7782</a>
                  <small>24/7</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="callUs">
          <div className="phone" />
          <a href="tel:+8663887782">{msg.callUs}</a>
        </div>
      </div>
    );
  }
}

export const __hotReload = true;
