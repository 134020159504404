import React from 'react';
import Component from 'react-pure-render/component';

import classNames from 'classnames';

let idInc = 0;

const keyHandlers = {
  38: 'handleUpKey',
  40: 'handleDownKey',
  32: 'handleSpaceKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
  74: 'handleDownKey',
  75: 'handleUpKey'
};

function interceptEvent(event) {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

export default class FancySelect extends Component {
  static defaultProps = {
    clearText: '+',
    onChange: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      id: `react-select-box-${++idInc}`,
      open: false,
      focusedIndex: -1,
      pendingValue: [],
      value: this.props.multiple ? [] : ''
    };
  }

  changeOnClose() {
    return this.isMultiple() && String(this.props.changeOnClose) === 'true';
  }

  updatePendingValue = (value, cb) => {
    if (this.changeOnClose()) {
      this.setState({ pendingValue: value }, cb);
      return true;
    }
    return false;
  };

  componentWillMount() {
    this.updatePendingValue(this.props.value);
  }

  componentWillReceiveProps(next) {
    if (next.depValue !== this.props.depValue) {
      return this.handleClear();
    }
    return this.updatePendingValue(next.value);
  }

  clickingOption = false;

  blurTimeout = null;

  handleFocus = () => {
    clearTimeout(this.blurTimeout);
  };

  handleBlur = e => {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(this.handleClose, 0);
  };

  handleMouseDown = () => {
    this.clickingOption = true;
  };

  handleChange = (val, cb) => event => {
    this.clickingOption = false;
    interceptEvent(event);
    if (this.isMultiple()) {
      let selected = [];
      if (val != null) {
        selected = this.value().slice(0);
        const index = selected.indexOf(val);
        if (index !== -1) {
          selected.splice(index, 1);
        } else {
          selected.push(val);
        }
      }
      this.setState({ value: selected || [] });
      const updated = this.updatePendingValue(selected, cb);
      if (!updated) this.props.onChange(selected);
    } else {
      const updated = this.updatePendingValue(val, cb);
      if (!updated) this.props.onChange(val ? [val] : []);
      this.handleClose();
      this.setState({ value: val });
      this.refs.button.focus();
    }
  };

  handleNativeChange = event => {
    let val = event.target.value;
    if (this.isMultiple()) {
      const children = [].slice.call(event.target.childNodes, 0);
      val = children.reduce((memo, child) => {
        if (child.selected) {
          memo.push(child.value);
        }
        return memo;
      }, []);
    }
    this.props.onChange(val);
  };

  handleClear = event => {
    interceptEvent(event);
    this.handleChange(null, () => {
      // only called when change="true"
      this.props.onChange(this.state.pendingValue);
    })(event);
  };

  toggleOpenClose = event => {
    const shouldContinue = this.props.onClick(event);
    if (!shouldContinue) return;

    interceptEvent(event);
    if (this.state.open) {
      this.setState({ open: false });
      return;
    }

    this.handleOpen();
  };

  handleOpen = event => {
    interceptEvent(event);
    this.setState({ open: true }, () => {
      this.refs.menu.focus();
    });
  };

  handleClose = event => {
    interceptEvent(event);
    if (!this.clickingOption) {
      this.setState({ open: false, focusedIndex: -1 });
    }
    if (this.changeOnClose()) {
      this.props.onChange(this.state.pendingValue);
    }
  };

  moveFocus = move => {
    const len = React.Children.count(this.props.children);
    const idx = (this.state.focusedIndex + move + len) % len;
    this.changeFocus(idx);
  };

  changeFocus = focusedIndex => this.setState({ focusedIndex });

  handleKeyDown = event => {
    if (keyHandlers[event.which]) {
      this[keyHandlers[event.which]](event);
    }
  };

  handleUpKey = event => {
    interceptEvent(event);
    this.moveFocus(-1);
  };

  handleDownKey = event => {
    interceptEvent(event);
    if (!this.state.open) {
      this.handleOpen(event);
    }
    this.moveFocus(1);
  };

  handleSpaceKey = event => {
    interceptEvent(event);
    if (!this.state.open) {
      this.handleOpen(event);
    } else if (this.state.focusedIndex !== -1) {
      this.handleEnterKey();
    }
  };

  handleEnterKey = event => {
    if (this.state.focusedIndex !== -1) {
      this.handleChange(this.options()[this.state.focusedIndex].value)(event);
    }
  };

  handleEscKey = event => {
    if (this.state.open) {
      this.handleClose(event);
    } else {
      this.handleClear(event);
    }
  };

  label() {
    const selected = this.options()
      .filter(option => this.isSelected(option.value))
      .map(option => option.preview);
    return selected.length > 0 ? selected.join(', ') : this.props.label;
  }

  isMultiple() {
    return String(this.props.multiple) === 'true';
  }

  options() {
    if (!this.props.children) return [];
    const options = [];
    React.Children.forEach(this.props.children, option => {
      options.push({
        value: option.props.value,
        label: option.props.children,
        preview: option.props['data-preview']
      });
    });
    return options;
  }

  value() {
    const value = this.changeOnClose()
      ? this.state.pendingValue
      : this.state.value;

    if (!this.isMultiple() || Array.isArray(value)) {
      return value;
    }
    if (value != null) {
      return [value];
    }
    return [];
  }

  hasValue() {
    if (this.isMultiple()) {
      return this.value().length > 0;
    }
    return this.value() && this.value() !== null;
  }

  isSelected = value => {
    if (this.isMultiple()) {
      return this.value().indexOf(value) !== -1;
    }
    return this.value() === value;
  };

  render() {
    const { className, disabled, title } = this.props;

    return (
      <div
        onKeyDown={this.handleKeyDown}
        className={classNames('react-select-box-container', className, {
          disabled,
          'react-select-box-multi': this.isMultiple(),
          'react-select-box-empty': !this.hasValue()
        })}
      >
        <button
          id={this.state.id}
          ref="button"
          className="react-select-box"
          onMouseDown={this.toggleOpenClose}
          onClick={interceptEvent}
          onBlur={this.handleBlur}
          tabIndex="0"
          disabled={disabled}
          title={title}
          aria-hidden
        >
          <div className="react-select-box-label">
            <span>{this.label()}</span>
          </div>
        </button>
        {this.renderOptionMenu()}
        {this.renderClearButton()}
        {this.renderNativeSelect()}
      </div>
    );
    // div(
    //   {
    //     onKeyDown: this.handleKeyDown,
    //     className
    //   },
    //   button(
    //     {
    //       id: this.state.id,
    //       ref: 'button',
    //       className: 'react-select-box',
    //       onClick: this.toggleOpenClose,
    //       onBlur: this.handleBlur,
    //       tabIndex: '0',
    //       'aria-hidden': true
    //     },
    //     div({className: 'react-select-box-label'}, this.label())
    //   ),
    //   this.renderOptionMenu(),
    //   this.renderClearButton(),
    //   this.renderNativeSelect()
    // )
  }

  renderNativeSelect = () => {
    const id = `${this.state.id}-native-select`;
    const multiple = this.isMultiple();
    const empty = multiple ? null : (
      <option key="no-selection" value="">
        No Selection
      </option>
    );
    const options = [empty].concat(this.props.children);
    return (
      <div className="react-select-box-native">
        <label htmlFor={id}>{this.props.label}</label>
        <select
          id={id}
          multiple={multiple}
          onKeyDown={e => e.stopPropagation()}
          value={this.state.value || (multiple ? [] : '')}
          onChange={this.handleNativeChange}
          name={this.props.name}
        >
          {options}
        </select>
      </div>
    );
  };

  renderOptionMenu = () => (
    <div
      className={classNames('react-select-box-options', {
        'react-select-box-hidden': !this.state.open
      })}
      onBlur={this.handleBlur}
      onFocus={this.handleFocus}
      aria-hidden
      ref="menu"
      tabIndex="0"
    >
      <div className="react-select-box-off-screen">
        {this.options().map(this.renderOption)}
      </div>
      {this.renderCloseButton()}
    </div>
  );

  renderOption = (option, i) => (
    <a
      className={classNames('react-select-box-option', {
        'react-select-box-option-focused': i === this.state.focusedIndex,
        'react-select-box-option-selected': this.isSelected(option.value)
      })}
      id={`${this.state.id}-${i}`}
      href="#"
      onClick={this.handleChange(option.value)}
      onMouseDown={this.handleMouseDown}
      tabIndex={-1}
      key={option.label}
      onBlur={this.handleBlur}
      onFocus={this.handleFocus}
      onMouseEnter={() => this.changeFocus(i)}
      title={option.label}
    >
      {option.label}
    </a>
  );

  renderClearButton = () => {
    if (!this.hasValue()) return null;
    return (
      <button
        aria-label={this.props.clearText || 'Clear'}
        className="react-select-box-clear"
        onClick={this.handleClear}
      >
        +
      </button>
    );
  };

  renderCloseButton = () => {
    if (!this.isMultiple() || !this.props.closeText) {
      return null;
    }
    return (
      <button
        onClick={this.handleClose}
        className="react-select-box-close"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        {this.props.closeText}
      </button>
    );
  };
}
