import * as moment from 'moment';
import {
  companyConstants,
  autocompleteUrl,
  BROWSER_DEVELOPMENT,
} from './config';
import * as cookies from 'js-cookie';
import { communityNasdaqUrl } from '../common/lib/utils';
import { getParameterByName } from './lib/utils';
import { API_GetUserPortfolios_Response } from 'sp/common/api-types';
import { reduce, keyBy, merge, values } from 'lodash';
import { getRealTimeQuotesWithoutAdapting } from 'sp/browser/components/RealTimePrices/api';
import { StockDetails } from 'sp/common/types';

if (BROWSER_DEVELOPMENT && typeof window !== 'undefined') {
  const Promise = require('bluebird');
  window['Promise'] = Promise;
  Promise.config({ longStackTraces: true, warnings: false });
  // Promise.setScheduler(fn => setTimeout(fn));
  // Promise.onPossiblyUnhandledRejection(function(e) {
  //  throw e;
  // });
}

require('whatwg-fetch');
const { List, OrderedMap } = require('immutable');

const getTickers = tickers => {
  if (getParameterByName('debug') === 'true') {
    const qsTickers = getParameterByName('tickers');
    const arrTickers = new List(qsTickers.split(','));
    return qsTickers.length > 0 ? arrTickers : tickers;
  } else {
    return tickers;
  }
};

// const tokenId = 'SAHAR12345';
// const tokenId = 'e946e68ae202cd843de31a10d0ab0a747690219e';

const tokenDisable = true;
const token = url =>
  tokenDisable ? '' : `${url.indexOf('?') > -1 ? '&' : '?'}token=${token}`;

class NetworkError extends Error {
  constructor(statusCode, url, msg) {
    super('StatusCode: ' + statusCode + 'For Url: ' + url + ':>' + msg);
    this['status'] = statusCode;
    this['url'] = url;
    this['msg'] = msg;
    if (typeof window !== 'undefined') {
      window['networkErrors'] = window['networkErrors'] || [];
      window['networkErrors'].push(this);
    }
  }
}
export const sendReq = (method, url, payload, opt = { noJson: false }) => {
  if (url.includes('=') && !url.includes('?')) {
    console.warn(
      'Tried to access url ',
      url,
      'are you missing a query string param?',
    );
  }
  url = url.replace(/^\/?/, '/');
  return fetch(`${url}${token(url)}`, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    } as any,
    method,
    ...payload ? { body: JSON.stringify(payload) } : {},
  }).then(res => {
    if (res.headers['X-Backend-User-In']) location.reload(true);
    if (res.status >= 400) {
      return Promise.reject({ error: { status: res.status, url } });
    }
    return opt.noJson
      ? Promise.resolve(res)
      : res.text().then(t => t && JSON.parse(t));
  });
};
interface Options {
  noJson: boolean;
}

interface Response { }

/**
 * @param url URL For get request
 * @param qs IGNORED.
 * @param opt: Options for fetch
 */
export type GetReq = <T extends Response | string | void>(
  url: string,
  qs?: any,
  opt?: Options,
) => Promise<T>;
export type PostReq = <T extends Response | string | void>(
  url: string,
  payload?: {},
  opt?: Options,
) => Promise<T>;
/**
 * Actually does a POST request.
 * Azure sucks.
 */
export type DeleteReq = <T extends Response | string | void>(
  url: string,
  payload?: {},
  opt?: Options,
) => Promise<T>;
/**
 * Actually does a POST request.
 * Azure sucks.
 */
export type PatchReq = <T extends Response | string | void>(
  url: string,
  payload?: {},
) => Promise<T>;

export const toDataString = (arr: object, shouldEncodeURIComponent = false) =>
  reduce(
    arr,
    (qs, val, key) =>
      `${qs}${key}=${shouldEncodeURIComponent
        ? encodeURIComponent(val)
        : val}&`,
    '?',
  ).slice(0, -1);

export const getReq: GetReq = (url, qs = '', opt?) =>
  sendReq('GET', url + (qs ? toDataString(qs) : qs), null, opt);

export const postReq: PostReq = (url, payload?, opt?) =>
  sendReq('POST', url, payload, opt);

export const deleteReq: DeleteReq = (url, payload?, opt?) =>
  sendReq('POST', url, payload, opt);

export const patchReq: PatchReq = (url, payload) =>
  sendReq('POST', url, payload); // Azure sucks. It doesn't support PATCH requests consistently.

const toQsVal = <T>(arr: T[] = []) => arr.filter(v => !!v).join(',');

const isLast = (index, arr) => arr.size - 1 === index;

const toDataString_Immutable = (map, isQs) =>
  map
    .entrySeq()
    .reduce(
      (qs, [key, val], index, arr) =>
        `${qs}${key}=${isQs ? encodeURIComponent(val) : val}${!isLast(
          index,
          arr,
        )
          ? '&'
          : ''}`,
      isQs ? '?' : '',
  );

const toQs = data => toDataString_Immutable(data, true);

export const api = (controller, req) => ['api', controller, req].join('/');

/* controlsers */

export const general = req => api('general', req);

export const feedback = req => api('feedback', req);

export const portfolio = req => api('portfolio', req);

export const funds = req => api('funds', req);

export const users = req => api('users', req);

export const autocomplete = req => `${autocompleteUrl}/api/autocomplete/${req}`;

/* requests */
// Autocomplete/GetAutocomplete?name=fax
export const getAutoComplete = q =>
  fetch(
    `${autocomplete('getAutoComplete')}/` +
    `?name=${encodeURIComponent(q)}&topAnalysts=0&topBloggers=0&topInsiders=0&topInstitutions=0&topTickers=6`,
  ).then(res => res.json());

export const getFundamentals = (tickers, daysBack) =>
  getReq(
    `${portfolio('fundamentals')}/${toQs(
      new OrderedMap({
        daysBack,
        tickers: toQsVal(tickers),
      }),
    )}`,
  );

export const getLiveFeed = (type, tickers) =>
  getReq(
    `${portfolio('liveFeed')}/${toQs(
      new OrderedMap({
        expertType: type,
        tickers: toQsVal(getTickers(tickers)),
      }),
    )}`,
  );

export const getPortfolioNews = (tickers, sentiment) =>
  getReq(
    `${portfolio('getPortfolioNews')}/` +
    `?tickers=${toQsVal(getTickers(tickers))}&sentiment=${sentiment}`,
  );

const stringify = date => date.format('YYYY-MM-DD');

export const getPortfolioEvents = tickers =>
  getReq(
    `${portfolio('getPortfolioEvents')}/${toQs(
      new OrderedMap({
        fromDate: stringify(moment(new Date()).subtract(3, 'months')),
        toDate: stringify(moment(new Date()).add(3, 'months')),
        tickers: toQsVal(getTickers(tickers)),
      }),
    )}`,
  );

export const addPortfolio = name =>
  postReq(`${portfolio('addPortfolio')}`, { name });

export const removePortfolio = id =>
  deleteReq(`${portfolio('removePortfolio')}`, { id });

export const renamePortfolio = (id, name) =>
  patchReq(`${portfolio('renamePortfolio')}`, {
    id,
    newname: name,
  });

export const updateImportedPortfolio = portfolioId =>
  postReq('/api/yodlee/UpdateNow', { portfolioId });

export const getPortfolioGains = portfolioId =>
  getReq(
    `${portfolio('getPortfolioGains')}/${toQs(
      new OrderedMap({
        portfolioId,
      }),
    )}`,
  );

export const getPortfolios = () =>
  getReq<API_GetUserPortfolios_Response>(`${portfolio('getUserPortfolios')}`);

export const getFundsFees = tickers =>
  getReq(
    `${funds('getFundsFees')}/${toQs(
      new OrderedMap({
        tickers,
      }),
    )}`,
  );

export const getPortfolioItems = id => {
  const isCombined = id === companyConstants.get('combinedUserPortfolio');
  const reqName = isCombined
    ? 'GetPortfoliosHoldingsUserData'
    : 'GetPortfolioHoldingUserData';

  const qs = isCombined
    ? ''
    : toQs(
      new OrderedMap({
        id,
        _t: new Date().getTime(),
      }),
    );

  return getReq(`${portfolio(reqName)}/${qs}`);
};

export const getPortfolioItemsByTickers = (id, tickers) =>
  getReq(
    `${portfolio('GetPortfolioHoldingUserData')}/${toQs(
      new OrderedMap({
        id,
        tickers: toQsVal(tickers),
      }),
    )}`,
  );

export const getPortfolioHoldingStockData = tickers =>
  getReq(
    `${portfolio('getPortfolioHoldingStockData')}/${toQs(
      new OrderedMap({
        tickers: toQsVal(tickers),
      }),
    )}`,
  );

export const getNewsSentimentForTickers = tickers =>
  tickers.size === 0
    ? Promise.resolve([])
    : getReq(
      'api/stocks/GetTickersNewsSentiments?tickers=' + tickers.join(','),
    );

export const addPortfolioStocks = (id, tickers) =>
  postReq(portfolio('addPortfolioStocks'), {
    id,
    tickers: toQsVal(tickers),
  });

export const setPortfolioNotifiactionStatus = (id, isDisabled) =>
  postReq(portfolio('setPortfolioNotifiactionStatus'), {
    id,
    isEnabled: !isDisabled,
  });

export const setPortfolioNotifiactionStatusExperts = isDisabled =>
  postReq(portfolio('toggleExpertAlerts'), {
    isEnabled: !isDisabled,
  });

export const getPortfolioStats = id =>
  getReq(`${portfolio('getPortfolioStats')}/?id=${id}`);

export const getPortfolioWarnings = id =>
  getReq(`${portfolio('GetPortfolioWarnings')}/?id=${id}`);

export const getPortfolioBetaRisk = beta =>
  getReq(`${portfolio('getPortfolioBetaRisk')}/?portfolioBeta=${beta}`);

export const removePortfolioStock = (id, ticker) =>
  deleteReq(portfolio('removePortfolioStock'), { id, ticker });

export const getStats = () => getReq(`${general('getExpertStatistics')}`);

export const getDetails = async (tickers: string[]) => {
  const [details, realTime] = await Promise.all([
    getReq<StockDetails[]>(`api/stockInfo/getDetails/?name=${toQsVal(tickers)}`),
    getRealTimeQuotesWithoutAdapting(tickers),
  ]);

  const detailsDict = keyBy(details, 'ticker');
  const realTimeDict = keyBy(realTime, 'ticker');

  const mergedDict = merge({}, detailsDict, realTimeDict);

  return values(mergedDict);
}

export const getIsMarketOpened = () => getReq('api/general/ismarketopened', {});

export const changePortfolioHolding = (
  id,
  ticker,
  newNumShares,
  prevNumShares,
) =>
  postReq(`${portfolio('changePortfolioHolding')}`, {
    id,
    ticker,
    prevNumShares,
    newNumShares,
  });

export const changePurchasePrice = (portfolioId, ticker, executionPrice) =>
  postReq(`${portfolio('SetExecutionPrice')}`, {
    portfolioId,
    ticker,
    executionPrice,
  });

export const changeCashValue = (portfolioId, cashValue, preveCashValue) =>
  patchReq(`${portfolio('changePortfolioCashValue')}`, {
    Id: portfolioId,
    NewCashValue: cashValue,
    OldCashValue: preveCashValue,
  });

export const makeUserAnExpert = () => postReq(`${users('makeUserAnExpert')}`);

export const login = (email, password) =>
  postReq(`${users('login')}`, {
    email,
    password,
  });

if (process.env.IS_RUN_LOCAL) window['login'] = login;

export const register = (email, password) =>
  postReq(`${users('signup')}`, {
    email,
    password,
    isMakeUserAnExpert: true,
  });

export const logout = (theme = process.env.THEME) =>
  theme === 'nasdaq'
    ? new Promise(resolve => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = resolve;
      img.src = `${communityNasdaqUrl}/common/templates/logout.aspx`;
      cookies.remove('welcomeinfo');
      cookies.remove('__trlogin');
      cookies.remove('token');
      cookies.remove('token', { domain: '.nasdaq.com' });
      cookies.remove('user');
      cookies.remove('user', { domain: '.nasdaq.com' });
      cookies.remove('tr_jt', { domain: '.nasdaq.com' });
      postReq(`${users('logout')}`).then(res =>  window.location.href = "https://www.nasdaq.com/user/logout");
    })
    : postReq(`${users('logout')}`);
if (process.env.IS_RUN_LOCAL) window['logout'] = logout;

export const identifyUser = ({ shouldRefresh = false } :{ shouldRefresh?: boolean } = {}) =>
  getReq(users(`getUserSettings2/?_t=${new Date().getTime()}&refresh=${shouldRefresh ? 'true': 'false'}`));

export const setStockLastRead = (alertId, stockId) =>
  postReq(`${portfolio('setStockLastReadAlert')}`, {
    lastReadId: alertId,
    stockUid: stockId,
  });

export const getPortfolio = id =>
  getReq(
    `${portfolio('getPortfolio')}/${toQs(
      new OrderedMap({
        id,
      }),
    )}`,
  );

export const getPortfolioNotifications = id =>
  getReq(
    `${portfolio('getPortfolioNotifications')}/${toQs(
      new OrderedMap({
        id,
      }),
    )}`,
  );

export const setPortfolioNotifications = (id, notifications, weeklyDigestEnabled: boolean) =>
  postReq(
    portfolio('setPortfolioNotifications'),
    {
      id,
      ...notifications,
      weeklyDigestEnabled,
    },
    { noJson: true },
  );

export const deletePortfolioNotifications = id =>
  deleteReq(
    portfolio('deletePortfolioNotifications'),
    { id },
    { noJson: true },
  );

export const sendFeedback = settings =>
  postReq(feedback('PerformanceComingSoon'), settings, { noJson: true });

export const disableStockAlerts = (portfolioId, ticker) =>
  postReq(portfolio('ExcludeStockFromNotification'), { portfolioId, ticker });

export const enableStockAlerts = (portfolioId, ticker) =>
  deleteReq(portfolio('DeleteExcludedStockNotification'), {
    portfolioId,
    ticker,
  });

export const getNumPortfolios = () => getReq(portfolio('getnumoldPortfolios'));

export const importedPortfolio = () =>
  getReq('api/yodlee/latestImportedPortfolios');

export const importPortfolioStatus = () => getReq('api/yodlee/getImportState');

export const importPortfolioTrigger = () =>
  getReq('api/yodlee/importPortfolio');

export const makeTemporaryUser = () => postReq('api/users/temporary');

export const getUserPerformance = portfolioId =>
  getReq(
    `api/portfolio/getPortfolioPerformance/${portfolioId >= 0
      ? `?portfolioId=${portfolioId}`
      : ''}`,
  );

export const getUserExtendedProperties = () =>
  getReq('api/portfolio/getUserExtendedProperties');
