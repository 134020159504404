import * as classnames from 'classnames';
import * as React from 'react';
import * as styles from './box.istyl';
import * as classNames from 'classnames';
import { NewsAnalysisSpinner } from '../Spinner/index.react';
import { Tooltip } from './Tooltip/Tooltip';

interface BoxProps {
  header?: any; //React.ReactNode;
  title?: string;
  tooltip?: string;
  spinner?: {
    isLoading: boolean;
    size: number;
    isLoadingClassName?: string;
  };
  mediaQuery?: any; //MediaQuery
}
export class Box extends React.PureComponent<BoxProps & any> {
  public render() {
    const {
      children,
      title,
      header,
      tooltip,
      spinner,
      mediaQuery,
      ...restProps
    } = this.props;
    const shouldShowSpinner = spinner && spinner.isLoading;
    const isLoadingClassName = (spinner && spinner.isLoadingClassName) || '';
    return (
      <div
        {...restProps}
        className={classNames(styles.box, restProps.className)}
      >
        {header && (
          <header className={styles.header}>
            <h3 title={title}>{header}</h3>
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                content={tooltip}
                mediaQuery={mediaQuery}
              />
            )}
          </header>
        )}
        {shouldShowSpinner ? (
          <div className={classNames(styles.center, isLoadingClassName)}>
            <NewsAnalysisSpinner size={40} />
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
}
