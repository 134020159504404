import { Plan } from '../news-analysis/types';
import { promote } from '../TargetedWebPage/actions';
import * as React from 'react';
import { Map } from 'immutable';
import * as classNames from 'classnames';
import { AuthRecord, Screener, ScreenerType } from '../../common/types';
import { Reporter } from '../analytics';
import { events } from './analytics';
import Filter, { FilterConf } from './filter';
import { VariableSizeSpinner } from '../Spinner/index.react';
import './filters.styl';

interface FiltersProps {
  onSubmit: React.EventHandler<React.SyntheticEvent<HTMLFormElement>>;
  filters: FilterConf[];
  openDialog: (dialog: string, data: {}) => void;
  hasFields: boolean;
  changeFilter: (label: string, value: string[]) => void;
  screener: Screener;
  selectedFields: Map<string, string[]>;
  auth: AuthRecord;
  stockType: ScreenerType;
  report: Reporter<typeof events>;
}

export default class Filters extends React.PureComponent<FiltersProps> {
  private onLockedClick = (e: React.SyntheticEvent<HTMLElement>) => {
    const type =
      this.props.stockType === 'etfScreener'
        ? 'etf-screener-filter'
        : 'stock-screener-filter';
    promote({ type, plan: 'premium' });
    // this.props.openDialog('promotion', {plan, type });
  };

  public render() {
    const {
      stockType,
      filters,
      selectedFields,
      auth,
      changeFilter,
      onSubmit,
      hasFields,
      screener,
      report
    } = this.props;
    return (
      <form className="filtersForm" onSubmit={onSubmit}>
        {filters.map(filterProps => {
          const { depends = '' } = filterProps;
          const hasDependencies = !depends;
          const hasSelectedFields = selectedFields && selectedFields.size;
          const depsHaveValue =
            hasSelectedFields &&
            typeof selectedFields.get(depends) !== 'undefined'
              ? selectedFields.get(depends).length === 0
              : false;
          const depValue = hasSelectedFields ? selectedFields.get(depends) : [];
          const disabled = !hasDependencies && depsHaveValue;
          const options =
            typeof filterProps.options === 'function'
              ? filterProps.depends
                ? filterProps.options(selectedFields.get(filterProps.depends))
                : []
              : filterProps.options;

          return (
            <Filter
              stockType={stockType}
              key={filterProps.apiLabel}
              {...filterProps}
              auth={auth}
              options={options}
              disabled={disabled}
              title={
                disabled && depends
                  ? 'Please select a value for the previous filter'
                  : ''
              }
              onLockedClick={this.onLockedClick}
              changeFilter={changeFilter}
              report={report}
              depValue={depValue}
            />
          );
        })}
        <button
          className={classNames('search', { loading: screener.loadingTable })}
          disabled={!hasFields}
        >
          <span className="text">Search</span>
          <VariableSizeSpinner size={30} className="spinner" />
        </button>
      </form>
    );
  }
}
