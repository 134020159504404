import * as React from 'react';
import { AssetBox } from './AssetBox.react.tsx';
import { FormattedNumber } from 'react-intl';
import { NoData } from './NoData.react';
import { connect } from 'react-redux';
import { Loading } from './Loading.react.tsx';
import { TickerLink } from './TickerLink.react';
import { get } from 'lodash';
import { format } from './AllocChart/src/utils';
import { dividendGlossary } from '../../../../decisions/index.ts';

import report from './analytics';

const DividendDatesList = NoData(({ items }) => (
  <ol>
    {items.map(
      (s, i) =>
        (
          <li key={i}>
            {i + 1}. {s.name} (<TickerLink holding={s} />)
            <span className="right-top-list">
              {' '}
              {s.dividendDate.toLocaleDateString()}
            </span>
          </li>
        )
    )}
  </ol>
));

const TopDividendStocksList = NoData(({ items }) => (
  <ol>
    {items.map((s, i) => (
      <li key={i}>
        <span className="left-top-list">
          {i + 1}. <span className="company-name">{s.name}</span> (<TickerLink
            holding={s}
          />)
        </span>
        <span className="right-top-list">
          <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            style="percent"
            value={s.yield}
          />
        </span>
      </li>
    ))}
  </ol>
));

const DividendLists = NoData(({ dates, dividends }) => (
  <div>
    <div className="top-list">
      <div className="top-list-title">
        <div>My Top Dividend Holdings</div>
        <div>Yield</div>
      </div>
      <TopDividendStocksList hasData={dividends.length} items={dividends} />
    </div>
    <div className="top-list">
      <div className="top-list-title">
        <div>Upcoming Dividends</div>
      </div>
      <DividendDatesList
        noDataMsg="No upcoming dividends"
        hasData={dates.length}
        items={dates}
      />
    </div>
  </div>
));

export default class PortfolioDividends extends AssetBox(
  'asset-box-portfolio-dividends',
  ['eventsByTickers']
) {
  constructor(...props) {
    super(...props);
    this.state = { ...this.state, show: false };
  }

  show = () => {
    report('clickedLearnMore', 'dividends');
    this.setState({ show: true });
  };

  head() {
    const { clickedDividendsGlossary } = this.props;
    return (
      <span className="section-header-alt-1-h1">
        <div>
          <h3>My Portfolio Dividends</h3>
          {this.headerTooltip(
            'List of holdings with highest dividend payout. Explore ways to increase your dividend yield.'
          )}
        </div>
        {this.headerSideLink(
          'What are Dividends? >',
          dividendGlossary,
          clickedDividendsGlossary
        )}
      </span>
    );
  }
  body() {
    const {
      dividends,
      coming,
      portfolioYield,
      portfolioYieldValue
    } = this.props;

    const { show } = this.state;

    return (
      <div>
        <div className="portfolio-dividend-yield">
          <div className="value">
            {!portfolioYield ? '-' : format.percentRounded(portfolioYield)}
          </div>
          <div className="text">Estimated Portfolio Dividend Yield</div>
        </div>
        <div className="portfolio-dividend-amount">
          <div className="text">Estimated Portfolio Dividend Amount</div>
          <div className="value">
            {!portfolioYieldValue ? (
              '—'
            ) : (
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  currency="USD"
                  style="currency"
                  value={portfolioYieldValue}
                />
              )}
          </div>
        </div>
        {show ? (
          <div className="dividends-list-container">
            <DividendLists
              dates={coming}
              dividends={dividends}
              hasData={get(dividends, 'length') || get(coming, 'length')}
              noDataMsg="No Dividend Holdings"
            />
          </div>
        ) : (
            <div className="learn-more">
              <button onClick={this.show}>See Dividends</button>
            </div>
          )}
      </div>
    );
  }
}

// <DividendYield title="Portfolio Dividend Amount" value="$2.33" />
