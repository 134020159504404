import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import { imgSrc } from '../../common/lib/utils.ts';

export class AnalyzingPortfolio extends Component {
  static propTypes = {
    msg: PropTypes.object,
    theme: PropTypes.string
  };

  render() {
    const { msg, theme } = this.props;

    return (
      <div className="analyzing-portfolio">
        <div className="analyzing">
          <img
            alt=""
            height="107"
            src={imgSrc(`analyzing-${theme}`)}
            width="107"
          />
        </div>
        <div className="message">
          <div className="message-inner">{msg.message}</div>
        </div>
      </div>
    );
  }
}
export default AnalyzingPortfolio