import * as React from 'react';
import { fromJS, List } from 'immutable';

import {
  typeToCategories,
  categoryToInvestmentStyle,
  categoryToCategory
} from './filtersConf';

import {
  FilterConf,
  SelectOption
} from '../tr-stock-screener-container/filter';

import { getColById } from '../tr-dashboard-holdings-table/cols';

import {
  EtfAssetTypeMapping,
  EtfCategoryMapping,
  InvestmentStrategyMapping,
  Issuer
} from '../../common/types';

declare function require(url: string): string;

const img1 = require('../RelatedLinks/img/optimize.png');
const img2 = require('../RelatedLinks/img/cash-clock.png');
const img3 = require('../RelatedLinks/img/document.png');

export const cols = {
  mobileS: List(['name', 'price']),
  tablet: List(['name', 'price']),
  laptop: List(['name', 'price', 'category', 'totalAssets']),
  laptopL: List(['name', 'price', 'category', 'totalAssets', '1yrReturn']),
  desktop: List([
    'name',
    'price',
    'category',
    'totalAssets',
    '1yrReturn',
    '3yrReturn',
    '5yrReturn'
  ])
};

const groups = {
  mobileS: fromJS([
    {
      width: 1,
      details: [
        '1yrReturn',
        '3yrReturn',
        '5yrReturn',
        'category',
        'totalAssets',
        'issuer',
        'etfType',
        'investmentStyle',
        'yield',
        'managementFee'
      ]
    }
  ]),
  mobileL: fromJS([
    {
      width: 1,
      details: ['description']
    },
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 1,
      border: true,
      details: [
        '1yrReturn',
        '3yrReturn',
        '5yrReturn',
        'category',
        'totalAssets',
        'issuer',
        'etfType',
        'investmentStyle',
        'yield',
        'managementFee'
      ]
    }
  ]),
  laptop: fromJS([
    {
      width: 1,
      details: ['description']
    },
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.5,
      border: true,
      details: ['1yrReturn', '3yrReturn', '5yrReturn', 'issuer']
    },
    {
      width: 0.5,
      border: true,
      details: ['etfType', 'investmentStyle', 'yield', 'managementFee']
    }
  ]),
  laptopL: fromJS([
    {
      width: 1,
      details: ['description']
    },
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.5,
      border: true,
      details: ['3yrReturn', '5yrReturn', 'issuer', 'etfType']
    },
    {
      width: 0.5,
      border: true,
      details: ['investmentStyle', 'yield', 'managementFee', 'highLow']
    }
  ]),
  desktop: fromJS([
    {
      width: 1,
      details: ['description']
    },
    {
      width: 1,
      details: ['prices']
    },
    {
      width: 0.38,
      border: true,
      details: ['issuer', 'etfType']
    },
    {
      width: 0.31,
      border: true,
      details: ['investmentStyle', 'yield']
    },
    {
      width: 0.31,
      border: true,
      details: ['managementFee', 'highLow']
    }
  ])
};

const latestMatching = obj => mediaQuery => {
  const keys = Object.keys(obj);
  const lastMatchingMediaQuery =
    keys.findIndex(key => !mediaQuery.get(key)) || 1;
  return obj[
    keys[
    lastMatchingMediaQuery === -1
      ? keys.length - 1
      : lastMatchingMediaQuery - 1
    ]
  ];
};

export const getRawCols = latestMatching(cols);

export const getDetailGroups = mediaQuery =>
  latestMatching(groups)(mediaQuery).map(group =>
    group.set('details', group.get('details').map(getColById))
  );

const makeOption = (
  display: string,
  value: string | number,
  preview: string = display
): SelectOption => ({ display, value, preview });

const byDisplayName = (opt1: SelectOption, opt2: SelectOption) =>
  opt1.display.toLowerCase() > opt2.display.toLowerCase() ? 1 : -1;

export const maxShowMore = 5;

//TODO: Actual options for all of these
export const filters: Array<FilterConf> = [
  {
    label: 'Asset Type',
    apiLabel: 'assetType',
    options: [
      makeOption('Equity', 4),
      makeOption('Debt', 3),
      makeOption('Commodity', 2),
      makeOption('Alternative', 1)
    ],
    tooltip: 'The type of asset an ETF is invested in',
    glossaryLink: 'screener-methodology',
    multiple: false
  },
  {
    label: 'Category',
    apiLabel: 'category',
    options: ([selectedAssetType]: EtfAssetTypeMapping[]) => {
      const categories = typeToCategories.get(selectedAssetType);
      if (!categories) {
        return [];
      }
      return categories
        .map(category =>
          makeOption(
            EtfCategoryMapping[categoryToCategory.get(category) || category],
            category
          )
        )
        .sort(byDisplayName);
    },
    tooltip: 'A more specific scope of the assets an ETF is invested in',
    glossaryLink: 'screener-methodology',
    depends: 'assetType',
    multiple: false
  },
  {
    label: 'Investment Style',
    apiLabel: 'investmentStyle',
    options: ([selectedCategory]: EtfCategoryMapping[]) => {
      const investmentStyles = categoryToInvestmentStyle.get(selectedCategory);
      if (!investmentStyles) {
        return [];
      }
      return investmentStyles
        .map(style => makeOption(InvestmentStrategyMapping[style], style))
        .sort(byDisplayName);
    },
    tooltip: 'The most specific scope of the assets an ETF is invested in',
    glossaryLink: 'screener-methodology',
    depends: 'category'
  },
  {
    label: 'Total Assets ($)',
    apiLabel: 'totalAssets',
    options: [
      makeOption('Mega (Over $1B)', '5', 'Mega'),
      makeOption('Large ($500M to $1B)', '4', 'Large'),
      makeOption('Medium ($100M to $500M)', '3', 'Medium'),
      makeOption('Small ($50M to $100M)', '2', 'Small'),
      makeOption('Micro (Under $50M)', '1', 'Micro')
    ],
    tooltip: 'Total market value of all financial assets managed by an ETF',
    glossaryLink: 'total-assets'
  },
  {
    label: 'Issuer',
    apiLabel: 'issuer',
    options: Object.keys(Issuer)
      .filter(Number)
      .map(key =>
        makeOption(
          Issuer[key],
          key,
          Issuer[key].length < 15 ? Issuer[key] : Issuer[key].slice(0, 15) + '…'
        )
      )
      .sort(byDisplayName),
    tooltip: 'List of firms that create and manage ETFs',
    glossaryLink: 'issuer'
  },
  {
    label: '1 Year Return',
    apiLabel: 'oneYearReturn',
    options: [
      makeOption('< -20%', '1'),
      makeOption('-20% to -10%', '2'),
      makeOption('-10% to 0%', '3'),
      makeOption('0% to 10%', '4'),
      makeOption('10% to 20%', '5'),
      makeOption('20% <', '6')
    ],
    tooltip: 'The trailing performance of an ETF in the past year',
    glossaryLink: 'performance'
  },
  {
    label: '3 Years Return',
    apiLabel: 'threeYearsReturn',
    options: [
      makeOption('< -20%', '1'),
      makeOption('-20% to -10%', '2'),
      makeOption('-10% to 0%', '3'),
      makeOption('0% to 10%', '4'),
      makeOption('10% to 20%', '5'),
      makeOption('20% <', '6')
    ],
    tooltip: 'The trailing performance of an ETF in the past 3 years',
    glossaryLink: 'performance'
  },
  {
    label: '5 Years Return',
    apiLabel: 'fiveYearsReturn',
    options: [
      makeOption('< -20%', '1'),
      makeOption('-20% to -10%', '2'),
      makeOption('-10% to 0%', '3'),
      makeOption('0% to 10%', '4'),
      makeOption('10% to 20%', '5'),
      makeOption('20% <', '6')
    ],
    tooltip: 'The trailing performance of an ETF in the past 5 years',
    glossaryLink: 'performance'
  },
  {
    label: 'Dividend Yield',
    apiLabel: 'dividendYield',
    options: [
      makeOption('None (0%)', 'none', 'None'),
      makeOption('Positive (>0%)', 'positive', 'Positive'),
      makeOption('High (>2.5%)', 'high', 'High'),
      makeOption('Very High (>5%)', 'veryHigh', 'Very High')
    ],
    tooltip:
      'The ratio of an ETFs yearly payout relative to its current share price',
    glossaryLink: 'dividend-yield'
  },
  {
    label: 'Management Fees',
    apiLabel: 'managementFees',
    options: [
      makeOption('Under 0.50%', '1'),
      makeOption('0.50% to 1%', '2'),
      makeOption('1% to 1.5%', '3'),
      makeOption('1.5% to 2%', '4'),
      makeOption('Over 2%', '5')
    ],
    tooltip:
      'The annual fee an ETF charges its shareholders divided by total assets',
    glossaryLink: 'management-fees',
    lockPlan: 'premium'
  }
];

export const links = [
  {
    text: 'How to optimize and reduce ETF management fees',
    icon: img1,
    href:
      'http://blog.tipranks.com/new-feature-reduce-etf-and-mutual-fund-fees-with-the-tipranks-smart-portfolio/'
  },
  {
    text: 'ETF Weekly Trading Strategy Newsletter',
    icon: img2,
    href:
      'https://www.smarteranalyst.com/investment-newsletters/gosector/?utm_source=TipRanks&utm_campaign=TipRanks%20ETFscreener%20page&utm_medium=TR%20Banner'
  },
  {
    text: "How to read Tipranks' ETF pages",
    icon: img3,
    href:
      'http://blog.tipranks.com/investors-making-moves-etfs-diversify-asset-classes/'
  }
];
