import { Record } from 'immutable';
import { StockType } from 'sp/common/types';
import { createNasdaqLink } from 'sp/browser/lib/utils';
import { getTickerUrl } from 'sp/common/lib/utils';

export const portfolioItemUrl = getTickerUrl;

export const assetAllocationGloassary =
  process.env.THEME === 'nasdaq'
    ? createNasdaqLink('investing/glossary/a/asset-allocation-decision')
    : `/smart-portfolio/glossary/a/why-asset-allocation-is-important`;

export const dividendGlossary =
  process.env.THEME === 'nasdaq'
    ? createNasdaqLink('investing/glossary/d/dividend')
    : `/smart-portfolio/glossary/d/dividends`;

export const peGlossary =
  process.env.THEME === 'nasdaq'
    ? createNasdaqLink('investing/glossary/p/p-e-ratio')
    : `/smart-portfolio/glossary/p/priceearnings-pe-ratio`;

export const riskGlossary =
  process.env.THEME === 'nasdaq'
    ? createNasdaqLink('investing/glossary/r/risk-management')
    : `/smart-portfolio/glossary/h/how-to-reduce-risk`;

export const tourLink = `${process.env.THEME === 'nasdaq'
  ? '/tour-nasdaq'
  : '/tour'}`;

export const planName = (plan, enums) =>
  process.env.THEME === 'nasdaq' && plan === 'premium'
    ? 'Professional'
    : enums.plans[plan];
