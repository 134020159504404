import { fromJS } from 'immutable';
import { List } from 'immutable';
import { set } from 'lodash';

export function calculateAnalysis(portfolioAnalysis: any) {
  // const portfolioAnalysis = selectoPortfolioAnalysis(dashboard);
  const {
    betaValue,
    chartData,
    holdingsChartData,
    portfolioYield
  } = portfolioAnalysis;

  const portfolioStocksDistribution = holdingsChartData.stock
    ? fromJS(holdingsChartData.stock.map(s => set(s, 'percent', s.count)))
    : List();

  return {
    portfolioItemsDistribution: centComplement(chartData.stockAllocation),
    portfolioStocksDistribution: centComplement(portfolioStocksDistribution)
  };
}

// TODO refactor those methods into utils.
export function centComplement(obj) {
  return obj;
}