import { HighStock, labelFormatter } from '../HighStock';
import * as React from 'react';
import * as moment from 'moment';
// Why doesn't .stockChart exist on HighCharts
import * as HighCharts from 'highcharts/highstock';
import { News } from './news';
import { HistoricalPrices } from './prices';
import { IndividualSeriesOptions } from 'highcharts';
import { Ticker } from 'sp/common/types';
import { currencySymbolFromTicker } from 'sp/browser/Money/Money';
const highChart = require('highcharts/highstock');

/**
 * Note: Programmed to show 3 months back of price data.
 */
export class MediaCoverageAnalysisHighStockChart extends React.PureComponent<{
  ticker: Ticker,
  data: {
    news: News;
    prices: HistoricalPrices;
  };
  colors: { positive: string; negative: string; neutral: string };
  className?: string;
  noDataElement?: React.ReactNode;
  height?: number;
}> {
  render() {
    const {
      noDataElement = <span />,
      data: { news, prices },
      height,
      colors: { positive, neutral, negative },
      className,
      ticker
    } = this.props;

    if (!news.length || !prices.length) return noDataElement as JSX.Element;

    // adjust news items by date to have only news that sync date-wise with prices.
    // const firstPriceDateIndex = new Date(prices[0].date);
    // const firstCompatibleNewsIndex = news.findIndex(d => {
    //   const foo = new Date(d.weekStart);
    //   return foo.getMonth() === firstPriceDateIndex.getMonth() && foo.getFullYear() === firstPriceDateIndex.getFullYear();
    // });
    const firstCompatibleNewsIndex = 0;
    const news0 = news
      .slice(firstCompatibleNewsIndex)
      .map(d => ({ ...d, x: new Date(d.weekStart + 'Z').getTime() }));
    const news1 = news0.sort((a, b) => (a.x > b.x ? 1 : -1));
    const positiveNewsArticles = news1.map(d => ({ x: d.x, y: d.buy }));
    const neutralNewsArticles = news1.map(d => ({ x: d.x, y: d.neutral }));
    const negativeNewsArticles = news1.map(d => ({ x: d.x, y: d.sell }));
    const articleCounts = news1.map(d => d.all);
    const maxArticlesCount = Math.max.apply(Math, articleCounts);
    const medianYAxisPoint = Math.ceil(maxArticlesCount / 10) * 10;

    const priceData1 = prices.map(d => ({
      y: d.price,
      x: new Date(d.date + 'Z').getTime(),
    }));
    const firstNewsItem = news1[0];
    const firstNewsItemDate = new Date(firstNewsItem.x);
    // const aestheticOffsetToFirstDate = firstNewsItemDate - 1;
    const _beginPriceDataIndex = priceData1.findIndex(d => {
      const foo = new Date(d.x);
      return (
        foo.getMonth() === firstNewsItemDate.getMonth() &&
        foo.getFullYear() === firstNewsItemDate.getFullYear()
      );
    });
    const beginPriceDataIndex =
      _beginPriceDataIndex === -1 ? 0 : _beginPriceDataIndex;
    const priceData = priceData1.slice(beginPriceDataIndex);

    const color1 = (HighCharts.getOptions() as any).colors[0];
    const color2 = (HighCharts.Color(color1) as any).setOpacity(0).get('rgba');

    const priceArr = priceData.map(d => d.y);
    const stockPrice = priceArr[priceArr.length - 1];
    const didStockPriceWentUp =
      priceArr[priceArr.length - 2] < priceArr[priceArr.length - 1];

    // show 3 months back of price data
    const now = new Date();
    const threeMonthsAgo = moment()
      .set('month', moment().get('month') - 3)
      .set('day', 0)
      .toDate()
      .getTime();

    return (
      <HighStock
        className={className}
        priceLabel={{ value: stockPrice, isPositive: didStockPriceWentUp }}
        height={height}
        options={{
          tooltip: {
            shared: true,
            split: false,
            style: {
              fontSize: 12,
            },
            xDateFormat: '%A, %b %d %Y',
          },
          xAxis: [
            {
              labels: { formatter: labelFormatter },
              type: 'datetime',
              min: threeMonthsAgo,
            },
          ],
          yAxis: [
            {
              // price
              labels: {
                align: 'left',
              },
              lineWidth: 2,
              showLastLabel: false,
              showFirstLabel: false,
              tickPositions: generateThreeTickPositions(priceArr),
            },
            {
              // articles
              labels: {
                align: 'right',
                formatter: function () {
                  return this.value + ' articles';
                },
              },
              // tickPositions: [0, medianYAxisPoint, medianYAxisPoint * 2], // This is how you generate one point in the center.
              tickPositions: generateThreeTickPositions(articleCounts).map(
                Math.floor,
              ), // half an article doesn't really make sense, right :)
              opposite: false,
              showFirstLabel: false,
              lineWidth: 2,
            },
          ],
          plotOptions: {
            column: {
              stacking: 'normal',
            },
          },
          series: [
            {
              name: 'Price',
              data: priceData,
              type: 'area',
              fillColor: {
                linearGradient: [0, 0, 0, 175],
                stops: [[0, color1], [1, color2]],
              },
              tooltip: {
                valueSuffix: currencySymbolFromTicker(ticker),
              },
            } as IndividualSeriesOptions,
            {
              ...baseColumnOptions,
              name: 'Bullish News Articles',
              data: positiveNewsArticles,
              color: positive,
            },
            {
              ...baseColumnOptions,
              name: 'Neutral News Articles',
              data: neutralNewsArticles,
              color: neutral,
            },
            {
              ...baseColumnOptions,
              name: 'Bearish News Articles',
              data: negativeNewsArticles,
              color: negative,
            },
          ],
        }}
      />
    );
  }
}

const baseColumnOptions = {
  type: 'column',
  yAxis: 1,
  pointWidth: 15,
};

export function generateThreeTickPositions(pointArr: number[]) {
  const lowestPoint = Math.min.apply(Math, pointArr);
  const highestPoint = Math.max.apply(Math, pointArr);
  const lowestPointTickPos = Math.floor(lowestPoint / 10) * 10;
  const highestPointTickPos = Math.ceil(highestPoint / 10) * 10;
  const middlePointTickPos = (lowestPointTickPos + highestPointTickPos) / 2;
  const pointTickPositions = [
    lowestPointTickPos,
    (middlePointTickPos + lowestPointTickPos) / 2,
    middlePointTickPos,
    (middlePointTickPos + highestPointTickPos) / 2,
    highestPointTickPos,
  ];
  return pointTickPositions;
}
