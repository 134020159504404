import { fromJS } from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';

import report from './analytics';
import WarningDialog from '../tr-dashboard-warning-dialog';
import { dashboardPath } from '../../common/config';
import { history } from 'sp/browser/history';

export default class TrDashboardDismissableMissingSharesContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    history: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    report('saw');
  }

  onDismiss = () => report('clickedDismiss');

  render() {
    const { actions, msg } = this.props;

    const closeAndFillShares = ({ dismiss }) => {
      actions.closeDialog();
      report('clickedBackToDashboard');
      history.push(`/${dashboardPath}/holdings`);
      if (dismiss) {
        actions.dismissWarningDialog('missingSharesOnNavIn');
      }
    };

    const controls = fromJS([
      {
        action: closeAndFillShares,
        label: msg.dialogs.missingSharesOnNavIn.positive
      }
    ]);

    return (
      <WarningDialog
        controls={controls}
        dismissWarningDialog={() =>
          actions.dismissWarningDialog('missingSharesOnNavIn')
        }
        isDismissable
        msg={msg.warningDialog}
        onChangedDismiss={this.onDismiss}
        warning={msg.dialogs.missingSharesOnNavIn.warning}
      />
    );
  }
}
