import Component from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';
import { dashboardPath } from '../../common/config';

export class Logout extends Component {
  componentDidMount() {
    this.props.history.replaceState(null, `/${dashboardPath}/welcome`);
    this.props.actions.logout();
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  actions: PropTypes.object
};
