import Copmonent from 'react-pure-render/component';
import React from 'react';
import PropTypes from 'prop-types';

import AlertsSettings from '../tr-dashboard-alerts-settings';
import { ApiDecorator } from '../ApiDecorator';
import report from './analytics';

import { selectViewerPlan } from '../../common/auth/selectors.ts';

import {
  selectActivePortfolio,
  selectPortfolios,
  selectTheme
} from '../dashboard/selectors';

import {
  selectComponentStates,
  selectExpectedExpertEmail,
  selectExpectedStockEmails,
  selectIsDisabled,
  selectIsDisabledExperts,
  selectNumSubscriptionToExperts,
  selectSettings,
  selectTotalAlertsPredictions,
  selectIsWeeklyDigestEnabled,
  selectComponentState
} from '../tr-dashboard-alerts-settings/selectors.ts';
import { promote } from '../TargetedWebPage/actions';

@ApiDecorator('portfolios', 'alertsSettings')
export default class TrDashboardAlertsSettingsContainer extends Copmonent {
  static propTypes = {
    actions: PropTypes.object,
    alertsSettings: PropTypes.object,
    auth: PropTypes.object,
    dashboard: PropTypes.object,
    msg: PropTypes.object
  };

  componentDidMount() {
    report('saw');
  }

  showPromotion = (plan, type = 'alertsSettings', field) => {
    const { actions, auth } = this.props;
    const currentPlan = selectViewerPlan(auth);
    if (currentPlan !== 'ultimate' && plan !== 'free') {
      report.setLastPromotionTrigger('clickedRestrictedTrigger', field);
      report('clickedRestricted', field);
      report('clickedRestrictedAlertSettings', { field });
      promote({ plan, type });
    }
  };

  close = () => {
    const { actions } = this.props;
    actions.closeDialog();
  };

  toggleIsDisabled = () => {
    const { actions, alertsSettings, dashboard } = this.props;
    const isDisabled = !selectIsDisabled(alertsSettings);
    const portfolio = selectActivePortfolio(dashboard);
    actions.toggleIsDisabled(portfolio.get('id'), isDisabled);
    report('changedEmailAlerts', { type: 'stock', isDisabled });
  };
  toggleWeeklyDigest = () => {
    const { actions, alertsSettings, dashboard } = this.props;
    const shouldEnable = !selectIsWeeklyDigestEnabled(alertsSettings);
    const portfolio = selectActivePortfolio(dashboard);
    actions.changeWeeklyDigest(portfolio.get('id'), selectSettings(alertsSettings), shouldEnable);
  };

  toggleIsDisabledExperts = () => {
    const { actions, alertsSettings, dashboard } = this.props;
    const portfolio = selectActivePortfolio(dashboard);
    const isDisabled = !selectIsDisabledExperts(alertsSettings);
    actions.toggleIsDisabledExperts(isDisabled);
    report('changedEmailAlerts', { type: 'expert', isDisabled });
  };

  showToast = (...props) => {
    // const { actions } = this.props;
    // actions.showToast(...props);
  };

  render() {
    const { actions, alertsSettings, auth, dashboard, msg } = this.props;

    const componentState = selectComponentState(alertsSettings);

    const activePortfolio = selectActivePortfolio(dashboard);
    const theme = selectTheme(dashboard);

    return (
      <AlertsSettings
        changePortfolio={actions.changePortfolio}
        changeSettings={actions.changeSettings}
        close={this.close}
        componentState={componentState}
        enums={msg.enums}
        expectedExpertEmail={selectExpectedExpertEmail(alertsSettings)}
        expectedStockEmails={selectExpectedStockEmails(alertsSettings)}
        isDisabled={selectIsDisabled(alertsSettings)}
        isDisabledExperts={selectIsDisabledExperts(alertsSettings)}
        isEnabledWeeklyDigest={selectIsWeeklyDigestEnabled(alertsSettings)}
        msg={msg.alertsSettings}
        numSubscriptionToExperts={selectNumSubscriptionToExperts(
          alertsSettings
        )}
        portfolioId={activePortfolio.get('id')}
        portfolioName={activePortfolio.get('name')}
        portfolioSelectMsg={msg.portfolioSelect}
        portfolios={selectPortfolios(dashboard)}
        settings={selectSettings(alertsSettings)}
        showPromotion={this.showPromotion}
        showToast={this.showToast}
        theme={theme}
        toggleIsDisabled={this.toggleIsDisabled}
        toggleWeeklyDigest={this.toggleWeeklyDigest}
        toggleIsDisabledExperts={this.toggleIsDisabledExperts}
        totalAlertsPredictions={selectTotalAlertsPredictions(alertsSettings)}
        viwerPlan={selectViewerPlan(auth)}
      />
    );
  }
}
