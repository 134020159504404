import React from 'react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import AddStockContainer from '../tr-dashboard-add-stock-container';
import { connect } from 'react-redux';
import { wat as msg } from './en';
import { Link } from 'react-router';
import { FormattedHTMLMessage } from 'react-intl';
import report from './analytics';
import { history } from '../history.ts';
import {
  selectIsPermanentLoggedIn,
  selectViewer
} from '../../common/auth/selectors.ts';

class WelcomeBanner extends Component {
  render() {
    const bannerMsg = this.props.msg;
    return (
      <div className="welcome-banner">
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: bannerMsg.title }}
        />
        <div className="new">{bannerMsg.new}</div>
        <div className="discover">{bannerMsg.discover}</div>
      </div>
    );
  }
}

WelcomeBanner.defaultProps = { msg: msg.banner };

class WelcomeInfo extends Component {
  renderMobileBanner() {
    const bannerMsg = msg.mobileBanner;
    return (
      <div className="mobile-banner">
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: bannerMsg.title }}
        />
        <div className="new">{bannerMsg.new}</div>
      </div>
    );
  }

  onDecline = () => {
    this.props.actions
      .discardExternalPortfolios()
      .then(this.props.actions.identifyUser)
      .then(() => history.replace(`/smart-portfolio/welcome`));
  };

  onConfirm = () => {
    this.props.actions
      .migratePortfolio()
      .then(this.props.actions.identifyUser)
      .then(() => {
        history.replace(`/smart-portfolio/holdings`);
      });
  };

  render() {
    const { msg } = this.props;
    return (
      <div className="welcome-info">
        <h1 dangerouslySetInnerHTML={{ __html: msg.title }} />
        <div className="subtitle">{msg.subtitle}</div>
        <div className="mobile-banner-wrapper">{this.renderMobileBanner()}</div>
        <div className="list-title">{msg.list.title}</div>
        <ul>
          <li>
            <div>{msg.list.importInfo}</div>
          </li>
          <li>
            <div>{msg.list.stayUpToDate}</div>
          </li>
          <li>
            <div>{msg.list.compare}</div>
          </li>
          <li>
            <div>{msg.list.optimize}</div>
          </li>
          <li>
            <div>{msg.list.alerts}</div>
          </li>
        </ul>
        <div className="note" dangerouslySetInnerHTML={{ __html: msg.note }} />
        <div className="buttons-wrapper">
          <button className="no-thanks" onClick={this.onDecline}>
            {msg.noThanks}
          </button>
          <div className="import-button-wrapper">
            <button onClick={this.onConfirm}>{msg.importButton}</button>
            <div
              className="button-note"
              dangerouslySetInnerHTML={{ __html: msg.buttonNote }}
            />
          </div>
        </div>
      </div>
    );
  }
}

WelcomeInfo.defaultProps = { msg: msg };

export default class Welcome extends Component {
  componentDidMount() {
    report('visited');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.importedUser.loading) {
      this.props.actions.openDialog('analyzingPortfolio');
    } else {
      this.props.actions.closeDialog();
    }
  }

  componentWillUnmount() {
    this.props.actions.closeDialog();
  }

  render() {
    const { auth } = this.props;

    const isPermanentLoggedIn = selectIsPermanentLoggedIn(auth);
    const viewer = selectViewer(auth);
    // const hasHoldings = viewer.getIn(['loggedInUser', 'hasHoldings']);

    return (
      <div className="welcome-dev">
        <div>
          <div className="welcome-banner-wrapper">
            <WelcomeBanner {...this.props} msg={msg.banner} />
          </div>
          <div className="welcome-info-wrapper">
            <WelcomeInfo {...this.props} msg={msg} />
          </div>
        </div>
      </div>
    );
  }
}
