import React from 'react';
import Popup from './popup.react';

import { communityNasdaqUrl } from '../../common/lib/utils.ts';

export default props => {
  const src =
    props.theme === 'web' || window.login
      ? '/social-auth/login'
      : `${communityNasdaqUrl}/login.aspx`;
  return <Popup {...props} src={src} />;
};
