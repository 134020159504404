export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const toggleSideMenu = payload => {
  return {
    type: TOGGLE_SIDE_MENU,
    payload
  };
};

export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const openSIdeMenu = () => {
  return {
    type: OPEN_SIDE_MENU
  };
};

export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const closeSideMenu = () => {
  return {
    type: CLOSE_SIDE_MENU
  };
};
