import { SectorEnum } from '../news-analysis/types';
import * as React from 'react';
import * as classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import { makeGraph } from 'sp/browser/tr-dashboard-user-performance-container/pie';
import { headIconGr } from 'sp/browser/tr-dashboard-avg-portfolio/utils';
import * as styles from './AllocationGraph.istyl';

const noop = () => null;

const colors = require(`../performance/common/colors.${
  process.env.THEME
  }.json`);
export const defaultColorList = colors.allocationColorList;
/** If there are more than X items, this color will be used for the "Other" item. */
export const otherColor = colors.otherColor;

export type AllocationItem = { id: string | 'other'; percent: number };

export class AllocationGraph extends React.PureComponent<{
  portfolioStocksDistribution: AllocationItem[];
  center: JSX.Element | string;
  lineThickness: number;
  className?: string;
  colors?: string[];
  size?: number;
}> {
  static defaultProps = {
    size: 110,
    colors: defaultColorList
  };

  drawGraph() {
    const {
      colors = AllocationGraph.defaultProps.colors,
      size,
      lineThickness,
      portfolioStocksDistribution
    } = this.props;

    makeGraph({
      theme: process.env.THEME,
      element: this.refs.graph,
      distribution: portfolioStocksDistribution,
      size: size,
      donutWidth: lineThickness,
      colors
    });
  }

  componentDidMount() {
    this.drawGraph();
  }
  componentDidUpdate() {
    this.drawGraph();
  }

  render() {
    const { portfolioStocksDistribution, center, className } = this.props;

    return (
      <div className={classNames(styles.container, className)}>
        <div className={classNames(styles.graph)} ref="graph" />
        <div className={classNames(styles.center)}>{center}</div>
      </div>
    );
  }
}
